import { Divider, Grid, Typography, styled } from '@mui/material';
import React from 'react';

const Caption = styled(Typography)({
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    opacity: 0.75
});

const BodyTypography = styled(Typography)({
    opacity: 0.75
});

interface StandaloneRefundSummaryProps {
    amount: string;
    refundedAmount: string;
    netAmount: string;
}

export const StandaloneRefundSummary: React.FC<StandaloneRefundSummaryProps> = ({
    amount,
    refundedAmount,
    netAmount
}) => (
    <Grid container>
        {refundedAmount && (
            <React.Fragment>
                <Grid item xs={8}>
                    <Caption align="right">Captured amount:</Caption>
                </Grid>
                <Grid item xs={4}>
                    <Caption align="right">{amount}</Caption>
                </Grid>
                <Grid item xs={8}>
                    <Caption align="right">Refunded amount:</Caption>
                </Grid>
                <Grid item xs={4}>
                    <Caption align="right">{refundedAmount}</Caption>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Divider />
                </Grid>
            </React.Fragment>
        )}
        <Grid item xs={8}>
            <BodyTypography variant="body2" align="right">
                Refundable amount:
            </BodyTypography>
        </Grid>
        <Grid item xs={4}>
            <BodyTypography variant="body2" align="right">
                {netAmount}
            </BodyTypography>
        </Grid>
    </Grid>
);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import {
    MESSAGE_MOBILE_CONTENT_UPDATE_LAYOUT_ERROR,
    MESSAGE_MOBILE_CONTENT_UPDATE_LAYOUT_SUCCESS
} from 'config/messages';
import { MOBILE_CONTENT_MODULE_CONFIGURATION } from 'config/routes';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { contentApi } from '../contentApi';
import { ContentLayout } from '../models/ContentLayout';
import { isDefined, isEmptyString, isString } from 'lib/typeguards';
import { Formik, FormikHelpers } from 'formik';
import { MuiTheme } from 'config/theme';
import * as Yup from 'yup';
import {
    ELayoutFormField,
    LAYOUT_COLOUR_FIELDS,
    LayoutConfigurationForm
} from 'components/content/layout-config/LayoutConfigurationForm';
import { rgbaToHex } from 'lib/helpers';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { useHistory, useParams } from 'react-router-dom';

export interface ILayoutConfigurationForm extends Record<string, any> {
    anchorBackgroundColour: string | RGBAValue;
    anchorTextColour: string | RGBAValue;
    titleTextColour: string | RGBAValue;
    bodyTextColour: string | RGBAValue;
    primaryActionBackgroundColour: string | RGBAValue;
    primaryActionTextColour: string | RGBAValue;
    tintColour: string | RGBAValue;
    useRoundedCorners: boolean;
    cornerRadius?: number;
}

interface IProps {
    className?: string;
}

const CORNER_RADIUS_VALIDATION_MESSAGE = 'Corner radius can range between 8 - 18 pixels.';

const validationSchema = Yup.object().shape({
    cornerRadius: Yup.number()
        .min(8, CORNER_RADIUS_VALIDATION_MESSAGE)
        .max(18, CORNER_RADIUS_VALIDATION_MESSAGE)
});

export const LayoutConfiguration = ({ className }: IProps) => {
    const currentLayout = useSelector<ApplicationState, ContentLayout>(state => state.content.currentLayout);
    const dispatch = useDispatch();
    const history = useHistory();
    const { layoutId } = useParams<{ layoutId?: string }>();

    const initialFormValues: ILayoutConfigurationForm = React.useMemo(() => {
        const {
            anchorBackgroundColour,
            anchorTextColour,
            titleTextColour,
            bodyTextColour,
            primaryActionBackgroundColour,
            primaryActionTextColour,
            tintColour,
            useRoundedCorners,
            cornerRadius
        } = currentLayout.anchor.properties;

        return {
            anchorBackgroundColour: anchorBackgroundColour ?? MuiTheme.palette.primary.main,
            anchorTextColour: anchorTextColour ?? MuiTheme.palette.primary.main,
            titleTextColour,
            bodyTextColour,
            primaryActionBackgroundColour: primaryActionBackgroundColour ?? MuiTheme.palette.primary.main,
            primaryActionTextColour: primaryActionTextColour ?? MuiTheme.palette.primary.main,
            tintColour: tintColour ?? MuiTheme.palette.primary.main,
            useRoundedCorners,
            cornerRadius
        };
    }, [currentLayout.anchor.properties]);

    const handleSubmit = React.useCallback(
        async (values: ILayoutConfigurationForm, formikHelpers: FormikHelpers<ILayoutConfigurationForm>) => {
            const valuesToSubmit = { ...values };

            if (isEmptyString(valuesToSubmit.bodyTextColour)) {
                delete valuesToSubmit.bodyTextColour;
            }
            if (isEmptyString(valuesToSubmit.titleTextColour)) {
                delete valuesToSubmit.titleTextColour;
            }
            if (
                isEmptyString(valuesToSubmit.cornerRadius) ||
                !isDefined(valuesToSubmit.cornerRadius) ||
                !valuesToSubmit.useRoundedCorners
            ) {
                delete valuesToSubmit.cornerRadius;
            } else {
                valuesToSubmit.cornerRadius = Number(valuesToSubmit.cornerRadius);
            }

            Object.entries(valuesToSubmit).forEach(([key, value]) => {
                if (value && LAYOUT_COLOUR_FIELDS.includes(key as ELayoutFormField)) {
                    valuesToSubmit[key as keyof ILayoutConfigurationForm] = isString(value)
                        ? value
                        : rgbaToHex(value as unknown as RGBAValue);
                }
            });

            const body = { anchor: { ...currentLayout.anchor, properties: valuesToSubmit } };
            const result = await contentApi.layouts.update(currentLayout.id, { body });
            if (!result.ok) {
                dispatch(enqueueSnackbar(MESSAGE_MOBILE_CONTENT_UPDATE_LAYOUT_ERROR, { variant: 'error' }));
                return;
            }
            dispatch(enqueueSnackbar(MESSAGE_MOBILE_CONTENT_UPDATE_LAYOUT_SUCCESS, { variant: 'success' }));
            formikHelpers.setSubmitting(false);
            history.push(`${MOBILE_CONTENT_MODULE_CONFIGURATION.replace(/:layoutId/g, currentLayout.id)}`);
        },
        [currentLayout.anchor, currentLayout.id, dispatch, history]
    );
    const isLayoutLoaded = React.useMemo(() => currentLayout.id === layoutId, [currentLayout.id, layoutId]);
    if (!isLayoutLoaded) {
        return null;
    }
    return (
        <Paper className={className}>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, handleSubmit: onSubmit, values, isValid }) => (
                    <LayoutConfigurationForm
                        isValid={isValid}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        values={values}
                    />
                )}
            </Formik>
        </Paper>
    );
};

/* eslint-disable max-len */
import { AutoFormFields } from 'lib/LegacyForm';

export const createLocationOpeningHoursForm: () => AutoFormFields = () => ({
    monday: {
        label: 'Monday',
        type: 'openingHoursInput'
    },
    tuesday: {
        label: 'Tuesday',
        type: 'openingHoursInput'
    },
    wednesday: {
        label: 'Wednesday',
        type: 'openingHoursInput'
    },
    thursday: {
        label: 'Thursday',
        type: 'openingHoursInput'
    },
    friday: {
        label: 'Friday',
        type: 'openingHoursInput'
    },
    saturday: {
        label: 'Saturday',
        type: 'openingHoursInput'
    },
    sunday: {
        label: 'Sunday',
        type: 'openingHoursInput'
    }
});

export const createHTMLLocationOpeningHoursForm: () => AutoFormFields = () => ({
    alternativeOpeningHoursText: {
        label: 'Custom Opening Hours HTML',
        description:
            'Using HTML, This lets you override how opening hours are displayed in the app. However, the standard opening hours will still be used to determine when the location is open for ordering. This will replace your standard defined opening hours.'
    }
});

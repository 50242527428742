import { TextField, styled } from '@mui/material';
import React from 'react';
import { CardBrandIcon } from 'lib/CardBrandIcon';
import { CardBrand } from 'components/pepper-pay/model/PepperPay';

const StyledTextField = styled(TextField)({
    '& input': {
        textAlign: 'right'
    }
});

interface OrderRefundPaymentMethodProps {
    last4: string;
    brand: CardBrand;
}

export const OrderRefundPaymentMethod: React.FC<OrderRefundPaymentMethodProps> = ({ brand, last4 }) => (
    <StyledTextField
        variant="outlined"
        disabled
        value={`**** ${last4}`}
        InputProps={{ startAdornment: <CardBrandIcon brand={brand} size="medium" /> }}
        fullWidth
        label="Payment method"
    />
);

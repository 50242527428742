import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Divider, Grid, Input, Link, Tooltip, Typography, styled } from '@mui/material';
import { Permission, Resource } from '@pepperhq/auth-client';
import { format, set } from 'date-fns';
import { CUSTOMERS } from 'config/routes';
import { titleize } from 'lib/helpers';
import { useToggle } from 'lib/hooks/useToggle';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { Panel } from 'ui/Panel';
import { CardViewChipArrayRow, CardViewChipRow, CardViewSwitchRow, CardViewTextRow } from 'ui/viewComponents';
import { customerApi } from '../customerApi';
import { getPrimaryPlatform, mapCustomerStateToStyles } from '../helpers';
import { Customer } from '../models/Customer';
import { CustomerContactForm } from './CustomerContactForm';
import { CustomerDeviceUniquenessForm } from './CustomerDeviceUniquenessForm';
import { CustomersGeneralEdit } from './CustomerGeneralEdit';
import {
    MESSAGE_CUSTOMER_DELETE_SUCCESS,
    MESSAGE_CUSTOMER_DELETE_ERROR,
    MESSAGE_CUSTOMER_SESSION_DELETE_SUCCESS,
    MESSAGE_CUSTOMER_SESSION_DELETE_ERROR,
    MESSAGE_CUSTOMER_PASSWORD_INVALIDATE_SUCCESS,
    MESSAGE_CUSTOMER_PASSWORD_INVALIDATE_ERROR
} from 'config/messages';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { sessionApi } from '../sessionApi';
import { IUserDeletionSetting } from '@pepperhq/location-sdk';
import { isDefined } from 'lib/typeguards';
import { QueryParameter } from 'lib/HttpClient';

const getUserAvatar = (id: string) => `${process.env.MEDIA_SERVICE_URL}/users/avatars/${id}.jpg`;

const PREFIX = 'CustomersGeneralCard';

const classes = {
    chip: `${PREFIX}-chip`,
    avatar: `${PREFIX}-avatar`,
    photoPlaceholder: `${PREFIX}-photoPlaceholder`,
    modal: `${PREFIX}-modal`,
    spacing: `${PREFIX}-spacing`,
    buttons: `${PREFIX}-buttons`,
    cancelButton: `${PREFIX}-cancelButton`,
    deleteButton: `${PREFIX}-deleteButton`
};

const StyledPanel: any = styled(Panel)<CustomersGeneralCardProps>(({ theme, customer }) => ({
    [`& .${classes.chip}`]: mapCustomerStateToStyles(customer.state, theme),
    [`& .${classes.avatar}`]: {
        width: theme.spacing(20),
        height: theme.spacing(20)
    },
    [`& .${classes.photoPlaceholder}`]: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[300]
    },
    [`& .${classes.deleteButton}`]: {
        marginBottom: theme.spacing(1)
    }
}));

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    [`&.${classes.modal}`]: {
        width: theme.spacing(75),
        minWidth: theme.spacing(56),
        maxWidth: theme.spacing(85)
    },
    [`& .${classes.spacing}`]: {
        margin: theme.spacing(2, 0)
    },
    [`& .${classes.buttons}`]: {
        width: '100%',
        display: 'flex'
    },
    [`& .${classes.cancelButton}`]: {
        marginRight: theme.spacing(2.25)
    }
}));

interface CustomersGeneralCardProps {
    customer: Customer;
    onChange: (data: Partial<Customer>) => void;
    uniqueDeviceVerification: boolean;
    userDeletionSetting: Partial<IUserDeletionSetting>;
}

export const CustomersGeneralCard: React.FC<CustomersGeneralCardProps> = props => {
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const [editing, setEditing] = React.useState(false);
    const [deleteCustomerInputValue, setDeleteCustomerInputValue] = React.useState('');
    const [isContactOpen, toggleContactModal] = useToggle(false);
    const [isLogoutSessionsModalOpen, toggleLogoutSessionsModal] = useToggle(false);
    const [isInvalidatePasswordModalOpen, toggleInvalidatePasswordModal] = useToggle(false);
    const [isDeleteCustomerDialogOpen, toggleDeleteCustomerDialog] = useToggle(false);
    const [isDeviceUniquenessOpen, toggleDeviceUniquenessModal] = useToggle(false);
    const handleClose = React.useCallback(() => setEditing(false), []);
    const handleOpen = React.useCallback(() => setEditing(true), []);
    const { customer, onChange, uniqueDeviceVerification, userDeletionSetting } = props;

    const { push } = useHistory();
    const dispatch = useDispatch();

    const handleDeleteUser = React.useCallback(() => {
        const queryParameters: QueryParameter[] = [];

        if (userDeletionSetting) {
            if (isDefined(userDeletionSetting.deleteLoyaltyAccountOnUserDeletion)) {
                queryParameters.push({
                    key: 'deleteLoyaltyAccountOnUserDeletion',
                    value: userDeletionSetting.deleteLoyaltyAccountOnUserDeletion
                });
            }

            if (isDefined(userDeletionSetting.deletePosAccountOnUserDeletion)) {
                queryParameters.push({
                    key: 'deletePosAccountOnUserDeletion',
                    value: userDeletionSetting.deletePosAccountOnUserDeletion
                });
            }
        }

        customerApi
            .delete(props.customer._id, { queryParameters })
            .then(() => {
                push(CUSTOMERS);
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_DELETE_SUCCESS, { variant: 'success' }));
            })
            .catch(() => {
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_DELETE_ERROR, { variant: 'error' }));
            });
    }, [dispatch, props.customer._id, push, userDeletionSetting]);
    const handleDeleteCustomerInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteCustomerInputValue(event.target.value);
    }, []);
    const handleLogoutSessions = React.useCallback(() => {
        sessionApi
            .deleteSessions(props.customer._id)
            .then(() => {
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_SESSION_DELETE_SUCCESS, { variant: 'success' }));
            })
            .catch(() => {
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_SESSION_DELETE_ERROR, { variant: 'error' }));
            })
            .finally(() => {
                toggleLogoutSessionsModal();
            });
    }, [dispatch, props.customer._id, toggleLogoutSessionsModal]);
    const handleInvalidatePassword = React.useCallback(async () => {
        const invalidated = await customerApi.adminInvalidatePassword(props.customer.credentials[0].id);
        if (!invalidated.ok) {
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_PASSWORD_INVALIDATE_ERROR, { variant: 'error' }));
        } else {
            // log out success is irrelevent because the user might not be logged in
            await sessionApi.deleteSessions(props.customer._id);
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_PASSWORD_INVALIDATE_SUCCESS, { variant: 'success' }));
        }
        return toggleInvalidatePasswordModal();
    }, [dispatch, props.customer._id, props.customer.credentials, toggleInvalidatePasswordModal]);
    const [year, month, day] = React.useMemo(
        () => (customer.birthdate ?? '').split('-'),
        [customer.birthdate]
    );

    return (
        <React.Fragment>
            <StyledPanel title="General" showAction={!editing} onClick={handleOpen} customer={customer}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {customer.hasProfilePhoto ? (
                                <Grid item xs={12}>
                                    <Row flex={1} align="center">
                                        <Avatar
                                            alt={customer.fullName}
                                            src={getUserAvatar(customer._id)}
                                            className={classes.avatar}
                                        />
                                    </Row>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Row flex={1} align="center" valign="center">
                                        <Row
                                            className={classes.photoPlaceholder}
                                            align="center"
                                            valign="center"
                                        >
                                            <Typography color="textSecondary">No Photo</Typography>
                                        </Row>
                                    </Row>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {customer.hasAgreedToReceiveMarketing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        onClick={toggleContactModal}
                                    >
                                        Contact {customer.firstName}
                                    </Button>
                                ) : (
                                    <Tooltip title="You cannot send a notification to this customer due to their marketing preferences">
                                        <div>
                                            <Button
                                                disabled
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                onClick={toggleContactModal}
                                            >
                                                Contact {customer.firstName}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {editing ? (
                        <CustomersGeneralEdit
                            onClose={handleClose}
                            customer={customer}
                            uniqueDeviceVerification={uniqueDeviceVerification}
                            onChange={onChange}
                            toggleDeviceUniquenessModal={toggleDeviceUniquenessModal}
                        />
                    ) : (
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <CardViewTextRow title="Name" value={customer.fullName} />
                                <CardViewChipRow
                                    title="State"
                                    value={customer.state}
                                    chipClasses={{ root: classes.chip }}
                                />
                                <CardViewChipArrayRow
                                    title="Contact Details"
                                    value={
                                        Array.isArray(customer.credentials)
                                            ? customer.credentials.map(item => item.id)
                                            : []
                                    }
                                />
                                <CardViewTextRow
                                    title={'Role' + (customer.roles?.length > 1 ? 's' : '')}
                                    value={customer.roles?.map(role => titleize(role)).join(', ')}
                                />
                                <CardViewTextRow
                                    title="Primary platform"
                                    value={getPrimaryPlatform(customer.primaryPlatform)}
                                />
                                <CardViewTextRow
                                    title="Created"
                                    value={format(new Date(customer.created), 'dd/MM/yyyy')}
                                />
                                <CardViewSwitchRow
                                    title="Push Notifications Enabled?"
                                    value={customer.isRegisteredForPushNotifications}
                                />
                                <CardViewSwitchRow
                                    title="Agreed to Receive Marketing?"
                                    value={customer.hasAgreedToReceiveMarketing}
                                />
                                {customer.birthdate && (
                                    <CardViewTextRow
                                        title="Date of Birth"
                                        value={format(
                                            set(new Date(), {
                                                year: Number(year),
                                                month: Number(month) - 1,
                                                date: Number(day)
                                            }),
                                            'dd/MM/yyyy'
                                        )}
                                    />
                                )}
                                <Grid item xs={12}>
                                    {uniqueDeviceVerification &&
                                        (customer.resetUniqueDeviceVerification ? (
                                            <p>
                                                The device registration reset process has been started for
                                                this user
                                            </p>
                                        ) : (
                                            <p>
                                                {customer.firstName} has a device registered to their user,
                                                click
                                                <Link
                                                    href="#"
                                                    onClick={toggleDeviceUniquenessModal}
                                                    underline="hover"
                                                >
                                                    {' '}
                                                    here{' '}
                                                </Link>
                                                to release it
                                            </p>
                                        ))}
                                </Grid>
                                {claims.hasPermission(Resource.Customer, Permission.delete) && (
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={toggleDeleteCustomerDialog}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.deleteButton}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                )}
                                {claims.hasPermission(Resource.Customer, Permission.delete) && (
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={toggleInvalidatePasswordModal}
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            className={classes.deleteButton}
                                        >
                                            Invalidate Password
                                        </Button>
                                    </Grid>
                                )}
                                {claims.hasPermission(Resource.Customer, Permission.delete) && (
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={toggleLogoutSessionsModal}
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            className={classes.deleteButton}
                                        >
                                            Log out all sessions
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </StyledPanel>
            <StyledMuiModal open={isContactOpen} onClose={toggleContactModal} className={classes.modal}>
                <CustomerContactForm onClose={toggleContactModal} customer={customer} />
            </StyledMuiModal>
            <StyledMuiModal
                open={isDeviceUniquenessOpen}
                onClose={toggleDeviceUniquenessModal}
                className={classes.modal}
            >
                <CustomerDeviceUniquenessForm
                    onClose={toggleDeviceUniquenessModal}
                    customer={customer}
                    onChange={onChange}
                />
            </StyledMuiModal>
            <StyledMuiModal open={isDeleteCustomerDialogOpen} onClose={toggleDeleteCustomerDialog}>
                <>
                    <Typography variant="h5" color="primary">
                        Delete {`${customer.fullName}`}
                    </Typography>
                    <Divider className={classes.spacing} />
                    <Typography>
                        If you delete the customer, you won&apos;t be able to recover it.
                        <br />
                        To confirm deletion, type &quot;DELETE&quot; in the text input field.
                    </Typography>
                    <Input fullWidth value={deleteCustomerInputValue} onChange={handleDeleteCustomerInput} />
                    <Divider className={classes.spacing} />
                    <Box justifyContent="flex-end" className={classes.buttons}>
                        <Button
                            className={classes.cancelButton}
                            variant="outlined"
                            color="primary"
                            onClick={toggleDeleteCustomerDialog}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={handleDeleteUser}
                            disabled={deleteCustomerInputValue !== 'DELETE'}
                        >
                            Delete
                        </LoadingButton>
                    </Box>
                </>
            </StyledMuiModal>
            <ConfirmDialog
                title={`Log out all sessions for ${customer.fullName}`}
                content="Are you sure you want to log this user out of all current sessions?"
                open={isLogoutSessionsModalOpen}
                onCancel={toggleLogoutSessionsModal}
                onConfirm={handleLogoutSessions}
            />
            <StyledMuiModal open={isInvalidatePasswordModalOpen} onClose={toggleInvalidatePasswordModal}>
                <>
                    <Typography variant="h5" color="primary">
                        Invalidate Password for {`${customer.fullName}`}
                    </Typography>
                    <Divider className={classes.spacing} />
                    <Typography>
                        If you invalidate the password for this user, they will be logged out of the app and
                        their existing password will no longer work.
                        <br />
                        <br />
                        This action is unrecoverable. The user will still have to manually reset their
                        password.
                    </Typography>
                    <Divider className={classes.spacing} />
                    <Box justifyContent="flex-end" className={classes.buttons}>
                        <Button
                            className={classes.cancelButton}
                            variant="outlined"
                            color="primary"
                            onClick={toggleInvalidatePasswordModal}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleInvalidatePassword}>
                            Invalidate Password
                        </Button>
                    </Box>
                </>
            </StyledMuiModal>
        </React.Fragment>
    );
};

import React, { ChangeEvent } from 'react';
import { InputAdornment, styled, TextField } from '@mui/material';
import Folder from '@mui/icons-material/Folder';
import { Field, FieldAttributes, FieldProps } from 'formik';
import logger from 'lib/logger';

const PREFIX = 'FileInputFormField';

const classes = {
    fileInput: `${PREFIX}-fileInput`,
    icon: `${PREFIX}-icon`
};

const StyledField = styled(Field)(({ theme }) => ({
    [`& .${classes.fileInput}`]: {
        paddingRight: theme.spacing(1.2),
        cursor: 'pointer'
    },
    [`& .${classes.icon}`]: {
        cursor: 'pointer'
    }
}));

interface FileInputProps {
    label?: string;
    placeholder?: string;
    attributes?: Record<string, string>;
    description?: React.ReactNode;
    path?: string;
    isLoading?: boolean;
    defaultOpen?: boolean;
}

const buildFileSelector = (
    handler: React.ChangeEventHandler<HTMLInputElement>,
    attributes: Record<string, string> = {}
) => {
    const fileSelector = document.createElement('input');
    Object.entries({ type: 'file', ...attributes }).forEach(([attribute, value]) => {
        fileSelector.setAttribute(attribute, value);
    });
    // FIXME: remove when we implement dropzone for files
    fileSelector.onchange = handler as any;

    return fileSelector;
};

export const FileInput: React.FC<FileInputProps & FieldProps> = ({
    label,
    field,
    placeholder,
    path,
    meta,
    isLoading,
    attributes,
    description,
    defaultOpen
}) => {
    const [fileSelector, setFileSelector] = React.useState(null);
    const isError = meta.touched && !!meta.error;
    const openFileSelector = React.useCallback(
        (event?: React.MouseEvent) => {
            event?.stopPropagation();
            event?.preventDefault();
            fileSelector.click();
        },
        [fileSelector]
    );
    React.useEffect(() => {
        const fileHandler = (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.files?.length) {
                const fileList: FileList = event.target.files;

                if (fileList.item(0) instanceof Blob) {
                    field.onChange({
                        target: { value: fileList.item(0), name: field.name }
                    });
                } else {
                    logger.error('Failed to load the file');
                }
            }
        };
        setFileSelector(buildFileSelector(fileHandler, attributes));
    }, [field, attributes]);
    React.useEffect(() => {
        if (defaultOpen && fileSelector && !field.value) {
            openFileSelector();
        }
    }, [defaultOpen, openFileSelector, fileSelector, field.value]);
    const helperText = React.useMemo(
        () => (isError ? meta.error : description),
        [description, isError, meta.error]
    );

    return (
        <TextField
            fullWidth
            value={isLoading ? 'Loading...' : field.value?.name || path || ''}
            placeholder={placeholder}
            inputProps={{ className: classes.fileInput, style: { textAlign: 'start' } }}
            name={field.name}
            onClick={openFileSelector}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="start">
                        <Folder className={classes.icon} />
                    </InputAdornment>
                )
            }}
            label={label}
            helperText={helperText}
        />
    );
};

export const FileInputFormField: React.FC<FieldAttributes<FileInputProps>> = ({
    label,
    attributes,
    description,
    path,
    isLoading,
    placeholder,
    defaultOpen,
    ...props
}) => (
    <StyledField {...props}>
        {(childProps: FieldProps) => (
            <FileInput
                label={label}
                path={path}
                isLoading={isLoading}
                attributes={attributes}
                placeholder={placeholder}
                description={description}
                defaultOpen={defaultOpen}
                {...childProps}
            />
        )}
    </StyledField>
);

import React from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import { MuiImage } from 'ui/MuiImage';
import { Location } from '../models/LocationModel';

interface LocationImagesCardComponentProps {
    location: Location;
}

export function LocationImagesCardComponent(props: LocationImagesCardComponentProps) {
    const { location } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            <b>Banner</b>
                        </Typography>
                    </Grid>
                    <Tooltip
                        placement="right-start"
                        title="This will be used to represent the location throughout the app."
                    >
                        <Grid item xs={12}>
                            <MuiImage
                                src={location.mainImageUrl}
                                placeholder="600 x 260"
                                aspect={600 / 260}
                                variant="formImage"
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid item xs={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            <b>Thumbnail</b>
                        </Typography>
                    </Grid>
                    <Tooltip
                        placement="right-start"
                        title="This will be used to represent the location within lists such as a user's favourited locations"
                    >
                        <Grid item xs={12}>
                            <MuiImage
                                src={location.listingImageUrl}
                                placeholder="465 x 496"
                                aspect={465 / 496}
                                variant="formImage"
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            <b>Map Pin</b>
                        </Typography>
                    </Grid>
                    <Tooltip
                        placement="right-start"
                        title="Apply a map pin for this individual location. This is optional."
                    >
                        <Grid item xs={12}>
                            <MuiImage
                                src={location.mapPinImageUrl}
                                placeholder="41 x 63"
                                aspect={41 / 63}
                                variant="formImage"
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

import { Chip, styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { titleize } from 'lib/helpers';
import React from 'react';
import { DisputeStatus } from '../model/PepperPay';

const PREFIX = 'DisputeStatusChip';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-grey`
};

const StyledChip = styled(Chip)(({ theme }) => ({
    [`&.${classes.green}`]: { backgroundColor: green['A700'], color: theme.palette.primary.contrastText },
    [`&.${classes.yellow}`]: { backgroundColor: orange[500], color: theme.palette.primary.contrastText },
    [`&.${classes.red}`]: { backgroundColor: red[500], color: theme.palette.primary.contrastText },
    [`&.${classes.grey}`]: {}
}));

interface DisputeStatusChipProps {
    value: string;
}

export const DisputeStatusChip: React.FC<DisputeStatusChipProps> = ({ value }) => {
    const className = React.useMemo(() => {
        switch (value) {
            case DisputeStatus.Won:
                return classes.green;
            case DisputeStatus.NeedsResponse:
            case DisputeStatus.WarningNeedsResponse:
                return classes.yellow;
            case DisputeStatus.Lost:
                return classes.red;
            default:
                return classes.grey;
        }
    }, [value]);
    const formattedValue = React.useMemo(() => titleize(String(value).replaceAll('_', ' '), true), [value]);
    return <StyledChip className={className} label={formattedValue} />;
};

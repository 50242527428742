import { reportTypeToMetadata } from './consts';

export function getOperationTypeAndFormat(operationType: string) {
    const reportTypeMetadata = reportTypeToMetadata[operationType];

    return reportTypeMetadata
        ? { type: reportTypeMetadata.type, format: reportTypeMetadata.format }
        : { type: null, format: null };
}

export function createTriggerOptions(count: number) {
    return new Array(count).fill('').map((_, index) => ({
        value: String(index + 1),
        label: String(index + 1)
    }));
}

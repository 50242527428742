import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { LocationCardsWrapper } from 'components/location/cards/LocationCardsWrapper';
import { locationApi } from 'components/location/LocationApi';
import { LOCATIONS } from 'config/routes';
import { Throbber } from 'ui/Throbber';

// FIXME: add loading and error handling
export default () => {
    const history = useHistory();
    const [currentLocation, setCurrentLocation] = React.useState(null);
    const { locationId } = useParams<{ locationId: string }>();
    React.useEffect(() => {
        async function load() {
            const response = await locationApi.get(locationId);
            if (response.ok) {
                setCurrentLocation(response.body);
            }
            if (response.statusCode === 404) {
                history.replace(LOCATIONS);
            }
        }
        load();
    }, [history, locationId]);
    if (!locationId) {
        return <Redirect to={LOCATIONS} />;
    }
    // TODO: add skeleton
    if (!currentLocation) {
        return <Throbber />;
    }
    return (
        <MainLayout
            breadcrumbs={[{ label: 'Locations', url: LOCATIONS }]}
            pageName={currentLocation.title}
            pageDescription="Location details"
        >
            {currentLocation && (
                <LocationCardsWrapper location={currentLocation} setCurrentLocation={setCurrentLocation} />
            )}
        </MainLayout>
    );
};

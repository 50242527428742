import { Box, Button, IconButton, Theme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { MenuV5 } from '@pepperhq/menu-sdk';
import React from 'react';
import { Row } from 'ui/Flex';
import { OperationStatusIndicator } from './OperationStatusIndicator';
import { RefreshMenuButton } from './RefreshMenuButton';
import { LocationsContext } from './useCopyTab';
import { CopyToButton } from './CopyToButton';
import { useOrientation } from 'react-use';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

interface IProps {
    onCreate?: () => void;
    onRefreshMenu: () => void;
    onCancelEditing: () => void;
    onSubmit: () => void;
    onCopy?: () => void;
    onDelete?: () => void;
    isEditedOrDeleted: boolean;
    menu: MenuV5;
    activeOperationId: string;
    copyEnabled?: boolean;
    deleteEnabled?: boolean;
}

export const ActionsHeader: React.FC<IProps> = ({
    onCreate,
    onCancelEditing,
    onRefreshMenu,
    onSubmit,
    onCopy,
    onDelete,
    deleteEnabled,
    menu,
    isEditedOrDeleted,
    activeOperationId,
    copyEnabled
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const otherLocations = React.useContext(LocationsContext);
    const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { type: orientationType } = useOrientation();
    const isLandscape = React.useMemo(
        () => orientationType.includes('landscape') || !orientationType,
        [orientationType]
    );

    return (
        <Row valign="center" align="space-between" wrap="wrap">
            <Box pl={mdDown ? 0 : 1}>
                <Row gutter valign="center">
                    {onCreate &&
                        (mdDown ? (
                            <IconButton onClick={onCreate} color="primary">
                                <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                        ) : (
                            <Button
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={onCreate}
                                variant="outlined"
                                color="primary"
                            >
                                Create
                            </Button>
                        ))}
                    {(!mdDown || isLandscape) && (
                        <RefreshMenuButton
                            isUnsavedChanges={isEditedOrDeleted}
                            onClick={onRefreshMenu}
                            updated={menu.updated}
                            settings={settings}
                            direction={mdDown ? 'column' : undefined}
                        />
                    )}
                </Row>
            </Box>
            <Box pr={mdDown ? 0 : 1}>
                <Row gutter valign="center">
                    <OperationStatusIndicator />
                    {isEditedOrDeleted && (
                        <>
                            <Box>
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={onCancelEditing}
                                    disabled={!!activeOperationId}
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    size="small"
                                    onClick={onSubmit}
                                    disabled={!!activeOperationId}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Box>
                        </>
                    )}
                    {onCopy && (
                        <CopyToButton
                            size="small"
                            onCopy={onCopy}
                            otherLocations={otherLocations}
                            readyToSave={isEditedOrDeleted}
                            disabled={!copyEnabled}
                        />
                    )}
                    {onDelete && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={onDelete}
                            disabled={!deleteEnabled}
                        >
                            Delete
                        </Button>
                    )}
                </Row>
            </Box>
        </Row>
    );
};

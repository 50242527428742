import * as React from 'react';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import Validator from 'validatorjs';
import { AutoFormFields, FormItem, FormStep, StepperForm } from 'lib/LegacyForm';
import { Row } from 'ui/Flex';
import { AutoFormField } from './AutoFormField';

type FieldData = any;
type RenderedFields = Record<string, React.ReactElement>;

type ValidationMap<Fields> = { [key in keyof Fields]?: string };
type RulesMap<Fields> = { [key in keyof Fields]?: string | string[] };
interface FormStepperData {
    [key: string]: FieldData;
}

export interface AutoFormStepperProps {
    title?: string;
    form: StepperForm;
    padding?: boolean;
    onBlur?: (name: string, value: any) => void;
    onNextStep?: (data: FormStepperData, step: number) => void;
    onClose?: () => void;
    children?: (
        fields: RenderedFields,
        defaultRenderer: (renderedFields: RenderedFields) => React.ReactElement[]
    ) => React.ReactNode;
}

export interface AutoFormStepperState<Fields> {
    data: FormStepperData;
    validationMap: ValidationMap<Fields>;
    step: number;
}

const getPrefilledFormData = function (fields: FormStep[]) {
    return fields.reduce((formData, step) => {
        const stepData = Object.keys(step.data).reduce((acc: FormStepperData, item) => {
            acc[item] = step.data[item].value || '';
            if (step.data[item].type === 'switch' || step.data[item].type === 'checkbox') {
                acc[item] = step.data[item].value || false;
            }
            return acc;
        }, {});
        return { ...formData, ...stepData };
    }, {});
};

const getPrefilledValidationMap = function (fields: FormStep[]) {
    const flatFields = fields.reduce((acc, item) => ({ ...acc, ...item.data }), {});
    return Object.keys(flatFields).reduce((acc: ValidationMap<AutoFormFields>, item) => {
        acc[item] = '';
        return acc;
    }, {});
};

const getValidationRules = function (fields: AutoFormFields) {
    return Object.keys(fields).reduce((acc: RulesMap<AutoFormFields>, item) => {
        if (fields[item].validation) {
            acc[item] = fields[item].validation;
        }
        return acc;
    }, {});
};

export class AutoFormStepper<Fields extends { [key: string]: any }> extends React.Component<
    AutoFormStepperProps,
    AutoFormStepperState<Fields>
> {
    constructor(props: AutoFormStepperProps) {
        super(props);
        this.state = {
            data: getPrefilledFormData(props.form.fields),
            validationMap: getPrefilledValidationMap(props.form.fields),
            step: 0
        };
    }
    componentDidMount() {
        this.props.form.setUpdateForParent(this.update);
    }
    update = (data: { [key: string]: any }) => {
        this.setState(state => ({
            data: { ...state.data, ...data }
        }));
    };
    validate = (submit?: () => void) => {
        const { data, step } = this.state;
        const { fields } = this.props.form;
        const validation = new Validator(data, getValidationRules(fields[step].data));
        validation.passes();
        const errors: Validator.ValidationErrors = validation.errors.all();
        this.setState(
            state => ({
                validationMap: Object.keys(state.validationMap).reduce(
                    (acc: ValidationMap<Fields>, item: Extract<keyof Fields, string>) => {
                        acc[item] = errors[item] ? (validation.errors.first(item) as string) : '';
                        return acc;
                    },
                    {}
                )
            }),
            submit
        );
    };
    handleBlur = (name: string, value: any) => {
        if (this.props.onBlur) {
            this.props.onBlur(name, value);
        }
    };
    isContainErrors() {
        return Object.values(this.state.validationMap).some(item => !!item);
    }
    handleChange = (name: string, value: any) => {
        this.setState(state => ({
            data: {
                ...state.data,
                [name]: value
            }
        }));
    };
    handleSubmit = () => {
        this.callWithValidation(() => this.props.form.handleSubmit(this.state.data));
    };
    goBack = () => {
        this.setState(({ step }) => ({ step: step - 1 }));
    };
    nextStep = () => {
        const { onNextStep } = this.props;
        if (onNextStep) {
            onNextStep(this.state.data, this.state.step);
        }
        this.callWithValidation(() => this.setState(({ step }) => ({ step: step + 1 })));
    };
    callWithValidation = (method: () => any) => {
        this.validate(() => {
            if (!this.isContainErrors()) {
                method();
            }
        });
    };
    renderField = (fieldName: string, field: FormItem, fieldData: FieldData, error?: string) => (
        <AutoFormField
            key={fieldName}
            onBlur={this.handleBlur}
            {...field}
            error={error}
            name={fieldName}
            value={fieldData}
            onChange={this.handleChange}
        />
    );
    getRenderedSteps = () => {
        const { fields } = this.props.form;
        const { step } = this.state;
        return fields.map(
            ({ stepTitle, stepSubTitle, data }, stepIndex) =>
                stepIndex === step && (
                    <React.Fragment key={`form-step-${stepIndex}`}>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                {stepTitle}
                            </Typography>
                            {stepSubTitle && <Typography variant="subtitle2">{stepSubTitle}</Typography>}
                        </Grid>
                        {this.renderFieldsAsRows(this.getRenderedFields(data))}
                    </React.Fragment>
                )
        );
    };
    getRenderedFields = (fields: AutoFormFields) => {
        const { data, validationMap } = this.state;
        return Object.keys(fields).reduce(
            (renderedFields, fieldName) => ({
                ...renderedFields,
                [fieldName]: this.renderField(
                    fieldName,
                    fields[fieldName],
                    data[fieldName],
                    validationMap[fieldName]
                )
            }),
            {}
        );
    };
    renderFieldsAsRows = (renderedFields: RenderedFields) =>
        Object.values(renderedFields).map((element, index) =>
            React.cloneElement(element, { key: `form-item-${index}` })
        );

    render() {
        const { title, onClose, form, padding } = this.props;
        const { step } = this.state;
        const formPadding = padding ? 16 : 0;
        return (
            <React.Fragment>
                <LinearProgress
                    style={{ paddingBottom: 8 }}
                    variant="determinate"
                    value={(100 * step) / form.fields.length}
                />
                <Grid container spacing={2}>
                    {title && (
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid style={{ padding: formPadding }} container spacing={2}>
                            {this.getRenderedSteps()}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Row align="flex-end" valign="center" style={{ padding: formPadding }}>
                            {onClose && (
                                <Button
                                    style={{ marginRight: step > 0 ? '10px' : null }}
                                    variant="contained"
                                    color="primary"
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            )}
                            {step > 0 && (
                                <Button variant="contained" color="primary" onClick={this.goBack}>
                                    Back
                                </Button>
                            )}
                            {step < form.fields.length - 1 && (
                                <Button
                                    style={{ marginLeft: '10px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.nextStep}
                                >
                                    Next Step
                                </Button>
                            )}
                            {step === form.fields.length - 1 && (
                                <Button
                                    style={{ marginLeft: '10px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </Button>
                            )}
                        </Row>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

import { IPagedCollection, IPageOptions, isPagedCollection } from 'lib/api/paging';
import { HttpClient, HttpClientResponseContent } from 'lib/HttpClient';
import { isDefined } from 'lib/typeguards';
import { IOperationV2 } from './models/OperationModel';

export type OperationQuery<Operation extends IOperationV2<any, any, any, any>> = Partial<
    Pick<Operation, 'status'>
>;

export class NamespacedOperationsApi<Operation extends IOperationV2<any, any, any, any>> {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly namespace: string,
        private readonly isOperation: (operation: any) => operation is Operation
    ) {}

    public async getOne(
        entityId: string,
        operationId: string
    ): Promise<HttpClientResponseContent<Operation>> {
        const response = await this.httpClient.get(
            `${process.env.API_SERVER_URL}/${this.namespace}/${entityId}/operations/${operationId}`
        );
        if (!response.ok) {
            throw new Error(response.body?.message || response.body?.error?.message || 'Request failed');
        }

        if (!this.isOperation(response.body)) {
            throw new Error('Failed to parse body');
        }

        return response;
    }

    public async getAllPaged(
        entityId: string,
        query: OperationQuery<Operation>,
        paging: IPageOptions
    ): Promise<HttpClientResponseContent<IPagedCollection<Operation>>> {
        const queryParameters = [...Object.entries(paging), ...Object.entries(query)]
            .filter(([_, value]) => isDefined(value))
            .map(([key, value]) => ({ key, value }));

        const response = await this.httpClient.get(
            `${process.env.API_SERVER_URL}/${this.namespace}/${entityId}/operations`,
            { queryParameters }
        );

        if (!response.ok) {
            throw new Error(response.body?.message || response.body?.error?.message || 'Request failed');
        }

        if (!isPagedCollection(this.isOperation, response.body)) {
            throw new Error('Failed to parse body');
        }

        return response;
    }

    public async retry<RetryOperation>(
        entityId: string,
        operationId: string,
        nonce: string,
        isRetryOperation: (operation: any) => operation is RetryOperation
    ): Promise<HttpClientResponseContent<RetryOperation>> {
        const response = await this.httpClient.post(
            `${process.env.API_SERVER_URL}/${this.namespace}/${entityId}/operations/${operationId}/retry`,
            {
                headers: { 'x-pepper-req-nonce': nonce }
            }
        );
        if (!response.ok) {
            throw new Error(response.body?.message || response.body?.error?.message || 'Request failed');
        }
        if (!isRetryOperation(response.body)) {
            throw new Error('Failed to parse body');
        }
        return response;
    }
}

import { AutoFormFields } from 'lib/LegacyForm';
import { ContentModule, SecondaryAction } from '../models/ContentModule';

export const createModuleBasicForm: AutoFormFields = {
    title: {
        label: 'Title',
        type: 'text',
        width: 9
    },
    index: {
        label: 'Sort',
        type: 'number',
        builtInLabel: true,
        width: 3,
        validation: 'required|integer|min:0|max:9999',
        errors: {
            required: 'Thie field is required',
            integer: 'Sort should be a number',
            max: 'Sort should not be greater than 9999',
            min: 'Sort should be greater or equal to 0'
        }
    },
    secondaryAction: {
        type: 'label',
        label: 'Secondary Action'
    },
    secondaryActionAction: {
        label: 'Action',
        type: 'select',
        options: Object.keys(SecondaryAction).map((key: string) => ({
            label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
            value: key
        })),
        width: 4
    },
    secondaryActionTitle: {
        label: 'Title',
        type: 'text',
        width: 8
    }
};

export function transformModuleToBasicFormFields(module: ContentModule): any {
    return {
        ...module,
        secondaryActionTitle: module.secondaryAction ? module.secondaryAction.title : undefined,
        secondaryActionAction: module.secondaryAction ? module.secondaryAction.action : undefined
    };
}

export function transformBasicFormFieldToModuleFields(
    fieldName: string,
    value: any,
    formData: { [key: string]: any }
): { name: string; value: any }[] {
    switch (fieldName) {
        case 'secondaryActionTitle':
            return [
                { name: 'secondaryAction', value: { action: formData.secondaryActionAction, title: value } }
            ];
        case 'secondaryActionAction':
            return [
                {
                    name: 'secondaryAction',
                    value: {
                        action: value,
                        title: formData.secondaryActionTitle
                    }
                }
            ];
        default:
            return [{ value, name: fieldName }];
    }
}

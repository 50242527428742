import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    Input,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    styled
} from '@mui/material';
import { useInputState } from 'lib/hooks/useInputState';
import { Option } from 'lib/types';
import React from 'react';
import { Row } from 'ui/Flex';
import { CheckboxPickerItem } from './CheckboxPickerItem';
import Search from '@mui/icons-material/Search';
import { isString } from 'lib/typeguards';

interface CheckboxPlainPickerProps {
    options: Option[];
    defaultItems?: string[];
    value?: string[];
    onChange: (value: string[]) => void;
    loading?: boolean;
    searchPlaceholder?: string;
    disableSelectAll?: boolean;
}

const PREFIX = 'CheckboxPlainPicker';

const classes = {
    search: `${PREFIX}-search`,
    searchInput: `${PREFIX}-searchInput`,
    searchInnerInput: `${PREFIX}-searchInnerInput`,
    list: `${PREFIX}-list`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`& .${classes.search}`]: {
        margin: theme.spacing(1, 0),
        minWidth: theme.spacing(35)
    },
    [`& .${classes.searchInput}`]: {
        marginRight: theme.spacing(-5)
    },
    [`& .${classes.searchInnerInput}`]: {
        paddingRight: theme.spacing(5)
    },
    [`& .${classes.list}`]: {
        maxHeight: theme.spacing(35),
        overflowY: 'auto'
    }
}));

export const CheckboxPlainPicker: React.FC<CheckboxPlainPickerProps> = ({
    options,
    defaultItems,
    value,
    onChange,
    searchPlaceholder,
    loading,
    disableSelectAll
}) => {
    const [search, onSearchChange] = useInputState('');
    const handleToggle = React.useCallback(
        (id: string) => () => {
            const newSet = new Set(value ?? []);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            onChange([...newSet]);
        },
        [onChange, value]
    );
    const handleSelectAll = React.useCallback(() => {
        if (value?.length === options.length) {
            return onChange(defaultItems);
        }
        return onChange(options.map(item => item.value));
    }, [value?.length, options, onChange, defaultItems]);
    const renderItem = React.useCallback(
        (item: Option) => (
            <CheckboxPickerItem
                key={`option-to-pick-${item.value}`}
                item={item}
                defaultItems={defaultItems}
                handleToggle={handleToggle}
                selected={value}
                disabled={item.disabled}
            />
        ),
        [defaultItems, handleToggle, value]
    );
    const filteredOptions = React.useMemo(
        () =>
            options.filter(
                option =>
                    (isString(option.label) && option.label.toLowerCase().includes(search.toLowerCase())) ||
                    (isString(option.description) &&
                        option.description?.toLowerCase().includes(search.toLowerCase()))
            ),
        [options, search]
    );
    return (
        <StyledDiv>
            <Row valign="center" gutter flex={1} className={classes.search}>
                <Input
                    value={search}
                    fullWidth
                    onChange={onSearchChange}
                    placeholder={searchPlaceholder ?? 'Search'}
                    className={classes.searchInput}
                    inputProps={{ className: classes.searchInnerInput }}
                />
                <Search />
            </Row>
            {!!options.length && !disableSelectAll && (
                <ListItem role={undefined} button onClick={handleSelectAll}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={(options.length === 1 && value.length === 1) || value?.length > 1}
                            indeterminate={value?.length > 1 && value?.length < options.length}
                            tabIndex={-1}
                            color="primary"
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'checkbox-list-label-select-all' }}
                        />
                    </ListItemIcon>
                    <ListItemText id="checkbox-list-label-select-all" primary="Select All" />
                </ListItem>
            )}
            {!!options.length && !disableSelectAll && <Divider />}
            <List className={classes.list} id="checkbox-picker-list">
                {filteredOptions.map(renderItem)}
                {!options.length && !loading && <Typography>No options found</Typography>}
                {!!(options.length && !filteredOptions.length && !loading) && (
                    <Typography>No options found for &quot;{search}&quot;</Typography>
                )}
                {loading && (
                    <Box width="100%" display="flex" justifyContent="center" padding={[1, 0]}>
                        <CircularProgress variant="indeterminate" color="primary" />
                    </Box>
                )}
            </List>
        </StyledDiv>
    );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { MainLayout } from 'layouts/MainLayout';
import { AudienceTable } from 'components/audience/AudienceTable';
import { AudienceCreateModal } from 'components/audience/modals/AudienceCreateModal';
import { Audience } from 'components/audience/models/segmentModel';
import { EmptyState } from 'components/utils/emptyState';
import { MARKETING_AUDIENCES_VIEW } from 'config/routes';
import EmptyImage from 'static/audience-empty-state.svg';
import { getAudiences, loadAudiences } from 'store/audiences/audiencesActions';
import { ApplicationState } from 'store/store';
import { changeAudiencesTableSelectedColumns } from 'store/ui/uiActions';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTableScrollItems } from 'ui/table/MuiTable';
import { AudienceAddTriggersModal } from 'components/audience/modals/AudienceAddTriggersModal';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_ERROR,
    MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_SUCCESS
} from 'config/messages';

interface IAddTriggersData {
    open: boolean;
    audienceData: Partial<Audience>;
}

const INITIAL_ADD_TRIGGERS_DATA: IAddTriggersData = {
    open: false,
    audienceData: {
        title: ''
    }
};

const AudiencesPage = () => {
    const { audiences, isLoading, summary } = useSelector((state: ApplicationState) => state.audiences);
    const selectedColumns = useSelector((state: ApplicationState) => state.ui.audiencesTableSelectedColumns);
    const dispatch = useDispatch();
    const [addAudienceOpen, setAddAudienceOpen] = React.useState(false);
    const [addTriggersData, setAddTriggersData] = React.useState<IAddTriggersData>({
        ...INITIAL_ADD_TRIGGERS_DATA
    });
    const history = useHistory();
    const nextKey = summary && summary.nextKey;
    React.useEffect(() => {
        getAudiences()(dispatch);
    }, [dispatch]);

    const openModal = React.useCallback(() => {
        setAddAudienceOpen(true);
    }, []);
    const closeModal = React.useCallback(() => {
        setAddAudienceOpen(false);
    }, []);
    const handleAddAudienceCreateSuccess = React.useCallback(
        (audienceData: Audience) => {
            closeModal();
            setAddTriggersData({
                open: true,
                audienceData: { ...audienceData }
            });
        },
        [closeModal]
    );
    const handleAddTriggersClose = React.useCallback(() => {
        setAddTriggersData({ ...INITIAL_ADD_TRIGGERS_DATA });
    }, []);
    const handleAddTriggersSuccess = React.useCallback(() => {
        setAddTriggersData({ ...INITIAL_ADD_TRIGGERS_DATA });
        dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_SUCCESS, { variant: 'success' }));
    }, [dispatch]);
    const handleAddTriggersError = React.useCallback(() => {
        dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_ERROR, { variant: 'error' }));
    }, [dispatch]);
    const handleScroll = React.useCallback(() => {
        loadAudiences(nextKey)(dispatch);
    }, [dispatch, nextKey]);

    const scroll: MuiTableScrollItems = {
        isMoreItems: !!nextKey,
        key: nextKey,
        onScroll: handleScroll
    };

    const itemActions = React.useCallback(
        (segment: Audience): MuiMenuItem[] => [
            {
                label: 'View',
                onClick: () =>
                    history.push(`${MARKETING_AUDIENCES_VIEW.replace(/:audienceId/g, segment._id)}`)
            }
        ],
        [history]
    );

    const changeSelectedColumnsFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        changeAudiencesTableSelectedColumns(name, checked, selectedColumns)(dispatch);
    };

    const toolbarItems = {
        renderCustomElement: () => <TableActionButton onClick={openModal}>Create Audience</TableActionButton>,
        settings: {
            items: [
                {
                    label: 'Identifier',
                    onChange: changeSelectedColumnsFunc,
                    isChecked: selectedColumns.includes('Identifier')
                }
            ]
        }
    };

    const emptyStateDescription =
        'Audiences unlock new ways to target marketing content at your customer base by grouping them based on their habits within the app.';
    return (
        <React.Fragment>
            <MainLayout pageName="Audiences" pageDescription="Group users together based on their activity.">
                {isLoading || (Array.isArray(audiences) && audiences.length > 0) ? (
                    <AudienceTable
                        scroll={scroll}
                        data={audiences}
                        itemActions={itemActions}
                        selectedColumns={selectedColumns}
                        isLoading={!!nextKey || isLoading}
                        toolbarItems={toolbarItems}
                    />
                ) : (
                    <EmptyState
                        headerText="Group your customers into audiences"
                        paragraphText={emptyStateDescription}
                        buttonText="Create an audience"
                        imageUrl={EmptyImage}
                        onClick={openModal}
                    />
                )}
            </MainLayout>
            <AudienceCreateModal
                open={addAudienceOpen}
                onClose={closeModal}
                onSuccess={handleAddAudienceCreateSuccess}
            />
            <AudienceAddTriggersModal
                {...addTriggersData}
                onClose={handleAddTriggersClose}
                onSuccess={handleAddTriggersSuccess}
                onError={handleAddTriggersError}
            />
        </React.Fragment>
    );
};

export default AudiencesPage;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { History } from 'history';
import { MainLayout } from 'layouts/MainLayout';
import { customerApi } from 'components/customers/customerApi';
import { CustomersTable } from 'components/customers/CustomersTable';
import {
    AllowedCustomerRoleFilter,
    Customer,
    CustomerRole,
    isAllowedCustomerRoleFilter,
    isCustomer,
    isCustomerData
} from 'components/customers/models/Customer';
import customersReducer from 'components/customers/reducers/CustomersReducer';
import { EmptyState } from 'components/utils/emptyState';
import { CUSTOMERS_VIEW, LOCATIONS } from 'config/routes';
import { titleize } from 'lib/helpers';
import EmptyStateImage from 'static/customers-empty-state.svg';
import { ApplicationState } from 'store/store';
import { changeCustomersTableSelectedColumns } from 'store/ui/uiActions';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { isArray } from 'lib/typeguards';
import logger from 'lib/logger';

const { reducer, initialState, ActionType: CustomersReducerActionType } = customersReducer;

interface CustomersPageProps {
    customers: Customer[];
    nextKey: string;
}

const ITEMS_PER_PAGE = 100;
const DEFAULT_QUERY = {
    limit: ITEMS_PER_PAGE,
    include: 'credentials'
};

const customerRoleFilters: AllowedCustomerRoleFilter[] = [CustomerRole.USER, CustomerRole.GUEST, 'ALL'];

const viewCustomer = (id: string, history: History) => {
    history.push(`${CUSTOMERS_VIEW.replace(/:customerId/g, id)}`);
};

enum ESearchCustomerBy {
    NAME = 'NAME',
    ID = 'ID',
    CREDENTIAL_ID = 'CREDENTIAL_ID'
}

const getCustomersData = async (
    options?: {
        [key: string]: string | number;
    },
    searchBy: string = ESearchCustomerBy.NAME
): Promise<CustomersPageProps> => {
    try {
        const queryParameters = Object.entries(options).map(([key, value]: [string, string]) => ({
            key,
            value
        }));
        const response =
            searchBy !== ESearchCustomerBy.ID
                ? await customerApi.getList({
                      queryParameters
                  })
                : await customerApi.getUser(options.id.toString(), queryParameters);
        if (response.ok) {
            if (isCustomerData(response.body)) {
                const customers: Customer[] = response.body.items;
                return {
                    customers:
                        customers &&
                        customers.filter(item => !item.deleted && !item.roles.includes(CustomerRole.ADMIN)),
                    nextKey: response.body.page.nextKey
                };
            }
            if (isCustomer(response.body)) {
                return {
                    customers: [response.body],
                    nextKey: null
                };
            }
            if (isArray(response.body.items) && response.body.items?.length === 0) {
                return {
                    customers: [],
                    nextKey: null
                };
            }
            throw new Error(`Expected CustomersData but got ${response.body}`);
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const CustomersPage: React.FC<CustomersPageProps> = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { customers, nextKey, isLoading, role } = state;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get('search');
    const searchType = params.get('t');
    const reduxDispatch = useDispatch();
    const selectedColumns = useSelector((store: ApplicationState) => store.ui.customersTableSelectedColumns);
    const [searchBy, setSearchBy] = React.useState<string>(searchType || ESearchCustomerBy.NAME);
    const history = useHistory();

    const renderRoleItem = React.useCallback(
        role => <FormControlLabel key={role} value={role} label={titleize(role)} control={<Radio />} />,
        []
    );

    const handleSearchClick = React.useCallback(
        (newSearch: string) => {
            if (newSearch !== search) {
                history.push(
                    newSearch.length
                        ? `${location.pathname}?search=${encodeURIComponent(
                              newSearch
                          )}&t=${encodeURIComponent(searchBy)}`
                        : location.pathname
                );
            }
        },
        [history, location.pathname, search, searchBy]
    );

    React.useEffect(() => {
        const getCustomers = async () => {
            dispatch({ type: CustomersReducerActionType.START_REQUEST });
            const requestOptions: Record<string, string | number> = {
                ...(searchType === ESearchCustomerBy.NAME && { name: search }),
                ...(searchType === ESearchCustomerBy.CREDENTIAL_ID && { credentialId: search.toLowerCase() }),
                ...(searchType === ESearchCustomerBy.ID && { id: search }),
                ...DEFAULT_QUERY
            };

            if (role !== 'ALL') {
                requestOptions.role = role;
            }

            const { customers, nextKey: newNextKey } = await getCustomersData(requestOptions, searchType);

            dispatch({
                nextKey: newNextKey,
                customers,
                type: CustomersReducerActionType.LOAD_SUCCESS
            });
        };
        getCustomers();
    }, [role, searchType, search]);
    const changeSelectedColumnsFunc = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, checked } = e.target;
            changeCustomersTableSelectedColumns(name, checked, selectedColumns)(reduxDispatch);
        },
        [reduxDispatch, selectedColumns]
    );
    const handleSearchByChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchBy(e.target.value);
    }, []);

    const changeCustomerRoleFilter = React.useCallback(
        async ({ target: { value: newRole } }: React.ChangeEvent<HTMLInputElement>) => {
            if (isAllowedCustomerRoleFilter(newRole) && newRole !== role) {
                const requestOptions: Record<string, string | number> = { ...DEFAULT_QUERY, name: search };
                if (!search) {
                    delete requestOptions.name;
                }

                if (newRole !== 'ALL') {
                    requestOptions.role = newRole;
                }

                dispatch({ type: CustomersReducerActionType.START_REQUEST });

                const { customers: newCustomers, nextKey: newNextKey } = await getCustomersData(
                    requestOptions
                );

                dispatch({
                    nextKey: newNextKey,
                    customers: newCustomers,
                    role: newRole,
                    type: CustomersReducerActionType.LOAD_SUCCESS
                });
            }
        },
        [role, search]
    );

    const renderRolesPicker = React.useCallback(
        () => (
            <>
                <Box mx={2}>
                    <Typography variant="h6">Role:</Typography>
                </Box>
                <RadioGroup row aria-label="role-filter" value={role} onChange={changeCustomerRoleFilter}>
                    {customerRoleFilters.map(renderRoleItem)}
                </RadioGroup>
            </>
        ),
        [changeCustomerRoleFilter, renderRoleItem, role]
    );

    const toolbarItems: MuiTableToolbarItems = React.useMemo(
        () => ({
            settings: {
                items: [
                    {
                        label: 'Identifier',
                        onChange: changeSelectedColumnsFunc,
                        isChecked: selectedColumns.includes('Identifier')
                    }
                ]
            },
            search: {
                searchPending: isLoading,
                onSearch: handleSearchClick,
                placeholder: 'Search',
                defaultValue: search
            },
            renderCustomElement: renderRolesPicker,
            TableSearchOptionsProps: {
                items: [
                    {
                        label: 'Name',
                        value: ESearchCustomerBy.NAME
                    },
                    {
                        label: 'Contact',
                        value: ESearchCustomerBy.CREDENTIAL_ID
                    },
                    {
                        label: 'Id',
                        value: ESearchCustomerBy.ID
                    }
                ],
                onChange: handleSearchByChange,
                value: searchBy
            }
        }),
        [
            changeSelectedColumnsFunc,
            selectedColumns,
            isLoading,
            handleSearchClick,
            search,
            renderRolesPicker,
            handleSearchByChange,
            searchBy
        ]
    );
    const handleScroll = React.useCallback(async () => {
        const requestBody: Record<string, string | number> = {
            startKey: nextKey,
            ...DEFAULT_QUERY
        };
        if (!!search) {
            if (searchBy === ESearchCustomerBy.NAME) {
                requestBody.name = search;
            } else if (searchBy === ESearchCustomerBy.CREDENTIAL_ID) {
                requestBody.credentialId = search;
            }
        }

        if (role !== 'ALL') {
            requestBody.role = role;
        }

        const { customers: newCustomers, nextKey: newNextKey } = await getCustomersData(
            requestBody,
            searchBy
        );

        if (newCustomers) {
            dispatch({
                nextKey: newNextKey,
                customers: [...customers, ...newCustomers],
                type: CustomersReducerActionType.LOAD_SUCCESS
            });
        }
    }, [customers, nextKey, search, searchBy, role]);
    const scroll: MuiTableScrollItems = {
        isMoreItems: !!nextKey,
        key: nextKey,
        onScroll: handleScroll
    };
    const itemActions = React.useCallback(
        (customer: Customer): MuiMenuItem[] => [
            { label: 'View', onClick: () => viewCustomer(customer._id, history) }
        ],
        [history]
    );

    const handleEmptyStateClick = React.useCallback(() => {
        history.push(LOCATIONS);
    }, [history]);
    return (
        <MainLayout pageName="Customers" pageDescription="View and interact with your customers.">
            {isLoading || !!search || nextKey || (customers && customers.length > 0) ? (
                <CustomersTable
                    scroll={scroll}
                    selectedColumns={selectedColumns}
                    itemActions={itemActions}
                    toolbarItems={toolbarItems}
                    data={customers}
                    isLoading={isLoading || !!nextKey}
                />
            ) : (
                <EmptyState
                    headerText="Either your app isn't live or you haven't got any customers using it just yet"
                    paragraphText="You can start your app's journey by building the content that your customers will see."
                    buttonText="Get started"
                    imageUrl={EmptyStateImage}
                    onClick={handleEmptyStateClick}
                />
            )}
        </MainLayout>
    );
};

export default CustomersPage;

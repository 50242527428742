import { Dispatch } from 'redux';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { ManifestActionType } from './manifestActionTypes';

interface IBaseAction {
    type: ManifestActionType;
}

interface ProgressAction extends IBaseAction {
    type: ManifestActionType.END_REQUEST | ManifestActionType.START_REQUEST;
}

interface SetCurrencyAction extends IBaseAction {
    type: ManifestActionType.SET_CURRENCY_SYMBOL;
    currencySymbol: string;
}

export type IManifestAction = ProgressAction | SetCurrencyAction;

export const setCurrencySymbolAction = (currencySymbol: string) => ({
    currencySymbol,
    type: ManifestActionType.SET_CURRENCY_SYMBOL
});

export const getCurrencySymbol = () => async (dispatch: Dispatch<SetCurrencyAction>) => {
    const settings = await new LocationSettingsApi().getPublicSettings();
    const currencySymbol = settings?.region?.currencySymbol; // we need to show currency
    return dispatch({ currencySymbol, type: ManifestActionType.SET_CURRENCY_SYMBOL });
};

import { Box, List, ListItem, styled, Typography } from '@mui/material';
import { useCurrencyString } from 'lib/hooks/useCurrencyString';
import React from 'react';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import { IOrder, IOrderModifier, OrderAdjustmentType } from '../model/Order';
import { IOrderSummaryItem, IOrderSummaryItemModifier, OrderSummaryItem } from './OrderSummaryItem';
import { isEmptyString, isString } from 'lib/typeguards';

const StyledListItem = styled(ListItem)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 0,
    maxWidth: '100%'
});

interface OrderSummaryProps {
    loading?: boolean;
    order?: IOrder;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({ order, loading }) => {
    const getCurrencyString = useCurrencyString(order?.currencyCode);
    const tips = React.useMemo(() => {
        if (!!order) {
            return +order.adjustments
                ?.reduce<number>(
                    (acc, adjustment) =>
                        adjustment.type === OrderAdjustmentType.TIP && adjustment.value > 0
                            ? acc + adjustment.value
                            : acc,
                    0
                )
                .toFixed(2);
        }
    }, [order]);
    const modifiersToSummaryModifiers = React.useCallback((modifiers: IOrderModifier[]) => {
        const items: IOrderSummaryItemModifier[] = [];
        modifiers.forEach(modifier => {
            modifier.options?.forEach(option => {
                items.push({
                    title: option.name,
                    id: option.id,
                    cost: option.cost
                });
            });
        });
        return items;
    }, []);
    const mappedItems = React.useMemo(() => {
        if (Array.isArray(order?.items)) {
            const result: IOrderSummaryItem[] = [];
            order.items.forEach(item => {
                const hasParentReferenceId =
                    isString(item.parentReferenceId) && !isEmptyString(item.parentReferenceId);
                const hasReferenceId = isString(item.referenceId) && !isEmptyString(item.referenceId);
                if (hasReferenceId && !hasParentReferenceId) {
                    const relatedItems = order.items
                        .filter(orderItem => orderItem.parentReferenceId === item.referenceId)
                        .map(relatedItem => ({
                            id: relatedItem.productId,
                            title: relatedItem.productName,
                            cost: relatedItem.cost,
                            deleted: relatedItem.deleted
                        }));
                    result.push({
                        id: item.id,
                        title: item.productName,
                        quantity: item.quantity,
                        cost: item.cost,
                        modifiers: [...modifiersToSummaryModifiers(item.modifiers ?? []), ...relatedItems],
                        deleted: item.deleted
                    });
                } else if (!hasParentReferenceId) {
                    result.push({
                        id: item.id,
                        title: item.productName,
                        quantity: item.quantity,
                        cost: item.cost,
                        modifiers: modifiersToSummaryModifiers(item.modifiers ?? []),
                        deleted: item.deleted
                    });
                }
            });
            return result;
        }
        return [];
    }, [modifiersToSummaryModifiers, order?.items]);
    const renderOrderItem = React.useCallback(
        (item: IOrderSummaryItem) => (
            <StyledListItem key={`order-item-${item.id}`} sx={{ mt: 0.5 }}>
                <OrderSummaryItem item={item} />
            </StyledListItem>
        ),
        []
    );
    const renderOrderTax = React.useCallback(
        (tax: IOrder['total']['taxes'][0]) => (
            <StyledListItem key={`order-tax-${tax.id}`}>
                <Typography>{tax.name}</Typography>
                <Typography>{getCurrencyString(tax.amount)}</Typography>
            </StyledListItem>
        ),
        [getCurrencyString]
    );
    const refundedTips = React.useMemo(
        () =>
            !!order &&
            +order.adjustments
                .reduce<number>(
                    (acc, adjustment) =>
                        adjustment.type === OrderAdjustmentType.TIP && adjustment.value < 0
                            ? acc + adjustment.value
                            : acc,
                    0
                )
                .toFixed(2),
        [order]
    );
    const filteredTaxes = React.useMemo(
        () => order?.total.taxes.filter(tax => !tax.inclusive && tax.amount),
        [order?.total.taxes]
    );
    if (loading || !order) {
        return <SkeletonComponent variant="text" count={3} />;
    }
    return (
        <List disablePadding>
            {!!order && mappedItems.map(renderOrderItem)}

            <Box mb={2} />
            {filteredTaxes.map(renderOrderTax)}
            {!!order.total.cost && (
                <StyledListItem>
                    <Typography>
                        <b>Subtotal</b>
                    </Typography>
                    <Typography>{getCurrencyString(order.total.cost)}</Typography>
                </StyledListItem>
            )}
            {!!order.total.charges && (
                <StyledListItem>
                    <Typography>
                        <b>Charges</b>
                    </Typography>
                    <Typography>{getCurrencyString(order.total.charges)}</Typography>
                </StyledListItem>
            )}
            {!!order.total.discounts && (
                <StyledListItem>
                    <Typography>
                        <b>Discounts</b>
                    </Typography>
                    <Typography>{getCurrencyString(order.total.discounts)}</Typography>
                </StyledListItem>
            )}
            <StyledListItem>
                <Typography>
                    <b>Total</b>
                </Typography>
                <Typography>{getCurrencyString(order.total.total)}</Typography>
            </StyledListItem>
            {!!tips && (
                <StyledListItem>
                    <Typography>
                        <b>Tips</b>
                    </Typography>
                    <Typography>{getCurrencyString(tips)}</Typography>
                </StyledListItem>
            )}
            {!!order.total.refunds && (
                <StyledListItem>
                    <Typography>
                        <b>Refunds</b>
                    </Typography>
                    <Typography>{getCurrencyString(order.total.refunds)}</Typography>
                </StyledListItem>
            )}
            {!!refundedTips && (
                <StyledListItem>
                    <Typography>
                        <b>Refunded Tips</b>
                    </Typography>
                    <Typography>{getCurrencyString(refundedTips)}</Typography>
                </StyledListItem>
            )}
        </List>
    );
};

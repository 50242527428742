import { PopoverVirtualElement } from '@mui/material';
import { IAction, IActionData } from 'components/actions/models/Action';
import logger from 'lib/logger';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';

enum ReducerActionType {
    START_REQUEST = 'START_REQUEST',
    CUSTOMER_ACTION_OPEN = 'CUSTOMERS_ACTION_OPEN',
    CUSTOMER_ACTION_CLOSE = 'CUSTOMERS_ACTION_CLOSE',
    CUSTOMER_ACTIONS_LOAD_SUCCESS = 'CUSTOMER_ACTIONS_LOAD_SUCCESS',
    CUSTOMER_ACTIONS_INITIAL_LOAD_SUCCESS = 'CUSTOMER_ACTIONS_INITIAL_LOAD_SUCCESS',
    CUSTOMER_ACTIONS_LOAD_ERROR = 'CUSTOMER_ACTIONS_LOAD_ERROR',
    CUSTOMER_ACTIONS_SET_TYPES_FILTER = 'CUSTOMER_ACTIONS_SET_TYPES_FILTER',
    CUSTOMER_ACTIONS_SET_DATE_FILTER = 'CUSTOMER_ACTIONS_SET_DATE_FILTER',
    CUSTOMER_ACTIONS_SET_TYPES_ANCHOR_ELEMENT = 'CUSTOMER_ACTIONS_SET_TYPES_ANCHOR_ELEMENT',
    CUSTOMER_ACTIONS_SET_DATE_ANCHOR_ELEMENT = 'CUSTOMER_ACTIONS_SET_DATE_ANCHOR_ELEMENT',
    UNKNOWN = 'UNKNOWN'
}

interface IState {
    actionsList: IAction[];
    currentAction?: IAction;
    currentPage: number;
    actionsFilter?: string;
    actionsDate?: DateRangePickerValue;
    anchorTypesElement?:
        | null
        | Element
        | (() => Element)
        | PopoverVirtualElement
        | (() => PopoverVirtualElement);
    anchorDateElement?:
        | null
        | Element
        | (() => Element)
        | PopoverVirtualElement
        | (() => PopoverVirtualElement);
    loading: boolean;
    hasMoreItems: boolean;
    error?: string;
}
type IReducerAction =
    | IReducerActionSelectAction
    | IEmptyReducerAction
    | IUnknownAction
    | IReducerActionActionsLoadSuccess
    | IReducerActionActionsLoadError
    | IReducerActionSetTypesFilter
    | IReducerActionSetDateFilter
    | IReducerActionSetAnchorElement;
interface IBaseReducerAction {
    type: ReducerActionType;
}
interface IUnknownAction extends IBaseReducerAction {
    type: ReducerActionType.UNKNOWN;
}
interface IEmptyReducerAction extends IBaseReducerAction {
    type: ReducerActionType.CUSTOMER_ACTION_CLOSE | ReducerActionType.START_REQUEST;
}
interface IReducerActionSelectAction extends IBaseReducerAction {
    type: ReducerActionType.CUSTOMER_ACTION_OPEN;
    action: IAction;
}
interface IReducerActionActionsLoadSuccess extends IBaseReducerAction {
    type:
        | ReducerActionType.CUSTOMER_ACTIONS_LOAD_SUCCESS
        | ReducerActionType.CUSTOMER_ACTIONS_INITIAL_LOAD_SUCCESS;
    data: IActionData;
}
interface IReducerActionActionsLoadError extends IBaseReducerAction {
    type: ReducerActionType.CUSTOMER_ACTIONS_LOAD_ERROR;
    error: string;
}
interface IReducerActionSetTypesFilter extends IBaseReducerAction {
    type: ReducerActionType.CUSTOMER_ACTIONS_SET_TYPES_FILTER;
    filter?: string;
}
interface IReducerActionSetDateFilter extends IBaseReducerAction {
    type: ReducerActionType.CUSTOMER_ACTIONS_SET_DATE_FILTER;
    date?: DateRangePickerValue;
}
interface IReducerActionSetAnchorElement extends IBaseReducerAction {
    type:
        | ReducerActionType.CUSTOMER_ACTIONS_SET_TYPES_ANCHOR_ELEMENT
        | ReducerActionType.CUSTOMER_ACTIONS_SET_DATE_ANCHOR_ELEMENT;
    element: null | Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement);
}

const initialState: IState = {
    actionsList: [],
    currentAction: null,
    currentPage: 0,
    actionsFilter: undefined,
    actionsDate: undefined,
    anchorTypesElement: null,
    loading: false,
    hasMoreItems: true,
    error: undefined
};

function getInitialActionsSuccess(state: IState, data: IActionData): IState {
    const { summary, actions } = data;
    const { page, pages } = summary;
    const hasMoreItems = page < pages;
    return {
        ...state,
        hasMoreItems,
        currentPage: page,
        actionsList: actions,
        loading: false,
        error: undefined
    };
}
function getActionsSuccess(state: IState, data: IActionData): IState {
    const { summary, actions } = data;
    const { page, pages } = summary;
    const hasMoreItems = page < pages;
    return {
        ...state,
        hasMoreItems,
        currentPage: page,
        actionsList: [...state.actionsList, ...actions],
        loading: false,
        error: undefined
    };
}
function reducer(state: IState, action: IReducerAction): IState {
    switch (action.type) {
        case ReducerActionType.START_REQUEST:
            return { ...state, loading: true, error: undefined };
        case ReducerActionType.CUSTOMER_ACTION_OPEN:
            return { ...state, currentAction: action.action };
        case ReducerActionType.CUSTOMER_ACTION_CLOSE:
            return { ...state, currentAction: null };
        case ReducerActionType.CUSTOMER_ACTIONS_INITIAL_LOAD_SUCCESS:
            return getInitialActionsSuccess(state, action.data);
        case ReducerActionType.CUSTOMER_ACTIONS_LOAD_SUCCESS:
            return getActionsSuccess(state, action.data);
        case ReducerActionType.CUSTOMER_ACTIONS_LOAD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                hasMoreItems: false,
                actionsList: [],
                currentPage: 1
            };
        case ReducerActionType.CUSTOMER_ACTIONS_SET_TYPES_FILTER:
            return {
                ...state,
                actionsFilter: action.filter,
                actionsList: [],
                currentPage: 0,
                hasMoreItems: true,
                error: undefined,
                anchorTypesElement: null
            };
        case ReducerActionType.CUSTOMER_ACTIONS_SET_DATE_FILTER:
            return {
                ...state,
                actionsDate: action.date,
                actionsList: [],
                currentPage: 0,
                hasMoreItems: true,
                error: undefined,
                anchorDateElement: null
            };
        case ReducerActionType.CUSTOMER_ACTIONS_SET_TYPES_ANCHOR_ELEMENT:
            return { ...state, anchorTypesElement: action.element };
        case ReducerActionType.CUSTOMER_ACTIONS_SET_DATE_ANCHOR_ELEMENT:
            return { ...state, anchorDateElement: action.element };
        default:
            logger.warn(
                `Expected reducer action type, but got: ${JSON.stringify(
                    action.type
                )}. CustomersTimelineCard.tsx-reducer`
            );
            return state;
    }
}

export default { reducer, initialState, ActionType: ReducerActionType };

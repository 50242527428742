/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Grid, Typography } from '@mui/material';
import { uploadStripeFile } from 'components/content/uploadFile';
import { DisputeReason } from 'components/pepper-pay/model/PepperPay';
import { TextFormField } from 'lib/form/fields/TextFormField';
import React from 'react';
import { StripeFileInput } from '../StripeFileInput';

export interface ReasonBasedEvidenceFormData {
    refund_policy?: string;
    new_refund_policy?: File;
    refund_policy_disclosure?: string;
    refund_refusal_explanation?: string;
    duplicate_charge_id?: string;
    duplicate_charge_explanation?: string;
    duplicate_charge_documentation?: string;
    new_duplicate_charge_documentation?: File;
    uncategorized_file?: string;
    new_uncategorized_file?: File;
    uncategorized_text?: string;
}

export function useReasonBasedEvidenceFields(reason: DisputeReason, locationId?: string) {
    const uncategorizedFileDescription = React.useMemo(
        () => (
            <Box component="span" display="flex" flexDirection="column">
                <Typography variant="caption">Include one of the following:</Typography>
                <Typography variant="caption">
                    - Any relevant documentation that another member of the cardholders family or household
                    completed the transaction.
                </Typography>
                <Typography variant="caption">
                    - One or more non-disputed payments for the same product
                </Typography>
            </Box>
        ),
        []
    );
    const uncategorizedTextDescription = React.useMemo(
        () => (
            <Box component="span" display="flex" flexDirection="column">
                <Typography variant="caption">Describe the following if applicable:</Typography>
                <Typography variant="caption">
                    - Any relevant documentation that another member of the cardholders family or household
                    completed the transaction.
                </Typography>
                <Typography variant="caption">
                    - One or more non-disputed payments for the same product
                </Typography>
            </Box>
        ),
        []
    );
    const renderOptionalFields = React.useCallback(() => {
        switch (reason) {
            case DisputeReason.CreditNotProcessed:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <StripeFileInput
                                name="refund_policy"
                                uploadName="new_refund_policy"
                                label="Refund Policy"
                                locationId={locationId}
                                description="Include a copy of your refund policy that was provided to the customer. "
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="refund_policy_disclosure"
                                label="Refund Policy Disclosure"
                                multiline
                                description="Include an explanation of how and when the customer was shown your refund policy prior to the purchase."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="refund_refusal_explanation"
                                label="Refund Refusal"
                                multiline
                                description="Include an explanation for why the customer is not entitled to a refund."
                            />
                        </Grid>
                    </React.Fragment>
                );
            case DisputeReason.Duplicate:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <TextFormField
                                name="duplicate_charge_id"
                                label="Duplicate Charge ID"
                                description="Include the charge ID from the transaction that is the duplicate of the transaction being disputed."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="duplicate_charge_explanation"
                                label="Duplicate Charge Explanation"
                                multiline
                                description="Include an explanation of the differences between the duplicate transaction and the transaction being disputed. "
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StripeFileInput
                                name="duplicate_charge_documentation"
                                uploadName="new_duplicate_charge_documentation"
                                label="Duplicate Charge Documentation"
                                locationId={locationId}
                                description="Include documentation for the prior payment that can uniquely identify it, such as a separate receipt. This document should be paired with a similar document from the disputed payment that proves the two are separate. This should also include a separate receipt for the other payment."
                            />
                        </Grid>
                    </React.Fragment>
                );
            case DisputeReason.Fraudulent:
            case DisputeReason.NotReceived:
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <StripeFileInput
                                name="uncategorized_file"
                                uploadName="new_uncategorized_file"
                                label="Uncategorised file"
                                locationId={locationId}
                                description={uncategorizedFileDescription}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="uncategorized_text"
                                label="Uncategorised description"
                                description={uncategorizedTextDescription}
                            />
                        </Grid>
                    </React.Fragment>
                );
            default:
                return null;
        }
    }, [reason, locationId, uncategorizedFileDescription, uncategorizedTextDescription]);
    const getReasonBasedData = React.useCallback(
        async ({
            refund_policy,
            new_refund_policy,
            refund_policy_disclosure,
            refund_refusal_explanation,
            duplicate_charge_id,
            duplicate_charge_explanation,
            duplicate_charge_documentation,
            new_duplicate_charge_documentation,
            uncategorized_file,
            new_uncategorized_file,
            uncategorized_text
        }: ReasonBasedEvidenceFormData) => {
            switch (reason) {
                case DisputeReason.CreditNotProcessed: {
                    let policy: string;
                    if (new_refund_policy) {
                        const result = await uploadStripeFile(
                            new_refund_policy,
                            'dispute_evidence',
                            locationId
                        );
                        if (!result.ok) {
                            throw new Error(result.body.message);
                        }
                        policy = result.body.id;
                    }
                    return {
                        refund_policy: policy ?? refund_policy,
                        refund_policy_disclosure,
                        refund_refusal_explanation
                    };
                }
                case DisputeReason.Duplicate: {
                    let documentation: string;
                    if (new_duplicate_charge_documentation) {
                        const result = await uploadStripeFile(
                            new_duplicate_charge_documentation,
                            'dispute_evidence',
                            locationId
                        );
                        if (!result.ok) {
                            throw new Error(result.body.message);
                        }
                        documentation = result.body.id;
                    }
                    return {
                        duplicate_charge_id,
                        duplicate_charge_explanation,
                        duplicate_charge_documentation: documentation ?? duplicate_charge_documentation
                    };
                }
                case DisputeReason.Fraudulent:
                case DisputeReason.NotReceived: {
                    let file: string;
                    if (new_uncategorized_file) {
                        const result = await uploadStripeFile(
                            new_uncategorized_file,
                            'dispute_evidence',
                            locationId
                        );
                        if (!result.ok) {
                            throw new Error(result.body.message);
                        }
                        file = result.body.id;
                    }
                    return { uncategorized_file: file ?? uncategorized_file, uncategorized_text };
                }
                default:
                    return {};
            }
        },
        [locationId, reason]
    );
    return { renderOptionalFields, getReasonBasedData };
}

import { Box } from '@mui/material';
import React from 'react';
import { PepperPayTimeline, PepperPayTimelineItem } from '../model/PepperPay';
import { PepperPayTimelineItemComponent } from './PepperPayTimelineItem';

interface PepperPayTimelineProps {
    timeline: PepperPayTimeline;
}

export const PepperPayTimelineComponent: React.FC<PepperPayTimelineProps> = ({ timeline }) => {
    const renderComponent = React.useCallback(
        (item: PepperPayTimelineItem) => (
            <PepperPayTimelineItemComponent key={`timeline-item-${item._id ?? item.id}`} item={item} />
        ),
        []
    );
    if (!Array.isArray(timeline)) {
        return null;
    }
    return <Box>{timeline.map(renderComponent)}</Box>;
};

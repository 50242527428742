import React from 'react';
import { styled, Typography } from '@mui/material';
import clsx from 'clsx';

const PREFIX = 'MuiImage';

const classes = {
    root: `${PREFIX}-root`,
    image: `${PREFIX}-image`,
    formImage: `${PREFIX}-formImage`,
    placeholder: `${PREFIX}-placeholder`
};

const StyledDiv = styled('div')<{ aspect?: MuiImageProps['aspect'] }>(({ theme, aspect }) => ({
    position: 'relative',
    [`&.${classes.formImage}`]: {
        paddingTop: `${aspect ? 100 * (1 / aspect) : 100}%`,
        height: 0,
        position: 'relative'
    },
    [`& .${classes.image}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.divider,
        border: theme.palette.divider,
        borderRadius: theme.shape.borderRadius
    },
    [`& .${classes.placeholder}`]: {
        position: 'absolute',
        color: theme.palette.text.secondary,
        top: '50%',
        textAlign: 'center',
        width: '100%',
        transform: 'translateY(-50%)'
    }
}));

interface MuiImageProps {
    src: string;
    aspect?: number;
    variant?: 'default' | 'formImage';
    placeholder?: string;
}

export function MuiImage(props: MuiImageProps) {
    const { src, variant, placeholder, ...rest } = props;
    return (
        <StyledDiv {...rest} className={clsx({ [classes.formImage]: variant === 'formImage' })}>
            <img className={classes.image} src={src} alt="" />
            {placeholder && !src && <Typography className={classes.placeholder}>{placeholder}</Typography>}
        </StyledDiv>
    );
}

import React from 'react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { MuiTableScrollItems } from 'ui/table/MuiTable';
import { SegmentOperation } from './models/segmentOperationModel';
import { SegmentOperationTable, SegmentRetryOperationButtonClickData } from './SegmentOperationTable';

export interface SegmentOperationTableCardComponentProps {
    operations: SegmentOperation[];
    scroll: MuiTableScrollItems;
    isLoading: boolean;
    onRetryButtonClick: (data: SegmentRetryOperationButtonClickData) => void;
}

export const SegmentOperationTableCardComponent: React.FC<SegmentOperationTableCardComponentProps> = ({
    operations,
    scroll,
    isLoading,
    onRetryButtonClick
}) => (
    <Paper>
        <Box padding={2}>
            <Typography>Bulk changes</Typography>
        </Box>
        <Divider />
        <SegmentOperationTable
            scroll={scroll}
            data={operations}
            isLoading={isLoading}
            onRetryButtonClick={onRetryButtonClick}
        />
    </Paper>
);

/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, styled, Typography } from '@mui/material';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { FormikValues } from 'formik';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { KeyFileInputFormField } from 'lib/form/fields/KeyFileInputFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import { parseIntOrUndefined, stringOrNull } from 'lib/helpers';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { ApplicationState } from 'store/store';
import { PowercardSettingsDisplay } from './LoyaltyProvider/Powercard/PowercardSettingsDisplay';
import { PowercardSettingsEdit } from './LoyaltyProvider/Powercard/PowercardSettingsEdit';
import { S3_MEDIA_URL } from './BrandingSettings';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { SettingDisplayText } from './SettingDisplayText';
import { PowercardGiftCardSettingsDisplay } from './GiftCardProvider/Powercard/PowercardGiftCardSettingsDisplay';
import { PowercardGiftCardSettingsEdit } from './GiftCardProvider/Powercard/PowercardGiftCardSettingsEdit';
import { ToggleGiftCardSettingsDisplay } from './GiftCardProvider/Toggle/ToggleGiftCardSettingsDisplay';
import { ToggleGiftCardSettingsEdit } from './GiftCardProvider/Toggle/ToggleGiftCardSettingsEdit';
import { ArteemoSettingsDisplay } from './LoyaltyProvider/Arteemo/ArteemoSettingsDisplay';
import { ArteemoSettingsEdit } from './LoyaltyProvider/Arteemo/ArteemoSettingsEdit';
import { RevelGiftCardSettingsEdit } from 'components/settings/GiftCardProvider/Revel/RevelGiftCardSettingsEdit';
import { RevelGiftCardSettingsDisplay } from 'components/settings/GiftCardProvider/Revel/RevelGiftCardSettingsDisplay';
import { ColorFormField, RGBAValue } from 'lib/form/fields/ColorFormField';
import { isString } from 'lib/typeguards';
import { SettingDisplayColor } from './SettingDisplayColor';
import { Permission, Resource } from '@pepperhq/auth-client';
import { getPublicAndPrivateSettings } from 'store/settings/settingsActions';

const PREFIX = 'MarketingSettings';

const rgbToString = (value: RGBAValue | string) => {
    if (isString(value)) {
        return value;
    }
    return `rgb(${value.r}, ${value.g}, ${value.b})`;
};

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`,
    image: `${PREFIX}-image`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    },
    [`& .${classes.image}`]: {
        width: 'auto',
        height: 'auto',
        maxHeight: '400px',
        maxWidth: '100%'
    }
}));

const loyaltyProviders: Option[] = [
    { label: 'Pepper', value: 'PEPPER' },
    { label: 'Powercard', value: 'POWERCARD' },
    { label: 'Atreemo', value: 'ATREEMO' },
    { label: 'Revel', value: 'REVEL' }
];

const userDeleteActionAvailableProviders = ['POWERCARD', 'ATREEMO', 'REVEL'];

const giftCardProviders: Option[] = [
    { label: 'None', value: 'NONE' },
    { label: 'Powercard', value: 'POWERCARD' },
    { label: 'Square', value: 'SQUARE' },
    { label: 'Toggle', value: 'TOGGLE' },
    { label: 'Revel', value: 'REVEL' }
];

const squareLoyaltyProviders: Option[] = [
    { label: 'Pepper', value: 'PEPPER' },
    { label: 'Square', value: 'SQUARE' }
];

const getUserDeletionSettingLabel = (loyaltyProvider: typeof userDeleteActionAvailableProviders[number]) => {
    if (userDeleteActionAvailableProviders.includes(loyaltyProvider)) {
        const provider = loyaltyProviders.find(provider => provider.value === loyaltyProvider).label;

        return `Delete User In ${provider}`;
    }
    return '';
};

export const MarketingSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId,
    globalSettings
}) => {
    const { tenant, claims } = useSelector((state: ApplicationState) => state.auth);
    const isSquarePos = React.useMemo(() => settings?.posProvider === 'SQUARE', [settings]);
    const dispatch = useDispatch();
    const computedLoyaltyProviders = React.useMemo(
        () => (isSquarePos ? squareLoyaltyProviders : loyaltyProviders),
        [isSquarePos]
    );
    const premiumLoyaltySettingsVisible = React.useMemo(
        () => claims.hasPermission(Resource.InternalSetting, Permission.read),
        [claims]
    );
    const premiumLoyaltySettingsEditable = React.useMemo(
        () => claims.hasPermission(Resource.InternalSetting, Permission.write),
        [claims]
    );

    const handleSubmitNotifications = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        apns: {
                            teamId: values.appleTeamId,
                            keyId: values.appleKeyId,
                            keyAsBase64: values.appleKeyFile
                        },
                        app: {
                            bundleIdentifier: values.appleBundleIdentifier
                        },
                        actionNotifications: {
                            ORDER_CREATED: { email: !!values.orderCreated },
                            ORDER_USER_BILLED: { email: !!values.orderUserBilled },
                            ORDER_COMPLETED: { email: !!values.orderCompleted },
                            USER_ACTIVATED: { email: !!values.userActivated }
                        }
                    };
                }
                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const globalLoyaltyProvider = React.useMemo(() => {
        if (locationId === undefined) {
            return settings?.loyaltyProvider;
        }
        return globalSettings?.loyaltyProvider;
    }, [locationId, globalSettings, settings]);

    const globalGiftCardProvider = React.useMemo(() => {
        if (locationId === undefined) {
            return settings?.giftCardPaymentProvider;
        }
        return globalSettings?.giftCardPaymentProvider;
    }, [locationId, globalSettings, settings]);

    const handleSubmitLoyalty = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        loyaltyEnabled: !!values.loyaltyEnabled,
                        loyaltyProvider: values.loyaltyProvider,
                        giftCardPaymentEnabled: values.giftCardEnabled,
                        giftCardPaymentProvider: values.giftCardProvider,
                        giftCardPayments: {
                            tippingIncluded: values.giftCardTippingIncluded
                        },
                        physicalStampsEnabled: values.soloStampsEnabled,
                        snowShoe: {
                            key: values.snowshoeKey
                        },
                        loyalty: {
                            showBalanceAward: values.loyaltyShowBalanceAward,
                            displayExternalMemberNumber: values.loyaltyDisplayExternalMemberNumber,
                            recalculateServiceChargeOnDiscount:
                                values.loyaltyRecalculateServiceChargeOnDiscount
                        },
                        userDeletion: {
                            ...values?.userDeletion,
                            deleteLoyaltyAccountOnUserDeletion:
                                values.userDeletion.deleteLoyaltyAccountOnUserDeletion
                        }
                    };
                    if (premiumLoyaltySettingsEditable) {
                        settingsToUpdate.premiumLoyaltyEnabled = values.premiumLoyaltyEnabled;
                    }
                }
                switch (values.loyaltyProvider) {
                    case 'POWERCARD': {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                powercardLoyalty: {
                                    isProduction: values.powercardIsProduction,
                                    apiToken: values.powercardApiToken,
                                    m_id: values.powercardMerchantId
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                powercardLoyalty: {
                                    l_id: values.powercardLocationId
                                }
                            };
                        }
                    }
                }
                switch (values.giftCardProvider) {
                    case 'POWERCARD':
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                powercardGift: {
                                    isProduction: values.powercardGiftCardIsProduction,
                                    apiToken: values.powercardGiftCardApiToken,
                                    m_id: values.powercardGiftCardMerchantId,
                                    gift_pid: values.powercardGiftCardGiftPID
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                powercardGift: {
                                    l_id: values.powercardGiftCardLocationId
                                }
                            };
                        }
                        break;
                    case 'TOGGLE': {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                toggle: {
                                    username: values.toggleGiftCardUsername,
                                    password: values.toggleGiftCardPassword
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                toggle: {
                                    eposReference: values.toggleGiftCardEposReference
                                }
                            };
                        }
                        break;
                    }
                    case 'REVEL': {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                revel: {
                                    userId: values.revelGiftCardUserId
                                }
                            };
                        }
                        break;
                    }
                }
                switch (values.loyaltyProvider) {
                    case 'ATREEMO': {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                atreemo: {
                                    host: values.atreemoHost,
                                    username: values.atreemoUsername,
                                    password: values.atreemoPassword,
                                    loyaltyBalanceVoucherCategoryId: values.atreemoVoucherCategoryId,
                                    loyaltyBalanceRedemptionValue: values.atreemoVoucherRedemptionValue
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                atreemo: {
                                    locationId: values.atreemoLocationId
                                }
                            };
                        }
                    }
                }
                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                if (!locationId && premiumLoyaltySettingsVisible) {
                    getPublicAndPrivateSettings(claims)(dispatch);
                }

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [
            claims,
            dispatch,
            locationId,
            onSettingsSaved,
            premiumLoyaltySettingsEditable,
            premiumLoyaltySettingsVisible
        ]
    );

    const handleSubmitReferrals = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        referralEnabled: values.referralEnabled,
                        referral: {
                            stampsPerReferer: parseIntOrUndefined(values.stampsPerReferer),
                            stampsPerReferee: parseIntOrUndefined(values.stampsPerReferee),
                            messageTemplate: values.messageTemplate
                        }
                    };
                }
                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitLoyaltyWallets = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        appleWalletEnabled: values.appleWalletEnabled,
                        appleWallet: {
                            passTypeIdentifier: stringOrNull(values.appleWalletPassTypeIdentifier),
                            description: stringOrNull(values.appleWalletDescription),
                            logoText: stringOrNull(values.appleWalletLogoText),
                            backgroundColor: stringOrNull(values.appleWalletBackgroundColor),
                            foregroundColor: stringOrNull(values.appleWalletForegroundColor),
                            labelColor: stringOrNull(values.appleWalletForegroundColor),
                            p12BundleAsBase64: stringOrNull(values.appleWalletP12BundleAsBase64)
                        },
                        googleWalletEnabled: values.googleWalletEnabled,
                        googleWallet: {
                            issuerId: stringOrNull(values.googleWalletIssuerId),
                            classId: stringOrNull(values.googleWalletClassId),
                            clientEmail: stringOrNull(values.googleWalletClientEmail)
                        }
                    };
                }
                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const renderLoyaltyEdit = React.useCallback(
        (values: FormikValues) => (
            <Box display="flex" flexDirection="column">
                {locationId === undefined && (
                    <>
                        <SettingDisplay label="Loyalty Enabled">
                            <SwitchFormField name="loyaltyEnabled" />
                        </SettingDisplay>
                        {premiumLoyaltySettingsEditable && (
                            <SettingDisplay label="Premium Loyalty Enabled">
                                <SwitchFormField name="premiumLoyaltyEnabled" />
                            </SettingDisplay>
                        )}
                        <SettingDisplay label="Loyalty Provider">
                            <SelectFormField name="loyaltyProvider" options={computedLoyaltyProviders} />
                        </SettingDisplay>
                        {userDeleteActionAvailableProviders.includes(values.loyaltyProvider) && (
                            <SettingDisplay label={getUserDeletionSettingLabel(values.loyaltyProvider)}>
                                <SwitchFormField name="userDeletion.deleteLoyaltyAccountOnUserDeletion" />
                            </SettingDisplay>
                        )}
                        <SettingDisplay
                            label="Show Balance Awards"
                            description="Should awards be added in API /awards for loyalty provider balances"
                        >
                            <SwitchFormField name="loyaltyShowBalanceAward" />
                        </SettingDisplay>
                        <SettingDisplay label="Recalculate Service Charge On Discount">
                            <SwitchFormField name="loyaltyRecalculateServiceChargeOnDiscount" />
                        </SettingDisplay>
                    </>
                )}
                {values.loyaltyProvider === 'POWERCARD' && <PowercardSettingsEdit locationId={locationId} />}
                {values.loyaltyProvider === 'ATREEMO' && <ArteemoSettingsEdit locationId={locationId} />}
                {locationId === undefined && (
                    <>
                        <SettingDisplay label="Gift Card Enabled">
                            <SwitchFormField name="giftCardEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Gift Card Provider">
                            <SelectFormField name="giftCardProvider" options={giftCardProviders} />
                        </SettingDisplay>
                        <SettingDisplay label="Tipping Included">
                            <SwitchFormField name="giftCardTippingIncluded" />
                        </SettingDisplay>
                    </>
                )}
                {values.giftCardProvider === 'POWERCARD' && (
                    <PowercardGiftCardSettingsEdit settings={settings} locationId={locationId} />
                )}
                {values.giftCardProvider === 'TOGGLE' && (
                    <ToggleGiftCardSettingsEdit settings={settings} locationId={locationId} />
                )}
                {values.giftCardProvider === 'REVEL' && (
                    <RevelGiftCardSettingsEdit settings={settings} locationId={locationId} />
                )}
                {locationId === undefined && (
                    <>
                        <SettingDisplay label="Solo Stamps Enabled">
                            <SwitchFormField name="soloStampsEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="SnowShoe API Key">
                            <TextFormField name="snowshoeKey" />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Awards Placeholder"
                            buildtimeAndroid
                            buildtimeIOS
                            description="600px by 400px PNG"
                        >
                            <ImageFormField
                                name="awardsPlaceholder"
                                aspect={600 / 400}
                                fileName="awards_placeholder"
                                uploadFolder="app_media"
                                unremovable
                            />
                        </SettingDisplay>
                        <SettingDisplay
                            label="External Awards Placeholder"
                            buildtimeAndroid
                            buildtimeIOS
                            description="600px by 400px JPG"
                        >
                            <ImageFormField
                                name="externalAwardsPlaceholder"
                                aspect={600 / 400}
                                fileName="external_awards_placeholder"
                                uploadFolder="app_media"
                                unremovable
                            />
                        </SettingDisplay>
                    </>
                )}
            </Box>
        ),
        [locationId, premiumLoyaltySettingsEditable, computedLoyaltyProviders, settings]
    );

    return (
        <Box minWidth="500px">
            {/* Cards for each settings block */}
            {locationId === undefined && (
                <StyledMuiCardForm
                    title="Notifications"
                    initialValues={{
                        appleTeamId: settings?.apns?.teamId,
                        appleBundleIdentifier: settings?.app?.bundleIdentifier,
                        appleKeyId: settings?.apns?.keyId,
                        appleKeyFile: settings?.apns?.keyAsBase64,
                        orderCreated: settings?.actionNotifications?.ORDER_CREATED?.email ?? false,
                        orderUserBilled: settings?.actionNotifications?.ORDER_USER_BILLED?.email ?? false,
                        orderCompleted: settings?.actionNotifications?.ORDER_COMPLETED?.email ?? false,
                        userActivated: settings?.actionNotifications?.USER_ACTIVATED?.email ?? false
                    }}
                    onSubmit={handleSubmitNotifications}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplayText title="Apple Team ID" text={settings?.apns?.teamId} />
                            <SettingDisplayText
                                title="Apple Bundle Identifier"
                                buildtimeIOS
                                text={settings?.app?.bundleIdentifier}
                            />
                            <SettingDisplayText title="Apple Key ID" text={settings?.apns?.keyId} crop />
                            <SettingDisplayText
                                title="Apple Key File"
                                text={settings?.apns?.keyAsBase64}
                                crop
                            />
                            <SettingDisplaySwitch
                                title="Order Created"
                                checked={settings?.actionNotifications?.ORDER_CREATED?.email}
                            />
                            <SettingDisplaySwitch
                                title="Order User Billed"
                                checked={settings?.actionNotifications?.ORDER_USER_BILLED?.email}
                            />
                            <SettingDisplaySwitch
                                title="Order Completed"
                                checked={settings?.actionNotifications?.ORDER_COMPLETED?.email}
                            />
                            <SettingDisplaySwitch
                                title="User Activated"
                                checked={settings?.actionNotifications?.USER_ACTIVATED?.email}
                            />
                        </Box>
                    }
                    childrenWhileEditing={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Apple Team ID">
                                <TextFormField name="appleTeamId" />
                            </SettingDisplay>
                            <SettingDisplay label="Apple Bundle Identifier" buildtimeIOS>
                                <TextFormField name="appleBundleIdentifier" />
                            </SettingDisplay>
                            <SettingDisplay label="Apple Key ID">
                                <TextFormField name="appleKeyId" />
                            </SettingDisplay>
                            <SettingDisplay label="Apple Key File">
                                <KeyFileInputFormField name="appleKeyFile" />
                            </SettingDisplay>
                            <SettingDisplay label="Order Created">
                                <SwitchFormField name="orderCreated" />
                            </SettingDisplay>
                            <SettingDisplay label="Order User Billed">
                                <SwitchFormField name="orderUserBilled" />
                            </SettingDisplay>
                            <SettingDisplay label="Order Completed">
                                <SwitchFormField name="orderCompleted" />
                            </SettingDisplay>
                            <SettingDisplay label="User Activated">
                                <SwitchFormField name="userActivated" />
                            </SettingDisplay>
                        </Box>
                    }
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}
            {(locationId === undefined ||
                globalLoyaltyProvider === 'POWERCARD' ||
                globalGiftCardProvider === 'POWERCARD' ||
                globalLoyaltyProvider === 'ATREEMO' ||
                globalGiftCardProvider === 'TOGGLE' ||
                globalGiftCardProvider === 'REVEL') && (
                <StyledMuiCardForm
                    title="Loyalty"
                    initialValues={{
                        loyaltyEnabled: settings?.loyaltyEnabled ?? false,
                        premiumLoyaltyEnabled: settings?.premiumLoyaltyEnabled ?? false,
                        loyaltyProvider: globalLoyaltyProvider,
                        giftCardEnabled: settings?.giftCardPaymentEnabled ?? false,
                        giftCardProvider: globalGiftCardProvider,
                        soloStampsEnabled: settings?.physicalStampsEnabled ?? false,
                        snowshoeKey: settings?.snowShoe?.key,
                        awardsPlaceholder: `${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/app_media/awards_placeholder.png`,
                        externalAwardsPlaceholder: `${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/app_media/external_awards_placeholder.jpg`,
                        powercardIsProduction: settings?.powercardLoyalty?.isProduction ?? false,
                        powercardApiToken: settings?.powercardLoyalty?.apiToken,
                        powercardMerchantId: settings?.powercardLoyalty?.m_id,
                        powercardLocationId: settings?.powercardLoyalty?.l_id,
                        giftCardTippingIncluded: settings?.giftCardPayments?.tippingIncluded ?? false,
                        powercardGiftCardIsProduction: settings?.powercardGift?.isProduction ?? false,
                        powercardGiftCardApiToken: settings?.powercardGift?.apiToken,
                        powercardGiftCardMerchantId: settings?.powercardGift?.m_id,
                        powercardGiftCardGiftPID: settings?.powercardGift?.gift_pid,
                        powercardGiftCardLocationId: settings?.powercardGift?.l_id,
                        toggleGiftCardUsername: settings?.toggle?.username,
                        toggleGiftCardPassword: settings?.toggle?.password,
                        toggleGiftCardEposReference: settings?.toggle?.eposReference,
                        revelGiftCardUserId: settings?.revel?.userId,
                        loyaltyShowBalanceAward: !!settings?.loyalty?.showBalanceAward,
                        loyaltyDisplayExternalMemberNumber: !!settings?.loyalty?.displayExternalMemberNumber,
                        loyaltyRecalculateServiceChargeOnDiscount:
                            !!settings?.loyalty?.recalculateServiceChargeOnDiscount,
                        atreemoHost: settings?.atreemo?.host,
                        atreemoUsername: settings?.atreemo?.username,
                        atreemoPassword: settings?.atreemo?.password,
                        atreemoLocationId: settings?.atreemo?.locationId,
                        atreemoVoucherCategoryId: settings?.atreemo?.loyaltyBalanceVoucherCategoryId,
                        atreemoVoucherRedemptionValue: settings?.atreemo?.loyaltyBalanceRedemptionValue,
                        userDeletion: {
                            ...settings?.userDeletion,
                            deleteLoyaltyAccountOnUserDeletion:
                                settings?.userDeletion?.deleteLoyaltyAccountOnUserDeletion === undefined
                                    ? true
                                    : settings?.userDeletion?.deleteLoyaltyAccountOnUserDeletion
                        }
                    }}
                    onSubmit={handleSubmitLoyalty}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            {locationId === undefined && (
                                <>
                                    <SettingDisplay label="Loyalty Enabled">
                                        {settings?.loyaltyEnabled && <Done />}
                                        {!settings?.loyaltyEnabled && <Close />}
                                    </SettingDisplay>
                                    {premiumLoyaltySettingsVisible && (
                                        <SettingDisplaySwitch
                                            title="Premium Loyalty Enabled"
                                            checked={settings?.premiumLoyaltyEnabled ?? false}
                                        />
                                    )}
                                    <SettingDisplay label="Loyalty Provider">
                                        <Chip
                                            label={
                                                computedLoyaltyProviders.find(
                                                    option => option.value === settings?.loyaltyProvider
                                                )?.label ?? 'None'
                                            }
                                            color="primary"
                                        />
                                    </SettingDisplay>
                                    {userDeleteActionAvailableProviders.includes(globalLoyaltyProvider) && (
                                        <SettingDisplaySwitch
                                            title={getUserDeletionSettingLabel(globalLoyaltyProvider)}
                                            checked={
                                                settings?.userDeletion?.deleteLoyaltyAccountOnUserDeletion ??
                                                true
                                            }
                                        />
                                    )}
                                    <SettingDisplaySwitch
                                        title="Show Balance Awards"
                                        description="Should awards be added in API /awards for loyalty provider balances"
                                        checked={settings?.loyalty?.showBalanceAward ?? false}
                                    />
                                    <SettingDisplaySwitch
                                        title="Recalculate Service Charge On Discount"
                                        checked={
                                            settings?.loyalty?.recalculateServiceChargeOnDiscount ?? false
                                        }
                                    />
                                    <SettingDisplaySwitch
                                        title="Tipping Included"
                                        checked={settings.giftCardPayments?.tippingIncluded ?? false}
                                    />
                                </>
                            )}
                            {globalLoyaltyProvider === 'POWERCARD' && (
                                <PowercardSettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {globalLoyaltyProvider === 'ATREEMO' && (
                                <ArteemoSettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {locationId === undefined && (
                                <>
                                    <SettingDisplaySwitch
                                        title="Gift Card Enabled"
                                        checked={settings?.giftCardPaymentEnabled}
                                    />
                                    <SettingDisplay label="Gift Card Provider">
                                        <Chip
                                            label={
                                                giftCardProviders.find(
                                                    option =>
                                                        option.value === settings?.giftCardPaymentProvider
                                                )?.label ?? 'None'
                                            }
                                            color="primary"
                                        />
                                    </SettingDisplay>
                                </>
                            )}
                            {globalGiftCardProvider === 'POWERCARD' && (
                                <PowercardGiftCardSettingsDisplay
                                    settings={settings}
                                    locationId={locationId}
                                />
                            )}
                            {globalGiftCardProvider === 'TOGGLE' && (
                                <ToggleGiftCardSettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {globalGiftCardProvider === 'REVEL' && (
                                <RevelGiftCardSettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {locationId === undefined && (
                                <>
                                    <SettingDisplay label="Solo Stamps Enabled">
                                        {settings?.physicalStampsEnabled && <Done />}
                                        {!settings?.physicalStampsEnabled && <Close />}
                                    </SettingDisplay>
                                    <SettingDisplay label="SnowShoe API Key">
                                        <Typography variant="body2">{settings?.snowShoe?.key}</Typography>
                                    </SettingDisplay>
                                    <SettingDisplay
                                        label="Awards Placeholder"
                                        buildtimeAndroid
                                        buildtimeIOS
                                        description="600px by 400px PNG"
                                    >
                                        <img
                                            src={`${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/app_media/awards_placeholder.png`}
                                            className={classes.image}
                                        />
                                    </SettingDisplay>
                                    <SettingDisplay
                                        label="External Awards Placeholder"
                                        buildtimeAndroid
                                        buildtimeIOS
                                        description="600px by 400px JPG"
                                    >
                                        <img
                                            src={`${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/app_media/external_awards_placeholder.png`}
                                            className={classes.image}
                                        />
                                    </SettingDisplay>
                                </>
                            )}
                        </Box>
                    }
                    childrenWhileEditing={renderLoyaltyEdit}
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}

            {locationId === undefined && (
                <StyledMuiCardForm
                    title="Loyalty Card in Wallet"
                    initialValues={{
                        appleWalletEnabled: settings?.appleWalletEnabled ?? false,
                        appleWalletDescription: settings?.appleWallet?.description,
                        appleWalletLogoText: settings?.appleWallet?.logoText,
                        appleWalletBackgroundColor: settings?.appleWallet?.backgroundColor,
                        appleWalletForegroundColor: settings?.appleWallet?.foregroundColor,
                        appleWalletImage: `${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/apple_wallet/strip.png`,
                        appleWalletP12BundleAsBase64: settings?.appleWallet?.p12BundleAsBase64,
                        appleWalletPassTypeIdentifier: settings?.appleWallet?.passTypeIdentifier,
                        googleWalletEnabled: settings?.googleWalletEnabled ?? false,
                        googleWalletIssuerId: settings?.googleWallet?.issuerId,
                        googleWalletClassId: settings?.googleWallet?.classId,
                        googleWalletClientEmail: settings?.googleWallet?.clientEmail
                    }}
                    onSubmit={handleSubmitLoyaltyWallets}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplaySwitch
                                title="Apple Wallet Enabled"
                                checked={settings?.appleWalletEnabled}
                            />
                            <SettingDisplayText
                                title="Wallet Page Header"
                                text={settings?.appleWallet?.description}
                            />
                            <SettingDisplayText
                                title="Wallet Card Title"
                                text={settings?.appleWallet?.logoText}
                            />
                            <SettingDisplayColor
                                title="Background Colour"
                                color={settings?.appleWallet?.backgroundColor}
                            />
                            <SettingDisplayColor
                                title="Text Colour"
                                color={settings?.appleWallet?.foregroundColor}
                            />
                            <SettingDisplay
                                label="Image"
                                description="Recommend image dimension 350px by 160px"
                            >
                                <img
                                    src={`${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/apple_wallet/strip.png`}
                                    className={classes.image}
                                />
                            </SettingDisplay>
                            <SettingDisplayText
                                title="P12 Bundle File"
                                text={settings?.appleWallet?.p12BundleAsBase64}
                                crop
                            />
                            <SettingDisplayText
                                title="Pass Type Identifier"
                                text={settings?.appleWallet?.passTypeIdentifier}
                            />
                            <SettingDisplaySwitch
                                title="Google Wallet Enabled"
                                checked={settings?.googleWalletEnabled}
                            />
                            <SettingDisplayText title="Issuer ID" text={settings?.googleWallet?.issuerId} />
                            <SettingDisplayText title="Class ID" text={settings?.googleWallet?.classId} />
                            <SettingDisplayText
                                title="Client Email"
                                text={settings?.googleWallet?.clientEmail}
                            />
                        </Box>
                    }
                    childrenWhileEditing={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Apple Wallet Enabled">
                                <SwitchFormField name="appleWalletEnabled" />
                            </SettingDisplay>
                            <SettingDisplay label="Wallet Page Header">
                                <TextFormField
                                    name="appleWalletDescription"
                                    description="The title of the screen when adding the pass to Apple wallet"
                                />
                            </SettingDisplay>
                            <SettingDisplay label="Wallet Card Title">
                                <TextFormField
                                    name="appleWalletLogoText"
                                    description="The title displayed next to icon on wallet pass"
                                />
                            </SettingDisplay>
                            <SettingDisplay label="Background Colour">
                                <ColorFormField
                                    name="appleWalletBackgroundColor"
                                    valueFormatter={rgbToString}
                                />
                            </SettingDisplay>
                            <SettingDisplay label="Text Colour">
                                <ColorFormField
                                    name="appleWalletForegroundColor"
                                    valueFormatter={rgbToString}
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Image"
                                description="Recommend image dimension 350px by 160px"
                            >
                                <ImageFormField
                                    name="appleWalletImage"
                                    aspect={350 / 160}
                                    fileName="strip"
                                    uploadFolder="apple_wallet"
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay label="P12 Bundle File">
                                <KeyFileInputFormField
                                    isText={false}
                                    name="appleWalletP12BundleAsBase64"
                                    attributes={{ type: 'file', accept: '.p12' }}
                                />
                            </SettingDisplay>
                            <SettingDisplay label="Pass Type Identifier">
                                <TextFormField name="appleWalletPassTypeIdentifier" />
                            </SettingDisplay>
                            <SettingDisplay label="Google Wallet Enabled">
                                <SwitchFormField name="googleWalletEnabled" />
                            </SettingDisplay>
                            <SettingDisplay label="Issuer ID">
                                <TextFormField name="googleWalletIssuerId" />
                            </SettingDisplay>
                            <SettingDisplay label="Class ID">
                                <TextFormField name="googleWalletClassId" />
                            </SettingDisplay>
                            <SettingDisplay label="Client Email">
                                <TextFormField name="googleWalletClientEmail" />
                            </SettingDisplay>
                        </Box>
                    }
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}

            {locationId === undefined && (
                <StyledMuiCardForm
                    title="Referrals"
                    initialValues={{
                        referralEnabled: settings?.referralEnabled ?? false,
                        stampsPerReferer: settings?.referral?.stampsPerReferer,
                        stampsPerReferee: settings?.referral?.stampsPerReferee,
                        messageTemplate: settings?.referral?.messageTemplate,
                        referralBanner: `${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/app_media/referral_banner.png`
                    }}
                    onSubmit={handleSubmitReferrals}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Referrals Enabled">
                                {settings?.referralEnabled && <Done />}
                                {!settings?.referralEnabled && <Close />}
                            </SettingDisplay>
                            <SettingDisplay label="Stamps Per Referer">
                                <Typography variant="body2">
                                    {settings?.referral?.stampsPerReferer}
                                </Typography>
                            </SettingDisplay>
                            <SettingDisplay label="Stamps Per Referee">
                                <Typography variant="body2">
                                    {settings?.referral?.stampsPerReferee}
                                </Typography>
                            </SettingDisplay>
                            <SettingDisplay label="Referrals Copy">
                                <Typography variant="body2">{settings?.referral?.messageTemplate}</Typography>
                            </SettingDisplay>
                            <SettingDisplay
                                label="Referrals Banner"
                                description="640px by 445px PNG"
                                buildtimeAndroid
                                buildtimeIOS
                            >
                                <img
                                    src={`${S3_MEDIA_URL}/tenants/${tenant?.tenantId}/app_media/referral_banner.png`}
                                    className={classes.image}
                                />
                            </SettingDisplay>
                        </Box>
                    }
                    childrenWhileEditing={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Referrals Enabled">
                                <SwitchFormField name="referralEnabled" />
                            </SettingDisplay>
                            <SettingDisplay label="Stamps Per Referer">
                                <NumberFormField name="stampsPerReferee" />
                            </SettingDisplay>
                            <SettingDisplay label="Stamps Per Referee">
                                <NumberFormField name="stampsPerReferer" />
                            </SettingDisplay>
                            <SettingDisplay label="Referrals Copy">
                                <TextFormField name="messageTemplate" />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Referrals Banner"
                                description="640px by 445px PNG"
                                buildtimeAndroid
                                buildtimeIOS
                            >
                                <ImageFormField
                                    name="referralBanner"
                                    aspect={640 / 445}
                                    fileName="referral_banner"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                        </Box>
                    }
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}
        </Box>
    );
};

import React from 'react';
import { IOrder, IOrderPayment } from '../model/Order';
import { Divider, Grid, Typography, styled } from '@mui/material';
import { isDefined } from 'lib/typeguards';
import { getCurrencyString } from 'lib/helpers';

interface OrderRefundSummaryProps {
    order: IOrder;
    payment: IOrderPayment;
}

const Caption = styled(Typography)({
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    opacity: 0.75
});

const BodyTypography = styled(Typography)({
    opacity: 0.75
});

export const OrderRefundSummary: React.FC<OrderRefundSummaryProps> = ({ order, payment }) => {
    const refundedAmount = React.useMemo(() => {
        const refundPayments = order.payments.filter(orderPayment => orderPayment.parentId === payment.id);
        if (refundPayments.length) {
            return refundPayments.reduce((acc, item) => acc + item.amount, 0);
        }
        return undefined;
    }, [order.payments, payment.id]);
    if (isDefined(refundedAmount)) {
        return (
            <Grid container>
                <Grid item xs={8}>
                    <Caption align="right">Payment amount:</Caption>
                </Grid>
                <Grid item xs={4}>
                    <Caption align="right">{getCurrencyString(payment.amount, order.currencyCode)}</Caption>
                </Grid>
                <Grid item xs={8}>
                    <Caption align="right">Refunded:</Caption>
                </Grid>
                <Grid item xs={4}>
                    <Caption align="right">{getCurrencyString(refundedAmount, order.currencyCode)}</Caption>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <Divider />
                </Grid>
                <Grid item xs={8}>
                    <BodyTypography variant="body2" align="right">
                        Refundable amount:
                    </BodyTypography>
                </Grid>
                <Grid item xs={4}>
                    <BodyTypography variant="body2" align="right">
                        {getCurrencyString(payment.amount + refundedAmount, order.currencyCode)}
                    </BodyTypography>
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container>
            <Grid item xs={8}>
                <BodyTypography variant="body2" align="right">
                    Refundable amount:
                </BodyTypography>
            </Grid>
            <Grid item xs={4}>
                <BodyTypography variant="body2" align="right">
                    {getCurrencyString(payment.amount, order.currencyCode)}
                </BodyTypography>
            </Grid>
        </Grid>
    );
};

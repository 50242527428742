import { ExitToApp } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';

const PREFIX = 'AccessCell';

const classes = {
    button: `${PREFIX}-button`
};

const StyledButton = styled(Button)(({ theme }) => ({
    [`&.${classes.button}`]: {
        color: theme.palette.divider,
        '.MuiDataGrid-row:hover &': {
            color: theme.palette.primary.main
        }
    }
}));

interface AccessCellProps {
    params: GridCellParams;
    onClick: (id: string | number) => void;
}

export const AccessCell: React.FC<AccessCellProps> = ({ params, onClick }) => {
    const handleClick = React.useCallback(() => {
        onClick(params.id);
    }, [onClick, params.id]);
    return (
        <StyledButton variant="text" size="small" onClick={handleClick} className={classes.button}>
            <ExitToApp />
        </StyledButton>
    );
};

import { Option } from 'lib/types';

export const weekdays: Option[] = [
    {
        value: '1',
        label: 'Monday'
    },
    {
        value: '2',
        label: 'Tuesday'
    },
    {
        value: '4',
        label: 'Wednesday'
    },
    {
        value: '8',
        label: 'Thursday'
    },
    {
        value: '16',
        label: 'Friday'
    },
    {
        value: '32',
        label: 'Saturday'
    },
    {
        value: '64',
        label: 'Sunday'
    }
];

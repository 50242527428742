import { GridDateFilter } from 'lib/grid/GridDateFilter';
import { Box } from '@mui/material';
import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { GiftingActivityFilter } from './model/giftingActivityFilter';
import { OnGiftingActivityFilterChange } from './hooks/useGiftingActivityFilter';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { GridLocationFilter } from 'lib/grid/GridLocationFilter';
import { Row } from 'ui/Flex';

interface GiftingActivityFiltersProps {
    filter: GiftingActivityFilter;
    onChange: OnGiftingActivityFilterChange;
}

export const GiftingActivityFilters: React.FC<GiftingActivityFiltersProps> = ({ filter, onChange }) => {
    const handleDateChange = React.useCallback(
        (value?: DateRangePickerValue) => {
            onChange('date', value);
        },
        [onChange]
    );
    const handleCardNumberChange = React.useCallback(
        (value: string) => {
            onChange('card_number', value);
        },
        [onChange]
    );
    const handleLocationChange = React.useCallback(
        (value?: string) => {
            onChange('location_ids', value?.split(','));
        },
        [onChange]
    );
    return (
        <Box mt={2} display="flex">
            <Row flex={1} wrap="wrap" valign="center">
                <Box marginRight={1} marginTop={2} minWidth={273}>
                    <GridDateFilter value={filter.date} onChange={handleDateChange} />
                </Box>
                <Box marginRight={1} marginTop={2}>
                    <GridLocationFilter
                        value={filter?.location_ids?.length ? filter.location_ids[0] : null}
                        onChange={handleLocationChange}
                    />
                </Box>
                <Box marginRight={1} marginTop={2}>
                    <GridFilterInput
                        value={filter.card_number}
                        onChange={handleCardNumberChange}
                        label="Filter by card"
                        name="cardnumber"
                    />
                </Box>
            </Row>
        </Box>
    );
};

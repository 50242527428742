/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { stringify } from 'query-string';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { OAuthProvider } from '@pepperhq/location-sdk';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const SquareSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId,
    posLocations,
    onSettingsSaved
}) => {
    const isAuthorized = React.useMemo(() => !!settings?.square?.oauth2AccessToken, [settings]);
    const [loadingOAuth, setLoadingOAuth] = React.useState(false);
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);

    const handleAuthorizeClicked = React.useCallback(async () => {
        setLoadingOAuth(true);
        if (isAuthorized) {
            await new LocationSettingsApi().revokeOAuthAccess(OAuthProvider.SQUARE);
            // Trigger parent update of settings
            if (typeof onSettingsSaved === 'function') {
                await onSettingsSaved();
            }
        } else {
            // Redirect to auth
            const query = {
                client_id: settings?.square?.isProduction
                    ? process.env.SQUARE_APPLICATION_ID
                    : process.env.SANDBOX_SQUARE_APPLICATION_ID,
                response_type: 'code',
                scope:
                    // eslint-disable-next-line max-len
                    'CUSTOMERS_READ CUSTOMERS_WRITE ITEMS_READ MERCHANT_PROFILE_READ ORDERS_READ ORDERS_WRITE PAYMENTS_READ PAYMENTS_WRITE PAYMENTS_WRITE_IN_PERSON INVENTORY_READ LOYALTY_READ LOYALTY_WRITE GIFTCARDS_READ',
                state: JSON.stringify({
                    tenantId,
                    successRedirectUrl: `${window.location.href}?oauthResult=success`,
                    errorRedirectUrl: `${window.location.href}?oauthResult=error`
                }),
                session: false
            };
            window.location.href = `https://connect.${
                settings?.square?.isProduction ? 'squareup' : 'squareupsandbox'
            }.com/oauth2/authorize?${stringify(query)}`;
        }
        setLoadingOAuth(false);
    }, [isAuthorized, tenantId, settings, onSettingsSaved]);

    const posLocationLabel = React.useMemo(() => {
        const posName =
            posLocations?.find(posLocation => posLocation.externalLocationId === settings?.square?.locationId)
                ?.name ?? null;
        return posName ? `${posName} (${settings?.square?.locationId})` : settings?.square?.locationId;
    }, [posLocations, settings]);

    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <>
                    <SettingDisplay label="Production Environment">
                        {settings?.square?.isProduction && <Done />}
                        {!settings?.square?.isProduction && <Close />}
                    </SettingDisplay>
                    <SettingDisplayText title="Merchant ID" text={settings?.square?.merchantId} />
                    <SettingDisplay label="Loyalty Program ID">
                        <Typography variant="body1">{settings?.square?.loyaltyProgramId}</Typography>
                    </SettingDisplay>
                    <SettingDisplay label="Webhook HMAC Secret">
                        <Typography variant="body1">{settings?.square?.webhookHmacSecret}</Typography>
                    </SettingDisplay>
                    <SettingDisplaySwitch
                        title="Delete User In Square"
                        checked={settings?.userDeletion?.deletePosAccountOnUserDeletion ?? true}
                    />
                    <SettingDisplay label="OAuth">
                        <LoadingButton
                            variant={isAuthorized ? 'outlined' : 'contained'}
                            color="primary"
                            onClick={handleAuthorizeClicked}
                            loading={loadingOAuth}
                        >
                            {isAuthorized ? 'De-Authorize' : 'Authorize'}
                        </LoadingButton>
                    </SettingDisplay>
                </>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <SettingDisplay label="Location ID">
                    <Typography variant="body1">{posLocationLabel}</Typography>
                </SettingDisplay>
            )}
        </>
    );
};

import React from 'react';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { getErrorDetails } from 'config/errors';
import { USER_MERCHANTS } from 'config/routes';

export default function UnauthorizedPage() {
    const error = getErrorDetails(401);
    return (
        <ErrorLayout
            title={error.title}
            description={error.description}
            fallbackTitle="homepage"
            fallbackUrl={USER_MERCHANTS}
        />
    );
}

import { styled } from '@mui/material';
import { green, orange } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';

const PREFIX = 'OrderStatusCell';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-grey`
};

const Root = styled('span')({
    [`&.${classes.green}`]: { color: green[500] },
    [`&.${classes.yellow}`]: { color: orange[500] }
});

interface OrderStatusCellProps {
    value: string;
}

const OrderStatusCell: React.FC<OrderStatusCellProps> = ({ value }) => {
    const className = React.useMemo(() => {
        switch (value) {
            case 'Closed':
                return classes.green;
            case 'Open':
            default:
                return classes.yellow;
        }
    }, [value]);
    return <Root className={className}>{titleize(String(value)).replace('_', ' ')}</Root>;
};

export const renderOrderStatusCell = (params: GridRenderCellParams) => (
    <OrderStatusCell value={params.value} />
);

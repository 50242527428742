import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useBaseRefundFields } from 'components/order/hooks/useBaseRefundFields';
import { useCustomRefund } from 'components/order/hooks/useCustomRefund';
import { PaymentRefundConfirmationDialog } from 'components/order/order-details/PaymentRefundConfirmationDialog';
import { OrderRefundReasons } from 'components/order/refund/OrderRefundReasons';
import { EGAEventName, useGAHelpers } from 'lib/hooks/useGAHelper';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Row } from 'ui/Flex';
import { StandaloneRefundSummary } from './StandaloneRefundSummary';
import { OrderRefundPaymentMethod } from 'components/order/refund/OrderRefundPaymentMethod';
import { CardBrand } from '../model/PepperPay';
import { EOrderPaymentType } from 'components/order/model/Order';
import Dinero from 'dinero.js';
import { isCurrencyCode } from '@pepperhq/regions';

interface StandalonePaymentRefundProps {
    refundedAmount: number;
    amount: number;
    paymentMethodBrand: CardBrand;
    last4: string;
    loading: boolean;
    onClose: () => void;
    onRefund: (amount?: number, reason?: string, note?: string) => void;
    currency?: string;
}

export const StandalonePaymentRefund: React.FC<StandalonePaymentRefundProps> = ({
    onRefund,
    amount: _amount,
    loading,
    onClose,
    refundedAmount: _refundedAmount,
    last4,
    paymentMethodBrand,
    currency
}) => {
    const amount = React.useMemo(
        () => Dinero({ amount: _amount, currency: isCurrencyCode(currency) ? currency : 'GBP' }),
        [_amount, currency]
    );
    const refundedAmount = React.useMemo(
        () => Dinero({ amount: _refundedAmount, currency: isCurrencyCode(currency) ? currency : 'GBP' }),
        [_refundedAmount, currency]
    );
    const { logUserEvent } = useGAHelpers();
    const [customAmount, _formattedCustomAmount, customAmountChange, customAmountBlur] = useCustomRefund();
    // It will work for the most of the european/western currencies
    const formattedCustomAmount = React.useMemo(
        () =>
            Dinero({
                amount: Math.round((_formattedCustomAmount ?? 0) * 100),
                currency: isCurrencyCode(currency) ? currency : 'GBP'
            }),
        [_formattedCustomAmount, currency]
    );
    const { reason, notes } = useBaseRefundFields();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [isRefundPaymentOpen, setIsRefundPaymentOpen] = React.useState(false);
    const handleRefundAll = React.useCallback(() => {
        setIsRefundPaymentOpen(true);
    }, []);
    const handleRefundAllClosed = React.useCallback(() => {
        setIsRefundPaymentOpen(false);
    }, []);
    const handleRefundAllSuccess = React.useCallback(() => {
        onRefund();
        logUserEvent(EGAEventName.StandaloneRefundAllAttempt);
        setIsRefundPaymentOpen(false);
    }, [logUserEvent, onRefund]);
    const hasPaymentMethodData = React.useMemo(
        () => paymentMethodBrand && last4,
        [last4, paymentMethodBrand]
    );
    const handleRefund = React.useCallback(() => {
        logUserEvent(EGAEventName.StandalonePaymentRefund);
        onRefund(formattedCustomAmount.getAmount(), reason.value, notes.value);
    }, [formattedCustomAmount, logUserEvent, notes.value, onRefund, reason.value]);
    const isRefundable = React.useMemo(
        () =>
            amount.subtract(refundedAmount).greaterThanOrEqual(formattedCustomAmount) &&
            !formattedCustomAmount.isZero() &&
            !!reason.value?.length,
        [amount, formattedCustomAmount, reason.value?.length, refundedAmount]
    );
    return (
        <Box display="flex" sx={{ maxWidth: '600px', maxHeight: '430px' }}>
            <Box maxWidth="600px">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary" sx={{ mb: 3 }}>
                            Refund a quickpad card payment
                        </Typography>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6} alignItems="flex-end" display="flex">
                        <StandaloneRefundSummary
                            amount={amount.toFormat()}
                            refundedAmount={refundedAmount.toFormat()}
                            netAmount={amount.subtract(refundedAmount).toFormat()}
                        />
                    </Grid>
                    {hasPaymentMethodData && (
                        <Grid item xs={6}>
                            <OrderRefundPaymentMethod brand={paymentMethodBrand} last4={last4} />
                        </Grid>
                    )}
                    <Grid item xs={hasPaymentMethodData ? 6 : 12}>
                        <TextField
                            value={customAmount}
                            disabled={loading}
                            onChange={customAmountChange}
                            onBlur={customAmountBlur}
                            label={
                                <>
                                    Amount to refund{' '}
                                    <Box component="span" color="primary.main">
                                        *
                                    </Box>
                                </>
                            }
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                                startAdornment: settings?.region?.currencySymbol
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OrderRefundReasons
                            value={reason.value}
                            onChange={reason.onChange}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            label="Notes"
                            variant="outlined"
                            fullWidth
                            value={notes.value ?? ''}
                            onChange={notes.onChange}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Row>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={loading}
                                onClick={handleRefundAll}
                            >
                                Refund all
                            </Button>
                            <Row gutter flex={1} align="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={loading || !isRefundable}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRefund}
                                >
                                    Refund {formattedCustomAmount.toFormat()}
                                </Button>
                            </Row>
                        </Row>
                    </Grid>
                </Grid>
            </Box>
            <PaymentRefundConfirmationDialog
                open={isRefundPaymentOpen}
                onClose={handleRefundAllClosed}
                onAgree={handleRefundAllSuccess}
                loading={loading}
                amount={amount.subtract(refundedAmount).toFormat()}
                paymentType={EOrderPaymentType.Terminal}
            />
        </Box>
    );
};

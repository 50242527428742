import { UserMetricDefinition, UserMetricListener } from 'components/metrics/model';
import {
    LoyaltyTier,
    UserMetricListenerMetricCondition,
    getMetricConditionString
} from 'components/metrics/model/loyalty-tier';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useTieredLoyaltyTiers(definition: UserMetricDefinition) {
    const { customerSegments } = useSelector((state: ApplicationState) => state.audiences);
    const audienceIds = React.useMemo(() => {
        const audienceIdList = new Set<string>();
        if (!definition) {
            return [];
        }
        definition.listeners.forEach(listener => {
            if (listener.type === 'ADD_TO_SEGMENT' || listener.type === 'REMOVE_FROM_SEGMENT') {
                audienceIdList.add(listener.segmentId);
            }
        });
        return [...audienceIdList];
    }, [definition]);
    const tiers = React.useMemo<LoyaltyTier[]>(() => {
        if (!audienceIds || !definition) {
            return [];
        }
        return audienceIds.map(id => {
            const addConditions: UserMetricListenerMetricCondition[] = [];
            const removeConditions: UserMetricListenerMetricCondition[] = [];
            const addListeners: UserMetricListener[] = [];
            const removeListeners: UserMetricListener[] = [];
            let missingConditions = false;
            let metricName = '';
            const audienceListeners = definition.listeners.filter(
                listener =>
                    (listener.type === 'ADD_TO_SEGMENT' || listener.type === 'REMOVE_FROM_SEGMENT') &&
                    listener.segmentId === id
            );
            audienceListeners.forEach(listener => {
                const isAddListener = listener.type === 'ADD_TO_SEGMENT';
                if (isAddListener) {
                    addListeners.push(listener);
                } else {
                    removeListeners.push(listener);
                }
                listener.conditions?.forEach(condition => {
                    if ('metricId' in condition) {
                        const metric = definition.metrics[condition.metricId];
                        metricName = metric?.name ?? 'UNKNOWN';
                        if (isAddListener) {
                            addConditions.push(condition);
                        } else {
                            removeConditions.push(condition);
                        }
                    }
                });
            });
            if (!addConditions.length || !removeConditions.length) {
                missingConditions = true;
            }
            const audience = customerSegments?.find(item => item._id === id);
            return {
                id,
                metric: metricName,
                metricAddConditions: addConditions,
                metricRemoveConditions: removeConditions,
                addConditionsString: addConditions
                    .map(item => getMetricConditionString(item.operator, item.value))
                    .join(' + '),
                removeConditionsString: removeConditions
                    .map(item => getMetricConditionString(item.operator, item.value))
                    .join(' + '),
                name: audience?.title ?? id,
                addListeners,
                removeListeners,
                missingConditions
            };
        });
    }, [audienceIds, customerSegments, definition]);
    return {
        tiers
    };
}

import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { FORGOT_YOUR_PASSWORD_CONFIRM, SIGN_IN } from 'config/routes';

enum FirebaseActionModes {
    resetPassword = 'resetPassword'
}

const ActionPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');

    if (mode) {
        if (mode === FirebaseActionModes.resetPassword) {
            const oobCode = params.get('oobCode');
            if (oobCode) {
                return <Redirect to={`${FORGOT_YOUR_PASSWORD_CONFIRM}?oobCode=${oobCode}`} />;
            }
        }
    }

    return <Redirect to={SIGN_IN} />;
};

export default ActionPage;

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { updateSelectedModule } from 'store/content/contentActions';
import { ApplicationState } from 'store/store';
import { Column } from 'ui/Flex';
import { MobileLayout } from 'ui/MobileLayout';
import { MobileContentRenderer } from '../MobileContentRenderer';
import { ContentLayout } from '../models/ContentLayout';
import { ContentModule } from '../models/ContentModule';

interface LayoutPreviewProps {
    currentLayout: ContentLayout;
    currentModules: ContentModule[];
    className?: string;
    isSelectable?: boolean;
    selectedModuleId?: string;
    updateSelectedModuleId?: (id: string) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
    currentLayout: state.content.currentLayout,
    currentModules: state.content.currentModules,
    selectedModuleId: state.content.selectedModuleId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSelectedModuleId: (id: string) => updateSelectedModule(id)(dispatch)
});

export const LayoutPreview = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ({
        currentModules,
        currentLayout,
        className,
        isSelectable,
        selectedModuleId,
        updateSelectedModuleId
    }: LayoutPreviewProps) => (
        <Column align="center" className={className}>
            <MobileLayout>
                <MobileContentRenderer
                    modules={currentModules}
                    anchor={currentLayout && currentLayout.anchor}
                    isSelectable={isSelectable}
                    selectedModuleId={selectedModuleId}
                    updateSelectedModuleId={updateSelectedModuleId}
                />
            </MobileLayout>
        </Column>
    )
);

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Button, Divider, Paper, TextField, styled, Typography } from '@mui/material';
import getValue from 'get-value';
import { MainLayout } from 'layouts/MainLayout';
import { bindActionCreators, Dispatch } from 'redux';
import { contentApi } from 'components/content/contentApi';
import { LayoutConfigStepper } from 'components/content/layout-config/LayoutConfigStepper';
import { LayoutPreview } from 'components/content/layout-config/LayoutPreview';
import {
    MESSAGE_MOBILE_CONTENT_LAYOUT_SET_ACTIVE_ERROR,
    MESSAGE_MOBILE_CONTENT_LAYOUT_SET_ACTIVE_SUCCESS,
    MESSAGE_MOBILE_CONTENT_LAYOUT_SET_DESCRIPTION_ERROR
} from 'config/messages';
import { MOBILE_CONTENT } from 'config/routes';
import { clearCurrentModules, getLayoutFromId } from 'store/content/contentActions';
import { ContentState } from 'store/content/contentReducer';
import { enqueueSnackbar as _enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { Column, Row } from 'ui/Flex';
import { MuiNotificationOptions } from 'ui/MuiNotifier';

const PREFIX = 'mobile-confirm';

const classes = {
    row: `${PREFIX}-row`,
    config: `${PREFIX}-config`,
    preview: `${PREFIX}-preview`,
    activateContent: `${PREFIX}-activateContent`,
    activateBody: `${PREFIX}-activateBody`,
    activateButton: `${PREFIX}-activateButton`,
    input: `${PREFIX}-input`
};

const StyledRow = styled(Row)(({ theme }) => ({
    [`& .${classes.row}`]: {
        width: '100%'
    },
    [`& .${classes.config}`]: {
        flexBasis: '35%'
    },
    [`& .${classes.preview}`]: {
        paddingLeft: theme.spacing(4)
    },
    [`& .${classes.activateContent}`]: {
        padding: theme.spacing(2),
        width: '500px'
    },
    [`& .${classes.activateBody}`]: {
        margin: `${theme.spacing(3)} 0`
    },
    [`& .${classes.activateButton}`]: {
        marginLeft: '10px'
    },
    [`& .${classes.input}`]: {
        marginTop: theme.spacing(2)
    }
}));

interface ConfirmationPageProps {
    content: ContentState;
    enqueueSnackbar: (message: string, options: MuiNotificationOptions) => void;
}

const ConfirmationPage = (props: ConfirmationPageProps) => {
    const [isLoading, setLoading] = useState(false);
    const { content, enqueueSnackbar } = props;
    const [description, setDescription] = useState(
        getValue(content, 'currentLayout.anchor.metadata.description')
    );
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { layoutId } = useParams<{ layoutId: string }>();

    React.useEffect(() => {
        async function load() {
            if (layoutId) {
                // Remove any current modules
                clearCurrentModules()(dispatch);
                // Get the latest saved version of this layout
                await getLayoutFromId(layoutId)(dispatch);
            }
        }
        load();
    }, [dispatch, location.search, layoutId]);

    const handleSaveDraft = React.useCallback(async () => {
        setLoading(true);
        const updateResponse = await contentApi.layouts.update(content.currentLayout.id, {
            body: { anchor: { ...content.currentLayout.anchor, metadata: { description } } }
        });
        if (!updateResponse.ok) {
            return enqueueSnackbar(MESSAGE_MOBILE_CONTENT_LAYOUT_SET_DESCRIPTION_ERROR, { variant: 'error' });
        }
        setLoading(false);
        history.push(MOBILE_CONTENT);
    }, [history, enqueueSnackbar, content?.currentLayout?.id, content?.currentLayout?.anchor, description]);

    const handleActivate = React.useCallback(async () => {
        setLoading(true);
        const updateResponse = await contentApi.layouts.update(content.currentLayout.id, {
            body: { anchor: { ...content.currentLayout.anchor, metadata: { description } } }
        });
        if (!updateResponse.ok) {
            return enqueueSnackbar(MESSAGE_MOBILE_CONTENT_LAYOUT_SET_DESCRIPTION_ERROR, { variant: 'error' });
        }
        // Call Content service to set this ID to active
        const activateResponse = await contentApi.layouts.setActiveLayout({
            layoutId: content.currentLayout.id
        });
        if (activateResponse.ok) {
            enqueueSnackbar(MESSAGE_MOBILE_CONTENT_LAYOUT_SET_ACTIVE_SUCCESS, { variant: 'success' });
        } else {
            enqueueSnackbar(MESSAGE_MOBILE_CONTENT_LAYOUT_SET_ACTIVE_ERROR, { variant: 'error' });
        }

        setLoading(false);
        history.push(MOBILE_CONTENT); // Mobile Content Redirect
    }, [history, enqueueSnackbar, description, content?.currentLayout?.id, content?.currentLayout?.anchor]);

    const handleDescriptionChange = React.useCallback(e => setDescription(e.target.value), []);

    return (
        <MainLayout
            columnProps={{ align: 'center' }}
            breadcrumbs={[{ label: 'Mobile content', url: MOBILE_CONTENT }]}
            pageName="Confirmation"
            pageDescription="Configure and manage what appears in your application"
        >
            <LayoutConfigStepper />
            <StyledRow valign="center" align="center">
                <Column align="center" valign="center">
                    <Paper>
                        <Box
                            flexDirection="column"
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            className={classes.activateContent}
                        >
                            <Box>
                                <Typography variant="h6">Are you ready to activate your app?</Typography>
                            </Box>
                            <Box className={classes.input} width="100%">
                                <TextField
                                    fullWidth
                                    label="Layout description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                                <Typography variant="caption" color="textSecondary">
                                    Provide a description to help you find your layout later
                                </Typography>
                            </Box>
                            <Box className={classes.activateBody}>
                                <Typography variant="caption" align="center">
                                    If this is the first time you have configured your app, it will take us a
                                    few days to get it to you. If you have previously configured your app,
                                    then these changes will be available immediately.
                                </Typography>
                            </Box>
                            <Box width="100%">
                                <Divider />
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    style={{ marginTop: '8px' }}
                                >
                                    <Box>
                                        <Button variant="outlined" onClick={handleSaveDraft}>
                                            Save Draft
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleActivate}
                                            disabled={isLoading}
                                            className={classes.activateButton}
                                        >
                                            Activate
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Column>
                <Column>
                    <LayoutPreview className={classes.preview} />
                </Column>
            </StyledRow>
        </MainLayout>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ enqueueSnackbar: _enqueueSnackbar }, dispatch);

const mapStateToProps = (state: ApplicationState) => ({
    content: state.content
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);

import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { Location, LocationState } from 'components/location/models/LocationModel';
import { CardViewChipRow, CardViewTextRow } from 'ui/viewComponents';
import { mapLocationStateToStyles } from '../helpers';

const PREFIX = 'LocationGeneralCardComponent';

const classes = {
    stateChip: `${PREFIX}-stateChip`
};

const StyledBox = styled(Box)<{ value: LocationState }>(({ theme, value }) => ({
    [`& .${classes.stateChip}`]: mapLocationStateToStyles(value, theme)
}));

interface LocationGeneralCardComponentProps {
    location: Location;
}

export const LocationGeneralCardComponent = (props: LocationGeneralCardComponentProps) => {
    const {
        location: { title, description, contacts, state, VATnumber, timezone }
    } = props;

    return (
        <Grid container spacing={2} direction="row">
            <Grid item xs={6}>
                <CardViewTextRow title="Title" value={title} />
            </Grid>
            <Grid item xs={6}>
                <StyledBox value={state}>
                    <CardViewChipRow title="State" value={state} chipClasses={{ root: classes.stateChip }} />
                </StyledBox>
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardViewTextRow title="VAT Number" value={VATnumber} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardViewTextRow title="Phone Number" value={contacts?.phone || ''} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardViewTextRow title="Timezone" value={timezone} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardViewTextRow title="Email" value={contacts?.email || ''} />
            </Grid>
            <Grid item xs={12}>
                <CardViewTextRow title="Description" value={description} />
            </Grid>
        </Grid>
    );
};

import React from 'react';
import { FormControl, Input, InputAdornment, InputLabel, styled } from '@mui/material';
import Folder from '@mui/icons-material/Folder';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';

interface FileSelectEvent extends Event {
    path?: HTMLInputElement[];
}

interface KeyFileInputProps {
    label: React.ReactNode;
    value: string;
    fullWidth: boolean;
    onKeyChange: (key: string) => void;
}

const PREFIX = 'KeyFileInput';

const classes = {
    fileInput: `${PREFIX}-fileInput`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [`& .${classes.fileInput}`]: {
        width: theme.spacing(6),
        height: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        border: `1px ${theme.palette.divider} solid`
    }
}));

const buildFileSelector = (handler: (event?: FileSelectEvent) => Promise<void>) => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.p8');
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    fileSelector.addEventListener('change', handler);
    return fileSelector;
};
const readFile = (file: File): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = () => resolve(reader.result);
        reader.onerror = err => reject(err);
    });

export const KeyFileInput = ({ onKeyChange, value, label, fullWidth }: KeyFileInputProps) => {
    const [fileSelector, setFileSelector] = React.useState(null);
    const openFileSelector = React.useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            fileSelector.click();
        },
        [fileSelector]
    );
    React.useEffect(() => {
        const fileHandler = async (event: FileSelectEvent) => {
            if (Array.isArray(event.path) && event.path.length && event.path[0].files.length) {
                const key = await readFile(event.path[0].files[0]);

                if (isString(key)) {
                    onKeyChange(key);
                } else {
                    logger.error('Failed to read key file');
                }
            }
        };
        setFileSelector(buildFileSelector(fileHandler));
    }, [onKeyChange]);
    return (
        <StyledFormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Input
                readOnly
                fullWidth={fullWidth}
                value={value || ''}
                inputProps={{ className: classes.fileInput }}
                onClick={openFileSelector}
                endAdornment={
                    <InputAdornment position="start">
                        <Folder sx={{ cursor: 'pointer' }} />
                    </InputAdornment>
                }
            />
        </StyledFormControl>
    );
};

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Grid,
    MenuItem,
    TextField,
    TextFieldProps
} from '@mui/material';
import { ReadOnlyOverlay } from 'lib/form/ReadOnlyOverlay';
import { Option } from 'lib/types';
import React from 'react';
import { TierItem } from '../model/loyalty-tier';

export interface TieredLoyaltyConditionField {
    label?: string;
    select?: boolean;
    options?: Option[];
    width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    searchable?: boolean;
    placeholder?: string;
    fieldMapper?: (body: Partial<TierItem>, value: any) => void;
}

interface TieredLoyaltyTierFormFieldProps {
    name: string;
    field: TieredLoyaltyConditionField;
    value: any;
    onChange: (name: string, value: any) => void;
    readOnly?: boolean;
    loading?: boolean;
    error?: boolean;
}

export const TieredLoyaltyTierFormField: React.FC<TieredLoyaltyTierFormFieldProps> = ({
    name,
    field,
    value,
    onChange,
    readOnly,
    loading,
    error
}) => {
    const textFieldSharedProps = React.useMemo<Partial<TextFieldProps>>(
        () => ({
            fullWidth: true,
            variant: 'outlined',
            disabled: loading,
            size: 'small',
            sx: { opacity: readOnly ? 0.8 : undefined },
            InputProps: {
                readOnly,
                sx: { bgcolor: readOnly ? 'divider' : undefined }
            }
        }),
        [loading, readOnly]
    );
    const renderSelectItem = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    const helperText = React.useMemo(() => (error ? 'This field is required.' : undefined), [error]);
    const renderAutocompleteInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                {...textFieldSharedProps}
                InputProps={{ ...params.InputProps, ...textFieldSharedProps.InputProps }}
                placeholder={field.placeholder}
                error={error}
                helperText={helperText}
            />
        ),
        [error, field.placeholder, helperText, textFieldSharedProps]
    );
    const handleAutocompleteChange = React.useCallback(
        (_: never, newValue: Option) => {
            onChange(name, newValue);
        },
        [name, onChange]
    );
    const getOptionKey = React.useCallback((option: Option) => option.value, []);
    const handleTextFieldChagne = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(name, e.target.value);
        },
        [name, onChange]
    );
    if (field.searchable) {
        return (
            <Grid item xs={12} lg={field.width} sx={{ position: 'relative' }}>
                <Autocomplete
                    value={value ?? null}
                    onChange={handleAutocompleteChange}
                    renderInput={renderAutocompleteInput}
                    options={field.options}
                    getOptionKey={getOptionKey}
                />
                {readOnly && <ReadOnlyOverlay />}
            </Grid>
        );
    }
    return (
        <Grid item xs={12} lg={field.width} sx={{ position: 'relative' }}>
            <TextField
                select={field.select}
                label={field.label}
                value={value ?? ''}
                onChange={handleTextFieldChagne}
                error={error}
                helperText={helperText}
                {...textFieldSharedProps}
            >
                {field.options ? field.options.map(renderSelectItem) : null}
            </TextField>
            {readOnly && <ReadOnlyOverlay />}
        </Grid>
    );
};

import React from 'react';
import { Tooltip, Typography } from '@mui/material';

interface RequiredStarProps {
    title?: string;
    fontSize?: number | string;
}

export function RequiredStar(props: RequiredStarProps) {
    const fontSize = props.fontSize || 22;
    const title = props.title || 'This field is required.';
    return (
        <Tooltip title={title}>
            <Typography style={{ fontSize, lineHeight: 0, paddingLeft: 1 }} color="primary" component="span">
                *
            </Typography>
        </Tooltip>
    );
}

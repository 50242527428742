// Please, do not ask about it. If you know how to validate it better - please let me know.
const regexp = [
    /()/,
    /((([0-1])|(2)))/,
    /((([0-1][0-9])|(2[0-4])))/,
    /((([0-1][0-9])|(2[0-4])):)/,
    /((([0-1][0-9])|(2[0-4])):(([0-5])|(6)))/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)))/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) )/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) -)/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) - )/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) - (([0-1])|(2)))/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) - (([0-1][0-9])|(2[0-4])))/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) - (([0-1][0-9])|(2[0-4])):)/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) - (([0-1][0-9])|(2[0-4])):(([0-5])|(6)))/,
    /((([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)) - (([0-1][0-9])|(2[0-4])):(([0-5][0-9])|(60)))/
];
export const isBasicTimeValid = (val: string) => {
    if (val.length === 0) {
        return true;
    }
    if (val.length > 13) {
        return false;
    }
    if (!regexp[val.length].test(val)) {
        return false;
    }

    return true;
};

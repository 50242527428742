import { createReducer } from 'redux-create-reducer';
import { MuiNotification } from 'ui/MuiNotifier';
import { MuiNotificationsAction } from './notificationsActions';
import { notificationsActionTypes } from './notificationsActionTypes';

export interface NotificationsState {
    notifications: MuiNotification[];
}

export const initialState: NotificationsState = {
    notifications: []
};

const actionHandlers = {
    [notificationsActionTypes.ENQUEUE_SNACKBAR]: (
        state: NotificationsState,
        action: MuiNotificationsAction
    ) => ({
        ...state,
        notifications: [
            ...state.notifications,
            {
                ...action.notification
            }
        ]
    }),
    [notificationsActionTypes.REMOVE_SNACKBAR]: (
        state: NotificationsState,
        action: MuiNotificationsAction
    ) => ({
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key)
    })
};

export default createReducer(initialState, actionHandlers);

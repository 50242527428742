import { OperationStatus } from 'components/operation/models/OperationModel';
import { PageObject } from 'lib/HttpClient';
/* eslint-disable @typescript-eslint/naming-convention */
export interface PepperPayAccount {
    id: string;
    settings?: {
        branding?: {
            logo?: string;
            icon?: string;
            primary_color?: string;
            secondary_color?: string;
        };
    };
    business_profile: {
        name: string;
    };
}

export type CardBrand =
    | 'amex'
    | 'diners'
    | 'discover'
    | 'jcb'
    | 'mastercard'
    | 'unionpay'
    | 'visa'
    | 'unknown';
type PepperTransactionState =
    | 'PENDING'
    | 'COMPLETED'
    | 'REFUNDED'
    | 'DECLINED'
    | 'DISPUTED'
    | 'EXPIRED'
    | 'PARTIALLY_REFUNDED';

export enum PepperTransactionType {
    Order = 'Order',
    Standalone = 'Standalone'
}
type RiskLevel = 'normal' | 'unknown' | 'elevated' | 'highest' | 'not_assessed';
type WalletType = string;

export interface PepperPayTimelineItem {
    created: number;
    type: string;
    id: string;
    _id?: string;
    resourceId?: string;
}

export type PepperPayTimeline = PepperPayTimelineItem[];

export interface PepperTransaction {
    state: PepperTransactionState;
    _id: string;
    tenantId: string;
    locationId: string;
    userId: string;
    clerkId: string;
    total: number;
    totalApplicationFees: number;
    totalFranchiseFees: number;
    created: number;
    charge: {
        riskLevel: RiskLevel;
        chargeId: string;
        riskScore: number;
        threeDSecure: true;
        amountRefunded?: number;
    };
    paymentIntent: {
        walletType: WalletType;
        paymentIntentId: string;
        lastFour: string;
        customerId: string;
        paymentMethodBrand: CardBrand;
        paymentMethodType: string;
        expiryDate?: string;
        paymentMethodFingerprint?: string;
        amount?: number;
        amountReceived?: number;
        amountCapturable?: number;
        status?: string;
    };
    order?: {
        orderId?: string;
    };
    transferGroupId: string;
    currency?: string;
    dispute?: PepperDispute;
    timeline: PepperPayTimeline;
    paymentId: string;
    standalone?: boolean;
    tableNumber?: string;
}

export enum DisputeReason {
    NotReceived = 'product_not_received',
    BankCannotProcess = 'bank_cannot_process',
    CheckReturned = 'check_returned',
    CreditNotProcessed = 'credit_not_processed',
    CustomerInitiated = 'customer_initiated',
    DebitNotAuthorized = 'debit_not_authorized',
    Duplicate = 'duplicate',
    Fraudulent = 'fraudulent',
    General = 'general',
    IncorrectAccountDetails = 'incorrect_account_details',
    InsufficientFunds = 'insufficient_funds',
    ProductUnacceptable = 'product_unacceptable',
    SubscriptionCanceled = 'subscription_canceled',
    Unrecognized = 'unrecognized'
}

export enum DisputeStatus {
    NeedsResponse = 'needs_response',
    WarningNeedsResponse = 'warning_needs_response',
    WarningUnderReview = 'warning_under_review',
    WarningClosed = 'warning_closed',
    UnderReview = 'under_review',
    ChargeRefunded = 'charge_refunded',
    Won = 'won',
    Lost = 'lost'
}

interface PepperDispute {
    disputeId: string;
    created: number;
    evidenceDueBy: 1647561599;
    reason: DisputeReason;
    status: DisputeStatus;
}

interface StripeEvidance {
    access_activity_log: null | string;
    billing_address: null | string;
    cancellation_policy: null | string;
    cancellation_policy_disclosure: null | string;
    cancellation_rebuttal: null | string;
    customer_communication: null | string;
    customer_email_address: null | string;
    customer_name: null | string;
    customer_purchase_ip: null | string;
    customer_signature: null | string;
    duplicate_charge_documentation: null | string;
    duplicate_charge_explanation: null | string;
    duplicate_charge_id: null | string;
    product_description: null | string;
    receipt: null | string;
    refund_policy: null | string;
    refund_policy_disclosure: null | string;
    refund_refusal_explanation: null | string;
    service_date: null | string;
    service_documentation: null | string;
    shipping_address: null | string;
    shipping_carrier: null | string;
    shipping_date: null | string;
    shipping_documentation: null | string;
    shipping_tracking_number: null | string;
    uncategorized_file: null | string;
    uncategorized_text: null | string;
}

interface EvidanceDetails {
    due_by: number;
    has_evidence: boolean;
    past_due: boolean;
    submission_count: number;
}

export interface Dispute {
    id: string;
    created: number;
    amount: number;
    balance_transaction: string;
    charge: string;
    currency: string;
    evidence: StripeEvidance;
    evidance_details: EvidanceDetails;
    is_charge_refundable: boolean;
    livemode: boolean;
    object: 'dispute';
    payment_intent: string;
    reason: DisputeReason;
    status: DisputeStatus;
    metadata: any;
}

export interface FormattedPepperTransaction {
    amount: string;
    orderId: string;
    date: Date;
    state: PepperTransactionState;
    customerId: string;
    risk: RiskLevel;
    paymentChargeId: string;
    lastFour?: string;
    expiryDate?: string;
    id: string;
    type: PepperTransactionType;
    pi: string;
}

export interface PepperPayOperation {
    metadata: {
        parameters: {
            interval_end: number;
            connected_account: string;
            columns: string[];
            interval_start: number;
        };
        reportName?: string;
        report_type: string;
    };
    isRetryable?: boolean;
    entityId: string;
    status: OperationStatus;
    tenantId: string;
    createdAt: string;
    result?: {
        id: string;
        href?: string;
        message: string;
        etaMs: number;
    };
    error?: {
        message: string;
        code: string;
    };
    name: string;
    endedAt: string;
    namespace: string;
    updatedAt: string;
    nonce: string;
    id: string;
    locationId?: string;
}

export interface PepperPayScheduleOperation {
    _id: string;
    tenantId: string;
    name: string;
    interval: string;
    lastRan: number;
    intervalTrigger: number;
    locationId: string;
    notificationEmail: string;
    reportData: {
        parameters: {
            columns: string[];
            connected_account: string;
        };
        report_type: string;
    };
}

export interface IPepperPayOperationResponse {
    items: PepperPayOperation[];
    page: PageObject;
}
export interface FormattedPepperDispute {
    id: string;
    status: DisputeStatus;
    reason: DisputeReason;
    amount: string;
    date: Date;
    dueBy: Date;
    transactionId: string;
    customerId: string;
    paymentIntentId: string;
    paymentChargeId: string;
}

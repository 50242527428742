import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { Option } from 'lib/types';
import React from 'react';
import { Column } from 'ui/Flex';

interface MuiGridSelectProps extends GridRenderEditCellParams {
    options: Option[];
    multiple?: boolean;
    verifyValue?: boolean;
}

export const MuiGridSelect: React.FC<MuiGridSelectProps> = ({
    value,
    id,
    api,
    field,
    options,
    multiple,
    verifyValue
}) => {
    const [open, setOpen] = React.useState(true);
    const handleChange = React.useCallback(
        (event: SelectChangeEvent) => {
            let newValue: string | string[];
            if (Array.isArray(event.target.value) && verifyValue) {
                newValue = event.target.value.filter(item => options.some(option => option.value === item));
            } else {
                newValue = event.target.value;
            }
            api.setEditCellValue({ id, field, value: newValue }, event);
            api.commitCellChange({ id, field });
            if (!multiple) {
                api.setCellMode(id, field, 'view');
            }
        },
        [api, field, id, multiple, options, verifyValue]
    );
    const handleOnBlur = React.useCallback(() => {
        api.setCellMode(id, field, 'view');
    }, [api, field, id]);
    const handleClose = React.useCallback(() => {
        setOpen(false);
        const isEditing = api.getCellMode(id, field) === 'edit';
        if (isEditing) {
            api.setCellMode(id, field, 'view');
        }
    }, [api, field, id]);
    const renderOption = React.useCallback(
        ({ description: localDescription, value, label: optionLabel }: Option) => (
            <MenuItem key={`select-${optionLabel}-${value}`} value={value}>
                {localDescription ? (
                    <Column>
                        {optionLabel}
                        <Typography variant="caption">{localDescription}</Typography>
                    </Column>
                ) : (
                    optionLabel
                )}
            </MenuItem>
        ),
        []
    );
    const renderValue = React.useCallback(
        (value: string[]) =>
            Array.isArray(value)
                ? value.map(item => options.find(option => item === option.value)?.label).join(', ')
                : undefined,
        [options]
    );
    const error = React.useMemo(
        () => api && api.componentsProps && api.componentsProps[id] && api.componentsProps[id][field],
        [api, field, id]
    );

    return (
        <Select
            value={value || (multiple ? [] : '')} // "" || undefined
            fullWidth
            onChange={handleChange}
            open={open}
            onClose={handleClose}
            multiple={multiple}
            renderValue={renderValue}
            onBlur={handleOnBlur}
            error={!!error}
        >
            {options && options.map(renderOption)}
        </Select>
    );
};

export function renderMuiGridSelect(props: GridRenderEditCellParams, options: Option[]) {
    return <MuiGridSelect {...props} options={options} />;
}

export function renderMuiGridMultiSelect(
    props: GridRenderEditCellParams,
    options: Option[],
    verify?: boolean
) {
    return <MuiGridSelect {...props} options={options} multiple verifyValue={verify} />;
}

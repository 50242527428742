import React from 'react';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { SettingDisplay } from 'lib/SettingDisplay';

interface SettingDisplaySwitchProps {
    title?: string;
    checked?: boolean;
    description?: string;
    buildtimeIOS?: boolean;
    buildtimeAndroid?: boolean;
}

export const SettingDisplaySwitch: React.FC<SettingDisplaySwitchProps> = ({
    checked,
    title,
    buildtimeAndroid,
    buildtimeIOS,
    description
}) => (
    <SettingDisplay
        label={title}
        description={description}
        buildtimeIOS={buildtimeIOS}
        buildtimeAndroid={buildtimeAndroid}
    >
        {checked ? <Done /> : <Close />}
    </SettingDisplay>
);

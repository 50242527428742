import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { CreateTagItem } from 'components/location-tags/CreateTagItem';
import AddIcon from '@mui/icons-material/Add';
import { CreateTagItemModal } from 'components/location-tags/modals/CreateTagItemModal';
import { LoadingButton } from '@mui/lab';

export interface ICreateTagItem {
    title: string;
    sort: string;
}

interface IProps {
    tagGroupTitle: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (tags: ICreateTagItem[]) => void;
    loading: boolean;
    initialTags?: ICreateTagItem[];
}

export function CreateTags({ tagGroupTitle, open, onClose, onSubmit, loading, initialTags }: IProps) {
    const [tags, setTags] = React.useState<ICreateTagItem[]>(initialTags ?? []);
    const [isAddTagModalOpen, setAddTagModalOpen] = React.useState(!initialTags.length);

    const handleTagDelete = React.useCallback((index: number) => {
        setTags(oldValue => {
            const oldTags = [...oldValue];
            oldTags.splice(index, 1);
            return oldTags;
        });
    }, []);

    const handleAddClick = React.useCallback(() => {
        setAddTagModalOpen(true);
    }, []);
    const handleAddition = React.useCallback((item: ICreateTagItem) => {
        setTags(oldTags => [...oldTags, item]);
        setAddTagModalOpen(false);
    }, []);
    const handleAddTagItemModalClose = React.useCallback(() => {
        setAddTagModalOpen(false);
    }, []);
    const handleSubmit = React.useCallback(() => {
        onSubmit(tags);
    }, [onSubmit, tags]);
    const handleItemEdit = React.useCallback((details: ICreateTagItem, index: number) => {
        setTags(oldTags => oldTags.map((tag, i) => (i === index ? { ...details } : tag)));
    }, []);

    React.useEffect(
        () => () => {
            setTags([]);
        },
        []
    );

    if (!open) {
        return null;
    }

    return (
        <Box>
            <Typography sx={{ mb: 1 }} variant="h6" color="primary">
                {initialTags?.length ? 'Update' : 'Create'} &apos;{tagGroupTitle}&apos; Tags
            </Typography>
            {!!tags.length && (
                <>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={5}>
                            <Typography fontWeight={700}>Title</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography fontWeight={700}>Sort</Typography>
                        </Grid>
                    </Grid>
                    {tags.map((tag, index) => (
                        <CreateTagItem
                            key={`${tag.title}_${index}`}
                            tag={tag}
                            index={index}
                            onDelete={handleTagDelete}
                            onEdit={handleItemEdit}
                        />
                    ))}
                </>
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <IconButton onClick={handleAddClick}>
                    <AddIcon />
                </IconButton>
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton
                    disabled={!tags.length}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ ml: 1 }}
                    loading={loading}
                >
                    Submit
                </LoadingButton>
            </Box>
            <CreateTagItemModal
                open={isAddTagModalOpen}
                onClose={handleAddTagItemModalClose}
                onCreate={handleAddition}
            />
        </Box>
    );
}

import { MenuSummary } from '@pepperhq/menu-sdk';
import { IMenuManagerAction } from './menuManagerAction';
import { MenuManagerActionType } from './menuManagerActionTypes';

export interface MenuManagerState {
    menuSummaryList: MenuSummary[];
    hasLoaded: boolean;
    isLoading: boolean;
}

export const initialState: MenuManagerState = {
    menuSummaryList: [],
    hasLoaded: false,
    isLoading: false
};

export default function (
    state: MenuManagerState = initialState,
    action: IMenuManagerAction
): MenuManagerState {
    switch (action.type) {
        case MenuManagerActionType.START_REQUEST:
            return { ...state, isLoading: true };
        case MenuManagerActionType.END_REQUEST:
            return { ...state, isLoading: false, hasLoaded: true };
        case MenuManagerActionType.GET_MENU_SUMMARY_LIST:
            return { ...state, menuSummaryList: action.list };
        default:
            return state;
    }
}

import React from 'react';

export function useTieredLoyaltyTierEdit() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    return {
        tiersModalOpen: open,
        handleTierEditOpen: handleOpen,
        handleTierEditClose: handleClose
    };
}

import { updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { getOrdersFilterQuery, PepperOrdersFilter } from './useOrdersFilter';
import { FormattedOrder, IOrder, IOrderItem, IOrderPayment, OrderScenario, IOrderUser } from '../model/Order';
import { orderApi } from '../orderApi';
import { getTimeslotTimes } from 'components/timeslots/helpers';
import { useCurrencyString } from 'lib/hooks/useCurrencyString';
import { isEmptyString, isString } from 'lib/typeguards';

export const ORDERS_PAGE_LIMIT = 100;

export function usePepperOrders(filter: PepperOrdersFilter) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [orders, setOrders] = React.useState<IOrder[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<PepperOrdersFilter>();
    const reset = React.useCallback(() => {
        setOrders([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    React.useEffect(() => {
        const getOrders = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters = getOrdersFilterQuery(internalFilter);
                queryParameters.push({ key: 'limit', value: ORDERS_PAGE_LIMIT });
                queryParameters.push({ key: 'include', value: 'userdetail' });
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }

                const { body, ok } = await orderApi.getList({
                    queryParameters
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey) {
                    setEndOfData(true);
                }
                setOrders(currentOrders => [...currentOrders, ...body.items]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
            } catch (e) {
                logger.error(e);
                setOrders([]);
            }
            setLoading(false);
        };
        if (
            (page === 0 && orders.length === 0) ||
            (!pages[page + 1] && orders.length < pages.length * ORDERS_PAGE_LIMIT)
        ) {
            getOrders();
        }
    }, [endOfData, internalFilter, page, pages, orders.length]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return orders.length;
        }
        return -1;
    }, [endOfData, orders.length]);
    const getTimeslotString = React.useCallback((item: IOrder) => {
        if (item.scenario === OrderScenario.PREORDER) {
            if (item.timeSlot) {
                return getTimeslotTimes(item.timeSlot, true);
            }
            return 'ASAP';
        }
        return undefined;
    }, []);
    const getItemsString = React.useCallback((items: IOrderItem[]) => {
        const itemsToDisplay = items.slice(0, 2);
        const hiddenItems = items.slice(2);
        const stringToDisplay = itemsToDisplay
            .map(item => (item.quantity > 1 ? `${item.quantity}x${item.productName}` : item.productName))
            .join(', ');
        const quantity = hiddenItems.reduce((acc, orderItem) => (acc += orderItem.quantity), 0);
        const secondaryString = quantity ? ` and ${quantity} more items` : '';
        return `${stringToDisplay}${secondaryString}`;
    }, []);
    const getCurrencyString = useCurrencyString();
    const getPaymentsString = React.useCallback(
        (payments: IOrderPayment[]) => {
            const filtered = payments.filter(item => !item.deleted && item.amount >= 0);
            const itemsToDisplay = filtered.slice(0, 2);
            const hiddenItems = filtered.slice(2);
            if (hiddenItems.length > 1) {
                const stringToDisplay = itemsToDisplay.map(item => getCurrencyString(item.amount)).join(', ');
                const quantity = hiddenItems.reduce(acc => acc + 1, 0);
                const secondaryString = quantity ? ` and ${quantity} more payments` : '';
                return `${stringToDisplay}${secondaryString}`;
            }
            return filtered.map(item => getCurrencyString(item.amount)).join(', ');
        },
        [getCurrencyString]
    );
    const getUsers = React.useCallback(
        (users: IOrderUser[] = []) =>
            users
                .map(item => {
                    let userName = '';
                    if (isString(item.firstName) && !isEmptyString(item.firstName)) {
                        userName = item.firstName;
                    }
                    if (isString(item.lastName) && !isEmptyString(item.lastName)) {
                        userName = `${userName} ${item.lastName}`;
                    }
                    return userName;
                })
                .filter(item => !isEmptyString(item))
                .join(', '),
        []
    );
    const formattedOrders = React.useMemo(() => {
        if (!Array.isArray(orders)) {
            return [];
        }
        return orders
            .slice(page * ORDERS_PAGE_LIMIT, page * ORDERS_PAGE_LIMIT + ORDERS_PAGE_LIMIT)
            .map<FormattedOrder>(item => ({
                id: item.id,
                scenario: item.scenario,
                createdAt: item.createdAt,
                isOpen: item.isOpen ? 'Open' : 'Closed',
                locationId: item.locationId,
                timeSlot: getTimeslotString(item),
                deliveryLocation: item.deliveryLocation,
                total: getCurrencyString(item.total.total),
                items: getItemsString(item.items),
                payments: getPaymentsString(item.payments),
                users: getUsers(item.users)
            }))
            .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    }, [orders, page, getTimeslotString, getCurrencyString, getItemsString, getPaymentsString, getUsers]);
    return { orders, page, onPageChange, loading, count, formattedOrders };
}

import { green, orange, red } from '@mui/material/colors';
import { IOperationV2, OperationStatus } from 'components/operation/models/OperationModel';

interface EnrichmentOperationMetadata {
    tenantId: string;
    locationIds: string[];
}

interface EnrichmentOperationResult {
    errorMessages?: string[];
    exported?: number;
    imported?: number;
    skipped: number;
    total: number;
    errors: number;
    removedUnlinked?: number;
    href?: string;
    etaMs: number;
    message: string;
}

interface EnrichmentOperationError {
    code: string;
    message: string;
    isRetryable: boolean;
}

export interface EnrichmentOperation
    extends IOperationV2<
        string,
        EnrichmentOperationMetadata,
        EnrichmentOperationResult,
        EnrichmentOperationError
    > {
    namespace: 'ENRICHMENTS';
    name: 'EXPORT' | 'IMPORT';
    id: string;
}

export function mapEnrichmentOperationStatusToChipColor(status: OperationStatus): string {
    switch (status) {
        case OperationStatus.PENDING:
        case OperationStatus.RUNNING:
            return orange['500'];
        case OperationStatus.DONE:
            return green['A700'];
        case OperationStatus.TIMEOUT:
        case OperationStatus.ERROR:
            return red['500'];
    }
}

export interface ICreateExportOption {
    autoGroup: boolean;
}

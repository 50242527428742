import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material';
import { generateNumArray } from 'lib/helpers';

interface TableSkeletonComponentProps {
    tableHeadColumns: number;
    tableBodyRows?: number;
    width?: string | number;
    height?: string | number;
}

const getTableHeadRowsElements = (
    tableNumOfColumns: number,
    width?: string | number,
    height?: string | number
) =>
    generateNumArray(tableNumOfColumns).map(column => (
        <TableCell key={`table-column-${column}`}>
            <Skeleton width={width} height={height} />
        </TableCell>
    ));

export const TableSkeletonComponent = (props: TableSkeletonComponentProps) => {
    const { tableHeadColumns, tableBodyRows, width, height } = props;

    const tableHeadRowsElements = getTableHeadRowsElements(tableHeadColumns, width, height);

    const tableBodyRowsElements = generateNumArray(tableBodyRows).map(row => (
        <TableRow key={`table-row-${row}`}>{tableHeadRowsElements}</TableRow>
    ));

    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>{tableHeadRowsElements}</TableRow>
            </TableHead>
            <TableBody>{tableBodyRowsElements}</TableBody>
        </Table>
    );
};

TableSkeletonComponent.defaultProps = {
    tableHeadColumns: 1,
    tableBodyRows: 1,
    width: '100%'
};

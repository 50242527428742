export const getGoogleTagsManagerScript = function () {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GTM_CONTAINER_ID}');`;
};

export const getGoogleOptimizeScript = function () {
    return `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${process.env.GA_TRACKING_ID}', { 'optimize_id': '${process.env.GTM_OPTIMIZE_ID}'});`;
};

export const getAntiFlickerStyles = function () {
    return '.async-hide { opacity: 0 !important} ';
};

export const getAntiFlickerScript = function () {
    return `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
            })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'${process.env.GTM_CONTAINER_ID}':true});`;
};

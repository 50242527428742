import React from 'react';
import { Box, Dialog, styled, Typography } from '@mui/material';
import { ModalProps } from '@mui/material/Modal';
import { PaperProps } from '@mui/material/Paper';
import clsx from 'clsx';

const PREFIX = 'MuiModal';

const classes = {
    noMaxWidth: `${PREFIX}-noMaxWidth`,
    container: `${PREFIX}-container`,
    scrollable: `${PREFIX}-scrollable`,
    noPadding: `${PREFIX}-noPadding`,
    overflowVisible: `${PREFIX}-overflowVisible`,
    paperRoot: `${PREFIX}-paperRoot`
};

const StyledDialog = styled(Dialog, {
    shouldForwardProp: prop => prop !== 'paperMaxWidth'
})<MuiModalProps>(({ paperMaxWidth, noMaxWidth }) => ({
    width: '100%',
    overflowY: 'auto',
    overflow: 'moz-scrollbars-none',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    [`& .${classes.noMaxWidth}`]: {
        maxWidth: '80%'
    },
    [`& .${classes.container}`]: {
        height: '100%',
        outline: 0,
        width: '100%'
    },
    [`&.${classes.scrollable}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },
    [`& .${classes.noPadding}`]: {
        padding: 0
    },
    [`& .${classes.overflowVisible}`]: {
        overflow: 'visible'
    },
    [`& .${classes.paperRoot}`]: {
        width: 'max-content',
        ...(!noMaxWidth &&
            paperMaxWidth && {
                maxWidth: paperMaxWidth
            })
    }
}));

export type MuiModalProps = ModalProps &
    PaperProps & {
        padding?: boolean;
        overflowVisible?: boolean;
        disableBackdropClick?: boolean;
        noMaxWidth?: boolean;
        paperMaxWidth?: string | number;
    };

export const MuiModal = (props: MuiModalProps) => {
    const {
        children,
        open,
        onClose,
        disableBackdropClick,
        padding,
        overflowVisible,
        title,
        noMaxWidth,
        paperMaxWidth,
        ...rest
    } = props;
    const handleClose = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-types
        (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
            if (disableBackdropClick) {
                return;
            }
            if (onClose) {
                onClose(event, reason);
            }
        },
        [disableBackdropClick, onClose]
    );
    const root = clsx(classes.paperRoot, {
        [classes.noPadding]: 'padding' in props && !padding,
        [classes.overflowVisible]: overflowVisible,
        [classes.noMaxWidth]: noMaxWidth
    });
    return (
        <StyledDialog
            fullWidth
            paperMaxWidth={paperMaxWidth}
            open={open}
            onClose={handleClose}
            classes={{ container: classes.container }}
            scroll="body"
            className={classes.scrollable}
            PaperProps={{
                classes: {
                    root
                },
                ...rest
            }}
        >
            {open && (
                <Box padding={'padding' in props && !padding ? 0 : 2}>
                    {!!title && (
                        <Typography variant="h6" color="primary">
                            {title}
                        </Typography>
                    )}
                    {children}
                </Box>
            )}
        </StyledDialog>
    );
};

import React from 'react';
import { IOrderRefundItemEntry } from '../hooks/useCalculatedRefund';
import { Checkbox, ListItem, ListItemButton, Typography, styled } from '@mui/material';
import { Row } from 'ui/Flex';
import { useCurrencyString } from 'lib/hooks/useCurrencyString';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 'thin',
    borderBottomStyle: 'dashed'
}));

const StyledTypography = styled(Typography)({
    fontSize: '0.8rem'
});

const StyledCostTypography = styled(Typography)({
    fontSize: '0.8rem',
    minWidth: '64px'
});

interface OrderRefundItemChildProps {
    item: IOrderRefundItemEntry;
    onClick: (id: string) => void;
    selected: boolean;
}

export const OrderRefundItemChild: React.FC<OrderRefundItemChildProps> = ({ onClick, item, selected }) => {
    const handleClick = React.useCallback(() => {
        onClick(item.id);
    }, [item.id, onClick]);
    const getCurrencyString = useCurrencyString();
    return (
        <StyledListItem disablePadding>
            <ListItemButton
                disabled={item.cost === 0}
                role={undefined}
                onClick={handleClick}
                dense
                sx={{ p: 0.5, pl: 2 }}
            >
                <Checkbox
                    edge="start"
                    sx={{ p: 0.5 }}
                    checked={selected}
                    tabIndex={-1}
                    disableRipple
                    size="small"
                />
                <Row align="space-between" flex={1} valign="center">
                    <StyledTypography>{item.title}</StyledTypography>
                    <StyledCostTypography align="right" sx={{ pl: 1 }}>
                        {getCurrencyString(item.cost)}
                    </StyledCostTypography>
                </Row>
            </ListItemButton>
        </StyledListItem>
    );
};

import { Box } from '@mui/material';
import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import {
    MenuChange,
    ModifierChangeUpdate,
    ModifierOptionChange,
    toModifierProductKey
} from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { TextGridColumn } from 'lib/MuiGrid/Columns';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import {
    BaseMenuTab,
    decompoundKey,
    getMenuChangeDataToCopy,
    getModifierIdModifierOptions,
    getModifiersData,
    isModifiersMinMaxSelectSupported,
    MENU_CHANGE_SCHEMA,
    ModifierOptionsMap,
    useMenuChange
} from '../model/menu';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_MODIFIERS_UPDATE_ERROR, MESSAGE_MODIFIERS_UPDATE_SUCCESS } from 'config/messages';
import { SelectedModifierGridColumn } from '../selectedModifierSelect';
import merge from 'deepmerge';
import { useGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { isEmptyString } from 'lib/typeguards';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { getMenuStorageName } from '../gridStateStorageHelpers';
import { ActionsHeader } from '../ActionsHeader';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { EPointOfSaleProvider } from 'components/settings/PointOfSaleSettings';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';

type ModifiersTabProps = BaseMenuTab;

const renderIdCell = (params: GridRenderEditCellParams) => {
    const [modifierId] = decompoundKey(params.value);
    return renderMuiGridTextView(params, modifierId);
};

export const ModifiersTab: React.FC<ModifiersTabProps> = ({
    menu,
    settings,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);

    const isMixMaxSupported = React.useMemo(() => isModifiersMinMaxSelectSupported(settings), [settings]);

    const includeFieldsToCopy = React.useMemo(() => {
        let fields = ['title', 'bundle', 'allowQuantitySelect', 'hide', 'isCollapsed'];
        if (isMixMaxSupported) {
            fields = [...fields, 'minSelect', 'maxSelect'];
        }
        return fields;
    }, [isMixMaxSupported]);

    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleFocusOut,
        handleCellSelection,
        copyEnabled
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, includeFieldsToCopy);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const modifiers = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getModifiersData(menu, change);
    }, [change, menu]);

    const modifierIdModifierOptions = React.useMemo<ModifierOptionsMap>(() => {
        if (!menu || !change) {
            return new Map();
        }
        return getModifierIdModifierOptions(menu, change);
    }, [menu, change]);

    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(() => [{ column: 'id' }, { column: 'title' }], []);
    const { filterModel, renderInput } = useGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'modifiers',
        'Search by ID or title'
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 120,
                renderCell: renderIdCell
            },
            {
                field: 'title',
                headerName: 'Title',
                editable: true,
                width: 300,
                ...TextGridColumn()
            },
            {
                field: 'category',
                headerName: 'Category',
                width: 200
            },
            {
                field: 'hide',
                headerName: 'Hidden',
                width: 140,
                editable: true,
                headerAlign: 'left',
                type: 'boolean'
            },
            {
                field: 'minSelect',
                headerName: 'Minimum Selection',
                type: 'number',
                width: 220,
                editable: isMixMaxSupported,
                headerAlign: 'left',
                ...TextGridColumn()
            },
            {
                field: 'maxSelect',
                headerName: 'Maximum Selection',
                type: 'number',
                width: 220,
                editable: isMixMaxSupported,
                headerAlign: 'left',
                ...TextGridColumn()
            },
            {
                field: 'isCollapsed',
                headerName: 'Collapsed',
                width: 140,
                editable: true,
                headerAlign: 'left',
                type: 'boolean',
                description:
                    'Determines whether the modifier group will be open or closed when landing on the product details page'
            },
            {
                field: 'bundle',
                headerName: 'Bundle',
                width: 140,
                editable: true,
                headerAlign: 'left',
                type: 'boolean'
            },
            {
                field: 'allowQuantitySelect',
                headerName: 'Quantity Select',
                width: 200,
                editable: settings.posProvider !== EPointOfSaleProvider.SQUARE,
                headerAlign: 'left',
                description:
                    settings.posProvider !== EPointOfSaleProvider.SQUARE ? undefined : 'Not supported',
                type: 'boolean'
            },
            {
                field: 'selectedOptions',
                headerName: 'Selected Product/Option',
                width: 270,
                editable: true,
                ...SelectedModifierGridColumn(modifierIdModifierOptions)
            },
            {
                field: 'displayOptions',
                headerName: 'Products/Options',
                width: 350,
                ...SelectedModifierGridColumn(modifierIdModifierOptions),
                editable: false
            }
        ],
        [isMixMaxSupported, modifierIdModifierOptions, settings.posProvider]
    );
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_MODIFIERS_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState();
            const isModifierProducts = menu.categories.some(category =>
                category.modifiers.some(modifier => Array.isArray(modifier.products))
            );
            const modifierOptions: Record<string, ModifierOptionChange> = {};
            const modifierChanges = Object.entries(changes).reduce<MenuChange['modifiers']>(
                // eslint-disable-next-line unused-imports/no-unused-vars-ts
                (acc, [id, { category, selectedOptions, _overriden, ...item }]) => {
                    const [modifierId] = decompoundKey(id);
                    if (Array.isArray(selectedOptions)) {
                        const options = modifierIdModifierOptions.get(id);
                        if (options) {
                            Object.keys(options).forEach(option => {
                                if (selectedOptions.includes(option)) {
                                    modifierOptions[
                                        isModifierProducts
                                            ? toModifierProductKey(option, modifierId, undefined)
                                            : option
                                    ] = {
                                        selected: true
                                    };
                                } else {
                                    modifierOptions[
                                        isModifierProducts
                                            ? toModifierProductKey(option, modifierId, undefined)
                                            : option
                                    ] = {
                                        selected: null
                                    };
                                }
                            });
                        }
                    } else if (!selectedOptions) {
                        const options = modifierIdModifierOptions.get(id);
                        if (options) {
                            Object.keys(options).forEach(option => {
                                modifierOptions[
                                    isModifierProducts
                                        ? toModifierProductKey(option, modifierId, undefined)
                                        : option
                                ] = {
                                    selected: null
                                };
                            });
                        }
                    }
                    if (!change?.modifiers || !change?.modifiers[id]) {
                        acc[modifierId] = item;
                    } else {
                        acc[modifierId] = { ...change.modifiers[id], ...item };
                    }
                    if (isEmptyString(acc[modifierId].hide)) {
                        acc[modifierId].hide = null;
                    }
                    if (isEmptyString(acc[modifierId].bundle)) {
                        acc[modifierId].bundle = null;
                    }
                    if (isEmptyString(acc[modifierId].isCollapsed)) {
                        acc[modifierId].isCollapsed = null;
                    }
                    if (isEmptyString(acc[modifierId].allowQuantitySelect)) {
                        acc[modifierId].allowQuantitySelect = null;
                    }
                    if (isEmptyString(acc[modifierId].title)) {
                        acc[modifierId].title = null;
                    }
                    return acc;
                },
                {}
            );
            const additionalData = isModifierProducts
                ? { modifierProducts: merge(externalChange.modifierProducts || {}, modifierOptions) }
                : { modifierOptions: merge(externalChange.modifierOptions || {}, modifierOptions) };
            if (deleted) {
                deleted.forEach(modifiers => {
                    modifierChanges[modifiers] = null;
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                modifiers: modifierChanges,
                schema: MENU_CHANGE_SCHEMA,
                ...additionalData
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_MODIFIERS_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [
        gridRef,
        menu.categories,
        externalChange?.modifierProducts,
        externalChange?.modifierOptions,
        deleted,
        menuChangeId,
        setChange,
        onUpdate,
        resetDeleted,
        dispatch,
        change.modifiers,
        modifierIdModifierOptions
    ]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const menuChange = getMenuChangeDataToCopy<ModifierChangeUpdate>(
            change,
            selectedIds.map(id => decompoundKey(id)[0]),
            'modifiers',
            selectedCell,
            row => {
                const [id] = decompoundKey(row.id);

                return id;
            }
        );
        setMenuChangeToCopy(menuChange);
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={modifiers}
                columns={columns}
                stateRef={gridRef}
                onStatusChange={handleStatusChange}
                filterModel={filterModel}
                storageName={getMenuStorageName(GridStorageName.Modifiers)}
                disableColumnFilter
                checkboxSelection
                checkboxVisibility
                onSelectionModelChange={handleSelectChange}
                selectionModel={selectedIds}
                onCellClick={handleCellSelection}
                onCellFocusOut={handleFocusOut}
                deleted={deleted}
                isCellEditable={isCellEditable}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};

/* eslint-disable @typescript-eslint/naming-convention */
import { AutoFormFields } from 'lib/LegacyForm';

export const createLocationServicesForm: () => AutoFormFields = () => ({
    book_room: {
        label: 'Room Bookings',
        type: 'switch'
    },
    book_table: {
        label: 'Table Bookings',
        type: 'switch'
    },
    checkins: {
        label: 'Checkins',
        type: 'switch'
    },
    offline_checkin: {
        label: 'Barcode Checkins & Rewards',
        type: 'switch',
        description:
            "This enables users to checkin and use rewards using a barcode. This is useful when the location doesn't have a good internet connection."
    },
    pre_order: {
        label: 'Pre-Orders',
        type: 'switch'
    },
    order_to_table: {
        label: 'Order to Table',
        type: 'switch'
    },
    order_to_table_nfc: {
        label: 'Order to Table NFCs Tags',
        type: 'switch',
        description:
            'This enables users to use their (NFC enabled) phone to scan a tag, letting the app know what table they want to open an order at.'
    },
    tab: {
        label: 'Bar Tabs',
        type: 'switch'
    },
    pay_at_table: {
        label: 'Pay at Table',
        type: 'switch'
    },
    pay_at_table_nfc: {
        label: 'Pay at Table NFCs Tags',
        type: 'switch',
        description:
            'This enables users to use their (NFC enabled) phone to scan a tag, letting the app know what table they want to open an order at.'
    }
});

import React from 'react';
import { NumericFormat, NumericFormatProps, NumberFormatValues } from 'react-number-format';

interface PrefixInputProps extends NumericFormatProps {
    inputRef: NumericFormatProps['getInputRef'];
    prefix?: string;
    onChange?: (e: any) => void;
}

export function PrefixInput(props: PrefixInputProps) {
    const { inputRef, onChange, ...other } = props;

    const handleChange = React.useCallback(
        (values: NumberFormatValues) => {
            onChange({
                target: {
                    value: values.value
                }
            });
        },
        [onChange]
    );

    return <NumericFormat {...other} getInputRef={inputRef} onValueChange={handleChange} thousandSeparator />;
}

import { EMetricType, UserMetricListener, UserMetricListenerCondition, UserMetricResource } from '.';
import { getMinutesFromDays } from 'lib/helpers';

export interface LoyaltyTier {
    name: string;
    id: string;
    metric: string;
    metricAddConditions: UserMetricListenerMetricCondition[];
    metricRemoveConditions: UserMetricListenerMetricCondition[];
    addConditionsString: string;
    removeConditionsString: string;
    addListeners: UserMetricListener[];
    removeListeners: UserMetricListener[];
    missingConditions?: boolean;
}

export enum ELoyaltyTierState {
    INACTIVE = 'INACTIVE',
    SCHEDULED = 'SCHEDULED',
    REALTIME = 'REALTIME',
    SCHEDULED_AND_REALTIME = 'SCHEDULED + REALTIME'
}

export interface EditTieredLoyaltyForm {
    name: string;
}

export interface MetricTierLoyaltyForm {
    name: string;
    type: EMetricType | string;
    action?: string;
    perkId?: string;
    includeTips?: boolean;
    timeWindowStart?: number;
    timeWindowEnd?: number;
    gt?: Date;
    lt?: Date;
}

export function generateUserMetricData(values: MetricTierLoyaltyForm): UserMetricResource {
    const baseFields: UserMetricResource = {
        name: values.name,
        displayName: values.name,
        type: values.type,
        timeRange: {
            gt: values.gt ?? undefined,
            lt: values.lt ?? undefined
        }
    };
    if (values.timeWindowStart) {
        baseFields.timeRange.windowStartOffsetMins = getMinutesFromDays(values.timeWindowStart);
        baseFields.timeRange.windowEndOffsetMins = getMinutesFromDays(values.timeWindowEnd);
    }
    switch (values.type) {
        case EMetricType.Spend: {
            return {
                ...baseFields,
                includeTips: values.includeTips
            };
        }
        case EMetricType.PointsBurned:
        case EMetricType.PointsEarned:
            return {
                ...baseFields,
                perkId: values.perkId
            };
        case EMetricType.ActionType:
            return {
                ...baseFields,
                type: values.action
            };
        case EMetricType.TipsGiven:
        default:
            return {
                ...baseFields
            };
    }
}

export function getMetricConditionString(
    operator:
        | 'EQUAL'
        | 'NOT_EQUAL'
        | 'GREATER_THAN'
        | 'LESS_THAN'
        | 'IS_WITHIN_TOP_PERCENTAGE'
        | 'IS_NOT_WITHIN_TOP_PERCENTAGE',
    value: number
) {
    switch (operator) {
        case 'EQUAL':
            return `Equal to ${value}`;
        case 'NOT_EQUAL':
            return `Not equal to ${value}`;
        case 'LESS_THAN':
            return `Less than ${value}`;
        case 'GREATER_THAN':
            return `Greater than ${value}`;
        default:
            return `${operator} + ${value}`;
    }
}

export const METRICS_HEIGHT = '240px';

export enum ETierType {
    Metric = 'METRIC',
    UserProperty = 'USER_PROPERTY',
    Audience = 'AUDIENCE'
}

export enum ETierOperator {
    Equal = 'EQUAL',
    NotEqual = 'NOT_EQUAL',
    GreaterThan = 'GREATER_THAN',
    LessThan = 'LESS_THAN',
    Contains = 'CONTAINS',
    NotContains = 'NOT_CONTAINS',
    IsInAudience = 'IS_IN_SEGMENT',
    IsNotInAudience = 'IS_NOT_IN_SEGMENT'
}

export enum ETierUserProperty {
    HasAgreedToReceiveMarketing = 'hasAgreedToReceiveMarketing',
    HasAgreedToShareData = 'hasAgreedToShareData',
    Birthdate = 'birthdate',
    FavouriteLocations = 'favouriteLocations',
    PrimaryPlatform = 'primaryPlatform'
}

export interface TierItem {
    type: ETierType;
    metricId?: string;
    userProperty?: ETierUserProperty;
    operator?: ETierOperator;
    value?: number | string | boolean | Date;
}

export type TierItemBody = Omit<TierItem, 'type'>;

export interface TieredLoyaltyListenerBody {
    conditions?: TierItemBody[];
    type: 'ADD_TO_SEGMENT' | 'REMOVE_FROM_SEGMENT';
    segmentId: string;
}

export type TieredLoyaltyTiersFieldName =
    | 'type'
    | 'metricId'
    | 'metricOperator'
    | 'value'
    | 'audienceOperator'
    | 'audience'
    | 'userProperty'
    | 'primaryPlatformOperator'
    | 'primaryPlatformValue'
    | 'favouriteLocationsOperator'
    | 'favouriteLocationsValue'
    | 'marketingOperator';

export const getTieredLoyaltyConditionType = (condition?: UserMetricListenerCondition) => {
    if (!condition) {
        return undefined;
    }
    if ('metricId' in condition) {
        return ETierType.Metric;
    }
    if ('userProperty' in condition) {
        return ETierType.UserProperty;
    }
    if (
        condition.operator === ETierOperator.IsInAudience ||
        condition.operator === ETierOperator.IsNotInAudience
    ) {
        return ETierType.Audience;
    }
    return undefined;
};

export type UserMetricListenerMetricCondition = {
    _id: string;
    metricId: string;
    operator:
        | 'EQUAL'
        | 'NOT_EQUAL'
        | 'GREATER_THAN'
        | 'LESS_THAN'
        | 'IS_WITHIN_TOP_PERCENTAGE'
        | 'IS_NOT_WITHIN_TOP_PERCENTAGE';
    value: number;
};

import { Box, Button, Grid, IconButton, styled, Typography } from '@mui/material';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import React from 'react';
import DragHandle from '@mui/icons-material/DragHandle';
import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from 'react-sortable-hoc';
import Add from '@mui/icons-material/Add';
import { TextFormField } from 'lib/form/fields/TextFormField';
import DeleteIcon from '@mui/icons-material/Delete';
import { TagForm } from './TagsForm';
import { TagsImagePiker } from './TagsImagePicker';

const SortableContainer = sortableContainer(({ children }: { children: React.ReactNodeArray }) => (
    <ul>{children}</ul>
));
const CustomDragHandle = sortableHandle(({ className }: { className: string }) => (
    <DragHandle className={className} />
));

interface TagValuesFormProps extends FieldArrayRenderProps {
    form: FormikProps<TagForm>;
}

interface TagValueFormProps {
    remove: (index: number) => void;
    index: number;
    internalIndex: number;
    form: FormikProps<TagForm>;
}

const PREFIX = 'TagValue';

const classes = {
    root: `${PREFIX}-root`,
    dragIcon: `${PREFIX}-dragIcon`,
    iconContainer: `${PREFIX}-iconContainer`
};

const StyledBox = styled(Box)(({ theme }) => ({
    zIndex: 999999,
    backgroundColor: theme.palette.background.paper,
    [`& .${classes.dragIcon}`]: {
        cursor: 'pointer'
    },
    [`& .${classes.iconContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const TagValueForm: React.FC<TagValueFormProps> = ({ remove, internalIndex, form }) => {
    const removeItem = React.useCallback(() => {
        remove(internalIndex);
    }, [internalIndex, remove]);

    return (
        <StyledBox zIndex={999999}>
            <Grid container spacing={2}>
                <Grid item xs={1} className={classes.iconContainer}>
                    <CustomDragHandle className={classes.dragIcon} />
                </Grid>
                <Grid item xs={2}>
                    <TextFormField type="number" name={`values[${internalIndex}].id`} min={0} />
                </Grid>
                <Grid item xs={2}>
                    <TextFormField type="number" name={`values[${internalIndex}].sort`} min={0} />
                </Grid>
                <Grid item xs={4}>
                    <TextFormField name={`values[${internalIndex}].title`} />
                </Grid>

                <Grid item xs={2}>
                    <TagsImagePiker
                        index={internalIndex}
                        name={`values[${internalIndex}].imageUrl`}
                        setFieldValue={form.setFieldValue}
                        values={form.values?.values}
                    />
                </Grid>
                <Grid item xs={1} className={classes.iconContainer}>
                    <IconButton onClick={removeItem} size="large">
                        <DeleteIcon color="error" />
                    </IconButton>
                </Grid>
            </Grid>
        </StyledBox>
    );
};

const SortableItem = sortableElement((props: TagValueFormProps) => <TagValueForm {...props} />);

export const TagValuesForm: React.FC<TagValuesFormProps> = ({ form, remove, move, push }) => {
    const mapFields = React.useCallback(
        (_: TagForm, index: number) => (
            <SortableItem
                key={`sortable-${index}`}
                index={index}
                internalIndex={index}
                remove={remove}
                form={form}
            />
        ),
        [form, remove]
    );

    const handleSortEnd = React.useCallback(
        ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            move(oldIndex, newIndex);
        },
        [move]
    );

    const handleAddItem = React.useCallback(() => {
        push({ id: '', title: '', sort: '' });
    }, [push]);

    if (Array.isArray(form.values?.values)) {
        return (
            <SortableContainer useDragHandle lockAxis="y" onSortEnd={handleSortEnd}>
                <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={2}>
                        <Typography align="center">ID</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align="center">Sort</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="center">Title</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align="center">Icon</Typography>
                    </Grid>
                </Grid>
                {form.values.values.map(mapFields)}
                <Button color="secondary" variant="outlined" onClick={handleAddItem}>
                    <Add />
                </Button>
            </SortableContainer>
        );
    }
};

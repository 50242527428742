import React from 'react';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from 'ui/buttons/IconButton';
import { Column, Row } from 'ui/Flex';
import { IAwardEnrichment, IAwardEnrichmentCreate } from 'components/loyalty/models/AwardEnrichment';
import {
    awardGeneralDefaultFormValues,
    AwardGeneralForm,
    AwardGeneralFormData
} from './forms/AwardGeneralForm';
import { AwardConfigureForm, AwardConfigureFormData } from './forms/AwardConfigureForm';
import { AwardRedemptionForm, AwardRedemptionFormData } from './forms/AwardRedemptionForm';
import { AwardPreviewForm } from './forms/AwardPreviewForm';
import { awardEnrichmentApi } from 'components/loyalty/AwardEnrichmentsApi';
import { LoyaltyProvider } from 'components/loyalty/enums';
import { getAwardEnrichmentCreateData } from './utils';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { useDispatch } from 'react-redux';
import {
    MESSAGE_MARKETING_AWARD_CREATE_ERROR,
    MESSAGE_MARKETING_AWARD_CREATE_SUCCESS
} from 'config/messages';

export interface AwardCreationStepperProps {
    onClose: () => void;
    onSubmit: (award: IAwardEnrichment) => void;
    currencySymbol: string;
    initialGeneralData?: Partial<AwardGeneralFormData>;
    loyaltyProvider: LoyaltyProvider;
}

export const AwardCreationStepper: React.FC<AwardCreationStepperProps> = ({
    onClose,
    onSubmit,
    initialGeneralData,
    currencySymbol,
    loyaltyProvider
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [steps] = React.useState(['Define', 'Configure', 'Redemption', 'Review']);
    const [activeStep, setActiveStep] = React.useState(0);
    const [generalData, setGeneralData] = React.useState<AwardGeneralFormData>({
        ...awardGeneralDefaultFormValues,
        ...initialGeneralData
    });
    const [configureData, setConfigureData] = React.useState<AwardConfigureFormData>();
    const [redemptionData, setRedemptionData] = React.useState<AwardRedemptionFormData>();
    const handleStepClick = React.useCallback(
        (index: number) => () => {
            setActiveStep(index);
        },
        []
    );

    const handleGeneralSubmit = React.useCallback((values: AwardGeneralFormData) => {
        setGeneralData(values);
        setActiveStep(1);
    }, []);
    const handleConfigureSubmit = React.useCallback((values: AwardConfigureFormData) => {
        setConfigureData(values);
        setActiveStep(2);
    }, []);
    const handleRedemptionSubmit = React.useCallback((values: AwardRedemptionFormData) => {
        setRedemptionData(values);
        setActiveStep(3);
    }, []);
    const handlePrevious = React.useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const handleCreatePerk = React.useCallback(async () => {
        setIsLoading(true);
        const body: IAwardEnrichmentCreate = getAwardEnrichmentCreateData(
            {
                ...generalData,
                ...configureData,
                ...redemptionData
            },

            loyaltyProvider
        );

        try {
            const result = await awardEnrichmentApi.create({
                body
            });

            if (result.ok) {
                dispatch(enqueueSnackbar(MESSAGE_MARKETING_AWARD_CREATE_SUCCESS, { variant: 'success' }));
                return onSubmit(result.body);
            }
            throw Error(result.body);
        } catch (e) {
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_AWARD_CREATE_ERROR, { variant: 'error' }));
        } finally {
            setIsLoading(false);
        }
    }, [configureData, dispatch, generalData, loyaltyProvider, onSubmit, redemptionData]);

    return (
        <Box width="100%" maxWidth={1600}>
            <Column>
                <Row valign="flex-start" align="center">
                    <Box flex={1} maxWidth={500}>
                        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                            {steps.map((item, index) => (
                                <Step key={`step-button-${item}`} completed={activeStep > index}>
                                    <StepButton
                                        disableRipple
                                        disabled={activeStep < index}
                                        onClick={handleStepClick(index)}
                                        color="inherit"
                                    >
                                        <StepLabel>{item}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton size="large">
                            <CloseIcon onClick={onClose} />
                        </IconButton>
                    </Box>
                </Row>
                <AwardGeneralForm
                    open={activeStep === 0}
                    initialValues={generalData}
                    onSubmit={handleGeneralSubmit}
                />
                <AwardConfigureForm
                    open={activeStep === 1}
                    initialValues={configureData}
                    onSubmit={handleConfigureSubmit}
                    clickPrevious={handlePrevious}
                    currencySymbol={currencySymbol}
                    title={generalData && generalData.title}
                />
                <AwardRedemptionForm
                    open={activeStep === 2}
                    initialValues={redemptionData}
                    onSubmit={handleRedemptionSubmit}
                    clickPrevious={handlePrevious}
                    auto={configureData?.auto}
                />
                <AwardPreviewForm
                    open={activeStep === 3}
                    data={{ ...generalData, ...configureData }}
                    submit={handleCreatePerk}
                    clickPrevious={handlePrevious}
                    isLoading={isLoading}
                    label="Link Award"
                />
            </Column>
        </Box>
    );
};

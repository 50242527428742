import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Grid, Box } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { voucherApi } from 'components/vouchers/voucherApi';
import { VoucherView } from 'components/vouchers/VoucherView';
import { MARKETING_VOUCHERS } from 'config/routes';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import { Voucher } from 'components/vouchers/models/VoucherModel';

const VouchersViewPage = () => {
    const [voucher, setVoucher] = React.useState<Voucher>(null);
    const history = useHistory();
    const { voucherId } = useParams<{ voucherId: string }>();

    React.useEffect(() => {
        const load = async () => {
            try {
                if (!isString(voucherId)) {
                    logger.error(`Expected voucherId but got ${voucherId}`);
                    history.push(MARKETING_VOUCHERS);
                }
                const voucherResponse = await voucherApi.get(voucherId);
                if (!voucherResponse.ok) {
                    logger.error('Expected voucherResponse 200 but got ', voucherResponse);
                    history.push(MARKETING_VOUCHERS);
                }
                const newVoucher = voucherResponse.body;

                setVoucher(newVoucher);
            } catch (error) {
                logger.error('Voucher view page error', error);
                history.push(MARKETING_VOUCHERS);
                setVoucher(null);
            }
        };

        load();
    }, [history, voucherId]);

    const handleEditSuccess = React.useCallback((updatedVoucher: Voucher) => {
        setVoucher(old => ({ ...old, ...updatedVoucher }));
    }, []);

    if (!voucher) {
        return (
            <MainLayout
                breadcrumbs={[{ label: 'Vouchers', url: MARKETING_VOUCHERS }]}
                pageName={'Voucher Details'}
                pageDescription="View more information on your voucher"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <SkeletonComponent height="36px" width="136px" count={1} variant="rectangular" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SkeletonComponent height="350px" count={1} variant="rectangular" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SkeletonComponent height="350px" count={1} variant="rectangular" />
                    </Grid>
                </Grid>
            </MainLayout>
        );
    }

    return (
        <MainLayout
            breadcrumbs={[{ label: 'Vouchers', url: MARKETING_VOUCHERS }]}
            pageName={voucher.title || 'Voucher Details'}
            pageDescription="View more information on your voucher"
        >
            <VoucherView onEdit={handleEditSuccess} voucher={voucher} />
        </MainLayout>
    );
};

export default VouchersViewPage;

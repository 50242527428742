import { getCurrencyString } from 'lib/helpers';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export const useCurrencyString = (currencyCode?: string) => {
    const region = useSelector((state: ApplicationState) => state.settings.settings?.region);
    const getCurrencyStringWrapper = React.useCallback(
        (value: number) => getCurrencyString(value, currencyCode ?? region?.currencyCode),
        [currencyCode, region?.currencyCode]
    );

    return getCurrencyStringWrapper;
};

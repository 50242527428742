import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';
import { EOrderPaymentType } from '../model/Order';

interface PaymentRefundConfirmationDialogProps {
    onClose: () => void;
    onAgree: () => void;
    open: boolean;
    loading?: boolean;
    amount?: string;
    paymentType?: EOrderPaymentType;
}

const paymentTypeString: Record<string, string> = {
    CARDHOLDER_PRESENT: 'Quickpad',
    CASH: 'cash',
    CARD_ON_FILE: 'card',
    GOOGLEPAY: 'Google Pay',
    APPLEPAY: 'Apple Pay',
    CARD: 'card',
    GIFT_CARD: 'giftcard'
};

const PaymentRefundConfirmationDialogContent: React.FC<PaymentRefundConfirmationDialogProps> = props => {
    const { onClose, onAgree, loading, amount, paymentType } = props;
    const confirmationText = React.useMemo(() => {
        if (!!amount && paymentType && paymentTypeString[paymentType]) {
            return `Are you sure you want to refund this ${paymentTypeString[paymentType]} payment of ${amount}?`;
        }
        return 'Are you sure you want to refund this order?';
    }, [amount, paymentType]);
    return (
        <React.Fragment>
            <Typography variant="h5" color="primary">
                Refund Confirmation
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{confirmationText}</Typography>
            <Divider sx={{ my: 2 }} />
            <Box justifyContent="flex-end" sx={{ width: '100%', display: 'flex' }}>
                <Button
                    sx={{ mr: 2.25 }}
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={onAgree}
                >
                    Refund
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export const PaymentRefundConfirmationDialog: React.FC<PaymentRefundConfirmationDialogProps> = props => {
    const { onClose, open } = props;
    return (
        <MuiModal open={open} onClose={props.loading ? undefined : onClose}>
            <PaymentRefundConfirmationDialogContent {...props} />
        </MuiModal>
    );
};

import * as React from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Divider, styled } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import clsx from 'clsx';
import { getFullHex } from 'lib/helpers';
import { IconButton } from 'ui/buttons/IconButton';
import { Row } from 'ui/Flex';

const PREFIX = 'ui-Panel';

const classes = {
    card: `${PREFIX}-card`,
    panelLoading: `${PREFIX}-panelLoading`,
    subhead: `${PREFIX}-subhead`,
    panelContent: `${PREFIX}-panelContent`,
    headerRoot: `${PREFIX}-headerRoot`
};

const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.card}`]: {
        position: 'relative',
        padding: theme.spacing(1, 2),
        transition: 'height 0.3s',
        overflow: 'visible'
    },
    [`& .${classes.panelLoading}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: `${getFullHex(theme.palette.common.white)}BB`
    },
    [`& .${classes.subhead}`]: {
        textTransform: 'none',
        fontSize: theme.spacing(1.5),
        lineHeight: theme.spacing(2.25),
        opacity: 0.7,
        transition: '0.3s'
    },
    [`& .${classes.panelContent}`]: {
        padding: theme.spacing(1, 0, 1.25, 0),
        '&:last-child': {
            paddingBottom: 0
        }
    },
    [`& .${classes.headerRoot}`]: {
        padding: theme.spacing(1.5),
        paddingLeft: 0,
        paddingRight: theme.spacing(0.5)
    }
}));

export interface ActionComponentProps {
    onClick: () => void;
    loading?: boolean;
}

interface PanelProps {
    onClick?: () => void;
    divider?: boolean;
    showAction?: boolean;
    loading?: boolean;
    header?: boolean;
    isLoading?: boolean;
    title?: string;
    subhead?: string;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    actionComponentRender?: (props: ActionComponentProps) => React.ReactElement<ActionComponentProps>;
}

const Panel: React.FC<PanelProps> = props => {
    const {
        title,
        subhead,
        loading,
        onClick,
        showAction,
        divider,
        header,
        actionComponentRender,
        isLoading,
        className
    } = props;
    return (
        <StyledCard className={clsx(classes.card, className)}>
            {/* tslint:disable-next-line */}
            {header !== false && (
                <React.Fragment>
                    <CardHeader
                        classes={{
                            root: classes.headerRoot,
                            subheader: classes.subhead
                        }}
                        action={
                            showAction && onClick && actionComponentRender({ onClick, loading: isLoading })
                        }
                        titleTypographyProps={{ variant: 'h6' }}
                        title={title}
                        subheader={subhead}
                    />
                    {divider && <Divider component="div" />}
                </React.Fragment>
            )}
            <CardContent className={classes.panelContent}>{props.children}</CardContent>
            {loading && (
                <Row className={classes.panelLoading} align="center" valign="center">
                    <CircularProgress />
                </Row>
            )}
        </StyledCard>
    );
};

const defaultActionComponentRender = (props: ActionComponentProps) => (
    <IconButton {...props} size="large">
        <Edit />
    </IconButton>
);

Panel.defaultProps = {
    showAction: true,
    actionComponentRender: defaultActionComponentRender
};

export { Panel };

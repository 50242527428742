import { Crud, HttpClient, httpClient, QueryParameter, RequestOptions } from 'lib/HttpClient';

class CustomerApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'users', { 'x-api-version': 4 });
    }
    getUser(id: string, queryParameters?: QueryParameter[]) {
        return this.httpClient.get(`${this.api}/users/${id}`, {
            queryParameters,
            headers: { 'x-api-version': 4 }
        });
    }
    getAwards(id: string) {
        return this.httpClient.get(`${this.api}/users/${id}/pointawards`, {
            headers: { 'x-api-version': 4 }
        });
    }
    credit(userId: string, options: RequestOptions) {
        return this.httpClient.post(`${this.api}/users/${userId}/credits`, {
            ...options,
            headers: { 'x-api-version': 4 }
        });
    }
    redeem(userId: string, awardId: string, options: RequestOptions) {
        return this.httpClient.put(`${this.api}/users/${userId}/pointawards/${awardId}`, {
            ...options,
            headers: { 'x-api-version': 4 }
        });
    }
    notify(userId: string, options: RequestOptions) {
        return this.httpClient.post(`${this.api}/users/${userId}/notifications`, {
            ...options,
            headers: { 'x-api-version': 4 }
        });
    }
    deleteUser(userId: string, options: RequestOptions) {
        return this.httpClient.delete(`${this.api}/users/${userId}`, {
            ...options,
            headers: { 'x-api-version': 4 }
        });
    }
    adminInvalidatePassword(id: string) {
        // admin context means password and token field values are ignored by api-server
        return this.httpClient.post(`${this.api}/users/reset`, {
            body: {
                id,
                password: '',
                token: ''
            },
            headers: { 'x-api-version': 4 }
        });
    }
}

export const customerApi = new CustomerApi(httpClient);

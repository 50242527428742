import { ActionCreator } from 'redux';
import uiActionTypes from './uiActionTypes';

export interface TableColumnsChangeAction {
    type: string;
    selectedColumns: string[];
}

const updateSelectedColumns = (selectedColumns: string[], column: string, isChecked: boolean) => {
    let updatedSelectedColumns;
    if (isChecked) {
        updatedSelectedColumns = [...selectedColumns, column];
    } else {
        updatedSelectedColumns = selectedColumns.filter(
            (selectedColumn: string) => selectedColumn !== column
        );
    }
    return updatedSelectedColumns;
};

export const changeLocationsTableSelectedColumns =
    (column: string, isChecked: boolean, locationsTableSelectedColumns: string[]) =>
    (dispatch: ActionCreator<TableColumnsChangeAction>) => {
        dispatch({
            selectedColumns: updateSelectedColumns(locationsTableSelectedColumns, column, isChecked),
            type: uiActionTypes.LOCATIONS_TABLE_SELECTED_COLUMNS_CHANGE
        });
    };

export const changeCustomersTableSelectedColumns =
    (column: string, isChecked: boolean, locationsTableSelectedColumns: string[]) =>
    (dispatch: ActionCreator<TableColumnsChangeAction>) => {
        dispatch({
            selectedColumns: updateSelectedColumns(locationsTableSelectedColumns, column, isChecked),
            type: uiActionTypes.CUSTOMERS_TABLE_SELECTED_COLUMNS_CHANGE
        });
    };

export const changePerksTableSelectedColumns =
    (column: string, isChecked: boolean, locationsTableSelectedColumns: string[]) =>
    (dispatch: ActionCreator<TableColumnsChangeAction>) => {
        dispatch({
            selectedColumns: updateSelectedColumns(locationsTableSelectedColumns, column, isChecked),
            type: uiActionTypes.PERKS_TABLE_SELECTED_COLUMNS_CHANGE
        });
    };

export const changeAudiencesTableSelectedColumns =
    (column: string, isChecked: boolean, locationsTableSelectedColumns: string[]) =>
    (dispatch: ActionCreator<TableColumnsChangeAction>) => {
        dispatch({
            selectedColumns: updateSelectedColumns(locationsTableSelectedColumns, column, isChecked),
            type: uiActionTypes.AUDIENCES_TABLE_SELECTED_COLUMNS_CHANGE
        });
    };

export const changeMerchantsTableSelectedColumns =
    (column: string, isChecked: boolean, locationsTableSelectedColumns: string[]) =>
    (dispatch: ActionCreator<TableColumnsChangeAction>) => {
        dispatch({
            selectedColumns: updateSelectedColumns(locationsTableSelectedColumns, column, isChecked),
            type: uiActionTypes.MERCHANTS_TABLE_SELECTED_COLUMNS_CHANGE
        });
    };

export const changeOrdersTableSelectedColumns =
    (column: string, isChecked: boolean, ordersTableSelectedColumns: string[]) =>
    (dispatch: ActionCreator<TableColumnsChangeAction>) => {
        dispatch({
            selectedColumns: updateSelectedColumns(ordersTableSelectedColumns, column, isChecked),
            type: uiActionTypes.ORDERS_TABLE_SELECTED_COLUMNS_CHANGE
        });
    };

import { IPagedCollection } from 'lib/api/paging';
import {
    Crud,
    HttpClient,
    httpClient,
    HttpClientResponse,
    QueryParameter,
    RequestOptions
} from 'lib/HttpClient';
import { AwardListRequestOptions, IAwardEnrichment } from './models/AwardEnrichment';
class AwardEnrichmentApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'awardenrichments');
    }

    getAllAwardEnrichments(
        options?: Partial<AwardListRequestOptions>
    ): HttpClientResponse<IPagedCollection<IAwardEnrichment>> {
        const queryParameters: QueryParameter[] = [];
        const defaultPageLimit = 20;

        if (options.startKey) {
            queryParameters.push({ value: options.startKey, key: 'startKey' });
        }

        queryParameters.push({ value: options.limit || defaultPageLimit, key: 'limit' });

        return this.getList({
            queryParameters
        });
    }

    deleteAward(id: string, options?: RequestOptions) {
        return this.delete(id, options);
    }
}

export const awardEnrichmentApi = new AwardEnrichmentApi(httpClient);

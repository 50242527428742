import { Theme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { INotificationReceipt } from './cards/NotificationReceiptsCard';
import { Notification } from './models/Notification';

export function mapNotificationStatusToStyles(
    status: Notification<string>['status'],
    theme: Theme
): { backgroundColor: string; color: string } {
    switch (status) {
        case 'DONE':
            return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
        case 'PENDING':
            return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
        case 'ERROR':
            return { backgroundColor: red['500'], color: theme.palette.primary.contrastText };
    }
}

export function mapNotificationReceiptToStyles(
    notificationReceipt: INotificationReceipt,
    theme: Theme
): { backgroundColor: string; color: string } {
    if (!!notificationReceipt.errorMessage) {
        return { backgroundColor: red['500'], color: theme.palette.primary.contrastText };
    }
    if (!!notificationReceipt.skipMessage) {
        return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
    }
    return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
}

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useHistorySearch() {
    const { replace } = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get('search');

    const onSearch = React.useCallback(
        (phrase: string) => {
            if (phrase !== search) {
                replace(
                    phrase.length
                        ? `${location.pathname}?search=${encodeURIComponent(phrase)}`
                        : location.pathname
                );
            }
        },
        [location.pathname, replace, search]
    );

    return { search, onSearch };
}

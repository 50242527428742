import React from 'react';
import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from 'react-sortable-hoc';
import { Button, IconButton, ListItem, styled, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import DragHandle from '@mui/icons-material/DragHandle';
import MoreVert from '@mui/icons-material/MoreVert';
import { arrayMove, removeArrayItem, titleize, updateArrayItem } from 'lib/helpers';
import { AutoFormFields, LegacyForm } from 'lib/LegacyForm';
import { Row } from 'ui/Flex';
import { MenuOn } from 'ui/MenuOn';
import { MuiModal } from 'ui/MuiModal';
import { AutoForm } from '../AutoForm';

const PREFIX = 'ItemField';

const classes = {
    dragHandle: `${PREFIX}-dragHandle`
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: '#fffd',
    padding: theme.spacing(0.5),
    zIndex: 1300,
    '&:hover': {
        backgroundColor: '#eeed'
    },
    [`& .${classes.dragHandle}`]: {
        cursor: 'pointer',
        height: '1em',
        width: '1em',
        flexBasis: '5%',
        marginRight: '30px'
    }
}));

const SORTABLE_PREFIX = 'SortableFormField';

const sortableClasses = {
    root: `${SORTABLE_PREFIX}-root`,
    head: `${SORTABLE_PREFIX}-head`
};

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    minWidth: theme.spacing(48)
}));

interface ItemFieldProps<Model> {
    item: Model;
    index: number;
    onEditItem: (index: number) => void;
    onDeleteItem: (index: number) => void;
    fields: Field[];
    internalIndex?: number;
}

const CustomDragHandle = sortableHandle(({ className }: { className: string }) => (
    <DragHandle className={className} />
));

const SortableItem = sortableElement((props: ItemFieldProps<any>) => <ItemField {...props} />);
const SortableContainer = sortableContainer(({ children }: { children: React.ReactNodeArray }) => (
    <ul>{children}</ul>
));

const ItemField: React.FC<ItemFieldProps<any>> = props => {
    const { fields, item, onEditItem, onDeleteItem, internalIndex } = props;
    const menuItems = [
        {
            label: 'Edit',
            onClick: () => {
                onEditItem(internalIndex);
            }
        },
        {
            label: 'Delete',
            onClick: () => {
                onDeleteItem(internalIndex);
            }
        }
    ];
    const renderOn = React.useCallback(
        (toggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => (
            <IconButton onClick={toggle} size="large">
                <MoreVert />
            </IconButton>
        ),
        []
    );
    return (
        <StyledListItem>
            <Row flex={1} align="space-between" valign="center">
                <CustomDragHandle className={classes.dragHandle} />
                {fields.map(field => (
                    <Typography
                        key={`sortable-item-${internalIndex}-${field.key}`}
                        style={{ flexBasis: field.width }}
                    >
                        {titleize((item[field.key] ?? '').replaceAll('_', ' '), true)}
                    </Typography>
                ))}
                <div style={{ flexBasis: '10%' }}>
                    <MenuOn items={menuItems} renderOn={renderOn} />
                </div>
            </Row>
        </StyledListItem>
    );
};

interface FormComponent<Model> {
    value?: Partial<Model>;
    onSubmit: (value: Partial<Model>) => void;
    close: () => void;
    formTitle: string;
}

interface Field {
    width: string;
    label: string;
    key: string;
}

interface SortableFieldProps<Model> {
    value?: Model[];
    onChange: (value: Partial<Model>[]) => void;
    fields: Field[];
    itemForm: AutoFormFields;
    FormComponent?: React.FunctionComponent<FormComponent<Model>>;
}

export function SortableField<Model extends { index: number }>(props: SortableFieldProps<Model>) {
    const { value, onChange, fields, itemForm, FormComponent } = props;
    const [openEdit, setEdit] = React.useState(false);
    const [openAdd, setAdd] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(-1);
    const currentItem: Partial<Model> = value[currentIndex];
    const handleSortEnd = React.useCallback(
        ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            onChange(arrayMove(value, oldIndex, newIndex));
        },
        [onChange, value]
    );
    const handleDeleteItem = React.useCallback(
        (index: number) => {
            onChange(removeArrayItem(value, index));
        },
        [onChange, value]
    );
    const handleSubmit = React.useCallback(
        (item: Partial<Model>) => {
            const arr = updateArrayItem(value, currentIndex, item);
            // Fix index (removed by update or add form because an index doesn't exist as a hidden input in the form)
            arr[currentIndex].index = currentIndex + 1;
            onChange(arr);
        },
        [value, currentIndex, onChange]
    );
    const handleEditItem = React.useCallback((index: number) => {
        setCurrentIndex(index);
        setEdit(true);
    }, []);
    const handleAddItem = React.useCallback(() => {
        setCurrentIndex(value.length);
        setAdd(true);
    }, [value.length]);
    const handleClose = React.useCallback(() => {
        setEdit(false);
        setAdd(false);
        setCurrentIndex(-1);
    }, []);
    const renderValue = (item: Model, index: number) => (
        // UPGRADING react-sortable-hoc past 1.9.1 MAY BREAK SORTABLE ITEM
        // https://github.com/clauderic/react-sortable-hoc/issues/612
        <SortableItem
            fields={fields}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            key={`primary-action-${index}`}
            item={item}
            index={index}
            internalIndex={index}
        />
    );
    return (
        <React.Fragment>
            <SortableContainer useDragHandle lockAxis="y" onSortEnd={handleSortEnd}>
                <Row flex={1} style={{ padding: 8 }}>
                    <div style={{ flexBasis: '5%', marginRight: '30px' }} />
                    {fields &&
                        fields.map(field => (
                            <Typography
                                sx={{ fontSize: '0.75rem', flexBasis: field.width }}
                                key={`sortable-title-${field.key}`}
                            >
                                {field.label}
                            </Typography>
                        ))}
                    <div style={{ flexBasis: '10%' }} />
                </Row>
                {value && value.map(renderValue)}
                <Button color="secondary" variant="outlined" onClick={handleAddItem}>
                    <Add />
                </Button>
            </SortableContainer>
            <StyledMuiModal className={sortableClasses.root} open={openEdit} onClose={handleClose}>
                {FormComponent ? (
                    <FormComponent
                        formTitle="Edit item"
                        close={handleClose}
                        value={currentItem}
                        onSubmit={handleSubmit}
                    />
                ) : (
                    <ItemForm
                        itemForm={itemForm}
                        formTitle="Edit item"
                        close={handleClose}
                        value={currentItem}
                        onSubmit={handleSubmit}
                    />
                )}
            </StyledMuiModal>
            <StyledMuiModal className={sortableClasses.root} open={openAdd} onClose={handleClose}>
                {FormComponent ? (
                    <FormComponent
                        formTitle="Add item"
                        close={handleClose}
                        value={currentItem}
                        onSubmit={handleSubmit}
                    />
                ) : (
                    <ItemForm
                        itemForm={itemForm}
                        formTitle="Add item"
                        close={handleClose}
                        onSubmit={handleSubmit}
                    />
                )}
            </StyledMuiModal>
        </React.Fragment>
    );
}

interface ItemFormProps<Model> {
    value?: Partial<Model>;
    onSubmit: (value: Partial<Model>) => void;
    close: () => void;
    formTitle: string;
    itemForm: AutoFormFields;
}

class ItemForm<Model> extends React.PureComponent<ItemFormProps<Model>> {
    form = new LegacyForm(this.props.itemForm, data => {
        this.props.onSubmit(data);
        this.props.close();
    }).update(this.props.value || {});
    render() {
        const { formTitle } = this.props;
        return <AutoForm form={this.form} title={formTitle} />;
    }
}

import { Box, Grid, Paper, Typography } from '@mui/material';
import { titleize } from 'lib/helpers';
import React from 'react';
import { PepperTransaction } from './model/PepperPay';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';

interface PepperPayChargeBlockProps {
    transaction?: PepperTransaction;
}

export const PepperPayChargeBlock: React.FC<PepperPayChargeBlockProps> = ({ transaction }) => (
    <Paper sx={{ height: '100%' }}>
        <Box padding={2}>
            <Typography variant="h6" gutterBottom>
                Charge
            </Typography>
            <Grid container spacing={2}>
                {transaction.paymentIntent?.paymentMethodBrand && (
                    <Grid item xs={12}>
                        <Typography variant="body2">Card Type</Typography>
                        <Typography variant="body1">
                            {titleize(transaction.paymentIntent?.paymentMethodBrand, true)}
                        </Typography>
                    </Grid>
                )}
                {transaction.paymentIntent?.lastFour && (
                    <Grid item xs={12}>
                        <Typography variant="body2">Last 4 digits</Typography>
                        <Typography variant="body1">{transaction.paymentIntent?.lastFour}</Typography>
                    </Grid>
                )}
                {!!transaction.paymentIntent?.expiryDate && (
                    <Grid item xs={12}>
                        <Typography variant="body2">Expiry Date</Typography>
                        <Typography variant="body1">{transaction.paymentIntent?.expiryDate}</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="body2">3D Secure</Typography>
                    {transaction.charge?.threeDSecure ? <Done /> : <Close />}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">Charge ID</Typography>
                    <Typography variant="body1">{transaction.charge?.chargeId}</Typography>
                </Grid>
            </Grid>
        </Box>
    </Paper>
);

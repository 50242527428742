import { isBoolean, isDefined, isOptional, isString } from 'lib/typeguards';

export enum OperationStatus {
    PENDING = 'PENDING',
    DONE = 'DONE',
    ERROR = 'ERROR',
    TIMEOUT = 'TIMEOUT',
    RUNNING = 'RUNNING'
}

export function isOperationStatus(status: any): status is OperationStatus {
    return status in OperationStatus;
}

export interface IOperationResult<IDType> {
    id: IDType;
    status: OperationStatus;
    etaMs: number;
    href?: string;
    resultHref?: string;
    resultId?: number;
    errorMessage?: string;
    errorCode?: string;
    isRetryable?: boolean;
}

export interface IOperationLinks {
    self: string;
    suboperations: string;
    retry?: string;
}

export function isOperationLinks(links: any): links is IOperationLinks {
    return (
        isDefined(links) &&
        isString(links.self) &&
        isString(links.suboperations) &&
        isOptional(isString, links.retry)
    );
}

export interface IOperationV2<Id, Metadata, Result, Error> {
    _id: Id;
    tenantId: string;
    namespace: string;
    entityId: string;
    name: string;
    nonce: string;
    status: OperationStatus;
    metadata: Metadata;
    result?: Result;
    error?: Error;
    isRetryable?: boolean;
    createdAt: string;
    updatedAt: string;
    endedAt?: string;
    links: IOperationLinks;
}

export function isOperationV2<Id, Metadata, Result, Error>(
    isId: (id: any) => id is Id,
    isMetadata: (metadata: any) => metadata is Metadata,
    isResult: (result: any) => result is Result,
    isError: (error: any) => error is Error,
    operation: any
): operation is IOperationV2<Id, Metadata, Result, Error> {
    return (
        isDefined(operation) &&
        isId(operation._id) &&
        isString(operation.tenantId) &&
        isString(operation.namespace) &&
        isString(operation.entityId) &&
        isString(operation.name) &&
        isString(operation.nonce) &&
        isOperationStatus(operation.status) &&
        isMetadata(operation.metadata) &&
        isOptional(isResult, operation.result) &&
        isOptional(isError, operation.error) &&
        isOptional(isBoolean, operation.isRetryable) &&
        isString(operation.createdAt) &&
        isString(operation.updatedAt) &&
        isOptional(isString, operation.endedAt) &&
        isOperationLinks(operation.links)
    );
}

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import { PasswordStrengthFormField } from 'lib/form/fields/PasswordStrengthFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';

interface SignUpFormData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    agreedToMarketing: boolean;
    agreedToShareData: boolean;
}

interface SignUpFormProps {
    onSubmit: (data: SignUpFormData) => void;
    error?: string;
}

const initialValues: SignUpFormData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreedToMarketing: false,
    agreedToShareData: false
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required.'),
    lastName: Yup.string().required('This field is required.'),
    email: Yup.string().email('Invalid email').required('This field is required.'),
    password: Yup.string()
        .min(8, 'Password should contain at least 8 characters')
        .required('This field is required.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match.')
        .required('This field is required.'),
    agreedToShareData: Yup.bool().oneOf([true], 'This field must be checked.')
});

export const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit, error }) => (
    <MuiForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ submitForm, isSubmitting }) => (
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            Lets get started...
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            label={<MuiFormLabel required>First Name</MuiFormLabel>}
                            name="firstName"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            name="lastName"
                            label={<MuiFormLabel required>Last Name</MuiFormLabel>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField
                            name="email"
                            type="email"
                            label={<MuiFormLabel required>Email</MuiFormLabel>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PasswordStrengthFormField
                            name="password"
                            label={<MuiFormLabel required>Password</MuiFormLabel>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            name="confirmPassword"
                            type="password"
                            label={<MuiFormLabel required>Confirm Password</MuiFormLabel>}
                            description="Confirm Your Password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxFormField
                            name="agreedToMarketing"
                            label="You agree to be contacted about Pepper products, services, news and offers"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxFormField
                            name="agreedToShareData"
                            label={
                                <MuiFormLabel required>
                                    You agree to{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${process.env.MEDIA_SERVICE_URL}/documentation/console_tos.pdf`}
                                    >
                                        Peppers Terms of Service
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${process.env.MEDIA_SERVICE_URL}/documentation/console_privacy.pdf`}
                                    >
                                        Data Privacy Policy
                                    </a>
                                </MuiFormLabel>
                            }
                        />
                    </Grid>
                    {!!error && (
                        <Grid item xs={12}>
                            <Typography color="error" data-cy="loginErrorLabel">
                                {error}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Row flex={1} align="flex-end">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                onClick={submitForm}
                            >
                                Sign Up
                            </LoadingButton>
                        </Row>
                    </Grid>
                </Grid>
            </Form>
        )}
    </MuiForm>
);

import { Theme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { TenantAccessState } from './models/TenantAccess';

export function mapTenantAccessStateToStyles(
    state: TenantAccessState,
    theme: Theme
): { backgroundColor: string; color: string } {
    switch (state) {
        case TenantAccessState.ACTIVE:
            return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
        case TenantAccessState.PENDING:
            return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
        case TenantAccessState.REVOKED:
        default:
            return { backgroundColor: red['500'], color: theme.palette.primary.contrastText };
    }
}

import { CardBrand } from 'components/pepper-pay/model/PepperPay';
import React from 'react';
import AmexImage from 'static/card-brands/amex.png';
import VisaImage from 'static/card-brands/visa.png';
import MastercardImage from 'static/card-brands/mastercard.png';
import DinersImage from 'static/card-brands/diners.png';
import DiscoverImage from 'static/card-brands/discover.png';
import JCBImage from 'static/card-brands/jcb.png';
import UnionImage from 'static/card-brands/union.png';
import GooglePay from 'static/card-brands/google-pay.svg';
import ApplePay from 'static/card-brands/apple-pay.svg';
import { Row } from 'ui/Flex';
import { styled } from '@mui/material';
import clsx from 'clsx';

const PREFIX = 'CardBrandIcon';

const classes = {
    root: `${PREFIX}-root`,
    medium: `${PREFIX}-medium`
};

const StyledRow = styled(Row)(({ theme }) => ({
    [`& .${classes.root}`]: {
        height: theme.spacing(3),
        width: 'auto'
    },
    [`& .${classes.medium}`]: {
        height: theme.spacing(5)
    }
}));

interface CardBrandIconProps {
    brand: CardBrand;
    walletType?: string;
    size?: 'medium' | 'small';
}

const brandImage: Record<CardBrand, string> = {
    visa: VisaImage,
    mastercard: MastercardImage,
    diners: DinersImage,
    discover: DiscoverImage,
    jcb: JCBImage,
    unionpay: UnionImage,
    amex: AmexImage,
    unknown: ''
};

const getWalletSrc = (value?: string) => {
    switch (value) {
        case 'google_pay':
            return GooglePay;
        case 'apple_pay':
            return ApplePay;
        default:
            return undefined;
    }
};

export const CardBrandIcon: React.FC<CardBrandIconProps> = ({ brand, walletType, size = 'small' }) => {
    const walletImage = getWalletSrc(walletType);
    const imageClassNames = clsx(classes.root, {
        [classes.medium]: size === 'medium'
    });
    return (
        <StyledRow>
            {walletImage && <img src={walletImage} className={imageClassNames} alt={walletImage} />}
            <img src={brandImage[brand]} className={imageClassNames} alt={brand} />
        </StyledRow>
    );
};

import * as React from 'react';
import { Box } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { OrderScenario, OrderScenarioLabels } from 'components/order/model/Order';
import { REQUIRED_ERROR_MESSAGE } from 'config/messages';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal, MuiModalProps } from 'ui/MuiModal';
import { LocationOrderNote } from '../models/LocationModel';

export interface ILocationOrderNoteForm {
    scenario: OrderScenario;
    title: string;
    description: string;
}
const scenarios = [
    { label: OrderScenarioLabels[OrderScenario.PREORDER], value: OrderScenario.PREORDER },
    { label: OrderScenarioLabels[OrderScenario.ORDER_TO_TABLE], value: OrderScenario.ORDER_TO_TABLE }
];

const validationSchema = Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    description: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    scenario: Yup.mixed().oneOf([OrderScenario.ORDER_TO_TABLE, OrderScenario.PREORDER])
});

const LocationOrderNoteForm: React.FC = () => {
    const { submitForm } = useFormikContext<ILocationOrderNoteForm>();

    return (
        <Box minWidth="500px">
            <SelectFormField name="scenario" options={scenarios} />
            <Box width="100%" mt={2}>
                <TextFormField label="Title" name="title" />
            </Box>
            <Box width="100%" mt={2}>
                <TextFormField label="Description" name="description" />
            </Box>
            <Box width="100%" display="flex" mt={2} justifyContent="flex-end" alignItems="center">
                <LoadingButton color="primary" variant="contained" onClick={submitForm}>
                    Save
                </LoadingButton>
            </Box>
        </Box>
    );
};

interface ICreateLocationOrderNote {
    onAddition: (zoneDetails: LocationOrderNote, scenario: OrderScenario) => void;
    onClose: () => void;
    title: string;
    open: boolean;
}

export const CreateOrderNoteModal: React.FC<ICreateLocationOrderNote> = ({ onAddition, ...modalProps }) => {
    const initialValues: ILocationOrderNoteForm = {
        scenario: OrderScenario.PREORDER,
        title: '',
        description: ''
    };

    const handleSubmit = React.useCallback(
        (values: ILocationOrderNoteForm) => {
            onAddition({ title: values.title, description: values.description }, values.scenario);
        },
        [onAddition]
    );

    return (
        <MuiModal {...modalProps}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <LocationOrderNoteForm />
                </Form>
            </Formik>
        </MuiModal>
    );
};

interface IEditLocationOrderNote extends Omit<MuiModalProps, 'children'> {
    initialValues: ILocationOrderNoteForm;
    onEdit: (values: ILocationOrderNoteForm) => void;
    open: boolean;
    onClose: () => void;
}

export const EditLocationOrderNote: React.FC<IEditLocationOrderNote> = ({
    initialValues = {},
    onEdit,
    ...modalProps
}) => {
    const handleSubmit = React.useCallback(
        (values: ILocationOrderNoteForm) => {
            onEdit(values);
        },
        [onEdit]
    );

    return (
        <MuiModal {...modalProps} title={`Edit "${initialValues.title}"`}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <LocationOrderNoteForm />
                </Form>
            </Formik>
        </MuiModal>
    );
};

// TODO: refactor and performance improvement
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Button, Checkbox, IconButton, styled, Table, TableBody, TableCell, Typography } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import NotInterested from '@mui/icons-material/NotInterested';
import getValue from 'get-value';
import { v4 as uuid } from 'uuid';
import { MenuOn, MuiMenuItem } from 'ui/MenuOn';
import { TableSkeletonComponent } from 'ui/skeleton/TableSkeleton';
import { MuiTableHead } from './MuiTableHead';
import { MuiTableRow } from './MuiTableRow';
import { MuiTableToolbar } from './MuiTableToolbar';
import { TableSearchOptionsProps } from './TableSearchOptions';
import { MuiCircularProgress } from 'lib/MuiCircularProgress';

const PREFIX = 'MuiCustomTable';

const classes = {
    noDataText: `${PREFIX}-noDataText`,
    noDataIcon: `${PREFIX}-noDataIcon`,
    tableWrapper: `${PREFIX}-tableWrapper`
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    [`& .${classes.noDataText}`]: {
        textAlign: 'center',
        color: theme.palette.text.disabled,
        padding: theme.spacing(3),
        paddingTop: 0
    },
    [`& .${classes.noDataIcon}`]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        color: theme.palette.text.disabled,
        padding: theme.spacing(3),
        paddingBottom: 0,
        fontSize: theme.spacing(10)
    },
    [`& .${classes.tableWrapper}`]: {
        overflowX: 'auto'
    }
}));

export interface MuiTableColumnItem {
    key?: string;
    label: string;
    headerProps?: {
        align: 'right' | 'justify' | 'left' | 'center';
    };
    render?: (item: any, key: string) => React.ReactNode;
}

export interface MuiTableMoreItem {
    label: string;
    onClick: (locationIds?: string[]) => void;
}

export interface MuiTableSettingsItem {
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked: boolean;
}

export interface MuiTableToolbarItems {
    more?: {
        items: MuiTableMoreItem[];
        title?: string;
    };
    settings?: {
        items: MuiTableSettingsItem[];
        title?: string;
    };
    search?: {
        onSearchChange?: (value: string) => void;
        onSearch?: (value: string) => void;
        searchPending: boolean;
        placeholder?: string;
        defaultValue?: string;
    };
    TableSearchOptionsProps?: TableSearchOptionsProps;
    renderCustomElement?: () => React.ReactNode;
}

export interface MuiTableScrollItems {
    onScroll: () => void;
    isMoreItems: boolean;
    key?: string;
}

interface MuiTableProps<Model> {
    data: any;
    columns: MuiTableColumnItem[];
    toolbarItems?: MuiTableToolbarItems;
    isLoading?: boolean;
    scroll?: MuiTableScrollItems;
    selectable?: boolean;
    itemActions?: (item: Model, index: number) => MuiMenuItem[];
    loadingRowId?: string;
    onClick?: (data: Model) => void;
    getRowKey?: (m: Model) => string;
    alwaysShowHead?: boolean;
    noDataMessage?: string;
}

// TODO: Model id
export class MuiTable<Model extends { _id?: string; id?: string }> extends React.Component<
    MuiTableProps<Model>
> {
    state = {
        selected: [] as string[]
    };

    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            this.setState({ selected: this.props.data.map((n: Model) => n._id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleRowClick = (id: string, index: number) => {
        if (this.props.onClick) {
            const { data } = this.props;
            this.props.onClick(data[index]);
        }
        if (this.props.selectable) {
            const { selected } = this.state;
            const selectedIndex = selected.indexOf(id);
            let newSelected: string[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
            this.setState({ selected: newSelected });
        }
    };

    isSelected = (id: string) => this.state.selected.includes(id);

    renderRows = (n: Model, index: number) => {
        const { selectable, itemActions, loadingRowId } = this.props;
        const id = this.props.getRowKey ? this.props.getRowKey(n) : n._id || n.id;
        const isSelected = this.isSelected(id);
        return (
            <MuiTableRow
                key={id || `table-row-${index}`}
                index={index}
                selectable={selectable}
                pointer={!!this.props.onClick}
                hover
                id={id}
                onClick={this.handleRowClick}
                selected={isSelected}
            >
                {selectable && (
                    <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isSelected} />
                    </TableCell>
                )}
                {this.renderItemsCells(n)}
                {itemActions &&
                    itemActions.length > 0 &&
                    this.renderItemActionsCell(itemActions(n, index), loadingRowId === id)}
            </MuiTableRow>
        );
    };

    renderNoData = () => {
        const { scroll, noDataMessage = 'No data' } = this.props;
        if (scroll && scroll.isMoreItems) {
            return (
                <Waypoint
                    key={typeof scroll.key === 'string' ? scroll.key : uuid()}
                    scrollableAncestor={document.getElementById('private-layout')}
                    onEnter={scroll.onScroll}
                />
            );
        }
        return (
            <div>
                <NotInterested className={classes.noDataIcon} strokeWidth="1.5" />
                <Typography variant="h6" className={classes.noDataText} color="inherit">
                    {noDataMessage}
                </Typography>
            </div>
        );
    };

    itemActionRenderOn =
        (items: MuiMenuItem[]) =>
        (toggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) =>
            (
                <IconButton disabled={!items.length} onClick={toggle} size="large">
                    <MoreVert />
                </IconButton>
            );

    renderItemActionsCell = (items: MuiMenuItem[], itemActionLoading?: boolean) => (
        <TableCell key={uuid()} align="center" size="small">
            {items.length === 1 ? (
                <Button
                    variant="text"
                    color="primary"
                    onClick={items[0].onClick}
                    disabled={items[0].disabled}
                >
                    {items[0].label}
                </Button>
            ) : (
                <MuiCircularProgress progress={itemActionLoading ? -1 : 100}>
                    <MenuOn items={items} renderOn={this.itemActionRenderOn(items)} />
                </MuiCircularProgress>
            )}
        </TableCell>
    );

    renderItemsCells = (n: Model) =>
        this.props.columns.map((item: MuiTableColumnItem) => {
            if (item.render) {
                return item.render(n, uuid());
            }
            return (
                <TableCell key={uuid()} align="justify" size="small">
                    {getValue(n, item.key)}
                </TableCell>
            );
        });

    render() {
        const { columns, data, itemActions, selectable, toolbarItems, isLoading, scroll } = this.props;
        const { selected } = this.state;
        return (
            <Root>
                <MuiTableToolbar
                    selectable={selectable}
                    selected={selected}
                    items={toolbarItems}
                    numSelected={selected.length}
                    TableSearchOptionsProps={toolbarItems?.TableSearchOptionsProps}
                />
                {isLoading || (data && data.length) || this.props.alwaysShowHead ? (
                    <div className={classes.tableWrapper}>
                        <Table>
                            <MuiTableHead
                                selectable={selectable}
                                itemActions={!!itemActions}
                                numSelected={selected.length}
                                onSelectAllClick={this.handleSelectAllClick}
                                rowCount={data && data.length}
                                columns={columns}
                            />
                            <TableBody>{data && data.map(this.renderRows)}</TableBody>
                        </Table>
                        {this.props.alwaysShowHead &&
                            !isLoading &&
                            !(data && data.length) &&
                            this.renderNoData()}
                        {scroll && scroll.isMoreItems && (
                            <Waypoint
                                key={typeof scroll.key === 'string' ? scroll.key : 'table-scroll'}
                                scrollableAncestor={document.getElementById('private-layout')}
                                onEnter={scroll.onScroll}
                            />
                        )}
                        {isLoading && <TableSkeletonComponent tableBodyRows={5} tableHeadColumns={1} />}
                    </div>
                ) : (
                    this.renderNoData()
                )}
            </Root>
        );
    }
}

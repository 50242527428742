import {
    Claims,
    ExpiringClaims,
    fromExpiringTokenClaims,
    isDecodedToken,
    Permission,
    Resource
} from '@pepperhq/auth-client';
import { ActionCreator } from 'redux';
import { authApi } from 'components/auth/AuthApi';
import { Tenant } from 'components/merchant/models/Tenant';
import { tenantApi } from 'components/merchant/tenantApi';
import { doSignInWithCustomToken, doSignOut } from 'lib/firebase/auth';
import { auth } from 'lib/firebase/firebase';
import { httpClient } from 'lib/HttpClient';
import authActionTypes from './authActionTypes';
import { FirebaseIdentifiers } from './authReducer';

export interface SignInAction {
    type: string;
    error?: string;
}

export interface AuthorizeAction {
    type: string;
    claims?: ExpiringClaims;
    tenant?: Tenant;
    identifiers?: FirebaseIdentifiers;
}

export interface SignOutAction {
    type: string;
}

const signInStart: ActionCreator<SignInAction> = () => ({
    type: authActionTypes.SIGN_IN_START
});

const signInFail: ActionCreator<SignInAction> = (error: string) => ({
    error,
    type: authActionTypes.SIGN_IN_FAIL
});

const signInSuccess: ActionCreator<SignInAction> = () => ({
    type: authActionTypes.SIGN_IN_SUCCESS
});

const authorizeSuccess: ActionCreator<AuthorizeAction> = (
    claims: ExpiringClaims,
    tenant: Tenant,
    identifiers: FirebaseIdentifiers
) => ({
    claims,
    tenant,
    identifiers,
    type: authActionTypes.AUTHORIZE_SUCCESS
});

const authorizeFail: ActionCreator<AuthorizeAction> = (error: string) => ({
    error,
    type: authActionTypes.AUTHORIZE_FAIL
});

const getAccessSuccess: ActionCreator<AuthorizeAction> = () => ({
    type: authActionTypes.GET_ACCESS_SUCCESS
});

const getAccessFail: ActionCreator<AuthorizeAction> = (error: string) => ({
    error,
    type: authActionTypes.GET_ACCESS_FAIL
});

function decodeToken(token: any): Claims {
    if (!token || !isDecodedToken(token)) {
        throw new Error('not a token');
    }
    return fromExpiringTokenClaims(token);
}

// We do not put user to store here because we do in authStateChanged listener (authorize action)
export const signIn = (email: string, password: string) => async (dispatch: ActionCreator<SignInAction>) => {
    try {
        // Sign in Flow:
        // 1. POST {user}/tokens (email, password) -> custom token
        // 2. sign into firebase with custom token -> auth state changes
        // 3. auth state change listener triggers authorise action (below)
        // 4. POST {manager}/authorise (idtoken) -> stores token in session cookie
        // 5. idtoken is decoded into claims and triggers action authorizeSuccess
        dispatch(signInStart());
        const response = await authApi.signInWithEmailAndPassword(email, password);
        if (response.statusCode !== 200) {
            return dispatch(signInFail(response.body.message));
        }
        await doSignInWithCustomToken(response.body.token);
        dispatch(signInSuccess());
    } catch (error) {
        dispatch(signInFail(error.message));
    }
};

export const signOut = () => async (dispatch: ActionCreator<SignOutAction>) => {
    await doSignOut();
    httpClient.updateToken(null);
    dispatch({ type: authActionTypes.SIGN_OUT });
    dispatch({ type: 'DESTROY_SESSION' });
};

export const authorize = () => async (dispatch: ActionCreator<AuthorizeAction>) => {
    try {
        const tokenResult = await auth().currentUser.getIdTokenResult(true);
        const { email, name } = tokenResult.claims;
        httpClient.updateToken(tokenResult.token, new Date(tokenResult.expirationTime));
        const claims = decodeToken(tokenResult.claims);
        let tenant;
        if (claims.tenantId && claims.hasPermission(Resource.Tenant, Permission.read)) {
            const tenantResult = await tenantApi.get(claims.tenantId);
            if (tenantResult.ok) {
                tenant = tenantResult.body;
            } else if (tenantResult.statusCode === 404) {
                return unauthorize()(dispatch);
            }
        }
        dispatch(authorizeSuccess(claims, tenant, { email, name }));
    } catch (error) {
        dispatch(authorizeFail(error.message));
        signOut()(dispatch);
    }
};

export const unauthorize = () => (dispatch: ActionCreator<SignOutAction>) => {
    httpClient.updateToken(null);
    return dispatch({ type: authActionTypes.UNAUTHORIZE });
};

export const getAccess = (token: string) => async (dispatch: ActionCreator<AuthorizeAction>) => {
    try {
        await doSignInWithCustomToken(token);
        await authorize()(dispatch);
        dispatch({ type: 'DESTROY_SESSION' });
        dispatch(getAccessSuccess());
    } catch (error) {
        dispatch(getAccessFail(error));
        signOut()(dispatch);
    }
};

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import React from 'react';

const SHRUNK_CLASS_NAME = 'shrunk';

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    background: theme.palette.common.white,
    [`&.${SHRUNK_CLASS_NAME}`]: {
        padding: theme.spacing(0, 1, 0, 1)
    }
}));

interface OrderRefundReasonsProps {
    value: string;
    onChange: (e: SelectChangeEvent) => void;
    disabled?: boolean;
}

const reasonsList = ['Returned goods', 'Accidental charge', 'Cancelled order', 'Fraudulent charge', 'Other'];

export const OrderRefundReasons: React.FC<OrderRefundReasonsProps> = ({ value, onChange, disabled }) => {
    const renderOption = React.useCallback(
        (option: string) => (
            <MenuItem key={option} value={option}>
                {option}
            </MenuItem>
        ),
        []
    );
    return (
        <FormControl fullWidth disabled={disabled}>
            <StyledInputLabel
                shrink={!!value}
                variant="outlined"
                className={!!value ? SHRUNK_CLASS_NAME : undefined}
            >
                Select a reason{' '}
                <Box component="span" color="primary.main">
                    *
                </Box>
            </StyledInputLabel>
            <Select
                value={value}
                onChange={onChange}
                fullWidth
                sx={{ color: value === 'NO_REASON' ? 'divider' : undefined }}
            >
                {reasonsList.map(renderOption)}
            </Select>
        </FormControl>
    );
};

/* eslint-disable react/no-multi-comp */
import React from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { InputLabelProps, TextField } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormatValues | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    noThousandSeparator?: boolean;
}
const NumberFormatCustom: React.FC<NumberFormatCustomProps> = props => {
    const { inputRef, onChange, noThousandSeparator, ...other } = props;
    const handleChange = React.useCallback(
        (values: NumberFormatValues) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value
                }
            });
        },
        [onChange, props.name]
    );
    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={handleChange}
            thousandSeparator={noThousandSeparator === undefined}
            allowLeadingZeros
            valueIsNumericString
        />
    );
};
const NumberFormatCustomNoThousands: React.FC<NumberFormatCustomProps> = props => (
    <NumberFormatCustom noThousandSeparator {...props} />
);
interface IFormikNumberFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    multiline?: boolean;
    maxRows?: number;
    disabled?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    description?: string;
    type?: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    max?: number;
    min?: number;
    variant?: 'standard' | 'filled' | 'outlined';
    className?: string;
    required?: boolean;
    shouldSeparateThousands?: boolean;
    InputLabelProps?: Partial<InputLabelProps>;
}
const TextFormFieldComponent: React.FC<FieldProps & IFormikNumberFieldProps> = ({
    field,
    meta,
    label,
    form,
    type,
    placeholder,
    description,
    multiline,
    min,
    max,
    disabled,
    inputRef,
    onKeyDown,
    maxRows,
    variant,
    required,
    className,
    shouldSeparateThousands,
    InputLabelProps
}) => (
    <TextField
        fullWidth
        InputLabelProps={InputLabelProps}
        variant={variant ?? 'standard'}
        placeholder={placeholder}
        type={type}
        multiline={multiline}
        maxRows={maxRows || (multiline ? 4 : undefined)}
        name={field.name}
        label={label}
        disabled={disabled || form.isSubmitting}
        inputRef={inputRef}
        value={field.value}
        onChange={field.onChange}
        inputProps={{ min, max }}
        onBlur={field.onBlur}
        error={meta.touched && !!meta.error}
        helperText={(meta.touched && !!meta.error && meta.error) || description}
        InputProps={{
            inputComponent: shouldSeparateThousands
                ? (NumberFormatCustom as any)
                : (NumberFormatCustomNoThousands as any),
            autoComplete: 'off'
        }}
        className={className}
        required={required}
        onKeyDown={onKeyDown}
    />
);
export const NumberFormField: React.FC<FieldAttributes<Omit<IFormikNumberFieldProps, 'onKeyDown'>>> = ({
    label,
    placeholder,
    description,
    multiline,
    disabled,
    maxRows,
    inputRef,
    max,
    min,
    variant,
    className,
    required,
    shouldSeparateThousands,
    InputLabelProps,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <TextFormFieldComponent
                InputLabelProps={InputLabelProps}
                label={label}
                placeholder={placeholder}
                multiline={multiline}
                type={props.type}
                disabled={disabled}
                inputRef={inputRef}
                description={description}
                maxRows={maxRows}
                min={min}
                max={max}
                variant={variant}
                className={className}
                required={required}
                shouldSeparateThousands={
                    shouldSeparateThousands !== undefined ? shouldSeparateThousands : true
                }
                {...childProps}
            />
        )}
    </Field>
);

import React from 'react';
import { Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';

export const JudopaySettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Production Environment">
                    {settings?.judopay?.isProduction && <Done />}
                    {!settings?.judopay?.isProduction && <Close />}
                </SettingDisplay>
                <SettingDisplay label="Judo ID">
                    <Typography variant="body2">{settings?.judopay?.judoId}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Token">
                    <Typography variant="body2">{settings?.judopay?.token}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Secret">
                    <Typography variant="body2">{settings?.judopay?.secret}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Client Token">
                    <Typography variant="body2">{settings?.judopay?.clientToken}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Client Secret">
                    <Typography variant="body2">{settings?.judopay?.clientSecret}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Card Check Enabled">
                    {settings?.judopay?.useCardCheckTokenisation && <Done />}
                    {!settings?.judopay?.useCardCheckTokenisation && <Close />}
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Judo ID">
                <Typography variant="body2">{settings?.judopay?.judoId}</Typography>
            </SettingDisplay>
        )}
    </>
);

import { Crud, HttpClient, httpClient } from 'lib/HttpClient';
import { SignUpData } from './models/SignUpData';

class AuthApi extends Crud {
    constructor(httpClient: HttpClient) {
        super(httpClient, process.env.USER_SERVICE_URL, 'auth');
    }
    signInWithEmailAndPassword(email: string, password: string) {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            Authorization: `Basic ${btoa(`${email}:${password}`)}`
        };
        return this.httpClient.post(`${this.api}/tokens`, {
            headers
        });
    }
    authorize(token: string) {
        const headers = {
            Authorization: `Bearer ${token}`
        };
        return this.httpClient.post('/authorize', { headers, skipExpirationCheck: true });
    }
    unauthorize() {
        return this.httpClient.post('/unauthorize', { skipExpirationCheck: true });
    }
    signUp(signUpData: SignUpData) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.post(
            `${this.api}/users?inviteCallbackUrl=${process.env.INVITE_CALLBACK_URL}`,
            {
                headers,
                body: {
                    ...signUpData
                },
                skipResponseBody: true,
                skipExpirationCheck: true,
                skipDefaultHeaders: true
            }
        );
    }
}

export const authApi = new AuthApi(httpClient);

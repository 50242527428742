import { createReducer } from 'redux-create-reducer';
import { ContentAnchor, ContentLayout } from 'components/content/models/ContentLayout';
import { ContentModule } from 'components/content/models/ContentModule';
import { ContentAnchorProperties } from 'components/content/models/ContentTemplate';
import { updateArrayItem } from 'lib/helpers';
import {
    ContentAction,
    CurrentLayoutAction,
    CurrentLayoutPropertiesAction,
    CurrentModuleAction,
    CurrentModuleDeleteAction,
    CurrentModulePropertyAction
} from './contentActions';
import { contentActionTypes as actionTypes } from './contentActionTypes';

export interface ContentState {
    error: string;
    currentLayout: ContentLayout;
    isLoading: boolean;
    currentModules: ContentModule[] | null;
    selectedModuleId: string;
}

type ContentActionHandlers = {
    [key in actionTypes]: (state: ContentState, action?: ContentAction) => ContentState;
};

export const initialState: ContentState = {
    currentLayout: null,
    currentModules: null,
    error: null,
    isLoading: false,
    selectedModuleId: undefined
};

const createAnchorWithNewProperty = (
    anchor: ContentAnchor,
    property: { [name in keyof ContentAnchorProperties]: any }
) => ({
    ...anchor,
    properties: {
        ...anchor.properties,
        ...property
    }
});

const actionHandlers: ContentActionHandlers = {
    [actionTypes.START_REQUEST]: state => ({ ...state, error: null, isLoading: true }),
    [actionTypes.END_REQUEST]: state => ({ ...state, error: null, isLoading: false }),
    [actionTypes.SET_CURRENT_LAYOUT_SUCCESS]: (state, action: CurrentLayoutAction) => ({
        ...state,
        error: null,
        currentLayout: action.layout
    }),
    [actionTypes.SET_CURRENT_LAYOUT_ERROR]: (state, action) => ({
        ...state,
        error: action.error,
        currentLayout: null
    }),
    [actionTypes.UPDATE_CURRENT_LAYOUT_PROPERTIES]: (state, action: CurrentLayoutPropertiesAction) => ({
        ...state,
        error: null,
        currentLayout: {
            ...state.currentLayout,
            anchor: createAnchorWithNewProperty(state.currentLayout.anchor, action.property)
        }
    }),
    [actionTypes.ADD_CURRENT_MODULE_SUCCESS]: (state, action: CurrentModuleAction) => ({
        ...state,
        error: null,
        currentModules: !!state.currentModules ? [...state.currentModules, action.module] : [action.module]
    }),
    [actionTypes.ADD_CURRENT_MODULE_ERROR]: (state, action) => ({
        ...state,
        error: action.error,
        currentModules: []
    }),
    [actionTypes.UPDATE_CURRENT_MODULE_SUCCESS]: (state, action: CurrentModuleAction) => {
        if (action.id && Array.isArray(state.currentModules)) {
            const moduleIndex = state.currentModules.findIndex(item => item.id === action.id);
            if (moduleIndex >= 0) {
                return {
                    ...state,
                    currentModules: updateArrayItem(state.currentModules, moduleIndex, action.module)
                };
            }
        }
        return state;
    },
    [actionTypes.UPDATE_CURRENT_MODULE_PROPERTIES_SUCCESS]: (state, action: CurrentModulePropertyAction) => {
        const index = state.currentModules.findIndex(item => action.id === item.id);
        const module = state.currentModules[index];
        return {
            ...state,
            error: null,
            currentModules: updateArrayItem(state.currentModules, index, {
                ...module,
                ...action.property
            })
        };
    },
    [actionTypes.UPDATE_CURRENT_MODULE_ERROR]: (state, action: CurrentModulePropertyAction) => ({
        ...state,
        error: action.error
    }),
    [actionTypes.CLEAR_CURRENT_MODULES]: state => ({ ...state, error: null, currentModules: null }),
    [actionTypes.CLEAR_CURRENT_LAYOUT]: state => ({ ...state, error: null, currentLayout: null }),
    [actionTypes.UPDATE_SELECTED_MODULE]: (state, action: CurrentModulePropertyAction) => ({
        ...state,
        selectedModuleId: action.id
    }),
    [actionTypes.DELETE_SELECTED_MODULE]: (state, action: CurrentModuleDeleteAction) => {
        const moduleIds = state.currentLayout.anchor.moduleIds?.filter(item => item !== action.id);
        return {
            ...state,
            currentModules: state.currentModules?.filter(item => item.id !== action.id),
            selectedModuleId: undefined,
            currentLayout: { ...state.currentLayout, anchor: { ...state.currentLayout.anchor, moduleIds } }
        };
    }
};

export default createReducer(initialState, actionHandlers);

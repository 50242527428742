/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { Field, FieldProps, FormikProps } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EReportType, EReportFormat } from './enums';
import { getReportCategoryGroups, reportFormatLabelMap, reportTypeLabelMap } from './model';
import { NewReportColumnSelection } from './NewReportColumnSelection';
import { CreateScheduleReportForm } from './NewSchedulesModal';
import { CreateReportForm } from './NewReportModal';

interface GeneralFormFieldsProps {
    formik: FormikProps<CreateScheduleReportForm | CreateReportForm>;
}

export const GeneralReportForm: React.FC<GeneralFormFieldsProps> = ({ formik }) => {
    const {
        setFieldValue,
        getFieldProps,
        values: { type, format, columns }
    } = formik;

    const getReportFormatTooltipInfo = React.useCallback(
        (format: EReportFormat) => {
            if (type === EReportType.ACTIVITY) {
                if (format === EReportFormat.SUMMARY) {
                    return 'Provides a breakdown by reporting category for your activity, including all transactions except for payouts.';
                }
                return 'Itemised downloads include attribution dates, related charge IDs, customer IDs, etc.';
            }
            if (type === EReportType.BALANCE) {
                if (format === EReportFormat.SUMMARY) {
                    return 'This downloads data in CSV format exactly as you see it in the dashboard.';
                }
                return 'This downloads the full list of individual transactions that are summarized in the dashboard. You can include custom metadata associated with those transactions to speed up the reconciliation process.';
            }
            if (format === EReportFormat.SUMMARY) {
                return 'Shows starting and ending balance in your Stripe account. Starting balance is the sum of your available balance, pending balance, and Connect reserved balance. Changes to your balance come from activity and from payouts to your bank';
            }
            return 'Detailed breakdown of the total payouts line from the above Balance Summary report. Includes all activity that makes up the payouts in this time range, as well as itemised downloads.';
        },
        [type]
    );

    const reportColumnsByCategories = React.useMemo(() => {
        if (type && format) {
            return getReportCategoryGroups(type, format);
        }
    }, [format, type]);

    const onChangeColumns = React.useCallback(
        (newValues: Map<string, boolean>) => {
            setFieldValue('columns', newValues, true);
        },
        [setFieldValue]
    );
    const validateColumns = React.useCallback((columns: Map<string, boolean>) => {
        let error;

        if (columns.size === 0) {
            error = 'Please select columns to create report';
        }

        return error;
    }, []);

    return (
        <Box marginTop={3}>
            <Typography variant="body1">Report Type</Typography>
            <Box marginTop={0.5}>
                <Divider />
            </Box>
            <RadioGroup row aria-label="report-type" name="type" {...getFieldProps('type')}>
                <Grid container>
                    <Grid item xs={4}>
                        <FormControlLabel
                            value={EReportType.ACTIVITY}
                            control={<Radio color="primary" />}
                            label={
                                <Box display="flex" alignItems="center">
                                    {reportTypeLabelMap[EReportType.ACTIVITY]}
                                    <Tooltip title="The Activity report allows you to see your payments activity alongside the related fees for the same period of time.">
                                        <InfoOutlinedIcon sx={{ ml: 0.5 }} />
                                    </Tooltip>
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControlLabel
                            value={EReportType.RECONCILIATION}
                            control={<Radio color="primary" />}
                            label={
                                <Box display="flex" alignItems="center">
                                    {reportTypeLabelMap[EReportType.RECONCILIATION]}
                                    <Tooltip title="The Payout reconciliation report helps you match the payouts you receive in your bank account with the batches of payments and other transactions that they relate to.">
                                        <InfoOutlinedIcon sx={{ ml: 0.5 }} />
                                    </Tooltip>
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            value={EReportType.BALANCE}
                            control={<Radio color="primary" />}
                            label={
                                <Box display="flex" alignItems="center">
                                    {reportTypeLabelMap[EReportType.BALANCE]}
                                    <Tooltip title="The Balance report is similar to a bank statement, helping you to reconcile your Stripe balance at the end of the month. It provides an itemized CSV export of your complete transaction history and any custom metadata associated with those transactions. All transactions are shown in your settlement currency (after any foreign currency conversion).">
                                        <InfoOutlinedIcon sx={{ ml: 0.5 }} />
                                    </Tooltip>
                                </Box>
                            }
                        />
                    </Grid>
                </Grid>
            </RadioGroup>
            <Box marginTop={3}>
                <Typography variant="body1">Report Format</Typography>
                <Box marginTop={0.5}>
                    <Divider />
                </Box>
                <RadioGroup row aria-label="report-format" name="format" {...getFieldProps('format')}>
                    <Grid container>
                        <Grid item xs={4}>
                            <FormControlLabel
                                value={EReportFormat.SUMMARY}
                                control={<Radio color="primary" />}
                                label={
                                    <Box display="flex" alignItems="center">
                                        {reportFormatLabelMap[EReportFormat.SUMMARY]}
                                        <Tooltip title={getReportFormatTooltipInfo(EReportFormat.SUMMARY)}>
                                            <InfoOutlinedIcon sx={{ ml: 0.5 }} />
                                        </Tooltip>
                                    </Box>
                                }
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControlLabel
                                value={EReportFormat.ITEMIZED}
                                control={<Radio color="primary" />}
                                label={
                                    <Box display="flex" alignItems="center">
                                        {reportFormatLabelMap[EReportFormat.ITEMIZED]}
                                        <Tooltip title={getReportFormatTooltipInfo(EReportFormat.ITEMIZED)}>
                                            <InfoOutlinedIcon sx={{ ml: 0.5 }} />
                                        </Tooltip>
                                    </Box>
                                }
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
                <Box marginTop={3}>
                    <Typography variant="body1">Columns</Typography>
                    <Box marginTop={0.5} marginBottom={1}>
                        <Divider />
                    </Box>
                    <Field name="columns" validate={validateColumns}>
                        {(props: FieldProps) => (
                            <>
                                <NewReportColumnSelection
                                    selectedColumns={columns}
                                    groupCategories={reportColumnsByCategories}
                                    onChange={onChangeColumns}
                                />
                                <FormHelperText error={Boolean(props.meta.error)}>
                                    {props.meta.error}
                                </FormHelperText>
                            </>
                        )}
                    </Field>
                </Box>
            </Box>
        </Box>
    );
};

import React, { CSSProperties } from 'react';
import { Typography } from '@mui/material';
import { Permission, Resource } from '@pepperhq/auth-client';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { isDefined } from 'lib/typeguards';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { formatDateTime } from 'lib/helpers';
import { Row } from 'ui/Flex';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { LoadingButton } from '@mui/lab';

interface RefreshMenuButtonProps {
    settings: IPublicAndPrivateSettings;
    onClick: () => void;
    updated?: string;
    isUnsavedChanges?: boolean;
    direction?: CSSProperties['flexDirection'];
    loading?: boolean;
}

export const RefreshMenuButton: React.FC<RefreshMenuButtonProps> = ({
    settings,
    onClick,
    updated,
    isUnsavedChanges,
    direction = 'row',
    loading
}) => {
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const [open, setOpen] = React.useState(false);
    const handleCancel = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleClick = React.useCallback(() => {
        if (isUnsavedChanges) {
            setOpen(true);
        } else {
            onClick();
        }
    }, [isUnsavedChanges, onClick]);
    const handleSubmit = React.useCallback(() => {
        onClick();
        setOpen(false);
    }, [onClick]);
    const refreshButton = React.useMemo(() => {
        if (
            !settings ||
            (isDefined(settings.menu?.manualRefreshEnabled) && !settings.menu?.manualRefreshEnabled) ||
            !claims.hasPermission(Resource.MenuRefresh, Permission.execute)
        ) {
            return null;
        }
        return (
            <LoadingButton
                loading={loading}
                color="secondary"
                size="small"
                onClick={handleClick}
                variant="outlined"
            >
                FORCE REFRESH MENU
            </LoadingButton>
        );
    }, [claims, handleClick, loading, settings]);

    return (
        <React.Fragment>
            <Row valign="center" flexDirection={direction} gutter>
                {refreshButton}
                {updated && !loading && (
                    <Typography sx={{ mx: 1 }} variant="caption">
                        Last Updated: {formatDateTime(updated)}
                    </Typography>
                )}
            </Row>
            <ConfirmDialog
                open={open}
                onCancel={handleCancel}
                onConfirm={handleSubmit}
                title="Refresh Menu"
                content="Refreshing the menu will discard all your changes. Do you want to continue?"
            />
        </React.Fragment>
    );
};

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { isEmptyString, isNumber } from 'lib/typeguards';
import { Option } from 'lib/types';
import React from 'react';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { EnrichedProduct } from '../model/product';
import { parseFloatOrUndefined, roundToDecimal } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

interface DuplicateProductDialogProps {
    open: boolean;
    categories: Option[];
    products: Option[];
    onSubmit: (categoryId: string, productId: string, price: number) => void;
    onCancel: () => void;
    deafultProduct?: EnrichedProduct;
}

export const DuplicateProductDialog: React.FC<DuplicateProductDialogProps> = ({
    open,
    categories,
    products,
    deafultProduct,
    onSubmit,
    onCancel
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [touched, setTouched] = React.useState({ price: false, categoryId: false, productId: false });
    const [selectedCategoryId, setSelectedCategoryId] = React.useState('');
    const [selectedProductId, setSelectedProductId] = React.useState(deafultProduct?.id ?? '');
    const [price, setPrice] = React.useState(
        isNumber(deafultProduct?.price) ? String(deafultProduct.price) : '0.00'
    );
    const handleCategoryChange = React.useCallback((_: React.SyntheticEvent, value: Option) => {
        setTouched(prev => ({ ...prev, categoryId: true }));
        setSelectedCategoryId(value?.value ?? '');
    }, []);
    const handleCategoryIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(prev => ({ ...prev, categoryId: true }));
        setSelectedCategoryId(e.target.value ?? '');
    }, []);
    const handleProductChange = React.useCallback((_: React.SyntheticEvent, value: Option) => {
        setTouched(prev => ({ ...prev, productId: true }));
        setSelectedProductId(value?.value ?? '');
    }, []);
    const handleProductIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(prev => ({ ...prev, productId: true }));
        setSelectedProductId(e.target.value ?? '');
    }, []);
    const handlePriceChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(prev => ({ ...prev, price: true }));
        setPrice(event.target.value.replace('-', ''));
    }, []);
    const handlePriceBlur = React.useCallback(() => {
        const formattedAmount = parseFloatOrUndefined(price);

        if (!formattedAmount && formattedAmount !== 0) {
            return setPrice('');
        }

        setPrice(String(roundToDecimal(Math.abs(formattedAmount)).toFixed(2)));
    }, [price]);
    const selectedCategory = React.useMemo(
        () => categories.find(item => item.value === selectedCategoryId) ?? null,
        [categories, selectedCategoryId]
    );
    const selectedProduct = React.useMemo(
        () => products.find(item => item.value === selectedProductId) ?? null,
        [products, selectedProductId]
    );
    const formattedPrice = React.useMemo(() => Number(roundToDecimal(Number(price))), [price]);
    const isCategoryError = touched.categoryId && !selectedCategory;
    const isProductError = touched.productId && !selectedProduct;
    const isPriceError = touched.price && (isEmptyString(price) || Number.isNaN(formattedPrice));
    const valid = React.useMemo(
        () =>
            !!selectedCategory && !!selectedProduct && !isEmptyString(price) && !Number.isNaN(formattedPrice),
        [formattedPrice, price, selectedCategory, selectedProduct]
    );
    const renderCategoriesInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                error={isCategoryError}
                helperText={isCategoryError && 'Please select a category from the list.'}
                label="Category"
            />
        ),
        [isCategoryError]
    );
    const renderProductsInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                error={isProductError}
                label="Product"
                helperText={isProductError && 'Please select a product from the list.'}
            />
        ),
        [isProductError]
    );
    const isTouched = React.useMemo(
        () => touched.categoryId || touched.price || touched.productId,
        [touched.categoryId, touched.price, touched.productId]
    );
    const handleSubmit = React.useCallback(() => {
        if (!isTouched) {
            setTouched({ productId: true, categoryId: true, price: true });
            if (!valid) {
                return;
            }
        }
        onSubmit(selectedCategoryId, selectedProductId, formattedPrice);
    }, [formattedPrice, isTouched, onSubmit, selectedCategoryId, selectedProductId, valid]);
    return (
        <MuiModal open={open} onClose={onCancel}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        Add a product duplicate
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Autocomplete
                        options={categories}
                        renderInput={renderCategoriesInput}
                        onChange={handleCategoryChange}
                        value={selectedCategory}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Category ID"
                        value={selectedCategoryId}
                        onChange={handleCategoryIdChange}
                        error={isCategoryError}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Autocomplete
                        options={products}
                        renderInput={renderProductsInput}
                        onChange={handleProductChange}
                        value={selectedProduct}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Product ID"
                        value={selectedProductId}
                        onChange={handleProductIdChange}
                        error={isProductError}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        onChange={handlePriceChange}
                        onBlur={handlePriceBlur}
                        label="New product price"
                        type="number"
                        error={isPriceError}
                        helperText={isPriceError && 'Please enter a valid number'}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ startAdornment: settings?.region?.currencySymbol }}
                        value={price}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Row align="flex-end" gutter>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button
                            disabled={isTouched && !valid}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Row>
                </Grid>
            </Grid>
        </MuiModal>
    );
};

import React from 'react';
import { styled } from '@mui/material';
import { createSignUpMerchantForm } from 'components/auth/forms/createSignUpMerchantForm';
import { tenantApi } from 'components/merchant/tenantApi';
import { IOperationResult, OperationStatus } from 'components/operation/models/OperationModel';
import { pollOperation } from 'components/operation/OperationUtils';
import {
    MESSAGE_MERCHANT_CREATE_ERROR,
    MESSAGE_MERCHANT_CREATE_INFO,
    MESSAGE_MERCHANT_CREATE_SUCCESS,
    MESSAGE_MERCHANT_PRECREATE_ERROR
} from 'config/messages';
import { auth } from 'lib/firebase/firebase';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import { MuiModal } from 'ui/MuiModal';

const StyledModal = styled(MuiModal)(({ theme }) => ({
    maxWidth: theme.spacing(58)
}));

interface IProps {
    onError?: (message: string) => void;
    onSuccess?: (message: string) => void;
    onInfo?: (message: string) => void;
    title: string;
    open: boolean;
    onClose: () => void;
    isLoading?: boolean;
}

interface IState {
    isLoading: boolean;
}

class MerchantSignUpForm extends React.Component<IProps, IState> {
    state = {
        isLoading: false
    };
    checkForOperationResult = async (operation: IOperationResult<string>) => {
        const { onError, onSuccess } = this.props;
        try {
            // Wait for operation to complete in some mannar
            const result = await pollOperation(tenantApi.getOperation.bind(tenantApi), operation);
            this.setState({ isLoading: false });
            if (result.status !== OperationStatus.DONE) {
                return onError(MESSAGE_MERCHANT_CREATE_ERROR(result.id));
            }

            return onSuccess(MESSAGE_MERCHANT_CREATE_SUCCESS);
        } catch (err) {
            this.setState({ isLoading: false });
            onError(MESSAGE_MERCHANT_CREATE_ERROR(operation.id));
        }
    };
    handleSubmit = async (data: any) => {
        this.setState({ isLoading: true });
        const { onInfo, onError } = this.props;

        // Add userdata to merchant form data
        // If firebase displayName was not provided on signup, then this would be null
        // some v4 accounts were created prior to this being inserted with the creation data
        const displayName = auth().currentUser.displayName || 'Admin Admin';
        const names = displayName.split(' ');
        const firstName = names.length >= 1 ? names.splice(0, 1)[0] : ''; // Get and Remove First Name
        const lastName = names.length >= 1 ? names.join(' ') : ''; // Rest are 'last names'

        const requestData = {
            firstName,
            lastName,
            email: auth().currentUser.email,
            ...data
        };

        try {
            const result = await tenantApi.create({ body: requestData });
            const { body } = result;
            // Handle non-200 errors
            if (!result.ok) {
                throw new Error(`${body.errorMessage || result.statusCode}`);
            }

            // Operation Created
            if (onInfo) {
                onInfo(MESSAGE_MERCHANT_CREATE_INFO);
            }

            // Start recursive checking for completion
            this.checkForOperationResult(body);
        } catch (err) {
            this.setState({ isLoading: false });
            if (onError) {
                onError(MESSAGE_MERCHANT_PRECREATE_ERROR(err.message));
            }
        }
    };
    handleClose = () => {
        if (!this.state.isLoading) {
            this.props.onClose();
        }
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    form = new LegacyForm(createSignUpMerchantForm(), this.handleSubmit);
    render() {
        const { title, open, isLoading } = this.props;
        return (
            <StyledModal open={open} onClose={this.handleClose}>
                <AutoForm
                    isLoading={isLoading || this.state.isLoading}
                    title={title}
                    subtitle="To create your merchant, we'll need some details."
                    form={this.form}
                />
            </StyledModal>
        );
    }
}

export default MerchantSignUpForm;

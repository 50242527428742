import { AutoFormFields } from 'lib/LegacyForm';
import { createPrimaryActionItemForm } from './createPrimaryActionItemForm';
import { getPrimaryCtaModuleForm } from './PrimaryCtaModuleItem';

export const createModulePrimaryActionForm: AutoFormFields = {
    index: {
        label: 'Sort',
        type: 'number',
        builtInLabel: true,
        width: 3,
        validation: 'required|integer|min:0|max:9999',
        errors: {
            required: 'Thie field is required',
            integer: 'Sort should be a number',
            max: 'Sort should not be greater than 9999',
            min: 'Sort should be greater or equal to 0'
        }
    },
    items: {
        type: 'sortableField',
        fields: [
            { label: 'Title', width: '55%', key: 'title' },
            { label: 'Action', width: '30%', key: 'type' }
        ],
        validation: 'required',
        errors: {
            required: 'Thie field is required'
        },
        itemForm: createPrimaryActionItemForm,
        FormComponent: getPrimaryCtaModuleForm,
        label: 'Primary action items'
    }
};

import React from 'react';
import { Button, Grid } from '@mui/material';

import { ActionOrderCompletedContent } from '../ActionOrderCompletedContent';
import { isActionOrderUserBilledMetadata, isOrderUserBilledAction } from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';
import { ORDERS_VIEW } from 'config/routes';
import { isDefined } from 'lib/typeguards';
import { useHistory } from 'react-router-dom';

export const ActionCardOrderBilled: React.FC<ActionCardModalComponentProps> = ({ action }) => {
    const history = useHistory();
    const orderLink = React.useMemo(
        () =>
            isActionOrderUserBilledMetadata(action.metadata) && isDefined(action.metadata?.order?.id)
                ? ORDERS_VIEW.replace(':orderId', action.metadata.order.id)
                : undefined,
        [action.metadata]
    );
    const handleViewClick = React.useCallback(() => {
        history.push(orderLink);
    }, [history, orderLink]);

    if (!isOrderUserBilledAction(action)) {
        return <ActionCardBase action={action} broken />;
    }
    return (
        <ActionCardBase
            buttons={
                <Button color="primary" variant="outlined" onClick={handleViewClick}>
                    View & Refund
                </Button>
            }
            action={action}
            size="double"
        >
            <Grid container spacing={1}>
                <ActionOrderCompletedContent
                    date={getActionDate(action)}
                    value={action.metadata.value.amount}
                    currency={action.metadata.value.currency}
                    locationTitle={action.context.location && action.context.location.title}
                    scenario={action.metadata?.order?.scenario}
                />
            </Grid>
        </ActionCardBase>
    );
};

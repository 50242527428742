import React from 'react';
import { Box, Button, Divider, Grid, styled, Typography } from '@mui/material';
import { IAction } from '../models/Action';
import { mapActionTypeToTimelineTitle } from '../utils/actionTypeToTitle';
import { ActionDetailModal } from 'components/actions/modals/ActionDetailModal';

interface ActionCardBaseProps {
    action: IAction;
    broken?: boolean;
    size?: 'single' | 'double';
    buttons?: JSX.Element;
}

const pointsSize: Record<'single' | 'double', number> = {
    single: 42,
    double: 71
};

const PREFIX = 'ActionCardBase';

const classes = {
    root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: ({ size = 'single' }: ActionCardBaseProps) => ({
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(pointsSize[size])
        }
    })
}));

export const ActionCardBase: React.FC<ActionCardBaseProps> = props => {
    const { action, children, broken, buttons, ...rest } = props;
    const [isActionDetailOpen, setActionDetailOpen] = React.useState(false);

    const toggleActionDetailClick = React.useCallback(() => {
        setActionDetailOpen(prevValue => !prevValue);
    }, []);

    return (
        <StyledGrid container spacing={1} className={classes.root} {...rest}>
            <Grid item xs={12}>
                <Typography variant="h6">{mapActionTypeToTimelineTitle(action)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {broken ? (
                <Typography>Sorry, the details of this event can&apos;t be loaded at the moment</Typography>
            ) : (
                <>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" marginTop={2}>
                            <Box marginRight={2}>
                                <Button onClick={toggleActionDetailClick} variant="outlined" color="primary">
                                    View Detail
                                </Button>
                            </Box>
                            {!!buttons && buttons}
                        </Box>
                    </Grid>
                </>
            )}
            <ActionDetailModal action={action} open={isActionDetailOpen} onClose={toggleActionDetailClick} />
        </StyledGrid>
    );
};

import React from 'react';
import { TableCell } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { MuiCircularProgress } from 'lib/MuiCircularProgress';
import { EnrichmentOperation } from './model/EnrichmentOperation';

interface ImportEnrichmentProgressCellProps {
    operation: EnrichmentOperation;
}

export const ImportEnrichmentProgressCell: React.FC<ImportEnrichmentProgressCellProps> = ({ operation }) => {
    const progress = React.useMemo(
        () =>
            operation.result && operation.result.total
                ? (operation.result.errors +
                      operation.result.imported +
                      operation.result.skipped +
                      operation.result.removedUnlinked) /
                  operation.result.total
                : undefined,
        [operation]
    );
    return (
        <TableCell align="center" size="small">
            <MuiCircularProgress
                progress={isNaN(progress) ? -1 : progress * 100}
                isError={operation.status === OperationStatus.ERROR}
            >
                <div />
            </MuiCircularProgress>
        </TableCell>
    );
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import {
    MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_ERROR,
    MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_SUCCESS
} from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Row } from 'ui/Flex';
import { customerApi } from '../customerApi';
import { Customer } from '../models/Customer';

interface CustomerDeviceUniquenessFormProps {
    onClose: () => void;
    customer: Customer;
    onChange: (data: Partial<Customer>) => void;
}

export const CustomerDeviceUniquenessForm: React.FC<CustomerDeviceUniquenessFormProps> = props => {
    const { onClose, customer, onChange } = props;
    const [isLoading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        const body = { resetUniqueDeviceVerification: true };
        const result = await customerApi.update(customer._id, { body });
        if (!result.ok) {
            return dispatch(
                enqueueSnackbar(MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_ERROR, { variant: 'error' })
            );
        }
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_SUCCESS, { variant: 'success' }));
        onChange(body);
        setLoading(false);
        onClose();
    }, [onClose, onChange, dispatch, customer._id]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography color="primary" variant="h6">
                    Reset device uniqueness for {customer.firstName}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">
                    <b> Warning </b> - this can potentially block a user from creating another account on your
                    app. In order to make sure this doesn’t happen,
                    <b> ensure that the user is currently signed in on the app. </b> If they are, click they
                    button below and ask them to sign out
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Row gutter align="flex-end" valign="right">
                    <Button color="secondary" disabled={isLoading} onClick={handleSubmit} variant="outlined">
                        Reset
                    </Button>
                    <Button variant="contained" disabled={isLoading} onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </Row>
            </Grid>
        </Grid>
    );
};

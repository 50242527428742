import { GridColTypeDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { AdvancedAvailabilityCell } from './AdvancedAvailabilityCell';
import { AvailabilityRedirect } from './AvailabilityRedirect';
import { Option } from 'lib/types';
import { renderMuiGridMultiselectView } from 'lib/MuiGrid/select/MuiGridMultiselectView';
import { isDefined } from 'lib/typeguards';
import { renderMuiGridMultiSelect } from 'lib/MuiGrid/select/MuiGridSelect';

const tooltip =
    'You cannot set an available day on this category due to an existing weekly availability. Please review in Weekly Availability section.';

export function CategoryDaysColumn(
    advancedAvailability: Set<string>,
    labels: Record<string, string>
): GridColTypeDef {
    const options: Option[] = [];
    Object.entries(labels).forEach(([value, label]) => {
        options.push({ label, value });
    });
    return {
        renderCell: props => {
            if (advancedAvailability.has('' + props.id)) {
                return <AdvancedAvailabilityCell tooltip={tooltip} />;
            }
            return renderMuiGridMultiselectView(props, labels, 'Every Day');
        },
        renderEditCell: props => {
            if (advancedAvailability.has('' + props.id)) {
                return <AvailabilityRedirect />;
            }
            return !isDefined(props.value) || Array.isArray(props.value)
                ? renderMuiGridMultiSelect(props, options)
                : 'Something went wrong';
        }
    };
}

import { CardBrand } from 'components/pepper-pay/model/PepperPay';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { isDefined } from 'lib/typeguards';

export enum OrderAdjustmentType {
    TIP = 'TIP',
    DISCOUNT = 'DISCOUNT',
    CHARGE = 'CHARGE',
    REFUND = 'REFUND'
}

interface IOrderModifierOption {
    id: string;
    name: string;
    cost?: number;
}

export interface IOrderModifier {
    id: string;
    name: string;
    options?: IOrderModifierOption[];
}

export interface IOrderItem {
    id: number;
    userId: string;
    categoryId: string;
    categoryName: string;
    productId: string;
    productName: string;
    cost: number;
    taxAdded: number;
    taxToAdd: number;
    quantity: number;
    modifiers: IOrderModifier[];
    adjustments: IOrderAdjustment[];
    referenceId: string;
    parentReferenceId?: string;
    deleted?: boolean;
}

export interface IOrderAdjustment {
    id: number;
    userId: string;
    pointsRedeemed: number;
    description: string;
    type: OrderAdjustmentType;
    quantity: number;
    value: number;
    taxAdded: number;
    taxToAdd: number;
    awardId?: string;
    paymentId?: number;
    deleted?: boolean;
}

export enum EOrderPaymentType {
    Terminal = 'CARDHOLDER_PRESENT',
    Cash = 'CASH',
    Card = 'CARD_ON_FILE',
    Googlepay = 'GOOGLEPAY',
    Applepay = 'APPLEPAY',
    PosCard = 'CARD',
    Giftcard = 'GIFT_CARD'
}

export interface IOrderPayment {
    id: number;
    userId: string;
    type: EOrderPaymentType;
    itemIds: number[];
    amount: number;
    receiptId?: string;
    deleted?: boolean;
    parentId?: number;
    completedAt: string;
    clerkId?: string;
    cardLast4?: string;
    cardBrand?: CardBrand;
    refundReason?: string;
    refundNote?: string;
}

export interface IOrderUser {
    id: number;
    userId: string;
    firstName: string;
    lastName: string;
    isPrimary: boolean;
}

interface IOrderTotalTax {
    amount: number;
    name: string;
    id: string;
    rate: number;
    inclusive?: boolean;
}

export interface IOrder {
    id: string;
    scenario: OrderScenario;
    items: IOrderItem[];
    adjustments: IOrderAdjustment[];
    payments: IOrderPayment[];
    users: IOrderUser[];
    externalId?: string;
    tenantId: string;
    locationId: string;
    deliveryLocation?: string;
    note?: string;
    pin?: string; // FIXME values are generated by the repo layer - change this so we can have blank pin for orders that don't need it
    passphrase?: string;
    isOpen: boolean;
    isPending: boolean; // NB a pending order that is closed has been cancelled
    currencyCode: string;
    nonce: string;
    createdAt: string;
    updatedAt: Date;
    isSingleItemIds: boolean;
    totalIsPOSCalculated: boolean;
    totalCost: number;
    totalDiscounts: number;
    totalCharges: number;
    totalToPay: number;
    currentExternalBasketId?: string;
    timeSlot?: Timeslot;
    total: {
        discounts: number;
        cost: number;
        taxes: IOrderTotalTax[];
        tips: number;
        refunds: number;
        charges: number;
        total: number;
        payments: number;
    };
}

export enum OrderScenario {
    PAYATPOS = 'PAYATPOS',
    PREORDER = 'PREORDER',
    TAB = 'TAB',
    TABLE = 'TABLE',
    ORDER_TO_TABLE = 'ORDER_TO_TABLE'
}

export const OrderScenarioLabels: Record<string, string> = {
    PAYATPOS: 'Pay at POS',
    PREORDER: 'Preorder',
    TAB: 'Tab',
    TABLE: 'Pay at Table',
    ORDER_TO_TABLE: 'Order to Table'
};

export function isOrderScenario(scenario: any): scenario is OrderScenario {
    return scenario in OrderScenario;
}

export interface IOrderRefundItem {
    id: number;
    quantity: number;
}

export const isOrderItemRefunded = (item: IOrderItem) => {
    const sumOfRefundAdjustments = item.adjustments.reduce<number>(
        (acc, adjustment) => (adjustment.type !== OrderAdjustmentType.TIP ? acc + adjustment.value : acc),
        0
    );

    return Number((sumOfRefundAdjustments + item.cost * item.quantity).toFixed(1)) <= 0;
};

export const hasOrderAnyTips = (order: IOrder, userId?: string) => {
    const selectedAdjustments = !!userId
        ? order.adjustments.filter(
              adjustment => adjustment.type === OrderAdjustmentType.TIP && adjustment.userId === userId
          )
        : order.adjustments.filter(adjustment => adjustment.type === OrderAdjustmentType.TIP);

    return (
        !!selectedAdjustments.length &&
        selectedAdjustments.reduce<number>(
            (acc, adjustment) =>
                adjustment.type === OrderAdjustmentType.TIP ? (acc += adjustment.value) : acc,
            0
        ) > 0.1
    );
};

export const isUserlessOrderPayment = (payment: IOrderPayment) => payment && !isDefined(payment.userId);

export interface FormattedOrder {
    id: string;
    scenario: OrderScenario;
    isOpen: 'Open' | 'Closed';
    createdAt: string;
    timeSlot?: string;
}

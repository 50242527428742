import { Button, Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import React from 'react';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { UserMetricDefinition } from '../model';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';

interface EditTieredLoyaltyModalProps {
    metricDefinition?: UserMetricDefinition;
    open: boolean;
    onClose: () => void;
    onSubmit: (data: { name: string; displayName: string }) => void;
    loading?: boolean;
}

export const EditTieredLoyaltyModal: React.FC<EditTieredLoyaltyModalProps> = ({
    open,
    onClose,
    onSubmit,
    loading,
    metricDefinition
}) => {
    const initialData = React.useMemo(
        () => ({
            name: metricDefinition?.name ?? ''
        }),
        [metricDefinition?.name]
    );
    return (
        <MuiModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        {!metricDefinition ? 'Create a Tier scheme' : 'Update Tier cheme'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MuiForm onSubmit={onSubmit} initialValues={initialData}>
                        {({ submitForm, isSubmitting, isValid }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextFormField
                                            name="name"
                                            label={<MuiFormLabel required>Name</MuiFormLabel>}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Row gutter align="flex-end">
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                onClick={onClose}
                                                disabled={loading || isSubmitting}
                                            >
                                                Cancel
                                            </Button>
                                            <LoadingButton
                                                variant="contained"
                                                onClick={submitForm}
                                                disabled={!isValid || isSubmitting || loading}
                                                loading={isSubmitting || loading}
                                            >
                                                Submit
                                            </LoadingButton>
                                        </Row>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </MuiForm>
                </Grid>
            </Grid>
        </MuiModal>
    );
};

import { AutoFormFields } from 'lib/LegacyForm';
import { ContentModule, isLocationsModule } from '../models/ContentModule';
import { createModuleBasicForm } from './createModuleBasicForm';

export const createModuleLoyaltyBalanceForm: AutoFormFields = {
    ...createModuleBasicForm
};

export function transformModuleToLocationFormFields(module: ContentModule): any {
    if (isLocationsModule(module)) {
        return {
            ...module,
            secondaryActionTitle: module.secondaryAction ? module.secondaryAction.title : undefined,
            secondaryActionAction: module.secondaryAction ? module.secondaryAction.action : undefined
        };
    }
    return module;
}

export function transformLocationFormFieldToModuleFields(
    fieldName: string,
    value: any,
    formData: { [key: string]: any }
): { name: string; value: any }[] {
    switch (fieldName) {
        case 'secondaryActionTitle':
            return [
                { name: 'secondaryAction', value: { action: formData.secondaryActionAction, title: value } }
            ];
        case 'secondaryActionAction':
            return [
                {
                    name: 'secondaryAction',
                    value: {
                        action: value,
                        title: formData.secondaryActionTitle
                    }
                }
            ];
        default:
            return [{ value, name: fieldName }];
    }
}

import React from 'react';
import { Box, styled } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SettingDisplayText } from './SettingDisplayText';

const PREFIX = 'LocationSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    }
}));

enum ETerminalAccessProvider {
    PEPPER = 'PEPPER',
    OMNIVORE_NCR_ALOHA = 'OMNIVORE_NCR_ALOHA'
}

const terminalAccessProviderLabels: Record<ETerminalAccessProvider, string> = {
    [ETerminalAccessProvider.PEPPER]: 'Pepper',
    [ETerminalAccessProvider.OMNIVORE_NCR_ALOHA]: 'Omnivore NCR Aloha'
};

const getTerminalAccessProviderLabel = (provider: string | undefined): string => {
    if (provider === undefined) {
        return terminalAccessProviderLabels[ETerminalAccessProvider.PEPPER];
    }

    return terminalAccessProviderLabels[provider as ETerminalAccessProvider];
};

export const TerminalSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId
}) => {
    const handleSubmit = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {
                    terminal: {
                        blockAmexPayments: values.blockAmexPayments
                    }
                };
                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        terminal: {
                            ...settingsToUpdate.terminal,
                            orderingEnabled: values.orderingEnabled,
                            counterServiceOrderingEnabled: values.counterServiceOrderingEnabled,
                            orderToTableOrderingEnabled: values.orderToTableOrderingEnabled,
                            openTableOrderingEnabled: values.openTableOrderingEnabled,
                            uncapturedPaymentHandlingEnabled: values.uncapturedPaymentHandlingEnabled,
                            tableNumberKeyboardInputEnabled: values.tableNumberKeyboardInputEnabled,
                            tablesViewEnabled: values.tablesViewEnabled,
                            createStandalonePaymentButtonEnabled: values.createStandalonePaymentButtonEnabled,
                            allergenCaptureEnabled: values.allergenCaptureEnabled
                        },
                        terminalAccessProvider: values.terminalAccessProvider,
                        orderNotesEnabled: {
                            item: values.itemOrderNotesEnabled
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );
    if (locationId !== undefined) {
        return (
            <Box minWidth="500px">
                <StyledMuiCardForm
                    title="General"
                    initialValues={{
                        blockAmexPayments: settings?.terminal?.blockAmexPayments ?? false
                    }}
                    onSubmit={handleSubmit}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplaySwitch
                                title="Block Amex Payments"
                                checked={settings?.terminal?.blockAmexPayments}
                            />
                        </Box>
                    }
                    childrenWhileEditing={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Block Amex Payments">
                                <SwitchFormField name="blockAmexPayments" />
                            </SettingDisplay>
                        </Box>
                    }
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            </Box>
        );
    }
    return (
        <Box minWidth="500px">
            <StyledMuiCardForm
                title="General"
                initialValues={{
                    orderingEnabled: settings?.terminal?.orderingEnabled ?? true,
                    counterServiceOrderingEnabled: settings?.terminal?.counterServiceOrderingEnabled ?? false,
                    orderToTableOrderingEnabled: settings?.terminal?.orderToTableOrderingEnabled ?? false,
                    openTableOrderingEnabled: settings?.terminal?.openTableOrderingEnabled ?? true,
                    uncapturedPaymentHandlingEnabled:
                        settings?.terminal?.uncapturedPaymentHandlingEnabled ?? false,
                    tableNumberKeyboardInputEnabled:
                        settings?.terminal?.tableNumberKeyboardInputEnabled ?? false,
                    tablesViewEnabled: settings?.terminal?.tablesViewEnabled ?? false,
                    createStandalonePaymentButtonEnabled:
                        settings?.terminal?.createStandalonePaymentButtonEnabled ?? false,
                    allergenCaptureEnabled: settings?.terminal?.allergenCaptureEnabled ?? false,
                    itemOrderNotesEnabled: settings?.orderNotesEnabled?.item ?? false,
                    terminalAccessProvider: settings?.terminalAccessProvider ?? 'PEPPER',
                    blockAmexPayments: settings?.terminal?.blockAmexPayments ?? false
                }}
                onSubmit={handleSubmit}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplaySwitch
                            title="Ordering Enabled"
                            checked={settings?.terminal?.orderingEnabled ?? true}
                        />
                        <SettingDisplaySwitch
                            title="Counter Service Enabled"
                            checked={settings?.terminal?.counterServiceOrderingEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Order To Table Enabled"
                            checked={settings?.terminal?.orderToTableOrderingEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Open Table Ordering Enabled"
                            checked={settings?.terminal?.openTableOrderingEnabled ?? true}
                        />
                        <SettingDisplaySwitch
                            title="Table Number Keyboard Input Enabled"
                            checked={settings?.terminal?.tableNumberKeyboardInputEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Tables View Enabled"
                            checked={settings?.terminal?.tablesViewEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Create Standalone Payment"
                            checked={settings?.terminal?.createStandalonePaymentButtonEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Capture and Void Pending Payments Enabled"
                            checked={settings?.terminal?.uncapturedPaymentHandlingEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Capture Allergens"
                            checked={settings?.terminal?.allergenCaptureEnabled}
                        />
                        <SettingDisplaySwitch
                            title="Item Level Notes"
                            checked={settings?.orderNotesEnabled?.item}
                        />
                        <SettingDisplaySwitch
                            title="Block Amex Payments"
                            checked={settings?.terminal?.blockAmexPayments}
                        />
                        <SettingDisplayText
                            title="Clerk Access Provider"
                            text={getTerminalAccessProviderLabel(settings?.terminalAccessProvider)}
                        />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Quickpad Ordering Enabled">
                            <SwitchFormField name="orderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Counter Service Enabled">
                            <SwitchFormField name="counterServiceOrderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Order To Table Enabled">
                            <SwitchFormField name="orderToTableOrderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Open Table Ordering Enabled">
                            <SwitchFormField name="openTableOrderingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Table Number Keyboard Input Enabled">
                            <SwitchFormField name="tableNumberKeyboardInputEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Tables View Enabled">
                            <SwitchFormField name="tablesViewEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Create Standalone Payment">
                            <SwitchFormField name="createStandalonePaymentButtonEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Capture and Void Pending Payments Enabled">
                            <SwitchFormField name="uncapturedPaymentHandlingEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Capture Allergens">
                            <SwitchFormField name="allergenCaptureEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Item Level Notes">
                            <SwitchFormField name="itemOrderNotesEnabled" />
                        </SettingDisplay>
                        <SettingDisplay label="Block Amex Payments">
                            <SwitchFormField name="blockAmexPayments" />
                        </SettingDisplay>
                        <SettingDisplay label="Clerk Access Provider">
                            <SelectFormField
                                name="terminalAccessProvider"
                                options={[
                                    {
                                        label: terminalAccessProviderLabels[ETerminalAccessProvider.PEPPER],
                                        value: ETerminalAccessProvider.PEPPER
                                    },
                                    {
                                        label: terminalAccessProviderLabels[
                                            ETerminalAccessProvider.OMNIVORE_NCR_ALOHA
                                        ],
                                        value: ETerminalAccessProvider.OMNIVORE_NCR_ALOHA
                                    }
                                ]}
                            />
                        </SettingDisplay>
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
        </Box>
    );
};

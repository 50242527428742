import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';

interface CheckboxProps {
    label?: React.ReactNode;
    disabled?: boolean;
    description?: string;
    readOnly?: boolean;
}

export const CheckboxFormField: React.FC<FieldAttributes<CheckboxProps>> = ({
    label,
    description,
    disabled,
    readOnly,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <CheckboxFormFieldComponent
                label={label}
                disabled={disabled}
                description={description}
                readOnly={readOnly}
                {...childProps}
            />
        )}
    </Field>
);

const CheckboxFormFieldComponent: React.FC<FieldProps & CheckboxProps> = ({
    field,
    meta,
    label,
    disabled,
    description,
    readOnly
}) => {
    const isError = meta.touched && !!meta.error;
    return (
        <FormControl error={isError} disabled={disabled} fullWidth>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        name={field.name}
                        checked={field.value}
                        onChange={readOnly ? undefined : field.onChange}
                        onBlur={field.onBlur}
                        readOnly={readOnly}
                    />
                }
                label={label}
            />
            {(isError || description) && (
                <FormHelperText sx={{ mx: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};

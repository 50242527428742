import { MenuItem, Select } from '@mui/material';
import { isUndefined } from 'lib/typeguards';
import { Option } from 'lib/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

const ANY_LOCATION = 'ANY';

interface GridLocationFilterProps {
    onChange: (value?: string) => void;
    value?: string;
    disabled?: boolean;
    required?: boolean;
}

export const GridLocationFilter: React.FC<GridLocationFilterProps> = ({
    value,
    onChange,
    disabled,
    required
}) => {
    const { locations } = useSelector((state: ApplicationState) => state.locations);
    const options = React.useMemo(() => {
        if (!Array.isArray(locations)) {
            return [];
        }
        return locations.map(item => ({ value: item._id, label: item.title }));
    }, [locations]);
    const handleLocationChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ANY_LOCATION) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    React.useEffect(() => {
        if (required && isUndefined(value) && options[0]) {
            onChange(options[0].value);
        }
    }, [onChange, options, required, value]);
    return (
        <Select
            value={value ?? ANY_LOCATION}
            onChange={handleLocationChange}
            variant="outlined"
            disabled={disabled}
        >
            {!required && <MenuItem value={ANY_LOCATION}>All Locations</MenuItem>}
            {options.map(renderOption)}
        </Select>
    );
};

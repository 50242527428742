import React from 'react';
import { useMenuDelete } from './useMenuDelete';

export function useMenuDeleteControls(
    selectedIds: string[],
    readyToSave: boolean,
    handleCancel: () => void,
    resetSelection: () => void
) {
    const { deleted, resetDeleted, isCellEditable, onRowDelete } = useMenuDelete();

    const editedOrDeleted = React.useMemo(
        () => readyToSave || deleted?.size > 0,
        [deleted?.size, readyToSave]
    );

    const handleCancelEditing = React.useCallback(() => {
        resetDeleted();
        handleCancel();
    }, [handleCancel, resetDeleted]);

    const deleteEnabled = React.useMemo(() => selectedIds.length > 0, [selectedIds.length]);

    const handleDelete = React.useCallback(() => {
        selectedIds.forEach(id => {
            onRowDelete(id);
        });
        resetSelection();
    }, [onRowDelete, resetSelection, selectedIds]);
    return {
        deleted,
        editedOrDeleted,
        handleCancelEditing,
        deleteEnabled,
        handleDelete,
        isCellEditable,
        resetDeleted
    };
}

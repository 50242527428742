import logger from 'lib/logger';
import { AllowedCustomerRoleFilter, Customer, CustomerRole } from '../models/Customer';

enum CustomersReducerActionType {
    START_REQUEST = 'START_REQUEST',
    LOAD_SUCCESS = 'LOAD_SUCCESS',
    LOAD_ERROR = 'LOAD_ERROR',
    UNKNOWN = 'UNKNOWN'
}

interface IState {
    customers: Customer[];
    nextKey?: string;
    isLoading: boolean;
    role: AllowedCustomerRoleFilter;
}
type IReducerAction = IUnknownAction | IProgressAction | IInitialLoadingAction;
interface IBaseReducerAction {
    type: CustomersReducerActionType;
}
interface IUnknownAction extends IBaseReducerAction {
    type: CustomersReducerActionType.UNKNOWN;
}
interface IProgressAction extends IBaseReducerAction {
    type: CustomersReducerActionType.START_REQUEST;
}
interface IInitialLoadingAction extends IBaseReducerAction {
    type: CustomersReducerActionType.LOAD_SUCCESS | CustomersReducerActionType.LOAD_ERROR;
    customers?: Customer[];
    nextKey?: string;
    role?: AllowedCustomerRoleFilter;
}

const initialState: IState = {
    customers: [],
    nextKey: undefined,
    isLoading: true,
    role: CustomerRole.USER
};

function reducer(state: IState, action: IReducerAction): IState {
    switch (action.type) {
        case CustomersReducerActionType.START_REQUEST:
            return { ...state, isLoading: true };
        case CustomersReducerActionType.LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                nextKey: action.nextKey,
                customers: action.customers,
                role: action.role ?? state.role
            };
        case CustomersReducerActionType.LOAD_ERROR:
            return { ...state, isLoading: false, nextKey: undefined, customers: [] };
        default:
            logger.warn(
                `Expected reducer action type, but got: ${JSON.stringify(
                    action.type
                )}. CustomersPage.tsx-reducer`
            );
            return state;
    }
}

export default { reducer, initialState, ActionType: CustomersReducerActionType };

import { Typography, styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import clsx from 'clsx';
import { isNumber } from 'lib/typeguards';
import React from 'react';

const PREFIX = 'TransactionRiskCircle';

const classes = {
    root: `${PREFIX}-root`,
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-grey`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        borderRadius: '100%',
        height: theme.spacing(3),
        width: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(0.5)
    },

    [`&.${classes.green}`]: { backgroundColor: green['A700'], color: theme.palette.primary.contrastText },
    [`&.${classes.yellow}`]: { backgroundColor: orange[500], color: theme.palette.primary.contrastText },
    [`&.${classes.red}`]: { backgroundColor: red[500], color: theme.palette.primary.contrastText },
    [`&.${classes.grey}`]: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.primary.contrastText
    }
}));

interface TransactionRiskCircleProps {
    score: number;
    level: string;
}

export const TransactionRiskCircle: React.FC<TransactionRiskCircleProps> = ({ score, level }) => {
    const className = React.useMemo(() => {
        switch (level) {
            case 'normal':
                return classes.green;
            case 'elevated':
                return classes.yellow;
            case 'highest':
                return classes.red;
            default:
                return classes.grey;
        }
    }, [level]);
    if (!isNumber(score)) {
        return null;
    }
    return (
        <Root className={clsx(classes.root, className)}>
            <Typography variant="caption" color="inherit">
                {score}
            </Typography>
        </Root>
    );
};

import { Box } from '@mui/material';
import { Product, toScopedKey } from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import { EMuiGridStatus, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import { Row } from 'ui/Flex';
import { BaseMenuTab, MENU_CHANGE_SCHEMA, useMenuChange } from '../model/menu';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_PRODUCTS_UPDATE_ERROR, MESSAGE_PRODUCTS_UPDATE_SUCCESS } from 'config/messages';
import { isDefined } from 'lib/typeguards';
import { useGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import {
    EnrichedProduct,
    getProductAndProductGroupCellToCopy,
    getProductsAndProductGroupsToCopy,
    getProductsData,
    getProductsMenuChangeUpdate
} from '../model/product';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { getMenuStorageName } from '../gridStateStorageHelpers';
import { ActionsHeader } from '../ActionsHeader';
import { useProductsColumns } from '../products/useProductsColumns';
import { useMenuSelection } from '../useMenuSelection';
import { DuplicateProductDialog } from '../products/DuplicateProductDialog';
import merge from 'deepmerge';
import { useCreateProduct } from '../products/useCreateProduct';
import { useMenuDeleteControls } from '../model/useMenuDeleteControls';

type ProductsTabProps = BaseMenuTab;

const productFieldsToCopy: (keyof EnrichedProduct)[] = [
    'title',
    'shortDescription',
    'availableDays',
    'availableScenarios',
    'sort',
    'prepTimeMins',
    'perkMatchCodes',
    'allergens',
    'nutrition',
    'dynamicImagery',
    'imageUrl',
    'listingTextColour',
    'listingBackgroundColour',
    'listingBackgroundImageUrl',
    'terminalListingTextColour',
    'terminalListingBackgroundColour',
    'suggestions',
    'listingImageUrl',
    'tags',
    'price',
    'priceLevel'
];

const validationSchema = {
    sort: Yup.number().integer('Whole number only').max(999999, '6 or less digits'),
    prepTime: Yup.number().integer('Whole number only')
};

export const ProductsTab: React.FC<ProductsTabProps> = ({
    menu,
    settings,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);
    const [products, productOptions] = React.useMemo(() => {
        if (!menu || !change) {
            return [[], []];
        }
        return getProductsData(menu, change, true);
    }, [change, menu]);
    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleFocusOut,
        handleCellSelection,
        copyEnabled
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, [...productFieldsToCopy, 'productGroupName']);
    const {
        editedOrDeleted,
        handleCancelEditing,
        handleDelete,
        deleted,
        deleteEnabled,
        resetDeleted,
        isCellEditable
    } = useMenuDeleteControls(selectedIds, readyToSave, handleCancel, resetSelection);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [
            { column: 'productId' },
            { column: 'title' },
            { column: 'categoryTitle' },
            { column: 'productGroupName' }
        ],
        []
    );
    const { filterModel, renderInput } = useGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'products',
        'Search by ID, title or category'
    );

    const columns = useProductsColumns(menu, change, productOptions);

    const {
        open: createDialogOpen,
        categoryOptions,
        productOptionsWithId,
        productToDuplicate,
        handleCreate,
        handleCreateCancel
    } = useCreateProduct(menu, externalChange, selectedIds, productOptions, products);

    const handleCreateSubmit = React.useCallback(
        (categoryId: string, productId: string, price: number) => {
            const { changes } = gridRef.current?.getState() ?? {};
            const productChanges = merge(change?.products || {}, changes);
            const originalRow = products.find(item => item.productId === productId);
            const newProductChagnes = productChanges[productId] ?? {};
            const { productGroupId, modifiers } = originalRow;
            const productGroupName = changes?.productGroupName ?? originalRow.productGroupName;
            productFieldsToCopy.forEach(item => {
                if (originalRow && isDefined(originalRow[item]) && !isDefined(newProductChagnes[item])) {
                    newProductChagnes[item] = originalRow[item];
                }
            });
            const id = toScopedKey(categoryId, productId);
            setChange({
                ...change,
                products: {
                    ...productChanges,
                    [id]: {
                        ...newProductChagnes,
                        price,
                        productGroupId,
                        modifiers
                    }
                }
            });
            gridRef.current?.createItem(
                id,
                undefined,
                {
                    ...newProductChagnes,
                    price,
                    productGroupId,
                    modifiers,
                    productGroupName
                },
                'productId'
            );
            handleStatusChange(EMuiGridStatus.CHANGED);
            handleCreateCancel();
        },
        [change, gridRef, handleCreateCancel, handleStatusChange, products, setChange]
    );

    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_PRODUCTS_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState() ?? {};
            const update = getProductsMenuChangeUpdate(changes, menu, change, products, deleted);
            const result = await menuApi.updateMenuChange(menuChangeId, {
                schema: MENU_CHANGE_SCHEMA,
                ...update
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            dispatch(enqueueSnackbar(MESSAGE_PRODUCTS_UPDATE_SUCCESS, { variant: 'success' }));
            resetDeleted();
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [gridRef, menu, change, products, deleted, menuChangeId, setChange, onUpdate, dispatch, resetDeleted]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();

        if (selectedIds.length > 0) {
            const update = getProductsAndProductGroupsToCopy(products, selectedIds, menu);
            setMenuChangeToCopy({
                ...update,
                schema: MENU_CHANGE_SCHEMA
            });
        } else {
            const update = getProductAndProductGroupCellToCopy(selectedCell, menu);
            setMenuChangeToCopy({
                ...update,
                schema: MENU_CHANGE_SCHEMA
            });
        }
    }, [toggleIsCopyModalOpen, selectedIds, products, setMenuChangeToCopy, selectedCell, menu]);

    const validateProduct = React.useCallback((item: Product) => {
        const errors: Record<string, string> = {};
        if (
            isDefined(item.listingBackgroundColour) &&
            item.listingBackgroundColour !== '' &&
            isDefined(item.listingBackgroundImageUrl) &&
            item.listingBackgroundImageUrl !== ''
        ) {
            const error =
                'You cannot set both a background colour and a background image on the same product';
            errors.listingBackgroundColour = error;
            errors.listingBackgroundImageUrl = error;
        }
        const yup = Yup.object().shape(validationSchema);
        try {
            yup.validateSync(item, { abortEarly: false });
        } catch (e) {
            (e as Yup.ValidationError).inner.forEach(error => {
                errors[error.path] = error.message;
            });
        }
        if (Object.keys(errors).length) {
            return errors;
        }
        return undefined;
    }, []);

    const handleHidableCreate = React.useMemo(
        () => (settings.menu?.productByCategoryIdEditingEnabled ? handleCreate : undefined),
        [handleCreate, settings.menu?.productByCategoryIdEditingEnabled]
    );

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={handleCopyTo}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={copyEnabled}
                    onCreate={handleHidableCreate}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                <Row>{renderInput()}</Row>
            </Box>
            <MuiGrid
                rows={products}
                columns={columns}
                stateRef={gridRef}
                filterModel={filterModel}
                onStatusChange={handleStatusChange}
                validateRow={validateProduct}
                storageName={getMenuStorageName(GridStorageName.Products)}
                initialState={{ pinnedColumns: { left: ['productId'] } }}
                disableColumnFilter
                checkboxSelection
                checkboxVisibility
                onSelectionModelChange={handleSelectChange}
                selectionModel={selectedIds}
                onCellClick={handleCellSelection}
                onCellFocusOut={handleFocusOut}
                deleted={deleted}
                isCellEditable={isCellEditable}
            />
            {renderLocationPickerModal()}
            {createDialogOpen && (
                <DuplicateProductDialog
                    open={createDialogOpen}
                    onCancel={handleCreateCancel}
                    onSubmit={handleCreateSubmit}
                    categories={categoryOptions}
                    products={productOptionsWithId}
                    deafultProduct={productToDuplicate}
                />
            )}
        </React.Fragment>
    );
};

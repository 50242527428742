// TODO: move receiving segments to the debounced search or to the background
import React from 'react';
import { useDispatch } from 'react-redux';
import { segmentsApi } from 'components/audience/segmentsApi';
import {
    MESSAGE_CUSTOMER_AUDIENCE_UPDATE_ERROR,
    MESSAGE_CUSTOMER_AUDIENCE_UPDATE_SUCCESS
} from 'config/messages';
import { LegacyForm } from 'lib/LegacyForm';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { AutoForm } from 'ui/form/AutoForm';
import { createCustomerAudienceEditForm } from '../forms/createCustomerAudienceEditForm';
import { Customer, CustomerSegment } from '../models/Customer';

interface CustomersAudienceEditCardProps {
    customer: Customer;
    onClose: () => void;
    onChange: (segments: CustomerSegment[]) => void;
}

export const CustomersAudienceEditCard = (props: CustomersAudienceEditCardProps) => {
    const { onClose, customer, onChange } = props;
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    function createForm(allSegments: { _id: string; title: string }[], customerSegments: CustomerSegment[]) {
        return new LegacyForm(
            createCustomerAudienceEditForm(allSegments, customerSegments),
            async (data: { segments?: { name: string; value: string }[] }) => {
                setLoading(true);
                const segments = data.segments || [];
                const currentSegmentIds = new Set(
                    customerSegments.reduce((acc, segment) => {
                        if (segment.included) {
                            acc.push(segment._id);
                        }
                        return acc;
                    }, [])
                );
                const newSegmentIds = new Set(segments.map(segment => segment.value));
                const removedSegmentIds = Array.from(currentSegmentIds).filter(x => !newSegmentIds.has(x));
                const addedSegmentIds = Array.from(newSegmentIds).filter(x => !currentSegmentIds.has(x));

                // Check if there have been any changes
                if (removedSegmentIds.length === 0 && addedSegmentIds.length === 0) {
                    return onClose();
                }

                // For each added Segment Id make request
                const responses = await Promise.all([
                    ...addedSegmentIds.map(segmentId => segmentsApi.addToSegment(segmentId, [customer._id])),
                    ...removedSegmentIds.map(segmentId =>
                        segmentsApi.removeFromSegment(segmentId, customer._id)
                    )
                ]);

                // If all requests are successful then success, else error -- No bulk segments, api based on single user
                let requestsSuccessful = true;
                for (const response of responses) {
                    if (!response.ok) {
                        requestsSuccessful = false;
                        break;
                    }
                }

                if (!requestsSuccessful) {
                    setLoading(false);
                    dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_AUDIENCE_UPDATE_ERROR, { variant: 'error' }));
                    onClose();
                    return;
                }

                const newSegments = allSegments.map(segment => ({
                    _id: segment._id,
                    title: segment.title,
                    included: newSegmentIds.has(segment._id)
                }));
                setLoading(false);
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_AUDIENCE_UPDATE_SUCCESS, { variant: 'success' }));
                onChange(newSegments);
                onClose();
            }
        );
    }
    const [form, setForm] = React.useState(null);

    React.useEffect(() => {
        const fetchSegments = async () => {
            try {
                const allSegments = (await segmentsApi.getAll()).map(
                    (segment: { _id: string; title: string }) => ({
                        _id: segment._id,
                        title: segment.title
                    })
                );
                setForm(createForm(allSegments, customer.segments ? customer.segments : []));
            } catch (error) {
                dispatch(enqueueSnackbar(error.message, { variant: 'error' }));
            }
        };
        fetchSegments();
        // eslint-disable-next-line
    }, [dispatch]);

    return <AutoForm isLoading={loading} form={form} onClose={onClose} />;
};

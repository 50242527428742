import { TextField, styled } from '@mui/material';
import { CustomersTimelineDateFilter } from 'components/customers/modals/CustomersTimelineDateFilter';
import { formatDateRange } from 'lib/helpers';
import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import CalendarToday from '@mui/icons-material/CalendarToday';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from 'ui/buttons/IconButton';
import { isDefined } from 'lib/typeguards';

const PREFIX = 'PepperPayDateFilter';

const classes = {
    pointer: `${PREFIX}-pointer`
};

const StyledTextField = styled(TextField)({
    [`& .${classes.pointer}`]: {
        cursor: 'pointer'
    }
});

interface GridDateFilterProps {
    onChange: (date?: DateRangePickerValue) => void;
    value: DateRangePickerValue;
    shouldDisableDate?: (date: Date) => boolean;
    minDate?: Date;
    maxDate?: Date;
    clearable?: boolean;
    disabled?: boolean;
    maxRangeMonths?: number;
}

export const GridDateFilter: React.FC<GridDateFilterProps> = ({
    value,
    onChange,
    shouldDisableDate,
    clearable = true,
    minDate,
    maxDate,
    disabled,
    maxRangeMonths
}) => {
    const [anchorDateElement, setAnchorDateElement] = React.useState<HTMLElement>(null);
    const handleDateFilterListOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorDateElement(event.currentTarget);
    }, []);
    const handleDeleteDateFilter = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(undefined);
        },
        [onChange]
    );
    const handleDateFilterClose = React.useCallback(() => {
        setAnchorDateElement(null);
    }, []);
    const handleDateFilter = React.useCallback(
        (value?: DateRangePickerValue) => {
            onChange(value);
        },
        [onChange]
    );
    const date = React.useMemo(
        () => (value && value.startDate ? formatDateRange(value, true) : undefined),
        [value]
    );
    const renderButton = React.useCallback(
        () => (
            <React.Fragment>
                {value && date && clearable ? (
                    <IconButton onClick={handleDeleteDateFilter} size="large">
                        <CancelIcon />
                    </IconButton>
                ) : (
                    <CalendarToday />
                )}
                <ExpandMore />
            </React.Fragment>
        ),
        [clearable, date, handleDeleteDateFilter, value]
    );
    return (
        <React.Fragment>
            <StyledTextField
                variant="outlined"
                value={date ?? ''}
                onClick={handleDateFilterListOpen}
                InputLabelProps={{ shrink: isDefined(date) }}
                label="Select Dates"
                InputProps={{ endAdornment: renderButton(), readOnly: true, className: classes.pointer }}
                inputProps={{ className: classes.pointer }}
                disabled={disabled}
            />
            <CustomersTimelineDateFilter
                anchorElement={anchorDateElement}
                value={value}
                onSelect={handleDateFilter}
                onClose={handleDateFilterClose}
                shouldDisableDate={shouldDisableDate}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                maxRangeMonths={maxRangeMonths}
            />
        </React.Fragment>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { SettingDisplaySwitch } from '../../SettingDisplaySwitch';

export const ZonalSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplaySwitch
                    title="Production Environment"
                    checked={settings?.zonalAztec?.isProduction ?? true}
                />
                <SettingDisplayText
                    title="Authentication Brand Token"
                    text={settings?.zonalAztec?.authBrandToken}
                />
                <SettingDisplayText title="Platform Type" text={settings?.zonalAztec?.platform} />
                <SettingDisplayText title="Bundle Identifier" text={settings?.zonalAztec?.bundleIdentifier} />
                <SettingDisplayText title="Host" text={settings?.zonalAztec?.host} />
                <SettingDisplayText
                    title="Service Charge IDs"
                    text={settings?.zonalAztec?.chargeTaxIds?.join(',')}
                />
                <SettingDisplayText
                    title="Exclusive Tax IDs"
                    text={settings?.zonalAztec?.exclusiveTaxTaxIds?.join(',')}
                />
                <SettingDisplayText
                    title="Discretionary Service Charge IDs"
                    text={settings?.zonalAztec?.discretionaryChargeTaxIds?.join(',')}
                />
                <SettingDisplayText
                    title="Loyalty At POS Password"
                    text={settings?.zonalAztec?.loyaltyAtPosPassword}
                />
                <SettingDisplaySwitch
                    title="Use Open Discounts"
                    checked={settings?.zonalAztec?.iOrderOpenDiscountsEnabled}
                />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplayText
                    title="Order to Table Service ID"
                    text={settings?.zonalAztec?.ORDER_TO_TABLE_serviceId}
                />
                <SettingDisplayText
                    title="Pre-Order Service ID"
                    text={settings?.zonalAztec?.PREORDER_serviceId}
                />
                <SettingDisplayText title="Menu ID" text={settings?.zonalAztec?.menuId} />
                <SettingDisplayText
                    title="Notes Display Record ID"
                    text={settings?.zonalAztec?.notesItemDisplayRecordId}
                />
                <SettingDisplayText
                    title="Notes Item Ingredient ID"
                    text={settings?.zonalAztec?.notesItemIngredientId}
                />
                <SettingDisplayText
                    title="Notes Item Portion Type ID"
                    text={settings?.zonalAztec?.notesItemPortionTypeId}
                />
                <SettingDisplayText title="Sales Area ID" text={settings?.zonalAztec?.salesAreaId} />
                <SettingDisplayText title="Site ID" text={settings?.zonalAztec?.siteId} />
                <SettingDisplayText
                    title="Table Payment Method ID"
                    text={settings?.zonalAztec?.tablePaymentMethodId}
                />
                <SettingDisplayText
                    title="Loyalty At POS Password"
                    text={settings?.zonalAztec?.loyaltyAtPosPassword}
                />
                <SettingDisplaySwitch
                    title="Use Open Discounts"
                    checked={settings?.zonalAztec?.iOrderOpenDiscountsEnabled}
                />
            </>
        )}
    </>
);

/* eslint-disable max-len */
import React from 'react';

import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { IPerkBasketQualification, PerkDiscount } from 'components/loyalty/models/PerkModel';
import { LoyaltyScope } from 'components/loyalty/enums';
import { IAwardEnrichmentDateTimeRestriction } from 'components/loyalty/models/AwardEnrichment';
import { Option } from 'lib/types';
import { isValid } from 'date-fns';
import { isDefined, isString } from 'lib/typeguards';

import { PerkFormStep } from 'components/perks/PerksEditStepper';
import { ReedemptionFormCardContent } from './RedemptionFormContent';
import { Form, FormikBag } from 'formik';
import { roundToDecimal } from 'lib/helpers';
import { AwardRedemptionFormProps } from '.';
import { AwardTemplate } from 'components/customers/models/Award';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export interface AwardRedemptionFormData {
    token?: string;
    discount?: Partial<Omit<PerkDiscount, 'maxValue'>>;
    basketQualification?: Omit<IPerkBasketQualification, 'scope'> & {
        scope?: LoyaltyScope;
    };
    dateTimeRestriction: Omit<IAwardEnrichmentDateTimeRestriction, 'startTime' | 'daysOfWeek' | 'endTime'> & {
        startTime: string | undefined;
        endTime: string | undefined;
        daysOfWeek: string[];
    };
}

export interface AwardRedemptionFullFormData extends AwardRedemptionFormData {
    // this is UI only fields, do not forget to exclude them from the request
    dateTimeRestriction: AwardRedemptionFormData['dateTimeRestriction'] & {
        dateTimeRestictionOption: DateTimeOptionValue[];
    };
}

const dateAndTimeRestricionOptions: Option[] = [
    {
        value: 'date',
        label: 'specific start and end date'
    },
    {
        value: 'time',
        label: 'specific start and end time'
    },
    {
        value: 'day',
        label: 'particular weekday'
    }
];
export type DateTimeOptionValue = typeof dateAndTimeRestricionOptions[number]['value'];

export const awardRedemptionDefaultFormValues: AwardRedemptionFullFormData = {
    token: '',
    discount: {
        scope: undefined,
        scopeMatchCode: undefined,
        value: undefined,
        rule: undefined,
        childItemRule: undefined,
        type: undefined
    },
    basketQualification: {
        scope: undefined,
        matchCode: undefined,
        minimumItems: undefined,
        minimumValue: undefined
    },
    dateTimeRestriction: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        daysOfWeek: [],
        dateTimeRestictionOption: []
    }
};

const getAwardRedemptionDefaultFormValues = (perkType: string) => {
    if (perkType === AwardTemplate.PSEUDO_CURRENCY) {
        return {
            ...awardRedemptionDefaultFormValues,
            discount: {
                ...awardRedemptionDefaultFormValues.discount,
                value: 0.01,
                type: 'VALUE'
            }
        };
    }
    return awardRedemptionDefaultFormValues;
};

const generateDateOptions = (initialData?: AwardRedemptionFormData) => {
    if (!initialData?.dateTimeRestriction) {
        return [];
    }
    const dateFields = [];
    if (initialData?.dateTimeRestriction?.startDate) {
        dateFields.push('date');
    }
    if (initialData?.dateTimeRestriction?.startTime) {
        dateFields.push('time');
    }
    if (
        initialData?.dateTimeRestriction?.daysOfWeek &&
        initialData?.dateTimeRestriction?.daysOfWeek?.length
    ) {
        dateFields.push('day');
    }
    return dateFields;
};
const generateToggleOptions = (initialData?: AwardRedemptionFormData) => {
    if (!initialData?.basketQualification) {
        return [];
    }
    const basketQualificationFields = [];
    if (isDefined(initialData.basketQualification.minimumValue)) {
        basketQualificationFields.push('basketQualification.minimumValue');
    }

    if (isDefined(initialData.basketQualification.minimumItems)) {
        basketQualificationFields.push('basketQualification.minimumItems');
    }
    return basketQualificationFields;
};

export const RedemptionCommonForm: React.FC<AwardRedemptionFormProps> = ({
    onSubmit,
    initialData,
    isEdit,
    perkType,
    clickPrevious,
    onPrevious,
    isLoading,
    currencySymbol,
    onUpdate
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const posProvider = React.useMemo(() => settings?.posProvider, [settings]);
    const [toggledFields, setToggledFields] = React.useState<string[]>(generateToggleOptions(initialData));
    const isPseudoCurrency = React.useMemo(() => perkType === AwardTemplate.PSEUDO_CURRENCY, [perkType]);

    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                discount: Yup.object().shape({
                    value: Yup.string().required('This field is required'),
                    type: Yup.mixed()
                        .oneOf(['VALUE', 'PERCENTAGE', 'OVERRIDE'])
                        .required('This field is required'),
                    scope: Yup.mixed()
                        .oneOf(['ITEM', 'CATEGORY', 'BASKET', 'ITEM_OR_CATEGORY'])
                        .required('This field is required'),
                    scopeMatchCode: Yup.string().when('scope', {
                        is: (value: string) => value === 'BASKET',
                        then: Yup.string().notRequired(),
                        otherwise: Yup.string().required('This field is required')
                    }),
                    rule: Yup.mixed().when('scope', (value, schema) =>
                        value !== 'BASKET'
                            ? schema
                                  .oneOf([
                                      'CHEAPEST',
                                      'COSTLIEST',
                                      'ALL_ALLOCATE',
                                      'ALL',
                                      'CHEAPEST_FIRST',
                                      'COSTLIEST_FIRST'
                                  ])
                                  .required('This field is required')
                            : schema.notRequired()
                    ),
                    childItemRule:
                        (posProvider === 'IBS' || posProvider === 'DELIVERECT') &&
                        Yup.mixed().oneOf(['INCLUDE', 'EXCLUDE'])
                }),
                basketQualification: Yup.object().shape({
                    scope: Yup.mixed().oneOf(Object.values(LoyaltyScope)).required('This field is required'),
                    matchCode: Yup.string().when('scope', (scope, schema) =>
                        !isEdit && scope !== LoyaltyScope.BASKET
                            ? schema.required('This field is required')
                            : schema
                    ),
                    minimumItems: Yup.number().min(0, 'Please enter the value more than or equal to 0'),
                    minimumValue: Yup.number().min(0, 'Please enter the value more than or equal to 0')
                }),
                dateTimeRestriction: Yup.object().shape({
                    startTime: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('time'),
                        then: Yup.string().required('This field is required'),
                        otherwise: Yup.string().notRequired()
                    }),
                    endTime: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('time'),
                        then: Yup.string().required('This field is required'),
                        otherwise: Yup.string().notRequired()
                    }),
                    startDate: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('date'),
                        then: Yup.string()
                            .required('This field is required.')
                            .test('valid-date', 'Please enter valid date', value => {
                                if (value && !isValid(new Date(value))) {
                                    return false;
                                }

                                return true;
                            }),
                        otherwise: Yup.string().notRequired()
                    }),
                    endDate: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('date'),
                        then: Yup.string()
                            .required('This field is required.')
                            .test('valid-date', 'Please enter valid date', value => {
                                if (value && !isValid(new Date(value))) {
                                    return false;
                                }

                                return true;
                            }),
                        otherwise: Yup.string().notRequired()
                    }),
                    daysOfWeek: Yup.array().notRequired()
                })
            }),
        [isEdit, posProvider]
    );

    const initialValues = React.useMemo(() => {
        if (initialData) {
            const dateTime = {
                ...(initialData.dateTimeRestriction ?? {}),
                dateTimeRestictionOption: generateDateOptions(initialData)
            };
            const scopeMatchCode = Array.isArray(initialData.discount?.scopeMatchCode)
                ? initialData.discount.scopeMatchCode.join(',')
                : initialData.discount?.scopeMatchCode;
            const matchCode = Array.isArray(initialData.basketQualification?.matchCode)
                ? initialData.basketQualification.matchCode.join(',')
                : initialData.basketQualification?.matchCode;
            if (initialData.discount && initialData.discount.type === 'PERCENTAGE') {
                return {
                    ...initialData,
                    basketQualification: {
                        ...(initialData.basketQualification ?? {}),
                        matchCode
                    },
                    discount: {
                        ...initialData.discount,
                        value: initialData.discount.value * 100,
                        scopeMatchCode
                    },
                    dateTimeRestriction: dateTime
                };
            }
            return {
                ...initialData,
                basketQualification: {
                    ...(initialData.basketQualification ?? {}),
                    matchCode
                },
                discount: { ...initialData.discount, scopeMatchCode },
                dateTimeRestriction: dateTime
            };
        }
    }, [initialData]);

    React.useEffect(() => {
        if (initialData?.basketQualification) {
            setToggledFields(generateToggleOptions(initialData));
        }
    }, [initialData]);

    const handleSubmit = React.useCallback(
        (
            { dateTimeRestriction, ...data }: AwardRedemptionFullFormData,
            formikBag: FormikBag<any, AwardRedemptionFullFormData>
        ) => {
            const { dateTimeRestictionOption: _, ...dateTime } = dateTimeRestriction;
            let scopeMatchCode = data.discount.scope === 'BASKET' ? undefined : data.discount.scopeMatchCode;
            let matchCode = data.basketQualification?.matchCode;
            if (!isPseudoCurrency) {
                if (isString(scopeMatchCode)) {
                    scopeMatchCode = scopeMatchCode.split(',').map(item => item.trim());
                }
                if (isString(matchCode)) {
                    matchCode = matchCode.split(',').map(item => item.trim());
                }
            }
            onSubmit({
                ...data,
                basketQualification: data.basketQualification
                    ? {
                          ...(data.basketQualification ?? {}),
                          matchCode
                      }
                    : undefined,
                discount: {
                    ...data.discount,
                    scopeMatchCode,
                    value:
                        data.discount.type === 'PERCENTAGE'
                            ? roundToDecimal(data.discount.value / 100, 4)
                            : data.discount.value,
                    rule: data.discount.scope === 'BASKET' ? 'ALL_ALLOCATE' : data.discount.rule
                },
                dateTimeRestriction: {
                    ...dateTime,
                    daysOfWeek: dateTime.daysOfWeek ? dateTime.daysOfWeek : []
                }
            });
            formikBag.setSubmitting(false);
        },
        [isPseudoCurrency, onSubmit]
    );

    const handleUpdate = React.useCallback(
        ({ dateTimeRestriction, ...data }: AwardRedemptionFullFormData) => {
            const { dateTimeRestictionOption: _, ...dateTime } = dateTimeRestriction;
            let scopeMatchCode = data.discount.scope === 'BASKET' ? undefined : data.discount.scopeMatchCode;
            let matchCode = data.basketQualification?.matchCode;
            if (!isPseudoCurrency) {
                if (isString(scopeMatchCode)) {
                    scopeMatchCode = scopeMatchCode.split(',').map(item => item.trim());
                }
                if (isString(matchCode)) {
                    matchCode = matchCode.split(',').map(item => item.trim());
                }
            }
            onUpdate({
                type: PerkFormStep.REDEMPTION,
                values: {
                    ...data,
                    basketQualification: data.basketQualification
                        ? {
                              ...(data.basketQualification ?? {}),
                              matchCode
                          }
                        : undefined,
                    discount: {
                        ...data.discount,
                        scopeMatchCode,
                        value:
                            data.discount.type === 'PERCENTAGE'
                                ? roundToDecimal(data.discount.value / 100, 4)
                                : data.discount.value,
                        rule: data.discount.scope === 'BASKET' ? 'ALL_ALLOCATE' : data.discount.rule
                    },
                    dateTimeRestriction: {
                        ...dateTime,
                        daysOfWeek: dateTime.daysOfWeek ? dateTime.daysOfWeek : []
                    }
                }
            });
        },
        [isPseudoCurrency, onUpdate]
    );
    return (
        <MuiForm
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues || getAwardRedemptionDefaultFormValues(perkType)}
        >
            <Form>
                <ReedemptionFormCardContent
                    clickPrevious={clickPrevious}
                    onUpdate={handleUpdate}
                    isLoading={isLoading}
                    onPrevious={onPrevious}
                    currencySymbol={currencySymbol}
                    perkType={perkType}
                    isEdit={isEdit}
                    toggledFields={toggledFields}
                    onToggleChange={setToggledFields}
                />
            </Form>
        </MuiForm>
    );
};

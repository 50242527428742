import { GridCellValue, GridColTypeDef } from '@mui/x-data-grid-pro';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { GridTimeRangeCell } from 'lib/MuiGrid/time-range/GridTimeRange';
import React from 'react';
import { AdvancedAvailabilityCell } from './AdvancedAvailabilityCell';
import { AvailabilityRedirect } from './AvailabilityRedirect';
import { isTimePeriod } from '@pepperhq/menu-sdk';

const tooltip =
    'You cannot set an available time on this category due to an existing weekly availability. Please review in Weekly Availability section.';

export function CategoryTimesColumn(advancedAvailability: Set<string>): GridColTypeDef {
    const sort = (v1: GridCellValue, v2: GridCellValue) =>
        ('' + (Array.isArray(v1) && v1[0])).localeCompare('' + (Array.isArray(v2) && v2[0]));
    return {
        renderCell: props => {
            if (advancedAvailability.has('' + props.id)) {
                return <AdvancedAvailabilityCell tooltip={tooltip} />;
            }
            return renderMuiGridTextView(
                props,
                Array.isArray(props.value) && isTimePeriod(props.value[0]) ? props.value : 'All Day'
            );
        },
        renderEditCell: props =>
            advancedAvailability.has('' + props.id) ? (
                <AvailabilityRedirect />
            ) : (
                <GridTimeRangeCell {...props} />
            ),
        sortComparator: sort
    };
}

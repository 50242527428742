import React from 'react';
import { Voucher } from '../models/VoucherModel';
import { VoucherDetailsFormData } from '../forms/VoucherDetailsForm';
import { voucherApi } from '../voucherApi';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_ADD_EFFECT_VOUCHER_ERROR,
    MESSAGE_ADD_EFFECT_VOUCHER_SUCCESS,
    MESSAGE_CREATE_VOUCHER_ERROR,
    MESSAGE_CREATE_VOUCHER_SUCCESS,
    MESSAGE_DELETE_VOUCHER_ERROR,
    MESSAGE_DELETE_VOUCHER_SUCCESS
} from 'config/messages';
import { AddVoucherEffectFormData } from '../forms/VoucherAddEffectsForm';
import { rulesApi } from 'components/rules/rulesApi';
import { createBodyEffect } from '../createBodyVoucherEffect';
import { endOfDay, startOfDay } from 'date-fns';

export function useVouchersActions(resetVouchers: () => void) {
    const dispatch = useDispatch();
    const [createdVoucher, setCreatedVoucher] = React.useState<Voucher>();
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [actionsLoading, setActionsLoading] = React.useState(false);
    const [voucherToDelete, setVoucherToDelete] = React.useState<Voucher>();
    const createVoucher = React.useCallback(
        async ({
            title,
            code,
            duration: { startDate, endDate },
            numGlobalRedemptions,
            numRedemptionsPerUser
        }: VoucherDetailsFormData) => {
            setActionsLoading(true);
            const body = {
                title,
                code,
                startDate: startOfDay(startDate).toJSON(),
                endDate: endOfDay(endDate).toJSON(),
                numGlobalRedemptions: Number(numGlobalRedemptions),
                numRedemptionsPerUser: Number(numRedemptionsPerUser)
            };

            const result = await voucherApi.create({
                body
            });
            setActionsLoading(false);
            if (!result.ok) {
                dispatch(enqueueSnackbar(MESSAGE_CREATE_VOUCHER_ERROR, { variant: 'error' }));
            } else {
                dispatch(enqueueSnackbar(MESSAGE_CREATE_VOUCHER_SUCCESS, { variant: 'success' }));
                resetVouchers();
                setCreateModalOpen(false);
                setCreatedVoucher(result.body);
            }
        },
        [dispatch, resetVouchers]
    );
    const createEffect = React.useCallback(
        async (values: AddVoucherEffectFormData) => {
            if (createdVoucher) {
                const effectBody = createBodyEffect(values, createdVoucher._id);
                const result = await rulesApi.create({ body: effectBody });
                if (!result.ok) {
                    dispatch(enqueueSnackbar(MESSAGE_ADD_EFFECT_VOUCHER_ERROR, { variant: 'error' }));
                } else {
                    dispatch(enqueueSnackbar(MESSAGE_ADD_EFFECT_VOUCHER_SUCCESS, { variant: 'success' }));
                    setCreatedVoucher(undefined);
                }
            }
        },
        [createdVoucher, dispatch]
    );
    const deleteVoucher = React.useCallback(
        (item: Voucher) => () => {
            setVoucherToDelete(item);
        },
        []
    );
    const deleteVoucherConfirm = React.useCallback(async () => {
        if (voucherToDelete) {
            setActionsLoading(true);
            const result = await voucherApi.delete(voucherToDelete._id, { skipResponseBody: true });
            if (result.ok) {
                dispatch(enqueueSnackbar(MESSAGE_DELETE_VOUCHER_SUCCESS, { variant: 'success' }));
            } else {
                dispatch(enqueueSnackbar(MESSAGE_DELETE_VOUCHER_ERROR, { variant: 'error' }));
            }
            setVoucherToDelete(undefined);
            setActionsLoading(false);
            resetVouchers();
        }
    }, [dispatch, resetVouchers, voucherToDelete]);

    const closeModals = React.useCallback(() => {
        setCreatedVoucher(undefined);
        setCreateModalOpen(false);
        setVoucherToDelete(undefined);
    }, []);
    const handleCreateButtonClick = React.useCallback(() => {
        setCreateModalOpen(true);
    }, []);
    return {
        createEffect,
        createVoucher,
        createdVoucher,
        createModalOpen,
        closeModals,
        handleCreateButtonClick,
        actionsLoading,
        voucherToDelete,
        deleteVoucher,
        deleteVoucherConfirm
    };
}

import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Grid, TextField, styled } from '@mui/material';
import { AutocompleteFormField } from 'lib/form/fields/AutocompleteFormField';
import { Option } from 'lib/types';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { AwardTemplate } from '../../../components/customers/models/Award';
import { convertPointToPrice } from '../helpers';

interface VoucherAddEfectsPerkFieldsProps {
    options: Option[] | [];
    perkId: string;
    amount: string;
    setFieldValue: (field: string, value: boolean) => void;
}

const PREFIX = 'VoucherAddEfectsPerkFields';

const classes = {
    box: `${PREFIX}-box`,
    inputAmount: `${PREFIX}-inputAmount`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.box}`]: {
        height: 130
    },
    [`& .${classes.inputAmount}`]: {
        paddingTop: theme.spacing(2)
    }
}));

export const VoucherAddEfectsPerkFields: React.FC<VoucherAddEfectsPerkFieldsProps> = ({
    options,
    perkId,
    amount,
    setFieldValue
}) => {
    const [perkTemplate, setPerkTemplate] = React.useState('');
    const { perks } = useSelector((state: ApplicationState) => state.perks);

    React.useEffect(() => {
        if (perkId) {
            const perk = perks.find(item => item._id === perkId);
            if (perk) {
                if (
                    perk?.template === AwardTemplate.PSEUDO_CURRENCY ||
                    perk?.template === AwardTemplate.STAMP_CARD
                ) {
                    setFieldValue('amountRequired', true);
                } else {
                    setFieldValue('amountRequired', false);
                }
                setPerkTemplate(perk?.template);
            }
        }
    }, [perkId, perks, setFieldValue]);

    return (
        <>
            <Grid item xs={12}>
                <AutocompleteFormField name="perkId" label="Select Perk" options={options} />
            </Grid>
            {perkTemplate === AwardTemplate.PSEUDO_CURRENCY && (
                <StyledGrid
                    item
                    container
                    xs={12}
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classes.box}
                >
                    <Grid item xs={12} sm={6}>
                        <NumberFormField
                            name="amount"
                            label="Amount"
                            description="How many points would you like to grant this user on the redemption of this voucher?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={convertPointToPrice(amount)}
                            helperText="The monetary value of that amount of points"
                            disabled
                            className={classes.inputAmount}
                        />
                    </Grid>
                </StyledGrid>
            )}

            {perkTemplate === AwardTemplate.STAMP_CARD && (
                <Grid item xs={12}>
                    <NumberFormField
                        name="amount"
                        label="Amount"
                        description="How many stamps would you like to grant this user on the redemption of this voucher?"
                    />
                </Grid>
            )}
        </>
    );
};

import { useEffect, useRef } from 'react';
import { isDefined } from 'lib/typeguards';

export function useInterval(callback: () => void | Promise<void>, delay: number | null) {
    const savedCallback = useRef<() => void | undefined>();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    });
    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (isDefined(savedCallback?.current)) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

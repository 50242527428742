import React from 'react';
import { IPointOfSaleSettingsProps, itemMappingSchemeOptions } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SelectFormField } from 'lib/form/fields/SelectFormField';

export const MicrosSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Omnivore Micros API Key">
                    <TextFormField name="omnivoreMicrosApiKey" />
                </SettingDisplay>
                <SettingDisplay label="Webhook Activation Code">
                    <TextFormField name="omnivoreMicrosWebhookActivationCode" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Location ID">
                    <TextFormField name="omnivoreMicrosLocationId" />
                </SettingDisplay>
                <SettingDisplay label="Employee ID">
                    <TextFormField name="omnivoreMicrosEmployeeId" />
                </SettingDisplay>
                <SettingDisplay label="Tender Type ID">
                    <TextFormField name="omnivoreMicrosTenderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Order Type ID">
                    <TextFormField name="omnivoreMicrosPreOrderOrderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Revenue Center ID">
                    <TextFormField name="omnivoreMicrosPreOrderRevenueCenterId" />
                </SettingDisplay>
                <SettingDisplay label="Table Order Type ID">
                    <TextFormField name="omnivoreMicrosTableOrderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Table Order Revenue Center ID">
                    <TextFormField name="omnivoreMicrosTableRevenueCenterId" />
                </SettingDisplay>
                <SettingDisplay label="Pay At Table Order Type ID">
                    <TextFormField name="omnivoreMicrosPayAtTableOrderTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Pay At Table Revenue Center ID">
                    <TextFormField name="omnivoreMicrosPayAtTableRevenueCenterId" />
                </SettingDisplay>
                <SettingDisplay
                    label="Menu Category Filter"
                    description="Comma delimited list of categories to include in the menu. If blank, all are returned."
                >
                    <TextFormField name="omnivoreMicrosMenuCategoryFilter" />
                </SettingDisplay>
                <SettingDisplay
                    label="Item Level Price"
                    // eslint-disable-next-line max-len
                    description="Will only use price level for item pricing otherwise will fall back to base price, if blank all price levels are returned."
                >
                    <TextFormField name="omnivoreMicrosItemLevelPrice" />
                </SettingDisplay>
                <SettingDisplay label="Item Mapping Scheme">
                    <SelectFormField
                        name="omnivoreMicrosItemMappingScheme"
                        options={itemMappingSchemeOptions}
                    />
                </SettingDisplay>
            </>
        )}
    </>
);

import { AutoFormFields } from 'lib/LegacyForm';
import { CustomerSegment } from '../models/Customer';

export const createCustomerAudienceEditForm: (
    segments: { _id: string; title: string }[],
    currentSegments: CustomerSegment[],
    label?: string
) => AutoFormFields = (segments, currentSegments, label) => ({
    segments: {
        label: label || '',
        description: 'Select audiences you want to add a user to or remove them from.',
        type: 'multiSelect',
        options: segments.map(segment => ({ label: segment.title, value: segment._id })),
        value: currentSegments.reduce((acc, segment) => {
            if (segment.included) {
                acc.push({ label: segment.title, value: segment._id });
            }
            return acc;
        }, [])
    }
});

import React from 'react';
import { Award, AwardTemplate } from '../models/Award';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Grid, TextField, Typography } from '@mui/material';
import { getStampCard } from '../helpers';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';

interface AdditionalAwardRedeemFieldsProps {
    awards: Partial<Award>[];
    awardId?: string;
    amount?: number;
    enableRedeemButton: (enable: boolean) => void;
    buttonEnabled: boolean;
}

export const AdditionalAwardRedeemFields: React.FC<AdditionalAwardRedeemFieldsProps> = ({
    awards = [],
    awardId,
    amount = 0,
    enableRedeemButton,
    buttonEnabled
}) => {
    const currentAward = React.useMemo(() => {
        if (!awardId) {
            return undefined;
        }
        return awards.find(item => item._id === awardId);
    }, [awardId, awards]);
    const isPseudocurrency = currentAward && currentAward.template === AwardTemplate.PSEUDO_CURRENCY;
    const isStampCard = currentAward && currentAward.template === AwardTemplate.STAMP_CARD;
    const showAmount = React.useMemo(() => isPseudocurrency || isStampCard, [isPseudocurrency, isStampCard]);
    const amountDescription = React.useMemo(() => {
        if (isStampCard) {
            return 'How many stamps would you like to redeem for this user?';
        }
        if (isPseudocurrency) {
            return 'How many points would you like to redeem for this user?';
        }
        return '';
    }, [isPseudocurrency, isStampCard]);
    const pointsAfterApplying = React.useMemo(
        () => currentAward?.points?.available - (amount > 0 ? amount : 0) || 0,
        [amount, currentAward?.points?.available]
    );
    React.useEffect(() => {
        if (pointsAfterApplying < 0 && buttonEnabled) {
            enableRedeemButton(false);
        } else if (pointsAfterApplying >= 0 && !buttonEnabled) {
            enableRedeemButton(true);
        }
    }, [buttonEnabled, enableRedeemButton, pointsAfterApplying]);
    return (
        <React.Fragment>
            {showAmount && (
                <Grid item xs={6}>
                    <TextFormField
                        type="number"
                        name="amount"
                        label={<MuiFormLabel required>Amount</MuiFormLabel>}
                        description={amountDescription}
                    />
                </Grid>
            )}
            {isPseudocurrency && (
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        disabled
                        label="Current Amount"
                        value={currentAward.points.available}
                    />
                    {pointsAfterApplying >= 0 ? (
                        <Typography variant="caption" color="textSecondary">
                            After redemption: {pointsAfterApplying}
                        </Typography>
                    ) : (
                        <Typography variant="caption" color="error">
                            A customer cannot have negative award points
                        </Typography>
                    )}
                </Grid>
            )}
            {isStampCard && (
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        disabled
                        label="Current Amount"
                        value={getStampCard(currentAward.points)}
                    />
                    {pointsAfterApplying >= 0 ? (
                        <Typography variant="caption" color="textSecondary">
                            After redemption:{' '}
                            {getStampCard({
                                ...currentAward.points,
                                available: pointsAfterApplying
                            })}
                        </Typography>
                    ) : (
                        <Typography variant="caption" color="error">
                            A customer cannot have negative award points
                        </Typography>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
};

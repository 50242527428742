import { Crud, HttpClient, httpClient, HttpClientResponse, RequestOptions } from 'lib/HttpClient';
import { Perk } from './models/PerkModel';

class PerkApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'perks');
    }
    update(perkId: string, options: RequestOptions) {
        return this.httpClient.put(`${process.env.API_SERVER_URL}/pointperks/${perkId}`, options);
    }
    grantAward(perkId: string, userId: string) {
        return this.httpClient.post(`${process.env.API_SERVER_URL}/pointperks/${perkId}/awards`, {
            body: { userId }
        });
    }
    getPointPerk(perkId: string, options: RequestOptions) {
        return this.httpClient.get(`${process.env.API_SERVER_URL}/perks/${perkId}`, {
            ...options,
            headers: { 'x-api-version': 5 }
        });
    }
    getPointPerks(options: RequestOptions) {
        return this.httpClient.get(`${process.env.API_SERVER_URL}/perks`, {
            ...options,
            headers: { 'x-api-version': 5 }
        });
    }
    getAllPointPerks(): HttpClientResponse<{ items: Perk[] }> {
        return this.httpClient.get(`${process.env.API_SERVER_URL}/perks`, {
            headers: { 'x-api-version': 5 }
        });
    }
    reset(perkId: string, options: RequestOptions) {
        return this.httpClient.post(`${process.env.API_SERVER_URL}/pointperks/${perkId}/reset`, {
            ...options,
            headers: { 'x-api-version': 5 }
        });
    }
    delete(id: string, options?: RequestOptions) {
        return this.httpClient.delete(`${process.env.API_SERVER_URL}/pointperks/${id}`, options);
    }
    createImpact(options: RequestOptions) {
        return this.httpClient.post(`${process.env.API_SERVER_URL}/impacts`, options);
    }
    createRules(options: RequestOptions) {
        return this.httpClient.post(`${process.env.API_SERVER_URL}/rules`, options);
    }
}

export const perkApi = new PerkApi(httpClient);

import React from 'react';
import { Location } from '../models/LocationModel';
import { Panel } from 'ui/Panel';
import { Grid, IconButton, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Row } from 'ui/Flex';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_COPIED_TO_CLIPBOARD } from 'config/messages';

interface LocationWebLinksProps {
    currentLocation: Location;
}

export const LocationWebLinks: React.FC<LocationWebLinksProps> = ({ currentLocation }) => {
    const dispatch = useDispatch();
    const { tenant } = useSelector((state: ApplicationState) => state.auth);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const domain = React.useMemo(
        () => (settings?.web?.domainName ? `https://${settings?.web?.domainName}` : process.env.WEB_APP_URL),
        [settings?.web?.domainName]
    );
    const preOrder = React.useMemo(
        () => `${domain}/${tenant?.tenantId}/${currentLocation?._id}/po`,
        [currentLocation?._id, domain, tenant?.tenantId]
    );
    const ott = React.useMemo(
        () => `${domain}/${tenant?.tenantId}/${currentLocation?._id}/ott`,
        [currentLocation?._id, domain, tenant?.tenantId]
    );
    const pat = React.useMemo(
        () => `${domain}/${tenant?.tenantId}/${currentLocation?._id}/pat/pay`,
        [currentLocation?._id, domain, tenant?.tenantId]
    );
    const journey = React.useMemo(
        () => `${domain}/${tenant?.tenantId}/${currentLocation?._id}/journey`,
        [currentLocation?._id, domain, tenant?.tenantId]
    );
    const handleCopy = React.useCallback(
        (value: string) => () => {
            navigator.clipboard.writeText(value);
            dispatch(enqueueSnackbar(MESSAGE_COPIED_TO_CLIPBOARD, { variant: 'info' }));
        },
        [dispatch]
    );
    return (
        <Panel title="Web App Links" divider>
            <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm={6}>
                    <Row gutter valign="center" align="space-between">
                        <Link sx={{ wordBreak: 'break-word' }} href={preOrder} target="_blank">
                            Preorder
                        </Link>
                        <IconButton onClick={handleCopy(preOrder)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Row gutter valign="center" align="space-between">
                        <Link sx={{ wordBreak: 'break-word' }} href={journey} target="_blank">
                            Journey
                        </Link>
                        <IconButton onClick={handleCopy(journey)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Row gutter valign="center" align="space-between">
                        <Link sx={{ wordBreak: 'break-word' }} href={ott} target="_blank">
                            Order to Table
                        </Link>
                        <IconButton onClick={handleCopy(ott)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Row gutter valign="center" align="space-between">
                        <Link sx={{ wordBreak: 'break-word' }} href={pat} target="_blank">
                            Pay at Table
                        </Link>
                        <IconButton onClick={handleCopy(pat)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Row>
                </Grid>
            </Grid>
        </Panel>
    );
};

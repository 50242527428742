import React from 'react';
import { EOrderPaymentType, IOrder, IOrderPayment } from '../model/Order';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { isDefined } from 'lib/typeguards';
import { Row } from 'ui/Flex';
import { OrderRefundSummary } from './OrderRefundSummary';
import { Option } from 'lib/types';
import { OrderRefundPaymentMethod } from './OrderRefundPaymentMethod';
import { useCustomRefund } from '../hooks/useCustomRefund';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getCurrencyString } from 'lib/helpers';
import { OrderRefundReasons } from './OrderRefundReasons';
import { EOrderRefundType, useOrderRefund } from '../hooks/useOrderRefund';
import { useCalculatedRefund } from '../hooks/useCalculatedRefund';
import { OrderRefundItemsSelector } from './OrderRefundItemsSelector';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { useCalculatedRefundWarning } from '../hooks/useCalculatedRefundWarning';
import { EGAEventName, useGAHelpers } from 'lib/hooks/useGAHelper';
import { PaymentRefundConfirmationDialog } from '../order-details/PaymentRefundConfirmationDialog';

interface OrderRefundV2Props {
    payment: IOrderPayment;
    onClose: () => void;
    order: IOrder;
    onRefund: (amount?: number, reason?: string, note?: string) => void;
    loading: boolean;
}

export const refundTypeOptions = [
    { value: EOrderRefundType.Custom, label: 'Custom amount' },
    { value: EOrderRefundType.Calculated, label: 'Calculated amount' }
];

export const OrderRefund: React.FC<OrderRefundV2Props> = ({ payment, onClose, order, onRefund, loading }) => {
    const { logUserEvent } = useGAHelpers();
    const [customAmount, formattedCustomAmount, customAmountChange, customAmountBlur] = useCustomRefund();
    const { itemsList, calculatedAmount, selectedItems, onSelectionChange } = useCalculatedRefund(
        order,
        payment.itemIds
    );
    const { refundType, reason, amount, notes, isRefundable, refundableAmount } = useOrderRefund(
        payment,
        order,
        formattedCustomAmount,
        calculatedAmount
    );
    const { open, onBridgeSelectionChange, onCofirmSelection, onDiscardSelection } =
        useCalculatedRefundWarning(onSelectionChange);
    const isCalculatedRefund = React.useMemo(
        () => refundType.value === EOrderRefundType.Calculated,
        [refundType.value]
    );
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const paymentUser = React.useMemo(
        () =>
            isDefined(payment.userId)
                ? order.users.find(({ userId }) => payment.userId === userId)
                : undefined,
        [order.users, payment.userId]
    );
    const [isRefundPaymentOpen, setIsRefundPaymentOpen] = React.useState(false);
    const handleRefundAll = React.useCallback(() => {
        setIsRefundPaymentOpen(true);
    }, []);
    const handleRefundAllClosed = React.useCallback(() => {
        setIsRefundPaymentOpen(false);
    }, []);
    const handleRefundAllSuccess = React.useCallback(() => {
        onRefund();
        logUserEvent(EGAEventName.RefundAllAttempt);
        setIsRefundPaymentOpen(false);
    }, [logUserEvent, onRefund]);
    const title = React.useMemo(() => {
        switch (payment.type) {
            case EOrderPaymentType.Applepay: {
                return `Refund ${paymentUser.firstName} ${paymentUser.lastName} apple pay payment`;
            }
            case EOrderPaymentType.Googlepay: {
                return `Refund ${paymentUser.firstName} ${paymentUser.lastName} google pay payment`;
            }
            case EOrderPaymentType.Card: {
                return `Refund ${paymentUser.firstName} ${paymentUser.lastName} card payment`;
            }
            case EOrderPaymentType.Terminal: {
                return 'Refund a quickpad card payment';
            }
            case EOrderPaymentType.Giftcard: {
                if (!paymentUser) {
                    return 'Refund a quickpad giftcard payment';
                }
                return `Refund ${paymentUser.firstName} ${paymentUser.lastName} giftcard payment`;
            }
            default:
                return '';
        }
    }, [payment.type, paymentUser]);
    const hasPaymentMethodData = React.useMemo(
        () => payment.cardBrand && payment.cardLast4,
        [payment.cardBrand, payment.cardLast4]
    );
    const renderSelectOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    const handleRefund = React.useCallback(() => {
        const refundEventName =
            refundType.value === EOrderRefundType.Calculated
                ? EGAEventName.CalculatedRefundAttempt
                : EGAEventName.CustomRefundAttempt;
        logUserEvent(refundEventName);
        onRefund(amount, reason.value, notes.value);
    }, [amount, logUserEvent, notes.value, onRefund, reason.value, refundType.value]);
    return (
        <Box display="flex" sx={{ maxWidth: isCalculatedRefund ? undefined : '600px', maxHeight: '430px' }}>
            {isCalculatedRefund && (
                <OrderRefundItemsSelector
                    itemsList={itemsList}
                    selectedItems={selectedItems}
                    onSelectionChange={onBridgeSelectionChange}
                    showSuggestions={!!payment.itemIds?.length}
                />
            )}
            <Box maxWidth="600px">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary" sx={{ mb: 3 }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Select value={refundType.value} onChange={refundType.onChange} fullWidth>
                                {refundTypeOptions.map(renderSelectOption)}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={6} alignItems="flex-end" display="flex">
                        <OrderRefundSummary payment={payment} order={order} />
                    </Grid>
                    {hasPaymentMethodData && (
                        <Grid item xs={6}>
                            <OrderRefundPaymentMethod brand={payment.cardBrand} last4={payment.cardLast4} />
                        </Grid>
                    )}
                    <Grid item xs={hasPaymentMethodData ? 6 : 12}>
                        <TextField
                            value={isCalculatedRefund ? calculatedAmount : customAmount}
                            disabled={loading || isCalculatedRefund}
                            onChange={customAmountChange}
                            onBlur={customAmountBlur}
                            label={
                                <>
                                    Amount to refund{' '}
                                    <Box component="span" color="primary.main">
                                        *
                                    </Box>
                                </>
                            }
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                                startAdornment: settings?.region?.currencySymbol
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OrderRefundReasons
                            value={reason.value}
                            onChange={reason.onChange}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            label="Notes"
                            variant="outlined"
                            fullWidth
                            value={notes.value ?? ''}
                            onChange={notes.onChange}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Row>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={loading}
                                onClick={handleRefundAll}
                            >
                                Refund all
                            </Button>
                            <Row gutter flex={1} align="flex-end">
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={!isRefundable || loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRefund}
                                >
                                    Refund {amount ? getCurrencyString(amount, order.currencyCode) : ''}
                                </Button>
                            </Row>
                        </Row>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmDialog
                open={open}
                noBackdropClick
                onConfirm={onCofirmSelection}
                onCancel={onDiscardSelection}
                title="Just so you know"
                confirmLabel="OK, understood"
                cancelLabel="Cancel"
                content="This list calculates the value of the refund, and doesn't refund specific items."
            />
            <PaymentRefundConfirmationDialog
                open={isRefundPaymentOpen}
                onClose={handleRefundAllClosed}
                onAgree={handleRefundAllSuccess}
                loading={loading}
                amount={getCurrencyString(refundableAmount, order.currencyCode)}
                paymentType={payment.type}
            />
        </Box>
    );
};

import { MenuItem, Select } from '@mui/material';
import { OrderScenario } from '@pepperhq/menu-sdk';
import { OrderScenarioLabels } from 'components/order/model/Order';
import { Option } from 'lib/types';
import React from 'react';

interface MenuOrderScenariosFilter {
    onChange: (value?: string) => void;
    value?: string;
}

const options = Object.values(OrderScenario)
    .map(scenario => ({
        label: OrderScenarioLabels[scenario],
        value: scenario
    }))
    .filter(({ value }) => value !== OrderScenario.PAYATPOS);

const ALL_SCENARIOS = 'ALL_SCENARIOS';

export const OrderingScenariosFilter: React.FC<MenuOrderScenariosFilter> = ({ value, onChange }) => {
    const handleScenarioChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ALL_SCENARIOS) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );

    return (
        <Select fullWidth value={value ?? ALL_SCENARIOS} onChange={handleScenarioChange} variant="outlined">
            <MenuItem value={ALL_SCENARIOS}>All Scenarios</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Tooltip, Typography, styled } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { MainLayout } from 'layouts/MainLayout';
import AnchorLayoutCard from 'components/content/AnchorLayoutCard';
import { contentApi } from 'components/content/contentApi';
import { MobileContentRenderer } from 'components/content/MobileContentRenderer';
import { ContentLayout } from 'components/content/models/ContentLayout';
import { ContentModule } from 'components/content/models/ContentModule';
import { EmptyState } from 'components/utils/emptyState';
import {
    MESSAGE_MOBILE_CONTENT_LAYOUT_DELETE_ERROR,
    MESSAGE_MOBILE_CONTENT_LAYOUT_DELETE_SUCCESS
} from 'config/messages';
import {
    MOBILE_CONTENT_CONFIRMATION,
    MOBILE_CONTENT_LAYOUT_CONFIG,
    MOBILE_CONTENT_TEMPLATE_SELECTION
} from 'config/routes';
import EmptyStateImage from 'static/app-content-empty-state.svg';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { Column } from 'ui/Flex';
import { MobileLayout } from 'ui/MobileLayout';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';

const PREFIX = 'mobile-content-index-page';
const StyledBox = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    [`& .${classes.layoutCardWrapper}`]: {
        flex: 1,
        paddingRight: theme.spacing(4)
    },
    [`& .${classes.layoutPreviewWrapper}`]: {
        height: 'auto',
        position: 'sticky',
        alignSelf: 'flex-start',
        top: 0
    },
    [`& .${classes.createNewCard}`]: {
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.layoutCard}`]: {
        boxSizing: 'border-box',
        cursor: 'pointer',
        position: 'relative',
        transition: '0.15s all ease-in',
        height: 'initial',
        '&:hover': {
            backgroundColor: 'rgba(92,92,92,0.24)'
        }
    },
    [`& .${classes.button}`]: {
        marginTop: theme.spacing(1)
    },
    [`& .${classes.buttonWrapper}`]: {
        padding: theme.spacing(0, 2),
        width: '100%'
    },
    [`& .${classes.layoutsContainer}`]: {
        display: 'grid',
        gridGap: theme.spacing(2),
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
    [`& .${classes.modalDivider}`]: {
        margin: theme.spacing(2, 0)
    }
}));
const classes = {
    layoutCardWrapper: `${PREFIX}-layoutCardWrapper`,
    layoutPreviewWrapper: `${PREFIX}-layoutPreviewWrapper`,
    createNewCard: `${PREFIX}-createNewCard`,
    layoutCard: `${PREFIX}-layoutCard`,
    sectionTitle: `${PREFIX}-sectionTitle`,
    button: `${PREFIX}-button`,
    buttonWrapper: `${PREFIX}-buttonWrapper`,
    layoutsContainer: `${PREFIX}-layoutsContainer`,
    modalDivider: `${PREFIX}-modalDivider`
};

const getModulesFromLayout = async (layout: ContentLayout): Promise<ContentModule[]> => {
    // Get Modules
    const modules: ContentModule[] = [];
    if (!!layout && !!layout.anchor && layout.anchor.moduleIds) {
        // Get all modules async
        await Promise.all(
            layout.anchor.moduleIds.map(async moduleId => {
                const moduleRes = await contentApi.modules.get(moduleId);
                if (moduleRes.ok) {
                    modules.push(moduleRes.body);
                }
            })
        );
    }
    return modules;
};

const LayoutsPage = () => {
    const [selectedLayoutModules, setSelectedLayoutModules] = useState([]);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [activeLayout, setActiveLayout] = useState(null);
    const [layouts, setLayouts] = React.useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        async function load() {
            setIsLoading(true);
            const layoutsResult = await contentApi.layouts.getList();
            if (!layoutsResult.ok) {
                throw new Error('Failed to load layout data');
            }
            const rowLayouts: ContentLayout[] = layoutsResult.body;
            if (!!rowLayouts && rowLayouts.length > 0) {
                // Find active layout
                const activeLayoutResult = await contentApi.layouts.getActiveLayout();
                let currentLayout: ContentLayout;
                if (activeLayoutResult.ok) {
                    currentLayout = activeLayoutResult.body;
                    setActiveLayout(currentLayout);
                    setSelectedLayout(currentLayout);
                }
                const sortedLayout = rowLayouts.sort((a, b) =>
                    a.id === b.id ? 0 : a.id === currentLayout.id ? -1 : 1
                );
                const modules = await getModulesFromLayout(currentLayout || sortedLayout[0]);
                setLayouts(sortedLayout);
                setSelectedLayoutModules(modules);
            }
            setIsLoading(false);
        }
        load();
    }, []);

    const handleGetStartedClick = React.useCallback(
        () => history.push(MOBILE_CONTENT_TEMPLATE_SELECTION),
        [history]
    );

    const handleLayoutClick = React.useCallback(
        async (layout: ContentLayout) => {
            if (selectedLayout && selectedLayout.id === layout.id) {
                return;
            }
            setSelectedLayoutModules(null); // Reset Modules
            setSelectedLayout(null); // Disable buttons

            // Set Modules
            const modules = await getModulesFromLayout(layout);

            // Set Selected ID to enable buttons, if any modules were returned
            if (modules.length >= 0) {
                setSelectedLayoutModules(modules);
                setSelectedLayout(layout);
            }
        },
        [selectedLayout]
    );

    const handleEditClick = React.useCallback(() => {
        history.push(
            `${MOBILE_CONTENT_LAYOUT_CONFIG.replace(/:layoutId/g, selectedLayout && selectedLayout.id)}`
        );
    }, [history, selectedLayout]);

    const handleActivateClick = React.useCallback(() => {
        history.push(
            `${MOBILE_CONTENT_CONFIRMATION.replace(/:layoutId/g, selectedLayout && selectedLayout.id)}`
        );
    }, [history, selectedLayout]);

    const handleDeleteClick = React.useCallback(() => {
        setDeleteModalOpen(true);
    }, []);

    const deleteModalClose = React.useCallback(() => setDeleteModalOpen(false), []);

    const handleDeleteLayout = React.useCallback(async () => {
        const deleteResult = await contentApi.layouts.delete(selectedLayout && selectedLayout.id);
        if (!deleteResult.ok) {
            setDeleteModalOpen(false);
            return dispatch(
                enqueueSnackbar(MESSAGE_MOBILE_CONTENT_LAYOUT_DELETE_ERROR, { variant: 'error' })
            );
        }

        // Update layouts
        const layoutIndex = layouts.findIndex(layout => selectedLayout && selectedLayout.id === layout.id);
        layouts.splice(layoutIndex, 1);

        setSelectedLayout(null);
        setSelectedLayoutModules([]);
        setDeleteModalOpen(false);
        dispatch(enqueueSnackbar(MESSAGE_MOBILE_CONTENT_LAYOUT_DELETE_SUCCESS, { variant: 'success' }));
    }, [dispatch, layouts, selectedLayout]);

    return (
        <React.Fragment>
            <MainLayout
                pageName="Mobile Content"
                pageDescription="View and edit the layouts you've created for your application"
            >
                {(!layouts || layouts.length <= 0) && !isLoading && (
                    <EmptyState
                        headerText="Start building content for your mobile app"
                        paragraphText="Before you go live, you'll need to choose and publish a layout for your app based on one of our templates."
                        buttonText="Choose a layout"
                        imageUrl={EmptyStateImage}
                        onClick={handleGetStartedClick}
                    />
                )}
                {((layouts && layouts.length > 0) || isLoading) && (
                    <StyledBox>
                        <Column className={classes.layoutCardWrapper}>
                            <Box className={classes.layoutsContainer}>
                                {/* CREATE NEW LAYOUT CARD */}
                                <Card className={classes.layoutCard} onClick={handleGetStartedClick}>
                                    <CardContent className={classes.createNewCard}>
                                        <Add />
                                        <Typography variant="button">CREATE NEW</Typography>
                                    </CardContent>
                                </Card>
                                {/* DRAFT & ACTIVE LAYOUT CARDS */}
                                {isLoading && (
                                    <SkeletonComponent count={11} variant="rectangular" height={18 * 8} />
                                )}
                                {!isLoading &&
                                    layouts.map(layout => (
                                        <AnchorLayoutCard
                                            key={layout.id}
                                            handleLayoutClick={handleLayoutClick}
                                            selected={selectedLayout && selectedLayout.id === layout.id}
                                            layout={layout}
                                            active={activeLayout && activeLayout.id === layout.id}
                                        />
                                    ))}
                            </Box>
                        </Column>
                        <Column align="center" className={classes.layoutPreviewWrapper}>
                            {/* PHONE PREVIEW OF LAYOUT */}
                            <MobileLayout>
                                <MobileContentRenderer
                                    isLoading={isLoading}
                                    modules={selectedLayoutModules}
                                    anchor={selectedLayout && selectedLayout.anchor}
                                    noModulesText={
                                        selectedLayoutModules && selectedLayoutModules.length === 0
                                            ? 'Select a layout'
                                            : undefined
                                    }
                                />
                            </MobileLayout>
                            <Tooltip title={!!selectedLayout ? '' : 'You must select a layout to edit it'}>
                                <span className={classes.buttonWrapper}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        disabled={!selectedLayout || isLoading}
                                        className={classes.button}
                                        onClick={handleEditClick}
                                    >
                                        Edit
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip
                                title={!!selectedLayout ? '' : 'You must select a layout to activate it'}
                            >
                                <span className={classes.buttonWrapper}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        disabled={!selectedLayout || isLoading}
                                        className={classes.button}
                                        onClick={handleActivateClick}
                                    >
                                        Activate
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip
                                title={
                                    !selectedLayout
                                        ? 'You must select a layout to delete it'
                                        : !!activeLayout &&
                                          selectedLayout &&
                                          selectedLayout.id === activeLayout.id
                                        ? 'You cannot delete an active layout'
                                        : ''
                                }
                            >
                                <span className={classes.buttonWrapper}>
                                    <Button
                                        variant={
                                            !selectedLayout ||
                                            (!!activeLayout && selectedLayout.id === activeLayout.id)
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        color="primary"
                                        fullWidth
                                        disabled={
                                            !selectedLayout ||
                                            (!!activeLayout && selectedLayout.id === activeLayout.id) ||
                                            isLoading
                                        }
                                        className={classes.button}
                                        onClick={handleDeleteClick}
                                    >
                                        Delete
                                    </Button>
                                </span>
                            </Tooltip>
                        </Column>
                    </StyledBox>
                )}
            </MainLayout>
            <DeleteDialog
                title="Delete layout?"
                content="Are you sure you want to delete this layout? This action is not reversible!"
                open={deleteModalOpen}
                onClose={deleteModalClose}
                onDelete={handleDeleteLayout}
            />
        </React.Fragment>
    );
};

export default LayoutsPage;

import { MenuItem, Select } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';

interface MenuZonesFilter {
    onChange: (value?: string) => void;
    value?: string;
    zones: string[];
}
export const ALL_ZONES = 'ALL_ZONES';

export const OrderingZonesFilter: React.FC<MenuZonesFilter> = ({ value, zones, onChange }) => {
    const options = React.useMemo(() => {
        if (zones) {
            return Object.values(zones).map(zone => ({
                label: zone,
                value: zone
            }));
        }

        return [];
    }, [zones]);

    const handleZoneChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ALL_ZONES) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );

    return (
        <Select fullWidth value={value ?? ALL_ZONES} onChange={handleZoneChange} variant="outlined">
            <MenuItem value={ALL_ZONES}>All zones</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};

import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { VoucherDetailsForm, VoucherDetailsFormData } from '../forms/VoucherDetailsForm';

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: theme.spacing(60)
}));

interface CreateVoucherModalProps {
    open: boolean;
    isLoading: boolean;
    onClose?: () => void;
    onSubmit: (voucher: VoucherDetailsFormData) => void;
    initialData?: Partial<VoucherDetailsFormData>;
}

export const CreateVoucherModal: React.FunctionComponent<CreateVoucherModalProps> = props => {
    const { open, onClose, onSubmit, isLoading, initialData } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <VoucherDetailsForm
                title="Create a Voucher"
                onSubmit={onSubmit}
                onClose={onClose}
                isLoading={isLoading}
                initialData={initialData}
            />
        </StyledMuiModal>
    );
};

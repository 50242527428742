import { Tooltip, Typography, styled } from '@mui/material';
import React from 'react';

const PREFIX = 'AdvancedAvailabilityCell';

const classes = {
    root: `${PREFIX}-root`,
    typography: `${PREFIX}-typography`
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    [`& .${classes.root}`]: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.grey[100]
    },

    [`& .${classes.typography}`]: {
        lineHeight: 'inherit'
    }
}));

interface AdvancedAvailabilityCellProps {
    tooltip?: string;
}

export const AdvancedAvailabilityCell: React.FC<AdvancedAvailabilityCellProps> = ({ tooltip }) => (
    <StyledTooltip title={tooltip ?? ''}>
        <div className={classes.root}>
            <Typography className={classes.typography} align="center" variant="body2">
                Advanced availability applied
            </Typography>
        </div>
    </StyledTooltip>
);

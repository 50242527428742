import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, styled, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface MuiBreadcrumb {
    label: string;
    url: string;
}

interface MuiBreadcrumbsProps {
    items?: MuiBreadcrumb[];
    title: string;
    naked?: boolean;
    rootLabel?: string;
}

const StyledLink = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'underline'
    }
});

function MuiBreadcrumbsPure(props: MuiBreadcrumbsProps) {
    const { items, title, rootLabel, naked } = props;
    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {!naked && rootLabel && (
                <StyledLink to="/" color="textPrimary">
                    {rootLabel}
                </StyledLink>
            )}
            {Array.isArray(items)
                ? items.map(breadcrumb => (
                      <StyledLink
                          key={`breadcrumb-${breadcrumb.label}`}
                          to={breadcrumb.url}
                          color="textPrimary"
                      >
                          {breadcrumb.label}
                      </StyledLink>
                  ))
                : null}
            <Typography
                maxWidth="20em"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                color="primary"
            >
                {title}
            </Typography>
        </Breadcrumbs>
    );
}

export const MuiBreadcrumbs = React.memo(MuiBreadcrumbsPure);

import * as React from 'react';
import { Location, locationActionToLabel, LocationButton } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import { Panel } from 'ui/Panel';
import { createLocationButtonForm, LocationButtonFormResponse } from '../forms/createLocationButtonForm';
import { locationApi } from '../LocationApi';
import { LocationButtonCardComponent } from './LocationButtonCardComponent';

export interface LocationButtonCardrops {
    location: Location;
    setCurrentLocation: (location: Location) => void;
}

export class LocationButtonCard extends React.Component<LocationButtonCardrops, { isEditing: boolean }> {
    state = {
        isEditing: false
    };

    handleSubmit = async (data: any) => {
        const locationId = this.props.location._id;
        const buttons: LocationButton[] = data.buttons.map((button: LocationButtonFormResponse) => ({
            title: button.title,
            action: button.action,
            style: button.style,
            properties: button.properties
        }));
        const response = await locationApi.update(locationId, {
            body: {
                buttons
            }
        });
        if (response.ok) {
            this.updateForm(response.body);
            this.props.setCurrentLocation(response.body);
        }
        this.setState({ isEditing: false });
    };
    componentDidMount() {
        this.updateForm(this.props.location);
    }
    updateForm = (data: Location) => {
        const { buttons } = data;
        const newButtons =
            buttons &&
            buttons.map((button: LocationButton) => ({
                ...button,
                actionLabel: locationActionToLabel[button.action]
            }));
        this.form.update({ buttons: newButtons });
    };
    toggleState = () => {
        this.setState(({ isEditing }) => ({ isEditing: !isEditing }));
        this.updateForm(this.props.location);
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    form = new LegacyForm(createLocationButtonForm, this.handleSubmit);

    render() {
        const { isEditing } = this.state;
        return (
            <Panel title="In App Location Buttons" showAction={!isEditing} onClick={this.toggleState} divider>
                {isEditing ? (
                    <AutoForm onClose={this.toggleState} form={this.form} />
                ) : (
                    <LocationButtonCardComponent location={this.props.location} />
                )}
            </Panel>
        );
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { getTime } from 'date-fns';
import { Crud, HttpClient, httpClient, QueryParameter, RequestOptions } from 'lib/HttpClient';
import { isEmptyString, isString } from 'lib/typeguards';
import { v4 } from 'uuid';

interface StandaloneRefundBody {
    amount: number;
    reason: string;
    note?: string;
}

class PepperPayApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'accounts', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
    createLinks(accountId: string, options?: RequestOptions) {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.post(`${this.api}/accounts/${accountId}/links`, { ...options, headers });
    }
}

class PepperPayTransactionsSearchApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'pepper/transaction/search', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
}
class PepperPayTransactionApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'pepper/transaction', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'Cache-Control': 'max-age=600'
        });
    }
}

class PepperPayDisputesApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'disputes', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
    close(disputeId: string, locationId: string) {
        return this.httpClient.post(`${this.api}/disputes/${disputeId}/close`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            },
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }
}

class PepperPayOperationsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'operations', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    async getReportList(nextKey?: string) {
        const queryParameters = [
            { key: 'namespace', value: 'REPORT' },
            { key: 'name', value: 'CREATE_REPORT' }
        ];
        if (nextKey) {
            queryParameters.push({ key: 'startKey', value: nextKey });
        }

        return this.getList({
            queryParameters
        });
    }

    async getOperation(id: string) {
        return this.get(id);
    }
}

class PepperPaySchedulesApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'reportSchedules', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    getSchedulesListItems(body: { page: Record<string, string | number> }) {
        return this.create({
            url: '/search',
            body
        });
    }

    deleteSchedule(id: string, locationId: string) {
        return this.delete(id, {
            skipResponseBody: true,
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }

    createSchedule(input: {
        appId: string;
        reportType: string;
        columns: string[];
        locationId: string;
        name: string;
        notificationEmail: string;
        interval: string;
        intervalTrigger: number;
    }) {
        return this.create({
            body: {
                interval: input.interval,
                intervalTrigger: input.intervalTrigger,
                name: input.name,
                notificationEmail: input.notificationEmail,
                reportData: {
                    report_type: input.reportType,
                    parameters: {
                        columns: input.columns,
                        connected_account: input.appId
                    }
                }
            },

            queryParameters: [{ key: 'locationId', value: input.locationId }]
        });
    }
}

class PepperPayReportsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'reports', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    async createReport(input: {
        appId: string;
        reportType: string;
        date: { startDate: Date; endDate: Date };
        columns: string[];
    }) {
        return this.create({
            body: {
                report_type: input.reportType,
                parameters: {
                    interval_start: Math.floor(getTime(new Date(input.date.startDate)) / 1000),
                    interval_end: Math.floor(getTime(new Date(input.date.endDate)) / 1000),
                    columns: input.columns,
                    connected_account: input.appId
                }
            },
            headers: {
                'x-pepper-req-nonce': v4()
            }
        });
    }
}

class PepperPayFileApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'files', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    downloadFile(fileId: string) {
        return fetch(`${this.api}/files/${fileId}/download`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                Authorization: `Bearer ${this.httpClient.getToken()}`,
                Accept: 'text/csv;charset=UTF-8'
            }
        });
    }
}

class PepperPayRadarListApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'radar/lists/search', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    getRadarLists(locationId: string) {
        return this.create({
            body: {
                limit: 30
            },
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }
}

class PepperPayRadarListItemsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'radar/lists', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    getRadarListItems(listId: string, locationId: string, body?: { starting_after?: string; limit: number }) {
        return this.create({
            url: `/${listId}/search`,
            body,
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }

    createNewListItem(listId: string, body: { value: string }, locationId: string) {
        return this.create({
            url: `/${listId}`,
            body,
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }

    deleteListItem(listId: string, itemId: string, locationId: string) {
        return this.delete(itemId, {
            url: `/${listId}`,
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }
}

class PepperPayPaymentIntentsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'paymentIntents', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    cancel(listId: string, locationId?: string) {
        let queryParameters: QueryParameter[] | undefined = undefined;
        if (isString(locationId) && !isEmptyString(locationId)) {
            queryParameters = [{ key: 'locationId', value: locationId }];
        }
        return this.create({
            url: `/${listId}/cancel`,
            queryParameters
        });
    }
    refund(id: string, body: StandaloneRefundBody, locationId?: string) {
        let queryParameters: QueryParameter[] | undefined = undefined;
        if (isString(locationId) && !isEmptyString(locationId)) {
            queryParameters = [{ key: 'locationId', value: locationId }];
        }
        return this.create({
            url: `/${id}/refunds`,
            body,
            queryParameters
        });
    }
    capture(id: string, amount: number) {
        return this.create({
            url: `/${id}/capture`,
            body: { amount }
        });
    }
}

class PepperPayAccountsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.PAY_ACCOUNT_SERVICE_URL, 'accounts', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    getAccounts(accountsIds: string[], locationId?: string) {
        const queryParameters: QueryParameter[] | undefined = [
            { key: 'accounts', value: accountsIds.join(',') }
        ];
        if (isString(locationId) && !isEmptyString(locationId)) {
            queryParameters?.push({ key: 'locationId', value: locationId });
        }
        return this.getList({
            url: '',
            queryParameters
        });
    }
}

export const pepperPayApi = {
    general: new PepperPayApi(httpClient),
    transactionsSearch: new PepperPayTransactionsSearchApi(httpClient),
    transactions: new PepperPayTransactionApi(httpClient),
    operations: new PepperPayOperationsApi(httpClient),
    schedules: new PepperPaySchedulesApi(httpClient),
    reporting: new PepperPayReportsApi(httpClient),
    file: new PepperPayFileApi(httpClient),
    disputes: new PepperPayDisputesApi(httpClient),
    radarList: new PepperPayRadarListApi(httpClient),
    radarListItems: new PepperPayRadarListItemsApi(httpClient),
    paymentIntents: new PepperPayPaymentIntentsApi(httpClient),
    accounts: new PepperPayAccountsApi(httpClient)
};

import { OperationStatus } from 'components/operation/models/OperationModel';
import {
    MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS
} from 'config/messages';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { EOrderPaymentType, IOrder } from '../model/Order';
import { orderApi } from '../orderApi';
import { roundToDecimal } from 'lib/helpers';

export function useRefundAll(order: IOrder | undefined, onRefund: () => void) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleRefundError = React.useCallback(() => {
        setOpen(false);
        setLoading(false);
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' }));
    }, [dispatch]);
    const handleRefundSuccess = React.useCallback(() => {
        setOpen(false);
        setLoading(false);
        onRefund();
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS, { variant: 'success' }));
    }, [dispatch, onRefund]);
    const onRefundAllClick = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        if (!loading) {
            setOpen(false);
        }
    }, [loading]);
    const onRefundAll = React.useCallback(async () => {
        if (!order) {
            return;
        }
        setLoading(true);
        const result = await orderApi.refundOC2(String(order.id), {
            refundTips: true
        });
        if (!result.ok) {
            return handleRefundError();
        }
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING, { variant: 'info' }));
        const operationResult = await orderApi.pollOC2Operation(result.body.id, result.body.etaMs);
        if (operationResult.status === OperationStatus.ERROR) {
            return handleRefundError();
        }
        handleRefundSuccess();
    }, [order, dispatch, handleRefundSuccess, handleRefundError]);
    const modalProps = React.useMemo(
        () => ({
            open,
            onAgree: onRefundAll,
            onClose: handleClose,
            loading
        }),
        [handleClose, loading, onRefundAll, open]
    );
    const isRefundable = React.useMemo(() => {
        if (order && Array.isArray(order.payments)) {
            return (
                order.payments.reduce((acc, item) => {
                    if (
                        item.type !== EOrderPaymentType.Cash &&
                        item.type !== EOrderPaymentType.PosCard &&
                        !item.deleted
                    ) {
                        acc = roundToDecimal(acc) + roundToDecimal(item.amount);
                    }
                    return acc;
                }, 0) > 0
            );
        }
    }, [order]);
    return { onRefundAll: onRefundAllClick, isRefundable, refundAllLoading: loading, modalProps };
}

import React from 'react';
import { Grid, styled } from '@mui/material';
import { Location } from 'components/location/models/LocationModel';
import { isValidCoordinate } from 'components/utils/isValidCoordinate';
import { Map } from 'lib/Map';
import { CardViewTextRow } from 'ui/viewComponents';

const StyledMap = styled(Map)(({ theme }) => ({
    height: theme.spacing(35),
    borderRadius: theme.shape.borderRadius
}));

interface LocationAddressCardComponentProps {
    location: Location;
}

export const LocationAddressCardComponent = (props: LocationAddressCardComponentProps) => {
    const {
        geo,
        address: { address, country, postcode, town, summary }
    } = props.location;
    const [lng, lat] = geo;
    const point = { lat, lng };

    return (
        <Grid container spacing={2}>
            <Grid item sm={isValidCoordinate([lng, lat]) ? 6 : 12} xs={12}>
                <Grid container spacing={1}>
                    <CardViewTextRow title="1st Line of Address" value={address} />
                    <CardViewTextRow title="City" value={town} />
                    <CardViewTextRow title="Country" value={country} />
                    <CardViewTextRow title="Postcode" value={postcode} />
                    <CardViewTextRow
                        title="Extra Information"
                        value={summary}
                        tooltip="If your address doesn't fit the usual form, you can use this to add to it. An example of this is a building name."
                    />
                </Grid>
            </Grid>
            {isValidCoordinate([lng, lat]) && (
                <Grid item sm={6} xs={12}>
                    <StyledMap center={point} width={0} markers={[point]} />
                </Grid>
            )}
        </Grid>
    );
};

import React from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { FormControl, FormHelperText, Grid, InputLabel, Popover, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { MuiTheme } from 'config/theme';
import { rgbaToHex } from 'lib/helpers';
import { isString } from 'lib/typeguards';

export type RGBAValue =
    | string
    | {
          r: number;
          g: number;
          b: number;
          a?: number;
      };

interface ColorFormFieldProps {
    label?: React.ReactNode;
    description?: string;
    opacity?: boolean;
    valueFormatter?: (value: RGBAValue | string) => string | RGBAValue;
}

const PREFIX = 'ColorFormField';

const classes = {
    colorWrapper: `${PREFIX}-colorWrapper`,
    color: `${PREFIX}-color`,
    swatch: `${PREFIX}-swatch`,
    wrapper: `${PREFIX}-wrapper`,
    root: `${PREFIX}-root`,
    dark: `${PREFIX}-dark`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`& .${classes.colorWrapper}`]: {
        width: theme.spacing(6),
        height: theme.spacing(3),
        position: 'relative',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius
    },
    [`& .${classes.color}`]: {
        width: '100%',
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: theme.shape.borderRadius,
        border: `1px ${theme.palette.divider} solid`
    },
    [`& .${classes.swatch}`]: {
        width: '100%',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
        background: theme.palette.background.paper,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        borderRadius: '1px',
        display: 'inline-flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        cursor: 'pointer'
    }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.wrapper}`]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: theme.shape.borderRadius
    },
    [`& .${classes.root}`]: {
        backgroundColor: '#fff'
    },
    [`& .${classes.dark}`]: {
        backgroundColor: '#eee'
    }
}));

const StyledInputLabel = styled(InputLabel)(() => ({
    '&[data-shrink="true"]': {
        transform: 'translate(0, -6px) scale(0.8)'
    },
    transform: 'translate(0, 16px)'
}));

const BackgroundForOpacity = () => (
    <StyledGrid container spacing={0} className={classes.wrapper}>
        <Grid item xs={6} className={classes.root} />
        <Grid item xs={6} className={clsx(classes.root, classes.dark)} />
        <Grid item xs={6} className={clsx(classes.root, classes.dark)} />
        <Grid item xs={6} className={classes.root} />
    </StyledGrid>
);

const defaultValueFormatter = (value: RGBAValue) => value;

export const ColorFormField: React.SFC<FieldAttributes<ColorFormFieldProps>> = ({
    label,
    placeholder: _placeholder,
    description,
    opacity,
    valueFormatter,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <ColorFormFieldComponent
                label={label}
                description={description}
                opacity={opacity}
                valueFormatter={valueFormatter}
                {...childProps}
            />
        )}
    </Field>
);

const ColorFormFieldComponent: React.FC<FieldProps & ColorFormFieldProps> = ({
    field,
    meta,
    label,
    description,
    opacity,
    valueFormatter = defaultValueFormatter
}) => {
    const [colorPickerAnchor, setColourPickerAnchor] = React.useState(null);
    const [color, setColor] = React.useState(field.value || MuiTheme.palette.primary.main);
    const isError = meta.touched && !!meta.error;
    const handleClick = React.useCallback(
        (e: React.MouseEvent) => setColourPickerAnchor(e.currentTarget),
        []
    );
    const handleClose = React.useCallback(() => setColourPickerAnchor(null), []);

    const handleChange = React.useCallback(
        (value: ColorResult) => {
            setColor(value.rgb);
            field.onChange({ target: { value: valueFormatter(value.rgb), name: field.name } });
        },
        [field, valueFormatter]
    );
    const colorString = isString(color) ? color : rgbaToHex(color);

    return (
        <FormControl fullWidth error={meta.touched && !!meta.error}>
            <StyledInputLabel shrink>{label}</StyledInputLabel>
            <StyledDiv>
                <div className={classes.swatch} onClick={handleClick}>
                    <div className={classes.colorWrapper}>
                        <BackgroundForOpacity />
                        <div className={classes.color} style={{ backgroundColor: colorString }} />
                    </div>
                    <Typography>{colorString}</Typography>
                </div>
                <Popover
                    open={!!colorPickerAnchor}
                    onBackdropClick={handleClose}
                    anchorEl={colorPickerAnchor}
                >
                    <ChromePicker disableAlpha={!opacity} color={color} onChangeComplete={handleChange} />
                </Popover>
            </StyledDiv>
            {(isError || description) && (
                <FormHelperText sx={{ ml: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};

import React from 'react';
import { Grid } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { PasswordStrengthFormField } from 'lib/form/fields/PasswordStrengthFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';

export interface PasswordResetConfirmFormData {
    password: string;
    confirmPassword: string;
}

interface PasswordResetConfirmFormProps {
    onSubmit: (data: PasswordResetConfirmFormData) => void;
    error?: string;
}

const initialValues: PasswordResetConfirmFormData = {
    password: '',
    confirmPassword: ''
};

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password should contain at least 8 characters')
        .required('This field is required.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match.')
        .required('This field is required.')
});

export const PasswordResetConfirmForm: React.FC<PasswordResetConfirmFormProps> = ({ onSubmit }) => {
    const handleKeyDown = React.useCallback(
        submitForm => (e: React.KeyboardEvent) => e.keyCode === 13 && submitForm(),
        []
    );
    return (
        <MuiForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PasswordStrengthFormField
                                name="password"
                                label={<MuiFormLabel required>New Password</MuiFormLabel>}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFormField
                                name="confirmPassword"
                                type="password"
                                label={<MuiFormLabel required>Confirm New Password</MuiFormLabel>}
                                description="Confirm Your Password"
                                onKeyDown={handleKeyDown(submitForm)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Row flex={1} align="flex-end">
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Submit
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};

import { styled } from '@mui/material';
import { isNumber } from 'lib/typeguards';
import React from 'react';

const WORKSPACE_WIDTH = 800;
const WORKSPACE_HEIGHT = 500;

const Wrapper = styled('div')({
    width: `${WORKSPACE_WIDTH}px`,
    height: `${WORKSPACE_HEIGHT}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const ImageContainer = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0px 0px 0px 2px rgba(0,0,0,0.5)'
});

const Image = styled('img')({
    width: '100%',
    height: '100%'
});

const Preview = styled('div')({
    boxShadow: '0px 0px 0px 9999em rgba(0,0,0,0.5)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
});

interface ImagePickerPreviewProps {
    src: string;
    aspect?: number;
}

export const ImagePickerPreview: React.FC<ImagePickerPreviewProps> = ({ src, aspect }) => {
    const imageRef = React.useRef<HTMLImageElement>();
    const workspaceRef = React.useRef<HTMLDivElement>();
    const [imageSize, setImageSize] = React.useState({ height: 0, width: 0 });
    const [frameSize, setFrameSize] = React.useState({ height: 0, width: 0 });
    const onLoad = React.useCallback(() => {
        const imageRects = imageRef.current?.getClientRects();
        const divRects = workspaceRef.current?.getClientRects();
        if (imageRects && divRects) {
            let ratio = 1;
            const originalHeight = imageRef.current?.naturalHeight;
            const originalWidth = imageRef.current?.naturalWidth;
            const containerHeight = workspaceRef.current?.clientHeight;
            const containerWidth = workspaceRef.current?.clientWidth;
            const widthDiv = originalWidth / containerWidth;
            const heightDiv = originalHeight / containerHeight;
            if (widthDiv > heightDiv) {
                ratio = widthDiv;
            } else {
                ratio = heightDiv;
            }
            const imageHeight = originalHeight / ratio;
            const imageWidth = originalWidth / ratio;
            setImageSize({ height: imageHeight, width: imageWidth });
            if (isNumber(aspect)) {
                const imageAspect = imageWidth / imageHeight;
                if (imageAspect > aspect) {
                    setFrameSize({ height: imageHeight, width: imageHeight * aspect });
                } else {
                    setFrameSize({ height: imageWidth / aspect, width: imageWidth });
                }
            }
        } else {
            throw new Error('Cannot display an image');
        }
    }, [aspect]);
    return (
        <Wrapper ref={workspaceRef}>
            <ImageContainer style={imageSize}>
                <Image src={src} ref={imageRef} onLoad={onLoad} />{' '}
                {isNumber(aspect) && <Preview style={frameSize} />}
            </ImageContainer>
        </Wrapper>
    );
};

import React from 'react';
import logger from 'lib/logger';
import { perkApi } from 'components/loyalty/PerkApi';
import { Perk, isPerkData } from 'components/loyalty/models/PerkModel';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencySymbol } from 'store/manifest/manifestAction';
import { PerksActionType } from 'store/perks/perksActionTypes';
import { ApplicationState } from 'store/store';
import { PERKS_ITEMS_PER_PAGE } from 'components/perks/PerksGrid';

const DEFAULT_QUERY = { limit: PERKS_ITEMS_PER_PAGE };

export const usePepperPerksData = () => {
    const dispatch = useDispatch();

    const { perks, loading, indexToReset, indexToDelete } = useSelector(
        (state: ApplicationState) => state.perks
    );

    const [searchValue, setSearchValue] = React.useState('');

    const handleSearchValueChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }, []);

    React.useEffect(() => {
        async function load() {
            let next: string;
            let endOfData = false;
            let loadedPerks: Perk[] = [];

            while (!endOfData) {
                try {
                    dispatch({ type: PerksActionType.START_REQUEST });

                    const query = next ? { ...DEFAULT_QUERY, startKey: next } : DEFAULT_QUERY;
                    const res = await getPerks(query);

                    loadedPerks = [...loadedPerks, ...res.perks];

                    next = res.nextKey;

                    if (!next) {
                        endOfData = true;
                    }

                    getCurrencySymbol()(dispatch);
                } catch (e) {
                    logger.error(e.message);
                    dispatch({ type: PerksActionType.LOAD_ERROR });
                }
            }

            dispatch({
                perks: loadedPerks,
                type: PerksActionType.LOAD_SUCCESS
            });
        }

        load();
    }, [dispatch]);

    return {
        perks,
        loading,
        searchValue,
        indexToReset,
        indexToDelete,
        handleSearchValueChange
    };
};

const getPerks = async (options?: {
    [key: string]: string | number;
}): Promise<{
    nextKey: string;
    perks: Perk[];
}> => {
    const response = await perkApi.getPointPerks({
        queryParameters: Object.entries(options).map(([key, value]: [string, string]) => ({ key, value }))
    });

    if (response.ok) {
        if (isPerkData(response.body)) {
            return {
                perks: response.body.items,
                nextKey: response.body.page.nextKey
            };
        }
        throw new Error(`Expected PerkData but got ${JSON.stringify(response.body)}`);
    }
    throw new Error(response.body.message);
};

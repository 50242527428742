import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { OpeningHoursFormField } from 'lib/form/fields/OpeningHoursFormField';
import React from 'react';
import { parseTimeRange } from '../helpers';
import { Location } from '../models/LocationModel';

interface IProps {
    location: Location;
    onSubmit: (workingHours: Record<EWeekDay, string>) => Promise<any>;
    onClose: () => void;
}

enum EWeekDay {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

type DAY_FIELD = { field: EWeekDay; label: string };

const DAYS: DAY_FIELD[] = [
    {
        field: EWeekDay.MONDAY,
        label: 'Monday'
    },
    {
        field: EWeekDay.TUESDAY,
        label: 'Tuesday'
    },
    {
        field: EWeekDay.WEDNESDAY,
        label: 'Wednesday'
    },
    {
        field: EWeekDay.THURSDAY,
        label: 'Thursday'
    },
    {
        field: EWeekDay.FRIDAY,
        label: 'Friday'
    },
    {
        field: EWeekDay.SATURDAY,
        label: 'Saturday'
    },
    {
        field: EWeekDay.SUNDAY,
        label: 'Sunday'
    }
];

export const LocationOpeningHoursEditForm: React.FC<IProps> = ({ location, onClose, onSubmit }) => {
    const [isWeekdayClosed, setIsWeekDayClosed] = React.useState<Record<EWeekDay, boolean>>(() => {
        const closedDays = Object.entries(location.openingHours).reduce<Record<EWeekDay, boolean>>(
            (acc, [day, workingHours]) => {
                if (workingHours === 'closed') {
                    acc[day as EWeekDay] = true;
                } else {
                    acc[day as EWeekDay] = false;
                }

                return acc;
            },
            {
                [EWeekDay.MONDAY]: false,
                [EWeekDay.TUESDAY]: false,
                [EWeekDay.WEDNESDAY]: false,
                [EWeekDay.THURSDAY]: false,
                [EWeekDay.FRIDAY]: false,
                [EWeekDay.SATURDAY]: false,
                [EWeekDay.SUNDAY]: false
            }
        );

        return closedDays;
    });

    const handleSubmit = React.useCallback(
        (values: Record<EWeekDay, string>) => {
            const valuesToUpdate = { ...values };

            Object.entries(isWeekdayClosed).forEach(([weekday, isClosed]) => {
                if (isClosed) {
                    valuesToUpdate[weekday as EWeekDay] = 'closed';
                } else {
                    const { start, end } = parseTimeRange(valuesToUpdate[weekday as EWeekDay]);

                    if (!start || !end) {
                        valuesToUpdate[weekday as EWeekDay] = '';
                    }
                }
            });

            onSubmit(valuesToUpdate);
            onClose();
        },
        [isWeekdayClosed, onClose, onSubmit]
    );
    const form = useFormik({
        onSubmit: handleSubmit,
        initialValues: {
            ...location.openingHours
        }
    });

    const handleSetToClosed = React.useCallback(
        (field: EWeekDay) => (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
            setIsWeekDayClosed(prevValue => ({ ...prevValue, [field]: value }));
        },
        []
    );
    const renderDayField = React.useCallback(
        (field: DAY_FIELD) => (
            <Box marginBottom={1.5} display="flex" alignItems="center" key={field.field}>
                <OpeningHoursFormField
                    name={field.field}
                    label={
                        <Box>
                            <Typography>{field.label}</Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isWeekdayClosed[field.field]}
                                        onChange={handleSetToClosed(field.field)}
                                    />
                                }
                                label="Closed"
                                labelPlacement="end"
                                sx={{
                                    marginTop: -0.75
                                }}
                            />
                        </Box>
                    }
                />
            </Box>
        ),
        [handleSetToClosed, isWeekdayClosed]
    );

    return (
        <FormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>
                {DAYS.map(renderDayField)}
                <Box width="100%" marginTop={2} display="flex" justifyContent="flex-end" columnGap={1}>
                    <Button disabled={form.isSubmitting} variant="outlined" color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button disabled={form.isSubmitting} variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </Box>
            </form>
        </FormikProvider>
    );
};

import { AutoFormFields } from 'lib/LegacyForm';

export const createLocationImagesForm = (): AutoFormFields => ({
    mainImageUrl: {
        label: 'Banner',
        type: 'image',
        aspect: 600 / 260,
        description: 'This will be used to represent the location throughout the app.',
        unremovable: true
    },
    listingImageUrl: {
        label: 'Thumbnail',
        type: 'image',
        aspect: 465 / 496,
        description:
            "This will be used to represent the location within lists such as a user's favourited locations",
        unremovable: true
    }
});

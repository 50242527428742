import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    FilterOptionsState,
    TextField,
    Typography,
    createFilterOptions
} from '@mui/material';
import { ReadOnlyOverlay } from 'lib/form/ReadOnlyOverlay';
import { Option } from 'lib/types';
import React from 'react';

const filter = createFilterOptions<Option>();

interface TieredLoyaltyTierAudienceSelectProps {
    value: string;
    loading?: boolean;
    onCreateAudience: () => void;
    onChange: (value: string) => void;
    readOnly?: boolean;
    excludeAudiences?: string[];
    options: Option[];
}

export const TieredLoyaltyTierAudienceSelect: React.FC<TieredLoyaltyTierAudienceSelectProps> = ({
    value,
    loading,
    onCreateAudience,
    onChange,
    readOnly,
    excludeAudiences,
    options
}) => {
    const availableAudiences = React.useMemo(
        () => options.filter(item => value === item.value || !excludeAudiences?.includes(item.value)),
        [options, value, excludeAudiences]
    );
    const renderAutocompleteInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                InputProps={{
                    readOnly,
                    sx: { bgcolor: readOnly ? 'divider' : undefined },
                    ...params.InputProps
                }}
                label="Audience"
                variant="outlined"
            />
        ),
        [readOnly]
    );
    const getOptionKey = React.useCallback((option: Option) => option.value, []);
    const handleAudienceChange = React.useCallback(
        (_: never, newValue: Option) => {
            if (newValue?.value === null) {
                onCreateAudience();
            } else {
                onChange(newValue.value);
            }
        },
        [onChange, onCreateAudience]
    );
    const selectedAudienceOption = React.useMemo(
        () => options?.find(item => item.value === value),
        [options, value]
    );
    const filterOptions = React.useCallback((options: Option[], params: FilterOptionsState<Option>) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
            filtered.push({
                label: '+ Create a new audience',
                value: null,
                color: 'primary'
            });
        }

        return filtered;
    }, []);
    const renderOption = React.useCallback(
        (props: any, option: Option) => (
            <Box {...props}>
                <Typography color={option.color}>{option.label}</Typography>
            </Box>
        ),
        []
    );
    return (
        <React.Fragment>
            <Autocomplete
                options={availableAudiences}
                renderInput={renderAutocompleteInput}
                autoComplete
                getOptionKey={getOptionKey}
                disableClearable
                value={selectedAudienceOption ?? null}
                disabled={loading}
                onChange={handleAudienceChange}
                readOnly={!!readOnly}
                sx={{ opacity: readOnly ? 0.8 : undefined }}
                filterOptions={filterOptions}
                renderOption={renderOption}
            />
            {!!readOnly && <ReadOnlyOverlay />}
        </React.Fragment>
    );
};

import { tenantsApi } from 'components/tenants/tenantsApi';
import { MESSAGE_APP_ID_CREATION_FAILED, MESSAGE_APP_ID_CREATION_SUCCESSFUL } from 'config/messages';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';

export interface IAppIdsDetails {
    loading?: boolean;
    listLoading?: boolean;
    ids?: string[];
}

export function useMerchantAppIds() {
    const dispatch = useDispatch();
    const { tenant } = useSelector((state: ApplicationState) => state.auth);
    const [appIdDetails, setAppIdDetails] = React.useState<IAppIdsDetails>({});
    const getAppIds = React.useCallback(() => {
        setAppIdDetails(oldValues => ({ ...oldValues, listLoading: true }));
        tenantsApi
            .get(tenant.tenantId)
            .then(response => {
                setAppIdDetails(oldValues => ({ ...oldValues, ids: response.body.applicationIds }));
            })
            .catch(() => {
                dispatch(
                    enqueueSnackbar('Unexpected error occurred while getting app IDs', { variant: 'error' })
                );
            })
            .finally(() => {
                setAppIdDetails(oldValues => ({ ...oldValues, listLoading: false }));
            });
    }, [dispatch, tenant.tenantId]);
    React.useEffect(() => {
        getAppIds();
    }, [getAppIds]);

    const handleAddAppId = React.useCallback(
        (value: string) => {
            setAppIdDetails(oldValues => ({ ...oldValues, loading: true }));
            tenantsApi
                .createAppId(tenant.tenantId, value)
                .then(res => {
                    if (res.ok) {
                        setAppIdDetails(oldValues => ({ ...oldValues, ids: res.body.applicationIds }));
                        dispatch(enqueueSnackbar(MESSAGE_APP_ID_CREATION_SUCCESSFUL, { variant: 'success' }));
                    } else {
                        dispatch(
                            enqueueSnackbar(res.body?.message || MESSAGE_APP_ID_CREATION_FAILED, {
                                variant: 'error'
                            })
                        );
                    }
                })
                .finally(() => {
                    setAppIdDetails(oldValues => ({ ...oldValues, loading: false }));
                });
        },
        [dispatch, tenant.tenantId]
    );

    return { appIdDetails, handleAddAppId };
}

import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { ELoyaltyTierState } from '../model/loyalty-tier';
import { Button, FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material';
import { titleize } from 'lib/helpers';
import { Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';

interface TieredLoyaltyStateModalProps {
    onClose: () => void;
    open: boolean;
    tierState: ELoyaltyTierState;
    readyToActivate?: boolean;
    onSubmit: (state: string) => void;
    loading?: boolean;
}

export const TieredLoyaltyStateModal: React.FC<TieredLoyaltyStateModalProps> = ({
    onClose,
    open,
    tierState,
    readyToActivate,
    loading,
    onSubmit
}) => {
    const [state, setState] = React.useState(tierState ?? '');
    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setState(e.target.value);
    }, []);
    const handleClick = React.useCallback(() => {
        onSubmit(state);
    }, [onSubmit, state]);
    React.useEffect(() => {
        setState(tierState);
    }, [tierState]);
    const isError = React.useMemo(
        () => !readyToActivate && state !== ELoyaltyTierState.INACTIVE,
        [readyToActivate, state]
    );
    const disabled = React.useMemo(
        () => state === tierState || isError || state === '' || loading,
        [isError, loading, state, tierState]
    );
    return (
        <MuiModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        Change your Tier scheme state
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth error={isError} margin="none">
                        <Select variant="standard" value={state ?? ''} fullWidth onChange={handleChange}>
                            <MenuItem value={ELoyaltyTierState.INACTIVE}>
                                {titleize(ELoyaltyTierState.INACTIVE)}
                            </MenuItem>
                            <MenuItem value={ELoyaltyTierState.REALTIME}>
                                {titleize(ELoyaltyTierState.REALTIME)}
                            </MenuItem>
                            <MenuItem value={ELoyaltyTierState.SCHEDULED}>
                                {titleize(ELoyaltyTierState.SCHEDULED)}
                            </MenuItem>
                            <MenuItem value={ELoyaltyTierState.SCHEDULED_AND_REALTIME}>
                                {titleize(ELoyaltyTierState.SCHEDULED_AND_REALTIME)}
                            </MenuItem>
                        </Select>
                        {isError && (
                            <FormHelperText sx={{ mx: 0 }} error>
                                At least one metric and listener is required to enable the tiered loyalty.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Row gutter flex={1} align="flex-end">
                        <Button variant="outlined" color="secondary" onClick={onClose}>
                            Close
                        </Button>
                        <LoadingButton
                            variant="contained"
                            onClick={handleClick}
                            disabled={disabled}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Row>
                </Grid>
            </Grid>
        </MuiModal>
    );
};

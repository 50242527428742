/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, styled } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { FormikValues } from 'formik';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { IPOSLocation, menuPosApi } from 'components/menu/MenuPosApi';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import {
    isSeparatedIntegerValid,
    parseIntOrNull,
    parseIntOrUndefined,
    stringOrNull as blankAsNull
} from 'lib/helpers';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { AlohaSettingsDisplay } from './PointOfSale/Aloha/AlohaSettingsDisplay';
import { AlohaSettingsEdit } from './PointOfSale/Aloha/AlohaSettingsEdit';
import { ComtrexSettingsDisplay } from './PointOfSale/Comtrex/ComtrexSettingsDisplay';
import { ComtrexSettingsEdit } from './PointOfSale/Comtrex/ComtrexSettingsEdit';
import { IBSSettingsDisplay } from './PointOfSale/IBS/IBSSettingsDisplay';
import { IBSSettingsEdit } from './PointOfSale/IBS/IBSSettingsEdit';
import { LightspeedSettingsDisplay } from './PointOfSale/Lightspeed/LightspeedSettingsDisplay';
import { LightspeedSettingsEdit } from './PointOfSale/Lightspeed/LightspeedSettingsEdit';
import { MicrosSettingsDisplay } from './PointOfSale/Micros/MicrosSettingsDisplay';
import { MicrosSettingsEdit } from './PointOfSale/Micros/MicrosSettingsEdit';
import { RevelSettingsDisplay } from './PointOfSale/Revel/RevelSettingsDisplay';
import { RevelSettingsEdit } from './PointOfSale/Revel/RevelSettingsEdit';
import { SquareSettingsDisplay } from './PointOfSale/Square/SquareSettingsDisplay';
import { SquareSettingsEdit } from './PointOfSale/Square/SquareSettingsEdit';
import { ZonalSettingsDisplay } from './PointOfSale/Zonal/ZonalSettingsDisplay';
import { ZonalSettingsEdit } from './PointOfSale/Zonal/ZonalSettingsEdit';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { DeliverectSettingsDisplay } from './PointOfSale/Deliverect/DeliverectSettingsDisplay';
import {
    DeliverectSettingsEdit,
    isDeliverectMenuServiceType
} from './PointOfSale/Deliverect/DeliverectSettingsEdit';
import { GoodtillSettingsDisplay } from './PointOfSale/Goodtill/GoodtillSettingsDisplay';
import { GoodtillSettingsEdit } from './PointOfSale/Goodtill/GoodtillSettingsEdit';
import { menusApi } from 'components/menu-manager/MenusApi';
import { MESSAGE_MENU_REFRESH } from 'config/messages';
import { isUndefined } from '@pepperhq/menu-sdk/dist/libs/typeUtils';
import { getPublicAndPrivateSettings } from 'store/settings/settingsActions';
import { EGoodtillPriceSource } from './PointOfSale/Goodtill/goodtill';
import { SettingDisplayText } from './SettingDisplayText';
import { ApplicationState } from 'store/store';
import { TevalisSettingsDisplay } from './PointOfSale/Tevalis/TevalisSettingsDisplay';
import { TevalisSettingsEdit } from './PointOfSale/Tevalis/TevalisSettingsEdit';

const PREFIX = 'PointOfSaleSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`,
    identityChip: `${PREFIX}-identityChip`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(1)
    },
    [`& .${classes.identityChip}`]: {
        marginRight: theme.spacing(0.5)
    }
}));

export interface IPointOfSaleSettingsProps {
    locationId?: string;
    settings?: IPublicAndPrivateSettings;
    globalSettings?: IPublicAndPrivateSettings;
    onSettingsSaved?: ILocationScopedSettingsComponentProps['onSettingsSaved'];
    posLocations?: IPOSLocation[];
    hasInternalSettingsWritePermission?: boolean;
    hasInternalSettingsReadPermission?: boolean;
}

export enum EPointOfSaleProvider {
    REVEL = 'REVEL',
    PEPPER = 'PEPPER',
    IBS = 'IBS',
    LAVU = 'LAVU',
    POINTONE = 'POINTONE',
    SQUARE = 'SQUARE',
    LIGHTSPEED = 'LIGHTSPEED',
    NOBLY = 'NOBLY',
    OMNIVORE_MICROS_SIMPHONY = 'OMNIVORE_MICROS_SIMPHONY',
    OMNIVORE_MICROS_3700 = 'OMNIVORE_MICROS_3700',
    OMNIVORE_NCR_ALOHA = 'OMNIVORE_NCR_ALOHA',
    COMTREX = 'COMTREX',
    ZONAL_AZTEC = 'ZONAL_AZTEC',
    DELIVERECT = 'DELIVERECT',
    GOODTILL = 'GOODTILL',
    TEVALIS = 'TEVALIS',
    NONE = 'NONE'
}

const posProviderOptions: Option[] = [
    { label: 'Omnivore Micros Simphony', value: EPointOfSaleProvider.OMNIVORE_MICROS_SIMPHONY },
    { label: 'Omnivore Micros 3700', value: EPointOfSaleProvider.OMNIVORE_MICROS_3700 },
    { label: 'Omnivore NCR Aloha', value: EPointOfSaleProvider.OMNIVORE_NCR_ALOHA },
    { label: 'IBS Access', value: EPointOfSaleProvider.IBS },
    { label: 'Revel', value: EPointOfSaleProvider.REVEL },
    { label: 'Lightspeed', value: EPointOfSaleProvider.LIGHTSPEED },
    { label: 'Comtrex', value: EPointOfSaleProvider.COMTREX },
    { label: 'Zonal Aztec', value: EPointOfSaleProvider.ZONAL_AZTEC },
    { label: 'Square', value: EPointOfSaleProvider.SQUARE },
    { label: 'Deliverect', value: EPointOfSaleProvider.DELIVERECT },
    { label: 'Goodtill', value: EPointOfSaleProvider.GOODTILL },
    { label: 'Tevalis', value: EPointOfSaleProvider.TEVALIS }
];

enum ERefreshSchedule {
    EVERY_2_HOURS = 'Every2Hours',
    DAILY_4_AM = 'Daily4AM'
}

const refreshScheduleLabels: Record<string, string> = {
    [ERefreshSchedule.DAILY_4_AM]: 'Daily at 4am',
    [ERefreshSchedule.EVERY_2_HOURS]: 'Every 2 hours'
};

const refreshScheduleOptions = [
    { label: 'Default', value: null },
    {
        value: ERefreshSchedule.DAILY_4_AM,
        label: refreshScheduleLabels[ERefreshSchedule.DAILY_4_AM]
    },
    {
        value: ERefreshSchedule.EVERY_2_HOURS,
        label: refreshScheduleLabels[ERefreshSchedule.EVERY_2_HOURS]
    }
];

export enum EItemMappingScheme {
    MAP_FIRST_MATCHED_PRICELEVEL = 'MAP_FIRST_MATCHED_PRICELEVEL',
    MAP_MATCHED_PRICELEVELS_AS_VARIANTS = 'MAP_MATCHED_PRICELEVELS_AS_VARIANTS',
    MAP_FIRST_MATCHED_PRICELEVEL_ID = 'MAP_FIRST_MATCHED_PRICELEVEL_ID'
}

export const itemMappingSchemeLabel: Record<string, string> = {
    [EItemMappingScheme.MAP_FIRST_MATCHED_PRICELEVEL]: 'Map Matched Price Level',
    [EItemMappingScheme.MAP_MATCHED_PRICELEVELS_AS_VARIANTS]: 'Map Matched Price Level As Variants',
    [EItemMappingScheme.MAP_FIRST_MATCHED_PRICELEVEL_ID]: 'Map First Matched Price Level ID'
};

export const itemMappingSchemeOptions: Option[] = [
    { label: 'None', value: null },
    {
        label: itemMappingSchemeLabel[EItemMappingScheme.MAP_FIRST_MATCHED_PRICELEVEL],
        value: EItemMappingScheme.MAP_FIRST_MATCHED_PRICELEVEL
    },
    {
        label: itemMappingSchemeLabel[EItemMappingScheme.MAP_MATCHED_PRICELEVELS_AS_VARIANTS],
        value: EItemMappingScheme.MAP_MATCHED_PRICELEVELS_AS_VARIANTS
    },
    {
        label: itemMappingSchemeLabel[EItemMappingScheme.MAP_FIRST_MATCHED_PRICELEVEL_ID],
        value: EItemMappingScheme.MAP_FIRST_MATCHED_PRICELEVEL_ID
    }
];

interface IPosSettingsComponentConfig {
    display: React.FC<IPointOfSaleSettingsProps> | null;
    edit: React.FC<IPointOfSaleSettingsProps> | null;
}
const posSettingsComponentConfig: Record<EPointOfSaleProvider, IPosSettingsComponentConfig> = {
    [EPointOfSaleProvider.OMNIVORE_MICROS_SIMPHONY]: {
        display: MicrosSettingsDisplay,
        edit: MicrosSettingsEdit
    },
    [EPointOfSaleProvider.OMNIVORE_MICROS_3700]: { display: MicrosSettingsDisplay, edit: MicrosSettingsEdit },
    [EPointOfSaleProvider.OMNIVORE_NCR_ALOHA]: { display: AlohaSettingsDisplay, edit: AlohaSettingsEdit },
    [EPointOfSaleProvider.IBS]: { display: IBSSettingsDisplay, edit: IBSSettingsEdit },
    [EPointOfSaleProvider.REVEL]: { display: RevelSettingsDisplay, edit: RevelSettingsEdit },
    [EPointOfSaleProvider.LIGHTSPEED]: { display: LightspeedSettingsDisplay, edit: LightspeedSettingsEdit },
    [EPointOfSaleProvider.COMTREX]: { display: ComtrexSettingsDisplay, edit: ComtrexSettingsEdit },
    [EPointOfSaleProvider.ZONAL_AZTEC]: { display: ZonalSettingsDisplay, edit: ZonalSettingsEdit },
    [EPointOfSaleProvider.SQUARE]: { display: SquareSettingsDisplay, edit: SquareSettingsEdit },
    [EPointOfSaleProvider.DELIVERECT]: { display: DeliverectSettingsDisplay, edit: DeliverectSettingsEdit },
    [EPointOfSaleProvider.GOODTILL]: { display: GoodtillSettingsDisplay, edit: GoodtillSettingsEdit },
    [EPointOfSaleProvider.TEVALIS]: { display: TevalisSettingsDisplay, edit: TevalisSettingsEdit },
    [EPointOfSaleProvider.PEPPER]: { display: null, edit: null },
    [EPointOfSaleProvider.NOBLY]: { display: null, edit: null },
    [EPointOfSaleProvider.LAVU]: { display: null, edit: null },
    [EPointOfSaleProvider.POINTONE]: { display: null, edit: null },
    [EPointOfSaleProvider.NONE]: { display: null, edit: null }
};

export const PointOfSaleSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId,
    globalSettings
}) => {
    const [posLocations, setPosLocations] = React.useState<IPOSLocation[]>([]);
    const dispatch = useDispatch();
    const { claims } = useSelector((state: ApplicationState) => state.auth);

    React.useEffect(() => {
        async function loadPosLocations() {
            const posLocationResult = await menuPosApi.getList();
            if (!posLocationResult.ok) {
                return setPosLocations([]);
            }
            setPosLocations(posLocationResult.body.items);
        }
        loadPosLocations();
    }, []);

    const handleSubmitPosProvider = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};
                let menuSettingsToUpdate: IPublicAndPrivateSettings['menu'] = {};

                // Common Settings
                settingsToUpdate = {
                    ...settingsToUpdate,
                    posProvider: values.posProvider
                };

                if (settingsToUpdate.posProvider === 'SQUARE') {
                    settingsToUpdate.paymentProvider = 'SQUARE'; // Force Square
                }

                switch (values.posProvider) {
                    case EPointOfSaleProvider.OMNIVORE_MICROS_SIMPHONY:
                    case EPointOfSaleProvider.OMNIVORE_MICROS_3700: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                omnivore: {
                                    apiKey: values.omnivoreMicrosApiKey,
                                    webhookActivationCode: values.omnivoreMicrosWebhookActivationCode
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                omnivore: {
                                    locationId: values.omnivoreMicrosLocationId,
                                    employeeId: values.omnivoreMicrosEmployeeId,
                                    tenderType: values.omnivoreMicrosTenderTypeId,
                                    PREORDER_orderType: values.omnivoreMicrosPreOrderOrderTypeId,
                                    PREORDER_revenueCenter: values.omnivoreMicrosPreOrderRevenueCenterId,
                                    ORDER_TO_TABLE_orderType: values.omnivoreMicrosTableOrderTypeId,
                                    ORDER_TO_TABLE_revenueCenter: values.omnivoreMicrosTableRevenueCenterId,
                                    TABLE_orderType: values.omnivoreMicrosPayAtTableOrderTypeId,
                                    TABLE_revenueCenter: values.omnivoreMicrosPayAtTableRevenueCenterId,
                                    menuCategoryFilter: values.omnivoreMicrosMenuCategoryFilter,
                                    onlyUsePriceLevel: values.omnivoreMicrosItemLevelPrice,
                                    itemMappingScheme: values.omnivoreMicrosItemMappingScheme
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.OMNIVORE_NCR_ALOHA: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                omnivore: {
                                    apiKey: values.omnivoreAlohaApiKey,
                                    webhookActivationCode: values.omnivoreAlohaWebhookActivationCode
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                omnivore: {
                                    locationId: values.omnivoreAlohaLocationId,
                                    employeeId: values.omnivoreAlohaEmployeeId,
                                    tenderType: values.omnivoreAlohaTenderTypeId,
                                    PREORDER_orderType: values.omnivoreAlohaPreOrderOrderTypeId,
                                    PREORDER_revenueCenter: values.omnivoreAlohaPreOrderCenterId,
                                    ORDER_TO_TABLE_orderType: values.omnivoreAlohaOrderToTableOrderTypeId,
                                    ORDER_TO_TABLE_revenueCenter:
                                        values.omnivoreAlohaOrderToTableRevenueCenterId,
                                    TABLE_orderType: values.omnivoreAlohaPayAtTableOrderTypeId,
                                    TABLE_revenueCenter: values.omnivoreAlohaPayAtTableRevenueCenterId,
                                    menuCategoryFilter: values.omnivoreAlohaMenuCategoryFilter,
                                    onlyUsePriceLevel: values.omnivoreAlohaItemLevelPrice,
                                    clerkJobIds: values.omnivoreAlohaManagerJobIds?.length
                                        ? values.omnivoreAlohaClerkJobIds?.map(
                                              (option: Option) => option.value
                                          )
                                        : null,
                                    managerJobIds: values.omnivoreAlohaManagerJobIds?.length
                                        ? values.omnivoreAlohaManagerJobIds?.map(
                                              (option: Option) => option.value
                                          )
                                        : null,
                                    itemMappingScheme: values.omnivoreAlohaItemMappingScheme
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.IBS: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                ibs: {
                                    szUserName: values.ibsApiUsername,
                                    szPassword: values.ibsApiPassword,
                                    ibsWebOrdersHost: values.ibsWebOrdersHostname,
                                    szAppGUID: values.ibsAppGuid,
                                    szCompanyCode: values.ibsCompanyCode,
                                    useKioskOrder: !!values.ibsEnableKioskOrdersMode,
                                    webNotificationsEnabled: !!values.ibsWebNotificationsEnabled,
                                    orderNotesTextBold: !!values.ibsOrderNotesBold,
                                    orderNotesTextDoubleHeight: !!values.ibsOrderNotesDoubleHeight,
                                    orderNotesTextDoubleWidth: !!values.ibsOrderNotesDoubleWidth,
                                    orderNotesTextJustify: parseIntOrUndefined(values.ibsOrderNotesJustify),
                                    PREORDER_priceLevel: parseIntOrNull(values.PREORDER_priceLevel),
                                    TABLE_priceLevel: parseIntOrNull(values.TABLE_priceLevel),
                                    TAB_priceLevel: parseIntOrNull(values.TAB_priceLevel),
                                    ORDER_TO_TABLE_priceLevel: parseIntOrNull(
                                        values.ORDER_TO_TABLE_priceLevel
                                    ),
                                    condimentListOption:
                                        values.condimentListOption === 'Default'
                                            ? null
                                            : blankAsNull(values.condimentListOption),
                                    itemNoteModifierNo: parseIntOrNull(values.ibsItemNoteModifierNo),
                                    itemNoteOffset: parseIntOrNull(values.ibsItemNoteOffset),
                                    itemNoteSalesGroupNo: parseIntOrNull(values.ibsItemNoteSalesGroupNo),
                                    paymentMediaType: values.ibsPaymentMediaType,
                                    ibsPosIANATimeZone: values.ibsTimezoneName
                                },
                                orderNotesEnabled: {
                                    item: values.itemOrderNotesEnabled ?? false
                                },
                                accessMenuManagerEnabled: !!values.accessMenuManagerEnabled,
                                accessMenuManager: {
                                    host: values.accessMenuManagerHost,
                                    apiKey: values.accessMenuManagerApiKey,
                                    password: values.accessMenuManagerPassword,
                                    PREORDER_channelId: values.preorderChannelId,
                                    ORDER_TO_TABLE_channelId: values.orderToTableChannelId,
                                    TABLE_channelId: values.tableChannelId,
                                    TAB_channelId: values.tabChannelId
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                orderNotesEnabled: {
                                    item: values.itemOrderNotesEnabled ?? false
                                },
                                ibs: {
                                    ibsLocationId: values.ibsLocationId,
                                    menuIbsLocationId: values.menuIbsLocationId,
                                    paymentMediaType: values.ibsPaymentMediaType,
                                    ibsPosIANATimeZone: values.ibsTimezoneName,
                                    PREORDER_seatingArea: parseIntOrUndefined(values.ibsPreOrderSeatingArea),
                                    ORDER_TO_TABLE_seatingArea: parseIntOrUndefined(
                                        values.ibsOrderToTableSeatingArea
                                    ),
                                    TABLE_seatingArea: parseIntOrUndefined(values.ibsPayAtTableSeatingArea),
                                    TAB_seatingArea: parseIntOrUndefined(values.ibsTabSeatingArea),
                                    PREORDER_priceLevel: parseIntOrNull(values.PREORDER_priceLevel),
                                    TABLE_priceLevel: parseIntOrNull(values.TABLE_priceLevel),
                                    TAB_priceLevel: parseIntOrNull(values.TAB_priceLevel),
                                    ORDER_TO_TABLE_priceLevel: parseIntOrNull(
                                        values.ORDER_TO_TABLE_priceLevel
                                    )
                                }
                            };

                            menuSettingsToUpdate = {
                                filterProductsByZone: values.filterProductsByZone
                            };
                            break;
                        }
                        break;
                    }
                    case EPointOfSaleProvider.REVEL: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                revel: {
                                    diningOption: values.revelDiningOption,
                                    taxinclusive: !!values.revelTaxInclusive,
                                    url: values.revelAccountName,
                                    apiKey: values.revelApiKey,
                                    useDynamicDiscounts: !!values.revelDynamicDiscounts,
                                    paymentType: parseIntOrUndefined(values.revelPaymentType),
                                    useStockAvailability: !!values.revelStockAvailability
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                revel: {
                                    establishmentId: values.revelEstablishmentId,
                                    useDynamicDiscounts: !!values.revelDynamicDiscounts,
                                    useStockAvailability: !!values.revelStockAvailability
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.LIGHTSPEED: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                lightspeed: {
                                    host: values.lightspeedWebOrdersServerHost
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                lightspeed: {
                                    companyId: values.lightspeedLightspeedId,
                                    paymentTypeName: values.lightspeedPaymentTypeName
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.COMTREX: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                comtrex: {
                                    vendorName: values.comtrexVendorName,
                                    menuCategoryFilter: values.comtrexMenuCategoryFilter
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                comtrex: {
                                    host: values.comtrexHost,
                                    paymentTypeId: values.comtrexPaymentTypeId,
                                    tipItemNumber: values.comtrexTipItemNumber,
                                    noteFireMessageId: values.comtrexNoteFireMessageId,
                                    allergenListNumber: values.comtrexAllergenListNumber,
                                    mapAllergenAsCondiment: values.comtrexMapAllergenAsCondiment,
                                    menuCategoryFilter: values.comtrexMenuCategoryFilter,
                                    ORDER_TO_TABLE_salesTypeNumber: blankAsNull(
                                        values.comtrexORDER_TO_TABLE_salesTypeNumber
                                    ),
                                    PREORDER_salesTypeNumber: blankAsNull(
                                        values.comtrexPREORDER_salesTypeNumber
                                    ),
                                    TABLE_salesTypeNumber: blankAsNull(values.comtrexTABLE_salesTypeNumber),
                                    TAB_salesTypeNumber: blankAsNull(values.comtrexTAB_salesTypeNumber)
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.ZONAL_AZTEC: {
                        if (locationId === undefined) {
                            if (
                                (!!values.chargeTaxIds && !isSeparatedIntegerValid(values.chargeTaxIds)) ||
                                (!!values.exclusiveTaxTaxIds &&
                                    !isSeparatedIntegerValid(values.exclusiveTaxTaxIds)) ||
                                (!!values.discretionaryChargeTaxIds &&
                                    !isSeparatedIntegerValid(values.discretionaryChargeTaxIds))
                            ) {
                                dispatch(
                                    enqueueSnackbar('Enter the tax ID and separate each ID with a comma', {
                                        variant: 'error'
                                    })
                                );
                                return;
                            }
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                zonalAztec: {
                                    isProduction: values.zonalIsProduction,
                                    authBrandToken: values.zonalAuthenticationBrandToken,
                                    platform: values.zonalPlatformType,
                                    bundleIdentifier: values.zonalBundleIdentifier,
                                    host: values.zonalHost,
                                    integratorIdentity: values.integratorIdentity,
                                    hmacSharedSecret: values.hmacSharedSecret,
                                    chargeTaxIds: values.chargeTaxIds
                                        ?.split(',')
                                        ?.map((taxId: string) => Number(taxId.trim())),
                                    exclusiveTaxTaxIds: values.exclusiveTaxTaxIds
                                        ?.split(',')
                                        ?.map((taxId: string) => Number(taxId.trim())),
                                    discretionaryChargeTaxIds: values.discretionaryChargeTaxIds
                                        ?.split(',')
                                        ?.map((taxId: string) => Number(taxId.trim())),
                                    loyaltyAtPosPassword: values.loyaltyAtPosPassword,
                                    iOrderOpenDiscountsEnabled: values.iOrderOpenDiscountsEnabled
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                zonalAztec: {
                                    ORDER_TO_TABLE_serviceId: parseIntOrUndefined(
                                        values.zonalOrderToTableServiceId
                                    ),
                                    PREORDER_serviceId: parseIntOrUndefined(values.zonalPreOrderServiceId),
                                    menuId: parseIntOrUndefined(values.zonalMenuId),
                                    notesItemDisplayRecordId: parseIntOrUndefined(
                                        values.zonalNotesDisplayRecordId
                                    ),
                                    notesItemIngredientId: parseIntOrUndefined(
                                        values.zonalNotesItemIngredientId
                                    ),
                                    notesItemPortionTypeId: parseIntOrUndefined(
                                        values.zonalNotesItemPortionTypeId
                                    ),
                                    salesAreaId: parseIntOrUndefined(values.zonalSalesAreaId),
                                    siteId: parseIntOrUndefined(values.zonalSiteId),
                                    tablePaymentMethodId: values.tablePaymentMethodId,
                                    loyaltyAtPosPassword: values.loyaltyAtPosPassword,
                                    iOrderOpenDiscountsEnabled: values.iOrderOpenDiscountsEnabled
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.SQUARE: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                square: {
                                    isProduction: !!values.squareIsProduction,
                                    merchantId: values.squareMerchantId,
                                    loyaltyProgramId: values.squareLoyaltyProgramId,
                                    webhookHmacSecret: values.squareWebhookHmacSecret
                                },
                                userDeletion: {
                                    ...values.userDeletion,
                                    deletePosAccountOnUserDeletion:
                                        values.userDeletion.deletePosAccountOnUserDeletion
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                square: {
                                    locationId: values.squareLocationId
                                },
                                userDeletion: {
                                    ...values.userDeletion,
                                    deletePosAccountOnUserDeletion:
                                        values.userDeletion.deletePosAccountOnUserDeletion
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.DELIVERECT: {
                        const preorderMenuType = isDeliverectMenuServiceType(values.PREORDER_menuType)
                            ? values.PREORDER_menuType
                            : '';

                        const orderToTableMenuType = isDeliverectMenuServiceType(
                            values.ORDER_TO_TABLE_menuType
                        )
                            ? values.ORDER_TO_TABLE_menuType
                            : '';

                        settingsToUpdate = {
                            ...settingsToUpdate,
                            deliverect: {
                                ...settingsToUpdate.deliverect,
                                mapTableNameToTableId: values?.mapTableNameToTableId,
                                PREORDER_menuType: parseIntOrNull(preorderMenuType),
                                ORDER_TO_TABLE_menuType: parseIntOrNull(orderToTableMenuType)
                            }
                        };

                        break;
                    }
                    case EPointOfSaleProvider.GOODTILL: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                goodtill: {
                                    subdomain: values.goodtillSubdomain,
                                    username: values.goodtillUsername,
                                    password: values.goodtillPassword,
                                    verificationToken: values.goodtillVerificationToken,
                                    loyaltyAtPOSPerkId: values.goodtillLoyaltyAtPOSPerkId,
                                    PREORDER_priceSource: values.goodtillPreorderPriceSource,
                                    ORDER_TO_TABLE_priceSource: values.goodtillOrderToTablePriceSource
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                goodtill: {
                                    outletId: values.goodtillOutletId,
                                    PREORDER_priceSource: values.goodtillPreorderPriceSource,
                                    ORDER_TO_TABLE_priceSource: values.goodtillOrderToTablePriceSource
                                }
                            };
                        }
                        break;
                    }
                    case EPointOfSaleProvider.TEVALIS: {
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                tevalis: {
                                    companyId: values.tevalisCompanyId,
                                    devID: values.tevalisDevId,
                                    guid: values.tevalisGuid,
                                    guid2: values.tevalisGuid2,
                                    placeholderItemPLU: values.tevalisPlaceholderItemPLU
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                tevalis: {
                                    siteId: parseIntOrNull(values.tevalisSiteId)
                                }
                            };
                        }
                        settingsToUpdate = {
                            ...settingsToUpdate,
                            tevalis: {
                                ...settingsToUpdate.tevalis,
                                PREORDER_priceGroupId: parseIntOrNull(values.tevalisPREORDER_priceGroupId),
                                ORDER_TO_TABLE_priceGroupId: parseIntOrNull(
                                    values.tevalisORDER_TO_TABLE_priceGroupId
                                ),
                                TABLE_priceGroupId: parseIntOrNull(values.tevalisTABLE_priceGroupId),
                                TAB_priceGroupId: parseIntOrNull(values.tevalisTAB_priceGroupId),
                                tenderType: values.tevalisTenderType,
                                discountTenderType: values.tevalisDiscountTenderType
                            }
                        };
                        break;
                    }
                }

                if (isUndefined(locationId)) {
                    menuSettingsToUpdate = {
                        ...menuSettingsToUpdate,
                        autoRefreshEnabled: values.autoRefreshEnabled,
                        manualRefreshEnabled: values.manualRefreshEnabled,
                        autoRefreshAvailabilityEnabled: values.autoRefreshAvailabilityEnabled
                    };
                }

                menuSettingsToUpdate = {
                    ...menuSettingsToUpdate,
                    refreshSchedule: values.refreshSchedule
                };

                if (!Object.keys(settingsToUpdate).length && !Object.keys(menuSettingsToUpdate).length) {
                    // No need to update anything
                    return true;
                }
                settingsToUpdate = {
                    ...settingsToUpdate,
                    menu: menuSettingsToUpdate
                };
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                if (!locationId) {
                    getPublicAndPrivateSettings(claims)(dispatch);
                }
                if (locationId) {
                    menusApi.refreshMenus(locationId);
                    dispatch(enqueueSnackbar(MESSAGE_MENU_REFRESH, { variant: 'info' }));
                }
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`, err);
                return false;
            }
        },
        [locationId, onSettingsSaved, dispatch, claims]
    );

    const posProvider = React.useMemo(
        () => settings?.posProvider || globalSettings?.posProvider,
        [globalSettings, settings]
    );

    const showAutoRefreshProductAvailability = React.useMemo(
        () =>
            // not SQUARE, since it uses webhooks
            posProvider === EPointOfSaleProvider.DELIVERECT ||
            posProvider === EPointOfSaleProvider.OMNIVORE_NCR_ALOHA ||
            posProvider === EPointOfSaleProvider.REVEL ||
            posProvider === EPointOfSaleProvider.ZONAL_AZTEC ||
            posProvider === EPointOfSaleProvider.OMNIVORE_MICROS_SIMPHONY ||
            posProvider === EPointOfSaleProvider.OMNIVORE_MICROS_3700,
        [posProvider]
    );

    const renderPosEdit = React.useCallback(
        (values: FormikValues) => {
            const PosEditComponent =
                posSettingsComponentConfig[values.posProvider as EPointOfSaleProvider]?.edit;
            const locationPosProviderOptions = posProviderOptions.filter(
                ({ value }) =>
                    value === values.posProvider ||
                    value === globalSettings?.posProvider ||
                    value === EPointOfSaleProvider.DELIVERECT
            );
            return (
                <Box display="flex" flexDirection="column">
                    {locationId !== undefined && (
                        <SettingDisplay label="POS Provider">
                            <SelectFormField options={locationPosProviderOptions} name="posProvider" />
                        </SettingDisplay>
                    )}
                    {locationId === undefined && (
                        <>
                            <SettingDisplay label="POS Provider">
                                <SelectFormField options={posProviderOptions} name="posProvider" />
                            </SettingDisplay>
                            <SettingDisplay label="Auto Refresh">
                                <SwitchFormField name="autoRefreshEnabled" />
                            </SettingDisplay>
                            <SettingDisplay label="Manual Menu Refresh">
                                <SwitchFormField name="manualRefreshEnabled" />
                            </SettingDisplay>
                            {showAutoRefreshProductAvailability && (
                                <SettingDisplay label="Auto Refresh Product Availability ">
                                    <SwitchFormField name="autoRefreshAvailabilityEnabled" />
                                </SettingDisplay>
                            )}
                            {posProvider !== EPointOfSaleProvider.SQUARE && (
                                <SettingDisplay label="Refresh Schedule">
                                    <SelectFormField
                                        name="refreshSchedule"
                                        options={refreshScheduleOptions}
                                        emptyLabel="Default"
                                        displayEmpty
                                    />
                                </SettingDisplay>
                            )}
                        </>
                    )}
                    {PosEditComponent && (
                        <PosEditComponent
                            settings={settings}
                            globalSettings={globalSettings}
                            locationId={locationId}
                            posLocations={posLocations}
                            onSettingsSaved={onSettingsSaved}
                        />
                    )}
                </Box>
            );
        },
        [
            locationId,
            showAutoRefreshProductAvailability,
            settings,
            globalSettings,
            posLocations,
            onSettingsSaved,
            posProvider
        ]
    );

    const PosDisplayComponent = React.useMemo(
        () => posSettingsComponentConfig[posProvider as EPointOfSaleProvider]?.display,
        [posProvider]
    );

    return (
        <Box minWidth="500px">
            {/* Cards for each settings block */}
            <StyledMuiCardForm
                title="Point of Sale"
                initialValues={
                    !settings
                        ? { posProvider }
                        : {
                              posProvider,
                              autoRefreshEnabled: settings.menu?.autoRefreshEnabled ?? true,
                              manualRefreshEnabled: settings.menu?.manualRefreshEnabled ?? true,
                              refreshSchedule: settings.menu?.refreshSchedule,
                              autoRefreshAvailabilityEnabled:
                                  settings.menu?.autoRefreshAvailabilityEnabled ?? true,
                              /* OMNIVORE MICROS 3700 */
                              omnivoreMicrosApiKey: settings.omnivore?.apiKey,
                              omnivoreMicrosWebhookActivationCode: settings.omnivore?.webhookActivationCode,
                              omnivoreMicrosLocationId: settings.omnivore?.locationId,
                              omnivoreMicrosEmployeeId: settings.omnivore?.employeeId,
                              omnivoreMicrosTenderTypeId: settings.omnivore?.tenderType,
                              omnivoreMicrosPreOrderOrderTypeId: settings.omnivore?.PREORDER_orderType,
                              omnivoreMicrosPreOrderRevenueCenterId:
                                  settings.omnivore?.PREORDER_revenueCenter,
                              omnivoreMicrosTableOrderTypeId: settings.omnivore?.ORDER_TO_TABLE_orderType,
                              omnivoreMicrosTableRevenueCenterId:
                                  settings.omnivore?.ORDER_TO_TABLE_revenueCenter,
                              omnivoreMicrosPayAtTableOrderTypeId: settings.omnivore?.TABLE_orderType,
                              omnivoreMicrosPayAtTableRevenueCenterId: settings.omnivore?.TABLE_revenueCenter,
                              omnivoreMicrosMenuCategoryFilter: settings.omnivore?.menuCategoryFilter,
                              omnivoreMicrosItemLevelPrice: settings.omnivore?.onlyUsePriceLevel,
                              omnivoreMicrosItemMappingScheme: settings.omnivore?.itemMappingScheme,

                              /* OMNIVORE ALOHA */
                              omnivoreAlohaApiKey: settings.omnivore?.apiKey,
                              omnivoreAlohaWebhookActivationCode: settings.omnivore?.webhookActivationCode,
                              omnivoreAlohaLocationId: settings.omnivore?.locationId,
                              omnivoreAlohaEmployeeId: settings.omnivore?.employeeId,
                              omnivoreAlohaTenderTypeId: settings.omnivore?.tenderType,
                              omnivoreAlohaPreOrderOrderTypeId: settings.omnivore?.PREORDER_orderType,
                              omnivoreAlohaPreOrderCenterId: settings.omnivore?.PREORDER_revenueCenter,
                              omnivoreAlohaOrderToTableOrderTypeId:
                                  settings.omnivore?.ORDER_TO_TABLE_orderType,
                              omnivoreAlohaOrderToTableRevenueCenterId:
                                  settings.omnivore?.ORDER_TO_TABLE_revenueCenter,
                              omnivoreAlohaPayAtTableOrderTypeId: settings.omnivore?.TABLE_orderType,
                              omnivoreAlohaPayAtTableRevenueCenterId: settings.omnivore?.TABLE_revenueCenter,
                              omnivoreAlohaMenuCategoryFilter: settings.omnivore?.menuCategoryFilter,
                              omnivoreAlohaItemLevelPrice: settings.omnivore?.onlyUsePriceLevel,
                              omnivoreAlohaManagerJobIds: settings.omnivore?.managerJobIds?.map(jobId => ({
                                  label: jobId,
                                  value: jobId
                              })),
                              omnivoreAlohaClerkJobIds: settings.omnivore?.clerkJobIds?.map(jobId => ({
                                  label: jobId,
                                  value: jobId
                              })),
                              omnivoreAlohaItemMappingScheme: settings.omnivore?.itemMappingScheme,

                              /* IBS ACCESS */
                              ibsApiUsername: settings.ibs?.szUserName,
                              ibsApiPassword: settings.ibs?.szPassword,
                              ibsWebOrdersHostname: settings.ibs?.ibsWebOrdersHost,
                              ibsAppGuid: settings.ibs?.szAppGUID,
                              ibsCompanyCode: settings.ibs?.szCompanyCode,
                              ibsEnableKioskOrdersMode: settings.ibs?.useKioskOrder,
                              ibsWebNotificationsEnabled: settings.ibs?.webNotificationsEnabled,
                              ibsLocationId: settings.ibs?.ibsLocationId,
                              menuIbsLocationId: settings.ibs?.menuIbsLocationId,
                              ibsPaymentMediaType: settings.ibs?.paymentMediaType,
                              ibsTimezoneName: settings.ibs?.ibsPosIANATimeZone,
                              ibsPreOrderSeatingArea: settings.ibs?.PREORDER_seatingArea,
                              ibsOrderToTableSeatingArea: settings.ibs?.ORDER_TO_TABLE_seatingArea,
                              ibsPayAtTableSeatingArea: settings.ibs?.TABLE_seatingArea,
                              ibsTabSeatingArea: settings.ibs?.TAB_seatingArea,
                              filterProductsByZone: !!settings.menu?.filterProductsByZone,
                              ibsOrderNotesBold: settings.ibs?.orderNotesTextBold,
                              ibsOrderNotesDoubleWidth: settings.ibs?.orderNotesTextDoubleWidth,
                              ibsOrderNotesDoubleHeight: settings.ibs?.orderNotesTextDoubleHeight,
                              ibsOrderNotesJustify: settings.ibs?.orderNotesTextJustify,
                              PREORDER_priceLevel: settings.ibs?.PREORDER_priceLevel,
                              ORDER_TO_TABLE_priceLevel: settings.ibs?.ORDER_TO_TABLE_priceLevel,
                              TABLE_priceLevel: settings.ibs?.TABLE_priceLevel,
                              TAB_priceLevel: settings.ibs?.TAB_priceLevel,
                              condimentListOption: settings.ibs?.condimentListOption ?? '',
                              ibsItemNoteModifierNo: settings.ibs?.itemNoteModifierNo,
                              ibsItemNoteOffset: settings.ibs?.itemNoteOffset,
                              ibsItemNoteSalesGroupNo: settings.ibs?.itemNoteSalesGroupNo,
                              itemOrderNotesEnabled: settings.orderNotesEnabled?.item ?? false,

                              /* ACCESS MENU MANAGER */
                              accessMenuManagerEnabled: settings.accessMenuManagerEnabled,
                              accessMenuManagerApiKey: settings.accessMenuManager?.apiKey,
                              accessMenuManagerHost: settings.accessMenuManager?.host,
                              accessMenuManagerPassword: settings.accessMenuManager?.password,
                              preorderChannelId: settings.accessMenuManager?.PREORDER_channelId,
                              orderToTableChannelId: settings.accessMenuManager?.ORDER_TO_TABLE_channelId,
                              tabChannelId: settings.accessMenuManager?.TAB_channelId,
                              tableChannelId: settings.accessMenuManager?.TABLE_channelId,

                              /* REVEL */
                              revelDiningOption: settings.revel?.diningOption,
                              revelTaxInclusive: settings.revel?.taxinclusive,
                              revelAccountName: settings.revel?.url,
                              revelApiKey: settings.revel?.apiKey,
                              revelPaymentType: settings.revel?.paymentType,
                              revelEstablishmentId: settings.revel?.establishmentId,
                              revelDynamicDiscounts: settings.revel?.useDynamicDiscounts,
                              revelStockAvailability: settings.revel?.useStockAvailability,

                              /* LIGHTSPEED */
                              lightspeedWebOrdersServerHost: settings.lightspeed?.host,
                              lightspeedLightspeedId: settings.lightspeed?.companyId,
                              lightspeedPaymentTypeName: settings.lightspeed?.paymentTypeName,

                              /* COMTREX */
                              ...(settings.comtrex && {
                                  comtrexVendorName: settings.comtrex.vendorName,
                                  comtrexHost: settings.comtrex.host,
                                  comtrexPaymentTypeId: settings.comtrex.paymentTypeId,
                                  comtrexTipItemNumber: settings.comtrex.tipItemNumber,
                                  comtrexNoteFireMessageId: settings.comtrex.noteFireMessageId,
                                  comtrexAllergenListNumber: settings.comtrex.allergenListNumber,
                                  comtrexMapAllergenAsCondiment:
                                      settings.comtrex.mapAllergenAsCondiment ?? false,
                                  comtrexMenuCategoryFilter: settings.comtrex.menuCategoryFilter,
                                  comtrexORDER_TO_TABLE_salesTypeNumber:
                                      settings.comtrex.ORDER_TO_TABLE_salesTypeNumber,
                                  comtrexPREORDER_salesTypeNumber: settings.comtrex.PREORDER_salesTypeNumber,
                                  comtrexTABLE_salesTypeNumber: settings.comtrex.TABLE_salesTypeNumber,
                                  comtrexTAB_salesTypeNumber: settings.comtrex.TAB_salesTypeNumber
                              }),

                              /* ZONAL AZTEC */
                              zonalIsProduction: settings.zonalAztec?.isProduction ?? true,
                              zonalAuthenticationBrandToken: settings.zonalAztec?.authBrandToken,
                              zonalPlatformType: settings.zonalAztec?.platform,
                              zonalBundleIdentifier: settings.zonalAztec?.bundleIdentifier,
                              zonalHost: settings.zonalAztec?.host,
                              zonalOrderToTableServiceId: settings.zonalAztec?.ORDER_TO_TABLE_serviceId,
                              zonalPreOrderServiceId: settings.zonalAztec?.PREORDER_serviceId,
                              zonalMenuId: settings.zonalAztec?.menuId,
                              zonalNotesDisplayRecordId: settings.zonalAztec?.notesItemDisplayRecordId,
                              zonalNotesItemIngredientId: settings.zonalAztec?.notesItemIngredientId,
                              zonalNotesItemPortionTypeId: settings.zonalAztec?.notesItemPortionTypeId,
                              zonalSalesAreaId: settings.zonalAztec?.salesAreaId,
                              zonalSiteId: settings.zonalAztec?.siteId,
                              tablePaymentMethodId: settings.zonalAztec?.tablePaymentMethodId,
                              loyaltyAtPosPassword: settings.zonalAztec?.loyaltyAtPosPassword,
                              chargeTaxIds: settings.zonalAztec?.chargeTaxIds?.join(', '),
                              exclusiveTaxTaxIds: settings.zonalAztec?.exclusiveTaxTaxIds?.join(', '),
                              discretionaryChargeTaxIds:
                                  settings.zonalAztec?.discretionaryChargeTaxIds?.join(', '),
                              iOrderOpenDiscountsEnabled:
                                  settings.zonalAztec?.iOrderOpenDiscountsEnabled ?? false,

                              /* SQUARE */
                              ...(settings.square && {
                                  squareIsProduction: settings.square.isProduction ?? false,
                                  squareMerchantId: settings.square.merchantId,
                                  squareLoyaltyProgramId: settings.square.loyaltyProgramId,
                                  squareLocationId: settings.square.locationId,
                                  squareWebhookHmacSecret: settings.square.webhookHmacSecret
                              }),
                              userDeletion: {
                                  ...settings.userDeletion,
                                  deletePosAccountOnUserDeletion:
                                      settings.userDeletion?.deletePosAccountOnUserDeletion === undefined
                                          ? true
                                          : settings.userDeletion?.deletePosAccountOnUserDeletion
                              },

                              /* DELIVERECT */
                              mapTableNameToTableId: !!settings.deliverect?.mapTableNameToTableId,
                              PREORDER_menuType: `${settings.deliverect?.PREORDER_menuType}`,
                              ORDER_TO_TABLE_menuType: `${settings.deliverect?.ORDER_TO_TABLE_menuType}`,

                              /* GOODTILL */
                              goodtillSubdomain: settings.goodtill?.subdomain,
                              goodtillUsername: settings.goodtill?.username,
                              goodtillPassword: settings.goodtill?.password,
                              goodtillVerificationToken: settings.goodtill?.verificationToken,
                              goodtillLoyaltyAtPOSPerkId: settings.goodtill?.loyaltyAtPOSPerkId,
                              goodtillOutletId: settings.goodtill?.outletId,
                              goodtillPreorderPriceSource:
                                  settings.goodtill?.PREORDER_priceSource ?? EGoodtillPriceSource.DEFAULT,
                              goodtillOrderToTablePriceSource:
                                  settings.goodtill?.ORDER_TO_TABLE_priceSource ??
                                  EGoodtillPriceSource.DEFAULT,

                              /* TEVALIS */
                              ...(settings.tevalis && {
                                  tevalisCompanyId: settings.tevalis.companyId,
                                  tevalisDevId: settings.tevalis.devID,
                                  tevalisGuid: settings.tevalis.guid,
                                  tevalisGuid2: settings.tevalis.guid2,
                                  tevalisSiteId: settings.tevalis.siteId,
                                  tevalisPlaceholderItemPLU: settings.tevalis.placeholderItemPLU,
                                  tevalisPREORDER_priceGroupId: settings.tevalis.PREORDER_priceGroupId,
                                  tevalisORDER_TO_TABLE_priceGroupId:
                                      settings.tevalis.ORDER_TO_TABLE_priceGroupId,
                                  tevalisTABLE_priceGroupId: settings.tevalis.TABLE_priceGroupId,
                                  tevalisTAB_priceGroupId: settings.tevalis.TAB_priceGroupId,
                                  tevalisTenderType: settings.tevalis.tenderType,
                                  tevalisDiscountTenderType: settings.tevalis.discountTenderType
                              })
                          }
                }
                onSubmit={handleSubmitPosProvider}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="POS Provider">
                            <Chip
                                label={
                                    posProviderOptions.find(option => option.value === posProvider)?.label ??
                                    'None'
                                }
                                color="primary"
                            />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplaySwitch
                                    checked={settings?.menu?.autoRefreshEnabled ?? true}
                                    title="Auto Menu Refresh"
                                />
                                <SettingDisplaySwitch
                                    checked={settings?.menu?.manualRefreshEnabled ?? true}
                                    title="Manual Menu Refresh"
                                />
                                {showAutoRefreshProductAvailability && (
                                    <SettingDisplaySwitch
                                        checked={settings?.menu?.autoRefreshAvailabilityEnabled ?? true}
                                        title="Auto Refresh Product Availability"
                                    />
                                )}
                                <SettingDisplayText
                                    text={
                                        refreshScheduleLabels[settings?.menu?.refreshSchedule] ??
                                        'Default (Every 2 hours)'
                                    }
                                    title="Refresh Schedule"
                                />
                            </>
                        )}
                        {locationId !== undefined && posProvider !== EPointOfSaleProvider.SQUARE && (
                            <SettingDisplayText
                                text={refreshScheduleLabels[settings?.menu?.refreshSchedule] ?? ''}
                                title="Refresh Schedule"
                            />
                        )}
                        {PosDisplayComponent && (
                            <PosDisplayComponent
                                settings={settings}
                                globalSettings={globalSettings}
                                locationId={locationId}
                                posLocations={posLocations}
                                onSettingsSaved={onSettingsSaved}
                            />
                        )}
                    </Box>
                }
                childrenWhileEditing={renderPosEdit}
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
        </Box>
    );
};

import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { PepperPayScheduleOperation } from '../model/PepperPay';
import { reportFormatLabelMap, reportTypeLabelMap } from './model';
import { getOperationTypeAndFormat } from './utils';
import { SchedulesDeleteActionCell } from './SchedulesDeleteActionCell';
import { titleize } from 'lib/helpers';
import { format } from 'date-fns';

interface ReportingTableProps {
    operations?: PepperPayScheduleOperation[];
    nextKey?: string;
    isLoading: boolean;
    toolbarItems?: MuiTableToolbarItems;
    onScroll?: () => void;
    onDelete: (id: string) => void;
}

export const SchedulesTable: React.FC<ReportingTableProps> = ({
    operations,
    nextKey,
    isLoading,
    toolbarItems,
    onScroll,
    onDelete
}) => {
    const columns = React.useMemo<MuiTableColumnItem[]>(
        () => [
            {
                key: 'lastRan',
                label: 'Last Ran',
                render: (item: PepperPayScheduleOperation) => (
                    <TableCell key={`create-date-${item._id}`}>
                        {item.lastRan === 0 ? 'Never' : format(item.lastRan, 'dd/MM/yyyy h:mmaaa')}
                    </TableCell>
                )
            },
            {
                key: 'name',
                label: 'Name',
                render: (item: PepperPayScheduleOperation) => (
                    <TableCell key={`export-name-${item._id}`} align="center" size="small">
                        <Typography variant="caption" align="center">
                            {item.name}
                        </Typography>
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'frequency',
                label: 'Frequency',
                render: (item: PepperPayScheduleOperation) => (
                    <TableCell key={`export-frequency-${item._id}`} align="center" size="small">
                        <Typography variant="caption" align="center">
                            {titleize(String(item.interval))}
                        </Typography>
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'type',
                label: 'Type',
                render: (item: PepperPayScheduleOperation) => {
                    const { type } = getOperationTypeAndFormat(item.reportData.report_type);
                    return (
                        <TableCell key={`export-type-${item._id}`} align="center" size="small">
                            <Typography variant="caption" align="center">
                                {reportTypeLabelMap[type]}
                            </Typography>
                        </TableCell>
                    );
                },

                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'format',
                label: 'Format',
                render: (item: PepperPayScheduleOperation) => {
                    const { format } = getOperationTypeAndFormat(item.reportData.report_type);
                    return (
                        <TableCell key={`export-format-${item._id}`} align="center" size="small">
                            <Typography variant="caption" align="center">
                                {reportFormatLabelMap[format]}
                            </Typography>
                        </TableCell>
                    );
                },
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'action',
                label: 'Conected Account',
                render: (item: PepperPayScheduleOperation) => (
                    <TableCell key={`export-conected-account-${item._id}`} align="center" size="small">
                        <Typography variant="caption" align="center">
                            {item.reportData.parameters.connected_account}
                        </Typography>
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'remove',
                label: '',
                render: (item: PepperPayScheduleOperation) => (
                    <SchedulesDeleteActionCell
                        key={`export-action-${item._id}`}
                        operation={item}
                        onDelete={onDelete}
                    />
                ),
                headerProps: {
                    align: 'center'
                }
            }
        ],
        [onDelete]
    );

    const scroll = React.useMemo<MuiTableScrollItems>(
        () => ({ isMoreItems: !!nextKey, key: nextKey, onScroll }),
        [onScroll, nextKey]
    );
    const getRowKey = React.useCallback((item: PepperPayScheduleOperation) => String(item._id), []);
    return (
        <MuiTable
            alwaysShowHead
            data={operations}
            columns={columns}
            getRowKey={getRowKey}
            isLoading={isLoading || !!nextKey}
            toolbarItems={toolbarItems}
            scroll={scroll}
        />
    );
};

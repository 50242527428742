import { MenuManagerTable } from 'components/menu-manager/MenuManagerTable';
import { MainLayout } from 'layouts/MainLayout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuSummaryList } from 'store/menu-manager/menuManagerAction';
import { ApplicationState } from 'store/store';

export const MenuManager = () => {
    const { menuSummaryList, isLoading } = useSelector((state: ApplicationState) => state.menuManager);
    const dispatch = useDispatch();

    React.useEffect(() => {
        getMenuSummaryList(dispatch);
    }, [dispatch]);

    return (
        <MainLayout
            pageName="Menus"
            pageDescription="View and manage your menus and their associated locations"
        >
            <MenuManagerTable isLoading={isLoading} menuSummaryList={menuSummaryList} />
        </MainLayout>
    );
};

import { GridApi, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { Suggestion } from '@pepperhq/menu-sdk';
import { Option } from 'lib/types';
import { MuiModal } from 'ui/MuiModal';
import { SuggestionsForm } from './SuggestionsForm';
import React from 'react';

interface SuggestionsValueProps extends GridRenderEditCellParams {
    api: GridApi;
    options: Option[];
}

const SuggestionsValue: React.FC<SuggestionsValueProps> = ({ value, api, id, field, options }) => {
    const handleModalClose = React.useCallback(() => {
        api.setCellMode(id, field, 'view');
    }, [id, field, api]);
    const handleSave = React.useCallback(
        (suggestion: Suggestion) => {
            api.setEditCellValue({ id, field, value: [suggestion] });
            api.commitCellChange({ id, field });
        },
        [api, field, id]
    );
    const handleClear = React.useCallback(() => {
        api.setEditCellValue({ id, field, value: null });
        api.commitCellChange({ id, field });
    }, [api, field, id]);
    return (
        <MuiModal open={true} onClose={handleModalClose}>
            <SuggestionsForm
                options={options}
                suggestion={Array.isArray(value) && value.length ? value[0] : undefined}
                onClear={handleClear}
                onClose={handleModalClose}
                onSave={handleSave}
            />
        </MuiModal>
    );
};

export const SuggestionsEditCell = (props: GridRenderEditCellParams, options: Option[]) => (
    <SuggestionsValue {...props} options={options} />
);

import React from 'react';
import { TableCell } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { MuiCircularProgress } from 'lib/MuiCircularProgress';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { EnrichmentOperation } from './model/EnrichmentOperation';
import { overwritesApi } from './overwritesApi';
import { isDefined } from 'lib/typeguards';

interface ExportEnrichmentProgressCellProps {
    operation: EnrichmentOperation;
}

export const ExportEnrichmentProgressCell: React.FC<ExportEnrichmentProgressCellProps> = ({ operation }) => {
    const [loading, setLoading] = React.useState(false);
    const handleButtonClick = React.useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setLoading(true);
            const response = await overwritesApi.getExportFile(operation.result?.href);
            if (response.status === 200) {
                const blob = await response.blob();

                const downloadUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'Menu_Enrichments.xlsx';

                document.body.appendChild(link);

                link.click();
                link.remove();

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
                    window.URL.revokeObjectURL(downloadUrl);
                }, 100);
            }
            setLoading(false);
        },
        [operation]
    );
    const progress =
        operation.result && isDefined(operation.result.total)
            ? (operation.result.errors +
                  operation.result.exported +
                  operation.result.skipped +
                  (operation.result.removedUnlinked || 0)) /
                  operation.result.total || 1
            : undefined;
    return (
        <TableCell align="center" size="small">
            <MuiCircularProgress
                progress={isNaN(progress) ? -1 : progress * 100}
                isError={operation.status === OperationStatus.ERROR}
            >
                {operation.status === OperationStatus.DONE && operation.result && operation.result.href && (
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        onClick={handleButtonClick}
                    >
                        Download
                    </LoadingButton>
                )}
            </MuiCircularProgress>
        </TableCell>
    );
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { MuiTheme } from 'config/theme';
import * as googleHelpers from 'lib/googleHelpers';
// tslint:disable-next-line
import 'static/reset.css';
// tslint:disable-next-line
import 'static/fonts/fonts.css';

export const Head = () => (
    <Helmet>
        {process.env.NODE_ENV === 'production' && (
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`} />
        )}
        {process.env.NODE_ENV === 'production' && (
            <script type="text/javascript">{googleHelpers.getGoogleTagsManagerScript()}</script>
        )}
        {process.env.NODE_ENV === 'production' && (
            <script type="text/javascript">{googleHelpers.getGoogleOptimizeScript()}</script>
        )}
        {process.env.NODE_ENV === 'production' && (
            <style type="text/javascript">{googleHelpers.getAntiFlickerStyles()}</style>
        )}
        <script
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=geometry,drawing,places`}
        />
        <title>Pepper Management Console</title>
        <meta name="title" content="Pepper Management Console" />
        {/* PWA primary color */}
        <meta name="theme-color" content={MuiTheme.palette.primary.main} />
        <meta charSet="utf-8" />
    </Helmet>
);

import React from 'react';
import { Grid, styled } from '@mui/material';
import { isValidCoordinate } from 'components/utils/isValidCoordinate';
import { LegacyForm } from 'lib/LegacyForm';
import { Map } from 'lib/Map';
import { AutoForm } from 'ui/form/AutoForm';

const StyledMap = styled(Map)(({ theme }) => ({
    height: theme.spacing(38)
}));

interface LocationAddressEditCardComponentProps {
    geo: number[];
    form: LegacyForm;
    onBlur: (name: string, value: any) => void;
    onClose: () => void;
}

export const LocationAddressEditCardComponent = (props: LocationAddressEditCardComponentProps) => {
    const {
        geo: [lng, lat]
    } = props;
    const point = { lng, lat };

    return (
        <AutoForm {...props}>
            {({ geo, ...rest }, defaultRenderer) => (
                <React.Fragment>
                    <Grid item xs={12}>
                        {geo}
                    </Grid>
                    <Grid item sm={isValidCoordinate([lng, lat]) ? 6 : 12} xs={12}>
                        <Grid container spacing={1}>
                            {defaultRenderer(rest)}
                        </Grid>
                    </Grid>
                    {isValidCoordinate([lng, lat]) && (
                        <Grid item sm={6} xs={12}>
                            <StyledMap center={point} width={0} markers={[point]} />
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </AutoForm>
    );
};

import { Box, BoxProps } from '@mui/material';
import React from 'react';

export const ReadOnlyOverlay: React.FC<BoxProps> = ({ sx, ...props }) => (
    <Box
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            ...sx
        }}
        {...props}
    />
);

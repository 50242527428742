/* eslint-disable @typescript-eslint/no-misused-promises */
import { IOperationResult, OperationStatus } from 'components/operation/models/OperationModel';

export async function pollOperation<IDType>(
    operationFunc: (id: IDType) => Promise<IOperationResult<IDType>>,
    operation: IOperationResult<IDType>
): Promise<IOperationResult<IDType>> {
    return new Promise((resolve, reject) => {
        setTimeout(async () => {
            const result = await operationFunc(operation.id);
            if (!result) {
                reject(new Error('Could not get Operation'));
            }

            if (result.status === OperationStatus.PENDING) {
                resolve(pollOperation(operationFunc, result));
            }

            resolve(result);
        }, operation.etaMs);
    });
}

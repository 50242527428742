import { isDefined } from 'lib/typeguards';
import React from 'react';
import { EOrderPaymentType, IOrder, IOrderPayment } from '../model/Order';
import { clerksApi } from 'components/clerks-access/clerksApi';
import logger from 'lib/logger';

interface IPaymentTimelineItemUser {
    name: string;
    userId: string;
}

export interface IPaymentTimelineItem {
    id: number;
    type: EOrderPaymentType;
    amount: number;
    user?: IPaymentTimelineItemUser;
    receiptId?: string;
    date: string;
    refundable?: boolean;
    refunded?: boolean;
    itemIds?: number[];
    clerkId?: string;
    clerkName?: string;
    deleted?: boolean;
    refundReason?: string;
    refundNote?: string;
}

export function useOrderTimelinePayments(order: IOrder) {
    const [isLoading, setLoading] = React.useState(false);
    const [clerkIdToClerkName, setClerkIdToClerkName] = React.useState<Record<string, string>>();
    const paymentsBalance = React.useMemo(() => {
        const paymentsObject: Record<number, IOrderPayment & { balance: number }> = {};
        order?.payments?.forEach(item => {
            if (isDefined(item.parentId)) {
                if (paymentsObject[item.parentId]) {
                    paymentsObject[item.parentId] = {
                        ...paymentsObject[item.parentId],
                        amount: paymentsObject[item.parentId].amount,
                        balance: paymentsObject[item.parentId].balance + item.amount
                    };
                } else {
                    paymentsObject[item.parentId] = { ...item, balance: item.amount };
                }
            } else {
                paymentsObject[item.id] = paymentsObject[item.id]
                    ? { ...item, balance: paymentsObject[item.id].balance + item.amount }
                    : { ...item, balance: item.amount };
            }
        });
        return paymentsObject;
    }, [order?.payments]);
    React.useEffect(() => {
        const clerkIds = new Set<string>();
        if (Array.isArray(order?.payments)) {
            order.payments.forEach(item => {
                if (isDefined(item.clerkId)) {
                    clerkIds.add(item.clerkId);
                }
            });
            if (clerkIds.size) {
                setLoading(true);
                Promise.all(
                    [...clerkIds].map(clerkId =>
                        clerksApi.get(clerkId, { headers: { 'Cache-Control': 'max-age=3600' } })
                    )
                )
                    .then(result => {
                        const idToName: Record<string, string> = {};
                        result.forEach(clerkResult => {
                            if (!clerkResult.ok) {
                                throw new Error(clerkResult.body.message);
                            }
                            idToName[clerkResult.body.id] = clerkResult.body.name;
                        });
                        setClerkIdToClerkName(idToName);
                    })
                    .catch(e => {
                        logger.error(e.message, e);
                        setClerkIdToClerkName({});
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }, [order?.payments]);
    const timelineItems = React.useMemo(() => {
        const payments: IPaymentTimelineItem[] = [];
        if (!order || !Array.isArray(order?.payments)) {
            return payments;
        }
        const fullyRefunded = order.total.payments + order.total.refunds <= 0;
        order.payments.forEach(item => {
            const paymentUser = isDefined(item.userId)
                ? order.users.find(({ userId }) => item.userId === userId)
                : undefined;
            const timelineItem: IPaymentTimelineItem = {
                id: item.id,
                type: item.type,
                amount: item.amount,
                date: item.completedAt,
                receiptId: item.receiptId,
                deleted: item.deleted,
                clerkId: item.clerkId,
                clerkName: clerkIdToClerkName ? clerkIdToClerkName[item.clerkId] : undefined,
                refundReason: item.refundReason,
                refundNote: item.refundNote
            };
            if (paymentUser) {
                timelineItem.user = {
                    userId: paymentUser.userId,
                    name: `${paymentUser.firstName} ${paymentUser.lastName}`
                };
            }
            if (
                item.type !== EOrderPaymentType.PosCard &&
                item.type !== EOrderPaymentType.Cash &&
                item.amount > 0 &&
                !item.deleted
            ) {
                timelineItem.refundable = true;
            }
            if (fullyRefunded || !paymentsBalance[item.id] || !paymentsBalance[item.id].balance) {
                timelineItem.refunded = true;
            }
            payments.push(timelineItem);
        });
        return payments.sort(
            (a, b) => Date.parse(a.date ?? '01-01-3000') - Date.parse(b.date ?? '01-01-3000')
        );
    }, [clerkIdToClerkName, order, paymentsBalance]);
    return { timelineItems, isLoading };
}

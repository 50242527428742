import React from 'react';
import { Grid } from '@mui/material';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { MainLayout } from 'layouts/MainLayout';
import { OverwritesDrawer } from 'components/overwrites/drawer/OverwritesDrawer';
import { ExportEnrichments } from 'components/overwrites/ExportEnrichments';
import { ImportEnrichments } from 'components/overwrites/ImportEnrichments';
import { EnrichmentOperation } from 'components/overwrites/model/EnrichmentOperation';
import { overwritesApi } from 'components/overwrites/overwritesApi';
import { LOCATIONS } from 'config/routes';
import { Throbber } from 'ui/Throbber';

export const OverwritesPage: React.FC = () => {
    const [exportOperations, setExportOperations] = React.useState<EnrichmentOperation[]>([]);
    const [exportNextKey, setExportNextKey] = React.useState<string>();
    const [exportLoading, setExportLoading] = React.useState(true);
    const [exportError, setExportError] = React.useState<string>();
    const [importOperations, setImportOperations] = React.useState<EnrichmentOperation[]>([]);
    const [importNextKey, setImporttNextKey] = React.useState<string>();
    const [importLoading, setImportLoading] = React.useState(true);
    const [importError, setImportError] = React.useState<string>();
    const [selectedOperationId, setSelectedOperationId] = React.useState<string>(undefined);
    const selectedOperation = React.useMemo(() => {
        if (!selectedOperationId) {
            return undefined;
        }
        const exportOperation = exportOperations.find(item => item.id === selectedOperationId);
        if (exportOperation) {
            return exportOperation;
        }
        const importOperation = importOperations.find(item => item.id === selectedOperationId);
        if (importOperation) {
            return importOperation;
        }
        return undefined;
    }, [selectedOperationId, importOperations, exportOperations]);
    const handleDrawerClose = React.useCallback(() => {
        setSelectedOperationId(undefined);
    }, []);
    const loadExportOperations = React.useCallback(() => {
        overwritesApi
            .getExportOperations()
            .then(res => {
                setExportOperations(res.body.items);
                setExportNextKey(res.body.page.nextKey);
                setExportLoading(false);
            })
            .catch(e => {
                setExportOperations([]);
                setExportNextKey(undefined);
                setExportError(e.message);
                setExportLoading(false);
            });
        overwritesApi
            .getImportOperations()
            .then(res => {
                setImportOperations(res.body.items);
                setImporttNextKey(res.body.page.nextKey);
                setImportLoading(false);
            })
            .catch(e => {
                setImportOperations([]);
                setImporttNextKey(undefined);
                setImportError(e.message);
                setImportLoading(false);
            });
    }, []);
    React.useEffect(() => {
        loadExportOperations();
    }, [loadExportOperations]);
    const handleExportScroll = React.useCallback(() => {
        if (exportNextKey) {
            overwritesApi
                .getExportOperations(exportNextKey)
                .then(res => {
                    setExportOperations(current => [...current, ...res.body.items]);
                    setExportNextKey(res.body.page.nextKey);
                })
                .catch(e => {
                    setExportNextKey(undefined);
                    setExportError(e.message);
                    setExportLoading(false);
                });
        }
    }, [exportNextKey]);
    const handleImportScroll = React.useCallback(() => {
        if (importNextKey) {
            overwritesApi
                .getImportOperations(importNextKey)
                .then(res => {
                    setImportOperations(current => [...current, ...res.body.items]);
                    setImporttNextKey(res.body.page.nextKey);
                })
                .catch(e => {
                    setImporttNextKey(undefined);
                    setImportError(e.message);
                    setImportLoading(false);
                });
        }
    }, [importNextKey]);
    const handleClick = React.useCallback((item: EnrichmentOperation) => {
        setSelectedOperationId(item.id);
    }, []);
    const isLoading = importLoading || exportLoading;
    const isError = importError || exportError;
    const showLanding = !importOperations.length && !exportOperations.length;
    if (isLoading && !isError) {
        return <Throbber removeMargin text="We're just loading your menu customisations..." />;
    }
    return (
        <MainLayout pageName="Menu Customisation" pageDescription="Import and export menu customisation">
            {isError && <ErrorLayout title="Unexpected Error" fallbackUrl={LOCATIONS} fallbackTitle="Home" />}
            {!isLoading && !isError && (
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    <Grid item xs={12} sm={6}>
                        <ExportEnrichments
                            operations={exportOperations}
                            onScroll={handleExportScroll}
                            nextKey={exportNextKey}
                            showLanding={showLanding}
                            onClick={handleClick}
                            setOperations={setExportOperations}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ImportEnrichments
                            operations={importOperations}
                            onScroll={handleImportScroll}
                            showLanding={showLanding}
                            onClick={handleClick}
                            setOperations={setImportOperations}
                        />
                    </Grid>
                </Grid>
            )}
            <OverwritesDrawer operation={selectedOperation} onClose={handleDrawerClose} />
        </MainLayout>
    );
};

import React from 'react';
import { Box, TextField } from '@mui/material';
import { DateRange } from '@mui/lab';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { format, isAfter, isBefore } from 'date-fns';

export interface DateRangePickerValue {
    startDate: Date;
    endDate: Date;
}

export interface MuiDateRangePickerProps {
    value?: DateRangePickerValue;
    disableFuture?: boolean;
    disablePast?: boolean;
    onChange: (value: DateRangePickerValue) => void;
    maxDate?: Date;
    minDate?: Date;
    shouldDisableDate?: (date: Date) => boolean;
    disabled?: boolean;
}

export const MuiDateRangePicker: React.FC<MuiDateRangePickerProps> = props => {
    const { disableFuture, disablePast, value, onChange, maxDate, minDate, shouldDisableDate, disabled } =
        props;
    const [dateRange, setDateRange] = React.useState<DateRange<Date>>(
        value ? [value.startDate, value.endDate] : [null, null]
    );
    const handleChange = React.useCallback(
        ([start, end]: DateRange<Date>) => {
            setDateRange([start, end]);
            onChange({ startDate: start, endDate: end });
        },
        [onChange]
    );
    const isValidEndDate = React.useMemo(
        () => (maxDate ? isBefore(new Date(value?.endDate), new Date(maxDate)) : true),
        [maxDate, value?.endDate]
    );
    const isValidStartDate = React.useMemo(
        () => (minDate ? isAfter(new Date(value?.startDate), new Date(minDate)) : true),
        [minDate, value?.startDate]
    );
    const getHelperText = React.useCallback(
        (validStart, validEnd) => {
            if (!validStart) {
                return `Please enter date starting after ${format(minDate, 'dd MMM yyyy')}`;
            }
            if (!validEnd) {
                return `Please enter date up to ${format(maxDate, 'dd MMM yyyy')}`;
            }
            return undefined;
        },
        [minDate, maxDate]
    );
    const renderInput = React.useCallback(
        (startProps, endProps) => (
            <React.Fragment>
                <TextField
                    {...startProps}
                    error={!isValidStartDate}
                    helperText={getHelperText(isValidStartDate, true)}
                />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField
                    {...endProps}
                    error={!isValidEndDate}
                    helperText={getHelperText(true, isValidEndDate)}
                />
            </React.Fragment>
        ),
        [getHelperText, isValidEndDate, isValidStartDate]
    );
    return (
        <StaticDateRangePicker
            shouldDisableDate={shouldDisableDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={handleChange}
            value={dateRange}
            maxDate={maxDate}
            minDate={minDate}
            renderInput={renderInput}
            inputFormat="dd/MM/yyyy"
            disabled={disabled}
        />
    );
};

import * as React from 'react';
import { connect } from 'react-redux';
import { match, withRouter } from 'react-router';
import get from 'get-value';
import { History, Location as LocationType } from 'history';
import { Dispatch } from 'redux';
import { LocationAddressCardComponent } from 'components/location/cards/LocationAddressCardComponent';
import { LocationAddressEditCardComponent } from 'components/location/cards/LocationAddressEditCardComponent';
import { createLocationAddressForm } from 'components/location/forms/createLocationAddressForm';
import { Location } from 'components/location/models/LocationModel';
import {
    geoSuggestAddressFormatter,
    isValidAddress
} from 'components/utils/geoSuggest/geoSuggestAddressFormatter';
import { getGeoArrayFromObject } from 'components/utils/geoSuggest/getGeoArrayFromObject';
import { isValidCoordinate } from 'components/utils/isValidCoordinate';
import { MESSAGE_LOCATIONS_UPDATE_ERROR, MESSAGE_LOCATIONS_UPDATE_SUCCESS } from 'config/messages';
import { LegacyForm } from 'lib/LegacyForm';
import { enqueueSnackbar as _enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MuiNotificationOptions } from 'ui/MuiNotifier';
import { Panel } from 'ui/Panel';
import { locationApi } from '../LocationApi';

export interface LocationAddressCardProps {
    location: LocationType;
    enqueueSnackbar: (message: string, options: MuiNotificationOptions) => void;
    setCurrentLocation: (location: Location) => void;
    match: match;
    currentLocation: Location;
    history: History;
}

interface IState {
    isEditing: boolean;
    geo?: number[];
    isMounted: boolean;
}

export class LocationAddressCard extends React.Component<LocationAddressCardProps, IState> {
    state = {
        isEditing: false,
        geo: this.props.currentLocation.geo,
        isMounted: false
    };
    handleSubmit = async (data: any) => {
        const locationId = this.props.currentLocation._id;
        const { geo, ...address } = data;
        const { enqueueSnackbar } = this.props;
        const response = await locationApi.update(locationId, {
            body: {
                address,
                geo: getGeoArrayFromObject(geo)
            }
        });
        if (response.ok) {
            enqueueSnackbar(MESSAGE_LOCATIONS_UPDATE_SUCCESS(this.props.currentLocation.title), {
                variant: 'success'
            });
            this.updateForm(response.body);
            this.props.setCurrentLocation(response.body);
            if (this.state.isMounted) {
                this.setState({ geo: getGeoArrayFromObject(geo) });
            }
        } else {
            enqueueSnackbar(MESSAGE_LOCATIONS_UPDATE_ERROR, { variant: 'error' });
        }
        this.setState({ isEditing: false });
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    form = new LegacyForm(createLocationAddressForm(), this.handleSubmit);
    componentDidMount() {
        this.updateForm(this.props.currentLocation);
        this.setState({ isMounted: true });
    }
    componentWillUnmount() {
        this.setState({ isMounted: false });
    }
    updateForm = (data: Location) => {
        const { geo, address } = data;
        const geoObject = Array.isArray(geo) ? { lat: geo[1], lng: geo[0] } : null;
        this.form.update({ ...address, geo: geoObject });
    };
    toggleState = () => {
        this.setState(state => ({ isEditing: !state.isEditing, geo: this.props.currentLocation.geo }));
        this.updateForm(this.props.currentLocation);
    };
    onBlur = (name: string, value: any) => {
        if (value && name === 'geo') {
            let googleMapsShortUrl: string;
            if (get(value, 'location')) {
                const {
                    location: { lat, lng }
                } = value;
                const placeId = get(value, 'placeId');
                if (isValidCoordinate([lng, lat])) {
                    this.setState({ geo: [lng, lat] });
                    googleMapsShortUrl = this.locationUrl(lat, lng, placeId);
                }
            }
            const address = geoSuggestAddressFormatter(value);
            if (isValidAddress(address)) {
                this.form.update({ ...address, googleMapsShortUrl });
            }
        }
    };
    locationUrl = (lat: number, lng: number, placeId: string) =>
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${placeId}`;
    render() {
        const { isEditing } = this.state;
        return (
            <Panel title="Address" showAction={!isEditing} onClick={this.toggleState} divider>
                {isEditing ? (
                    <LocationAddressEditCardComponent
                        onBlur={this.onBlur}
                        onClose={this.toggleState}
                        form={this.form}
                        geo={this.state.geo}
                    />
                ) : (
                    <LocationAddressCardComponent location={this.props.currentLocation} />
                )}
            </Panel>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    enqueueSnackbar: (message: string, options: MuiNotificationOptions) =>
        dispatch(_enqueueSnackbar(message, options))
});

export const LocationAddressCardConnected = withRouter(
    connect(null, mapDispatchToProps)(LocationAddressCard)
);

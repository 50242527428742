import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const SquareSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId, posLocations }) => {
    const posLocationOptions = React.useMemo(
        () =>
            posLocations?.map(posLocation => ({
                label: `${posLocation.name} (${posLocation.externalLocationId})`,
                value: posLocation.externalLocationId
            })) ?? [],
        [posLocations]
    );

    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <>
                    <SettingDisplay label="Production Environment">
                        <SwitchFormField name="squareIsProduction" />
                    </SettingDisplay>
                    <SettingDisplay label="Merchant ID">
                        <TextFormField name="squareMerchantId" />
                    </SettingDisplay>
                    <SettingDisplay label="Loyalty Program ID">
                        <TextFormField name="squareLoyaltyProgramId" />
                    </SettingDisplay>
                    <SettingDisplay label="Webhook HMAC Secret">
                        <TextFormField name="squareWebhookHmacSecret" />
                    </SettingDisplay>
                    <SettingDisplay label="Delete User In Square">
                        <SwitchFormField name="userDeletion.deletePosAccountOnUserDeletion" />
                    </SettingDisplay>
                </>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <SettingDisplay label="Location ID">
                    <SelectFormField options={posLocationOptions} name="squareLocationId" />
                </SettingDisplay>
            )}
        </>
    );
};

import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class CardsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'cards', { 'x-api-version': 4 });
    }
    deleteCard(id: string) {
        return this.httpClient.delete(`${this.api}/cards/${id}`, {
            headers: { 'x-api-version': 4 }
        });
    }
}

export const cardsApi = new CardsApi(httpClient);

export enum LoyaltyProvider {
    PEPPER = 'PEPPER',
    SQUARE = 'SQUARE',
    POWERCARD = 'POWERCARD',
    ATREEMO = 'ATREEMO',
    REVEL = 'REVEL'
}

export enum LoyaltyTemplate {
    AD_HOC = 'AD_HOC',
    PSEUDO_CURRENCY = 'PSEUDO_CURRENCY',
    STAMP_CARD = 'STAMP_CARD',
    CLUB = 'CLUB',
    LEVEL = 'LEVEL'
}

export enum LoyaltyScope {
    ITEM = 'ITEM',
    CATEGORY = 'CATEGORY',
    BASKET = 'BASKET',
    ITEM_OR_CATEGORY = 'ITEM_OR_CATEGORY'
}

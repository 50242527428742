import React from 'react';
import { Box, Chip, Typography, styled } from '@mui/material';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { FormikValues } from 'formik';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { KeyFileInputFormField } from 'lib/form/fields/KeyFileInputFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import {
    getTippingDefault,
    parseFloatOrUndefined,
    defaultTippingIndexLabel,
    parseFloatOrNull
} from 'lib/helpers';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { BraintreeSettingsDisplay } from './PaymentProvider/Braintree/BraintreeSettingsDisplay';
import { BraintreeSettingsEdit } from './PaymentProvider/Braintree/BraintreeSettingsEdit';
import { JudopaySettingsDisplay } from './PaymentProvider/JudoPay/JudopaySettingsDisplay';
import { JudopaySettingsEdit } from './PaymentProvider/JudoPay/JudopaySettingsEdit';
import { SquarePaymentsSettingsDisplay } from './PaymentProvider/Square/SquarePaymentsSettingsDisplay';
import { SquarePaymentsSettingsEdit } from './PaymentProvider/Square/SquarePaymentsSettingsEdit';
import { StripeSettingsDisplay } from './PaymentProvider/Stripe/StripeSettingsDisplay';
import { StripeSettingsEdit } from './PaymentProvider/Stripe/StripeSettingsEdit';
import { WorldPaySettingsDisplay } from './PaymentProvider/WorldPay/WorldPaySettingsDisplay';
import { WorldPaySettingsEdit } from './PaymentProvider/WorldPay/WorldPaySettingsEdit';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Permission, Resource } from '@pepperhq/auth-client';
import { isDefined } from 'lib/typeguards';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_GENERAL_ERROR } from 'config/messages';

const PREFIX = 'PaymentsSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`
};

const StyledMuiCardForm = styled(MuiCardForm)(({ theme }) => ({
    [`&.${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(1)
    }
}));

const paymentModeOptions: Option[] = [
    { label: 'Pay As You Go', value: 'PAYG' },
    { label: 'Prepay', value: 'PREPAY' }
];

export enum EPaymentProvider {
    Stripe = 'STRIPE',
    Judopay = 'JUDOPAY',
    Braintree = 'BRAINTREE',
    Worldpay = 'WORLDPAY',
    None = 'NONE'
}

export enum ETippingProvider {
    None = 'None',
    TIPJAR = 'TIPJAR'
}

const paymentProviderOptions: Option[] = [
    { label: 'Braintree', value: EPaymentProvider.Braintree },
    { label: 'JudoPay', value: EPaymentProvider.Judopay },
    { label: 'Stripe', value: EPaymentProvider.Stripe },
    { label: 'WorldPay', value: EPaymentProvider.Worldpay },
    { label: 'None', value: EPaymentProvider.None }
];

const tippingProviderOptions: Option[] = [
    { label: 'None', value: null },
    { label: 'TIPJAR', value: ETippingProvider.TIPJAR }
];

enum TipLabel {
    TipValue1 = '0',
    TipValue2 = '1',
    TipValue3 = '2',
    TipValue4 = '3'
}

const defaultTipOptions: Option[] = [
    { label: 'Tip Value 1', value: TipLabel.TipValue1 },
    { label: 'Tip Value 2', value: TipLabel.TipValue2 },
    { label: 'Tip Value 3', value: TipLabel.TipValue3 },
    { label: 'Tip Value 4', value: TipLabel.TipValue4 }
];

export const PaymentsSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId,
    globalSettings
}) => {
    const dispatch = useDispatch();
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const hasInternalSettingsWritePermission = React.useMemo(
        () => claims.hasPermission(Resource.InternalSetting, Permission.write),
        [claims]
    );
    const hasInternalSettingsReadPermission = React.useMemo(
        () => claims.hasPermission(Resource.InternalSetting, Permission.read),
        [claims]
    );

    const isScenarioTipping = React.useMemo(
        () =>
            isDefined(globalSettings?.tippingPreorder) ||
            isDefined(globalSettings?.tippingOrderToTable) ||
            isDefined(globalSettings?.tippingPayAtTable) ||
            isDefined(globalSettings?.tippingTab) ||
            isDefined(globalSettings?.tippingPreorderEnabled) ||
            isDefined(globalSettings?.tippingOrderToTableEnabled) ||
            isDefined(globalSettings?.tippingPayAtTableEnabled) ||
            isDefined(globalSettings?.tippingTabEnabled),
        [
            globalSettings?.tippingPreorder,
            globalSettings?.tippingOrderToTable,
            globalSettings?.tippingPayAtTable,
            globalSettings?.tippingTab,
            globalSettings?.tippingPreorderEnabled,
            globalSettings?.tippingOrderToTableEnabled,
            globalSettings?.tippingPayAtTableEnabled,
            globalSettings?.tippingTabEnabled
        ]
    );

    const handleSubmitAppleGooglePay = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        applePayEnabled: values.applePayEnabled,
                        applePayWebEnabled: values.applePayWebEnabled,
                        applePay: {
                            merchantId: values.applePayMerchantId,
                            countryCode: values.applePayCountryCode,
                            supportedNetworksMastercard: values.applePaySupportedNetworksMastercard,
                            supportedNetworksVisa: values.applePaySupportedNetworksVisa,
                            supportedNetworksAmex: values.applePaySupportedNetworksAmex,
                            merchantIdentityCertificateAsBase64: values.merchantIdentityCertificateAsBase64
                        },
                        googlePayEnabled: values.googlePayEnabled,
                        googlePayWebEnabled: values.googlePayWebEnabled,
                        googlePay: {
                            supportedNetworksMastercard: values.googlePaySupportedNetworksMastercard,
                            supportedNetworksVisa: values.googlePaySupportedNetworksVisa,
                            supportedNetworksAmex: values.googlePaySupportedNetworksAmex,
                            merchantTitle: values.googlePayMerchantTitle,
                            merchantId: values.googlePayMerchantId,
                            countryCode: values.googlePayCountryCode
                        }
                    };
                }
                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitTipping = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingEnabled: values.tippingEnabled,
                        tipping: {
                            scheme: values.tippingScheme,
                            default: parseFloatOrUndefined(values.tippingDefault ?? 0),
                            values: values.tippingOptions.map((tippingValue: string) =>
                                parseFloatOrUndefined(tippingValue)
                            )
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitTippingPreorder = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingPreorderEnabled: values.preorderTippingEnabled,
                        tippingPreorder: {
                            scheme: values.preorderTippingScheme,
                            default: parseFloatOrUndefined(values.preorderTippingDefault ?? 0),
                            values: values.preorderTippingOptions.map((preorderTippingValue: string) =>
                                parseFloatOrUndefined(preorderTippingValue)
                            )
                        }
                    };
                } else {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingPreorderEnabled: values.preorderTippingEnabled
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitTippingOrderToTable = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingOrderToTableEnabled: values.orderToTableTippingEnabled,
                        tippingOrderToTable: {
                            scheme: values.orderToTableTippingScheme,
                            default: parseFloatOrUndefined(values.orderToTableTippingDefault ?? 0),
                            values: values.orderToTableTippingOptions.map(
                                (orderToTableTippingValue: string) =>
                                    parseFloatOrUndefined(orderToTableTippingValue)
                            )
                        }
                    };
                } else {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingOrderToTableEnabled: values.orderToTableTippingEnabled
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitTippingTab = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingTabEnabled: values.tabTippingEnabled,
                        tippingTab: {
                            scheme: values.tabTippingScheme,
                            default: parseFloatOrUndefined(values.tabTippingDefault ?? 0),
                            values: values.tabTippingOptions.map((tabTippingValue: string) =>
                                parseFloatOrUndefined(tabTippingValue)
                            )
                        }
                    };
                } else {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingTabEnabled: values.tabTippingEnabled
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitTippingPayAtTable = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingPayAtTableEnabled: values.payAtTableTippingEnabled,
                        tippingPayAtTable: {
                            scheme: values.payAtTableTippingScheme,
                            default: parseFloatOrUndefined(values.payAtTableTippingDefault ?? 0),
                            values: values.payAtTableTippingOptions.map((payAtTableTippingValue: string) =>
                                parseFloatOrUndefined(payAtTableTippingValue)
                            )
                        }
                    };
                } else {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tippingPayAtTableEnabled: values.payAtTableTippingEnabled
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitPayments = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                // Both Contexts
                let settingsToUpdate: IPublicAndPrivateSettings = {
                    transaction: {
                        maximumAmount: parseFloatOrUndefined(values.maximumTransactionLimit),
                        minimumAmount: parseFloatOrUndefined(values.minimumTransactionLimit)
                    }
                };

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        payments: {
                            mode: values.paymentMode,
                            topupAmountDefault: parseFloatOrUndefined(values.defaultTopUpAmount),
                            topupAmountAlternative: parseFloatOrUndefined(values.alternateTopUpAmount)
                        }
                    };
                }
                if (Object.keys(settingsToUpdate).length === 0) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [onSettingsSaved, locationId]
    );

    const handleSubmitPaymentProvider = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                // Both Contexts
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        paymentProvider: values.paymentProvider
                    };
                }
                switch (values.paymentProvider) {
                    case 'SQUARE':
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                square: {
                                    isProduction: values.squareIsProduction
                                }
                            };
                        }
                        break;
                    case 'STRIPE':
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                stripe: {
                                    secretKey: values.stripeSecretKey,
                                    publishableKey: values.stripePublishableKey,
                                    accountId: values.stripeAccountId,
                                    disputeNotificationEmail: values.stripeDisputeNotificationEmail,
                                    franchiseConnectAccountId: values.stripeFranchiseAccountId,
                                    franchiseConnectFeePercentage: parseFloatOrUndefined(
                                        values.stripeFranchiseFeePercentage
                                    ),
                                    franchiseConnectFeePercentageCardholderPresent: parseFloatOrNull(
                                        values.stripeFranchiseFeePercentageCardholderPresent
                                    ),
                                    isProduction: values.stripeIsProduction,
                                    ...(hasInternalSettingsWritePermission && {
                                        connectEnabled: values.stripeConnectEnabled,
                                        gatewayFee: parseFloatOrUndefined(values.stripeGatewayFee),
                                        gatewayFeeCardholderPresent: parseFloatOrNull(
                                            values.stripeGatewayFeeCardholderPresent
                                        ),
                                        transactionFeeRate: parseFloatOrUndefined(
                                            values.stripeTransactionFeeRate
                                        ),
                                        transactionFeeRateCardholderPresent: parseFloatOrNull(
                                            values.stripeTransactionFeeRateCardholderPresent
                                        ),
                                        threeDS2Fee: parseFloatOrUndefined(values.stripeThreeDS2Fee)
                                    })
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                stripe: {
                                    accountId: values.stripeAccountId,
                                    disputeNotificationEmail: values.stripeDisputeNotificationEmail,
                                    locationId: values.stripeLocationId,
                                    franchiseConnectAccountId: values.stripeFranchiseAccountId,
                                    franchiseConnectFeePercentage: parseFloatOrUndefined(
                                        values.stripeFranchiseFeePercentage
                                    ),
                                    franchiseConnectFeePercentageCardholderPresent: parseFloatOrNull(
                                        values.stripeFranchiseFeePercentageCardholderPresent
                                    )
                                }
                            };
                        }
                        break;
                    case 'JUDOPAY':
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                judopay: {
                                    isProduction: values.judopayIsProduction,
                                    token: values.judopayToken,
                                    judoId: values.judopayJudoId,
                                    secret: values.judopaySecret,
                                    clientToken: values.judopayClientToken,
                                    clientSecret: values.judopayClientSecret,
                                    useCardCheckTokenisation: values.judopayUseCardCheckTokenisation
                                }
                            };
                        } else {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                judopay: {
                                    judoId: values.judopayJudoId
                                }
                            };
                        }
                        break;
                    case 'BRAINTREE':
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                braintree: {
                                    isProduction: values.braintreeIsProduction,
                                    merchantId: values.braintreeMerchantId,
                                    publicKey: values.braintreePublicKey,
                                    privateKey: values.braintreePrivateKey
                                }
                            };
                        }
                        break;
                    case 'WORLDPAY':
                        if (locationId === undefined) {
                            settingsToUpdate = {
                                ...settingsToUpdate,
                                worldpay: {
                                    isProduction: values.worldpayIsProduction,
                                    waitForSettlement: values.worldpayWaitForSettlement,
                                    username: values.worldpayUsername,
                                    password: values.worldpayPassword,
                                    merchantId: values.worldpayMerchantId,
                                    checkoutId: values.worldpayCheckoutId,
                                    googlePayMerchantId: values.worldpayGooglePayMerchantId
                                }
                            };
                        }
                        break;
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                dispatch(enqueueSnackbar(MESSAGE_GENERAL_ERROR, { variant: 'error' }));
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [dispatch, hasInternalSettingsWritePermission, locationId, onSettingsSaved]
    );

    const handleSubmitTippingProvider = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                if (values.tipProvider === ETippingProvider.TIPJAR && !values.tipjarShortcutCode) {
                    dispatch(
                        enqueueSnackbar('Shortcut code must be provided to use TIPJAR.', { variant: 'error' })
                    );

                    return false;
                }

                // Location-scoped Settings
                if (locationId !== undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        tipProvider: values.tipProvider,
                        tipjar: {
                            shortcutCode: values.tipjarShortcutCode
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);

                await onSettingsSaved();
                return true;
            } catch (err) {
                dispatch(enqueueSnackbar(MESSAGE_GENERAL_ERROR, { variant: 'error' }));
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [locationId, onSettingsSaved, dispatch]
    );

    // Square cannot be chosen, so should be added only when it is square, and disabled
    const modifiedPaymentProviderOptions = React.useMemo(() => {
        if (settings?.posProvider === 'SQUARE') {
            return [...paymentProviderOptions, { label: 'Square', value: 'SQUARE' }];
        }
        return paymentProviderOptions;
    }, [settings]);

    const globalPaymentProvider = React.useMemo(() => {
        if (locationId === undefined) {
            if (settings?.posProvider === 'SQUARE') {
                return 'SQUARE';
            }
            return settings?.paymentProvider;
        }
        if (globalSettings?.posProvider === 'SQUARE') {
            return 'SQUARE';
        }
        return globalSettings?.paymentProvider;
    }, [locationId, globalSettings, settings]);

    const renderPaymentProviderEdit = React.useCallback(
        (values: FormikValues) => (
            <Box display="flex" flexDirection="column">
                {locationId === undefined && (
                    <SettingDisplay
                        label="Provider"
                        buildtimeAndroid
                        buildtimeIOS
                        description={
                            globalPaymentProvider === 'SQUARE'
                                ? 'This setting is managed by your Square POS Provider and cannot be changed'
                                : undefined
                        }
                    >
                        <SelectFormField
                            options={modifiedPaymentProviderOptions}
                            name="paymentProvider"
                            disabled={settings?.posProvider === 'SQUARE'}
                        />
                    </SettingDisplay>
                )}
                {values.paymentProvider === 'SQUARE' && (
                    <SquarePaymentsSettingsEdit
                        settings={settings}
                        locationId={locationId}
                        globalSettings={globalSettings}
                    />
                )}
                {values.paymentProvider === 'STRIPE' && (
                    <StripeSettingsEdit
                        settings={settings}
                        locationId={locationId}
                        hasInternalSettingsWritePermission={hasInternalSettingsWritePermission}
                        globalSettings={globalSettings}
                    />
                )}
                {values.paymentProvider === 'JUDOPAY' && (
                    <JudopaySettingsEdit
                        settings={settings}
                        globalSettings={globalSettings}
                        locationId={locationId}
                    />
                )}
                {values.paymentProvider === 'BRAINTREE' && (
                    <BraintreeSettingsEdit
                        settings={settings}
                        globalSettings={globalSettings}
                        locationId={locationId}
                    />
                )}
                {values.paymentProvider === 'WORLDPAY' && (
                    <WorldPaySettingsEdit
                        settings={settings}
                        globalSettings={globalSettings}
                        locationId={locationId}
                    />
                )}
            </Box>
        ),
        [
            globalPaymentProvider,
            hasInternalSettingsWritePermission,
            locationId,
            modifiedPaymentProviderOptions,
            settings,
            globalSettings
        ]
    );

    const renderTippingProviderEdit = React.useCallback(
        (values: FormikValues) => (
            <Box display="flex" flexDirection="column">
                <SettingDisplay label="Provider">
                    <SelectFormField
                        options={tippingProviderOptions}
                        name="tipProvider"
                        displayEmpty={true}
                        emptyLabel="None"
                    />
                </SettingDisplay>
                {values.tipProvider === ETippingProvider.TIPJAR && (
                    <SettingDisplay label="Shortcut Code">
                        <TextFormField name="tipjarShortcutCode" />
                    </SettingDisplay>
                )}
            </Box>
        ),
        []
    );

    const validationSchema = Yup.object().shape({
        stripeDisputeNotificationEmail: Yup.string().email('Invalid email.')
    });

    const paymentMode = React.useMemo(() => {
        if (!settings) {
            return 'None';
        }
        return paymentModeOptions.find(option => option.value === settings?.payments?.mode)?.label ?? 'None';
    }, [settings]);

    return (
        <Box minWidth="500px">
            {/* Cards for each settings block */}
            {(locationId === undefined || ['JUDOPAY', 'STRIPE'].includes(globalPaymentProvider)) && (
                <StyledMuiCardForm
                    title="Payment Provider"
                    validationSchema={validationSchema}
                    initialValues={{
                        paymentProvider: globalPaymentProvider,

                        /* SQUARE */
                        squareIsProduction: settings?.square?.isProduction ?? false,

                        /* STRIPE */
                        stripeIsProduction: settings?.stripe?.isProduction ?? false,
                        stripeSecretKey: settings?.stripe?.secretKey,
                        stripePublishableKey: settings?.stripe?.publishableKey,
                        stripeConnectEnabled: settings?.stripe?.connectEnabled ?? false,
                        stripeAccountId: settings?.stripe?.accountId,
                        stripeGatewayFee: settings?.stripe?.gatewayFee ?? 0.2,
                        stripeGatewayFeeCardholderPresent: settings?.stripe?.gatewayFeeCardholderPresent,
                        stripeTransactionFeeRate: settings?.stripe?.transactionFeeRate ?? 0.02,
                        stripeTransactionFeeRateCardholderPresent:
                            settings?.stripe?.transactionFeeRateCardholderPresent,
                        stripeThreeDS2Fee: settings?.stripe?.threeDS2Fee ?? 0.2,
                        stripeFranchiseAccountId: settings?.stripe?.franchiseConnectAccountId ?? '',
                        stripeFranchiseFeePercentage: settings?.stripe?.franchiseConnectFeePercentage ?? 0,
                        stripeFranchiseFeePercentageCardholderPresent:
                            settings?.stripe?.franchiseConnectFeePercentageCardholderPresent,
                        stripeDisputeNotificationEmail: settings?.stripe?.disputeNotificationEmail ?? '',
                        stripeLocationId: settings?.stripe?.locationId ?? '',

                        /* JUDOPAY */
                        judopayIsProduction: settings?.judopay?.isProduction ?? false,
                        judopayJudoId: settings?.judopay?.judoId,
                        judopayToken: settings?.judopay?.token,
                        judopaySecret: settings?.judopay?.secret,
                        judopayClientToken: settings?.judopay?.clientToken,
                        judopayClientSecret: settings?.judopay?.clientSecret,
                        judopayUseCardCheckTokenisation: settings?.judopay?.useCardCheckTokenisation ?? false,

                        /* BRAINTREE */
                        braintreeIsProduction: settings?.braintree?.isProduction ?? false,
                        braintreeMerchantId: settings?.braintree?.merchantId,
                        braintreePublicKey: settings?.braintree?.publicKey,
                        braintreePrivateKey: settings?.braintree?.privateKey,

                        /* WORLDPAY */
                        worldpayIsProduction: settings?.worldpay?.isProduction ?? false,
                        worldpayWaitForSettlement: settings?.worldpay?.waitForSettlement ?? false,
                        worldpayUsername: settings?.worldpay?.username,
                        worldpayPassword: settings?.worldpay?.password,
                        worldpayMerchantId: settings?.worldpay?.merchantId,
                        worldpayCheckoutId: settings?.worldpay?.checkoutId,
                        worldpayGooglePayMerchantId: settings?.worldpay?.googlePayMerchantId
                    }}
                    onSubmit={handleSubmitPaymentProvider}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            {locationId === undefined && (
                                <SettingDisplay
                                    label="Provider"
                                    description={
                                        globalPaymentProvider === 'SQUARE'
                                            ? 'This setting is managed by your Square POS Provider and cannot be changed'
                                            : undefined
                                    }
                                    buildtimeAndroid
                                    buildtimeIOS
                                >
                                    <Chip
                                        label={
                                            modifiedPaymentProviderOptions.find(
                                                option => option.value === globalPaymentProvider
                                            )?.label ?? 'None'
                                        }
                                        color="primary"
                                    />
                                </SettingDisplay>
                            )}
                            {/* SQUARE */}
                            {globalPaymentProvider === 'SQUARE' && (
                                <SquarePaymentsSettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {/* STRIPE */}
                            {globalPaymentProvider === 'STRIPE' && (
                                <StripeSettingsDisplay
                                    hasInternalSettingsReadPermission={hasInternalSettingsReadPermission}
                                    settings={settings}
                                    locationId={locationId}
                                    globalSettings={globalSettings}
                                />
                            )}
                            {/* JUDOPAY */}
                            {globalPaymentProvider === 'JUDOPAY' && (
                                <JudopaySettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {/* BRAINTREE */}
                            {globalPaymentProvider === 'BRAINTREE' && (
                                <BraintreeSettingsDisplay settings={settings} locationId={locationId} />
                            )}
                            {globalPaymentProvider === 'WORLDPAY' && (
                                <WorldPaySettingsDisplay settings={settings} locationId={locationId} />
                            )}
                        </Box>
                    }
                    childrenWhileEditing={renderPaymentProviderEdit}
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}
            {locationId !== undefined && globalPaymentProvider === 'STRIPE' && (
                <StyledMuiCardForm
                    title="Digital Cash Tipping"
                    initialValues={{
                        tipProvider: settings?.tipProvider,
                        tipjarShortcutCode: settings?.tipjar?.shortcutCode
                    }}
                    onSubmit={handleSubmitTippingProvider}
                    childrenWhileDisplaying={
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay label="Provider">
                                <Typography variant="body2">{settings?.tipProvider ?? 'None'}</Typography>
                            </SettingDisplay>
                            {settings?.tipProvider === 'TIPJAR' && (
                                <SettingDisplay label="Shortcut Code">
                                    <Typography variant="body2">{settings?.tipjar?.shortcutCode}</Typography>
                                </SettingDisplay>
                            )}
                        </Box>
                    }
                    childrenWhileEditing={renderTippingProviderEdit}
                    className={classes.settingsCard}
                    boxClassName={classes.displayClassName}
                />
            )}
            {locationId === undefined && (
                <>
                    <StyledMuiCardForm
                        title="Apple & Google Pay"
                        initialValues={{
                            applePayEnabled: settings?.applePayEnabled ?? false,
                            applePayWebEnabled: settings?.applePayWebEnabled ?? false,
                            applePayMerchantId: settings?.applePay?.merchantId,
                            applePayCountryCode: settings?.applePay?.countryCode,
                            applePaySupportedNetworksMastercard:
                                settings?.applePay?.supportedNetworksMastercard ?? false,
                            applePaySupportedNetworksVisa: settings?.applePay?.supportedNetworksVisa ?? false,
                            applePaySupportedNetworksAmex: settings?.applePay?.supportedNetworksAmex ?? false,
                            merchantIdentityCertificateAsBase64:
                                settings?.applePay?.merchantIdentityCertificateAsBase64,
                            googlePayEnabled: settings?.googlePayEnabled ?? false,
                            googlePayWebEnabled: settings?.googlePayWebEnabled ?? false,
                            googlePayMerchantTitle: settings?.googlePay?.merchantTitle,
                            googlePayMerchantId: settings?.googlePay?.merchantId,
                            googlePayCountryCode: settings?.googlePay?.countryCode,
                            googlePaySupportedNetworksMastercard:
                                settings?.googlePay?.supportedNetworksMastercard ?? false,
                            googlePaySupportedNetworksVisa:
                                settings?.googlePay?.supportedNetworksVisa ?? false,
                            googlePaySupportedNetworksAmex:
                                settings?.googlePay?.supportedNetworksAmex ?? false
                        }}
                        onSubmit={handleSubmitAppleGooglePay}
                        childrenWhileDisplaying={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Apple Pay Enabled For iOS">
                                    {settings?.applePayEnabled && <Done />}
                                    {!settings?.applePayEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Enabled For Web">
                                    {settings?.applePayWebEnabled && <Done />}
                                    {!settings?.applePayWebEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Merchant ID">
                                    <Typography variant="body2">{settings?.applePay?.merchantId}</Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Country Code">
                                    <Typography variant="body2">{settings?.applePay?.countryCode}</Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Visa Supported">
                                    {settings?.applePay?.supportedNetworksVisa && <Done />}
                                    {!settings?.applePay?.supportedNetworksVisa && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Mastercard Supported">
                                    {settings?.applePay?.supportedNetworksMastercard && <Done />}
                                    {!settings?.applePay?.supportedNetworksMastercard && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Amex Supported">
                                    {settings?.applePay?.supportedNetworksAmex && <Done />}
                                    {!settings?.applePay?.supportedNetworksAmex && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Apple Identity Certificate">
                                    <Typography variant="body2">
                                        {!!settings?.applePay?.merchantIdentityCertificateAsBase64
                                            ? `${settings?.applePay?.merchantIdentityCertificateAsBase64.substring(
                                                  0,
                                                  50
                                              )}...`
                                            : ''}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Google Pay Enabled For Android"
                                    tooltipText="This setting is only disabled at run time"
                                >
                                    {settings?.googlePayEnabled && <Done />}
                                    {!settings?.googlePayEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Enabled For Web">
                                    {settings?.googlePayWebEnabled && <Done />}
                                    {!settings?.googlePayWebEnabled && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Merchant Title" buildtimeAndroid>
                                    <Typography variant="body2">
                                        {settings?.googlePay?.merchantTitle}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Merchant ID" buildtimeAndroid>
                                    <Typography variant="body2">{settings?.googlePay?.merchantId}</Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Country Code" buildtimeAndroid>
                                    <Typography variant="body2">
                                        {settings?.googlePay?.countryCode}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Visa Supported" buildtimeAndroid>
                                    {settings?.googlePay?.supportedNetworksVisa && <Done />}
                                    {!settings?.googlePay?.supportedNetworksVisa && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Mastercard Supported" buildtimeAndroid>
                                    {settings?.googlePay?.supportedNetworksMastercard && <Done />}
                                    {!settings?.googlePay?.supportedNetworksMastercard && <Close />}
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Amex Supported" buildtimeAndroid>
                                    {settings?.googlePay?.supportedNetworksAmex && <Done />}
                                    {!settings?.googlePay?.supportedNetworksAmex && <Close />}
                                </SettingDisplay>
                            </Box>
                        }
                        childrenWhileEditing={
                            <Box display="flex" flexDirection="column">
                                <SettingDisplay label="Apple Pay Enabled For iOS">
                                    <SwitchFormField name="applePayEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Enabled For Web">
                                    <SwitchFormField name="applePayWebEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Merchant ID">
                                    <TextFormField name="applePayMerchantId" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Country Code">
                                    {/* This is not the same as Pepper's Region Code, so should be text field */}
                                    <TextFormField name="applePayCountryCode" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Visa Supported">
                                    <SwitchFormField name="applePaySupportedNetworksVisa" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Mastercard Supported">
                                    <SwitchFormField name="applePaySupportedNetworksMastercard" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Pay Amex Supported">
                                    <SwitchFormField name="applePaySupportedNetworksAmex" />
                                </SettingDisplay>
                                <SettingDisplay label="Apple Identity Certificate">
                                    <KeyFileInputFormField
                                        name="merchantIdentityCertificateAsBase64"
                                        attributes={{ type: 'file', accept: '.pem' }}
                                    />
                                </SettingDisplay>
                                <SettingDisplay
                                    label="Google Pay Enabled For Android"
                                    tooltipText="This setting is only disabled at run time"
                                >
                                    <SwitchFormField name="googlePayEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Enabled For Web">
                                    <SwitchFormField name="googlePayWebEnabled" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Merchant Title" buildtimeAndroid>
                                    <TextFormField name="googlePayMerchantTitle" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Merchant ID" buildtimeAndroid>
                                    <TextFormField name="googlePayMerchantId" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Country Code" buildtimeAndroid>
                                    <TextFormField name="googlePayCountryCode" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Visa Supported" buildtimeAndroid>
                                    <SwitchFormField name="googlePaySupportedNetworksVisa" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Mastercard Supported" buildtimeAndroid>
                                    <SwitchFormField name="googlePaySupportedNetworksMastercard" />
                                </SettingDisplay>
                                <SettingDisplay label="Google Pay Amex Supported" buildtimeAndroid>
                                    <SwitchFormField name="googlePaySupportedNetworksAmex" />
                                </SettingDisplay>
                            </Box>
                        }
                        className={classes.settingsCard}
                        boxClassName={classes.displayClassName}
                    />
                    {!isScenarioTipping && (
                        <StyledMuiCardForm
                            title="Tipping"
                            initialValues={{
                                tippingEnabled: settings?.tippingEnabled ?? false,
                                tippingScheme: settings?.tipping?.scheme,
                                tippingDefault: getTippingDefault(
                                    settings?.tipping?.default,
                                    settings?.tipping?.values
                                ),
                                tippingOptions: settings?.tipping?.values
                            }}
                            onSubmit={handleSubmitTipping}
                            childrenWhileDisplaying={
                                <Box display="flex" flexDirection="column">
                                    <SettingDisplay label="Tipping Enabled">
                                        {settings?.tippingEnabled && <Done />}
                                        {!settings?.tippingEnabled && <Close />}
                                    </SettingDisplay>
                                    <SettingDisplay label="Tipping Scheme">
                                        <Typography variant="body2">{settings?.tipping?.scheme}</Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 1">
                                        <Typography variant="body2">
                                            {settings?.tipping?.values && settings?.tipping?.values[0]}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 2">
                                        <Typography variant="body2">
                                            {settings?.tipping?.values && settings?.tipping?.values[1]}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 3">
                                        <Typography variant="body2">
                                            {settings?.tipping?.values && settings?.tipping?.values[2]}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 4">
                                        <Typography variant="body2">
                                            {settings?.tipping?.values && settings?.tipping?.values[3]}
                                        </Typography>
                                    </SettingDisplay>
                                    <SettingDisplay label="Default Tip">
                                        <Typography variant="body2">
                                            {defaultTippingIndexLabel(
                                                settings?.tipping?.default,
                                                defaultTipOptions
                                            )}
                                        </Typography>
                                    </SettingDisplay>
                                </Box>
                            }
                            childrenWhileEditing={
                                <Box display="flex" flexDirection="column">
                                    <SettingDisplay label="Tipping Enabled">
                                        <SwitchFormField name="tippingEnabled" />
                                    </SettingDisplay>
                                    <SettingDisplay label="Tipping Scheme">
                                        <SelectFormField
                                            name="tippingScheme"
                                            options={[
                                                { label: 'Percentage', value: 'PERCENTAGE' },
                                                { label: 'Amount', value: 'ABSOLUTE' }
                                            ]}
                                        />
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 1">
                                        <NumberFormField name="tippingOptions[0]" />
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 2">
                                        <NumberFormField name="tippingOptions[1]" />
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 3">
                                        <NumberFormField name="tippingOptions[2]" />
                                    </SettingDisplay>
                                    <SettingDisplay label="Tip Value 4">
                                        <NumberFormField name="tippingOptions[3]" />
                                    </SettingDisplay>
                                    <SettingDisplay label="Default Tip">
                                        <SelectFormField name="tippingDefault" options={defaultTipOptions} />
                                    </SettingDisplay>
                                </Box>
                            }
                            className={classes.settingsCard}
                            boxClassName={classes.displayClassName}
                        />
                    )}
                </>
            )}
            <StyledMuiCardForm
                title="Pre-Order Tipping"
                initialValues={{
                    preorderTippingEnabled: settings?.tippingPreorderEnabled ?? false,
                    preorderTippingScheme: settings?.tippingPreorder?.scheme,
                    preorderTippingDefault: getTippingDefault(
                        settings?.tippingPreorder?.default,
                        settings?.tippingPreorder?.values
                    ),
                    preorderTippingOptions: settings?.tippingPreorder?.values
                }}
                onSubmit={handleSubmitTippingPreorder}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Preorder Tipping Enabled">
                            {settings?.tippingPreorderEnabled && <Done />}
                            {!settings?.tippingPreorderEnabled && <Close />}
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Preorder Tipping Scheme">
                                    <Typography variant="body2">
                                        {settings?.tippingPreorder?.scheme}
                                    </Typography>
                                </SettingDisplay>

                                <SettingDisplay label="Tip Value 1">
                                    <Typography variant="body2">
                                        {settings?.tippingPreorder?.values &&
                                            settings?.tippingPreorder?.values[0]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <Typography variant="body2">
                                        {settings?.tippingPreorder?.values &&
                                            settings?.tippingPreorder?.values[1]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <Typography variant="body2">
                                        {settings?.tippingPreorder?.values &&
                                            settings?.tippingPreorder?.values[2]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <Typography variant="body2">
                                        {settings?.tippingPreorder?.values &&
                                            settings?.tippingPreorder.values[3]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <Typography variant="body2">
                                        {defaultTippingIndexLabel(
                                            settings?.tippingPreorder?.default,
                                            defaultTipOptions
                                        )}
                                    </Typography>
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Pre-Order Tipping Enabled">
                            <SwitchFormField name="preorderTippingEnabled" />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Pre-Order Tipping Scheme">
                                    <SelectFormField
                                        name="preorderTippingScheme"
                                        options={[
                                            { label: 'Percentage', value: 'PERCENTAGE' },
                                            { label: 'Amount', value: 'ABSOLUTE' }
                                        ]}
                                    />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <NumberFormField name="preorderTippingOptions[0]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <NumberFormField name="preorderTippingOptions[1]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <NumberFormField name="preorderTippingOptions[2]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <NumberFormField name="preorderTippingOptions[3]" />
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <SelectFormField
                                        name="preorderTippingDefault"
                                        options={defaultTipOptions}
                                    />
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Order to Table Tipping"
                initialValues={{
                    orderToTableTippingEnabled: settings?.tippingOrderToTableEnabled ?? false,
                    orderToTableTippingScheme: settings?.tippingOrderToTable?.scheme,
                    orderToTableTippingDefault: getTippingDefault(
                        settings?.tippingOrderToTable?.default,
                        settings?.tippingOrderToTable?.values
                    ),
                    orderToTableTippingOptions: settings?.tippingOrderToTable?.values
                }}
                onSubmit={handleSubmitTippingOrderToTable}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Order to Table Tipping Enabled">
                            {settings?.tippingOrderToTableEnabled && <Done />}
                            {!settings?.tippingOrderToTableEnabled && <Close />}
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Order to Table Tipping Scheme">
                                    <Typography variant="body2">
                                        {settings?.tippingOrderToTable?.scheme}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <Typography variant="body2">
                                        {settings?.tippingOrderToTable?.values &&
                                            settings?.tippingOrderToTable?.values[0]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <Typography variant="body2">
                                        {settings?.tippingOrderToTable?.values &&
                                            settings?.tippingOrderToTable?.values[1]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <Typography variant="body2">
                                        {settings?.tippingOrderToTable?.values &&
                                            settings?.tippingOrderToTable?.values[2]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <Typography variant="body2">
                                        {settings?.tippingOrderToTable?.values &&
                                            settings?.tippingOrderToTable?.values[3]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <Typography variant="body2">
                                        {defaultTippingIndexLabel(
                                            settings?.tippingOrderToTable?.default,
                                            defaultTipOptions
                                        )}
                                    </Typography>
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Order to Table Tipping Enabled">
                            <SwitchFormField name="orderToTableTippingEnabled" />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Order to Table Tipping Scheme">
                                    <SelectFormField
                                        name="orderToTableTippingScheme"
                                        options={[
                                            { label: 'Percentage', value: 'PERCENTAGE' },
                                            { label: 'Amount', value: 'ABSOLUTE' }
                                        ]}
                                    />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <NumberFormField name="orderToTableTippingOptions[0]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <NumberFormField name="orderToTableTippingOptions[1]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <NumberFormField name="orderToTableTippingOptions[2]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <NumberFormField name="orderToTableTippingOptions[3]" />
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <SelectFormField
                                        name="orderToTableTippingDefault"
                                        options={defaultTipOptions}
                                    />
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Bartab Tipping"
                initialValues={{
                    tabTippingEnabled: settings?.tippingTabEnabled ?? false,
                    tabTippingScheme: settings?.tippingTab?.scheme,
                    tabTippingDefault: getTippingDefault(
                        settings?.tippingTab?.default,
                        settings?.tippingTab?.values
                    ),
                    tabTippingOptions: settings?.tippingTab?.values
                }}
                onSubmit={handleSubmitTippingTab}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Tab Tipping Enabled">
                            {settings?.tippingTabEnabled && <Done />}
                            {!settings?.tippingTabEnabled && <Close />}
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Tab Tipping Scheme">
                                    <Typography variant="body2">{settings?.tippingTab?.scheme}</Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <Typography variant="body2">
                                        {settings?.tippingTab?.values && settings?.tippingTab?.values[0]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <Typography variant="body2">
                                        {settings?.tippingTab?.values && settings?.tippingTab?.values[1]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <Typography variant="body2">
                                        {settings?.tippingTab?.values && settings?.tippingTab?.values[2]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <Typography variant="body2">
                                        {settings?.tippingTab?.values && settings?.tippingTab?.values[3]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <Typography variant="body2">
                                        {defaultTippingIndexLabel(
                                            settings?.tippingTab?.default,
                                            defaultTipOptions
                                        )}
                                    </Typography>
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Tab Tipping Enabled">
                            <SwitchFormField name="tabTippingEnabled" />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Tab Tipping Scheme">
                                    <SelectFormField
                                        name="tabTippingScheme"
                                        options={[
                                            { label: 'Percentage', value: 'PERCENTAGE' },
                                            { label: 'Amount', value: 'ABSOLUTE' }
                                        ]}
                                    />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <NumberFormField name="tabTippingOptions[0]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <NumberFormField name="tabTippingOptions[1]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <NumberFormField name="tabTippingOptions[2]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <NumberFormField name="tabTippingOptions[3]" />
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <SelectFormField name="tabTippingDefault" options={defaultTipOptions} />
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Pay at Table Tipping"
                initialValues={{
                    payAtTableTippingEnabled: settings?.tippingPayAtTableEnabled ?? false,
                    payAtTableTippingScheme: settings?.tippingPayAtTable?.scheme,
                    payAtTableTippingDefault: getTippingDefault(
                        settings?.tippingPayAtTable?.default,
                        settings?.tippingPayAtTable?.values
                    ),
                    payAtTableTippingOptions: settings?.tippingPayAtTable?.values
                }}
                onSubmit={handleSubmitTippingPayAtTable}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Pay at Table Tipping Enabled">
                            {settings?.tippingPayAtTableEnabled && <Done />}
                            {!settings?.tippingPayAtTableEnabled && <Close />}
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Tab Tipping Scheme">
                                    <Typography variant="body2">
                                        {settings?.tippingPayAtTable?.scheme}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <Typography variant="body2">
                                        {settings?.tippingPayAtTable?.values &&
                                            settings?.tippingPayAtTable?.values[0]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <Typography variant="body2">
                                        {settings?.tippingPayAtTable?.values &&
                                            settings?.tippingPayAtTable?.values[1]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <Typography variant="body2">
                                        {settings?.tippingPayAtTable?.values &&
                                            settings?.tippingPayAtTable?.values[2]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <Typography variant="body2">
                                        {settings?.tippingPayAtTable?.values &&
                                            settings?.tippingPayAtTable?.values[3]}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <Typography variant="body2">
                                        {defaultTippingIndexLabel(
                                            settings?.tippingPayAtTable?.default,
                                            defaultTipOptions
                                        )}
                                    </Typography>
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Pay at Table Tipping Enabled">
                            <SwitchFormField name="payAtTableTippingEnabled" />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Pay at Table Tipping Scheme">
                                    <SelectFormField
                                        name="payAtTableTippingScheme"
                                        options={[
                                            { label: 'Percentage', value: 'PERCENTAGE' },
                                            { label: 'Amount', value: 'ABSOLUTE' }
                                        ]}
                                    />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 1">
                                    <NumberFormField name="payAtTableTippingOptions[0]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 2">
                                    <NumberFormField name="payAtTableTippingOptions[1]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 3">
                                    <NumberFormField name="payAtTableTippingOptions[2]" />
                                </SettingDisplay>
                                <SettingDisplay label="Tip Value 4">
                                    <NumberFormField name="payAtTableTippingOptions[3]" />
                                </SettingDisplay>
                                <SettingDisplay label="Default Tip">
                                    <SelectFormField
                                        name="payAtTableTippingDefault"
                                        options={defaultTipOptions}
                                    />
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
            <StyledMuiCardForm
                title="Payments"
                initialValues={{
                    minimumTransactionLimit: settings?.transaction?.minimumAmount,
                    maximumTransactionLimit: settings?.transaction?.maximumAmount,
                    paymentMode: settings?.payments?.mode,
                    defaultTopUpAmount: settings?.payments?.topupAmountDefault,
                    alternateTopUpAmount: settings?.payments?.topupAmountAlternative
                }}
                onSubmit={handleSubmitPayments}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Minimum Transaction Limit">
                            <Typography variant="body2">{settings?.transaction?.minimumAmount}</Typography>
                        </SettingDisplay>
                        <SettingDisplay label="Maximum Transaction Limit">
                            <Typography variant="body2">{settings?.transaction?.maximumAmount}</Typography>
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Payment Mode">
                                    <Typography variant="body2">{paymentMode}</Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Default Top Up">
                                    <Typography variant="body2">
                                        {settings?.payments?.topupAmountDefault}
                                    </Typography>
                                </SettingDisplay>
                                <SettingDisplay label="Alternate Top Up">
                                    <Typography variant="body2">
                                        {settings?.payments?.topupAmountAlternative}
                                    </Typography>
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay label="Minimum Transaction Limit">
                            <NumberFormField name="minimumTransactionLimit" />
                        </SettingDisplay>
                        <SettingDisplay label="Maximum Transaction Limit">
                            <NumberFormField name="maximumTransactionLimit" />
                        </SettingDisplay>
                        {locationId === undefined && (
                            <>
                                <SettingDisplay label="Payment Mode">
                                    <SelectFormField name="paymentMode" options={paymentModeOptions} />
                                </SettingDisplay>
                                <SettingDisplay label="Default Top Up">
                                    <NumberFormField name="defaultTopUpAmount" />
                                </SettingDisplay>
                                <SettingDisplay label="Alternate Top Up">
                                    <NumberFormField name="alternateTopUpAmount" />
                                </SettingDisplay>
                            </>
                        )}
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />
        </Box>
    );
};

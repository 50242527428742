// TODO: replace it with something lighter
import React, { ChangeEvent } from 'react';
import { LinearProgress, styled, TextField, Typography } from '@mui/material';
import { StandardTextFieldProps } from '@mui/material/TextField';
import { Omit } from 'lib/types';
import logger from './logger';

const PREFIX = 'PasswordWithStrengthField';

const classes = {
    colorPrimary: `${PREFIX}-colorPrimary`,
    barColorPrimaryVeryWeak: `${PREFIX}-barColorPrimaryVeryWeak`,
    barColorPrimaryWeak: `${PREFIX}-barColorPrimaryWeak`,
    barColorPrimaryOkay: `${PREFIX}-barColorPrimaryOkay`,
    barColorPrimaryStrong: `${PREFIX}-barColorPrimaryStrong`,
    barColorPrimaryVeryStrong: `${PREFIX}-barColorPrimaryVeryStrong`,
    passwordInput: `${PREFIX}-passwordInput`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.colorPrimary}`]: {
        backgroundColor: '#B2DFDB',
        height: '10px'
    },

    [`& .${classes.barColorPrimaryVeryWeak}`]: {
        backgroundColor: '#d50000'
    },

    [`& .${classes.barColorPrimaryWeak}`]: {
        backgroundColor: '#c62828'
    },

    [`& .${classes.barColorPrimaryOkay}`]: {
        backgroundColor: '#ff9800'
    },

    [`& .${classes.barColorPrimaryStrong}`]: {
        backgroundColor: '#cddc39'
    },

    [`& .${classes.barColorPrimaryVeryStrong}`]: {
        backgroundColor: '#8bc34a'
    },

    [`& .${classes.passwordInput}`]: {
        marginBottom: '5px',
        width: '100%'
    }
}));

export interface PasswordWithStrengthFieldProps extends Omit<StandardTextFieldProps, 'classes' | 'onChange'> {
    onChange: (password: string) => void;
}

interface PasswordWithStrengthFieldState {
    passwordStrength: number;
    loading: boolean;
    zxcvbn?: (value: string) => { score: number };
    loadingError?: string;
}

export const PasswordWithStrengthField = class extends React.Component<
    PasswordWithStrengthFieldProps,
    PasswordWithStrengthFieldState
> {
    constructor(props: PasswordWithStrengthFieldProps) {
        super(props);
        this.state = {
            passwordStrength: 0,
            loading: true,
            zxcvbn: undefined,
            loadingError: undefined
        };
    }
    componentDidMount(): void {
        import('zxcvbn')
            .then(result => {
                this.setState({ zxcvbn: result.default });
            })
            .catch(e => {
                logger.error(e.message, e);
                this.setState({ loadingError: e.message });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }
    onPasswordInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        const { zxcvbn } = this.state;
        const password = event.target.value;
        if (zxcvbn) {
            const result = zxcvbn(password);
            this.setState({ passwordStrength: result.score });
        }

        // Handle Event elsewhere
        onChange(password);
    };
    scoreToStrengthTextAndClass = (score: number): string[] => {
        switch (score) {
            case 4:
                return ['Very Strong', classes.barColorPrimaryVeryStrong];
            case 3:
                return ['Strong', classes.barColorPrimaryStrong];
            case 2:
                return ['Okay', classes.barColorPrimaryOkay];
            case 1:
                return ['Weak', classes.barColorPrimaryWeak];
            default:
                return ['Very Weak', classes.barColorPrimaryVeryWeak];
        }
    };
    render() {
        const { disabled, ...rest } = this.props;
        const { passwordStrength, loading, loadingError } = this.state;
        const [strengthText, barColorPrimary] = this.scoreToStrengthTextAndClass(passwordStrength);
        return (
            <Root>
                <TextField
                    disabled={disabled || loading || !!loadingError}
                    {...rest}
                    type="password"
                    onChange={this.onPasswordInputChanged}
                    className={classes.passwordInput}
                />
                <LinearProgress
                    variant="determinate"
                    value={20 * (passwordStrength + 1)}
                    classes={{ barColorPrimary, colorPrimary: classes.colorPrimary }}
                />
                <Typography component="div" variant="caption" align="left">
                    {strengthText} Password
                </Typography>
            </Root>
        );
    }
};

/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Typography, styled, Theme } from '@mui/material';
import get from 'get-value';
import { isValidCoordinate } from 'components/utils/isValidCoordinate';
import { Map } from 'lib/Map';
import { Column, Row } from 'ui/Flex';
import { ILocationsModule } from '../models/ContentModule';
import { ContentAnchorProperties } from '../models/ContentTemplate';
import { ContentModuleRenderer, getBorderRadius, MobileLayoutTheme } from '../renderContentModule';
import { MUIStyledCommonProps } from '@mui/system';

const PREFIX = 'renderLocationModule';

const classes = {
    title: `${PREFIX}-title`,
    secondaryAction: `${PREFIX}-secondaryAction`,
    content: `${PREFIX}-content`,
    errorMessage: `${PREFIX}-errorMessage`
};

const StyledColumn = styled(Column)(
    ({
        theme,
        properties,
        layoutTheme,
        location
    }: StyledLocationModuleProps & MUIStyledCommonProps<Theme>) => ({
        '&:hover': {
            backgroundColor: properties.isSelectable
                ? properties.isSelected
                    ? 'rgba(92, 92, 92, 0.16)'
                    : 'rgba(92, 92, 92, 0.12)'
                : 'initial',
            transform: `${properties.isSelectable && !properties.isSelected ? 'scale(1.025)' : 'none'}`
        },
        width: '100%',
        padding: theme.spacing(0.5),
        paddingTop: '0px',
        margin: '0px',
        marginTop: theme.spacing(0.5),
        backgroundColor: properties.isSelected ? 'rgba(92, 92, 92, 0.16)' : 'initial',
        cursor: properties.isSelectable ? 'pointer' : 'inherit',
        transition: 'background-color 0.1s ease-in, transform 0.1s ease-in',
        border: `2px solid ${
            properties.isSelectable && properties.isSelected ? 'rgb(62, 141, 208)' : 'rgba(0,0,0,0)'
        }`,
        [`& .${classes.title}`]: {
            transition: 'color .15s linear',
            color: properties.titleTextColour ? properties.titleTextColour : properties.anchorTextColour,
            lineHeight: '1rem',
            fontSize: layoutTheme.fontSize
        },
        [`& .${classes.secondaryAction}`]: {
            transition: 'color .15s linear',
            lineHeight: '0.9rem',
            textAlign: 'right',
            marginRight: layoutTheme.unit / 2,
            color: properties.tintColour,
            fontSize: layoutTheme.fontSize - 2
        },
        [`& .${classes.content}`]: {
            height: location?.display?.height === 'DOUBLE' ? layoutTheme.height * 2 : layoutTheme.height,
            width: '100%',
            borderRadius: getBorderRadius(properties),
            transition: 'border-radius .15s linear',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'rgba(211, 211, 211, 1)'
        },
        [`& .${classes.errorMessage}`]: {
            textAlign: 'center',
            verticalAlign: 'middle',
            lineHeight: '26px',
            paddingTop: '10%'
        }
    })
);

interface LocationModuleProps {
    location: ILocationsModule;
    theme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

interface StyledLocationModuleProps {
    location: ILocationsModule;
    layoutTheme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

export const renderLocationModule: ContentModuleRenderer = (
    location: ILocationsModule,
    theme,
    properties,
    _
) => <LocationModuleComponent location={location} theme={theme} properties={properties} />;

function LocationModuleComponent({ location, theme, properties }: LocationModuleProps) {
    const lat = get(location, 'properties.defaultRegion.latitude');
    const lng = get(location, 'properties.defaultRegion.longitude');
    const width = get(location, 'properties.defaultRegion.width');
    const height = location.display?.height === 'DOUBLE' ? theme.height * 2 : theme.height;

    const handleClick = React.useCallback(() => {
        if (properties.handleClick && !properties.isSelected) {
            properties.handleClick();
        }
    }, [properties]);

    return (
        <StyledColumn onClick={handleClick} location={location} properties={properties} layoutTheme={theme}>
            <Row flex={1} align="space-between" valign="flex-end">
                <Typography className={classes.title}>{location.title}</Typography>
                {location.secondaryAction && (
                    <Typography className={classes.secondaryAction}>
                        {location.secondaryAction?.title}
                    </Typography>
                )}
            </Row>
            {isValidCoordinate([lng, lat]) ? (
                <Map center={{ lat, lng }} width={width} height={height} />
            ) : (
                <Box className={classes.content}>
                    <Typography className={classes.errorMessage}>
                        The coordinates are not pointing to the correct location.
                    </Typography>
                </Box>
            )}
        </StyledColumn>
    );
}

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const JudopaySettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Production Environment">
                    <SwitchFormField name="judopayIsProduction" />
                </SettingDisplay>
                <SettingDisplay label="Judo ID">
                    <TextFormField name="judopayJudoId" />
                </SettingDisplay>
                <SettingDisplay label="Token">
                    <TextFormField name="judopayToken" />
                </SettingDisplay>
                <SettingDisplay label="Secret">
                    <TextFormField name="judopaySecret" />
                </SettingDisplay>
                <SettingDisplay label="Client Token">
                    <TextFormField name="judopayClientToken" />
                </SettingDisplay>
                <SettingDisplay label="Client Secret">
                    <TextFormField name="judopayClientSecret" />
                </SettingDisplay>
                <SettingDisplay label="Card Check Enabled">
                    <SwitchFormField name="judopayUseCardCheckTokenisation" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Judo ID">
                <TextFormField name="judopayJudoId" />
            </SettingDisplay>
        )}
    </>
);

import { GridCellParams } from '@mui/x-data-grid-pro';
import { RemoveCell } from 'lib/MuiGrid/RemoveCell';
import React from 'react';

export function useMenuDelete(isCellRemovable?: (id: string | number) => boolean) {
    const [deleted, setDeleted] = React.useState(new Set<string | number>());
    const remove = React.useCallback((id: string | number) => {
        setDeleted(set => new Set(set.add(id)));
    }, []);
    const unremove = React.useCallback((id: string | number) => {
        setDeleted(set => {
            set.delete(id);
            return new Set(set);
        });
    }, []);
    const resetDeleted = React.useCallback(() => {
        setDeleted(new Set());
    }, []);
    const renderDeleteCell = React.useCallback(
        (params: GridCellParams) => (
            <RemoveCell
                params={params}
                onDelete={remove}
                isRemovable={isCellRemovable}
                onUndelete={unremove}
                deleted={deleted.has(params.id)}
            />
        ),
        [deleted, isCellRemovable, remove, unremove]
    );
    const isCellEditable = React.useCallback(
        (params: GridCellParams) => {
            if (deleted?.has(params.id)) {
                return false;
            }
            return params.colDef?.editable || params.isEditable;
        },
        [deleted]
    );
    return { deleted, renderDeleteCell, resetDeleted, isCellEditable, onRowDelete: remove };
}

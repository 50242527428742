import React from 'react';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { getDistanceBettwenTodayAndMillisecondsInWords } from 'components/actions/utils/formatDate';
import { CardViewChipRow, CardViewTextRow } from 'ui/viewComponents';
import { Audience, AudienceType } from './models/segmentModel';
import { Row } from 'ui/Flex';

interface SegmentDetailCardComponentProps {
    segment: Audience;
    onGrantPerkButtonClick: () => void;
    onDelete: () => void;
    disabled?: boolean;
}

export const SegmentDetailCardComponent = ({
    segment,
    onGrantPerkButtonClick,
    onDelete,
    disabled
}: SegmentDetailCardComponentProps) => {
    const { title, description, type, expiryHorizon, count } = segment;

    return (
        <Paper>
            <Box position="relative" py={1} px={2}>
                <Row flex={1} align="space-between" valign="center">
                    <Typography variant="h6">{title}</Typography>
                    <Row gutter>
                        <Button onClick={onDelete} color="error" variant="outlined" disabled={disabled}>
                            Permanently Delete
                        </Button>
                        <Button
                            onClick={onGrantPerkButtonClick}
                            color="primary"
                            variant="contained"
                            disabled={disabled}
                        >
                            Grant Perk
                        </Button>
                    </Row>
                </Row>
            </Box>
            <Divider />
            <Box padding={2}>
                <Grid container spacing={2} direction="row">
                    <Divider />
                    <Grid item xs={12} md={3}>
                        <Grid container spacing={2} direction="column">
                            <CardViewChipRow title="Type" value={type} />
                            {segment.type !== AudienceType.PERSISTENT && (
                                <CardViewTextRow
                                    title="Expires after"
                                    value={getDistanceBettwenTodayAndMillisecondsInWords(expiryHorizon)}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <CardViewTextRow title="Description" value={description} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CardViewTextRow title="Customers in this Audience" value={count.toString()} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

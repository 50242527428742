import React from 'react';
import { TableCell } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { MuiCircularProgress } from 'lib/MuiCircularProgress';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { actionExportsApi } from 'components/analytics/export/actionExportsApi';
import { MESSAGE_ACTION_EXPORT_FILE_DOWNLOAD_FAIL } from 'config/messages';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import logger from 'lib/logger';

interface IExportedDataActionCellProps {
    operation: any;
}

export const ExportedDataActionCell: React.FC<IExportedDataActionCellProps> = ({ operation }) => {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const handleButtonClick = React.useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setLoading(true);

            try {
                const response = await actionExportsApi.getActionExportFile(operation.result?.href);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const blob = await response.blob();

                const downloadUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'actions.zip';

                document.body.appendChild(link);

                link.click();
                link.remove();

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
                    window.URL.revokeObjectURL(downloadUrl);
                }, 100);
            } catch (e) {
                logger.error(e);
                dispatch(enqueueSnackbar(MESSAGE_ACTION_EXPORT_FILE_DOWNLOAD_FAIL, { variant: 'error' }));
            } finally {
                setLoading(false);
            }
        },
        [dispatch, operation.result?.href]
    );
    const progress = React.useMemo(() => {
        if (operation.result && operation.result.total) {
            return (
                (operation.result.errors +
                    operation.result.exported +
                    operation.result.skipped +
                    (operation.result.removedUnlinked || 0)) /
                operation.result.total
            );
        }
        if (operation?.result?.total === 0) {
            return 1;
        }
        return undefined;
    }, [operation.result]);

    return (
        <TableCell align="center" size="small">
            <MuiCircularProgress
                progress={isNaN(progress) ? -1 : progress * 100}
                isError={operation.status === OperationStatus.ERROR}
            >
                {operation.status === OperationStatus.DONE && operation.result && operation.result.href && (
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        onClick={handleButtonClick}
                    >
                        Download
                    </LoadingButton>
                )}
            </MuiCircularProgress>
        </TableCell>
    );
};

import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';

export interface ConfirmDialogProps extends ConfirmDialogContentProps {
    open: boolean;
    noBackdropClick?: boolean;
}

export function ConfirmDialog({ open, noBackdropClick, ...rest }: ConfirmDialogProps) {
    return (
        <MuiModal open={open} onClose={noBackdropClick ? undefined : rest.onCancel}>
            <ConfirmDialogContent {...rest} />
        </MuiModal>
    );
}

interface ConfirmDialogContentProps {
    title: React.ReactNode;
    content: string;
    confirmLabel?: string;
    cancelLabel?: string;
    onCancel: () => void;
    loading?: boolean;
    onConfirm: () => void;
}

function ConfirmDialogContent({
    title,
    content,
    onCancel,
    loading,
    onConfirm,
    confirmLabel,
    cancelLabel
}: ConfirmDialogContentProps) {
    return (
        <React.Fragment>
            <Typography variant="h5" color="primary">
                {title}
            </Typography>
            <Divider sx={{ my: 2, mx: 0 }} />
            <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
                {content}
            </Typography>
            <Divider sx={{ my: 2, mx: 0 }} />
            <Box justifyContent="flex-end" sx={{ width: '100%', display: 'flex' }}>
                <Button sx={{ mr: 2.25 }} variant="outlined" color="primary" onClick={onCancel}>
                    {cancelLabel || 'Cancel'}
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                >
                    {confirmLabel || 'Confirm'}
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}

import React from 'react';
import { connect } from 'react-redux';
import { match, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Claims } from '@pepperhq/auth-client';
import { History, Location } from 'history';
import { ActionCreator } from 'redux';
import { authApi } from 'components/auth/AuthApi';
import { SignUpForm } from 'components/auth/forms/SignUpForm';
import { getAuthToken } from 'components/auth/getAuthToken';
import { MarketingLayout } from 'components/auth/MarketingLayout';
import { SignUpData } from 'components/auth/models/SignUpData';
import { FORGOT_YOUR_PASSWORD, SIGN_IN, USER_MERCHANTS } from 'config/routes';
import { signIn, SignInAction } from 'store/auth/authActions';
import { enqueueSnackbar as _enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { Link } from 'ui/Link';
import { MuiNotificationOptions } from 'ui/MuiNotifier';
import logger from 'lib/logger';

interface SignUpPageProps {
    signIn: (email: string, password: string) => void;
    claims: Claims;
    history: History;
    location: Location;
    match: match;
    error: string;
    enqueueSnackbar: (message: string, options: MuiNotificationOptions) => void;
}

interface SignUpPageState {
    error: string;
}

class SignUpPage extends React.Component<SignUpPageProps, SignUpPageState> {
    constructor(props: SignUpPageProps) {
        super(props);
        this.state = {
            error: null
        };
    }
    async componentDidMount() {
        const authToken = await getAuthToken();
        // Do not render this page and redirect to home one if user is authenticated
        if (authToken) {
            this.props.history.push(USER_MERCHANTS);
        }
    }
    handleSubmit = async (data: SignUpData) => {
        const { email, password } = data;
        // Sign-up
        try {
            this.setState({ error: null });
            const result = await authApi.signUp(data);
            if (!result.ok) {
                this.setState({ error: result.body && result.body.message });
                throw new Error(result.body.message);
            }
            // Sign-in
            this.props.signIn(email, password);
            const params = new URLSearchParams(this.props.location.search);
            const redirectTo = params.get('redirectTo');
            this.props.history.push(redirectTo || USER_MERCHANTS);
        } catch (error) {
            logger.error(error);
        }
    };

    handleMerchantCreateInfo = (message: string) => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(message, { variant: 'info' });
    };
    handleMerchantCreateError = (message: string) => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 });
    };

    render() {
        const { error } = this.state;
        const { error: signInError } = this.props;
        return (
            <MarketingLayout
                after={
                    <React.Fragment>
                        <Typography align="center" variant="caption" color="textSecondary">
                            <Link href={FORGOT_YOUR_PASSWORD}>Forgot your password? </Link>
                        </Typography>
                        <Typography align="center" variant="caption" color="textSecondary">
                            Already have an Account? <Link href={SIGN_IN}>Sign In</Link>
                        </Typography>
                    </React.Fragment>
                }
            >
                <SignUpForm onSubmit={this.handleSubmit} error={error || signInError} />
            </MarketingLayout>
        );
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    error: state.auth.error,
    claims: state.auth.claims
});

const mapDispatchToProps = (dispatch: ActionCreator<SignInAction>) => ({
    signIn: (email: string, password: string) => dispatch(signIn(email, password)),
    enqueueSnackbar: (message: string, options: MuiNotificationOptions) =>
        dispatch(_enqueueSnackbar(message, options))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpPage));

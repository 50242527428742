import React from 'react';
import { Grid } from '@mui/material';
import { getPrimaryPlatform } from 'components/customers/helpers';
import { CardViewSwitchRow, CardViewTextRow } from 'ui/viewComponents';
import { isUserCreatedAction } from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';

export const ActionCardUserCreated: React.FC<ActionCardModalComponentProps> = ({ action }) => {
    if (!isUserCreatedAction(action)) {
        return <ActionCardBase action={action} broken />;
    }
    return (
        <ActionCardBase action={action} size="double">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow title="Date" value={getActionDate(action)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow
                        title="Platform"
                        value={getPrimaryPlatform(action.context.user.primaryPlatform)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow title="Name" value={action.context.user.fullName} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewSwitchRow
                        title="Has Agreed to Share Data?"
                        value={action.metadata.hasAgreedToShareData}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow title="Credential" value={action.metadata.username} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewSwitchRow
                        title="Has Agreed to Marketing?"
                        value={action.metadata.hasAgreedToReceiveMarketing}
                    />
                </Grid>
            </Grid>
        </ActionCardBase>
    );
};

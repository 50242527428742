import React from 'react';
import { useDispatch } from 'react-redux';
import { PerksActionType } from 'store/perks/perksActionTypes';

export const usePepperPerksModals = () => {
    const dispatch = useDispatch();

    const toggleCreateModal = React.useCallback(
        () => dispatch({ type: PerksActionType.TOGGLE_CREATE_MODAL }),
        [dispatch]
    );

    const toggleCloseCreateModal = React.useCallback(() => {
        dispatch({ type: PerksActionType.TOGGLE_CLOSE_CREATE_MODAL });
    }, [dispatch]);

    const toggleUpdateCreateModal = React.useCallback(
        () => dispatch({ type: PerksActionType.TOGGLE_CLOSE_UPDATE_MODAL }),
        [dispatch]
    );

    const closeModals = React.useCallback(() => {
        dispatch({ type: PerksActionType.CLOSE_MODALS });
    }, [dispatch]);

    const closeResetPopUp = React.useCallback(() => {
        dispatch({ index: -1, type: PerksActionType.SET_ITEM_TO_RESET });
        dispatch({ count: -1, type: PerksActionType.SET_PERK_AWARD_COUNT });
    }, [dispatch]);

    const closeDeletePopUp = React.useCallback(() => {
        dispatch({ index: -1, type: PerksActionType.SET_ITEM_TO_DELETE });
    }, [dispatch]);

    return {
        toggleCreateModal,
        toggleCloseCreateModal,
        toggleUpdateCreateModal,
        closeModals,
        closeResetPopUp,
        closeDeletePopUp
    };
};

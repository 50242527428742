import React from 'react';
import { Formik } from 'formik';
import { Box, Button, Typography } from '@mui/material';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { ITagGroupDetails } from 'components/location-tags/modals/LocationTagDetailsModal';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import * as Yup from 'yup';

interface IProps {
    onSubmit: (values: ITagGroupDetails) => void;
    open: boolean;
    onCancel: () => void;
    initialTagGroupDetails: ITagGroupDetails;
}

enum EFormField {
    TITLE = 'title',
    MATCH_ALL = 'matchAll',
    SORT = 'sort'
}

const INITIAL_VALUES: ITagGroupDetails = {
    title: '',
    matchAll: false,
    sort: '1'
};

const REQUIRED_ERROR_MESSAGE = 'This field is required.';

const validationSchema = Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    sort: Yup.number().required(REQUIRED_ERROR_MESSAGE)
});

export function CreateTagGroupForm({ onSubmit, open, onCancel, initialTagGroupDetails }: IProps) {
    const initialValues = React.useMemo(
        () => initialTagGroupDetails ?? INITIAL_VALUES,
        [initialTagGroupDetails]
    );

    if (!open) {
        return null;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ handleSubmit: onSubmit, isValid, values }) => (
                <form onSubmit={onSubmit}>
                    <Typography sx={{ mb: 1 }} variant="h6" color="primary">
                        {initialTagGroupDetails
                            ? `Update ${initialTagGroupDetails.title ?? ''}`
                            : 'Create Tag Group'}
                    </Typography>

                    <TextFormField
                        name={EFormField.TITLE}
                        label={<MuiFormLabel required>Title</MuiFormLabel>}
                    />

                    <Box my={2}>
                        <SwitchFormField
                            name={EFormField.MATCH_ALL}
                            label={<MuiFormLabel>Match All Tags</MuiFormLabel>}
                        />
                    </Box>

                    <NumberFormField
                        InputLabelProps={{ shrink: true }}
                        name={EFormField.SORT}
                        label={<MuiFormLabel required>Sort</MuiFormLabel>}
                    />

                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button color="primary" variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!isValid || !values.title}
                            type="submit"
                            color="primary"
                            variant="contained"
                            sx={{ ml: 1 }}
                        >
                            Next
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
}

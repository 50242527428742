import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, styled, Typography } from '@mui/material';
import Logo from 'static/pepper-logo.png';

interface ErrorLayoutProps {
    description?: string;
    title: string;
    fallbackUrl: string;
    fallbackTitle: string;
    onClick?: () => void;
}
const PREFIX = 'ErrorLayout';

const classes = {
    logo: `${PREFIX}-logo`,
    fallback: `${PREFIX}-fallback`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    height: '100%',
    width: '100%',
    [`& .${classes.logo}`]: {
        marginBottom: theme.spacing(3),
        width: theme.spacing(18)
    },
    [`& .${classes.fallback}`]: {
        textDecoration: 'none',
        display: 'inline-block',
        cursor: 'pointer',
        color: theme.palette.primary.main
    }
}));

export const ErrorLayout = (props: ErrorLayoutProps) => {
    const { description, fallbackTitle, fallbackUrl, title, onClick } = props;
    return (
        <StyledGrid spacing={2} direction="column" container justifyContent="center" alignItems="center">
            <img className={classes.logo} src={Logo} alt="" />
            <Grid item>
                <Typography variant="h4" color="textPrimary">
                    {title}
                </Typography>
            </Grid>
            {description && description.length && (
                <Grid item>
                    <Typography variant="body1">{description}</Typography>
                </Grid>
            )}
            <Grid item>
                <Typography variant="body1">
                    Return to{' '}
                    <Link to={fallbackUrl} onClick={onClick}>
                        <span className={classes.fallback}>{fallbackTitle}</span>
                    </Link>
                </Typography>
            </Grid>
        </StyledGrid>
    );
};

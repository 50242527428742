import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { menuApi } from 'components/menu/MenuApi';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { MESSAGE_BULK_UPDATE_ERROR, MESSAGE_BULK_UPDATE_SUCCESS } from 'config/messages';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ActiveOperationContext } from './useCopyTab';

const PREFIX = 'OperationStatusIndicator';

const classes = {
    item: `${PREFIX}-item`
};

const StyledBox = styled(Box)(({ theme }) => ({
    minWidth: '150px',
    display: 'flex',
    justifyContent: 'flex-end',
    [`& .${classes.item}`]: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        color: `${theme.palette.primary.main}`
    }
}));

export const OperationStatusIndicator: React.FC = () => {
    const dispatch = useDispatch();
    const { activeOperationId, setOperationId } = React.useContext(ActiveOperationContext);
    const [operationStatus, setOperationStatus] = React.useState();
    const [operationPercentDone, setOperationPercentDone] = React.useState(0);
    const [shouldRender, setshouldRender] = React.useState(false);

    const pollOperation = React.useCallback(
        (id: string) => {
            setTimeout(() => {
                menuApi.getOperationStatus(id).then(response => {
                    const currentStatus = response.status;
                    setOperationPercentDone(response?.result?.percentDone ?? 0);
                    setOperationStatus(currentStatus);
                    setshouldRender(true);
                    if (currentStatus === OperationStatus.ERROR) {
                        setshouldRender(false);
                        dispatch(enqueueSnackbar(MESSAGE_BULK_UPDATE_ERROR, { variant: 'error' }));
                    }
                    if (
                        currentStatus === OperationStatus.PENDING ||
                        currentStatus === OperationStatus.RUNNING
                    ) {
                        pollOperation(id);
                        return;
                    }
                    if (currentStatus === OperationStatus.DONE) {
                        setOperationId(null);
                        setTimeout(() => {
                            setshouldRender(false);
                            setOperationStatus(null);
                            setOperationPercentDone(0);
                        }, 5 * 1000);
                        dispatch(enqueueSnackbar(MESSAGE_BULK_UPDATE_SUCCESS, { variant: 'success' }));
                    }
                });
            }, 100);
        },
        [dispatch, setOperationId]
    );

    useEffect(() => {
        if (activeOperationId) {
            pollOperation(activeOperationId);
        }
    }, [activeOperationId, pollOperation]);

    return (
        shouldRender && (
            <StyledBox>
                <Typography className={classes.item}>
                    COPY OPERATION {operationStatus || 'PENDING'}
                </Typography>
                <CircularProgress
                    className={classes.item}
                    variant={operationStatus === 'PENDING' ? 'indeterminate' : 'determinate'}
                    value={operationPercentDone}
                />
            </StyledBox>
        )
    );
};

import React from 'react';

export enum MuiImagePickerStep {
    CLOSED = 'CLOSED',
    FILE_CHOOSING = 'FILE_CHOOSING',
    SELECTED = 'SELECTED',
    CROPPING = 'CROPPING'
}

export function useImagePickerState() {
    const [step, setStep] = React.useState(MuiImagePickerStep.CLOSED);
    const onFileSelectStateChange = React.useCallback(() => {
        setStep(MuiImagePickerStep.SELECTED);
    }, []);
    const onCloseStateChange = React.useCallback(() => {
        setStep(MuiImagePickerStep.CLOSED);
    }, []);
    const onCropStartStateChange = React.useCallback(() => {
        setStep(MuiImagePickerStep.CROPPING);
    }, []);
    const onCropCancelStateChange = React.useCallback(() => {
        setStep(MuiImagePickerStep.SELECTED);
    }, []);
    return {
        step,
        onFileSelectStateChange,
        onCloseStateChange,
        onCropStartStateChange,
        onCropCancelStateChange
    };
}

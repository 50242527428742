import { MenuItem, Select } from '@mui/material';
import { locationApi } from 'components/location/LocationApi';
import { ILocationZones } from 'components/location/models/LocationModel';
import { Option } from 'lib/types';
import React from 'react';

interface MenuZonesFilter {
    locationId: string;
    onChange: (value?: string) => void;
    value?: string;
}
const ALL_ZONES = 'ALL_ZONES';

export const MenuZonesFilter: React.FC<MenuZonesFilter> = ({ value, onChange, locationId }) => {
    const [zones, setZones] = React.useState<ILocationZones>();
    const options = React.useMemo(() => {
        if (zones) {
            return Object.keys(zones).map(zone => ({
                label: zone,
                value: zone
            }));
        }

        return [];
    }, [zones]);
    React.useEffect(() => {
        if (!zones) {
            locationApi.get(locationId).then(locationRes => {
                if (locationRes.ok && locationRes.body) {
                    setZones(locationRes.body.zones);
                }
            });
        }
    }, [locationId, zones]);

    const handleScenarioChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ALL_ZONES) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );

    return (
        <Select value={value ?? ALL_ZONES} onChange={handleScenarioChange} variant="outlined">
            <MenuItem value={ALL_ZONES}>All zones</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};

import { TenantAccessState } from 'components/merchant/models/TenantAccess';
import React from 'react';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { TENANT_ACCESS_ITEMS_PER_PAGE, getTenantAccessData } from './useTenantAccess';

interface TenantCreateProps {
    updateTenantAccess: React.Dispatch<React.SetStateAction<any>>;
    updateNextKey: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const useTenantCreate = ({ updateTenantAccess, updateNextKey }: TenantCreateProps) => {
    const [merchantCreateOpen, setMerchantCreateOpen] = React.useState(false);
    const [isCreateLoading, setIsCreateLoading] = React.useState(false);

    const handleMerchantCreateClick = React.useCallback(() => {
        setMerchantCreateOpen(!merchantCreateOpen);
    }, [merchantCreateOpen]);

    const handleMerchantCreateInfo = React.useCallback((message: string) => {
        setIsCreateLoading(true);
        enqueueSnackbar(message, { variant: 'info' });
    }, []);

    const handleMerchantCreateSuccess = React.useCallback(
        async (message: string) => {
            const response = await getTenantAccessData({
                limit: TENANT_ACCESS_ITEMS_PER_PAGE,
                states: TenantAccessState.ACTIVE
            });

            if (response) {
                updateTenantAccess(response.tenantAccess);
                updateNextKey(response.nextKey);
            }
            setIsCreateLoading(false);
            setMerchantCreateOpen(false);
            enqueueSnackbar(message, { variant: 'success' });
        },
        [updateNextKey, updateTenantAccess]
    );

    const handleMerchantCreateError = React.useCallback((message: string) => {
        setIsCreateLoading(false);
        setMerchantCreateOpen(false);
        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
    }, []);

    return {
        merchantCreateOpen,
        isCreateLoading,
        handleMerchantCreateClick,
        handleMerchantCreateInfo,
        handleMerchantCreateSuccess,
        handleMerchantCreateError
    };
};

import { PerkVisualisation } from 'components/loyalty/models/PerkModel';
import { isString } from 'lib/typeguards';

export interface Award {
    _id: string;
    auto: boolean;
    isDonatable: boolean;
    isGlobal: boolean;
    isTier: boolean;
    maximumPointCashValue: number;
    perkId: string;
    points: AwardPoints;
    priority: string;
    redemptionMethod: string;
    template: AwardTemplate;
    tenantId: string;
    terms: string;
    title: string;
    token: string;
    userId: string;
    visualisation: PerkVisualisation;
    internalTitle?: string;
}

export enum AwardTemplate {
    AD_HOC = 'AD_HOC',
    PSEUDO_CURRENCY = 'PSEUDO_CURRENCY',
    STAMP_CARD = 'STAMP_CARD',
    CLUB = 'CLUB',
    LEVEL = 'LEVEL'
}

export const awardTemplateLabels = {
    AD_HOC: 'Ad Hoc',
    PSEUDO_CURRENCY: 'Pseudo currency',
    STAMP_CARD: 'Stamp card',
    CLUB: 'Club',
    LEVEL: 'Level'
};

export interface AwardPoints {
    available?: number;
    initial: number;
    max: number;
    min: number;
    redemption: number;
    step: number;
}

// TODO: replace with real typeguard
export function isAward(item: any): item is Award {
    return (
        !!item &&
        isString(item.title) &&
        isString(item.tenantId) &&
        isString(item.userId) &&
        isString(item.template)
    );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { SIGN_IN } from 'config/routes';
import { ApplicationState } from 'store/store';
import { Throbber } from 'ui/Throbber';

type PrivateRouteProps = RouteProps;

export const PrivateRoute: React.FC<PrivateRouteProps> = props => {
    const auth = useSelector(
        (state: ApplicationState) => state.auth,
        (left, right) => left.authorizedDate?.toString() === right.authorizedDate?.toString()
    );
    if (auth.isAuthorizingInProgress) {
        return <Throbber />;
    }
    if (!auth.claims) {
        return <Redirect to={SIGN_IN} />;
    }
    return <Route {...props} />;
};

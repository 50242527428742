import { RequestOptions } from 'lib/HttpClient';
import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { endOfDay, getTime, startOfDay } from 'date-fns';
import { PepperTransactionState } from 'components/pepper-pay/transactions/hooks/usePepperTransactionsFilter';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';

export interface PepperDisputesFilter {
    date?: DateRangePickerValue;
    locationId?: string;
}
type PepperDisputesFilterValue = DateRangePickerValue | string;
export type PepperDisputesFilterChange = (
    field: keyof PepperDisputesFilter,
    value: PepperDisputesFilterValue
) => void;

export function usePepperDisputesFilter(): [PepperDisputesFilter, PepperDisputesFilterChange] {
    const { search, onSearch } = useHistoryFilters();
    const [filter, setFilter] = React.useState<PepperDisputesFilter>({
        date: {
            startDate: search.startDate ? new Date(search.startDate) : undefined,
            endDate: search.endDate ? new Date(search.endDate) : undefined
        },
        locationId: search.locationId || undefined
    });
    const onFilterChange = React.useCallback<PepperDisputesFilterChange>((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { date, ...rest } = filter;
        onSearch({ ...rest, startDate: date?.startDate, endDate: date?.endDate });
    }, [filter, onSearch]);
    return [filter, onFilterChange];
}

export const getDisputesFilterBody = (filter: PepperDisputesFilter) => {
    const body: RequestOptions['body'] = { state: PepperTransactionState.Disputed };
    if (filter.locationId) {
        body.locationIds = [filter.locationId];
    }
    const epoch: { $gt?: number; $lt?: number } = {};
    if (filter.date?.startDate) {
        epoch.$gt = Math.floor(getTime(startOfDay(filter.date.startDate)) / 1000);
    }
    if (filter.date?.endDate) {
        epoch.$lt = Math.floor(getTime(endOfDay(filter.date.endDate)) / 1000);
    }
    if (filter.date?.startDate && !filter.date?.endDate) {
        epoch.$lt = Math.floor(getTime(endOfDay(filter.date.startDate)) / 1000);
    }
    if (epoch.$gt || epoch.$lt) {
        body.disputeCreated = epoch;
    }
    return body;
};

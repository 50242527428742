import { DynamicImagery } from '@pepperhq/menu-sdk';
import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { EnrichedCategory, EnrichedDynamicImagery } from '../model/menu';
import { EnrichedProduct } from '../model/product';
import { DynamicImageryEditForm } from './DynamicImageryEditForm';

interface DynamicImageryEditModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (dynamicImagery: DynamicImagery) => void;
    dynamicImagery?: EnrichedDynamicImagery;
    products: EnrichedProduct[];
    categories: EnrichedCategory[];
    productIdToTitle: Map<string, string>;
}

export const DynamicImageryEditModal: React.FC<DynamicImageryEditModalProps> = ({
    open,
    onClose,
    onSubmit,
    dynamicImagery,
    products,
    categories,
    productIdToTitle
}) => (
    <MuiModal open={open} onClose={onClose} noMaxWidth>
        <DynamicImageryEditForm
            onSubmit={onSubmit}
            dynamicImagery={dynamicImagery}
            products={products}
            categories={categories}
            onClose={onClose}
            productIdToTitle={productIdToTitle}
        />
    </MuiModal>
);

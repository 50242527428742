import React from 'react';
import { Claims } from '@pepperhq/auth-client';
import { MuiRouterContext } from 'lib/MuiRouterContext';
import { AppDrawer } from 'ui/AppDrawer';
import { getActivePage } from './findActivePage';
import { pages } from './pages';

interface NavigationBlockProps {
    claims?: Claims;
}

export const NavigationBlock: React.FC<NavigationBlockProps> = ({ claims }) => (
    <MuiRouterContext.Consumer>
        {({ pathname }) => (
            <AppDrawer
                claims={claims}
                activePage={getActivePage(pathname)}
                pathname={pathname}
                pages={pages}
            />
        )}
    </MuiRouterContext.Consumer>
);

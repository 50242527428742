import { AutoFormFields } from 'lib/LegacyForm';

export const createLocationAddressForm: () => AutoFormFields = () => ({
    geo: {
        label: 'Search for your location',
        type: 'geo',
        validation: 'required'
    },
    address: {
        label: '1st Line of Address'
    },
    country: {
        label: 'Country'
    },
    town: {
        label: 'Town'
    },
    postcode: {
        label: 'Postal Code'
    },
    summary: {
        label: 'Extra Information',
        description:
            "If your address doesn't fit the usual form, you can use this to add to it. An example of this is a building name."
    }
});

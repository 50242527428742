import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { Dispute } from '../model/PepperPay';
import { DisputeEvidenceForm } from './DisputeEvidanceForm';

interface DisputeEvidenceModalProps {
    dispute?: Dispute;
    onClose: () => void;
    onSuccess: (dispute: Dispute) => void;
    open: boolean;
    locationId?: string;
}

export const DisputeEvidenceModal: React.FC<DisputeEvidenceModalProps> = ({
    dispute,
    onClose,
    open,
    onSuccess,
    locationId
}) => {
    if (!open) {
        return null;
    }
    return (
        <MuiModal open={open} onClose={onClose}>
            <DisputeEvidenceForm
                onCancel={onClose}
                dispute={dispute}
                locationId={locationId}
                onSuccess={onSuccess}
            />
        </MuiModal>
    );
};

import { Crud, httpClient, HttpClient, HttpClientResponse, QueryParameter } from 'lib/HttpClient';
import { v4 as uuid } from 'uuid';
import {
    ACTION_OPERATIONS_LIMIT,
    IActionOperation,
    ICreateActionExportOptions,
    IGetActionOperationsParams
} from 'components/analytics/export/models';
import { IPagedCollection } from 'lib/api/paging';

class ActionExportsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'operations', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    public createExport({ from, to, locationIds, types, maxFileSizeMB }: ICreateActionExportOptions) {
        const body: ICreateActionExportOptions = { from, to, maxFileSizeMB };

        if (locationIds && locationIds.length) {
            body.locationIds = locationIds;
        }

        if (types && types.length) {
            body.types = types;
        }

        if (maxFileSizeMB) {
            body.maxFileSizeMB = maxFileSizeMB;
        }

        return this.httpClient.post(`${this.api}/actions/exports`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-pepper-req-nonce': uuid()
            },
            body
        });
    }

    public getOperations(
        params?: IGetActionOperationsParams
    ): HttpClientResponse<IPagedCollection<IActionOperation>> {
        const queryParameters: QueryParameter[] = [
            { key: 'limit', value: ACTION_OPERATIONS_LIMIT },
            { key: 'sort', value: '-createdAt' }
        ];
        const { nextKey } = params ?? {};
        if (nextKey) {
            queryParameters.push({ key: 'startKey', value: nextKey });
        }
        return this.httpClient.get(`${this.api}/actions/EXPORTS/operations`, { queryParameters });
    }

    public async getActionExportFile(href: string) {
        return fetch(`${this.api}/${href}`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                Authorization: `Bearer ${this.httpClient.getToken()}`,
                Accept: 'application/json,text/plain,application/octet-stream,application/javascript'
            }
        });
    }

    public getOperation(id: string): HttpClientResponse<IActionOperation> {
        return this.get(id);
    }
}

export const actionExportsApi = new ActionExportsApi(httpClient);

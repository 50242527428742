/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import React from 'react';
import { styled } from '@mui/material';
import get from 'get-value';
import { createLocationCreateForm } from 'components/location/forms/createLocationCreateForm';
import { locationApi } from 'components/location/LocationApi';
import {
    geoSuggestAddressFormatter,
    isValidAddress
} from 'components/utils/geoSuggest/geoSuggestAddressFormatter';
import { getGeoArrayFromObject } from 'components/utils/geoSuggest/getGeoArrayFromObject';
import { isValidCoordinate } from 'components/utils/isValidCoordinate';
import { LegacyForm, StepperForm } from 'lib/LegacyForm';
import { AutoFormStepper } from 'ui/form/AutoFormStepper';
import { MuiModal } from 'ui/MuiModal';

const StyledDiv = styled('div')(() => ({
    overflow: 'hidden'
}));

interface CreateLocationFormProps {
    onClose?: () => void;
    onError: (message: string) => void;
    onSuccess: (locationTitle: string) => void;
}

interface CreateLocationFormState {
    toggle: boolean;
}

export class CreateLocationForm extends React.Component<CreateLocationFormProps, CreateLocationFormState> {
    constructor(props: CreateLocationFormProps) {
        super(props);
        this.state = {
            toggle: false
        };
    }
    handleSubmit = async ({
        email,
        facebook,
        phone,
        address,
        country,
        fullAddress,
        googleMapsShortUrl,
        postcode,
        sunday,
        order_to_table,
        order_to_table_nfc,
        pay_at_table,
        pay_at_table_nfc,
        book_room,
        book_table,
        checkins,
        offline_checkin,
        pre_order,
        saturday,
        friday,
        thursday,
        wednesday,
        tuesday,
        monday,
        summary,
        town,
        foursquare: _foursquare,
        twitter,
        geo,
        tab,
        instagram,
        citymapper,
        hailo: _hailo,
        uber,
        timezone,
        ...data
    }: any) => {
        const result = await locationApi.create({
            body: {
                ...data,
                address: {
                    address,
                    country,
                    fullAddress,
                    googleMapsShortUrl,
                    postcode,
                    summary,
                    town
                },
                openingHours: {
                    sunday: formatDayOpeningHours(sunday),
                    saturday: formatDayOpeningHours(saturday),
                    friday: formatDayOpeningHours(friday),
                    thursday: formatDayOpeningHours(thursday),
                    wednesday: formatDayOpeningHours(wednesday),
                    tuesday: formatDayOpeningHours(tuesday),
                    monday: formatDayOpeningHours(monday)
                },
                services: {
                    book_room,
                    book_table,
                    checkins,
                    offline_checkin,
                    pre_order,
                    tab,
                    order_to_table,
                    order_to_table_nfc,
                    pay_at_table,
                    pay_at_table_nfc
                },
                geo: getGeoArrayFromObject(geo),
                contacts: {
                    email,
                    phone
                },
                links: {
                    facebook: { enabled: !!facebook, parameters: facebook },
                    twitter: { enabled: !!twitter, parameters: twitter },
                    instagram: { enabled: !!instagram, parameters: instagram },
                    // No parameters for these
                    citymapper: { enabled: citymapper },
                    foursquare: { enabled: false },
                    hailo: { enabled: false },
                    uber: { enabled: uber }
                },
                timezone: timezone ? timezone.value : undefined
            }
        });
        if (!result.ok) {
            this.props.onError(result.body.message);
        } else {
            this.props.onSuccess(data.title);
        }
    };

    locationUrl = (lat: number, lng: number, placeId: string) =>
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${placeId}`;

    onBlur = (name: string, value: any) => {
        if (name === 'geo') {
            let googleMapsShortUrl: string;
            if (get(value, 'location')) {
                const {
                    location: { lat, lng }
                } = value;
                const placeId = get(value, 'placeId');
                if (isValidCoordinate([lat, lng])) {
                    googleMapsShortUrl = this.locationUrl(lat, lng, placeId);
                }
            }

            const address = geoSuggestAddressFormatter(value);
            if (isValidAddress(address)) {
                this.form.update({ ...address, googleMapsShortUrl });
            }
        }
    };
    form = new LegacyForm(createLocationCreateForm(), this.handleSubmit) as StepperForm;
    render() {
        const { onClose } = this.props;
        return (
            <StyledDiv>
                <AutoFormStepper onBlur={this.onBlur} onClose={onClose} padding form={this.form} />
            </StyledDiv>
        );
    }
}

const formatDayOpeningHours = (hours: string): string => {
    if (!hours || hours.length < 1) {
        return 'closed';
    }
    return hours;
};

interface CreateLocationModalProps {
    onClose?: () => void;
    onError: (message: string) => void;
    onSuccess: (locationTitle: string) => void;
    open: boolean;
}

export const CreateLocationModal: React.SFC<CreateLocationModalProps> = ({
    open,
    onClose,
    onError,
    onSuccess
}) => (
    <MuiModal paperMaxWidth={480} open={open} onClose={onClose} disableBackdropClick padding={false}>
        <CreateLocationForm onClose={onClose} onSuccess={onSuccess} onError={onError} />
    </MuiModal>
);

import React from 'react';

export interface ClerksDataFilter {
    name?: string;
    locationId?: string;
}

export type ClerksDataFilterChange = (field: keyof ClerksDataFilter, value: string | undefined) => void;

export function useClerksDataFilter(): [ClerksDataFilter, ClerksDataFilterChange] {
    const [filter, setFilter] = React.useState<ClerksDataFilter>({
        name: undefined,
        locationId: undefined
    });
    const onFilterChange = React.useCallback<ClerksDataFilterChange>((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    return [filter, onFilterChange];
}

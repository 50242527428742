import React from 'react';
import { Checkbox, Divider, Input, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { Row } from 'ui/Flex';
import { useInputState } from './hooks/useInputState';

interface PickerListItemProps {
    item: ListPickerOption;
    onClick: (id: string, checked: boolean) => void;
    disabled: boolean;
    selected: boolean;
}

const PickerListItem: React.FC<PickerListItemProps> = ({ item, disabled, selected, onClick }) => {
    const handleClick = React.useCallback(() => {
        onClick(item.value, !selected);
    }, [onClick, item.value, selected]);
    return (
        <ListItem role={undefined} button disabled={disabled} onClick={handleClick}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={selected}
                    tabIndex={-1}
                    color="primary"
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.value}` }}
                />
            </ListItemIcon>
            <ListItemText id={`checkbox-list-label-${item.value}`} primary={item.label} />
        </ListItem>
    );
};

const PickerItemMemoized = React.memo(PickerListItem);

export interface ListPickerOption {
    value: string;
    label: string;
}

const PREFIX = 'ListPicker';

const classes = {
    search: `${PREFIX}-search`,
    searchInput: `${PREFIX}-searchInput`,
    searchInnerInput: `${PREFIX}-searchInnerInput`,
    list: `${PREFIX}-list`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`& .${classes.search}`]: {
        margin: theme.spacing(1, 0),
        minWidth: theme.spacing(35)
    },
    [`& .${classes.searchInput}`]: {
        marginRight: theme.spacing(-5)
    },
    [`& .${classes.searchInnerInput}`]: {
        paddingRight: theme.spacing(5)
    },
    [`& .${classes.list}`]: {
        maxHeight: theme.spacing(35),
        overflowY: 'auto'
    }
}));

interface ListPickerProps {
    items: ListPickerOption[];
    selectedItems: string[];
    disabledItems?: string[];
    defaultItems?: string[];
    onChange: (data: string[]) => void;
}

export const ListPicker: React.FC<ListPickerProps> = ({
    items,
    defaultItems = [],
    disabledItems = [],
    onChange,
    selectedItems
}) => {
    const [search, onSearchChange] = useInputState('');

    // Calculated
    const filteredItems = React.useMemo(() => {
        if (!search || !search.length) {
            return items;
        }
        const formattedSearch = search.toLowerCase();
        return items.filter(item => item.label.toLowerCase().includes(formattedSearch));
    }, [items, search]);

    // Callbacks
    const handleSelectAll = React.useCallback(() => {
        if (selectedItems.length === items.length) {
            return onChange(defaultItems);
        }
        return onChange(items.map(item => item.value));
    }, [selectedItems, defaultItems, items, onChange]);

    const handleToggle = React.useCallback(
        (value: string, checked: boolean) => {
            const newSet = new Set(selectedItems);
            if (!checked) {
                newSet.delete(value);
            } else {
                newSet.add(value);
            }
            onChange([...newSet]);
        },
        [selectedItems, onChange]
    );

    const renderListItem = React.useCallback(
        (item: ListPickerOption) => (
            <PickerItemMemoized
                key={`location-to-pick-${item.value}`}
                item={item}
                disabled={disabledItems.includes(item.value) || defaultItems.includes(item.value)}
                onClick={handleToggle}
                selected={disabledItems.includes(item.value) || selectedItems.includes(item.value)}
            />
        ),
        [defaultItems, selectedItems, handleToggle, disabledItems]
    );

    return (
        <StyledDiv>
            <Row valign="center" gutter flex={1} className={classes.search}>
                <Input
                    value={search}
                    fullWidth
                    onChange={onSearchChange}
                    placeholder="Search"
                    className={classes.searchInput}
                    inputProps={{ className: classes.searchInnerInput }}
                />
                <Search />
            </Row>
            <ListItem role={undefined} button onClick={handleSelectAll}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={selectedItems.length > 0}
                        indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                        tabIndex={-1}
                        disabled={defaultItems.length === items.length}
                        color="primary"
                        disableRipple
                        inputProps={{ 'aria-labelledby': 'checkbox-list-label-select-all' }}
                    />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-select-all'} primary="Select All" />
            </ListItem>
            <Divider />
            <List className={classes.list}>{filteredItems.map(renderListItem)}</List>
        </StyledDiv>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const RevelSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                {/* TODO: any reasons we have body1 and body2 for settings?*/}
                <SettingDisplayText
                    variant="body1"
                    title="Dining Option"
                    text={settings?.revel?.diningOption}
                />
                <SettingDisplaySwitch title="Tax Inclusive" checked={settings?.revel?.taxinclusive} />
                <SettingDisplayText variant="body1" title="Account Name" text={settings?.revel?.url} />
                <SettingDisplayText variant="body1" title="API Key" text={settings?.revel?.apiKey} crop />
                <SettingDisplayText
                    variant="body1"
                    title="Payment Type"
                    text={settings?.revel?.paymentType}
                />
                <SettingDisplaySwitch
                    title="Dynamic Discounts"
                    checked={settings?.revel?.useDynamicDiscounts}
                />
                <SettingDisplaySwitch
                    title="Use Revel Stock Availability"
                    checked={settings?.revel?.useStockAvailability}
                />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplayText
                    variant="body1"
                    title="Establishment ID"
                    text={settings?.revel?.establishmentId}
                />
                <SettingDisplaySwitch
                    title="Dynamic Discounts"
                    checked={settings?.revel?.useDynamicDiscounts}
                />
                <SettingDisplaySwitch
                    title="Use Revel Stock Availability"
                    checked={settings?.revel?.useStockAvailability}
                />
            </>
        )}
    </>
);

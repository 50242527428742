import { AutoFormFields } from 'lib/LegacyForm';
import { PRIMARY_ACTION_ITEMS } from '../ContentModuleActions';

export const createPrimaryActionItemForm: AutoFormFields = {
    title: {
        label: 'Title',
        validation: 'required'
    },
    type: {
        type: 'select',
        label: 'Action',
        options: PRIMARY_ACTION_ITEMS,
        validation: 'required'
    }
};

import React, { MutableRefObject } from 'react';
import { styled } from '@mui/material';
import { green, lightGreen, red } from '@mui/material/colors';
import {
    GridCellEditCommitParams,
    GridCellParams,
    MuiEvent,
    DataGridPro,
    DataGridProProps,
    GridRowData,
    GridRowId,
    GridCellValue,
    useGridApiRef,
    GridApi
} from '@mui/x-data-grid-pro';
import { isDefined } from 'lib/typeguards';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import clsx from 'clsx';
import { ObjectShape } from 'yup/lib/object';
import { useGridStateStorage } from './StateController';

const PREFIX = 'MuiGrid';

const classes = {
    changed: `${PREFIX}-changed`,
    error: `${PREFIX}-error`,
    removed: `${PREFIX}-removed`,
    checkboxVisibility: `${PREFIX}-checkboxVisibility`,
    overriden: `${PREFIX}-overriden`
};

const StyledDataGridPro = styled(DataGridPro)<MuiGridProps>(({ theme, checkboxVisibility }) => ({
    [`& .${classes.changed}`]: {
        // Hate material UI for thing like this one
        backgroundColor: `${lightGreen[100]} !important`,
        color: theme.palette.getContrastText(lightGreen[100])
    },
    [`& .${classes.error}`]: {
        // Hope they will bring a bit better styling
        border: `${theme.palette.error.light} !important`,
        borderStyle: 'solid !important',
        borderWidth: '2px !important',
        '&:focus': {
            borderWidth: '1px !important'
        }
    },
    [`& .${classes.checkboxVisibility}`]: {
        '&.MuiDataGrid-cellCheckbox': {
            display: checkboxVisibility ? 'flex' : 'none'
        },
        '&.MuiDataGrid-columnHeaderCheckbox': {
            display: checkboxVisibility ? 'flex' : 'none'
        }
    },
    [`& .${classes.removed}`]: {
        // Hate material UI for thing like this one
        backgroundColor: `${red[100]} !important`,
        color: theme.palette.getContrastText(red[100]),
        borderStyle: 'none !important',
        borderWidth: '2px !important',
        '&:focus': {
            borderStyle: 'none !important'
        }
    },
    [`& .${classes.overriden}`]: {
        color: green[600],
        fontWeight: 600,
        '& > .MuiDataGrid-booleanCell': {
            color: 'inherit'
        }
    }
}));

export enum EMuiGridStatus {
    DEFAULT = 'default',
    CHANGED = 'changed'
}

export interface GridRowValidationParams {
    id: GridRowId;
    field: string;
    value: GridCellValue;
}

export interface GridStateHelper {
    getState: () => MuiGridExternalState;
    reset: () => void;
    applyChanges: (id: string | number, data: Record<string | number, any>) => void;
    createItem: (id: string | number, fieldName?: string, defaultData?: any, columnToScroll?: string) => void;
    validate: (
        params?: GridRowValidationParams
    ) => Record<string | number, Record<string, string>> | undefined;
    getApiRef: () => GridApi;
    remove: (id: string | number) => void;
}

interface MuiGridProps extends DataGridProProps {
    stateRef?: MutableRefObject<GridStateHelper>;
    onStatusChange?: (status: EMuiGridStatus) => void;
    validationScheme?: ObjectShape;
    deleted?: Set<string | number>;
    validateRow?: (
        row: GridRowData,
        rows: GridRowData[],
        params?: GridRowValidationParams
    ) => Record<string, string> | undefined;
    onChange?: (id: GridRowId, field: string, value: GridCellValue) => void;
    shouldEditCellChangeCommitted?: (row: GridRowData, field: string) => boolean;
    storageName?: string;
    checkboxVisibility?: boolean;
    shouldReturnRowValueOnChange?: boolean;
}
export type MuiGridChanges = Record<string | number, Record<string | number, any>>;

export interface MuiGridExternalState {
    changes: MuiGridChanges;
}

const ROW_HEIGHT = 28;
const HEADER_HEIGHT = 36;

export const MuiGrid: React.FC<MuiGridProps> = function ({
    stateRef,
    onStatusChange,
    rows: gridRows,
    validationScheme,
    deleted,
    validateRow,
    onChange,
    shouldEditCellChangeCommitted,
    storageName,
    shouldReturnRowValueOnChange,
    ...gridProps
}) {
    const [changes, setChanges] = React.useState<MuiGridChanges>({});
    const [errors, setErrors] = React.useState<Record<string, Record<string, string>>>(undefined);
    const apiRef = useGridApiRef();
    const isChangeDefined = React.useCallback(
        (id: string | number, field: string) => isDefined(changes[id]) && isDefined(changes[id][field]),
        [changes]
    );
    const handleEditCellChangeCommitted = React.useCallback(
        ({ id, field, value }: GridCellEditCommitParams) => {
            const row: Record<string, any> = gridRows.find(item =>
                item._id ? item._id === id : item.id === id
            );
            if (!!row && (shouldEditCellChangeCommitted ? shouldEditCellChangeCommitted(row, field) : true)) {
                if (row[field] !== value) {
                    setChanges(currentChange => {
                        const currentItemChange = currentChange[id] ?? {};
                        const itemChange = { ...currentItemChange, [field]: value };

                        if (shouldReturnRowValueOnChange) {
                            itemChange.row = row;
                        }

                        return { ...currentChange, [id]: itemChange };
                    });

                    if (onStatusChange) {
                        onStatusChange(EMuiGridStatus.CHANGED);
                    }
                }
                if (validationScheme) {
                    try {
                        Yup.object()
                            .shape({ [field]: validationScheme[field] })
                            .validateSync({ [field]: value }, { abortEarly: false });
                        setErrors(currentErrors => {
                            if (currentErrors && currentErrors[id] && currentErrors[id][field]) {
                                const { [field]: _, ...rest } = currentErrors[id];
                                return { ...currentErrors, [id]: rest };
                            }
                            return currentErrors;
                        });
                    } catch (e) {
                        setErrors(currentErrors =>
                            currentErrors
                                ? { ...currentErrors, [id]: { ...currentErrors[id], [field]: e.message } }
                                : { [id]: { [field]: e.message } }
                        );
                    }
                }
            }
            if (onChange) {
                onChange(id, field, value);
            }
        },
        [
            gridRows,
            onChange,
            shouldEditCellChangeCommitted,
            validationScheme,
            onStatusChange,
            shouldReturnRowValueOnChange
        ]
    );
    const handleGetCellClassName = React.useCallback(
        ({ id, field, row }: GridCellParams) =>
            clsx({
                [classes.error]: isDefined(errors) && errors[id] && errors[id][field],
                [classes.changed]: isChangeDefined(id, field),
                [classes.removed]: deleted && deleted.has(id),
                [classes.overriden]: !(deleted && deleted.has(id)) && row._overriden?.has(field)
            }),
        [errors, isChangeDefined, deleted]
    );
    const getState = React.useCallback(
        () => ({
            changes
        }),
        [changes]
    );
    const reset = React.useCallback(() => {
        setChanges({});
        setErrors(undefined);
        if (onStatusChange) {
            onStatusChange(EMuiGridStatus.DEFAULT);
        }
    }, [onStatusChange]);
    const handleKeyDown = React.useCallback(
        (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>) => {
            const gridApi = apiRef.current;
            if (params.cellMode === 'edit') {
                switch (event.code) {
                    case 'ArrowUp': {
                        gridApi.commitCellChange({ id: params.id, field: params.field });
                        gridApi.setCellMode(params.id, params.field, 'view');
                        const currentIndex = gridApi.getRowIndex(params.id);
                        if (currentIndex > 0) {
                            const idToGo = gridApi.getRowIdFromRowIndex(currentIndex - 1);
                            gridApi.setCellFocus(idToGo, params.field);
                        }
                        break;
                    }
                    case 'ArrowDown': {
                        gridApi.commitCellChange({ id: params.id, field: params.field });
                        gridApi.setCellMode(params.id, params.field, 'view');
                        const currentIndex = gridApi.getRowIndex(params.id);
                        if (currentIndex < gridApi.getRowsCount() - 1) {
                            const idToGo = gridApi.getRowIdFromRowIndex(currentIndex + 1);
                            gridApi.setCellFocus(idToGo, params.field);
                        }
                        break;
                    }
                    case 'Tab': {
                        gridApi.commitCellChange({ id: params.id, field: params.field }, event);
                        break;
                    }
                }
            } else {
                switch (event.code) {
                    case 'Tab': {
                        event.preventDefault();
                        const visibleColumns = gridApi.getVisibleColumns();
                        const currentIndex = gridApi.getColumnIndex(params.field);
                        gridApi.setCellFocus(
                            params.id,
                            currentIndex < visibleColumns.length - 1
                                ? visibleColumns[currentIndex + 1].field
                                : visibleColumns[currentIndex].field
                        );
                        break;
                    }
                    case 'F2': {
                        if (gridApi.isCellEditable(params)) {
                            gridApi.setCellMode(params.id, params.field, 'edit');
                        }
                        break;
                    }
                }
            }
        },
        [apiRef]
    );
    const createItem = React.useCallback(
        (id: string | number, fieldName?: string, defaultData?: any, columnToScroll?: string) => {
            setChanges(current => ({ ...current, [id]: defaultData }));
            if (apiRef.current) {
                // A timeout to edit cell only when it is in a table
                setTimeout(() => {
                    const rowIndex = apiRef.current.getRowIndex(id);
                    const colIndex = apiRef.current.getColumnIndex(columnToScroll ?? 'id');
                    apiRef.current.scrollToIndexes({ rowIndex, colIndex });
                    if (isDefined(fieldName)) {
                        apiRef.current.setCellMode(id, fieldName, 'edit');
                    }
                }, 0);
            }
        },
        [apiRef]
    );
    const getApiRef = React.useCallback(() => apiRef.current, [apiRef]);
    const applyChanges = React.useCallback((id: string | number, data: Record<string | number, any>) => {
        setChanges(current => ({ ...current, [id]: data }));
    }, []);
    const validate = React.useCallback(
        (params?: GridRowValidationParams) => {
            if (!!validateRow) {
                if (params) {
                    const newErrors: Record<string | number, Record<string, string>> = { ...(errors || {}) };
                    const rowsAfterEdit = gridRows?.map(row => ({ ...row, ...(changes[row.id] || {}) }));
                    const rowChange = changes[params.id];
                    const currentItem = apiRef.current?.getRow(params.id) || {};
                    const currentItemErrors = validateRow(
                        { ...currentItem, ...rowChange },
                        rowsAfterEdit,
                        params
                    );
                    if (currentItemErrors) {
                        newErrors[params.id] = { ...(newErrors[params.id] || {}), ...currentItemErrors };
                    } else {
                        delete newErrors[params.id];
                    }
                    const errorsArray = Object.entries(newErrors);
                    if (errorsArray.length) {
                        const [[rowId, rowValues]] = errorsArray;
                        const [fieldId] = Object.keys(rowValues);
                        const rowIndex = apiRef.current?.getRowIndex(rowId);
                        const colIndex = apiRef.current?.getColumnIndex(fieldId);
                        apiRef.current?.scrollToIndexes({ rowIndex, colIndex });
                        setErrors(newErrors);
                        return newErrors;
                    }
                    setErrors(undefined);
                    return undefined;
                }
                const newErrors: Record<string | number, Record<string, string>> = {};
                const rowsAfterEdit = gridRows?.map(row => ({ ...row, ...(changes[row.id] || {}) }));

                Object.entries(changes)
                    .filter(([id]) => !deleted || !deleted.has(id))
                    .forEach(([id, item]) => {
                        const currentItem = apiRef.current?.getRow(id) || {};
                        const currentItemErrors = validateRow({ ...currentItem, ...item }, rowsAfterEdit);
                        if (currentItemErrors) {
                            newErrors[id] = currentItemErrors;
                        }
                    });
                const errorsArray = Object.entries(newErrors);
                if (errorsArray.length) {
                    const [[rowId, rowValues]] = errorsArray;
                    const [fieldId] = Object.keys(rowValues);
                    const rowIndex = apiRef.current?.getRowIndex(rowId);
                    const colIndex = apiRef.current?.getColumnIndex(fieldId);
                    apiRef.current?.scrollToIndexes({ rowIndex, colIndex });
                    setErrors(newErrors);
                    return newErrors;
                }
                setErrors(undefined);
                return undefined;
            }
            if (!!validationScheme) {
                const errors: Record<string | number, Record<string, string>> = {};
                const yup = Yup.object().shape(validationScheme);
                Object.entries(changes).forEach(([id, item]) => {
                    if (!deleted || !deleted.has(id)) {
                        const currentItem = apiRef.current?.getRow(id) || {};
                        try {
                            yup.validateSync({ ...currentItem, ...item }, { abortEarly: false });
                        } catch (e) {
                            errors[id] = (e as ValidationError).inner.reduce<Record<string, string>>(
                                (acc, error) => {
                                    acc[error.path] = error.message;
                                    return acc;
                                },
                                {}
                            );
                        }
                    }
                });
                const errorsArray = Object.entries(errors);
                if (errorsArray.length) {
                    const [[rowId, rowValues]] = errorsArray;
                    const [fieldId] = Object.keys(rowValues);
                    const rowIndex = apiRef.current?.getRowIndex(rowId);
                    const colIndex = apiRef.current?.getColumnIndex(fieldId);
                    apiRef.current?.scrollToIndexes({ rowIndex, colIndex });
                    setErrors(errors);
                    return errors;
                }
                setErrors(undefined);
                return undefined;
            }
        },
        [changes, deleted, errors, gridRows, validateRow, validationScheme, apiRef]
    );
    const remove = React.useCallback(
        (id: string | number) => {
            apiRef.current?.updateRows([{ id, _action: 'delete' }]);
        },
        [apiRef]
    );
    const onStateChange = useGridStateStorage(apiRef, storageName, gridRows);

    React.useEffect(() => {
        if (stateRef) {
            stateRef.current = {
                getState,
                reset,
                applyChanges,
                createItem,
                getApiRef,
                validate,
                remove
            };
        }
    }, [getState, stateRef, getApiRef, reset, applyChanges, createItem, validate, remove]);
    const errorsToDisplay = React.useMemo(() => {
        if (!deleted || !errors) {
            return errors;
        }
        return Object.entries(errors).reduce<Record<string, Record<string, string>>>((acc, [key, value]) => {
            if (deleted.has(key)) {
                return acc;
            }
            acc[key] = value;
            return acc;
        }, {});
    }, [deleted, errors]);
    const rows = React.useMemo(() => [...gridRows], [gridRows]);

    return (
        <StyledDataGridPro
            checkboxVisibility={gridProps.checkboxVisibility}
            getCellClassName={handleGetCellClassName}
            onCellEditCommit={handleEditCellChangeCommitted}
            hideFooter
            componentsProps={errorsToDisplay}
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            rows={rows}
            disableSelectionOnClick
            onCellKeyDown={handleKeyDown}
            apiRef={apiRef}
            onStateChange={onStateChange}
            classes={{
                cell: classes.checkboxVisibility,
                columnHeader: classes.checkboxVisibility
            }}
            {...gridProps}
        />
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const WorldPaySettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => {
    if (locationId !== undefined) {
        return null;
    }
    return (
        <>
            <SettingDisplaySwitch title="Production Environment" checked={settings?.worldpay?.isProduction} />
            <SettingDisplaySwitch
                title="Wait For Settlement"
                checked={settings?.worldpay?.waitForSettlement}
            />
            <SettingDisplayText title="Username" text={settings?.worldpay?.username} />
            <SettingDisplayText title="Password" text={settings?.worldpay?.password} />
            <SettingDisplayText title="Merchant ID" text={settings?.worldpay?.merchantId} />
            <SettingDisplayText title="Checkout ID" text={settings?.worldpay?.checkoutId} />
            <SettingDisplayText
                title="Google Pay Merchant ID"
                text={settings?.worldpay?.googlePayMerchantId}
            />
        </>
    );
};

import React from 'react';
import { List } from '@mui/material';
import { Option } from 'lib/types';
import { DynamicImageryImageItem } from './DynamicImageryImageItem';

interface DynamicImageryImagesListProps {
    onChange: (data: Record<string, { imageUrl: string; sort: number }>) => void;
    images: Record<string, { imageUrl: string; sort: number }>;
    products: Option[];
}

export const DynamicImageryImagesList: React.FC<DynamicImageryImagesListProps> = ({
    images,
    products,
    onChange
}) => {
    const handleChange = React.useCallback(
        (image: { imageUrl: string; sort: string }, id: string) => {
            let sort: number;
            if (image.sort !== '') {
                const formattedSort = Number(image.sort);
                if (!isNaN(formattedSort)) {
                    sort = formattedSort;
                }
            }
            onChange({
                ...images,
                [id]: { ...image, sort }
            });
        },
        [images, onChange]
    );
    const renderListItem = React.useCallback(
        (product: Option) => (
            <DynamicImageryImageItem
                key={product.value}
                product={product}
                image={images[product.value]}
                onChange={handleChange}
            />
        ),
        [handleChange, images]
    );
    return <List>{products.map(renderListItem)}</List>;
};

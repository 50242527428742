// Main layout with Drawer and Header
import React from 'react';
import { useSelector } from 'react-redux';
import { NavigationBlock } from 'components/navigation/NavigationBlock';
import { ApplicationState } from 'store/store';
import { Column, Row } from 'ui/Flex';
import { MuiAppBar } from 'ui/MuiAppBar';
import clsx from 'clsx';
import { Alert, styled } from '@mui/material';

const PREFIX = 'PrivateLayout';

const classes = {
    content: `${PREFIX}-content`,
    drawer: `${PREFIX}-drawer`,
    alertVisible: `${PREFIX}-alertVisible`
};

const StyledRow = styled(Row)(({ theme }) => ({
    height: '100%',
    paddingTop: theme.spacing(7),
    [`& .${classes.content}`]: {
        flex: 1,
        height: '100%',
        padding: theme.spacing(3),
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1, 1, 2, 2)
        }
    },
    [`& .${classes.drawer}`]: {
        height: '100%'
    },
    [`&.${classes.alertVisible}`]: {
        height: `calc(100% - ${theme.spacing(6)})`
    }
}));

const StyledAlert = styled(Alert)(() => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 0
}));

type PrivateLayoutProps = React.ComponentProps<'div'>;

export const PrivateLayout: React.FC<PrivateLayoutProps> = props => {
    const { tenant, claims } = useSelector(
        (state: ApplicationState) => state.auth,
        (left, right) => left.authorizedDate === right.authorizedDate
    );
    const { children } = props;

    return (
        <React.Fragment>
            <MuiAppBar title={tenant && tenant.title} />
            {tenant?.isDisabled && (
                <StyledAlert severity="error" variant="filled">
                    This tenant has been disabled. Please contact Pepper Support if this is incorrect.
                </StyledAlert>
            )}
            <StyledRow className={clsx({ [classes.alertVisible]: tenant?.isDisabled })} flex={1}>
                <Column className={clsx(classes.drawer, 'hidden-scroll')} id="drawer-layout">
                    {tenant && <NavigationBlock claims={claims} />}
                </Column>
                <Column className={classes.content} id="private-layout">
                    {children}
                </Column>
            </StyledRow>
        </React.Fragment>
    );
};

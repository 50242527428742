import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class VoucherApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'vouchers', {
            'x-api-version': '10'
        });
    }
}

export const voucherApi = new VoucherApi(httpClient);

import { InputAdornment, TextField, styled } from '@mui/material';
import {
    GridCellValue,
    GridFilterItem,
    GridFilterModel,
    GridFilterOperator,
    GridLinkOperator
} from '@mui/x-data-grid-pro';
import React from 'react';
import Search from '@mui/icons-material/Search';
import { MenuSearchKey } from './useMenuGridSearchState';

const StyledTextField = styled(TextField)(({ theme }) => ({
    minWidth: '360px',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: 0
    }
}));

export type GridSearchItem = {
    column: string;
    labels?: Record<string, string> | Map<string, string>;
    formatValue?: (value: GridCellValue) => string;
};

export type GridSearchItems = GridSearchItem[];

export function useGridSearch(
    items: GridSearchItems,
    searchValue: string,
    onChange: (value: string, name: string) => void,
    name: MenuSearchKey,
    placeholder?: string
) {
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.Or
    });
    const filterOperators = React.useMemo(() => {
        const operators: Record<string, GridFilterOperator | undefined> = {};
        if (Array.isArray(items)) {
            items.forEach(item => {
                if (item.labels) {
                    const getName = (id: string) => {
                        if (item.labels instanceof Map) {
                            return item.labels.get(id);
                        }
                        return item.labels[id] ?? '';
                    };
                    operators[item.column] = {
                        label: 'title',
                        value: 'title',
                        getApplyFilterFn: (filterItem: GridFilterItem) => {
                            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                                return null;
                            }
                            return params =>
                                getName(String(params.value))
                                    .toLowerCase()
                                    .includes(String(filterItem.value).toLowerCase());
                        }
                    };
                }
                if (item.formatValue) {
                    operators[item.column] = {
                        label: 'Resolve',
                        value: 'resolve',
                        getApplyFilterFn: (filterItem: GridFilterItem) => {
                            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                                return null;
                            }
                            return params =>
                                item
                                    .formatValue(params.value)
                                    .toLowerCase()
                                    .includes(String(filterItem.value).toLowerCase());
                        }
                    };
                }
            });
        }
        return operators;
    }, [items]);
    React.useEffect(() => {
        if (!searchValue || !searchValue.length) {
            setFilterModel({ items: [], linkOperator: GridLinkOperator.Or });
        } else {
            const newItems: GridFilterItem[] = [];
            items.forEach(item => {
                if (item.formatValue) {
                    newItems.push({
                        id: item.column,
                        columnField: item.column,
                        operatorValue: 'resolve',
                        value: searchValue
                    });
                } else if (item.labels) {
                    newItems.push({
                        id: item.column,
                        columnField: item.column,
                        operatorValue: 'title',
                        value: searchValue
                    });
                } else {
                    newItems.push({
                        id: item.column,
                        columnField: item.column,
                        operatorValue: 'contains',
                        value: searchValue
                    });
                }
            });
            setFilterModel({ items: newItems, linkOperator: GridLinkOperator.Or });
        }
    }, [searchValue, items]);
    const handleSearchChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value, e.target.name);
        },
        [onChange]
    );
    const renderInput = React.useCallback(
        () => (
            <StyledTextField
                name={name}
                onChange={handleSearchChange}
                value={searchValue}
                placeholder={placeholder ?? 'Search'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
        ),
        [handleSearchChange, name, placeholder, searchValue]
    );
    return { filterModel, filterOperators, renderInput };
}

import { isCurrencyCode } from '@pepperhq/regions';
import Dinero from 'dinero.js';
import { updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { FormattedPepperDispute, PepperTransaction } from '../../model/PepperPay';
import { pepperPayApi } from '../../pepperPayApi';
import { getDisputesFilterBody, PepperDisputesFilter } from './usePepperDisputesFilter';

export const DISPUTES_PAGE_LIMIT = 100;

export function usePepperDisputes(filter: PepperDisputesFilter) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [disputes, setDisputes] = React.useState<PepperTransaction[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<PepperDisputesFilter>();
    const reset = React.useCallback(() => {
        setDisputes([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    React.useEffect(() => {
        const getDisputes = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                const filterBody = getDisputesFilterBody(internalFilter);
                const pageBody: Record<string, string | number> = { limit: DISPUTES_PAGE_LIMIT };
                if (pages[page]) {
                    pageBody.startKey = pages[page];
                }
                const { body, ok } = await pepperPayApi.transactionsSearch.create({
                    body: { page: pageBody, ...filterBody }
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.has_more) {
                    setEndOfData(true);
                }
                setDisputes(currentTransactions => [...currentTransactions, ...body.items]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
            } catch (e) {
                logger.error(e);
                setDisputes([]);
            }
            setLoading(false);
        };
        if (
            (page === 0 && disputes.length === 0) ||
            (!pages[page + 1] && disputes.length < pages.length * DISPUTES_PAGE_LIMIT)
        ) {
            getDisputes();
        }
    }, [endOfData, internalFilter, page, pages, disputes.length]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const formattedDisputes = React.useMemo(() => {
        if (!Array.isArray(disputes)) {
            return [];
        }
        return disputes
            .slice(page * DISPUTES_PAGE_LIMIT, page * DISPUTES_PAGE_LIMIT + DISPUTES_PAGE_LIMIT)
            .map<FormattedPepperDispute>(item => {
                const currencyCode = item.currency?.toUpperCase();
                return {
                    amount: Dinero({
                        amount: item.total,
                        currency: isCurrencyCode(currencyCode) ? currencyCode : 'GBP'
                    }).toFormat(),
                    status: item.dispute?.status,
                    transactionId: item._id,
                    id: item.dispute?.disputeId,
                    date: new Date(item.dispute?.created * 1000),
                    dueBy: new Date(item.dispute?.evidenceDueBy * 1000),
                    reason: item.dispute?.reason,
                    customerId: item.userId,
                    paymentIntentId: item.paymentIntent?.paymentIntentId,
                    paymentChargeId: item.charge?.chargeId
                };
            });
    }, [page, disputes]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return disputes.length;
        }
        return -1;
    }, [endOfData, disputes.length]);
    return { disputes, formattedDisputes, page, onPageChange, loading, count };
}

import { Box, TablePagination } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GIFTING_DATA_PAGE_LIMIT_OPTIONS } from 'components/analytics/gifting/activity/hooks/useGiftingActivityData';
import { MuiTheme } from 'config/theme';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { Row } from 'ui/Flex';
import { useGiftingLiabilityFilter } from 'components/analytics/gifting/liability/hooks/useGiftingLiabilityFilter';
import { useGiftingLiabilityData } from 'components/analytics/gifting/liability/hooks/useGiftingLiabilityData';
import { GiftingLiabilityFilters } from 'components/analytics/gifting/liability/GiftingLiabilityFilters';
import { GiftingLiabilitySummary } from 'components/analytics/gifting/liability/GiftingLiabilitySummary';

export const GiftingLiabilityPage: React.FC = () => {
    const [filter, onFilterChange] = useGiftingLiabilityFilter();
    const {
        summary,
        formattedData,
        page,
        onPageChange,
        pageLimit,
        onPageLimitChange,
        sortModel,
        onSortModelChange,
        loading,
        count,
        error
    } = useGiftingLiabilityData(filter);
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const handlePageLimitChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onPageLimitChange(Number.parseInt(event.target.value));
        },
        [onPageLimitChange]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Gift Program',
                field: 'gift_program_name',
                width: 240,
                sortable: true,
                headerAlign: 'left'
            },
            {
                headerName: 'Transaction Count',
                field: 'total_transactions',
                width: 180,
                sortable: false,
                align: 'right'
            },
            {
                headerName: 'Debits',
                field: 'formatted_debits',
                sortable: false,
                align: 'right',
                width: 140
            },
            {
                headerName: 'Credits',
                field: 'formatted_credits',
                sortable: false,
                align: 'right',
                width: 140
            },
            {
                headerName: 'Liabilities',
                field: 'formatted_liability',
                sortable: false,
                align: 'right',
                width: 140
            }
        ],
        []
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={pageLimit}
                rowsPerPageOptions={GIFTING_DATA_PAGE_LIMIT_OPTIONS}
                onRowsPerPageChange={handlePageLimitChange}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, handlePageLimitChange, pageLimit, page]
    );
    const getRowId = React.useCallback((item: any) => item.gift_program_id, []);
    if (error) {
        return (
            <ErrorLayout
                title="Something wrong"
                fallbackUrl="/"
                fallbackTitle="Main Page"
                description={error}
            />
        );
    }
    return (
        <MainLayout
            pageName="Liability"
            pageDescription="Useful insights into how gifting is used by your customers."
        >
            <Box paddingBottom={10} height="100%">
                <Box paddingBottom={1}>
                    <Row flex={1} wrap="wrap" valign="center" align="space-between">
                        <GiftingLiabilityFilters filter={filter} onChange={onFilterChange} />
                        <GiftingLiabilitySummary summary={summary} loading={loading} />
                    </Row>
                </Box>
                <MuiGrid
                    rows={formattedData}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    disableMultipleSelection
                    hideFooter={false}
                    pagination
                    getRowId={getRowId}
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    components={{
                        Pagination
                    }}
                />
            </Box>
        </MainLayout>
    );
};

// MERCHANT CREATION
export const MESSAGE_MERCHANT_CREATE_SUCCESS = 'Your Merchant has been created.';
export const MESSAGE_MERCHANT_PRECREATE_ERROR = (reason: string) =>
    `Sorry, there was an error creating your Merchant. Error: ${reason}`;
export const MESSAGE_MERCHANT_CREATE_ERROR = (operationId: string) =>
    `Sorry, there was an error creating your Merchant. Please try again or contact support with ID: ${operationId}`;
export const MESSAGE_MERCHANT_CREATE_INFO = "We're just setting up your Merchant, please wait.";
// POS SETTINGS
export const MESSAGE_MERCHANT_SETTINGS_POS_REMOVE_SETTINGS_ERROR = 'Failed to remove settings.';
export const MESSAGE_MERCHANT_SETTINGS_POS_REMOVE_SETTINGS_SUCCESS = 'Successfully removed settings.';
export const MESSAGE_MERCHANT_MOBILE_SETTINGS_UPDATE_SUCCESS = 'Successfully updated settings';
export const MESSAGE_MERCHANT_MOBILE_SETTINGS_REMOVE_SUCCESS = 'Successfully removed settings';
export const MESSAGE_MERCHANT_MOBILE_SETTINGS_UPDATE_ERROR = 'Failed to update settings';
export const MESSAGE_MERCHANT_MOBILE_SETTINGS_REMOVE_ERROR = 'Failed to remove settings';
// INTEGRATION CONFIG
export const MESSAGE_INTEGRATION_TENANT_SAVE_SUCCESS = (integration: string) =>
    `Successfully updated ${integration} tenant settings.`;
export const MESSAGE_INTEGRATION_TENANT_SAVE_ERROR = (integration: string) =>
    `Failed to update ${integration} tenant settings.`;
export const MESSAGE_INTEGRATION_LOCATION_SAVE_SUCCESS = (integration: string) =>
    `Successfully updated ${integration} location settings.`;
export const MESSAGE_INTEGRATION_LOCATION_SAVE_ERROR = (integration: string) =>
    `Failed to update ${integration} location settings.`;
export const MESSAGE_INTEGRATION_ACTIVATED = (integration: string) =>
    `Successfully activated ${integration} integration.`;
// MOBILE CONTENT
export const MESSAGE_MOBILE_CONTENT_LAYOUT_LOAD_ERROR =
    'Failed to load layout, please refresh the page and try again.';
export const MESSAGE_MOBILE_CONTENT_LAYOUT_DELETE_ERROR = 'Failed to delete a layout.';
export const MESSAGE_MOBILE_CONTENT_LAYOUT_DELETE_SUCCESS = 'Layout successfully deleted.';
export const MESSAGE_MOBILE_CONTENT_CREATE_LAYOUT_ERROR = 'Failed to create a layout.';
export const MESSAGE_MOBILE_CONTENT_CREATE_LAYOUT_SUCCESS = 'Layout successfully created.';
export const MESSAGE_MOBILE_CONTENT_UPDATE_LAYOUT_ERROR = 'Failed to update a layout.';
export const MESSAGE_MOBILE_CONTENT_UPDATE_LAYOUT_SUCCESS = 'Layout successfully updated.';
export const MESSAGE_MOBILE_CONTENT_LAYOUT_SET_ACTIVE_SUCCESS = 'Layout successfully made active.';
export const MESSAGE_MOBILE_CONTENT_LAYOUT_SET_ACTIVE_ERROR = 'Failed to make layout active.';
export const MESSAGE_MOBILE_CONTENT_LAYOUT_SET_DESCRIPTION_ERROR = 'Failed to update layout description.';
export const MESSAGE_MOBILE_CONTENT_UPDATE_MODULE_ERROR = 'Failed to update the Module.';
export const MESSAGE_MOBILE_CONTENT_UPDATE_MODULE_SUCCESS = 'Module successfully updated.';
export const MESSAGE_MOBILE_CONTENT_DELETE_MODULE_SUCCESS = 'Module successfully deleted.';
export const MESSAGE_MOBILE_CONTENT_DELETE_MODULE_ERROR = 'Failed to delete the Module.';
export const MESSAGE_MOBILE_CONTENT_CREATE_MODULE_SUCCESS = 'Module successfully created.';
// LOCATIONS
export const MESSAGE_LOCATIONS_CREATE_SUCCESS = (title: string) => `${title} location successfully created.`;
export const MESSAGE_LOCATIONS_CREATE_ERROR = 'Failed to create a location.';
export const MESSAGE_LOCATIONS_UPDATE_SUCCESS = (title: string) => `${title} location successfully updated.`;
export const MESSAGE_LOCATIONS_UPDATE_ERROR = 'Failed to update the location.';
export const MESSAGE_LOCATION_RETIRE_SUCCESS = (title: string) => `${title} location successfully retired.`;
export const MESSAGE_LOCATION_RETIRE_ERROR = 'Failed to retire a location.';
export const MESSAGE_LOCATION_ACTIVATE_SUCCESS = (title: string) =>
    `${title} location successfully activated.`;
export const MESSAGE_LOCATION_ACTIVATE_ERROR = 'Failed to activate a location.';
export const MESSAGE_LOCATION_DELETE_SUCCESS = 'Location successfully deleted.';
export const MESSAGE_LOCATION_DELETE_ERROR = 'Failed to delete a location.';
export const MESSAGE_LOCATION_REFRESH_ERROR = 'Failed to refresh a location menu.';
export const MESSAGE_TAX_DELETE_SUCCESS = 'Tax successfully deleted.';
export const MESSAGE_TAX_DELETE_ERROR = 'Failed to delete a tax.';
export const MESSAGE_DYNAMIC_IMAGE_DELETE_SUCCESS = 'The dynamic imagery has been successfully deleted.';
export const MESSAGE_DYNAMIC_IMAGE_DELETE_ERROR = 'Failed to delete a dynamic imagery.';
export const MESSAGE_DYNAMIC_IMAGE_UPDATE_SUCCESS = 'The dynamic imagery has been successfully updated.';
export const MESSAGE_DYNAMIC_IMAGE_UPDATE_ERROR = 'Failed to update a dynamic imagery.';
export const MESSAGE_DYNAMIC_IMAGE_CREATE_SUCCESS = 'The dynamic imagery has been successfully created..';
export const MESSAGE_DYNAMIC_IMAGE_CREATE_ERROR = 'Failed to create a dynamic imagery.';
export const MESSAGE_MATCH_CODES_DELETE_SUCCESS = 'The Match Code has been successfully deleted.';
export const MESSAGE_MATCH_CODES_DELETE_ERROR = 'Failed to delete a Match Code.';
export const MESSAGE_MATCH_CODES_UPDATE_SUCCESS = 'The Match Code has been successfully updated.';
export const MESSAGE_MATCH_CODES_UPDATE_ERROR = 'Failed to update a Match Code.';
export const MESSAGE_MATCH_CODES_CREATE_SUCCESS = 'The Match Code has been successfully created..';
export const MESSAGE_MATCH_CODES_CREATE_ERROR = 'Failed to create a Match Code.';
export const MESSAGE_TEN_KITES_CONFIG_ERROR = 'Check your ten kites configuration!';
// CUSTOMERS
export const MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_SUCCESS = 'Customer info successfully updated.';
export const MESSAGE_CUSTOMER_SESSION_DELETE_SUCCESS = 'User sessions successfully deleted.';
export const MESSAGE_CUSTOMER_SESSION_DELETE_ERROR = 'Error deleting user sessions.';
export const MESSAGE_CUSTOMER_CARD_DELETE_SUCCESS = 'Card has been successfully deleted.';
export const MESSAGE_CUSTOMER_CARD_DELETE_ERROR = 'Failed to delete card.';
export const MESSAGE_CUSTOMER_DELETE_SUCCESS = 'Customer has been successfully deleted.';
export const MESSAGE_CUSTOMER_DELETE_ERROR = 'Failed to delete customer.';
export const MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_ERROR = 'Failed to update customer info.';
export const MESSAGE_CUSTOMER_AUDIENCE_UPDATE_SUCCESS = 'Customer Audiences successfully updated.';
export const MESSAGE_CUSTOMER_AUDIENCE_UPDATE_ERROR = 'Failed to update audiences, please try again.';
export const MESSAGE_CUSTOMER_CREDIT_SUCCESS = 'The customer has been successfully awarded.';
export const MESSAGE_CUSTOMER_CREDIT_ERROR = 'Failed to award a customer.';
export const MESSAGE_CUSTOMER_PASSWORD_INVALIDATE_SUCCESS = 'Password has been invalidated successfully.';
export const MESSAGE_CUSTOMER_PASSWORD_INVALIDATE_ERROR = 'Error invalidating password.';

// CUSTOMERS ORDER REFUNDING
export const MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING = 'Refund in progress.';
export const MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS =
    'This transaction is currently in the process of being refunded.';
export const MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR =
    "We can't currently refund this order, please contact Pepper support.";
export const MESSAGE_CUSTOMER_ORDER_CLOSING_PENDING = 'Closing order is in progress.';
export const MESSAGE_CUSTOMER_ORDER_CLOSING_SUCCESS = 'Order has been successfully closed.';
export const MESSAGE_CUSTOMER_ORDER_CLOSING_ERROR =
    'We are unable to close this order, please contact Pepper support.';
// This isn't duplicate, in the future we can change copy only for an award or only for a credit.
export const MESSAGE_CUSTOMER_AWARD_SUCCESS = 'The customer has been successfully awarded.';
export const MESSAGE_CUSTOMER_AWARD_ERROR = 'Failed to award a customer.';
export const MESSAGE_CUSTOMER_AWARD_ERROR_EXIST =
    'Failed to award a customer. The user already has this award.';
export const MESSAGE_CUSTOMER_REDEEM_AWARD_SUCCESS = 'The award has been successfully redeemed.';
export const MESSAGE_CUSTOMER_REDEEM_AWARD_ERROR = 'Failed to redeem an award.';
export const MESSAGE_CUSTOMER_GET_PERKS_ERROR = 'Failed to load available perks. Please, try to do it later.';
export const MESSAGE_CUSTOMER_CONTACT_SUCCESS = 'The notification was successfully sent.';
export const MESSAGE_CUSTOMER_CONTACT_SKIPPED =
    'This customer did not receive the notification - this may be due to their marketing preferences.';
export const MESSAGE_CUSTOMER_CONTACT_ERROR = 'Failed to send the notification.';
export const MESSAGE_CREATE_VOUCHER_SUCCESS = 'Successfully created the voucher.';
export const MESSAGE_EDIT_VOUCHER_SUCCESS = 'Changes have been saved successfully.';
export const MESSAGE_EDIT_VOUCHER_ERROR = 'Failed to edit voucher.';
export const MESSAGE_CREATE_VOUCHER_ERROR = 'Failed to create the voucher.';
export const MESSAGE_ADD_EFFECT_VOUCHER_ERROR = 'Failed to add trigger to voucher.';
export const MESSAGE_ADD_EFFECT_VOUCHER_SUCCESS = 'Successfully added trigger to voucher.';
export const MESSAGE_DELETE_VOUCHER_SUCCESS = 'Successfully deleted the voucher.';
export const MESSAGE_DELETE_VOUCHER_ERROR = 'Failed to delete voucher.';

// FORGOT_YOUR_PASSWORD
export const FORGOT_YOUR_PASSWORD_EMAIL_SENT =
    'You will receive an email with instructions about how to reset your password in a few minutes.';
export const FORGOT_YOUR_PASSWORD_CONFIRMED = 'Your password was successfully reset.';

// MARKETING
export const MESSAGE_MARKETING_NOTIFICATION_CREATE_ERROR = (errorMessage: string) =>
    `An error occurred sending the notification, ${errorMessage}.`;
export const MESSAGE_MARKETING_NOTIFICATION_CREATE_IN_PROGRESS =
    'Sending notifications... you can check the progress of this by refreshing the page.';
export const MESSAGE_MARKETING_AUDIENCE_USERS_ADDED_SUCCESS = (usersCount: number) =>
    `${usersCount} user${usersCount > 1 ? 's' : ''} successfully added to the audience.`;
export const MESSAGE_MARKETING_AUDIENCE_USERS_ADDED_ERROR = 'Failed to add users.';
export const MESSAGE_MARKETING_AUDIENCE_CREATE_ERROR = 'Failed to create an audience.';
export const MESSAGE_MARKETING_AUDIENCE_CREATE_SUCCESS = 'Audience successfully created.';
export const MESSAGE_MARKETING_AUDIENCE_REMOVE_USER_ERROR = 'Failed to remove customer from audience.';
export const MESSAGE_MARKETING_AUDIENCE_REMOVE_USER_SUCCESS = 'Customer successfully removed from audience.';
export const MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_SUCCESS = 'Triggers have been saved successfully!';
export const MESSAGE_MARKETING_AUDIENCE_ADD_TRIGGERS_ERROR =
    'Failed to add conditions. Please, try again later';
export const MESSAGE_MARKETING_AUDIENCE_UPDATE_ERROR = 'Failed to update the audience.';
export const MESSAGE_MARKETING_PERK_CREATE_ERROR = 'Failed to create a perk.';
export const MESSAGE_MARKETING_PERK_CREATE_SUCCESS = 'Perk successfully created.';
export const MESSAGE_MARKETING_PERK_UPDATE_ERROR = 'Failed to update a perk.';
export const MESSAGE_MARKETING_PERK_UPDATE_SUCCESS = 'Perk successfully updated.';
export const MESSAGE_MARKETING_PERK_RESET_SUCCESS = 'All awards successfully removed.';
export const MESSAGE_MARKETING_PERK_RESET_ERROR = 'Failed to remove awards.';
export const MESSAGE_MARKETING_PERK_DELETE_ERROR = 'Failed to delete a perk.';
export const MESSAGE_MARKETING_PERK_DELETE_SUCCESS = 'Perk successfully deleted.';
export const MESSAGE_MARKETING_AWARD_CREATE_ERROR = 'Failed to link award';
export const MESSAGE_MARKETING_AWARD_CREATE_SUCCESS = 'Award successfully linked';
export const MESSAGE_MARKETING_AWARD_UPDATE_ERROR = 'Failed to update award';
export const MESSAGE_MARKETING_AWARD_UPDATE_SUCCESS = 'Award successfully updated';
export const MESSAGE_MARKETING_AWARD_DELETE_ERROR = 'Failed to delete award';
export const MESSAGE_MARKETING_AWARD_DELETE_SUCCESS = 'Award successfully deleted';

export const MESSAGE_MARKETING_CAMPAIGN_SAVE_ERROR = 'Failed to save a campaign.';
export const MESSAGE_MARKETING_CAMPAIGN_SAVE_SUCCESS = 'Campaign successfully saved.';
export const MESSAGE_MARKETING_CAMPAIGN_STOP_ERROR = 'Failed to stop Campaign.';
export const MESSAGE_MARKETING_CAMPAIGN_STOP_SUCCESS = 'Campaign successfully stopped.';
export const MESSAGE_MARKETING_CAMPAIGN_START_ERROR = 'Failed to start Campaign.';
export const MESSAGE_MARKETING_CAMPAIGN_START_SUCCESS = 'Campaign successfully started.';
export const MESSAGE_MARKETING_CAMPAIGN_LOADING_ERROR = 'Failed to load a campaign.';
// MARKETING-CAMPAIGNS-ITEMS
export const MESSAGE_MARKETING_CAMPAIGN_ITEM_CREATE_SUCCESS = 'New campaign item was created';
export const MESSAGE_MARKETING_CAMPAIGN_ITEM_CREATE_ERROR = 'Error during saving the campaign';
export const MESSAGE_MARKETING_CAMPAIGN_ITEM_UPDATE_SUCCESS = 'Campaign item successfuly updated';
export const MESSAGE_MARKETING_CAMPAIGN_ITEM_UPDATE_ERROR = 'Error during updating the campaign item';
export const MESSAGE_MARKETING_CAMPAIGN_ITEM_DELETE_SUCCESS = 'Campaign item successfuly removed';
export const MESSAGE_MARKETING_CAMPAIGN_ITEM_DELETE_ERROR = 'Error during removing the campaign item';

export const MESSAGE_GRANT_PERK_TO_SEGMENT_ACCEPTED = (perkTitle: string) =>
    `Granting perk (${perkTitle}) to audience.`;
export const MESSAGE_GRANT_PERK_TO_SEGMENT_REJECTED = (err: string) =>
    `Sorry, we can't grant this perk to this audience at the moment: ${err}`;
export const MESSAGE_RETRY_OPERATION_ACCEPTED = 'Retrying failed bulk change.';
export const MESSAGE_RETRY_OPERATION_REJECTED = (err: string) => `Failed retrying bulk change: ${err}`;
export const MESSAGE_AUDIENCE_DELETE_SUCCESS =
    'Audience has been deleted. You will be redirected to audiences page in a moment.';
export const MESSAGE_AUDIENCE_DELETE_ERROR = 'Error deleting audience.';
// OVERWRITES
// Export
export const MESSAGE_EXPORT_ENRICHMENTS_CREATED = 'Export customisations is in progress';
export const MESSAGE_EXPORT_ENRICHMENTS_ERROR = 'Error during the customisations export';
export const MESSAGE_EXPORT_ENRICHMENTS_SUCCESS =
    'Customisations successfuly exported. Now you can download them';
// Import
export const MESSAGE_IMPORT_ENRICHMENTS_CREATED = 'Import customisations is in progress';
export const MESSAGE_IMPORT_ENRICHMENTS_ERROR = 'Error during the customisations import';
export const MESSAGE_IMPORT_ENRICHMENTS_SUCCESS = 'Customisations successfuly imported.';
// Table Zones
export const MESSAGE_TABLE_ZONE_UPDATE_ERROR = 'Failed to update the zones. Please, try again later';
export const MESSAGE_TABLE_ZONE_UPDATE_SUCCESS = 'Zone updated successfully!';
export const MESSAGE_NOTES_UPDATE_SUCCESS = 'Order notes updated successfully!';
export const MESSAGE_NOTES_UPDATE_ERROR = 'Failed to update order notes. Please, try again later';
export const REQUIRED_ERROR_MESSAGE = 'This field is required';
export const TABLE_ZONE_ALPHANUMERIC_CHARACTERS_ERROR = 'Please, only enter alphanumeric characters';
export const TABLE_NUMBER_CHARACTERS_ERROR =
    'Please, use comma to separate table numbers and dash to express a range of tables.';
export const MESSAGE_MENU_REFRESH =
    'Updating menus after setting change. Please allow a moment for them to show changes on the Menus page.';
// Menu Category Groups
export const MESSAGE_CATEGORY_GROUP_UPDATE_SUCCESS = 'Category groups updated successfully!';
export const MESSAGE_CATEGORY_GROUP_UPDATE_ERROR =
    'Failed to update the category groups. Please try again later.';
// Menu Categories
export const MESSAGE_CATEGORIES_UPDATE_SUCCESS = 'Categories updated successfully!';
export const MESSAGE_CATEGORIES_UPDATE_ERROR = 'Failed to update the categories. Please try again later.';
// Menu Products
export const MESSAGE_PRODUCTS_UPDATE_SUCCESS = 'Products updated successfully!';
export const MESSAGE_PRODUCTS_UPDATE_ERROR = 'Failed to update the products. Please try again later.';
export const MESSAGE_PRODUCT_AVAILABILITY_UPDATE_SUCCESS = 'Product availability updated successfully!';
export const MESSAGE_PRODUCT_AVAILABILITY_UPDATE_ERROR =
    'Failed to update availability. Please try again later.';
export const MESSAGE_PRODUCT_AVAILABILITY_COPY_SUCCESS = 'Product availability copied successfully!';
export const MESSAGE_PRODUCT_AVAILABILITY_COPY_ERROR = 'Failed to copy availability. Please try again later.';
export const MESSAGE_BUSYNESS_UPDATE_ERROR = 'Failed to update busyness. Please try again later.';
// Menu Modifiers
export const MESSAGE_MODIFIERS_UPDATE_SUCCESS = 'Modifiers updated successfully!';
export const MESSAGE_MODIFIERS_UPDATE_ERROR = 'Failed to update the modifiers. Please try again later.';
// Menu Modifier Products
export const MESSAGE_MODIFIER_PRODUCTS_UPDATE_SUCCESS = 'Modifier products updated successfully!';
export const MESSAGE_MODIFIER_PRODUCTS_UPDATE_ERROR =
    'Failed to update the modifier products. Please try again later.';
// Menu Modifier Options
export const MESSAGE_MODIFIER_OPTIONS_UPDATE_SUCCESS = 'Modifier options updated successfully!';
export const MESSAGE_MODIFIER_OPTIONS_UPDATE_ERROR =
    'Failed to update the modifier options. Please try again later.';
// Menu Dynamic Images
export const MESSAGE_DYNAMIC_IMAGES_UPDATE_SUCCESS = 'Dynamic images updated successfully!';
export const MESSAGE_DYNAMIC_IMAGES_UPDATE_ERROR =
    'Failed to update the Dynamic images. Please try again later.';
// Menu Tags
export const MESSAGE_TAGS_UPDATE_SUCCESS = 'Tags updated successfully!';
export const MESSAGE_TAGS_UPDATE_ERROR = 'Failed to update tags. Please try again later.';
// Menu Taxes
export const MESSAGE_TAXES_UPDATE_SUCCESS = 'Taxes updated successfully!';
export const MESSAGE_TAXES_UPDATE_ERROR = 'Failed to update taxes. Please try again later.';
// Weekly Availability
export const MESSAGE_WEEKLY_AVAILABILITY_UPDATE_SUCCESS = 'Weekly availability updated successfully!';
export const MESSAGE_WEEKLY_AVAILABILITY_UPDATE_ERROR =
    'Failed to update weekly availability. Please try again later.';
// Bulk Update menuChange
export const MESSAGE_BULK_UPDATE_SUCCESS = 'Menu copy operation complete!';
export const MESSAGE_BULK_UPDATE_ERROR = 'Failed to copy menu item. Please try again later.';
// Access Management
export const MESSAGE_APP_ID_CREATION_SUCCESSFUL = 'App ID has been created successfully.';
export const MESSAGE_APP_ID_CREATION_FAILED =
    'There was an error while creating the app ID. Please, try again later.';
// Customers
export const MESSAGE_ACTION_COPY_TO_CLIPBOARD_SUCCESS = 'Action successfully copied to the clipboard.';
export const MESSAGE_ACTION_COPY_TO_CLIPBOARD_FAIL = 'Failed to copy, please try again later.';
// Report
export const MESSAGE_REPORT_CREATION_CREATED = 'Report creation is in progress';
export const MESSAGE_REPORT_CREATION_ERROR = 'Error during the report creation';
export const MESSAGE_REPORT_CREATION_SUCCESS = 'Report successfuly exported. Now you can download them';
// Schedules
export const MESSAGE_SCHEDULE_SUCCESS = 'Schedule successfuly created.';
export const MESSAGE_SCHEDULE_ERROR = 'Error during the schedule creation.';
export const MESSAGE_SCHEDULE_DELETED_SUCCESS = 'Schedule report deleted successfully.';
export const MESSAGE_SCHEDULE_DELETED_ERROR = 'Failed to delete a report, please try again later';
// Disputes
export const MESSAGE_DSIPUTE_ACCEPT_SUCCESS = 'Dispute successfully refunded and closed.';
export const MESSAGE_DSIPUTE_EVIDENCE_SUCCESS = 'Dispute evidence successfully submitted.';
// Action exports
export const MESSAGE_ACTION_EXPORT_FILE_DOWNLOAD_FAIL =
    'Failed to download the file, please try again later.';
export const MESSAGE_ACTION_EXPORT_DELAY_WARNING =
    'A report produced for more than 7 days will take a few minutes to prepare';
export const MESSAGE_ACTION_EXPORT_ERROR = 'Error occurred while export';
export const MESSAGE_ACTION_EXPORT_SUCCESS = 'Actions successfully exported. Now you can download them';
export const MESSAGE_ACTION_EXPORT_IN_PROGRESS = 'Export actions is in progress';
export const MESSAGE_ACTION_EXPORT_DATE_RANGE_WARNING =
    'Please select a date range which does not exceed 90 days';

// Block List
export const MESSAGE_ADD_BLOCK_LIST_ITEM_SUCCESS = 'Block list item added successfully.';
export const MESSAGE_ADD_BLOCK_LIST_ITEM_TRANSACTION_SUCCESS = (value: string) =>
    `Block list item "${value}" added successfully.`;
export const MESSAGE_ADD_BLOCK_LIST_ITEM_VALUE_ERROR = (value: string) =>
    `Block list item "${value}" is already in the list.`;
export const MESSAGE_ADD_BLOCK_LIST_ITEM_ERROR = 'Failed to add item to Block list, please try again later.';
export const MESSAGE_REMOVE_BLOCK_LIST_ITEM_SUCCESS = 'Block list item removed successfully.';
export const MESSAGE_REMOVE_BLOCK_LIST_ITEM_ERROR =
    'Failed to remove item from Block list, please try again later.';

export const MESSAGE_VOID_TRANSACTION_SUCCESS = 'Void in progress.';
export const MESSAGE_VOID_TRANSACTION_ERROR = 'Failed to void transaction, please try again later.';

export const MESSAGE_CAPTURE_PAYMENT_SUCCESS = 'Payment capture is in progress.';
export const MESSAGE_CAPTURE_PAYMENT_ERROR = 'Error capturing the payment.';

export const MESSAGE_LOCATION_NO_MENU = 'Unable to find active menu for the location';

// GENERAL
export const MESSAGE_GENERAL_ERROR = 'Unexpected error occurred, please try again later';
// Location Tag Groups
export const MESSAGE_DELETE_TAG_GROUP_SUCCESS = 'Tag has been deleted successfully';
export const MESSAGE_DELETE_TAG_GROUP_ERROR = 'Error occurred while deleting the tag, please try again later';
export const MESSAGE_CREATE_TAG_GROUP_SUCCESS = 'Tag has been created successfully';
export const MESSAGE_CREATE_TAG_GROUP_ERROR = 'Error occurred while creating the tag, please try again later';
export const MESSAGE_UPDATE_TAG_GROUP_SUCCESS = 'Tag has been updated successfully';
export const MESSAGE_UPDATE_TAG_GROUP_ERROR = 'Error occurred while updating the tag, please try again later';

// Clerks
export const MESSAGE_CREATE_CLERK_SUCCESS = 'Clerk successfully created';
export const MESSAGE_UPDATE_CLERK_SUCCESS = 'Clerk successfully updated';
export const MESSAGE_DELETE_CLERK_SUCCESS = 'Clerk successfully deleted';
export const MESSAGE_DELETE_CLERK_ERROR = 'Error occured while deleting the clerk';

// Tenants
export const MESSAGE_TENANT_DISABLE_SUCCESS = 'Tenant successfully disabled';
export const MESSAGE_TENANT_DISABLE_ERROR = 'Error occured while disabling the tenant';
export const MESSAGE_TENANT_REACTIVATE_SUCCESS = 'Tenant successfully reactivated';
export const MESSAGE_TENANT_REACTIVATE_ERROR = 'Error occured while reactivating the tenant';

// General
export const MESSAGE_COPIED_TO_CLIPBOARD = 'Copied to clipboard.';

// Tiered loyalty
export const MESSAGE_TIERED_LOYALTY_CREATE_SUCCESS = 'Tiered loyalty successfully created.';
export const MESSAGE_TIERED_LOYALTY_NAME_EDIT_SUCCESS = 'Tiered loyalty successfully updated.';
export const MESSAGE_TIERED_LOYALTY_NAME_EDIT_ERROR = 'Failed to update a tiered loyalty.';
export const MESSAGE_TIERED_LOYALTY_STATE_EDIT_SUCCESS = 'Tiered loyalty state updated.';
export const MESSAGE_TIERED_LOYALTY_STATE_EDIT_ERROR = 'Failed to update a tiered loyalty state.';
export const MESSAGE_TIERED_LOYALTY_METRIC_CREATE_SUCCESS = 'Metric successfully created.';
export const MESSAGE_TIERED_LOYALTY_METRIC_CREATE_ERROR = 'Failed to create a metric.';
export const MESSAGE_TIERED_LOYALTY_METRIC_DELETE_SUCCESS = 'Metric successfully deleted.';
export const MESSAGE_TIERED_LOYALTY_METRIC_DELETE_ERROR = 'Failed to delete a metric.';
export const MESSAGE_TIERED_LOYALTY_TIER_DELETE_SUCCESS = 'Tier successfully deleted.';
export const MESSAGE_TIERED_LOYALTY_TIER_DELETE_ERROR = 'Failed to delete a tier.';
export const MESSAGE_TIERED_LOYALTY_TIER_LISTENER_CREATE_SUCCESS = 'Tier listener successfully created.';
export const MESSAGE_TIERED_LOYALTY_TIER_LISTENER_CREATE_ERROR = 'Failed to create a tier listener.';
export const MESSAGE_TIERED_LOYALTY_TIER_CONDITION_CREATE_SUCCESS = 'Tier condition successfully created.';
export const MESSAGE_TIERED_LOYALTY_TIER_CONDITION_CREATE_ERROR = 'Failed to create a tier condition.';
export const MESSAGE_TIERED_LOYALTY_TIER_CONDITION_DELETE_SUCCESS = 'Tier condition successfully deleted.';
export const MESSAGE_TIERED_LOYALTY_TIER_CONDITION_DELETE_ERROR = 'Failed to delete a tier condition.';

import React from 'react';
import { Grid } from '@mui/material';
import { Panel } from 'ui/Panel';
import { CardViewChipArrayRow } from 'ui/viewComponents';
import { Customer, CustomerSegment } from '../models/Customer';
import { CustomersAudienceEditCard } from './CustomersAudienceEditCard';

interface CustomersAudienceCardProps {
    customer: Customer;
    onChange: (data: Partial<Customer>) => void;
}

export const CustomersAudienceCard: React.FC<CustomersAudienceCardProps> = ({ customer, onChange }) => {
    const [editing, setEditing] = React.useState(false);

    const updateCustomerSegments = React.useCallback(
        (newSegments: CustomerSegment[]) => {
            onChange({ segments: newSegments });
        },
        [onChange]
    );
    const handleEdit = React.useCallback(() => setEditing(true), []);
    const handleClose = React.useCallback(() => setEditing(false), []);

    return (
        <Panel title="Audiences" divider showAction={!editing} onClick={handleEdit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            {editing ? (
                                <CustomersAudienceEditCard
                                    onChange={updateCustomerSegments}
                                    onClose={handleClose}
                                    customer={customer}
                                />
                            ) : (
                                <CardViewChipArrayRow
                                    value={
                                        Array.isArray(customer.segments)
                                            ? customer.segments.reduce((acc, segment) => {
                                                  if (segment.included) {
                                                      acc.push(segment.title);
                                                  }
                                                  return acc;
                                              }, [])
                                            : []
                                    }
                                    fallback="This customer doesn't belong to any audience"
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Panel>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const PowercardGiftCardSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Production Environment">
                    <SwitchFormField name="powercardGiftCardIsProduction" />
                </SettingDisplay>
                <SettingDisplay label="API Token">
                    <TextFormField name="powercardGiftCardApiToken" />
                </SettingDisplay>
                <SettingDisplay label="Merchant ID">
                    <TextFormField name="powercardGiftCardMerchantId" />
                </SettingDisplay>
                <SettingDisplay label="Gift PID">
                    <TextFormField name="powercardGiftCardGiftPID" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Gift Card Location ID">
                <TextFormField name="powercardGiftCardLocationId" />
            </SettingDisplay>
        )}
    </>
);

import { Crud, HttpClient, httpClient, QueryParameter } from 'lib/HttpClient';

class ActionApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'actions');
    }
    getActionsList(id: string, queryParameters?: QueryParameter[]) {
        return this.httpClient.get(`${this.api}/users/${id}/actions`, { queryParameters });
    }
}

export const actionApi = new ActionApi(httpClient);

import {
    AvailabilityOverrideCode,
    CategoryGroupOverride,
    CategoryGroupOverrideEdit,
    CategoryOverride,
    CategoryOverrideEdit,
    MenuOverrideSchema,
    OrderScenario,
    ProductOverride,
    ProductOverrideEdit
} from '@pepperhq/menu-sdk';

export interface IMenuOverrideEdit {
    schema: MenuOverrideSchema;
    zones: string[];
    scenarios: OrderScenario[];
    categories?: Record<string, CategoryOverrideEdit>;
    categoryGroups?: Record<string, CategoryGroupOverrideEdit>;
    products?: Record<string, ProductOverrideEdit>;
}

export interface IMenuOverrideEditUpdate {
    schema: MenuOverrideSchema;
    categories?: Record<string, Partial<CategoryOverride>>;
    categoryGroups?: Record<string, Partial<CategoryGroupOverride>>;
    products?: Record<string, Partial<ProductOverride>>;
}

export interface IAvailabilityRowItem {
    _id: string;
    id: string;
    value: string;
    isAvailable: boolean;
    availabilityExplaination: string | undefined;
    visibilityStatus: boolean;
    visibilityExplaination: string | undefined;
    searchKeys: string[];
    availabilityOverrideCode?: AvailabilityOverrideCode;
    availableOverride?: boolean;
    parentKeys: string[];
    type: 'PRODUCT' | 'CATEGORY' | 'CATEGORY_GROUP';
    path: string[];
}

export interface MenuOverridesFilter {
    zone: string;
    scenario: OrderScenario;
    date: string;
    time: string;
}

export const getMenuCategoryOverrideRowItems = (
    categories: Record<string, CategoryOverrideEdit>,
    categoryGroupId?: string,
    categoryGroupSearchKey: string[] = []
): IAvailabilityRowItem[] => {
    const rows: IAvailabilityRowItem[] = [];
    Object.entries(categories).forEach(
        ([categoryId, categoryOverrideEdit]: [string, CategoryOverrideEdit]) => {
            const categoryUniqueId =
                categoryGroupId !== undefined ? `${categoryGroupId}^${categoryId}` : categoryId;
            const categoryPath: string[] = [categoryUniqueId];
            const categorySearchKeys: string[] = [categoryId, categoryOverrideEdit.title];
            if (categoryGroupId !== undefined) {
                categoryPath.unshift(categoryGroupId);
            }

            rows.push({
                _id: categoryUniqueId,
                id: categoryId,
                value: categoryOverrideEdit.title,
                isAvailable: categoryOverrideEdit.isAvailable ?? true,
                visibilityStatus: categoryOverrideEdit.isVisible ?? true,
                visibilityExplaination: categoryOverrideEdit.isVisibleExplain,
                availabilityExplaination: categoryOverrideEdit.isAvailableExplain,
                availabilityOverrideCode: categoryOverrideEdit.availabilityOverrideCode,
                searchKeys: [...categoryGroupSearchKey, ...categorySearchKeys],
                parentKeys: categoryGroupId ? [categoryGroupId] : [],
                type: 'CATEGORY',
                path: categoryPath
            });

            Object.entries(categoryOverrideEdit.products).forEach(([productId, productOverrideEdit]) => {
                const productUniqueId = `${categoryUniqueId}^${productId}`;
                rows.push({
                    _id: productUniqueId,
                    id: productId,
                    value: productOverrideEdit.title,
                    isAvailable: productOverrideEdit.isAvailable ?? true,
                    visibilityStatus: productOverrideEdit.isVisible ?? true,
                    visibilityExplaination: productOverrideEdit.isVisibleExplain,
                    availabilityExplaination: productOverrideEdit.isAvailableExplain,
                    availableOverride: productOverrideEdit.availableOverride,
                    parentKeys: categoryGroupId ? [categoryGroupId, categoryUniqueId] : [categoryUniqueId],
                    type: 'PRODUCT',
                    searchKeys: [
                        ...categoryGroupSearchKey,
                        ...categorySearchKeys,
                        productId,
                        productOverrideEdit.title
                    ],
                    path: [...categoryPath, `${categoryGroupId}^${categoryId}^${productId}`]
                });
            });
        }
    );

    return rows;
};

export const getMenuOverrideEditRows = (overrideEditInfo: IMenuOverrideEdit): IAvailabilityRowItem[] => {
    const rows: IAvailabilityRowItem[] = [];

    if (overrideEditInfo.categoryGroups) {
        Object.entries(overrideEditInfo.categoryGroups).forEach(
            ([categoryGroupId, categoryGroupOverrideEdit]: [string, CategoryGroupOverrideEdit]) => {
                const searchKeys = [categoryGroupId, categoryGroupOverrideEdit.title];
                rows.push({
                    id: categoryGroupId,
                    _id: categoryGroupId,
                    value: categoryGroupOverrideEdit.title,
                    isAvailable: categoryGroupOverrideEdit.isAvailable ?? true,
                    visibilityStatus: categoryGroupOverrideEdit.isVisible ?? true,
                    visibilityExplaination: categoryGroupOverrideEdit.isVisibleExplain,
                    availabilityExplaination: categoryGroupOverrideEdit.isAvailableExplain,
                    availabilityOverrideCode: categoryGroupOverrideEdit.availabilityOverrideCode,
                    searchKeys: [categoryGroupId, categoryGroupOverrideEdit.title],
                    path: [categoryGroupId],
                    parentKeys: [],
                    type: 'CATEGORY_GROUP'
                });
                rows.push(
                    ...getMenuCategoryOverrideRowItems(
                        categoryGroupOverrideEdit.categories,
                        categoryGroupId,
                        searchKeys
                    )
                );
            }
        );
    } else if (overrideEditInfo.categories) {
        rows.push(...getMenuCategoryOverrideRowItems(overrideEditInfo.categories));
    }
    return rows;
};

interface OverrideGridConsts<T extends string> {
    labels: Record<T, string>;
    values: Record<T, any>;
    options: Record<T, string>;
}

export type ProductOverrideEditKeys = 'true' | 'false' | 'empty';
export const ProductOverrideEditConst: OverrideGridConsts<ProductOverrideEditKeys> = {
    labels: {
        true: 'Available',
        false: 'Unavailable',
        empty: 'Clear'
    },
    values: {
        true: true,
        false: false,
        empty: null
    },
    options: {
        true: 'Available',
        false: 'Unavailable',
        empty: 'Clear'
    }
};

export type CategoryOverrideEditKeys =
    | `${AvailabilityOverrideCode.SHOWN}`
    | `${AvailabilityOverrideCode.HIDDEN}`
    | `${AvailabilityOverrideCode.SCHEDULED}`
    | 'empty';
export const CategoryOverrideEditConst: OverrideGridConsts<CategoryOverrideEditKeys> = {
    labels: {
        [AvailabilityOverrideCode.SHOWN]: 'Shown',
        [AvailabilityOverrideCode.HIDDEN]: 'Hidden',
        [AvailabilityOverrideCode.SCHEDULED]: '',
        empty: ''
    },
    values: {
        [AvailabilityOverrideCode.HIDDEN]: AvailabilityOverrideCode.HIDDEN,
        [AvailabilityOverrideCode.SHOWN]: AvailabilityOverrideCode.SHOWN,
        [AvailabilityOverrideCode.SCHEDULED]: AvailabilityOverrideCode.SCHEDULED,
        empty: null
    },
    options: {
        [AvailabilityOverrideCode.SHOWN]: 'Show',
        [AvailabilityOverrideCode.HIDDEN]: 'Hide',
        empty: 'Clear',
        [AvailabilityOverrideCode.SCHEDULED]: null
    }
};

import React from 'react';
import { awardEnrichmentApi } from 'components/loyalty/AwardEnrichmentsApi';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import logger from 'lib/logger';
import { PERKS_ITEMS_PER_PAGE } from 'components/perks/PerksGrid';

const DEFAULT_QUERY = { limit: PERKS_ITEMS_PER_PAGE };

export const useExternalPerksData = () => {
    const [loading, setLoading] = React.useState(false);

    const [awardEnrichments, setAwardEnrichments] = React.useState<IAwardEnrichment[]>([]);

    const [searchValue, setSearchValue] = React.useState('');

    const handleSearchValueChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }, []);

    React.useEffect(() => {
        const load = async () => {
            let next: string;
            let endOfData = false;
            let loadedAwards: IAwardEnrichment[] = [];

            setLoading(true);

            while (!endOfData) {
                try {
                    const query = next ? { ...DEFAULT_QUERY, startKey: next } : DEFAULT_QUERY;

                    const res = await awardEnrichmentApi.getAllAwardEnrichments(query);

                    if (!res.ok || !res.body.items) {
                        throw new Error(res.body.message);
                    }

                    const {
                        items: awards,
                        page: { nextKey }
                    } = res.body;

                    if (!nextKey) {
                        endOfData = true;
                    }

                    loadedAwards = [...loadedAwards, ...awards];

                    next = nextKey;

                    if (!next) {
                        endOfData = true;
                    }
                } catch (e) {
                    logger.error(e.message);
                    enqueueSnackbar('Failed to load awards', { variant: 'error' });
                }
            }

            setAwardEnrichments(loadedAwards);
            setLoading(false);
        };

        load();
    }, [setAwardEnrichments, setLoading]);

    return {
        awardEnrichments,
        loading,
        searchValue,
        setAwardEnrichments,
        handleSearchValueChange
    };
};

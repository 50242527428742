import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { LocationScopedSettings } from 'components/settings/LocationScopedSettings';
import { OrderingSettings } from 'components/settings/OrderingSettings';
import { MERCHANT_MOBILE_SETTINGS } from 'config/routes';

export const OrderingSettingsPage: React.FC = () => (
    <MainLayout
        pageName="Ordering"
        pageDescription="Adjust your ordering settings."
        breadcrumbs={[{ label: 'Settings', url: MERCHANT_MOBILE_SETTINGS }]}
    >
        <LocationScopedSettings globalComponent={OrderingSettings} scopedComponent={OrderingSettings} />
    </MainLayout>
);

import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    styled,
    TextFieldVariants,
    Typography
} from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { Option } from 'lib/types';
import { Column } from 'ui/Flex';

interface SelectFormFieldProps {
    label?: React.ReactNode;
    options: Option[];
    description?: React.ReactNode;
    disabled?: boolean;
    className?: string;
    multiple?: boolean;
    native?: boolean;
    displayEmpty?: boolean;
    emptyLabel?: string;
    shrink?: boolean;
    hasAllOption?: boolean;
    selectAllOptionLabel?: string;
    clearable?: boolean;
    onSelect?: (value: string | string[]) => void;
    readOnly?: boolean;
    variant?: TextFieldVariants;
    size?: 'small' | 'medium';
}

const ALL_OPTION = '*';

export const SelectFormField: React.FC<FieldAttributes<SelectFormFieldProps>> = ({
    label,
    placeholder: _placeholder,
    description,
    options,
    disabled,
    multiple,
    className,
    displayEmpty,
    native,
    emptyLabel,
    shrink,
    hasAllOption,
    selectAllOptionLabel,
    clearable,
    onSelect,
    readOnly,
    variant,
    size,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <SelectFormFieldComponent
                label={label}
                description={description}
                options={options}
                multiple={multiple}
                disabled={disabled}
                className={className}
                displayEmpty={displayEmpty}
                native={native}
                emptyLabel={emptyLabel}
                shrink={shrink}
                selectAllOptionLabel={selectAllOptionLabel}
                hasAllOption={hasAllOption}
                clearable={clearable}
                onSelect={onSelect}
                readOnly={readOnly}
                variant={variant}
                size={size}
                {...childProps}
            />
        )}
    </Field>
);

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    background: theme.palette.common.white
}));

interface StyledSelectProps extends SelectProps {
    hasLabel?: boolean;
}

const StyledSelect = styled(Select, {
    shouldForwardProp: prop => prop !== 'hasLabel'
})<StyledSelectProps>(({ theme, hasLabel }) => ({
    ...(!hasLabel && {
        marginTop: `${theme.spacing(0)} !important`
    })
}));

const SelectFormFieldComponent: React.FC<FieldProps & SelectFormFieldProps> = ({
    description,
    field,
    form,
    meta,
    label,
    options,
    disabled,
    multiple,
    className,
    native,
    displayEmpty,
    emptyLabel,
    shrink,
    hasAllOption,
    clearable,
    selectAllOptionLabel,
    onSelect,
    readOnly,
    variant,
    size
}) => {
    const isError = meta.touched && !!meta.error;
    const renderValue = React.useCallback(
        (value: string[] | string) =>
            Array.isArray(value)
                ? value
                      .map(item => options.find(option => item === option.value)?.label)
                      .filter(Boolean)
                      .join(', ')
                : options.find(option => value === option.value)?.label ?? emptyLabel,
        [emptyLabel, options]
    );
    const renderOption = React.useCallback(
        ({ value, label: localLabel, description: localDescription }: Option) => (
            <MenuItem value={value} key={`select-${value ?? localLabel}`}>
                {localDescription ? (
                    <Column>
                        {localLabel}
                        {React.isValidElement(localDescription) ? (
                            localDescription
                        ) : (
                            <Typography variant="caption">{localDescription}</Typography>
                        )}
                    </Column>
                ) : (
                    localLabel
                )}
            </MenuItem>
        ),
        []
    );
    const selectOptions = React.useMemo(() => {
        const availableOptions = [...options];
        if (hasAllOption && multiple) {
            availableOptions.unshift({ label: selectAllOptionLabel || 'Select all', value: ALL_OPTION });
        }

        if (clearable) {
            availableOptions.push({ label: 'None', value: 'none' });
        }

        return availableOptions;
    }, [hasAllOption, clearable, multiple, options, selectAllOptionLabel]);
    const handleChange = React.useCallback(
        (event: SelectChangeEvent<any>) => {
            let customEvent;
            if (
                multiple &&
                hasAllOption &&
                Array.isArray(event.target.value) &&
                event.target.value.includes(ALL_OPTION)
            ) {
                customEvent = { target: { value: options.map(({ value }) => value), name: field.name } };
            } else if (clearable && event.target.value.includes('none')) {
                if (multiple) {
                    customEvent = { target: { value: undefined, name: field.name } };
                } else {
                    customEvent = { target: { value: undefined, name: field.name } };
                }
            } else {
                customEvent = event;
            }
            field.onChange(customEvent);
            onSelect?.(customEvent.target.value);
        },
        [clearable, field, hasAllOption, multiple, onSelect, options]
    );

    return (
        <FormControl
            fullWidth
            error={meta.touched && !!meta.error}
            className={className}
            disabled={disabled || form.isSubmitting}
            margin="none"
            variant={variant}
            size={size}
        >
            {label && (
                <StyledInputLabel shrink={readOnly ? true : shrink} variant={variant ?? 'standard'}>
                    {label}
                </StyledInputLabel>
            )}
            <StyledSelect
                variant={variant ?? 'standard'}
                displayEmpty={displayEmpty}
                native={native}
                value={field.value ?? ''}
                fullWidth
                multiple={field.multiple || multiple}
                onChange={handleChange}
                onBlur={field.onBlur}
                name={field.name}
                renderValue={renderValue}
                hasLabel={!!label}
                readOnly={readOnly}
                size={size}
            >
                {selectOptions && selectOptions.map(renderOption)}
            </StyledSelect>
            {(isError || description) && (
                <FormHelperText sx={{ mx: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};

import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { filterKeyToFilterChecker, MenuSummaryFilter } from './MenuManagerModel';
import { MenuManagerTableFilter } from './filter/MenuManagerTableFilter';
import { MENU_MANAGER_ENRICHMENTS_EMPTY } from 'config/routes';
import { Link } from 'react-router-dom';
import { MenuLocationsTooltip } from './MenuLocationsTooltip';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { isDefined } from 'lib/typeguards';
import { MenuSummary } from '@pepperhq/menu-sdk';
import { useMenuManagerFilter } from './filter/useMenuManagerFilter';
interface IProps {
    isLoading: boolean;
    menuSummaryList: MenuSummary[];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`.${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        padding: 0
    }
}));

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    width: '100%',
    display: 'none',
    color: theme.palette.text.primary,
    '.MuiDataGrid-row:hover &': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '&:hover': {
        color: theme.palette.primary.main
    }
}));

export const MenuManagerTable: React.FC<IProps> = ({ menuSummaryList, isLoading }) => {
    const { locationIdToLocationName } = useLocationHelpers();
    const [activeLocationPicker, setActiveLocationPicker] = React.useState<string>(null);

    const [filter, handleFilterChange] = useMenuManagerFilter();

    const handleClickLocationsCell = React.useCallback(
        (id?: string) => () => setActiveLocationPicker(id),
        []
    );

    const renderLocationsCell = React.useCallback(
        (params: GridRenderCellParams) => {
            const [firstLocationId] = params.value as MenuSummary['locationIds'];
            const locationCount = (params.value as MenuSummary['locationIds']).length;

            return (
                <div>
                    <StyledTooltip
                        disableFocusListener
                        disableHoverListener
                        open={activeLocationPicker === `${params.row.menuId}-${params.row.menuChangeId}`}
                        arrow
                        title={
                            <MenuLocationsTooltip
                                onClose={handleClickLocationsCell()}
                                locationIds={params.value as MenuSummary['locationIds']}
                            />
                        }
                    >
                        <Button
                            sx={{ textTransform: 'initial', justifyContent: 'flex-start' }}
                            variant="text"
                            onClick={handleClickLocationsCell(
                                `${params.row.menuId}-${params.row.menuChangeId}`
                            )}
                        >
                            {locationCount > 1
                                ? `${locationCount} Locations`
                                : locationIdToLocationName.get(firstLocationId)}
                        </Button>
                    </StyledTooltip>
                </div>
            );
        },
        [activeLocationPicker, handleClickLocationsCell, locationIdToLocationName]
    );

    const locationSortComparator = React.useCallback(
        (location1: string[], location2: string[]) => {
            const l1 =
                location1.length > 1
                    ? `${location1.length} Locations`
                    : locationIdToLocationName.get(location1[0]);
            const l2 =
                location2.length > 1
                    ? `${location2.length} Locations`
                    : locationIdToLocationName.get(location2[0]);

            if (!l1) {
                return 1;
            }

            if (!l2) {
                return -1;
            }

            return l1.localeCompare(l2);
        },
        [locationIdToLocationName]
    );

    const renderEditCell = React.useCallback((params: GridRenderCellParams) => {
        const [locationId] = params.row.locationIds;
        const pathname = MENU_MANAGER_ENRICHMENTS_EMPTY.replace(':menuId', params.row.menuId)
            .replace(':locationId', locationId)
            .replace(':menuChangeId', params.row.menuChangeId);
        return (
            <Button sx={{ textTransform: 'initial' }} variant="text">
                <StyledLink to={{ pathname }}>Edit Menu</StyledLink>
            </Button>
        );
    }, []);

    const getUniqueKey = React.useCallback(
        (row: GridValidRowModel) => `${row.menuId}-${row.menuChangeId}`,
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Menu ID',
                field: 'menuId',
                disableReorder: true,
                disableExport: true,
                sortable: true,
                width: 300
            },
            {
                headerName: 'Menu Name',
                field: 'menuDescription',
                disableReorder: true,
                disableExport: true,
                width: 400,
                sortable: true
            },
            {
                headerName: 'Locations',
                field: 'locationIds',
                disableReorder: true,
                disableExport: true,
                width: 240,
                sortable: true,
                sortComparator: locationSortComparator,
                renderCell: renderLocationsCell
            },
            {
                headerName: 'Menu Change ID',
                field: 'menuChangeId',
                disableReorder: true,
                disableExport: true,
                sortable: true,
                width: 240
            },
            {
                headerName: '',
                field: 'Edit',
                disableColumnMenu: true,
                disableReorder: true,
                disableExport: true,
                hideable: false,
                width: 100,
                sortable: false,
                renderCell: renderEditCell
            }
        ],
        [renderLocationsCell, renderEditCell, locationSortComparator]
    );
    const filteredMenuSummaries = React.useMemo(
        () =>
            menuSummaryList.filter(summary =>
                Object.entries(filter)
                    .filter(([, value]) => isDefined(value))
                    .every(([key, value]: [keyof MenuSummaryFilter, string]) => {
                        let meta = {};

                        if (key === 'search') {
                            meta = { locationIdToLocationName };
                        }
                        return filterKeyToFilterChecker[key](summary, value, meta);
                    })
            ),
        [filter, locationIdToLocationName, menuSummaryList]
    );

    return (
        <Box height="100%" pb={8}>
            <Box paddingBottom={1}>
                <MenuManagerTableFilter onFilterChange={handleFilterChange} filter={filter} />
            </Box>
            <MuiGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: { menuId: false, menuChangeId: false }
                    }
                }}
                rows={filteredMenuSummaries}
                columns={columns}
                hideFooter={false}
                hideFooterRowCount
                loading={isLoading}
                getRowId={getUniqueKey}
            />
        </Box>
    );
};

import { RequestOptions } from 'lib/HttpClient';
import React from 'react';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

export enum EOrderStatusFilter {
    Any = 'ANY',
    Open = 'OPEN',
    Closed = 'CLOSED'
}

export const orderStatusToFilter = {
    [EOrderStatusFilter.Any]: 'Any Status',
    [EOrderStatusFilter.Closed]: 'Closed Orders',
    [EOrderStatusFilter.Open]: 'Open Orders'
};

export interface PepperOrdersFilter {
    scenario?: string;
    status?: EOrderStatusFilter;
    locationId?: string;
    date?: DateRangePickerValue;
    customerId?: string;
    customerCredential?: string;
    deliveryLocation?: string;
    orderId?: string;
}

const pepperOrderFilterKeys: Record<keyof PepperOrdersFilter, boolean> = {
    scenario: true,
    status: true,
    locationId: true,
    date: true,
    customerId: true,
    customerCredential: true,
    deliveryLocation: true,
    orderId: true
};

export function isPepperOrderFilterKey(item: string): item is keyof PepperOrdersFilter {
    return item in pepperOrderFilterKeys;
}

type PepperOrdersFilterValue = string | DateRangePickerValue;
export type PepperOrdersFilterChange = (
    field: keyof PepperOrdersFilter,
    value: PepperOrdersFilterValue
) => void;

export function useOrdersFilter(): [PepperOrdersFilter, PepperOrdersFilterChange] {
    const { search, onSearch } = useHistoryFilters();
    const [filter, setFilter] = React.useState<PepperOrdersFilter>(() => {
        const defaultDate = Object.keys(search).length ? undefined : new Date();
        return {
            scenario: search.scenario || undefined,
            status: search.status || undefined,
            locationId: search.locationId || undefined,
            date: {
                startDate: search.startDate ? new Date(search.startDate) : defaultDate,
                endDate: search?.endDate ? new Date(search.endDate) : undefined
            },
            customerId: search.customerId || undefined,
            deliveryLocation: search.deliveryLocation || undefined,
            customerCredential: search.customerCredential || undefined,
            orderId: search.orderId || undefined
        };
    });
    const onFilterChange = React.useCallback<PepperOrdersFilterChange>((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { date, ...rest } = filter;
        onSearch({ ...rest, startDate: date?.startDate, endDate: date?.endDate });
    }, [filter, onSearch]);
    return [filter, onFilterChange];
}

export const getOrdersFilterQuery = (filter: PepperOrdersFilter) => {
    if (filter.orderId) {
        return [{ key: 'orderId', value: filter.orderId }];
    }
    const query: RequestOptions['queryParameters'] = [{ key: 'sort', value: '-id' }];
    if (filter.scenario) {
        query.push({ key: 'scenario', value: filter.scenario });
    }
    if (filter.status && filter.status !== EOrderStatusFilter.Any) {
        let isOpen = 'false';
        if (filter.status === EOrderStatusFilter.Open) {
            isOpen = 'true';
        }
        query.push({ key: 'isOpen', value: isOpen });
    }
    if (filter.locationId) {
        query.push({ key: 'locationId', value: filter.locationId });
    }
    if (filter.customerId) {
        query.push({ key: 'userId', value: filter.customerId });
    }
    if (filter.deliveryLocation) {
        query.push({ key: 'deliveryLocation', value: filter.deliveryLocation });
    }
    if (filter.customerCredential) {
        query.push({ key: 'userCredential', value: filter.customerCredential });
    }
    if (filter.date?.startDate) {
        query.push({ key: 'createdDate.gte', value: formatISO(startOfDay(filter.date.startDate)) });
    }
    if (filter.date?.endDate) {
        query.push({ key: 'createdDate.lt', value: formatISO(endOfDay(filter.date.endDate)) });
    }
    if (filter.date?.startDate && !filter.date?.endDate) {
        query.push({ key: 'createdDate.lt', value: formatISO(endOfDay(filter.date.startDate)) });
    }
    return query;
};

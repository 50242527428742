import React from 'react';
import { Button } from '@mui/material';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import {
    ACTION_TYPES_WITHOUT_LOCATION,
    getActionTypeOptions,
    LOCATION_INDEPENDENT_ACTION_TYPES
} from 'components/actions/utils';
import { CheckboxPlainPicker } from 'lib/checkbox-picker/CheckboxPlainPicker';
import { ActionType } from 'components/actions/models/Action';

interface IActionTypesDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (selectedOptions: string[]) => void;
    selectedOptions: string[];
    hasLocationsSelected?: boolean;
}

const ACTION_TYPE_OPTIONS = getActionTypeOptions(false);

const ActionTypesDialogContent: React.FC<Omit<IActionTypesDialogProps, 'open'>> = ({
    onSubmit,
    onClose,
    selectedOptions,
    hasLocationsSelected
}) => {
    const [selectedActionTypeValues, setSelectedActionTypeValues] = React.useState<string[]>(selectedOptions);
    const finalActionTypeOptions = React.useMemo(
        () =>
            ACTION_TYPE_OPTIONS.filter(option => {
                const actionType = option.value as ActionType;
                return !(
                    ((!hasLocationsSelected && !ACTION_TYPES_WITHOUT_LOCATION.includes(actionType)) ||
                        (hasLocationsSelected && ACTION_TYPES_WITHOUT_LOCATION.includes(actionType))) &&
                    !LOCATION_INDEPENDENT_ACTION_TYPES.includes(actionType)
                );
            }),
        [hasLocationsSelected]
    );

    const handleItemToggle = React.useCallback((values: string[]) => {
        setSelectedActionTypeValues(values);
    }, []);

    const handleSubmit = React.useCallback(() => {
        onSubmit(selectedActionTypeValues);
    }, [onSubmit, selectedActionTypeValues]);

    return (
        <>
            <CheckboxPlainPicker
                options={finalActionTypeOptions}
                value={selectedActionTypeValues}
                searchPlaceholder="Search Action Type"
                onChange={handleItemToggle}
                disableSelectAll
            />
            <Row flex={1} align="flex-end">
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit}>
                    Confirm
                </Button>
            </Row>
        </>
    );
};

export const ActionTypesDialog: React.FC<IActionTypesDialogProps> = props => {
    const { open, onClose } = props;
    return (
        <MuiModal open={open} onClose={onClose}>
            <ActionTypesDialogContent {...props} />
        </MuiModal>
    );
};

import React from 'react';
import { Column, Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { ImagePickerPreview } from './ImagePickerPreview';
import { Button, styled, Typography } from '@mui/material';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { isDefined } from 'lib/typeguards';

interface ImagePickerPreviewModalProps {
    open: boolean;
    onClose: () => void;
    file?: File;
    aspect?: number;
    src?: string;
    loading?: boolean;
    disabled?: boolean;
    isCropAvailable?: boolean;
    error?: string;
    onCropStart: () => void;
    onSubmit: () => void;
}

const StyledRow = styled(Row)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    width: '100%'
}));

const StyledImage = styled('div')<{ src?: string; aspect?: number }>(({ src, aspect }) => {
    const commonProps = {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat'
    };
    if (isDefined(aspect)) {
        const aspectProps = {
            border: '1px rgba(0, 0, 0, 0.5) solid',
            backgroundSize: 'cover'
        };
        if (aspect === 1) {
            return {
                ...commonProps,
                ...aspectProps,
                width: '500px',
                height: '500px'
            };
        }
        if (aspect < 1) {
            return {
                ...commonProps,
                ...aspectProps,
                height: '600px',
                paddingLeft: `${aspect * 100}%`
            };
        }
        return {
            ...commonProps,
            ...aspectProps,
            width: '700px',
            paddingTop: `${700 / aspect}px`
        };
    }
    return {
        ...commonProps,
        width: '100%',
        height: '50vh',
        maxHeight: '450px'
    };
});

export const ImagePickerPreviewModal: React.FC<ImagePickerPreviewModalProps> = ({
    open,
    onClose,
    file,
    aspect,
    src,
    loading,
    disabled,
    isCropAvailable,
    error,
    onCropStart,
    onSubmit
}) => {
    if (!file || !src) {
        return null;
    }
    return (
        <MuiModal open={open} style={{ maxWidth: 800 }} onClose={onClose}>
            <Column align="center">
                <ImagePickerPreview src={src} aspect={aspect} />
                {!src && <StyledImage src={src} aspect={aspect} />}
                <StyledRow flex={1} align="space-between" valign="center">
                    {isCropAvailable && (
                        <Button color="secondary" onClick={onCropStart} variant="outlined">
                            Crop
                        </Button>
                    )}
                    <Typography color="error" sx={{ width: '100%', display: 'inline', px: 1 }}>
                        {error}
                    </Typography>
                    <Row align="flex-end" gutter>
                        <Button color="secondary" onClick={onClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            onClick={onSubmit}
                            loading={loading}
                            disabled={disabled}
                        >
                            Submit
                        </LoadingButton>
                    </Row>
                </StyledRow>
            </Column>
        </MuiModal>
    );
};

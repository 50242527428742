import React from 'react';
import { useDispatch } from 'react-redux';
import { MESSAGE_CUSTOMER_CREDIT_ERROR, MESSAGE_CUSTOMER_CREDIT_SUCCESS } from 'config/messages';
import { LegacyForm } from 'lib/LegacyForm';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { AutoForm } from 'ui/form/AutoForm';
import { customerApi } from '../customerApi';
import { createCustomerCreditForm } from '../forms/createCustomerCreditForm';
import { Customer } from '../models/Customer';

interface CustomerCreditFormProps {
    customer: Customer;
    onClose: () => void;
    currencySymbol: string;
    onCreditSuccess: (value: number) => void;
}

export const CustomerCreditForm: React.FC<CustomerCreditFormProps> = props => {
    const { customer, onClose, onCreditSuccess, currencySymbol } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = React.useState(false);
    const handleSubmit = async ({ balance, message }: { balance: string; message: string }) => {
        setLoading(true);
        const result = await customerApi.credit(customer._id, {
            body: { number: Number(balance), description: message, scheme: 'BALANCE' }
        });
        setLoading(false);
        if (!result.ok) {
            return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CREDIT_ERROR, { variant: 'error' }));
        }
        onCreditSuccess(Number(balance));
        onClose();
        return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CREDIT_SUCCESS, { variant: 'success' }));
    };
    const form = new LegacyForm(createCustomerCreditForm(currencySymbol), handleSubmit);
    return (
        <AutoForm
            subtitle="Gift your user some money for them to spend when they're making purchases."
            isLoading={isLoading}
            submitLabel="Credit"
            form={form}
            title={`Credit ${customer.firstName} with some balance`}
            onClose={onClose}
        />
    );
};

import { Box, Button, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Row } from 'ui/Flex';
import { LoyaltyTier } from '../model/loyalty-tier';
import { UserMetricDefinition, UserMetricListener, UserMetricListenerCondition } from '../model';
import { TieredLoyaltyTierRow } from '../tiered-loyalty/TieredLoyaltyTierRow';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTieredLoyaltyTiersRequests } from '../tiered-loyalty/hooks/useTieredLoyaltyTiersRequests';
import { AudienceCreateModal } from 'components/audience/modals/AudienceCreateModal';
import { Audience } from 'components/audience/models/segmentModel';
import { TieredLoyaltyTierAudienceSelect } from '../tiered-loyalty/TieredLoyaltyTierAudienceSelect';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';

const StyledAndContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 50%)',
    padding: theme.spacing(0, 2),
    color: theme.palette.divider
}));
const StyledConditionsContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    position: 'relative'
}));
const StyledFormContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.background.paper
}));

interface TieredLoyaltyTierModalProps {
    open: boolean;
    onClose: () => void;
    tier?: LoyaltyTier;
    tiers?: LoyaltyTier[];
    metricDefinition?: UserMetricDefinition;
    onConditionAdd: (value: UserMetricDefinition, newAudienceId?: string) => void;
}

export const TieredLoyaltyTierModal: React.FC<TieredLoyaltyTierModalProps> = ({
    onClose,
    tier,
    metricDefinition,
    tiers = [],
    onConditionAdd
}) => {
    const { customerSegments } = useSelector((state: ApplicationState) => state.audiences);
    const { locations } = useSelector((state: ApplicationState) => state.locations);
    // ==========
    const locationOptions = React.useMemo(
        () => locations.map(item => ({ label: item.title, value: item._id })),
        [locations]
    );
    // ==========Audience input==============
    const [audienceId, setAudienceId] = React.useState<string>(tier?.id);
    const audienceOptions = React.useMemo(
        () =>
            customerSegments?.map(item => ({ label: `${item.title} [${item._id}]`, value: item._id })) ?? [],
        [customerSegments]
    );
    const handleAudienceChange = React.useCallback((value: string) => {
        setAudienceId(value);
    }, []);
    const audiencesToExclude = React.useMemo(() => tiers.map(item => item.id), [tiers]);
    // ========================
    const [createAudienceOpen, setCreateAudienceOpen] = React.useState(false);
    const handleAudienceCreateOpen = React.useCallback(() => {
        setCreateAudienceOpen(true);
    }, []);
    const handleAudienceCreateSuccess = React.useCallback((audience: Audience) => {
        setAudienceId(audience._id);
        setCreateAudienceOpen(false);
    }, []);
    const handleAudienceCreateClose = React.useCallback(() => {
        setCreateAudienceOpen(false);
    }, []);
    // =========================================
    const {
        loading,
        handleDelete,
        handleSubmitToAdd,
        handleSubmitToRemove,
        deleteModalOpen,
        handleDeleteModalClose,
        handleDeleteModalConfirm
    } = useTieredLoyaltyTiersRequests(onConditionAdd, metricDefinition, tier, audienceId);
    // =========================================
    const isOnlyMetricCondition = React.useCallback(
        (item: UserMetricListenerCondition, type: string) =>
            'metricId' in item &&
            (type === 'ADD_TO_SEGMENT'
                ? tier?.metricAddConditions?.length === 1
                : tier?.metricRemoveConditions?.length === 1),
        [tier?.metricAddConditions?.length, tier?.metricRemoveConditions?.length]
    );
    const renderCondition = React.useCallback(
        (listenerId: string, type: string) => (item: UserMetricListenerCondition) =>
            (
                <TieredLoyaltyTierRow
                    key={item._id}
                    item={item}
                    audienceOptions={audienceOptions}
                    locationOptions={locationOptions}
                    metrics={metricDefinition?.metrics}
                    onDelete={handleDelete(listenerId)}
                    loading={loading}
                    disableDelete={isOnlyMetricCondition(item, type)}
                />
            ),
        [
            audienceOptions,
            handleDelete,
            isOnlyMetricCondition,
            loading,
            locationOptions,
            metricDefinition?.metrics
        ]
    );
    const renderListener = React.useCallback(
        (item: UserMetricListener) => item.conditions.map(renderCondition(item._id, item.type)),
        [renderCondition]
    );
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        {tier ? 'Edit tier' : 'New tier'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    <TieredLoyaltyTierAudienceSelect
                        value={audienceId}
                        onChange={handleAudienceChange}
                        loading={loading}
                        readOnly={!!tier}
                        onCreateAudience={handleAudienceCreateOpen}
                        options={audienceOptions}
                        excludeAudiences={audiencesToExclude}
                    />
                </Grid>
                {!audienceId && (
                    <Grid item xs={12}>
                        <Typography>Select an audience to continue with tier creation</Typography>
                    </Grid>
                )}
                {!!audienceId && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <StyledConditionsContainer>
                                <Typography sx={{ mb: 1 }}>Add conditions</Typography>
                                {tier?.addListeners?.map(renderListener)}
                                {!tier?.addListeners?.length && (
                                    <Row valign="center">
                                        <ErrorOutlineIcon sx={{ mr: 1, fontSize: '28px' }} />
                                        <Typography>
                                            You have no add conditions yet. If you leave it blank, no
                                            customers will be added to this tier.
                                        </Typography>
                                    </Row>
                                )}
                                {!!tier?.addListeners?.length && (
                                    <StyledAndContainer>
                                        <Typography>AND</Typography>
                                    </StyledAndContainer>
                                )}
                            </StyledConditionsContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormContainer>
                                <TieredLoyaltyTierRow
                                    metrics={metricDefinition?.metrics}
                                    audienceOptions={audienceOptions}
                                    locationOptions={locationOptions}
                                    onSubmit={handleSubmitToAdd}
                                    loading={loading}
                                    metricOnly={!tier?.metricAddConditions?.length}
                                    key={`add-${metricDefinition?.updatedAt}`}
                                />
                            </StyledFormContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledConditionsContainer>
                                <Typography sx={{ mb: 1 }}>Remove conditions</Typography>
                                {tier?.removeListeners?.map(renderListener)}
                                {!tier?.removeListeners?.length && (
                                    <Row valign="center">
                                        <ErrorOutlineIcon sx={{ mr: 1, fontSize: '28px' }} />
                                        <Typography>
                                            You have no remove conditions yet. Please add one to ensure the
                                            customer isn&apos;t stuck in this tier.
                                        </Typography>
                                    </Row>
                                )}
                                {!!tier?.removeListeners?.length && (
                                    <StyledAndContainer>
                                        <Typography>AND</Typography>
                                    </StyledAndContainer>
                                )}
                            </StyledConditionsContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormContainer>
                                <TieredLoyaltyTierRow
                                    metrics={metricDefinition?.metrics}
                                    audienceOptions={audienceOptions}
                                    locationOptions={locationOptions}
                                    onSubmit={handleSubmitToRemove}
                                    loading={loading}
                                    metricOnly={!tier?.metricRemoveConditions?.length}
                                    key={`remove-${metricDefinition?.updatedAt}`}
                                />
                            </StyledFormContainer>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12}>
                    <Row flex={1} align="flex-end">
                        <Button onClick={onClose} color="secondary" variant="outlined">
                            Close
                        </Button>
                    </Row>
                </Grid>
            </Grid>
            <AudienceCreateModal
                open={createAudienceOpen}
                onClose={handleAudienceCreateClose}
                onSuccess={handleAudienceCreateSuccess}
            />
            <DeleteDialog
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                onDelete={handleDeleteModalConfirm}
                title="Deleting condition"
                content="This change will affect a live Tier Scheme, are you sure?"
            />
        </React.Fragment>
    );
};

import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';

interface PrefixInputProps extends NumericFormatProps {
    inputRef: NumericFormatProps['getInputRef'];
    prefix?: string;
    onChange?: (e: any) => void;
}

function PrefixInput(props: PrefixInputProps) {
    const { inputRef, onChange, ...other } = props;

    const handleChange = React.useCallback(
        values => {
            onChange({
                target: {
                    value: values.value,
                    name: other.name
                }
            });
        },
        [onChange, other.name]
    );

    return <NumericFormat {...other} getInputRef={inputRef} onValueChange={handleChange} thousandSeparator />;
}

interface PrefixInputFormFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    prefix: string;
    description?: string;
    type?: string;
}

export const PrefixInputFormField: React.SFC<FieldAttributes<PrefixInputFormFieldProps>> = ({
    label,
    placeholder,
    description,
    prefix,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <PrefixInputFormFieldComponent
                label={label}
                placeholder={placeholder}
                prefix={prefix}
                type={props.type}
                description={description}
                {...childProps}
            />
        )}
    </Field>
);

const PrefixInputFormFieldComponent: React.SFC<FieldProps & PrefixInputFormFieldProps> = ({
    field,
    meta,
    label,
    type,
    placeholder,
    description,
    prefix
}) => (
    <TextField
        fullWidth
        placeholder={placeholder}
        value={field.value}
        label={label}
        name={field.name}
        onChange={field.onChange}
        type={type}
        inputProps={{ prefix }}
        InputProps={{
            inputComponent: PrefixInput
        }}
        onBlur={field.onBlur}
        error={meta.touched && !!meta.error}
        helperText={(meta.touched && !!meta.error && meta.error) || description}
    />
);

import React from 'react';
import { useDispatch } from 'react-redux';
import { ListItemButton, styled, Typography } from '@mui/material';
import { copyToClipboard } from 'lib/helpers';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

const PREFIX = 'ActionExportErrorMessage';

const classes = {
    root: `${PREFIX}-root`,
    text: `${PREFIX}-text`
};

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: theme.spacing(1),
        borderBottom: `1px dotted ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 'none'
        }
    },

    [`& .${classes.text}`]: {
        fontSize: '0.85rem'
    }
}));

interface IActionExportErrorMessageProps {
    message: string;
}

const ActionExportErrorMessagePure = ({ message }: IActionExportErrorMessageProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleClick = React.useCallback(() => {
        dispatch(enqueueSnackbar(`"${message}" copied to the clipboard`, { variant: 'default' }));
        copyToClipboard(message);
    }, [message, dispatch]);
    return (
        <StyledListItem onClick={handleClick} className={classes.root}>
            <Typography variant="caption" className={classes.text}>
                {message}
            </Typography>
        </StyledListItem>
    );
};

export const ActionExportErrorMessage = React.memo(
    ActionExportErrorMessagePure,
    (l, r) => l.message !== r.message
);

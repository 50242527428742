import { createReducer } from 'redux-create-reducer';
import { Audience, AudiencesPage } from 'components/audience/models/segmentModel';
import { CustomerSegment } from 'components/customers/models/Customer';
import {
    AudiencesCreateAction,
    AudiencesGetCustomerSegmentsAction,
    AudiencesGetErrorAction,
    AudiencesGetListAction,
    AudiencesRequestAction
} from './audiencesActions';
import { AudiencesActionTypes } from './audiencesActionTypes';

export interface AudiencesState {
    audiences: Audience[];
    isLoading: boolean;
    summary: AudiencesPage;
    customerSegments: CustomerSegment[];
}

type AudiencesAction =
    | AudiencesRequestAction
    | AudiencesGetErrorAction
    | AudiencesGetListAction
    | AudiencesCreateAction
    | AudiencesGetCustomerSegmentsAction;

type AudienceActionHandlers = {
    [key in AudiencesActionTypes]: (state: AudiencesState, action?: AudiencesAction) => AudiencesState;
};

const defaultSummary = { limit: 0, count: 0 };

export const initialState: AudiencesState = {
    audiences: [],
    customerSegments: [],
    summary: defaultSummary,
    isLoading: false
};

const actionHandlers: AudienceActionHandlers = {
    [AudiencesActionTypes.START_REQUEST]: state => ({ ...state, isLoading: true }),
    [AudiencesActionTypes.END_REQUEST]: state => ({ ...state, isLoading: false }),
    [AudiencesActionTypes.GET_AUDIENCES_ERROR]: state => ({ ...state, audiences: [] }),
    [AudiencesActionTypes.GET_AUDIENCES_SUCCESS]: (
        state,
        { audiences, summary }: AudiencesGetListAction
    ) => ({ ...state, summary, audiences }),
    [AudiencesActionTypes.LOAD_AUDIENCES_SUCCESS]: (
        state,
        { audiences, summary }: AudiencesGetListAction
    ) => ({
        ...state,
        summary,
        audiences: [...state.audiences, ...audiences]
    }),
    [AudiencesActionTypes.CREATE_AUDIENCE_SUCCESS]: (state, { audience }: AudiencesCreateAction) => {
        const newCustomerSegment: CustomerSegment = {
            _id: audience._id,
            title: audience.title,
            included: false
        };
        const customerSegments: CustomerSegment[] = [...state.customerSegments, newCustomerSegment];
        if (state.summary && !state.summary.nextKey) {
            return { ...state, customerSegments, audiences: [...state.audiences, audience] };
        }
        return { ...state, customerSegments };
    },
    [AudiencesActionTypes.GET_CUSTOMER_SEGMENTS_SUCCESS]: (
        state,
        { customerSegments }: AudiencesGetCustomerSegmentsAction
    ) => ({
        ...state,
        customerSegments
    }),
    [AudiencesActionTypes.GET_CUSTOMER_SEGMENTS_ERROR]: state => ({ ...state, customerSegments: [] })
};

export default createReducer(initialState, actionHandlers);

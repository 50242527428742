import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class SessionApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'sessions', { 'x-api-version': 4 });
    }

    deleteSessions(id: string) {
        return this.httpClient.delete(`${this.api}/sessions/${id}`, {
            headers: { 'x-api-version': 4 }
        });
    }
}

export const sessionApi = new SessionApi(httpClient);

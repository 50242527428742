import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const RevelSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Dining Option">
                    <TextFormField name="revelDiningOption" />
                </SettingDisplay>
                <SettingDisplay label="Tax Inclusive">
                    <SwitchFormField name="revelTaxInclusive" />
                </SettingDisplay>
                <SettingDisplay label="Account Name">
                    <TextFormField name="revelAccountName" />
                </SettingDisplay>
                <SettingDisplay label="API Key">
                    <TextFormField name="revelApiKey" />
                </SettingDisplay>
                <SettingDisplay label="Payment Type">
                    <NumberFormField min={0} name="revelPaymentType" />
                </SettingDisplay>
                <SettingDisplay label="Dynamic Discounts">
                    <SwitchFormField name="revelDynamicDiscounts" />
                </SettingDisplay>
                <SettingDisplay label="Use Revel Stock Availability">
                    <SwitchFormField name="revelStockAvailability" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Establishment ID">
                    <TextFormField name="revelEstablishmentId" />
                </SettingDisplay>
                <SettingDisplay label="Dynamic Discounts">
                    <SwitchFormField name="revelDynamicDiscounts" />
                </SettingDisplay>
                <SettingDisplay label="Use Revel Stock Availability">
                    <SwitchFormField name="revelStockAvailability" />
                </SettingDisplay>
            </>
        )}
    </>
);

import React, { CSSProperties } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Typography,
    styled
} from '@mui/material';
import { AwardTemplate } from 'components/customers/models/Award';
import { valignStyle, VerticalAlignment } from 'components/loyalty/models/PerkModel';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { rgbaToHex } from 'lib/helpers';
import { Column, Row } from 'ui/Flex';
import { AdhocPerkPreviewEditable } from './AdhocPerkPreviewEditable';
import { PseudoCurPerkPreviewEditable } from './PseudoCurPerkPreviewEditable';
import { StampCardPerkPreviewEditable } from './StampCardPerkPreviewEditable';
import { useSomethingNewPopupState } from 'lib/hooks/useSomethingNewPopupState';
import { ELocalStorageKey, ESessionStorageKey } from 'config/storageKeys';
import { EGAEventName, useGAHelpers } from 'lib/hooks/useGAHelper';

export const perkPreviewHeightByType: Record<AwardTemplate, number> = {
    [AwardTemplate.AD_HOC]: 40,
    [AwardTemplate.PSEUDO_CURRENCY]: 40,
    [AwardTemplate.CLUB]: 40,
    [AwardTemplate.LEVEL]: 30,
    [AwardTemplate.STAMP_CARD]: 25
};

export const perkPreviewWidthByType: Record<AwardTemplate, number> = {
    [AwardTemplate.AD_HOC]: 60,
    [AwardTemplate.PSEUDO_CURRENCY]: 60,
    [AwardTemplate.CLUB]: 60,
    [AwardTemplate.LEVEL]: 45,
    [AwardTemplate.STAMP_CARD]: 37.5
};

export const perkPreviewFontSizesByType: Record<
    AwardTemplate,
    { title: number; subtitle: number; subtitleTwo: number }
> = {
    [AwardTemplate.AD_HOC]: { title: 45, subtitle: 28, subtitleTwo: 20 },
    [AwardTemplate.PSEUDO_CURRENCY]: { title: 45, subtitle: 29, subtitleTwo: 21 },
    [AwardTemplate.CLUB]: { title: 45, subtitle: 28, subtitleTwo: 20 },
    [AwardTemplate.LEVEL]: { title: 34, subtitle: 22, subtitleTwo: 16 },
    [AwardTemplate.STAMP_CARD]: { title: 29, subtitle: 19, subtitleTwo: 14 }
};

const PREFIX = 'PerkPreviewEditable';

const classes = {
    background: `${PREFIX}-background`
};

const stylePurposeProps: PropertyKey[] = ['backgroundColor', 'perkType'];

const StyledColumn: any = styled(Column, {
    shouldForwardProp: propName => !stylePurposeProps.includes(propName)
})<PerkPreviewEditableProps>(({ theme, perkType, backgroundColor }) => ({
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    height: theme.spacing(perkPreviewHeightByType[perkType]),
    width: theme.spacing(perkPreviewWidthByType[perkType]),
    backgroundColor: '#888888',
    [`& .${classes.background}`]: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: rgbaToHex(backgroundColor, true)
    }
}));

type PerkPreviewEditableProps = {
    backgroundColor?: RGBAValue;
    image?: string;
    valign: VerticalAlignment;
    title: string;
    titleText?: string;
    textColor: RGBAValue;
    editable?: boolean;
    label?: string;
    cardAvailable?: boolean;
    textAlign: CSSProperties['textAlign'];
} & (
    | {
          perkType: AwardTemplate.PSEUDO_CURRENCY;
          stampColor: RGBAValue;
      }
    | {
          perkType: AwardTemplate.STAMP_CARD;
          stampColor: RGBAValue;
          stampImageUrl?: string;
          stampsPerCard: number;
          randomizeStampCard?: boolean;
      }
    | {
          perkType: AwardTemplate.AD_HOC | AwardTemplate.LEVEL | AwardTemplate.CLUB | undefined;
      }
);

export const PerkPreviewEditable: React.FC<PerkPreviewEditableProps> = props => {
    const { backgroundColor, image, valign, label, ...rest } = props;
    const isPseudocurrency = rest.perkType === AwardTemplate.PSEUDO_CURRENCY;
    const isStampCard = rest.perkType === AwardTemplate.STAMP_CARD;
    const [titleClicked, setTitleClicked] = React.useState(false);
    const [doNotShowAgain, setDoNotShowAgain] = React.useState(false);
    const { logUserEvent } = useGAHelpers();
    const { setIsConfirmed, incrementCountShown, shouldShow } = useSomethingNewPopupState(
        ESessionStorageKey.PerkTitleVisualisationModal,
        ELocalStorageKey.PerkTitleVisualisationInfoShown
    );
    const handleCheckboxChange = React.useCallback((_: never, checked: boolean) => {
        setDoNotShowAgain(checked);
    }, []);
    const handleModalClose = React.useCallback(() => {
        logUserEvent(EGAEventName.PerkVisualisationTitleClick);
        setTitleClicked(false);
        incrementCountShown(doNotShowAgain);
        setIsConfirmed();
    }, [doNotShowAgain, incrementCountShown, logUserEvent, setIsConfirmed]);
    const handleTitleClick = React.useCallback(() => {
        setTitleClicked(true);
    }, []);
    const showModal = React.useMemo(() => titleClicked && shouldShow(), [shouldShow, titleClicked]);

    return (
        <Column>
            {!!label && (
                <Typography variant="body2" color="textSecondary">
                    {label}
                </Typography>
            )}
            <StyledColumn
                backgroundColor={backgroundColor}
                perkType={rest.perkType}
                valign={rest.perkType === AwardTemplate.STAMP_CARD ? 'flex-start' : valignStyle[valign]}
            >
                {image && <img className={classes.background} src={image} alt="" />}
                {!!backgroundColor && (
                    <div
                        className={classes.background}
                        style={{
                            backgroundColor:
                                typeof backgroundColor === 'string'
                                    ? backgroundColor
                                    : `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}${
                                          backgroundColor.a ? `, ${backgroundColor.a}` : ', 0'
                                      })`
                        }}
                    />
                )}
                {isPseudocurrency && (
                    <PseudoCurPerkPreviewEditable onTitleClick={handleTitleClick} {...rest} />
                )}
                {rest.perkType === AwardTemplate.STAMP_CARD && (
                    <StampCardPerkPreviewEditable onTitleClick={handleTitleClick} {...rest} />
                )}
                {!isPseudocurrency && !isStampCard && (
                    <AdhocPerkPreviewEditable onTitleClick={handleTitleClick} {...rest} />
                )}
            </StyledColumn>
            <Dialog open={showModal}>
                <DialogTitle>Just so you know</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        To be able to see this new visualisation title in the Pepper app, you need the latest
                        iOS and Android version. Contact Pepper support if you do not see this new title in
                        current app and therefore require a new build.
                    </DialogContentText>
                    <FormControlLabel
                        control={<Checkbox value={doNotShowAgain} onChange={handleCheckboxChange} />}
                        label="Do not show again"
                    />
                    <Row flex={1} align="flex-end">
                        <Button variant="contained" color="primary" onClick={handleModalClose}>
                            UNDERSTOOD
                        </Button>
                    </Row>
                </DialogContent>
            </Dialog>
        </Column>
    );
};

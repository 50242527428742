import { Box } from '@mui/material';
import React from 'react';
import { Row } from 'ui/Flex';

import { GridLocationFilter } from 'lib/grid/GridLocationFilter';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { ClerksDataFilter, ClerksDataFilterChange } from './hooks/useClerksFilter';
import { isString, isUndefined } from 'lib/typeguards';

interface ClerksDataFiltersProps {
    filter: ClerksDataFilter;
    onChange: ClerksDataFilterChange;
}

export const ClerksDataFilters: React.FC<ClerksDataFiltersProps> = ({ filter, onChange }) => {
    const handleTextChange = React.useCallback(
        (value: string, name: keyof ClerksDataFilter) => {
            if (isString(value) || isUndefined(value)) {
                onChange(name, value);
            }
        },
        [onChange]
    );
    const handleLocationChange = React.useCallback(
        (value?: string) => {
            onChange('locationId', value);
        },
        [onChange]
    );
    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginRight={1}>
                <GridFilterInput value={filter.name} onChange={handleTextChange} label="Name" name="name" />
            </Box>
            <Box>
                <GridLocationFilter value={filter.locationId} onChange={handleLocationChange} required />
            </Box>
        </Row>
    );
};

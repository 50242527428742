/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import React from 'react';
import { Grid } from '@mui/material';
import { LocationServices } from 'components/location/models/LocationModel';
import { CardViewSwitchRow } from 'ui/viewComponents';

interface LocationServicesCardComponentProps {
    services: LocationServices;
}

export const LocationServicesCardComponent = (props: LocationServicesCardComponentProps) => {
    const {
        services: {
            pre_order,
            order_to_table,
            order_to_table_nfc,
            pay_at_table,
            pay_at_table_nfc,
            tab,
            checkins,
            offline_checkin,
            book_table,
            book_room
        }
    } = props;
    return (
        <Grid container spacing={2} direction="row">
            <Grid item sm={6} xs={12}>
                <Grid container spacing={1}>
                    <CardViewSwitchRow title="Pre-Orders" value={pre_order} />
                    <CardViewSwitchRow title="Bar Tabs" value={tab} />
                    <CardViewSwitchRow title="Order to Table" value={order_to_table} />
                    <CardViewSwitchRow
                        title="Order to Table NFC Tags"
                        value={order_to_table_nfc}
                        tooltip="This enables users to use their (NFC enabled) phone to scan a tag, letting the app know what table they want to open an order at."
                    />
                    <CardViewSwitchRow title="Pay at Table" value={pay_at_table} />
                </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Grid container spacing={1}>
                    <CardViewSwitchRow title="Checkins" value={checkins} />
                    <CardViewSwitchRow
                        title="Barcode Checkins & Rewards"
                        value={offline_checkin}
                        tooltip="This enables users to checkin and use rewards using a barcode. This is useful when the location doesn't have a good internet connection."
                    />
                    <CardViewSwitchRow title="Table Bookings" value={book_table} />
                    <CardViewSwitchRow title="Room Bookings" value={book_room} />
                    <CardViewSwitchRow
                        title="Pay at Table NFC Tags"
                        value={pay_at_table_nfc}
                        tooltip="This enables users to use their (NFC enabled) phone to scan a tag, letting the app know what table they want to open an order at."
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

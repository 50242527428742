import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const WorldPaySettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => {
    if (locationId !== undefined) {
        return null;
    }
    return (
        <>
            <SettingDisplay label="Production Environment">
                <SwitchFormField name="worldpayIsProduction" />
            </SettingDisplay>
            <SettingDisplay label="Wait For Settlement">
                <SwitchFormField name="worldpayWaitForSettlement" />
            </SettingDisplay>
            <SettingDisplay label="Username">
                <TextFormField name="worldpayUsername" />
            </SettingDisplay>
            <SettingDisplay label="Password">
                <TextFormField name="worldpayPassword" />
            </SettingDisplay>
            <SettingDisplay label="Merchant ID">
                <TextFormField name="worldpayMerchantId" />
            </SettingDisplay>
            <SettingDisplay label="Checkout ID">
                <TextFormField name="worldpayCheckoutId" />
            </SettingDisplay>
            <SettingDisplay label="Google Pay Merchant ID">
                <TextFormField name="worldpayGooglePayMerchantId" />
            </SettingDisplay>
        </>
    );
};

import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { ITagGroup } from 'components/location/models/LocationModel';

interface IProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
    onClose: () => void;
    anchorEl: HTMLElement | null;
    tagGroup: ITagGroup;
    onEdit: (tagGroup: ITagGroup) => void;
    onDelete: (tagGroup: ITagGroup) => void;
}

export function LocationTagsActionsCell({ onClick, anchorEl, onClose, onEdit, onDelete, tagGroup }: IProps) {
    const handleEdit = React.useCallback(() => {
        onEdit(tagGroup);
    }, [onEdit, tagGroup]);

    const handleDelete = React.useCallback(() => {
        onDelete(tagGroup);
    }, [onDelete, tagGroup]);
    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            onClick(event, tagGroup.id);
        },
        [onClick, tagGroup.id]
    );

    return (
        <Box display="flex" flex={1} justifyContent="flex-end">
            <IconButton
                aria-haspopup="true"
                aria-controls={`tag-actions-menu-${tagGroup.id}`}
                onClick={handleClick}
                color="secondary"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={!!anchorEl}
                id={`tag-actions-menu-${tagGroup.id}`}
                keepMounted={false}
                onClose={onClose}
                anchorEl={anchorEl}
            >
                <MenuItem onClick={handleEdit}>Update</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Box>
    );
}

/* eslint-disable @typescript-eslint/naming-convention */
import { isString } from 'lib/typeguards';

export enum DaysOfTheWeek {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday'
}

export const DAYS_OF_THE_WEEK: DaysOfTheWeek[] = [
    DaysOfTheWeek.monday,
    DaysOfTheWeek.tuesday,
    DaysOfTheWeek.wednesday,
    DaysOfTheWeek.thursday,
    DaysOfTheWeek.friday,
    DaysOfTheWeek.saturday,
    DaysOfTheWeek.sunday
];

export enum LocationButtonActions {
    BROWSER = 'BROWSER',
    CHECKIN = 'CHECKIN',
    OPENING_TIMES = 'OPENING_TIMES',
    PREORDER = 'PREORDER',
    OPEN_TAB = 'OPEN_TAB',
    TABLE_ORDER = 'TABLE_ORDER',
    EVENTS = 'EVENTS'
}

export const locationActionToLabel = {
    [LocationButtonActions.BROWSER]: 'Browser',
    [LocationButtonActions.CHECKIN]: 'Check In',
    [LocationButtonActions.OPENING_TIMES]: 'Opening Times',
    [LocationButtonActions.PREORDER]: 'Preorder',
    [LocationButtonActions.OPEN_TAB]: 'Open tab',
    [LocationButtonActions.TABLE_ORDER]: 'Table Order',
    [LocationButtonActions.EVENTS]: 'Events'
};

export enum LocationState {
    ACTIVE = 'ACTIVE',
    RETIRED = 'RETIRED',
    PENDING = 'PENDING'
}

export type ILocationZones = Record<string, number[][]>;

export interface Location {
    _id: string;
    address: LocationAddress;
    alternativeOpeningHoursText?: string;
    beacons: any[];
    buttons: LocationButton[];
    contacts: LocationContacts;
    created: string;
    extended?: LocationExtended;
    facilities: string[];
    geo: number[];
    description: string;
    links: LocationLinks;
    listingImageUrl?: string;
    mainImageUrl?: string;
    mapPinImageUrl?: string;
    ordering: LocationOrdering;
    openingHours: LocationOpeningHours;
    regions?: any[];
    services: LocationServices;
    state: LocationState;
    tags: string[];
    tenantId: string;
    title: string;
    VATnumber: string;
    updated: string;
    timezone: string;
    zones?: ILocationZones;
}

export interface LocationOrderNote {
    title: string;
    description: string;
}

export interface LocationOrderingConfig {
    note?: LocationOrderNote[];
}
interface LocationOrdering {
    extendedFields?: Record<string, LocationOrderingConfig>;
}

export interface LocationAddress {
    address: string;
    country: string;
    fullAddress: string;
    googleMapsShortUrl: string;
    postcode: string;
    summary: string;
    town: string;
}

export interface LocationContacts {
    email: string;
    phone: string;
}

export interface LocationButton {
    title: string;
    style: 'PRIMARY' | 'SECONDARY';
    action: LocationButtonActions;
    properties: {
        url: string;
    };
}

export interface LocationLinks {
    citymapper?: LocationLink;
    facebook?: LocationLink;
    foursquare?: LocationLink;
    hailo?: LocationLink;
    twitter?: LocationLink;
    uber?: LocationLink;
    instagram?: LocationLink;
}

export interface LocationLink {
    enabled: boolean;
    parameters?: string;
}

export type LocationOpeningHours = {
    [key in DaysOfTheWeek]: string;
};

export interface LocationServices {
    book_room: boolean;
    book_table: boolean;
    checkins: boolean;
    offline_checkin: boolean;
    pre_order: boolean;
    tab: boolean;
    order_to_table: boolean;
    order_to_table_nfc: boolean;
    pay_at_table: boolean;
    pay_at_table_nfc: boolean;
}

export interface LocationExtended {
    bookRoomId: string;
    bookTableId: string;
    secretDjId: string;
}

export function isLocation(location: any): location is Location {
    return !!location && isString(location._id);
}

export function isLocations(locations: any[]): locations is Location[] {
    return !locations || !locations.length || isLocation(locations[0]);
}

export interface ITag {
    id: string;
    sort: number;
    title: string;
}

export interface ITagGroup {
    id: string;
    matchAll: boolean;
    sort: number;
    title: string;
    tags: Record<string, ITag>;
}

import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { contentApi } from 'components/content/contentApi';
import { ContentTemplateItem } from 'components/content/ContentTemplateItem';
import { LayoutConfigStepper } from 'components/content/layout-config/LayoutConfigStepper';
import { ContentTemplate } from 'components/content/models/ContentTemplate';
import { MOBILE_CONTENT } from 'config/routes';
import { Row } from 'ui/Flex';

export default () => {
    const [templates, setTemplates] = React.useState([] as ContentTemplate[]);
    React.useEffect(() => {
        async function load() {
            const response = await contentApi.templates.getList();
            setTemplates(response.body);
        }
        load();
    }, []);

    return (
        <MainLayout
            columnProps={{ align: 'center' }}
            breadcrumbs={[{ label: 'Mobile content', url: MOBILE_CONTENT }]}
            pageName="Template selection"
            pageDescription="Configure and manage what appears in your application"
        >
            <LayoutConfigStepper />
            <Row flex={1} align="center" wrap="wrap">
                {Array.isArray(templates) &&
                    templates.map((item, index) => (
                        <ContentTemplateItem template={item} key={`mobile-layout-${index}`} />
                    ))}
            </Row>
        </MainLayout>
    );
};

import { SystemRole } from '@pepperhq/auth-client';
import { JOIN } from 'config/routes';
import { Crud, HttpClient, httpClient, HttpClientResponse, QueryParameter } from 'lib/HttpClient';
import { TenantAccess } from './models/TenantAccess';
import { TenantAccessData } from './models/TenantAccessData';

class TenantAccessApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.USER_SERVICE_URL, 'access');
    }
    getList(options?: { [key: string]: string | number }): HttpClientResponse<TenantAccessData> {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        const optionsString = options
            ? Object.entries(options)
                  .map(([key, value]) => `${key}=${value}`)
                  .join('&')
            : '';
        return this.httpClient.get(`${this.api}/access?${optionsString}`, { headers });
    }
    createNewToken(accessId: string) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.post(`${this.api}/access/${accessId}/tokens`, { headers });
    }
    invite(body: { email: string; roleId: SystemRole; locationIds: string[] }) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.post(`${this.api}/access?inviteCallbackPath=${JOIN}`, { headers, body });
    }
    getListByTenant(queryParameters?: QueryParameter[]): HttpClientResponse<TenantAccessData> {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.get(`${this.api}/access/users`, { headers, queryParameters });
    }
    getRolesList() {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        return this.httpClient.get(`${this.api}/roles`, { headers });
    }
    revokeUserAccess(body: { id: string }): HttpClientResponse<TenantAccess> {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };

        return this.httpClient.delete(`${this.api}/access/users`, { headers, body });
    }
    updateUserAccess(id: string, body: { roleId: SystemRole; locationIds: string[] }) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };

        return this.httpClient.put(`${this.api}/access/${id}`, { headers, body });
    }
}

export const tenantAccessApi = new TenantAccessApi(httpClient);

/* eslint-disable max-len */
import React from 'react';
import {
    EPointOfSaleProvider,
    IPointOfSaleSettingsProps,
    itemMappingSchemeLabel
} from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { SettingDisplay } from '../../../../lib/SettingDisplay';
import { Chip } from '@mui/material';

export const AlohaSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    globalSettings,
    settings,
    locationId
}) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplayText
                    title="Omnivore NCR Aloha API Key"
                    variant="body1"
                    text={settings?.omnivore?.apiKey}
                    crop
                />
                <SettingDisplayText
                    title="Webhook Activation Code"
                    variant="body1"
                    text={settings?.omnivore?.webhookActivationCode}
                />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplayText title="Location ID" text={settings?.omnivore?.locationId} />
                <SettingDisplayText title="Employee ID" text={settings?.omnivore?.employeeId} />
                <SettingDisplayText title="Tender Type ID" text={settings?.omnivore?.tenderType} />
                <SettingDisplayText
                    title="Pre-Order Order Type ID"
                    text={settings?.omnivore?.PREORDER_orderType}
                />
                <SettingDisplayText
                    title="Pre-Order Revenue Center ID"
                    text={settings?.omnivore?.PREORDER_revenueCenter}
                />
                <SettingDisplayText
                    title="Order to Table Order Type ID"
                    text={settings?.omnivore?.ORDER_TO_TABLE_orderType}
                />
                <SettingDisplayText
                    title="Order to Table Revenue Center ID"
                    text={settings?.omnivore?.ORDER_TO_TABLE_revenueCenter}
                />
                <SettingDisplayText
                    title="Pay at Table Order Type ID"
                    text={settings?.omnivore?.TABLE_orderType}
                />
                <SettingDisplayText
                    title="Pay at Table Revenue Center ID"
                    text={settings?.omnivore?.TABLE_revenueCenter}
                />
                <SettingDisplayText
                    title="Menu Category Filter"
                    description="Comma delimited list of categories to include in the menu. If blank, all are returned."
                    text={settings?.omnivore?.menuCategoryFilter}
                />
                <SettingDisplayText
                    description="Will only use price level for item pricing otherwise will fall back to base price, if blank all price levels are returned."
                    title="Item Level Price"
                    text={settings?.omnivore?.onlyUsePriceLevel}
                />
                <SettingDisplayText
                    title="Item Mapping Scheme"
                    text={
                        settings?.omnivore?.itemMappingScheme
                            ? itemMappingSchemeLabel[settings?.omnivore?.itemMappingScheme]
                            : 'None'
                    }
                />
                {{ ...globalSettings, ...settings }.terminalAccessProvider ===
                    EPointOfSaleProvider.OMNIVORE_NCR_ALOHA && (
                    <>
                        <SettingDisplay
                            label="Manager Job IDs"
                            description="The IDs of the jobs representing clerks on the point of sale."
                        >
                            {(settings?.omnivore?.managerJobIds ?? []).map(jobId => (
                                <Chip key={jobId} label={jobId} color="primary" />
                            ))}
                        </SettingDisplay>
                        <SettingDisplay
                            label="Clerk Job IDs"
                            description="The IDs of the jobs representing clerks on the point of sale."
                        >
                            {(settings?.omnivore?.clerkJobIds ?? []).map(jobId => (
                                <Chip key={jobId} label={jobId} color="primary" />
                            ))}
                        </SettingDisplay>
                    </>
                )}
            </>
        )}
    </>
);

import React from 'react';
import { GridColTypeDef } from '@mui/x-data-grid-pro';
import { GridStringArrayColumn } from 'lib/MuiGrid/text/GridStringArrayColumn';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { AdvancedAvailabilityCell } from './AdvancedAvailabilityCell';
import { AvailabilityRedirect } from './AvailabilityRedirect';

export function ZonesArrayColumn(advancedAvailability: Set<string>, tooltip?: string): GridColTypeDef {
    return {
        renderCell: props => {
            if (advancedAvailability.has('' + props.id)) {
                return <AdvancedAvailabilityCell tooltip={tooltip} />;
            }
            return renderMuiGridTextView(props, Array.isArray(props.value) ? props.value.join(', ') : 'All');
        },
        renderEditCell: props =>
            advancedAvailability.has('' + props.id) ? (
                <AvailabilityRedirect />
            ) : (
                <GridStringArrayColumn {...props} />
            )
    };
}

import { SelectChangeEvent } from '@mui/material';
import React, { ChangeEventHandler } from 'react';

interface OrderRefundField<T, V = string> {
    value: V | undefined;
    onChange: T;
}

interface OrderRefundHook {
    reason: OrderRefundField<(e: SelectChangeEvent) => void>;
    notes: OrderRefundField<ChangeEventHandler<HTMLInputElement>>;
}

export function useBaseRefundFields(): OrderRefundHook {
    const [reason, setReason] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const handleReasonChange = React.useCallback((e: SelectChangeEvent) => {
        setReason(e.target.value);
    }, []);
    const handleNotesChange = React.useCallback((e: React.ChangeEvent<{ value: string; name: string }>) => {
        setNotes(e.target.value);
    }, []);
    return {
        reason: { value: reason, onChange: handleReasonChange },
        notes: { value: notes, onChange: handleNotesChange }
    };
}

import { QueryParameter } from 'lib/HttpClient';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeguards';
import { contentApi } from './contentApi';

export async function uploadFile(file: File, fileName: string, folder: string) {
    const formData = new FormData();
    formData.append('files', file, fileName);
    try {
        const result = await contentApi.media.uploadFile({ body: formData, skipStringify: true }, folder);
        if (!result.ok) {
            throw new Error('Failed to upload an image');
        }
        return result;
    } catch (e) {
        logger.error('Failed to upload an image', e);
    }
}

export async function uploadStripeFile(file: File, purpose: string, locationId?: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
        const queryParameters: QueryParameter[] = [];
        if (isDefined(locationId)) {
            queryParameters.push({ value: locationId, key: 'locationId' });
        }
        const result = await contentApi.media.uploadStripeFile(
            { body: formData, skipStringify: true, queryParameters },
            purpose
        );
        if (!result.ok) {
            throw new Error('Failed to upload a file');
        }
        return result;
    } catch (e) {
        logger.error('Failed to upload a file', e);
    }
}

export async function loadStripeFile(fileId: string, locationId?: string) {
    try {
        const queryParameters: QueryParameter[] = [];
        if (isDefined(locationId)) {
            queryParameters.push({ value: locationId, key: 'locationId' });
        }
        const result = await contentApi.media.loadStripeFile({ queryParameters }, fileId);
        if (!result.ok) {
            throw new Error('Failed to load a file');
        }
        return result;
    } catch (e) {
        logger.error('Failed to upload a file', e);
    }
}

import React from 'react';
import { IOrderRefundItemEntry } from '../hooks/useCalculatedRefund';
import { Box, Grow, List, Typography, alpha, styled } from '@mui/material';
import { Waypoint } from 'react-waypoint';
import KeyboardDown from '@mui/icons-material/KeyboardArrowDown';
import { OrderRefundItemChild } from './OrderRefundItemChild';

interface OrderRefundItemsSelectorProps {
    itemsList: IOrderRefundItemEntry[];
    selectedItems: Set<string>;
    onSelectionChange: (items: Set<string>) => void;
    showSuggestions?: boolean;
}

const StyledRoot = styled(Box)(({ theme }) => ({
    paddingRight: theme.spacing(1),
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    marginRight: theme.spacing(2),
    position: 'relative',
    overflowY: 'hidden'
}));

const StyledBox = styled(Box)({
    overflowY: 'auto',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 0
});

const BottomFade = styled('div')({
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50px'
});

const StyledIconWrapper = styled('div')(({ theme }) => ({
    background: `linear-gradient(180deg, ${alpha(theme.palette.background.paper, 0)} 0%, ${alpha(
        theme.palette.background.paper,
        0.4
    )} 25%, ${alpha(theme.palette.background.paper, 1)} 100%)`,
    cursor: 'pointer',
    transition: 'all 0.1s',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '&:hover': {
        paddingBottom: theme.spacing(0.5)
    }
}));

export const OrderRefundItemsSelector: React.FC<OrderRefundItemsSelectorProps> = ({
    itemsList,
    selectedItems,
    onSelectionChange,
    showSuggestions
}) => {
    const boxRef = React.useRef<HTMLDivElement>();
    const [isFadeVisible, setIsFadeVisible] = React.useState(false);
    const isItemSelected = React.useCallback((id: string) => selectedItems.has(id), [selectedItems]);
    const handleChange = React.useCallback(
        (id: string) => {
            const newSelection = new Set([...selectedItems]);
            if (isItemSelected(id)) {
                newSelection.delete(id);
            } else {
                newSelection.add(id);
            }
            onSelectionChange(newSelection);
        },
        [isItemSelected, onSelectionChange, selectedItems]
    );
    const renderItem = React.useCallback(
        (item: IOrderRefundItemEntry) => (
            <OrderRefundItemChild
                onClick={handleChange}
                item={item}
                key={item.id}
                selected={selectedItems.has(item.id)}
            />
        ),
        [handleChange, selectedItems]
    );
    const handleBottomFadeClick = React.useCallback(() => {
        boxRef?.current?.scrollTo({ top: boxRef?.current?.scrollHeight });
    }, []);
    const handleLeave = React.useCallback(() => {
        setIsFadeVisible(true);
    }, []);
    const handleEnter = React.useCallback(() => {
        setIsFadeVisible(false);
    }, []);
    React.useLayoutEffect(() => {
        setIsFadeVisible(boxRef?.current?.scrollHeight > boxRef?.current?.clientHeight);
    }, []);
    const suggestions = React.useMemo(() => itemsList.filter(item => item.suggestion), [itemsList]);
    return (
        <StyledRoot>
            <StyledBox ref={boxRef} className="hidden-scroll">
                {showSuggestions && !!suggestions.length && (
                    <Typography variant="h6" sx={{ pl: 1.5 }}>
                        Claimed items
                    </Typography>
                )}
                {showSuggestions && !!suggestions.length && (
                    <List sx={{ pb: 2 }}>{suggestions.map(renderItem)}</List>
                )}
                <Typography variant="h6" sx={{ pl: 1.5 }}>
                    All items
                </Typography>
                <List>
                    {itemsList.map(renderItem)}
                    <Waypoint
                        key="refund-items-calculator"
                        scrollableAncestor={boxRef.current}
                        onEnter={handleEnter}
                        onLeave={handleLeave}
                    />
                </List>
            </StyledBox>
            <Grow in={isFadeVisible}>
                <BottomFade onClick={handleBottomFadeClick}>
                    <StyledIconWrapper>
                        <KeyboardDown sx={{ opacity: 0.75 }} />
                    </StyledIconWrapper>
                </BottomFade>
            </Grow>
        </StyledRoot>
    );
};

export enum StripeSettingsActionTypes {
    START_REQUEST = 'STRIPE_SETTINGS_START_REQUEST',
    END_REQUEST = 'STRIPE_SETTINGS_END_REQUEST',
    SET_LOCATION = 'STRIPE_SETTINGS_SELECT_LOCATION',
    LOAD_ERROR = 'STRIPE_SETTINGS_LOAD_ERROR',
    SOFT_RESET = 'STRIPE_SETTINGS_SOFT_RESET',
    SET_PICKER_STATE = 'STRIPE_SETTINGS_SET_PICKER_STATE',
    SET_SIGNUP_STATUS = 'STRIPE_SET_SIGNUP_STATUS',
    SET_ACCOUNT = 'STRIPE_SET_ACCOUNT'
}

import React from 'react';
import { styled } from '@mui/material';
import { blue, green, orange, red } from '@mui/material/colors';
import clsx from 'clsx';
import { EnrichmentOperation } from '../model/EnrichmentOperation';
import { IActionOperation } from 'components/analytics/export/models';

const PREFIX = 'OperationStatusBar';

const classes = {
    root: `${PREFIX}-root`,
    bar: `${PREFIX}-bar`,
    innerBar: `${PREFIX}-innerBar`,
    rate: `${PREFIX}-rate`,
    success: `${PREFIX}-success`,
    skip: `${PREFIX}-skip`,
    error: `${PREFIX}-error`,
    removedUnlinked: `${PREFIX}-removedUnlinked`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%'
    },
    [`& .${classes.bar}`]: {
        width: '100%',
        padding: theme.spacing(0, 2)
    },
    [`& .${classes.innerBar}`]: {
        width: '100%',
        height: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        display: 'flex',
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative'
    },
    [`& .${classes.rate}`]: {
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        width: 0,
        borderRadius: 4,
        transition: `width ${theme.transitions.duration.short}ms`
    },
    [`& .${classes.success}`]: {
        backgroundColor: green['500']
    },
    [`& .${classes.skip}`]: {
        backgroundColor: orange['500']
    },
    [`& .${classes.error}`]: {
        backgroundColor: red['500']
    },
    [`& .${classes.removedUnlinked}`]: {
        backgroundColor: blue['500']
    }
}));

interface OperationStatusBarProps {
    operation: EnrichmentOperation | IActionOperation;
}

export const OperationStatusBar: React.FC<OperationStatusBarProps> = ({ operation }) => {
    const { result } = operation;
    if (!result) {
        return (
            <StyledDiv className={classes.root}>
                <div className={classes.bar}>
                    <div className={classes.innerBar}></div>
                </div>
            </StyledDiv>
        );
    }
    const { total, skipped, errors, exported, imported, removedUnlinked } = result;
    const done = exported || imported || 0;
    const successRate = done / total;
    const skippedRate = skipped / total;
    const errorsRate = errors / total;
    const removedRate = (removedUnlinked || 0) / total;
    return (
        <StyledDiv className={classes.root}>
            <div className={classes.bar}>
                <div className={classes.innerBar}>
                    <div
                        className={clsx(classes.rate, classes.error)}
                        style={{ width: `${(errorsRate + skippedRate + removedRate + successRate) * 100}%` }}
                    />
                    <div
                        className={clsx(classes.rate, classes.skip)}
                        style={{ width: `${(skippedRate + successRate + removedRate) * 100}%` }}
                    />
                    <div
                        className={clsx(classes.rate, classes.removedUnlinked)}
                        style={{ width: `${(removedRate + successRate) * 100}%` }}
                    />
                    <div
                        className={clsx(classes.rate, classes.success)}
                        style={{ width: `${successRate * 100}%` }}
                    />
                </div>
            </div>
        </StyledDiv>
    );
};

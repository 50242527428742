import { Box, Grid, Paper, Typography } from '@mui/material';
import { isCurrencyCode } from '@pepperhq/regions';
import Dinero from 'dinero.js';
import React from 'react';
import { PepperTransaction } from './model/PepperPay';

interface PepperPayPaymentDetailsBlockProps {
    transaction?: PepperTransaction;
}

export const PepperPayPaymentDetailsBlock: React.FC<PepperPayPaymentDetailsBlockProps> = ({
    transaction
}) => {
    const amount = Dinero({
        amount: transaction.paymentIntent?.amountReceived ?? 0,
        currency: isCurrencyCode(transaction.currency) ? transaction.currency : 'GBP'
    });
    const refundedAmount = Dinero({
        amount: transaction.charge?.amountRefunded ?? 0,
        currency: isCurrencyCode(transaction.currency) ? transaction.currency : 'GBP'
    });
    return (
        <Paper>
            <Box padding={2}>
                <Typography variant="h6" gutterBottom>
                    Payment details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2">Amount</Typography>
                        <Typography variant="body1">{amount.toFormat()}</Typography>
                    </Grid>
                    {!refundedAmount.isZero() && (
                        <Grid item xs={6}>
                            <Typography variant="body2">Refunded Amount</Typography>
                            <Typography variant="body1">{refundedAmount.toFormat()}</Typography>
                        </Grid>
                    )}
                    {!refundedAmount.isZero() && (
                        <Grid item xs={6}>
                            <Typography variant="body2">Net</Typography>
                            <Typography variant="body1">
                                {amount.subtract(refundedAmount).toFormat()}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="body2">Transfer group ID</Typography>
                        <Typography variant="body1">{transaction.transferGroupId}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">Payment ID</Typography>
                        <Typography variant="body1">{transaction.paymentId}</Typography>
                    </Grid>
                    {transaction.paymentIntent?.paymentMethodFingerprint && (
                        <Grid item xs={12}>
                            <Typography variant="body2">Card fingerprint</Typography>
                            <Typography variant="body1">
                                {transaction.paymentIntent?.paymentMethodFingerprint}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    );
};

import React from 'react';
import { Box } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import {
    AudienceAddTriggersForm,
    IAudienceAddTriggersFormData
} from 'components/audience/forms/AudienceAddTriggersForm';
import { Audience } from 'components/audience/models/segmentModel';
import { makeCreateRuleBody } from 'components/audience/models/addTriggersModel';
import { rulesApi } from 'components/rules/rulesApi';
import { FormikHelpers } from 'formik';

interface IAudienceAddTriggersModalProps {
    open: boolean;
    onClose: () => void;
    audienceData: Partial<Audience>;
    onSuccess?: () => void;
    onError?: () => void;
}

const AudienceAddTriggersModalComponent = ({
    onClose,
    audienceData,
    onError,
    onSuccess
}: IAudienceAddTriggersModalProps) => {
    const handleSubmit = React.useCallback(
        async (
            id: string,
            values: IAudienceAddTriggersFormData,
            formikHelpers: FormikHelpers<IAudienceAddTriggersFormData>
        ) => {
            const response = await rulesApi.create({
                body: makeCreateRuleBody(values, id)
            });

            if (!response.ok) {
                onError();
            } else {
                onSuccess();
            }

            formikHelpers.setSubmitting(false);
        },
        [onSuccess, onError]
    );

    return (
        <Box width={60 * 8}>
            <AudienceAddTriggersForm onSubmit={handleSubmit} audienceData={audienceData} onCancel={onClose} />
        </Box>
    );
};

export const AudienceAddTriggersModal = (props: IAudienceAddTriggersModalProps) => {
    const { open, onClose } = props;
    return (
        <MuiModal open={open} onClose={onClose}>
            <AudienceAddTriggersModalComponent {...props} />
        </MuiModal>
    );
};

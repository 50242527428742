import { auth } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    auth().createUserWithEmailAndPassword(email, password);

// We have to somehow update auth state
// Sign In
export const doSignInWithCustomToken = async (token: string) => {
    const user = await auth().signInWithCustomToken(token);
    return user.user.getIdToken();
};

// Sign Out
export const doSignOut = () => auth().signOut();

// Password Reset
export const doPasswordReset = (email: string) => auth().sendPasswordResetEmail(email);

// Password Reset Confirmation
export const doConfirmPasswordReset = (code: string, password: string) =>
    auth().confirmPasswordReset(code, password);

// Password Change
export const doPasswordUpdate = (password: string) => auth().currentUser.updatePassword(password);

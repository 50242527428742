import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class TenantsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'tenants', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    createAppId(tenantId: string, appId: string) {
        return this.httpClient.post(`${this.api}/tenants/${tenantId}/applicationIds`, { body: { appId } });
    }
}

export const tenantsApi = new TenantsApi(httpClient);

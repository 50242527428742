enum uiActionTypes {
    LOCATIONS_TABLE_SELECTED_COLUMNS_CHANGE = 'LOCATIONS_TABLE_SELECTED_COLUMNS_CHANGE',
    CUSTOMERS_TABLE_SELECTED_COLUMNS_CHANGE = 'CUSTOMERS_TABLE_SELECTED_COLUMNS_CHANGE',
    PERKS_TABLE_SELECTED_COLUMNS_CHANGE = 'PERKS_TABLE_SELECTED_COLUMNS_CHANGE',
    AUDIENCES_TABLE_SELECTED_COLUMNS_CHANGE = 'AUDIENCES_TABLE_SELECTED_COLUMNS_CHANGE',
    MERCHANTS_TABLE_SELECTED_COLUMNS_CHANGE = 'MERCHANTS_TABLE_SELECTED_COLUMNS_CHANGE',
    ORDERS_TABLE_SELECTED_COLUMNS_CHANGE = 'ORDERS_TABLE_SELECTED_COLUMNS_CHANGE'
}

export default uiActionTypes;

import * as React from 'react';
import { LocationGeneralCardComponent } from 'components/location/cards/LocationGeneralCardComponent';
import { LocationGeneralEditCardComponent } from 'components/location/cards/LocationGeneralEditCardComponent';
import { createLocationGeneralForm } from 'components/location/forms/createLocationGeneralForm';
import { Location } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { Panel } from 'ui/Panel';
import { locationApi } from '../LocationApi';

export interface LocationGeneralCardProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

export class LocationGeneralCard extends React.Component<LocationGeneralCardProps, { isEditing: boolean }> {
    state = {
        isEditing: false
    };
    handleSubmit = async (data: any) => {
        const locationId = this.props.currentLocation._id;
        const { email, phone, timezone, ...rest } = data;
        const response = await locationApi.update(locationId, {
            body: {
                ...rest,
                contacts: {
                    email,
                    phone
                },
                timezone: timezone?.value
            }
        });
        if (response.ok) {
            this.updateForm(response.body);
            this.props.setCurrentLocation(response.body);
            this.setState({ isEditing: false });
        }
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    form = new LegacyForm(createLocationGeneralForm(), this.handleSubmit);
    componentDidMount() {
        this.updateForm(this.props.currentLocation);
    }
    updateForm = (data: Location) => {
        const { title, state, contacts, description, VATnumber, buttons, timezone } = data;
        const { phone, email } = contacts || { phone: '', email: '' };
        this.form.update({
            title,
            state,
            phone,
            email,
            description,
            VATnumber,
            buttons,
            timezone: { label: timezone, value: timezone }
        });
    };
    toggleState = () => {
        this.setState(({ isEditing }) => ({ isEditing: !isEditing }));
        this.updateForm(this.props.currentLocation);
    };
    render() {
        const { isEditing } = this.state;
        return (
            <Panel title="General" showAction={!isEditing} onClick={this.toggleState} divider>
                {isEditing ? (
                    <LocationGeneralEditCardComponent
                        onClose={this.toggleState}
                        form={this.form}
                        location={this.props.currentLocation}
                    />
                ) : (
                    <LocationGeneralCardComponent location={this.props.currentLocation} />
                )}
            </Panel>
        );
    }
}

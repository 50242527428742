import { MenuChangeUpdate } from '@pepperhq/menu-sdk';
import { Crud, HttpClient, httpClient } from 'lib/HttpClient';
import { v4 as uuid } from 'uuid';

class MenuApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.MENU_SERVICE_URL, 'menu', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'x-api-version': 4
        });
    }
    getMenuChange(locationId: string) {
        return this.httpClient.get(`${process.env.MENU_SERVICE_URL}/menuchanges/${locationId}`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            }
        });
    }
    updateMenuChange(locationId: string, body: MenuChangeUpdate) {
        return this.httpClient.put(`${process.env.MENU_SERVICE_URL}/menuchanges/${locationId}`, {
            body,
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            }
        });
    }
    bulkUpdateMenuChange(selectedMenus: string[], update: MenuChangeUpdate) {
        const { locationIds, menuChangeIds } = selectedMenus.reduce(
            (acc, selectedMenu) => {
                const [locationId, menuChangeId] = selectedMenu.split('::');
                acc.locationIds.push(locationId);
                acc.menuChangeIds.push(menuChangeId);
                return acc;
            },
            { locationIds: [], menuChangeIds: [] }
        );

        return this.httpClient.post(`${process.env.MENU_SERVICE_URL}/menuchanges/bulkUpdate`, {
            body: {
                locationIds,
                menuChangeIds,
                update
            },
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-pepper-req-nonce': uuid()
            }
        });
    }
    async getAllBulkUpdateOperations() {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        const result = await this.httpClient.get(
            `${process.env.MENU_SERVICE_URL}/operations?namespace=MENUCHANGES&name=BULK_UPDATE`,
            { headers }
        );
        if (result.ok) {
            return result.body.items;
        }
        return undefined;
    }
    async getOperationStatus(id: string) {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        const result = await this.httpClient.get(`${process.env.MENU_SERVICE_URL}/operations/${id}`, {
            headers
        });
        if (result.ok) {
            return result.body;
        }
        return undefined;
    }
}

export const menuApi = new MenuApi(httpClient);

import React from 'react';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { IncludeOptions } from 'lib/api/include';
import { IPageOptions } from 'lib/api/paging';
import logger from 'lib/logger';
import { TableSkeletonComponent } from 'ui/skeleton/TableSkeleton';
import { MuiTableScrollItems } from 'ui/table/MuiTable';
import { AudienceAddCustomersModal } from './modals/AudienceAddCustomersModal';
import { Audience, AudienceType } from './models/segmentModel';
import { SegmentUser } from './models/segmentUserModel';
import { segmentsApi } from './segmentsApi';
import { SegmentUserTable } from './SegmentUserTable';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { isDefined } from 'lib/typeguards';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_MARKETING_AUDIENCE_REMOVE_USER_ERROR,
    MESSAGE_MARKETING_AUDIENCE_REMOVE_USER_SUCCESS
} from 'config/messages';

const pagingOptions: IPageOptions = { limit: 100, sort: '-expires' };
const includeOptions: IncludeOptions<keyof Pick<SegmentUser, 'userFullName'>> = { userFullName: true };

export interface SegmentUserTableCardComponentProps {
    segment: Audience;
    disabled?: boolean;
}

export const SegmentUserTableCardComponent: React.FC<SegmentUserTableCardComponentProps> = ({
    segment,
    disabled
}) => {
    const segmentId = segment._id;
    const [users, setUsers] = React.useState<SegmentUser[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [nextKey, setNextKey] = React.useState(undefined);
    const [mounted, setMounted] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const openModal = React.useCallback(() => setOpen(true), []);
    const closeModal = React.useCallback(() => setOpen(false), []);

    const handleScroll = React.useCallback(async () => {
        const {
            body: { items, page }
        } = await segmentsApi.getSegmentUsersPaged(segmentId, includeOptions, {
            startKey: nextKey,
            ...pagingOptions
        });
        setUsers([...users, ...items]);
        setNextKey(page.nextKey);
    }, [users, nextKey, segmentId]);

    const scrollItems: MuiTableScrollItems = {
        isMoreItems: !!nextKey && mounted,
        key: nextKey,
        onScroll: handleScroll
    };

    React.useEffect(() => {
        async function getInitialUsers() {
            try {
                const {
                    body: { items, page }
                } = await segmentsApi.getSegmentUsersPaged(segmentId, includeOptions, pagingOptions);
                setUsers(items);
                setNextKey(page.nextKey);
            } catch (error) {
                logger.error('Expected users info but got', error);
            }
            setLoading(false);
            setMounted(true);
        }
        getInitialUsers();
    }, [segmentId]);

    const dispatch = useDispatch();

    const [userIdToDelete, setUserIdToDelete] = React.useState<string>();
    const [userDeleteLoading, setUserDeleteLoading] = React.useState(false);

    const handleDeleteClick = React.useCallback((userId: string) => {
        setUserIdToDelete(userId);
    }, []);
    const handleCancelDelete = React.useCallback(() => {
        setUserIdToDelete(undefined);
    }, []);

    const userToDelete = React.useMemo(() => {
        if (isDefined(userIdToDelete)) {
            return users.find(item => item.userId === userIdToDelete);
        }
        return undefined;
    }, [userIdToDelete, users]);

    const handleDeleteUser = React.useCallback(async () => {
        setUserDeleteLoading(true);
        const result = await segmentsApi.removeFromSegment(segmentId, userIdToDelete);
        if (!result.ok) {
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_REMOVE_USER_ERROR, { variant: 'error' }));
        }
        dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_REMOVE_USER_SUCCESS, { variant: 'success' }));
        setUserIdToDelete(undefined);
        setUsers(oldUsers => oldUsers.filter(item => item.userId !== userIdToDelete));
        setUserDeleteLoading(false);
    }, [dispatch, segmentId, userIdToDelete]);

    return (
        <React.Fragment>
            <Paper>
                {loading && <TableSkeletonComponent tableHeadColumns={2} tableBodyRows={4} />}
                {!loading && (
                    <React.Fragment>
                        <Box position="relative" padding={2}>
                            <Typography>Customers</Typography>
                            <Box position="absolute" top={8} right={8}>
                                <Button
                                    onClick={openModal}
                                    color="primary"
                                    variant="contained"
                                    disabled={disabled}
                                >
                                    Add Customer
                                </Button>
                            </Box>
                        </Box>
                        <Divider />
                        <SegmentUserTable
                            scroll={scrollItems}
                            data={users}
                            includeExpiry={segment.type !== AudienceType.PERSISTENT}
                            onDelete={handleDeleteClick}
                        />
                    </React.Fragment>
                )}
            </Paper>
            <AudienceAddCustomersModal
                open={open}
                onClose={closeModal}
                customers={users}
                segmentId={segmentId}
            />
            <DeleteDialog
                title={`Remove ${userToDelete?.userFullName}?`}
                content="To remove the customer from this audience, type 'REMOVE' in the text input field."
                onClose={handleCancelDelete}
                onDelete={handleDeleteUser}
                protectionWord="REMOVE"
                open={!!userToDelete}
                submitLabel="Submit"
                loading={userDeleteLoading}
            />
        </React.Fragment>
    );
};

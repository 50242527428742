import { Box, Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import { TextFormField } from 'lib/form/fields/TextFormField';
import React from 'react';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';
import { EBlockListAlias } from './enums';
import * as Yup from 'yup';

const newModalDetailsToTypeMap: Record<
    EBlockListAlias,
    {
        label: string;
        placeholder: string;
    }
> = {
    [EBlockListAlias.EMAIL]: {
        label: 'Single Email',
        placeholder: 'e.g user@domain.com'
    },
    [EBlockListAlias.FINGERPRINT]: {
        label: 'Single Card Fingerprint',
        placeholder: 'eg. acZmDaOVPdsq5iNz'
    }
};

interface IProps {
    open: boolean;
    onClose: () => void;
    onCreate: (value: string) => Promise<any>;
    alias: EBlockListAlias;
}

export const AddBlockListItemModal: React.FC<IProps> = ({ open, onClose, onCreate, alias }) => {
    const handleSubmit = React.useCallback(({ value }: { value: string }) => onCreate(value), [onCreate]);
    const inputDetails = React.useMemo(() => newModalDetailsToTypeMap[alias], [alias]);
    const validationScheme = React.useMemo(() => {
        if (alias === EBlockListAlias.EMAIL) {
            return Yup.object().shape({
                value: Yup.string().email('Invalid email').required('Email is required')
            });
        }

        return Yup.object().shape({
            value: Yup.string()
                .required('Fingerprint is required')
                .matches(
                    new RegExp('^[a-zA-Z0-9]{16}$'),
                    'A valid card fingerprint contains 16 alphanumeric characters.'
                )
        });
    }, [alias]);

    return (
        <MuiModal open={open} onClose={onClose}>
            <Box width={400}>
                <Typography variant="h6" color="primary">
                    Add new item
                </Typography>
                <Box marginTop={1}>
                    <Formik
                        validationSchema={validationScheme}
                        initialValues={{ value: '' }}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, handleSubmit: onSubmit }) => (
                            <form onSubmit={onSubmit}>
                                <TextFormField
                                    name="value"
                                    label={inputDetails?.label}
                                    placeholder={inputDetails?.placeholder}
                                />
                                <Box display="flex" justifyContent="flex-end" paddingTop={2}>
                                    <Box paddingRight={1}>
                                        <Button color="secondary" onClick={onClose}>
                                            Cancel
                                        </Button>
                                    </Box>
                                    <LoadingButton
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Create
                                    </LoadingButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </MuiModal>
    );
};

import React from 'react';
import { KibanaIframeLayout } from '../../components/analytics/KibanaIframeLayout';

export default () => (
    <KibanaIframeLayout
        pageName="Loyalty Analytics"
        pageDescription="Useful insights into how loyalty is used by your customers."
        dashboardId={process.env.KIBANA_LOYALTY_DASHBOARD_ID}
    />
);

import { Box, Grid, styled, TextField, Typography } from '@mui/material';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Column, Row } from 'ui/Flex';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import { IAppIdsDetails } from './useAppIds';

const PREFIX = 'API-Tokens';

const classes = {
    hintHeader: `${PREFIX}-hintHeader`
};

const StyledRow = styled(Row)(({ theme }) => ({
    padding: theme.spacing(2),
    [`& .${classes.hintHeader}`]: {
        fontWeight: theme.typography.body1.fontWeight
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    '&::before': {
        content: '""',
        display: 'inline-block',
        width: theme.spacing(1),
        height: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main
    }
}));

interface ApiTokensPageProps {
    appIdDetails: IAppIdsDetails;
    onAppIdAdd: (value: string) => void;
}

export const ApiTokensPage: React.FC<ApiTokensPageProps> = ({ appIdDetails, onAppIdAdd }) => {
    const { tenant } = useSelector((state: ApplicationState) => state.auth);
    const [appId, setAppId] = React.useState('');
    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAppId(e.target.value);
    }, []);
    const handleClick = React.useCallback(() => {
        onAppIdAdd(appId);
        setAppId('');
    }, [appId, onAppIdAdd]);
    return (
        <React.Fragment>
            <StyledRow>
                <Column flex={4}>
                    <Grid container spacing={2} alignItems="flex-end">
                        <Grid item xs={7}>
                            <TextField
                                label={<MuiFormLabel required>Application ID</MuiFormLabel>}
                                fullWidth
                                value={appId}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <LoadingButton
                                fullWidth
                                onClick={handleClick}
                                color="primary"
                                variant="contained"
                                disabled={appId === '' || appIdDetails.loading}
                                loading={appIdDetails.loading}
                            >
                                ADD APP ID
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Column>
                <Column flex={8} />
            </StyledRow>
            <StyledRow>
                <Column flex={1}>
                    <Typography variant="body1">Application IDs:</Typography>
                </Column>
                <Column flex={2}>
                    <Box>
                        {!appIdDetails.listLoading ? (
                            appIdDetails.ids?.map(applicationId => (
                                <StyledTypography key={applicationId}>{applicationId}</StyledTypography>
                            ))
                        ) : (
                            <Box>
                                <Box display="flex">
                                    <Box marginRight={1}>
                                        <SkeletonComponent width={10} height={10} />
                                    </Box>
                                    <SkeletonComponent width={200} height={10} />
                                </Box>
                                <Box display="flex">
                                    <Box marginRight={1}>
                                        <SkeletonComponent width={10} height={10} />
                                    </Box>
                                    <SkeletonComponent width={220} height={10} />
                                </Box>
                                <Box display="flex">
                                    <Box marginRight={1}>
                                        <SkeletonComponent width={10} height={10} />
                                    </Box>
                                    <SkeletonComponent width={190} height={10} />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Column>
                <Column flex={4}>
                    <Typography>
                        Pass this in as{' '}
                        <span className={classes.hintHeader}>&quot;x-application-id&quot;</span> when making a
                        call against the api.
                    </Typography>
                </Column>
            </StyledRow>
            <StyledRow>
                <Column flex={1}>
                    <Typography variant="body1">API Key:</Typography>
                </Column>
                <Column flex={2}>
                    <Typography>{tenant && tenant.platform.apiKey}</Typography>
                </Column>
                <Column flex={4}>
                    <Typography>
                        Pass this in as{' '}
                        <span className={classes.hintHeader}>&quot;Authorization Token&quot;</span> when
                        making a call against the api.
                    </Typography>
                </Column>
            </StyledRow>
        </React.Fragment>
    );
};

import { hexToRgb, Typography, styled, TypographyProps } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import React from 'react';

const PREFIX = 'MuiGridTextView';

const classes = {
    error: `${PREFIX}-error`
};

const Root = styled('div')(({ theme }) => ({
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position: 'relative',
    width: '100%',
    [`& .${classes.error}`]: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        lineHeight: 1,
        color: theme.palette.error.main,
        backgroundColor: hexToRgb(lightGreen[100]).replace(')', `, ${0.8})`),
        borderRadius: `${theme.shape.borderRadius} 0`
    }
}));

export const MuiGridTextView: React.FC<{ error?: string; fontWeight?: TypographyProps['fontWeight'] }> = ({
    children,
    fontWeight = 400,
    error
}) => (
    <Root>
        {children}
        {!!error && (
            <Typography className={classes.error} fontWeight={fontWeight} variant="caption" color="error">
                {error}
            </Typography>
        )}
    </Root>
);

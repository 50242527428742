import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SwitchFormField } from '../../../../lib/form/fields/SwitchFormField';

export const ZonalSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Production Environment">
                    <SwitchFormField name="zonalIsProduction" />
                </SettingDisplay>
                <SettingDisplay label="Authentication Brand Token">
                    <TextFormField name="zonalAuthenticationBrandToken" />
                </SettingDisplay>
                <SettingDisplay label="Platform Type">
                    <TextFormField name="zonalPlatformType" />
                </SettingDisplay>
                <SettingDisplay label="Bundle Identifier">
                    <TextFormField name="zonalBundleIdentifier" />
                </SettingDisplay>
                <SettingDisplay label="Host">
                    <TextFormField name="zonalHost" />
                </SettingDisplay>
                <SettingDisplay label="Service Charge IDs">
                    <TextFormField name="chargeTaxIds" />
                </SettingDisplay>
                <SettingDisplay label="Exclusive Tax IDs">
                    <TextFormField name="exclusiveTaxTaxIds" />
                </SettingDisplay>
                <SettingDisplay label="Discretionary Service Charge IDs">
                    <TextFormField name="discretionaryChargeTaxIds" />
                </SettingDisplay>
                <SettingDisplay label="Loyalty At POS Password">
                    <TextFormField name="loyaltyAtPosPassword" />
                </SettingDisplay>
                <SettingDisplay label="Use Open Discounts">
                    <SwitchFormField name="iOrderOpenDiscountsEnabled" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Order to Table Service ID">
                    <NumberFormField name="zonalOrderToTableServiceId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Service ID">
                    <NumberFormField name="zonalPreOrderServiceId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Menu ID">
                    <NumberFormField name="zonalMenuId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Notes Display Record ID">
                    <NumberFormField name="zonalNotesDisplayRecordId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Notes Item Ingredient ID">
                    <NumberFormField name="zonalNotesItemIngredientId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Notes Item Portion Type ID">
                    <NumberFormField name="zonalNotesItemPortionTypeId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Sales Area ID">
                    <NumberFormField name="zonalSalesAreaId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Site ID">
                    <NumberFormField name="zonalSiteId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Table Payment Method ID">
                    <NumberFormField name="tablePaymentMethodId" shouldSeparateThousands={false} />
                </SettingDisplay>
                <SettingDisplay label="Loyalty At POS Password">
                    <TextFormField name="loyaltyAtPosPassword" />
                </SettingDisplay>
                <SettingDisplay label="Use Open Discounts">
                    <SwitchFormField name="iOrderOpenDiscountsEnabled" />
                </SettingDisplay>
            </>
        )}
    </>
);

import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Row } from 'ui/Flex';

interface SettingDisplayTextProps {
    title?: string;
    color?: string;
    description?: string;
    crop?: boolean;
    buildtimeIOS?: boolean;
    buildtimeAndroid?: boolean;
    variant?: 'body2' | 'body1';
}

const ColorBox = styled(Box)(({ theme }) => ({
    width: '48px',
    height: '24px',
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: theme.palette.grey[400]
}));

export const SettingDisplayColor: React.FC<SettingDisplayTextProps> = ({
    color,
    title,
    description,
    buildtimeAndroid,
    buildtimeIOS,
    variant = 'body2'
}) => (
    <SettingDisplay
        label={title}
        description={description}
        buildtimeIOS={buildtimeIOS}
        buildtimeAndroid={buildtimeAndroid}
    >
        <Row valign="center">
            <ColorBox sx={{ backgroundColor: color }} />
            <Typography variant={variant}>{color}</Typography>
        </Row>
    </SettingDisplay>
);

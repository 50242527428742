/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import logger from 'lib/logger';
import { giftingApi } from '../../giftingApi';
import { FormattedGiftingTransaction, GiftingActivitySummaryData, GiftingTransaction } from '../model';
import { GiftingActivityFilter } from '../model/giftingActivityFilter';
import { getGiftingActivityFilterBody } from './useGiftingActivityFilter';
import { isDefined } from 'lib/typeguards';
import Dinero from 'dinero.js';
import { CurrencyCode, isCurrencyCode } from '@pepperhq/regions';
import { GridSortModel } from '@mui/x-data-grid-pro';

export const GIFTING_DATA_PAGE_LIMIT_OPTIONS = [10, 25, 50, 100];

export function useGiftingActivityData(filter: GiftingActivityFilter) {
    const [loading, setLoading] = React.useState(false);
    const [summary, setSummary] = React.useState<GiftingActivitySummaryData>();
    const [data, setData] = React.useState<GiftingTransaction[]>([]);
    const [page, setPage] = React.useState(0);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'transaction_date',
            sort: 'asc'
        }
    ]);
    const [pageLimit, setPageLimit] = React.useState(50);
    const [loadedPage, setLoadedPage] = React.useState<number>();
    const [internalFilter, setInternalFilter] = React.useState<Partial<GiftingActivityFilter>>();
    const [error, setError] = React.useState<string>();
    const endOfData = React.useMemo(
        () => summary && summary.all_page_count === summary.current_page_number,
        [summary]
    );
    const reset = React.useCallback(() => {
        setData([]);
        setSummary(undefined);
        setLoading(true);
        setPage(0);
        setLoadedPage(undefined);
    }, []);
    React.useEffect(() => {
        const getGiftingData = async () => {
            if (!internalFilter || endOfData || error) {
                return;
            }
            setLoading(true);
            try {
                if (!internalFilter.date?.startDate && !internalFilter.date?.endDate) {
                    reset();
                    setLoading(false);
                    return;
                }

                const paging: Record<string, string | number> = { limit: pageLimit };
                if (isDefined(page)) {
                    paging.page = page + 1;
                }
                const sorting: Record<string, string> = {};
                if (isDefined(sortModel) && sortModel.length > 0) {
                    sorting.sort_by = sortModel[0].field;
                    sorting.sort_order = sortModel[0].sort;
                }

                const { body, ok } = await giftingApi.activityReport.create({
                    body: { ...getGiftingActivityFilterBody(internalFilter), ...paging, ...sorting }
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                setLoadedPage(body.report_summary.current_page_number - 1);
                setSummary(body.report_summary);
                setData(currentTransactions =>
                    [...currentTransactions, ...body.report].filter(
                        (v, i, a) => a.findIndex(t => t.transaction_id === v.transaction_id) === i
                    )
                );
            } catch (e) {
                setError(e.message);
                logger.error(e);
                setData([]);
            }
            setLoading(false);
        };
        if (!isDefined(loadedPage) || page > loadedPage || data.length < page * pageLimit) {
            getGiftingData();
        }
    }, [
        endOfData,
        internalFilter,
        data.length,
        filter,
        loadedPage,
        page,
        pageLimit,
        error,
        reset,
        sortModel
    ]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const formattedTransactions = React.useMemo(() => {
        if (!Array.isArray(data)) {
            return [];
        }
        return data
            .slice(page * pageLimit, page * pageLimit + pageLimit)
            .map<FormattedGiftingTransaction>(transaction => {
                let type = 'Unknown';
                if (['08', '09', '10'].includes(transaction.transaction_type)) {
                    type = 'Debit';
                } else if (transaction.transaction_type === '50') {
                    type = 'Credit';
                }
                const amount = Dinero({
                    precision: 2,
                    amount: Math.round(transaction.transaction_sales_amount * 100),
                    currency: isCurrencyCode(summary?.currency) ? summary?.currency : CurrencyCode.GBP
                }).toFormat();

                return {
                    ...transaction,
                    currency: isCurrencyCode(summary?.currency) ? summary?.currency : 'GBP',
                    mapped_type: type,
                    debit_value: type === 'Debit' ? amount : null,
                    credit_value: type === 'Credit' ? amount : null
                };
            });
    }, [data, page, pageLimit, summary?.currency]);
    const onPageLimitChange = React.useCallback(
        (limit: number) => {
            setPageLimit(limit);
            reset();
        },
        [reset]
    );
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const onSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            setSortModel(sortModel);
            reset();
        },
        [reset]
    );
    const count = React.useMemo(() => summary?.all_count ?? 0, [summary?.all_count]);
    return {
        data,
        summary,
        formattedTransactions,
        page,
        onPageChange,
        pageLimit,
        onPageLimitChange,
        sortModel,
        onSortModelChange,
        loading,
        count,
        error
    };
}

import React from 'react';
import { Button, Chip, styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { formatDateTime, titleize } from 'lib/helpers';
import { MuiTable, MuiTableScrollItems } from 'ui/table/MuiTable';
import { isGrantPerkToSegmentOperation, SegmentOperation } from './models/segmentOperationModel';

const columns = [
    {
        key: 'createdAt',
        label: 'Date'
    },
    {
        key: 'name',
        label: 'Name'
    },
    {
        key: 'status',
        label: 'Status'
    },
    {
        key: 'result',
        label: 'Result'
    },
    {
        key: 'retry',
        label: ''
    }
];

export interface SegmentRetryOperationButtonClickData {
    operation: SegmentOperation;
}

interface OperationRetryButtonProps {
    operation: SegmentOperation;
    onClick: (data: SegmentRetryOperationButtonClickData) => void;
}

const OperationRetryButton: React.FC<OperationRetryButtonProps> = ({ operation, onClick }) => {
    const handleClick = React.useCallback(() => {
        onClick({ operation });
    }, [operation, onClick]);
    return (
        <Button color="primary" variant="contained" onClick={handleClick}>
            Retry
        </Button>
    );
};

function mapOperationStatusToChipColor(status: OperationStatus): string {
    switch (status) {
        case OperationStatus.PENDING:
            return orange['500'];
        case OperationStatus.DONE:
            return green['A700'];
        case OperationStatus.TIMEOUT:
        case OperationStatus.ERROR:
            return red['500'];
    }
}

interface OperationStatusChipProps {
    status: OperationStatus;
}

const OperationStatusChip: React.FC<OperationStatusChipProps> = ({ status }) => (
    <Chip classes={{ root: classes.statusChip }} label={status} />
);

const PREFIX = 'SegmentOperationTable';

const classes = {
    statusChip: `${PREFIX}-statusChip`
};

const StyledOperationStatusChip = styled(OperationStatusChip)(({ theme }) => ({
    [`& .${classes.statusChip}`]: ({ status }: { status: OperationStatus }) => ({
        backgroundColor: mapOperationStatusToChipColor(status),
        color: theme.palette.primary.contrastText
    })
}));

function mapOperationNameToTableRow(
    operation: SegmentOperation,
    onRetryButtonClick: (data: SegmentRetryOperationButtonClickData) => void
): any {
    const status = <StyledOperationStatusChip status={operation.status} />;
    const retry =
        operation.isRetryable &&
        (operation.status === OperationStatus.ERROR || operation.status === OperationStatus.TIMEOUT) ? (
            <OperationRetryButton operation={operation} onClick={onRetryButtonClick} />
        ) : undefined;
    if (isGrantPerkToSegmentOperation(operation)) {
        const { total = 0, successes = 0, skipped = 0, errors = 0 } = operation.result || {};
        return {
            name: `Granted ${operation.metadata.perkTitle || 'perk'}`,
            status,
            createdAt: formatDateTime(operation.createdAt),
            endedAt: formatDateTime(operation.endedAt),
            result: (
                <React.Fragment>
                    <b>Total:</b> {total} / <b>Successes:</b> {successes} / <b>Skipped:</b> {skipped} /{' '}
                    <b>Errors</b>: {errors}
                </React.Fragment>
            ),
            retry
        };
    }
    return { name: titleize(operation.name), status, retry };
}

interface SegmentOperationTableProps {
    scroll: MuiTableScrollItems;
    data: SegmentOperation[];
    isLoading: boolean;
    onRetryButtonClick: (data: SegmentRetryOperationButtonClickData) => void;
}

export const SegmentOperationTable: React.FC<SegmentOperationTableProps> = ({
    scroll,
    data,
    isLoading,
    onRetryButtonClick
}) => {
    const operations = data.map(operation => mapOperationNameToTableRow(operation, onRetryButtonClick));
    return <MuiTable columns={columns} scroll={scroll} data={operations} isLoading={isLoading} />;
};

import React from 'react';
import { defaults } from 'chart.js';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Chip, Grid, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { Voucher } from 'components/vouchers/models/VoucherModel';
import { isNumber } from 'lib/typeguards';
import { Column, Row } from 'ui/Flex';
import { Panel } from 'ui/Panel';

interface VoucherRedemptionsCardProps {
    voucher: Voucher;
}

defaults.font.family = 'Ubuntu, Railway, Helvetica Neue';

const colors = {
    redeemed: 'rgb(119, 218, 0)',
    available: 'rgb(248, 189, 0)',
    total: '#ccc'
};

const PREFIX = 'VoucherRedemptionsCard';

const classes = {
    statsBottomWrapper: `${PREFIX}-statsBottomWrapper`,
    availableChip: `${PREFIX}-availableChip`,
    redeemedChip: `${PREFIX}-redeemedChip`,
    chip: `${PREFIX}-chip`,
    stats: `${PREFIX}-stats`,
    noDataText: `${PREFIX}-noDataText`,
    noDataIcon: `${PREFIX}-noDataIcon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.statsBottomWrapper}`]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    [`& .${classes.availableChip}`]: {
        backgroundColor: colors.available
    },
    [`& .${classes.redeemedChip}`]: {
        backgroundColor: colors.redeemed
    },
    [`& .${classes.chip}`]: {
        width: theme.spacing(10),
        height: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        cursor: 'pointer'
    },
    [`& .${classes.stats}`]: {
        flexBasis: '33%',
        padding: theme.spacing(0.5)
    },
    [`& .${classes.noDataText}`]: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.text.disabled,
        padding: theme.spacing(3),
        paddingTop: 0
    },
    [`& .${classes.noDataIcon}`]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        color: theme.palette.text.disabled,
        padding: theme.spacing(3),
        paddingBottom: 0,
        fontSize: theme.spacing(10)
    }
}));

const buildPieData = (total: number, redeemed: number) => {
    const data = {
        labels: ['Redeemed', 'Available'],
        datasets: [
            {
                data: [redeemed, total - redeemed],
                backgroundColor: [colors.redeemed, colors.available],
                hoverBackgroundColor: [colors.redeemed, colors.available]
            }
        ]
    };

    return data;
};

const percentage = (partialValue: number, totalValue: number): string => {
    if (!isNumber(partialValue) || !isNumber(totalValue) || totalValue === 0) {
        return '';
    }
    const percent = ((100 * partialValue) / totalValue).toFixed(1);
    return `(${percent}%)`;
};

export const VoucherRedemptionsCard: React.FC<VoucherRedemptionsCardProps> = props => {
    const { voucher } = props;
    const { numGlobalRedemptions: total, numRedemptions: redeemed } = voucher;
    const data = buildPieData(total, redeemed);

    return (
        <Panel title="Redemptions" divider>
            <Grid container spacing={2}>
                <Grid item xl={12} xs={12}>
                    <Doughnut
                        data={data}
                        options={{
                            plugins: {
                                legend: {
                                    display: false
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false
                        }}
                    />
                </Grid>
                <StyledGrid item xl={12} xs={12}>
                    <Row flex={1} align="center" className={classes.statsBottomWrapper}>
                        <Column align="center" className={classes.stats}>
                            <Chip className={clsx(classes.availableChip, classes.chip)} size="small" />
                            <Typography align="center">Available to redeem</Typography>
                            <Typography align="center">{`${total - redeemed} ${percentage(
                                total - redeemed,
                                total
                            )}`}</Typography>
                        </Column>
                        <Column align="center" className={classes.stats}>
                            <Chip className={clsx(classes.redeemedChip, classes.chip)} size="small" />
                            <Typography align="center">Redeemed</Typography>
                            <Typography align="center">{`${redeemed} ${percentage(
                                redeemed,
                                total
                            )}`}</Typography>
                        </Column>
                    </Row>
                </StyledGrid>
            </Grid>
        </Panel>
    );
};

export enum LocationsActionTypes {
    START_REQUEST = 'START_REQUEST',
    END_REQUEST = 'END_REQUEST',
    GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR',
    LOAD_LOCATIONS_SUCCESS = 'LOAD_LOCATIONS_SUCCESS',
    GET_ALL_LOCATION_TITLES = 'GET_ALL_LOCATION_TITLES'
}

export enum LocationActionTypes {
    DELETE_LOCATION = 'DELETE_LOCATION',
    UPDATE_LOCATION = 'UPDATE_LOCATION'
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography, styled } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import {
    MESSAGE_IMPORT_ENRICHMENTS_CREATED,
    MESSAGE_IMPORT_ENRICHMENTS_ERROR,
    MESSAGE_IMPORT_ENRICHMENTS_SUCCESS
} from 'config/messages';
import { useInterval } from 'lib/hooks/useInterval';
import logger from 'lib/logger';
import Placeholder from 'static/import-enrichments-placeholder.svg';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { EnrichmentOperation } from './model/EnrichmentOperation';
import { ImportEnrichmentsTablePure as ImportEnrichmentsTable } from './tables/ImportEnrichmentsTable';
import { overwritesApi } from './overwritesApi';

const StyledImg = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    maxWidth: theme.spacing(54),
    height: 'auto'
}));

function filterPendingOpertaions(item: EnrichmentOperation) {
    return item.status === OperationStatus.PENDING || item.status === OperationStatus.RUNNING;
}

function mergeOperations(oOne: EnrichmentOperation[], oTwo: EnrichmentOperation[]) {
    return oOne.map(item => {
        const newOperation = oTwo.find(operation => operation.id === item.id);
        if (newOperation) {
            return newOperation;
        }
        return item;
    });
}

interface ImportEnrichmentsProps {
    operations: EnrichmentOperation[];
    onScroll: () => void;
    showLanding?: boolean;
    onClick: (item: EnrichmentOperation) => void;
    setOperations: React.Dispatch<React.SetStateAction<EnrichmentOperation[]>>;
}

export const ImportEnrichments: React.FC<ImportEnrichmentsProps> = ({
    operations,
    onScroll,
    showLanding,
    onClick,
    setOperations
}) => {
    const [pendingOperations, setPendingOperations] = React.useState<EnrichmentOperation[]>(() => {
        const pending = operations.filter(filterPendingOpertaions);
        return pending;
    });
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    useInterval(() => {
        if (pendingOperations.length) {
            const promises = pendingOperations.map(item => overwritesApi.getExportOperation(item.id));
            Promise.all(promises)
                .then(results => {
                    const resultOperations = results.map(item => item.body);
                    const pending = resultOperations.filter(
                        item =>
                            item.status === OperationStatus.PENDING || item.status === OperationStatus.RUNNING
                    );
                    setPendingOperations(pending);
                    if (resultOperations.length - pending.length) {
                        dispatch(enqueueSnackbar(MESSAGE_IMPORT_ENRICHMENTS_SUCCESS, { variant: 'success' }));
                    }
                    setOperations(current => mergeOperations(current, resultOperations));
                })
                .catch(e => {
                    logger.error(e);
                    dispatch(enqueueSnackbar(MESSAGE_IMPORT_ENRICHMENTS_ERROR, { variant: 'error' }));
                });
        }
    }, 1500);
    const handleClick = React.useCallback(() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = async (event: Event) => {
            setLoading(true);
            try {
                // TODO: any chance to fix it?
                const [file] = (event.target as any).files as FileList;
                const formData = new FormData();
                formData.append('file', file, file.name);
                const result = await overwritesApi.createImport(formData);
                setPendingOperations(current => [...current, result.body]);
                setOperations(current => [...current, result.body]);
                dispatch(enqueueSnackbar(MESSAGE_IMPORT_ENRICHMENTS_CREATED, { variant: 'info' }));
                setLoading(false);
            } catch (e) {
                dispatch(enqueueSnackbar(MESSAGE_IMPORT_ENRICHMENTS_ERROR, { variant: 'error' }));
                setLoading(false);
                logger.error(e.message);
            }
        };
        input.click();
    }, [dispatch, setOperations]);

    if (showLanding) {
        return (
            <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <StyledImg src={Placeholder} alt="" />
                <Typography align="center" variant="h5" gutterBottom>
                    Imports
                </Typography>
                <Typography align="center" gutterBottom>
                    Upload your changes to existing customisations. Make
                    <br /> sure you have a copy of the original data set so you
                    <br /> can revert the changes if needed.
                </Typography>
                <Box display="flex" alignItems="center" marginTop={2}>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                    >
                        Import
                    </LoadingButton>
                </Box>
            </Box>
        );
    }

    return (
        <Paper>
            <Box display="flex" alignItems="center" justifyContent="space-between" padding={2}>
                <Typography variant="h6">Menu Customisation Imports</Typography>
                <Box display="flex" alignItems="center">
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                    >
                        Import
                    </LoadingButton>
                </Box>
            </Box>
            <ImportEnrichmentsTable operations={operations} onScroll={onScroll} onClick={onClick} />
        </Paper>
    );
};

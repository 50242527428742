import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { Column } from 'ui/Flex';
import {
    GrantPerkToSegmentOperation,
    isGrantPerkToSegmentOperation,
    SegmentOperation
} from './models/segmentOperationModel';

interface GrantPerkToSegmentOperationProgressBarProps {
    operation: GrantPerkToSegmentOperation;
}

export const GrantPerkToSegmentOperationProgressBar = ({
    operation
}: GrantPerkToSegmentOperationProgressBarProps) => {
    const { perkTitle = '' } = operation.metadata || {};
    const { total = 0, successes = 0, skipped = 0, errors = 0 } = operation.result || {};
    const completed = successes + skipped + errors;
    const progress = total === 0 ? 0 : Math.round((completed / total) * 100);
    return (
        <Column>
            <Typography variant="caption" color="textSecondary">
                Granting perk ({perkTitle}) to {completed} / {total} audience users
            </Typography>
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" color="primary" value={progress} />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="caption" color="textSecondary">{`${progress}%`}</Typography>
                </Box>
            </Box>
            <Typography variant="caption" color="textSecondary">
                Successes: {successes} / Skipped: {skipped} / Errors: {errors}
            </Typography>
            <Typography variant="caption" color="textSecondary"></Typography>
        </Column>
    );
};

interface SegmentOperationProgressBarProps {
    operation: SegmentOperation;
}

export const SegmentOperationProgressBar = ({ operation }: SegmentOperationProgressBarProps) => {
    if (isGrantPerkToSegmentOperation(operation)) {
        return <GrantPerkToSegmentOperationProgressBar operation={operation} />;
    }
};

export enum AudiencesActionTypes {
    START_REQUEST = 'START_REQUEST',
    END_REQUEST = 'END_REQUEST',
    GET_AUDIENCES_ERROR = 'GET_AUDIENCES_ERROR',
    GET_AUDIENCES_SUCCESS = 'GET_AUDIENCES_SUCCESS',
    LOAD_AUDIENCES_SUCCESS = 'LOAD_AUDIENCES_SUCCESS',
    CREATE_AUDIENCE_SUCCESS = 'CREATE_AUDIENCE_SUCCESS',
    GET_CUSTOMER_SEGMENTS_ERROR = 'GET_CUSTOMER_SEGMENTS_ERROR',
    GET_CUSTOMER_SEGMENTS_SUCCESS = 'GET_CUSTOMER_SEGMENTS_SUCCESS'
}

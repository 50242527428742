import { removeArrayItem, updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { IClerk } from '../Clerk';
import { clerksApi } from '../clerksApi';
import { QueryParameter } from 'lib/HttpClient';
import { ClerksDataFilter } from './useClerksFilter';

export const CLERKS_PAGE_LIMIT = 100;

export function useClerksData(filter: ClerksDataFilter) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [clerks, setClerks] = React.useState<IClerk[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<ClerksDataFilter>();
    const [error, setError] = React.useState<string>();
    const reset = React.useCallback(() => {
        setClerks([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    React.useEffect(() => {
        const getClerks = async () => {
            if (!internalFilter || endOfData || !internalFilter.locationId) {
                return;
            }
            setLoading(true);
            try {
                const queryParameters: QueryParameter[] = [{ key: 'limit', value: CLERKS_PAGE_LIMIT }];
                if (internalFilter.name) {
                    queryParameters.push({ key: 'name', value: internalFilter.name });
                }
                if (internalFilter.locationId) {
                    queryParameters.push({ key: 'locationId', value: internalFilter.locationId });
                }
                if (pages[page]) {
                    queryParameters.push({ key: 'startKey', value: pages[page] });
                }
                const { body, ok } = await clerksApi.getList({
                    queryParameters
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.page.nextKey || !body.items.length) {
                    setEndOfData(true);
                }
                setClerks(currentTransactions => [...currentTransactions, ...body.items]);
                setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
            } catch (e) {
                logger.error(e);
                setClerks([]);
            }
            setLoading(false);
        };
        if (
            (page === 0 && clerks.length === 0) ||
            (!pages[page + 1] && clerks.length < pages.length * CLERKS_PAGE_LIMIT)
        ) {
            getClerks();
        }
    }, [clerks.length, endOfData, internalFilter, page, pages]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const formattedClerks = React.useMemo<IClerk[]>(() => {
        if (!Array.isArray) {
            return [];
        }
        return clerks
            .slice(page * CLERKS_PAGE_LIMIT, page * CLERKS_PAGE_LIMIT + CLERKS_PAGE_LIMIT)
            .map(item => ({ ...item, sessionMs: Math.round(item.sessionMs / 1000) }));
    }, [clerks, page]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return clerks.length;
        }
        return -1;
    }, [clerks.length, endOfData]);
    const addClerk = React.useCallback(
        (clerk: IClerk) => {
            if (endOfData) {
                if (count && count % CLERKS_PAGE_LIMIT === 0) {
                    reset();
                } else if (!filter.locationId || filter.locationId === clerk.locationId) {
                    setClerks(current => [...current, clerk]);
                }
            }
        },
        [count, endOfData, filter.locationId, reset]
    );
    const updateClerk = React.useCallback(
        (id: string, clerk?: IClerk) => {
            const clerkIndex = clerks.findIndex(item => item.id === id);
            if (clerkIndex >= 0) {
                if (clerk) {
                    setClerks(clerksArray => updateArrayItem(clerksArray, clerkIndex, clerk));
                } else {
                    setClerks(clerksArray => removeArrayItem(clerksArray, clerkIndex));
                    reset();
                }
            }
        },
        [clerks, reset]
    );
    const updateError = React.useCallback((message?: string) => {
        setError(message);
    }, []);
    return {
        formattedClerks,
        page,
        onPageChange,
        loading,
        count,
        addClerk,
        error,
        updateError,
        updateClerk
    };
}

import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { LocationScopedSettings } from 'components/settings/LocationScopedSettings';
import { MERCHANT_MOBILE_SETTINGS } from 'config/routes';
import { TerminalSettings } from 'components/settings/TerminalSettings';

export const TerminalSettingsPage: React.FC = () => (
    <MainLayout
        pageName="Quickpad"
        pageDescription="Edit quickpad settings."
        breadcrumbs={[{ label: 'Settings', url: MERCHANT_MOBILE_SETTINGS }]}
    >
        <LocationScopedSettings globalComponent={TerminalSettings} scopedComponent={TerminalSettings} />
    </MainLayout>
);

import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';
import { MESSAGE_LOCATIONS_UPDATE_ERROR, MESSAGE_LOCATIONS_UPDATE_SUCCESS } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Panel } from '../../../ui/Panel';
import { locationApi } from '../LocationApi';
import { Location } from '../models/LocationModel';
import { LocationImagesCardComponent } from './LocationImagesCardComponent';
import { Formik, FormikHelpers } from 'formik';
import { MuiImagePicker } from 'lib/image-picker';
import { LoadingButton } from '@mui/lab';

interface IProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

enum EFormField {
    MAIN_IMAGE_URL = 'mainImageUrl',
    LISTING_IMAGE_URL = 'listingImageUrl',
    MAP_PIN_IMAGE_URL = 'mapPinImageUrl'
}

interface IForm {
    mainImageUrl: string;
    listingImageUrl: string;
    mapPinImageUrl: string;
}

const getFileName = (url?: string) => {
    if (url) {
        const [, namePart] = url.split('/content/');

        if (namePart) {
            return namePart.split('.')[0];
        }
    }
};

export const LocationImagesCard = ({ currentLocation, setCurrentLocation }: IProps) => {
    const [isEditing, setEditing] = React.useState(false);
    const dispatch = useDispatch();

    const handleEditClick = React.useCallback(() => {
        setEditing(true);
    }, []);

    const handleSubmit = React.useCallback(
        async (values: IForm, helpers: FormikHelpers<IForm>) => {
            const id = currentLocation._id;
            const result = await locationApi.update(id, { body: values });
            if (!result.ok) {
                helpers.setSubmitting(false);
                dispatch(enqueueSnackbar(MESSAGE_LOCATIONS_UPDATE_ERROR, { variant: 'error' }));
            }
            helpers.setSubmitting(false);
            setEditing(false);
            setCurrentLocation(result.body);
            dispatch(
                enqueueSnackbar(MESSAGE_LOCATIONS_UPDATE_SUCCESS(currentLocation.title), {
                    variant: 'success'
                })
            );
        },
        [currentLocation._id, currentLocation.title, dispatch, setCurrentLocation]
    );
    const initialFormValues: IForm = React.useMemo(
        () => ({
            mainImageUrl: currentLocation.mainImageUrl,
            listingImageUrl: currentLocation.listingImageUrl,
            mapPinImageUrl: currentLocation.mapPinImageUrl
        }),
        [currentLocation.listingImageUrl, currentLocation.mainImageUrl, currentLocation.mapPinImageUrl]
    );

    const makeImageUploadHandler = React.useCallback(
        (changeHandler: (event: any) => void, fieldName: string) => (imageUrl: string) => {
            changeHandler({ target: { name: fieldName, value: imageUrl } });
        },
        []
    );

    const handleClose = React.useCallback(() => {
        setEditing(false);
    }, []);

    return (
        <Panel title="Photos" divider showAction={!isEditing} onClick={handleEditClick}>
            {isEditing ? (
                <Formik onSubmit={handleSubmit} initialValues={initialFormValues}>
                    {({ handleSubmit: onSubmit, handleChange, values, isSubmitting }) => {
                        const mainImageChangeHandler = makeImageUploadHandler(
                            handleChange,
                            EFormField.MAIN_IMAGE_URL
                        );
                        const listingImageChangeHandler = makeImageUploadHandler(
                            handleChange,
                            EFormField.LISTING_IMAGE_URL
                        );
                        const mapPinImageChangeHandler = makeImageUploadHandler(
                            handleChange,
                            EFormField.MAP_PIN_IMAGE_URL
                        );

                        return (
                            <form onSubmit={onSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <InputLabel shrink>Banner</InputLabel>
                                        <MuiImagePicker
                                            unremovable
                                            onSelect={mainImageChangeHandler}
                                            url={values.mainImageUrl}
                                            aspect={600 / 260}
                                            uploadFileName={getFileName(currentLocation.mainImageUrl)}
                                        />
                                        <Typography variant="caption">
                                            This will be used to represent the location throughout the app.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <InputLabel shrink>Thumbnail</InputLabel>
                                        <MuiImagePicker
                                            unremovable
                                            url={values.listingImageUrl}
                                            onSelect={listingImageChangeHandler}
                                            aspect={465 / 496}
                                            uploadFileName={getFileName(currentLocation.listingImageUrl)}
                                        />
                                        <Typography variant="caption">
                                            This will be used to represent the location within lists such as a
                                            user&apos;s favourited locations
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <InputLabel shrink>Map Pin</InputLabel>
                                        <MuiImagePicker
                                            smallText
                                            unremovable
                                            url={values.mapPinImageUrl}
                                            onSelect={mapPinImageChangeHandler}
                                            aspect={41 / 63}
                                            uploadFileName={getFileName(currentLocation.mapPinImageUrl)}
                                        />
                                        <Typography variant="caption">
                                            Apply a map pin for this individual location. This is optional.
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Box mt={2} display="flex" justifyContent="flex-end">
                                    <Button onClick={handleClose} variant="outlined" color="primary">
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        sx={{ ml: 1.5 }}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            ) : (
                <LocationImagesCardComponent location={currentLocation} />
            )}
        </Panel>
    );
};

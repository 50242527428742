import React from 'react';
import { Card } from '@mui/material';
import { isArrayOf, isOptionalString, isString } from 'lib/typeguards';
import { TableSkeletonComponent } from 'ui/skeleton/TableSkeleton';
import { MuiTableScrollItems } from 'ui/table/MuiTable';
import { Notification } from '../models/Notification';
import { notificationApi } from '../notificationApi';
import { NotificationReceiptsTable } from '../NotificationReceptsTable';
import logger from 'lib/logger';

const DEFAULT_QUERY = { limit: 100 };

export interface INotificationReceipt {
    _id: string;
    userFullName?: string;
    skipMessage?: string;
    errorMessage?: string;
}

export const isNotificationReceipt = (input: any): input is INotificationReceipt =>
    isString(input._id) &&
    isOptionalString(input.userFullName) &&
    isOptionalString(input.skipMessage) &&
    isOptionalString(input.errorMessage);

const getReceipts = async (notificationId: string, options: Record<string, string | number>) => {
    const res = await notificationApi.getNotificationReceipts(notificationId, options);
    if (!isArrayOf(isNotificationReceipt, res.body.items)) {
        logger.error(`Expected Notification Receipts, but got ${JSON.stringify(res.body)}`);
        return { receipts: [] as INotificationReceipt[] };
    }
    return { receipts: res.body.items, nextKey: res.body.page.nextKey };
};

interface NotificationReceiptsCardProps {
    notification: Notification<string>;
}

export const NotificationReceiptsCard: React.FC<NotificationReceiptsCardProps> = props => {
    const { notification } = props;
    const [receipts, setReceipts] = React.useState([] as INotificationReceipt[]);
    const [nextKey, setNextKey] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
        (async function () {
            // Get Receipts from API
            const { receipts: newReceipts, nextKey: newNextKey } = await getReceipts(
                notification._id,
                DEFAULT_QUERY
            );
            setReceipts(newReceipts);
            setNextKey(newNextKey);
            setIsLoading(false);
        })();
    }, [notification._id]);

    const handleScroll = async () => {
        const options: Record<string, string | number> = {
            startKey: nextKey,
            ...DEFAULT_QUERY
        };
        const { receipts: newReceipts, nextKey: newNextKey } = await getReceipts(notification._id, options);
        setReceipts([...receipts, ...newReceipts]);
        setNextKey(newNextKey);
    };
    const scroll: MuiTableScrollItems = {
        isMoreItems: !!nextKey && mounted,
        key: nextKey,
        onScroll: handleScroll
    };

    return (
        <React.Fragment>
            {(isLoading || notification.status === 'PENDING') && (
                <Card>
                    <TableSkeletonComponent tableBodyRows={5} tableHeadColumns={2} />
                </Card>
            )}
            {!isLoading && notification.status !== 'PENDING' && (
                <NotificationReceiptsTable scroll={scroll} data={receipts} />
            )}
        </React.Fragment>
    );
};

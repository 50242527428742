import React from 'react';
import { Box, Button, Card, CardActions, Divider, IconButton, styled, Typography } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import clsx from 'clsx';
import { Form, FormikConfig, FormikValues, isFunction } from 'formik';
import { MuiForm } from 'lib/Form';
import { LoadingButton } from 'ui/buttons/LoadingButton';

interface IMuiCardFormProps<T extends FormikValues> extends FormikConfig<T> {
    title?: string;
    imageSrc?: string;
    childrenWhileDisplaying: React.ReactNode;
    childrenWhileEditing: ((values: FormikValues) => React.ReactNode) | React.ReactNode;
    className?: string;
    boxClassName?: string;
    saveButtonText?: string;
    cancelButtonText?: string;
    onSubmit: (values: Record<string, any>) => Promise<boolean>; // For success or failure
}

const PREFIX = 'MuiCardForm';

const classes = {
    boxPadding: `${PREFIX}-boxPadding`,
    imageHeader: `${PREFIX}-imageHeader`,
    titleWrapper: `${PREFIX}-titleWrapper`,
    form: `${PREFIX}-form`,
    cancelButton: `${PREFIX}-cancelButton`
};

const StyledCard = styled(Card)(({ theme }) => ({
    [`& .${classes.boxPadding}`]: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4)
    },
    [`& .${classes.imageHeader}`]: {
        marginRight: theme.spacing(1),
        height: theme.spacing(4.5),
        width: 'auto'
    },
    [`& .${classes.titleWrapper}`]: {
        marginLeft: theme.spacing(1),
        minHeight: theme.spacing(5.25)
    },
    [`& .${classes.form}`]: {
        padding: theme.spacing(0)
    },
    [`& .${classes.cancelButton}`]: {
        marginRight: theme.spacing(1)
    }
}));

export const MuiCardForm: React.FC<IMuiCardFormProps<Record<string, any> & FormikValues>> = props => {
    const {
        title,
        imageSrc,
        onSubmit,
        initialValues,
        validationSchema,
        childrenWhileDisplaying,
        childrenWhileEditing,
        className,
        boxClassName,
        saveButtonText,
        cancelButtonText
    } = props;
    const [isEditing, setIsEditing] = React.useState(false);

    const handleEditClicked = React.useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleCancelClicked = React.useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleSubmit = React.useCallback(
        async (values: Record<string, any>) => {
            const success = await onSubmit(values);
            if (success) {
                setIsEditing(false);
            }
        },
        [onSubmit]
    );

    return (
        <StyledCard className={className}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={1}
                justifyContent="space-between"
            >
                <Box display="flex" flexDirection="row" alignItems="center" className={classes.titleWrapper}>
                    {!!imageSrc && <img src={imageSrc} className={classes.imageHeader} />}
                    {!!title && <Typography variant="h6">{title}</Typography>}
                </Box>
                {!isEditing && (
                    <IconButton onClick={handleEditClicked} size="large">
                        <Edit />
                    </IconButton>
                )}
            </Box>
            <Divider />
            {isEditing && (
                <MuiForm
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ submitForm, isSubmitting, values }) => (
                        <Form className={classes.form}>
                            <Box className={clsx(classes.boxPadding, boxClassName)}>
                                {isFunction(childrenWhileEditing)
                                    ? childrenWhileEditing(values)
                                    : childrenWhileEditing}
                            </Box>
                            <CardActions>
                                <Box display="flex" flexDirection="row" justifyContent="flex-end" flex={1}>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={handleCancelClicked}
                                        disabled={isSubmitting}
                                        className={classes.cancelButton}
                                    >
                                        {cancelButtonText ?? 'Cancel'}
                                    </Button>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                    >
                                        {saveButtonText ?? 'Save'}
                                    </LoadingButton>
                                </Box>
                            </CardActions>
                        </Form>
                    )}
                </MuiForm>
            )}
            {!isEditing && (
                <Box className={clsx(classes.boxPadding, boxClassName)}>{childrenWhileDisplaying}</Box>
            )}
        </StyledCard>
    );
};

export enum EReportType {
    'ACTIVITY' = 'ACTIVITY',
    'RECONCILIATION' = 'RECONCILIATION',
    'BALANCE' = 'BALANCE'
}

export enum EReportFormat {
    'ITEMIZED' = 'ITEMIZED',
    'SUMMARY' = 'SUMMARY'
}
export enum EScheduleReportInterval {
    'DAILY' = 'DAILY',
    'WEEKLY' = 'WEEKLY',
    'MONTHLY' = 'MONTHLY'
}

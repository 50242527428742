import React from 'react';
import { useDispatch } from 'react-redux';
import { MainLayout } from 'layouts/MainLayout';
import { EmptyState } from 'components/utils/emptyState';
import { CreateVoucherModal } from 'components/vouchers/modals/CreateVoucherModal';
import EmptyImage from 'static/vouchers-image-placeholder.svg';
import { AddVoucherEffectsModal } from 'components/vouchers/modals/AddVoucherEffectsModal';
import { getAllAudiences } from 'store/audiences/audiencesActions';
import { getAllPerks } from 'store/perks/perksActions';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { VOUCHERS_PAGE_LIMIT, useVouchersData } from 'components/vouchers/hooks/useVouchersData';
import { useVouchersFilter } from 'components/vouchers/hooks/useVouchersFilter';
import { Box, Button, TablePagination, styled } from '@mui/material';
import { MuiTheme } from 'config/theme';
import { GridLinkCell } from 'lib/MuiGrid/GridLinkCell';
import { MARKETING_VOUCHERS_VIEW } from 'config/routes';
import { VoucherFilters } from 'components/vouchers/VouchersFilters';
import { useVouchersActions } from 'components/vouchers/hooks/useVouchersActions';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { Voucher } from 'components/vouchers/models/VoucherModel';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import DeleteIcon from '@mui/icons-material/Delete';

// Keep the version updated
const VOUCHERS_GRID_STORAGE_NAME = `${GridStorageName.Vouchers}-2`;

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 60,
    color: theme.palette.divider,
    '.MuiDataGrid-row:hover &': {
        color: theme.palette.primary.main
    }
}));

function VouchersPage() {
    const dispatch = useDispatch();

    const [filter, onFilterChange, sortModel, onSortModelChange] = useVouchersFilter();
    const { pagedVouchers, loading, page, onPageChange, count, resetVouchers } = useVouchersData(filter);

    const {
        createdVoucher,
        createEffect,
        createVoucher,
        closeModals,
        handleCreateButtonClick,
        createModalOpen,
        actionsLoading,
        voucherToDelete,
        deleteVoucher,
        deleteVoucherConfirm
    } = useVouchersActions(resetVouchers);

    React.useEffect(() => {
        getAllAudiences()(dispatch);
        getAllPerks()(dispatch);
    }, [dispatch]);

    const renderViewCell = React.useCallback(
        (params: GridCellParams) => (
            <GridLinkCell id={params.value} param="voucherId" path={MARKETING_VOUCHERS_VIEW} />
        ),
        []
    );

    const renderDeleteCell = React.useCallback(
        (item: GridCellParams<Voucher, Voucher>) => (
            <StyledButton variant="text" size="small" onClick={deleteVoucher(item.row)}>
                <DeleteIcon />
            </StyledButton>
        ),
        [deleteVoucher]
    );

    const dateValueFormatter = React.useCallback<GridColDef['valueGetter']>(
        ({ value }) => new Date(value).toLocaleString(),
        []
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: '_id',
                headerName: 'ID',
                disableReorder: true,
                disableExport: true,
                width: 270,
                sortable: false,
                renderCell: renderViewCell
            },
            {
                field: 'title',
                headerName: 'Title',
                disableExport: true,
                width: 230,
                sortable: false
            },
            {
                field: 'code',
                headerName: 'Claim Code',
                disableExport: true,
                width: 150,
                sortable: true
            },
            {
                field: 'startDate',
                headerName: 'Start Date',
                disableExport: true,
                width: 196,
                sortable: true,
                type: 'dateTime',
                valueGetter: dateValueFormatter
            },
            {
                field: 'endDate',
                headerName: 'End Date',
                disableExport: true,
                width: 196,
                sortable: true,
                type: 'dateTime',
                valueGetter: dateValueFormatter
            },
            {
                field: 'voucherSchemeId',
                headerName: 'Scheme ID',
                disableExport: true,
                width: 270,
                sortable: false
            },
            {
                field: 'numGlobalRedemptions',
                headerName: 'Max Redemptions',
                disableExport: true,
                width: 180,
                sortable: false,
                align: 'right'
            },
            {
                field: 'numRedemptionsPerUser',
                headerName: 'Redemptions per Customer',
                disableExport: true,
                width: 230,
                sortable: false,
                align: 'right'
            },
            {
                field: 'Delete',
                headerName: 'Delete',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 120,
                renderCell: renderDeleteCell
            }
        ],
        [dateValueFormatter, renderDeleteCell, renderViewCell]
    );

    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={VOUCHERS_PAGE_LIMIT}
                rowsPerPageOptions={[VOUCHERS_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );

    const getId = React.useCallback((voucher: Voucher) => voucher._id, []);

    return (
        <MainLayout
            pageName="Vouchers"
            pageDescription="Vouchers can be exchanged for perks to redeem within the app."
        >
            <Box pb={7} height="100%">
                <Box pb={1}>
                    <VoucherFilters
                        filter={filter}
                        onChange={onFilterChange}
                        onCreateClick={handleCreateButtonClick}
                        loading={loading || actionsLoading}
                    />
                </Box>
                {loading || (Array.isArray(pagedVouchers) && pagedVouchers.length > 0) ? (
                    <MuiGrid
                        columns={columns}
                        rows={pagedVouchers}
                        loading={loading}
                        getRowId={getId}
                        disableMultipleSelection
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        hideFooter={false}
                        rowCount={count}
                        pagination
                        disableColumnFilter
                        sortingMode="server"
                        paginationMode="server"
                        storageName={VOUCHERS_GRID_STORAGE_NAME}
                        components={{
                            Pagination
                        }}
                    />
                ) : (
                    <EmptyState
                        headerText="Create vouchers to give to your customers"
                        paragraphText="Vouchers can be used to award perks to your customers or group them into audiences"
                        buttonText="Create voucher"
                        imageUrl={EmptyImage}
                        onClick={handleCreateButtonClick}
                    />
                )}
            </Box>
            <CreateVoucherModal
                isLoading={actionsLoading || loading}
                open={createModalOpen}
                onClose={closeModals}
                onSubmit={createVoucher}
            />
            <AddVoucherEffectsModal
                open={!!createdVoucher}
                voucher={createdVoucher}
                onClose={closeModals}
                onSubmit={createEffect}
            />
            <DeleteDialog
                open={!!voucherToDelete}
                onClose={closeModals}
                loading={actionsLoading}
                onDelete={deleteVoucherConfirm}
                title={`Delete "${voucherToDelete?.title}"?`}
                protectionWord="DELETE"
                content="If you delete the voucher, you won’t be able to recover it. To confirm deletion, type 'DELETE' in the text input field."
            />
        </MainLayout>
    );
}

export default VouchersPage;

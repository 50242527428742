// TODO: remove it
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { History } from 'history';
import {
    MOBILE_CONTENT_CONFIRMATION,
    MOBILE_CONTENT_LAYOUT_CONFIG,
    MOBILE_CONTENT_MODULE_CONFIGURATION,
    MOBILE_CONTENT_TEMPLATE_SELECTION
} from 'config/routes';
import { MuiRouterContext } from 'lib/MuiRouterContext';
import { ApplicationState } from 'store/store';
import { ContentLayout } from '../models/ContentLayout';
import { ContentModule } from '../models/ContentModule';

interface LayoutConfigStepperBodyProps {
    pathname: string;
    currentLayout: ContentLayout;
    currentModules?: ContentModule[];
}

interface IStep {
    path: string;
    label: string;
    query?: {
        layoutId?: string;
    };
    isClickable?: boolean;
    key: string;
}

const getLayoutConfigSteps = (currentLayout: ContentLayout, currentModules?: ContentModule[]) => [
    {
        path: MOBILE_CONTENT_TEMPLATE_SELECTION,
        label: 'Choose your template',
        isClickable: true,
        key: 'template-selection'
    },
    {
        path: MOBILE_CONTENT_LAYOUT_CONFIG,
        query: {
            layoutId: currentLayout && currentLayout.id
        },
        label: 'Configure your layout',
        isClickable: !!currentLayout,
        key: 'layout-config'
    },
    {
        path: MOBILE_CONTENT_MODULE_CONFIGURATION,
        query: {
            layoutId: currentLayout && currentLayout.id
        },
        label: 'Module configuration',
        isClickable: !!currentLayout && !!currentModules,
        key: 'module-config'
    },
    {
        path: MOBILE_CONTENT_CONFIRMATION,
        query: {
            layoutId: currentLayout && currentLayout.id
        },
        label: 'Confirm your choice',
        key: 'confirm'
    }
];

function findCurrentStep(steps: IStep[], pathname: string) {
    return steps.findIndex(step => pathname.includes(step.key));
}

function getStep(
    { label, path, query, isClickable }: IStep,
    index: number,
    activeStep: number,
    history: History
) {
    const isCompleted = activeStep > index;
    function handleClick() {
        history.push(query ? `${path.replace(/:layoutId/g, query.layoutId || '')}` : path);
    }
    return (
        <Step key={path} completed={isCompleted}>
            <StepButton disableRipple disabled={!isClickable} onClick={handleClick} color="inherit">
                <StepLabel>{label}</StepLabel>
            </StepButton>
        </Step>
    );
}

const LayoutConfigStepperBodyElement: React.FC<LayoutConfigStepperBodyProps> = ({
    pathname,
    currentLayout,
    currentModules
}) => {
    const layoutConfigSteps = getLayoutConfigSteps(currentLayout, currentModules);
    const activeStep = findCurrentStep(layoutConfigSteps, pathname);
    const history = useHistory();
    return (
        <Stepper
            nonLinear
            sx={{ backgroundColor: 'transparent', mb: 2 }}
            alternativeLabel
            activeStep={activeStep}
        >
            {layoutConfigSteps.map((step, index) => getStep(step, index, activeStep, history))}
        </Stepper>
    );
};

const mapStateToProps = ({ content }: ApplicationState) => ({
    currentLayout: content.currentLayout,
    currentModules: content.currentModules || []
});

const LayoutConfigStepperBody = connect(mapStateToProps)(LayoutConfigStepperBodyElement);

export const LayoutConfigStepper = () => (
    <MuiRouterContext.Consumer>
        {({ pathname }) => <LayoutConfigStepperBody pathname={pathname} />}
    </MuiRouterContext.Consumer>
);

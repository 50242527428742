/* eslint-disable @typescript-eslint/ban-types */
import { GridSize } from '@mui/material';
import { Option } from 'lib/types';
import { DateRangePickerInputLabels } from './DateRangePickerInput';

type FormData = any;

export type FormFieldType =
    | 'text'
    | 'select'
    | 'multiline'
    | 'switch'
    | 'checkbox'
    | 'number'
    | 'color'
    | 'keyFile'
    | 'geo'
    | 'multiSelect'
    | 'image'
    | 'searchableSelect'
    | 'sortableField'
    | 'label'
    | 'openingHoursInput'
    | 'chips'
    | 'prefixInput'
    | 'dateRangePicker';

interface BaseFormItem {
    label?: React.ReactNode;
    labels?: DateRangePickerInputLabels;
    description?: React.ReactNode;
    itemForm?: AutoFormFields;
    errors?: { [key: string]: string };
    fields?: any[];
    value?: any;
    placeholder?: string;
    FormComponent?: any;
    type?: FormFieldType;
    options?: Option[];
    validation?: string | string[];
    inputType?: string;
    width?: GridSize;
    fallbackToDefaultColour?: boolean;
    builtInLabel?: boolean;
}
interface ImageFormItem extends BaseFormItem {
    type: 'image';
    aspect?: number;
    unremovable?: boolean;
}

interface PrefixFormItem extends BaseFormItem {
    type: 'prefixInput';
    prefix: string;
}

export type FormItem = BaseFormItem | ImageFormItem | PrefixFormItem;

export type StepperForm = LegacyForm & { fields: FormStep[] };
export interface FormStep {
    stepTitle: string;
    stepSubTitle?: string;
    data: AutoFormFields;
}

export interface AutoFormFields {
    [key: string]: FormItem;
}

// DEPRECATED, use lib/Form.tsx instead
export class LegacyForm {
    fields: AutoFormFields | FormStep[];
    submit: (data: FormData) => Promise<any> | void | Function;
    handleResponse: (res: any) => void;
    updateParentComponent: Function;

    constructor(
        formFields: AutoFormFields | FormStep[],
        submit?: (data: FormData) => Promise<any> | Function | void,
        handleResponse?: (res: any) => void
    ) {
        console.warn(
            'LegacyForm and AutoForm are deprecated!!!\nPlease, use new Form component instead.\n\nIf you have any questions, ask Andrii.'
        );
        this.fields = formFields;
        this.submit = submit || (() => console.log('submit'));
        this.handleResponse = handleResponse || (() => {});
    }
    setUpdateForParent = (func: Function) => {
        this.updateParentComponent = func;
    };
    handleSubmit = async (data: FormData) => {
        this.update(data);
        const response = await this.submit(data);
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await this.handleResponse(response);
    };
    getFormData = (): FormData =>
        Object.entries(this.fields).reduce((acc, [key, field]) => {
            acc[key] = field.value;
            return acc;
        }, {} as FormData);
    update(data: FormData) {
        // It fields is array: our form is stepper form and we should update data for each step
        if (Array.isArray(this.fields)) {
            this.fields.forEach((field, index) => {
                (this.fields as FormStep[])[index].data = Object.keys(field.data).reduce(
                    (acc: AutoFormFields, item: string) => {
                        const value =
                            item in data ? data[item] : (this.fields as FormStep[])[index].data[item].value;
                        acc[item] = {
                            ...(this.fields as FormStep[])[index].data[item],
                            value
                        };
                        return acc;
                    },
                    {}
                );
            });
        } else {
            this.fields = Object.keys(this.fields).reduce((acc: AutoFormFields, item: string) => {
                const value = item in data ? data[item] : (this.fields as AutoFormFields)[item].value;
                acc[item] = {
                    ...(this.fields as AutoFormFields)[item],
                    value
                };
                return acc;
            }, {});
        }
        if (this.updateParentComponent) {
            this.updateParentComponent(data);
        }
        return this;
    }
}

import React, { useEffect } from 'react';
import { Drawer, List, Typography, styled, Theme, CSSObject, IconButton } from '@mui/material';
import { Claims } from '@pepperhq/auth-client';
import clsx from 'clsx';
import { Page, PageChild } from 'components/navigation/models/Page';
import { mobileToolbarHeight } from 'config/theme';
import { pageToTitle } from 'lib/helpers';
import { AppDrawerNavItem } from 'ui/AppDrawerNavItem';
import { Column } from 'ui/Flex';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { isDefined } from 'lib/typeguards';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useToggle } from 'lib/hooks/useToggle';

const PREFIX = 'AppDrawer';

const classes = {
    paper: `${PREFIX}-paper`,
    title: `${PREFIX}-title`,
    nav: `${PREFIX}-nav`,
    toolbarIe11: `${PREFIX}-toolbarIe11`,
    placeholder: `${PREFIX}-placeholder`,
    signout: `${PREFIX}-signout`,
    toolbar: `${PREFIX}-toolbar`,
    active: `${PREFIX}-active`,
    drawerFooterText: `${PREFIX}-drawerFooterText`
};
const openedMixin = (theme: Theme): CSSObject => ({
    width: theme.spacing(224 / 8),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 1px)`
    }
});

const MuiDrawer = styled(Drawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    height: '100%',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

const ArrowButton = styled(IconButton, { shouldForwardProp: prop => prop !== 'open' })<{ open?: boolean }>(
    ({ theme, open }) => ({
        position: 'absolute',
        top: theme.spacing(4),
        width: theme.spacing(3),
        height: theme.spacing(3),
        right: 0,
        zIndex: 5,
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        transform: `translateX(50%) rotate(${open ? 0 : 180}deg)`,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    })
);

const ArrowButtonIcon = styled(ArrowBackIosNewIcon)(({ theme }) => ({
    fontSize: theme.spacing(1.5)
}));

const StyledNav = styled('nav')(({ theme }) => ({
    height: '100%',

    [`& .${classes.paper}`]: {
        width: theme.spacing(28),
        zIndex: 0,
        backgroundColor: theme.palette.background.default,
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            height: '100%',
            top: mobileToolbarHeight
        }
    },
    [`& .${classes.title}`]: {
        marginBottom: theme.spacing(0.5),
        width: '100%',
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(3),
        height: 'auto'
    },
    [`& .${classes.nav}`]: {
        height: '100%'
    },
    [`& .${classes.toolbarIe11}`]: {
        display: 'flex'
    },
    [`& .${classes.placeholder}`]: {
        height: 29
    },
    [`& .${classes.signout}`]: {
        marginTop: 'auto',
        marginBottom: theme.spacing(1)
    },
    [`& .${classes.toolbar}`]: {
        ...theme.mixins.toolbar,
        paddingLeft: theme.spacing(3),
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    [`& .${classes.active}`]: {
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    [`& .${classes.drawerFooterText}`]: {
        padding: theme.spacing(1, 2),
        color: theme.palette.text.secondary
    }
}));

interface AppDrawerProps {
    className?: string;
    activePage: Page;
    pages: Page[];
    claims: Claims;
    disablePermanent?: boolean;
    mobileOpen?: boolean;
    pathname: string;
    onClose?: (event: React.MouseEvent) => void;
    onOpen?: (event: React.MouseEvent) => void;
}

interface RenderNavItemsProps {
    props: AppDrawerProps;
    icon?: React.ReactNode;
    pages: Page[];
    depth: number;
    settings?: IPublicAndPrivateSettings;
    isOpen?: boolean;
}

const renderNavItems: React.FC<RenderNavItemsProps> = ({ pages, ...params }) => (
    <List disablePadding>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define*/}
        {pages.reduce((items, page: Page) => reduceChildRoutes({ items, page, ...params }), [])}
    </List>
);

const isResourceAllowed = (page: Page, claims: Claims) =>
    page.resources ? claims && claims.hasPermissions(page.resources) : true;

const isConditionValid = (
    claims: Claims,
    settings?: IPublicAndPrivateSettings,
    condition?: (settings: IPublicAndPrivateSettings) => boolean
) => {
    if (isDefined(condition)) {
        if (!settings || !claims) {
            return false;
        }
        return condition(settings);
    }
    return true;
};

function reduceChildRoutes({
    props,
    items,
    page,
    depth,
    settings,
    isOpen
}: {
    props: AppDrawerProps;
    items: React.ReactNode[];
    page: Page;
    depth: number;
    settings?: IPublicAndPrivateSettings;
    isOpen?: boolean;
}) {
    // Children to the SUBHEADING
    if (page.children && page.children.length) {
        const title = pageToTitle(page);

        const hasAllowedResources = page.children.some((children: PageChild) =>
            isResourceAllowed(children, props.claims)
        );
        if (
            hasAllowedResources &&
            isResourceAllowed(page, props.claims) &&
            isConditionValid(props.claims, settings, page.condition)
        ) {
            items.push(
                <AppDrawerNavItem
                    open={isOpen}
                    Icon={page.icon}
                    depth={depth}
                    key={title}
                    title={title}
                    isActive={false}
                >
                    {renderNavItems({
                        props,
                        isOpen,
                        pages: page.children,
                        depth: depth + 1,
                        icon: page.icon,
                        settings
                    })}
                </AppDrawerNavItem>
            );
        }
    } else {
        const title = pageToTitle(page);
        const currentPage = page.children && page.children.length === 1 ? page.children[0] : page;
        const isActive = currentPage.pathname && props.pathname.startsWith(currentPage.pathname);
        if (
            isResourceAllowed(currentPage, props.claims) &&
            isConditionValid(props.claims, settings, currentPage.condition)
        ) {
            items.push(
                <AppDrawerNavItem
                    depth={depth}
                    Icon={currentPage.icon}
                    key={title}
                    title={title}
                    href={currentPage.pathname}
                    onClick={props.onClose}
                    isActive={isActive}
                    open={isOpen}
                />
            );
        }
    }

    return items;
}

export const AppDrawer = (props: AppDrawerProps) => {
    const { className, pages } = props;
    const [isOpen, setIsOpen] = useToggle(true);
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const drawerFooter = (
        <Typography variant="caption" display="block" gutterBottom className={classes.drawerFooterText}>
            © {new Date().getFullYear()} PepperHQ LTD
        </Typography>
    );
    const drawer = (
        <Column className={classes.nav}>
            {renderNavItems({ isOpen, props, pages, depth: 0, settings })}
            {isOpen && drawerFooter}
        </Column>
    );

    // Center the selected item in the list container.
    const activeElement = document.querySelector(`.${classes.active}`);
    useEffect(() => {
        if (activeElement && activeElement.scrollIntoView) {
            activeElement.scrollIntoView({});
        }
    }, [activeElement]);

    return (
        <StyledNav className={className}>
            <ArrowButton open={isOpen} size="small" onClick={setIsOpen} color="primary" aria-label="hide">
                <ArrowButtonIcon />
            </ArrowButton>
            <MuiDrawer
                classes={{
                    paper: clsx(classes.paper, 'hidden-scroll')
                }}
                variant="permanent"
                open={isOpen}
            >
                {drawer}
            </MuiDrawer>
        </StyledNav>
    );
};

enum AuthActionTypes {
    SIGN_IN_START = 'SIGN_IN_START',
    SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
    SIGN_IN_FAIL = 'SIGN_IN_FAIL',
    GET_ACCESS_SUCCESS = 'GET_ACCESS_SUCCESS',
    GET_ACCESS_FAIL = 'GET_ACCESS_FAIL',
    SIGN_OUT = 'SIGN_OUT',
    AUTHORIZE_SUCCESS = 'AUTHORIZE_SUCCESS',
    AUTHORIZE_FAIL = 'AUTHORIZE_FAIL',
    UNAUTHORIZE = 'UNAUTHORIZE',
    LEGACY_AUTHORIZE_SUCCESS = 'AUTH_LEGACY_AUTHORIZE_SUCCESS',
    LEGACY_AUTHORIZE_ERROR = 'AUTH_LEGACY_AUTHORIZE_ERROR',
    LEGACY_AUTHORIZE_START = 'AUTH_LEGACY_AUTHORIZE_START'
}

export default AuthActionTypes;

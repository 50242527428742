import React from 'react';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';

export const SquarePaymentsSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId
}) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <SettingDisplay label="Production Environment">
                {settings?.square?.isProduction && <Done />}
                {!settings?.square?.isProduction && <Close />}
            </SettingDisplay>
        )}
    </>
);

import { ActionCreator } from 'redux';
import { isPerk, Perk } from 'components/loyalty/models/PerkModel';
import { perkApi } from 'components/loyalty/PerkApi';
import logger from 'lib/logger';
import { PerksActionType } from './perksActionTypes';
import { isDefined } from 'lib/typeguards';
import { awardEnrichmentApi } from 'components/loyalty/AwardEnrichmentsApi';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';
import { GridSelectionModel } from '@mui/x-data-grid-pro';

interface IBaseReducerAction {
    type: PerksActionType;
}
interface IUnknownAction extends IBaseReducerAction {
    type: PerksActionType.UNKNOWN;
}
interface IProgressAction extends IBaseReducerAction {
    type: PerksActionType.START_REQUEST | PerksActionType.START_DELETE_REQUEST;
}
interface ILoadingAction extends IBaseReducerAction {
    type: PerksActionType.LOAD_SUCCESS | PerksActionType.LOAD_ERROR;
    perks?: Perk[];
    nextKey?: string;
    pages?: (string | undefined)[];
    endOfData?: boolean;
}
interface ILoadAllAction extends IBaseReducerAction {
    type: PerksActionType.LOAD_ALL_SUCCESS;
    perks?: Perk[];
    codeSpecificEntries: Record<string, string>;
    perkIdToTitle?: Map<string, string>;
}
interface ICreateAction extends IBaseReducerAction {
    type: PerksActionType.CREATE_SUCCESS;
    perk: Perk;
}
interface ICreateClubAction extends IBaseReducerAction {
    type: PerksActionType.CREATE_SUCCESS_CLUB;
    perk: Perk;
}
interface IUpdateAction extends IBaseReducerAction {
    type: PerksActionType.UPDATE_SUCCESS;
    perk: Perk;
}
interface IModalAction extends IBaseReducerAction {
    type:
        | PerksActionType.TOGGLE_CREATE_MODAL
        | PerksActionType.TOGGLE_CLOSE_CREATE_MODAL
        | PerksActionType.CLOSE_MODALS
        | PerksActionType.TOGGLE_CLOSE_UPDATE_MODAL;
}
interface IItemModalAction extends IBaseReducerAction {
    type:
        | PerksActionType.SET_ITEM_TO_DELETE
        | PerksActionType.SET_OPEN_ITEM
        | PerksActionType.SET_ITEM_TO_RESET;
    index: number;
}

interface IResetAction extends IBaseReducerAction {
    type: PerksActionType.RESET_SUCCESS | PerksActionType.RESET_ERROR;
}

interface IDeleteAction extends IBaseReducerAction {
    type: PerksActionType.DELETE_ERROR | PerksActionType.DELETE_SUCCESS;
}

interface ISetPerkAwardCountAction extends IBaseReducerAction {
    type: PerksActionType.SET_PERK_AWARD_COUNT;
    count: number;
}

interface ISetSelectRowAction extends IBaseReducerAction {
    type: PerksActionType.SET_SELECTED_ROW;
    selectedRow: GridSelectionModel;
}

export type IPerksAction =
    | IUnknownAction
    | IProgressAction
    | ILoadingAction
    | ICreateAction
    | ICreateClubAction
    | IUpdateAction
    | IModalAction
    | IItemModalAction
    | IResetAction
    | IDeleteAction
    | ILoadAllAction
    | ISetPerkAwardCountAction
    | ISetSelectRowAction;

export function getAllPerks() {
    return async function (dispatch: ActionCreator<ILoadingAction | IProgressAction>) {
        dispatch({ type: PerksActionType.START_REQUEST });
        try {
            const response = await perkApi.getList();
            const awardEnrichmentsApi = await awardEnrichmentApi.getAllAwardEnrichments({ limit: 100 });

            if (!response.ok && !awardEnrichmentsApi.ok) {
                throw new Error(`Failed to load perks with code: ${response.statusCode}`);
            }
            // eslint-disable-next-line prefer-destructuring
            const perks: Perk[] = response.body.perks;
            const awardEnrichments: IAwardEnrichment[] = awardEnrichmentsApi.body.items;
            const perksMap = new Map<string, string>();
            let codeSpecificEntries: Record<string, string> = {};
            if (Array.isArray(perks)) {
                perks.forEach(perk => {
                    const { _id, title, internalTitle } = perk ?? {};
                    let finalTitle = '';
                    if ((title ?? '').trim()) {
                        if (internalTitle) {
                            finalTitle = `${internalTitle} - ${title}`;
                        } else {
                            finalTitle = title;
                        }
                    } else if (internalTitle) {
                        finalTitle = internalTitle;
                    }
                    perksMap.set(_id, finalTitle);
                });
                codeSpecificEntries = [...perks, ...awardEnrichments].reduce<Record<string, string>>(
                    (acc, perk) => {
                        const scopeMatchCode = perk.discount?.scopeMatchCode;
                        const matchCode = perk.basketQualification?.matchCode;
                        let title = '';
                        if (isPerk(perk) && perk.internalTitle && perk.internalTitle.trim() !== '') {
                            title = ` (${perk.internalTitle})`;
                        }
                        if (isDefined(perk.title) && perk.title.trim() !== '') {
                            title = ` (${perk.title})`;
                        }
                        if (scopeMatchCode) {
                            if (Array.isArray(scopeMatchCode)) {
                                scopeMatchCode.forEach(code => {
                                    acc[code] = `${code}${title}`;
                                });
                            } else {
                                acc[scopeMatchCode] = `${scopeMatchCode}${title}`;
                            }
                        }
                        if (matchCode) {
                            if (Array.isArray(matchCode)) {
                                matchCode.forEach(code => {
                                    acc[code] = `${code}${title}`;
                                });
                            } else {
                                acc[matchCode] = `${matchCode}${title}`;
                            }
                        }

                        return acc;
                    },
                    {}
                );
            }

            dispatch({
                perkIdToTitle: perksMap,
                perks,
                codeSpecificEntries,
                type: PerksActionType.LOAD_ALL_SUCCESS
            });
        } catch (e) {
            logger.error('Failed to load all perks', e);
            dispatch({
                type: PerksActionType.LOAD_ERROR
            });
        }
    };
}

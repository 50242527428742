import * as React from 'react';
import { format as dateFnsFormatter } from 'date-fns';

export const LANGUAGE_TO_DATE_FORMAT: Record<string, string> = {
    'sq-AL': 'yyyy-MM-dd',
    'ar-AE': 'dd/MM/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'en-AU': 'dd/MM/yyyy',
    'de-AT': 'dd.MM.yyyy',
    'fr-BE': 'dd/MM/yyyy',
    'nl-BE': 'dd/MM/yyyy',
    'bg-BG': 'yyyy-MM-dd',
    'ar-BH': 'dd/MM/yyyy',
    'sr-BA': 'yyyy-MM-dd',
    'be-BY': 'dd.MM.yyyy',
    'es-BO': 'dd-MM-yyyy',
    'pt-BR': 'dd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'en-CA': 'dd/MM/yyyy',
    'de-CH': 'dd.MM.yyyy',
    'fr-CH': 'dd.MM.yyyy',
    'it-CH': 'dd.MM.yyyy',
    'es-CL': 'dd-MM-yyyy',
    'zh-CN': 'yyyy-MM-dd',
    'es-CO': 'dd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'el-CY': 'dd/MM/yyyy',
    'cs-CZ': 'dd.MM.yyyy',
    'de-DE': 'dd.MM.yyyy',
    'da-DK': 'dd-MM-yyyy',
    'es-DO': 'MM/dd/yyyy',
    'ar-DZ': 'dd/MM/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'et-EE': 'dd.MM.yyyy',
    'fi-FI': 'dd.MM.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'en-GB': 'dd/MM/yyyy',
    'el-GR': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'zh-HK': 'yyyy年M月d日',
    'es-HN': 'MM-dd-yyyy',
    'hr-HR': 'dd.MM.yyyy.',
    'hu-HU': 'yyyy.MM.dd.',
    'in-ID': 'dd/MM/yyyy',
    'hi-IN': '३/६/१२',
    'en-IN': 'dd/MM/yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'en-IE': 'dd/MM/yyyy',
    'ar-IQ': 'dd/MM/yyyy',
    'is-IS': 'dd.MM.yyyy',
    'iw-IL': 'dd/MM/yyyy',
    'it-IT': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ko-KR': 'yyyy. MM. dd',
    'ar-KW': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'lt-LT': 'yyyy.MM.dd',
    'fr-LU': 'dd/MM/yyyy',
    'de-LU': 'dd.MM.yyyy',
    'lv-LV': 'yyyy.dd.MM',
    'ar-MA': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'mk-MK': 'dd.MM.yyyy',
    'en-MT': 'dd/MM/yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'sr-ME': 'dd.MM.yyyy.',
    'ms-MY': 'dd/MM/yyyy',
    'es-NI': 'MM-dd-yyyy',
    'nl-NL': 'd-MM-yyyy',
    'no-NO': 'dd.MM.yyyy',
    'en-NZ': 'dd/MM/yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'en-PH': 'MM/dd/yyyy',
    'pl-PL': 'dd.MM.yyyy',
    'es-PR': 'MM-dd-yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'es-PY': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    'ar-SA': 'dd/MM/yyyy',
    'sr-CS': 'dd.MM.yyyy.',
    'ar-SD': 'dd/MM/yyyy',
    'zh-SG': 'dd/MM/yyyy',
    'en-SG': 'MM/dd/yyyy',
    'es-SV': 'MM-dd-yyyy',
    'sr-RS': 'dd.MM.yyyy.',
    'sk-SK': 'dd.MM.yyyy',
    'sl-SI': 'dd.MM.yyyy',
    'sv-SE': 'yyyy-MM-dd',
    'ar-SY': 'dd/MM/yyyy',
    'th-TH': 'dd/MM/2555',
    'ar-TN': 'dd/MM/yyyy',
    'tr-TR': 'dd.MM.yyyy',
    'zh-TW': 'yyyy/MM/d',
    'uk-UA': 'dd.MM.yyyy',
    'es-UY': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
    'es-US': 'MM/dd/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'en-ZA': 'yyyy/MM/dd'
};

export function useLocalDateTimeFormat() {
    const defaultLanguage = 'en-GB';
    const locale = window.navigator.language || defaultLanguage;

    const getUserDateFormat = React.useCallback(() => {
        if (LANGUAGE_TO_DATE_FORMAT[locale]) {
            return LANGUAGE_TO_DATE_FORMAT[locale];
        }

        return LANGUAGE_TO_DATE_FORMAT[defaultLanguage];
    }, [locale, defaultLanguage]);

    const format = (value: Date, type: 'DATE' | 'TIME' = 'DATE') => {
        if (type === 'TIME') {
            return new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' }).format(value);
        }

        return dateFnsFormatter(value, getUserDateFormat());
    };

    return { format, userLocale: locale, getUserDateFormat };
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_TIERED_LOYALTY_METRIC_DELETE_ERROR,
    MESSAGE_TIERED_LOYALTY_METRIC_DELETE_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { UserMetric, UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';

export function useTieredLoyaltyMetricDeleteModal(
    metricDefinition: UserMetricDefinition | undefined,
    metricId: string | undefined,
    metric: UserMetric,
    setDefinition: (value: UserMetricDefinition) => void
) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        const result = await metricsApi.deleteMetric(metricDefinition?._id, metricId);
        setLoading(false);
        if (!result.ok) {
            logger.error(result.body.message, result.body);
            dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_METRIC_DELETE_ERROR, { variant: 'error' }));
            return;
        }
        dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_METRIC_DELETE_SUCCESS, { variant: 'success' }));
        setDefinition(result.body);
        setOpen(false);
    }, [dispatch, metricDefinition?._id, metricId, setDefinition]);
    const title = React.useMemo(() => `Delete "${metric?.name}" metric?`, [metric?.name]);
    const content = React.useMemo(
        () => 'This action is irreversible, if you delete this metric, the data will be lost.',
        []
    );
    return {
        metricsDeleteModalOpen: open,
        handleMetricDeleteOpen: handleOpen,
        handleMetricDeleteClose: handleClose,
        handleMetricDeleteSubmit: handleSubmit,
        loading,
        title,
        content
    };
}

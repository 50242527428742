import React from 'react';

import logger from 'lib/logger';
import { UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';
import { LoyaltyTier } from 'components/metrics/model/loyalty-tier';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_TIERED_LOYALTY_TIER_DELETE_ERROR,
    MESSAGE_TIERED_LOYALTY_TIER_DELETE_SUCCESS
} from 'config/messages';

export function useTieredLoyaltyTierDeleteModal(
    metricDefinition: UserMetricDefinition | undefined,
    tier: LoyaltyTier | undefined,
    loadTieredLoyalty: () => void
) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const listeners = React.useMemo(() => {
        if (!metricDefinition || !metricDefinition.listeners) {
            return [];
        }
        return metricDefinition.listeners.filter(
            item =>
                (item.type === 'ADD_TO_SEGMENT' || item.type === 'REMOVE_FROM_SEGMENT') &&
                item.segmentId === tier?.id
        );
    }, [metricDefinition, tier?.id]);
    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        const requests = listeners.map(item => metricsApi.deleteListener(metricDefinition?._id, item._id));
        const results = await Promise.all(requests);
        if (results.some(item => !item.ok)) {
            setLoading(false);
            logger.error('Failed to remove a tier', results);
            dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_TIER_DELETE_ERROR, { variant: 'error' }));
            return;
        }
        dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_TIER_DELETE_SUCCESS, { variant: 'success' }));
        loadTieredLoyalty();
        setLoading(false);
        setOpen(false);
    }, [dispatch, listeners, loadTieredLoyalty, metricDefinition?._id]);
    const title = React.useMemo(() => `Delete "${tier?.name}" tier?`, [tier?.name]);
    const content = React.useMemo(
        () => 'Are you sure you want to delete this tier? This action is irreversible.',
        []
    );
    return {
        tiersDeleteModalOpen: open,
        handleTierDeleteOpen: handleOpen,
        handleTierDeleteClose: handleClose,
        handleTierDeleteSubmit: handleSubmit,
        loading,
        title,
        content
    };
}

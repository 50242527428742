import React, { CSSProperties } from 'react';
import { styled } from '@mui/material';
import clsx from 'clsx';
/**
 * Semantic react components for common flex row/column patterns.
 */

const PREFIX = 'MuiFlex';

const classes = {
    row: `${PREFIX}-row`,
    rowWithGutter: `${PREFIX}-rowWithGutter`
};

const Root = styled('div')(({ theme }) => ({
    /* YOGA standard https://facebook.github.io/yoga */
    boxSizing: 'border-box',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexShrink: 0,
    alignContent: 'flex-start',

    borderWidth: 0,
    margin: 0,
    padding: 0,
    minWidth: 0,

    [`&.${classes.row}`]: {
        flexDirection: 'row'
    },

    [`&.${classes.rowWithGutter}`]: {
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(2)
        }
    }
}));

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
    align?: CSSProperties['justifyContent'];
    valign?: CSSProperties['alignItems'];
    wrap?: CSSProperties['flexWrap'];
    flexDirection?: CSSProperties['flexDirection'];
    gutter?: boolean;
    flex?: CSSProperties['flex'];
}

export const Row = (props: RowProps) => {
    const { align, flex, flexDirection, valign, wrap, style, className, children, gutter, ...rest } = props;
    const updatedStyle = {
        flex,
        flexWrap: wrap,
        justifyContent: align,
        alignItems: valign,
        flexDirection,
        ...style
    };
    const classNames = clsx(classes.row, className, {
        [classes.rowWithGutter]: gutter
    });
    return (
        <Root className={classNames} style={updatedStyle} {...rest}>
            {children}
        </Root>
    );
};

export type ColumnProps = React.HTMLAttributes<HTMLDivElement> & {
    align?: CSSProperties['alignItems'];
    valign?: CSSProperties['justifyContent'];
    flex?: CSSProperties['flex'];
    wrap?: CSSProperties['flexWrap'];
};

export const Column = (props: ColumnProps) => {
    const { align, valign, flex, wrap, style, className, children, ...rest } = props;
    const updatedStyle = { flex, flexWrap: wrap, justifyContent: valign, alignItems: align, ...style };
    return (
        <Root className={className} style={updatedStyle} {...rest}>
            {children}
        </Root>
    );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, styled, Theme } from '@mui/material';
import DoubleImagePlaceholder from 'static/double-image-placeholder.png';
import { ApplicationState } from 'store/store';
import { Column, Row } from 'ui/Flex';
import { IFavouritesModule } from '../models/ContentModule';
import { ContentAnchorProperties } from '../models/ContentTemplate';
import { ContentModuleRenderer, getBorderRadius, MobileLayoutTheme } from '../renderContentModule';
import { MUIStyledCommonProps } from '@mui/system';

const PLACEHOLDER_IMAGE = `${process.env.MEDIA_SERVICE_URL}/console/content/template_images/favourite_locations.jpg`;
function generateFavouritesImage(tenantId: string) {
    if (!tenantId) {
        return undefined;
    }
    return `${process.env.MEDIA_SERVICE_URL}/tenants/${tenantId}/app_media/add_fav_location.png`;
}

const PREFIX = 'renderFavouritesModule';

const classes = {
    title: `${PREFIX}-title`,
    secondaryAction: `${PREFIX}-secondaryAction`,
    content: `${PREFIX}-content`,
    img: `${PREFIX}-img`
};

const StyledColumn = styled(Column)(
    ({
        theme,
        layoutTheme,
        properties,
        favourites
    }: StyledFavouriteModuleProps & MUIStyledCommonProps<Theme>) => ({
        '&:hover': {
            backgroundColor: properties.isSelectable
                ? `rgba(92, 92, 92, ${properties.isSelected ? '0.16' : '0.12'})`
                : 'initial',
            transform: `${properties.isSelectable && !properties.isSelected ? 'scale(1.025)' : 'none'}`
        },
        width: '100%',
        padding: theme.spacing(0.5),
        paddingTop: '0px',
        margin: '0px',
        marginTop: theme.spacing(0.5),
        backgroundColor: properties.isSelected ? 'rgba(92, 92, 92, 0.16)' : 'initial',
        cursor: properties.isSelectable ? 'pointer' : 'inherit',
        transition: 'background-color 0.1s ease-in, transform 0.1s ease-in',
        border: `2px solid ${
            properties.isSelectable && properties.isSelected ? 'rgb(62, 141, 208)' : 'rgba(0,0,0,0)'
        }`,
        [`& .${classes.title}`]: {
            transition: 'color .15s linear',
            color: properties.titleTextColour ? properties.titleTextColour : properties.anchorTextColour,
            fontSize: layoutTheme.fontSize
        },
        [`& .${classes.secondaryAction}`]: {
            transition: 'color .15s linear',
            color: properties.tintColour,
            lineHeight: '0.9rem',
            textAlign: 'right',
            marginRight: layoutTheme.unit / 2,
            fontSize: layoutTheme.fontSize - 2
        },
        [`& .${classes.content}`]: {
            width: '100%',
            height: favourites.display?.height === 'DOUBLE' ? layoutTheme.height * 2 : layoutTheme.height,
            borderRadius: getBorderRadius(properties),
            transition: 'border-radius .15s linear',
            position: 'relative',
            overflow: 'hidden'
        },
        [`& .${classes.img}`]: {
            height: '100%',
            width: '100%',
            display: 'block',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            background: `url('${DoubleImagePlaceholder}')`,
            borderRadius: getBorderRadius(properties),
            transition: 'background-color .15s linear, border-radius .15s linear',
            backgroundColor: properties.primaryActionBackgroundColour
        }
    })
);

interface FavouriteModuleProps {
    favourites: IFavouritesModule;
    theme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

interface StyledFavouriteModuleProps {
    favourites: IFavouritesModule;
    layoutTheme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

export const renderFavouriteModule: ContentModuleRenderer = (
    favourites: IFavouritesModule,
    theme,
    properties,
    _
) => <FavouriteModuleComponent favourites={favourites} theme={theme} properties={properties} />;

function FavouriteModuleComponent({ favourites, properties, theme }: FavouriteModuleProps) {
    const tenant = useSelector((state: ApplicationState) => state.auth.tenant);

    const handleClick = React.useCallback(() => {
        if (properties.handleClick && !properties.isSelected) {
            properties.handleClick();
        }
    }, [properties]);
    return (
        <StyledColumn
            onClick={handleClick}
            favourites={favourites}
            layoutTheme={theme}
            properties={properties}
        >
            <Row flex={1} align="space-between" valign="flex-end">
                <Typography className={classes.title}>{favourites.title}</Typography>
                {favourites.secondaryAction && (
                    <Typography className={classes.secondaryAction}>
                        {favourites.secondaryAction?.title}
                    </Typography>
                )}
            </Row>
            <div className={classes.content}>
                <img
                    className={classes.img}
                    src={
                        theme.isTemplate
                            ? PLACEHOLDER_IMAGE
                            : generateFavouritesImage(tenant && tenant.tenantId)
                    }
                    alt=""
                />
            </div>
        </StyledColumn>
    );
}

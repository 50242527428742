function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b: number) => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
}

export async function preloadImage(link: string) {
    const [loaderExtensionType] = link.split('.').pop().split('?');
    const finalizedDataType = loaderExtensionType === 'svg' ? 'svg+xml' : loaderExtensionType;
    try {
        const result = await fetch(link);
        if (!result.ok) {
            throw new Error('Failed to load an image');
        }
        const buffer = await result.arrayBuffer();
        const base64Flag = `data:image/${finalizedDataType};base64,`;
        return base64Flag + arrayBufferToBase64(buffer);
    } catch (e) {
        return undefined;
    }
}

import { ContentModuleType } from './models/ContentModule';
import { ContentAnchorProperties, ContentAnchorStyle } from './models/ContentTemplate';
import { renderAwardsModule } from './renderers/renderAwardsModule';
import { renderFavouriteModule } from './renderers/renderFavouritesModule';
import { renderLocationModule } from './renderers/renderLocationModule';
import { renderLoyaltyBalanceModule } from './renderers/renderLoyaltyBalanceModule';
import { renderMarketingModule } from './renderers/renderMarketingModule';
import { renderPrimaryActionModule } from './renderers/renderPrimaryActionModule';

export interface MobileLayoutTheme {
    isTemplate?: boolean;
    fontSize: number;
    height: number;
    unit: number;
    borderRadius: number;
}

export const BASE_LAYOUT_THEME: MobileLayoutTheme = {
    fontSize: 12,
    height: 60,
    unit: 4,
    borderRadius: 8
};

const isCornerRadiusValid = (radius: number) => radius >= 8 && radius <= 18;

export const getBorderRadius = ({ useRoundedCorners, cornerRadius }: ContentAnchorProperties) => {
    if (useRoundedCorners) {
        if (cornerRadius && isCornerRadiusValid(cornerRadius)) {
            return `${cornerRadius}px`;
        }

        return `${BASE_LAYOUT_THEME.borderRadius}px`;
    }

    return 'unset';
};

export const LAYOUT_TEMPLATES_THEME: MobileLayoutTheme = {
    ...BASE_LAYOUT_THEME,
    isTemplate: true
};

export type ContentTemplateModuleRenderer = (
    properties: ContentAnchorProperties,
    style: ContentAnchorStyle
) => React.ReactElement;
export type ContentModuleRenderer = (
    item: any,
    theme: MobileLayoutTheme,
    properties: ContentAnchorProperties,
    style: ContentAnchorStyle
) => React.ReactElement;

export const renderContentModule: { [key in ContentModuleType]: ContentModuleRenderer } = {
    PRIMARY_ACTION: renderPrimaryActionModule,
    MARKETING: renderMarketingModule,
    LOCATIONS: renderLocationModule,
    FAVOURITES: renderFavouriteModule,
    AWARDS: renderAwardsModule,
    SECRET_DJ: renderPrimaryActionModule,
    LOYALTY_BALANCE: renderLoyaltyBalanceModule
};

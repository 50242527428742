import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, Typography } from '@mui/material';
import { debounce } from 'debounce';
import { customerApi } from 'components/customers/customerApi';
import { Customer, CustomerRole } from 'components/customers/models/Customer';
import {
    MESSAGE_MARKETING_AUDIENCE_USERS_ADDED_ERROR,
    MESSAGE_MARKETING_AUDIENCE_USERS_ADDED_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { MultiSelectSuggest } from 'lib/MultiSelectSuggest';
import { Option } from 'lib/types';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { segmentsApi } from '../segmentsApi';

interface AudienceAddCustomersModalComponentProps {
    onClose: () => void;
    segmentId: string;
    selectedCustomers: { userId: string }[];
}

const AudienceAddCustomersModalComponent: React.FC<AudienceAddCustomersModalComponentProps> = props => {
    const { onClose, selectedCustomers, segmentId } = props;
    const dispatch = useDispatch();
    const [customers, setCustomers] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [value, setValue] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const options = customers.map(item => ({ value: item._id, label: item.fullName }));
    React.useEffect(() => {
        async function load() {
            try {
                setLoading(true);
                const customersResult = await customerApi.getList({
                    queryParameters: [
                        { key: 'name', value: search },
                        { key: 'limit', value: 40 }
                    ]
                });
                if (!customersResult.ok) {
                    throw new Error(customersResult.body);
                }
                setCustomers(
                    customersResult.body.items.filter(
                        (item: Customer) =>
                            !item.deleted &&
                            !item.roles.includes(CustomerRole.ADMIN) &&
                            selectedCustomers.findIndex(selectedItem => item._id === selectedItem.userId) < 0
                    )
                );
            } catch (e) {
                setCustomers([]);
                setSearch('');
            } finally {
                setLoading(false);
            }
        }
        load();
    }, [search, selectedCustomers]);

    const handleSubmit = React.useCallback(async () => {
        try {
            setLoading(true);
            const result = await segmentsApi.addToSegment(
                segmentId,
                value.map(item => item.value)
            );
            if (!result.ok) {
                throw new Error(result.body);
            }
            dispatch(
                enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_USERS_ADDED_SUCCESS(result.body.added), {
                    variant: 'success'
                })
            );
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_USERS_ADDED_ERROR, { variant: 'error' }));
        } finally {
            setLoading(false);
            onClose();
        }
    }, [dispatch, onClose, segmentId, value]);
    const handleChange = React.useCallback((value: Option[]) => {
        setValue(value);
    }, []);
    const handleInputChangeFunction = React.useCallback(
        (newValue: string) => {
            if (newValue !== search) {
                setSearch(newValue);
            }
        },
        [search]
    );
    const handleInputChange = debounce(handleInputChangeFunction, 200);
    return (
        <Box width={500}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                        Select Customers
                    </Typography>
                    <Typography variant="subtitle2">Manually add up to 100 users to this audience</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MultiSelectSuggest
                        value={value}
                        isCreatable={false}
                        isMulti={true}
                        label={''}
                        options={options}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        placeholder="Start typing..."
                    />
                    {value && !!value.length && (
                        <Typography variant="caption" color="textSecondary">
                            You are still able to select {100 - value.length} more customers
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Row gutter flex={1} align="flex-end" valign="center">
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={value && value.length === 0}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Row>
                </Grid>
            </Grid>
        </Box>
    );
};

interface AudienceAddCustomersModalProps {
    open: boolean;
    onClose: () => void;
    segmentId: string;
    customers: { userId: string }[];
}

export const AudienceAddCustomersModal: React.FC<AudienceAddCustomersModalProps> = ({
    open,
    onClose,
    customers,
    segmentId
}) => (
    <MuiModal overflowVisible open={open} onClose={onClose}>
        <AudienceAddCustomersModalComponent
            selectedCustomers={customers}
            onClose={onClose}
            segmentId={segmentId}
        />
    </MuiModal>
);

import { menusApi } from 'components/menu-manager/MenusApi';
import { MESSAGE_LOCATION_REFRESH_ERROR } from 'config/messages';
import { isDefined } from 'lib/typeguards';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MenuOverridesFilter } from '../models/Overrides';

const pullForInfo = (
    locationId: string,
    lastUpdated: string,
    filter: MenuOverridesFilter,
    callback: (error?: string, result?: string) => void
) => {
    setTimeout(() => {
        menusApi.getInfo(locationId, filter).then(infoResult => {
            if (!infoResult.ok || !Array.isArray(infoResult.body || !infoResult.body[0])) {
                callback(infoResult.body.message);
                return;
            }
            if (infoResult.body[0].updated !== lastUpdated) {
                callback(undefined, infoResult.body[0].updated);
            } else {
                pullForInfo(locationId, lastUpdated, filter, callback);
            }
        });
    }, 5000);
};

export function useRefreshButton(
    refreshOverrides: () => Promise<{ ok: boolean; statusCode: number; body: any }>,
    filter: MenuOverridesFilter
) {
    const { locationId } = useParams<{ locationId: string }>();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);
    const [updated, setUpdated] = React.useState<string>();
    const handleRefreshMenu = React.useCallback(() => {
        setLoading(true);
        menusApi.refreshMenus(locationId);
        pullForInfo(locationId, updated, filter, (e, updated) => {
            if (isDefined(e)) {
                dispatch(enqueueSnackbar(MESSAGE_LOCATION_REFRESH_ERROR, { variant: 'warning' }));
            } else {
                setUpdated(updated);
                refreshOverrides();
            }
            setLoading(false);
        });
    }, [locationId, updated, filter, dispatch, refreshOverrides]);
    React.useEffect(() => {
        menusApi.getInfo(locationId, filter).then(infoResult => {
            if (!infoResult.ok || !Array.isArray(infoResult.body || !infoResult.body[0])) {
                setUpdated(undefined);
            } else {
                setUpdated(infoResult.body[0].updated);
            }
            setLoading(false);
        });
    }, [filter, locationId]);
    return {
        handleRefreshMenu,
        updated,
        loading
    };
}

import React from 'react';
import { ConfirmDialog, ConfirmDialogProps } from 'ui/dialogs/ConfirmDialog';
import { isGrantPerkToSegmentOperation, SegmentOperation } from '../models/segmentOperationModel';

export interface SegmentRetryOperationModalProps
    extends Pick<ConfirmDialogProps, 'open' | 'onConfirm' | 'onCancel'> {
    operation: SegmentOperation;
}

export const SegmentRetryOperationModal = ({
    operation,
    open,
    onConfirm,
    onCancel
}: SegmentRetryOperationModalProps) => {
    if (isGrantPerkToSegmentOperation(operation)) {
        return (
            <ConfirmDialog
                confirmLabel="Yes"
                cancelLabel="No"
                title={`Are you sure you want to retry granting ${operation.metadata.perkTitle}?`}
                content={`This will re-run the change for ${operation.result.errors} failed users.`}
                open={open}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
        );
    }
    return (
        <ConfirmDialog
            confirmLabel="Yes"
            cancelLabel="No"
            title="Are you sure you want to retry this change?"
            content={'This will re-run the change for failed users'}
            open={open}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    );
};

import { ActionCreator, Dispatch } from 'redux';
import { locationApi } from 'components/location/LocationApi';
import { isLocations, Location, LocationState } from 'components/location/models/LocationModel';
import {
    MESSAGE_LOCATION_ACTIVATE_ERROR,
    MESSAGE_LOCATION_ACTIVATE_SUCCESS,
    MESSAGE_LOCATION_DELETE_ERROR,
    MESSAGE_LOCATION_DELETE_SUCCESS,
    MESSAGE_LOCATION_RETIRE_ERROR,
    MESSAGE_LOCATION_RETIRE_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { LocationActionTypes, LocationsActionTypes } from './locationsActionTypes';
import { LocationsState } from './locationsReducer';

const LOCATIONS_PER_PAGE = '100';

export interface LocationsAction {
    type: LocationsActionTypes;
    summary?: { total: number; count: number; pages: number; page: number };
    locations?: Location[];
    locationIdToTitleMap?: Map<string, string>;
    error?: string;
}

export interface LocationAction {
    type: LocationActionTypes;
    index: number;
    location: Location;
}

const startRequest = {
    type: LocationsActionTypes.START_REQUEST
};

const endRequest = {
    type: LocationsActionTypes.END_REQUEST
};
export const updateLocation = (index: number, location: Location) => ({
    index,
    location,
    type: LocationActionTypes.UPDATE_LOCATION
});

export const getAllLocationTitles = async (dispatch: ActionCreator<LocationsAction>) => {
    const getAllLocations = async (allLocations: Location[] = [], page = 1): Promise<Location[]> => {
        const queryParameters = [
            { key: 'state', value: 'ACTIVE,PENDING,RETIRED' },
            { key: 'page', value: page },
            { key: 'per_page', value: 100 }
        ];
        const response = await locationApi.getList({ queryParameters });
        const { locations, summary } = response.body;
        allLocations.push(...locations);

        if (allLocations.length !== summary.total) {
            return getAllLocations(allLocations, summary.page + 1);
        }

        return allLocations;
    };

    const allLocations: Location[] = await getAllLocations();
    const locationIdToTitleMap = allLocations.reduce((acc, location) => {
        acc.set(location._id, location.title);

        return acc;
    }, new Map());

    dispatch({
        locations: allLocations,
        locationIdToTitleMap,
        type: LocationsActionTypes.GET_ALL_LOCATION_TITLES
    });
    return allLocations;
};

export const getLocations = (title?: string) => async (dispatch: ActionCreator<LocationsAction>) => {
    dispatch(startRequest);
    const queryParameters = [
        { key: 'page', value: '0' },
        { key: 'per_page', value: LOCATIONS_PER_PAGE },
        { key: 'state', value: 'ACTIVE,PENDING,RETIRED' }
    ];
    if (!!title) {
        queryParameters.push({
            key: 'title',
            value: title
        });
    }
    const response = await locationApi.getList({ queryParameters });
    const { locations, summary } = response.body;
    if (isLocations(locations)) {
        dispatch({
            locations,
            summary,
            type: LocationsActionTypes.GET_LOCATIONS_SUCCESS
        });
    } else {
        logger.error('TypeError: expected Location[], got:', locations);
        dispatch({
            type: LocationsActionTypes.GET_LOCATIONS_ERROR,
            error: 'Locations data violated'
        });
    }
    dispatch(endRequest);
};

export const loadLocations =
    (locationStateSummary: LocationsState['summary'], title?: string) =>
    async (dispatch: ActionCreator<LocationsAction>) => {
        dispatch(startRequest);
        if (locationStateSummary.page === locationStateSummary.pages) {
            return dispatch(endRequest);
        }
        const nextPage = locationStateSummary.page + 1;
        const queryParameters = [
            { key: 'page', value: String(nextPage) },
            { key: 'per_page', value: LOCATIONS_PER_PAGE },
            { key: 'state', value: 'ACTIVE,PENDING,RETIRED' }
        ];
        if (!!title) {
            queryParameters.push({
                key: 'title',
                value: title
            });
        }
        const response = await locationApi.getList({ queryParameters });
        const { locations, summary } = response.body;
        if (isLocations(locations)) {
            dispatch({
                locations,
                summary,
                type: LocationsActionTypes.LOAD_LOCATIONS_SUCCESS
            });
        } else {
            logger.error('TypeError: expected Location[], got:', locations);
            dispatch({
                type: LocationsActionTypes.GET_LOCATIONS_ERROR,
                error: 'Locations data violated'
            });
        }
        dispatch(endRequest);
    };

export const getAllLocations = () => async (dispatch: Dispatch) => {
    dispatch(startRequest);
    const queryParameters = [{ key: 'state', value: 'ACTIVE,PENDING,RETIRED' }];
    const response = await locationApi.getList({ queryParameters });
    const { locations, summary } = response.body;
    if (isLocations(locations)) {
        dispatch({
            locations,
            summary,
            type: LocationsActionTypes.GET_LOCATIONS_SUCCESS
        });
    } else {
        logger.error('TypeError: expected Location[], got:', locations);
        dispatch({
            type: LocationsActionTypes.GET_LOCATIONS_ERROR,
            error: 'Locations data violated'
        });
    }
    dispatch(endRequest);

    return locations;
};

export const retireLocation = (location: Location, index: number) => async (dispatch: Dispatch) => {
    const { _id, ...rest } = location;
    dispatch(startRequest);
    const response = await locationApi.update(_id, { body: { ...rest, state: LocationState.RETIRED } });
    if (response.ok) {
        dispatch(enqueueSnackbar(MESSAGE_LOCATION_RETIRE_SUCCESS(location.title), { variant: 'success' }));
        dispatch(updateLocation(index, response.body));
    } else {
        dispatch(enqueueSnackbar(MESSAGE_LOCATION_RETIRE_ERROR, { variant: 'error' }));
    }
    dispatch(endRequest);
};

export const activateLocation = (location: Location, index: number) => async (dispatch: Dispatch) => {
    const { _id, ...rest } = location;
    dispatch(startRequest);
    const response = await locationApi.update(_id, { body: { ...rest, state: LocationState.ACTIVE } });
    if (response.ok) {
        dispatch(enqueueSnackbar(MESSAGE_LOCATION_ACTIVATE_SUCCESS(location.title), { variant: 'success' }));
        dispatch(updateLocation(index, response.body));
    } else {
        dispatch(enqueueSnackbar(MESSAGE_LOCATION_ACTIVATE_ERROR, { variant: 'error' }));
    }
    dispatch(endRequest);
};

export const deleteLocation = (locationId: string, index: number) => async (dispatch: Dispatch) => {
    dispatch(startRequest);
    const response = await locationApi.delete(locationId, { skipResponseBody: true });
    if (response.ok) {
        dispatch(enqueueSnackbar(MESSAGE_LOCATION_DELETE_SUCCESS, { variant: 'success' }));
        dispatch({
            index,
            type: LocationActionTypes.DELETE_LOCATION
        });
    } else {
        dispatch(enqueueSnackbar(MESSAGE_LOCATION_DELETE_ERROR, { variant: 'error' }));
    }
    dispatch(endRequest);
};

import React from 'react';
import { Box, ListItem, ListItemText, TextField } from '@mui/material';
import { MuiImagePicker } from 'lib/image-picker';
import { Option } from 'lib/types';
import { Column } from 'ui/Flex';

interface IProps {
    product: Option;
    image?: { imageUrl: string; sort: number };
    onChange: (image: { imageUrl: string; sort: string }, id: string) => void;
}

export const DynamicImageryImageItem: React.FC<IProps> = ({ product, image, onChange }) => {
    const handleImageChange = React.useCallback(
        (imageUrl: string) => {
            onChange({ imageUrl, sort: String(image?.sort) || '' }, product.value);
        },
        [image, onChange, product.value]
    );
    const handleSortChange = React.useCallback(
        (e: React.ChangeEvent<{ value: string }>) => {
            onChange({ imageUrl: image?.imageUrl, sort: e.target.value }, product.value);
        },
        [image, onChange, product.value]
    );
    return (
        <ListItem disableGutters>
            <Box marginRight={1} width={100}>
                <MuiImagePicker
                    url={image?.imageUrl}
                    disableCrop
                    onSelect={handleImageChange}
                    unremovable
                    aspect={1}
                    fullWidth
                    smallText
                />
            </Box>
            <Column flex={1}>
                <ListItemText primary={product.label} secondary={product.value} />
                <TextField
                    label="Sort"
                    value={image?.sort ?? ''}
                    onChange={handleSortChange}
                    fullWidth
                    type="number"
                />
            </Column>
        </ListItem>
    );
};

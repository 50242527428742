import React, { MouseEventHandler } from 'react';
import { Button, styled, Typography } from '@mui/material';
import { Column } from 'ui/Flex';

const PREFIX = 'EmptyState';

const classes = {
    image: `${PREFIX}-image`,
    paragraph: `${PREFIX}-paragraph`,
    button: `${PREFIX}-button`
};

const StyledColumn = styled(Column)(({ theme }) => ({
    margin: 'auto',
    padding: theme.spacing(10),
    [`& .${classes.image}`]: {
        marginBottom: theme.spacing(2),
        width: '60%',
        minWidth: '275px'
    },
    [`& .${classes.paragraph}`]: {
        marginTop: theme.spacing(0.5)
    },
    [`& .${classes.button}`]: {
        marginTop: theme.spacing(2)
    }
}));

interface EmptyStateProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    headerText: string;
    paragraphText: string;
    buttonText: string;
    imageUrl: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
    onClick,
    headerText,
    paragraphText,
    buttonText,
    imageUrl
}) => (
    <StyledColumn flex={1} valign="center" align="center">
        <img src={imageUrl} className={classes.image} alt="" />
        <Typography align="center" variant="h5">
            {headerText}
        </Typography>
        <Typography align="center" variant="h6" color="textSecondary" className={classes.paragraph}>
            {paragraphText}
        </Typography>
        {onClick && (
            <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
                {buttonText}
            </Button>
        )}
    </StyledColumn>
);

import React from 'react';
import { ContentModuleRenderer } from '../renderContentModule';

export const renderLoyaltyBalanceModule: ContentModuleRenderer = (_properties, _) => (
    <LoyaltyBalanceModuleComponent />
);

function LoyaltyBalanceModuleComponent(): null {
    return null;
}

import ct from 'countries-and-timezones';
import { AutoFormFields } from 'lib/LegacyForm';
export const createLocationGeneralForm: () => AutoFormFields = () => ({
    title: {
        label: 'Title',
        validation: 'required|min:3'
    },
    description: {
        label: 'Description',
        type: 'multiline'
    },
    phone: {
        label: 'Phone Number',
        validation: 'required'
    },
    email: {
        label: 'Email Address'
    },
    VATnumber: {
        label: 'VAT Number'
    },
    timezone: {
        label: 'Timezone',
        type: 'searchableSelect',
        value: 'Europe/London',
        options: Object.entries(ct.getAllTimezones()).map(tz => ({ label: tz[0], value: tz[0] }))
    },
    state: {
        label: 'State',
        type: 'select',
        value: 'PENDING',
        options: [
            { label: 'ACTIVE', value: 'ACTIVE' },
            { label: 'RETIRED', value: 'RETIRED' },
            { label: 'PENDING', value: 'PENDING' }
        ]
    }
});

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Typography } from '@mui/material';
import { uploadStripeFile } from 'components/content/uploadFile';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { isDefined, isEmptyString } from 'lib/typeguards';
import React from 'react';
import { StripeFileInput } from '../StripeFileInput';

export interface DefaultEvidenceFormData {
    billing_address?: string;
    customer_name?: string;
    customer_email_address?: string;
    customer_purchase_ip?: string;
    product_description?: string;
    customer_signature?: string;
    new_customer_signature?: File;
    customer_communication?: string;
    new_customer_communication?: File;
    receipt?: string;
    new_receipt?: File;
}

export function removeNulls(data: Record<string, any>) {
    if (!data) {
        return {};
    }
    return Object.entries(data).reduce<Record<string, any>>((acc, [key, value]) => {
        if (value !== null) {
            acc[key] = value;
        }
        return acc;
    }, {});
}

export function removeEmptyFields(data: Record<string, any>) {
    if (!data) {
        return {};
    }
    return Object.entries(data).reduce<Record<string, any>>((acc, [key, value]) => {
        if (isDefined(value) && !isEmptyString(value) && value !== null) {
            acc[key] = value;
        }
        return acc;
    }, {});
}

export function useDefaultEvidenceFields(locationId?: string) {
    const renderFields = React.useCallback(
        () => (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">Evidence form</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextFormField name="billing_address" label="Billing address" />
                </Grid>
                <Grid item xs={12}>
                    <TextFormField name="customer_name" label="Customer name" />
                </Grid>
                <Grid item xs={12}>
                    <TextFormField
                        name="customer_email_address"
                        label="Customer email address"
                        type="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFormField
                        name="customer_purchase_ip"
                        label="Customer purchase IP"
                        description="Include the IP address that the customer used when making the purchase."
                    />
                </Grid>
                <Grid item xs={12}>
                    <StripeFileInput
                        name="customer_signature"
                        uploadName="new_customer_signature"
                        label="Customer Signature"
                        description="Include relevant documentation with the customer signature."
                        locationId={locationId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StripeFileInput
                        name="customer_communication"
                        uploadName="new_customer_communication"
                        label="Customer communication"
                        description="Include relevant communication between you and the customer. This can include emails."
                        locationId={locationId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StripeFileInput
                        name="receipt"
                        uploadName="new_receipt"
                        label="Receipt"
                        locationId={locationId}
                        description="Include any receipt or message to the customer informing them of the transaction. "
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFormField
                        name="product_description"
                        label="Product description"
                        multiline
                        description="Include a description of the product and any relevant details on how this was presented to the customer at the time of the purchase."
                    />
                </Grid>
            </React.Fragment>
        ),
        [locationId]
    );
    const getDefaultData = React.useCallback(
        async ({
            billing_address,
            customer_name,
            customer_email_address,
            customer_purchase_ip,
            product_description,
            customer_signature,
            new_customer_signature,
            customer_communication,
            new_customer_communication,
            receipt,
            new_receipt
        }: DefaultEvidenceFormData) => {
            let signature: string;
            if (new_customer_signature) {
                const result = await uploadStripeFile(new_customer_signature, 'dispute_evidence', locationId);
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                signature = result.body.id;
            }
            let communication: string;
            if (new_customer_communication) {
                const result = await uploadStripeFile(
                    new_customer_communication,
                    'dispute_evidence',
                    locationId
                );
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                communication = result.body.id;
            }
            let receiptFile: string;
            if (new_receipt) {
                const result = await uploadStripeFile(new_receipt, 'dispute_evidence', locationId);
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                receiptFile = result.body.id;
            }
            return {
                billing_address,
                customer_name,
                customer_email_address,
                customer_purchase_ip,
                product_description,
                customer_signature: signature ?? customer_signature,
                customer_communication: communication ?? customer_communication,
                receipt: receiptFile ?? receipt
            };
        },
        [locationId]
    );
    return { renderFields, getDefaultData };
}

import React from 'react';
import { Tooltip, Typography } from '@mui/material';

interface RequiredStarProps {
    title?: string;
    fontSize?: number | string;
    variant?: 'standard' | 'secondary';
}

export function RequiredStar({ fontSize = 22, title, variant = 'standard' }: RequiredStarProps) {
    const color = variant === 'secondary' ? 'secondary' : 'primary';
    const tooltip =
        title ?? (variant === 'secondary' ? 'This field might be required.' : 'This field is required.');
    return (
        <Tooltip title={tooltip}>
            <Typography style={{ fontSize, lineHeight: 0, paddingLeft: 1 }} color={color} component="span">
                *
            </Typography>
        </Tooltip>
    );
}

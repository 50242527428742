import React from 'react';
import { Checkbox, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { MuiTableColumnItem } from './MuiTable';

interface MuiTableHeadProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    columns: MuiTableColumnItem[];
    selectable: boolean;
    itemActions?: boolean;
}

const renderItemColumn = (column: MuiTableColumnItem): React.ReactNode => {
    const props = column.headerProps || {};
    return (
        <TableCell
            key={`table-head-column-${column.key || column.label || uuid()}`}
            align="justify"
            size="small"
            {...props}
        >
            <Typography variant="body2" component="span" color="textSecondary">
                {column.label}
            </Typography>
        </TableCell>
    );
};
const ItemActionsColumn = () => (
    <TableCell key="table-head-item-actions-column" align="center" size="small" style={{ width: 64 }}>
        <Typography variant="body2" component="span" color="textSecondary">
            Actions
        </Typography>
    </TableCell>
);

export const MuiTableHead: React.FC<MuiTableHeadProps> = ({
    onSelectAllClick,
    selectable,
    itemActions,
    columns,
    numSelected,
    rowCount
}) => (
    <TableHead>
        <TableRow>
            {selectable && (
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
            )}
            {columns.map(renderItemColumn)}
            {itemActions && <ItemActionsColumn />}
        </TableRow>
    </TableHead>
);

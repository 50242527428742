import { MenuItem, Select } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';

const ANY_FILTER_VALUE = 'ANY';

interface TransactionsSelectFilterProps {
    onChange: (name: string, value?: string) => void;
    name: string;
    value?: string;
    options: Option[];
    disabled?: boolean;
    unselectedLabel?: string;
}

export const TransactionsSelectFilter: React.FC<TransactionsSelectFilterProps> = ({
    value,
    onChange,
    disabled,
    options,
    name,
    unselectedLabel = 'All'
}) => {
    const handleSelectChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ANY_FILTER_VALUE) {
                onChange(name, undefined);
            } else {
                onChange(name, e.target.value);
            }
        },
        [onChange, name]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select
            value={value ?? ANY_FILTER_VALUE}
            onChange={handleSelectChange}
            variant="outlined"
            disabled={disabled}
        >
            <MenuItem value={ANY_FILTER_VALUE}>{unselectedLabel}</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};

import { isString } from 'lib/typeguards';
import React, { useState, useEffect, useRef } from 'react';
import { isBasicTimeValid } from './validate';

interface TimeInputProps {
    initTime?: string;
    disabled?: boolean;
    mountFocus?: boolean;
    onTimeChange: (value: string) => void;
    type?: string;
    placeholder?: string;
    className?: string;
    name?: string;
    onBlur?: () => void;
}

export const TimeRangeInput: React.FC<TimeInputProps> = ({
    initTime,
    disabled,
    mountFocus,
    onTimeChange,
    type,
    placeholder = '',
    className,
    name,
    onBlur
}) => {
    const [time, setTime] = useState(isString(initTime) ? initTime : '');
    const [lastVal, setLastVal] = React.useState('');

    const _input = useRef(null);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!disabled && mountFocus) {
            timeout = setTimeout(() => {
                _input.current.focus();
            }, 0);
        }
        return () => clearTimeout(timeout);
    }, [disabled, mountFocus]);

    const onChangeHandler = React.useCallback(
        (val: string) => {
            if (val === time) {
                return;
            }
            if (isBasicTimeValid(val)) {
                if (val.length === 2 && lastVal.length !== 3) {
                    val = val + ':';
                }

                if (val.length === 2 && lastVal.length === 3) {
                    val = val.slice(0, 1);
                }

                if (val.length === 5 && lastVal.length !== 8) {
                    val = val + ' - ';
                }

                if (val.length === 7 && lastVal.length === 8) {
                    val = val.slice(0, 4);
                }

                if (val.length === 10 && lastVal.length !== 11) {
                    val = val + ':';
                }

                if (val.length === 10 && lastVal.length === 11) {
                    val = val.slice(0, 9);
                }

                if (val.length > 13) {
                    return false;
                }

                setLastVal(val);

                setTime(val);

                if (val.length === 13 || val.length === 0) {
                    onTimeChange(val);
                }
            }
        },
        [lastVal.length, onTimeChange, time]
    );

    const inputType = React.useMemo(() => {
        if (type) {
            return type;
        }
        return 'tel';
    }, [type]);

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeHandler(e.target.value);
        },
        [onChangeHandler]
    );
    const handleBlur = React.useCallback(() => {
        if (onBlur) {
            onBlur();
        }
    }, [onBlur]);
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'Tab') {
                if (onBlur) {
                    onBlur();
                }
            }
        },
        [onBlur]
    );

    return (
        <input
            name={name ? name : undefined}
            className={className}
            type={inputType}
            disabled={disabled}
            placeholder={placeholder}
            value={time}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            ref={_input}
        />
    );
};

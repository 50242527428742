/* eslint-disable @typescript-eslint/no-misused-promises */
import { Crud, HttpClient, httpClient, RequestOptions } from 'lib/HttpClient';
import { Notification } from './models/Notification';

const REQUEST_TIMEOUT = 1200;

class NotificationApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'notifications', { 'x-api-version': 4 });
    }
    send(userId: string, options: RequestOptions) {
        return this.httpClient.post(`${this.api}/users/${userId}/notifications`, {
            ...options,
            headers: { 'x-api-version': 4 }
        });
    }
    async pollNotification<IDType extends string>({
        notification
    }: {
        notification: Notification<IDType>;
    }): Promise<Notification<IDType>> {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const result = await this.get(notification._id);
                if (!result || !result.ok) {
                    reject(new Error('Could not get Notification'));
                }

                if (result.body && result.body.status === 'PENDING') {
                    resolve(this.pollNotification({ notification: result.body }));
                }

                resolve(result.body);
            }, REQUEST_TIMEOUT);
        });
    }
    async getNotificationReceipts(id: string, options: Record<string, string | number>) {
        let optionsString = `?include=userFullName&limit=${options.limit}`;
        if (!!options.startKey) {
            optionsString += `&startKey=${options.startKey}`;
        }

        return this.httpClient.get(`${this.api}/notifications/${id}/receipts${optionsString}`, {
            headers: { 'x-api-version': 2 }
        });
    }
}

export const notificationApi = new NotificationApi(httpClient);

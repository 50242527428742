import React from 'react';
import { Grid } from '@mui/material';
import { CardViewTextRow } from 'ui/viewComponents';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';

export const ActionCardBasicInfo: React.FC<ActionCardModalComponentProps> = ({ action }) => (
    <ActionCardBase action={action}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <CardViewTextRow title="Date" value={getActionDate(action)} />
            </Grid>
        </Grid>
    </ActionCardBase>
);

import React from 'react';
import { Button, Grid, Typography, TextField, styled } from '@mui/material';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import { Option } from 'lib/types';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { AutocompleteFormField } from 'lib/form/fields/AutocompleteFormField';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { VoucherAddEfectsPerkFields } from './VoucherAddEfectsPerkFields';
import { Voucher } from '../models/VoucherModel';
import * as Yup from 'yup';

interface VoucherCreateFormProps {
    onSubmit: (values: AddVoucherEffectFormData) => void;
    onClose: () => void;
    perksOptions: Option[] | [];
    audiencesOptions: Option[] | [];
    voucher: Voucher;
}

export interface AddVoucherEffectFormData {
    selectedEffect: string;
    audienceId: string;
    perkId: string;
    amount: string;
    amountRequired: boolean;
    worth: string;
}

const initialValues: AddVoucherEffectFormData = {
    selectedEffect: '',
    audienceId: '',
    perkId: '',
    amount: '',
    amountRequired: false,
    worth: ''
};

export enum EffectType {
    AUDIENCE = 'audience',
    PERK = 'perk'
}

const validationSchema = Yup.object().shape({
    selectedEffect: Yup.string().required('This field is required.'),
    audienceId: Yup.string().when('selectedEffect', {
        is: 'audience',
        then: Yup.string().required('This field is required.')
    }),
    perkId: Yup.string().when('selectedEffect', {
        is: 'perk',
        then: Yup.string().required('This field is required.')
    }),
    amount: Yup.string().when('amountRequired', {
        is: true,
        then: Yup.string().required('This field is required.')
    })
});

const PREFIX = 'VoucherAddEffectsForm';

const classes = {
    title: `${PREFIX}-title`,
    button: `${PREFIX}-button`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.title}`]: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(2)
    }
}));

export const VoucherAddEffectsForm: React.FC<VoucherCreateFormProps> = ({
    onSubmit,
    onClose,
    audiencesOptions,
    perksOptions,
    voucher
}) => {
    const { title, code } = voucher;

    const options = [
        {
            label: 'Join user to audience',
            value: 'audience'
        },
        {
            label: 'Grant perk to user',
            value: 'perk'
        }
    ];

    return (
        <MuiForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ submitForm, setFieldValue, isSubmitting, values: { selectedEffect, perkId, amount } }) => (
                <Form>
                    <StyledGrid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant="h6">
                                Create a Voucher
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth disabled value={title} label="Title" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                value={code}
                                label="Code"
                                helperText="This is the code that your customers will use to redeem the voucher."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                Add effect to voucher
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <SelectFormField options={options} name="selectedEffect" label="Select Effect" />
                        </Grid>
                        {selectedEffect === EffectType.AUDIENCE && (
                            <Grid item xs={12}>
                                <AutocompleteFormField
                                    name="audienceId"
                                    label="Select Audience"
                                    options={audiencesOptions}
                                />
                            </Grid>
                        )}

                        {selectedEffect === EffectType.PERK && (
                            <VoucherAddEfectsPerkFields
                                options={perksOptions}
                                perkId={perkId}
                                amount={amount}
                                setFieldValue={setFieldValue}
                            />
                        )}

                        <Grid item xs={12}>
                            <Row align="flex-end" flex={1}>
                                <Button color="secondary" variant="outlined" onClick={onClose}>
                                    No Thanks
                                </Button>
                                <LoadingButton
                                    color="primary"
                                    variant="contained"
                                    onClick={submitForm}
                                    className={classes.button}
                                    disabled={isSubmitting}
                                >
                                    Save Effects
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </StyledGrid>
                </Form>
            )}
        </MuiForm>
    );
};

import React from 'react';
import { isString } from './typeguards';

interface BasicStringReplaceProps {
    children: string;
    param: string;
    renderFunction: () => React.ReactNode;
}

// Works only for 1 key at a time
export const BasicStringReplace: React.FC<BasicStringReplaceProps> = ({
    children,
    param,
    renderFunction
}) => {
    const content = React.useMemo(() => {
        if (!isString(children)) {
            return children;
        }
        const chunks = children.split(`{{${param}}}`);
        if (chunks.length !== 2) {
            return children;
        }
        const [start, end] = chunks;
        return (
            <>
                {start}
                {renderFunction()}
                {end}
            </>
        );
    }, [children, param, renderFunction]);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
};

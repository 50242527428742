import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { IAdditionalUpload, MuiImagePicker } from 'lib/image-picker';
import { MuiImageDropZoneUpload } from 'lib/image-picker/MuiImageDropZoneUpload';

interface ImageFormFieldProps {
    label?: React.ReactNode;
    description?: React.ReactNode;
    aspect?: number;
    fullWidth?: boolean;
    fileName?: string;
    disableCrop?: boolean;
    uploadFolder?: string;
    additionalUploads?: IAdditionalUpload[];
    unremovable?: boolean;
    smallText?: boolean;
    croppable?: boolean;
    accept?: string | string[];
}

export const ImageFormField: React.FC<FieldAttributes<ImageFormFieldProps>> = ({
    label,
    placeholder: _placeholder,
    description,
    aspect,
    fullWidth,
    fileName,
    uploadFolder,
    additionalUploads,
    unremovable,
    smallText,
    accept,
    croppable,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <ImageFormFieldComponent
                label={label}
                accept={accept}
                description={description}
                aspect={aspect}
                fullWidth={fullWidth}
                fileName={fileName}
                uploadFolder={uploadFolder}
                additionalUploads={additionalUploads}
                unremovable={unremovable}
                smallText={smallText}
                croppable={croppable}
                {...childProps}
            />
        )}
    </Field>
);

const ImageFormFieldComponent: React.FC<FieldProps & ImageFormFieldProps> = ({
    description,
    field,
    meta,
    aspect,
    label,
    disableCrop,
    fullWidth,
    fileName,
    uploadFolder,
    additionalUploads,
    smallText,
    unremovable,
    croppable = true,
    accept
}) => {
    const isError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        (value: string) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    return (
        <FormControl fullWidth={fullWidth} error={meta.touched && !!meta.error}>
            <InputLabel shrink>{label}</InputLabel>
            <Box paddingTop={2}>
                {croppable ? (
                    <MuiImagePicker
                        url={field.value}
                        onSelect={handleChange}
                        aspect={aspect}
                        uploadFileName={fileName}
                        uploadFolder={uploadFolder}
                        disableCrop={disableCrop}
                        additionalUploads={additionalUploads}
                        unremovable={unremovable}
                        smallText={smallText}
                        accept={accept}
                    />
                ) : (
                    <MuiImageDropZoneUpload
                        uploadFolder={uploadFolder}
                        accept={accept}
                        aspect={aspect}
                        fileName={fileName}
                        url={field.value}
                        fullWidth={fullWidth}
                        onChange={handleChange}
                        smallText={smallText}
                    />
                )}
            </Box>
            {(isError || description) && (
                <FormHelperText error={isError}>{isError ? meta.error : description}</FormHelperText>
            )}
        </FormControl>
    );
};

ImageFormFieldComponent.defaultProps = {
    fullWidth: true
};

import React from 'react';
import { Box } from '@mui/material';
import { Container, ContainerDivision } from '@pepperhq/menu-sdk';

interface IProps {
    item: Container;
    index: number;
    colors: string[];
}

export const DynamicImageryOverlayBox: React.FC<IProps> = ({ index, item, colors }) => {
    if (item.division === ContainerDivision.HORIZONTAL) {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: `${item.y * 100}%`,
                    left: `${item.x * 100}%`,
                    width: `${item.width * 100}%`,
                    height: `${item.height * 100}%`,
                    backgroundColor: colors[index],
                    boxSizing: 'border-box',
                    transition: 'all .2s',
                    display: 'flex'
                }}
            >
                {new Array(item.count).fill(undefined).map((_, innerIndex) => (
                    <Box
                        key={`box-${index}-${innerIndex}`}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        boxSizing="border-box"
                        style={{
                            width: `${100 / item.count}%`,
                            height: '100%',
                            border: `1px solid ${colors[index]}`,
                            color: colors[index],
                            transition: 'all .2s'
                        }}
                    >
                        {index + 1}
                    </Box>
                ))}
            </div>
        );
    }
    return (
        <div
            style={{
                position: 'absolute',
                top: `${item.y * 100}%`,
                left: `${item.x * 100}%`,
                width: `${item.width * 100}%`,
                height: `${item.height * 100}%`,
                backgroundColor: colors[index],
                boxSizing: 'border-box',
                transition: 'all .2s'
            }}
        >
            {new Array(item.count).fill(undefined).map((_, innerIndex) => (
                <Box
                    key={`box-${index}-${innerIndex}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    boxSizing="border-box"
                    style={{
                        height: `${100 / item.count}%`,
                        border: `1px solid ${colors[index]}`,
                        color: colors[index],
                        transition: 'all .2s'
                    }}
                >
                    {index + 1}
                </Box>
            ))}
        </div>
    );
};

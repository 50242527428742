import { EReportType, EReportFormat, EScheduleReportInterval } from './enums';
/* eslint-disable @typescript-eslint/naming-convention */

export const reportCreationValues: Record<
    EReportType,
    Record<
        EReportFormat,
        {
            name: string;
            columns: Record<
                string,
                {
                    id: string;
                    defaultMessage: string;
                }
            >;
        }
    >
> = {
    [EReportType.ACTIVITY]: {
        [EReportFormat.SUMMARY]: {
            name: 'connected_account_activity.summary.1',
            columns: {
                reporting_category: {
                    id: 'statement.reportType.connected_account_activity.summary.1.columns.reporting_category',
                    defaultMessage: 'Reporting category'
                },
                currency: {
                    id: 'statement.reportType.connected_account_activity.summary.1.columns.currency',
                    defaultMessage: 'Currency'
                },
                count: {
                    id: 'statement.reportType.connected_account_activity.summary.1.columns.count',
                    defaultMessage: 'Count'
                },
                gross: {
                    id: 'statement.reportType.connected_account_activity.summary.1.columns.gross',
                    defaultMessage: 'Gross'
                },
                net: {
                    id: 'statement.reportType.connected_account_activity.summary.1.columns.net',
                    defaultMessage: 'Net'
                },
                fee: {
                    id: 'statement.reportType.connected_account_activity.summary.1.columns.fee',
                    defaultMessage: 'Fee'
                }
            }
        },
        [EReportFormat.ITEMIZED]: {
            name: 'connected_account_activity.itemized.2',
            columns: {
                balance_transaction_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.balance_transaction_id',
                    defaultMessage: 'Balance transaction ID'
                },
                balance_transaction_created_at: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.balance_transaction_created_at',
                    defaultMessage: 'Balance transaction created at'
                },
                balance_transaction_reporting_category: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.balance_transaction_reporting_category',
                    defaultMessage: 'Balance transaction reporting category'
                },
                balance_transaction_component: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.balance_transaction_component',
                    defaultMessage: 'Balance transaction component'
                },
                balance_transaction_regulatory_tag: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.balance_transaction_regulatory_tag',
                    defaultMessage: 'Balance transaction regulatory tag'
                },
                activity_at: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.activity_at',
                    defaultMessage: 'Activity at'
                },
                currency: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.currency',
                    defaultMessage: 'Currency'
                },
                amount: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.amount',
                    defaultMessage: 'Amount'
                },
                charge_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.charge_id',
                    defaultMessage: 'Charge ID'
                },
                payment_intent_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.payment_intent_id',
                    defaultMessage: 'Payment intent ID'
                },
                refund_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.refund_id',
                    defaultMessage: 'Refund ID'
                },
                dispute_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.dispute_id',
                    defaultMessage: 'Dispute ID'
                },
                invoice_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.invoice_id',
                    defaultMessage: 'Invoice ID'
                },
                subscription_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.subscription_id',
                    defaultMessage: 'Subscription ID'
                },
                fee_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.fee_id',
                    defaultMessage: 'Fee ID'
                },
                transfer_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.transfer_id',
                    defaultMessage: 'Transfer ID'
                },
                destination_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.destination_id',
                    defaultMessage: 'Destination ID'
                },
                customer_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.customer_id',
                    defaultMessage: 'Customer ID'
                },
                customer_email: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.customer_email',
                    defaultMessage: 'Customer email'
                },
                customer_name: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.customer_name',
                    defaultMessage: 'Customer name'
                },
                customer_description: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.customer_description',
                    defaultMessage: 'Customer description'
                },
                shipping_address_line1: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.shipping_address_line1',
                    defaultMessage: 'Shipping address line 1'
                },
                shipping_address_line2: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.shipping_address_line2',
                    defaultMessage: 'Shipping address line 2'
                },
                shipping_address_city: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.shipping_address_city',
                    defaultMessage: 'Shipping address city'
                },
                shipping_address_state: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.shipping_address_state',
                    defaultMessage: 'Shipping address state'
                },
                shipping_address_postal_code: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.shipping_address_postal_code',
                    defaultMessage: 'Shipping address postal code'
                },
                shipping_address_country: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.shipping_address_country',
                    defaultMessage: 'Shipping address country'
                },
                automatic_payout_id: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.automatic_payout_id',
                    defaultMessage: 'Automatic payout ID'
                },
                automatic_payout_effective_at: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.automatic_payout_effective_at',
                    defaultMessage: 'Automatic payout effective at'
                },
                event_type: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.event_type',
                    defaultMessage: 'Event type'
                },
                payment_method_type: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.payment_method_type',
                    defaultMessage: 'Payment method type'
                },
                card_brand: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.card_brand',
                    defaultMessage: 'Card brand'
                },
                card_funding: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.card_funding',
                    defaultMessage: 'Card funding'
                },
                card_country: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.card_country',
                    defaultMessage: 'Card country'
                },
                statement_descriptor: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.statement_descriptor',
                    defaultMessage: 'Statement descriptor'
                },
                customer_facing_currency: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.customer_facing_currency',
                    defaultMessage: 'Customer-facing currency'
                },
                customer_facing_amount: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.customer_facing_amount',
                    defaultMessage: 'Customer-facing amount'
                },
                activity_interval_type: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.activity_interval_type',
                    defaultMessage: 'Activity interval type'
                },
                activity_start_date: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.activity_start_date',
                    defaultMessage: 'Activity start date'
                },
                activity_end_date: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.activity_end_date',
                    defaultMessage: 'Activity end date'
                },
                balance_transaction_description: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.balance_transaction_description',
                    defaultMessage: 'Balance transaction description'
                },
                connected_account: {
                    id: 'statement.reportType.connected_account_activity.itemized.2.columns.connected_account',
                    defaultMessage: 'Connected account'
                },
                'payment_metadata[applicationFeeAmount]': {
                    id: '',
                    defaultMessage: 'Application fee amount'
                },
                'payment_metadata[applicationFeeTaxAmount]': {
                    id: '',
                    defaultMessage: 'Application fee tax amount'
                },
                'payment_metadata[connectAccountId]': {
                    id: '',
                    defaultMessage: 'Connected account ID'
                },
                'payment_metadata[franchiseFeeAmount]': {
                    id: '',
                    defaultMessage: 'Franchise fee Amount'
                },
                'payment_metadata[franchiseeAccountId]': {
                    id: '',
                    defaultMessage: 'Franchisee account ID'
                },
                'payment_metadata[franchisorAccountId]': {
                    id: '',
                    defaultMessage: 'Franchisor account ID'
                },
                'payment_metadata[isThreeDSecure]': {
                    id: '',
                    defaultMessage: 'Is threeD secure'
                },
                'payment_metadata[locationId]': {
                    id: '',
                    defaultMessage: 'Location ID'
                },
                'payment_metadata[orderId]': {
                    id: '',
                    defaultMessage: 'Order ID'
                },
                'payment_metadata[paymentAmount]': {
                    id: '',
                    defaultMessage: 'Payment amount'
                },
                'payment_metadata[tenantId]': {
                    id: '',
                    defaultMessage: 'Tenant ID'
                },
                'payment_metadata[userId]': {
                    id: '',
                    defaultMessage: 'User ID'
                }
            }
        }
    },
    [EReportType.RECONCILIATION]: {
        [EReportFormat.SUMMARY]: {
            name: 'connected_account_payout_reconciliation.summary.1',
            columns: {
                reporting_category: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.summary.1.columns.reporting_category',
                    defaultMessage: 'Reporting category'
                },
                currency: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.summary.1.columns.currency',
                    defaultMessage: 'Currency'
                },
                count: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.summary.1.columns.count',
                    defaultMessage: 'Count'
                },
                gross: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.summary.1.columns.gross',
                    defaultMessage: 'Gross'
                },
                net: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.summary.1.columns.net',
                    defaultMessage: 'Net'
                },
                fee: {
                    id: 'statement.reportType. connected_account_payout_reconciliation.summary.1.columns.fee',
                    defaultMessage: 'Fee'
                }
            }
        },
        [EReportFormat.ITEMIZED]: {
            name: 'connected_account_payout_reconciliation.itemized.5',
            columns: {
                automatic_payout_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.automatic_payout_id',
                    defaultMessage: 'Automatic payout ID'
                },
                automatic_payout_effective_at: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.automatic_payout_effective_at',
                    defaultMessage: 'Automatic payout effective at (requested time zone)'
                },
                balance_transaction_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.balance_transaction_id',
                    defaultMessage: 'Balance transaction ID'
                },
                created_utc: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.created_utc',
                    defaultMessage: 'Created (UTC)'
                },
                created: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.created',
                    defaultMessage: 'Created (requested time zone)'
                },
                available_on_utc: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.available_on_utc',
                    defaultMessage: 'Available on (UTC)'
                },
                available_on: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.available_on',
                    defaultMessage: 'Available on (requested time zone)'
                },
                currency: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.currency',
                    defaultMessage: 'Currency'
                },
                gross: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.gross',
                    defaultMessage: 'Gross'
                },
                net: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.net',
                    defaultMessage: 'Net'
                },
                fee: {
                    id: 'statement.reportType. connected_account_payout_reconciliation.summary.1.columns.fee',
                    defaultMessage: 'Fee'
                },
                reporting_category: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.reporting_category',
                    defaultMessage: 'Reporting category'
                },
                source_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.source_id',
                    defaultMessage: 'Source ID'
                },
                description: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.description',
                    defaultMessage: 'Description'
                },
                customer_facing_amount: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.customer_facing_amount',
                    defaultMessage: 'Customer-facing amount'
                },
                customer_facing_currency: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.customer_facing_currency',
                    defaultMessage: 'Customer-facing currency'
                },
                regulatory_tag: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.regulatory_tag',
                    defaultMessage: 'Regulatory tag'
                },
                automatic_payout_effective_at_utc: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.automatic_payout_effective_at_utc',
                    defaultMessage: 'Automatic payout effective at (UTC)'
                },
                customer_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.customer_id',
                    defaultMessage: 'Customer ID'
                },
                customer_email: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.customer_email',
                    defaultMessage: 'Customer email'
                },
                customer_name: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.customer_name',
                    defaultMessage: 'Customer name'
                },
                customer_description: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.customer_description',
                    defaultMessage: 'Customer description'
                },
                shipping_address_line1: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.shipping_address_line1',
                    defaultMessage: 'Shipping address line 1'
                },
                shipping_address_line2: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.shipping_address_line2',
                    defaultMessage: 'Shipping address line 2'
                },
                shipping_address_city: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.shipping_address_city',
                    defaultMessage: 'Shipping address city'
                },
                shipping_address_state: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.shipping_address_state',
                    defaultMessage: 'Shipping address state'
                },
                shipping_address_postal_code: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.shipping_address_postal_code',
                    defaultMessage: 'Shipping address postal code'
                },
                shipping_address_country: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.shipping_address_country',
                    defaultMessage: 'Shipping address country'
                },
                charge_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.charge_id',
                    defaultMessage: 'Charge ID'
                },
                payment_intent_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.payment_intent_id',
                    defaultMessage: 'Payment intent ID'
                },
                charge_created_utc: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.charge_created_utc',
                    defaultMessage: 'Charge created (UTC)'
                },
                charge_created: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.charge_created',
                    defaultMessage: 'Charge created (requested time zone)'
                },
                invoice_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.invoice_id',
                    defaultMessage: 'Invoice ID'
                },
                subscription_id: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.subscription_id',
                    defaultMessage: 'Subscription ID'
                },
                payment_method_type: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.payment_method_type',
                    defaultMessage: 'Payment method type'
                },
                card_brand: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.card_brand',
                    defaultMessage: 'Card brand'
                },
                card_funding: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.card_funding',
                    defaultMessage: 'Card funding'
                },
                card_country: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.card_country',
                    defaultMessage: 'Card country'
                },
                statement_descriptor: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.statement_descriptor',
                    defaultMessage: 'Statement descriptor'
                },
                dispute_reason: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.dispute_reason',
                    defaultMessage: 'Dispute reason'
                },
                connected_account: {
                    id: 'statement.reportType.connected_account_payout_reconciliation.itemized.5.columns.connected_account',
                    defaultMessage: 'Connected account'
                },
                'payment_metadata[applicationFeeAmount]': {
                    id: '',
                    defaultMessage: 'Application fee amount'
                },
                'payment_metadata[applicationFeeTaxAmount]': {
                    id: '',
                    defaultMessage: 'Application fee tax amount'
                },
                'payment_metadata[connectAccountId]': {
                    id: '',
                    defaultMessage: 'Connected account ID'
                },
                'payment_metadata[franchiseFeeAmount]': {
                    id: '',
                    defaultMessage: 'Franchise fee Amount'
                },
                'payment_metadata[franchiseeAccountId]': {
                    id: '',
                    defaultMessage: 'Franchisee account ID'
                },
                'payment_metadata[franchisorAccountId]': {
                    id: '',
                    defaultMessage: 'Franchisor account ID'
                },
                'payment_metadata[isThreeDSecure]': {
                    id: '',
                    defaultMessage: 'Is threeD secure'
                },
                'payment_metadata[locationId]': {
                    id: '',
                    defaultMessage: 'Location ID'
                },
                'payment_metadata[orderId]': {
                    id: '',
                    defaultMessage: 'Order ID'
                },
                'payment_metadata[paymentAmount]': {
                    id: '',
                    defaultMessage: 'Payment amount'
                },
                'payment_metadata[tenantId]': {
                    id: '',
                    defaultMessage: 'Tenant ID'
                },
                'payment_metadata[userId]': {
                    id: '',
                    defaultMessage: 'User ID'
                }
            }
        }
    },
    [EReportType.BALANCE]: {
        [EReportFormat.SUMMARY]: {
            name: 'connected_account_balance_change_from_activity.summary.1',
            columns: {
                reporting_category: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.summary.1.columns.reporting_category',
                    defaultMessage: 'Reporting category'
                },
                currency: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.summary.1.columns.currency',
                    defaultMessage: 'Currency'
                },
                count: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.summary.1.columns.count',
                    defaultMessage: 'Count'
                },
                gross: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.summary.1.columns.gross',
                    defaultMessage: 'Gross'
                },
                net: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.summary.1.columns.net',
                    defaultMessage: 'Net'
                },
                fee: {
                    id: 'statement.reportType. connected_account_balance_change_from_activity.summary.1.columns.fee',
                    defaultMessage: 'Fee'
                }
            }
        },
        [EReportFormat.ITEMIZED]: {
            name: 'connected_account_balance_change_from_activity.itemized.3',
            columns: {
                balance_transaction_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.balance_transaction_id',
                    defaultMessage: 'Balance transaction ID'
                },
                created_utc: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.created_utc',
                    defaultMessage: 'Created (UTC)'
                },
                created: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.created',
                    defaultMessage: 'Created (requested time zone)'
                },
                available_on_utc: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.available_on_utc',
                    defaultMessage: 'Available on (UTC)'
                },
                available_on: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.available_on',
                    defaultMessage: 'Available on (requested time zone)'
                },
                currency: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.currency',
                    defaultMessage: 'Currency'
                },
                gross: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.gross',
                    defaultMessage: 'Gross'
                },
                net: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.net',
                    defaultMessage: 'Net'
                },
                fee: {
                    id: 'statement.reportType. connected_account_balance_change_from_activity.summary.1.columns.fee',
                    defaultMessage: 'Fee'
                },
                reporting_category: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.reporting_category',
                    defaultMessage: 'Reporting category'
                },
                source_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.source_id',
                    defaultMessage: 'Source ID'
                },
                description: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.description',
                    defaultMessage: 'Description'
                },
                customer_facing_amount: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.customer_facing_amount',
                    defaultMessage: 'Customer-facing amount'
                },
                customer_facing_currency: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.customer_facing_currency',
                    defaultMessage: 'Customer-facing currency'
                },
                regulatory_tag: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.regulatory_tag',
                    defaultMessage: 'Regulatory tag'
                },
                automatic_payout_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.automatic_payout_id',
                    defaultMessage: 'Automatic payout ID'
                },
                automatic_payout_effective_at_utc: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.automatic_payout_effective_at_utc',
                    defaultMessage: 'Automatic payout effective at (UTC)'
                },
                automatic_payout_effective_at: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.automatic_payout_effective_at',
                    defaultMessage: 'Automatic payout effective at (requested time zone)'
                },
                customer_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.customer_id',
                    defaultMessage: 'Customer ID'
                },
                customer_email: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.customer_email',
                    defaultMessage: 'Customer email'
                },
                customer_name: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.customer_name',
                    defaultMessage: 'Customer name'
                },
                customer_description: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.customer_description',
                    defaultMessage: 'Customer description'
                },
                shipping_address_line1: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.shipping_address_line1',
                    defaultMessage: 'Shipping address line 1'
                },
                shipping_address_line2: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.shipping_address_line2',
                    defaultMessage: 'Shipping address line 2'
                },
                shipping_address_city: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.shipping_address_city',
                    defaultMessage: 'Shipping address city'
                },
                shipping_address_state: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.shipping_address_state',
                    defaultMessage: 'Shipping address state'
                },
                shipping_address_postal_code: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.shipping_address_postal_code',
                    defaultMessage: 'Shipping address postal code'
                },
                shipping_address_country: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.shipping_address_country',
                    defaultMessage: 'Shipping address country'
                },
                charge_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.charge_id',
                    defaultMessage: 'Charge ID'
                },
                payment_intent_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.payment_intent_id',
                    defaultMessage: 'Payment intent ID'
                },
                charge_created_utc: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.charge_created_utc',
                    defaultMessage: 'Charge created (UTC)'
                },
                charge_created: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.charge_created',
                    defaultMessage: 'Charge created (requested time zone)'
                },
                invoice_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.invoice_id',
                    defaultMessage: 'Invoice ID'
                },
                subscription_id: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.subscription_id',
                    defaultMessage: 'Subscription ID'
                },
                payment_method_type: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.payment_method_type',
                    defaultMessage: 'Payment method type'
                },
                card_brand: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.card_brand',
                    defaultMessage: 'Card brand'
                },
                card_funding: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.card_funding',
                    defaultMessage: 'Card funding'
                },
                card_country: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.card_country',
                    defaultMessage: 'Card country'
                },
                statement_descriptor: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.statement_descriptor',
                    defaultMessage: 'Statement descriptor'
                },
                dispute_reason: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.dispute_reason',
                    defaultMessage: 'Dispute reason'
                },
                connected_account: {
                    id: 'statement.reportType.connected_account_balance_change_from_activity.itemized.3.columns.connected_account',
                    defaultMessage: 'Connected account'
                },
                'payment_metadata[applicationFeeAmount]': {
                    id: '',
                    defaultMessage: 'Application fee amount'
                },
                'payment_metadata[applicationFeeTaxAmount]': {
                    id: '',
                    defaultMessage: 'Application fee tax amount'
                },
                'payment_metadata[connectAccountId]': {
                    id: '',
                    defaultMessage: 'Connected account ID'
                },
                'payment_metadata[franchiseFeeAmount]': {
                    id: '',
                    defaultMessage: 'Franchise fee Amount'
                },
                'payment_metadata[franchiseeAccountId]': {
                    id: '',
                    defaultMessage: 'Franchisee account ID'
                },
                'payment_metadata[franchisorAccountId]': {
                    id: '',
                    defaultMessage: 'Franchisor account ID'
                },
                'payment_metadata[isThreeDSecure]': {
                    id: '',
                    defaultMessage: 'Is threeD secure'
                },
                'payment_metadata[locationId]': {
                    id: '',
                    defaultMessage: 'Location ID'
                },
                'payment_metadata[orderId]': {
                    id: '',
                    defaultMessage: 'Order ID'
                },
                'payment_metadata[paymentAmount]': {
                    id: '',
                    defaultMessage: 'Payment amount'
                },
                'payment_metadata[tenantId]': {
                    id: '',
                    defaultMessage: 'Tenant ID'
                },
                'payment_metadata[userId]': {
                    id: '',
                    defaultMessage: 'User ID'
                }
            }
        }
    }
};

export const reportTypeToMetadata: Record<
    string,
    {
        type: EReportType;
        format: EReportFormat;
        categories: { name: string; columns: string[] }[];
    }
> = {
    'connected_account_payout_reconciliation.itemized.5': {
        type: EReportType.RECONCILIATION,
        format: EReportFormat.ITEMIZED,
        categories: [
            {
                name: 'payout',
                columns: [
                    'automatic_payout_id',
                    'automatic_payout_effective_at_utc',
                    'automatic_payout_effective_at'
                ]
            },
            {
                name: 'balance_transaction',
                columns: [
                    'connected_account',
                    'balance_transaction_id',
                    'created_utc',
                    'created',
                    'available_on_utc',
                    'available_on',
                    'currency',
                    'gross',
                    'net',
                    'fee',
                    'reporting_category',
                    'source_id',
                    'description',
                    'customer_facing_amount',
                    'customer_facing_currency'
                ]
            },
            {
                name: 'customer',
                columns: [
                    'customer_id',
                    'customer_email',
                    'customer_name',
                    'customer_description',
                    'shipping_address_line1',
                    'shipping_address_line2',
                    'shipping_address_city',
                    'shipping_address_state',
                    'shipping_address_postal_code',
                    'shipping_address_country'
                ]
            },
            {
                name: 'payment',
                columns: [
                    'charge_id',
                    'payment_intent_id',
                    'charge_created_utc',
                    'charge_created',
                    'invoice_id',
                    'subscription_id',
                    'payment_method_type',
                    'card_brand',
                    'card_funding',
                    'card_country',
                    'statement_descriptor',
                    'dispute_reason'
                ]
            },
            {
                name: 'paymentMetaData',
                columns: [
                    'payment_metadata[applicationFeeAmount]',
                    'payment_metadata[applicationFeeTaxAmount]',
                    'payment_metadata[connectAccountId]',
                    'payment_metadata[franchiseFeeAmount]',
                    'payment_metadata[franchiseeAccountId]',
                    'payment_metadata[franchisorAccountId]',
                    'payment_metadata[isThreeDSecure]',
                    'payment_metadata[locationId]',
                    'payment_metadata[orderId]',
                    'payment_metadata[paymentAmount]',
                    'payment_metadata[tenantId]',
                    'payment_metadata[userId]'
                ]
            }
        ]
    },
    'connected_account_activity.itemized.2': {
        type: EReportType.ACTIVITY,
        format: EReportFormat.ITEMIZED,
        categories: [
            {
                name: 'balance_transaction',
                columns: [
                    'connected_account',
                    'balance_transaction_id',
                    'balance_transaction_created_at',
                    'balance_transaction_reporting_category',
                    'balance_transaction_component',
                    'event_type',
                    'activity_at',
                    'activity_interval_type',
                    'activity_start_date',
                    'activity_end_date',
                    'currency',
                    'amount',
                    'customer_facing_currency',
                    'customer_facing_amount',
                    'balance_transaction_description',
                    'fee_id',
                    'destination_id'
                ]
            },
            {
                name: 'customer',
                columns: [
                    'customer_id',
                    'customer_email',
                    'customer_name',
                    'customer_description',
                    'shipping_address_line1',
                    'shipping_address_line2',
                    'shipping_address_city',
                    'shipping_address_state',
                    'shipping_address_postal_code',
                    'shipping_address_country'
                ]
            },
            {
                name: 'payout',
                columns: ['automatic_payout_id', 'automatic_payout_effective_at']
            },
            {
                name: 'payment',
                columns: [
                    'charge_id',
                    'payment_intent_id',
                    'invoice_id',
                    'subscription_id',
                    'transfer_id',
                    'payment_method_type',
                    'card_brand',
                    'card_funding',
                    'card_country',
                    'statement_descriptor'
                ]
            },
            {
                name: 'refund_and_dispute',
                columns: ['refund_id', 'dispute_id']
            },
            {
                name: 'paymentMetaData',
                columns: [
                    'payment_metadata[applicationFeeAmount]',
                    'payment_metadata[applicationFeeTaxAmount]',
                    'payment_metadata[connectAccountId]',
                    'payment_metadata[franchiseFeeAmount]',
                    'payment_metadata[franchiseeAccountId]',
                    'payment_metadata[franchisorAccountId]',
                    'payment_metadata[isThreeDSecure]',
                    'payment_metadata[locationId]',
                    'payment_metadata[orderId]',
                    'payment_metadata[paymentAmount]',
                    'payment_metadata[tenantId]',
                    'payment_metadata[userId]'
                ]
            }
        ]
    },
    'connected_account_activity.summary.1': {
        type: EReportType.ACTIVITY,
        format: EReportFormat.SUMMARY,
        categories: [
            {
                name: 'all',
                columns: ['reporting_category', 'currency', 'count', 'gross', 'net', 'fee']
            }
        ]
    },
    'connected_account_payout_reconciliation.summary.1': {
        type: EReportType.RECONCILIATION,
        format: EReportFormat.SUMMARY,
        categories: [
            {
                name: 'all',
                columns: ['reporting_category', 'currency', 'count', 'gross', 'net', 'fee']
            }
        ]
    },
    'connected_account_balance_change_from_activity.summary.1': {
        type: EReportType.BALANCE,
        format: EReportFormat.SUMMARY,
        categories: [
            {
                name: 'all',
                columns: ['reporting_category', 'currency', 'count', 'gross', 'net', 'fee']
            }
        ]
    },
    'connected_account_balance_change_from_activity.itemized.3': {
        type: EReportType.BALANCE,
        format: EReportFormat.ITEMIZED,
        categories: [
            {
                name: 'balance_transaction',
                columns: [
                    'connected_account',
                    'balance_transaction_id',
                    'created_utc',
                    'created',
                    'available_on_utc',
                    'available_on',
                    'currency',
                    'gross',
                    'net',
                    'fee',
                    'reporting_category',
                    'source_id',
                    'description',
                    'customer_facing_amount',
                    'customer_facing_currency'
                ]
            },
            {
                name: 'payout',
                columns: [
                    'automatic_payout_id',
                    'automatic_payout_effective_at_utc',
                    'automatic_payout_effective_at'
                ]
            },
            {
                name: 'customer',
                columns: [
                    'customer_id',
                    'customer_email',
                    'customer_name',
                    'customer_description',
                    'shipping_address_line1',
                    'shipping_address_line2',
                    'shipping_address_city',
                    'shipping_address_state',
                    'shipping_address_postal_code',
                    'shipping_address_country'
                ]
            },
            {
                name: 'payment',
                columns: [
                    'charge_id',
                    'payment_intent_id',
                    'charge_created_utc',
                    'charge_created',
                    'invoice_id',
                    'subscription_id',
                    'payment_method_type',
                    'card_brand',
                    'card_funding',
                    'card_country',
                    'statement_descriptor',
                    'dispute_reason'
                ]
            },
            {
                name: 'paymentMetaData',
                columns: [
                    'payment_metadata[applicationFeeAmount]',
                    'payment_metadata[applicationFeeTaxAmount]',
                    'payment_metadata[connectAccountId]',
                    'payment_metadata[franchiseFeeAmount]',
                    'payment_metadata[franchiseeAccountId]',
                    'payment_metadata[franchisorAccountId]',
                    'payment_metadata[isThreeDSecure]',
                    'payment_metadata[locationId]',
                    'payment_metadata[orderId]',
                    'payment_metadata[paymentAmount]',
                    'payment_metadata[tenantId]',
                    'payment_metadata[userId]'
                ]
            }
        ]
    }
};

export const reportCategories: Record<string, { id: string; defaultMessage: string }> = {
    all: {
        id: '',
        defaultMessage: ''
    },
    balance_transaction: {
        id: 'statement.category.balance_transaction',
        defaultMessage: 'Balance transaction'
    },
    charge: {
        id: 'statement.category.charge',
        defaultMessage: 'Charge'
    },
    payment: {
        id: 'statement.category.payment',
        defaultMessage: 'Payment'
    },
    payment_intent: {
        id: 'statement.category.payment_intent',
        defaultMessage: 'Payment intent'
    },
    refund: {
        id: 'statement.category.refund',
        defaultMessage: 'Refund'
    },
    dispute: {
        id: 'statement.category.dispute',
        defaultMessage: 'Dispute'
    },
    invoice: {
        id: 'statement.category.invoice',
        defaultMessage: 'Invoice'
    },
    subscription: {
        id: 'statement.category.subscription',
        defaultMessage: 'Subscription'
    },
    customer: {
        id: 'statement.category.customer',
        defaultMessage: 'Customer'
    },
    payout: {
        id: 'statement.category.payout',
        defaultMessage: 'Payout'
    },
    fee: {
        id: 'statement.category.fee',
        defaultMessage: 'Fee'
    },
    connect: {
        id: 'statement.category.connect',
        defaultMessage: 'Connect'
    },
    source_transaction: {
        id: 'statement.category.source_transaction',
        defaultMessage: 'Source Transaction'
    },
    other: {
        id: 'statement.category.other',
        defaultMessage: 'Other'
    },
    refund_and_dispute: {
        id: 'statement.category.refund_and_dispute',
        defaultMessage: 'Refund and Dispute'
    },
    paymentMetaData: {
        id: 'statement.category.refund_and_dispute',
        defaultMessage: 'Payment Metadata'
    }
};

export const intervalOption = [
    { value: EScheduleReportInterval.DAILY, label: 'Daily' },
    { value: EScheduleReportInterval.WEEKLY, label: 'Weekly' },
    { value: EScheduleReportInterval.MONTHLY, label: 'Monthly' }
];

export const weeklyOptions = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' }
];

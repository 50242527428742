import { Chip, styled } from '@mui/material';
import { green, orange } from '@mui/material/colors';
import React from 'react';
import { IOrder } from '../model/Order';

const PREFIX = 'OrderStateChip';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`
};

const StyledChip = styled(Chip)(({ theme }) => ({
    [`&.${classes.green}`]: { backgroundColor: green['A700'], color: theme.palette.primary.contrastText },
    [`&.${classes.yellow}`]: { backgroundColor: orange[500], color: theme.palette.primary.contrastText }
}));

interface OrderStateChipProps {
    order: IOrder;
}

export const OrderStateChip: React.FC<OrderStateChipProps> = ({ order }) => {
    const className = React.useMemo(() => {
        if (order.isOpen) {
            return classes.yellow;
        }
        return classes.green;
    }, [order.isOpen]);
    return <StyledChip className={className} label={order.isOpen ? 'Open' : 'Closed'} />;
};

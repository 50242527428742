export enum contentActionTypes {
    START_REQUEST = 'START_REQUEST',
    END_REQUEST = 'END_REQUEST',
    SET_CURRENT_LAYOUT_SUCCESS = 'SET_CURRENT_LAYOUT_SUCCESS',
    SET_CURRENT_LAYOUT_ERROR = 'SET_CURRENT_LAYOUT_ERROR',
    UPDATE_CURRENT_LAYOUT_PROPERTIES = 'UPDATE_CURRENT_LAYOUT_PROPERTIES',
    ADD_CURRENT_MODULE_SUCCESS = 'ADD_CURRENT_MODULE_SUCCESS',
    UPDATE_CURRENT_MODULE_SUCCESS = 'CONTENT_UPDATE_CURRENT_MODULE_SUCCESS',
    ADD_CURRENT_MODULE_ERROR = 'ADD_CURRENT_MODULE_ERROR',
    UPDATE_CURRENT_MODULE_PROPERTIES_SUCCESS = 'UPDATE_CURRENT_MODULE_PROPERTIES_SUCCESS',
    UPDATE_CURRENT_MODULE_ERROR = 'UPDATE_CURRENT_MODULE_ERROR',
    CLEAR_CURRENT_MODULES = 'CLEAR_CURRENT_MODULES',
    CLEAR_CURRENT_LAYOUT = 'CLEAR_CURRENT_LAYOUT',
    UPDATE_SELECTED_MODULE = 'UPDATE_SELECTED_MODULE',
    DELETE_SELECTED_MODULE = 'CONTENT_DELETE_SELECTED_MODULE'
}

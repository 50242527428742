import { ActionCreator } from 'redux';
import { Audience, AudiencesPage, isAudience } from 'components/audience/models/segmentModel';
import { segmentsApi } from 'components/audience/segmentsApi';
import { CustomerSegment } from 'components/customers/models/Customer';
import {
    MESSAGE_MARKETING_AUDIENCE_CREATE_ERROR,
    MESSAGE_MARKETING_AUDIENCE_CREATE_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { isArrayOf } from 'lib/typeguards';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { AudiencesActionTypes } from './audiencesActionTypes';

export interface AudienceCreateRequestData {
    title: string;
    description?: string;
    type: string;
    expiryHorizon?: number;
}

const AUDIENCES_PER_PAGE = 20;

export interface AudiencesRequestAction {
    type: AudiencesActionTypes.END_REQUEST | AudiencesActionTypes.START_REQUEST;
}

export interface AudiencesGetErrorAction {
    type: AudiencesActionTypes.GET_AUDIENCES_ERROR;
}

export interface AudiencesGetListAction {
    type: AudiencesActionTypes.GET_AUDIENCES_SUCCESS | AudiencesActionTypes.LOAD_AUDIENCES_SUCCESS;
    audiences: Audience[];
    summary: AudiencesPage;
}

export interface AudiencesGetCustomerSegmentsAction {
    type:
        | AudiencesActionTypes.GET_CUSTOMER_SEGMENTS_ERROR
        | AudiencesActionTypes.GET_CUSTOMER_SEGMENTS_SUCCESS;
    customerSegments: CustomerSegment[];
}

export interface AudiencesCreateAction {
    type: AudiencesActionTypes.CREATE_AUDIENCE_SUCCESS;
    audience: Audience;
}

const startRequest = {
    type: AudiencesActionTypes.START_REQUEST
};

const endRequest = {
    type: AudiencesActionTypes.END_REQUEST
};

export const getAudiences =
    () => async (dispatch: ActionCreator<AudiencesGetListAction | AudiencesGetErrorAction>) => {
        dispatch(startRequest);
        const queryParameters = [{ key: 'limit', value: AUDIENCES_PER_PAGE }];
        const response = await segmentsApi.getList({ queryParameters });
        if (!response.ok || !isArrayOf(isAudience, response.body.items)) {
            logger.error('TypeError: expected Audience[], got:', response.body.items);
            dispatch({
                type: AudiencesActionTypes.GET_AUDIENCES_ERROR
            });
        } else {
            dispatch({
                audiences: response.body.items,
                type: AudiencesActionTypes.GET_AUDIENCES_SUCCESS,
                summary: response.body.page
            });
        }

        dispatch(endRequest);
    };

export const getAllAudiences =
    () => async (dispatch: ActionCreator<AudiencesGetCustomerSegmentsAction | AudiencesGetErrorAction>) => {
        dispatch(startRequest);
        try {
            const customerSegments = await segmentsApi.getAll();
            dispatch({
                customerSegments,
                type: AudiencesActionTypes.GET_CUSTOMER_SEGMENTS_SUCCESS
            });
        } catch (e) {
            logger.error('Failed to load all audiences', e);
            dispatch({
                type: AudiencesActionTypes.GET_CUSTOMER_SEGMENTS_ERROR
            });
        }

        dispatch(endRequest);
    };

export const loadAudiences =
    (startKey: string) =>
    async (dispatch: ActionCreator<AudiencesGetListAction | AudiencesGetErrorAction>) => {
        dispatch(startRequest);
        const queryParameters = [
            { key: 'limit', value: AUDIENCES_PER_PAGE },
            { key: 'startKey', value: startKey }
        ];
        const response = await segmentsApi.getList({ queryParameters });
        if (!response.ok || !isArrayOf(isAudience, response.body.items)) {
            logger.error('TypeError: expected Audience[], got:', response.body.items);
            dispatch({
                type: AudiencesActionTypes.GET_AUDIENCES_ERROR
            });
        } else {
            dispatch({
                audiences: response.body.items,
                type: AudiencesActionTypes.LOAD_AUDIENCES_SUCCESS,
                summary: response.body.page
            });
        }

        dispatch(endRequest);
    };

export const createAudience =
    (body: AudienceCreateRequestData) => async (dispatch: ActionCreator<AudiencesCreateAction>) => {
        dispatch(startRequest);
        try {
            const result = await segmentsApi.create({ body });
            if (!result.ok) {
                throw new Error(result.body.mesaage);
            }
            dispatch({ type: AudiencesActionTypes.CREATE_AUDIENCE_SUCCESS, audience: result.body });
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_CREATE_SUCCESS, { variant: 'success' }));
            dispatch(endRequest);
            return result.body;
        } catch (e) {
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_AUDIENCE_CREATE_ERROR, { variant: 'error' }));
            logger.error('Failed to create an action', e);
            dispatch(endRequest);
            return null;
        }
    };

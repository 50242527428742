import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class RulesApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'rules', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
}

export const rulesApi = new RulesApi(httpClient);

import { parseFloatOrUndefined, roundToDecimal } from 'lib/helpers';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';

type CustomRefundHook = [
    string,
    number,
    ChangeEventHandler<HTMLInputElement>,
    FocusEventHandler<HTMLInputElement>
];

export function useCustomRefund(): CustomRefundHook {
    const [amount, setAmount] = React.useState<string>();
    const formattedAmount = React.useMemo(() => parseFloatOrUndefined(String(amount)), [amount]);
    const handleAmountChange = React.useCallback((e: React.ChangeEvent<{ value: string; name: string }>) => {
        setAmount(e.target.value.replace('-', ''));
    }, []);
    const handleAmountBlur = React.useCallback(() => {
        const formattedAmount = parseFloatOrUndefined(amount);

        if (!formattedAmount) {
            return setAmount(undefined);
        }

        setAmount(String(roundToDecimal(Math.abs(formattedAmount)).toFixed(2)));
    }, [amount]);
    return [amount, formattedAmount, handleAmountChange, handleAmountBlur];
}

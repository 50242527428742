import React from 'react';
import { Grid } from '@mui/material';
import { getCurrencyString } from 'lib/helpers';
import { CardViewTextRow } from 'ui/viewComponents';
import { isRefundAction } from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';

export const ActionCardRefund: React.FC<ActionCardModalComponentProps> = ({ action }) => {
    if (!isRefundAction(action)) {
        return <ActionCardBase action={action} broken />;
    }
    return (
        <ActionCardBase action={action} size="double">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <CardViewTextRow title="Date" value={getActionDate(action)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow title="Currency" value={action.metadata.value.currency} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow
                        title="Amount"
                        value={getCurrencyString(
                            action.metadata.value.amount,
                            action.metadata.value.currency
                        )}
                    />
                </Grid>
            </Grid>
        </ActionCardBase>
    );
};

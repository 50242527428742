import React, { CSSProperties } from 'react';
import { AwardTemplate } from 'components/customers/models/Award';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { rgbaToHex } from 'lib/helpers';
import { perkPreviewFontSizesByType } from './PerkPreviewEditable';

interface AdhocPerkPreviewEditableProps {
    textAlign: CSSProperties['textAlign'];
    textColor: RGBAValue;
    perkType: AwardTemplate;
    onTitleClick: () => void;
}

export const AdhocPerkPreviewEditable: React.FC<AdhocPerkPreviewEditableProps> = ({
    textAlign,
    textColor,
    perkType,
    onTitleClick
}) => {
    const fontSizes = perkPreviewFontSizesByType[perkType];

    return (
        <React.Fragment>
            <TextFormField
                naked
                multiline
                maxRows={2}
                name="titleText"
                fontSize={`${fontSizes.title}px`}
                onClick={onTitleClick}
                textAlign={textAlign}
                textColor={rgbaToHex(textColor)}
                placeholder="Click to add text or leave blank"
            />
            <TextFormField
                fontSize={`${fontSizes.subtitle}px`}
                textAlign={textAlign}
                textColor={rgbaToHex(textColor)}
                multiline
                maxRows={2}
                name="subtitle"
                naked
                placeholder="Click to add text or leave blank"
            />
            <TextFormField
                fontSize={`${fontSizes.subtitleTwo}px`}
                multiline
                maxRows={2}
                textAlign={textAlign}
                textColor={rgbaToHex(textColor)}
                name="subtitle2"
                naked
                placeholder="Click to add text or leave blank"
            />
        </React.Fragment>
    );
};

import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { AwardGeneralFormData } from '../forms/AwardGeneralForm';
import { AwardCreationStepper, AwardCreationStepperProps } from '../AwardEnrichmentCreationStepper';

interface AwardCreationModalProps extends AwardCreationStepperProps {
    open: boolean;
    currencySymbol: string;
    initialGeneralData?: Partial<AwardGeneralFormData>;
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    maxWidth: theme.spacing(160)
}));

export const AwardCreationModal: React.FC<AwardCreationModalProps> = props => {
    const { open, onClose, ...rest } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <AwardCreationStepper onClose={onClose} {...rest} />
        </StyledMuiModal>
    );
};

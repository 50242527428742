import { SystemRole } from '@pepperhq/auth-client';

export interface TenantAccess {
    accessState: TenantAccessState;
    authId: string;
    createdDate: string;
    email: string;
    id: string;
    roleId: SystemRole;
    tenantId: string;
    locationIds?: string[];
    tenantName: string;
    updatedDate: string;
    tenantDisabled?: boolean;
}

export enum TenantAccessState {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    REVOKED = 'REVOKED'
}

import { isNumber, isOptional, isOptionalString, isString } from 'lib/typeguards';

export enum AudienceType {
    PERSISTENT = 'PERSISTENT',
    ROLLING = 'ROLLING',
    SCHEDULED = 'SCHEDULED'
}

export interface Audience {
    _id: string;
    tenantId: string;
    title: string;
    created: Date;
    updated: Date;
    type: AudienceType;
    description?: string;
    expiryHorizon?: number;
    count?: number;
}

export interface AudiencesPage {
    limit: number;
    count: number;
    startKey?: string;
    nextKey?: string;
}

export function isAudience(audience: any): audience is Audience {
    return (
        !!audience &&
        isString(audience._id) &&
        isString(audience.tenantId) &&
        isString(audience.title) &&
        isOptionalString(audience.description) &&
        isString(audience.created) &&
        isString(audience.updated) &&
        isOptional(isNumber, audience.expiryHorizon) &&
        audience.type in AudienceType
    );
}

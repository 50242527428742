import { AwardTemplate } from 'components/customers/models/Award';
import { isArrayOf, isBoolean, isNumber, isOptional, isOptionalString, isString } from 'lib/typeguards';
import { LoyaltyScope } from '../enums';

interface VisualisationKeypair {
    name: string;
    value: string;
}

export enum TextAlignment {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
    JUSTIFY = 'JUSTIFY'
}

export enum VerticalAlignment {
    TOP = 'TOP',
    CENTER = 'CENTER',
    BOTTOM = 'BOTTOM'
}

export const valignStyle = {
    [VerticalAlignment.TOP]: 'flex-start',
    [VerticalAlignment.CENTER]: 'center',
    [VerticalAlignment.BOTTOM]: 'flex-end'
};

export interface IPerkBasketQualification {
    matchCode?: string | string[];
    minimumItems?: number;
    minimumValue?: number;
    scope?: LoyaltyScope;
}

export interface IPerkPoints {
    initial: number;
    min: number;
    max: number;
    step: number;
    redemption: number;
}

export interface IPerkDateTimeRestriction {
    startTime?: number;
    endTime?: number;
    daysOfWeek?: number;
    startDate?: Date;
    endDate?: Date;
}

export interface PerkDiscount {
    value: number;
    type: 'VALUE' | 'PERCENTAGE' | 'OVERRIDE';
    maxValue?: number;
    scope: 'ITEM' | 'CATEGORY' | 'BASKET' | 'ITEM_OR_CATEGORY';
    scopeMatchCode?: string | string[];
    rule: 'CHEAPEST' | 'COSTLIEST' | 'ALL' | 'CHEAPEST_FIRST' | 'COSTLIEST_FIRST' | 'ALL_ALLOCATE';
    childItemRule: 'INCLUDE' | 'EXCLUDE';
}

export interface IBasketQualification {
    matchCode: string | string[];
    minimumItems?: number;
    scope: LoyaltyScope;
}

export interface Perk {
    _id: string;
    tenantId: string;
    title: string;
    internalTitle?: string;
    template: AwardTemplate;
    redemptionMethod: 'AUTO' | 'CODE_PDF417' | 'CODE_QR' | 'VOUCHER' | 'PSEUDO_CURRENCY';
    discount?: PerkDiscount;
    points: {
        initial: number;
        min: number;
        max: number;
        step: number;
        redemption: number;
        available?: number;
    };
    maximumPointCashValue: number;
    auto: boolean;
    token: string;
    enabled: boolean;
    isGlobal: boolean;
    isTier: boolean;
    isDonatable: boolean;
    terms: string;
    priority: string;
    visualisation: PerkVisualisation;
    locationIds?: string[];
    basketQualification?: IBasketQualification;
}

export interface PerkVisualisation {
    templates: VisualisationKeypair[];
    media: VisualisationKeypair[];
    colours: VisualisationKeypair[];
    stampImageUrl?: string;
    textColour: string;
    stampColour?: string;
    randomiseStampPositions?: boolean;
    backgroundOverlayColour?: string;
    backgroundOverlayOpacity?: number;
    subtitleText?: string;
    description: string;
    availableCardCopy?: string;
    nextCardProgressCopy?: string;
    textAlignment: TextAlignment;
    subtitleTwoText?: string;
    verticalAlignment: VerticalAlignment;
    backgroundImageUrl?: string;
    titleText?: string;
}

function isVisualisationKeypair(input: any): input is VisualisationKeypair {
    return isString(input.name) && isString(input.value);
}

// FIXME this typeguard is missing all the new fields
export function isPerk(input: any): input is Perk {
    return (
        isString(input._id) &&
        isString(input.tenantId) &&
        isString(input.title) &&
        isOptionalString(input.internalTitle) &&
        (input.template === 'PSEUDO_CURRENCY' ||
            input.template === 'AD_HOC' ||
            input.template === 'STAMP_CARD' ||
            input.template === 'LEVEL' ||
            input.template === 'CLUB') &&
        (input.redemptionMethod === 'AUTO' ||
            input.redemptionMethod === 'CODE_PDF417' ||
            input.redemptionMethod === 'CODE_QR' ||
            input.redemptionMethod === 'VOUCHER' ||
            input.redemptionMethod === 'PSEUDO_CURRENCY') &&
        !!input.points &&
        isNumber(input.points.initial) &&
        isNumber(input.points.min) &&
        isNumber(input.points.max) &&
        isNumber(input.points.step) &&
        isNumber(input.points.redemption) &&
        isNumber(input.maximumPointCashValue) &&
        isBoolean(input.auto) &&
        isString(input.token) &&
        isOptional(isBoolean, input.enabled) &&
        isBoolean(input.isGlobal) &&
        isBoolean(input.isTier) &&
        isBoolean(input.isDonatable) &&
        isString(input.terms) &&
        isNumber(input.priority) &&
        !!input.visualisation &&
        isArrayOf(isVisualisationKeypair, input.visualisation.templates) &&
        isArrayOf(isVisualisationKeypair, input.visualisation.media) &&
        isArrayOf(isVisualisationKeypair, input.visualisation.colours) &&
        isString(input.visualisation.stampImageUrl) &&
        isString(input.visualisation.textColour) &&
        isString(input.visualisation.stampColour) &&
        isBoolean(input.visualisation.randomiseStampPositions) &&
        isString(input.visualisation.backgroundOverlayColour) &&
        isNumber(input.visualisation.backgroundOverlayOpacity) &&
        isString(input.visualisation.subtitleText) &&
        isString(input.visualisation.description) &&
        isString(input.visualisation.availableCardCopy) &&
        isString(input.visualisation.nextCardProgressCopy) &&
        (input.visualisation.textAlignment === 'LEFT' ||
            input.visualisation.textAlignment === 'CENTER' ||
            input.visualisation.textAlignment === 'RIGHT' ||
            input.visualisation.textAlignment === 'JUSTIFY') &&
        isString(input.visualisation.subtitleTwoText) &&
        (input.visualisation.verticalAlignment === 'TOP' ||
            input.visualisation.verticalAlignment === 'CENTER' ||
            input.visualisation.verticalAlignment === 'BOTTOM') &&
        isString(input.visualisation.backgroundImageUrl)
    );
}

interface PerkData {
    items: Perk[];
    page: {
        nextKey?: string;
        startKey?: string;
        count: number;
        limit?: number;
    };
}

export function isPerkData(input: any): input is PerkData {
    return (
        isArrayOf(isPerk, input.items) &&
        !!input.page &&
        isOptionalString(input.page.nextKey) &&
        isOptionalString(input.page.startKey) &&
        isNumber(input.page.count) &&
        isOptional(isNumber, input.page.limit)
    );
}

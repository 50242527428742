import React from 'react';
import { Box } from '@mui/material';
import { DynamicImageryOverlayBox } from './DynamicImageryOverlayBox';
import { Container } from '@pepperhq/menu-sdk';

interface IProps {
    boxContainers: Container[];
    colors: string[];
}

export const DynamicImageryImageOverlay: React.FC<IProps> = ({ boxContainers, colors }) => (
    <Box width="100%" height="100%" position="relative">
        {boxContainers.map((item, index) => (
            <DynamicImageryOverlayBox
                key={`colored-box-${index}`}
                item={item}
                index={index}
                colors={colors}
            />
        ))}
    </Box>
);

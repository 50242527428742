/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Grid } from '@mui/material';
import { LocationServices } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';

interface LocationServicesCardEditComponentProps {
    onClose: () => void;
    form: LegacyForm;
    services: LocationServices;
}

export const LocationServicesCardEditComponent = (props: LocationServicesCardEditComponentProps) => (
    <AutoForm {...props}>
        {({
            pre_order,
            order_to_table,
            order_to_table_nfc,
            tab,
            pay_at_table,
            pay_at_table_nfc,
            checkins,
            offline_checkin,
            book_table,
            book_room
        }) => (
            <React.Fragment>
                <Grid item sm={6} xs={12}>
                    <Grid container spacing={1}>
                        {pre_order}
                        {tab}
                        {order_to_table}
                        {order_to_table_nfc}
                        {pay_at_table}
                    </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Grid container spacing={1}>
                        {checkins}
                        {offline_checkin}
                        {book_table}
                        {book_room}
                        {pay_at_table_nfc}
                    </Grid>
                </Grid>
            </React.Fragment>
        )}
    </AutoForm>
);

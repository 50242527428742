import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';

interface SnackbarProps {
    children?: React.ReactElement<any, any> & React.ReactNode;
}

export const SnackbarProviderWrapper: React.FC<SnackbarProps> = ({ children }) => {
    const notistackRef = React.useRef<SnackbarProvider>();

    const onClickDismiss = React.useCallback(
        (key: string) => () => {
            notistackRef.current.closeSnackbar(key);
        },
        [notistackRef]
    );

    const buttonDismissNotification = React.useCallback(
        (key: string) => (
            <Button color="inherit" onClick={onClickDismiss(key)}>
                Dismiss
            </Button>
        ),
        [onClickDismiss]
    );

    return (
        <SnackbarProvider
            ref={notistackRef}
            action={buttonDismissNotification}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

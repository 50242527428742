import React from 'react';
import { Box, Button, Grid, InputLabel, styled, TextField, Typography } from '@mui/material';
import { Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { MuiForm } from 'lib/Form';
import {
    EActionTypeGroup,
    EChannel,
    ETimeOfDay,
    getActionTypeGroup
} from 'components/audience/models/addTriggersModel';
import * as Yup from 'yup';
import { Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getAllLocations } from 'store/locations/locationsActions';
import { Audience } from 'components/audience/models/segmentModel';
import {
    renderAmountField,
    renderChannelSelect,
    renderDaysOfWeekSelect,
    renderLocationsSelect,
    renderMonthOfYearSelect,
    renderPrimaryPlatformSelect,
    renderScenarioSelect,
    renderTimeOfDaySelect,
    renderWeekOfYearInput
} from 'components/audience/utils/addTriggerUtils';
import { OrderScenario } from 'components/order/model/Order';
import { ActionType } from 'components/actions/models/Action';
import { getActionTypeOptions } from 'components/actions/utils';
import { EPrimaryPlatform } from 'components/customers/models/Customer';
import { Option } from 'lib/types';
import { ConditionPredicate } from '@pepperhq/sdk';

const PREFIX = 'AudienceAddTriggersForm';

const classes = {
    disabledInput: `${PREFIX}-disabledInput`
};

const StyledForm = styled(Form)({
    [`& .${classes.disabledInput}.MuiInput-underline.Mui-disabled`]: {
        '&:before': {
            borderBottomStyle: 'solid'
        }
    }
});

export interface IAudienceAddTriggersFormData {
    actionType: ActionType;
    locationId?: string;
    dayPart?: ETimeOfDay;
    dayOfWeek?: string;
    weekOfYear?: string;
    monthOfYear?: string;
    primaryPlatform?: EPrimaryPlatform;
    amountPredicate?: ConditionPredicate;
    amount?: string;
    scenario?: OrderScenario;
    channel?: EChannel;
}

interface IAudienceAddTriggersFormProps {
    onCancel: () => void;
    audienceData: Partial<Audience>;
    onSubmit: (
        id: string,
        data: IAudienceAddTriggersFormData,
        formikHelpers?: FormikHelpers<IAudienceAddTriggersFormData>
    ) => void;
}

const validationSchema = Yup.object().shape({
    actionType: Yup.string().required('This field is required.')
});

export const AudienceAddTriggersForm = ({
    onCancel,
    audienceData: { title, description, _id },
    onSubmit
}: IAudienceAddTriggersFormProps) => {
    const dispatch = useDispatch();
    const { locations } = useSelector((state: ApplicationState) => state.locations);

    React.useEffect(() => {
        if (!locations?.length) {
            dispatch(getAllLocations());
        }
    }, [dispatch, locations]);

    const locationOptions = React.useMemo(
        () =>
            locations.map<Option>(location => ({
                value: location._id,
                label: location.title
            })),
        [locations]
    );

    const handleSubmit = React.useCallback(
        (
            values: IAudienceAddTriggersFormData,
            formikHelpers: FormikHelpers<IAudienceAddTriggersFormData>
        ) => {
            onSubmit(_id, values, formikHelpers);
        },
        [onSubmit, _id]
    );

    return (
        <MuiForm
            validationSchema={validationSchema}
            initialValues={{ actionType: undefined }}
            onSubmit={handleSubmit}
        >
            {({ submitForm, isSubmitting, values: { actionType }, isValid }) => (
                <StyledForm>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" variant="h6">
                                Create Audience
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                InputProps={{ className: classes.disabledInput }}
                                disabled
                                value={title}
                                label={<MuiFormLabel>Title</MuiFormLabel>}
                            />
                        </Grid>
                        {!!description && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    InputProps={{ className: classes.disabledInput }}
                                    disabled
                                    value={description}
                                    label={<MuiFormLabel>Description</MuiFormLabel>}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                Add triggers to audience
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel>Action Type</InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <SelectFormField options={getActionTypeOptions()} name="actionType" />
                                </Grid>
                            </Grid>
                        </Grid>
                        {getActionTypeGroup(actionType) === EActionTypeGroup.FIRST && (
                            <>
                                {renderLocationsSelect(locationOptions)}
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.SECOND && (
                            <>
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.THIRD && (
                            <>
                                {renderAmountField()}
                                {renderLocationsSelect(locationOptions)}
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                                {renderScenarioSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.FOURTH && (
                            <>
                                {renderAmountField()}
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                                {renderChannelSelect()}
                            </>
                        )}
                        {getActionTypeGroup(actionType) === EActionTypeGroup.FIFTH && (
                            <>
                                {renderTimeOfDaySelect()}
                                {renderDaysOfWeekSelect()}
                                {renderWeekOfYearInput()}
                                {renderMonthOfYearSelect()}
                                {renderPrimaryPlatformSelect()}
                            </>
                        )}
                        <Grid item xs={12}>
                            <Row gutter align="flex-end" valign="center">
                                <Box display="flex" marginTop={2}>
                                    <Box marginRight={1}>
                                        <Button onClick={onCancel} variant="outlined" color="primary">
                                            NO THANKS
                                        </Button>
                                    </Box>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        disabled={!isValid || isSubmitting}
                                        loading={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        SAVE TRIGGERS
                                    </LoadingButton>
                                </Box>
                            </Row>
                        </Grid>
                    </Grid>
                </StyledForm>
            )}
        </MuiForm>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const BraintreeSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => {
    if (locationId !== undefined) {
        return null;
    }
    return (
        <>
            <SettingDisplay label="Production Environment">
                <SwitchFormField name="braintreeIsProduction" />
            </SettingDisplay>
            <SettingDisplay label="Merchant ID">
                <TextFormField name="braintreeMerchantId" />
            </SettingDisplay>
            <SettingDisplay label="Public Key">
                <TextFormField name="braintreePublicKey" />
            </SettingDisplay>
            <SettingDisplay label="Private Key">
                <TextFormField name="braintreePrivateKey" />
            </SettingDisplay>
        </>
    );
};

import { hexToRgb, Typography, styled } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import { GridCellValue, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import React from 'react';

const PREFIX = 'MuiGridMultiselectView';

const classes = {
    root: `${PREFIX}-root`,
    error: `${PREFIX}-error`
};

function getLabelOfIdFunction(map: Record<string, string> | Map<string, string>) {
    if (!map) {
        return function (id: string) {
            return id;
        };
    }
    if (map instanceof Map) {
        return function (id: string) {
            return map.get(id);
        };
    }
    return function (id: string) {
        return map[id];
    };
}

interface MuiGridMultiselectViewProps {
    error?: string;
    value?: GridCellValue;
    labels: Record<string, string> | Map<string, string>;
    emptyLabel?: string;
}

export const MuiGridMultiselectView: React.FC<MuiGridMultiselectViewProps> = ({
    value,
    error,
    labels,
    emptyLabel = '-'
}) => {
    const getLabel = React.useMemo(() => getLabelOfIdFunction(labels), [labels]);
    const text = React.useMemo(
        () =>
            Array.isArray(value)
                ? value.map((item: string) => getLabel(item) ?? item).join(', ')
                : emptyLabel,
        [emptyLabel, getLabel, value]
    );
    return (
        <div className={classes.root}>
            {text}
            {!!error && (
                <Typography className={classes.error} variant="caption" color="error">
                    {error}
                </Typography>
            )}
        </div>
    );
};

const StyledMuiGridMultiselectView = styled(MuiGridMultiselectView)(({ theme }) => ({
    [`& .${classes.root}`]: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'relative',
        width: '100%',
        height: '100%'
    },

    [`& .${classes.error}`]: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        lineHeight: 1,
        color: theme.palette.error.main,
        backgroundColor: hexToRgb(lightGreen[100]).replace(')', `, ${0.8})`),
        borderRadius: `${theme.shape.borderRadius} 0`
    }
}));

export const renderMuiGridMultiselectView = (
    { id, field, api, value }: GridRenderEditCellParams,
    labels: Record<string, string> | Map<string, string>,
    emptyLabel?: string
) => {
    const error = !!api.componentsProps && api.componentsProps[id] && api.componentsProps[id][field];
    return (
        <StyledMuiGridMultiselectView error={error} value={value} labels={labels} emptyLabel={emptyLabel} />
    );
};

import { AutoFormFields } from 'lib/LegacyForm';

export const createCustomerContactForm: () => AutoFormFields = () => ({
    message: {
        label: 'Message',
        validation: 'required',
        errors: {
            required: 'A message is required.'
        },
        type: 'multiline'
    }
});

import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

interface ClerksFormErrorProps {
    error?: string;
    onErrorChange: (message?: string) => void;
}

export const ClerksFormError: React.FC<ClerksFormErrorProps> = ({ error, onErrorChange }) => {
    const { values, setSubmitting } = useFormikContext();
    React.useEffect(() => {
        onErrorChange();
    }, [onErrorChange, values]);
    React.useEffect(() => {
        if (error) {
            setSubmitting(false);
        }
    }, [error, setSubmitting]);
    if (!error) {
        return null;
    }
    return <Typography color="error">{error}</Typography>;
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const ToggleGiftCardSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId
}) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplayText title="Username" text={settings?.toggle?.username} />
                <SettingDisplayText title="Password" text={settings?.toggle?.password} />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplayText title="EPOS Reference" text={settings?.toggle?.eposReference} />
        )}
    </>
);

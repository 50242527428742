import React from 'react';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';

export const useExternalPerksUtils = ({ awardEnrichments }: { awardEnrichments: IAwardEnrichment[] }) => {
    const [indexToDelete, setIndexToDelete] = React.useState(-1);
    const [selectedToEdit, setSelectedToEdit] = React.useState<IAwardEnrichment>();

    const [selectedRow, setSelectedRow] = React.useState<GridSelectionModel>([]);
    const selectedAwardId = selectedRow.length === 1 ? selectedRow[0] : null;

    const handleSelectRow = React.useCallback((newSelectedRow: GridSelectionModel) => {
        // Enforces single selection
        if (newSelectedRow.length > 1) {
            setSelectedRow([newSelectedRow[newSelectedRow.length - 1]]);
        } else {
            setSelectedRow(newSelectedRow);
        }
    }, []);

    const handleReviewAndEditClick = React.useCallback(() => {
        const selectedAward = awardEnrichments.find(({ _id }) => _id === selectedAwardId);

        setSelectedToEdit(selectedAward);
    }, [awardEnrichments, selectedAwardId]);

    const handleDeleteClick = React.useCallback(() => {
        const awardIndex = awardEnrichments.findIndex(({ _id }) => _id === selectedAwardId);

        setIndexToDelete(awardIndex);
    }, [awardEnrichments, selectedAwardId]);

    const handleResetEditSelection = React.useCallback(() => setSelectedToEdit(undefined), []);

    return {
        selectedRow,
        selectedAwardId,
        selectedToEdit,
        indexToDelete,
        setIndexToDelete,
        handleSelectRow,
        handleReviewAndEditClick,
        handleDeleteClick,
        handleResetEditSelection
    };
};

import { AddVoucherEffectFormData, EffectType } from 'components/vouchers/forms/VoucherAddEffectsForm';

interface Parameters {
    perkId?: string;
    segmentId?: string;
    currencyEarned?: number;
}

interface Impact {
    evaluatorType: string;
    evaluatorName: string;
    parameters: Parameters;
}

interface Condition {
    subject: string;
    object: string;
    predicate: string;
}

export interface BodyEffectPerk {
    matchType: string;
    conditions: Condition[];
    effect: {
        controller: string;
        method: string;
        impact: Impact;
    };
}

export function createBodyEffect(values: AddVoucherEffectFormData, voucherId: string): BodyEffectPerk {
    const { selectedEffect, perkId, audienceId, amount } = values;
    const parameters: Parameters = {};

    if (selectedEffect === EffectType.PERK) {
        parameters.perkId = perkId;
    }

    if (selectedEffect === EffectType.AUDIENCE) {
        parameters.segmentId = audienceId;
    }

    if (selectedEffect === EffectType.PERK && amount) {
        parameters.currencyEarned = +amount;
    }

    return {
        matchType: 'TYPE',
        conditions: [
            {
                subject: 'type',
                object: 'VOUCHER_REDEEMED',
                predicate: 'EQUAL'
            },
            {
                subject: 'metadata.voucherId',
                object: voucherId,
                predicate: 'EQUAL'
            }
        ],
        effect: {
            controller: selectedEffect === EffectType.AUDIENCE ? 'segment' : 'pointPerk',
            method: selectedEffect === EffectType.AUDIENCE ? 'addMemberToSegment' : 'grantPerkFromImpact',
            impact: {
                evaluatorType: 'BUILTIN_FUNCTION',
                evaluatorName: 'passThru',
                parameters
            }
        }
    };
}

/* eslint-disable @typescript-eslint/await-thenable */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Head } from 'layouts/Head';
import { auth } from 'lib/firebase/firebase';
import { httpClient } from 'lib/HttpClient';
import { authorize, unauthorize } from 'store/auth/authActions';
import { AppWrapper } from './app/AppWrapper';
import { initStore } from './store/store';
import { Root } from './pages';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(process.env.MATERIAL_UI_KEY);

const store = initStore();

export class App extends React.Component {
    state = {
        isFirebaseLoaded: false
    };
    authUnsubscribe: (() => void) | undefined;
    componentDidMount() {
        this.authUnsubscribe = auth().onAuthStateChanged(async user => {
            if (user) {
                if (window.dataLayer) {
                    window.dataLayer.push({ firebaseUserId: user.uid });
                }
                // To refresh it when expired
                httpClient.refreshToken = () => {
                    authorize()(store.dispatch);
                };
                // Get auth.currentUser for more user data (specifically token)
                await authorize()(store.dispatch);
            } else {
                httpClient.updateToken('');
                await unauthorize()(store.dispatch);
            }
            this.setState({ isFirebaseLoaded: true });
        });
    }
    componentWillUnmount() {
        if (this.authUnsubscribe) {
            this.authUnsubscribe();
        }
    }
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppWrapper>
                        <Head />
                        <Root isFirebaseLoaded={this.state.isFirebaseLoaded} />
                    </AppWrapper>
                </BrowserRouter>
            </Provider>
        );
    }
}

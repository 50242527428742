import React from 'react';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import getValue from 'get-value';
import { segmentsApi } from 'components/audience/segmentsApi';
import { CustomerSegment } from 'components/customers/models/Customer';
import { locationApi } from 'components/location/LocationApi';
import { Location } from 'components/location/models/LocationModel';
import { Column } from 'ui/Flex';
import { PRESET_ACTION_ITEM } from '../ContentModuleActions';
import {
    IDisplay,
    IItemContext,
    IMarketingAction,
    MarketingMessageItem,
    marketingTypeguards,
    MessageItem
} from '../models/ContentModule';
import BrowserMessageProperties from './marketing/BrowserMessagePropertiesForm';
import NativeMessageProperties from './marketing/NativeMessagePropertiesForm';
import { TableOrderProperties } from './marketing/TableOrderMessageProperties';
import { DefaultMarketingMessageProperties } from './marketing/DefaultMarketingMessageForm';
import { StartOrderMessageProperties } from './marketing/StartOrderMessagePropertiesForm';
import { LocationMessageProperties } from './marketing/LocationMessagePropertiesForm';
import logger from 'lib/logger';

interface MarketingModuleFormProps {
    display?: IDisplay;
    value?: MarketingMessageItem;
    onSubmit: (value: MarketingMessageItem) => void;
    close: () => void;
    formTitle: string;
}

interface PropertiesFormProps {
    action: IMarketingAction;
    message: MarketingMessageItem;
    handleSubmit: (properties: any) => void;
    contextForm: () => React.ReactNode;
    display: IDisplay;
    locations?: Location[];
}

const PropertiesForm = function PropertiesFormMemo({
    action,
    message,
    handleSubmit,
    contextForm,
    display,
    locations
}: PropertiesFormProps) {
    const newMessage = { ...message, action };

    if (marketingTypeguards.isBrowserMessage(newMessage)) {
        return (
            <BrowserMessageProperties
                name={newMessage.name}
                properties={newMessage.properties}
                onSubmit={handleSubmit}
                contextForm={contextForm}
                display={display}
            />
        );
    }
    if (marketingTypeguards.isNativeMessage(newMessage)) {
        return (
            <NativeMessageProperties
                name={newMessage.name}
                properties={newMessage.properties}
                onSubmit={handleSubmit}
                contextForm={contextForm}
                display={display}
            />
        );
    }
    if (marketingTypeguards.isLocationMessage(newMessage)) {
        return (
            <LocationMessageProperties
                name={newMessage.name}
                properties={newMessage.properties}
                onSubmit={handleSubmit}
                contextForm={contextForm}
                display={display}
                locations={locations}
            />
        );
    }

    if (marketingTypeguards.isStartOrderMessage(newMessage)) {
        return (
            <StartOrderMessageProperties
                name={newMessage.name}
                properties={newMessage.properties}
                onSubmit={handleSubmit}
                contextForm={contextForm}
                display={display}
            />
        );
    }

    if (marketingTypeguards.isTableOrderMessage(newMessage)) {
        return (
            <TableOrderProperties
                name={newMessage.name}
                properties={newMessage.properties}
                onSubmit={handleSubmit}
                display={display}
                contextForm={contextForm}
            />
        );
    }

    if (marketingTypeguards.isDefaultMarketingMessage(newMessage)) {
        return (
            <DefaultMarketingMessageProperties
                name={newMessage.name}
                properties={newMessage.properties}
                onSubmit={handleSubmit}
                display={display}
                contextForm={contextForm}
            />
        );
    }

    if (!action) {
        return (
            <Grid item xs={12}>
                <Typography variant="caption">Select your marketing message type to start editing</Typography>
            </Grid>
        );
    }
    return (
        <Grid item xs={12}>
            <Typography>Sorry, this functionality isn&apos;t implemented yet</Typography>
        </Grid>
    );
};

const MarketingModuleForm = React.memo((props: MarketingModuleFormProps) => {
    const { close, onSubmit } = props;
    const [moduleType, setModuleType] = React.useState(props.value ? props.value.action : null);
    const [locationIds, setLocationIds] = React.useState(getValue(props, 'value.context.locationIds') || []);
    const [segmentIds, setSegmentIds] = React.useState(getValue(props, 'value.context.segmentIds') || []);
    const [authenticated, setAuthenticated] = React.useState(
        getValue(props, 'value.context.authenticated') ?? true
    );
    const [unauthenticated, setUnauthenticated] = React.useState(
        getValue(props, 'value.context.unauthenticated') ?? true
    );
    const [excludingSegmentIds, setExcludingSegmentIds] = React.useState(
        getValue(props, 'value.context.excludingSegmentIds') || []
    );
    const [locations, setLocations] = React.useState([] as Location[]);
    const [segments, setSegments] = React.useState<CustomerSegment[]>([]);
    React.useEffect(() => {
        async function getLocations() {
            const result = await locationApi.getList();
            if (!result.ok) {
                return setLocations([]);
            }
            setLocations(result.body.locations);
        }
        async function getSegments() {
            const result = await segmentsApi.getAll();
            if (!result) {
                return setSegments([]);
            }
            setSegments(result);
        }
        getLocations();
        getSegments();
    }, []);
    const handleModuleChange = React.useCallback(
        (
            e: Event & {
                target: {
                    value: IMarketingAction;
                    name: string;
                };
            }
        ) => {
            setModuleType(e.target.value);
        },
        []
    );
    const handleSubmit = React.useCallback(
        ({ name, ...properties }: any) => {
            const context: IItemContext = {
                authenticated,
                unauthenticated
            };
            if (locationIds.length) {
                context.locationIds = locationIds;
            }
            if (segmentIds.length) {
                context.segmentIds = segmentIds;
            }
            if (excludingSegmentIds.length) {
                context.excludingSegmentIds = excludingSegmentIds;
            }
            onSubmit({
                properties,
                context,
                action: moduleType,
                type: MessageItem.MESSAGE,
                index: 0,
                name: name?.length ? name : undefined
            });
            close();
        },
        [
            authenticated,
            unauthenticated,
            locationIds,
            segmentIds,
            excludingSegmentIds,
            onSubmit,
            moduleType,
            close
        ]
    );
    const handleSelectChange = React.useCallback((e: SelectChangeEvent) => {
        switch (e.target.name) {
            case 'segmentIds':
                setSegmentIds(e.target.value);
                return;
            case 'excludingSegmentIds':
                setExcludingSegmentIds(e.target.value);
                return;
            default:
                logger.warn('Unexpected select change event');
        }
    }, []);
    const handleCheckboxChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            switch (e.target.name) {
                case 'authenticated':
                    setAuthenticated(checked);
                    return;
                case 'unauthenticated':
                    setUnauthenticated(checked);
                    return;
                default:
                    logger.warn('Unexpected checkbox change event');
            }
        },
        []
    );
    const handleLocationIdChange = React.useCallback(e => setLocationIds(e.target.value), []);
    const contextForm = React.useCallback(
        () => (
            <React.Fragment>
                <Grid item xs={12}>
                    <Grid container direction="column">
                        <Typography color="primary">Context</Typography>
                        <Typography variant="caption">
                            Allow only certain groups to see this marketing item. All of these fields are
                            optional.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink>Locations</InputLabel>
                    <Select
                        multiple
                        value={locationIds}
                        fullWidth
                        onChange={handleLocationIdChange}
                        disabled={!locations || !locations.length}
                    >
                        {locations &&
                            locations.length &&
                            locations.map(location => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.title}
                                </MenuItem>
                            ))}
                    </Select>
                    <Typography variant="caption" color="textSecondary" sx={{ lineHeight: 1.4 }}>
                        {locations && locations.length
                            ? // eslint-disable-next-line max-len
                              'Choose which locations you would like to display this content to when a user is checked in, or is set as the favourite location. Leaving this empty will show it to all locations.'
                            : 'No locations available.'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink>Show if user is in Audience</InputLabel>
                    <Select
                        multiple
                        value={segmentIds}
                        name="segmentIds"
                        fullWidth
                        onChange={handleSelectChange}
                        disabled={!segments || !segments.length}
                    >
                        {segments &&
                            segments.length &&
                            segments.map(segment => (
                                <MenuItem key={segment._id} value={segment._id}>
                                    {segment.title}
                                </MenuItem>
                            ))}
                    </Select>
                    <Typography variant="caption" color="textSecondary" sx={{ lineHeight: 1.4 }}>
                        {segments && segments.length
                            ? 'Choose which audiences you would like to display this content to. Leaving this empty will show it to all audiences.'
                            : 'No audiences available.'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink>Hide if user is in Audience</InputLabel>
                    <Select
                        multiple
                        value={excludingSegmentIds}
                        name="excludingSegmentIds"
                        fullWidth
                        onChange={handleSelectChange}
                        disabled={!segments || !segments.length}
                    >
                        {segments &&
                            segments.length &&
                            segments.map(segment => (
                                <MenuItem key={segment._id} value={segment._id}>
                                    {segment.title}
                                </MenuItem>
                            ))}
                    </Select>
                    <Typography variant="caption" color="textSecondary" sx={{ lineHeight: 1.4 }}>
                        {segments && segments.length
                            ? // eslint-disable-next-line max-len
                              'Choose which audiences you would not like to display this content to. Leaving this empty will show it to all audiences.'
                            : 'No audiences available.'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Show content when user is:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                name="authenticated"
                                checked={authenticated}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label="Signed In"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                name="unauthenticated"
                                checked={unauthenticated}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label="Signed Out"
                    />
                </Grid>
            </React.Fragment>
        ),
        [
            authenticated,
            excludingSegmentIds,
            handleCheckboxChange,
            handleLocationIdChange,
            handleSelectChange,
            locationIds,
            locations,
            segmentIds,
            segments,
            unauthenticated
        ]
    );

    return (
        <Grid container spacing={3} sx={{ maxWidth: 512, p: 0 }}>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                    {props.formTitle}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Select value={moduleType || ''} onChange={handleModuleChange} sx={{ width: '100%' }}>
                    {PRESET_ACTION_ITEM.map(action => (
                        <MenuItem key={`select-${action.value}`} value={action.value}>
                            <Column>
                                {action.label}
                                <Typography variant="caption">{action.description}</Typography>
                            </Column>
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <PropertiesForm
                    action={moduleType}
                    message={props.value}
                    handleSubmit={handleSubmit}
                    contextForm={contextForm}
                    display={props.display}
                    locations={locations}
                />
            </Grid>
        </Grid>
    );
});

export const getMarketingModuleForm = (display?: IDisplay) => (props: MarketingModuleFormProps) =>
    <MarketingModuleForm display={display} {...props} />;

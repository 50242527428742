import React from 'react';
import { Chip, Paper, TableCell } from '@mui/material';
import { getDistanceBettwenTodayAndMillisecondsInWords } from 'components/actions/utils/formatDate';
import { Audience } from 'components/audience/models/segmentModel';
import { titleize } from 'lib/helpers';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';

const columns: MuiTableColumnItem[] = [
    {
        key: 'title',
        label: 'Title',
        render: (item: Audience, key: string) => (
            <TableCell style={{ width: '40%' }} key={key}>
                {item.title}
            </TableCell>
        )
    },
    {
        key: 'type',
        label: 'Type',
        render: (item: Audience, key: string) => (
            <TableCell style={{ width: '20%' }} key={key}>
                <Chip label={titleize(item.type.toLocaleLowerCase())} />
            </TableCell>
        )
    },
    {
        key: 'count',
        label: 'Customers in this Audience',
        render: (item: Audience, key: string) => (
            <TableCell style={{ width: '20%' }} key={key}>
                {item.count}
            </TableCell>
        )
    },
    {
        key: 'expiryHorizon',
        label: 'Expiry Time',
        render: (item: Audience, key: string) => (
            <TableCell style={{ width: '20%' }} key={key}>
                {getDistanceBettwenTodayAndMillisecondsInWords(item.expiryHorizon)}
            </TableCell>
        )
    }
];

const additionalColumns: Record<string, MuiTableColumnItem> = {
    Identifier: {
        key: '_id',
        label: 'Identifier'
    }
};

interface AudienceTableProps {
    toolbarItems?: MuiTableToolbarItems;
    itemActions: (item: Audience, index: number) => MuiMenuItem[];
    data: Audience[];
    selectedColumns: string[];
    isLoading: boolean;
    scroll: MuiTableScrollItems;
}

export const AudienceTable: React.FC<AudienceTableProps> = ({ selectedColumns, ...props }) => {
    const getColumns = React.useCallback(() => {
        let resultColumns: MuiTableColumnItem[] = [];
        selectedColumns.forEach(item => {
            resultColumns.push(additionalColumns[item]);
        });
        resultColumns = [...resultColumns, ...columns];
        return resultColumns;
    }, [selectedColumns]);
    return (
        <Paper>
            <MuiTable columns={getColumns()} {...props} />
        </Paper>
    );
};

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Button, TableCell } from '@mui/material';
import { pepperPayApi } from '../pepperPayApi';
import { ConfirmDialog } from 'ui/dialogs/ConfirmDialog';
import { PepperPayScheduleOperation } from '../model/PepperPay';
import { MESSAGE_SCHEDULE_DELETED_ERROR, MESSAGE_SCHEDULE_DELETED_SUCCESS } from 'config/messages';
import logger from 'lib/logger';

interface PepperPaySchedulesCellAction {
    operation: PepperPayScheduleOperation;
    onDelete: (id: string) => void;
}

export const SchedulesDeleteActionCell: React.FC<PepperPaySchedulesCellAction> = ({
    operation,
    onDelete
}) => {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = React.useState<PepperPayScheduleOperation>(null);
    const [loading, setLoading] = React.useState(false);

    const handleButtonClick = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();

            setSelectedItem(operation);
        },
        [operation]
    );
    const handleConfirmDelete = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await pepperPayApi.schedules.deleteSchedule(operation._id, operation.locationId);
            if (response.ok) {
                dispatch(enqueueSnackbar(MESSAGE_SCHEDULE_DELETED_SUCCESS, { variant: 'success' }));
                onDelete(operation._id);
            }
        } catch (e) {
            dispatch(enqueueSnackbar(MESSAGE_SCHEDULE_DELETED_ERROR, { variant: 'error' }));
            logger.error(e);
        } finally {
            setLoading(false);
            setSelectedItem(null);
        }
    }, [dispatch, onDelete, operation._id, operation.locationId]);

    const handleConfirmCancel = React.useCallback(() => {
        setSelectedItem(null);
    }, []);

    return (
        <>
            <TableCell align="center" size="small">
                <Button color="primary" onClick={handleButtonClick}>
                    Delete
                </Button>
            </TableCell>
            <ConfirmDialog
                loading={loading}
                confirmLabel="Remove"
                cancelLabel="Cancel"
                title="Remove item?"
                content={`Are you sure you want to remove item "${selectedItem?.name}"?`}
                open={!!selectedItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </>
    );
};

import { Add } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import { PepperPayScheduleOperation } from 'components/pepper-pay/model/PepperPay';
import { pepperPayApi } from 'components/pepper-pay/pepperPayApi';
import { NewScheduleModal } from 'components/pepper-pay/reporting/NewSchedulesModal';
import { SchedulesTable } from 'components/pepper-pay/reporting/SchedulesTable';
import React from 'react';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import { MuiTableToolbarItems } from 'ui/table/MuiTable';

export const SCHEDULE_PAGE_LIMIT = 100;

export const Schedules: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
    const [operations, setOperations] = React.useState<PepperPayScheduleOperation[]>([]);
    const lastOperation = React.useMemo(() => (operations ? operations[0] : null), [operations]);
    const [nextKey, setNextKey] = React.useState();

    React.useEffect(() => {
        const pageBody: Record<string, string | number> = { limit: SCHEDULE_PAGE_LIMIT };
        pepperPayApi.schedules
            .getSchedulesListItems({
                page: pageBody
            })
            .then(res => {
                setOperations(res.body.items);
                setNextKey(res.body.page?.nextKey);
                return res;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const toggleCreateModal = React.useCallback(() => setIsCreateModalOpen(state => !state), []);

    const toolbarItems: MuiTableToolbarItems = {
        renderCustomElement: () => (
            <TableActionButton onClick={toggleCreateModal} Icon={Add}>
                SCHEDULE REPORT
            </TableActionButton>
        )
    };

    const handleCreateSchedule = React.useCallback((operation: PepperPayScheduleOperation) => {
        setOperations(previous => [operation, ...previous]);
    }, []);

    const handleDeleteSchedule = React.useCallback((id: string) => {
        setOperations(previous => [...previous.filter(item => item._id !== id)]);
    }, []);

    const handleLoadMore = React.useCallback(() => {
        if (nextKey) {
            const pageBody: Record<string, string | number> = { limit: SCHEDULE_PAGE_LIMIT };
            pageBody.startKey = nextKey;

            pepperPayApi.schedules
                .getSchedulesListItems({
                    page: pageBody
                })
                .then(res => {
                    setOperations(prev => [...prev, ...res.body.items]);
                    setNextKey(res.body.page?.nextKey);
                });
        }
    }, [nextKey]);

    return (
        <Box>
            <Paper>
                <SchedulesTable
                    isLoading={isLoading}
                    toolbarItems={toolbarItems}
                    operations={operations}
                    nextKey={nextKey}
                    onScroll={handleLoadMore}
                    onDelete={handleDeleteSchedule}
                />
            </Paper>
            {!isLoading && (
                <NewScheduleModal
                    open={isCreateModalOpen}
                    onClose={toggleCreateModal}
                    onCreate={handleCreateSchedule}
                    lastOperation={lastOperation}
                />
            )}
        </Box>
    );
};

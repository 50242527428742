import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import {
    PasswordResetConfirmForm,
    PasswordResetConfirmFormData
} from 'components/auth/forms/PasswordResetConfirmForm';
import { MarketingLayout } from 'components/auth/MarketingLayout';
import { FORGOT_YOUR_PASSWORD_CONFIRMED } from 'config/messages';
import { SIGN_IN } from 'config/routes';
import { doConfirmPasswordReset } from 'lib/firebase/auth';
import { isString } from 'lib/typeguards';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Link } from 'ui/Link';

const ConfirmPasswordReset = () => {
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const oobCode = params.get('oobCode');
    React.useEffect(() => {
        function getParams() {
            if (!isString(oobCode)) {
                history.replace(SIGN_IN);
            }
        }
        getParams();
    }, [oobCode, history]);
    const handleSubmit = React.useCallback(
        async ({ password }: PasswordResetConfirmFormData) => {
            setError(null);
            try {
                await doConfirmPasswordReset(oobCode, password);
                dispatch(enqueueSnackbar(FORGOT_YOUR_PASSWORD_CONFIRMED, { variant: 'success' }));
                return history.push(SIGN_IN);
            } catch (e) {
                setError(e.message);
            }
        },
        [dispatch, oobCode, history]
    );
    return (
        <MarketingLayout
            marketingId="optimize-forgot-your-password-confirmation"
            after={
                <Typography align="center" variant="caption" color="textSecondary">
                    Remembered your password? <Link href={SIGN_IN}>Sign In</Link>
                </Typography>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                        Reset my password
                    </Typography>
                    <Typography variant="caption">
                        Enter the new password for your account in the fields below.
                    </Typography>
                </Grid>
                {error && (
                    <Grid item xs={12}>
                        <Typography color="error">{error}</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <PasswordResetConfirmForm onSubmit={handleSubmit} />
                </Grid>
            </Grid>
        </MarketingLayout>
    );
};

export default ConfirmPasswordReset;

import { MenuItem, Select } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';
import { OrderScenario } from '../model/Order';

const ANY_SCENARIO = 'ANY';

interface OrderScenarioFilterProps {
    onChange: (value?: string) => void;
    value?: string;
}

export const OrderScenarioLabels: Record<OrderScenario, string> = {
    [OrderScenario.PREORDER]: 'Preorder',
    [OrderScenario.ORDER_TO_TABLE]: 'Order to Table',
    [OrderScenario.TABLE]: 'Pay at Table',
    [OrderScenario.PAYATPOS]: 'Pay at POS',
    [OrderScenario.TAB]: 'Tabs'
};

const options = Object.entries(OrderScenarioLabels).map(([key, value]) => ({ label: value, value: key }));

export const OrderScenarioFilter: React.FC<OrderScenarioFilterProps> = ({ value, onChange }) => {
    const handleStateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === ANY_SCENARIO) {
                onChange(undefined);
            } else {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const renderOption = React.useCallback(
        (option: Option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select value={value ?? ANY_SCENARIO} onChange={handleStateChange} variant="outlined">
            <MenuItem value={ANY_SCENARIO}>All Scenarios</MenuItem>
            {options.map(renderOption)}
        </Select>
    );
};

import { GridRowData, GridState } from '@mui/x-data-grid-pro';
import { isArrayOf, isBoolean, isDefined, isNumber, isOptional, isString } from 'lib/typeguards';
import React from 'react';
import merge from 'deepmerge';
const overwriteMerge = (_destinationArray: never, sourceArray: Array<any>, _: never) => sourceArray;
import { debounce } from 'debounce';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export enum GridStorageName {
    Categories = 'grid-state-categories',
    CategoryGroups = 'grid-state-category-group',
    Products = 'grid-state-products',
    CategoryProducts = 'grid-state-category-products',
    Modifiers = 'grid-state-modifiers',
    ModifierOptions = 'grid-state-modifier-options',
    ModifiersProducts = 'grid-state-modifier-products',
    Taxes = 'grid-state-taxes',
    Tags = 'grid-state-tags',
    DynamicImages = 'grid-state-dynamic-images',
    WeeklyAvailability = 'grid-state-weekly',
    ProductAvailability = 'grid-state-product-availability',
    Orders = 'grid-state-orders',
    Vouchers = 'grid-state-vouchers',
    ExternalPerks = 'grid-state-external-perks',
    Perks = 'grid-state-perks'
}

interface LookupColumnData {
    width?: number;
    computedWidth?: number;
}

interface LocalStoredData {
    all: string[];
    lookup: Record<string, LookupColumnData>;
    pinnedColumns?: {
        left?: string[];
        right?: string[];
    };
}

function isLookupColumnData(data: any): data is LookupColumnData {
    return isOptional(isNumber, data.width) && isOptional(isBoolean, data.hide);
}

function isLocalStoredData(data: any): data is LocalStoredData {
    return (
        !!data &&
        isDefined(data.all) &&
        isArrayOf(isString, data.all) &&
        isDefined(data.lookup) &&
        Object.values(data.lookup).every(isLookupColumnData)
    );
}

export function useGridStateStorage(
    apiRef: React.MutableRefObject<GridApiPro>,
    storageName: string,
    gridRows?: GridRowData
) {
    React.useEffect(() => {
        if (!isDefined(storageName)) {
            return;
        }
        const storedItems = JSON.parse(localStorage.getItem(storageName));
        if (isLocalStoredData(storedItems)) {
            const { pinnedColumns: _, ...columns } = storedItems;
            apiRef.current?.setState(prevState => ({
                ...prevState,
                columns: merge<any>(prevState.columns, columns, {
                    arrayMerge: overwriteMerge
                })
            }));
        }
        // We need to track row changes to update the state with row changes
    }, [apiRef, storageName, gridRows]);
    React.useEffect(() => {
        if (!isDefined(storageName)) {
            return;
        }
        const storedItems = JSON.parse(localStorage.getItem(storageName));
        if (isLocalStoredData(storedItems) && storedItems.pinnedColumns) {
            const { pinnedColumns } = storedItems;
            apiRef.current?.setState(prevState => ({
                ...prevState,
                pinnedColumns
            }));
        }
    }, [apiRef, storageName]);
    const handleStateChange = React.useCallback(
        ({ columns, pinnedColumns }: GridState) => {
            const { all, lookup, columnVisibilityModel } = columns;
            const toPersist = {
                all,
                columnVisibilityModel,
                lookup: Object.entries(lookup).reduce<Record<string, LookupColumnData>>(
                    (acc, [key, column]) => {
                        acc[key] = { width: column.width, computedWidth: column.computedWidth };
                        return acc;
                    },
                    {}
                ),
                pinnedColumns
            };
            localStorage.setItem(storageName, JSON.stringify(toPersist));
        },
        [storageName]
    );
    const onStateChange = React.useMemo(
        () => (isDefined(storageName) ? debounce(handleStateChange, 1000) : undefined),
        [handleStateChange, storageName]
    );
    return onStateChange;
}

import { ActionType } from 'components/actions/models/Action';
import { IAudienceAddTriggersFormData } from 'components/audience/forms/AudienceAddTriggersForm';
import { makeAudienceConditions } from 'components/audience/utils/addTriggerUtils';
import { Option } from 'lib/types';
import { OrderScenario } from 'components/order/model/Order';
import { EPrimaryPlatform } from 'components/customers/models/Customer';
import { ConditionPredicate, MatchType } from '@pepperhq/sdk';

export enum ETimeOfDay {
    EARLY_MORNING = 'EARLY_MORNING',
    MORNING = 'MORNING',
    PRE_LUNCH = 'PRE_LUNCH',
    LUNCH = 'LUNCH',
    AFTERNOON = 'AFTERNOON',
    EVENING = 'EVENING',
    LATE_NIGHT = 'LATE_NIGHT'
}

export enum EChannel {
    CARD = 'CARD',
    BALANCE = 'BALANCE',
    PAY_PAL = 'PAY_PAL',
    GOOGLE_PAY = 'GOOGLE_PAY',
    APPLE_PAY = 'APPLE_PAY'
}

export enum EActionTypeGroup {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    FOURTH = 'FOURTH',
    FIFTH = 'FIFTH'
}

const NONE_OPTION: Option = {
    value: '',
    label: 'None'
};

export const getActionTypeGroup = (actionType: ActionType): EActionTypeGroup | undefined => {
    if ([ActionType.CHECKIN, ActionType.REWARD_BURNED, ActionType.REWARD_EARNED].includes(actionType)) {
        return EActionTypeGroup.FIRST;
    }
    if (
        [
            ActionType.CARD_CREATED,
            ActionType.CARD_DELETED,
            ActionType.ORDER_CREATED,
            ActionType.ORDER_USER_JOINED,
            ActionType.ORDER_PAYMENT_REFUNDED,
            ActionType.REDEEM_PERK,
            ActionType.REFERAL_CLAIMED_REFEREE,
            ActionType.REFERAL_CLAIMED_REFERER,
            ActionType.REFUND,
            ActionType.TOPUP_REFUNDED,
            ActionType.USER_ACTIVATION_RESENT,
            ActionType.USER_CREDITED,
            ActionType.USER_DELETED,
            ActionType.VOUCHER_REDEEMED
        ].includes(actionType)
    ) {
        return EActionTypeGroup.SECOND;
    }
    if (
        [
            ActionType.ORDER_COMPLETED,
            ActionType.ORDER_USER_BILLED,
            ActionType.ORDER_PAYMENT_RECEIVED,
            ActionType.PURCHASE
        ].includes(actionType)
    ) {
        return EActionTypeGroup.THIRD;
    }
    if (actionType === ActionType.TOPUP) {
        return EActionTypeGroup.FOURTH;
    }
    if ([ActionType.USER_ACTIVATED, ActionType.USER_CREATED].includes(actionType)) {
        return EActionTypeGroup.FIFTH;
    }
};

export const makeCreateRuleBody = (values: IAudienceAddTriggersFormData, audienceId: string) => {
    const conditions = makeAudienceConditions(values);

    return {
        conditions,
        matchType: MatchType.TYPE,
        effect: {
            controller: 'segment',
            method: 'addMemberToSegment',
            impact: {
                evaluatorType: 'BUILTIN_FUNCTION',
                evaluatorName: 'passThru',
                parameters: {
                    segmentId: audienceId
                }
            }
        }
    };
};

export const TIME_OF_DAY_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: ETimeOfDay.EARLY_MORNING,
        label: 'Early Morning (Midnight-6am)'
    },
    {
        value: ETimeOfDay.MORNING,
        label: 'Morning (6am-10am)'
    },
    {
        value: ETimeOfDay.PRE_LUNCH,
        label: 'Before Lunch (10am-Midday)'
    },
    {
        value: ETimeOfDay.LUNCH,
        label: 'Lunch (Midday-3pm)'
    },
    {
        value: ETimeOfDay.AFTERNOON,
        label: 'Afternoon (3pm-6pm)'
    },
    {
        value: ETimeOfDay.EVENING,
        label: 'Evening (6pm-10pm)'
    },
    {
        value: ETimeOfDay.LATE_NIGHT,
        label: 'Late Night (10pm- Midnight)'
    }
];

export const DAYS_OF_WEEK_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: '1',
        label: 'Monday'
    },
    {
        value: '2',
        label: 'Tuesday'
    },
    {
        value: '3',
        label: 'Wednesday'
    },
    {
        value: '4',
        label: 'Thursday'
    },
    {
        value: '5',
        label: 'Friday'
    },
    {
        value: '6',
        label: 'Saturday'
    },
    {
        value: '0',
        label: 'Sunday'
    }
];

export const MONTH_OF_YEAR_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: '0',
        label: 'January'
    },
    {
        value: '1',
        label: 'February'
    },
    {
        value: '2',
        label: 'March'
    },
    {
        value: '3',
        label: 'April'
    },
    {
        value: '4',
        label: 'May'
    },
    {
        value: '5',
        label: 'June'
    },
    {
        value: '6',
        label: 'July'
    },
    {
        value: '7',
        label: 'August'
    },
    {
        value: '8',
        label: 'September'
    },
    {
        value: '9',
        label: 'October'
    },
    {
        value: '10',
        label: 'November'
    },
    {
        value: '11',
        label: 'December'
    }
];

export const PRIMARY_PLATFORM_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: EPrimaryPlatform.ANDROID,
        label: 'Android'
    },
    {
        value: EPrimaryPlatform.IOS,
        label: 'iOS'
    },
    {
        value: EPrimaryPlatform.WEB,
        label: 'Web'
    },
    {
        value: EPrimaryPlatform.UNKNOWN,
        label: 'Unknown'
    }
];

export const NUMBER_PERIOD_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: ConditionPredicate.GREATER,
        label: 'is more than'
    },
    {
        value: ConditionPredicate.LESSER,
        label: 'is less than'
    },
    {
        value: ConditionPredicate.EQUAL,
        label: 'is equal to'
    }
];

export const SCENARIO_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: OrderScenario.TAB,
        label: 'Tab'
    },
    {
        value: OrderScenario.ORDER_TO_TABLE,
        label: 'Order to table'
    },
    {
        value: OrderScenario.PREORDER,
        label: 'Preorder'
    },
    {
        value: OrderScenario.PAYATPOS,
        label: 'Pay at POS'
    },
    {
        value: OrderScenario.TABLE,
        label: 'Pay at Table'
    }
];

export const CHANNEL_OPTIONS: Option[] = [
    { ...NONE_OPTION },
    {
        value: EChannel.CARD,
        label: 'Card'
    },
    {
        value: EChannel.BALANCE,
        label: 'Balance'
    },
    {
        value: EChannel.PAY_PAL,
        label: 'PayPal'
    },
    {
        value: EChannel.GOOGLE_PAY,
        label: 'Google Pay'
    },
    {
        value: EChannel.APPLE_PAY,
        label: 'Apple Pay'
    }
];

/* eslint-disable */
import React, { CSSProperties } from 'react';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRight from '@mui/icons-material/FormatAlignRight';
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenter from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignTop from '@mui/icons-material/VerticalAlignTop';
import clsx from 'clsx';
import { Form } from 'formik';
import * as Yup from 'yup';
import { AwardTemplate } from 'components/customers/models/Award';
import { TextAlignment, VerticalAlignment } from 'components/loyalty/models/PerkModel';
import { MuiTheme } from 'config/theme';
import { MuiForm } from 'lib/Form';
import { ButtonGroupInputFormField } from 'lib/form/fields/ButtonGroupInputFormField';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import { ColorFormField, RGBAValue } from 'lib/form/fields/ColorFormField';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { PrefixInputFormField } from 'lib/form/fields/PrefixInputFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Column, Row } from 'ui/Flex';
import { PerkPreviewEditable, perkPreviewWidthByType } from './visualisation/PerkPreviewEditable';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { PerkFormStep } from '../PerksEditStepper';
import { PerkSettingsTypeHandler } from './PerkSettingsTypeHandler';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export const alignOptions: Option[] = [
    { value: TextAlignment.LEFT, label: <FormatAlignLeft /> },
    { value: TextAlignment.CENTER, label: <FormatAlignCenter /> },
    { value: TextAlignment.RIGHT, label: <FormatAlignRight /> }
];

export const valignOptions: Option[] = [
    { value: VerticalAlignment.TOP, label: <VerticalAlignTop /> },
    { value: VerticalAlignment.CENTER, label: <VerticalAlignCenter /> },
    { value: VerticalAlignment.BOTTOM, label: <VerticalAlignBottom /> }
];

export const perkTypeToLabelOptions: Record<AwardTemplate | string, string> = {
    [AwardTemplate.AD_HOC]: 'Adhoc',
    [AwardTemplate.PSEUDO_CURRENCY]: 'Pseudo Currency',
    [AwardTemplate.STAMP_CARD]: 'Stamp Card',
    [AwardTemplate.CLUB]: 'Club',
    [AwardTemplate.LEVEL]: 'Level'
};
const typeOptions: Option[] = [
    { value: AwardTemplate.AD_HOC, label: 'Adhoc' },
    { value: AwardTemplate.PSEUDO_CURRENCY, label: 'Pseudo Currency' },
    { value: AwardTemplate.STAMP_CARD, label: 'Stamp Card' },
    { value: AwardTemplate.CLUB, label: 'Club' }
];

export interface PerkSettingsFormData {
    auto?: boolean;
    terms: string;
    perkType: AwardTemplate;
    redemption: string;
    subtitle?: string;
    subtitle2?: string;
    image?: string;
    enabled?: boolean;
    global: boolean;
    textColor: RGBAValue;
    randomizeStampCard?: boolean;
    backgroundColor: RGBAValue;
    impact?: string;
    nextCardProgressCopy?: string;
    align: TextAlignment;
    stampsPerCard?: string;
    stampImageUrl?: string;
    stampColor: RGBAValue;
    valign: VerticalAlignment;
    initial?: string;
    step?: string;
    availableCardCopy?: string;
    multi?: boolean;
    locationIds?: string[];
    titleText?: string;
}

interface PerkSettingsFormProps {
    onSubmit: (data: PerkSettingsFormData) => void;
    clickPrevious: () => void;
    isEdit?: boolean;
    open: boolean;
    title?: string;
    initialValues?: PerkSettingsFormData;
    isLoading?: boolean;
    onPrevious?: (isValid?: boolean, values?: PerkSettingsFormData) => void;
    onUpdate?: (values: { type: PerkFormStep.CONFIGURE; values: PerkSettingsFormData }) => void;
    onPerkTypeChange?: (perkType: AwardTemplate) => void;
    currencySymbol: string;
}

const PREFIX = 'PerkSettingsForm';

const classes = {
    colours: `${PREFIX}-colours`,
    stampFields: `${PREFIX}-stampFields`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.colours}`]: {
        height: '100%',
        alignItems: 'center',
        display: 'flex'
    },
    [`& .${classes.stampFields}`]: {
        marginLeft: theme.spacing(2)
    }
}));

const createValidationSchema = Yup.object().shape({
    terms: Yup.string().required('This field is required.'),
    redemption: Yup.string().required('This field is required.'),
    initial: Yup.number().when('perkType', {
        is: AwardTemplate.PSEUDO_CURRENCY,
        then: Yup.number().when('global', {
            is: true,
            then: Yup.number()
                .min(0, "This field mustn't be negative.")
                .max(10000, 'This field must be lower than 10000.'),
            otherwise: undefined
        }),
        otherwise: undefined
    }),
    step: Yup.string().when('perkType', {
        is: AwardTemplate.PSEUDO_CURRENCY,
        then: Yup.string().required('This field is required.'),
        otherwise: undefined
    }),
    impact: Yup.string().when('perkType', {
        is: AwardTemplate.PSEUDO_CURRENCY,
        then: Yup.string().required('This field is required.'),
        otherwise: undefined
    }),
    stampsPerCard: Yup.number().when('perkType', {
        is: AwardTemplate.STAMP_CARD,
        then: Yup.number()
            .min(2, 'This field must be higher than 2.')
            .max(12, 'This field must be lower than 12.'),
        otherwise: undefined
    })
});

const editValidationSchema = Yup.object().shape({
    redemption: Yup.string().required('This field is required.'),
    terms: Yup.string().required('This field is required.'),
    stampsPerCard: Yup.number().when('perkType', {
        is: AwardTemplate.STAMP_CARD,
        then: Yup.number()
            .min(2, 'This field must be higher than 2.')
            .max(12, 'This field must be lower than 12.'),
        otherwise: undefined
    })
});

export const PerkSettingsForm: React.FC<PerkSettingsFormProps> = props => {
    const {
        onSubmit,
        clickPrevious,
        initialValues,
        open,
        title,
        isEdit,
        onPrevious,
        onUpdate,
        isLoading,
        currencySymbol,
        onPerkTypeChange
    } = props;
    const { allLocations } = useLocationHelpers();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isIbs = React.useMemo(() => settings.posProvider === 'IBS', [settings]);

    const initialData = React.useMemo<PerkSettingsFormData>(
        () => ({
            auto: (!isEdit && isIbs) || undefined,
            terms: '',
            redemption: '',
            perkType: AwardTemplate.AD_HOC,
            subtitle: '',
            subtitle2: '',
            availableCardCopy: '',
            nextCardProgressCopy: '',
            image: '',
            step: '',
            impact: '1',
            textColor: '#000000',
            stampsPerCard: '6',
            enabled: true,
            global: false,
            backgroundColor: '#ffffff00',
            randomizeStampCard: false,
            stampColor: '#000000',
            stampImageUrl: '',
            align: TextAlignment.LEFT,
            valign: VerticalAlignment.BOTTOM,
            locationIds: [],
            initial: '1',
            multi: false,
            titleText: ''
        }),
        []
    );

    const locationOptions: Option[] = React.useMemo(() => {
        if (allLocations) {
            return allLocations
                .filter(location => location.state === 'ACTIVE')
                .map(location => ({ value: location._id, label: location.title }));
        }

        return [];
    }, [allLocations]);

    return (
        <MuiForm
            onSubmit={onSubmit}
            validationSchema={isEdit ? editValidationSchema : createValidationSchema}
            initialValues={initialValues ? { ...initialData, ...initialValues } : initialData}
        >
            {({ submitForm, values, isValid }) => {
                if (!open) {
                    return null;
                }
                const {
                    valign,
                    align,
                    image,
                    backgroundColor,
                    textColor,
                    perkType,
                    enabled,
                    global,
                    stampsPerCard,
                    stampColor,
                    stampImageUrl,
                    randomizeStampCard
                } = values;
                const textAlign: CSSProperties['textAlign'] =
                    align.toLowerCase() as CSSProperties['textAlign'];
                const handlePreviousStep = () => {
                    if (isEdit) {
                        onPrevious(isValid, values);
                    }
                    clickPrevious();
                };
                const handleUpdate = () => {
                    if (isValid) {
                        onUpdate({ type: PerkFormStep.CONFIGURE, values });
                    }
                };
                return (
                    <Form>
                        <PerkSettingsTypeHandler
                            isEdit={isEdit}
                            perkType={perkType}
                            onPerkTypeChange={onPerkTypeChange}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <Box padding={3} maxWidth={600}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" color="secondary">
                                                2. Configure
                                            </Typography>
                                            <Typography variant="body2">
                                                Set up how the Perk will behave for your users and how it will
                                                look in your app.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="terms"
                                                multiline
                                                maxRows={2}
                                                label={
                                                    <MuiFormLabel required>Terms & Conditions</MuiFormLabel>
                                                }
                                                description="The terms and conditions associated with this perk for your customers to see in the app."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ButtonGroupInputFormField
                                                disabled={isEdit}
                                                name="perkType"
                                                label="Perk Type"
                                                options={typeOptions}
                                                description="Choose which type of perk you would like to configure."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="redemption"
                                                placeholder="123abc"
                                                label={<MuiFormLabel required>Discount Code</MuiFormLabel>}
                                                description="Enter a code that wil be visible to the cashier at the POS to let them know what discount to apply to the order."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectFormField
                                                name="locationIds"
                                                placeholder="123abc"
                                                multiple
                                                hasAllOption
                                                selectAllOptionLabel="Select All Locations"
                                                options={locationOptions}
                                                label={<MuiFormLabel>Locations</MuiFormLabel>}
                                                description="If no locations are selected, the perk will be available and shown for all locations."
                                            />
                                        </Grid>
                                        {!isEdit && perkType === AwardTemplate.PSEUDO_CURRENCY && (
                                            <Grid item xs={12}>
                                                <Row wrap="wrap" valign="center">
                                                    <Typography variant="caption" color="textSecondary">
                                                        User has to spend
                                                    </Typography>
                                                    <Box width={80} marginLeft={1} marginRight={1}>
                                                        <PrefixInputFormField
                                                            prefix={currencySymbol}
                                                            name="step"
                                                            placeholder="£1.00"
                                                        />
                                                    </Box>
                                                    <Typography variant="caption" color="textSecondary">
                                                        to receive
                                                    </Typography>
                                                    <Box width={80} marginLeft={1} marginRight={1}>
                                                        <TextFormField
                                                            name="impact"
                                                            type="number"
                                                            placeholder="10"
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant="caption"
                                                        color="textSecondary"
                                                        style={{ lineHeight: 3 }}
                                                    >
                                                        <MuiFormLabel required>{title}s.</MuiFormLabel>
                                                    </Typography>
                                                </Row>
                                            </Grid>
                                        )}
                                        {isIbs && (
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    disabled={isEdit}
                                                    name="auto"
                                                    label="Auto Apply"
                                                    description="Automatically apply this award to your user's basket"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <CheckboxFormField
                                                name="enabled"
                                                label="Enable & Push Live"
                                                description={
                                                    enabled
                                                        ? 'This award will be granted to your users and displayed in app. Users will be able to redeem on app and/or POS.'
                                                        : 'This award will not be granted to your users. Users who already have the award will be hidden in app nor available to redeem on app and/or POS.'
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxFormField
                                                name="global"
                                                label="Global"
                                                description="Automatically grant this award to all of your users."
                                            />
                                        </Grid>
                                        {(perkType === AwardTemplate.AD_HOC ||
                                            perkType === AwardTemplate.CLUB) && (
                                            <Grid item xs={12}>
                                                <CheckboxFormField
                                                    disabled={isEdit}
                                                    name="multi"
                                                    label="Multi"
                                                    description="Allow your users to redeem this perk multiple times."
                                                />
                                            </Grid>
                                        )}
                                        {(perkType === AwardTemplate.PSEUDO_CURRENCY ||
                                            perkType === AwardTemplate.STAMP_CARD) &&
                                            !isEdit &&
                                            global && (
                                                <Grid item xs={12}>
                                                    <TextFormField
                                                        name="initial"
                                                        label={`Initial ${
                                                            perkType === AwardTemplate.PSEUDO_CURRENCY
                                                                ? 'points'
                                                                : 'stamps'
                                                        }`}
                                                        type="number"
                                                        placeholder="0"
                                                        description="How many points a user has to start with."
                                                    />
                                                </Grid>
                                            )}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Box padding={3} maxWidth={866}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography>Preview</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Row gutter valign="flex-end" align="space-between">
                                                <PerkPreviewEditable
                                                    label={
                                                        perkType === AwardTemplate.STAMP_CARD
                                                            ? 'No purchase available'
                                                            : undefined
                                                    }
                                                    title={title}
                                                    editable
                                                    perkType={perkType}
                                                    randomizeStampCard={randomizeStampCard}
                                                    stampsPerCard={stampsPerCard ? Number(stampsPerCard) : 6}
                                                    stampColor={stampColor}
                                                    valign={valign}
                                                    stampImageUrl={stampImageUrl}
                                                    textAlign={textAlign}
                                                    image={image}
                                                    backgroundColor={backgroundColor}
                                                    textColor={textColor}
                                                />
                                                {perkType === AwardTemplate.STAMP_CARD && (
                                                    <PerkPreviewEditable
                                                        label="Purchase available"
                                                        cardAvailable
                                                        editable
                                                        title={title}
                                                        perkType={perkType}
                                                        randomizeStampCard={randomizeStampCard}
                                                        stampColor={stampColor}
                                                        stampsPerCard={
                                                            stampsPerCard ? Number(stampsPerCard) : 6
                                                        }
                                                        valign={valign}
                                                        stampImageUrl={stampImageUrl}
                                                        textAlign={textAlign}
                                                        image={image}
                                                        backgroundColor={backgroundColor}
                                                        textColor={textColor}
                                                    />
                                                )}
                                                {perkType !== AwardTemplate.STAMP_CARD && (
                                                    <Column
                                                        style={{
                                                            width: `calc(100% - ${MuiTheme.spacing(
                                                                perkPreviewWidthByType[perkType]
                                                            )}px)`
                                                        }}
                                                    >
                                                        <Box marginTop={1}>
                                                            <Typography>Text Alignment</Typography>
                                                        </Box>
                                                        <ButtonGroupInputFormField
                                                            name="align"
                                                            options={alignOptions}
                                                        />
                                                        <ButtonGroupInputFormField
                                                            name="valign"
                                                            options={valignOptions}
                                                        />
                                                    </Column>
                                                )}
                                            </Row>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ImageFormField
                                                aspect={1.5}
                                                name="image"
                                                label="Background"
                                                description="600px by 400px. Max. file size is 1M."
                                            />
                                        </Grid>
                                        <StyledGrid item xs={12} sm={6}>
                                            <Column valign="space-around" className={classes.colours}>
                                                <ColorFormField name="textColor" label="Text Colour" />
                                                <ColorFormField
                                                    opacity
                                                    name="backgroundColor"
                                                    label="Background Overlay Colour"
                                                />
                                            </Column>
                                        </StyledGrid>
                                        {perkType === AwardTemplate.STAMP_CARD && (
                                            <React.Fragment>
                                                <StyledGrid item xs={12}>
                                                    <Row
                                                        flex={1}
                                                        valign="space-around"
                                                        className={classes.colours}
                                                    >
                                                        <ImageFormField
                                                            fullWidth={false}
                                                            name="stampImageUrl"
                                                            label="Stamp"
                                                            aspect={1}
                                                            description="600px by 400px. Max. file size is 1M."
                                                        />
                                                        <Column
                                                            valign="space-around"
                                                            className={clsx(
                                                                classes.colours,
                                                                classes.stampFields
                                                            )}
                                                        >
                                                            <TextFormField
                                                                max={12}
                                                                name="stampsPerCard"
                                                                type="number"
                                                                disabled={isEdit}
                                                                label={
                                                                    <MuiFormLabel required>
                                                                        Stamps per Card
                                                                    </MuiFormLabel>
                                                                }
                                                            />
                                                            <ColorFormField
                                                                name="stampColor"
                                                                label="Stamp Colour"
                                                            />
                                                        </Column>
                                                    </Row>
                                                </StyledGrid>
                                                {stampImageUrl && (
                                                    <Grid item xs={12}>
                                                        <CheckboxFormField
                                                            name="randomizeStampCard"
                                                            label="Randomise Stamp Position"
                                                        />
                                                    </Grid>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Row flex={1} gutter align="flex-end">
                                    {isEdit && (
                                        <LoadingButton
                                            loading={isLoading}
                                            disabled={isLoading || !isValid}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={handlePreviousStep} color="primary" variant="outlined">
                                        Previous
                                    </Button>
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};

import React from 'react';
import { Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';

export const BraintreeSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => {
    if (locationId !== undefined) {
        return null;
    }
    return (
        <>
            <SettingDisplay label="Production Environment">
                {settings?.braintree?.isProduction && <Done />}
                {!settings?.braintree?.isProduction && <Close />}
            </SettingDisplay>
            <SettingDisplay label="Merchant ID">
                <Typography variant="body2">{settings?.braintree?.merchantId}</Typography>
            </SettingDisplay>
            <SettingDisplay label="Public Key">
                <Typography variant="body2">{settings?.braintree?.publicKey}</Typography>
            </SettingDisplay>
            <SettingDisplay label="Private Key">
                <Typography variant="body2">{settings?.braintree?.privateKey}</Typography>
            </SettingDisplay>
        </>
    );
};

import {
    MenuChange,
    MenuV,
    ModifierProduct,
    ModifierProductChange,
    fromModifierProductKey,
    toModifierProductKey
} from '@pepperhq/menu-sdk';
import { TableRowWithOverrides } from './menu';
import { isDefined } from 'lib/typeguards';
import { getProductPrice } from './product';

type ModifierProductX = ModifierProduct & { hide?: boolean };

export interface EnrichedModifierProduct extends TableRowWithOverrides, ModifierProductX {
    hide?: boolean;
    modifierTitle?: string;
    categoryTitle?: string;
    price?: number;
}

function applyModifierProduct(pc: ModifierProductChange, optionProduct: ModifierProductX) {
    if (isDefined(pc.title)) {
        optionProduct.title = pc.title;
    }
    if (isDefined(pc.selected)) {
        optionProduct.selected = pc.selected;
    }
    if (isDefined(pc.imageUrl)) {
        optionProduct.imageUrl = pc.imageUrl;
    }
    if (isDefined(pc.hide)) {
        optionProduct.hide = pc.hide;
    }
    if (isDefined(pc.sort)) {
        optionProduct.sort = pc.sort;
    }
    if (isDefined(pc.categoryId)) {
        optionProduct.categoryId = pc.categoryId;
    }
}

function applyModifierProducts(
    optionProduct: EnrichedModifierProduct,
    modifierId: string,
    categoryId: string,
    changes?: Record<string, ModifierProductChange>,
    allowCategorySpecific?: boolean
) {
    if (!changes) {
        return;
    }
    let overrides: Record<string, any> = {};
    const wildCardChange = changes[toModifierProductKey(optionProduct.id, undefined, undefined)];
    if (wildCardChange) {
        applyModifierProduct(wildCardChange, optionProduct);
        if (!allowCategorySpecific) {
            overrides = { ...overrides, ...wildCardChange };
        }
    }
    const modifierChange = changes[toModifierProductKey(optionProduct.id, modifierId, undefined)];
    if (modifierChange) {
        applyModifierProduct(modifierChange, optionProduct);
        if (!allowCategorySpecific) {
            overrides = { ...overrides, ...modifierChange };
        }
    }
    if (allowCategorySpecific) {
        const categoryChange = changes[toModifierProductKey(optionProduct.id, undefined, categoryId)];
        if (categoryChange) {
            applyModifierProduct(categoryChange, optionProduct);
            overrides = { ...overrides, ...categoryChange };
        }
        const modCategoryChange = changes[toModifierProductKey(optionProduct.id, modifierId, categoryId)];
        if (modCategoryChange) {
            applyModifierProduct(modCategoryChange, optionProduct);
            overrides = { ...overrides, ...modCategoryChange };
        }
    }
    const _overriden = new Set(Object.keys(overrides));
    optionProduct._overriden = _overriden;
}

export function getModifierProductsData(
    menu: MenuV<5>,
    menuChange: MenuChange,
    allowScoped = true
): [EnrichedModifierProduct[], Record<string, string>, Record<string, string>, Record<string, string>] {
    if (!menu || !Array.isArray(menu.categories)) {
        return [[], {}, {}, {}];
    }
    const modifierProducts: Record<string, EnrichedModifierProduct> = {};
    const productIdToTitle: Record<string, string> = {};
    const modifierIdToTitle: Record<string, string> = {};
    const categoryIdToTitle: Record<string, string> = {};
    const keyProcessed = new Set<string>();
    menu.categories.forEach(category => {
        if (category) {
            const categoryTitle = menuChange.categories?.[category.id]?.title || category.title;
            categoryIdToTitle[category.id] = categoryTitle;
            category.modifiers.forEach(modifier => {
                if (!isDefined(modifierIdToTitle[modifier.id])) {
                    modifierIdToTitle[modifier.id] =
                        menuChange.modifiers?.[modifier.id]?.title ?? modifier.title;
                }
                if (Array.isArray(modifier.products)) {
                    modifier.products.forEach(option => {
                        if (!isDefined(productIdToTitle[option.id])) {
                            productIdToTitle[option.id] =
                                menuChange.modifierProducts?.[option.id]?.title ?? option.title;
                        }
                        const unscopedKey = toModifierProductKey(option.id, modifier.id, undefined);
                        if (!keyProcessed.has(unscopedKey)) {
                            const currentOption = { ...option };
                            applyModifierProducts(
                                currentOption,
                                modifier.id,
                                category.id,
                                menuChange.modifierProducts
                            );
                            modifierProducts[unscopedKey] = {
                                ...currentOption,
                                id: unscopedKey,
                                categoryTitle,
                                modifierTitle: menuChange.modifiers?.[modifier.id]?.title ?? modifier.title,
                                price: getProductPrice(option.id, menu, menuChange, currentOption?.categoryId)
                            };
                            keyProcessed.add(unscopedKey);
                        } else {
                            const existingRow = modifierProducts[unscopedKey];
                            existingRow.categoryTitle = existingRow.categoryTitle
                                ? `${existingRow.categoryTitle}, ${categoryTitle}`
                                : categoryTitle;
                        }
                    });
                }
            });
        }
    });
    // append modifier products from specific category
    if (menuChange.modifierProducts && allowScoped) {
        for (const key of Object.keys(menuChange.modifierProducts)) {
            if (!keyProcessed.has(key)) {
                const { id, categoryId, modifierId } = fromModifierProductKey(key);
                if (isDefined(categoryId)) {
                    const unscopedKey = toModifierProductKey(id, modifierId, undefined);
                    const existingRow = modifierProducts[unscopedKey];
                    const currentOption = { ...existingRow, id };
                    applyModifierProducts(
                        currentOption,
                        modifierId,
                        categoryId,
                        menuChange.modifierProducts,
                        true
                    );
                    currentOption._overriden?.add('id');
                    modifierProducts[key] = {
                        ...currentOption,
                        id: key,
                        categoryTitle: categoryIdToTitle[categoryId],
                        price: getProductPrice(id, menu, menuChange, currentOption?.categoryId)
                    };
                    keyProcessed.add(key);
                }
            }
        }
    }
    return [Object.values(modifierProducts), categoryIdToTitle, modifierIdToTitle, productIdToTitle];
}

/* eslint-disable max-len */
import React from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import { DAYS_OF_THE_WEEK, Location, LocationOpeningHours } from 'components/location/models/LocationModel';
import { titleize } from 'lib/helpers';
import { Row } from 'ui/Flex';
import { parseTimeRange } from '../helpers';
import { LocationAlternativeOpeningHoursModal } from '../modal/LocationAlternativeOpeningHoursModal';

interface LocationOpeningHoursCardComponentProps {
    location: Location;
    mode: 'STANDART' | 'HTML';
}

export function LocationOpeningHoursCardComponent(props: LocationOpeningHoursCardComponentProps) {
    const { location, mode } = props;
    const [alternativeHoursOpened, setAlternativeHoursOpened] = React.useState(false);

    const handleAlternativeHoursClick = React.useCallback(() => {
        setAlternativeHoursOpened(true);
    }, []);
    const handleAlternativeHoursClose = React.useCallback(() => {
        setAlternativeHoursOpened(false);
    }, []);

    function openingHoursMapper(name: keyof LocationOpeningHours) {
        if (!location || !location.openingHours) {
            return null;
        }

        const { start, end } = parseTimeRange(location.openingHours[name]);
        let workingHours = start && end && `${start} - ${end}`;

        if (location.openingHours[name] === 'closed') {
            workingHours = 'Closed';
        } else if (location.openingHours[name] === '') {
            workingHours = 'Empty';
        }

        return (
            <Grid item xs={12} key={`opening-hours-${name}`}>
                <Typography align="center">
                    <b>{titleize(name)} </b>
                    {workingHours}
                </Typography>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            {mode === 'STANDART' && DAYS_OF_THE_WEEK.map(openingHoursMapper)}
            {!!location.alternativeOpeningHoursText && mode === 'HTML' && (
                <Tooltip
                    placement="left"
                    title="Using HTML, This lets you override how opening hours are displayed in the app. However, the standard opening hours will still be used to determine when the location is open for ordering. This will replace your standard defined opening hours."
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Divider style={{ opacity: 0.4 }} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    <b>Custom Opening Hours HTML</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Row valign="center">
                                    <Typography
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {location.alternativeOpeningHoursText}
                                    </Typography>
                                    <Tooltip title="View">
                                        <IconButton onClick={handleAlternativeHoursClick} size="large">
                                            <RemoveRedEye />
                                        </IconButton>
                                    </Tooltip>
                                </Row>
                            </Grid>
                        </Grid>
                    </Grid>
                </Tooltip>
            )}
            <LocationAlternativeOpeningHoursModal
                open={alternativeHoursOpened}
                onClose={handleAlternativeHoursClose}
                openingHours={location.alternativeOpeningHoursText}
            />
        </Grid>
    );
}

import { menusApi } from 'components/menu-manager/MenusApi';
import { Dispatch } from 'redux';

import logger from 'lib/logger';
import { MenuManagerActionType } from './menuManagerActionTypes';
import { MenuSummary } from '@pepperhq/menu-sdk';

interface IBaseAction {
    type: MenuManagerActionType;
}

interface MenuSummaryListLoadingAction extends IBaseAction {
    type: MenuManagerActionType.START_REQUEST | MenuManagerActionType.END_REQUEST;
}

interface MenuSummaryListAction extends IBaseAction {
    type: MenuManagerActionType.GET_MENU_SUMMARY_LIST;
    list: MenuSummary[];
}

export type IMenuManagerAction = MenuSummaryListAction | MenuSummaryListLoadingAction;

export const getMenuSummaryList = async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: MenuManagerActionType.START_REQUEST
        });
        const menuSummaryList = await menusApi.getMenus();

        if (menuSummaryList.ok) {
            dispatch<MenuSummaryListAction>({
                type: MenuManagerActionType.GET_MENU_SUMMARY_LIST,
                list: menuSummaryList.body
            });
        }

        return menuSummaryList;
    } catch (error) {
        logger.error('Failed to load a menu summary.', error.message);
    } finally {
        dispatch({
            type: MenuManagerActionType.END_REQUEST
        });
    }
};

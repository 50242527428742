import React from 'react';
import { useLocation, useParams } from 'react-router';
import { KibanaIframeLayout } from 'components/analytics/KibanaIframeLayout';

export default () => {
    const queryParams = new URLSearchParams(useLocation().search);
    const pageName = queryParams.get('pageName');
    const pageDescription = queryParams.get('pageDescription');
    const { dashboardId } = useParams<{ dashboardId: string }>();
    return (
        <KibanaIframeLayout pageName={pageName} pageDescription={pageDescription} dashboardId={dashboardId} />
    );
};

import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { MuiDropZone } from './MuiDropZone';

import { useImageCrop } from './useImageCrop';
import { ImagePickerPreviewModal } from './ImagePickerPreviewModal';
import { ImagePickerCropModal } from './ImagePickerCropModal';
import { useImagePicker } from './useImagePicker';
import { MuiImagePickerStep, useImagePickerState } from './useImagePickerState';

const StyledDiv = styled('div')({
    width: '100%'
});

export interface IAdditionalUpload {
    width: number;
    height: number;
    uploadFolder: string;
    fileName: string;
    format: string;
}

interface MuiImagePickerProps {
    onSelect: (imageUrl: string) => void;
    height?: number;
    url?: string;
    // Due to upgrading material ui we had to do that, please remove legacy forms finally
    width?: number | any;
    aspect?: number;
    unremovable?: boolean;
    fullWidth?: boolean;
    uploadFileName?: string;
    uploadFolder?: string;
    overlay?: React.ReactNode;
    disableCrop?: boolean;
    additionalUploads?: IAdditionalUpload[];
    smallText?: boolean;
    accept?: string | string[];
}

export const MuiImagePicker: React.FC<MuiImagePickerProps> = props => {
    const { fullWidth, overlay, smallText, accept, unremovable, disableCrop } = props;
    const {
        step,
        onCloseStateChange,
        onCropCancelStateChange,
        onCropStartStateChange,
        onFileSelectStateChange
    } = useImagePickerState();
    const {
        src,
        file,
        error,
        loading,
        externalUrl,
        aspect,
        onFileSelect,
        onClear,
        onClose,
        onSubmit,
        onUploadSuccess
    } = useImagePicker(props, onFileSelectStateChange, onCloseStateChange);

    const {
        crop,
        cropLoading,
        cropError,
        isCropAvailable,
        onCropClose,
        onCropStart,
        onCropChange,
        onCropComplete,
        onImageLoaded,
        onCropSubmit
    } = useImageCrop(props, onCropStartStateChange, onCropCancelStateChange, onUploadSuccess, file);
    const disabled = React.useMemo(() => !!error || loading, [error, loading]);
    const cropDisabled = React.useMemo(() => !!cropError || cropLoading, [cropError, cropLoading]);
    return (
        <StyledDiv>
            <MuiDropZone
                aspect={aspect}
                onSelect={onFileSelect}
                imageUrl={externalUrl}
                fullWidth={fullWidth}
                overlay={overlay}
                smallText={smallText}
                accept={accept}
                submitFileAs="FILE"
            />
            {!unremovable && (
                <Box pt={1}>
                    <Button color="secondary" variant="outlined" size="small" onClick={onClear}>
                        Clear Image
                    </Button>
                </Box>
            )}

            {step === MuiImagePickerStep.SELECTED && (
                <ImagePickerPreviewModal
                    open={step === MuiImagePickerStep.SELECTED}
                    onClose={onClose}
                    onCropStart={onCropStart}
                    isCropAvailable={isCropAvailable && !disableCrop}
                    onSubmit={onSubmit}
                    loading={loading}
                    disabled={disabled}
                    error={error}
                    src={src}
                    file={file}
                    aspect={aspect}
                />
            )}
            {src && (
                <ImagePickerCropModal
                    open={step === MuiImagePickerStep.CROPPING}
                    onClose={onCropClose}
                    crop={crop}
                    src={src}
                    disabled={cropDisabled}
                    disableCrop={disableCrop}
                    onImageLoaded={onImageLoaded}
                    onCropComplete={onCropComplete}
                    onCropChange={onCropChange}
                    error={cropError}
                    loading={cropLoading}
                    onSubmit={onCropSubmit}
                />
            )}
        </StyledDiv>
    );
};

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
    GridCellValue,
    GridFilterItem,
    GridFilterModel,
    GridFilterOperator,
    GridLinkOperator
} from '@mui/x-data-grid-pro';
import React from 'react';

export type GridOverrideFilterItem = {
    column: string;
    labels?: Record<string, string> | Map<string, string>;
    formatValue?: (value: GridCellValue) => string;
};

export type GridSearchItems = GridOverrideFilterItem[];
export enum OverrideFilterOptions {
    'ALL',
    'AVAILABLE_STATUS',
    'UNAVAILABLE_STATUS',
    'AVAILABLE',
    'UNAVAILABLE'
}

export const filterValueByOverrideState = (
    filterValue: OverrideFilterOptions,
    availableOverride: boolean | undefined,
    isAvailable: boolean | undefined
) => {
    switch (filterValue) {
        case OverrideFilterOptions.AVAILABLE:
            return availableOverride === true;
        case OverrideFilterOptions.UNAVAILABLE:
            return availableOverride === false;
        case OverrideFilterOptions.AVAILABLE_STATUS:
            return isAvailable === true || isAvailable === undefined;
        case OverrideFilterOptions.UNAVAILABLE_STATUS:
            return isAvailable === false;
        default:
            return true;
    }
};

export function useOverridesGridFilter(items: GridSearchItems) {
    const [filterValue, setFilterValue] = React.useState<OverrideFilterOptions>(OverrideFilterOptions.ALL);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And
    });
    const filterOperators = React.useMemo(() => {
        const operators: Record<string, GridFilterOperator | undefined> = {};
        if (Array.isArray(items)) {
            items.forEach(item => {
                operators[item.column] = {
                    label: 'Override Search',
                    value: 'override-search',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem?.columnField || !filterItem?.value || !filterItem?.operatorValue) {
                            return null;
                        }

                        return params =>
                            filterValueByOverrideState(
                                filterItem.value,
                                params.row.availableOverride,
                                params.row.isAvailable
                            );
                    }
                };
            });
        }
        return operators;
    }, [items]);
    React.useEffect(() => {
        if (filterValue === OverrideFilterOptions.ALL) {
            setFilterModel({ items: [], linkOperator: GridLinkOperator.Or });
        } else {
            const newItems: GridFilterItem[] = [];
            items.forEach(item => {
                newItems.push({
                    id: item.column,
                    columnField: item.column,
                    operatorValue: 'override-search',
                    value: filterValue
                });
            });
            setFilterModel({ items: newItems, linkOperator: GridLinkOperator.Or });
        }
    }, [items, filterValue]);

    const handleFilterChange = React.useCallback((event: SelectChangeEvent<OverrideFilterOptions>) => {
        setFilterValue(event.target.value as OverrideFilterOptions);
    }, []);

    const renderInput = React.useCallback(
        () => (
            <Select
                title="Filter results by"
                value={filterValue}
                onChange={handleFilterChange}
                variant="outlined"
            >
                <MenuItem value={OverrideFilterOptions.ALL}>All Status & Override States</MenuItem>
                <MenuItem value={OverrideFilterOptions.AVAILABLE_STATUS}>Available Status</MenuItem>
                <MenuItem value={OverrideFilterOptions.UNAVAILABLE_STATUS}>Unavailable Status</MenuItem>
                <MenuItem value={OverrideFilterOptions.AVAILABLE}>Available Override Set</MenuItem>
                <MenuItem value={OverrideFilterOptions.UNAVAILABLE}>Unavailable Override Set</MenuItem>
            </Select>
        ),
        [filterValue, handleFilterChange]
    );

    return { filterModel, filterOperators, filterValue, renderInput };
}

import { IOperationV2 } from 'components/operation/models/OperationModel';

export interface ICreateActionExportOptions {
    from?: string;
    to?: string;
    maxFileSizeMB?: number;
    locationIds?: string[];
    types?: string[];
}

interface IActionOperationResult {
    errorMessages?: string[];
    exported?: number;
    imported?: number;
    skipped: number;
    total: number;
    errors: number;
    removedUnlinked?: number;
    href?: string;
    etaMs: number;
    message: string;
}

interface IActionOperationError {
    code: string;
    message: string;
    isRetryable: boolean;
}

export interface IActionOperation
    extends IOperationV2<string, ICreateActionExportOptions, IActionOperationResult, IActionOperationError> {
    namespace: 'ACTIONS';
    name: 'ACTION_EXPORT';
}

export interface IGetActionOperationsParams {
    nextKey?: string;
}

export const ACTION_OPERATIONS_LIMIT = 20;

// platform does not return result when export is created, so will push it on frontend side
export const INITIAL_OPERATION_RESULT = {
    total: 0,
    exported: 0,
    errors: 0,
    skipped: 0,
    message: 'Creating export'
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField, styled, Typography } from '@mui/material';
import { Perk } from 'components/loyalty/models/PerkModel';
import { perkApi } from 'components/loyalty/PerkApi';
import {
    MESSAGE_CUSTOMER_AWARD_ERROR,
    MESSAGE_CUSTOMER_AWARD_ERROR_EXIST,
    MESSAGE_CUSTOMER_AWARD_SUCCESS
} from 'config/messages';
import { LegacyForm } from 'lib/LegacyForm';
import { isString } from 'lib/typeguards';
import { Option } from 'lib/types';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { AutoForm } from 'ui/form/AutoForm';
import { customerApi } from '../customerApi';
import { createCustomerAwardForm } from '../forms/createCustomerAwardForm';
import { getStampCard } from '../helpers';
import { Award, AwardTemplate, awardTemplateLabels } from '../models/Award';
import { Customer } from '../models/Customer';

const perksToOptions = (perk: Perk) => ({
    value: perk._id,
    label: perk.title,
    description: awardTemplateLabels[perk.template]
});

const filterUniqPerks = (currentPerksAndAwards: Partial<Award | Perk>[]) => (perk: Perk) =>
    perk.template === AwardTemplate.PSEUDO_CURRENCY ||
    perk.template === AwardTemplate.STAMP_CARD ||
    currentPerksAndAwards.every(item =>
        'perkId' in item ? perk._id !== item.perkId : perk._id !== item._id
    );

interface CustomerAwardFormProps {
    customer: Customer;
    perks: Perk[];
    awards: Partial<Award>[];
    onClose: () => void;
    onSuccess: () => void;
}

const StyledDiv = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
        marginLeft: theme.spacing(-1.5)
    },
    '& .MuiTypography-body1': {
        fontSize: theme.typography.subtitle2.fontSize
    }
}));

export const CustomerAwardForm: React.FC<CustomerAwardFormProps> = props => {
    const { customer, onClose, onSuccess, perks, awards } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = React.useState(false);
    const [selected, setSelected] = React.useState('');
    const [amountValue, setAmountValue] = React.useState(0);
    const handleSubmit = React.useCallback(
        async ({
            perk: { value: perkId },
            value,
            sendBalanceUpdate,
            message
        }: {
            perk: Option;
            value: string;
            sendBalanceUpdate: boolean;
            message: string;
        }) => {
            setLoading(true);
            const award = awards.find(item => item.perkId === perkId);
            if (!!award) {
                if (
                    award.template === AwardTemplate.PSEUDO_CURRENCY ||
                    award.template === AwardTemplate.STAMP_CARD
                ) {
                    const result = await customerApi.credit(customer._id, {
                        body: {
                            awardId: award._id,
                            number: Number(value),
                            description: message,
                            scheme: 'POINT_PERK',
                            disableDefaultMessagePrefix: !sendBalanceUpdate
                        }
                    });
                    if (result.ok) {
                        setLoading(false);
                        onSuccess();
                        return dispatch(
                            enqueueSnackbar(MESSAGE_CUSTOMER_AWARD_SUCCESS, { variant: 'success' })
                        );
                    }
                }
                setLoading(false);
                return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_AWARD_ERROR_EXIST, { variant: 'error' }));
            }
            const result = await perkApi.grantAward(perkId, customer._id);
            if (result.ok) {
                setLoading(false);
                onSuccess();
                return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_AWARD_SUCCESS, { variant: 'success' }));
            }
            setLoading(false);
            return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_AWARD_ERROR, { variant: 'error' }));
        },
        [awards, dispatch, onSuccess, customer._id]
    );
    const [form, setForm] = React.useState(
        new LegacyForm(
            createCustomerAwardForm(
                perks.filter(filterUniqPerks(awards)).map(perksToOptions),
                false,
                undefined
            ),
            handleSubmit
        )
    );
    const handleBlur = React.useCallback(
        (fieldName: string, data: string | { value: string }) => {
            if (fieldName === 'perk' && !isString(data)) {
                const perk = perks.find(item => item._id === data.value);
                setSelected(perk._id);
                setAmountValue(0);
                if (
                    perk.template !== AwardTemplate.PSEUDO_CURRENCY &&
                    perk.template !== AwardTemplate.STAMP_CARD
                ) {
                    setForm(
                        new LegacyForm(
                            createCustomerAwardForm(
                                perks.filter(filterUniqPerks(awards)).map(perksToOptions),
                                false,
                                perk._id
                            ),
                            handleSubmit
                        )
                    );
                } else {
                    setForm(
                        new LegacyForm(
                            createCustomerAwardForm(
                                perks.filter(filterUniqPerks(awards)).map(perksToOptions),
                                true,
                                perk._id
                            ),
                            handleSubmit
                        )
                    );
                }
            }
            if (fieldName === 'value') {
                setAmountValue(Number(data));
            }
        },
        [awards, handleSubmit, perks]
    );
    const currentAward = awards.find(item => item.perkId === selected);
    return (
        <AutoForm
            onBlur={handleBlur}
            isLoading={isLoading}
            submitLabel="Award"
            subtitle="Grant your user an award for them to use when they're purchasing."
            form={form}
            title={`Grant ${customer.firstName} an award`}
            onClose={onClose}
        >
            {({ perk, message, value, sendBalanceUpdate }) => (
                <React.Fragment>
                    {perk}
                    {value}
                    {currentAward && currentAward.template === AwardTemplate.PSEUDO_CURRENCY && (
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled
                                label="Current Amount"
                                value={currentAward.points.available}
                            />
                            <Typography variant="caption" color="textSecondary">
                                After award: {currentAward.points.available + amountValue || 0}
                            </Typography>
                        </Grid>
                    )}
                    {currentAward && currentAward.template === AwardTemplate.STAMP_CARD && (
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled
                                label="Current Amount"
                                value={getStampCard(currentAward.points)}
                            />
                            <Typography variant="caption" color="textSecondary">
                                After award:{' '}
                                {getStampCard({
                                    ...currentAward.points,
                                    available: currentAward.points.available + amountValue || 0
                                })}
                            </Typography>
                        </Grid>
                    )}
                    {message}
                    <StyledDiv>{sendBalanceUpdate}</StyledDiv>
                </React.Fragment>
            )}
        </AutoForm>
    );
};

CustomerAwardForm.defaultProps = {
    perks: []
};

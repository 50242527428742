import debounce from 'debounce';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import { isDefined, isEmptyString } from 'lib/typeguards';
import React from 'react';
import { MenuSummaryFilter } from '../MenuManagerModel';

export function useMenuManagerFilter(): [MenuSummaryFilter, (filter: MenuSummaryFilter) => void] {
    const { search, onSearch } = useHistoryFilters();
    const [filter, setFilter] = React.useState<MenuSummaryFilter>({
        search: search.search || ''
    });
    const handleSearch = React.useRef(
        debounce(
            React.useCallback(
                (
                    filter: Partial<MenuSummaryFilter>,
                    runSearch: (value: Partial<MenuSummaryFilter>) => void
                ) => {
                    runSearch(filter);
                },
                []
            ),
            250
        )
    );
    const handleFilterChange = React.useCallback(
        (filter: MenuSummaryFilter) => {
            setFilter(filter);
            const body: Partial<MenuSummaryFilter> = {};
            if (isDefined(filter.search) && !isEmptyString(filter.search)) {
                body.search = filter.search;
            }
            handleSearch.current(body, onSearch);
        },
        [handleSearch, onSearch]
    );
    return [filter, handleFilterChange];
}

import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { AutoFormFields, LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import { locationActionToLabel, LocationButtonActions } from '../models/LocationModel';

const createLocationButtonFormItem: AutoFormFields = {
    title: {
        label: 'Title',
        validation: 'required'
    },
    url: {
        label: 'URL'
    },
    style: {
        label: 'Style',
        type: 'select',
        value: 'PRIMARY',
        options: [
            { label: 'Primary', value: 'PRIMARY' },
            { label: 'Secondary', value: 'SECONDARY' }
        ]
    },
    actionLabel: {
        // required so that action is updated in sortableField
        label: 'action'
    }
};

interface LocationButtonFormProps {
    value?: any;
    onSubmit: (value: any) => void;
    close: () => void;
    formTitle: string;
}

export interface LocationButtonFormResponse {
    title: string;
    action: string;
    style: string;
    properties: {
        url: string;
    };
}

const LocationButtonForm = React.memo((props: LocationButtonFormProps) => {
    const [locationButtonType, setLocationButtonType] = React.useState(
        props.value ? props.value.action : 'BROWSER'
    );
    const handleChange = React.useCallback(event => setLocationButtonType(event.target.value.toString()), []);
    const form = new LegacyForm(createLocationButtonFormItem, data => {
        props.onSubmit({ ...data, action: locationButtonType, properties: { url: data.url } });
        props.close();
    }).update(
        props.value
            ? {
                  title: props.value.title,
                  action: locationButtonType,
                  actionLabel: locationActionToLabel[locationButtonType as LocationButtonActions],
                  style: props.value.style,
                  url: props.value.properties ? props.value.properties.url : props.value.url || undefined
              }
            : {}
    );
    return (
        <AutoForm form={form} onClose={props.close}>
            {({ title, style, url }) => (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                            {props.formTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>Action</InputLabel>
                        <FormControl fullWidth>
                            <Select value={locationButtonType.toString()} onChange={handleChange}>
                                <MenuItem value="BROWSER">Browser</MenuItem>
                                <MenuItem value="CHECKIN">Check-In</MenuItem>
                                <MenuItem value="OPENING_TIMES">Opening Times</MenuItem>
                                <MenuItem value="EVENTS">Events</MenuItem>
                                <MenuItem value="OPEN_TAB">Open Tab</MenuItem>
                                <MenuItem value="PREORDER">Pre-Order</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {title}
                    {style}
                    {locationButtonType === 'BROWSER' && url}
                </React.Fragment>
            )}
        </AutoForm>
    );
});

export const createLocationButtonForm: AutoFormFields = {
    buttons: {
        type: 'sortableField',
        fields: [
            { label: 'Title', width: '55%', key: 'title' },
            { label: 'Action', width: '30%', key: 'actionLabel' }
        ],
        FormComponent: LocationButtonForm,
        label: ''
    }
};

import React from 'react';
import { Chip, Paper, TableCell, styled } from '@mui/material';
import { format } from 'date-fns';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { Notification } from './models/Notification';
import { mapNotificationStatusToStyles } from './helpers';

const StyledChip = styled(Chip)<{ label: 'PENDING' | 'DONE' | 'ERROR' }>(({ theme, label }) => ({
    ...mapNotificationStatusToStyles(label, theme),
    fontSize: '0.8rem'
}));

function NotificationStateChip(props: { notification: Notification<string> }) {
    return <StyledChip label={props.notification.status} />;
}

const columns: MuiTableColumnItem[] = [
    {
        key: 'createdAt',
        label: 'Date & Time',
        render: (item: Notification<string>, key: string) => (
            <TableCell style={{ width: '15%' }} key={key}>
                {format(new Date(item.createdAt), 'dd/MM/yyyy h:mmaa')}
            </TableCell>
        )
    },
    {
        key: 'message',
        label: 'Message'
    },
    {
        key: 'status',
        label: 'Status',
        headerProps: {
            align: 'center'
        },
        render: (item: Notification<string>, key: string) => (
            <TableCell align="center" key={key} size="small">
                <NotificationStateChip notification={item} />
            </TableCell>
        )
    }
];

interface NotificationsTableProps {
    scroll: MuiTableScrollItems;
    toolbarItems: MuiTableToolbarItems;
    itemActions: (item: Notification<string>, index: number) => MuiMenuItem[];
    data: Notification<string>[];
    isLoading: boolean;
}

export const NotificationsTable: React.FC<NotificationsTableProps> = props => (
    <Paper>
        <MuiTable columns={columns} {...props} />
    </Paper>
);

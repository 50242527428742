import { Box, TablePagination } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
    TRANSACTIONS_PAGE_LIMIT,
    usePepperTransactions
} from 'components/pepper-pay/transactions/hooks/usePepperTransactions';
import { usePepperTransactionsFilter } from 'components/pepper-pay/transactions/hooks/usePepperTransactionsFilter';
import { TransactionsFilters } from 'components/pepper-pay/transactions/transactions-filter/TransactionsFilters';
import { TransactionStatusCell } from 'components/pepper-pay/transactions/TransactionStatusCell';
import { ViewTransactionCell } from 'components/pepper-pay/transactions/ViewTransactionCell';
import { CUSTOMERS_VIEW, PEPPER_PAY_TRANSACTIONS_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getAllLocations } from 'store/locations/locationsActions';
import { MuiTheme } from 'config/theme';

export const TransactionsPage: React.FC = () => {
    const dispatch = useDispatch();

    const [filter, onFilterChange] = usePepperTransactionsFilter();
    const { formattedTransactions, page, onPageChange, loading, count } = usePepperTransactions(filter);
    React.useEffect(() => {
        getAllLocations()(dispatch);
    }, [dispatch]);
    const renderCustomerViewCell = React.useCallback(
        (params: GridCellParams) => (
            <ViewTransactionCell id={params.value} param="customerId" path={CUSTOMERS_VIEW} />
        ),
        []
    );
    const renderViewCell = React.useCallback(
        (params: GridCellParams) => (
            <ViewTransactionCell
                id={params.value}
                param="transactionId"
                path={PEPPER_PAY_TRANSACTIONS_VIEW}
            />
        ),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'pi',
                disableReorder: true,
                disableExport: true,
                width: 300,
                sortable: false,
                renderCell: renderViewCell
            },
            {
                headerName: 'Pre-auth Amount',
                field: 'amount',
                type: 'number',
                width: 170,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Captured Amount',
                field: 'amountReceived',
                type: 'number',
                width: 170,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Order Number',
                field: 'orderId',
                sortable: false,
                width: 130
            },
            {
                headerName: 'Type',
                field: 'type',
                sortable: false,
                width: 120
            },
            {
                headerName: 'State',
                field: 'state',
                sortable: false,
                width: 130,
                renderCell: TransactionStatusCell
            },
            {
                headerName: 'Customer ID',
                field: 'customerId',
                sortable: false,
                width: 210,
                renderCell: renderCustomerViewCell
            },
            {
                headerName: 'Risk',
                field: 'risk',
                sortable: false,
                width: 130
            },
            {
                headerName: 'Charge ID',
                field: 'paymentChargeId',
                width: 260,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Last Four',
                field: 'lastFour',
                sortable: false,
                width: 150
            },
            {
                headerName: 'Expiry Date',
                field: 'expiryDate',
                sortable: false,
                width: 130
            },
            {
                headerName: 'Date',
                field: 'date',
                type: 'dateTime',
                sortable: false,
                width: 180
            }
        ],
        [renderCustomerViewCell, renderViewCell]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={TRANSACTIONS_PAGE_LIMIT}
                rowsPerPageOptions={[TRANSACTIONS_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    return (
        <MainLayout pageName="Transactions" pageDescription="View and interact with transactions">
            <Box paddingBottom={16} height="100%">
                <Box paddingBottom={1}>
                    <TransactionsFilters filter={filter} onChange={onFilterChange} />
                </Box>
                <MuiGrid
                    rows={formattedTransactions}
                    columns={columns}
                    disableMultipleSelection
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    components={{
                        Pagination
                    }}
                />
            </Box>
        </MainLayout>
    );
};

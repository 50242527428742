import { isArrayOf, isDefined, isNumber, isOptional, isString } from '../typeguards';

export interface IPageOptions {
    limit?: number;
    startKey?: string;
    sort?: string;
}

export interface IPageLinks {
    self: string;
    next?: string;
}

export function isPageLinks(links: any): links is IPageLinks {
    return isDefined(links) && isString(links.self) && isOptional(isString, links.next);
}

export interface IPagedCollection<T> {
    items: T[];
    page: {
        count?: number;
        limit: number;
        startKey?: string;
        nextKey?: string;
    };
    links?: IPageLinks;
}

export function isPagedCollection<T>(
    isT: (item: any) => item is T,
    resource: any
): resource is IPagedCollection<T> {
    return (
        isDefined(resource) &&
        isArrayOf(isT, resource.items) &&
        isDefined(resource.page) &&
        isOptional(isNumber, resource.page.count) &&
        isNumber(resource.page.limit) &&
        isOptional(isString, resource.page.startKey) &&
        isOptional(isString, resource.page.nextKey) &&
        isOptional(isPageLinks, resource.links)
    );
}

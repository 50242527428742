import { Crud, HttpClient, httpClient, RequestOptions } from 'lib/HttpClient';
import { ITagGroup } from 'components/location/models/LocationModel';

class LocationApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'locations', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }

    getTagGroups() {
        return this.httpClient.get(`${this.api}/locations/taggroups`);
    }

    deleteTagGroup(tagGroupId: string) {
        return this.httpClient.delete(`${this.api}/locations/taggroups/${tagGroupId}`, {
            skipResponseBody: true,
            skipStringify: true
        });
    }

    updateTagGroup(tagGroup: ITagGroup) {
        return this.httpClient.put(`${this.api}/locations/taggroups`, {
            body: tagGroup
        });
    }

    create(options: RequestOptions) {
        if (!options.headers) {
            options.headers = {};
        }
        options.headers['x-api-version'] = 6; // Override this header
        return this.httpClient.post(`${this.api}/locations`, options);
    }
    getListV9 = (options: RequestOptions) => {
        if (!options.headers) {
            options.headers = {};
        }
        options.headers['x-api-version'] = 9; // Override this header
        return this.getList(options);
    };
}

export const locationApi = new LocationApi(httpClient);

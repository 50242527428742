import React from 'react';
import { AutoFormFields } from 'lib/LegacyForm';

export const createLocationInAppLinksForm: () => AutoFormFields = () => ({
    facebook: {
        label: 'Facebook',
        description: (
            <React.Fragment>
                This is the ID of the Facebook page you want to link to the location. You can find it easily
                using <a href="https://findmyfbid.com/">https://findmyfbid.com/</a>.
            </React.Fragment>
        )
    },
    twitter: {
        label: 'Twitter',
        description: 'This is the username of the Twitter account you want to link to the location.'
    },
    instagram: {
        label: 'Instagram',
        description: 'This is the username of the Instagram page you want to link to the location.'
    },
    citymapper: {
        label: 'Citymapper',
        type: 'switch'
    },
    uber: {
        label: 'Uber',
        type: 'switch'
    }
});

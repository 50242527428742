import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useCurrencyString } from 'lib/hooks/useCurrencyString';

export interface IOrderSummaryItemModifier {
    id: string;
    title: string;
    cost?: number;
    deleted?: boolean;
}

export interface IOrderSummaryItem {
    id: string | number;
    quantity: number;
    title: string;
    cost: number;
    modifiers: IOrderSummaryItemModifier[];
    deleted?: boolean;
}

interface OrderSummaryItemProps {
    item: IOrderSummaryItem;
}

export const OrderSummaryItem: React.FC<OrderSummaryItemProps> = ({ item }) => {
    const getCurrencyString = useCurrencyString();
    const renderModifierOptions = React.useCallback(
        (modifier: IOrderSummaryItemModifier, index: number) => (
            <Grid
                container
                justifyContent="space-between"
                spacing={1}
                key={`${modifier.id}-${index}`}
                sx={{ textDecoration: modifier.deleted ? 'line-through' : undefined }}
            >
                <Grid item xs={9}>
                    <Box pl={3}>
                        <Typography variant="body2" sx={{ opacity: 0.7 }}>
                            {modifier.title}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography sx={{ opacity: 0.7 }} align="right" variant="body2">
                            {getCurrencyString(modifier.cost)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        ),
        [getCurrencyString]
    );
    return (
        <React.Fragment>
            <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ textDecoration: item.deleted ? 'line-through' : undefined }}
            >
                <Grid item xs={9}>
                    <Box>
                        <Typography>{`${item.quantity} x ${item.title}`}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography align="right">{getCurrencyString(item.cost)}</Typography>
                    </Box>
                </Grid>
            </Grid>
            {item.modifiers?.map(renderModifierOptions)}
        </React.Fragment>
    );
};

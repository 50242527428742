import React from 'react';
import { Box, Button, List, ListItem, ListItemProps, styled, TextField, Typography } from '@mui/material';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { useInputState } from 'lib/hooks/useInputState';

interface ILocationOption {
    id: string;
    title: string;
}

interface MenuLocationsListModalProps {
    open: boolean;
    onClose: () => void;
    locationOptions: ILocationOption[];
}

const StyledListItem = styled(({ className, ...props }: ListItemProps) => (
    <ListItem {...props} classes={{ root: className }} />
))(({ theme }) => ({
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
        borderBottom: 'none'
    }
}));

const MenuLocationsList: React.FC<MenuLocationsListModalProps> = props => {
    const { onClose, locationOptions } = props;
    const [search, onSearchChange] = useInputState('');
    const locationsSorted = React.useMemo(() => {
        if (locationOptions.length > 0) {
            return locationOptions
                .sort((a, b) => a.title.localeCompare(b.title))
                .filter(({ title }) => title.toLowerCase().includes(search.toLowerCase()));
        }

        return locationOptions;
    }, [locationOptions, search]);

    const renderListItem = React.useCallback(
        (item: ILocationOption) => (
            <StyledListItem key={item.id}>
                {item.title} ({item.id})
            </StyledListItem>
        ),
        []
    );

    return (
        <React.Fragment>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 2 }}>
                This menu is active at the following locations
            </Typography>
            <Box width="100%">
                <TextField
                    variant="outlined"
                    value={search}
                    onChange={onSearchChange}
                    fullWidth
                    label="Search locations"
                    size="small"
                />
                <List>
                    {locationsSorted.length > 0 ? (
                        locationsSorted.map(renderListItem)
                    ) : (
                        <Typography variant="body1">{`No locations found for "${search}"`}</Typography>
                    )}
                </List>
            </Box>
            <Row flex={1} align="flex-end">
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
            </Row>
        </React.Fragment>
    );
};

export const MenuLocationsListModal: React.FC<MenuLocationsListModalProps> = props => {
    const { open, onClose } = props;
    return (
        <MuiModal open={open} onClose={onClose}>
            <MenuLocationsList {...props} />
        </MuiModal>
    );
};

import { MuiModal } from 'ui/MuiModal';
import React from 'react';
import { ITag, ITagGroup } from 'components/location/models/LocationModel';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { TagFilter } from 'components/location/models/LocationTagModel';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router';

interface IProps {
    tagGroups?: ITagGroup[];
    open: boolean;
    onClose: () => void;
    onSubmit?: (tagFilter: TagFilter) => void;
    tagFilter: TagFilter;
    isLoading?: boolean;
    closeOnSubmit?: boolean;
    title?: string;
    resetOnClose?: boolean;
}

interface ICommonTagProps {
    onTagChange: (tagGroupId: string, tagId: string, checked: boolean) => void;
    tagFilter: TagFilter;
}

interface ITagGroupProps extends ICommonTagProps {
    tagGroup: ITagGroup;
}

interface ITagItemProps extends ICommonTagProps {
    groupId: string;
    tag: ITag;
}

const TagItem = ({ tag, onTagChange, groupId, tagFilter }: ITagItemProps) => {
    const handleCheckboxChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onTagChange(groupId, tag.id, event.target.checked);
        },
        [groupId, onTagChange, tag.id]
    );
    const checked = React.useMemo(() => {
        if (tagFilter[groupId]) {
            return tagFilter[groupId][tag.id] ?? false;
        }

        return false;
    }, [groupId, tag.id, tagFilter]);

    return (
        <Grid item xs={6}>
            <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
                label={tag.title}
            />
        </Grid>
    );
};

const TagGroup = ({ tagGroup: { tags, title, id }, onTagChange, tagFilter }: ITagGroupProps) => {
    const handleSelectAll = React.useCallback(() => {
        Object.values(tags).forEach(tag => {
            onTagChange(id, tag.id, true);
        });
    }, [id, onTagChange, tags]);

    const handleClearAll = React.useCallback(() => {
        Object.values(tags).forEach(tag => {
            onTagChange(id, tag.id, false);
        });
    }, [id, onTagChange, tags]);

    const [allTagsSelected, noTagsSelected] = React.useMemo(() => {
        const tagValues = Object.values(tags);

        return [
            tagValues.every(tag => tagFilter[id] && tagFilter[id][tag.id]),
            tagValues.every(tag => !(tagFilter[id] && tagFilter[id][tag.id]))
        ];
    }, [id, tagFilter, tags]);

    return (
        <Box sx={{ mt: 1.5 }}>
            <Box display="flex" flex={1} justifyContent="space-between" alignItems="center">
                <Typography fontWeight={700}>{title}</Typography>
                <Box>
                    <Button color="primary" disabled={allTagsSelected} onClick={handleSelectAll}>
                        ALL
                    </Button>
                    <Button sx={{ ml: 1 }} color="primary" disabled={noTagsSelected} onClick={handleClearAll}>
                        NONE
                    </Button>
                </Box>
            </Box>
            <Divider sx={{ my: 0.5 }} />
            <Grid container>
                {tags &&
                    Object.entries(tags).map(([tagId, tag]) => (
                        <TagItem
                            tagFilter={tagFilter}
                            groupId={id}
                            tag={tag}
                            onTagChange={onTagChange}
                            key={`${id}_${tagId}`}
                        />
                    ))}
            </Grid>
        </Box>
    );
};

export const TagGroupsSelectionModal = ({
    open,
    onClose,
    tagGroups,
    onSubmit,
    tagFilter: initialTagFilter,
    isLoading,
    closeOnSubmit,
    title = 'Filter Locations by Tags',
    resetOnClose
}: IProps) => {
    const { push } = useHistory();
    const [tagFilter, setTagFilter] = React.useState<TagFilter>(initialTagFilter);
    const handleTagFilterChange = React.useCallback((tagGroupId: string, tagId: string, checked: boolean) => {
        setTagFilter(oldFilter => ({
            ...oldFilter,
            [tagGroupId]: {
                ...oldFilter[tagGroupId],
                [tagId]: checked
            }
        }));
    }, []);
    const handleClose = React.useCallback(() => {
        onClose();
        if (resetOnClose) {
            setTagFilter(initialTagFilter);
        }
    }, [initialTagFilter, onClose, resetOnClose]);

    const handleSubmit = React.useCallback(() => {
        onSubmit(tagFilter);

        if (closeOnSubmit) {
            onClose();
        }
    }, [closeOnSubmit, onClose, onSubmit, tagFilter]);

    React.useEffect(() => {
        setTagFilter(initialTagFilter);
    }, [initialTagFilter]);

    const handleViewTags = React.useCallback(() => {
        push('/location-tags', { openCreateModal: true });
    }, [push]);

    let content;

    if (!tagGroups || !tagGroups.length) {
        content = (
            <Box>
                <Typography variant="h6" color="primary">
                    {title}
                </Typography>
                <Typography sx={{ my: 1 }}>No tag groups have been created.</Typography>
                <Typography>Please visit the Tags page to create and manage location tags.</Typography>

                <Box mt={1.5} display="flex" flex={1} justifyContent="flex-end">
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button sx={{ ml: 1 }} onClick={handleViewTags} variant="contained" color="primary">
                        View Tags
                    </Button>
                </Box>
            </Box>
        );
    } else {
        content = (
            <Box>
                <Typography variant="h6" color="primary">
                    {title}
                </Typography>
                {tagGroups.map(tagGroup => (
                    <TagGroup
                        tagFilter={tagFilter}
                        onTagChange={handleTagFilterChange}
                        tagGroup={tagGroup}
                        key={tagGroup.id}
                    />
                ))}

                <Box mt={1.5} display="flex" flex={1} justifyContent="flex-end">
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        sx={{ ml: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Confirm
                    </LoadingButton>
                </Box>
            </Box>
        );
    }

    return !tagGroups ? null : (
        <MuiModal open={open} onClose={handleClose}>
            {content}
        </MuiModal>
    );
};

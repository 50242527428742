import { ActionType } from 'components/actions/models/Action';
import { EPrimaryPlatform } from 'components/customers/models/Customer';

// percentage expressed as integer e.g 10% is value: 10
export type UserMetricListenerCondition = {
    _id: string;
} & (
    | {
          metricId: string;
          operator:
              | 'EQUAL'
              | 'NOT_EQUAL'
              | 'GREATER_THAN'
              | 'LESS_THAN'
              | 'IS_WITHIN_TOP_PERCENTAGE'
              | 'IS_NOT_WITHIN_TOP_PERCENTAGE';
          value: number;
      }
    | {
          userProperty: 'hasAgreedToReceiveMarketing' | 'hasAgreedToShareData';
          operator: 'EQUAL' | 'NOT_EQUAL';
          value: boolean;
      }
    | {
          userProperty: 'primaryPlatform';
          operator: 'EQUAL' | 'NOT_EQUAL';
          value: EPrimaryPlatform;
      }
    | {
          userProperty: 'birthdate';
          operator: 'EQUAL' | 'NOT_EQUAL' | 'GREATER_THAN' | 'LESS_THAN';
          value: Date;
      }
    | {
          userProperty: 'gender';
          operator: 'EQUAL' | 'NOT_EQUAL';
          value: 'MALE' | 'FEMALE';
      }
    | {
          userProperty: 'favouriteLocations';
          operator: 'CONTAINS' | 'NOT_CONTAINS';
          value: string;
      }
    | {
          operator: 'IS_IN_SEGMENT' | 'IS_NOT_IN_SEGMENT';
          value: string;
      }
);

export enum EMetricType {
    Spend = 'SPEND',
    PointsEarned = 'POINTS_EARNED',
    PointsBurned = 'POINTS_BURNED',
    TipsGiven = 'TIPS_GIVEN',
    ActionType = 'ACTION_TYPE'
}

export type UserMetric = (
    | {
          type: EMetricType.TipsGiven | ActionType;
      }
    | {
          type: EMetricType.Spend;
          includeTips?: boolean;
      }
    | {
          type: EMetricType.PointsEarned | EMetricType.PointsBurned;
          perkId: string;
      }
) & {
    name: string;
    displayName: string;
    timeRange:
        | {
              windowStartOffsetMins: number;
              windowEndOffsetMins?: number;
              gt?: Date;
              lt?: Date;
          }
        | {
              gt: Date;
              lt?: Date;
          };
};

export interface UserMetricResource {
    timeRange: {
        windowStartOffsetMins?: number;
        windowEndOffsetMins?: number;
        gt?: Date;
        lt?: Date;
    };
    name: string;
    displayName: string;
    includeTips?: boolean;
    perkId?: string;
    type: string;
}

// In order for listener to work we still need at least one defined metric
export type UserMetricListener = {
    _id: string;
    conditions?: UserMetricListenerCondition[];
} & (
    | {
          type: 'ADD_TO_SEGMENT' | 'REMOVE_FROM_SEGMENT';
          segmentId: string;
      }
    | {
          type: 'TRIGGER_NOTIFICATION';
          message: string;
          isProcessingMessage: boolean;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
    | {
          type: 'TRIGGER_SMS';
          message: string;
          isProcessingMessage: boolean;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
    | {
          type: 'TRIGGER_EMAIL';
          subject: string;
          body: string;
          bodyMarkupLanguage?: 'HTML' | 'MJML';
          isProcessingMessage: boolean;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
    | {
          type: 'GRANT_PERK' | 'REVOKE_PERK';
          perkId: string;
          points?: number;
          counter: {
              max: number;
              resetsAfterMins?: number;
          };
      }
);

export interface UserMetricDefinition {
    _id: string;
    tenantId: string;
    scheduled: boolean;
    realtime: boolean;
    name: string;
    displayName: string;
    metrics: { [id: string]: UserMetric };
    listeners: UserMetricListener[];
    type?: 'TIERED_LOYALTY' | 'OTHER';
    updatedAt?: string;
}

import { ExpiringClaims } from '@pepperhq/auth-client';
import { createReducer } from 'redux-create-reducer';
import { Tenant } from 'components/merchant/models/Tenant';
import { AuthorizeAction, SignInAction } from './authActions';
import actionTypes from './authActionTypes';

export interface FirebaseIdentifiers {
    email?: string;
    name?: string;
}

export interface AuthState {
    claims?: ExpiringClaims;
    authorizedDate?: Date;
    tenant?: Tenant;
    error?: string;
    identifiers: FirebaseIdentifiers;
    isAuthorizingInProgress: boolean;
    isLegacyLoggedIn?: boolean;
    isLegacyLoading?: boolean;
}

type AuthActionHandlers = {
    [key in actionTypes]: (state: AuthState, action?: AuthorizeAction & SignInAction) => AuthState;
};

export const initialState: AuthState = {
    identifiers: { email: undefined, name: undefined },
    isAuthorizingInProgress: false,
    isLegacyLoading: true,
    isLegacyLoggedIn: false
};

const actionHandlers: AuthActionHandlers = {
    [actionTypes.SIGN_IN_START]: state => ({ ...state, error: null, isAuthorizingInProgress: false }),
    [actionTypes.SIGN_IN_SUCCESS]: state => ({ ...state, error: null, isAuthorizingInProgress: true }),
    [actionTypes.GET_ACCESS_SUCCESS]: state => ({ ...state, error: null, isAuthorizingInProgress: false }),
    [actionTypes.GET_ACCESS_FAIL]: (state, action) => ({
        ...state,
        error: action.error,
        isAuthorizingInProgress: false
    }),
    [actionTypes.SIGN_IN_FAIL]: (state, action) => ({
        ...state,
        error: action.error,
        isAuthorizingInProgress: false
    }),
    [actionTypes.AUTHORIZE_SUCCESS]: (state, { claims, tenant, identifiers }) => ({
        ...state,
        claims,
        tenant,
        identifiers,
        isAuthorizingInProgress: false,
        authorizedDate: new Date()
    }),
    [actionTypes.AUTHORIZE_FAIL]: (state, action) => ({
        ...state,
        error: action.error,
        claims: null,
        authorizedDate: undefined,
        isAuthorizingInProgress: false
    }),
    [actionTypes.SIGN_OUT]: state => ({
        ...state,
        claims: null,
        authorizedDate: undefined,
        isAuthorizingInProgress: false
    }),
    [actionTypes.UNAUTHORIZE]: state => ({
        ...state,
        claims: null,
        authorizedDate: undefined,
        isAuthorizingInProgress: false
    }),
    [actionTypes.LEGACY_AUTHORIZE_START]: state => ({
        ...state,
        isLegacyLoading: true,
        isLegacyLoggedIn: false
    }),
    [actionTypes.LEGACY_AUTHORIZE_ERROR]: state => ({
        ...state,
        isLegacyLoading: false,
        isLegacyLoggedIn: false
    }),
    [actionTypes.LEGACY_AUTHORIZE_SUCCESS]: state => ({
        ...state,
        isLegacyLoading: false,
        isLegacyLoggedIn: true
    })
};

export default createReducer(initialState, actionHandlers);

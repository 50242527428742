import { OrderScenario } from '@pepperhq/menu-sdk';

export enum MessageItem {
    MESSAGE = 'MESSAGE'
}

export enum PresetAction {
    AWARDS = 'AWARDS',
    CHECKIN = 'CHECKIN',
    PREORDER = 'PREORDER',
    LOCATIONS = 'LOCATIONS',
    FAVOURITES = 'FAVOURITES',
    REFERRALS = 'REFERRALS',
    VOUCHER = 'VOUCHER',
    SECRET_DJ = 'SECRET_DJ',
    TABS = 'TABS',
    BARCODE = 'BARCODE'
}

export enum MessageAction {
    BROWSER = 'BROWSER',
    LOCATION = 'LOCATION',
    NATIVE = 'NATIVE',
    CUSTOM = 'CUSTOM',
    BARCODE = 'BARCODE',
    START_ORDER = 'START_ORDER',
    TABLE_ORDER = 'TABLE_ORDER',
    PAY_AT_TABLE = 'PAY_AT_TABLE',
    PREORDER = 'PREORDER',
    VOUCHER = 'VOUCHER',
    REFERRALS = 'REFERRALS',
    USER_DETAILS = 'USER_DETAILS',
    USER_PREFERENCES = 'USER_PREFERENCES',
    CHECKIN = 'CHECKIN'
}

export enum ContentModuleType {
    PRIMARY_ACTION = 'PRIMARY_ACTION',
    MARKETING = 'MARKETING',
    LOCATIONS = 'LOCATIONS',
    FAVOURITES = 'FAVOURITES',
    AWARDS = 'AWARDS',
    SECRET_DJ = 'SECRET_DJ',
    LOYALTY_BALANCE = 'LOYALTY_BALANCE'
}

interface IIndexableItem {
    index: number;
}

interface IMessageItem extends IFilterableItem, IIndexableItem {
    type: MessageItem;
}

export interface IPresetMessageItem extends IMessageItem {
    action: PresetAction;
    name: string;
    properties: {
        imageUrl: string;
        // overlay text properties
        title?: string;
        subtitle?: string;
        subtitleTwo?: string;
        invertTextColour?: boolean;
        showTextBackgroundBar?: boolean;
    };
}

interface ICustomMessageItem extends IMessageItem {
    action: MessageAction.CUSTOM;
    name: string;
    properties: {
        url: string;
        // no overlay text properties - all content comes from URL
    };
}

export interface IBrowserMessageItem extends IMessageItem {
    action: MessageAction.BROWSER;
    name: string;
    properties: {
        url: string;
        imageUrl: string;
        requiresLocationSelection?: boolean;
        // overlay text properties
        title?: string;
        subtitle?: string;
        subtitleTwo?: string;
        invertTextColour?: boolean;
        showTextBackgroundBar?: boolean;
    };
}

export interface ILocationMessageItem extends IMessageItem {
    action: MessageAction.LOCATION;
    name: string;
    properties: {
        locationId: string;
        imageUrl: string;
        // overlay text properties
        title?: string;
        subtitle?: string;
        subtitleTwo?: string;
        invertTextColour?: boolean;
        showTextBackgroundBar?: boolean;
    };
}

export interface INativeMessageItem extends IMessageItem {
    action: MessageAction.NATIVE;
    name: string;
    properties: {
        imageUrl: string;
        // overlay text properties
        title?: string;
        subtitle?: string;
        subtitleTwo?: string;
        invertTextColour?: boolean;
        showTextBackgroundBar?: boolean;
        content: {
            imageUrl: string;
            pageTitle: string;
            title: string;
            subtitle: string;
            body: string;
            buttonTitle: string;
            buttonUrl: string;
        };
    };
}

export enum EStartOrderDestination {
    MENU = 'MENU',
    PRODUCT = 'PRODUCT'
}

export interface IStartOrderMessageItem extends IMessageItem {
    action: MessageAction.START_ORDER;
    name: string;
    properties: {
        imageUrl: string;
        selectableScenarios: OrderScenario[];
        focus: {
            type: EStartOrderDestination;
            properties?: {
                categoryId: string;
                productId: string;
            };
        };
        selectableLocations?: {
            tags?: string[];
        };
    };
}

export interface IDefaultMessageItem extends IMessageItem {
    action: MessageAction.BARCODE;
    name: string;
    properties: {
        locationId: string;
        imageUrl: string;
        title?: string;
        subtitle?: string;
        subtitleTwo?: string;
        invertTextColour?: boolean;
        showTextBackgroundBar?: boolean;
    };
}

export interface IItemContext {
    visibleFrom?: Date;
    visibleTo?: Date;
    locationIds?: string[];
    segmentIds?: string[];
    authenticated?: boolean;
    unauthenticated?: boolean;
    excludingSegmentIds?: string[];
}

interface IFilterableItem {
    context: IItemContext;
}

export enum Height {
    SINGLE = 'SINGLE',
    DOUBLE = 'DOUBLE'
}

export enum EItemShape {
    RECTANGLE = 'RECTANGLE',
    SQUARE = 'SQUARE'
}

export enum SecondaryAction {
    BARCODE = 'BARCODE',
    LOCATIONS = 'LOCATIONS',
    FAVOURITES = 'FAVOURITES'
}

export interface IDisplay {
    height?: Height | EHeightOption;
    itemShape?: EItemShape;
    itemsOnScreen?: number;
}

export enum EHeightOption {
    SINGLE_HEIGHT_RECTANGLE = 'SINGLE_HEIGHT_RECTANGLE',
    DOUBLE_HEIGHT_RECTANGLE = 'DOUBLE_HEIGHT_RECTANGLE',
    SMALL_SQUARE = 'SMALL_SQUARE',
    LARGE_SQUARE = 'LARGE_SQUARE'
}

export interface ISecondaryAction {
    title: string;
    action: SecondaryAction;
}

export type PrimaryActionType =
    | 'AWARDS'
    | 'CHECKIN'
    | 'PREORDER'
    | 'LOCATIONS'
    | 'FAVOURITES'
    | 'START_ORDER'
    | 'TABLE_ORDER'
    | 'PAY_AT_TABLE'
    | 'VOUCHER'
    | 'REFERRALS'
    | 'BARCODE'
    | 'USER_DETAILS'
    | 'USER_PREFERENCES'
    | 'TABS'
    | 'BOOK_TABLE'
    | 'BOOK_ROOM'
    | 'BROWSER';

export interface PrimaryActionItem {
    title: string;
    type: PrimaryActionType;
    name: string;
    context?: Omit<IItemContext, 'visibleTo' | 'visibleFrom'>;
    properties?: {
        useCheckinLocation?: boolean;
        url?: string;
    };
    index: number;
}
interface IFilterableItemsModule {
    items: IFilterableItem[];
}

interface IBaseModule {
    id: string;
    type: ContentModuleType;
    index: number;
    title?: string;
    secondaryAction?: ISecondaryAction;
    display?: IDisplay;
}

export type MarketingMessageItem =
    | IPresetMessageItem
    | IBrowserMessageItem
    | ILocationMessageItem
    | INativeMessageItem
    | ICustomMessageItem
    | IStartOrderMessageItem
    | IDefaultMessageItem;
interface IFilterableItemsModule {
    items: IFilterableItem[];
}
export interface IPrimaryAction extends IBaseModule {
    type: ContentModuleType.PRIMARY_ACTION;
    items: PrimaryActionItem[];
}

export interface IAwardsModule extends IBaseModule {
    type: ContentModuleType.AWARDS;
}

export interface ILocationsModule extends IBaseModule {
    type: ContentModuleType.LOCATIONS;
    properties: {
        defaultRegion: {
            latitude: number;
            longitude: number;
            width: number;
        };
    };
}

export interface IFavouritesModule extends IBaseModule {
    type: ContentModuleType.FAVOURITES;
}

export interface IMarketingModule extends IBaseModule, IFilterableItemsModule {
    type: ContentModuleType.MARKETING;
    items: MarketingMessageItem[];
}

export type IMarketingAction =
    | PresetAction
    | MessageAction.BROWSER
    | MessageAction.LOCATION
    | MessageAction.NATIVE
    | MessageAction.CUSTOM
    | MessageAction.START_ORDER
    | MessageAction.BARCODE;

export type ContentModule =
    | IMarketingModule
    | IFavouritesModule
    | ILocationsModule
    | IAwardsModule
    | IPrimaryAction;

function isBrowserMessage(message: any): message is IBrowserMessageItem {
    return !!message && message.action === MessageAction.BROWSER;
}
function isLocationMessage(message: any): message is ILocationMessageItem {
    return !!message && message.action === MessageAction.LOCATION;
}
function isNativeMessage(message: any): message is INativeMessageItem {
    return !!message && message.action === MessageAction.NATIVE;
}
function isTableOrderMessage(message: any): message is INativeMessageItem {
    return !!message && message.action === MessageAction.TABLE_ORDER;
}
function isCustomMessage(message: any): message is ICustomMessageItem {
    return !!message && message.action === MessageAction.CUSTOM;
}
function isPresetMessage(message: any): message is IPresetMessageItem {
    return !!message && Object.values(PresetAction).includes(message.action);
}
function isStartOrderMessage(message: any): message is IStartOrderMessageItem {
    return !!message && message.action === MessageAction.START_ORDER;
}
const defaultMessages = [
    MessageAction.PREORDER,
    MessageAction.PAY_AT_TABLE,
    MessageAction.VOUCHER,
    MessageAction.REFERRALS,
    MessageAction.USER_DETAILS,
    MessageAction.CHECKIN,
    MessageAction.USER_PREFERENCES,
    MessageAction.BARCODE
];

function isDefaultMarketingMessage(message: any): message is IDefaultMessageItem {
    return !!message && defaultMessages.includes(message.action);
}

export function isLocationsModule(module: any): module is ILocationsModule {
    const defaultRegionProperties: string[] = ['latitude', 'longitude', 'width'];
    return (
        !!module &&
        !!module.properties &&
        !!module.properties.defaultRegion &&
        defaultRegionProperties.reduce<boolean>((i, j) => i && j in module.properties.defaultRegion, true)
    );
}

export function displayToMarketingHeight(display?: IDisplay) {
    if (display?.itemShape) {
        if (display.itemShape === EItemShape.RECTANGLE) {
            return display.height === Height.DOUBLE
                ? EHeightOption.DOUBLE_HEIGHT_RECTANGLE
                : EHeightOption.SINGLE_HEIGHT_RECTANGLE;
        }
        return display.itemsOnScreen === 3 ? EHeightOption.SMALL_SQUARE : EHeightOption.LARGE_SQUARE;
    }

    return display ? display.height : EHeightOption.SINGLE_HEIGHT_RECTANGLE;
}

export const marketingHeightToDisplay = (display?: IDisplay) => {
    switch (display?.height) {
        case EHeightOption.SINGLE_HEIGHT_RECTANGLE: {
            return {
                itemShape: EItemShape.RECTANGLE,
                height: Height.SINGLE
            };
        }
        case EHeightOption.DOUBLE_HEIGHT_RECTANGLE: {
            return {
                itemShape: EItemShape.RECTANGLE,
                height: Height.DOUBLE
            };
        }
        case EHeightOption.SMALL_SQUARE: {
            return {
                itemShape: EItemShape.SQUARE,
                itemsOnScreen: 3
            };
        }
        case EHeightOption.LARGE_SQUARE: {
            return {
                itemShape: EItemShape.SQUARE,
                itemsOnScreen: 2
            };
        }
        default: {
            return { height: display?.height };
        }
    }
};

const checkDisplayEquality = (shape: EHeightOption, display?: IDisplay) => {
    const convertedDisplayHeight = displayToMarketingHeight(display);

    return convertedDisplayHeight === shape || display?.height === shape;
};

export const isSquaredDisplay = (display?: IDisplay) => {
    const convertedDisplayHeight = displayToMarketingHeight(display);
    const squaredHeightValues: (EHeightOption | Height)[] = [
        EHeightOption.SMALL_SQUARE,
        EHeightOption.LARGE_SQUARE
    ];
    if (display?.height && squaredHeightValues.includes(display?.height)) {
        return true;
    }
    return squaredHeightValues.includes(convertedDisplayHeight);
};

export const isSmallSquareDisplay = (display?: IDisplay) =>
    checkDisplayEquality(EHeightOption.SMALL_SQUARE, display);

export const isLargeSquareDisplay = (display?: IDisplay) =>
    checkDisplayEquality(EHeightOption.LARGE_SQUARE, display);

export const isSingleHeightDisplay = (display?: IDisplay) =>
    checkDisplayEquality(EHeightOption.SINGLE_HEIGHT_RECTANGLE, display);

export const isDoubleHeightDisplay = (display?: IDisplay) =>
    checkDisplayEquality(EHeightOption.DOUBLE_HEIGHT_RECTANGLE, display);

export const marketingTypeguards = {
    isBrowserMessage,
    isLocationMessage,
    isNativeMessage,
    isCustomMessage,
    isPresetMessage,
    isStartOrderMessage,
    isTableOrderMessage,
    isDefaultMarketingMessage
};

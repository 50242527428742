import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { LocationScopedSettings } from 'components/settings/LocationScopedSettings';
import { PaymentsSettings } from 'components/settings/PaymentsSettings';
import { MERCHANT_MOBILE_SETTINGS } from 'config/routes';

export const PaymentsSettingsPage: React.FC = () => (
    <MainLayout
        pageName="Payment Settings"
        pageDescription="Adjust your payment settings."
        breadcrumbs={[{ label: 'Settings', url: MERCHANT_MOBILE_SETTINGS }]}
    >
        <LocationScopedSettings globalComponent={PaymentsSettings} scopedComponent={PaymentsSettings} />
    </MainLayout>
);

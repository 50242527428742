import React from 'react';
import { RequiredStar } from './RequiredStar';

interface MuiFormLabelProps {
    children: React.ReactNode;
    required?: boolean;
    variant?: 'standard' | 'secondary';
}

export const MuiFormLabel: React.SFC<MuiFormLabelProps> = ({ required, children, variant }) => (
    <>
        {children}
        {required && <RequiredStar variant={variant} />}
    </>
);

import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

const TRANSITION_PARTS = 60;

const SmoothCircularProgress: React.FC<CircularProgressProps & { transitionSpeed: number }> = ({
    value,
    variant,
    transitionSpeed
}) => {
    const [animatedProgress, setAnimatedProgress] = React.useState(value);
    const [stepsLeft, setStepsLeft] = React.useState([value, 0]);
    React.useEffect(() => {
        setStepsLeft([value, TRANSITION_PARTS - 1]);
    }, [value]);
    React.useEffect(() => {
        const [currentValue, steps] = stepsLeft;
        if (steps && animatedProgress !== currentValue) {
            const timeout = setTimeout(() => {
                setAnimatedProgress(animatedProgress + (currentValue - animatedProgress) / steps);
                setStepsLeft([currentValue, steps - 1]);
            }, transitionSpeed / TRANSITION_PARTS);
            return () => clearTimeout(timeout);
        }
    }, [stepsLeft, transitionSpeed, animatedProgress]);
    return <CircularProgress variant={variant} color="primary" value={animatedProgress} />;
};

interface MuiCircularProgressProps {
    isError?: boolean;
    errorComponent?: React.ReactNode;
    progress?: number;
    children: React.ReactNode;
    transitionSpeed?: number;
}

export const MuiCircularProgress: React.FC<MuiCircularProgressProps> = ({
    errorComponent,
    children,
    progress = -1,
    isError,
    transitionSpeed = 1500
}) => {
    const [showSuccess, setShowSuccess] = React.useState(progress >= 100);
    const variant = React.useMemo(() => (progress < 0 ? 'indeterminate' : 'determinate'), [progress]);
    React.useEffect(() => {
        if (!showSuccess && progress >= 100) {
            const timeout = setTimeout(() => {
                setShowSuccess(true);
            }, transitionSpeed);
            return () => clearTimeout(timeout);
        }
    }, [showSuccess, progress, transitionSpeed]);
    if (isError) {
        return <div>{errorComponent}</div>;
    }
    if (showSuccess && progress >= 100 && React.isValidElement(children)) {
        return children;
    }
    return (
        <Box position="relative">
            <Box
                width="100%"
                height="100%"
                position="absolute"
                top="0"
                left="0"
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
            >
                <CircularProgress sx={{ opacity: '20%' }} color="primary" variant="determinate" value={100} />
            </Box>
            <SmoothCircularProgress
                variant={variant}
                color="primary"
                value={progress}
                transitionSpeed={transitionSpeed}
            />
        </Box>
    );
};

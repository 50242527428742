import { hexToRgb } from '@mui/material';
import { AwardTemplate } from 'components/customers/models/Award';
import { LoyaltyProvider } from 'components/loyalty/enums';
import { IAwardEnrichment, IAwardEnrichmentCreate } from 'components/loyalty/models/AwardEnrichment';
import { differenceInSeconds, setHours, setMinutes, setSeconds, startOfDay } from 'date-fns';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { getTimezoneIrrelevantDateTime, rgbaToHex } from 'lib/helpers';
import { getPerkOpacity } from '../helpers';
import { weekdays } from './consts';
import { AwardConfigureFormData } from './forms/AwardConfigureForm';
import { AwardGeneralFormData } from './forms/AwardGeneralForm';
import { awardRedemptionDefaultFormValues, AwardRedemptionFormData } from './forms/AwardRedemptionForm';
import { isBoolean, isDefined } from 'lib/typeguards';

export const getDifferenceFromMidnightInMinutes = (time: string): number | undefined => {
    if (time) {
        const [hours, minutes] = time.split(':');

        if (hours && minutes) {
            const dateToCompare = setSeconds(
                setMinutes(setHours(new Date(), Number(hours)), Number(minutes)),
                0
            );
            const midnight = startOfDay(new Date());

            return differenceInSeconds(dateToCompare, midnight);
        }

        return 0;
    }

    return undefined;
};

export const convertSecondsToTime = (seconds: number) => {
    let hours: number | string = Math.floor(seconds / 3600);
    let minutes: number | string = Math.floor((seconds - hours * 3600) / 60);

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return hours + ':' + minutes;
};

const generateCreatePseudoAwardVisualizationBody = (settings: AwardConfigureFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);

    return {
        visualisation: {
            subtitleTwoText: 'Worth {{currency}}',
            textAlignment: settings.align,
            subtitleText: '{{available}} point{{s}}',
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: settings.titleText
        }
    };
};

const generateCreateAdHocVisualizationBody = (settings: AwardConfigureFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);
    return {
        visualisation: {
            subtitleTwoText: settings.subtitle2,
            textAlignment: settings.align,
            subtitleText: settings.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: settings.titleText
        }
    };
};

const generateCreateClubVisualizationBody = (settings: AwardConfigureFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);

    return {
        visualisation: {
            subtitleTwoText: settings.subtitle2,
            textAlignment: settings.align,
            subtitleText: settings.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: settings.titleText
        }
    };
};

const generateCreateStampCardVisualizationBody = (settings: AwardConfigureFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);
    return {
        visualisation: {
            textAlignment: settings.align,
            randomiseStampPositions: settings.randomizeStampCard,
            stampImageUrl: settings.stampImageUrl,
            stampColour: rgbaToHex(settings.stampColor, true),
            subtitleText: settings.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            availableCardCopy: settings.availableCardCopy,
            nextCardProgressCopy: settings.nextCardProgressCopy,
            titleText: settings.titleText
        }
    };
};

export const generateCreateVisualizationBody = (settings: AwardConfigureFormData) => {
    if (settings.perkType === AwardTemplate.PSEUDO_CURRENCY) {
        return generateCreatePseudoAwardVisualizationBody(settings);
    }
    if (settings.perkType === AwardTemplate.STAMP_CARD) {
        return generateCreateStampCardVisualizationBody(settings);
    }
    if (settings.perkType === AwardTemplate.CLUB) {
        return generateCreateClubVisualizationBody(settings);
    }
    return generateCreateAdHocVisualizationBody(settings);
};

export const generateAwardConfigureFormData = (award: IAwardEnrichment): AwardConfigureFormData => {
    const colorArray =
        award.visualisation.backgroundOverlayColour &&
        hexToRgb(award.visualisation.backgroundOverlayColour).match(/\d+/g);
    const [r, g, b] = Array.isArray(colorArray) ? colorArray : [255, 255, 255];
    const rgba: RGBAValue = {
        r: Number(r),
        g: Number(g),
        b: Number(b),
        a: Number(award.visualisation.backgroundOverlayOpacity) || 0
    };

    return {
        terms: award.visualisation.terms,
        perkType: award.template || AwardTemplate.AD_HOC,
        textColor: award.visualisation.textColour,
        image: award.visualisation.backgroundImageUrl,
        stampImageUrl: award.visualisation.stampImageUrl,
        stampColor: award.visualisation.stampColour,
        backgroundColor: rgbaToHex(rgba),
        align: award.visualisation.textAlignment,
        valign: award.visualisation.verticalAlignment,
        titleText: award.visualisation.titleText,
        subtitle: award.visualisation.subtitleText,
        subtitle2: award.visualisation.subtitleTwoText,
        randomizeStampCard: award.visualisation.randomiseStampPositions,
        availableCardCopy: award.visualisation.availableCardCopy,
        nextCardProgressCopy: award.visualisation.nextCardProgressCopy,
        auto: award.auto,
        multi: !award.points?.redemption
    };
};

export const generateAwardRedemptionFormData = (
    award: Omit<IAwardEnrichment, 'loyaltyRewardId' | 'loyaltyProvider'>
): AwardRedemptionFormData => {
    let weekDayValues: string[] = [];
    if (award.dateTimeRestriction?.daysOfWeek) {
        weekDayValues = weekdays
            .filter(item => {
                const itemValue = Number(item.value);
                // eslint-disable-next-line no-bitwise
                return itemValue === (itemValue & award.dateTimeRestriction?.daysOfWeek);
            })
            .map(item => item.value);
    }

    return {
        token: award.token,
        discount: award.discount
            ? {
                  ...award.discount
              }
            : awardRedemptionDefaultFormValues.discount,
        basketQualification: award.basketQualification
            ? {
                  ...award.basketQualification
              }
            : awardRedemptionDefaultFormValues.basketQualification,
        dateTimeRestriction: award.dateTimeRestriction
            ? {
                  ...award.dateTimeRestriction,
                  startTime: award.dateTimeRestriction.startTime
                      ? convertSecondsToTime(award.dateTimeRestriction.startTime)
                      : undefined,
                  endTime: award.dateTimeRestriction.endTime
                      ? convertSecondsToTime(award.dateTimeRestriction.endTime)
                      : undefined,
                  startDate: award.dateTimeRestriction.startDate?.toString(),
                  endDate: award.dateTimeRestriction.endDate?.toString(),
                  daysOfWeek: weekDayValues
              }
            : awardRedemptionDefaultFormValues.dateTimeRestriction
    };
};

export const getAwardEnrichmentCreateData = (
    {
        basketQualification,
        discount,
        dateTimeRestriction,
        ...data
    }: AwardGeneralFormData & AwardConfigureFormData & AwardRedemptionFormData,
    loyaltyProvider: LoyaltyProvider
): IAwardEnrichmentCreate => {
    const weekDays = dateTimeRestriction.daysOfWeek?.length
        ? dateTimeRestriction.daysOfWeek.reduce((acc, weekday) => {
              acc += Number(weekday);

              return acc;
          }, 0)
        : undefined;
    const hasDiscountFields = !!Object.values(discount).filter(Boolean).length;
    const hasBasketQualificationFields = !!Object.values(basketQualification).filter(item => isDefined(item))
        .length;
    // Weird date time manipulations to ignore the timezone
    let { startDate, endDate } = dateTimeRestriction;
    if (startDate) {
        startDate = getTimezoneIrrelevantDateTime(startDate).toUTCString();
    }
    if (endDate) {
        endDate = getTimezoneIrrelevantDateTime(endDate).toUTCString();
    }

    const body: IAwardEnrichmentCreate = {
        loyaltyProvider,
        loyaltyRewardId: data.loyaltyRewardId,
        title: data.title || undefined,
        token: data.token,
        template: data.perkType,
        auto: isBoolean(data.auto) ? data.auto : undefined,
        basketQualification: hasBasketQualificationFields
            ? {
                  matchCode: basketQualification.matchCode || undefined,
                  minimumItems: basketQualification.minimumItems || undefined,
                  minimumValue: basketQualification.minimumValue || undefined,
                  scope: basketQualification.scope
              }
            : undefined,
        discount: hasDiscountFields
            ? {
                  value: discount.value ? Number(discount.value) : undefined,
                  scope: discount.scope,
                  type: discount.type,
                  scopeMatchCode: discount.scopeMatchCode,
                  rule: discount.rule,
                  childItemRule: discount.childItemRule
              }
            : undefined,
        dateTimeRestriction: {
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            startTime: getDifferenceFromMidnightInMinutes(dateTimeRestriction.startTime),
            endTime: getDifferenceFromMidnightInMinutes(dateTimeRestriction.endTime),
            daysOfWeek: weekDays
        },
        visualisation: {
            description: data.description || undefined,
            terms: data.terms || undefined,
            priority: data.order ? Number(data.order) : undefined,
            ...generateCreateVisualizationBody(data).visualisation
        }
    };
    if (data.perkType === AwardTemplate.AD_HOC || data.perkType === AwardTemplate.CLUB) {
        body.points = {
            ...(!!data.multi
                ? {
                      initial: 1,
                      min: 1,
                      max: 1,
                      step: 0,
                      redemption: 0
                  }
                : {
                      initial: 1,
                      min: 0,
                      max: 1,
                      step: 1,
                      redemption: 1
                  })
        };
    }
    return body;
};

import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { UserMetricDefinition } from '../model';
import { LoadingTypography } from 'lib/LoadingTypoghraphy';
import { ELoyaltyTierState, METRICS_HEIGHT } from '../model/loyalty-tier';
import { titleize } from 'lib/helpers';
import { Row } from 'ui/Flex';

interface TieredLoyaltyOverviewProps {
    metricDefinition?: UserMetricDefinition;
    loading?: boolean;
    readyToActivate?: boolean;
    onEdit: () => void;
    onStateChange: () => void;
    state?: ELoyaltyTierState;
}

export const TieredLoyaltyOverview: React.FC<TieredLoyaltyOverviewProps> = ({
    metricDefinition,
    loading,
    readyToActivate,
    onEdit,
    onStateChange,
    state
}) => {
    const showSkeleton = loading;

    return (
        <Paper sx={{ p: 2, pt: 1, height: METRICS_HEIGHT }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Row align="space-between">
                        <Typography variant="h6">Overview</Typography>
                        {!!metricDefinition && (
                            <Row gutter>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        variant={
                                            state === ELoyaltyTierState.INACTIVE ? 'contained' : 'outlined'
                                        }
                                        size="small"
                                        disabled={!readyToActivate || loading}
                                        onClick={onStateChange}
                                    >
                                        {state === ELoyaltyTierState.INACTIVE ? 'Activate' : 'Change state'}
                                    </Button>
                                    {!readyToActivate && (
                                        <Tooltip title="In order to activate, please make sure you have at least one tier setup.">
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={loading}
                                    onClick={onEdit}
                                >
                                    Edit
                                </Button>
                            </Row>
                        )}
                        {!metricDefinition && (
                            <Button size="small" variant="contained" disabled={loading} onClick={onEdit}>
                                Create
                            </Button>
                        )}
                    </Row>
                </Grid>
                {!loading && !metricDefinition && (
                    <Grid item xs={12}>
                        <Typography variant="body2">No metric definition title</Typography>
                        <LoadingTypography loading={showSkeleton}>
                            No metric definition content
                        </LoadingTypography>
                    </Grid>
                )}
                {!loading && metricDefinition && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="body2">Name</Typography>
                            <LoadingTypography loading={showSkeleton}>
                                {metricDefinition?.name}
                            </LoadingTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">State</Typography>
                            <LoadingTypography loading={showSkeleton}>{titleize(state)}</LoadingTypography>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    );
};

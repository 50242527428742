import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { Option } from 'lib/types';
import { removeArrayItem } from './helpers';

interface ButtonGroupInputProps {
    options: Option[];
    value: string | string[];
    name: string;
    disabled?: boolean;
    multi?: boolean;
    onChange: (e: { target: { name: string; value: string | string[] } }) => void;
}

export const ButtonGroupInput: React.FC<ButtonGroupInputProps> = props => {
    const { options, value, name, multi, onChange, disabled } = props;

    const getNewMultiValue = (itemValue: string) => {
        if (!Array.isArray(value)) {
            return [itemValue];
        }
        const index = value.indexOf(itemValue);
        if (index < 0) {
            return [...value, itemValue];
        }
        return removeArrayItem(value, index);
    };
    const handleClick = (itemValue: string) => () => {
        const newValue = multi ? getNewMultiValue(itemValue) : itemValue;
        onChange({ target: { name, value: newValue } });
    };
    return (
        <ButtonGroup color="primary" sx={{ display: 'flex' }}>
            {!!options &&
                options.map(item => {
                    const isSelected =
                        multi && Array.isArray(value) ? value.includes(item.value) : item.value === value;
                    const variant = isSelected ? 'contained' : 'outlined';
                    const key = `group-button-${item.value}`;
                    const handleItemClick = handleClick(item.value);
                    return (
                        <Button
                            key={key}
                            variant={variant}
                            onClick={handleItemClick}
                            disabled={disabled}
                            color="primary"
                            sx={{ flexGrow: 1 }}
                        >
                            {item.label}
                        </Button>
                    );
                })}
        </ButtonGroup>
    );
};

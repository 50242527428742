export type TagFilter = Record<string, Record<string, boolean>>;
export const tagFilterObjectToString = (tagFilter: TagFilter) => {
    const tagsValue: string[] = [];

    Object.entries(tagFilter).forEach(([tagGroupId, tags]) => {
        const selectedTags = Object.entries(tags)
            .filter(([_, value]) => !!value)
            .map(([tagId]) => tagId);

        if (selectedTags.length) {
            selectedTags.forEach(tag => {
                tagsValue.push(`${tagGroupId}/${tag}`);
            });
        }
    });

    return tagsValue;
};

import { Box, Button, styled, TablePagination } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { CONTENT_AVAILABILITY_VIEW } from 'config/routes';
import { Link, useHistory } from 'react-router-dom';
import { MuiTheme } from 'config/theme';
import { useLocationsData } from 'components/location/useLocationsData';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { DEFAULT_PAGE_LIMIT } from 'lib/hooks/usePagedData';

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    width: '100%',
    display: 'none',
    color: theme.palette.text.primary,
    '.MuiDataGrid-row:hover &': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '&:hover': {
        color: theme.palette.primary.main
    }
}));

export const OrderingAvailabilityPage: React.FC = () => {
    const [search, setSearch] = React.useState<string>();
    const history = useHistory();
    const { count, page, onPageChange, items, loading } = useLocationsData(search);
    const handleSearchChange = React.useCallback((value: string) => {
        setSearch(value);
    }, []);
    const renderEditCell = React.useCallback((params: GridRenderCellParams) => {
        const pathname = CONTENT_AVAILABILITY_VIEW.replace(':locationId', params.row._id);
        return (
            <Button sx={{ textTransform: 'initial' }} variant="text">
                <StyledLink to={{ pathname }}>View Availability</StyledLink>
            </Button>
        );
    }, []);
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={DEFAULT_PAGE_LIMIT}
                rowsPerPageOptions={[DEFAULT_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Location ID',
                field: '_id',
                disableReorder: true,
                disableExport: true,
                width: 300
            },
            {
                headerName: 'Name',
                field: 'title',
                disableReorder: true,
                disableExport: true,
                width: 400
            },
            {
                headerName: '',
                field: 'view',
                disableColumnMenu: true,
                disableReorder: true,
                disableExport: true,
                hideable: false,
                width: 140,
                sortable: false,
                renderCell: renderEditCell
            }
        ],
        [renderEditCell]
    );
    const getUniqueKey = React.useCallback((row: GridValidRowModel) => row._id, []);
    React.useEffect(() => {
        if (items && items.length === 1) {
            history.replace(CONTENT_AVAILABILITY_VIEW.replace(':locationId', items[0]._id));
        }
    }, [history, items]);
    return (
        <MainLayout
            pageName="Ordering Availability"
            pageDescription="Manage product availability per location"
        >
            <Box height="100%" pb={8}>
                <Box paddingBottom={1}>
                    <GridFilterInput
                        value={search}
                        onChange={handleSearchChange}
                        label="Location title"
                        name="search"
                        size="small"
                    />
                </Box>
                <MuiGrid
                    rows={items}
                    columns={columns}
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    components={{
                        Pagination
                    }}
                    getRowId={getUniqueKey}
                />
            </Box>
        </MainLayout>
    );
};

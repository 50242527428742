import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PasswordResetForm, PasswordResetFormData } from 'components/auth/forms/PasswordResetForm';
import { MarketingLayout } from 'components/auth/MarketingLayout';
import { FORGOT_YOUR_PASSWORD_EMAIL_SENT } from 'config/messages';
import { SIGN_IN } from 'config/routes';
import { doPasswordReset } from 'lib/firebase/auth';
import { Link } from 'ui/Link';

function ResetYourPassword() {
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);
    const handleSubmit = React.useCallback(async ({ email }: PasswordResetFormData) => {
        setError(null);
        setSuccess(null);
        try {
            await doPasswordReset(email);
            setSuccess(FORGOT_YOUR_PASSWORD_EMAIL_SENT);
        } catch (e) {
            setError(e.message);
        }
    }, []);
    return (
        <MarketingLayout
            marketingId="optimize-forgot-your-password"
            after={
                <Typography align="center" variant="caption" color="textSecondary">
                    Remembered your password? <Link href={SIGN_IN}>Sign In</Link>
                </Typography>
            }
        >
            {!!success ? (
                <Typography color="primary" align="center">
                    {success}
                </Typography>
            ) : (
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            Reset your password
                        </Typography>
                        <Typography variant="caption">
                            You&apos;ll receive an email with instructions on how to reset your password.
                        </Typography>
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <Typography color="error">{error}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <PasswordResetForm onSubmit={handleSubmit} />
                    </Grid>
                </Grid>
            )}
        </MarketingLayout>
    );
}

export default ResetYourPassword;

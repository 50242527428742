import { ActionCreator } from 'redux';
import { IAllPublicAndPrivateSettings, IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { SettingsActionTypes } from './settingsActionTypes';
import { ExpiringClaims, Permission, Resource } from '@pepperhq/auth-client';

interface IBaseReducerAction {
    type: SettingsActionTypes;
}
interface IProgressAction extends IBaseReducerAction {
    type: SettingsActionTypes.START_REQUEST | SettingsActionTypes.END_REQUEST;
}
interface IErrorAction extends IBaseReducerAction {
    type: SettingsActionTypes.LOAD_ERROR;
    message: string;
}
interface ILoadingAction extends IBaseReducerAction {
    type: SettingsActionTypes.LOAD_SUCCESS;
    settings?: IPublicAndPrivateSettings;
}
interface IResetAction extends IBaseReducerAction {
    type: SettingsActionTypes.RESET;
}
export type ISettingAction = IProgressAction | ILoadingAction | IErrorAction | IResetAction;

export function resetPublicSettings() {
    return function (dispatch: ActionCreator<IResetAction>) {
        dispatch({
            type: SettingsActionTypes.RESET
        });
    };
}

export function getPublicAndPrivateSettings(claims: ExpiringClaims) {
    return async function (dispatch: ActionCreator<ILoadingAction | IProgressAction>) {
        dispatch({ type: SettingsActionTypes.START_REQUEST });
        try {
            let settings: DeepPartial<IAllPublicAndPrivateSettings>;
            if (claims.hasPermission(Resource.Secret, Permission.read)) {
                settings = await new LocationSettingsApi().getPublicAndPrivateSettings(null);
            } else if (claims.hasPermission(Resource.Setting, Permission.read)) {
                settings = await new LocationSettingsApi().getPublicSettings(null);
            }
            dispatch({
                settings,
                type: SettingsActionTypes.LOAD_SUCCESS
            });
        } catch (e) {
            dispatch({
                type: SettingsActionTypes.LOAD_ERROR,
                message: e.message
            });
        } finally {
            dispatch({
                type: SettingsActionTypes.END_REQUEST
            });
        }
    };
}

import { differenceInCalendarDays, format, formatDistanceStrict, isSameDay, isValid } from 'date-fns';
import logger from 'lib/logger';
import { isNumber } from 'lib/typeguards';
import { IAction } from '../models/Action';

export const getDistanceBettwenTodayAndMillisecondsInWords = (timestamp: number) => {
    try {
        if (isNumber(timestamp)) {
            const distance = formatDistanceStrict(0, timestamp * 1000);
            return distance;
        }
        return '';
    } catch (error) {
        logger.error(`Invalid timestamp field ${timestamp} for expiryHorizon`);
        return '';
    }
};

export const formatActionDate = (timestamp: string, actionId: string, isShort?: boolean): string => {
    try {
        if (isValid(new Date(timestamp))) {
            let returnDate;
            const date = new Date(timestamp);
            const today = new Date();
            if (isSameDay(today, date)) {
                returnDate = `Today at ${format(date, 'h:mmaaa')}`;
            } else if (differenceInCalendarDays(new Date(), date) <= 7) {
                returnDate = `${format(date, 'EEEE')} at ${format(date, 'h:mmaaa')}`;
            } else {
                returnDate = format(date, isShort ? 'dd/MM/yyyy' : 'dd/MM/yyyy h:mmaaa');
            }
            return returnDate;
        }
    } catch (error) {
        logger.error(`Invalid timestamp field ${timestamp} for action id ${actionId}`);
    }
    return 'Invalid date';
};

export const getActionDate = (action: IAction) => {
    const { _id, when } = action;
    const { timestamp } = when;
    return formatActionDate(timestamp, _id);
};

export const getShortActionDate = (action: IAction) => {
    const { _id, when } = action;
    const { timestamp } = when;
    return formatActionDate(timestamp, _id, true);
};

export const getActionDay = (action: IAction) => {
    const { timestamp } = action.when;
    try {
        if (isValid(new Date(timestamp))) {
            let returnDate;
            const date = new Date(timestamp);
            const today = new Date();
            if (isSameDay(today, date)) {
                returnDate = 'Today';
            } else if (differenceInCalendarDays(new Date(), date) <= 7) {
                returnDate = format(date, 'EEEE');
            } else {
                returnDate = format(date, 'dd/MM/yyyy');
            }
            return returnDate;
        }
    } catch (error) {
        logger.error(`Invalid timestamp field ${timestamp} for action id ${action._id}`);
        return 'Invalid date';
    }
};

export const getActionTime = (action: IAction) => {
    const { timestamp } = action.when;
    try {
        if (isValid(new Date(timestamp))) {
            const date = new Date(timestamp);
            return format(date, 'hh:mmaa');
        }
    } catch (error) {
        logger.error(`Invalid timestamp field ${timestamp} for action id ${action._id}`);
        return 'Invalid date';
    }
};

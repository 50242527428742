import firebase from 'firebase/app';
// tslint:disable-next-line
import 'firebase/auth';

export const auth: () => firebase.auth.Auth = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: process.env.GOOGLE_API_KEY,
            authDomain: process.env.CONSOLE_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.CONSOLE_FIREBASE_DATABASE_URL,
            projectId: process.env.CONSOLE_FIREBASE_PROJECT_ID,
            storageBucket: process.env.CONSOLE_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.CONSOLE_FIREBASE_MESSAGING_SENDER_ID,
            Persistence: firebase.auth.Auth.Persistence.SESSION
        });
    }
    return firebase.auth();
};

export const HEALTH = '/health';
export const JOIN = '/join';
export const ACTION = '/action';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const UNAUTHORIZED = '/unauthorized';
export const FORGOT_YOUR_PASSWORD = '/forgot-your-password';
export const FORGOT_YOUR_PASSWORD_CONFIRM = '/forgot-your-password/confirm';

// Analytics
export const ANALYTICS = '/analytics';
export const ANALYTICS_ORDERS = '/analytics/orders';
export const ANALYTICS_LOYALTY = '/analytics/loyalty';
export const ANALYTICS_CUSTOMERS = '/analytics/customers';
export const ANALYTICS_DASHBOARD_VIEW = '/analytics/dashboard/:dashboardId';
export const ANALYTICS_EXPORT = '/analytics/export';
export const ANALYTICS_GIFTING_ACTIVITY = '/analytics/gifting/activity';
export const ANALYTICS_GIFTING_LIABILITY = '/analytics/gifting/liability';

// Customer Management
export const CUSTOMERS = '/customers';
export const CUSTOMERS_VIEW = '/customers/:customerId';

// Marketing
export const MARKETING = '/marketing';
export const MARKETING_AUDIENCES = '/marketing/audiences';
export const MARKETING_AUDIENCES_VIEW = '/marketing/audiences/:audienceId';
export const MARKETING_PERKS = '/marketing/perks';
export const MARKETING_PEPPER_PERKS = '/marketing/perks/pepper';
export const MARKETING_3RD_PARTY_PERKS = '/marketing/perks/external';
export const MARKETING_NOTIFICATIONS = '/marketing/notifications';
export const MARKETING_NOTIFICATIONS_VIEW = '/marketing/notifications/:notificationId';
export const MARKETING_VOUCHERS = '/marketing/vouchers';
export const MARKETING_VOUCHERS_VIEW = '/marketing/vouchers/:voucherId';
export const MARKETING_CAMPAIGNS = '/marketing/campaigns';
export const MARKETING_CAMPAIGNS_CREATE = '/marketing/campaigns/create';
export const MARKETING_CAMPAIGNS_UPDATE = '/marketing/campaigns/:campaignId';

// Content
export const CONTENT = '/locations/content';
export const CONTENT_AVAILABILITY = '/content/availability';
export const CONTENT_AVAILABILITY_VIEW = '/content/availability/:locationId';
export const CONTENT_OVERWRITES = '/menu-customisation';

// Orders
export const ORDERS = '/orders';
export const ORDERS_VIEW = '/orders/:orderId';
// Locations
export const LOCATIONS = '/locations';
export const LOCATION_VIEW = '/locations/:locationId';
export function buildLocationViewUrl(locationId: string) {
    return `/locations/${locationId}`;
}
export const LOCATION_TAGS = '/location-tags';
// User
export const USER = '/user';
export const USER_MERCHANTS = '/user/merchants';
// Tenant
export const TENANT = '/tenant';
// Merchant
export const MERCHANT_CONFIG_EMPTY = '/merchant-config';
export const MERCHANT_CONFIG = '/merchant-config/:tabId';
export const MERCHANT_CONFIG_USER = '/merchant-config/users';
export const MERCHANT_CONFIG_API = '/merchant-config/api';
export const MERCHANT_SETTINGS_CLERKS = '/merchant-settings/clerks';
export const MERCHANT_MOBILE_SETTINGS = '/merchant-settings/settings';

export const MERCHANT = '/merchant';
export const MERCHANT_USER_MANAGEMENT = '/merchant/user-management';

// Mobile Content
export const MOBILE_CONTENT = '/mobile-content';
export const MOBILE_CONTENT_LAYOUT_CONFIG = '/mobile-content/layout-config/:layoutId';
export const MOBILE_CONTENT_TEMPLATE_SELECTION = '/mobile-content/template-selection';
export const MOBILE_CONTENT_MODULE_CONFIGURATION = '/mobile-content/module-config/:layoutId';
export const MOBILE_CONTENT_CONFIRMATION = '/mobile-content/confirm/:layoutId';

// Pepper Pay
export const PEPPER_PAY = '/pepper-pay';
export const PEPPER_PAY_REPORTING = '/pepper-pay/reporting/:table';
export const PEPPER_PAY_REPORTING_SCHEDULES = '/pepper-pay/reporting/schedules';
export const PEPPER_PAY_REPORTING_REPORTS = '/pepper-pay/reporting/reports';
export const PEPPER_PAY_BLOCK_LIST = '/pepper-pay/block-list';
export const PEPPER_PAY_DISPUTES = '/pepper-pay/disputes';
export const PEPPER_PAY_DISPUTES_VIEW = '/pepper-pay/disputes/:transactionId';
export const PEPPER_PAY_TRANSACTIONS = '/pepper-pay/transactions';
export const PEPPER_PAY_TRANSACTIONS_VIEW = '/pepper-pay/transactions/:transactionId';

// Settings
export const MERCHANT_SETTINGS_GLOBAL = '/merchant-settings/settings/global';
export const MERCHANT_SETTINGS_BRANDING = '/merchant-settings/settings/branding';
export const MERCHANT_SETTINGS_ORDERING = '/merchant-settings/settings/ordering';
export const MERCHANT_SETTINGS_PAYMENTS = '/merchant-settings/settings/payments';
export const MERCHANT_SETTINGS_MARKETING = '/merchant-settings/settings/marketing';
export const MERCHANT_SETTINGS_LOCATIONS_AND_FAVOURITES = '/merchant-settings/settings/locations';
export const MERCHANT_SETTINGS_POS = '/merchant-settings/settings/pointofsale';
export const MERCHANT_SETTINGS_TERMINAL = '/merchant-settings/settings/quickpad';

// Menu Manager
export const MENU_MANAGER = '/menu-manager';
export const MENU_MANAGER_SCHEDULING = '/menu-manager/scheduling/:locationId';
export const MENU_MANAGER_ENRICHMENTS = '/menu-manager/menu/:menuId/:locationId/:menuChangeId/:tabId';
export const MENU_MANAGER_ENRICHMENTS_EMPTY = '/menu-manager/menu/:menuId/:locationId/:menuChangeId';
export const MENU_MANAGER_ENRICHMENTS_CATEGORY_GROUPS = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/groups`;
export const MENU_MANAGER_ENRICHMENTS_CATEGORIES = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/categories`;
export const MENU_MANAGER_ENRICHMENTS_PRODUCTS = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/products`;
export const MENU_MANAGER_ENRICHMENTS_MODIFIERS = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/modifiers`;
export const MENU_MANAGER_ENRICHMENTS_MODIFIER_OPTIONS = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/modifier-options`;
export const MENU_MANAGER_ENRICHMENTS_MODIFIER_PRODUCTS = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/modifier-products`;
export const MENU_MANAGER_ENRICHMENTS_TAGS = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/tags`;
export const MENU_MANAGER_ENRICHMENTS_DYNAMIC_IMAGES = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/dynamic-images`;
export const MENU_MANAGER_ENRICHMENTS_TAXES = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/taxes`;
export const MENU_MANAGER_ENRICHMENTS_WEEKLY_AVAILABILITY = `${MENU_MANAGER_ENRICHMENTS_EMPTY}/weekly-availability`;

// Terminal
export const TERMINAL = '/quickpad';

// Schemes
export const TIERED_LOYALTY = '/tiered-loyalty';

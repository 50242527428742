import IframeResizer from 'iframe-resizer-react';
import { httpClient } from 'lib/HttpClient';
import React from 'react';
import { Throbber } from 'ui/Throbber';
import { ErrorLayout } from '../../layouts/ErrorLayout';
import { MainLayout } from '../../layouts/MainLayout';
import { useInterval } from '../../lib/hooks/useInterval';

export interface KibanaIframeLayoutProps {
    pageName?: string;
    pageDescription?: string;
    dashboardId: string;
}

export const KibanaIframeLayout: React.FC<KibanaIframeLayoutProps> = ({
    pageName,
    pageDescription,
    dashboardId
}) => {
    const [isTokenLoaded, setTokenLoaded] = React.useState(false);
    const [isIframeLoaded, setIframeLoaded] = React.useState(false);
    const [error, setError] = React.useState('');
    React.useEffect(() => {
        async function setTokenCookie() {
            const response = await httpClient.post(`${process.env.KIBANA_URL}/token`, {
                skipResponseBody: true,
                headers: {
                    'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
                },
                credentials: 'include'
            });
            if (!response.ok) {
                setError('Failed to load dashboard');
            }
            setTokenLoaded(true);
        }
        setTokenCookie();
    }, []);

    useInterval(() => {
        async function refreshTokenCookie() {
            const response = await httpClient.post(`${process.env.KIBANA_URL}/token`, {
                skipResponseBody: true,
                headers: {
                    'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
                },
                credentials: 'include'
            });
            if (!response.ok) {
                setError('Failed to load dashboard');
            }
            setTokenLoaded(true);
        }
        refreshTokenCookie();
    }, 240000);

    React.useEffect(() => {
        window.addEventListener('message', e => {
            if (e.origin !== process.env.KIBANA_URL || e.data !== 'DATA_IFRAME_HEIGHT_ADDED') {
                return;
            }
            setIframeLoaded(true);
        });
    }, []);

    if (!isTokenLoaded) {
        return <Throbber />;
    }

    if (error) {
        return <ErrorLayout title={error} fallbackTitle={'Home'} fallbackUrl="/" />;
    }

    return (
        <MainLayout pageName={pageName} pageDescription={pageDescription}>
            {!isIframeLoaded && <Throbber />}
            <IframeResizer
                src={
                    // eslint-disable-next-line max-len
                    `${process.env.KIBANA_URL}/s/tenants/app/dashboards?override-css=true#/view/${dashboardId}?embed=true&show-time-filter=true`
                }
                heightCalculationMethod="taggedElement"
                height="5000px"
                width="100%"
            ></IframeResizer>
        </MainLayout>
    );
};

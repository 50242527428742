import { TypographyProps } from '@mui/material';
import { GridRenderEditCellParams, GridCellValue } from '@mui/x-data-grid-pro';
import React from 'react';
import { MuiGridTextView } from '../MuiGridTextView';

export const renderMuiGridTextView = (
    { id, field, api }: GridRenderEditCellParams,
    value: GridCellValue,
    fontWeight?: TypographyProps['fontWeight']
) => {
    const error = !!api.componentsProps && api.componentsProps[id] && api.componentsProps[id][field];
    return (
        <MuiGridTextView fontWeight={fontWeight} error={error}>
            {value}
        </MuiGridTextView>
    );
};

/* eslint-disable @typescript-eslint/await-thenable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Box } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { AudienceCreateRequestData, createAudience } from 'store/audiences/audiencesActions';
import { ApplicationState } from 'store/store';
import { MuiModal } from 'ui/MuiModal';
import { AudienceCreateForm, AudienceCreateFormData, TimeMeasure } from '../forms/AudienceCreateForm';
import { Audience, AudienceType, isAudience } from '../models/segmentModel';

const SECONDS_IN_HOUR = 3600;

const timeMeasureToSeconds = {
    [TimeMeasure.HOUR]: SECONDS_IN_HOUR,
    [TimeMeasure.DAY]: SECONDS_IN_HOUR * 24,
    [TimeMeasure.WEEK]: SECONDS_IN_HOUR * 24 * 7
};

interface AudienceCreateModalState {
    audienceType?: AudienceType;
    measure: TimeMeasure;
    expires: string;
    error: boolean;
}

class AudienceCreateModalContent extends React.Component<
    AudienceCreateModalComponentProps,
    AudienceCreateModalState
> {
    state: AudienceCreateModalState = {
        audienceType: undefined,
        measure: TimeMeasure.DAY,
        expires: '',
        error: false
    };
    handleSubmit = async ({ expires, measure, ...rest }: AudienceCreateFormData) => {
        const createBody: AudienceCreateRequestData = {
            ...rest
        };
        const { onSuccess, createAudience } = this.props;
        if (rest.type !== AudienceType.PERSISTENT) {
            createBody.expiryHorizon = Number(expires) * timeMeasureToSeconds[measure];
        }
        const audience = await createAudience(createBody);
        if (!!audience && isAudience(audience) && !!onSuccess) {
            onSuccess(audience);
        }
    };
    handleBlur = (name: string, value: any) => {
        if (name === 'type') {
            this.setState({ audienceType: value });
        }
    };
    render() {
        return (
            <Box width={60 * 8}>
                <AudienceCreateForm
                    initialData={this.props.initialData}
                    onSubmit={this.handleSubmit}
                    isLoading={this.props.isLoading}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.audiences.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            createAudience
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AudienceCreateModalComponent = connector(AudienceCreateModalContent);

interface AudienceCreateModalComponentProps extends AudienceCreateModalProps, PropsFromRedux {}

interface AudienceCreateModalProps {
    open: boolean;
    onClose: () => void;
    initialData?: Partial<AudienceCreateFormData>;
    onSuccess?: (audience: Audience) => void;
}

export const AudienceCreateModal = (props: AudienceCreateModalProps) => {
    const { open, onClose } = props;
    return (
        <MuiModal open={open} onClose={onClose}>
            <AudienceCreateModalComponent {...props} />
        </MuiModal>
    );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paper } from '@mui/material';
import AddLocation from '@mui/icons-material/AddLocation';
import { Permission, Resource } from '@pepperhq/auth-client';
import { Location, LocationState } from 'components/location/models/LocationModel';
import * as routes from 'config/routes';
import {
    activateLocation as activateLocationFunction,
    deleteLocation as deleteLocationFunction,
    retireLocation as retireLocationFunction
} from 'store/locations/locationsActions';
import { ApplicationState } from 'store/store';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTable, MuiTableColumnItem, MuiTableSettingsItem, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_LOCATION_NO_MENU } from 'config/messages';
import { menusApi } from 'components/menu-manager/MenusApi';

interface LocationTableProps {
    locations: Location[];
    isMoreItems: boolean;
    columns: MuiTableColumnItem[];
    selectedColumns: MuiTableSettingsItem[];
    onSearch: (title: string) => void;
    onScroll: (title: string) => void;
    onCreateClick: () => void;
    isLoading?: boolean;
    search?: string;
}

export function LocationsTable(props: LocationTableProps) {
    const {
        isMoreItems,
        columns,
        onSearch,
        onScroll,
        locations,
        selectedColumns,
        onCreateClick,
        isLoading,
        search
    } = props;
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const [locationIndexToDelete, setLocationIndexToDelete] = React.useState(-1);
    const [deleteLocationPending, setDeleteLocationPending] = React.useState(false);
    const [loadingRowId, setLoadingRowId] = React.useState('');
    const history = useHistory();
    const dispatch = useDispatch();

    const retireLocation = React.useCallback(
        (location: Location, index: number) => {
            retireLocationFunction(location, index)(dispatch);
        },
        [dispatch]
    );
    const activateLocation = React.useCallback(
        (location: Location, index: number) => {
            activateLocationFunction(location, index)(dispatch);
        },
        [dispatch]
    );
    const deleteLocation = React.useCallback(async () => {
        setDeleteLocationPending(true);
        await deleteLocationFunction(locations[locationIndexToDelete]._id, locationIndexToDelete)(dispatch);
        setLocationIndexToDelete(-1);
        setDeleteLocationPending(false);
    }, [dispatch, locations, locationIndexToDelete]);
    const openDeletionPopUp = React.useCallback((index: number) => setLocationIndexToDelete(index), []);
    const closeDeletionPopUp = React.useCallback(() => setLocationIndexToDelete(-1), []);

    const handleSearch = React.useCallback(
        (title: string) => {
            onSearch(title);
        },
        [onSearch]
    );
    const handleScroll = React.useCallback(() => {
        onScroll(search);
    }, [search, onScroll]);

    const viewLocation = React.useCallback(
        (locationId: string) => {
            history.push(`${routes.LOCATION_VIEW.replace(/:locationId/g, locationId)}`);
        },
        [history]
    );
    const viewLocationMenu = React.useCallback(
        async (locationId: string, locationTitle: string) => {
            setLoadingRowId(locationId);
            const menuOptionsRes = await menusApi.getMenus(locationId);
            if (menuOptionsRes.ok) {
                const menuIdSet = new Set(
                    menuOptionsRes.body.map((menuSummary: any) => menuSummary.menuChangeId)
                );
                if (menuIdSet.size === 1) {
                    const [menu] = menuOptionsRes.body;
                    history.push(
                        `${routes.MENU_MANAGER_ENRICHMENTS.replace(':menuId', menu.menuId)
                            .replace(':locationId', locationId)
                            .replace(':menuChangeId', menu.menuChangeId)}`
                    );
                    return;
                }
            }
            if (
                menuOptionsRes.body.length === 0 ||
                (!menuOptionsRes.ok && menuOptionsRes.body.code === 'E-MEN-0208')
            ) {
                dispatch(enqueueSnackbar(MESSAGE_LOCATION_NO_MENU, { variant: 'error' }));
            }
            history.push(`${routes.MENU_MANAGER}?search=${locationTitle}`);
            setLoadingRowId('');
        },
        [dispatch, history]
    );

    const viewLocationMenuScheduling = React.useCallback(
        (locationId: string) => {
            history.push(`${routes.MENU_MANAGER_SCHEDULING.replace(/:locationId/g, locationId)}`);
        },
        [history]
    );
    const viewOrderingAvailability = React.useCallback(
        (locationId: string) => {
            history.push(`${routes.CONTENT_AVAILABILITY_VIEW.replace(/:locationId/g, locationId)}`);
        },
        [history]
    );
    const handleTagsClick = React.useCallback(() => {
        history.push('/location-tags');
    }, [history]);

    const renderCustomElement = React.useCallback(
        () => (
            <>
                <TableActionButton
                    variant="outlined"
                    onClick={handleTagsClick}
                    disabled={claims.isLocationScoped()}
                >
                    Location Tags
                </TableActionButton>
                <TableActionButton
                    Icon={AddLocation}
                    onClick={onCreateClick}
                    disabled={claims.isLocationScoped()}
                >
                    Add location
                </TableActionButton>
            </>
        ),
        [claims, handleTagsClick, onCreateClick]
    );
    const toolbarItems: MuiTableToolbarItems = {
        search: {
            searchPending: false,
            onSearch: handleSearch,
            placeholder: 'Search by title',
            defaultValue: search
        },
        renderCustomElement,
        settings: {
            items: selectedColumns
        }
    };
    const itemActions = React.useCallback(
        (location: Location, index: number): MuiMenuItem[] => {
            const actions: MuiMenuItem[] = [
                { label: 'View location', onClick: () => viewLocation(location._id) },
                { label: 'View menu', onClick: () => viewLocationMenu(location._id, location.title) },
                { label: 'View menu scheduling', onClick: () => viewLocationMenuScheduling(location._id) },
                { label: 'Ordering availability', onClick: () => viewOrderingAvailability(location._id) }
            ];
            if (location.state === LocationState.RETIRED || location.state === LocationState.PENDING) {
                actions.push({ label: 'Activate', onClick: () => activateLocation(location, index) });
            }
            if (location.state === LocationState.ACTIVE || location.state === LocationState.PENDING) {
                if (claims.hasPermissionForLocation(location._id, Resource.Location, Permission.write)) {
                    actions.push({ label: 'Retire', onClick: () => retireLocation(location, index) });
                }
            }
            if (claims.hasPermissionForLocation(location._id, Resource.Location, Permission.delete)) {
                actions.push({ label: 'Delete', color: 'error', onClick: () => openDeletionPopUp(index) });
            }
            return actions;
        },
        [
            claims,
            viewLocation,
            viewLocationMenu,
            viewLocationMenuScheduling,
            viewOrderingAvailability,
            activateLocation,
            retireLocation,
            openDeletionPopUp
        ]
    );
    const scroll = {
        isMoreItems,
        onScroll: handleScroll
    };
    return (
        <React.Fragment>
            <Paper>
                <MuiTable
                    scroll={scroll}
                    itemActions={itemActions}
                    toolbarItems={toolbarItems}
                    data={locations}
                    columns={columns}
                    isLoading={isLoading}
                    loadingRowId={loadingRowId}
                />
            </Paper>
            <DeleteDialog
                title="Delete location?"
                content="If you delete this location, you won’t be able to recover it."
                open={locationIndexToDelete >= 0}
                onClose={closeDeletionPopUp}
                onDelete={deleteLocation}
                loading={deleteLocationPending}
            />
        </React.Fragment>
    );
}

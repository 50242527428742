import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';

interface SignInFormData {
    email: string;
    password: string;
}

interface SignInFormProps {
    onSubmit: (data: SignInFormData) => void;
}

const initialValues: SignInFormData = {
    email: '',
    password: ''
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .min(3, 'Password should contain at least 3 characters')
        .required('Password is required')
});

export const SignInForm: React.FC<SignInFormProps> = ({ onSubmit }) => {
    const emailRef = React.useRef(null);
    React.useEffect(() => {
        if (emailRef && !!emailRef.current) {
            emailRef.current.focus();
        }
    }, []);
    const handleKeyDown = React.useCallback(
        submitForm => (e: React.KeyboardEvent) => e.keyCode === 13 && submitForm(),
        []
    );
    return (
        <MuiForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                Sign In...
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="email"
                                type="email"
                                label="Email"
                                inputRef={emailRef}
                                onKeyDown={handleKeyDown(submitForm)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                name="password"
                                type="password"
                                label="Password"
                                onKeyDown={handleKeyDown(submitForm)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Row flex={1} align="flex-end">
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Sign In
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};

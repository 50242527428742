import { Box, TablePagination } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { DisputeReasonCell } from 'components/pepper-pay/disputes/DisputeReasonCell';
import { DisputesFilters } from 'components/pepper-pay/disputes/DisputesFilter';
import { DisputeStatusCell } from 'components/pepper-pay/disputes/DisputeStatusCell';
import {
    DISPUTES_PAGE_LIMIT,
    usePepperDisputes
} from 'components/pepper-pay/disputes/hooks/usePepperDisputes';
import { usePepperDisputesFilter } from 'components/pepper-pay/disputes/hooks/usePepperDisputesFilter';

import { PEPPER_PAY_DISPUTES_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { LinkIdCell } from 'lib/MuiGrid/LinkIdCell';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getAllLocations } from 'store/locations/locationsActions';

export const DisputesPage: React.FC = () => {
    const dispatch = useDispatch();
    const [filter, onFilterChange] = usePepperDisputesFilter();
    const { formattedDisputes, page, onPageChange, loading, count } = usePepperDisputes(filter);
    React.useEffect(() => {
        getAllLocations()(dispatch);
    }, [dispatch]);
    const renderViewCell = React.useCallback(
        (params: GridCellParams) => (
            <LinkIdCell id={params.value} param="transactionId" path={PEPPER_PAY_DISPUTES_VIEW} />
        ),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'ID',
                field: 'transactionId',
                disableReorder: true,
                width: 300,
                renderCell: renderViewCell
            },
            {
                headerName: 'Amount',
                field: 'amount',
                type: 'number',
                width: 130,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 160,
                sortable: false,
                renderCell: DisputeStatusCell
            },
            {
                headerName: 'Reason',
                field: 'reason',
                width: 210,
                sortable: false,
                renderCell: DisputeReasonCell
            },
            {
                headerName: 'Disputed on',
                field: 'date',
                type: 'dateTime',
                sortable: false,
                width: 180
            },
            {
                headerName: 'Respond by',
                field: 'dueBy',
                type: 'dateTime',
                sortable: false,
                width: 180
            },
            {
                headerName: 'Customer ID',
                field: 'customerId',
                width: 210,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Intent ID',
                field: 'paymentIntentId',
                width: 260,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Charge ID',
                field: 'paymentChargeId',
                width: 260,
                sortable: false,
                hide: true
            }
        ],
        [renderViewCell]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={DISPUTES_PAGE_LIMIT}
                rowsPerPageOptions={[DISPUTES_PAGE_LIMIT]}
                component="div"
            />
        ),
        [count, handlePageChange, page]
    );
    return (
        <MainLayout pageName="Disputes" pageDescription="View and interact with disputes">
            <Box paddingBottom={12} height="100%">
                <Box paddingBottom={1}>
                    <DisputesFilters filter={filter} onChange={onFilterChange} />
                </Box>
                <MuiGrid
                    rows={formattedDisputes}
                    columns={columns}
                    disableMultipleSelection
                    hideFooter={false}
                    hideFooterRowCount
                    pagination
                    paginationMode="server"
                    loading={loading}
                    disableColumnFilter
                    components={{
                        Pagination
                    }}
                />
            </Box>
        </MainLayout>
    );
};

import React from 'react';
import { IOrder, OrderAdjustmentType } from '../model/Order';
import { v4 } from 'uuid';
import { roundToDecimal } from 'lib/helpers';
import { isEmptyString, isString } from 'lib/typeguards';

export interface IOrderRefundItemEntry {
    id: string;
    title: string;
    cost: number;
    suggestion?: boolean;
}

export function useCalculatedRefund(order: IOrder, suggestionIds?: number[]) {
    const [selectedItems, setSelectedItems] = React.useState(new Set<string>([]));
    const itemsList = React.useMemo<IOrderRefundItemEntry[]>(() => {
        const entries: IOrderRefundItemEntry[] = [];
        if (Array.isArray(order.items)) {
            const usedAdjustments = new Set();
            const modifierProducts: Record<string, IOrderRefundItemEntry[]> = {};
            order.items.forEach(item => {
                const hasParentReferenceId =
                    isString(item.parentReferenceId) && !isEmptyString(item.parentReferenceId);
                if (hasParentReferenceId && !item.deleted) {
                    const isClaimedItem = suggestionIds?.includes(item.id);
                    modifierProducts[item.parentReferenceId] = modifierProducts[item.parentReferenceId] || [];
                    for (let i = 0; i < item.quantity ?? 1; i++) {
                        modifierProducts[item.parentReferenceId].push({
                            id: v4(),
                            title: item.productName,
                            cost: item.cost,
                            suggestion: isClaimedItem
                        });
                    }
                }
            });
            order.items.forEach(item => {
                if (!item.parentReferenceId && !item.deleted) {
                    const isClaimedItem = suggestionIds?.includes(item.id);
                    for (let i = 0; i < item.quantity ?? 1; i++) {
                        entries.push({
                            id: v4(),
                            title: item.productName,
                            cost: item.cost,
                            suggestion: isClaimedItem
                        });
                    }
                    const modifiers = modifierProducts[item.referenceId];
                    if (Array.isArray(modifiers)) {
                        modifiers.forEach(modifier => {
                            entries.push(modifier);
                        });
                    }
                    if (Array.isArray(item.modifiers)) {
                        item.modifiers.forEach(modifier => {
                            modifier.options?.forEach(option => {
                                for (let i = 0; i < item.quantity ?? 1; i++) {
                                    entries.push({
                                        title: option.name,
                                        id: v4(),
                                        cost: option.cost,
                                        suggestion: isClaimedItem
                                    });
                                }
                            });
                        });
                    }
                }
            });
            order.adjustments.forEach(adjustment => {
                if (
                    !usedAdjustments.has(adjustment.awardId) &&
                    !adjustment.deleted &&
                    adjustment.type !== OrderAdjustmentType.REFUND
                ) {
                    entries.push({
                        id: v4(),
                        cost: adjustment.value * (adjustment.quantity ?? 1),
                        title: adjustment.description
                    });
                }
            });
        }
        return entries;
    }, [order.adjustments, order.items, suggestionIds]);
    const onSelectionChange = React.useCallback((newSelection: Set<string>) => {
        setSelectedItems(newSelection);
    }, []);
    const calculatedAmount = React.useMemo(
        () =>
            roundToDecimal(
                itemsList.reduce((acc, item) => {
                    if (selectedItems.has(item.id)) {
                        acc += item.cost;
                    }
                    return acc;
                }, 0)
            ),
        [itemsList, selectedItems]
    );
    return {
        calculatedAmount,
        itemsList,
        selectedItems,
        onSelectionChange
    };
}

import React from 'react';

export function useMediaCheck(
    src: string,
    onSuccessResult?: string
): [string | undefined, boolean, () => void, () => string] {
    const [loading, setLoading] = React.useState(true);
    const [existingMedia, setExistingMedia] = React.useState<string>(undefined);
    const check = React.useCallback(() => {
        setLoading(true);
    }, []);
    const getUrl = React.useCallback(() => `${src}?date=${new Date().toLocaleTimeString()}`, [src]);
    React.useEffect(() => {
        if (loading) {
            const url = getUrl();
            fetch(url, {
                method: 'HEAD'
            })
                .then(result => {
                    if (!result.ok) {
                        throw new Error('Failed to load the resource: ' + src);
                    }
                    setExistingMedia(onSuccessResult ?? url);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [src, onSuccessResult, loading, getUrl]);
    return [existingMedia, loading, check, getUrl];
}

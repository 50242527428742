import React from 'react';
import { KibanaIframeLayout } from '../../components/analytics/KibanaIframeLayout';

export default () => (
    <KibanaIframeLayout
        pageName="Customer Analytics"
        pageDescription="Useful insights into your customer base."
        dashboardId={process.env.KIBANA_CUSTOMERS_DASHBOARD_ID}
    />
);

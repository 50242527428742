import { Box, Typography } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import {
    MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS,
    MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR
} from 'config/messages';
import { isDefined } from 'lib/typeguards';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { IPaymentTimelineItem, useOrderTimelinePayments } from '../hooks/useOrderTimelinePayments';
import { IOrder } from '../model/Order';
import { OrderRefundResultOC2, orderApi } from '../orderApi';
import { OrderPaymentTimelineItem } from './OrderPaymentTimelineItem';
import { HttpClientResponseContent } from 'lib/HttpClient';
import { CustomRefundDialog } from '../refund/CustomRefundDialog';

interface OrderPaymentsTimelineProps {
    order?: IOrder;
    loading?: boolean;
    onRefundSuccess: () => void;
    isRefundEnabled?: boolean;
}

export const OrderPaymentsTimeline: React.FC<OrderPaymentsTimelineProps> = ({
    order,
    loading,
    onRefundSuccess,
    isRefundEnabled
}) => {
    const dispatch = useDispatch();
    const { timelineItems, isLoading: isClerkNameLoading } = useOrderTimelinePayments(order);
    const [refundPaymentId, setRefundPaymentId] = React.useState<number>();
    const [refundPaymentIdLoading, setRefundPaymentIdLoading] = React.useState<number>();
    const disableTimeline = React.useMemo(
        () => (!loading && !order) || !Array.isArray(order?.payments),
        [loading, order]
    );
    const handleRefund = React.useCallback(
        (id: number) => {
            if (Array.isArray(order?.payments)) {
                const payment = order.payments.find(payment => id === payment.id);
                if (!payment) {
                    return dispatch(
                        enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' })
                    );
                }
                setRefundPaymentId(id);
            }
        },
        [dispatch, order?.payments]
    );
    const processRefund = React.useCallback(
        async (amount?: number, reason?: string, note?: string) => {
            setRefundPaymentIdLoading(refundPaymentId);
            let result: HttpClientResponseContent<OrderRefundResultOC2> | undefined;
            if (amount) {
                result = await orderApi.refundOC2ById(String(order?.id), {
                    paymentId: refundPaymentId,
                    amount,
                    reason,
                    note
                });
            } else {
                result = await orderApi.refundOC2ById(String(order?.id), {
                    paymentId: refundPaymentId
                });
            }
            setRefundPaymentId(undefined);
            if (!result.ok) {
                return dispatch(
                    enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' })
                );
            }
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_PENDING, { variant: 'info' }));
            const operationResult = await orderApi.pollOC2Operation(result.body.id, result.body.etaMs);
            setRefundPaymentIdLoading(undefined);
            if (operationResult.status === OperationStatus.ERROR) {
                return dispatch(
                    enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_ERROR, { variant: 'error' })
                );
            }
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_REFUNDING_SUCCESS, { variant: 'success' }));
            onRefundSuccess();
        },
        [dispatch, onRefundSuccess, order?.id, refundPaymentId]
    );
    const handlePaymentRefundClose = React.useCallback(() => {
        setRefundPaymentId(undefined);
    }, []);
    const paymentToRefund = React.useMemo(() => {
        if (isDefined(refundPaymentId)) {
            return order?.payments.find(item => item.id === refundPaymentId);
        }
        return undefined;
    }, [order?.payments, refundPaymentId]);
    const renderComponent = React.useCallback(
        (item: IPaymentTimelineItem) => (
            <OrderPaymentTimelineItem
                key={`timeline-item-${item.id}`}
                item={item}
                isClerkNameLoading={isClerkNameLoading}
                onRefund={handleRefund}
                loading={item.id === refundPaymentIdLoading}
                isRefundEnabled={isRefundEnabled}
                currencyCode={order?.currencyCode}
            />
        ),
        [handleRefund, isClerkNameLoading, isRefundEnabled, order?.currencyCode, refundPaymentIdLoading]
    );
    if (disableTimeline) {
        return null;
    }
    if (!timelineItems.length) {
        return <Typography variant="body2">No payments yet</Typography>;
    }
    return (
        <Box>
            {timelineItems.map(renderComponent)}
            <CustomRefundDialog
                onRefund={processRefund}
                loading={loading || isDefined(refundPaymentIdLoading)}
                payment={paymentToRefund}
                order={order}
                onClose={handlePaymentRefundClose}
            />
        </Box>
    );
};

export enum tenantAccessActionTypes {
    START_REQUEST = 'START_REQUEST',
    END_REQUEST = 'END_REQUEST',
    GET_TENANT_USERS_ACCESS_SUCCESS = 'GET_TENANT_USERS_ACCESS_SUCCESS',
    LOAD_TENANT_USERS_ACCESS_SUCCESS = 'LOAD_TENANT_USERS_ACCESS_SUCCESS',
    GET_TENANT_USERS_ACCESS_ERROR = 'GET_TENANT_USERS_ACCESS_ERROR',
    UPDATE_TENANT_USER_ACCESS_SUCCESS = 'UPDATE_TENANT_USER_ACCESS_SUCCESS',
    UPDATE_TENANT_USER_ACCESS_ERROR = 'UPDATE_TENANT_USER_ACCESS_ERROR',
    ADD_TENANT_USER_ACCESS_SUCCESS = 'ADD_TENANT_USER_ACCESS_SUCCESS',
    ADD_TENANT_USER_ACCESS_ERROR = 'ADD_TENANT_USER_ACCESS_ERROR'
}

import React from 'react';
import { MenuChange, MenuV, fromModifierProductKey } from '@pepperhq/menu-sdk';
import { getModifierProductsData } from './modifierProduct';
import { isDefined } from 'lib/typeguards';

export function useCreateModifierProduct(
    menu?: MenuV<5>,
    externalChange?: MenuChange,
    selectedIds?: string[]
) {
    const [open, setOpen] = React.useState(false);
    const [_, categoryIdToTitle, modifierIdToTitle, productIdToTitle] = getModifierProductsData(
        menu,
        externalChange,
        false
    );
    const [modifierProductIdToDuplicate, setModifierProductIdToDuplicate] = React.useState<string>();
    const productOptions = React.useMemo(
        () =>
            Object.entries(productIdToTitle).map(([value, label]) => ({
                value,
                label: `${label} [${value}]`
            })),
        [productIdToTitle]
    );
    const categoryOptions = React.useMemo(
        () =>
            Object.entries(categoryIdToTitle).map(([value, label]) => ({
                value,
                label: `${label} [${value}]`
            })),
        [categoryIdToTitle]
    );
    const modifierOptions = React.useMemo(
        () =>
            Object.entries(modifierIdToTitle).map(([value, label]) => ({
                value,
                label: `${label} [${value}]`
            })),
        [modifierIdToTitle]
    );

    const [modifiersByCategory, productsByModifier] = React.useMemo(() => {
        const _modifiersByCategory: Record<string, Set<string>> = {};
        const _productsByModifier: Record<string, Set<string>> = {};
        menu.categories.forEach(category => {
            if (!_modifiersByCategory[category.id]) {
                _modifiersByCategory[category.id] = new Set();
                category.modifiers.forEach(modifier => {
                    _modifiersByCategory[category.id].add(modifier.id);
                    if (Array.isArray(modifier.products) && !_productsByModifier[modifier.id]) {
                        _productsByModifier[modifier.id] = new Set();
                        modifier.products.forEach(product => {
                            _productsByModifier[modifier.id].add(product.id);
                        });
                    }
                });
            }
        });
        return [_modifiersByCategory, _productsByModifier];
    }, [menu.categories]);

    const isModifierProductRemoveable = React.useCallback((rowId: string) => {
        const { categoryId } = fromModifierProductKey(rowId);
        if (isDefined(categoryId)) {
            return true;
        }
        return false;
    }, []);
    const handleCreateCancel = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleCreate = React.useCallback(() => {
        if (selectedIds && selectedIds.length === 1 && !isModifierProductRemoveable(selectedIds[0])) {
            const [selectedModifierProductId] = selectedIds;
            setModifierProductIdToDuplicate(selectedModifierProductId);
        } else {
            setModifierProductIdToDuplicate(undefined);
        }
        setOpen(true);
    }, [isModifierProductRemoveable, selectedIds]);

    return {
        open,
        modifierProductIdToDuplicate,
        categoryOptions,
        modifierOptions,
        productOptions,
        modifiersByCategory,
        productsByModifier,
        handleCreateCancel,
        handleCreate
    };
}

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ColorFormField } from 'lib/form/fields/ColorFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { LoadingButton } from '@mui/lab';
import { ILayoutConfigurationForm } from 'components/content/layout-config/LayoutConfiguration';
import { useDispatch } from 'react-redux';
import { updateCurrentLayoutProperties } from 'store/content/contentActions';
import { isString } from 'lib/typeguards';
import { rgbaToHex } from 'lib/helpers';

interface IProps {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    values: ILayoutConfigurationForm;
    isValid: boolean;
    isSubmitting?: boolean;
}

export enum ELayoutFormField {
    ANCHOR_BACKGROUND_COLOUR = 'anchorBackgroundColour',
    ANCHOR_TEXT_COLOUR = 'anchorTextColour',
    TITLE_TEXT_COLOUR = 'titleTextColour',
    BODY_TEXT_COLOUR = 'bodyTextColour',
    PRIMARY_ACTION_BACKGROUND_COLOUR = 'primaryActionBackgroundColour',
    PRIMARY_ACTION_TEXT_COLOUR = 'primaryActionTextColour',
    TINT_COLOUR = 'tintColour',
    USE_ROUNDED_CORNERS = 'useRoundedCorners',
    CORNER_RADIUS = 'cornerRadius'
}

export const LAYOUT_COLOUR_FIELDS = [
    ELayoutFormField.ANCHOR_TEXT_COLOUR,
    ELayoutFormField.ANCHOR_BACKGROUND_COLOUR,
    ELayoutFormField.BODY_TEXT_COLOUR,
    ELayoutFormField.TINT_COLOUR,
    ELayoutFormField.TITLE_TEXT_COLOUR,
    ELayoutFormField.PRIMARY_ACTION_TEXT_COLOUR,
    ELayoutFormField.PRIMARY_ACTION_BACKGROUND_COLOUR
];

export const LayoutConfigurationForm = ({ onSubmit, values, isSubmitting, isValid }: IProps) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (isValid) {
            Object.entries(values).forEach(([key, value]) => {
                if (LAYOUT_COLOUR_FIELDS.includes(key as ELayoutFormField)) {
                    if (value) {
                        dispatch(
                            updateCurrentLayoutProperties(key, isString(value) ? value : rgbaToHex(value))
                        );
                    }
                } else {
                    dispatch(updateCurrentLayoutProperties(key, value));
                }
            });
        }
    }, [values, isValid, dispatch]);

    return (
        <form onSubmit={onSubmit}>
            <Grid container>
                <Grid mb={2} item xs={12}>
                    <Typography color="primary" variant="h6">
                        Configure your layout
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <ColorFormField
                            description="The background colour for your app."
                            name={ELayoutFormField.ANCHOR_BACKGROUND_COLOUR}
                            label="Background colour"
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <ColorFormField
                            label="Text colour"
                            name={ELayoutFormField.ANCHOR_TEXT_COLOUR}
                            description="The default text colour seen throughout your app."
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <ColorFormField
                            label="Title text colour"
                            name={ELayoutFormField.TITLE_TEXT_COLOUR}
                            description="Text colour applied to the title. Overrides default text colour."
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <ColorFormField
                            label="Body text colour"
                            name={ELayoutFormField.BODY_TEXT_COLOUR}
                            description="Text colour applied to the body of text. Overrides default text colour."
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2}>
                        <ColorFormField
                            label="Secondary background colour"
                            name={ELayoutFormField.PRIMARY_ACTION_BACKGROUND_COLOUR}
                            description="Background colour of highlighted elements such as overlays."
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <ColorFormField
                            label="Secondary text colour"
                            name={ELayoutFormField.PRIMARY_ACTION_TEXT_COLOUR}
                            description="Text colour of highlighted elements such as navigation buttons and overlay text."
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <ColorFormField
                            label="Accent colour"
                            name={ELayoutFormField.TINT_COLOUR}
                            description="An accent colour provides more ways to accent and distinguish your product."
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2} width="100%">
                        <SwitchFormField
                            name={ELayoutFormField.USE_ROUNDED_CORNERS}
                            label="Use rounded corners"
                            description="Toggles the rounding of module corners on your home screen."
                        />
                    </Box>
                </Grid>

                {values.useRoundedCorners && (
                    <Grid item xs={12}>
                        <Box mb={2} width="100%">
                            <NumberFormField
                                name={ELayoutFormField.CORNER_RADIUS}
                                label="Corner radius"
                                min={8}
                                InputLabelProps={{ shrink: true }}
                                max={18}
                                description="Optional. Set the radius of the corners used throughout the app. It can range between 8 - 18 pixels."
                            />
                        </Box>
                    </Grid>
                )}

                <Box width="100%" display="flex" justifyContent="flex-end">
                    <LoadingButton
                        disabled={!isValid}
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        type="submit"
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Grid>
        </form>
    );
};

import React from 'react';
import { Drawer, styled } from '@mui/material';
import { EnrichmentOperation } from '../model/EnrichmentOperation';
import { OverwritesDrawerContent } from './OverwritesDrawerContent';

const PREFIX = 'OverwritesDrawer';

const classes = {
    paper: `${PREFIX}-paper`
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        width: theme.spacing(52),
        paddingBottom: theme.spacing(10)
    }
}));

interface OverwritesDrawerProps {
    onClose: () => void;
    operation?: EnrichmentOperation;
}

export const OverwritesDrawer: React.FC<OverwritesDrawerProps> = props => {
    const { onClose, operation } = props;

    return (
        <StyledDrawer
            anchor="right"
            hideBackdrop
            open={!!operation}
            classes={{ paper: classes.paper }}
            disableScrollLock
            disableEnforceFocus
            style={{ left: 'auto' }}
        >
            <OverwritesDrawerContent operation={operation} onClose={onClose} />
        </StyledDrawer>
    );
};

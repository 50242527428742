import React from 'react';
import { Chip, styled } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { mapEnrichmentOperationStatusToChipColor } from './model/EnrichmentOperation';

const StyledChip: any = styled(Chip)<OperationStatusChipProps>(({ theme, status }) => ({
    backgroundColor: mapEnrichmentOperationStatusToChipColor(status),
    color: theme.palette.primary.contrastText
}));

interface OperationStatusChipProps {
    status: OperationStatus;
}

export const OperationStatusChip: React.FC<OperationStatusChipProps> = ({ status }) => (
    <StyledChip label={status} status={status} />
);

import React from 'react';
import { Grid, Hidden, Paper, styled } from '@mui/material';
import Logo from 'static/pepper-logo.png';
import PlaceholderImage from 'static/sign-up-content-placeholder.svg';
import { Column } from 'ui/Flex';

interface MarketingLayoutProps {
    children: React.ReactNode;
    marketingId?: string;
    after?: React.ReactNode;
}

const PREFIX = 'NotificationSuccessRateCard';

const classes = {
    root: `${PREFIX}-root`,
    grid: `${PREFIX}-grid`,
    auth: `${PREFIX}-auth`,
    form: `${PREFIX}-form`,
    signUpContent: `${PREFIX}-signUpContent`,
    signUpContentPlaceholder: `${PREFIX}-signUpContentPlaceholder`,
    logo: `${PREFIX}-logo`
};

const StyledColumn = styled(Column)(({ theme }) => ({
    height: '100vh',
    [`& .${classes.grid}`]: {
        height: '100%'
    },
    [`& .${classes.auth}`]: {
        background: theme.palette.background.default
    },
    [`& .${classes.form}`]: {
        padding: theme.spacing(3, 4),
        position: 'relative',
        width: theme.spacing(75),
        minWidth: theme.spacing(56),
        maxWidth: theme.spacing(85),
        flexShrink: 1
    },
    [`& .${classes.signUpContent}`]: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper
    },
    [`& .${classes.signUpContentPlaceholder}`]: {
        width: theme.spacing(75),
        margin: 'auto'
    },
    [`& .${classes.logo}`]: {
        width: theme.spacing(15),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block'
    }
}));

export const MarketingLayout = (props: MarketingLayoutProps) => (
    <StyledColumn className={classes.root}>
        <Grid className={classes.grid} container alignItems="center">
            <Grid item md={6} xs={12}>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    className={classes.auth}
                    direction="column"
                >
                    <Grid item xs={12}>
                        <Paper className={classes.form}>
                            <img className={classes.logo} src={Logo} alt="" />
                            {props.children}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Column align="center" valign="center">
                            {props.after}
                        </Column>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden mdDown>
                <Grid
                    className={classes.signUpContent}
                    item
                    xs={6}
                    id={props.marketingId || 'optimize-content-sign-up'}
                >
                    <img className={classes.signUpContentPlaceholder} src={PlaceholderImage} alt="" />
                </Grid>
            </Hidden>
        </Grid>
    </StyledColumn>
);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, styled, Typography } from '@mui/material';
import { LocationsPickerModal } from 'components/location/modal/LocationsPickerModal';
import { OperationStatus } from 'components/operation/models/OperationModel';
import {
    MESSAGE_EXPORT_ENRICHMENTS_CREATED,
    MESSAGE_EXPORT_ENRICHMENTS_ERROR,
    MESSAGE_EXPORT_ENRICHMENTS_SUCCESS
} from 'config/messages';
import { useInterval } from 'lib/hooks/useInterval';
import logger from 'lib/logger';
import Placeholder from 'static/export-enrichments-placeholder.svg';
import { getAllLocations } from 'store/locations/locationsActions';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { EnrichmentOperation } from './model/EnrichmentOperation';
import { ExportEnrichmentsTablePure as ExportEnrichmentsTable } from './tables/ExportEnrichmentsTable';
import { overwritesApi } from './overwritesApi';

const PREFIX = 'ExportEnrichments';

const classes = {
    linkButton: `${PREFIX}-linkButton`,
    placeholder: `${PREFIX}-placeholder`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.linkButton}`]: {
        textDecoration: 'underline',
        color: theme.palette.info.dark,
        cursor: 'pointer',
        marginRight: theme.spacing(2),
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.info.light
        }
    },
    [`& .${classes.placeholder}`]: {
        marginBottom: theme.spacing(3),
        maxWidth: theme.spacing(54),
        height: 'auto'
    }
}));

interface ExportEnrichmentsProps {
    operations: EnrichmentOperation[];
    onScroll: () => void;
    nextKey?: string;
    showLanding?: boolean;
    onClick: (item: EnrichmentOperation) => void;
    setOperations: React.Dispatch<React.SetStateAction<EnrichmentOperation[]>>;
}

function mergeOperations(oOne: EnrichmentOperation[], oTwo: EnrichmentOperation[]) {
    return oOne.map(item => {
        const newOperation = oTwo.find(operation => operation.id === item.id);
        if (newOperation) {
            return newOperation;
        }
        return item;
    });
}

function filterPendingOpertaions(item: EnrichmentOperation) {
    return item.status === OperationStatus.PENDING || item.status === OperationStatus.RUNNING;
}

export const ExportEnrichments: React.FC<ExportEnrichmentsProps> = ({
    operations,
    onScroll,
    nextKey,
    showLanding,
    onClick,
    setOperations
}) => {
    const [pendingOperations, setPendingOperations] = React.useState<EnrichmentOperation[]>(() =>
        operations.filter(filterPendingOpertaions)
    );
    const [loading, setLoading] = React.useState(false);
    const [locationIds, setLocationIds] = React.useState<string[]>([]);
    const [isLocationPickerOpen, setLocationPickerOpen] = React.useState(false);

    const dispatch = useDispatch();
    const {
        locations,
        summary,
        isLoading: locationsLoading
    } = useSelector((state: ApplicationState) => state.locations);

    const selectLocationsString = React.useMemo(() => {
        if (locationIds.length === 0 || (summary && summary.total === locationIds.length)) {
            return 'All Locations';
        }
        if (locationIds.length === 1) {
            return '1 Location';
        }
        return `${locationIds.length} Locations`;
    }, [locationIds, summary]);

    React.useEffect(() => {
        getAllLocations()(dispatch);
    }, [dispatch]);

    useInterval(() => {
        if (pendingOperations.length) {
            const promises = pendingOperations.map(item => overwritesApi.getExportOperation(item.id));
            Promise.all(promises)
                .then(results => {
                    const resultOperations = results.map(item => item.body);
                    const pending = resultOperations.filter(
                        item =>
                            item.status === OperationStatus.PENDING || item.status === OperationStatus.RUNNING
                    );
                    setPendingOperations(pending);
                    if (resultOperations.length - pending.length) {
                        dispatch(enqueueSnackbar(MESSAGE_EXPORT_ENRICHMENTS_SUCCESS, { variant: 'success' }));
                    }
                    setOperations(current => mergeOperations(current, resultOperations));
                })
                .catch(e => {
                    logger.error(e.message);
                    dispatch(enqueueSnackbar(MESSAGE_EXPORT_ENRICHMENTS_ERROR, { variant: 'error' }));
                });
        }
    }, 1500);
    const handleClick = React.useCallback(async () => {
        setLoading(true);
        try {
            const result = await overwritesApi.createExport({
                locationIds
            });
            setPendingOperations(current => [...current, result.body]);
            setOperations(current => [result.body, ...current]);
            dispatch(enqueueSnackbar(MESSAGE_EXPORT_ENRICHMENTS_CREATED, { variant: 'info' }));
        } catch (e) {
            logger.error(e.message);
            dispatch(enqueueSnackbar(MESSAGE_EXPORT_ENRICHMENTS_ERROR, { variant: 'error' }));
        } finally {
            setLoading(false);
        }
    }, [locationIds, setOperations, dispatch]);

    const handleOpenLocations = React.useCallback(() => {
        setLocationPickerOpen(true);
    }, []);
    const handleCloseLocations = React.useCallback(() => {
        setLocationPickerOpen(false);
    }, []);
    const handleSubmitLocations = React.useCallback((value: string[]) => {
        setLocationIds(value);
    }, []);

    if (showLanding) {
        return (
            <StyledBox
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <img src={Placeholder} alt="" className={classes.placeholder} />
                <Typography align="center" variant="h5" gutterBottom>
                    Exports
                </Typography>
                <Typography align="center" gutterBottom>
                    Download your existing customisations to
                    <br /> create a backup or edit them to
                    <br /> update the current customisations.
                </Typography>
                <Box display="flex" alignItems="center" marginTop={2}>
                    <Typography
                        onClick={handleOpenLocations}
                        variant="caption"
                        className={classes.linkButton}
                    >
                        {selectLocationsString}
                    </Typography>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                    >
                        Export
                    </LoadingButton>
                </Box>
                <LocationsPickerModal
                    locations={locations}
                    selectedLocationIds={locationIds}
                    open={isLocationPickerOpen}
                    onClose={handleCloseLocations}
                    onSubmit={handleSubmitLocations}
                    loading={locationsLoading}
                />
            </StyledBox>
        );
    }

    return (
        <React.Fragment>
            <Paper>
                <StyledBox display="flex" alignItems="center" justifyContent="space-between" padding={2}>
                    <Typography variant="h6">Menu Customisation Exports</Typography>
                    <Box display="flex" alignItems="center">
                        <Typography
                            onClick={handleOpenLocations}
                            variant="caption"
                            className={classes.linkButton}
                        >
                            {selectLocationsString}
                        </Typography>
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            variant="outlined"
                            color="primary"
                            onClick={handleClick}
                        >
                            Export
                        </LoadingButton>
                    </Box>
                </StyledBox>
                <ExportEnrichmentsTable
                    nextKey={nextKey}
                    operations={operations}
                    onScroll={onScroll}
                    onClick={onClick}
                />
            </Paper>
            <LocationsPickerModal
                locations={locations}
                selectedLocationIds={locationIds}
                open={isLocationPickerOpen}
                onClose={handleCloseLocations}
                onSubmit={handleSubmitLocations}
                loading={locationsLoading}
            />
        </React.Fragment>
    );
};

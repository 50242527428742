import {
    Badge,
    ButtonBase,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
    styled
} from '@mui/material';
import React, { useRef } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Column, Row } from './Flex';
import { isEmptyString, isString } from 'lib/typeguards';
import { matchPath, useLocation } from 'react-router-dom';
import logger from 'lib/logger';
import { EGAEventName, useGAHelpers } from 'lib/hooks/useGAHelper';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ELocalStorageKey } from 'config/storageKeys';

const getLocalNotificationsRead = () => {
    const notificationsRead = localStorage.getItem(ELocalStorageKey.NotificationsRead);
    if (!isString(notificationsRead) || isEmptyString(notificationsRead)) {
        return [];
    }
    return notificationsRead.split(',');
};
const setLocalNotificationsRead = (ids: string[]) => {
    localStorage.setItem(ELocalStorageKey.NotificationsRead, ids.join(','));
};
const getLocalNotificationsPopedUp = () => {
    const notificationsPopedUp = localStorage.getItem(ELocalStorageKey.NotificationsPopup);
    if (!isString(notificationsPopedUp) || isEmptyString(notificationsPopedUp)) {
        return [];
    }
    return notificationsPopedUp.split(',');
};
const setLocalNotificationsPopedUp = (ids: string[]) => {
    localStorage.setItem(ELocalStorageKey.NotificationsPopup, ids.join(','));
};

interface ConsoleNotification {
    id: string;
    title: string;
    content: string;
    link?: string;
    showOnThePage?: string;
}

const PREFIX = 'NotificationsCentre';

const classes = {
    link: `${PREFIX}-link`,
    userMenuButton: `${PREFIX}-userMenuButton`,
    userMenuItem: `${PREFIX}-userMenuItem`
};

const StyledMenuItem = styled(MenuItem)({
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
});

const NotificationsCenterPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.link}`]: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        width: '100%',
        '&: focused': {
            textDecoration: 'none'
        }
    },
    [`& .${classes.userMenuButton}`]: {
        color: theme.palette.text.primary,
        fontSize: '1rem',
        width: '100%'
    },
    [`& .${classes.userMenuItem}`]: {
        color: theme.palette.text.primary,
        fontSize: '1rem',
        width: '100%',
        padding: theme.spacing(0.75, 2)
    },
    '& .MuiBadge-badge': {
        top: '50%',
        right: '18px',
        opacity: 0.3
    },
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    maxHeight: '280px',
    overflowY: 'auto'
}));

export const NotificationsCentre: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const [shouldRead, setShouldRead] = React.useState(false);
    const location = useLocation();
    const anchor = useRef(null);
    const [notifications, setNotifications] = React.useState<ConsoleNotification[]>([]);
    const [notificationsRead, setNotificationsRead] = React.useState([]);
    const [notificationsPopedUp, setNotificationsPopedUp] = React.useState([]);
    const { logUserEvent } = useGAHelpers();
    React.useEffect(() => {
        const loadNotifications = async () => {
            try {
                const result = await fetch(
                    `${process.env.MEDIA_SERVICE_URL}/console/whats-new-${process.env.TIER}.json`
                );
                if (result.ok) {
                    setNotifications(await result.json());
                } else {
                    setNotifications([]);
                }
            } catch (e) {
                logger.error(e.message);
                setNotifications([]);
            }
        };
        loadNotifications();
    }, []);
    const handleNotificationsButtonClick = React.useCallback(() => {
        logUserEvent(EGAEventName.WhatsNewClick);
        setOpen(true);
        setShouldRead(true);
    }, [logUserEvent]);
    const handleNotificationsMenuClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleClick = React.useCallback(
        (id: string) => () => {
            if (!notificationsRead.includes(id)) {
                setNotificationsRead(current => [...current, id]);
            }
            logUserEvent(EGAEventName.WhatsNewLinkClick);
            handleNotificationsMenuClose();
        },
        [handleNotificationsMenuClose, logUserEvent, notificationsRead]
    );
    React.useEffect(() => {
        setNotificationsRead(getLocalNotificationsRead());
        setNotificationsPopedUp(getLocalNotificationsPopedUp());
    }, []);
    React.useEffect(() => {
        if (notificationsRead.length) {
            setLocalNotificationsRead(notificationsRead);
        }
    }, [notificationsRead]);
    const unreadNotifications = React.useMemo(
        () => notifications.filter(item => !notificationsRead.includes(item.id)),
        [notifications, notificationsRead]
    );
    const notificationsToPopUp = React.useMemo(
        () =>
            unreadNotifications.filter(item => item.showOnThePage && !notificationsPopedUp.includes(item.id)),
        [notificationsPopedUp, unreadNotifications]
    );
    React.useEffect(() => {
        if (shouldRead) {
            const unreadableNotifications = notifications.filter(
                item => !item.link && !notificationsRead.includes(item.id)
            );
            if (unreadableNotifications.length) {
                setNotificationsRead(current => [
                    ...current,
                    ...unreadableNotifications.map(item => item.id)
                ]);
            }
            setShouldRead(false);
        }
    }, [notifications, notificationsRead, shouldRead]);
    React.useEffect(() => {
        setLocalNotificationsPopedUp(notificationsPopedUp);
    }, [notificationsPopedUp]);
    React.useEffect(() => {
        if (notificationsToPopUp.length) {
            notificationsToPopUp.forEach(item => {
                if (matchPath(location.pathname, { path: item.showOnThePage })) {
                    setOpen(true);
                    setNotificationsPopedUp(current => [...current, item.id]);
                }
            });
        }
    }, [location.pathname, notificationsToPopUp]);
    const renderNotificationItem = React.useCallback(
        (item: ConsoleNotification) =>
            item.link ? (
                <a
                    className={classes.link}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                    onClick={handleClick(item.id)}
                >
                    <StyledMenuItem className={classes.userMenuButton}>
                        <Column>
                            <Row valign="center">
                                {!notificationsRead.includes(item.id) && (
                                    <FiberManualRecordIcon
                                        color="primary"
                                        sx={{ width: '16px', height: '16px', mr: 0.5 }}
                                    />
                                )}
                                <Typography color="primary">
                                    <b>{item.title}</b>
                                </Typography>
                            </Row>
                            {item.content.split('/n').map((text, index) => (
                                <Typography key={index}>{text}</Typography>
                            ))}
                        </Column>
                    </StyledMenuItem>
                </a>
            ) : (
                <Column className={classes.userMenuItem} key={item.id}>
                    <Typography color="primary">
                        <b>{item.title}</b>
                    </Typography>
                    {item.content.split('/n').map((text, index) => (
                        <Typography key={index}>{text}</Typography>
                    ))}
                </Column>
            ),
        [handleClick, notificationsRead]
    );
    if (!notifications.length) {
        return null;
    }
    return (
        <React.Fragment>
            <ButtonBase onClick={handleNotificationsButtonClick} sx={{ px: 2, height: '100%' }} ref={anchor}>
                <Badge variant="dot" color="primary" invisible={!unreadNotifications.length}>
                    <NotificationsIcon />
                </Badge>
            </ButtonBase>
            <Popper open={open} sx={{ zIndex: 1 }} anchorEl={anchor.current} transition placement="bottom">
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
                        <NotificationsCenterPaper square>
                            <ClickAwayListener onClickAway={handleNotificationsMenuClose}>
                                <MenuList>{notifications.map(renderNotificationItem)}</MenuList>
                            </ClickAwayListener>
                        </NotificationsCenterPaper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

import { AutoFormFields } from 'lib/LegacyForm';

export const createCustomerCreditForm: (currency: string) => AutoFormFields = (currency: string) => ({
    balance: {
        label: 'Balance',
        validation: 'required',
        description: 'How much Balance would you like to award?',
        type: 'prefixInput',
        prefix: currency,
        errors: {
            required: 'This field is required.'
        }
    },
    message: {
        label: 'Message',
        validation: 'required',
        type: 'multiline',
        description: 'Let the user know of this change.',
        errors: {
            required: 'This field is required.'
        }
    }
});

import { Button, TextField } from '@mui/material';
import { loadStripeFile } from 'components/content/uploadFile';
import { useField } from 'formik';
import { FileInputFormField } from 'lib/form/fields/FileInputFormField';
import logger from 'lib/logger';
import React from 'react';

interface StripeFileInputProps {
    name: string;
    uploadName?: string;
    label?: string;
    locationId?: string;
    description?: React.ReactNode;
}

export const StripeFileInput: React.FC<StripeFileInputProps> = ({
    uploadName,
    label,
    name,
    locationId,
    description
}) => {
    const [field] = useField(name);
    const [edit, setEdit] = React.useState(!field.value);
    const [loading, setLoading] = React.useState(!!field.value);
    const [fileData, setFileData] = React.useState(undefined);
    const handleEdit = React.useCallback(() => {
        setEdit(true);
    }, []);
    const renderEditButton = React.useCallback(
        () => (
            <Button onClick={handleEdit} variant="text" color="primary">
                Edit
            </Button>
        ),
        [handleEdit]
    );
    React.useEffect(() => {
        const loadFile = async () => {
            try {
                const result = await loadStripeFile(field.value, locationId);
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                setFileData(result.body);
            } catch (e) {
                logger.error(e);
            } finally {
                setLoading(false);
            }
        };
        if (field.value) {
            loadFile();
        }
    }, [field.value, locationId]);
    if (loading) {
        return <TextField fullWidth label={label} value="Loading..." disabled helperText={description} />;
    }
    if (!edit && fileData) {
        return (
            <TextField
                fullWidth
                label={label}
                value={fileData?.filename}
                InputProps={{ endAdornment: renderEditButton(), readOnly: true }}
                helperText={description}
            />
        );
    }
    return (
        <FileInputFormField
            defaultOpen={field.value}
            name={uploadName}
            description={description}
            label={label}
            attributes={{ accept: 'image/jpeg, image/png, application/pdf' }}
        />
    );
};

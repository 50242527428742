import React from 'react';
import { Menu, MenuItem, PopoverVirtualElement } from '@mui/material';
import { lightFormatActionTypeToTitle } from 'components/actions/utils/actionTypeToTitle';

interface CustomersTimelineTypesFilterProps {
    items: string[];
    onSelect: (value?: string) => void;
    anchorElement: null | Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement);
    onClose: () => void;
    selectedItem?: string;
}

export const CustomersTimelineTypesFilter: React.FC<CustomersTimelineTypesFilterProps> = ({
    items,
    onSelect,
    anchorElement,
    onClose,
    selectedItem
}) => {
    const handleSelect = React.useCallback((type?: string) => () => onSelect(type), [onSelect]);
    const menuItems = React.useMemo(
        () =>
            items.map(type => (
                <MenuItem selected={type === selectedItem} key={type} onClick={handleSelect(type)}>
                    {lightFormatActionTypeToTitle(type)}
                </MenuItem>
            )),
        [items, selectedItem, handleSelect]
    );
    return (
        <Menu id="actions-menu" anchorEl={anchorElement} open={!!anchorElement} onClose={onClose}>
            <MenuItem onClick={handleSelect()}>Show All</MenuItem>
            {menuItems}
        </Menu>
    );
};

import React, { useState } from 'react';

export type MenuSearchKey =
    | 'categoryGroups'
    | 'categories'
    | 'dynamicImages'
    | 'modifiers'
    | 'modifierOptions'
    | 'modifierProducts'
    | 'products'
    | 'tags'
    | 'taxes'
    | 'weeklyAvailability';

const initialState: Record<MenuSearchKey, string> = {
    categories: '',
    categoryGroups: '',
    dynamicImages: '',
    modifiers: '',
    modifierOptions: '',
    modifierProducts: '',
    products: '',
    tags: '',
    taxes: '',
    weeklyAvailability: ''
};

type MenuSearchState = typeof initialState;

export function useMenuGridSearchState(): [MenuSearchState, (value: string, name: MenuSearchKey) => void] {
    const [values, setValues] = useState(initialState);
    const onChange = React.useCallback((value: string, name: MenuSearchKey) => {
        setValues(current => ({ ...current, [name]: value }));
    }, []);
    return [values, onChange];
}

import { styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';
import { DisputeStatus } from '../model/PepperPay';

const PREFIX = 'DisputeStatusCell';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-grey`
};

const Root = styled('span')(({ theme }) => ({
    [`&.${classes.green}`]: { color: green[500] },
    [`&.${classes.yellow}`]: { color: orange[500] },
    [`&.${classes.red}`]: { color: red[400] },
    [`&.${classes.grey}`]: { color: theme.palette.grey[500] }
}));

type DisputeStatusCellProps = GridRenderCellParams;

export const DisputeStatusCell: React.FC<DisputeStatusCellProps> = ({ value }) => {
    const className = React.useMemo(() => {
        switch (value) {
            case DisputeStatus.Won:
                return classes.green;
            case DisputeStatus.NeedsResponse:
            case DisputeStatus.WarningNeedsResponse:
                return classes.yellow;
            case DisputeStatus.Lost:
                return classes.red;
            default:
                return classes.grey;
        }
    }, [value]);
    const formattedValue = React.useMemo(() => titleize(String(value).replaceAll('_', ' '), true), [value]);
    return <Root className={className}>{formattedValue}</Root>;
};

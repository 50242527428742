import React from 'react';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { PasswordWithStrengthField } from 'lib/PasswordWithStrengthField';

interface PasswordStrengthFormFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    description?: string;
    type?: string;
}

export const PasswordStrengthFormField: React.SFC<FieldAttributes<PasswordStrengthFormFieldProps>> = ({
    label,
    placeholder,
    description,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <PasswordStrengthFormFieldComponent
                label={label}
                placeholder={placeholder}
                type={props.type}
                description={description}
                {...childProps}
            />
        )}
    </Field>
);

const PasswordStrengthFormFieldComponent: React.SFC<FieldProps & PasswordStrengthFormFieldProps> = ({
    field,
    meta,
    label,
    type,
    placeholder,
    description
}) => {
    const handleChange = React.useCallback(
        (value: string) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    return (
        <PasswordWithStrengthField
            fullWidth
            placeholder={placeholder}
            type={type}
            name={field.name}
            label={label}
            value={field.value}
            onChange={handleChange}
            onBlur={field.onBlur}
            error={meta.touched && !!meta.error}
            helperText={(meta.touched && !!meta.error && meta.error) || description}
        />
    );
};

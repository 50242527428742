import React from 'react';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';

export enum DeliverectServiceType {
    DELIVERY_AND_PICKUP = '0',
    DELIVERY = '1',
    PICKUP = '2',
    EAT_IN = '3',
    CURBSIDE_PICKUP = '4'
}

export const deliverectServiceLabel: Record<DeliverectServiceType, string> = {
    [DeliverectServiceType.DELIVERY_AND_PICKUP]: 'Delivery and Pickup',
    [DeliverectServiceType.DELIVERY]: 'Delivery',
    [DeliverectServiceType.PICKUP]: 'Pickup',
    [DeliverectServiceType.EAT_IN]: 'Eat-In',
    [DeliverectServiceType.CURBSIDE_PICKUP]: 'Curbside Pickup'
};

const deliverectMenuServiceTypes = Object.values(DeliverectServiceType);

export function isDeliverectMenuServiceType(serviceType: any): serviceType is DeliverectServiceType {
    return deliverectMenuServiceTypes.includes(serviceType);
}

const deliverectServiceTypeOptions = Object.keys(deliverectServiceLabel).map(
    (serviceType: DeliverectServiceType) => ({
        label: deliverectServiceLabel[serviceType],
        value: serviceType
    })
);

export function DeliverectSettingsEdit() {
    return (
        <>
            <SettingDisplay label="Order To Table Menu Type">
                <SelectFormField
                    options={deliverectServiceTypeOptions}
                    type="number"
                    name="ORDER_TO_TABLE_menuType"
                />
            </SettingDisplay>
            <SettingDisplay label="Pre-Order Menu Type">
                <SelectFormField
                    options={deliverectServiceTypeOptions}
                    type="number"
                    name="PREORDER_menuType"
                />
            </SettingDisplay>
            <SettingDisplay label="Map Table Name To Table Id">
                <SwitchFormField name="mapTableNameToTableId" />
            </SettingDisplay>
        </>
    );
}

import { Box, Grid, Paper, Typography } from '@mui/material';
import { locationApi } from 'components/location/LocationApi';
import { IOrder, OrderScenario, OrderScenarioLabels } from 'components/order/model/Order';
import { orderApi } from 'components/order/orderApi';
import { OrderStateChip } from 'components/order/order-details/OrderStateChip';
import { getTimeslotTimes } from 'components/timeslots/helpers';
import { LOCATION_VIEW, ORDERS } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { formatDateTime } from 'lib/helpers';
import { useCurrencyString } from 'lib/hooks/useCurrencyString';
import { LoadingTypography } from 'lib/LoadingTypoghraphy';
import logger from 'lib/logger';
import { ResourceLink } from 'lib/ResourceLink';
import { isEmptyString, isString } from 'lib/typeguards';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row } from 'ui/Flex';
import { OrderSummary } from 'components/order/order-details/OrderSummary';
import { OrderPaymentsTimeline } from 'components/order/order-details/OrderPaymentsTimeline';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { useRefundAll } from 'components/order/hooks/useRefundAll';
import { PaymentRefundConfirmationDialog } from 'components/order/order-details/PaymentRefundConfirmationDialog';
import { customerApi } from 'components/customers/customerApi';
import { Customer } from 'components/customers/models/Customer';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Permission, Resource, permission } from '@pepperhq/auth-client';
import { useForceClose } from 'components/order/hooks/useForceClose';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';

export const OrderViewPage = () => {
    const { orderId } = useParams<{ orderId?: string }>();
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState<IOrder>();
    const [userDetails, setUserDetails] = React.useState<Customer>();
    const getCurrencyString = useCurrencyString(order?.currencyCode);
    const history = useHistory();
    const loadOrder = React.useCallback(() => {
        const initLoad = async () => {
            if (isString(orderId) && !isEmptyString(orderId)) {
                const result = await orderApi.getOrder(orderId);
                if (!result.ok) {
                    logger.error(result.body.message);
                    return history.replace(ORDERS);
                }
                setOrder(result.body);
            }
            setLoading(false);
        };
        initLoad();
    }, [history, orderId]);
    const { onRefundAll, isRefundable, refundAllLoading, modalProps } = useRefundAll(order, loadOrder);
    const {
        isLoading,
        onForceClose,
        isClosable,
        modalProps: closeModalProps
    } = useForceClose(order, loadOrder);
    React.useEffect(() => {
        loadOrder();
    }, [loadOrder, orderId]);
    React.useEffect(() => {
        if (order?.scenario === OrderScenario.PREORDER && order?.users[0]) {
            customerApi
                .getUser(order?.users[0].userId)
                .then(result => {
                    if (!result.ok) {
                        throw new Error(result.body.message);
                    }
                    setUserDetails(result.body);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [order?.scenario, order?.users]);
    const collectionTime = React.useMemo(() => {
        if (order?.scenario === OrderScenario.PREORDER) {
            if (order?.timeSlot) {
                return getTimeslotTimes(order.timeSlot, true);
            }
            return 'ASAP';
        }
        return undefined;
    }, [order?.scenario, order?.timeSlot]);
    const hasRefundPermission = React.useMemo(
        () => claims.hasPermissions(permission(Resource.OrderRefund, Permission.all)),
        [claims]
    );
    const forceCloseContent = React.useMemo(
        () =>
            // eslint-disable-next-line max-len
            `Please be aware you will be permanently closing order ${order?.id} and this action is irreversible. To confirm closure, type 'FORCE CLOSE' in the text input field.`,
        [order?.id]
    );
    const isRefundEnabled = hasRefundPermission && !order?.isOpen;
    return (
        <MainLayout
            breadcrumbs={[{ label: 'Orders', url: ORDERS }]}
            pageName={orderId}
            pageDescription="View and interact with selected order."
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box padding={2}>
                            <Row flex={1} align="space-between" valign="center">
                                <Row valign="center" gutter>
                                    <Box>
                                        <Typography variant="h6">Overview</Typography>
                                        <LoadingTypography
                                            gutterBottom
                                            variant="caption"
                                            width={300}
                                            loading={loading}
                                        >
                                            {formatDateTime(new Date(order?.createdAt))}
                                        </LoadingTypography>
                                    </Box>
                                    {order && <OrderStateChip order={order} />}
                                </Row>
                                {isClosable && (
                                    <LoadingButton
                                        onClick={onForceClose}
                                        loading={isLoading}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Force Close
                                    </LoadingButton>
                                )}
                                {isRefundable && isRefundEnabled && (
                                    <LoadingButton
                                        onClick={onRefundAll}
                                        loading={refundAllLoading}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Refund All
                                    </LoadingButton>
                                )}
                            </Row>
                            <Box paddingY={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Typography variant="body2">Location</Typography>
                                        <LoadingTypography variant="body1" loading={loading} width={200}>
                                            <ResourceLink
                                                id={order?.locationId}
                                                consoleLink={LOCATION_VIEW}
                                                pathParam=":locationId"
                                                crud={locationApi}
                                                title="title"
                                            />
                                        </LoadingTypography>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <Typography variant="body2">Scenario</Typography>
                                        <LoadingTypography variant="body1" loading={loading} width={200}>
                                            {OrderScenarioLabels[order?.scenario]}
                                        </LoadingTypography>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <Typography variant="body2">Order Number</Typography>
                                        <LoadingTypography variant="body1" loading={loading} width={200}>
                                            {order?.id}
                                        </LoadingTypography>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <Typography variant="body2">Order Value</Typography>
                                        <LoadingTypography variant="body1" loading={loading} width={200}>
                                            {getCurrencyString(order?.total?.total)}
                                        </LoadingTypography>
                                    </Grid>
                                    {order?.total?.tips > 0 && (
                                        <Grid item xs={6} md={3} lg={2}>
                                            <Typography variant="body2">Tips</Typography>
                                            <LoadingTypography variant="body1" loading={loading} width={200}>
                                                {getCurrencyString(order?.total?.tips)}
                                            </LoadingTypography>
                                        </Grid>
                                    )}
                                    {isString(order?.deliveryLocation) && (
                                        <Grid item xs={6} md={3} lg={2}>
                                            <Typography variant="body2">Table</Typography>
                                            <Typography variant="body1">{order?.deliveryLocation}</Typography>
                                        </Grid>
                                    )}
                                    {isString(collectionTime) && (
                                        <Grid item xs={6} md={3} lg={2}>
                                            <Typography variant="body2">Collection Time</Typography>
                                            <Typography variant="body1">{collectionTime}</Typography>
                                        </Grid>
                                    )}
                                    {isString(userDetails?.phoneNumberLastUsedToOrder) && (
                                        <Grid item xs={6} md={3} lg={2}>
                                            <Typography variant="body2">Contact Details</Typography>
                                            <Typography variant="body1">
                                                {userDetails?.phoneNumberLastUsedToOrder}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Paper>
                        <Box padding={2}>
                            <Typography variant="h6" sx={{ pb: 2 }}>
                                Payments
                            </Typography>
                            <OrderPaymentsTimeline
                                order={order}
                                loading={loading}
                                onRefundSuccess={loadOrder}
                                isRefundEnabled={isRefundEnabled}
                            />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Paper>
                        <Box padding={2}>
                            <Typography variant="h6" sx={{ pb: 2 }}>
                                Order Details
                            </Typography>
                            <OrderSummary order={order} loading={loading} />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <PaymentRefundConfirmationDialog {...modalProps} />
            <DeleteDialog
                open={closeModalProps.open}
                onDelete={closeModalProps.onAgree}
                onClose={closeModalProps.onClose}
                loading={closeModalProps.loading}
                submitLabel="Force Close"
                title="Force close order"
                content={forceCloseContent}
                protectionWord="FORCE CLOSE"
            />
        </MainLayout>
    );
};

import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Dinero from 'dinero.js';
import { CurrencyCode } from '@pepperhq/regions';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import { GiftingLiabilitySummaryData } from 'components/analytics/gifting/liability/model';

interface GiftingLiabilitySummaryProps {
    summary?: GiftingLiabilitySummaryData;
    loading?: boolean;
}

export const GiftingLiabilitySummary: React.FC<GiftingLiabilitySummaryProps> = ({ summary, loading }) => {
    const debits = Dinero({
        amount: Math.round((summary?.sum_debits || 0) * 100),
        currency: summary?.currency || CurrencyCode.GBP,
        precision: 2
    }).toFormat();
    const credits = Dinero({
        amount: Math.round((summary?.sum_credits || 0) * 100),
        currency: summary?.currency || CurrencyCode.GBP,
        precision: 2
    }).toFormat();
    const liabities = Dinero({
        amount: Math.round(((summary?.sum_credits || 0) - (summary?.sum_debits || 0)) * 100),
        currency: summary?.currency || CurrencyCode.GBP,
        precision: 2
    }).toFormat();

    return (
        <Box mt={2} display="flex">
            <Box mr={1} minWidth="100px">
                <Paper
                    sx={{
                        p: loading ? 0 : 1
                    }}
                    variant="outlined"
                >
                    {loading ? (
                        <SkeletonComponent variant="rectangular" height={67} />
                    ) : (
                        <>
                            <Typography variant="h6" component="div" color="primary">
                                {summary?.total_transactions || 0}
                            </Typography>
                            <Typography variant="body2" component="div">
                                Transactions
                            </Typography>
                        </>
                    )}
                </Paper>
            </Box>
            <Box mr={1} minWidth="100px">
                <Paper
                    sx={{
                        p: loading ? 0 : 1
                    }}
                    variant="outlined"
                >
                    {loading ? (
                        <SkeletonComponent variant="rectangular" height={67} />
                    ) : (
                        <>
                            <Typography variant="h6" component="div" color="primary">
                                {debits}
                            </Typography>
                            <Typography variant="body2" component="div">
                                Debits
                            </Typography>
                        </>
                    )}
                </Paper>
            </Box>
            <Box mr={1} minWidth="100px">
                <Paper
                    sx={{
                        p: loading ? 0 : 1
                    }}
                    variant="outlined"
                >
                    {loading ? (
                        <SkeletonComponent variant="rectangular" height={67} />
                    ) : (
                        <>
                            <Typography variant="h6" component="div" color="primary">
                                {credits}
                            </Typography>
                            <Typography variant="body2" component="div">
                                Credits
                            </Typography>
                        </>
                    )}
                </Paper>
            </Box>
            <Box mr={1} minWidth="100px">
                <Paper
                    sx={{
                        p: loading ? 0 : 1
                    }}
                    variant="outlined"
                >
                    {loading ? (
                        <SkeletonComponent variant="rectangular" height={67} />
                    ) : (
                        <>
                            <Typography variant="h6" component="div" color="primary">
                                {liabities}
                            </Typography>
                            <Typography variant="body2" component="div">
                                Liabilities
                            </Typography>
                        </>
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

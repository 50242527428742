import React from 'react';
import { ChromePicker, Color, ColorResult } from 'react-color';
import { styled, Typography } from '@mui/material';
import { MuiTheme } from 'config/theme';

export interface MuiColorPickerProps {
    value: Color;
    onChange: (color: string) => void;
    fallbackToDefaultColour?: boolean;
}

const PREFIX = 'ColorPicker';

const classes = {
    color: `${PREFIX}-color`,
    swatch: `${PREFIX}-swatch`,
    popover: `${PREFIX}-popover`,
    cover: `${PREFIX}-cover`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.color}`]: {
        width: theme.spacing(6),
        height: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        border: `1px ${theme.palette.divider} solid`
    },
    [`& .${classes.swatch}`]: {
        width: '100%',
        padding: theme.spacing(0.5),
        paddingLeft: 0,
        paddingRight: 0,
        background: theme.palette.background.paper,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        borderRadius: '1px',
        display: 'inline-flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        cursor: 'pointer'
    },
    [`& .${classes.popover}`]: {
        position: 'absolute',
        zIndex: 2
    },
    [`& .${classes.cover}`]: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    }
}));

export const MuiColorPicker = (props: MuiColorPickerProps) => {
    const { value, onChange, fallbackToDefaultColour } = props;
    const [displayColorPicker, setDisplayColourPicker] = React.useState(false);
    const [color, setColor] = React.useState(
        value ?? (fallbackToDefaultColour === false ? undefined : MuiTheme.palette.primary.main)
    );
    const handleClick = React.useCallback(
        () => setDisplayColourPicker(!displayColorPicker),
        [displayColorPicker]
    );
    const handleClose = React.useCallback(() => setDisplayColourPicker(false), []);

    const handleChange = React.useCallback(
        (newColor: ColorResult) => {
            setColor(newColor.hex);
            onChange(newColor.hex);
        },
        [onChange]
    );
    return (
        <Root>
            <div className={classes.swatch} onClick={handleClick}>
                <div className={classes.color} style={{ backgroundColor: String(color) }} />
                <Typography>{color}</Typography>
            </div>
            {displayColorPicker ? (
                <div className={classes.popover}>
                    <div className={classes.cover} onClick={handleClose} />
                    <ChromePicker disableAlpha color={color} onChangeComplete={handleChange} />
                </div>
            ) : null}
        </Root>
    );
};

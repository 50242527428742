import { TenantAccess } from './TenantAccess';

export interface TenantAccessData {
    items: TenantAccess[];
    page: { count: number; limit: number; nextKey?: string };
}

// TODO: replace by real type guard
export function isTenantAccessItems(data: any): data is TenantAccessData {
    return data && data.items && data.page && Array.isArray(data.items);
}

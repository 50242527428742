import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { VoucherDetailCard } from './cards/VoucherDetailCard';
import { VoucherRedemptionsCard } from './cards/VoucherRedemptionsCard';
import { Voucher } from './models/VoucherModel';
import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { EditVoucherModal } from 'components/vouchers/modals/EditVoucherModal';
import { VoucherDetailsFormData } from 'components/vouchers/forms/VoucherDetailsForm';
import { voucherApi } from 'components/vouchers/voucherApi';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { FormikHelpers } from 'formik';
import {
    MESSAGE_DELETE_VOUCHER_ERROR,
    MESSAGE_DELETE_VOUCHER_SUCCESS,
    MESSAGE_EDIT_VOUCHER_ERROR,
    MESSAGE_EDIT_VOUCHER_SUCCESS
} from 'config/messages';
import { Row } from 'ui/Flex';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { useHistory } from 'react-router-dom';
import { MARKETING_VOUCHERS } from 'config/routes';

interface VoucherViewProps {
    voucher: Voucher;
    onEdit: (voucher: Voucher) => void;
}

export const VoucherView: React.FC<VoucherViewProps> = ({ onEdit, voucher }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isEditModalOpen, setEditModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleEditClick = React.useCallback(() => {
        setEditModalOpen(true);
    }, []);
    const handleEditModalClose = React.useCallback(() => {
        setEditModalOpen(false);
    }, []);
    const handleDeleteClick = React.useCallback(() => {
        setDeleteModalOpen(true);
    }, []);
    const handleDeleteModalClose = React.useCallback(() => {
        setDeleteModalOpen(false);
    }, []);
    const handleDeleteSubmit = React.useCallback(async () => {
        setLoading(true);
        const result = await voucherApi.delete(voucher?._id, { skipResponseBody: true });
        if (result.ok) {
            dispatch(enqueueSnackbar(MESSAGE_DELETE_VOUCHER_SUCCESS, { variant: 'success' }));
        } else {
            dispatch(enqueueSnackbar(MESSAGE_DELETE_VOUCHER_ERROR, { variant: 'error' }));
        }
        setLoading(false);
        history.replace(MARKETING_VOUCHERS);
    }, [dispatch, history, voucher?._id]);
    const handleEditSubmit = React.useCallback(
        async (
            { title, code, numGlobalRedemptions, numRedemptionsPerUser, duration }: VoucherDetailsFormData,
            formikHelpers: FormikHelpers<VoucherDetailsFormData>
        ) => {
            try {
                const response = await voucherApi.update(voucher._id, {
                    body: {
                        title,
                        code,
                        numGlobalRedemptions: Number(numGlobalRedemptions),
                        numRedemptionsPerUser: Number(numRedemptionsPerUser),
                        startDate: startOfDay(duration.startDate).toJSON(),
                        endDate: endOfDay(duration.endDate).toJSON()
                    }
                });

                if (!response.ok) {
                    dispatch(enqueueSnackbar(MESSAGE_EDIT_VOUCHER_ERROR, { variant: 'error' }));
                } else {
                    dispatch(enqueueSnackbar(MESSAGE_EDIT_VOUCHER_SUCCESS, { variant: 'success' }));
                    handleEditModalClose();
                    onEdit(response.body);
                }
            } finally {
                formikHelpers.setSubmitting(false);
            }
        },
        [voucher._id, dispatch, handleEditModalClose, onEdit]
    );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Row gutter>
                            <Button onClick={handleDeleteClick} color="error" variant="outlined">
                                Delete
                            </Button>
                            <Button onClick={handleEditClick} color="primary" variant="contained">
                                Edit
                            </Button>
                        </Row>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VoucherDetailCard voucher={voucher} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <VoucherRedemptionsCard voucher={voucher} />
                </Grid>
            </Grid>
            <EditVoucherModal
                initialData={{
                    ...voucher,
                    duration: { startDate: parseISO(voucher.startDate), endDate: parseISO(voucher.endDate) }
                }}
                open={isEditModalOpen}
                onClose={handleEditModalClose}
                onSubmit={handleEditSubmit}
            />
            <DeleteDialog
                open={!!isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                loading={loading}
                onDelete={handleDeleteSubmit}
                title={`Delete "${voucher?.title}"?`}
                protectionWord="DELETE"
                content="If you delete the voucher, you won’t be able to recover it. To confirm deletion, type 'DELETE' in the text input field."
            />
        </>
    );
};

import React from 'react';

interface IContext {
    onSelectLocation: (locationId: string) => void;
    selectedLocation: string;
}

export const LocationSelectByAppIdContext = React.createContext<IContext>({
    onSelectLocation: () => {},
    selectedLocation: ''
});

export const LocationSelectByAppIdContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
    children
}) => {
    const [selectedLocation, setSelectedLocation] = React.useState<string>('');
    const handleSelectLocation = React.useCallback((locationId: string) => {
        setSelectedLocation(locationId);
    }, []);

    return (
        <LocationSelectByAppIdContext.Provider
            value={{ onSelectLocation: handleSelectLocation, selectedLocation }}
        >
            {children}
        </LocationSelectByAppIdContext.Provider>
    );
};

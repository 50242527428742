import React from 'react';
import { Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Option } from 'lib/types';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import Help from '@mui/icons-material/HelpOutline';

export enum EIbsOrderNotesTextJustify {
    LEFT = 0,
    CENTER = 1,
    RIGHT = 2
}

export const ibsOrderNotesTextJustifyOptions: Option[] = [
    { label: 'Left', value: EIbsOrderNotesTextJustify.LEFT.toString() },
    { label: 'Center', value: EIbsOrderNotesTextJustify.CENTER.toString() },
    { label: 'Right', value: EIbsOrderNotesTextJustify.RIGHT.toString() }
];

export const ibsCondimentListOptions: Option[] = [
    { label: 'POS Condiment Lists', value: 'POS' },
    { label: 'Web Condiment Lists', value: 'WEB' },
    { label: 'Default', value: 'Default' }
];

export const IBSSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="API Username">
                    <Typography variant="body1">{settings?.ibs?.szUserName}</Typography>
                </SettingDisplay>
                <SettingDisplay label="API Password">
                    <Typography variant="body1">{settings?.ibs?.szPassword}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Web Orders Hostname">
                    <Typography variant="body1">{settings?.ibs?.ibsWebOrdersHost}</Typography>
                </SettingDisplay>
                <SettingDisplay label="App GUID">
                    <Typography variant="body1">{settings?.ibs?.szAppGUID}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Company Code">
                    <Typography variant="body1">{settings?.ibs?.szCompanyCode}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Enable Kiosk Orders Mode">
                    <Typography variant="body1">
                        {settings?.ibs?.useKioskOrder && <Done />}
                        {!settings?.ibs?.useKioskOrder && <Close />}
                    </Typography>
                </SettingDisplay>
                <SettingDisplay label="Enable Availability Web Notifications">
                    <Typography variant="body1">
                        {settings?.ibs?.webNotificationsEnabled && <Done />}
                        {!settings?.ibs?.webNotificationsEnabled && <Close />}
                    </Typography>
                </SettingDisplay>
                <SettingDisplay label="Filter Products by Zone">
                    {settings?.menu?.filterProductsByZone && <Done />}
                    {!settings?.menu?.filterProductsByZone && <Close />}
                </SettingDisplay>
                <SettingDisplay label="Order Notes Bold">
                    {settings?.ibs?.orderNotesTextBold && <Done />}
                    {!settings?.ibs?.orderNotesTextBold && <Close />}
                </SettingDisplay>
                <SettingDisplay label="Order Notes Double Width">
                    {settings?.ibs?.orderNotesTextDoubleWidth && <Done />}
                    {!settings?.ibs?.orderNotesTextDoubleWidth && <Close />}
                </SettingDisplay>
                <SettingDisplay label="Order Notes Double Height">
                    {settings?.ibs?.orderNotesTextDoubleHeight && <Done />}
                    {!settings?.ibs?.orderNotesTextDoubleHeight && <Close />}
                </SettingDisplay>
                <SettingDisplay label="Order Notes Align">
                    <Typography variant="body1">
                        {ibsOrderNotesTextJustifyOptions.find(
                            option => option.value === settings?.ibs?.orderNotesTextJustify?.toString()
                        )?.label ?? 'Center'}
                    </Typography>
                </SettingDisplay>
                <SettingDisplaySwitch
                    title="Quickpad Item Notes Enabled"
                    checked={settings?.orderNotesEnabled?.item}
                />
                <SettingDisplayText
                    title="Keyboard ID for Quickpad Item Notes"
                    text={settings?.ibs?.itemNoteModifierNo}
                />
                <SettingDisplayText
                    title="PLU for Quickpad Item Notes"
                    text={settings?.ibs?.itemNoteOffset}
                />
                <SettingDisplayText
                    title="PLU Sales Group for Quickpad Item Notes"
                    text={settings?.ibs?.itemNoteSalesGroupNo}
                />
                <SettingDisplayText title="Pre-Order Price Level" text={settings?.ibs?.PREORDER_priceLevel} />
                <SettingDisplayText
                    title="Order to Table Price Level"
                    text={settings?.ibs?.ORDER_TO_TABLE_priceLevel}
                />
                <SettingDisplayText title="Pay at Table Price Level" text={settings?.ibs?.TABLE_priceLevel} />
                <SettingDisplayText title="Tab Price Level" text={settings?.ibs?.TAB_priceLevel} />
                <SettingDisplayText title="Condiment List Type" text={settings?.ibs?.condimentListOption} />
                <SettingDisplaySwitch
                    title="Enable Access Menu Manager (AMM)"
                    checked={settings?.accessMenuManagerEnabled}
                />
                <SettingDisplayText
                    title="AMM Host"
                    text={settings?.accessMenuManager?.host}
                    variant="body1"
                />
                <SettingDisplayText
                    title="AMM API Key"
                    text={settings?.accessMenuManager?.apiKey}
                    variant="body1"
                />
                <SettingDisplayText
                    title="AMM Password"
                    text={settings?.accessMenuManager?.password}
                    variant="body1"
                />
                <SettingDisplayText
                    title="Preorder Channel ID"
                    text={settings?.accessMenuManager?.PREORDER_channelId}
                    variant="body1"
                />
                <SettingDisplayText
                    title="Order to Table Channel ID"
                    text={settings?.accessMenuManager?.ORDER_TO_TABLE_channelId}
                    variant="body1"
                />
                <SettingDisplayText
                    title="Tab Channel ID"
                    text={settings?.accessMenuManager?.TAB_channelId}
                    variant="body1"
                />
                <SettingDisplayText
                    title="Table Channel ID"
                    text={settings?.accessMenuManager?.TABLE_channelId}
                    variant="body1"
                />
                <SettingDisplay label="Payment Media Type">
                    <Typography variant="body1">{settings?.ibs?.paymentMediaType}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Timezone Name">
                    <Typography variant="body1">{settings?.ibs?.ibsPosIANATimeZone}</Typography>
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Location ID">
                    <Typography variant="body1">{settings?.ibs?.ibsLocationId}</Typography>
                </SettingDisplay>
                <SettingDisplay
                    label="Location ID for Menu"
                    tooltipText="The IBS location which contains the PLUs for your AMM menu, if different from above."
                    tooltipIcon={<Help />}
                >
                    <Typography variant="body1">{settings?.ibs?.menuIbsLocationId}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Payment Media Type">
                    <Typography variant="body1">{settings?.ibs?.paymentMediaType}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Timezone Name">
                    <Typography variant="body1">{settings?.ibs?.ibsPosIANATimeZone}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Seating Area">
                    <Typography variant="body1">{settings?.ibs?.PREORDER_seatingArea}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Order to Table Seating Area">
                    <Typography variant="body1">{settings?.ibs?.ORDER_TO_TABLE_seatingArea}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Pay at Table Seating Area">
                    <Typography variant="body1">{settings?.ibs?.TABLE_seatingArea}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Tab Seating Area">
                    <Typography variant="body1">{settings?.ibs?.TAB_seatingArea}</Typography>
                </SettingDisplay>
                <SettingDisplaySwitch
                    title="Filter Products by Zone"
                    checked={settings?.menu?.filterProductsByZone}
                />
                <SettingDisplaySwitch
                    title="Quickpad Item Notes Enabled"
                    checked={settings?.orderNotesEnabled?.item}
                />
                <SettingDisplayText title="Pre-Order Price Level" text={settings?.ibs?.PREORDER_priceLevel} />
                <SettingDisplayText
                    title="Order to Table Price Level"
                    text={settings?.ibs?.ORDER_TO_TABLE_priceLevel}
                />
                <SettingDisplayText title="Pay at Table Price Level" text={settings?.ibs?.TABLE_priceLevel} />
                <SettingDisplayText title="Tab Price Level" text={settings?.ibs?.TAB_priceLevel} />
            </>
        )}
    </>
);

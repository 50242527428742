/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { AwardFormStep, StepUpdateMetadata } from '../AwardEnrichmentEditStepper';

export interface AwardGeneralFormData {
    title: string;
    order: string;
    description: string;
    loyaltyRewardId: string;
}

interface AwardGeneralFormProps {
    onSubmit: (data: AwardGeneralFormData) => void;
    initialValues?: AwardGeneralFormData;
    open: boolean;
    isEdit?: boolean;
    isLoading?: boolean;
    isFormValid?: boolean;
    onUpdate?: (values: StepUpdateMetadata) => void;
}

export const awardGeneralDefaultFormValues: AwardGeneralFormData = {
    title: '',
    order: '',
    description: '',
    loyaltyRewardId: ''
};

const validationSchema = Yup.object().shape({
    loyaltyRewardId: Yup.string().required('This field is required.'),
    title: Yup.string(),
    order: Yup.string(),
    internalTitle: Yup.string().notRequired(),
    description: Yup.string()
});

export const AwardGeneralForm: React.FC<AwardGeneralFormProps> = ({
    onSubmit,
    initialValues,
    open,
    isEdit,
    isLoading,
    onUpdate
}) => {
    if (!open) {
        return null;
    }
    return (
        <MuiForm
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues || awardGeneralDefaultFormValues}
        >
            {({ submitForm, values, isValid, dirty }) => {
                const handleUpdate = () => {
                    onUpdate({ values, type: AwardFormStep.GENERAL });
                };
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box padding={3} maxWidth={600}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" color="secondary">
                                                1. Define
                                            </Typography>
                                            <Typography variant="body2">
                                                These settings are only visible to yourself.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="loyaltyRewardId"
                                                label={
                                                    <MuiFormLabel required>Loyalty Reward ID</MuiFormLabel>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="title"
                                                label="Title"
                                                description="Set a title for use on receipts and baskets (this title will not display on the award visualisation)."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="order"
                                                type="number"
                                                placeholder="123"
                                                label="Order"
                                                description="Enter a number for the position you want this award to show in your customers award carousel."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField
                                                name="description"
                                                multiline
                                                maxRows={3}
                                                label="Description"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Row flex={1} align="flex-end" gutter>
                                    {isEdit && !isValid && (
                                        <Tooltip title="You should fill all fields here to update this Award.">
                                            <div>
                                                <LoadingButton
                                                    disabled
                                                    loading={isLoading}
                                                    onClick={handleUpdate}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    Update
                                                </LoadingButton>
                                            </div>
                                        </Tooltip>
                                    )}
                                    {isEdit && isValid && (
                                        <LoadingButton
                                            disabled={isLoading || !dirty}
                                            loading={isLoading}
                                            onClick={handleUpdate}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Update & Close
                                        </LoadingButton>
                                    )}
                                    <Button onClick={submitForm} color="primary" variant="contained">
                                        Next
                                    </Button>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};

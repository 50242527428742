import React from 'react';
import { useDropzone } from 'react-dropzone';
import { styled, Typography } from '@mui/material';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';
import clsx from 'clsx';
import { Row } from 'ui/Flex';

const PREFIX = 'FormFieldDropZone';

const classes = {
    image: `${PREFIX}-image`,
    label: `${PREFIX}-label`,
    activeLabel: `${PREFIX}-activeLabel`,
    text: `${PREFIX}-text`
};

const StyledDiv = styled('div')<FormFieldDropZone>(({ theme, fullWidth, aspect, disableCrop }) => ({
    marginTop: theme.spacing(1),
    width: (fullWidth && '100%') || (aspect ? theme.spacing(18 * aspect) : '100%'),
    maxWidth: '100%',
    cursor: 'pointer',
    display: 'block',
    overflow: 'hidden',
    boxSizing: 'content-box',
    border: disableCrop ? `1px solid ${theme.palette.divider}` : `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    '&:after': {
        content: 'no-open-quote',
        display: 'block',
        paddingTop: `${aspect ? 100 * (1 / aspect) : 30}%`
    },
    [`& .${classes.image}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: '100%',
        borderRadius: theme.shape.borderRadius
    },
    [`& .${classes.label}`]: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        fontSize: 28,
        transform: 'translate(-50%, -50%)',
        color: theme.palette.divider
    },
    [`& .${classes.activeLabel}`]: {
        color: theme.palette.common.white
    },
    [`& .${classes.text}`]: {
        fontSize: '0.60rem'
    }
}));

interface FormFieldDropZone {
    onSelect: (file: File[]) => void;
    aspect?: number;
    imageUrl?: string;
    fullWidth?: boolean;
    disableCrop?: boolean;
    className?: string;
}

export const FormFieldDropZone: React.FC<FormFieldDropZone> = function (props) {
    const { onSelect, imageUrl, className } = props;

    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            onSelect(acceptedFiles);
        },
        [onSelect]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/png',
        multiple: false
    });
    return (
        <StyledDiv {...getRootProps()} className={className}>
            <input {...getInputProps()} />
            {imageUrl && <img className={classes.image} src={imageUrl} />}

            <Row gutter valign="center" className={clsx(classes.label, imageUrl && classes.activeLabel)}>
                {!imageUrl &&
                    (isDragActive ? (
                        <Typography className={classes.text} color="inherit">
                            Upload photo
                        </Typography>
                    ) : (
                        <Typography className={classes.text} color="inherit">
                            Drag or upload your photo
                        </Typography>
                    ))}
                <AddPhotoAlternate color="inherit" fontSize="inherit" />
            </Row>
        </StyledDiv>
    );
};

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-danger */
import React from 'react';
import { Grid, NoSsr, Typography } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import dompurify from 'dompurify';
import { IconButton } from 'ui/buttons/IconButton';
import { MuiModal } from 'ui/MuiModal';

interface LocationAlternativeOpeningHoursProps {
    openingHours: string;
    onClose: () => void;
}

function LocationAlternativeOpeningHours({ onClose, openingHours }: LocationAlternativeOpeningHoursProps) {
    const cleanHtml = dompurify.sanitize(openingHours.replace(/\\n/g, ''));
    return (
        <Grid container justifyContent="flex-end" sx={{ maxWidth: 512 }}>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={10}>
                        <Typography variant="caption" color="textSecondary">
                            This is a representative view of your custom opening hours and
                            <br /> may differ slightly from what you see in the app
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose} size="large">
                            <Clear />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <NoSsr>
                    <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
                </NoSsr>
            </Grid>
        </Grid>
    );
}

interface LocationAlternativeOpeningHoursModalProps {
    open: boolean;
    onClose: () => void;
    openingHours: string;
}

export function LocationAlternativeOpeningHoursModal(props: LocationAlternativeOpeningHoursModalProps) {
    const { open, onClose, openingHours } = props;
    return (
        <MuiModal open={open} onClose={onClose}>
            <LocationAlternativeOpeningHours openingHours={openingHours} onClose={onClose} />
        </MuiModal>
    );
}

import React from 'react';
import { SkeletonComponent, SkeletonProps } from './SkeletonComponent';

interface FallbackSkeletonProps extends SkeletonProps {
    loading?: boolean;
    children: React.ReactNode;
}

export const FallbackSkeleton: React.FC<FallbackSkeletonProps> = ({ loading, children, ...rest }) => {
    if (loading) {
        return <SkeletonComponent {...rest} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

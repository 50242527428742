import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { BrandingSettings } from 'components/settings/BrandingSettings';
import { LocationScopedSettings } from 'components/settings/LocationScopedSettings';
import { MERCHANT_MOBILE_SETTINGS } from 'config/routes';

export const BrandingSettingsPage: React.FC = () => (
    <MainLayout
        pageName="Branding Settings"
        pageDescription="Adjust your brand's settings and media."
        breadcrumbs={[{ label: 'Settings', url: MERCHANT_MOBILE_SETTINGS }]}
    >
        <LocationScopedSettings globalComponent={BrandingSettings} />
    </MainLayout>
);

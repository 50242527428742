import React from 'react';
import logger from 'lib/logger';
import { UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';
import { ELoyaltyTierState } from 'components/metrics/model/loyalty-tier';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { useHistory } from 'react-router-dom';

const TIERED_LOYALTY_TAG = 'tiered_loyalty';

export function useUserMetricDefinition() {
    const { settings, loading: settingsLoading } = useSelector((state: ApplicationState) => state.settings);
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [metricDefinition, setMetricDefinition] = React.useState<UserMetricDefinition>();
    const [isSetup, setIsSetup] = React.useState(false);
    const loadTieredLoyalty = React.useCallback(() => {
        setLoading(true);
        metricsApi
            .getList({ queryParameters: [{ key: 'tag', value: TIERED_LOYALTY_TAG }] })
            .then(result => {
                if (!result.ok) {
                    throw new Error(result.body.message);
                }
                const [definition] = result.body.items;
                setMetricDefinition(definition);
                if (!definition) {
                    setIsSetup(true);
                }
            })
            .catch(e => {
                logger.error(e.message, e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    React.useEffect(() => {
        loadTieredLoyalty();
    }, [loadTieredLoyalty]);
    const updateMetricDefinition = React.useCallback((value: UserMetricDefinition) => {
        setMetricDefinition(value);
    }, []);
    const finishSetup = React.useCallback(() => {
        setIsSetup(false);
    }, []);
    const state = React.useMemo(() => {
        if (!metricDefinition) {
            return undefined;
        }
        if (metricDefinition.realtime) {
            if (metricDefinition.scheduled) {
                return ELoyaltyTierState.SCHEDULED_AND_REALTIME;
            }
            return ELoyaltyTierState.REALTIME;
        }
        if (metricDefinition.scheduled) {
            return ELoyaltyTierState.SCHEDULED;
        }
        return ELoyaltyTierState.INACTIVE;
    }, [metricDefinition]);
    const isValidDefinition = React.useMemo(
        () =>
            !!metricDefinition &&
            !!metricDefinition?.listeners?.length &&
            !!Object.keys(metricDefinition?.metrics).length,
        [metricDefinition]
    );
    React.useEffect(() => {
        if (settings && !settingsLoading && !settings?.premiumLoyaltyEnabled) {
            history.replace('/');
        }
    }, [history, settings, settings?.premiumLoyaltyEnabled, settingsLoading]);
    return {
        loading,
        metricDefinition,
        isSetup,
        setMetricDefinition: updateMetricDefinition,
        finishSetup,
        state,
        isValidMetricDefinition: isValidDefinition,
        loadTieredLoyalty
    };
}

import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { GiftingLiabilityFilter } from 'components/analytics/gifting/liability/model/giftingLiabilityFilter';

export type OnGiftingLiabilityFilterChange = (
    field: keyof GiftingLiabilityFilter,
    value: string | string[] | DateRangePickerValue
) => void;

export function useGiftingLiabilityFilter(): [GiftingLiabilityFilter, OnGiftingLiabilityFilterChange] {
    const [filter, setFilter] = React.useState<GiftingLiabilityFilter>({
        date: {
            startDate: new Date(),
            endDate: new Date()
        }
    });
    const onFilterChange = React.useCallback((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    return [filter, onFilterChange];
}

/* eslint-disable @typescript-eslint/naming-convention */
export const getGiftingActivityFilterBody = (filter: Partial<GiftingLiabilityFilter>) => ({
    start_date: filter.date?.startDate?.toISOString(),
    end_date: filter.date?.endDate?.toISOString() ?? filter.date?.startDate?.toISOString()
});

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Divider, styled, Typography } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { uploadFile } from 'components/content/uploadFile';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { MuiForm } from 'lib/Form';
import { FileInputFormField } from 'lib/form/fields/FileInputFormField';
import { ImageFormField } from 'lib/form/fields/ImageFormField';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { MuiCardForm } from 'lib/form/MuiCardForm';
import { parseFloatOrUndefined } from 'lib/helpers';
import { useMediaCheck } from 'lib/hooks/useMediaCheck';
import logger from 'lib/logger';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ApplicationState } from 'store/store';
import { ILocationScopedSettingsComponentProps } from './LocationScopedSettings';
import { SettingDisplaySwitch } from './SettingDisplaySwitch';
import { SettingDisplayText } from './SettingDisplayText';
import { InputAnimationPicker } from './InputAnimationPicker';
import { preloadImage } from 'lib/imageHelpers';
import { isDefined } from 'lib/typeguards';
import { SettingDisplayFile } from './SettingDisplayFile';

export const S3_MEDIA_URL = 'https://media.pepperhq.com';

const PREFIX = 'BrandingSettings';

const classes = {
    settingsCard: `${PREFIX}-settingsCard`,
    displayClassName: `${PREFIX}-displayClassName`,
    titleWrapper: `${PREFIX}-titleWrapper`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.settingsCard}`]: {
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.displayClassName}`]: {
        padding: theme.spacing(2)
    },
    [`& .${classes.titleWrapper}`]: {
        marginLeft: theme.spacing(1),
        minHeight: theme.spacing(5.25)
    }
}));

enum EBrandingImage {
    HOME_BACKGROUND_IMAGE = 'HOME_BACKGROUND_IMAGE',
    LAUNCH_IMAGE = 'LAUNCH_IMAGE',
    LOGO_OVERLAY = 'LOGO_OVERLAY',
    ALERT_BACKGROUND = 'ALERT_BACKGROUND',
    APP_ICON = 'APP_ICON',
    IOS_BRAND_ANCHOR_IMAGE = 'IOS_BRAND_ANCHOR_IMAGE',
    ANDROID_BRAND_ANCHOR_IMAGE = 'ANDROID_BRAND_ANCHOR_IMAGE',
    BRAND_LOADING_IMAGE = 'BRAND_LOADING_IMAGE',
    WEB_LOCATION_IMAGE = 'WEB_LOCATION_IMAGE',
    WEB_BACKGROUND_IMAGE = 'WEB_BACKGROUND_IMAGE',
    LOADING_ANIMATION = 'LOADING_ANIMATION',
    PAY_AT_TABLE_MERCHANT_LOGO = 'PAY_AT_TABLE_MERCHANT_LOGO'
}

const aspectRatios: { [key in EBrandingImage]: { w: number; h: number } } = {
    HOME_BACKGROUND_IMAGE: { w: 640, h: 655 },
    LAUNCH_IMAGE: { w: 1242, h: 2208 },
    LOGO_OVERLAY: { w: 1242, h: 2208 },
    ALERT_BACKGROUND: { w: 520, h: 289 },
    APP_ICON: { w: 1024, h: 1024 },
    IOS_BRAND_ANCHOR_IMAGE: { w: 640, h: 480 },
    ANDROID_BRAND_ANCHOR_IMAGE: { w: 640, h: 437 },
    BRAND_LOADING_IMAGE: { w: 400, h: 400 },
    WEB_LOCATION_IMAGE: { w: 200, h: 26 },
    WEB_BACKGROUND_IMAGE: { w: 2629, h: 989 },
    LOADING_ANIMATION: { w: 200, h: 200 },
    PAY_AT_TABLE_MERCHANT_LOGO: { w: 200, h: 140 }
};

const calculatedAspectRatios: { [key in EBrandingImage]: number } = {
    HOME_BACKGROUND_IMAGE:
        aspectRatios[EBrandingImage.HOME_BACKGROUND_IMAGE].w /
        aspectRatios[EBrandingImage.HOME_BACKGROUND_IMAGE].h,
    LAUNCH_IMAGE: aspectRatios[EBrandingImage.LAUNCH_IMAGE].w / aspectRatios[EBrandingImage.LAUNCH_IMAGE].h,
    LOGO_OVERLAY: aspectRatios[EBrandingImage.LOGO_OVERLAY].w / aspectRatios[EBrandingImage.LOGO_OVERLAY].h,
    ALERT_BACKGROUND:
        aspectRatios[EBrandingImage.ALERT_BACKGROUND].w / aspectRatios[EBrandingImage.ALERT_BACKGROUND].h,
    APP_ICON: aspectRatios[EBrandingImage.APP_ICON].w / aspectRatios[EBrandingImage.APP_ICON].h,
    IOS_BRAND_ANCHOR_IMAGE:
        aspectRatios[EBrandingImage.IOS_BRAND_ANCHOR_IMAGE].w /
        aspectRatios[EBrandingImage.IOS_BRAND_ANCHOR_IMAGE].h,
    ANDROID_BRAND_ANCHOR_IMAGE:
        aspectRatios[EBrandingImage.ANDROID_BRAND_ANCHOR_IMAGE].w /
        aspectRatios[EBrandingImage.ANDROID_BRAND_ANCHOR_IMAGE].h,
    BRAND_LOADING_IMAGE:
        aspectRatios[EBrandingImage.BRAND_LOADING_IMAGE].w /
        aspectRatios[EBrandingImage.BRAND_LOADING_IMAGE].h,
    WEB_LOCATION_IMAGE:
        aspectRatios[EBrandingImage.WEB_LOCATION_IMAGE].w / aspectRatios[EBrandingImage.WEB_LOCATION_IMAGE].h,
    WEB_BACKGROUND_IMAGE:
        aspectRatios[EBrandingImage.WEB_BACKGROUND_IMAGE].w /
        aspectRatios[EBrandingImage.WEB_BACKGROUND_IMAGE].h,
    LOADING_ANIMATION:
        aspectRatios[EBrandingImage.LOADING_ANIMATION].w / aspectRatios[EBrandingImage.LOADING_ANIMATION].h,
    PAY_AT_TABLE_MERCHANT_LOGO:
        aspectRatios[EBrandingImage.PAY_AT_TABLE_MERCHANT_LOGO].w /
        aspectRatios[EBrandingImage.PAY_AT_TABLE_MERCHANT_LOGO].h
};

export const BrandingSettings: React.FC<ILocationScopedSettingsComponentProps> = ({
    settings,
    onSettingsSaved,
    locationId
}) => {
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);
    const [primaryCustomFontSrc, primaryCustomFontLoading, checkPrimaryFont, getCustomFontUrl] =
        useMediaCheck(`${S3_MEDIA_URL}/tenants/${tenantId}/app_media/customfont.otf`, 'customfont.otf');
    const [secondaryCustomFontSrc, secondaryCustomFontLoading, checkSecondaryFont, getSecondaryFontUrl] =
        useMediaCheck(
            `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/secondary_custom_font.otf`,
            'secondary_custom_font.otf'
        );

    const [date, setDate] = React.useState(new Date());
    const [animationLoading, setAnimationLoading] = React.useState(true);
    const [animations, setAnimations] = React.useState<string[]>([]);

    const handleSubmitFonts = React.useCallback(
        async (values: Record<string, any>) => {
            try {
                if (values.customFont) {
                    const { ok } = await uploadFile(
                        new File([values.customFont.slice()], values.customFont.name, {
                            type: 'font/otf'
                        }),
                        'customfont',
                        'app_media'
                    );
                    if (ok) {
                        checkPrimaryFont();
                    }
                }

                if (values.secondaryCustomFont) {
                    const { ok } = await uploadFile(
                        new File([values.secondaryCustomFont.slice()], values.secondaryCustomFont.name, {
                            type: 'font/otf'
                        }),
                        'secondary_custom_font',
                        'app_media'
                    );
                    if (ok) {
                        checkSecondaryFont();
                    }
                }
                let settingsToUpdate: IPublicAndPrivateSettings = {};

                // Common Settings
                if (locationId === undefined) {
                    settingsToUpdate = {
                        ...settingsToUpdate,
                        app: {
                            fontScale: parseFloatOrUndefined(values.customFontScale),
                            lightStatusBarContent: values.lightStatusBarContent
                        }
                    };
                }
                if (!Object.keys(settingsToUpdate).length) {
                    // No need to update nothing
                    return true;
                }
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await onSettingsSaved();
                return true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                return false;
            }
        },
        [locationId, onSettingsSaved, checkPrimaryFont, checkSecondaryFont]
    );

    const handleSubmitAnimation = React.useCallback(async (values: { file: File; fileName: string }) => {
        try {
            if (values) {
                const { file, fileName } = values;
                const { ok } = await uploadFile(file, fileName, 'web_loading_images');
                if (ok) {
                    setDate(new Date());
                }
            }
        } catch (err) {
            logger.error(`Error Updating Animation: ${JSON.stringify(err)}`);
        }
    }, []);

    const mediaInitialValues = React.useMemo(() => {
        const date = new Date().toTimeString();
        return {
            homeBackgroundImage: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/home_background.png?${date}`,
            // This is meant to generate 4, 55 and 47 for phone sizes. Might have to be manual for now.
            launchImage: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/launchimage4.png?${date}`,
            logoOverlay: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/launchimagelogooverlay.png?${date}`,
            alertBackground: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/alert_background.png?${date}`,
            appIcon: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/icon_itunes.png?${date}`,
            iosBrandAnchorImage: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/title_brand_image.png?${date}`,
            androidBrandAnchorImage: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/title_brand_image_android.png?${date}`,
            brandLoadingImage: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/anchor_loading_logo.png?${date}`,
            webLocationImage: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/web_icon.svg?${date}`,
            webDesktopBackground: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/web_desktop_background.png?${date}`,
            payAtTableMerchantLogo: `${S3_MEDIA_URL}/tenants/${tenantId}/app_media/pat_logo.png?${date}`
        };
    }, [tenantId]);

    const animationInitialValues = React.useMemo(
        () => [
            `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading1.svg?${date}`,
            `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading2.svg?${date}`,
            `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading3.svg?${date}`,
            `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading4.svg?${date}`,
            `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading5.svg?${date}`,
            `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading6.svg?${date}`
        ],
        [date, tenantId]
    );

    React.useEffect(() => {
        setAnimationLoading(true);
        Promise.all([
            preloadImage(animationInitialValues[0]),
            preloadImage(animationInitialValues[1]),
            preloadImage(animationInitialValues[2]),
            preloadImage(animationInitialValues[3]),
            preloadImage(animationInitialValues[4]),
            preloadImage(animationInitialValues[5])
        ])
            .then(result => {
                const animations = result.map((item, index) => {
                    if (isDefined(item)) {
                        return `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading${
                            index + 1
                        }.svg?${date}`;
                    }
                    if (!isDefined(item)) {
                        return `${S3_MEDIA_URL}/tenants/${tenantId}/web_loading_images/loading${
                            index + 1
                        }.gif?${date}`;
                    }
                });
                setAnimations(animations);
                setAnimationLoading(false);
            })
            .catch(error => logger.error(`Error in to load animations ${error}`));
    }, [animationInitialValues, date, mediaInitialValues, tenantId]);

    // This function does nothing. Image Form Field auto saves
    const handleMediaSubmit = React.useCallback(() => {}, []);

    return (
        <StyledBox minWidth="500px">
            {/* Cards for each settings block */}
            <MuiCardForm
                title="Style"
                initialValues={{
                    customFont: undefined,
                    customFontScale: settings?.app?.fontScale,
                    lightStatusBarContent: settings?.app?.lightStatusBarContent ?? false
                }}
                onSubmit={handleSubmitFonts}
                childrenWhileDisplaying={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplayFile
                            title="Primary Custom Font"
                            buildtimeAndroid
                            buildtimeIOS
                            description="Used on Primary CTAs, screen titles and section titles"
                            noFileText="Click Edit to Upload Primary Custom Font"
                            variant="body2"
                            url={getCustomFontUrl()}
                            fileName={primaryCustomFontSrc}
                        />
                        <SettingDisplayFile
                            title="Secondary Custom Font"
                            buildtimeAndroid
                            buildtimeIOS
                            description="Used everywhere else in the app"
                            noFileText="Click Edit to Upload Secondary Custom Font"
                            variant="body2"
                            url={getSecondaryFontUrl()}
                            fileName={secondaryCustomFontSrc}
                        />
                        <SettingDisplayText
                            title="Custom Font Scale"
                            text={settings?.app?.fontScale}
                            buildtimeAndroid
                            buildtimeIOS
                        />
                        <SettingDisplaySwitch
                            title="Light Status Bar Content"
                            buildtimeIOS
                            checked={settings?.app?.lightStatusBarContent}
                        />
                    </Box>
                }
                childrenWhileEditing={
                    <Box display="flex" flexDirection="column">
                        <SettingDisplay
                            label="Primary Custom Font"
                            buildtimeAndroid
                            buildtimeIOS
                            description="Used on Primary CTAs, screen titles and section titles"
                        >
                            <FileInputFormField
                                name="customFont"
                                attributes={{ accept: '.otf' }}
                                path={primaryCustomFontSrc}
                                isLoading={primaryCustomFontLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay
                            label="Secondary Custom Font"
                            description="Used everywhere else in the app"
                            buildtimeAndroid
                            buildtimeIOS
                        >
                            <FileInputFormField
                                name="secondaryCustomFont"
                                attributes={{ accept: '.otf' }}
                                path={secondaryCustomFontSrc}
                                isLoading={secondaryCustomFontLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Custom Font Scale" buildtimeAndroid buildtimeIOS>
                            <NumberFormField name="customFontScale" />
                        </SettingDisplay>
                        <SettingDisplay label="Light Status Bar Content" buildtimeIOS>
                            <SwitchFormField name="lightStatusBarContent" />
                        </SettingDisplay>
                    </Box>
                }
                className={classes.settingsCard}
                boxClassName={classes.displayClassName}
            />

            <Card className={classes.settingsCard}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding={1}
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.titleWrapper}
                    >
                        <Typography variant="h6">IOS & Android Media</Typography>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.displayClassName}>
                    <MuiForm onSubmit={handleMediaSubmit} initialValues={mediaInitialValues}>
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay
                                label="Home Background Image"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.HOME_BACKGROUND_IMAGE.w}px by ${aspectRatios.HOME_BACKGROUND_IMAGE.h}px PNG`}
                            >
                                <ImageFormField
                                    name="homeBackgroundImage"
                                    aspect={calculatedAspectRatios.HOME_BACKGROUND_IMAGE}
                                    fileName="home_background"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Launch Image"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.LAUNCH_IMAGE.w}px by ${aspectRatios.LAUNCH_IMAGE.h}px PNG`}
                            >
                                <ImageFormField
                                    name="launchImage"
                                    aspect={calculatedAspectRatios.LAUNCH_IMAGE}
                                    fileName="launchimage55"
                                    uploadFolder="app_media"
                                    additionalUploads={[
                                        {
                                            width: 750,
                                            height: 1334,
                                            uploadFolder: 'app_media',
                                            fileName: 'launchimage47',
                                            format: 'PNG'
                                        },
                                        {
                                            width: 640,
                                            height: 1136,
                                            uploadFolder: 'app_media',
                                            fileName: 'launchimage4',
                                            format: 'PNG'
                                        }
                                    ]}
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Logo Overlay"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.LOGO_OVERLAY.w}px by ${aspectRatios.LOGO_OVERLAY.h}px PNG`}
                            >
                                <ImageFormField
                                    name="logoOverlay"
                                    aspect={calculatedAspectRatios.LOGO_OVERLAY}
                                    fileName="launchimagelogooverlay"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Alert Background"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.ALERT_BACKGROUND.w}px by ${aspectRatios.ALERT_BACKGROUND.h}px PNG`}
                            >
                                <ImageFormField
                                    name="alertBackground"
                                    aspect={calculatedAspectRatios.ALERT_BACKGROUND}
                                    fileName="alert_background"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="App Icon"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.APP_ICON.w}px by ${aspectRatios.APP_ICON.h}px PNG`}
                            >
                                <ImageFormField
                                    name="appIcon"
                                    aspect={calculatedAspectRatios.APP_ICON}
                                    fileName="icon_itunes"
                                    uploadFolder="app_media"
                                    additionalUploads={[
                                        {
                                            width: 80,
                                            height: 80,
                                            uploadFolder: 'app_media',
                                            fileName: 'icon_40',
                                            format: 'PNG'
                                        },
                                        {
                                            width: 120,
                                            height: 120,
                                            uploadFolder: 'app_media',
                                            fileName: 'icon_60',
                                            format: 'PNG'
                                        },
                                        {
                                            width: 152,
                                            height: 152,
                                            uploadFolder: 'app_media',
                                            fileName: 'icon_76',
                                            format: 'PNG'
                                        }
                                    ]}
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="IOS Brand Anchor Image"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.IOS_BRAND_ANCHOR_IMAGE.w}px by ${aspectRatios.IOS_BRAND_ANCHOR_IMAGE.h}px PNG`}
                            >
                                <ImageFormField
                                    name="iosBrandAnchorImage"
                                    aspect={calculatedAspectRatios.IOS_BRAND_ANCHOR_IMAGE}
                                    fileName="title_brand_image"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Android Brand Anchor Image"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.ANDROID_BRAND_ANCHOR_IMAGE.w}px by ${aspectRatios.ANDROID_BRAND_ANCHOR_IMAGE.h}px PNG`}
                            >
                                <ImageFormField
                                    name="androidBrandAnchorImage"
                                    aspect={calculatedAspectRatios.ANDROID_BRAND_ANCHOR_IMAGE}
                                    fileName="title_brand_image_android"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Brand Loading Image"
                                buildtimeAndroid
                                buildtimeIOS
                                description={`${aspectRatios.BRAND_LOADING_IMAGE.w}px by ${aspectRatios.BRAND_LOADING_IMAGE.h}px PNG`}
                            >
                                <ImageFormField
                                    name="brandLoadingImage"
                                    aspect={calculatedAspectRatios.BRAND_LOADING_IMAGE}
                                    fileName="anchor_loading_logo"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                        </Box>
                    </MuiForm>
                </Box>
            </Card>

            <Card className={classes.settingsCard}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding={1}
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.titleWrapper}
                    >
                        <Typography variant="h6">Web Media</Typography>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.displayClassName}>
                    <MuiForm onSubmit={handleMediaSubmit} initialValues={mediaInitialValues}>
                        <Box display="flex" flexDirection="column">
                            <SettingDisplay
                                label="Web Location Image"
                                description={`${aspectRatios.WEB_LOCATION_IMAGE.w}px by ${aspectRatios.WEB_LOCATION_IMAGE.h}px SVG`}
                            >
                                <ImageFormField
                                    name="webLocationImage"
                                    aspect={calculatedAspectRatios.WEB_LOCATION_IMAGE}
                                    fileName="web_icon"
                                    uploadFolder="app_media"
                                    unremovable
                                    disableCrop
                                    accept="image/svg+xml"
                                    croppable={false}
                                />
                            </SettingDisplay>
                            <SettingDisplay
                                label="Desktop Background Image"
                                description={`${aspectRatios.WEB_BACKGROUND_IMAGE.w}px by ${aspectRatios.WEB_BACKGROUND_IMAGE.h}px PNG`}
                            >
                                <ImageFormField
                                    name="webDesktopBackground"
                                    aspect={calculatedAspectRatios.WEB_BACKGROUND_IMAGE}
                                    fileName="web_desktop_background"
                                    uploadFolder="app_media"
                                    unremovable
                                />
                            </SettingDisplay>
                        </Box>
                    </MuiForm>

                    <Box display="flex" flexDirection="column">
                        <SettingDisplay
                            label="Loading Animation 1"
                            description={
                                'You must upload a file for all 6 loading animations. You can upload the same file for each animation. SVG, GIF'
                            }
                        >
                            <InputAnimationPicker
                                name="loading1"
                                path={animations[0]}
                                uploadFolder="web_loading_images"
                                accept=".svg, .gif"
                                postAnimationFile={handleSubmitAnimation}
                                loading={animationLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Loading Animation 2" description={'SVG, GIF'}>
                            <InputAnimationPicker
                                name="loading2"
                                path={animations[1]}
                                uploadFolder="web_loading_images"
                                accept=".svg, .gif"
                                postAnimationFile={handleSubmitAnimation}
                                loading={animationLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Loading Animation 3" description={' SVG, GIF'}>
                            <InputAnimationPicker
                                name="loading3"
                                path={animations[2]}
                                uploadFolder="web_loading_images"
                                accept=".svg, .gif"
                                postAnimationFile={handleSubmitAnimation}
                                loading={animationLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Loading Animation 4" description={' SVG, GIF'}>
                            <InputAnimationPicker
                                name="loading4"
                                path={animations[3]}
                                uploadFolder="web_loading_images"
                                accept=".svg, .gif"
                                postAnimationFile={handleSubmitAnimation}
                                loading={animationLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Loading Animation 5" description={'SVG, GIF'}>
                            <InputAnimationPicker
                                name="loading5"
                                path={animations[4]}
                                uploadFolder="web_loading_images"
                                accept=".svg, .gif"
                                postAnimationFile={handleSubmitAnimation}
                                loading={animationLoading}
                            />
                        </SettingDisplay>
                        <SettingDisplay label="Loading Animation 6" description={'SVG, GIF'}>
                            <InputAnimationPicker
                                name="loading6"
                                path={animations[5]}
                                uploadFolder="web_loading_images"
                                accept=".svg, .gif"
                                postAnimationFile={handleSubmitAnimation}
                                loading={animationLoading}
                            />
                        </SettingDisplay>
                    </Box>
                    <MuiForm onSubmit={handleMediaSubmit} initialValues={mediaInitialValues}>
                        <SettingDisplay label="Pay at Table Merchant Logo" description="140px by 200px PNG">
                            <ImageFormField
                                name="payAtTableMerchantLogo"
                                aspect={calculatedAspectRatios.PAY_AT_TABLE_MERCHANT_LOGO}
                                fileName="pat_logo"
                                uploadFolder="app_media"
                                unremovable
                            />
                        </SettingDisplay>
                    </MuiForm>
                </Box>
            </Card>
        </StyledBox>
    );
};

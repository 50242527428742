import { Button, TextField } from '@mui/material';
import { useInputState } from 'lib/hooks/useInputState';
import { isEmptyString } from 'lib/typeguards';
import React from 'react';
import { Row } from 'ui/Flex';

interface TransactionsLastFourFilterProps {
    onChange: (value?: string) => void;
    value?: string;
    disabled?: boolean;
}

function isLastFour(value: string) {
    const exp = new RegExp(/^([0-9]{4})$/);
    return isEmptyString(value) || exp.test(value);
}

export const TransactionsLastFourFilter: React.FC<TransactionsLastFourFilterProps> = ({
    value = '',
    onChange,
    disabled
}) => {
    const [field, handleChange] = useInputState(value);
    const handleClick = React.useCallback(() => {
        onChange(isEmptyString(field) ? undefined : field);
    }, [field, onChange]);
    const renderButton = React.useCallback(
        () => (
            <Button
                color="secondary"
                disabled={value === field || !isLastFour(field) || disabled}
                onClick={handleClick}
                variant="outlined"
            >
                {value === field || field.length ? 'Submit' : 'Clear'}
            </Button>
        ),
        [disabled, field, handleClick, value]
    );
    return (
        <Row>
            <TextField
                variant="outlined"
                value={field}
                onChange={handleChange}
                label="Filter by card (last 4)"
                disabled={disabled}
                InputProps={{ endAdornment: renderButton() }}
            />
        </Row>
    );
};

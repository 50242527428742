import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Input,
    Typography,
    styled
} from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';
import { ReportColumnMeta, ReportColumnCategory } from './model';

const PREFIX = 'NewReportColumnSelection';

const classes = {
    rootWrapper: `${PREFIX}-rootWrapper`,
    optionsWrapper: `${PREFIX}-optionsWrapper`,
    groupActions: `${PREFIX}-groupActions`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.rootWrapper}`]: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius
    },

    [`& .${classes.optionsWrapper}`]: {
        height: theme.spacing(200 / 8),
        overflow: 'auto',
        padding: theme.spacing(0, 1)
    },

    [`& .${classes.groupActions}`]: {
        padding: theme.spacing(0),
        minWidth: 'auto',
        lineHeight: 'initial',
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.main
    }
}));

interface IProps {
    groupCategories: ReportColumnCategory[];
    selectedColumns: Map<string, boolean>;
    onChange: (columns: Map<string, boolean>) => void;
}

export const NewReportColumnSelection: React.FC<IProps> = ({
    groupCategories,
    selectedColumns,
    onChange
}) => {
    const [searchQuery, setSearchQuery] = React.useState('');

    const filteredGroupCategories = React.useMemo(() => {
        if (searchQuery.trim().length === 0) {
            return groupCategories;
        }

        return groupCategories
            .map(groupCategory => {
                const filteredColumns = groupCategory.columns.filter(column =>
                    column.label.toLowerCase().includes(searchQuery.toLowerCase())
                );

                return { ...groupCategory, columns: filteredColumns };
            })
            .filter(groupCategory => groupCategory.columns.length);
    }, [groupCategories, searchQuery]);
    const handleCheckboxClick = React.useCallback(
        (columnId: string) => (_: any, checked: boolean) => {
            const newValues = new Map(selectedColumns);
            if (checked) {
                newValues.set(columnId, true);
            } else {
                newValues.delete(columnId);
            }

            onChange(newValues);
        },
        [onChange, selectedColumns]
    );
    const renderColumn = React.useCallback(
        (column: ReportColumnMeta) => (
            <Grid item xs={6} key={column.id}>
                <FormControlLabel
                    checked={selectedColumns.has(column.id)}
                    value={column.id}
                    control={<Checkbox color="primary" />}
                    label={column.label}
                    key={column.id}
                    onChange={handleCheckboxClick(column.id)}
                />
            </Grid>
        ),
        [handleCheckboxClick, selectedColumns]
    );
    const handleGroupAction = React.useCallback(
        (action: 'ALL' | 'NONE', columns: ReportColumnMeta[]) => () => {
            const newValues = new Map(selectedColumns);
            columns.forEach(({ id }) => {
                if (action === 'ALL') {
                    newValues.set(id, true);
                } else {
                    newValues.delete(id);
                }
            });

            onChange(newValues);
        },
        [onChange, selectedColumns]
    );
    const renderColumnCategory = React.useCallback(
        (categoryGroup: ReportColumnCategory) => (
            <Box marginTop={1.5} key={categoryGroup.groupName}>
                {categoryGroup.groupName && (
                    <>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2">{categoryGroup.groupName}</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle2">Select</Typography>
                                <Button
                                    onClick={handleGroupAction('ALL', categoryGroup.columns)}
                                    className={classes.groupActions}
                                    variant="text"
                                    color="secondary"
                                    disableRipple
                                >
                                    All
                                </Button>
                                <Button
                                    onClick={handleGroupAction('NONE', categoryGroup.columns)}
                                    className={classes.groupActions}
                                    variant="text"
                                    disableRipple
                                    color="secondary"
                                >
                                    None
                                </Button>
                            </Box>
                        </Box>
                        <Box marginTop={0.5}>
                            <Divider />
                        </Box>
                    </>
                )}
                <Grid container>{categoryGroup.columns.map(renderColumn)}</Grid>
            </Box>
        ),
        [handleGroupAction, renderColumn]
    );
    const handleSearchChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
    }, []);

    return (
        <StyledBox className={classes.rootWrapper}>
            <Box paddingX={1} paddingY={0.5}>
                <Input
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                    startAdornment={<Search />}
                    placeholder="Filter by name"
                />
            </Box>
            <Box className={classes.optionsWrapper}>{filteredGroupCategories.map(renderColumnCategory)}</Box>
        </StyledBox>
    );
};

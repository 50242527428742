import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Chip, TableCell, styled } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { mapLocationStateToStyles } from 'components/location/helpers';
import { LocationsTable } from 'components/location/LocationTable';
import { CreateLocationModal } from 'components/location/modal/CreateLocationForm';
import { Location, LocationState } from 'components/location/models/LocationModel';
import { EmptyState } from 'components/utils/emptyState';
import { MESSAGE_LOCATIONS_CREATE_ERROR, MESSAGE_LOCATIONS_CREATE_SUCCESS } from 'config/messages';
import EmptyStateImage from 'static/locations-empty-state.svg';
import { getLocations, loadLocations } from 'store/locations/locationsActions';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import { changeLocationsTableSelectedColumns } from 'store/ui/uiActions';
import { MuiTableColumnItem, MuiTableSettingsItem } from 'ui/table/MuiTable';
import { useHistorySearch } from 'lib/hooks/useHistorySearch';

function LocationStateChip(props: { state: LocationState }) {
    return <StyledChip label={props.state} />;
}

const StyledChip = styled(Chip)<{ label: LocationState }>(({ theme, label }) => ({
    ...mapLocationStateToStyles(label, theme),
    fontSize: '0.8rem'
}));

const stateColumn: MuiTableColumnItem = {
    key: 'state',
    label: 'State',
    headerProps: {
        align: 'center'
    },
    render: (item: Location, key: string) => (
        <TableCell align="center" key={key} size="small">
            <LocationStateChip state={item.state} />
        </TableCell>
    )
};

const additionalColumns: Record<string, MuiTableColumnItem[]> = {
    Description: [{ key: 'description', label: 'Description' }],
    Address: [{ key: 'address.summary', label: 'Address' }]
};

const toolbarSettings = {
    Identifier: [{ key: '_id', label: 'Identifier' }],
    ...additionalColumns
};

function LocationsPage() {
    const [isCreateLocationPopUpOpened, setCreateLocationPopUpOpened] = React.useState(false);
    const { search, onSearch } = useHistorySearch();

    const dispatch = useDispatch();
    const selectedColumns = useSelector(
        (state: ApplicationState) => state.ui.locationsTableSelectedColumns,
        (left, right) => left.length === right.length
    );
    const locationsState = useSelector(
        (state: ApplicationState) => state.locations,
        (left, right) => right.isLoading === left.isLoading && shallowEqual(left.summary, right.summary)
    );
    const { locations, isLoading } = locationsState;
    const isMoreItems = locationsState.summary && locationsState.summary.page < locationsState.summary.pages;

    React.useEffect(() => {
        async function load() {
            await getLocations(search)(dispatch);
        }
        load();
    }, [dispatch, search]);

    const handleScroll = React.useCallback(
        async (title: string) => {
            await loadLocations(locationsState.summary, title)(dispatch);
        },
        [dispatch, locationsState]
    );

    const changeSelectedColumnsFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        changeLocationsTableSelectedColumns(name, checked, selectedColumns)(dispatch);
    };

    const getSelectedColumns = (): MuiTableColumnItem[] => {
        const selectedColumn: MuiTableColumnItem[] = Object.entries(additionalColumns).reduce(
            (acc, [key, value]) => {
                if (selectedColumns.includes(key)) {
                    acc.push(...value);
                }
                return acc;
            },
            []
        );
        let startColumns: MuiTableColumnItem[] = [];
        if (selectedColumns.includes('Identifier')) {
            startColumns = [{ key: '_id', label: 'Identifier' }];
        }

        return [...startColumns, { key: 'title', label: 'Title' }, ...selectedColumn, stateColumn];
    };

    const getColumnsSelectData = (): MuiTableSettingsItem[] =>
        Object.keys(toolbarSettings).map(label => ({
            label,
            onChange: changeSelectedColumnsFunc,
            isChecked: selectedColumns.includes(label)
        }));

    const handleCreateClick = React.useCallback(() => {
        setCreateLocationPopUpOpened(true);
    }, []);

    const handleCreateClose = React.useCallback(() => {
        setCreateLocationPopUpOpened(false);
    }, []);

    const handleCreateSuccess = React.useCallback(
        (title: string) => {
            onSearch('');
            setCreateLocationPopUpOpened(false);
            dispatch(enqueueSnackbar(MESSAGE_LOCATIONS_CREATE_SUCCESS(title), { variant: 'success' }));
        },
        [dispatch, onSearch]
    );

    const handleCreateError = React.useCallback(() => {
        dispatch(enqueueSnackbar(MESSAGE_LOCATIONS_CREATE_ERROR, { variant: 'error' }));
    }, [dispatch]);

    return (
        <MainLayout pageName="Locations" pageDescription="Manage your locations">
            {(locations && locations.length > 0) || !!search || isLoading ? (
                <LocationsTable
                    isMoreItems={isMoreItems}
                    isLoading={isMoreItems || isLoading}
                    columns={getSelectedColumns()}
                    locations={locations}
                    selectedColumns={getColumnsSelectData()}
                    onSearch={onSearch}
                    onScroll={handleScroll}
                    onCreateClick={handleCreateClick}
                    search={search}
                />
            ) : (
                <EmptyState
                    headerText="It looks like you haven't added any locations yet!"
                    paragraphText="Before you go live, you'll need to add at least one location for your customers to check in at."
                    buttonText="Add a location"
                    imageUrl={EmptyStateImage}
                    onClick={handleCreateClick}
                />
            )}
            <CreateLocationModal
                onClose={handleCreateClose}
                onError={handleCreateError}
                onSuccess={handleCreateSuccess}
                open={isCreateLocationPopUpOpened}
            />
        </MainLayout>
    );
}

export default LocationsPage;

import { ActionCreator } from 'redux';
import { StripeSettingsActionTypes } from './stripeSettingsActionTypes';
import { PepperPayAccount } from 'components/pepper-pay/model/PepperPay';

export enum StripeSignUpStatus {
    UNKNOWN = 'UNKNOWN',
    NO_ACCOUNT = 'NO_ACCOUNT',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED'
}

interface IBaseReducerAction {
    type: StripeSettingsActionTypes;
}
interface IProgressAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.START_REQUEST | StripeSettingsActionTypes.END_REQUEST;
}
interface IErrorAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.LOAD_ERROR;
    message: string;
}
interface ILocationAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.SET_LOCATION;
    locationId: string;
}
interface ILocationPickerAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.SET_PICKER_STATE;
    open: boolean;
}
interface IResetAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.SOFT_RESET;
}
interface IStatusChangeAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.SET_SIGNUP_STATUS;
    status: StripeSignUpStatus;
}
interface ISetAccountAction extends IBaseReducerAction {
    type: StripeSettingsActionTypes.SET_ACCOUNT;
    account: PepperPayAccount;
}
export type IStripeSettingAction =
    | IProgressAction
    | IErrorAction
    | ILocationAction
    | ILocationPickerAction
    | IResetAction
    | IStatusChangeAction
    | ISetAccountAction;

export function setStripeAccount(account?: PepperPayAccount) {
    return function (dispatch: ActionCreator<ISetAccountAction>) {
        dispatch({
            type: StripeSettingsActionTypes.SET_ACCOUNT,
            account
        });
    };
}
export function setStripeSignupStatus(status: StripeSignUpStatus) {
    return function (dispatch: ActionCreator<IStatusChangeAction>) {
        dispatch({
            type: StripeSettingsActionTypes.SET_SIGNUP_STATUS,
            status
        });
    };
}

export function softResetStripeSettings() {
    return function (dispatch: ActionCreator<IResetAction>) {
        dispatch({
            type: StripeSettingsActionTypes.SOFT_RESET
        });
    };
}

export function setStripeSettingsLocation(locationId: string) {
    return function (dispatch: ActionCreator<ILocationAction>) {
        dispatch({
            type: StripeSettingsActionTypes.SET_LOCATION,
            locationId
        });
    };
}

export function setStripeLocationPickerOpen(open: boolean) {
    return function (dispatch: ActionCreator<ILocationPickerAction>) {
        dispatch({
            type: StripeSettingsActionTypes.SET_PICKER_STATE,
            open
        });
    };
}

export function setStripeSettingsError(message: string) {
    return function (dispatch: ActionCreator<IErrorAction>) {
        dispatch({
            type: StripeSettingsActionTypes.LOAD_ERROR,
            message
        });
    };
}

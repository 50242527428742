import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';

export const ArteemoSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay
                    label="Display External Member Number"
                    description="Should users' external member numbers be visible in the app"
                >
                    <SwitchFormField name="loyaltyDisplayExternalMemberNumber" />
                </SettingDisplay>
                <SettingDisplay label="Host">
                    <TextFormField name="atreemoHost" />
                </SettingDisplay>
                <SettingDisplay label="Username">
                    <TextFormField name="atreemoUsername" />
                </SettingDisplay>
                <SettingDisplay label="Password">
                    <TextFormField name="atreemoPassword" />
                </SettingDisplay>
                <SettingDisplay label="Voucher Category ID">
                    <TextFormField type="number" name="atreemoVoucherCategoryId" />
                </SettingDisplay>
                <SettingDisplay label="Voucher Redemption Value">
                    <TextFormField type="number" name="atreemoVoucherRedemptionValue" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Atreemo Location ID">
                <TextFormField type="number" name="atreemoLocationId" />
            </SettingDisplay>
        )}
    </>
);

import { IManifestAction } from './manifestAction';
import { ManifestActionType } from './manifestActionTypes';

export interface ManifestState {
    isLoading: boolean;
    currencySymbol: string;
}

export const initialState: ManifestState = {
    isLoading: false,
    currencySymbol: ''
};

export default function (state: ManifestState = initialState, action: IManifestAction): ManifestState {
    switch (action.type) {
        case ManifestActionType.START_REQUEST:
            return { ...state, isLoading: true };
        case ManifestActionType.END_REQUEST:
            return { ...state, isLoading: false };
        case ManifestActionType.SET_CURRENCY_SYMBOL:
            return { ...state, currencySymbol: action.currencySymbol };
        default:
            return state;
    }
}

import { Button, TextField } from '@mui/material';
import { isEmptyString } from 'lib/typeguards';
import React from 'react';
import { Row } from 'ui/Flex';

interface GridFilterNumberInputProps {
    onChange: (value: string | undefined, name: string) => void;
    value?: string;
    label?: string;
    name: string;
}

export const GridFilterNumberInput: React.FC<GridFilterNumberInputProps> = ({
    value = '',
    onChange,
    label,
    name
}) => {
    const [field, setField] = React.useState(value);
    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setField(fieldValue => (e.target.validity.valid ? e.target.value : fieldValue));
    }, []);
    const handleClick = React.useCallback(() => {
        onChange(isEmptyString(field) ? undefined : field, name);
    }, [field, onChange, name]);
    const renderButton = React.useCallback(
        () => (
            <Button color="secondary" disabled={value === field} onClick={handleClick} variant="outlined">
                {value === field || field.length ? 'Submit' : 'Clear'}
            </Button>
        ),
        [field, handleClick, value]
    );
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => e.key === 'Enter' && value !== field && handleClick(),
        [field, handleClick, value]
    );
    return (
        <Row>
            <TextField
                variant="outlined"
                value={field}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                label={label}
                inputProps={{ pattern: '[0-9]*' }}
                name={name}
                InputProps={{ endAdornment: renderButton() }}
            />
        </Row>
    );
};

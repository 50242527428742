import React from 'react';
import { Grid } from '@mui/material';
import { AwardTemplate, awardTemplateLabels } from 'components/customers/models/Award';
import { Perk } from 'components/loyalty/models/PerkModel';
import { getCurrencyString, titleize } from 'lib/helpers';
import { CardViewTextRow } from 'ui/viewComponents';
import { isUserCreditedAction } from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';

export const ActionCardUserCredited: React.FC<ActionCardModalComponentProps> = ({ action, perks }) => {
    if (!isUserCreditedAction(action)) {
        return <ActionCardBase action={action} broken />;
    }
    let perk: Perk;
    if (action.metadata.perkId) {
        perk = perks.find(item => item._id === action.metadata.perkId);
    }
    const { amount, currency } = action.metadata.value;
    const isStampCard = !!perk && perk.template === AwardTemplate.STAMP_CARD;
    return (
        <ActionCardBase action={action}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CardViewTextRow title="Date" value={getActionDate(action)} />
                </Grid>
                {!!perk && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <CardViewTextRow title="Type" value={awardTemplateLabels[perk.template]} />
                        </Grid>
                        <Grid item xs={12}>
                            <CardViewTextRow title="Perk Name" value={perk.title} />
                        </Grid>
                        <Grid item xs={12}>
                            <CardViewTextRow
                                title="Credited"
                                value={`${amount} ${
                                    isStampCard
                                        ? `Stamp${amount > 1 ? 's' : ''}`
                                        : `Point${amount > 1 ? 's' : ''}`
                                }`}
                            />
                        </Grid>
                    </React.Fragment>
                )}
                {!perk && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <CardViewTextRow
                                title="Type"
                                value={titleize(action.metadata.scheme.replace(/_/g, ' ').toLowerCase())}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CardViewTextRow title="Credited" value={getCurrencyString(amount, currency)} />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </ActionCardBase>
    );
};

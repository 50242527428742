import { Box, IconButton, TablePagination, styled, Typography, TablePaginationProps } from '@mui/material';
import { formatDateTime } from 'lib/helpers';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { RadarListItem } from './models';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { BLOCK_LIST_PAGE_LIMIT } from './hooks/usePepperBlockList';

interface IProps {
    isLoading?: boolean;
    data: RadarListItem[];
    count: number;
    page: number;
    toolbarItems: JSX.Element[];
    onDelete: (item: RadarListItem) => void;
    onPageChange: (pageNumber: number) => void;
}

const StyledPagination = styled((props: TablePaginationProps) => (
    <TablePagination component="div" {...props} />
))(({ theme }) => ({
    '&:last-child': {
        paddingRight: theme.spacing(6)
    }
}));

export const BlockListTable: React.FC<IProps> = ({
    data,
    isLoading,
    count,
    page,
    toolbarItems,
    onPageChange,
    onDelete
}) => {
    const handleDelete = React.useCallback(
        (item: RadarListItem) => () => {
            onDelete(item);
        },
        [onDelete]
    );

    const gridColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Value',
                field: 'value',
                disableReorder: true,
                disableExport: true,
                flex: 1,
                sortable: false
            },
            {
                headerName: 'Date Added',
                field: 'created',
                disableReorder: true,
                disableExport: true,
                flex: 1,
                sortable: false,
                renderCell: (item: GridCellParams) => (
                    <Typography variant="caption" align="center">
                        {formatDateTime(new Date((item.value as number) * 1000))}
                    </Typography>
                )
            },
            {
                headerName: 'Remove',
                field: 'actions',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 150,
                renderCell: (item: GridCellParams) => (
                    <IconButton onClick={handleDelete(item.row as RadarListItem)}>
                        <DeleteIcon />
                    </IconButton>
                )
            }
        ],
        [handleDelete]
    );

    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const Pagination = React.useCallback(
        () => (
            <StyledPagination
                count={count}
                page={page}
                rowsPerPage={BLOCK_LIST_PAGE_LIMIT}
                rowsPerPageOptions={[BLOCK_LIST_PAGE_LIMIT]}
                onPageChange={handlePageChange}
            />
        ),
        [count, handlePageChange, page]
    );

    return (
        <>
            <Box marginX={-1} display="flex" justifyContent="flex-end">
                {toolbarItems}
            </Box>
            <MuiGrid
                rows={data}
                columns={gridColumns}
                disableMultipleSelection
                hideFooter={false}
                hideFooterRowCount
                pagination
                disableColumnFilter
                paginationMode="server"
                loading={isLoading}
                components={{
                    Pagination
                }}
            />
        </>
    );
};

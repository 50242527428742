import { reportCategories, reportCreationValues, reportTypeToMetadata } from './consts';
import { EReportType, EReportFormat } from './enums';

export const reportTypeLabelMap: Record<EReportType, string> = {
    [EReportType.ACTIVITY]: 'Activity',
    [EReportType.BALANCE]: 'Balance',
    [EReportType.RECONCILIATION]: 'Payout Reconciliation'
};

export const reportFormatLabelMap: Record<EReportFormat, string> = {
    [EReportFormat.ITEMIZED]: 'Itemized',
    [EReportFormat.SUMMARY]: 'Summary'
};

export interface ReportColumnMeta {
    label: string;
    id: string;
}

export interface ReportColumnCategory {
    groupName: string;
    columns: ReportColumnMeta[];
}

export const getAllReportColumns = (type: EReportType, format: EReportFormat): string[] => {
    const reportGroupColumns = reportCreationValues[type][format].columns;

    return Object.keys(reportGroupColumns);
};

export const getReportCategoryGroups = (type: EReportType, format: EReportFormat): ReportColumnCategory[] => {
    const reportName = reportCreationValues[type][format].name;
    const reportGroupColumns = reportCreationValues[type][format].columns;

    if (reportName) {
        return reportTypeToMetadata[reportName].categories.map(group => {
            const categoryName = reportCategories[group.name].defaultMessage;
            const categoryColumns = group.columns.map(column => ({
                label: reportGroupColumns[column].defaultMessage,
                id: column
            }));

            return { groupName: categoryName, columns: categoryColumns };
        });
    }
};

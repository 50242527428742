import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Notifications from '@mui/icons-material/Notifications';
import { MainLayout } from 'layouts/MainLayout';
import { segmentsApi } from 'components/audience/segmentsApi';
import { CustomerSegment } from 'components/customers/models/Customer';
import { locationApi } from 'components/location/LocationApi';
import { Location } from 'components/location/models/LocationModel';
import { CreateNotificationModal } from 'components/notification/modals/CreateNotificationModal';
import { isNotificationData, Notification } from 'components/notification/models/Notification';
import { notificationApi } from 'components/notification/notificationApi';
import { NotificationsTable } from 'components/notification/NotificationsTable';
import notificationsReducer from 'components/notification/reducers/notificationsReducer';
import { EmptyState } from 'components/utils/emptyState';
import {
    MESSAGE_MARKETING_NOTIFICATION_CREATE_ERROR,
    MESSAGE_MARKETING_NOTIFICATION_CREATE_IN_PROGRESS
} from 'config/messages';
import { MARKETING_NOTIFICATIONS_VIEW } from 'config/routes';
import logger from 'lib/logger';
import EmptyStateImage from 'static/notifications-empty-state.svg';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';

const ITEMS_PER_PAGE = 100;
const DEFAULT_QUERY = { limit: ITEMS_PER_PAGE, sort: '-createdAt' };

const { reducer, initialState, ActionType } = notificationsReducer;

const getNotifications = async (options?: {
    [key: string]: string | number;
}): Promise<{
    nextKey: string;
    notifications: Notification<string>[];
}> => {
    const response = await notificationApi.getList({
        queryParameters: Object.entries(options).map(([key, value]: [string, string]) => ({ key, value }))
    });

    if (response.ok) {
        if (isNotificationData(response.body)) {
            return {
                notifications: response.body.items,
                nextKey: response.body.page.nextKey
            };
        }
        throw new Error(`Expected NotificationData but got ${JSON.stringify(response.body)}`);
    }
    throw new Error(response.body.message);
};

async function getLocations(): Promise<Location[]> {
    const result = await locationApi.getList();
    if (!result.ok) {
        return [];
    }
    return result.body.locations;
}

async function getSegments(): Promise<CustomerSegment[]> {
    try {
        return segmentsApi.getAll();
    } catch (err) {
        return [];
    }
}

const NotificationsPage = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();
    const history = useHistory();

    const {
        isLoading,
        locations,
        segments,
        notifications,
        nextKey,
        createNotificationModalOpen,
        notificationToResend
    } = state;

    React.useEffect(() => {
        async function load() {
            try {
                const newLocations = await getLocations();
                const newSegments = await getSegments();
                const { notifications: newNotifications, nextKey: newNextKey } = await getNotifications(
                    DEFAULT_QUERY
                );
                dispatch({
                    notifications: newNotifications,
                    nextKey: newNextKey,
                    locations: newLocations,
                    segments: newSegments,
                    type: ActionType.LOAD_SUCCESS
                });
            } catch (e) {
                logger.error('Failed to load notifications', e);
                dispatch({ type: ActionType.LOAD_ERROR });
            }
        }
        load();
    }, []);

    const handleScroll = async () => {
        const requestBody: Record<string, string | number> = {
            startKey: nextKey,
            ...DEFAULT_QUERY
        };
        const { notifications: newNotifications, nextKey: newNextKey } = await getNotifications(requestBody);
        dispatch({
            nextKey: newNextKey,
            notifications: [...notifications, ...newNotifications],
            type: ActionType.LOAD_SUCCESS
        });
    };
    const scroll: MuiTableScrollItems = {
        isMoreItems: !!nextKey,
        key: nextKey,
        onScroll: handleScroll
    };
    const createButtonOnClick = React.useCallback(() => {
        dispatch({ notificationToResend: null, isOpen: true, type: ActionType.MODAL_ACTION });
    }, [dispatch]);
    const renderCreateButton = React.useCallback(
        (): React.ReactNode => (
            <TableActionButton Icon={Notifications} onClick={createButtonOnClick}>
                Send Notification
            </TableActionButton>
        ),
        [createButtonOnClick]
    );
    const toolbarItems: MuiTableToolbarItems = {
        renderCustomElement: renderCreateButton
    };
    const itemActions = React.useCallback(
        (notification: Notification<string>): MuiMenuItem[] => [
            {
                label: 'View',
                onClick: () =>
                    history.push(
                        `${MARKETING_NOTIFICATIONS_VIEW.replace(/:notificationId/g, notification._id)}`
                    )
            },
            {
                label: 'Resend',
                onClick: () => {
                    dispatch({
                        notificationToResend: notification,
                        isOpen: true,
                        type: ActionType.MODAL_ACTION
                    });
                }
            }
        ],
        [history, dispatch]
    );

    const handleCreateClose = React.useCallback(() => {
        dispatch({ isOpen: false, type: ActionType.MODAL_ACTION });
    }, [dispatch]);
    const handleCreateError = React.useCallback(
        (errorMessage: string) => {
            dispatch({ isOpen: false, type: ActionType.MODAL_ACTION });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_MARKETING_NOTIFICATION_CREATE_ERROR(errorMessage), {
                    variant: 'error'
                })
            );
        },
        [dispatch, reduxDispatch]
    );
    const handleCreateSuccess = React.useCallback(
        (notification: Notification<string>) => {
            dispatch({ notification, type: ActionType.CREATE_SUCCESS });
            reduxDispatch(
                enqueueSnackbar(MESSAGE_MARKETING_NOTIFICATION_CREATE_IN_PROGRESS, { variant: 'info' })
            );
        },
        [reduxDispatch, dispatch]
    );
    const handleEmptyStateClick = React.useCallback(
        () => dispatch({ isOpen: true, type: ActionType.MODAL_ACTION }),
        [dispatch]
    );
    const segmentOptions = React.useMemo(
        () =>
            segments.map((segment: CustomerSegment) => ({
                label: segment.title,
                value: segment._id
            })),
        [segments]
    );
    const locationOptions = React.useMemo(
        () =>
            locations.map((segment: Location) => ({
                label: segment.title,
                value: segment._id
            })),
        [locations]
    );

    return (
        <MainLayout
            pageName="Notifications"
            pageDescription="Push marketing notifications to your customers."
        >
            {isLoading || (notifications && notifications.length > 0) ? (
                <NotificationsTable
                    isLoading={isLoading}
                    scroll={scroll}
                    data={notifications}
                    toolbarItems={toolbarItems}
                    itemActions={itemActions}
                />
            ) : (
                <EmptyState
                    headerText="Get in touch with your users"
                    paragraphText="Send your customers push notifications to promote your app and in-store promotions."
                    buttonText="Send a notification"
                    imageUrl={EmptyStateImage}
                    onClick={handleEmptyStateClick}
                />
            )}
            <CreateNotificationModal
                open={createNotificationModalOpen}
                onClose={handleCreateClose}
                onSuccess={handleCreateSuccess}
                onError={handleCreateError}
                notification={notificationToResend}
                segments={segmentOptions}
                locations={locationOptions}
            />
        </MainLayout>
    );
};

export default NotificationsPage;

import { createReducer } from 'redux-create-reducer';
import { TableColumnsChangeAction } from './uiActions';
import uiActionTypes from './uiActionTypes';

export interface UiState {
    locationsTableSelectedColumns: string[];
    customersTableSelectedColumns: string[];
    perksTableSelectedColumns: string[];
    audiencesTableSelectedColumns: string[];
    merchantsTableSelectedColumns: string[];
    ordersTableSelectedColumns: string[];
}

export const initialState: UiState = {
    locationsTableSelectedColumns: ['Title', 'State'],
    customersTableSelectedColumns: [],
    perksTableSelectedColumns: [],
    audiencesTableSelectedColumns: [],
    merchantsTableSelectedColumns: [],
    ordersTableSelectedColumns: [
        'Order Number',
        'Order Type',
        'Collection Time',
        'Order Details',
        'Order Value'
    ]
};

const actionHandlers: Record<uiActionTypes, (state: UiState, action: TableColumnsChangeAction) => UiState> = {
    [uiActionTypes.LOCATIONS_TABLE_SELECTED_COLUMNS_CHANGE]: (state, action) =>
        Object.assign({}, state, { locationsTableSelectedColumns: action.selectedColumns }),
    [uiActionTypes.CUSTOMERS_TABLE_SELECTED_COLUMNS_CHANGE]: (state, action) =>
        Object.assign({}, state, { customersTableSelectedColumns: action.selectedColumns }),
    [uiActionTypes.AUDIENCES_TABLE_SELECTED_COLUMNS_CHANGE]: (state, action) =>
        Object.assign({}, state, { audiencesTableSelectedColumns: action.selectedColumns }),
    [uiActionTypes.MERCHANTS_TABLE_SELECTED_COLUMNS_CHANGE]: (state, action) =>
        Object.assign({}, state, { merchantsTableSelectedColumns: action.selectedColumns }),
    [uiActionTypes.PERKS_TABLE_SELECTED_COLUMNS_CHANGE]: (state, action) =>
        Object.assign({}, state, { perksTableSelectedColumns: action.selectedColumns }),
    [uiActionTypes.ORDERS_TABLE_SELECTED_COLUMNS_CHANGE]: (state, action) =>
        Object.assign({}, state, { ordersTableSelectedColumns: action.selectedColumns })
};

export default createReducer(initialState, actionHandlers);

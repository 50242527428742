/* eslint-disable unused-imports/no-unused-vars-ts */
import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import { GridEditCellProps } from '@mui/x-data-grid-pro';

// Hope material UI will fix an issue with missing order of hooks
export function GridStringArrayColumn(props: GridEditCellProps) {
    const {
        id,
        value,
        formattedValue,
        api,
        field,
        row,
        colDef,
        cellMode,
        isEditable,
        hasFocus,
        getValue,
        ...other
    } = props;

    const [inputValue, setInputValue] = React.useState(Array.isArray(value) ? value.join(', ') : '');

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }, []);

    const handleBlur = React.useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const newValue = event.target.value.split(',').map(item => item.trim());
            api.setEditCellValue({ id, field, value: newValue }, event);
        },
        [api, field, id]
    );

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Tab') {
                const newValue = inputValue.split(',').map(item => item.trim());
                api.setEditCellValue({ id, field, value: newValue }, event);
            }
        },
        [api, field, id, inputValue]
    );

    return (
        <InputBase
            autoFocus
            fullWidth
            type={colDef.type === 'number' ? colDef.type : 'text'}
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            {...other}
        />
    );
}

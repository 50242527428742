import { contentApi } from 'components/content/contentApi';
import logger from 'lib/logger';
import React from 'react';
import { MuiDropZone } from './MuiDropZone';

interface IProps {
    url?: string;
    uploadFolder: string;
    aspect: number;
    accept?: string | string[];
    fileName: string;
    fullWidth?: boolean;
    smallText?: boolean;
    overlay?: React.ReactNode;
    onChange: (uploadedFileUrl: string) => void;
}

export const MuiImageDropZoneUpload: React.FC<IProps> = ({
    url,
    uploadFolder,
    aspect,
    fullWidth,
    accept,
    fileName,
    onChange,
    overlay,
    smallText
}) => {
    const handleFileSelect = React.useCallback(
        async (file: File) => {
            const formData = new FormData();
            formData.append('files', file, fileName);
            try {
                const result = await contentApi.media.uploadFile(
                    { body: formData, skipStringify: true },
                    uploadFolder
                );
                if (!result.ok) {
                    throw new Error('Failed to upload an image');
                }
                const date = new Date().toTimeString();
                const resultImageUrl = result.body.items[0].url;
                onChange(`${resultImageUrl}?${date}`);
            } catch (e) {
                logger.error('Failed to upload an image', e);
            }
        },
        [fileName, onChange, uploadFolder]
    );

    return (
        <MuiDropZone
            aspect={aspect}
            onSelect={handleFileSelect}
            imageUrl={url}
            fullWidth={fullWidth}
            overlay={overlay}
            smallText={smallText}
            submitFileAs="FILE"
            accept={accept}
        />
    );
};

import React, { CSSProperties } from 'react';
import { InputBase } from '@mui/material';
import { AwardTemplate } from 'components/customers/models/Award';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { rgbaToHex } from 'lib/helpers';
import { perkPreviewFontSizesByType } from './PerkPreviewEditable';
import { TextFormField } from 'lib/form/fields/TextFormField';

interface PseudoCurPerkPreviewEditableProps {
    textAlign: CSSProperties['textAlign'];
    textColor: RGBAValue;
    perkType: AwardTemplate;
    onTitleClick: () => void;
}

export const PseudoCurPerkPreviewEditable: React.FC<PseudoCurPerkPreviewEditableProps> = ({
    textAlign,
    textColor,
    perkType,
    onTitleClick
}) => {
    const fontSizes = perkPreviewFontSizesByType[perkType];
    return (
        <React.Fragment>
            <TextFormField
                naked
                multiline
                maxRows={2}
                name="titleText"
                fontSize={`${fontSizes.title}px`}
                textAlign={textAlign}
                textColor={rgbaToHex(textColor)}
                placeholder="Click to add text or leave blank"
                onClick={onTitleClick}
            />
            <InputBase
                fullWidth
                disabled
                multiline
                maxRows={2}
                style={{ fontSize: fontSizes.subtitle }}
                inputProps={{
                    style: {
                        textAlign: textAlign || 'start',
                        color: rgbaToHex(textColor),
                        WebkitTextFillColor: rgbaToHex(textColor)
                    }
                }}
                value="123 points"
            />
            <InputBase
                fullWidth
                disabled
                multiline
                maxRows={2}
                style={{ fontSize: fontSizes.subtitleTwo }}
                inputProps={{
                    style: {
                        textAlign: textAlign || 'start',
                        color: rgbaToHex(textColor),
                        WebkitTextFillColor: rgbaToHex(textColor)
                    }
                }}
                value="Worth £1.23"
            />
        </React.Fragment>
    );
};

// Main layout with Drawer and Header
import React from 'react';
import { connect } from 'react-redux';
import { styled, Typography } from '@mui/material';
import { Tenant } from 'components/merchant/models/Tenant';
import { isString } from 'lib/typeguards';
import { ApplicationState } from 'store/store';
import { Column, ColumnProps } from 'ui/Flex';
import { MuiBreadcrumb, MuiBreadcrumbs } from 'ui/MuiBreadcrumbs';

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const StyledHeaderRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

interface MainLayoutProps extends React.ComponentProps<'div'> {
    pageName?: string;
    pageDescription?: string;
    headerActions?: JSX.Element | React.ReactNode;
    noBreadcrumbs?: boolean;
    tenant?: Tenant;
    breadcrumbs?: MuiBreadcrumb[];
    columnProps?: ColumnProps;
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
    tenant: auth.tenant
});

export const MainLayout = connect(mapStateToProps)(
    ({
        children,
        pageName,
        pageDescription,
        tenant,
        columnProps,
        breadcrumbs,
        headerActions = null,
        noBreadcrumbs
    }: MainLayoutProps) => {
        const childrenColumnProps = columnProps || { align: 'stretch' };
        return (
            <React.Fragment>
                <StyledHeaderRow>
                    <Column>
                        <MuiBreadcrumbs
                            rootLabel={tenant && tenant.title}
                            items={breadcrumbs}
                            title={pageName}
                            naked={noBreadcrumbs}
                        />
                        {isString(pageDescription) && (
                            <StyledTypography gutterBottom variant="caption" color="textSecondary">
                                {pageDescription}
                            </StyledTypography>
                        )}
                    </Column>
                    {headerActions}
                </StyledHeaderRow>
                <Column flex={1} {...childrenColumnProps} id="main-layout">
                    {children}
                </Column>
            </React.Fragment>
        );
    }
);

import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    styled,
    Switch,
    TextField,
    TextFieldProps,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { LoyaltyScope } from 'components/loyalty/enums';
import { AwardRedemptionFormData } from 'components/perks/award-enrichments/forms/AwardRedemptionForm';
import { useFormikContext } from 'formik';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { isDefined } from 'lib/typeguards';
import { Option } from 'lib/types';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { useLocalDateTimeFormat } from 'lib/hooks/useLocalDateTimeFormat';
import { AwardRedemptionFullFormData, DateTimeOptionValue } from '.';
import { perkTypeToLabelOptions } from '../PerkSettingsForm';
import { weekdays } from 'components/perks/award-enrichments/consts';
import { AwardTemplate } from 'components/customers/models/Award';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

const StyledText = styled(Typography)(() => ({
    whiteSpace: 'nowrap'
}));
const StyledSelectFormField = styled(SelectFormField)(() => ({
    width: 'auto'
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const scopeOptionsLabels: Record<string, string> = {
    ITEM: 'product',
    CATEGORY: 'category',
    BASKET: 'entire basket',
    ITEM_OR_CATEGORY: 'category and/or product'
};

const rules: Option[] = [
    {
        value: 'ALL',
        label: 'all'
    },
    {
        value: 'CHEAPEST',
        label: 'the cheapest'
    },
    {
        value: 'COSTLIEST',
        label: 'the costliest'
    },
    {
        value: 'CHEAPEST_FIRST',
        label: 'the first cheapest'
    },
    {
        value: 'COSTLIEST_FIRST',
        label: 'the first costliest'
    },
    {
        value: 'ALL_ALLOCATE',
        label: 'the allocated'
    }
];
const discountScopeOptions: Option[] = [
    {
        value: 'ITEM',
        label: 'a specific product'
    },
    {
        value: 'CATEGORY',
        label: 'a specific category'
    },
    {
        value: 'BASKET',
        label: 'entire basket'
    },
    {
        value: 'ITEM_OR_CATEGORY',
        label: 'either a category or product'
    }
];

const basketScopeOptions: Option[] = [
    {
        value: 'ITEM',
        label: 'a specific product'
    },
    {
        value: 'CATEGORY',
        label: 'a specific category'
    },
    {
        value: 'ITEM_OR_CATEGORY',
        label: 'either a product or category'
    },
    {
        value: 'BASKET',
        label: 'nothing specific'
    }
];

const childItemRules: Option[] = [
    {
        value: 'EXCLUDE',
        label: 'excluded'
    },
    {
        value: 'INCLUDE',
        label: 'included'
    }
];

interface ReedemptionFormCardContentProps {
    onUpdate?: (data: AwardRedemptionFullFormData) => void;
    onPrevious?: (isValid?: boolean, values?: AwardRedemptionFormData) => void;
    clickPrevious: () => void;
    isEdit?: boolean;
    isLoading?: boolean;
    currencySymbol: string;
    perkType: string;
    toggledFields?: string[];
    onToggleChange?: (value: string[] | ((state: string[]) => string[])) => void;
    auto?: boolean;
}

export const ReedemptionFormCardContent: React.FC<ReedemptionFormCardContentProps> = ({
    onUpdate,
    onPrevious,
    isEdit,
    clickPrevious,
    isLoading,
    currencySymbol,
    perkType,
    toggledFields,
    onToggleChange,
    auto
}) => {
    const { submitForm, values, setFieldValue, isValid, errors, setFieldTouched, touched } =
        useFormikContext<AwardRedemptionFullFormData>();
    const { getUserDateFormat } = useLocalDateTimeFormat();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const posProvider = React.useMemo(() => settings?.posProvider, [settings]);
    const isPseudoCurrency = React.useMemo(() => perkType === AwardTemplate.PSEUDO_CURRENCY, [perkType]);
    const discountRules = React.useMemo(() => {
        switch (perkType) {
            case 'AD_HOC':
            case 'STAMP_CARD':
            case 'CLUB':
                return rules.filter(
                    rule => rule.value !== 'COSTLIEST_FIRST' && rule.value !== 'CHEAPEST_FIRST'
                );
            default:
                return rules;
        }
    }, [perkType]);
    const inputFormat = React.useMemo(getUserDateFormat, [getUserDateFormat]);
    const renderField = React.useCallback(
        (error: string) => (params: TextFieldProps) =>
            <TextField sx={{ mr: 0.5 }} variant="standard" {...params} error={!!error} helperText={error} />,
        []
    );
    const handleBasketScopeSelect = React.useCallback(
        (value: any) => {
            if (value === LoyaltyScope.BASKET) {
                setFieldValue('basketQualification.matchCode', undefined);
            }
        },
        [setFieldValue]
    );
    const types: Option[] = React.useMemo(
        () =>
            perkType === AwardTemplate.AD_HOC
                ? [
                      {
                          value: 'VALUE',
                          label: currencySymbol || 'Value'
                      },
                      {
                          value: 'PERCENTAGE',
                          label: '%'
                      },
                      {
                          value: 'OVERRIDE',
                          label: 'price override'
                      }
                  ]
                : [
                      {
                          value: 'VALUE',
                          label: currencySymbol || 'Value'
                      },
                      {
                          value: 'PERCENTAGE',
                          label: '%'
                      }
                  ],
        [currencySymbol, perkType]
    );
    const handleUpdate = React.useCallback(() => {
        onUpdate(values);
    }, [onUpdate, values]);

    const handlePreviousStep = React.useCallback(() => {
        if (isEdit) {
            onPrevious(isValid, values);
        }
        clickPrevious();
    }, [clickPrevious, isEdit, isValid, onPrevious, values]);
    const handleDateFieldChange = React.useCallback(
        (fieldName: string) => (date: Date) => {
            setFieldValue(fieldName, date ? new Date(date) : undefined);
        },
        [setFieldValue]
    );

    const toggleDateOption = React.useCallback(
        (option: DateTimeOptionValue) => () => {
            const newDateTimeRestriction = { ...values.dateTimeRestriction };

            if (newDateTimeRestriction.dateTimeRestictionOption?.includes(option)) {
                switch (option) {
                    case 'date':
                        newDateTimeRestriction.startDate = undefined;
                        newDateTimeRestriction.endDate = undefined;
                        break;
                    case 'time':
                        newDateTimeRestriction.startTime = undefined;
                        newDateTimeRestriction.endTime = undefined;
                        break;
                    case 'day':
                        newDateTimeRestriction.daysOfWeek = [];
                        break;
                    default:
                        break;
                }
                newDateTimeRestriction.dateTimeRestictionOption =
                    newDateTimeRestriction.dateTimeRestictionOption.filter(value => value !== option);
            } else {
                newDateTimeRestriction.dateTimeRestictionOption = [
                    ...(newDateTimeRestriction.dateTimeRestictionOption ?? []),
                    option
                ];
            }

            setFieldValue('dateTimeRestriction', newDateTimeRestriction);
        },
        [setFieldValue, values.dateTimeRestriction]
    );

    const handleToggleField = React.useCallback(
        (field: string) => () => {
            if (onToggleChange) {
                onToggleChange(state => {
                    if (state.includes(field)) {
                        setFieldValue(field, undefined);
                        return state.filter(val => val !== field);
                    }

                    return [...state, field];
                });
            }
        },
        [onToggleChange, setFieldValue]
    );

    const discountRulesPerDiscountType = React.useMemo(
        () =>
            values.discount?.type === 'PERCENTAGE'
                ? discountRules.filter(rule => rule.value !== 'ALL_ALLOCATE')
                : discountRules,
        [discountRules, values.discount?.type]
    );

    const isIbs = React.useMemo(() => posProvider === 'IBS', [posProvider]);

    const isBasketQualificationVisible = React.useMemo(() => {
        if (auto && isIbs) {
            return false;
        }
        if (values.discount?.scope === 'BASKET') {
            return true;
        }
        return [
            values.discount?.scope,
            values.discount?.rule,
            values.discount?.scopeMatchCode,
            values.discount?.value,
            values.discount?.type
        ].every(isDefined);
    }, [
        auto,
        isIbs,
        values.discount?.rule,
        values.discount?.scope,
        values.discount?.scopeMatchCode,
        values.discount?.type,
        values.discount?.value
    ]);

    const isDateTimeRestrictionVisible = React.useMemo(
        () =>
            auto ||
            (values.basketQualification?.scope === LoyaltyScope.BASKET
                ? [values.basketQualification.scope]
                : [values.basketQualification?.scope, values.basketQualification?.matchCode]
            ).every(isDefined),
        [auto, values.basketQualification?.matchCode, values.basketQualification?.scope]
    );
    const isDiscountVisible = React.useMemo(() => !auto, [auto]);

    const handleSubmit = React.useCallback(() => {
        // For some reasons it doesn't become touched by itself when submitting the form
        setFieldTouched('discount.scopeMatchCode', true, true);
        setTimeout(() => {
            submitForm();
        }, 0);
    }, [setFieldTouched, submitForm]);

    const discountValueString = React.useMemo(() => {
        if (values.discount?.type === 'OVERRIDE') {
            return `${currencySymbol}${values.discount?.value} override`;
        }
        if (values.discount?.type === 'PERCENTAGE') {
            return `${values.discount?.value}% off`;
        }
        return `${currencySymbol}${values.discount?.value} off`;
    }, [currencySymbol, values.discount?.type, values.discount?.value]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box padding={3} maxWidth={866}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="secondary">
                                3. Redemption
                            </Typography>
                            <Typography variant="body2">
                                Set up how the award will behave for your users when redeeming through
                                ordering in your app
                            </Typography>
                        </Grid>
                        {isDiscountVisible && (
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="column" marginBottom={1.5}>
                                    <StyledTitle>3.1 Setting up the discount</StyledTitle>
                                    <Typography variant="caption">
                                        This section controls the discountable items. It determines based on
                                        the rules that are supplied to it, what items should be discounted and
                                        by how much
                                    </Typography>
                                </Box>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" columnGap="4px">
                                            <Typography variant="body2">
                                                This{' '}
                                                {perkType in perkTypeToLabelOptions
                                                    ? perkTypeToLabelOptions[perkType].toLowerCase()
                                                    : 'Unknown'}{' '}
                                                perk applies a
                                            </Typography>
                                            <Box width={values.discount?.type === 'OVERRIDE' ? 130 : 40}>
                                                <SelectFormField
                                                    options={types}
                                                    name="discount.type"
                                                    disabled={isPseudoCurrency}
                                                />
                                            </Box>
                                            {values.discount?.type === 'OVERRIDE' && (
                                                <Typography variant="body2">
                                                    of {currencySymbol ?? ''}
                                                </Typography>
                                            )}
                                            <Box width={44}>
                                                <TextFormField
                                                    name="discount.value"
                                                    type="number"
                                                    disabled={isPseudoCurrency}
                                                />
                                            </Box>
                                            <Typography variant="body2">
                                                {values.discount?.type === 'OVERRIDE' ? 'to' : 'discount to'}
                                            </Typography>
                                            <Box>
                                                <StyledSelectFormField
                                                    options={discountScopeOptions}
                                                    name="discount.scope"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {isDefined(values.discount?.scope) &&
                                        isDefined(values.discount?.type) &&
                                        isDefined(values.discount?.value) &&
                                        values.discount?.scope !== 'BASKET' && (
                                            <Grid item xs={12}>
                                                <Box display="flex" alignItems="center" columnGap="4px">
                                                    <Typography variant="body2">
                                                        {`${
                                                            values.discount.type === 'PERCENTAGE'
                                                                ? `${values.discount.value}%`
                                                                : `${currencySymbol}${values.discount.value}`
                                                        }`}{' '}
                                                        off is redeemed on
                                                    </Typography>
                                                    <Box>
                                                        <StyledSelectFormField
                                                            options={discountRulesPerDiscountType}
                                                            name="discount.rule"
                                                        />
                                                    </Box>
                                                    <Typography variant="body2">
                                                        product(s) in the basket
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    {isDefined(values.discount?.rule) &&
                                        values.discount?.scope !== 'BASKET' &&
                                        (posProvider === 'IBS' || posProvider === 'DELIVERECT') && (
                                            <Grid item xs={12}>
                                                <Box display="flex" alignItems="center" columnGap="4px">
                                                    <Typography variant="body2">
                                                        The cost of modifier products are
                                                    </Typography>
                                                    <Box>
                                                        <StyledSelectFormField
                                                            options={childItemRules}
                                                            name="discount.childItemRule"
                                                        />
                                                    </Box>
                                                    <Typography variant="body2">
                                                        when calculating the discount
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    {values.discount?.scope !== 'BASKET' &&
                                        (values.discount?.childItemRule ||
                                            (isDefined(values.discount?.rule) &&
                                                posProvider !== 'IBS' &&
                                                posProvider !== 'DELIVERECT')) && (
                                            <Grid item xs={12}>
                                                <Box display="inline" lineHeight="2">
                                                    <Typography variant="body2" display="inline">
                                                        Assign the perk code &ldquo;
                                                    </Typography>
                                                    <Box
                                                        width={isPseudoCurrency ? 150 : 250}
                                                        display="inline-block"
                                                    >
                                                        <TextFormField
                                                            name="discount.scopeMatchCode"
                                                            placeholder={
                                                                isPseudoCurrency
                                                                    ? 'e.g. free drink'
                                                                    : 'e.g. free drink, free hot drink'
                                                            }
                                                            multiline={!isPseudoCurrency}
                                                            maxRows={4}
                                                        />
                                                    </Box>
                                                    <Typography variant="body2" display="inline">
                                                        &rdquo; to the{' '}
                                                        {scopeOptionsLabels[values.discount.scope]} in your
                                                        menu via the menu manager page under the Perks column
                                                        in the table
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>
                        )}

                        {isBasketQualificationVisible && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" marginBottom={1.5}>
                                        <StyledTitle>3.2 Setting up the basket qualification</StyledTitle>
                                        <Typography variant="caption">
                                            This section controls what items you must have in your basket,
                                            alongside the discountable items to be able to qualify for the
                                            award
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" columnGap="4px">
                                                <Typography variant="body2">
                                                    {' '}
                                                    {discountValueString} will only be redeemable if you have
                                                </Typography>
                                                <Box>
                                                    <StyledSelectFormField
                                                        options={basketScopeOptions}
                                                        name="basketQualification.scope"
                                                        onSelect={handleBasketScopeSelect}
                                                    />
                                                </Box>
                                                <Typography variant="body2">in the basket</Typography>
                                            </Box>
                                        </Grid>
                                        {isDefined(values.basketQualification?.scope) &&
                                            values.basketQualification.scope !== LoyaltyScope.BASKET && (
                                                <Grid item xs={12}>
                                                    <Box display="inline" lineHeight="2">
                                                        <Typography variant="body2" display="inline">
                                                            Assign the perk code &ldquo;
                                                        </Typography>
                                                        <Box
                                                            width={isPseudoCurrency ? 150 : 250}
                                                            display="inline-block"
                                                        >
                                                            <TextFormField
                                                                name="basketQualification.matchCode"
                                                                placeholder={
                                                                    isPseudoCurrency
                                                                        ? 'e.g. buy 2 mains'
                                                                        : 'e.g. buy 2 mains, buy 2 coffees'
                                                                }
                                                                multiline={!isPseudoCurrency}
                                                                maxRows={4}
                                                            />
                                                        </Box>
                                                        <Typography variant="body2" display="inline">
                                                            &rdquo; to the{' '}
                                                            {
                                                                scopeOptionsLabels[
                                                                    values.basketQualification.scope
                                                                ]
                                                            }{' '}
                                                            in your menu via the menu manager page under the
                                                            Perks column in the table
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                        {(isDefined(values.basketQualification?.matchCode) ||
                                            (isDefined(values.basketQualification?.scope) &&
                                                values.basketQualification.scope ===
                                                    LoyaltyScope.BASKET)) && (
                                            <>
                                                <Grid item xs={12}>
                                                    {toggledFields?.includes(
                                                        'basketQualification.minimumValue'
                                                    ) ? (
                                                        <Box display="flex">
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                columnGap="4px"
                                                            >
                                                                <Typography variant="body2">
                                                                    The{' '}
                                                                    {values.basketQualification.scope ===
                                                                    LoyaltyScope.BASKET
                                                                        ? 'basket'
                                                                        : 'product(s)'}{' '}
                                                                    must have a minimum cost of{' '}
                                                                    {currencySymbol}
                                                                </Typography>
                                                                <Box width={44}>
                                                                    <TextFormField
                                                                        name="basketQualification.minimumValue"
                                                                        type="number"
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            <IconButton
                                                                onClick={handleToggleField(
                                                                    'basketQualification.minimumValue'
                                                                )}
                                                                aria-label="delete"
                                                                size="small"
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Box>
                                                    ) : (
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    onChange={handleToggleField(
                                                                        'basketQualification.minimumValue'
                                                                    )}
                                                                />
                                                            }
                                                            label="Set a minimum value on the product or basket"
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {toggledFields?.includes(
                                                        'basketQualification.minimumItems'
                                                    ) ? (
                                                        <Box display="flex" alignItems="center">
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                columnGap="4px"
                                                            >
                                                                <Typography variant="body2">
                                                                    There must be a minimum of
                                                                </Typography>
                                                                <Box width={44}>
                                                                    <TextFormField
                                                                        name="basketQualification.minimumItems"
                                                                        type="number"
                                                                    />
                                                                </Box>
                                                                <Typography variant="body2">
                                                                    product(s) in the basket
                                                                </Typography>
                                                            </Box>
                                                            <IconButton
                                                                onClick={handleToggleField(
                                                                    'basketQualification.minimumItems'
                                                                )}
                                                                aria-label="delete"
                                                                size="small"
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Box>
                                                    ) : (
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    onChange={handleToggleField(
                                                                        'basketQualification.minimumItems'
                                                                    )}
                                                                />
                                                            }
                                                            label="Set a minimum number of products"
                                                        />
                                                    )}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {isDateTimeRestrictionVisible && (
                            <>
                                {!auto && (
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" marginBottom={1.5}>
                                        <StyledTitle>
                                            {auto ? '3.1' : '3.3'} Setting up specific time / date
                                            availability
                                        </StyledTitle>
                                        <Typography variant="caption">
                                            This section controls when an award will be available to the user
                                            to redeem in app. If nothing is set, this award will always be
                                            available
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12}>
                                            {values.dateTimeRestriction.dateTimeRestictionOption.includes(
                                                'date'
                                            ) ? (
                                                <Box display="flex" alignItems="center">
                                                    <Box display="flex" alignItems="center" columnGap="4px">
                                                        {!auto && (
                                                            <StyledText variant="body2">
                                                                {discountValueString} will be available
                                                                between
                                                                {''}
                                                            </StyledText>
                                                        )}
                                                        {auto && (
                                                            <StyledText variant="body2">
                                                                This award will be available between
                                                                {''}
                                                            </StyledText>
                                                        )}
                                                        <Box flexShrink={0} width="128px">
                                                            <DatePicker
                                                                value={
                                                                    values.dateTimeRestriction.startDate
                                                                        ? new Date(
                                                                              values.dateTimeRestriction.startDate
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={handleDateFieldChange(
                                                                    'dateTimeRestriction.startDate'
                                                                )}
                                                                renderInput={renderField(
                                                                    touched?.dateTimeRestriction?.startDate &&
                                                                        errors?.dateTimeRestriction?.startDate
                                                                )}
                                                                inputFormat={inputFormat}
                                                            />
                                                        </Box>
                                                        <StyledText variant="body2"> and </StyledText>
                                                        <Box flexShrink={0} width="128px">
                                                            <DatePicker
                                                                value={
                                                                    values.dateTimeRestriction.endDate
                                                                        ? new Date(
                                                                              values.dateTimeRestriction.endDate
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={handleDateFieldChange(
                                                                    'dateTimeRestriction.endDate'
                                                                )}
                                                                renderInput={renderField(
                                                                    touched?.dateTimeRestriction?.endDate &&
                                                                        errors?.dateTimeRestriction?.endDate
                                                                )}
                                                                inputFormat={inputFormat}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <IconButton
                                                        onClick={toggleDateOption('date')}
                                                        aria-label="delete"
                                                        size="small"
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            ) : (
                                                <FormControlLabel
                                                    control={<Switch onChange={toggleDateOption('date')} />}
                                                    label="Add date range"
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {values.dateTimeRestriction.dateTimeRestictionOption.includes(
                                                'time'
                                            ) ? (
                                                <Box display="flex" alignItems="center">
                                                    <Box display="flex" alignItems="center" columnGap="4px">
                                                        {!auto && (
                                                            <StyledText variant="body2">
                                                                {discountValueString} will be available
                                                                between
                                                                {''}
                                                            </StyledText>
                                                        )}
                                                        {auto && (
                                                            <StyledText variant="body2">
                                                                This award will be available between
                                                                {''}
                                                            </StyledText>
                                                        )}
                                                        <Box flexShrink={0} width="110px">
                                                            <TextFormField
                                                                name="dateTimeRestriction.startTime"
                                                                type="time"
                                                                shrink
                                                            />
                                                        </Box>
                                                        <StyledText variant="body2"> and </StyledText>
                                                        <Box flexShrink={0} width="110px">
                                                            <TextFormField
                                                                name="dateTimeRestriction.endTime"
                                                                type="time"
                                                                shrink
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <IconButton
                                                        onClick={toggleDateOption('time')}
                                                        aria-label="delete"
                                                        size="small"
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            ) : (
                                                <FormControlLabel
                                                    control={<Switch onChange={toggleDateOption('time')} />}
                                                    label="Add time range"
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {values.dateTimeRestriction.dateTimeRestictionOption.includes(
                                                'day'
                                            ) ? (
                                                <Box display="flex" alignItems="center">
                                                    <Box display="flex" alignItems="center" columnGap="4px">
                                                        {!auto && (
                                                            <StyledText variant="body2">
                                                                {discountValueString} will be available
                                                                between
                                                                {''}
                                                            </StyledText>
                                                        )}
                                                        {auto && (
                                                            <StyledText variant="body2">
                                                                This award will be available on
                                                                {''}
                                                            </StyledText>
                                                        )}
                                                        <Box flexShrink={0}>
                                                            <StyledSelectFormField
                                                                options={weekdays}
                                                                multiple
                                                                name="dateTimeRestriction.daysOfWeek"
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <IconButton
                                                        onClick={toggleDateOption('day')}
                                                        aria-label="delete"
                                                        size="small"
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            ) : (
                                                <FormControlLabel
                                                    control={<Switch onChange={toggleDateOption('day')} />}
                                                    label="Add specific day(s)"
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Row flex={1} gutter align="flex-end">
                    {isEdit && (
                        <LoadingButton
                            loading={isLoading}
                            disabled={isLoading || !isValid}
                            onClick={handleUpdate}
                            color="primary"
                            variant="outlined"
                        >
                            Update & Close
                        </LoadingButton>
                    )}
                    <Button onClick={handlePreviousStep} color="primary" variant="outlined">
                        Previous
                    </Button>
                    <Button
                        disabled={!isValid || isLoading}
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                    >
                        Next
                    </Button>
                </Row>
            </Grid>
        </Grid>
    );
};

import { Skeleton, Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface LoadingTypographyProsp extends TypographyProps {
    loading?: boolean;
    width?: number;
    height?: number;
}

export const LoadingTypography: React.FC<LoadingTypographyProsp> = ({
    loading,
    width,
    height,
    className,
    ...props
}) => {
    if (loading) {
        return <Skeleton className={className} variant="text" width={width} height={height ?? 24} />;
    }
    return <Typography className={className} {...props} />;
};

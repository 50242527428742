import React from 'react';
import { Chip, Paper, TableCell, styled } from '@mui/material';
import { titleize } from 'lib/helpers';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { Customer, CustomerState } from './models/Customer';
import { mapCustomerStateToStyles, processCredentials } from './helpers';

const StyledChip = styled(Chip)<{ label: CustomerState }>(({ theme, label }) => ({
    ...mapCustomerStateToStyles(label, theme),
    fontSize: '0.8rem'
}));

function CustomerStateChip(props: { state: CustomerState }) {
    return <StyledChip label={props.state} />;
}

function renderStateChip(item: Customer, key: string) {
    return (
        <TableCell align="center" key={key} size="small">
            <CustomerStateChip state={item.state} />
        </TableCell>
    );
}

function renderCredentials(customer: Customer, key: string) {
    return <TableCell key={key}>{processCredentials(customer)}</TableCell>;
}

function renderRoles(customer: Customer, key: string) {
    return <TableCell key={key}>{customer.roles?.map(role => titleize(role)).join(', ')}</TableCell>;
}

const additionalColumns: Record<string, MuiTableColumnItem> = {
    Identifier: {
        key: '_id',
        label: 'Identifier'
    }
};

const columns: MuiTableColumnItem[] = [
    { key: 'fullName', label: 'User Name' },
    {
        key: 'credentials',
        label: 'Primary Contact',
        render: renderCredentials
    },
    {
        key: 'roles',
        label: 'Role',
        render: renderRoles
    },
    {
        key: 'state',
        label: 'State',
        headerProps: {
            align: 'center'
        },
        render: renderStateChip
    }
];

interface CustomersTableProps {
    scroll: MuiTableScrollItems;
    itemActions: (data: Customer) => MuiMenuItem[];
    toolbarItems: MuiTableToolbarItems;
    selectedColumns: string[];
    data: Customer[];
    isLoading?: boolean;
}

export const CustomersTable: React.FC<CustomersTableProps> = ({ selectedColumns, ...props }) => {
    const getColumns = React.useCallback(() => {
        let resultColumns: MuiTableColumnItem[] = [];
        selectedColumns.forEach(item => {
            resultColumns.push(additionalColumns[item]);
        });
        resultColumns = [...resultColumns, ...columns];
        return resultColumns;
    }, [selectedColumns]);
    return (
        <Paper>
            <MuiTable columns={getColumns()} {...props} />
        </Paper>
    );
};

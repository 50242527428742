import React from 'react';
import { Skeleton } from '@mui/material';
import { generateNumArray } from 'lib/helpers';

export interface SkeletonProps {
    count?: number;
    width?: string | number;
    height?: string | number;
    className?: string;
    variant?: 'circular' | 'text' | 'rectangular';
}

export const SkeletonComponent = (props: SkeletonProps) => {
    const { count, width, height, className, variant } = props;
    const elements = generateNumArray(count).map(item => (
        <Skeleton
            variant={variant}
            key={`skeleton-item-${item}`}
            width={width}
            height={height}
            className={className}
        />
    ));

    // it should know that there is an array but it is a stupid technology)
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{elements}</React.Fragment>;
};

SkeletonComponent.defaultProps = {
    count: 1,
    width: '100%'
};

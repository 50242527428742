import { Button, styled } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import clsx from 'clsx';

const PREFIX = 'RemoveCell';

const classes = {
    deletedButton: `${PREFIX}-deletedButton`
};

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 60,
    color: theme.palette.divider,
    '.MuiDataGrid-row:hover &': {
        color: theme.palette.primary.main
    },
    [`&.${classes.deletedButton}`]: {
        color: theme.palette.secondary.main
    }
}));

interface RemoveCellProps {
    params: GridCellParams;
    onDelete: (id: string | number) => void;
    isRemovable?: (id: string | number) => boolean;
    onUndelete?: (id: string | number) => void;
    deleted?: boolean;
}

export const RemoveCell: React.FC<RemoveCellProps> = ({
    params,
    onDelete,
    isRemovable,
    onUndelete,
    deleted
}) => {
    const handleClick = React.useCallback(() => {
        if (deleted && onUndelete) {
            onUndelete(params.id);
        } else {
            onDelete(params.id);
        }
    }, [onDelete, onUndelete, params.id, deleted]);
    if (isRemovable && !isRemovable(params.id)) {
        return null;
    }
    return (
        <StyledButton
            variant="text"
            size="small"
            className={clsx({
                [classes.deletedButton]: deleted
            })}
            onClick={handleClick}
        >
            {deleted ? <UndoIcon /> : <DeleteIcon />}
        </StyledButton>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const SquarePaymentsSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <SettingDisplay label="Production Environment">
                <SwitchFormField name="squareIsProduction" />
            </SettingDisplay>
        )}
    </>
);

/* eslint-disable no-bitwise */
import Archive from '@mui/icons-material/Archive';
import Contacts from '@mui/icons-material/Contacts';
import Group from '@mui/icons-material/Group';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LocalOffer from '@mui/icons-material/LocalOffer';
import LocationOn from '@mui/icons-material/LocationOn';
import Notifications from '@mui/icons-material/Notifications';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import PhonelinkSetup from '@mui/icons-material/PhonelinkSetup';
import SettingsApplications from '@mui/icons-material/SettingsApplications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Star from '@mui/icons-material/Star';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Permission, permission, Resource } from '@pepperhq/auth-client';
import { EPaymentProvider } from 'components/settings/PaymentsSettings';
import * as routes from 'config/routes';
import BlockIcon from '@mui/icons-material/Block';
import { Page } from './models/Page';
import MenuBook from '@mui/icons-material/MenuBook';
import Loyalty from '@mui/icons-material/Loyalty';
import Giftcard from '@mui/icons-material/CardGiftcard';
import AccountBalance from '@mui/icons-material/AccountBalance';
import People from '@mui/icons-material/People';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import EditNoteIcon from '@mui/icons-material/EditNote';

export const pages: Page[] = [
    {
        title: 'ANALYTICS',
        children: [
            {
                pathname: routes.ANALYTICS_ORDERS,
                resources: permission(Resource.Analytics, Permission.read),
                icon: ShoppingCartIcon,
                title: 'Orders'
            },
            {
                pathname: routes.ANALYTICS_CUSTOMERS,
                resources: permission(Resource.Analytics, Permission.read),
                icon: People,
                title: 'Customers'
            },
            {
                pathname: routes.ANALYTICS_LOYALTY,
                resources: permission(Resource.Analytics, Permission.read),
                icon: Loyalty,
                title: 'Loyalty'
            },
            {
                pathname: routes.ANALYTICS_EXPORT,
                resources: permission(Resource.Analytics, Permission.read),
                icon: Archive,
                title: 'Export Data'
            }
        ]
    },
    {
        title: 'GIFTING ANALYTICS',
        children: [
            {
                pathname: routes.ANALYTICS_GIFTING_ACTIVITY,
                resources: permission(Resource.Analytics, Permission.read),
                icon: Giftcard,
                title: 'Activity'
            },
            {
                pathname: routes.ANALYTICS_GIFTING_LIABILITY,
                resources: permission(Resource.Analytics, Permission.read),
                icon: AccountBalance,
                title: 'Liability'
            }
        ],
        condition: settings =>
            settings?.giftCardPaymentProvider === 'POWERCARD' && !!settings.giftCardPaymentEnabled
    },
    {
        title: 'MANAGING',
        children: [
            {
                pathname: routes.CUSTOMERS,
                resources: permission(Resource.Customer, Permission.read),
                icon: Group,
                title: 'Customers'
            },
            {
                pathname: routes.ORDERS,
                resources: permission(Resource.Order, Permission.read),
                icon: ShoppingCartIcon,
                title: 'Orders'
            },
            {
                pathname: routes.LOCATIONS,
                resources: permission(Resource.Location, Permission.read),
                icon: LocationOn,
                title: 'Locations'
            },
            {
                pathname: routes.MENU_MANAGER,
                resources: permission(Resource.Menu, Permission.read | Permission.write),
                icon: MenuBook,
                title: 'Menus'
            },
            {
                pathname: routes.CONTENT_AVAILABILITY,
                resources: permission(Resource.Menu, Permission.read | Permission.write),
                icon: EditNoteIcon,
                title: 'Ordering Availability'
            },
            {
                pathname: routes.CONTENT_OVERWRITES,
                resources: permission(Resource.Menu, Permission.all),
                icon: ImportExportIcon,
                title: 'Menu Customisation'
            },
            {
                pathname: routes.MOBILE_CONTENT,
                resources: permission(Resource.Layout, Permission.read | Permission.write),
                icon: PhoneIphone,
                title: 'Mobile Content'
            }
        ]
    },
    {
        title: 'MARKETING',
        children: [
            {
                pathname: routes.MARKETING_AUDIENCES,
                resources: permission(Resource.Segment, Permission.read),
                icon: Contacts,
                title: 'Audiences'
            },
            {
                pathname: routes.MARKETING_NOTIFICATIONS,
                resources: permission(Resource.Message, Permission.read),
                icon: Notifications,
                title: 'Notifications'
            },
            {
                pathname: routes.MARKETING_PEPPER_PERKS,
                resources: permission(Resource.Perk, Permission.read),
                icon: Star,
                title: 'Perks'
            },
            {
                pathname: routes.MARKETING_VOUCHERS,
                resources: permission(Resource.Voucher, Permission.read),
                icon: LocalOffer,
                title: 'Vouchers'
            },
            {
                pathname: routes.TIERED_LOYALTY,
                resources: permission(Resource.TieredLoyalty, Permission.read),
                icon: WorkspacePremiumIcon,
                title: 'Tier Schemes',
                condition: settings => !!settings?.premiumLoyaltyEnabled
            }
        ]
    },
    {
        title: 'PEPPER PAY',
        resources: permission(Resource.Pay, Permission.read),
        condition: settings =>
            settings?.paymentProvider === EPaymentProvider.Stripe && !!settings.stripe?.connectEnabled,
        children: [
            {
                pathname: routes.PEPPER_PAY_TRANSACTIONS,
                resources: permission(Resource.Pay, Permission.read),
                icon: SyncAltIcon,
                title: 'Transactions'
            },
            {
                pathname: routes.PEPPER_PAY_REPORTING,
                resources: permission(Resource.PayReporting, Permission.read),
                icon: AssessmentOutlinedIcon,
                title: 'Reporting'
            },
            {
                pathname: routes.PEPPER_PAY_BLOCK_LIST,
                resources: permission(Resource.Pay, Permission.read),
                icon: BlockIcon,
                title: 'Blocked Lists'
            },
            {
                pathname: routes.PEPPER_PAY_DISPUTES,
                resources: permission(Resource.Pay, Permission.read),
                icon: ErrorOutlineIcon,
                title: 'Disputes'
            }
        ]
    },
    {
        title: 'SETTINGS',
        children: [
            {
                pathname: routes.MERCHANT_CONFIG_EMPTY,
                resources: permission(Resource.Tenant, Permission.write),
                icon: SettingsApplications,
                title: 'Access Management'
            },
            {
                condition: settings =>
                    settings?.paymentProvider === EPaymentProvider.Stripe &&
                    !!settings.stripe?.connectEnabled,
                pathname: routes.MERCHANT_SETTINGS_CLERKS,
                resources: permission(
                    Resource.TerminalAccess,
                    Permission.read | Permission.write | Permission.delete
                ),
                icon: ManageAccountsIcon,
                title: 'Clerks Access'
            },
            {
                pathname: routes.MERCHANT_MOBILE_SETTINGS,
                resources: permission(Resource.Setting, Permission.write),
                icon: PhonelinkSetup,
                title: 'Settings'
            }
        ]
    }
];

import { AutoFormFields } from 'lib/LegacyForm';
import { ContentModule, isLocationsModule } from '../models/ContentModule';
import { createModuleBasicForm } from './createModuleBasicForm';

export const createModuleLocationsForm: AutoFormFields = {
    ...createModuleBasicForm,
    geoSuggestion: {
        label: 'Location Suggestion',
        type: 'geo',
        description:
            'Choose where you want the map to look by default. Users of the app will see your locations marked on it',
        validation: 'required',
        errors: {
            required: 'This field is required'
        }
    },
    width: {
        label: 'Map Zoom Level',
        type: 'number',
        inputType: 'number',
        description: 'Set the standard zoom level of the map on your home screen',
        value: 0.04,
        validation: ['regex:/^[0-9]{1,3}(,[0-9]{3})*(([\\.,]{1}[0-9]*)|())$']
    }
};

export function transformModuleToLocationFormFields(module: ContentModule): any {
    if (isLocationsModule(module)) {
        return {
            ...module,
            secondaryActionTitle: module.secondaryAction ? module.secondaryAction.title : undefined,
            secondaryActionAction: module.secondaryAction ? module.secondaryAction.action : undefined,
            width: module.properties.defaultRegion.width,
            geoSuggestion: {
                lat: module.properties.defaultRegion.latitude,
                lng: module.properties.defaultRegion.longitude
            }
        };
    }
    return module;
}

export function transformLocationFormFieldToModuleFields(
    fieldName: string,
    value: any,
    formData: { [key: string]: any }
): { name: string; value: any }[] {
    switch (fieldName) {
        case 'secondaryActionTitle':
            return [
                { name: 'secondaryAction', value: { action: formData.secondaryActionAction, title: value } }
            ];
        case 'secondaryActionAction':
            return [
                {
                    name: 'secondaryAction',
                    value: {
                        action: value,
                        title: formData.secondaryActionTitle
                    }
                }
            ];
        case 'width':
            if (value) {
                const geoSuggestionData = formData?.geoSuggestion?.location ?? formData?.geoSuggestion;
                return [
                    {
                        name: 'properties',
                        value: {
                            defaultRegion: {
                                latitude: geoSuggestionData.lat,
                                longitude: geoSuggestionData.lng,
                                width: Number(value)
                            }
                        }
                    }
                ];
            }
            break;
        case 'geoSuggestion':
            if (value) {
                return [
                    {
                        name: 'properties',
                        value: {
                            defaultRegion: {
                                latitude: value.location.lat,
                                longitude: value.location.lng,
                                width: Number(formData.width)
                            }
                        }
                    }
                ];
            }
            break;
        default:
            return [{ value, name: fieldName }];
    }
    return [{ value, name: fieldName }];
}

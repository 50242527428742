import { Box, TablePagination, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { GiftingActivityFilters } from 'components/analytics/gifting/activity/GiftingActivityFilters';
import {
    GIFTING_DATA_PAGE_LIMIT_OPTIONS,
    useGiftingActivityData
} from 'components/analytics/gifting/activity/hooks/useGiftingActivityData';
import { useGiftingActivityFilter } from 'components/analytics/gifting/activity/hooks/useGiftingActivityFilter';
import { MuiTheme } from 'config/theme';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocations } from 'store/locations/locationsActions';
import { GiftingActivitySummary } from 'components/analytics/gifting/activity/GiftingActivitySummary';
import { Row } from 'ui/Flex';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { ApplicationState } from 'store/store';

export const GiftingActivityPage: React.FC = () => {
    const { locationIdToLocationTitle } = useSelector((state: ApplicationState) => state.locations);
    const dispatch = useDispatch();

    const [filter, onFilterChange] = useGiftingActivityFilter();
    const {
        summary,
        formattedTransactions,
        page,
        onPageChange,
        pageLimit,
        onPageLimitChange,
        sortModel,
        onSortModelChange,
        loading,
        count,
        error
    } = useGiftingActivityData(filter);
    React.useEffect(() => {
        getAllLocations()(dispatch);
    }, [dispatch]);
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );
    const handlePageLimitChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onPageLimitChange(Number.parseInt(event.target.value));
        },
        [onPageLimitChange]
    );
    const renderLocationCell = React.useCallback(
        (params: GridRenderCellParams) =>
            renderMuiGridTextView(
                params,
                <Typography variant="body2">
                    {params.row.location_pepper_id
                        ? locationIdToLocationTitle.get(params.row.location_pepper_id) ??
                          params.row.location_name
                        : params.row.location_name}
                </Typography>
            ),
        [locationIdToLocationTitle]
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Date',
                field: 'transaction_date',
                type: 'dateTime',
                valueGetter: (params: GridValueGetterParams) => new Date(params.row.transaction_date),
                disableReorder: true,
                disableExport: true,
                width: 240
            },
            {
                headerName: 'Transaction ID',
                field: 'transaction_id',
                width: 140,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Location ID',
                field: 'location_id',
                width: 140,
                sortable: false,
                hide: true
            },
            {
                headerName: 'Location',
                field: 'location_name',
                width: 140,
                renderCell: renderLocationCell,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Gift Program',
                field: 'gift_program_name',
                width: 140,
                sortable: false,
                headerAlign: 'left'
            },
            {
                headerName: 'Card Number',
                field: 'card_number',
                sortable: false,
                width: 140
            },
            {
                headerName: 'Server',
                field: 'server_id',
                sortable: false,
                width: 140
            },
            {
                headerName: 'Raw Type',
                field: 'transaction_type',
                sortable: false,
                width: 140,
                hide: true
            },
            {
                headerName: 'Type',
                field: 'mapped_type',
                sortable: false,
                width: 140
            },
            {
                headerName: 'Debits',
                field: 'debit_value',
                sortable: false,
                align: 'right',
                width: 140
            },
            {
                headerName: 'Credits',
                field: 'credit_value',
                sortable: false,
                align: 'right',
                width: 140
            },
            {
                headerName: 'Item',
                field: 'transaction_item',
                sortable: false,
                width: 140
            }
        ],
        [renderLocationCell]
    );
    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={pageLimit}
                rowsPerPageOptions={GIFTING_DATA_PAGE_LIMIT_OPTIONS}
                onRowsPerPageChange={handlePageLimitChange}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, handlePageLimitChange, pageLimit, page]
    );
    const getRowId = React.useCallback((item: any) => item.transaction_id, []);
    if (error) {
        return (
            <ErrorLayout
                title="Something wrong"
                fallbackUrl="/"
                fallbackTitle="Main Page"
                description={error}
            />
        );
    }
    return (
        <MainLayout
            pageName="Gifting"
            pageDescription="Useful insights into how gifting is used by your customers."
        >
            <Box paddingBottom={10} height="100%">
                <Box paddingBottom={1}>
                    <Row flex={1} wrap="wrap" valign="center" align="space-between">
                        <GiftingActivityFilters filter={filter} onChange={onFilterChange} />
                        <GiftingActivitySummary summary={summary} loading={loading} />
                    </Row>
                </Box>
                <MuiGrid
                    rows={formattedTransactions}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    disableMultipleSelection
                    hideFooter={false}
                    pagination
                    getRowId={getRowId}
                    disableColumnFilter
                    paginationMode="server"
                    loading={loading}
                    components={{
                        Pagination
                    }}
                />
            </Box>
        </MainLayout>
    );
};

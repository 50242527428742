import React from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router';
import * as routes from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { Box, Tab, Tabs } from '@mui/material';
import { EReportingListAlias } from 'components/pepper-pay/block-list/enums';
import { Schedules } from './schedules';
import { Reporting } from './reporting';

export const ReportingPage: React.FC = () => {
    const history = useHistory();
    const { table } = useParams<{ table: string }>();

    const buttons: { key: EReportingListAlias; label: string }[] = React.useMemo(
        () => [
            {
                label: 'Report List',
                key: EReportingListAlias.REPORTING
            },
            {
                label: 'Schedules',
                key: EReportingListAlias.SCHEDULES
            }
        ],
        []
    );

    const renderButton = React.useCallback(
        ({ label, key }) => <Tab value={key} key={`tab-${key}`} label={<span>{label}</span>} />,
        []
    );

    const handleChange = React.useCallback(
        (_: never, table: string) => {
            if (table === EReportingListAlias.REPORTING) {
                history.push(routes.PEPPER_PAY_REPORTING_REPORTS);
            }
            if (table === EReportingListAlias.SCHEDULES) {
                history.push(routes.PEPPER_PAY_REPORTING_SCHEDULES);
            }
        },
        [history]
    );

    return (
        <MainLayout pageName="Reporting" pageDescription="View and generate reports">
            <Tabs value={table} onChange={handleChange} variant="scrollable" indicatorColor="primary">
                {buttons.map(renderButton)}
            </Tabs>

            <Box>
                <Switch>
                    <Route path={routes.PEPPER_PAY_REPORTING_REPORTS}>
                        <Reporting />
                    </Route>
                    <Route path={routes.PEPPER_PAY_REPORTING_SCHEDULES}>
                        <Schedules />
                    </Route>
                    <Redirect from="/" to={routes.PEPPER_PAY_REPORTING_REPORTS} />
                </Switch>
            </Box>
        </MainLayout>
    );
};

import { RequestOptions } from 'lib/HttpClient';
import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { endOfDay, getTime, startOfDay } from 'date-fns';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import { PepperTransactionType } from 'components/pepper-pay/model/PepperPay';

export interface PepperTransactionsFilter {
    date?: DateRangePickerValue;
    paymentMethod?: string;
    walletType?: string;
    state?: PepperTransactionState;
    risk?: string;
    orderId?: string;
    last4?: string;
    locationId?: string;
    customerId?: string;
    expiryDate?: string;
    type?: PepperTransactionType;
    method?: PepperTransactionMethod;
    paymentIntentId?: string;
}

export enum PepperTransactionState {
    Completed = 'COMPLETED',
    Refunded = 'REFUNDED',
    Disputed = 'DISPUTED',
    Declined = 'DECLINED',
    Pending = 'PENDING',
    Expired = 'EXPIRED',
    PartiallyRefunded = 'PARTIALLY_REFUNDED'
}

export enum PepperTransactionRiskLevel {
    Unknown = 'unknown',
    NotAssessed = 'not_assessed',
    Normal = 'normal',
    Elevated = 'elevated',
    Highest = 'highest'
}

export enum PepperTransactionMethod { // Determines the mode of the 'method' filter
    QuickPad = 'INCLUDE',
    NonQuickPad = 'EXCLUDE'
}

type PepperTransactionsFilterValue = DateRangePickerValue | string;
export type PepperTransactionsFilterChange = (
    field: keyof PepperTransactionsFilter,
    value: PepperTransactionsFilterValue
) => void;

export function usePepperTransactionsFilter(): [PepperTransactionsFilter, PepperTransactionsFilterChange] {
    const { search, onSearch } = useHistoryFilters();
    const [filter, setFilter] = React.useState<PepperTransactionsFilter>({
        date: {
            startDate: search.startDate ? new Date(search.startDate) : new Date(),
            endDate: search?.endDate ? new Date(search.endDate) : undefined
        },
        paymentMethod: search?.paymentMethod || undefined,
        state: search?.state || undefined,
        risk: search?.risk || undefined,
        orderId: search?.orderId || undefined,
        last4: search?.last4 || undefined,
        locationId: search?.locationId || undefined,
        walletType: search?.walletType || undefined,
        customerId: search?.customerId || undefined,
        expiryDate: search?.expiryDate || undefined,
        type: search?.type || undefined,
        method: search?.method || undefined,
        paymentIntentId: search?.paymentIntentId || undefined
    });
    const onFilterChange = React.useCallback<PepperTransactionsFilterChange>((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        const { date, ...rest } = filter;
        onSearch({ ...rest, startDate: date?.startDate, endDate: date?.endDate });
    }, [filter, onSearch]);
    return [filter, onFilterChange];
}

export const getTransactionsFilterBody = (filter: PepperTransactionsFilter) => {
    const body: RequestOptions['body'] = {};
    if (filter.orderId) {
        body.orderId = filter.orderId;
        if (Number(filter.orderId) >= 0) {
            return body;
        }
    }
    if (filter.customerId) {
        body.userId = filter.customerId;
    }
    if (filter.expiryDate) {
        body.expiryDate = filter.expiryDate;
    }
    if (filter.last4) {
        body.lastFour = filter.last4;
    }
    if (filter.state) {
        body.state = filter.state;
    }
    if (filter.risk) {
        body.chargeRiskLevel = filter.risk;
    }
    if (filter.locationId) {
        body.locationIds = [filter.locationId];
    }
    if (filter.paymentMethod) {
        body.paymentIntentPaymentMethodType = filter.paymentMethod;
    }
    if (filter.walletType) {
        body.paymentIntentWalletType = filter.walletType;
    }
    if (filter.type) {
        if (filter.type === PepperTransactionType.Standalone) {
            body.standalone = true;
        } else {
            body.standalone = false;
        }
    }
    const epoch: { $gt?: number; $lt?: number } = {};
    if (filter.date?.startDate) {
        epoch.$gt = getTime(startOfDay(filter.date.startDate));
    }
    if (filter.date?.endDate) {
        epoch.$lt = getTime(endOfDay(filter.date.endDate));
    }
    if (filter.date?.startDate && !filter.date?.endDate) {
        epoch.$lt = getTime(endOfDay(filter.date.startDate));
    }
    if (epoch.$gt || epoch.$lt) {
        body.created = epoch;
    }
    if (filter.method) {
        body.method = {
            type: 'card_present',
            mode: filter.method
        };
    }
    return body;
};

const stateOptions = [
    {
        value: PepperTransactionState.Completed,
        label: 'Completed'
    },
    {
        value: PepperTransactionState.Declined,
        label: 'Declined'
    },
    {
        value: PepperTransactionState.Disputed,
        label: 'Disputed'
    },
    {
        value: PepperTransactionState.Pending,
        label: 'Pending'
    },
    {
        value: PepperTransactionState.Refunded,
        label: 'Refunded'
    },
    {
        value: PepperTransactionState.Expired,
        label: 'Expired'
    },
    {
        value: PepperTransactionState.PartiallyRefunded,
        label: 'Partially Refunded'
    }
];

const walletOptions = [
    {
        value: 'google_pay',
        label: 'Google Pay'
    },
    {
        value: 'apple_pay',
        label: 'Apple Pay'
    }
];

const riskOptions = [
    {
        value: PepperTransactionRiskLevel.Unknown,
        label: 'Unknown'
    },
    {
        value: PepperTransactionRiskLevel.NotAssessed,
        label: 'Not Assessed'
    },
    {
        value: PepperTransactionRiskLevel.Normal,
        label: 'Normal'
    },
    {
        value: PepperTransactionRiskLevel.Elevated,
        label: 'Elevated'
    },
    {
        value: PepperTransactionRiskLevel.Highest,
        label: 'Highest'
    }
];

const typeOptions = [
    {
        value: PepperTransactionType.Order,
        label: 'Order'
    },
    {
        value: PepperTransactionType.Standalone,
        label: 'Standalone'
    }
];

const methodOptions = [
    {
        value: PepperTransactionMethod.QuickPad,
        label: 'QuickPad'
    },
    {
        value: PepperTransactionMethod.NonQuickPad,
        label: 'Non-QuickPad'
    }
];

export const transactionsFilterOptions = {
    state: stateOptions,
    wallet: walletOptions,
    risk: riskOptions,
    type: typeOptions,
    method: methodOptions
};

import { AutoFormFields, FormItem } from 'lib/LegacyForm';
import { Option } from 'lib/types';

export const createCustomerAwardForm: (
    perks: Option[],
    additionalFields?: boolean,
    value?: string
) => AutoFormFields = (perks, additionalFields, value) => {
    const formattedValue = perks.find(item => item.value === value);
    const perk: FormItem = {
        value: formattedValue,
        label: 'Type',
        placeholder: 'Start typing or select an item in the list below...',
        description: 'Choose the Award.',
        options: perks,
        validation: 'required',
        type: 'searchableSelect',
        errors: {
            required: 'This field is required.'
        }
    };
    const form: Record<string, FormItem> = { perk };
    if (additionalFields) {
        form.value = {
            label: 'Amount',
            description: 'How many points or stamps would you like to grant this user?',
            inputType: 'number',
            validation: 'required',
            width: 6,
            errors: {
                required: 'This field is required.'
            }
        };
        form.sendBalanceUpdate = {
            label: 'Include Balance Update',
            description: `When selected, the user will recieve a default balance update along with your message.
                For example 'Your perk has been credited with 5 points and you now have a total of 10 points.'`,
            type: 'checkbox'
        };
        form.message = {
            label: 'Notification',
            description:
                'Let the user know that you have granted them some points or stamps. This will show in the push notifications that they receive.',
            type: 'multiline',
            validation: 'required',
            errors: {
                required: 'This field is required.'
            }
        };
    }
    return form;
};

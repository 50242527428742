import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface ViewTransactionCellProps {
    id: string;
    param: string;
    path: string;
    variant?: TypographyProps['variant'];
}

export const ViewTransactionCell: React.FC<ViewTransactionCellProps> = ({ id, param, path, variant }) => {
    if (id === 'N/A') {
        return <Typography variant={variant}>{id}</Typography>;
    }
    return (
        <Link to={path.replace(`:${param}`, String(id))}>
            <Typography variant={variant}>{id}</Typography>
        </Link>
    );
};

import { Permission, Resource } from '@pepperhq/auth-client';
import PersonAdd from '@mui/icons-material/PersonAdd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import { MuiMenuItem } from 'ui/MenuOn';
import { MuiTable, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { TenantAccess, TenantAccessState } from '../models/TenantAccess';
import { Chip, styled, TableCell } from '@mui/material';
import { Role } from '../models/Role';
import { mapTenantAccessStateToStyles } from 'components/merchant/helpers';
import { Location } from 'components/location/models/LocationModel';
import { loadTenantUserAccess, revokeUsersAccess } from 'store/tenant-access/tenantAccessActions';

const StyledChip = styled(Chip)<{ label: TenantAccessState }>(({ theme, label }) =>
    mapTenantAccessStateToStyles(label, theme)
);

interface UserAccessTableProps {
    isLoading: boolean;
    currentRoles?: Role[];
    currentLocations?: Location[];
    onToggleInviteOpen: () => void;
    onChangeAccessLevelClick: (value: string) => () => void;
    onToggleResendInvite: (value: TenantAccess) => void;
}

export const UserAccessTable: React.FC<UserAccessTableProps> = ({
    isLoading,
    currentRoles,
    currentLocations,
    onToggleInviteOpen,
    onChangeAccessLevelClick,
    onToggleResendInvite
}) => {
    const dispatch = useDispatch();
    const { tenantUsersAccess, summary } = useSelector((state: ApplicationState) => state.tenantAccess);
    const nextKey = summary?.nextKey;
    const tableColumns = React.useMemo(
        () => [
            { key: 'email', label: 'Email' },
            {
                key: 'roleId',
                label: 'Access level',
                render: (item: TenantAccess, key: string) => {
                    const role = currentRoles?.find(role => role.id === item.roleId);
                    return <TableCell key={key}>{role?.name || 'Unknown'}</TableCell>;
                }
            },
            {
                key: 'locationIds',
                label: 'Locations',
                render: (item: TenantAccess, key: string) => {
                    if (!item.locationIds) {
                        return (
                            <TableCell key={key}>
                                <Chip sx={{ mr: 0.5 }} label="All" />
                            </TableCell>
                        );
                    }
                    const locationNames = item.locationIds
                        .slice(0, 2)
                        .map(
                            locationId =>
                                (currentLocations &&
                                    currentLocations.find(location => location._id === locationId)?.title) ||
                                'Unknown Location'
                        );
                    return (
                        <TableCell key={key}>
                            {locationNames.map((locationName, index) => (
                                <Chip
                                    key={`location-name-${key}-${index}`}
                                    label={locationName}
                                    sx={{ mr: 0.5 }}
                                />
                            ))}
                            {item.locationIds.length > 2 && (
                                <Chip label={`+${item.locationIds.length - 2} more`} />
                            )}
                        </TableCell>
                    );
                }
            },
            {
                key: 'accessState',
                label: 'State',
                render: (item: TenantAccess, key: string) => (
                    <TableCell key={key}>
                        <StyledChip label={item.accessState} />
                    </TableCell>
                )
            }
        ],
        [currentLocations, currentRoles]
    );
    const renderInviteButton = React.useCallback(
        () => (
            <TableActionButton Icon={PersonAdd} onClick={onToggleInviteOpen} disabled={isLoading}>
                Invite user
            </TableActionButton>
        ),
        [isLoading, onToggleInviteOpen]
    );
    const revokeUserAccess = React.useCallback(
        (id: string) => async () => {
            const index = tenantUsersAccess.findIndex(user => user.id === id);
            await revokeUsersAccess(id, index)(dispatch);
        },
        [tenantUsersAccess, dispatch]
    );
    const handleScroll = React.useCallback(() => {
        loadTenantUserAccess(nextKey)(dispatch);
    }, [dispatch, nextKey]);

    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const scroll = React.useMemo(
        () => ({
            isMoreItems: !!nextKey,
            key: nextKey,
            onScroll: handleScroll
        }),
        [handleScroll, nextKey]
    );
    const toolbarItems: MuiTableToolbarItems = React.useMemo(
        () =>
            claims &&
            claims.hasPermission(Resource.TenantAccess, Permission.write) && {
                renderCustomElement: renderInviteButton
            },
        [claims, renderInviteButton]
    );
    const itemActions = React.useCallback(
        (tenantAccess: TenantAccess): MuiMenuItem[] => {
            if (!claims || !claims.hasPermission(Resource.TenantAccess, Permission.write)) {
                return [];
            }

            const actions = [];
            if (tenantAccess.accessState !== TenantAccessState.REVOKED) {
                actions.push(
                    ...[
                        {
                            label: 'Change access level',
                            onClick: onChangeAccessLevelClick(tenantAccess.id)
                        },
                        { label: 'Revoke access', onClick: revokeUserAccess(tenantAccess.id) }
                    ]
                );
            }

            if (tenantAccess.accessState === TenantAccessState.PENDING) {
                actions.push({
                    label: 'Resend invite',
                    onClick: () => {
                        onToggleResendInvite(tenantAccess);
                    }
                });
            }

            return actions;
        },
        [claims, onChangeAccessLevelClick, onToggleResendInvite, revokeUserAccess]
    );
    return (
        <MuiTable
            isLoading={isLoading || !!nextKey}
            itemActions={itemActions}
            toolbarItems={toolbarItems}
            data={tenantUsersAccess}
            columns={tableColumns}
            scroll={scroll}
        />
    );
};

import React from 'react';
import { useHistoryFilters } from 'lib/hooks/useHistoryFilters';
import { GridSortModel } from '@mui/x-data-grid-pro';

export interface VouchersFilter {
    voucherSchemeId?: string;
    s?: string;
}

const vouchersFilterKeys: Record<keyof VouchersFilter, boolean> = {
    voucherSchemeId: true,
    s: true
};

export function isVouchersFilterKey(item: string): item is keyof VouchersFilter {
    return item in vouchersFilterKeys;
}

type VouchersFilterValue = string;
export type VouchersFilterChange = (field: keyof VouchersFilter, value: VouchersFilterValue) => void;

export function useVouchersFilter(): [
    VouchersFilter,
    VouchersFilterChange,
    GridSortModel,
    (sortModel: GridSortModel) => void
] {
    const { search, onSearch } = useHistoryFilters();
    const [filter, setFilter] = React.useState<VouchersFilter>(() => ({
        voucherSchemeId: search.voucherSchemeId || undefined,
        s: search.s || undefined
    }));
    const [sortModel, setSortModel] = React.useState<GridSortModel>(
        filter.s
            ? [
                  {
                      sort: filter.s[0] === '-' ? 'desc' : 'asc',
                      field: filter.s[0] === '-' ? filter.s.substring(1) : filter.s
                  }
              ]
            : []
    );
    const generateSortString = React.useCallback((model: GridSortModel) => {
        if (model && model[0]) {
            const sortDirection = model[0]?.sort ?? 'asc';
            const sortField = model[0]?.field ?? 'code';
            return `${sortDirection === 'asc' ? '' : '-'}${sortField}`;
        }
        return undefined;
    }, []);
    const onFilterChange = React.useCallback<VouchersFilterChange>((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    React.useEffect(() => {
        onSearch(filter);
    }, [filter, onSearch]);
    const onSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            setSortModel(sortModel);
            onFilterChange('s', generateSortString(sortModel));
        },
        [generateSortString, onFilterChange]
    );
    return [filter, onFilterChange, sortModel, onSortModelChange];
}

export const getVouchersFilterQuery = (filter: VouchersFilter) => {
    if (filter.voucherSchemeId) {
        return [{ key: 'voucherSchemeId', value: filter.voucherSchemeId }];
    }
    return [];
};

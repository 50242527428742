import { SystemRole } from '@pepperhq/auth-client';
import { roundToDecimal } from 'lib/helpers';

export interface IClerkResource {
    pin: string;
    name: string;
    roleId: string;
    sessionMs: number;
    locationId: string;
    tipGroupId?: string;
}

export interface IClerk extends IClerkResource {
    id: string;
    tenantId: string;
}

export const getTerminalRoleName = (roleId: SystemRole) => {
    switch (roleId) {
        case SystemRole.TerminalClerk:
            return 'Clerk';
        case SystemRole.TerminalManager:
            return 'Manager';
        default:
            return 'Unknown';
    }
};

export const terminalRoleToTitile = {
    [SystemRole.TerminalClerk]: 'Clerk',
    [SystemRole.TerminalManager]: 'Manager'
};

export const getClerkResource = (data: IClerkResource) => ({
    pin: data.pin,
    locationId: data.locationId,
    sessionMs: roundToDecimal(data.sessionMs * 1000, 0),
    name: data.name,
    roleId: data.roleId,
    tipGroupId: data.tipGroupId
});

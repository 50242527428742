import { Box, Button, Card, IconButton, styled, Tooltip, Typography } from '@mui/material';
import {
    DataGridProProps,
    GridCellParams,
    GridColDef,
    gridFilteredSortedRowIdsSelector,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridValidRowModel,
    GridValueGetterParams,
    GridValueSetterParams
} from '@mui/x-data-grid-pro';
import { MenuOverrideSchema } from '@pepperhq/menu-sdk';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { useMenuOverrideChange } from '../../menu/model/menu';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_PRODUCTS_UPDATE_ERROR, MESSAGE_PRODUCT_AVAILABILITY_UPDATE_SUCCESS } from 'config/messages';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { getMenuStorageName } from 'components/menu/gridStateStorageHelpers';
import { RefreshMenuButton } from 'components/menu/RefreshMenuButton';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import {
    CategoryOverrideEditConst,
    CategoryOverrideEditKeys,
    IAvailabilityRowItem,
    IMenuOverrideEditUpdate,
    MenuOverridesFilter,
    ProductOverrideEditConst,
    ProductOverrideEditKeys
} from '../models/Overrides';
import { ProductAvailabilityFilter } from './ProductAvailabilityFilters';
import { useOverridesGridSearch } from './useOverridesGridSearch';
import {
    filterValueByOverrideState,
    OverrideFilterOptions,
    useOverridesGridFilter
} from './useOverridesGridFilter';
import { green, red } from '@mui/material/colors';
import { renderMuiGridSelect } from 'lib/MuiGrid/select/MuiGridSelect';
import { renderMuiGridTextView } from 'lib/MuiGrid/text/MuiGridTextView';
import { isDefined, isString } from 'lib/typeguards';
import NotAvailableIcon from '@mui/icons-material/Cancel';
import AvailableIcon from '@mui/icons-material/CheckCircle';
import VisibleIcon from '@mui/icons-material/Visibility';
import NotVisibleIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { TextGridColumn } from 'lib/MuiGrid/Columns';
import { menuOverridesApi } from 'components/menu/MenuOverridesApi';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useRefreshButton } from '../hooks/useRefreshButton';
import { useOverrideCopyCell } from '../hooks/useOverrideCopyCell';
import { CopyToButton } from 'components/menu/CopyToButton';

const StyledStatusChip = styled('span', { shouldForwardProp: (prop: string) => prop !== 'status' })<{
    status: boolean;
}>(({ status }) => ({
    '& .MuiSvgIcon-root': {
        color: status ? green['A700'] : red['500']
    }
}));

const ExpandAllIcon = styled(DoubleArrowIcon, {
    shouldForwardProp: (prop: string) => prop !== 'isExpanded'
})<{ isExpanded?: boolean }>(({ isExpanded }) => ({
    transform: `rotate(${isExpanded ? '-90deg' : '90deg'})`
}));

const ExpandAllButton = styled(IconButton)(({ theme }) => ({
    alignSelf: 'center',
    marginLeft: theme.spacing(1)
}));

interface LocationProductAvailabilityProps {
    settings: IPublicAndPrivateSettings;
    locationId: string;
    overrides?: IAvailabilityRowItem[];
    zones: string[];
    filter: MenuOverridesFilter;
    onFilterChange: (newFilter: MenuOverridesFilter) => void;
    onRefreshOverrides: () => Promise<{ ok: boolean; statusCode: number; body: any }>;
    resetOverrides: () => void;
    editingDisabled?: boolean;
}

const PREFIX = 'LocationProductAvailability';

const classes = {
    parentCard: `${PREFIX}-parentCard`,
    spacer: `${PREFIX}-spacer`,
    button: `${PREFIX}-button`
};

const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.parentCard}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        flexGrow: 1,
        flexShrink: 0
    },
    [`& .${classes.spacer}`]: {
        flex: '1 1 auto'
    },
    [`& .${classes.button}`]: {
        margin: theme.spacing(0, 1, 0, 1)
    },
    [`& .${classes.button}`]: {
        margin: theme.spacing(0, 1, 0, 1)
    }
}));

const OverrideAvailabilityFilterWrapper = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(1)
}));

const getTitleFontWeight = (type: IAvailabilityRowItem['type']) => {
    switch (type) {
        case 'CATEGORY_GROUP':
        case 'CATEGORY':
            return 600;
        case 'PRODUCT':
            return 400;
        default:
            return 400;
    }
};

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Title',
    hideDescendantCount: true,
    valueFormatter: props => {
        const row = props.api.getRow(props.id);
        return <Typography fontWeight={getTitleFontWeight(row.type)}>{row.value}</Typography>;
    },
    width: 300
};

export const LocationProductAvailability: React.FC<LocationProductAvailabilityProps> = (
    props: LocationProductAvailabilityProps
) => {
    const {
        locationId,
        onRefreshOverrides,
        zones,
        settings,
        overrides,
        filter,
        onFilterChange,
        resetOverrides,
        editingDisabled
    } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    const { readyToSave, handleCancel, handleStatusChange, gridRef } = useMenuOverrideChange(resetOverrides);

    const {
        handleRefreshMenu,
        updated,
        loading: refreshLoading
    } = useRefreshButton(onRefreshOverrides, filter);

    useLeavePageBlock(readyToSave);

    const gridSearchOptions = React.useMemo(() => [{ column: 'value' }], []);
    const gridFilterOptions = React.useMemo(() => [{ column: 'availableOverride' }], []);
    const { filterModel, renderInput, filterOperators, searchValue } = useOverridesGridSearch(
        gridSearchOptions,
        'Search by ID or name'
    );
    const {
        filterModel: overrideFilterModel,
        renderInput: renderOverrideFilterSelect,
        filterOperators: overrideFilterOperator,
        filterValue
    } = useOverridesGridFilter(gridFilterOptions);

    const {
        selectedCell,
        handleCellSelection,
        locationsExcludingCurrent,
        handleFocusOut,
        openModal,
        renderLocationPickerModal,
        copyLoading
    } = useOverrideCopyCell();

    React.useEffect(() => {
        if (gridRef.current) {
            const api = gridRef.current.getApiRef();
            if (api) {
                const expandedRowIds = gridFilteredSortedRowIdsSelector(api.state, api.instanceId).reduce(
                    (acc, rowId) => {
                        const element: IAvailabilityRowItem = api.getRow(rowId);
                        const search = searchValue.toLowerCase();

                        if (
                            element.value.toLowerCase().includes(search) ||
                            element.id.toLowerCase().includes(search) ||
                            filterValueByOverrideState(
                                filterValue,
                                element.availableOverride,
                                element.isAvailable
                            )
                        ) {
                            acc.push(...element.parentKeys);
                        }

                        return acc;
                    },
                    []
                );
                // to remove duplicates
                const isExpanded = searchValue.trim() !== '' || filterValue !== OverrideFilterOptions.ALL;
                [...new Set(expandedRowIds)].forEach(id => api.setRowChildrenExpansion(id, isExpanded));
            }
        }
    }, [filterValue, gridRef, searchValue]);

    const isCellEditable = React.useCallback((params: GridCellParams) => {
        if (params.field === 'availableOverride' && params.row.type === 'PRODUCT') {
            return true;
        }
        return false;
    }, []);

    const renderOverrideCell = React.useCallback((props: GridRenderCellParams) => {
        const item: IAvailabilityRowItem = props.row;
        const displayValues: Record<string, string> =
            item.type === 'PRODUCT' ? ProductOverrideEditConst.labels : CategoryOverrideEditConst.labels;
        let displayValue;

        if (item.type === 'PRODUCT') {
            if (props.field === 'availableOverride') {
                if (props.value !== undefined && props.value !== '') {
                    displayValue = displayValues[props.value as ProductOverrideEditKeys];
                } else {
                    displayValue = '';
                }
            } else {
                displayValue = '';
            }
        } else if (props.field === 'availabilityOverrideCode') {
            displayValue = displayValues[props.value as CategoryOverrideEditKeys];
        } else {
            displayValue = '';
        }

        return renderMuiGridTextView(props, isString(displayValue) ? displayValue : '');
    }, []);
    const renderOverrideProductEditCell = React.useCallback((props: GridRenderEditCellParams) => {
        const item: IAvailabilityRowItem = props.row;
        const displayValues: Record<string, string> =
            item.type === 'PRODUCT' ? ProductOverrideEditConst.options : CategoryOverrideEditConst.options;
        const options = Object.entries(displayValues)
            .map(([value, label]) => ({ value, label }))
            .filter(({ label }) => !!label);

        return item.type === 'PRODUCT' ? renderMuiGridSelect(props, options) : null;
    }, []);
    const overrideProductCellValueSetter = React.useCallback((params: GridValueSetterParams) => {
        const item: IAvailabilityRowItem = params.row;
        if (item.type === 'PRODUCT') {
            const valueToBeSet = {
                availableOverride: ProductOverrideEditConst.values[params.value as ProductOverrideEditKeys]
            };

            return { ...params.row, ...valueToBeSet };
        }
        return params.row;
    }, []);

    const overrideAvailabilityCellValueGetter = React.useCallback((params: GridValueGetterParams) => {
        const { availableOverride } = params.row;
        return isDefined(availableOverride) ? availableOverride : '';
    }, []);
    const overridesColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                editable: false,
                width: 150,
                ...TextGridColumn()
            },
            {
                field: 'value',
                headerName: 'Title',
                editable: false,
                width: 300,
                hide: true,
                renderCell: props =>
                    renderMuiGridTextView(
                        props,
                        <Typography fontWeight={getTitleFontWeight(props.row.type)}>{props.value}</Typography>
                    ),
                filterOperators: [filterOperators.value]
            },
            {
                field: 'isAvailable',
                headerName: 'Availability',
                description: 'See whether a product is available for a specific location',
                width: 100,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                type: 'string',
                renderCell: (params: GridRenderCellParams) => {
                    const item: IAvailabilityRowItem = params.row;

                    return item.type === 'PRODUCT' ? (
                        <Box>
                            <Tooltip title={item.availabilityExplaination || 'Product available - default'}>
                                <StyledStatusChip status={item.isAvailable}>
                                    {item.isAvailable ? (
                                        <AvailableIcon color="success" />
                                    ) : (
                                        <NotAvailableIcon color="error" />
                                    )}
                                </StyledStatusChip>
                            </Tooltip>
                        </Box>
                    ) : (
                        ''
                    );
                }
            },
            {
                field: 'availableOverride',
                headerName: 'Availability Override',
                description: 'Manually override the availability status for products.',
                editable: !editingDisabled,
                width: 150,
                type: 'string',
                renderCell: renderOverrideCell,
                renderEditCell: renderOverrideProductEditCell,
                valueSetter: overrideProductCellValueSetter,
                valueGetter: overrideAvailabilityCellValueGetter,
                filterOperators: [overrideFilterOperator.availableOverride]
            },
            {
                field: 'visibilityStatus',
                headerName: 'Visibility',
                description:
                    'See whether a product, category or category group is visible in your app for a specific location.',
                width: 100,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const item: IAvailabilityRowItem = params.row;
                    const defaultTooltip =
                        (item.type === 'PRODUCT' && 'Product visible - default') ||
                        (item.type === 'CATEGORY' && 'Category visible - default') ||
                        (item.type === 'CATEGORY_GROUP' && 'Category Group visible - default');

                    return (
                        <Box textAlign="center">
                            <Tooltip title={item.visibilityExplaination || defaultTooltip}>
                                <StyledStatusChip status={item.visibilityStatus}>
                                    {item.visibilityStatus ? (
                                        <VisibleIcon color="success" />
                                    ) : (
                                        <NotVisibleIcon color="error" />
                                    )}
                                </StyledStatusChip>
                            </Tooltip>
                        </Box>
                    );
                }
            }
        ],
        [
            filterOperators.value,
            overrideAvailabilityCellValueGetter,
            overrideFilterOperator.availableOverride,
            overrideProductCellValueSetter,
            renderOverrideCell,
            renderOverrideProductEditCell,
            editingDisabled
        ]
    );
    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_PRODUCTS_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState() ?? {};
            const allChanges = Object.entries(changes).reduce<Partial<IMenuOverrideEditUpdate>>(
                (acc, [, { ...item }]) => {
                    let valuesMap: any;
                    switch (item.row.type as IAvailabilityRowItem['type']) {
                        case 'PRODUCT':
                            // eslint-disable-next-line no-case-declarations
                            valuesMap = ProductOverrideEditConst.values;
                            acc.products[item.row.id] = {
                                availableOverride:
                                    valuesMap[item.availableOverride as ProductOverrideEditKeys]
                            };

                            break;
                        case 'CATEGORY':
                            valuesMap = CategoryOverrideEditConst.values;

                            acc.categories[item.row.id] = {
                                availabilityOverrideCode:
                                    valuesMap[item.availabilityOverrideCode as CategoryOverrideEditKeys]
                            };

                            break;
                        case 'CATEGORY_GROUP':
                            valuesMap = CategoryOverrideEditConst.values;
                            acc.categoryGroups[item.row.id] = {
                                availabilityOverrideCode:
                                    valuesMap[item.availabilityOverrideCode as CategoryOverrideEditKeys]
                            };

                            break;

                        default:
                            break;
                    }

                    return acc;
                },
                {
                    categories: {},
                    products: {},
                    categoryGroups: {}
                }
            );
            const result = await menuOverridesApi.updateMenuOverride(locationId, {
                ...allChanges,
                schema: MenuOverrideSchema.v6
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            await onRefreshOverrides();
            dispatch(enqueueSnackbar(MESSAGE_PRODUCT_AVAILABILITY_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(MESSAGE_PRODUCTS_UPDATE_ERROR, { variant: 'error' }));
        }

        setLoading(false);
    }, [gridRef, locationId, onRefreshOverrides, dispatch]);
    const handleCopyClick = React.useCallback(() => {
        openModal();
    }, [openModal]);
    const getPath = React.useCallback((row: IAvailabilityRowItem) => row.path, []);
    const getRowId = React.useCallback((row: IAvailabilityRowItem) => row._id, []);
    const handleShouldEditCellChangeCommitted = React.useCallback((row: GridValidRowModel, field: string) => {
        if (field === 'availabilityOverrideCode' && row.type === 'PRODUCT') {
            return false;
        }

        if (field === 'availableOverride' && row.type !== 'PRODUCT') {
            return false;
        }

        return true;
    }, []);
    const getRowsForStateChange = React.useCallback(() => {
        if (gridRef.current) {
            const api = gridRef.current.getApiRef();
            return gridFilteredSortedRowIdsSelector(api.state, api.instanceId).reduce((acc, rowId) => {
                const element: IAvailabilityRowItem = api.getRow(rowId);

                acc.push(...element.parentKeys);

                return acc;
            }, []);
        }
        return [];
    }, [gridRef]);
    const handleExpand = React.useCallback(() => {
        if (gridRef.current) {
            const rows = getRowsForStateChange();
            const api = gridRef.current.getApiRef();
            [...new Set(rows)].forEach(id => api.setRowChildrenExpansion(id, true));
        }
    }, [getRowsForStateChange, gridRef]);
    const handleCollapse = React.useCallback(() => {
        if (gridRef.current) {
            const rows = getRowsForStateChange();
            const api = gridRef.current.getApiRef();

            [...new Set(rows)].forEach(id => api.setRowChildrenExpansion(id, false));
        }
    }, [getRowsForStateChange, gridRef]);

    return (
        <>
            <RefreshMenuButton
                isUnsavedChanges={readyToSave}
                onClick={handleRefreshMenu}
                updated={updated}
                direction="row-reverse"
                settings={settings}
                loading={refreshLoading}
            />
            <StyledCard className={classes.parentCard}>
                <Box display="flex" flexWrap="wrap" pb={1} alignItems="center">
                    {renderInput()}
                    <OverrideAvailabilityFilterWrapper>
                        {renderOverrideFilterSelect()}
                    </OverrideAvailabilityFilterWrapper>
                    <Tooltip title="Collapse All">
                        <ExpandAllButton onClick={handleCollapse}>
                            <ExpandAllIcon isExpanded />
                        </ExpandAllButton>
                    </Tooltip>
                    <Tooltip title="Expand All">
                        <ExpandAllButton sx={{ ml: 0 }} onClick={handleExpand}>
                            <ExpandAllIcon />
                        </ExpandAllButton>
                    </Tooltip>
                    <ProductAvailabilityFilter
                        zones={zones}
                        filter={filter}
                        onFilterChange={onFilterChange}
                    />
                    {readyToSave && !editingDisabled && (
                        <Box marginLeft="auto">
                            <Button className={classes.button} onClick={handleCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                loading={loading}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    )}
                    {!readyToSave && !editingDisabled && (
                        <CopyToButton
                            className={classes.button}
                            readyToSave={readyToSave}
                            loading={copyLoading}
                            disabled={!selectedCell || copyLoading}
                            otherLocations={locationsExcludingCurrent}
                            onCopy={handleCopyClick}
                        />
                    )}
                </Box>
                {overrides && (
                    <MuiGrid
                        rowHeight={35}
                        sx={{ minHeight: '500px' }}
                        disableSelectionOnClick
                        rows={overrides}
                        columns={overridesColumns}
                        stateRef={gridRef}
                        onStatusChange={handleStatusChange}
                        storageName={getMenuStorageName(GridStorageName.ProductAvailability)}
                        disableColumnFilter
                        treeData
                        getTreeDataPath={getPath}
                        isCellEditable={isCellEditable}
                        getRowId={getRowId}
                        onCellClick={handleCellSelection}
                        onCellFocusOut={handleFocusOut}
                        groupingColDef={groupingColDef}
                        filterModel={{
                            items: [...filterModel.items, ...overrideFilterModel.items],
                            linkOperator: filterModel.linkOperator
                        }}
                        shouldReturnRowValueOnChange
                        shouldEditCellChangeCommitted={handleShouldEditCellChangeCommitted}
                    />
                )}
                {renderLocationPickerModal()}
            </StyledCard>
        </>
    );
};

import { Typography } from '@mui/material';
import { GridCellValue } from '@mui/x-data-grid-pro';
import React from 'react';
import { Link } from 'react-router-dom';

interface GridLinkCellProps {
    id: GridCellValue;
    param: string;
    path: string;
}

export const GridLinkCell: React.FC<GridLinkCellProps> = ({ id, param, path }) => (
    <Link to={path.replace(`:${param}`, String(id))}>
        <Typography>{id}</Typography>
    </Link>
);

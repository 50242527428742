import React from 'react';
import { TableCell, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems } from 'ui/table/MuiTable';
import { SegmentUser } from './models/segmentUserModel';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from 'ui/buttons/IconButton';
import { Link } from 'react-router-dom';
import { CUSTOMERS_VIEW } from 'config/routes';

const classes = {
    iconButton: 'Segment-User-Table-Delete'
};

const StyledTableCell = styled(TableCell)(() => ({
    [`& .${classes.iconButton}`]: {
        height: '24px',
        opacity: 0.3
    },
    [`& .${classes.iconButton}:hover`]: {
        opacity: 1
    }
}));

function renderUserExpiry(user: SegmentUser, key: string) {
    return (
        <TableCell key={key}>{user.expires && format(new Date(user.expires), 'dd/MM/yyyy h:mmaa')}</TableCell>
    );
}

interface SegmentUserTableProps {
    scroll: MuiTableScrollItems;
    data: SegmentUser[];
    includeExpiry?: boolean;
    onDelete: (userId: string) => void;
}

export const SegmentUserTable: React.FC<SegmentUserTableProps> = ({ onDelete, ...props }) => {
    const handleDelete = React.useCallback(
        (userId: string) => () => {
            onDelete(userId);
        },
        [onDelete]
    );
    const renderUserName = React.useCallback(
        (user: SegmentUser, key: string) => (
            <TableCell size="small" key={key}>
                <Link to={CUSTOMERS_VIEW.replace(':customerId', user.userId)}>
                    <Typography>{user.userFullName}</Typography>
                </Link>
            </TableCell>
        ),
        []
    );
    const renderDeleteUser = React.useCallback(
        (user: SegmentUser, key: string) => (
            <StyledTableCell key={key} size="small" align="right">
                <IconButton className={classes.iconButton} onClick={handleDelete(user.userId)}>
                    <DeleteIcon />
                </IconButton>
            </StyledTableCell>
        ),
        [handleDelete]
    );

    const expiryColumns: MuiTableColumnItem[] = React.useMemo(
        () => [
            {
                key: 'userFullName',
                label: 'Name',
                render: renderUserName
            },
            {
                key: 'expires',
                label: 'Expiry',
                render: renderUserExpiry
            },
            {
                key: 'delete',
                label: '',
                render: renderDeleteUser
            }
        ],
        [renderDeleteUser, renderUserName]
    );

    const columns: MuiTableColumnItem[] = React.useMemo(
        () => [
            {
                key: 'userFullName',
                label: 'Name',
                render: renderUserName
            },
            {
                key: 'delete',
                label: '',
                render: renderDeleteUser
            }
        ],
        [renderDeleteUser, renderUserName]
    );
    return <MuiTable columns={props.includeExpiry ? expiryColumns : columns} {...props} />;
};

import Tab, { TabProps } from '@mui/material/Tab';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface LinkTabProps extends TabProps<'a'> {
    label?: string;
    href?: string;
}

export function LinkTab(props: LinkTabProps) {
    const { push } = useHistory();

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
        event => {
            event.preventDefault();
            push(props.href);
        },
        [props.href, push]
    );

    return <Tab component="a" onClick={handleClick} {...props} />;
}

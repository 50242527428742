import { Chip, styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import React from 'react';

const PREFIX = 'TransactionStateChip';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-grey`
};

const StyledChip = styled(Chip)(({ theme }) => ({
    [`&.${classes.green}`]: { backgroundColor: green['A700'], color: theme.palette.primary.contrastText },
    [`&.${classes.yellow}`]: { backgroundColor: orange[500], color: theme.palette.primary.contrastText },
    [`&.${classes.red}`]: { backgroundColor: red[500], color: theme.palette.primary.contrastText },
    [`&.${classes.grey}`]: {}
}));

interface TransactionStateChipProps {
    value: string;
}

export const TransactionStateChip: React.FC<TransactionStateChipProps> = ({ value }) => {
    const className = React.useMemo(() => {
        switch (value) {
            case 'COMPLETED':
                return classes.green;
            case 'PENDING':
                return classes.yellow;
            case 'DECLINED':
            case 'DISPUTED':
                return classes.red;
            default:
                return classes.grey;
        }
    }, [value]);
    return <StyledChip className={className} label={value.replace('_', ' ')} />;
};

import React from 'react';
import { Grid } from '@mui/material';
import { CardViewTextRow } from 'ui/viewComponents';
import { isCheckinAction } from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';

export const ActionCardUserCheckin: React.FC<ActionCardModalComponentProps> = ({ action }) => {
    if (!isCheckinAction(action)) {
        return <ActionCardBase action={action} broken />;
    }
    return (
        <ActionCardBase action={action}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CardViewTextRow title="Date" value={getActionDate(action)} />
                </Grid>
                <Grid item xs={12}>
                    <CardViewTextRow title="Location" value={action.context.location.title} />
                </Grid>
            </Grid>
        </ActionCardBase>
    );
};

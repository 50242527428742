import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';

export const ComtrexSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <SettingDisplay label="Vendor Name">
                <TextFormField name="comtrexVendorName" />
            </SettingDisplay>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Host">
                    <TextFormField name="comtrexHost" />
                </SettingDisplay>
                <SettingDisplay label="Payment Type Id">
                    <TextFormField name="comtrexPaymentTypeId" />
                </SettingDisplay>
                <SettingDisplay label="Tip Item Number">
                    <TextFormField name="comtrexTipItemNumber" />
                </SettingDisplay>
                <SettingDisplay label="Note Fire Message ID">
                    <TextFormField name="comtrexNoteFireMessageId" />
                </SettingDisplay>
                <SettingDisplay label="Allergen List Number">
                    <TextFormField name="comtrexAllergenListNumber" />
                </SettingDisplay>
                <SettingDisplay label="Map Allergen As Modifier">
                    <SwitchFormField name="comtrexMapAllergenAsCondiment" />
                </SettingDisplay>
                <SettingDisplay label="Order To Table Sales Type Number">
                    <TextFormField name="comtrexORDER_TO_TABLE_salesTypeNumber" />
                </SettingDisplay>
                <SettingDisplay label="Pre-Order Sales Type Number">
                    <TextFormField name="comtrexPREORDER_salesTypeNumber" />
                </SettingDisplay>
                <SettingDisplay label="Table Sales Type Number">
                    <TextFormField name="comtrexTABLE_salesTypeNumber" />
                </SettingDisplay>
                <SettingDisplay label="Tab Sales Type Number">
                    <TextFormField name="comtrexTAB_salesTypeNumber" />
                </SettingDisplay>
            </>
        )}
        {/* Both */}
        <SettingDisplay label="Filter for Menu Category Ids">
            <TextFormField name="comtrexMenuCategoryFilter" />
        </SettingDisplay>
    </>
);

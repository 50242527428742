import { Option } from 'lib/types';

export enum EGoodtillPriceSource {
    DEFAULT = 'DEFAULT',
    TAKEAWAY = 'TAKEAWAY'
}

export const goodtillPriceSourceOptions: Option[] = [
    { label: 'Default', value: EGoodtillPriceSource.DEFAULT },
    { label: 'Takeaway', value: EGoodtillPriceSource.TAKEAWAY }
];

export const getGoodtillPriceSourceLabel = (value: string | undefined): string => {
    if (value === EGoodtillPriceSource.TAKEAWAY) {
        return 'Takeaway';
    }

    return 'Default';
};

import { isDefined, isNumber, isString } from 'lib/typeguards';

export interface ApiError {
    httpCode: number;
    code: string;
    message: string;
    isRetryable: boolean;
}

export function isApiError(error: any): error is ApiError {
    return (
        isDefined(error) &&
        isNumber(error.httpCode) &&
        isString(error.code) &&
        isString(error.message) &&
        isString(error.isRetryable)
    );
}

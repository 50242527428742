import { AutoFormFields } from 'lib/LegacyForm';
import { ContentModule, ContentModuleType, IDisplay } from '../models/ContentModule';
import {
    createModuleBasicForm,
    transformBasicFormFieldToModuleFields,
    transformModuleToBasicFormFields
} from './createModuleBasicForm';
import {
    createModuleLocationsForm,
    transformLocationFormFieldToModuleFields,
    transformModuleToLocationFormFields
} from './createModuleLocationsForm';
import { createModuleLoyaltyBalanceForm } from './createModuleLoyaltyBalanceForm';
import {
    createModuleMarketingForm,
    transformMarketingFormFieldToModuleFields,
    transformModuleToMarketingFormFields
} from './createModuleMarketingForm';
import { createModulePrimaryActionForm } from './createModulePrimaryActionForm';

export interface ContentModuleAdditionalData {
    display?: IDisplay;
}

const moduleForms: (
    additionalData?: ContentModuleAdditionalData
) => { [key in ContentModuleType]: AutoFormFields } = additionalData => ({
    PRIMARY_ACTION: createModulePrimaryActionForm,
    MARKETING: createModuleMarketingForm(additionalData),
    LOCATIONS: createModuleLocationsForm,
    FAVOURITES: createModuleBasicForm,
    AWARDS: createModuleBasicForm,
    SECRET_DJ: createModuleBasicForm,
    LOYALTY_BALANCE: createModuleLoyaltyBalanceForm
});

export const createModuleForm = (
    moduleType: ContentModuleType,
    additionalData?: ContentModuleAdditionalData
) => moduleForms(additionalData)[moduleType];

export function transformModuleToFormFields(module: ContentModule): any {
    switch (module.type) {
        case ContentModuleType.FAVOURITES:
        case ContentModuleType.AWARDS:
            return transformModuleToBasicFormFields(module);
        case ContentModuleType.LOCATIONS:
            return transformModuleToLocationFormFields(module);
        case ContentModuleType.MARKETING:
            return transformModuleToMarketingFormFields(module);
        default:
            return module;
    }
}

export function transformFormFieldToModuleFields(
    module: ContentModule,
    fieldName: string,
    value: any,
    formData: { [key: string]: any }
): { name: string; value: any }[] {
    if (fieldName === 'index') {
        return [{ name: fieldName, value: Number(value) }];
    }
    switch (module.type) {
        case ContentModuleType.FAVOURITES:
        case ContentModuleType.AWARDS:
            return transformBasicFormFieldToModuleFields(fieldName, value, formData);
        case ContentModuleType.LOCATIONS:
            return transformLocationFormFieldToModuleFields(fieldName, value, formData);
        case ContentModuleType.MARKETING:
            return transformMarketingFormFieldToModuleFields(fieldName, value, formData);
        default:
            return [{ value, name: fieldName }];
    }
}

import React from 'react';
import { Column, Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { Button, styled, Typography } from '@mui/material';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiReactImageCrop } from './MuiReactImageCrop';
import { Crop } from 'react-image-crop';

const PREFIX = 'MuiImagePicker';

const classes = {
    column: `${PREFIX}-column`
};

interface ImagePickerCropModalProps {
    open: boolean;
    onClose: () => void;
    src?: string;
    loading?: boolean;
    disabled?: boolean;
    disableCrop?: boolean;
    error?: string;
    onSubmit: () => void;
    crop?: Crop;
    onImageLoaded: (image: HTMLImageElement) => void;
    onCropComplete: (crop: Crop) => void;
    onCropChange: (crop: Crop) => void;
}

const StyledRow = styled(Row)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    width: '100%',
    [`& .${classes.column}`]: {
        minHeight: theme.spacing(5),
        display: 'contents'
    }
}));

export const ImagePickerCropModal: React.FC<ImagePickerCropModalProps> = ({
    open,
    onClose,
    src,
    loading,
    disabled,
    error,
    onSubmit,
    crop,
    onCropChange,
    onCropComplete,
    onImageLoaded,
    disableCrop
}) => {
    if (!src) {
        return null;
    }
    return (
        <MuiModal open={open} style={{ maxWidth: 800 }} onClose={onClose}>
            <Column>
                <MuiReactImageCrop
                    src={src}
                    crop={crop}
                    disabled={disableCrop}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                />
                <StyledRow flex={1} align="space-between" valign="center">
                    <Column className={classes.column} valign="center">
                        {error && (
                            <Typography color="error" sx={{ width: '100%' }}>
                                {error}
                            </Typography>
                        )}
                        {!disabled && !error && (
                            <Typography variant="caption">Drag and drop to crop the image</Typography>
                        )}
                    </Column>
                    <Row align="flex-end" gutter>
                        <Button color="secondary" onClick={onClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            onClick={onSubmit}
                            loading={loading}
                            disabled={disabled}
                        >
                            Submit
                        </LoadingButton>
                    </Row>
                </StyledRow>
            </Column>
        </MuiModal>
    );
};

import { perkApi } from 'components/loyalty/PerkApi';
import { Perk } from 'components/loyalty/models/PerkModel';
import {
    MESSAGE_MARKETING_PERK_RESET_SUCCESS,
    MESSAGE_MARKETING_PERK_RESET_ERROR,
    MESSAGE_MARKETING_PERK_DELETE_ERROR,
    MESSAGE_MARKETING_PERK_DELETE_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { PerksActionType } from 'store/perks/perksActionTypes';
import { ApplicationState } from 'store/store';

export const usePepperPerksMutation = () => {
    const dispatch = useDispatch();
    const { perks, indexToReset, indexToDelete } = useSelector((state: ApplicationState) => state.perks);

    const handleCreateSuccess = React.useCallback(
        ({ perk, stepFour }: { perk: Perk; stepFour?: boolean }) => {
            if (stepFour) {
                return dispatch({ perk, type: PerksActionType.CREATE_SUCCESS_CLUB });
            }
            dispatch({ perk, type: PerksActionType.CREATE_SUCCESS });
        },
        [dispatch]
    );

    const handleUpdateSuccess = React.useCallback(
        (perk: Perk) => {
            dispatch({ perk, type: PerksActionType.UPDATE_SUCCESS });
        },
        [dispatch]
    );

    const resetPerk = React.useCallback(async () => {
        try {
            dispatch({ type: PerksActionType.START_RESET_REQUEST });

            const perk = perks[indexToReset];
            const result = await perkApi.reset(perk._id, {
                body: { confirmationString: 'RESET PERK' },
                skipResponseBody: true
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }

            dispatch({ type: PerksActionType.RESET_SUCCESS });
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_RESET_SUCCESS, { variant: 'success' }));
        } catch (error) {
            logger.error(error.message);

            dispatch({ type: PerksActionType.RESET_ERROR });
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_RESET_ERROR, { variant: 'error' }));
        }
    }, [dispatch, perks, indexToReset]);

    const deletePerk = React.useCallback(async () => {
        try {
            dispatch({ type: PerksActionType.START_DELETE_REQUEST });
            const perk = perks[indexToDelete];
            const result = await perkApi.delete(perk._id, { skipResponseBody: true });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            dispatch({ type: PerksActionType.DELETE_SUCCESS });
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_DELETE_SUCCESS, { variant: 'success' }));
        } catch (error) {
            logger.error(error.message);
            dispatch({ type: PerksActionType.DELETE_ERROR });
            dispatch(enqueueSnackbar(MESSAGE_MARKETING_PERK_DELETE_ERROR, { variant: 'error' }));
        }
    }, [dispatch, perks, indexToDelete]);

    return {
        handleCreateSuccess,
        handleUpdateSuccess,
        resetPerk,
        deletePerk
    };
};

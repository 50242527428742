import React from 'react';
import { styled, Theme } from '@mui/material';
import clsx from 'clsx';
import { MUIStyledCommonProps } from '@mui/system';

const PREFIX = 'MobileLayout';

const classes = {
    phone: `${PREFIX}-phone`,
    content: `${PREFIX}-content`
};

const StyledDiv = styled('div', {
    shouldForwardProp: propName => propName === 'className' || propName === 'children'
})(({ theme, scale }: MobileLayoutProps & MUIStyledCommonProps<Theme>) => ({
    borderRadius: theme.spacing(2),
    width: scale * 375,
    height: scale * 785,
    margin: scale * 16,
    border: '8px #eae8e8 solid',
    borderTopWidth: scale * 50,
    borderBottomWidth: scale * 50,
    boxShadow: theme.shadows[3],
    position: 'relative',
    '&:after': {
        content: 'no-close-quote',
        display: 'block',
        width: scale * 40,
        height: scale * 5,
        position: 'absolute',
        top: scale * -25,
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#333',
        borderRadius: scale * 5
    },
    [`& .${classes.content}`]: {
        position: 'relative',
        zIndex: 0,
        marginRight: 2,
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
        width: '100%'
    }
}));

export interface MobileLayoutProps {
    className?: string;
    scale?: number;
}

export const MobileLayout: React.FC<MobileLayoutProps> = props => {
    const { children, ...rest } = props;
    return (
        <StyledDiv {...rest} className={rest.className}>
            <div className={clsx(classes.content, 'hidden-scroll')}>{children}</div>
        </StyledDiv>
    );
};

MobileLayout.defaultProps = {
    scale: 0.6
};

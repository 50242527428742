import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { SelectComponentsProps } from 'react-select/src/Select';
import { ActionMeta, ValueType } from 'react-select/src/types';
import { Chip, emphasize, MenuItem, Paper, TextField, Theme, Typography, styled } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Option } from 'lib/types';
import { Column } from 'ui/Flex';
import { StyledComponent } from '@emotion/styled';

const PREFIX = 'MultiSelectSuggest';

const classes = {
    root: `${PREFIX}-root`,
    input: `${PREFIX}-input`,
    valueContainer: `${PREFIX}-valueContainer`,
    chip: `${PREFIX}-chip`,
    chipFocused: `${PREFIX}-chipFocused`,
    noOptionsMessage: `${PREFIX}-noOptionsMessage`,
    singleValue: `${PREFIX}-singleValue`,
    placeholder: `${PREFIX}-placeholder`,
    paper: `${PREFIX}-paper`,
    divider: `${PREFIX}-divider`,
    clearIndicator: `${PREFIX}-clearIndicator`
};

const applyStyles = ({ theme }: { theme: Theme }) => ({
    [`& .${classes.root}`]: {
        flexGrow: 1,
        height: 250,
        minWidth: 290
    },

    [`& .${classes.input}`]: {
        display: 'flex',
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.5),
        paddingLeft: 0,
        paddingRight: 0,
        height: 'auto'
    },

    [`& .${classes.valueContainer}`]: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },

    [`& .${classes.chip}`]: {
        margin: theme.spacing(0.5, 0.25)
    },

    [`& .${classes.chipFocused}`]: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        )
    },

    [`& .${classes.noOptionsMessage}`]: {
        padding: theme.spacing(1, 2)
    },

    [`& .${classes.singleValue}`]: {
        fontSize: 16
    },

    [`& .${classes.placeholder}`]: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16
    },

    [`& .${classes.paper}`]: {
        position: 'absolute',
        zIndex: 1000000,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },

    [`& .${classes.divider}`]: {
        height: theme.spacing(2)
    },

    [`& .${classes.clearIndicator}`]: {
        padding: theme.spacing(0.25)
    }
});

const StyledCreatable = styled(Creatable)(applyStyles);
const StyledSelect = styled(Select)(applyStyles);

export interface MultiSelectSuggestProps {
    label?: React.ReactNode;
    disabled?: boolean;
    name?: string;
    onBlur?: (e: React.FocusEvent) => void;
    options?: Option[];
    placeholder?: string;
    value: ValueType<Option, true> | ValueType<Option, false>;
    onChange: ((
        value: ValueType<Option, true> | ValueType<Option, false>,
        actionMeta?: ActionMeta<ValueType<Option, true> | ValueType<Option, false>>
    ) => void) &
        ((
            value: ValueType<Option, true> | ValueType<Option, false>,
            action?: ActionMeta<ValueType<Option, true> | ValueType<Option, false>>
        ) => void);
    inputValue?: string | undefined;
    menuIsOpen?: boolean;
    onInputChange?: (newValue: string) => void;
    onKeyDown?: React.KeyboardEventHandler;
    isCreatable?: boolean;
    isMulti?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
    className?: string;
}

function NoOptionsMessage(props: MenuProps<Option, true> | MenuProps<Option, false>) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...rest }: any) {
    return <div ref={inputRef} {...rest} />;
}

function Control(props: ControlProps<Option, true> | ControlProps<Option, false>) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { TextFieldProps, isDisabled }
    } = props;

    return (
        <TextField
            fullWidth
            disabled={isDisabled}
            InputProps={{
                inputComponent,
                inputProps: {
                    children,
                    className: classes.input,
                    ref: innerRef,
                    ...innerProps
                }
            }}
            {...TextFieldProps}
        />
    );
}

function OptionComponent(props: OptionProps<Option, true> | OptionProps<Option, false>) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props: PlaceholderProps<Option, true> | PlaceholderProps<Option, false>) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props: SingleValueProps<Option>) {
    return (
        <Typography component="div" className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props: ValueContainerProps<Option, true> | ValueContainerProps<Option, false>) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props: MultiValueProps<Option>) {
    return (
        <Chip
            key={typeof props.children === 'string' ? props.children : uuid()}
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props: MenuProps<Option, true> | MenuProps<Option, false>) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

function Empty(): React.ReactElement {
    return null;
}

const formatOptionLabel = ({ description, label }: Option) =>
    description ? (
        <Column>
            {label}
            <Typography variant="caption">{description}</Typography>
        </Column>
    ) : (
        label
    );

export function MultiSelectSuggest(props: MultiSelectSuggestProps) {
    const {
        value,
        onChange,
        options,
        disabled,
        label,
        placeholder,
        inputValue,
        onBlur,
        menuIsOpen,
        onInputChange,
        onKeyDown,
        isCreatable,
        error,
        isMulti,
        helperText,
        name,
        className
    } = props;

    const Component: StyledComponent<SelectComponentsProps> = isCreatable ? StyledCreatable : StyledSelect;
    const uniqIdentifier = React.useMemo(() => (label ? label : uuid()), [label]);

    return (
        <Component
            classes={classes}
            inputId={`react-select-multiple-${uniqIdentifier}`}
            TextFieldProps={{
                label,
                error,
                helperText,
                InputLabelProps: {
                    htmlFor: `react-select-multiple-${uniqIdentifier}`,
                    shrink: true
                }
            }} // I don't worry on what do we have as a base, example you can find here https://react-select.com/advanced#replacing-builtins
            styles={{ clearIndicator: (base: any) => ({ ...base, padding: 2 }) }}
            onBlur={onBlur}
            name={name}
            isDisabled={disabled}
            placeholder={placeholder}
            options={options}
            components={{
                Control,
                Menu,
                MultiValue,
                NoOptionsMessage,
                Placeholder,
                SingleValue,
                ValueContainer,
                Option: OptionComponent,
                DropdownIndicator: Empty,
                DownChevron: Empty,
                IndicatorSeparator: Empty
            }}
            formatOptionLabel={formatOptionLabel}
            value={value ?? ''}
            onChange={onChange}
            isMulti={isMulti}
            inputValue={inputValue}
            isClearable
            menuIsOpen={menuIsOpen}
            onInputChange={onInputChange}
            onKeyDown={onKeyDown}
            className={className}
        />
    );
}

MultiSelectSuggest.defaultProps = {
    isMulti: true
};

import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { getZonesArrayFromString } from 'components/location/helpers';
import {
    REQUIRED_ERROR_MESSAGE,
    TABLE_NUMBER_CHARACTERS_ERROR,
    TABLE_ZONE_ALPHANUMERIC_CHARACTERS_ERROR
} from 'config/messages';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal, MuiModalProps } from 'ui/MuiModal';

export interface ITableZoneForm {
    zoneId: string;
    tables: string;
}

const validationSchema = Yup.object().shape({
    zoneId: Yup.string()
        .required(REQUIRED_ERROR_MESSAGE)
        .matches(/^[a-zA-Z0-9_]*$/, TABLE_ZONE_ALPHANUMERIC_CHARACTERS_ERROR),
    tables: Yup.string()
        .required(REQUIRED_ERROR_MESSAGE)
        .matches(/[0-9]+(?:-[0-9]+)?(,[0-9]+(?:-[0-9]+)?)*/, TABLE_NUMBER_CHARACTERS_ERROR)
        .test('isValidZone', TABLE_NUMBER_CHARACTERS_ERROR, tableZones => {
            const zonesArray = getZonesArrayFromString(tableZones ?? '');

            return !zonesArray.some(zone => zone.includes(NaN) || zone.includes(0));
        })
});

const TableZoneForm: React.FC = () => {
    const { submitForm } = useFormikContext<ITableZoneForm>();

    return (
        <Grid container>
            <Box width="100%" mt={2}>
                <TextFormField
                    description="Enter the table numbers you'd like this zone to be applied to. Each table should be
                    separated with a comma and use a dash for a range of tables e.g. 1-10,60,70-75."
                    label="Table Number"
                    name="tables"
                />
            </Box>

            <Box width="100%" mt={2}>
                <TextFormField label="Zone" name="zoneId" />
            </Box>

            <Box width="100%" display="flex" mt={2} justifyContent="flex-end" alignItems="center">
                <LoadingButton color="primary" variant="contained" onClick={submitForm}>
                    Save
                </LoadingButton>
            </Box>
        </Grid>
    );
};

interface ICreateTableZoneModalProps {
    onAddition: (zoneDetails: ITableZoneForm) => void;
    onClose: () => void;
    title: string;
    open: boolean;
}

export const CreateTableZoneModal: React.FC<ICreateTableZoneModalProps> = ({ onAddition, ...modalProps }) => {
    const initialValues: ITableZoneForm = {
        zoneId: '',
        tables: ''
    };

    const handleSubmit = React.useCallback(
        (values: ITableZoneForm) => {
            onAddition(values);
        },
        [onAddition]
    );

    return (
        <MuiModal {...modalProps}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <TableZoneForm />
                </Form>
            </Formik>
        </MuiModal>
    );
};

interface IEditTableZoneModalProps extends Omit<MuiModalProps, 'children'> {
    initialValues: ITableZoneForm;
    onEdit: (values: ITableZoneForm) => void;
    open: boolean;
    onClose: () => void;
}

export const EditTableZoneModal: React.FC<IEditTableZoneModalProps> = ({
    initialValues = {},
    onEdit,
    ...modalProps
}) => {
    const handleSubmit = React.useCallback(
        (values: ITableZoneForm) => {
            onEdit(values);
        },
        [onEdit]
    );

    return (
        <MuiModal {...modalProps} title={`Edit ${initialValues.zoneId}`}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <TableZoneForm />
                </Form>
            </Formik>
        </MuiModal>
    );
};

import React from 'react';
import { IOrder } from '../model/Order';
import { add, isAfter } from 'date-fns';
import { orderApi } from '../orderApi';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
    MESSAGE_CUSTOMER_ORDER_CLOSING_ERROR,
    MESSAGE_CUSTOMER_ORDER_CLOSING_PENDING,
    MESSAGE_CUSTOMER_ORDER_CLOSING_SUCCESS
} from 'config/messages';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { ApplicationState } from 'store/store';
import { Permission, Resource, permission } from '@pepperhq/auth-client';

export function useForceClose(order: IOrder | undefined, onClosed: () => void) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const hasPermissionsToClose = React.useMemo(
        () => claims.hasPermissions(permission(Resource.Order, Permission.write)),
        [claims]
    );
    const isClosable = React.useMemo(() => {
        if (order?.isOpen && hasPermissionsToClose) {
            const currentDate = new Date();
            const createdDate = new Date(order?.createdAt);
            return isAfter(currentDate, add(createdDate, { days: 1 }));
        }
        return false;
    }, [order?.createdAt, order?.isOpen, hasPermissionsToClose]);
    const handleCloseError = React.useCallback(() => {
        setOpen(false);
        setLoading(false);
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_CLOSING_ERROR, { variant: 'error' }));
    }, [dispatch]);
    const handleCloseSuccess = React.useCallback(() => {
        setOpen(false);
        setLoading(false);
        onClosed();
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_CLOSING_SUCCESS, { variant: 'success' }));
    }, [dispatch, onClosed]);
    const handleCloseOrder = React.useCallback(async () => {
        setLoading(true);
        const result = await orderApi.update(order?.id, {
            body: undefined,
            queryParameters: [{ key: 'forceClose', value: true }],
            headers: {
                'x-pepper-req-nonce': v4()
            }
        });
        if (!result.ok) {
            return handleCloseError();
        }
        dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_ORDER_CLOSING_PENDING, { variant: 'info' }));
        const operationResult = await orderApi.pollOC2Operation(result.body.id, result.body.etaMs);
        if (operationResult.status === OperationStatus.ERROR) {
            return handleCloseError();
        }
        handleCloseSuccess();
    }, [dispatch, handleCloseError, handleCloseSuccess, order?.id]);
    const onForceClose = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const modalProps = React.useMemo(
        () => ({
            open,
            onAgree: handleCloseOrder,
            onClose: handleClose,
            loading
        }),
        [handleClose, handleCloseOrder, loading, open]
    );
    return {
        isClosable,
        isLoading: loading,
        onForceClose,
        modalProps
    };
}

import React from 'react';
import { getImageAspect, getImagePickerDescriptionByDisplay, MarketingMessageProps } from './base';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { MuiImagePicker } from 'lib/image-picker';
import { getFileName } from 'lib/helpers';
import { Box, Button, Grid, InputLabel, styled, Typography } from '@mui/material';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';

export const StyledImagePickerDescription = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
    fontSize: '0.75rem'
}));

interface IDefaultMarketingMessageProperties {
    imageUrl: string;
    title?: string;
    subtitle?: string;
    subtitleTwo?: string;
    invertTextColour?: boolean;
    showTextBackgroundBar?: boolean;
}

enum EFormField {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    SUBTITLE_TWO = 'subtitleTwo',
    INVERT_TEXT_COLOR = 'invertTextColour',
    SHOW_TEXT_BACKGROUND_BAR = 'showTextBackgroundBar',
    IMAGE_URL = 'imageUrl',
    NAME = 'name'
}

const REQUIRED_ERROR_MESSAGE = 'This field is required.';

const requiredStringSchema = Yup.string().required(REQUIRED_ERROR_MESSAGE);

const validationSchema = Yup.object().shape({
    [EFormField.IMAGE_URL]: requiredStringSchema.nullable(true),
    [EFormField.NAME]: requiredStringSchema,
    [EFormField.TITLE]: Yup.string().nullable(true),
    [EFormField.SUBTITLE]: Yup.string().nullable(true),
    [EFormField.INVERT_TEXT_COLOR]: Yup.boolean(),
    [EFormField.SHOW_TEXT_BACKGROUND_BAR]: Yup.boolean()
});

export const DefaultMarketingMessageProperties: React.FC<
    MarketingMessageProps<IDefaultMarketingMessageProperties>
> = ({ name, properties, contextForm, onSubmit, display }) => {
    const handleSubmit = React.useCallback(
        (data: IDefaultMarketingMessageProperties) => {
            onSubmit({
                ...properties,
                ...data
            });
        },
        [onSubmit, properties]
    );
    const formik = useFormik<IDefaultMarketingMessageProperties & { name: string }>({
        initialValues: {
            imageUrl: '',
            name: name ?? '',
            title: undefined,
            subtitle: undefined,
            subtitleTwo: undefined,
            invertTextColour: false,
            showTextBackgroundBar: false,
            ...properties
        },
        onSubmit: handleSubmit,
        validationSchema
    });

    const makeImageChangeHandler = React.useCallback(
        (changeHandler: (changeEvent: any) => void) => (imageUrl: string) => {
            changeHandler({ target: { value: imageUrl, name: EFormField.IMAGE_URL } });
        },
        []
    );
    const imageChangeHandler = makeImageChangeHandler(formik.handleChange);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <MuiFormLabel required>Image</MuiFormLabel>
                <MuiImagePicker
                    url={formik.values.imageUrl}
                    onSelect={imageChangeHandler}
                    uploadFileName={getFileName(formik.values.imageUrl)}
                    aspect={getImageAspect(display)}
                    accept={['image/jpeg', 'image/png', 'image/gif']}
                />
                <StyledImagePickerDescription variant="caption">
                    {getImagePickerDescriptionByDisplay(display)}
                </StyledImagePickerDescription>
                <Box mt={1}>
                    <TextFormField
                        name={EFormField.NAME}
                        label={<MuiFormLabel required>Reporting Name</MuiFormLabel>}
                        description="This code is used to track the number of marketing module item taps on anchor screen"
                    />
                </Box>
                {!!formik.errors.imageUrl && (
                    <InputLabel shrink error sx={{ mt: 1.5 }}>
                        {formik.errors.imageUrl}
                    </InputLabel>
                )}
                <Box marginTop={2}>
                    <Typography variant="h6" color="primary">
                        Marketing message overlay
                    </Typography>
                </Box>
                <Box mt={1}>
                    <TextFormField name={EFormField.TITLE} label={<MuiFormLabel>Title</MuiFormLabel>} />
                </Box>
                <Box mt={2}>
                    <TextFormField name={EFormField.SUBTITLE} label={<MuiFormLabel>Subtitle</MuiFormLabel>} />
                </Box>
                <Box mt={2}>
                    <TextFormField
                        name={EFormField.SUBTITLE_TWO}
                        label={<MuiFormLabel>Subtitle two</MuiFormLabel>}
                    />
                </Box>
                <Box mt={2}>
                    <SwitchFormField
                        name={EFormField.INVERT_TEXT_COLOR}
                        label={<MuiFormLabel>Invert text colour</MuiFormLabel>}
                    />
                </Box>
                <Box mt={2}>
                    <SwitchFormField
                        name={EFormField.SHOW_TEXT_BACKGROUND_BAR}
                        label={<MuiFormLabel>Show text background bar</MuiFormLabel>}
                    />
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {contextForm()}
                    </Grid>
                </Box>

                <Box display="flex" flex={1} justifyContent="flex-end" mt={1.5}>
                    <Button variant="contained" color="primary" type="submit" disabled={!formik.isValid}>
                        Submit
                    </Button>
                </Box>
            </form>
        </FormikProvider>
    );
};

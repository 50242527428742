import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { parseTimeRange } from 'components/location/helpers';
import { MuiTheme } from 'config/theme';
import { isString } from './typeguards';

interface OpeningHoursInputProps {
    label?: React.ReactNode;
    value?: string;
    name?: string;
    onChange?: (value: string) => void;
}

const DATE_FORMAT = 'hh:mmaaa';
const SHORT_DATE_FORMAT = 'h:mmaaa';

function generateDateFormat(date: string) {
    const { length } = date;
    if (length === 6) {
        return SHORT_DATE_FORMAT;
    }
    return DATE_FORMAT;
}

function formatOpeningHours(left: Date, right: Date) {
    if (!isValid(left) || !isValid(right)) {
        return null;
    }
    return `${format(left, DATE_FORMAT)} - ${format(right, DATE_FORMAT)}`.toLowerCase();
}

export function OpeningHoursInput(props: OpeningHoursInputProps) {
    const { label, onChange, value } = props;
    const [left, setLeft] = React.useState<Date>(() => {
        const { start } = parseTimeRange(value);
        if (start) {
            return parse(start.toUpperCase(), generateDateFormat(start), new Date());
        }
        return undefined;
    });
    const [right, setRight] = React.useState<Date>(() => {
        const { end } = parseTimeRange(value);
        if (end) {
            return parse(end.toUpperCase(), generateDateFormat(end), new Date());
        }
        return undefined;
    });
    const [opensDate, closesDate] = React.useMemo(() => {
        const { start, end } = parseTimeRange(value);
        if (start && end) {
            return [
                format(parse(start.toUpperCase(), generateDateFormat(start), new Date()), 'HH:mm'),
                format(parse(end.toUpperCase(), generateDateFormat(end), new Date()), 'HH:mm')
            ];
        }
        return ['', ''];
    }, [value]);
    const handleOpensChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value) {
                const parsedDate = parse(e.target.value?.toUpperCase(), 'HH:mm', new Date());
                if (isValid(parsedDate)) {
                    setLeft(parsedDate);
                    return onChange(formatOpeningHours(parsedDate, right));
                }
            }
            onChange('closed');
        },
        [right, onChange]
    );
    const handleClosesChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value) {
                const parsedDate = parse(e.target.value, 'HH:mm', new Date());
                if (isValid(parsedDate)) {
                    setRight(parsedDate);
                    return onChange(formatOpeningHours(left, parsedDate));
                }
            }
            onChange('closed');
        },
        [onChange, left]
    );
    return (
        <Grid container spacing={1} alignItems="center">
            {label && (
                <Grid item xs={4}>
                    {isString(label) ? <Typography>{label}</Typography> : label}
                </Grid>
            )}
            <Grid item xs={4}>
                <TextField
                    label="From"
                    type="time"
                    defaultValue={opensDate}
                    onChange={handleOpensChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        step: 60 // 1 min
                    }}
                    sx={{
                        width: {
                            [MuiTheme.breakpoints.up(1700)]: {
                                width: 100
                            },
                            [MuiTheme.breakpoints.down(1700)]: {
                                width: 90
                            },
                            [MuiTheme.breakpoints.down(1500)]: {
                                width: 90
                            }
                        }
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="From"
                    type="time"
                    defaultValue={closesDate}
                    onChange={handleClosesChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        step: 60 // 1 min
                    }}
                    sx={{
                        width: {
                            [MuiTheme.breakpoints.up(1700)]: {
                                width: 100
                            },
                            [MuiTheme.breakpoints.down(1700)]: {
                                width: 90
                            },
                            [MuiTheme.breakpoints.down(1500)]: {
                                width: 90
                            }
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

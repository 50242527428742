import { v4 as uuid } from 'uuid';
import { IPagedCollection } from 'lib/api/paging';
import { Crud, HttpClient, httpClient, HttpClientResponse } from 'lib/HttpClient';
import { EnrichmentOperation, ICreateExportOption } from './model/EnrichmentOperation';
import { GROUP_BY_OPTIONS } from 'components/overwrites/consts';

const OVERWRITES_LIMIT = 20;

class OverwritesApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.MENU_SERVICE_URL, 'operations', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
    public getExportOperations(nextKey?: string): HttpClientResponse<IPagedCollection<EnrichmentOperation>> {
        const queryParameters = [
            { key: 'namespace', value: 'ENRICHMENTS' },
            { key: 'name', value: 'EXPORT' },
            { key: 'limit', value: String(OVERWRITES_LIMIT) }
        ];
        if (nextKey) {
            queryParameters.push({ key: 'startKey', value: nextKey });
        }
        return this.getList({ queryParameters });
    }
    public getImportOperations(nextKey?: string): HttpClientResponse<IPagedCollection<EnrichmentOperation>> {
        const queryParameters = [
            { key: 'namespace', value: 'ENRICHMENTS' },
            { key: 'name', value: 'IMPORT' },
            { key: 'limit', value: String(OVERWRITES_LIMIT) }
        ];
        if (nextKey) {
            queryParameters.push({ key: 'startKey', value: nextKey });
        }
        return this.getList({ queryParameters });
    }
    public getExportOperation(id: string): HttpClientResponse<EnrichmentOperation> {
        return this.get(id);
    }
    public getExportFile(href: string) {
        return fetch(`${this.api}/${href}`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                Authorization: `Bearer ${this.httpClient.getToken()}`,
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    }
    public createExport(exportParams: {
        locationIds?: string[];
        groupByOptions?: string[];
    }): HttpClientResponse<EnrichmentOperation> {
        const body: { locationIds?: string[]; options?: Record<string, ICreateExportOption> } = {};
        const { locationIds, groupByOptions } = exportParams;
        if (locationIds && locationIds.length) {
            body.locationIds = locationIds;
        }

        body.options = {};
        if (!!groupByOptions && !!groupByOptions.length) {
            GROUP_BY_OPTIONS.forEach(({ value }) => {
                body.options[value] = {
                    autoGroup: groupByOptions.includes(value)
                };
            });
        } else {
            GROUP_BY_OPTIONS.forEach(({ value }) => {
                body.options[value] = {
                    autoGroup: true
                };
            });
        }

        return this.httpClient.post(`${this.api}/enrichments/exports`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-pepper-req-nonce': uuid()
            },
            body
        });
    }
    public createImport(body: FormData): HttpClientResponse<EnrichmentOperation> {
        return this.httpClient.post(`${this.api}/enrichments/imports`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-pepper-req-nonce': uuid(),
                Authorization: `Bearer ${this.httpClient.getToken()}`
            },
            skipStringify: true,
            skipDefaultHeaders: true,
            body
        });
    }
}

export const overwritesApi = new OverwritesApi(httpClient);

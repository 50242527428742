import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { EMetricType, UserMetricResource } from '../model';
import { Button, Grid, Typography } from '@mui/material';
import { MuiForm } from 'lib/Form';
import { Form } from 'formik';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MetricTierLoyaltyForm, generateUserMetricData } from '../model/loyalty-tier';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { Option } from 'lib/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import * as Yup from 'yup';
import { getDaysFromMinutes } from 'lib/helpers';
import { ActionType } from 'components/actions/models/Action';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { DatePickerFormField } from 'lib/form/fields/DatePickerFormField';

interface TieredLoyaltyMetricModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (metric: UserMetricResource) => void;
    metric?: UserMetricResource;
    loading?: boolean;
    readOnly?: boolean;
    metricNames?: string[];
}

const typeOptions: Option[] = [
    { value: EMetricType.Spend, label: 'Spend' },
    { value: EMetricType.PointsEarned, label: 'Reward earned' },
    { value: EMetricType.PointsBurned, label: 'Reward burned' },
    { value: EMetricType.TipsGiven, label: 'Tips given' },
    { value: EMetricType.ActionType, label: 'Action count' }
];

const actionOptions = [
    { value: ActionType.CARD_CREATED, label: 'Card created' },
    { value: ActionType.CARD_DELETED, label: 'Card deleted' },
    { value: ActionType.CHECKIN, label: 'Checkin' },
    { value: ActionType.USER_CREDITED, label: 'User credited' },
    { value: ActionType.ORDER_CREATED, label: 'Order created' },
    { value: ActionType.ORDER_COMPLETED, label: 'Order completed' },
    { value: ActionType.REDEEM_PERK, label: 'Redeem perk' },
    { value: ActionType.REFERAL_CLAIMED_REFEREE, label: 'Referal claimed referee' },
    { value: ActionType.REFERAL_CLAIMED_REFERER, label: 'Referal claimed referer' },
    { value: ActionType.REWARD_EARNED, label: 'Reward earned' },
    { value: ActionType.USER_CREATED, label: 'User created' },
    { value: ActionType.USER_ACTIVATED, label: 'User activated' },
    { value: ActionType.ORDER_PAYMENT_REFUNDED, label: 'Order payment refunded' },
    { value: ActionType.ORDER_PAYMENT_RECEIVED, label: 'Order payment received' },
    { value: ActionType.ORDER_USER_BILLED, label: 'Order user billed' },
    { value: ActionType.ORDER_USER_JOINED, label: 'Order user joined' }
];

const getMetricTypeValue = (metricType: string) => {
    if (metricType && actionOptions.some(item => item.value === metricType)) {
        return EMetricType.ActionType;
    }
    return metricType;
};

export const TieredLoyaltyMetricModal: React.FC<TieredLoyaltyMetricModalProps> = ({
    open,
    onClose,
    metric,
    loading,
    onSubmit,
    readOnly,
    metricNames = []
}) => {
    const { perks } = useSelector((state: ApplicationState) => state.perks);
    const handleSubmit = React.useCallback(
        (values: MetricTierLoyaltyForm) => {
            const metric = generateUserMetricData(values);
            onSubmit(metric);
        },
        [onSubmit]
    );
    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .notOneOf(metricNames, 'Metric name must be unique.')
                    .required('This field is required.'),
                type: Yup.string().required('This field is required.'),
                timeWindowStart: Yup.number()
                    .max(366, 'This field maximum value is "366".')
                    .required('This field is required.'),
                timeWindowEnd: Yup.number().notRequired(),
                gt: Yup.date()
                    .nullable()
                    .typeError('Date is invalid.')
                    .min(new Date(), '"After" cannot be in past.'),
                lt: Yup.date()
                    .nullable()
                    .typeError('Date is invalid.')
                    .min(new Date(), '"Before" cannot be in past.'),
                perkId: Yup.string().when('type', {
                    is: (value: any) =>
                        value === EMetricType.PointsBurned || value === EMetricType.PointsEarned,
                    then: Yup.string().required('This field is required.'),
                    otherwise: undefined
                })
            }),
        [metricNames]
    );
    const initialValues: MetricTierLoyaltyForm = React.useMemo(
        () => ({
            name: metric?.name ?? '',
            perkId: metric?.perkId ?? '',
            includeTips: metric?.includeTips ?? false,
            timeWindowStart: metric ? getDaysFromMinutes(metric?.timeRange?.windowStartOffsetMins) : 182,
            timeWindowEnd: getDaysFromMinutes(metric?.timeRange?.windowEndOffsetMins),
            type: getMetricTypeValue(metric?.type),
            action: getMetricTypeValue(metric?.type) === EMetricType.ActionType ? metric?.type : '',
            gt: metric?.timeRange?.gt,
            lt: metric?.timeRange?.lt
        }),
        [metric]
    );
    const title = React.useMemo(() => (!!metric ? 'Metric details' : 'Create a new metric'), [metric]);
    const perkOptions = React.useMemo(
        () => perks.map(item => ({ value: item._id, label: item.title })),
        [perks]
    );
    const getTypeSpecificFields = React.useCallback(
        (type: string) => {
            switch (type) {
                case EMetricType.Spend:
                    return (
                        <Grid item xs={12}>
                            <CheckboxFormField name="includeTips" label="Include Tips" readOnly={readOnly} />
                        </Grid>
                    );
                case EMetricType.PointsBurned:
                case EMetricType.PointsEarned:
                    return (
                        <Grid item xs={12}>
                            <SelectFormField
                                variant="outlined"
                                name="perkId"
                                label={<MuiFormLabel required>Perk</MuiFormLabel>}
                                readOnly={readOnly}
                                options={perkOptions}
                                disabled={readOnly}
                            />
                        </Grid>
                    );
                case EMetricType.ActionType:
                    return (
                        <Grid item xs={12}>
                            <SelectFormField
                                variant="outlined"
                                name="action"
                                label="Action"
                                readOnly={readOnly}
                                options={actionOptions}
                                disabled={readOnly}
                            />
                        </Grid>
                    );
                case EMetricType.TipsGiven:
                default:
                    return null;
            }
        },
        [perkOptions, readOnly]
    );
    return (
        <MuiModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MuiForm
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({ submitForm, isValid, isSubmitting, values }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextFormField
                                            variant="outlined"
                                            name="name"
                                            label={<MuiFormLabel required>Name</MuiFormLabel>}
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectFormField
                                            variant="outlined"
                                            name="type"
                                            label={<MuiFormLabel required>Type</MuiFormLabel>}
                                            readOnly={readOnly}
                                            options={typeOptions}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    {getTypeSpecificFields(values.type)}
                                    <Grid item xs={12}>
                                        <Row flex={1} valign="center">
                                            <TextFormField
                                                variant="outlined"
                                                name="timeWindowStart"
                                                label={
                                                    <MuiFormLabel required>Time window start</MuiFormLabel>
                                                }
                                                type="number"
                                                readOnly={readOnly}
                                                shrink={readOnly ? true : undefined}
                                                disabled={readOnly}
                                            />
                                            <Typography sx={{ pl: 1 }}>days</Typography>
                                        </Row>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Row flex={1} valign="center">
                                            <TextFormField
                                                variant="outlined"
                                                name="timeWindowEnd"
                                                label="Time window end"
                                                type="number"
                                                readOnly={readOnly}
                                                shrink={readOnly ? true : undefined}
                                                disabled={readOnly}
                                            />
                                            <Typography sx={{ pl: 1 }}>days</Typography>
                                        </Row>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePickerFormField
                                            variant="outlined"
                                            name="gt"
                                            label="After"
                                            type="datetime-local"
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePickerFormField
                                            variant="outlined"
                                            name="lt"
                                            label="Before"
                                            type="datetime-local"
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Row flex={1} align="flex-end" gutter>
                                            <Button onClick={onClose} variant="outlined" color="secondary">
                                                Cancel
                                            </Button>
                                            {!readOnly && (
                                                <LoadingButton
                                                    disabled={!isValid}
                                                    loading={loading || isSubmitting}
                                                    onClick={submitForm}
                                                    variant="contained"
                                                >
                                                    Submit
                                                </LoadingButton>
                                            )}
                                        </Row>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </MuiForm>
                </Grid>
            </Grid>
        </MuiModal>
    );
};

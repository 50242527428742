/* eslint-disable @typescript-eslint/naming-convention */
import { Suggest } from 'react-geosuggest';
import { Omit } from 'lib/types';

export interface GeocoderAddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface Address {
    address: string;
    country: string;
    postcode: string;
    town: string;
    fullAddress?: string;
    geo?: { lat: string; lng: string };
    googleMapsShortUrl?: string;
}

const findAddress = (components: google.maps.GeocoderAddressComponent[], type: string): string => {
    const address: GeocoderAddressComponent = components.find(
        (component: GeocoderAddressComponent): boolean => component.types.includes(type)
    );

    if (address !== undefined && 'long_name' in address) {
        return address['long_name'];
    }

    return '';
};

const getFullAddress = (address: Omit<Address, 'geo'>) =>
    Object.entries(address).reduce((acc, [key, value]: [keyof Omit<Address, 'geo'>, string]) => {
        if (value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {} as Address);

const geoSuggestAddressFormatter = (suggest: Suggest) => {
    if (!suggest || !('gmaps' in suggest)) {
        return;
    }

    const components: google.maps.GeocoderAddressComponent[] = suggest.gmaps.address_components;
    const space = findAddress(components, 'street_number') !== '' ? ', ' : '';

    const addressFormat = {
        address: `${findAddress(components, 'street_number')}${space}${findAddress(components, 'route')}`,
        town: findAddress(components, 'locality'),
        postcode: findAddress(components, 'postal_code'),
        country: findAddress(components, 'country')
    };

    const returnAddress = getFullAddress(addressFormat);
    returnAddress.fullAddress = suggest.gmaps.formatted_address;
    const { lat, lng } = suggest.location;
    returnAddress.geo = { lat: String(lat), lng: String(lng) };
    return returnAddress;
};

const isValidAddress = (address: Partial<Address>): boolean => {
    if (!address) {
        return false;
    }
    const allowedKeys = ['address', 'town', 'postcode', 'country', 'fullAddress'];
    const isValid =
        !!address &&
        // eslint-disable-next-line no-prototype-builtins
        allowedKeys.some(k => address.hasOwnProperty(k)) &&
        Object.values(address).some(value => value !== '');
    return isValid;
};

export { geoSuggestAddressFormatter, isValidAddress, getFullAddress, findAddress };

import React from 'react';
import { Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';

export const PowercardSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Production Environment">
                    {settings?.powercardLoyalty?.isProduction && <Done />}
                    {!settings?.powercardLoyalty?.isProduction && <Close />}
                </SettingDisplay>
                <SettingDisplay label="API Token">
                    <Typography variant="body2">{settings?.powercardLoyalty?.apiToken}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Merchant ID">
                    <Typography variant="body2">{settings?.powercardLoyalty?.m_id}</Typography>
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Location ID">
                <Typography variant="body2">{settings?.powercardLoyalty?.l_id}</Typography>
            </SettingDisplay>
        )}
    </>
);

import React, { useEffect, useState } from 'react';
import { CircularProgress, styled, Typography } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import AvatarImage from 'static/avatar_image.png';
import Logo from 'static/pepper-logo.png';
import { Column, Row } from 'ui/Flex';
import { ContentAnchor } from './models/ContentLayout';
import { ContentModule } from './models/ContentModule';
import { ContentAnchorProperties, ContentTemplateModule } from './models/ContentTemplate';
import { BASE_LAYOUT_THEME, renderContentModule } from './renderContentModule';

const PREFIX = 'MobileContentRenderer';

const classes = {
    column: `${PREFIX}-column`,
    appBar: `${PREFIX}-appBar`,
    logo: `${PREFIX}-logo`,
    spinner: `${PREFIX}-spinner`
};

const StyledColumn = styled(Column)(({ theme }) => ({
    minWidth: '100%',
    minHeight: '100%',
    position: 'relative',
    width: '100%',
    height: 'auto',
    paddingRight: 17,
    boxSizing: 'content-box',
    [`& .${classes.appBar}`]: {
        width: '100%',
        height: theme.spacing(4),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
    },
    [`& .${classes.logo}`]: {
        height: '80%',
        width: 'auto'
    },
    [`& .${classes.spinner}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        justifySelf: 'center',
        marginTop: '75%'
    }
}));

interface MobileContentProps {
    modules: ContentModule[] | ContentTemplateModule[] | null;
    anchor?: ContentAnchor;
    selectedModuleId?: string;
    isSelectable?: boolean;
    isLoading?: boolean;
    updateSelectedModuleId?: (id: string) => void;
    noModulesText?: string;
}

function isContentModules(modules: any[]): modules is ContentModule[] {
    return !!modules.length;
}

export const MobileContentRenderer = ({
    anchor,
    modules,
    isSelectable,
    selectedModuleId,
    updateSelectedModuleId,
    noModulesText,
    isLoading
}: MobileContentProps) => {
    const [sortedModules, setSortedModules] = useState(null);
    useEffect(() => {
        setSortedModules(
            !!modules
                ? [...modules].sort(
                      (a: ContentModule | ContentTemplateModule, b: ContentModule | ContentTemplateModule) =>
                          a.index - b.index
                  )
                : null
        );
    }, [modules]);
    const generateProperties = (id: string) => {
        const properties: ContentAnchorProperties = { ...anchor.properties };
        if (isSelectable) {
            properties.isSelectable = true;
            properties.handleClick = () => updateSelectedModuleId(id);
            if (selectedModuleId === id) {
                properties.isSelected = true;
            }
        }
        return properties;
    };

    const renderModules = () => {
        if (!sortedModules || isLoading) {
            return <CircularProgress variant="indeterminate" className={classes.spinner} />;
        }
        if (sortedModules.length === 0 || !anchor) {
            return (
                <div className={classes.spinner}>
                    <Clear />
                    <Typography variant="caption">{noModulesText || 'No Modules'}</Typography>
                </div>
            );
        }
        if (isContentModules(sortedModules)) {
            return sortedModules.map((item, index) => (
                <React.Fragment key={`module-${item.id ?? index}`}>
                    {renderContentModule[item.type](
                        item,
                        BASE_LAYOUT_THEME,
                        generateProperties(item.id),
                        anchor.style
                    )}
                </React.Fragment>
            ));
        }
    };

    useEffect(() => {
        // Reset Selected Module, if this preview is selectable
        if (isSelectable) {
            updateSelectedModuleId(undefined);
        }
    }, [isSelectable, updateSelectedModuleId]);

    return (
        <StyledColumn
            flex={1}
            style={{ backgroundColor: anchor ? anchor.properties.anchorBackgroundColour : '#fff' }}
        >
            <Row className={classes.appBar} align="space-between" valign="center">
                <img className={classes.logo} src={Logo} alt="" />
                <img className={classes.logo} src={AvatarImage} alt="" />
            </Row>
            {renderModules()}
        </StyledColumn>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SwitchFormField } from 'lib/form/fields/SwitchFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';
import { NumberFormField } from 'lib/form/fields/NumberFormField';

export const StripeSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({
    locationId,
    hasInternalSettingsWritePermission,
    globalSettings
}) => {
    const isConnectEnabled = React.useMemo(
        () => globalSettings?.stripe?.connectEnabled ?? false,
        [globalSettings]
    );

    if (locationId !== undefined) {
        return (
            <>
                {isConnectEnabled && (
                    <>
                        <SettingDisplay label="Account ID">
                            <TextFormField name="stripeAccountId" />
                        </SettingDisplay>
                        <SettingDisplay label="Dispute Notification Email">
                            <TextFormField name="stripeDisputeNotificationEmail" />
                        </SettingDisplay>
                        <SettingDisplay label="Stripe Location ID (for card reader)">
                            <TextFormField name="stripeLocationId" />
                        </SettingDisplay>
                    </>
                )}
                {isConnectEnabled && hasInternalSettingsWritePermission && (
                    <>
                        <SettingDisplay label="Franchise Account ID">
                            <TextFormField name="stripeFranchiseAccountId" />
                        </SettingDisplay>
                        <SettingDisplay label="Franchise Fee Percentage (Default)">
                            <NumberFormField name="stripeFranchiseFeePercentage" />
                        </SettingDisplay>
                        <SettingDisplay label="Franchise Fee Percentage (Quickpad)">
                            <NumberFormField name="stripeFranchiseFeePercentageCardholderPresent" />
                        </SettingDisplay>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <SettingDisplay label="Production Environment">
                <SwitchFormField name="stripeIsProduction" />
            </SettingDisplay>
            {isConnectEnabled && (
                <>
                    <SettingDisplay label="Account ID">
                        <TextFormField name="stripeAccountId" />
                    </SettingDisplay>
                    <SettingDisplay label="Dispute Notification Email">
                        <TextFormField name="stripeDisputeNotificationEmail" />
                    </SettingDisplay>
                </>
            )}
            {hasInternalSettingsWritePermission && (
                <SettingDisplay label="Connected Account">
                    <SwitchFormField name="stripeConnectEnabled" />
                </SettingDisplay>
            )}
            {isConnectEnabled && hasInternalSettingsWritePermission && (
                <>
                    <SettingDisplay label="Gateway Fee (Default)">
                        <NumberFormField name="stripeGatewayFee" shouldSeparateThousands={false} />
                    </SettingDisplay>
                    <SettingDisplay label="Gateway Fee (Quickpad)">
                        <NumberFormField
                            name="stripeGatewayFeeCardholderPresent"
                            shouldSeparateThousands={false}
                        />
                    </SettingDisplay>
                    <SettingDisplay label="Transaction Fee Rate (Default)">
                        <NumberFormField name="stripeTransactionFeeRate" shouldSeparateThousands={false} />
                    </SettingDisplay>
                    <SettingDisplay label="Transaction Fee Rate (Quickpad)">
                        <NumberFormField
                            name="stripeTransactionFeeRateCardholderPresent"
                            shouldSeparateThousands={false}
                        />
                    </SettingDisplay>
                    <SettingDisplay label="3DS2 Fee">
                        <NumberFormField name="stripeThreeDS2Fee" shouldSeparateThousands={false} />
                    </SettingDisplay>
                    <SettingDisplay label="Franchise Account ID">
                        <TextFormField name="stripeFranchiseAccountId" />
                    </SettingDisplay>
                    <SettingDisplay label="Franchise Fee Percentage (Default)">
                        <NumberFormField name="stripeFranchiseFeePercentage" />
                    </SettingDisplay>
                    <SettingDisplay label="Franchise Fee Percentage (Quickpad)">
                        <NumberFormField name="stripeFranchiseFeePercentageCardholderPresent" />
                    </SettingDisplay>
                </>
            )}
            {!isConnectEnabled && (
                <>
                    <SettingDisplay label="Secret Key">
                        <TextFormField name="stripeSecretKey" />
                    </SettingDisplay>
                    <SettingDisplay label="Publishable Key">
                        <TextFormField name="stripePublishableKey" />
                    </SettingDisplay>
                </>
            )}
        </>
    );
};

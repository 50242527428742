import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';

export function useHistoryFilters(): {
    search: Record<string, any>;
    onSearch: (value: Record<string, any>) => void;
} {
    const { replace } = useHistory();
    const location = useLocation();

    const onSearch = React.useCallback(
        (query: Record<string, any>) => {
            if (query && Object.keys(query)) {
                replace(`${location.pathname}?${stringify(query)}`);
            } else {
                replace(location.pathname);
            }
        },
        [location.pathname, replace]
    );

    const search = React.useMemo(() => parse(location.search), [location.search]);

    return { search, onSearch };
}

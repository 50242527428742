import { Crud, httpClient, HttpClient } from 'lib/HttpClient';

class ClerksApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.USER_SERVICE_URL, 'terminal/access', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
}

export const clerksApi = new ClerksApi(httpClient);

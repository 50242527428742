import React from 'react';
import { Grid } from '@mui/material';
import { LocationLinks } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';

interface LocationInAppLinksEditCardComponentProps {
    form: LegacyForm;
    onClose: () => void;
    links: LocationLinks;
}

export const LocationInAppLinksEditCardComponent = (props: LocationInAppLinksEditCardComponentProps) => (
    <AutoForm {...props}>
        {({ facebook, twitter, instagram, uber, citymapper }) => (
            <React.Fragment>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        {facebook}
                        {twitter}
                        {instagram}
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        {uber}
                        {citymapper}
                    </Grid>
                </Grid>
            </React.Fragment>
        )}
    </AutoForm>
);

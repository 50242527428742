import { ITagGroup, Location } from 'components/location/models/LocationModel';
import { Panel } from 'ui/Panel';
import React from 'react';
import { locationApi } from 'components/location/LocationApi';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { tagFilterObjectToString, TagFilter } from 'components/location/models/LocationTagModel';
import { TagGroupsSelectionModal } from 'components/content/forms/marketing/modals/TagGroupsSelectionModal';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_LOCATIONS_UPDATE_ERROR, MESSAGE_LOCATIONS_UPDATE_SUCCESS } from 'config/messages';

interface IProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

export function LocationTagsCard({ currentLocation, setCurrentLocation }: IProps) {
    const [loading, setLoading] = React.useState(false);
    const [tagGroups, setTagGroups] = React.useState<ITagGroup[]>([]);
    const [isTagSelectionOpen, setTagSelectionOpen] = React.useState(false);
    const [selectedTags, setSelectedTags] = React.useState<TagFilter>({});
    const [isUpdateLoading, setUpdateLoading] = React.useState(false);
    const dispatch = useDispatch();

    const handleEditClick = React.useCallback(() => {
        setTagSelectionOpen(true);
    }, []);

    const getTagGroups = React.useCallback(() => {
        setLoading(true);
        locationApi
            .getTagGroups()
            .then(res => {
                if (res.ok) {
                    setTagGroups(res.body.items);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        const { tags } = currentLocation;
        if (tags) {
            const resultTagFilter: TagFilter = {};
            tags.forEach((tag: string) => {
                const [groupId, tagId] = tag.split('/');
                resultTagFilter[groupId] = { ...resultTagFilter[groupId], [tagId]: true };
            });

            setSelectedTags(resultTagFilter);
        }
    }, [currentLocation]);

    React.useEffect(() => {
        getTagGroups();
    }, [getTagGroups]);

    const handleTagSelectionClose = React.useCallback(() => {
        setTagSelectionOpen(false);
    }, []);

    const tagsValue = React.useMemo(() => {
        const tagsEntries = Object.entries(selectedTags);

        if (!tagsEntries.length) {
            return [];
        }
        const result: string[] = [];

        tagsEntries.forEach(([groupId, tags]) => {
            const groupTags = tagGroups.find(t => t.id === groupId)?.tags ?? {};

            Object.entries(tags).forEach(([tag, selected]) => {
                if (selected) {
                    const title = groupTags[tag]?.title;

                    if (title) {
                        result.push(title);
                    }
                }
            });
        });

        return result;
    }, [selectedTags, tagGroups]);

    const handleTagSelectionSubmit = React.useCallback(
        tagSelectionResult => {
            setUpdateLoading(true);

            locationApi
                .update(currentLocation._id, {
                    body: {
                        tags: tagFilterObjectToString(tagSelectionResult)
                    }
                })
                .then(res => {
                    if (res.ok) {
                        setCurrentLocation(res.body);
                        dispatch(
                            enqueueSnackbar(MESSAGE_LOCATIONS_UPDATE_SUCCESS(currentLocation.title), {
                                variant: 'success'
                            })
                        );
                    } else {
                        dispatch(enqueueSnackbar(MESSAGE_LOCATIONS_UPDATE_ERROR, { variant: 'error' }));
                    }
                })
                .finally(() => {
                    setTagSelectionOpen(false);
                    setUpdateLoading(false);
                });
        },
        [currentLocation._id, currentLocation.title, dispatch, setCurrentLocation]
    );

    let content;

    if (loading) {
        content = (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box pt={1.5} pb={0.5}>
                {!tagsValue.length ? (
                    <Typography>You have no tags configured</Typography>
                ) : (
                    tagsValue.map((tag, i) => (
                        <Chip sx={{ mr: 1, mb: 1, lineHeight: '20px' }} key={`${tag}_${i}`} label={tag} />
                    ))
                )}
            </Box>
        );
    }

    return (
        <Panel title="Tags" divider onClick={handleEditClick}>
            {content}
            <TagGroupsSelectionModal
                title="Tags"
                resetOnClose
                open={isTagSelectionOpen}
                onClose={handleTagSelectionClose}
                tagFilter={selectedTags}
                tagGroups={tagGroups}
                onSubmit={handleTagSelectionSubmit}
                isLoading={isUpdateLoading}
            />
        </Panel>
    );
}

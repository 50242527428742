import { EHeightOption, EItemShape, IDisplay } from 'components/content/models/ContentModule';
import { Location } from 'components/location/models/LocationModel';

export interface MarketingMessageProps<Model> {
    name: string;
    properties?: Model;
    locations?: Location[];
    contextForm: () => React.ReactNode;
    onSubmit: (properties: Model) => void;
    display?: IDisplay;
}

export const getImageAspect = (display?: IDisplay) => {
    if (display?.itemShape === EItemShape.SQUARE) {
        return 1;
    }
    switch (display?.height) {
        case EHeightOption.SINGLE_HEIGHT_RECTANGLE:
            return 3;
        case EHeightOption.LARGE_SQUARE:
            return 1;
        case EHeightOption.SMALL_SQUARE:
            return 1;
        case 'SINGLE':
            return 3;
        case EHeightOption.DOUBLE_HEIGHT_RECTANGLE:
        case 'DOUBLE':
        default:
            return 3 / 2;
    }
};

export const getImagePickerDescriptionByDisplay = (display: IDisplay) => {
    if (display?.itemShape === EItemShape.SQUARE) {
        return '200px by 200px';
    }
    switch (display?.height) {
        case EHeightOption.SINGLE_HEIGHT_RECTANGLE:
            return '600px by 200px';
        case EHeightOption.LARGE_SQUARE:
            return '200px by 200px';
        case EHeightOption.SMALL_SQUARE:
            return '200px by 200px';
        case 'SINGLE':
            return '600px by 200px';
        case EHeightOption.DOUBLE_HEIGHT_RECTANGLE:
        case 'DOUBLE':
        default:
            return '600px by 400px';
    }
};

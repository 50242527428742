import { createReducer } from 'redux-create-reducer';
import { Location } from 'components/location/models/LocationModel';
import { updateArrayItem } from 'lib/helpers';
import { LocationAction, LocationsAction } from './locationsActions';
import { LocationActionTypes, LocationsActionTypes } from './locationsActionTypes';

export interface LocationsState {
    locations?: Location[];
    summary?: { total: number; count: number; pages: number; page: number };
    error: string;
    isLoading: boolean;
    locationIdToLocationTitle?: Map<string, string>;
}

type LocationsBaseActionHandlers = {
    [key in LocationsActionTypes]: (state: LocationsState, action?: LocationsAction) => LocationsState;
};
type LocationStatusActionHandlers = {
    [key in LocationActionTypes]: (state: LocationsState, action?: LocationAction) => LocationsState;
};
type LocationsActionHandlers = LocationsBaseActionHandlers | LocationStatusActionHandlers;

export const initialState: LocationsState = {
    locations: [],
    locationIdToLocationTitle: new Map(),
    error: null,
    summary: null,
    isLoading: false
};

const actionHandlers: LocationsActionHandlers = {
    [LocationsActionTypes.START_REQUEST]: state => ({ ...state, error: null, isLoading: true }),
    [LocationsActionTypes.END_REQUEST]: state => ({ ...state, error: null, isLoading: false }),
    [LocationsActionTypes.GET_LOCATIONS_SUCCESS]: (state, { locations, summary }) => ({
        ...state,
        locations,
        summary,
        error: null
    }),
    [LocationsActionTypes.GET_ALL_LOCATION_TITLES]: (state, { locationIdToTitleMap, locations }) => ({
        ...state,
        locations,
        locationIdToLocationTitle: locationIdToTitleMap
    }),
    [LocationsActionTypes.GET_LOCATIONS_ERROR]: (state, { error }) => ({
        ...state,
        error,
        locations: null,
        summary: null
    }),
    [LocationsActionTypes.LOAD_LOCATIONS_SUCCESS]: (state, { locations, summary }) => ({
        ...state,
        summary,
        error: null,
        locations: [...state.locations, ...locations]
    }),
    [LocationActionTypes.DELETE_LOCATION]: (state, { index }) => ({
        ...state,
        locations: [...state.locations.slice(0, index), ...state.locations.slice(index + 1)]
    }),
    [LocationActionTypes.UPDATE_LOCATION]: (state, { index, location }) => ({
        ...state,
        locations: updateArrayItem(state.locations, index, location)
    })
};

export default createReducer(initialState, actionHandlers);

import * as React from 'react';
import { ErrorLayout } from 'layouts/ErrorLayout';
import logger from 'lib/logger';

export class ErrorBoundary extends React.Component {
    state = {
        hasError: false
    };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        logger.critical('componentDidCatch', { error, info });
    }

    handleClick = () => {
        this.setState({ hasError: false });
    };

    render() {
        if (this.state.hasError) {
            // TODO: Custom UI for unhandled errors
            return (
                <ErrorLayout
                    title="Something went wrong"
                    fallbackTitle="Home"
                    fallbackUrl="/"
                    onClick={this.handleClick}
                />
            );
        }
        // No errors were thrown
        return this.props.children;
    }
}

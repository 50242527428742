import React from 'react';
import {
    Box,
    Button,
    Card,
    ClickAwayListener,
    List,
    ListItem,
    Popover,
    TextField,
    Typography,
    styled
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { useInputState } from 'lib/hooks/useInputState';

const PREFIX = 'PerkLocationsPopover';

const classes = {
    content: `${PREFIX}-content`,
    actions: `${PREFIX}-actions`,
    searchBar: `${PREFIX}-searchBar`
};

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 100,
    maxHeight: 320,
    minWidth: 320,
    maxWidth: 640,
    ...theme.typography.body1,
    [`& .${classes.content}`]: {
        flex: 1,
        overflow: 'auto',
        paddingLeft: theme.spacing(0.5)
    },
    [`& .${classes.actions}`]: {
        display: 'flex',
        padding: theme.spacing(1, 2),
        justifyContent: 'flex-end'
    },
    [`& .${classes.searchBar}`]: {
        padding: theme.spacing(1.5, 2, 1, 2)
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary
}));

const LocationsCell = (params: GridRenderCellParams) => {
    const { locationIdToLocationName } = useLocationHelpers();

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null);

    const [search, onSearchChange] = useInputState('');

    const [firstLocationId] = params.value;
    const locationCount = params.value.length;

    const locationOptions = React.useMemo(
        () =>
            (params.value as string[])
                .map(id => ({
                    label: locationIdToLocationName.get(id) || '',
                    value: id
                }))
                .filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())),
        [params.value, locationIdToLocationName, search]
    );
    const renderLocationItem = React.useCallback(
        (option: { value: string; label: string }) => (
            <ListItem key={option.value}>
                {option.label} ({option.value})
            </ListItem>
        ),
        []
    );

    const handleOpenPopover = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchorEl(event.currentTarget);
        setIsPopoverOpen(true);
    }, []);

    const handleClosePopover = React.useCallback(() => {
        setIsPopoverOpen(false);
    }, []);

    return (
        <div>
            {params.value.length ? (
                <>
                    <Button
                        sx={{ textTransform: 'initial', justifyContent: 'flex-start' }}
                        variant="text"
                        onClick={handleOpenPopover}
                    >
                        {locationCount > 1
                            ? `${locationCount} Locations`
                            : locationIdToLocationName.get(firstLocationId)}
                    </Button>
                    <Popover
                        anchorEl={popoverAnchorEl}
                        open={isPopoverOpen}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <ClickAwayListener onClickAway={handleClosePopover}>
                            <StyledCard>
                                <Box className={classes.searchBar}>
                                    <TextField
                                        variant="outlined"
                                        value={search}
                                        onChange={onSearchChange}
                                        fullWidth
                                        label="Search locations"
                                        size="small"
                                    />
                                </Box>
                                <Box className={classes.content}>
                                    {locationOptions.length > 0 ? (
                                        <List>{locationOptions.map(renderLocationItem)}</List>
                                    ) : (
                                        <Box paddingY={1.5} paddingX={2}>
                                            <Typography variant="body1">{`No locations found for "${search}"`}</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box className={classes.actions}>
                                    <Button onClick={handleClosePopover} variant="contained" color="primary">
                                        Close
                                    </Button>
                                </Box>
                            </StyledCard>
                        </ClickAwayListener>
                    </Popover>
                </>
            ) : (
                <StyledTypography variant="body2">All Locations</StyledTypography>
            )}
        </div>
    );
};

export const renderLocationsCell = (props: GridRenderCellParams) => <LocationsCell {...props} />;

import { GridCellValue, GridColTypeDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { MuiGridCreatableSelect } from 'lib/MuiGrid/select/MuiGridCreatableSelect';
import { renderMuiGridMultiselectView } from 'lib/MuiGrid/select/MuiGridMultiselectView';
import { isDefined } from 'lib/typeguards';
import React from 'react';

interface TaxCategoriesSelectProps extends GridRenderEditCellParams {
    labels: Map<string, string>;
}

const TaxCategoriesSelect: React.FC<TaxCategoriesSelectProps> = ({ labels, ...props }) => (
    <MuiGridCreatableSelect
        {...props}
        labels={labels}
        title="Categories"
        // eslint-disable-next-line max-len
        description="Select the category you want this tax to apply too. Selecting a category will automatically select all products within the category."
        inputLabel="Custom Categories"
        inputHelper="Enter the category ID and separate each ID with a comma."
        selectLabel="Existing Categories"
    />
);

export function renderTaxCategoriesSelect(props: GridRenderEditCellParams, labels: Map<string, string>) {
    return <TaxCategoriesSelect {...props} labels={labels} />;
}

export function TaxCategoriesGridColumn(labels: Map<string, string>): GridColTypeDef {
    const getName = (id: string) => labels.get(id) ?? id;
    const sort = (v1: GridCellValue, v2: GridCellValue) => getName('' + v1).localeCompare(getName('' + v2));
    return {
        sortComparator: sort,
        renderCell: props => renderMuiGridMultiselectView(props, labels),
        renderEditCell: props =>
            !isDefined(props.value) || Array.isArray(props.value)
                ? renderTaxCategoriesSelect(props, labels)
                : 'Something went wrong'
    };
}

import { ActionCreator } from 'redux';
import { TenantAccess } from 'components/merchant/models/TenantAccess';
import { tenantAccessApi } from 'components/merchant/tenantAccessApi';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { tenantAccessActionTypes } from './tenantAccessActionTypes';

const TENANT_ACCESS_PER_PAGE = 100;

export interface TenantAccessUsersAction {
    type: string;
    tenantUsersAccess?: TenantAccess[];
    index?: number;
    tenantAccess?: TenantAccess;
    error?: string;
    summary?: { count: number; limit: number; nextKey?: string };
}

const startRequest = () => ({
    type: tenantAccessActionTypes.START_REQUEST
});

const endRequest = () => ({
    type: tenantAccessActionTypes.END_REQUEST
});

const updateTenantAccessSuccess = (index: number, tenantAccess: TenantAccess) => ({
    index,
    tenantAccess,
    type: tenantAccessActionTypes.UPDATE_TENANT_USER_ACCESS_SUCCESS
});

export const getTenantUsersAccess = () => async (dispatch: ActionCreator<TenantAccessUsersAction>) => {
    dispatch(startRequest());
    const queryParameters = [{ key: 'limit', value: TENANT_ACCESS_PER_PAGE }];
    const response = await tenantAccessApi.getListByTenant(queryParameters);
    dispatch(endRequest());
    if (response.ok) {
        const tenantUsersAccess = response.body;
        dispatch({
            tenantUsersAccess: tenantUsersAccess.items,
            summary: tenantUsersAccess.page,
            type: tenantAccessActionTypes.GET_TENANT_USERS_ACCESS_SUCCESS
        });
    }
};

export const loadTenantUserAccess =
    (startKey: string) => async (dispatch: ActionCreator<TenantAccessUsersAction>) => {
        dispatch(startRequest);
        const queryParameters = [
            { key: 'limit', value: TENANT_ACCESS_PER_PAGE },
            { key: 'startKey', value: startKey }
        ];
        const response = await tenantAccessApi.getListByTenant(queryParameters);
        if (!response.ok) {
            dispatch({
                type: tenantAccessActionTypes.GET_TENANT_USERS_ACCESS_ERROR
            });
        } else {
            dispatch({
                tenantUsersAccess: response.body.items,
                type: tenantAccessActionTypes.LOAD_TENANT_USERS_ACCESS_SUCCESS,
                summary: response.body.page
            });
        }
        dispatch(endRequest);
    };

export const revokeUsersAccess =
    (id: string, index: number) => async (dispatch: ActionCreator<TenantAccessUsersAction>) => {
        dispatch(startRequest());
        const result = await tenantAccessApi.revokeUserAccess({ id });
        if (result.ok) {
            dispatch(updateTenantAccessSuccess(index, result.body));
            dispatch(
                enqueueSnackbar(`Successfully revoked access for ${result.body.email}`, {
                    variant: 'success'
                })
            );
        } else {
            dispatch({
                error: (result.body as any).message,
                type: tenantAccessActionTypes.UPDATE_TENANT_USER_ACCESS_ERROR
            });
            dispatch(
                enqueueSnackbar(`Error revoking user access: ${(result.body as any).message}`, {
                    variant: 'error'
                })
            );
        }
        dispatch(endRequest());
    };

export const updateUsersAccess =
    (index: number, tenantAccess: TenantAccess) => (dispatch: ActionCreator<TenantAccessUsersAction>) => {
        dispatch(updateTenantAccessSuccess(index, tenantAccess));
    };

export const addUsersAccess =
    (tenantAccess: TenantAccess) => (dispatch: ActionCreator<TenantAccessUsersAction>) => {
        dispatch({
            tenantAccess,
            type: tenantAccessActionTypes.ADD_TENANT_USER_ACCESS_SUCCESS
        });
    };

import React from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Row } from 'ui/Flex';

export interface TableSearchProps {
    onSearchChange?: (value: string) => void;
    onSearch?: (value: string) => void;
    searchPending: boolean;
    placeholder?: string;
    defaultValue?: string;
}

export const TableSearch: React.FC<TableSearchProps> = ({
    searchPending,
    placeholder,
    onSearch,
    onSearchChange,
    defaultValue = ''
}) => {
    const [search, setSearch] = React.useState(defaultValue);
    const handleSearch = React.useCallback(() => {
        if (onSearch) {
            onSearch(search);
        }
    }, [onSearch, search]);
    const handleSearchChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (onSearchChange) {
                onSearchChange(e.target.value);
            }
            setSearch(e.target.value);
        },
        [onSearchChange]
    );
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                onSearch(search);
            }
        },
        [onSearch, search]
    );
    const searchComponent = React.useMemo(
        () =>
            !!onSearch ? (
                <Button
                    disabled={searchPending}
                    onClick={handleSearch}
                    variant="outlined"
                    color="primary"
                    key={`search-btn-${String(searchPending)}`}
                >
                    Search
                </Button>
            ) : null,
        [handleSearch, onSearch, searchPending]
    );
    return (
        <Row valign="center" gutter>
            <TextField
                onKeyDown={handleKeyDown}
                sx={{ minWidth: 280 }}
                disabled={searchPending}
                onChange={handleSearchChange}
                placeholder={placeholder}
                value={search ?? ''}
            />
            {searchPending && <CircularProgress size={24} />}
            {searchComponent}
        </Row>
    );
};

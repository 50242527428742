import { applyMiddleware, combineReducers, createStore, Middleware, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import audiencesReducer, {
    AudiencesState,
    initialState as audiencesInitial
} from './audiences/audiencesReducer';
import authReducer, { AuthState, initialState as authInitial } from './auth/authReducer';
import menuManagerReducer, {
    MenuManagerState,
    initialState as menuManagerInitial
} from './menu-manager/menuManagerReducer';
import contentReducer, { ContentState, initialState as contentInitial } from './content/contentReducer';
import locationsReducer, {
    initialState as locationInitial,
    LocationsState
} from './locations/locationsReducer';
import manifestReducer, { initialState as manifestInitial, ManifestState } from './manifest/manifestReducer';
import notificationsReducer, {
    initialState as notificationInitial,
    NotificationsState
} from './notifications/notificationsReducer';
import stripeSettingsReducer, {
    initialState as stripeSettingsInitial,
    StripeSettingsState
} from './stripeSettings/stripeSettingsReducer';

import perksReducer, { initialState as perksInitial, PerksState } from './perks/perksReducer';
import tenantAccessReducer, {
    initialState as tenantAccessInitial,
    TenantAccessState
} from './tenant-access/tenantAccessReducer';
import uiReducer, { initialState as uiInitial, UiState } from './ui/uiReducer';
import settingsReducer, { initialState as settingsInitial, SettingsState } from './settings/settingsReducer';

export interface ApplicationState {
    auth: AuthState;
    audiences: AudiencesState;
    ui: UiState;
    notifications: NotificationsState;
    locations: LocationsState;
    tenantAccess: TenantAccessState;
    content: ContentState;
    manifest: ManifestState;
    perks: PerksState;
    stripeSettings: StripeSettingsState;
    settings: SettingsState;
    menuManager: MenuManagerState;
}

const appReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    auth: authReducer,
    audiences: audiencesReducer,
    ui: uiReducer,
    notifications: notificationsReducer,
    locations: locationsReducer,
    tenantAccess: tenantAccessReducer,
    content: contentReducer,
    manifest: manifestReducer,
    perks: perksReducer,
    stripeSettings: stripeSettingsReducer,
    settings: settingsReducer,
    menuManager: menuManagerReducer
});

const emptyState: ApplicationState = {
    auth: authInitial,
    audiences: audiencesInitial,
    ui: uiInitial,
    notifications: notificationInitial,
    locations: locationInitial,
    tenantAccess: tenantAccessInitial,
    content: contentInitial,
    manifest: manifestInitial,
    perks: perksInitial,
    stripeSettings: stripeSettingsInitial,
    settings: settingsInitial,
    menuManager: menuManagerInitial
};

const rootReducer: Reducer<ApplicationState> = (state = emptyState, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'DESTROY_SESSION') {
        // tslint:disable-next-line
        state = { ...emptyState, settings: state.settings, auth: state.auth };
    }

    return appReducer(state, action);
};

// Init store
export const initStore = (initialState = emptyState) => {
    const middlewares: [Middleware] = [reduxThunk];
    // Client side logger
    if (process.env.NODE_ENV !== 'production') {
        middlewares.push(createLogger());
    }
    return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
};

import { Card, CardContent, CardHeader, Divider, styled } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { MESSAGE_BUSYNESS_UPDATE_ERROR } from 'config/messages';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DeepPartial } from 'redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { LocationTimeSlotOptionCard } from './cards/LocationTimeSlotOptionCard';
import { LocationSettingsApi } from './LocationSettingsApi';

interface IProps {
    settings: DeepPartial<IPublicAndPrivateSettings>;
    locationId: string;
    handleSettingsSaved: () => Promise<void>;
    disabled?: boolean;
}

export type LocationBusyness = 'QUIET' | 'STANDARD' | 'BUSY';

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3)
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
    }
}));

export const LocationTimeSlotSettings = ({ settings, locationId, handleSettingsSaved, disabled }: IProps) => {
    const dispatch = useDispatch();
    // we never check case so convert to upper case here, as this is what console uses as standard
    const [busyness, setBusyness] = React.useState(settings?.busyness?.toUpperCase() as LocationBusyness);
    const [isLoading, setIsLoading] = React.useState({
        quiet: false,
        standard: false,
        busy: false
    });

    const disableSelection = React.useMemo(
        () => isLoading.quiet || isLoading.standard || isLoading.busy || disabled,
        [disabled, isLoading.busy, isLoading.quiet, isLoading.standard]
    );

    const handleSubmitLocationTimeSlotSettings = React.useCallback(
        async (busyness: LocationBusyness) => {
            let success = false;
            setIsLoading({ ...isLoading, [busyness]: true });
            const settingsToUpdate: IPublicAndPrivateSettings = {
                busyness
            };

            if (!Object.keys(settingsToUpdate).length) {
                // No need to update anything
                return true;
            }

            try {
                await new LocationSettingsApi().updatePublicAndPrivateSettings(settingsToUpdate, locationId);
                await handleSettingsSaved();
                dispatch(
                    enqueueSnackbar(`Location busyness updated to '${busyness}'`, { variant: 'success' })
                );
                success = true;
            } catch (err) {
                logger.error(`Error Updating Settings: ${JSON.stringify(err)}`);
                dispatch(enqueueSnackbar(MESSAGE_BUSYNESS_UPDATE_ERROR, { variant: 'error' }));
            } finally {
                setIsLoading({ ...isLoading, [busyness]: false });
            }

            return success;
        },
        [handleSettingsSaved, locationId, isLoading, dispatch]
    );

    const handleClickQuiet = React.useCallback(() => {
        setBusyness('QUIET');
        handleSubmitLocationTimeSlotSettings('QUIET');
    }, [handleSubmitLocationTimeSlotSettings]);
    const handleClickStandard = React.useCallback(() => {
        setBusyness('STANDARD');
        handleSubmitLocationTimeSlotSettings('STANDARD');
    }, [handleSubmitLocationTimeSlotSettings]);
    const handleClickBusy = React.useCallback(() => {
        setBusyness('BUSY');
        handleSubmitLocationTimeSlotSettings('BUSY');
    }, [handleSubmitLocationTimeSlotSettings]);

    return (
        <StyledCard title="Set Busyness Status">
            <CardHeader title="Set Busyness Status" titleTypographyProps={{ variant: 'h6' }} />
            <Divider />
            <StyledCardContent>
                <LocationTimeSlotOptionCard
                    busynessSetting={busyness}
                    busynessForCard="QUIET"
                    isLoading={isLoading.quiet}
                    isDisabled={disableSelection}
                    handleClick={handleClickQuiet}
                    ordersPerTimeSlot={settings?.timeSlots?.quiet?.ordersPerTimeslot ?? 0}
                    timeSlotFrequencyInMins={settings?.timeSlots?.quiet?.timeslotFrequencyInMins ?? 0}
                />
                <LocationTimeSlotOptionCard
                    busynessSetting={busyness}
                    busynessForCard="STANDARD"
                    isLoading={isLoading.standard}
                    isDisabled={disableSelection}
                    handleClick={handleClickStandard}
                    ordersPerTimeSlot={settings?.timeSlots?.standard?.ordersPerTimeslot ?? 0}
                    timeSlotFrequencyInMins={settings?.timeSlots?.standard?.timeslotFrequencyInMins ?? 0}
                />
                <LocationTimeSlotOptionCard
                    busynessSetting={busyness}
                    busynessForCard="BUSY"
                    isLoading={isLoading.busy}
                    isDisabled={disableSelection}
                    handleClick={handleClickBusy}
                    ordersPerTimeSlot={settings?.timeSlots?.busy?.ordersPerTimeslot ?? 0}
                    timeSlotFrequencyInMins={settings?.timeSlots?.busy?.timeslotFrequencyInMins ?? 0}
                />
            </StyledCardContent>
        </StyledCard>
    );
};

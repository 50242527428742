import { createLocationAddressForm } from './createLocationAddressForm';
import { createLocationGeneralForm } from './createLocationGeneralForm';
import { createLocationImagesForm } from './createLocationImagesForm';
import { createLocationInAppLinksForm } from './createLocationInAppLinksForm';
import { createLocationOpeningHoursForm } from './createLocationOpeningHoursForm';
import { createLocationServicesForm } from './createLocationServicesForm';

export const createLocationCreateForm = () => [
    {
        stepTitle: 'General',
        stepSubTitle: 'General information about your location including contact information.',
        data: createLocationGeneralForm()
    },
    {
        stepTitle: 'Location',
        stepSubTitle: 'Let us know where your location is so it can be displayed properly in your app.',
        data: createLocationAddressForm()
    },
    {
        stepTitle: 'Services',
        stepSubTitle: 'Configure what services you would like to be enabled at your location.',
        data: createLocationServicesForm()
    },
    {
        stepTitle: 'Links',
        stepSubTitle: 'Set up the social media accounts linked to your location.',
        data: createLocationInAppLinksForm()
    },
    {
        stepTitle: 'Opening Hours',
        stepSubTitle: 'Set the opening hours of your location so your customers know when to come.',
        data: createLocationOpeningHoursForm()
    },
    {
        stepTitle: 'Images',
        stepSubTitle: 'Upload Images to show your location in-app.',
        data: createLocationImagesForm()
    }
];

import { GridApi, GridCellValue, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isTagValue, TagValue } from '@pepperhq/menu-sdk';
import { isArrayOf } from 'lib/typeguards';
import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { TagsForm } from './TagsForm';

const TagValueCell: React.FC<Omit<GridRenderCellParams, 'api'> & { api: GridApi }> = ({
    value,
    api,
    id,
    field
}) => {
    const formatValue = React.useCallback((value: GridCellValue) => {
        if (Array.isArray(value)) {
            return value.map(item => ({
                id: String(item.id),
                title: item.title,
                sort: Number.isInteger(item.sort) ? item.sort : 0,
                imageUrl: item?.imageUrl ? item.imageUrl : undefined
            }));
        }
        return [];
    }, []);
    const internalValue = React.useMemo(() => formatValue(value), [formatValue, value]);
    const renderString = React.useMemo(() => {
        const getValuesString = (values: TagValue[]) =>
            values.map(item => `${item.id} - ${item.title}`).join(', ');
        if (isArrayOf(isTagValue, value)) {
            return getValuesString(value);
        }
        return '';
    }, [value]);
    const handleModalClose = React.useCallback(() => {
        api.setCellMode(id, field, 'view');
    }, [api, field, id]);
    const handleSave = React.useCallback(
        (value: TagValue[]) => {
            api.setEditCellValue({ id, field, value: formatValue(value) });
            api.commitCellChange({ id, field });
        },
        [api, field, formatValue, id]
    );
    const handleClear = React.useCallback(() => {
        api.setEditCellValue({ id, field, value: null });
        api.commitCellChange({ id, field });
    }, [api, field, id]);
    const tags = React.useMemo(
        () => (isArrayOf(isTagValue, internalValue) ? internalValue : []),
        [internalValue]
    );

    return (
        <>
            <span>{renderString}</span>
            <MuiModal open={true} onClose={handleModalClose}>
                <TagsForm tags={tags} onClose={handleModalClose} onSave={handleSave} onClear={handleClear} />
            </MuiModal>
        </>
    );
};

export const TagsEditCell = (props: GridRenderCellParams) => <TagValueCell {...props} />;

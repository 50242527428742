/* eslint-disable max-len */
import React from 'react';

import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { Option } from 'lib/types';
import { isValid } from 'date-fns';

import { PerkFormStep } from 'components/perks/PerksEditStepper';
import { ReedemptionFormCardContent } from './RedemptionFormContent';
import { Form, FormikBag } from 'formik';
import { AwardRedemptionFormData } from './RedemptionCommonForm';
import { AwardRedemptionFormProps } from '.';

type AwardRedemptionAutoFormData = Pick<AwardRedemptionFormData, 'dateTimeRestriction'>;
type AwardRedemptionAutoFullFormData = AwardRedemptionAutoFormData & {
    dateTimeRestriction: AwardRedemptionFormData['dateTimeRestriction'] & {
        dateTimeRestictionOption: DateTimeOptionValue[];
    };
};

const dateAndTimeRestricionOptions: Option[] = [
    {
        value: 'date',
        label: 'specific start and end date'
    },
    {
        value: 'time',
        label: 'specific start and end time'
    },
    {
        value: 'day',
        label: 'particular weekday'
    }
];
export type DateTimeOptionValue = typeof dateAndTimeRestricionOptions[number]['value'];

export const awardRedemptionDefaultFormValues: AwardRedemptionAutoFullFormData = {
    dateTimeRestriction: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        daysOfWeek: [],
        dateTimeRestictionOption: []
    }
};

const generateDateOptions = (initialData?: AwardRedemptionAutoFormData) => {
    if (!initialData?.dateTimeRestriction) {
        return [];
    }
    const dateFields = [];
    if (initialData?.dateTimeRestriction?.startDate) {
        dateFields.push('date');
    }
    if (initialData?.dateTimeRestriction?.startTime) {
        dateFields.push('time');
    }
    if (
        initialData?.dateTimeRestriction?.daysOfWeek &&
        initialData?.dateTimeRestriction?.daysOfWeek?.length
    ) {
        dateFields.push('day');
    }
    return dateFields;
};

export const RedemptionAutoForm: React.FC<AwardRedemptionFormProps> = ({
    onSubmit,
    initialData,
    isEdit,
    perkType,
    clickPrevious,
    onPrevious,
    isLoading,
    currencySymbol,
    onUpdate
}) => {
    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                dateTimeRestriction: Yup.object().shape({
                    startTime: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('time'),
                        then: Yup.string().required('This field is required'),
                        otherwise: Yup.string().notRequired()
                    }),
                    endTime: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('time'),
                        then: Yup.string().required('This field is required'),
                        otherwise: Yup.string().notRequired()
                    }),
                    startDate: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('date'),
                        then: Yup.string()
                            .required('This field is required.')
                            .test('valid-date', 'Please enter valid date', value => {
                                if (value && !isValid(new Date(value))) {
                                    return false;
                                }

                                return true;
                            }),
                        otherwise: Yup.string().notRequired()
                    }),
                    endDate: Yup.string().when('dateTimeRestictionOption', {
                        is: (value: string[]) => value.includes('date'),
                        then: Yup.string()
                            .required('This field is required.')
                            .test('valid-date', 'Please enter valid date', value => {
                                if (value && !isValid(new Date(value))) {
                                    return false;
                                }

                                return true;
                            }),
                        otherwise: Yup.string().notRequired()
                    }),
                    daysOfWeek: Yup.array().notRequired()
                })
            }),
        []
    );

    const initialValues = React.useMemo(() => {
        if (initialData) {
            return {
                ...initialData,
                dateTimeRestriction: {
                    ...(initialData.dateTimeRestriction || {}),
                    dateTimeRestictionOption: generateDateOptions(initialData)
                }
            };
        }
    }, [initialData]);

    const handleSubmit = React.useCallback(
        (
            { dateTimeRestriction, ...data }: AwardRedemptionAutoFullFormData,
            formikBag: FormikBag<any, AwardRedemptionAutoFullFormData>
        ) => {
            const { dateTimeRestictionOption: _, ...dateTime } = dateTimeRestriction;
            onSubmit({
                ...data,
                dateTimeRestriction: {
                    ...dateTime,
                    daysOfWeek: dateTime.daysOfWeek ? dateTime.daysOfWeek : []
                }
            });
            formikBag.setSubmitting(false);
        },
        [onSubmit]
    );

    const handleUpdate = React.useCallback(
        ({ dateTimeRestriction, ...data }: AwardRedemptionAutoFullFormData) => {
            const { dateTimeRestictionOption: _, ...dateTime } = dateTimeRestriction;
            onUpdate({
                type: PerkFormStep.REDEMPTION,
                values: {
                    ...data,
                    dateTimeRestriction: {
                        ...dateTime,
                        daysOfWeek: dateTime.daysOfWeek ? dateTime.daysOfWeek : []
                    }
                }
            });
        },
        [onUpdate]
    );
    return (
        <MuiForm
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues || awardRedemptionDefaultFormValues}
        >
            <Form>
                <ReedemptionFormCardContent
                    clickPrevious={clickPrevious}
                    onUpdate={handleUpdate}
                    isLoading={isLoading}
                    onPrevious={onPrevious}
                    currencySymbol={currencySymbol}
                    perkType={perkType}
                    isEdit={isEdit}
                    auto
                />
            </Form>
        </MuiForm>
    );
};

import React from 'react';
import { useHistory } from 'react-router-dom';

export function useLeavePageBlock(prompt?: boolean) {
    const history = useHistory();

    React.useEffect(() => {
        const unblock = history.block(() => {
            if (prompt) {
                // eslint-disable-next-line no-alert
                const result = window.confirm(
                    'Do you want to leave this page? Changes you made will not be saved.'
                );
                return result ? undefined : false;
            }
            return undefined;
        });
        const preventNav = (event: BeforeUnloadEvent) => {
            if (!prompt) {
                return;
            }
            event.preventDefault();
            event.returnValue = '';
        };
        const createListener = () => {
            window.addEventListener('beforeunload', preventNav);
        };

        const removeListener = () => {
            window.removeEventListener('beforeunload', preventNav);
        };
        createListener();

        return () => {
            unblock();
            removeListener();
        };
    }, [history, prompt]);
}

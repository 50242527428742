import { Box, Button, Grid, Typography } from '@mui/material';
import { NutritionInfo } from '@pepperhq/menu-sdk';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import React from 'react';
import { Row } from 'ui/Flex';
import * as Yup from 'yup';

export interface NutritionCaloriesFormProps {
    nutrition?: NutritionInfo;
    onClose: () => void;
    onSave: (nutrition: NutritionInfo) => void;
    onClear: () => void;
}

const schema = Yup.object().shape({
    values: Yup.array()
        .of(
            Yup.object().shape({
                nutrient: Yup.string().required('This field is required'),
                value: Yup.string().required('This field is required')
            })
        )
        .required('This field is required')
});

export const NutritionCaloriesForm: React.FC<NutritionCaloriesFormProps> = ({
    nutrition,
    onClose,
    onClear,
    onSave
}) => {
    const handleSubmit = React.useCallback(
        (values: NutritionInfo) => {
            onSave(values);
            onClose();
        },
        [onClose, onSave]
    );
    const handleRemove = React.useCallback(() => {
        onClear();
        onClose();
    }, [onClear, onClose]);
    const initialValues = React.useMemo<NutritionInfo>(
        () => nutrition ?? { title: '', values: [{ value: '', nutrient: '' }] },
        [nutrition]
    );
    return (
        <MuiForm initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            {({ submitForm }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary" gutterBottom>
                                Edit Nutrition Information
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <div />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="center">Nutrient</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="center">Value</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box zIndex={999999}>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}>
                                        {' '}
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextFormField name={'values[0].nutrient'} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextFormField name={'values[0]].value'} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Row align="spave-between" flex={1}>
                                <Row flex={1}>
                                    <Button onClick={handleRemove} color="primary">
                                        Remove Nutrition
                                    </Button>
                                </Row>
                                <Row gutter>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button variant="contained" color="primary" onClick={submitForm}>
                                        Submit
                                    </Button>
                                </Row>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};

import React from 'react';
import { Divider, Grid, styled, Typography } from '@mui/material';
import { Perk } from 'components/loyalty/models/PerkModel';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { MuiModal } from 'ui/MuiModal';
import { ActionComponentProps, Panel } from 'ui/Panel';
import { CardViewChipArrayRow, CardViewTextRowValue, CardViewTitle } from 'ui/viewComponents';
import { getStampCard } from '../helpers';
import { Award, AwardTemplate } from '../models/Award';
import { Customer } from '../models/Customer';
import { CustomerAwardForm } from './CustomerAwardForm';
import { Row } from 'ui/Flex';
import { CustomerRedeemAwardForm } from './CustomerRedeemAwardForm';

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: theme.spacing(75),
    minWidth: theme.spacing(56),
    maxWidth: theme.spacing(85),
    overflow: 'visible'
}));

interface CustomersAwardsCardProps {
    awards: Partial<Award>[];
    customer: Customer;
    perks: Perk[];
    isLoading: boolean;
    // TODO: split the pointperks data from customer data, and remove this dependency
    loadCustomer: () => void;
}

export const CustomersAwardsCard: React.FC<CustomersAwardsCardProps> = ({
    customer,
    perks,
    isLoading,
    loadCustomer,
    awards
}) => {
    const [isGrantOpen, setIsGrantOpen] = React.useState(false);
    const [isRedeemOpen, setIsRedeemOpen] = React.useState(false);
    const [stampCards, pseudocurrencies, otherAwards] = React.useMemo(() => {
        const stampCards: Partial<Award>[] = [];
        const pseudocurrencies: Partial<Award>[] = [];
        const otherAwards: string[] = [];
        awards.forEach(item => {
            if (item.template === AwardTemplate.STAMP_CARD) {
                return stampCards.push(item);
            }
            if (item.template === AwardTemplate.PSEUDO_CURRENCY) {
                return pseudocurrencies.push(item);
            }
            otherAwards.push(item.title);
        });
        return [stampCards, pseudocurrencies, otherAwards];
    }, [awards]);
    const closeGrantModal = React.useCallback(() => setIsGrantOpen(false), []);
    const openGrantModal = React.useCallback(() => setIsGrantOpen(true), []);
    const closeRedeemModal = React.useCallback(() => setIsRedeemOpen(false), []);
    const openRedeemModal = React.useCallback(() => setIsRedeemOpen(true), []);
    const actionRender = React.useCallback(
        ({ loading }: ActionComponentProps) => (
            <Row gutter>
                <LoadingButton
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    loading={loading}
                    onClick={openRedeemModal}
                >
                    Redeem an Award
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    loading={loading}
                    onClick={openGrantModal}
                >
                    Grant an Award
                </LoadingButton>
            </Row>
        ),
        [openGrantModal, openRedeemModal]
    );
    const handleGrantSuccess = React.useCallback(() => {
        loadCustomer();
        closeGrantModal();
    }, [closeGrantModal, loadCustomer]);
    const handleRedeemSuccess = React.useCallback(() => {
        loadCustomer();
        closeRedeemModal();
    }, [closeRedeemModal, loadCustomer]);
    return (
        <React.Fragment>
            <Panel
                title="Awards"
                actionComponentRender={actionRender}
                isLoading={isLoading}
                divider
                onClick={openGrantModal}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <CardViewTitle title="Stamp Cards" />
                                    </Grid>
                                    {stampCards.length ? (
                                        stampCards.map((item, index) => (
                                            <React.Fragment key={`spamps-${index}`}>
                                                <Grid item xs={6}>
                                                    <CardViewTextRowValue value={item.title} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CardViewTextRowValue value={getStampCard(item.points)} />
                                                </Grid>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography color="textSecondary" variant="body2">
                                                This customer does not have any stamp cards
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <CardViewTitle title="Pseudocurrencies" />
                                    </Grid>
                                    {pseudocurrencies.length ? (
                                        pseudocurrencies.map((item, index) => (
                                            <React.Fragment key={`spamps-${index}`}>
                                                <Grid item xs={6}>
                                                    <CardViewTextRowValue value={item.title} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CardViewTextRowValue
                                                        value={item.points && String(item.points.available)}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography color="textSecondary" variant="body2">
                                                This customer does not have any pseudocurrencies
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <CardViewChipArrayRow
                                    title="Other Loyalty Awards"
                                    value={otherAwards}
                                    fallback="This customer does not have any other loyalty awards"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Panel>
            <StyledMuiModal open={isGrantOpen} onClose={closeGrantModal}>
                <CustomerAwardForm
                    awards={awards}
                    customer={customer}
                    perks={perks}
                    onClose={closeGrantModal}
                    onSuccess={handleGrantSuccess}
                />
            </StyledMuiModal>
            <StyledMuiModal open={isRedeemOpen} onClose={closeRedeemModal}>
                <CustomerRedeemAwardForm
                    awards={awards}
                    customer={customer}
                    perks={perks}
                    onClose={closeRedeemModal}
                    onSuccess={handleRedeemSuccess}
                />
            </StyledMuiModal>
        </React.Fragment>
    );
};

import { GridCellValue, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isSuggestion } from '@pepperhq/menu-sdk';
import { isArrayOf } from '../../../lib/typeguards';
import { Option } from '../../../lib/types';
import React from 'react';

interface SuggestionsValueProps {
    value: GridCellValue;
    options: Option[];
}

const SuggestionsValue: React.FC<SuggestionsValueProps> = ({ value, options }) => {
    const renderString = React.useMemo(() => {
        if (isArrayOf(isSuggestion, value)) {
            if (value[0].type === 'add') {
                const found = options.find(item => item.value === value[0].productId);
                if (found && found?.label) {
                    return found.label;
                }
                return '';
            }
            if (value[0].type === 'bogof') {
                return 'OFFER';
            }
            return '';
        }
        return '';
    }, [value, options]);

    return <span>{renderString}</span>;
};

export const SuggestionsViewCell = (props: GridRenderCellParams, options: Option[]) => (
    <SuggestionsValue value={props.value} options={options} />
);

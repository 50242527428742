import React from 'react';
import { styled } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { VoucherDetailsForm, VoucherDetailsFormData } from '../forms/VoucherDetailsForm';
import { FormikHelpers } from 'formik';

const StyledModal = styled(MuiModal)(({ theme }) => ({
    width: theme.spacing(60)
}));

interface IEditVoucherModalProps {
    open: boolean;
    onClose?: () => void;
    onSubmit: (voucher: VoucherDetailsFormData, formikHelpers: FormikHelpers<VoucherDetailsFormData>) => void;
    initialData?: Partial<VoucherDetailsFormData>;
}

export const EditVoucherModal: React.FunctionComponent<IEditVoucherModalProps> = props => {
    const { open, onClose, onSubmit, initialData } = props;
    return (
        <StyledModal open={open} onClose={onClose}>
            <VoucherDetailsForm
                title="Edit Voucher"
                submitText="Save Changes"
                onSubmit={onSubmit}
                onClose={onClose}
                initialData={initialData}
            />
        </StyledModal>
    );
};

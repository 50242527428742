import * as React from 'react';
import { ButtonBase, styled } from '@mui/material';
import { MuiTheme } from 'config/theme';
import { Column } from '../Flex';

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    width: theme.spacing(4.5),
    textAlign: 'center',
    height: theme.spacing(4.5),
    background: 'transparent',
    color: theme.palette.text.secondary,
    borderRadius: '50%',
    marginLeft: theme.spacing(1),
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
    border: 'none',
    cursor: 'pointer',
    transition: 'color .15s linear',
    '&:hover': {
        color: theme.palette.primary.dark
    }
}));

interface ActionButtonProps {
    onClick?: (event?: React.MouseEvent) => void;
    disabled?: boolean;
    isDisabled?: boolean;
    elevation?: number;
    className?: string;
    children: React.ReactNode;
    title?: string;
    size?: 'small' | 'medium' | 'large';
}

export const IconButton = React.forwardRef(
    (
        { className, children, elevation, isDisabled, ...rest }: ActionButtonProps,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        if (isDisabled) {
            return null;
        }
        return (
            <StyledButtonBase
                className={className}
                style={{ boxShadow: MuiTheme.shadows[elevation] }}
                {...rest}
                ref={ref}
            >
                <Column valign="center" align="center">
                    {children}
                </Column>
            </StyledButtonBase>
        );
    }
);

IconButton.defaultProps = {
    elevation: 0
};

import React from 'react';
import { Grid } from '@mui/material';
import { OrderScenario, OrderScenarioLabels } from 'components/order/model/Order';
import { getCurrencyString } from 'lib/helpers';
import { CardViewTextRow } from 'ui/viewComponents';

interface ActionOrderCompletedContentProps {
    date: string;
    value: number;
    currency: string;
    locationTitle: string;
    scenario: OrderScenario;
}

export const ActionOrderCompletedContent: React.FC<ActionOrderCompletedContentProps> = ({
    date,
    value,
    currency,
    locationTitle,
    scenario
}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <CardViewTextRow title="Date" value={date} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CardViewTextRow title="Price" value={getCurrencyString(value, currency)} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CardViewTextRow title="Location" value={locationTitle} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CardViewTextRow title="Type" value={OrderScenarioLabels[scenario]} />
        </Grid>
    </React.Fragment>
);

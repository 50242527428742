import { Crud, HttpClient, httpClient } from 'lib/HttpClient';
import { UserMetric } from './model';
import { TierItemBody, TieredLoyaltyListenerBody } from './model/loyalty-tier';

class MetricsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'userMetrics');
    }
    addMetric(definitionId: string, metricId: string, data: UserMetric) {
        return this.httpClient.post(`${this.api}/userMetrics/${definitionId}/metrics/${metricId}`, {
            body: {
                ...data
            }
        });
    }
    deleteMetric(definitionId: string, metricId: string) {
        return this.httpClient.delete(`${this.api}/userMetrics/${definitionId}/metrics/${metricId}`, {});
    }
    addListener(definitionId: string, body: TieredLoyaltyListenerBody) {
        return this.httpClient.post(`${this.api}/userMetrics/${definitionId}/listeners`, { body });
    }
    deleteListener(definitionId: string, listenerId: string) {
        return this.httpClient.delete(`${this.api}/userMetrics/${definitionId}/listeners/${listenerId}`, {});
    }
    addListenerCondition(definitionId: string, listenerId: string, body: TierItemBody) {
        return this.httpClient.post(
            `${this.api}/userMetrics/${definitionId}/listeners/${listenerId}/conditions`,
            { body }
        );
    }
    deleteListenerCondition(definitionId: string, listenerId: string, conditionId: string) {
        return this.httpClient.delete(
            `${this.api}/userMetrics/${definitionId}/listeners/${listenerId}/conditions/${conditionId}`,
            {}
        );
    }
}

export const metricsApi = new MetricsApi(httpClient);

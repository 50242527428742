import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class MenuOptionsApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.MENU_SERVICE_URL, 'menuoptions', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'x-api-version': 5
        });
    }

    getMenuOptionsByLocation(locationId: string) {
        return this.getList({
            queryParameters: [{ key: 'locationId', value: locationId }]
        });
    }
}

export const menuOptionsApi = new MenuOptionsApi(httpClient);

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const LightspeedSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <SettingDisplay label="Web Orders Server Host">
                <TextFormField name="lightspeedWebOrdersServerHost" />
            </SettingDisplay>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplay label="Lightspeed Id">
                    <TextFormField name="lightspeedLightspeedId" />
                </SettingDisplay>
                <SettingDisplay label="Payment Type Name">
                    <TextFormField name="lightspeedPaymentTypeName" />
                </SettingDisplay>
            </>
        )}
    </>
);

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';

type DisputeReasonCellProps = GridRenderCellParams;

export const DisputeReasonCell: React.FC<DisputeReasonCellProps> = ({ value }) => {
    const formattedValue = React.useMemo(() => titleize(String(value).replaceAll('_', ' ')), [value]);
    return <span>{formattedValue}</span>;
};

/* eslint-disable @typescript-eslint/await-thenable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, styled } from '@mui/material';
import { ApplicationState } from 'store/store';
import { Column } from 'ui/Flex';
import { MobileLayout } from 'ui/MobileLayout';
import { ContentTemplate } from './models/ContentTemplate';
import { MobileContentRenderer } from './MobileContentRenderer';
import { createAppLayoutFromTemplate } from 'store/content/contentActions';

const PREFIX = 'ConditionValue';

const classes = {
    column: `${PREFIX}-column`
};

const StyledColumn = styled(Column)(({ theme }) => ({
    margin: theme.spacing(12),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(3),
    [`& .${classes.column}`]: {
        marginTop: theme.spacing(2)
    }
}));

interface ContentTemplateItemProps {
    template: ContentTemplate;
}

export const ContentTemplateItem: React.FC<ContentTemplateItemProps> = ({ template }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: ApplicationState) => state.content);
    const history = useHistory();
    const handleButtonClick = React.useCallback(() => {
        createAppLayoutFromTemplate(template, history.push)(dispatch);
    }, [dispatch, history.push, template]);
    return (
        <StyledColumn>
            <MobileLayout>
                <MobileContentRenderer anchor={template.anchor} modules={template.anchor.modules} />
            </MobileLayout>
            <Column align="center" className={classes.column}>
                <Button
                    sx={{ mt: 2 }}
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                >
                    Select this layout
                </Button>
            </Column>
        </StyledColumn>
    );
};

import React from 'react';
import { Grid } from '@mui/material';
import { Panel } from 'ui/Panel';
import { CardViewDateRow, CardViewTextRow } from 'ui/viewComponents';
import { Voucher } from '../models/VoucherModel';

interface VoucherDetailCardProps {
    voucher: Voucher;
}

export const VoucherDetailCard: React.FC<VoucherDetailCardProps> = props => {
    const { voucher } = props;
    return (
        <Panel divider title="General">
            <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm={12}>
                    <CardViewTextRow title="Title" value={voucher.title} />
                </Grid>
                <Grid item xs={12} sm={12} xl={6}>
                    <CardViewTextRow title="Claim Code" value={voucher.code} />
                </Grid>
                {!!voucher.voucherSchemeId && (
                    <Grid item xs={12} sm={12} xl={6}>
                        <CardViewTextRow title="Scheme ID" value={voucher.voucherSchemeId} />
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow
                        title="Maximum Redemptions"
                        value={String(voucher.numGlobalRedemptions)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewTextRow
                        title="Redemptions Per User"
                        value={String(voucher.numRedemptionsPerUser)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewDateRow title="Start Date" value={voucher.startDate} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardViewDateRow title="End Date" value={voucher.endDate} />
                </Grid>
            </Grid>
        </Panel>
    );
};

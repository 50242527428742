export enum PerksActionType {
    START_REQUEST = 'START_PERKS_REQUEST',
    LOAD_SUCCESS = 'LOAD_PERKS_SUCCESS',
    LOAD_ALL_SUCCESS = 'LOAD_ALL_PERKS_SUCCESS',
    LOAD_ERROR = 'LOAD_PERKS_ERROR',
    MODAL_ACTION = 'PERKS_MODAL_ACTION',
    CREATE_SUCCESS = 'CREATE_PERK_SUCCESS',
    CREATE_SUCCESS_CLUB = 'CREATE_PERK_SUCCESS_CLUB',
    UPDATE_SUCCESS = 'UPDATE_PERK_SUCCESS',
    TOGGLE_CREATE_MODAL = 'TOGGLE_CREATE_PERK_MODAL',
    TOGGLE_CLOSE_CREATE_MODAL = 'TOGGLE_CLOSE_CREATE_PERK_MODAL',
    TOGGLE_CLOSE_UPDATE_MODAL = 'TOGGLE_CLOSE_UPDATE_PERK_MODAL',
    CLOSE_MODALS = 'CLOSE_PERK_MODALS',
    START_DELETE_REQUEST = 'START_DELETE_PERK_REQUEST',
    DELETE_SUCCESS = 'DELETE_PERK_SUCCESS',
    DELETE_ERROR = 'DELETE_PERK_ERROR',
    SET_ITEM_TO_DELETE = 'SET_PERK_ITEM_TO_DELETE',
    START_RESET_REQUEST = 'START_PERK_RESET_REQUEST',
    RESET_SUCCESS = 'RESET_PERK_SUCCESS',
    RESET_ERROR = 'RESET_PERK_ERROR',
    SET_ITEM_TO_RESET = 'SET_PERK_ITEM_TO_RESET',
    SET_OPEN_ITEM = 'SET_OPEN_PERK_ITEM',
    SET_PERK_AWARD_COUNT = 'SET_PERK_AWARD_COUNT',
    SET_SELECTED_ROW = 'SET_SELECTED_PERK_ROW',
    UNKNOWN = 'UNKNOWN'
}

import { GridApi, GridCellValue, GridColTypeDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { MuiGridCreatableSelect } from 'lib/MuiGrid/select/MuiGridCreatableSelect';
import { renderMuiGridMultiselectView } from 'lib/MuiGrid/select/MuiGridMultiselectView';
import { isDefined } from 'lib/typeguards';
import React from 'react';

interface TaxProductsSelectProps extends GridRenderEditCellParams {
    labels: Map<string, string>;
    categoryProducts: Record<string, string[]>;
}

const TaxProductsSelect: React.FC<TaxProductsSelectProps> = ({ labels, categoryProducts, ...props }) => {
    const disabledItems = React.useMemo(() => {
        const row = (props.api as GridApi).getRow(props.id);
        if (Array.isArray(row.categoryIds) && row.categoryIds.length) {
            const items: string[][] = [];
            row.categoryIds.forEach((id: string) => {
                items.push(categoryProducts[id] || []);
            });
            return items.flat();
        }
        return undefined;
    }, [categoryProducts, props.api, props.id]);
    return (
        <MuiGridCreatableSelect
            {...props}
            labels={labels}
            title="Products"
            // eslint-disable-next-line max-len
            description="Select the individual products you want this tax to apply too. You cannot untick a product if the category has been selected already."
            inputLabel="Custom Products"
            inputHelper="Enter the product ID and separate each ID with a comma."
            selectLabel="Existing Products"
            disabledItems={disabledItems}
        />
    );
};

export function renderTaxProductsSelect(
    props: GridRenderEditCellParams,
    labels: Map<string, string>,
    categoryProducts: Record<string, string[]>
) {
    return <TaxProductsSelect {...props} labels={labels} categoryProducts={categoryProducts} />;
}

export function TaxProductsGridColumn(
    labels: Map<string, string>,
    categoryProducts: Record<string, string[]>
): GridColTypeDef {
    const getName = (id: string) => labels.get(id) ?? id;
    const sort = (v1: GridCellValue, v2: GridCellValue) => getName('' + v1).localeCompare(getName('' + v2));
    return {
        sortComparator: sort,
        renderCell: props => renderMuiGridMultiselectView(props, labels),
        renderEditCell: props =>
            !isDefined(props.value) || Array.isArray(props.value)
                ? renderTaxProductsSelect(props, labels, categoryProducts)
                : 'Something went wrong'
    };
}

import { AwardTemplate } from 'components/customers/models/Award';
import { Perk, PerkVisualisation } from 'components/loyalty/models/PerkModel';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { getTimezoneIrrelevantDateTime, rgbaToHex } from 'lib/helpers';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { AwardRedemptionFormData } from './award-enrichments/forms/AwardRedemptionForm';
import { getDifferenceFromMidnightInMinutes } from './award-enrichments/utils';
import { PerkGeneralFormData } from './forms/PerkGeneralForm';
import { PerkSettingsFormData } from './forms/PerkSettingsForm';

const generateRedemptionFields = ({
    basketQualification,
    discount,
    dateTimeRestriction
}: AwardRedemptionFormData) => {
    const weekDays = dateTimeRestriction.daysOfWeek?.length
        ? dateTimeRestriction.daysOfWeek.reduce((acc, weekday) => {
              acc += Number(weekday);

              return acc;
          }, 0)
        : undefined;
    const hasDiscountFields = !!discount && !!Object.values(discount).filter(Boolean).length;
    const hasBasketQualificationFields =
        !!basketQualification && !!Object.values(basketQualification).filter(Boolean).length;

    // Weird date time manipulations to ignore the timezone
    let { startDate, endDate } = dateTimeRestriction;
    if (startDate) {
        startDate = getTimezoneIrrelevantDateTime(startDate).toUTCString();
    }
    if (endDate) {
        endDate = getTimezoneIrrelevantDateTime(endDate).toUTCString();
    }

    return {
        basketQualification: hasBasketQualificationFields
            ? {
                  matchCode: basketQualification.matchCode || undefined,
                  minimumItems: basketQualification.minimumItems || undefined,
                  minimumValue: basketQualification.minimumValue || undefined,
                  scope: basketQualification.scope
              }
            : undefined,
        discount: hasDiscountFields
            ? {
                  value:
                      isDefined(discount.value) && !isEmptyString(discount.value)
                          ? Number(discount.value)
                          : undefined,
                  scope: discount.scope,
                  type: discount.type,
                  scopeMatchCode: discount.scopeMatchCode,
                  rule: discount.rule,
                  childItemRule: discount.childItemRule
              }
            : undefined,
        dateTimeRestriction: {
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            startTime: getDifferenceFromMidnightInMinutes(dateTimeRestriction.startTime),
            endTime: getDifferenceFromMidnightInMinutes(dateTimeRestriction.endTime),
            daysOfWeek: weekDays
        }
    };
};

export const getPerkOpacity = (color: string | RGBAValue) => {
    const opacity = typeof color === 'string' ? parseInt(color.substring(7, 9), 16) / 255 : color.a;
    if (Number.isNaN(opacity)) {
        return 1;
    }
    return opacity ?? 1;
};

const generateCreatePseudoCurPerkBody = (general: PerkGeneralFormData, settings: PerkSettingsFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);
    const initial =
        settings.perkType === AwardTemplate.PSEUDO_CURRENCY && settings.global
            ? Number(settings.initial ?? 0)
            : 1;
    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        priority: general.order,
        template: AwardTemplate.PSEUDO_CURRENCY,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        visualisation: {
            subtitleTwoText: 'Worth {{currency}}',
            textAlignment: settings.align,
            description: general.description,
            subtitleText: '{{available}} point{{s}}',
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: settings.titleText
        } as PerkVisualisation,
        points: {
            initial,
            max: 100000,
            min: 0,
            step: Number(settings.step),
            redemption: Number(settings.impact)
        }
    };
};

const generateCreateAdHocPerkBody = (general: PerkGeneralFormData, settings: PerkSettingsFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);
    const isMultiSet = !!settings.multi;

    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        priority: general.order,
        template: AwardTemplate.AD_HOC,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: isMultiSet ? 0 : 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        visualisation: {
            subtitleTwoText: settings.subtitle2,
            textAlignment: settings.align,
            description: general.description,
            subtitleText: settings.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: settings.titleText
        } as PerkVisualisation,
        points: {
            ...(isMultiSet
                ? {
                      initial: 1,
                      min: 1,
                      max: 1,
                      step: 0,
                      redemption: 0
                  }
                : {
                      initial: 1,
                      min: 0,
                      max: 1,
                      step: 1,
                      redemption: 1
                  })
        }
    };
};

const generateCreateClubPerkBody = (general: PerkGeneralFormData, settings: PerkSettingsFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);
    const isMultiSet = !!settings.multi;

    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        priority: general.order,
        template: AwardTemplate.CLUB,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: isMultiSet ? 0 : 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        visualisation: {
            subtitleTwoText: settings.subtitle2,
            textAlignment: settings.align,
            description: general.description,
            subtitleText: settings.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: settings.titleText
        } as PerkVisualisation,
        points: {
            ...(isMultiSet
                ? {
                      initial: 1,
                      min: 1,
                      max: 1,
                      step: 0,
                      redemption: 0
                  }
                : {
                      initial: 1,
                      min: 0,
                      max: 1,
                      step: 1,
                      redemption: 1
                  })
        }
    };
};

const generateCreateStampCardPerkBody = (general: PerkGeneralFormData, settings: PerkSettingsFormData) => {
    const opacity = getPerkOpacity(settings.backgroundColor);
    const initial =
        settings.perkType === AwardTemplate.STAMP_CARD && settings.global ? Number(settings.initial ?? 0) : 1;
    return {
        terms: settings.terms,
        isGlobal: settings.global,
        enabled: settings.enabled,
        locationIds: settings.locationIds?.length > 0 ? settings.locationIds : undefined,
        priority: general.order,
        template: AwardTemplate.STAMP_CARD,
        auto: settings.auto ?? false,
        isDonatable: false,
        maximumPointCashValue: 1,
        isTier: false,
        title: general.title,
        internalTitle: general.internalTitle,
        redemptionMethod: 'AUTO' as Perk['redemptionMethod'],
        visualisation: {
            textAlignment: settings.align,
            randomiseStampPositions: settings.randomizeStampCard,
            stampImageUrl: settings.stampImageUrl,
            stampColour: rgbaToHex(settings.stampColor, true),
            description: general.description,
            subtitleText: settings.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(settings.backgroundColor, true),
            textColour: rgbaToHex(settings.textColor, true),
            backgroundImageUrl: settings.image ?? undefined,
            verticalAlignment: settings.valign,
            availableCardCopy: settings.availableCardCopy,
            nextCardProgressCopy: settings.nextCardProgressCopy,
            titleText: settings.titleText,
            colours: [] as [],
            media: [] as [],
            templates: [] as []
        } as PerkVisualisation,
        points: {
            initial,
            max: 100000,
            min: 0,
            step: 1,
            redemption: settings.stampsPerCard ? Number(settings.stampsPerCard) : 6
        }
    };
};

export const generateCreatePerkBody = (
    general: PerkGeneralFormData,
    settings: PerkSettingsFormData,
    redemption: AwardRedemptionFormData
) => {
    let perkBody = {};
    switch (settings.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreatePseudoCurPerkBody(general, settings)
            };
            break;
        case AwardTemplate.STAMP_CARD:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreateStampCardPerkBody(general, settings)
            };
            break;
        case AwardTemplate.CLUB:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreateClubPerkBody(general, settings)
            };
            break;
        default:
            perkBody = {
                ...perkBody,
                token: settings.redemption,
                ...generateCreateAdHocPerkBody(general, settings)
            };

            break;
    }

    if (redemption) {
        perkBody = { ...perkBody, ...generateRedemptionFields(redemption) };
    }

    return perkBody;
};

export const generateUpdatePseudoCurPerkBody = (updateData: PerkGeneralFormData & PerkSettingsFormData) => {
    const opacity = getPerkOpacity(updateData.backgroundColor);
    return {
        terms: updateData.terms,
        priority: updateData.order,
        title: updateData.title,
        internalTitle: updateData.internalTitle,
        locationIds: updateData.locationIds?.length > 0 ? updateData.locationIds : null,
        isGlobal: updateData.global,
        enabled: updateData.enabled,
        visualisation: {
            subtitleTwoText: 'Worth {{currency}}',
            textAlignment: updateData.align,
            description: updateData.description,
            subtitleText: '{{available}} point{{s}}',
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(updateData.backgroundColor, true),
            textColour: rgbaToHex(updateData.textColor, true),
            backgroundImageUrl: updateData.image ?? undefined,
            verticalAlignment: updateData.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: updateData.titleText
        } as PerkVisualisation
    };
};

const generateUpdateStampCardPerkBody = (updateData: PerkGeneralFormData & PerkSettingsFormData) => {
    const opacity = getPerkOpacity(updateData.backgroundColor);
    return {
        terms: updateData.terms,
        priority: updateData.order,
        title: updateData.title,
        internalTitle: updateData.internalTitle,
        locationIds: updateData.locationIds?.length > 0 ? updateData.locationIds : null,
        isGlobal: updateData.global,
        enabled: updateData.enabled,
        visualisation: {
            textAlignment: updateData.align,
            randomiseStampPositions: updateData.randomizeStampCard,
            stampImageUrl: updateData.stampImageUrl,
            stampColour: rgbaToHex(updateData.stampColor, true),
            description: updateData.description,
            subtitleText: updateData.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(updateData.backgroundColor, true),
            textColour: rgbaToHex(updateData.textColor, true),
            backgroundImageUrl: updateData.image ?? undefined,
            verticalAlignment: updateData.valign,
            availableCardCopy: updateData.availableCardCopy,
            nextCardProgressCopy: updateData.nextCardProgressCopy,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: updateData.titleText
        } as PerkVisualisation
    };
};

const generateUpdateAdHocPerkBody = (updateData: PerkGeneralFormData & PerkSettingsFormData) => {
    const opacity = getPerkOpacity(updateData.backgroundColor);
    return {
        terms: updateData.terms,
        priority: updateData.order,
        title: updateData.title,
        internalTitle: updateData.internalTitle,
        locationIds: updateData.locationIds?.length > 0 ? updateData.locationIds : null,
        isGlobal: updateData.global,
        enabled: updateData.enabled,
        visualisation: {
            subtitleTwoText: updateData.subtitle2,
            textAlignment: updateData.align,
            description: updateData.description,
            subtitleText: updateData.subtitle,
            backgroundOverlayOpacity: opacity,
            backgroundOverlayColour: rgbaToHex(updateData.backgroundColor, true),
            textColour: rgbaToHex(updateData.textColor, true),
            backgroundImageUrl: updateData.image ?? undefined,
            verticalAlignment: updateData.valign,
            colours: [] as [],
            media: [] as [],
            templates: [] as [],
            titleText: updateData.titleText
        } as PerkVisualisation
    };
};

export const generateEditPerkBody = (
    updateData: PerkGeneralFormData & PerkSettingsFormData & AwardRedemptionFormData
) => {
    let perkBody: Partial<Perk> = {};
    switch (updateData.perkType) {
        case AwardTemplate.PSEUDO_CURRENCY:
            perkBody = { ...perkBody, ...generateUpdatePseudoCurPerkBody(updateData) };
            break;
        case AwardTemplate.STAMP_CARD:
            perkBody = { ...perkBody, ...generateUpdateStampCardPerkBody(updateData) };
            break;
        default:
            perkBody = { ...perkBody, ...generateUpdateAdHocPerkBody(updateData) };
            break;
    }

    perkBody = { ...perkBody, ...generateRedemptionFields(updateData) };
    if (isDefined(updateData.redemption)) {
        perkBody.token = updateData.redemption;
    }

    return perkBody;
};

export const generatePerkGrantImpactBody = (audienceId: string, perkId: string) => {
    const grantPerkFromImpactBody = {
        matchType: 'TYPE',
        conditions: [
            {
                subject: 'type',
                object: 'MEMBER_ADDED',
                predicate: 'EQUAL'
            },
            {
                subject: 'metadata.audienceId',
                object: [audienceId],
                predicate: 'IN'
            }
        ],
        effect: {
            controller: 'pointPerk',
            method: 'grantPerkFromImpact',
            impact: {
                evaluatorType: 'BUILTIN_FUNCTION',
                evaluatorName: 'passThru',
                parameters: {
                    perkId
                }
            }
        }
    };

    return grantPerkFromImpactBody;
};

export const generatePerkGrantRevokeBody = (audienceId: string, perkId: string) => {
    const revokeFromImpactBody = {
        matchType: 'TYPE',
        conditions: [
            {
                subject: 'type',
                object: 'MEMBER_REMOVED',
                predicate: 'EQUAL'
            },
            {
                subject: 'metadata.audienceId',
                object: [audienceId],
                predicate: 'IN'
            }
        ],
        effect: {
            controller: 'pointAward',
            method: 'revokeFromImpact',
            impact: {
                evaluatorType: 'BUILTIN_FUNCTION',
                evaluatorName: 'passThru',
                parameters: {
                    perkId
                }
            }
        }
    };

    return revokeFromImpactBody;
};

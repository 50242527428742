import React from 'react';
import { useHistory, useParams } from 'react-router';
import { MainLayout } from 'layouts/MainLayout';
import { segmentsApi } from 'components/audience/segmentsApi';
import { AudienceView } from 'components/audience/SegmentView';
import { MARKETING_AUDIENCES } from 'config/routes';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';
import { Throbber } from 'ui/Throbber';

const AudienceViewPage = () => {
    const [segment, setSegment] = React.useState(null);
    const history = useHistory();
    const { audienceId: segmentId } = useParams<{ audienceId: string }>();

    React.useEffect(() => {
        async function load() {
            try {
                if (!isString(segmentId)) {
                    logger.error(`Expected segmentId but got ${segmentId}`);
                    history.push(MARKETING_AUDIENCES);
                }
                const segmentResponse = await segmentsApi.get(segmentId);
                if (!segmentResponse.ok) {
                    logger.error('Expected segmentResponse 200 but got ', segmentResponse);
                    history.push(MARKETING_AUDIENCES);
                }
                setSegment(segmentResponse.body);
            } catch (error) {
                logger.error('Audience view page error', error);
                history.push(MARKETING_AUDIENCES);
            }
        }
        load();
    }, [history, segmentId]);

    if (!segment) {
        return (
            <MainLayout
                breadcrumbs={[{ label: 'Audiences', url: MARKETING_AUDIENCES }]}
                pageName={'Audience Details'}
                pageDescription="View more information on your audience"
            >
                <Throbber />
            </MainLayout>
        );
    }

    return (
        <MainLayout
            breadcrumbs={[{ label: 'Audiences', url: MARKETING_AUDIENCES }]}
            pageName={segment.title || 'Audience Details'}
            pageDescription="View more information on your audience"
        >
            <AudienceView segment={segment} />
        </MainLayout>
    );
};

export default AudienceViewPage;

import React from 'react';
import { Button, Divider, Fade, ListItem, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { Link } from './Link';

// TODO: tmp solution, material-ui haven't proper types at the moment
const MuiButton = (props: any) => <Button {...props} />;

const PREFIX = 'AppDrawerNavItem';

const classes = {
    label: `${PREFIX}-label`,
    item: `${PREFIX}-item`,
    itemLeaf: `${PREFIX}-itemLeaf`,
    typography: `${PREFIX}-typography`,
    typographyRoot: `${PREFIX}-typographyRoot`,
    button: `${PREFIX}-button`,
    buttonLeaf: `${PREFIX}-buttonLeaf`,
    depthItemLeaf: `${PREFIX}-depthItemLeaf`,
    activeButtonLeaf: `${PREFIX}-activeButtonLeaf`,
    buttonLeafIcon: `${PREFIX}-buttonLeafIcon`,
    active: `${PREFIX}-active`
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
    [`& .${classes.label}`]: {
        width: '100%'
    },
    [`&.${classes.item}`]: {
        display: 'block',
        width: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    [`&.${classes.itemLeaf}`]: {
        width: '100%',
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    [`&.${classes.depthItemLeaf}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    [`& .${classes.typography}`]: {
        marginLeft: theme.spacing(1.5)
    },
    [`& .${classes.typographyRoot}`]: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(1, 2)
    },
    [`& .${classes.button}`]: {
        letterSpacing: 0,
        justifyContent: 'flex-start',
        textTransform: 'none',
        width: '100%'
    },
    [`& .${classes.buttonLeaf}`]: {
        letterSpacing: 0,
        justifyContent: 'flex-start',
        textTransform: 'none',
        width: '100%',
        padding: '0',
        height: theme.spacing(5),
        borderRadius: '0',
        backgroundColor: 'inherit',
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: 'rgba(92, 92, 92, 0.04)'
        }
    },
    [`& .${classes.activeButtonLeaf}`]: {
        backgroundColor: 'rgba(92,92,92,0.16)',
        color: theme.palette.primary.main
    },
    [`& .${classes.buttonLeafIcon}`]: {
        color: 'inherit',
        fontSize: '20px'
    },
    [`& .${classes.active}`]: {
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));

interface AppDrawerNavItemProps {
    depth?: number;
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    title?: string;
    Icon?: React.ComponentClass<{ className: string }>;
    children?: React.ReactElement | null;
    key: any;
    isActive: boolean;
    open?: boolean;
}

export function AppDrawerNavItem(props: AppDrawerNavItemProps) {
    const { children, depth, href, Icon, open, onClick, title, isActive } = props;

    const style: React.CSSProperties = {
        paddingLeft: 16
    };

    if (href) {
        return (
            <React.Fragment>
                <StyledListItem
                    className={clsx(classes.itemLeaf, {
                        [classes.depthItemLeaf]: href && depth === 0
                    })}
                    disableGutters
                >
                    <MuiButton
                        component={Link}
                        naked
                        href={href}
                        color={isActive ? 'primary' : 'secondary'}
                        activeClassName={classes.active}
                        className={clsx(classes.buttonLeaf, `depth-${depth}`, {
                            [classes.activeButtonLeaf]: isActive
                        })}
                        classes={{ label: classes.label }}
                        fullWidth
                        onClick={onClick}
                        style={style}
                    >
                        <Icon className={classes.buttonLeafIcon} />
                        <Typography variant="body2" className={classes.typography}>
                            {title}
                        </Typography>
                    </MuiButton>
                </StyledListItem>
                {depth === 0 && <Divider />}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <StyledListItem className={classes.item} disableGutters>
                {title && open && (
                    <Fade in={open}>
                        <Typography variant="body2" className={classes.typographyRoot}>
                            {title}
                        </Typography>
                    </Fade>
                )}
                {children}
            </StyledListItem>
            <Divider />
        </React.Fragment>
    );
}

import React from 'react';
import { Grid } from '@mui/material';
import { Perk } from 'components/loyalty/models/PerkModel';
import { perkApi } from 'components/loyalty/PerkApi';
import { CustomersAudienceCard } from './cards/CustomersAudienceCard';
import { CustomersAwardsCard } from './cards/CustomersAwardsCard';
import { CustomersFinancialCard } from './cards/CustomersFinancialCard';
import { CustomersGeneralCard } from './cards/CustomersGeneralCard';
import { CustomersTimelineCard } from './cards/CustomersTimelineCard';
import { Award } from './models/Award';
import { Customer } from './models/Customer';
import { IUserDeletionSetting } from '@pepperhq/location-sdk';

interface CustomerViewProps {
    customer: Customer;
    awards: Award[];
    currency: string;
    paymentMode: 'PAYG' | string;
    currencySymbol: string;
    uniqueDeviceVerification: boolean;
    userDeletionSetting: Partial<IUserDeletionSetting>;
    loadCustomer: () => void;
}

export const CustomerView: React.FC<CustomerViewProps> = props => {
    const {
        awards,
        currency,
        paymentMode,
        currencySymbol,
        userDeletionSetting,
        uniqueDeviceVerification,
        loadCustomer
    } = props;
    const [loading, setLoading] = React.useState(false);
    const [customer, setCustomer] = React.useState(props.customer);
    const [perks, setPerks] = React.useState(null as Perk[]);
    React.useEffect(() => {
        const getPerks = async () => {
            setLoading(true);
            const perksResult = await perkApi.getList();
            if (!perksResult.ok) {
                setPerks(null);
            } else {
                setPerks(perksResult.body.perks);
            }
            setLoading(false);
        };
        getPerks();
    }, []);
    const handleChange = React.useCallback((data: Partial<Customer>) => {
        setCustomer(currentCustomer => ({ ...currentCustomer, ...data }));
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <CustomersGeneralCard
                    customer={customer}
                    onChange={handleChange}
                    uniqueDeviceVerification={uniqueDeviceVerification}
                    userDeletionSetting={userDeletionSetting}
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomersFinancialCard
                            customer={customer}
                            currency={currency}
                            paymentMode={paymentMode}
                            currencySymbol={currencySymbol}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomersAwardsCard
                            isLoading={loading}
                            perks={perks}
                            awards={awards}
                            customer={customer}
                            loadCustomer={loadCustomer}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomersAudienceCard customer={customer} onChange={handleChange} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <CustomersTimelineCard
                    perks={perks}
                    title="History"
                    customerId={customer._id}
                    paymentMode={paymentMode}
                />
            </Grid>
        </Grid>
    );
};

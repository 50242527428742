import { getAuthToken } from 'components/auth/getAuthToken';
import logger from './logger';
const INTERVAL = 500;
const TIMEOUT = 20000;

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type RequestOptions = {
    body?: any;
    skipResponseBody?: boolean;
    headers?: any;
    skipDefaultHeaders?: boolean;
    credentials?: RequestCredentials;
    skipExpirationCheck?: boolean;
    skipStringify?: boolean;
    queryParameters?: QueryParameter[];
    url?: string;
};

export interface QueryParameter {
    key: string;
    value: string | number | boolean;
}

export interface PageRequest {
    limit: number;
    startKey?: string;
}

interface HttpError {
    message?: string;
}

export type HttpClientResponse<T> = Promise<HttpClientResponseContent<T>>;

export interface HttpClientResponseContent<T> {
    ok: boolean;
    statusCode: number;
    body: T & HttpError;
}

export interface PageObject {
    count: number;
    limit: number;
    nextKey: number;
    startKey: number;
}

const getBody = (options?: RequestOptions) => {
    if (!options || !options.body) {
        return null;
    }
    if (options.skipStringify) {
        return options.body;
    }
    return JSON.stringify(options.body);
};

const pepperDefaultHeaders = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json'
};

export class HttpClient {
    public refreshToken: () => void;
    private expiration: Date;
    constructor(private defaultHeaders: Record<string, string | number>, private token?: string) {}
    get isToken() {
        return !!this.token;
    }
    private get isTokenExpired() {
        return this.expiration && Number(this.expiration) - Number(new Date()) < 0;
    }
    updateToken = (token: string, expiration?: Date) => {
        this.token = token;
        if (expiration) {
            this.expiration = expiration;
        }
    };
    get = (url: string, options?: RequestOptions) => this.makeApiRequest(url, 'GET', options);
    post = (url: string, options?: RequestOptions) => this.makeApiRequest(url, 'POST', options);
    put = (url: string, options?: RequestOptions) => this.makeApiRequest(url, 'PUT', options);
    delete = (url: string, options?: RequestOptions) => this.makeApiRequest(url, 'DELETE', options);
    getToken = () => (!this.isTokenExpired ? this.token : undefined);
    private waitForExpirationUpdate = () =>
        new Promise((resolve, reject) => {
            let count = 0;
            const interval = setInterval(() => {
                if (count > TIMEOUT / INTERVAL) {
                    clearInterval(interval);
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject('Timeout error while refreshing a token');
                }
                if (!this.isTokenExpired) {
                    clearInterval(interval);
                    resolve(true);
                }
                count = count + 1;
            }, INTERVAL);
        });

    private assembleUrlWithQueryParameters = (url: string, queryParameters: QueryParameter[]): string => {
        if (queryParameters && queryParameters.length) {
            const assembledQueryParameters = queryParameters
                .map(queryParameter => `${queryParameter.key}=${encodeURIComponent(queryParameter.value)}`)
                .join('&');
            return url + '?' + assembledQueryParameters;
        }
        return url;
    };

    // TODO: require refactoring
    private async makeApiRequest(url: string, method: HttpMethod, options?: RequestOptions) {
        if (!this.token) {
            const authToken = await getAuthToken();
            this.updateToken(authToken);
        }
        if (!options?.skipExpirationCheck && this.isTokenExpired) {
            if (this.refreshToken) {
                this.refreshToken();
            }
            await this.waitForExpirationUpdate();
        }
        const baseHeaders =
            options && options.skipDefaultHeaders
                ? {}
                : {
                      ...this.defaultHeaders,
                      Authorization: `Bearer ${this.token}`
                  };
        const headers = { ...baseHeaders, ...(options ? options.headers : {}) };
        Object.entries(headers).forEach(([key, value]) => {
            if (!value) {
                delete headers[key];
            }
        });
        const body = getBody(options);
        const assembleUrlQueryParameters = options && options.queryParameters ? options.queryParameters : [];
        const assembledUrl = this.assembleUrlWithQueryParameters(url, assembleUrlQueryParameters);
        const isomorphicUnfetchOptions: RequestOptions & { method: HttpMethod } = { method, headers, body };
        if (options && options.credentials) {
            isomorphicUnfetchOptions.credentials = options.credentials;
        }
        const response: Response = await fetch(assembledUrl, isomorphicUnfetchOptions);
        let responseBody = null;
        // we need to get an error message if the response is not ok
        if (!response.ok || !options || !options.skipResponseBody) {
            responseBody = await response.json();
            if (!response.ok) {
                logger.error('Failed to fetch', {
                    request: { url, method, headers, body },
                    response: { ok: response.ok, statusCode: response.status, body: responseBody }
                });
            }
        }
        return { ok: response.ok, statusCode: response.status, body: responseBody };
    }
}

export class Crud {
    constructor(
        public httpClient: HttpClient,
        public api?: string,
        public readonly instanceName?: string,
        public readonly headers?: Record<string, string | number>
    ) {}
    getList(options?: RequestOptions) {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.get(`${this.api}/${this.instanceName}${options?.url || ''}`, {
            ...options,
            headers
        });
    }
    get(id: string, options?: RequestOptions) {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.get(
            `${this.api}/${this.instanceName}${options?.url || ''}/${encodeURIComponent(id)}`,
            {
                ...options,
                headers
            }
        );
    }
    create(options: RequestOptions) {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.post(`${this.api}/${this.instanceName}${options?.url || ''}`, {
            ...options,
            headers
        });
    }
    update(id: string, options: RequestOptions) {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.put(
            `${this.api}/${this.instanceName}${options?.url || ''}/${encodeURIComponent(id)}`,
            {
                ...options,
                headers
            }
        );
    }
    delete(id: string, options?: RequestOptions) {
        const headers = options && options.headers ? { ...this.headers, ...options.headers } : this.headers;
        return this.httpClient.delete(
            `${this.api}/${this.instanceName}${options?.url || ''}/${encodeURIComponent(id)}`,
            {
                ...options,
                headers
            }
        );
    }
}

export const httpClient = new HttpClient(pepperDefaultHeaders);

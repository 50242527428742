import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Grid, Typography, Button, FormHelperText, Box } from '@mui/material';
import { Form } from 'formik';
import { MuiForm } from 'lib/Form';
import { AutocompleteFormField } from 'lib/form/fields/AutocompleteFormField';
import { getAllAudiences } from 'store/audiences/audiencesActions';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import { Row } from 'ui/Flex';
import * as Yup from 'yup';
import { LoadingButton } from 'ui/buttons/LoadingButton';

interface PerkClubFormProps {
    open?: boolean;
    label?: string;
    clickPrevious?: () => void;
    submit: (values: PerkAddEffectFormData) => void;
    isLoading: boolean;
    onClose: () => void;
}

export interface PerkAddEffectFormData {
    audienceId: string;
    grantPerkFromImpact: boolean;
    revokeFromImpact: boolean;
    validateCheckBoxes: string;
}

const initialValues = {
    audienceId: '',
    grantPerkFromImpact: false,
    revokeFromImpact: false,
    validateCheckBoxes: ''
};

const validationSchema = Yup.object().shape({
    grantPerkFromImpact: Yup.boolean(),
    revokeFromImpact: Yup.boolean(),
    validateCheckBoxes: Yup.string().when(['revokeFromImpact', 'grantPerkFromImpact'], {
        is: (revokeFromImpact: boolean, grantPerkFromImpact: boolean) =>
            revokeFromImpact === false && grantPerkFromImpact === false,
        then: Yup.string().required('At least one checkbox should be checked.')
    }),
    audienceId: Yup.string().required('This field is required.')
});

export const PerkClubForm: React.FC<PerkClubFormProps> = ({ open, submit, onClose, isLoading }) => {
    const dispatch = useDispatch();
    const { customerSegments } = useSelector((state: ApplicationState) => state.audiences);

    const audiencesOptions = React.useMemo(
        () => customerSegments.map(item => ({ value: item._id, label: item.title })),
        [customerSegments]
    );

    React.useEffect(() => {
        getAllAudiences()(dispatch);
    }, [dispatch]);

    return (
        <MuiForm onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ submitForm, errors }) => {
                if (!open) {
                    return null;
                }
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" color="secondary">
                                    5. Add Triggers
                                </Typography>
                                <Typography variant="body1">
                                    Your club perk has been successfully created. To configure, select an
                                    audience to associate with this perk.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <AutocompleteFormField
                                    name="audienceId"
                                    label="Select Audience"
                                    options={audiencesOptions}
                                />
                            </Grid>

                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Typography variant="body1">
                                    Create trigger to grant my associated perk when users become an audience
                                    member
                                </Typography>
                                <Box marginRight={-1}>
                                    <CheckboxFormField name="grantPerkFromImpact" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Typography variant="body1">
                                    Create trigger to revoke my associated perk when users are removed from an
                                    audience
                                </Typography>
                                <Box marginRight={-1}>
                                    <CheckboxFormField name="revokeFromImpact" />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.validateCheckBoxes && (
                                    <FormHelperText error>{errors.validateCheckBoxes}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Row align="flex-end" flex={1}>
                                    <Row gutter>
                                        <Button color="primary" onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            onClick={submitForm}
                                            color="primary"
                                            variant="contained"
                                            loading={isLoading}
                                            disabled={isLoading}
                                        >
                                            Save & Close
                                        </LoadingButton>
                                    </Row>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </MuiForm>
    );
};

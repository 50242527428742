import React from 'react';
import { useDispatch } from 'react-redux';
import { Notification } from 'components/notification/models/Notification';
import { notificationApi } from 'components/notification/notificationApi';
import {
    MESSAGE_CUSTOMER_CONTACT_ERROR,
    MESSAGE_CUSTOMER_CONTACT_SKIPPED,
    MESSAGE_CUSTOMER_CONTACT_SUCCESS
} from 'config/messages';
import { LegacyForm } from 'lib/LegacyForm';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { AutoForm } from 'ui/form/AutoForm';
import { createCustomerContactForm } from '../forms/createCustomerContactForm';
import { Customer } from '../models/Customer';

interface CustomerContactFormProps {
    onClose: () => void;
    customer: Customer;
}

export const CustomerContactForm: React.FC<CustomerContactFormProps> = props => {
    const { onClose, customer } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();
    const checkForNotificationResult = async (notification: Notification<string>) => {
        try {
            // Wait for operation to complete in some mannar
            const result = await notificationApi.pollNotification({ notification });
            setIsLoading(false);
            if (result.skipped) {
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CONTACT_SKIPPED, { variant: 'warning' }));
                return onClose();
            }
            if (result.sent) {
                dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CONTACT_SUCCESS, { variant: 'success' }));
                return onClose();
            }
            return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CONTACT_ERROR, { variant: 'error' }));
        } catch (err) {
            setIsLoading(false);
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_CONTACT_ERROR, { variant: 'error' }));
        }
    };
    const handleSubmit = async ({ message }: { message: string }) => {
        setIsLoading(true);
        const type = 'alert';
        const isProcessingMessage = false;
        const value = '';
        const result = await notificationApi.send(customer._id, {
            body: { message, value, type, isProcessingMessage }
        });
        checkForNotificationResult(result.body);
    };
    const form = new LegacyForm(createCustomerContactForm(), handleSubmit);
    return (
        <AutoForm
            subtitle={`From here, you can send a message to ${customer.firstName}. The message will appear as a push notification on their phone.`}
            submitLabel="Send"
            isLoading={isLoading}
            title={`Contact ${customer.firstName}`}
            form={form}
            onClose={onClose}
        />
    );
};

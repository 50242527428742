import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { OpeningHoursInput } from 'lib/OpeningHoursInput';

interface OpeningHoursFormFieldProps {
    label?: React.ReactNode;
    description?: string;
}

export const OpeningHoursFormField: React.SFC<FieldAttributes<OpeningHoursFormFieldProps>> = ({
    label,
    placeholder: _placeholder,
    description,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <OpeningHoursFormFieldComponent label={label} description={description} {...childProps} />
        )}
    </Field>
);

const OpeningHoursFormFieldComponent: React.SFC<FieldProps & OpeningHoursFormFieldProps> = ({
    field,
    meta,
    label,
    description
}) => {
    const isError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        (value: string) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    return (
        <FormControl fullWidth error={meta.touched && !!meta.error}>
            <OpeningHoursInput label={label} value={field.value} onChange={handleChange} />
            {(isError || description) && (
                <FormHelperText error={isError}>{isError ? meta.error : description}</FormHelperText>
            )}
        </FormControl>
    );
};

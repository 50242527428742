import { Box, Stack, TablePagination } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { ORDERS_VIEW } from 'config/routes';
import { MainLayout } from 'layouts/MainLayout';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React from 'react';
import { MuiTheme } from 'config/theme';
import { useOrdersFilter } from 'components/order/hooks/useOrdersFilter';
import { ORDERS_PAGE_LIMIT, usePepperOrders } from 'components/order/hooks/usePepperOrders';
import { GridLinkCell } from 'lib/MuiGrid/GridLinkCell';
import { OrdersFilters } from 'components/order/OrdersFilters';
import { SelectGridColumn, SelectTitleColumn } from 'lib/MuiGrid/Columns';
import { OrderScenarioLabels } from 'components/order/model/Order';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { renderOrderStatusCell } from 'components/order/order-details/OrderStatusCell';

// Keep the version updated
const ORDER_GRID_STORAGE_NAME = `${GridStorageName.Orders}-1`;

export const OrdersPage: React.FC = () => {
    const [filter, onFilterChange] = useOrdersFilter();
    const { formattedOrders, page, onPageChange, loading, count } = usePepperOrders(filter);
    const { locationIdToLocationName, isLoading: locationsLoading } = useLocationHelpers();
    const renderViewCell = React.useCallback(
        (params: GridCellParams) => <GridLinkCell id={params.value} param="orderId" path={ORDERS_VIEW} />,
        []
    );
    const dateValueFormatter = React.useCallback<GridColDef['valueGetter']>(
        ({ value }) => new Date(value).toLocaleString(),
        []
    );
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Order Number',
                field: 'id',
                disableReorder: true,
                disableExport: true,
                sortable: false,
                width: 160,
                renderCell: renderViewCell
            },
            {
                headerName: 'Scenario',
                field: 'scenario',
                sortable: false,
                width: 130,
                ...SelectGridColumn(OrderScenarioLabels)
            },
            {
                headerName: 'Date Created',
                field: 'createdAt',
                sortable: false,
                type: 'dateTime',
                width: 180,
                valueGetter: dateValueFormatter
            },
            {
                headerName: 'Status',
                field: 'isOpen',
                sortable: false,
                width: 90,
                renderCell: renderOrderStatusCell
            },
            {
                headerName: 'Location',
                field: 'locationId',
                sortable: false,
                width: 250,
                ...SelectTitleColumn(locationIdToLocationName)
            },
            {
                headerName: 'Table',
                field: 'deliveryLocation',
                width: 90,
                sortable: false,
                align: 'right'
            },
            {
                headerName: 'Collection Time',
                field: 'timeSlot',
                sortable: false,
                width: 300
            },
            {
                headerName: 'Total',
                field: 'total',
                width: 110,
                sortable: false,
                align: 'right'
            },
            {
                headerName: 'Items',
                field: 'items',
                sortable: false,
                width: 250
            },
            {
                headerName: 'Payments',
                field: 'payments',
                sortable: false,
                width: 200,
                align: 'right'
            },
            {
                headerName: 'Customers',
                field: 'users',
                sortable: false,
                width: 300
            }
        ],
        [dateValueFormatter, locationIdToLocationName, renderViewCell]
    );
    const handlePageChange = React.useCallback(
        (_: never, page: number) => {
            onPageChange(page);
        },
        [onPageChange]
    );

    const Pagination = React.useCallback(
        () => (
            <TablePagination
                count={count}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={ORDERS_PAGE_LIMIT}
                rowsPerPageOptions={[ORDERS_PAGE_LIMIT]}
                component="div"
                sx={{
                    '&:last-child': {
                        paddingRight: MuiTheme.spacing(6)
                    }
                }}
            />
        ),
        [count, handlePageChange, page]
    );
    const NoRowsOverlay = React.useCallback(
        () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
                No orders found
            </Stack>
        ),
        []
    );
    return (
        <MainLayout pageName="Orders" pageDescription="View and interact with orders.">
            <Box paddingBottom={18} height="100%">
                <Box paddingBottom={1}>
                    <OrdersFilters filter={filter} onChange={onFilterChange} />
                </Box>
                {!count && (
                    <MuiGrid
                        rows={[]}
                        columns={columns}
                        rowCount={0}
                        pagination
                        disableColumnFilter
                        loading={loading || locationsLoading}
                        components={{
                            NoRowsOverlay
                        }}
                    />
                )}
                {!!count && (
                    <MuiGrid
                        rows={formattedOrders}
                        columns={columns}
                        disableMultipleSelection
                        hideFooter={false}
                        rowCount={count}
                        pagination
                        storageName={ORDER_GRID_STORAGE_NAME}
                        disableVirtualization
                        disableColumnFilter
                        paginationMode="server"
                        loading={loading || locationsLoading}
                        components={{
                            Pagination
                        }}
                    />
                )}
            </Box>
        </MainLayout>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { bindActionCreators, Dispatch } from 'redux';
import { removeSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';

export interface MuiNotificationOptions {
    variant?: 'warning' | 'success' | 'error' | 'info' | 'default';
    persist?: boolean;
    autoHideDuration?: number;
}

export interface MuiNotification {
    key?: string;
    message: string;
    options?: MuiNotificationOptions;
}

export interface MuiNotifierProps extends WithSnackbarProps {
    notifications: MuiNotification[];
    enqueueSnackbar: (message: string, options?: MuiNotificationOptions) => React.ReactText;
    removeSnackbar: (key: string) => void;
}

class Notifier extends React.Component<MuiNotifierProps> {
    displayed: string[] = [];

    storeDisplayed = (id: string) => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate({ notifications: newSnacks = [] }: { notifications: MuiNotification[] }) {
        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (const i of newSnacks) {
            if (notExists) {
                continue;
            }
            notExists = notExists || !currentSnacks.filter(({ key }) => i.key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props;

        notifications.forEach(notification => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(notification.key)) {
                return;
            }
            // Display snackbar using notistack
            this.props.enqueueSnackbar(notification.message, notification.options);
            // Keep track of snackbars that we've displayed
            this.storeDisplayed(notification.key);
            // Dispatch action to remove snackbar from redux store
            this.props.removeSnackbar(notification.key);
        });
    }

    render(): null {
        return null;
    }
}

const mapStateToProps = ({ notifications }: ApplicationState) => ({
    notifications: notifications.notifications
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ removeSnackbar }, dispatch);

export const MuiNotifier = connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));

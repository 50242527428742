import React from 'react';
import { Card, CardActions, CardContent, Typography, styled } from '@mui/material';

import { green } from '@mui/material/colors';
import CheckCircle from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';
import { ContentLayout } from './models/ContentLayout';

const PREFIX = 'AnchorLayoutCard';

const classes = {
    content: `${PREFIX}-content`,
    draftLayoutCard: `${PREFIX}-draftLayoutCard`,
    activeLayout: `${PREFIX}-activeLayout`,
    activeLayoutVisible: `${PREFIX}-activeLayoutVisible`,
    layoutStatusText: `${PREFIX}-layoutStatusText`,
    selectedCard: `${PREFIX}-selectedCard`,
    selectedCheck: `${PREFIX}-selectedCheck`
};

const StyledCard = styled(Card)(({ theme }) => ({
    boxSizing: 'border-box',
    cursor: 'pointer',
    position: 'relative',
    height: 'intrinsic',
    transition: '0.15s all ease-in',
    '&:hover': {
        backgroundColor: 'rgba(92,92,92,0.24)'
    },
    [`& .${classes.content}`]: {
        minHeight: theme.spacing(13.75)
    },
    [`&.${classes.draftLayoutCard}`]: {
        minHeight: theme.spacing(18)
    },
    [`& .${classes.activeLayout}`]: {
        backgroundColor: green['A700'],
        visibility: 'hidden'
    },
    [`& .${classes.activeLayoutVisible}`]: {
        visibility: 'visible'
    },
    [`& .${classes.layoutStatusText}`]: {
        textAlign: 'center',
        width: '100%',
        color: '#fff'
    },
    [`&.${classes.selectedCard}`]: {
        backgroundColor: 'rgba(92,92,92,0.24)'
    },
    [`& .${classes.selectedCheck}`]: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: '#fff',
        zIndex: 1000
    }
}));

interface AnchorLayoutCardProps {
    active?: boolean;
    selected?: boolean;
    layout: ContentLayout;
    handleLayoutClick: (layout: ContentLayout) => void;
}

const AnchorLayoutCard = (props: AnchorLayoutCardProps) => {
    const { active, selected, layout, handleLayoutClick } = props;

    const handleClick = React.useCallback(() => handleLayoutClick(layout), [layout, handleLayoutClick]);

    return (
        <StyledCard
            className={clsx({
                [classes.selectedCard]: selected,
                [classes.draftLayoutCard]: active
            })}
            onClick={handleClick}
        >
            <CardContent className={classes.content}>
                {!!selected && <CheckCircle className={classes.selectedCheck} />}
                <Typography variant="h6">
                    {layout.anchor.metadata ? layout.anchor.metadata.description : 'An Anchor Layout'}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    ID: {layout.id}
                </Typography>
            </CardContent>
            <CardActions
                className={clsx(classes.activeLayout, {
                    [classes.activeLayoutVisible]: active
                })}
            >
                <Typography className={classes.layoutStatusText}>ACTIVE</Typography>
            </CardActions>
        </StyledCard>
    );
};

export default AnchorLayoutCard;

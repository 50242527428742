import React from 'react';
import { Button, Grid } from '@mui/material';

import { ActionOrderCompletedContent } from '../ActionOrderCompletedContent';
import {
    isActionOrderCompletedMetadataOC2,
    isOrderCompletedOC1Action,
    isOrderCompletedOC2Action
} from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';
import { useHistory } from 'react-router-dom';
import { isDefined } from 'lib/typeguards';
import { ORDERS_VIEW } from 'config/routes';

export const ActionCardOrderCompleted: React.FC<ActionCardModalComponentProps> = ({ action }) => {
    const history = useHistory();
    const orderLink = React.useMemo(
        () =>
            isActionOrderCompletedMetadataOC2(action.metadata) && isDefined(action.metadata?.order?.id)
                ? ORDERS_VIEW.replace(':orderId', action.metadata.order.id)
                : undefined,
        [action.metadata]
    );
    const handleViewClick = React.useCallback(() => {
        history.push(orderLink);
    }, [history, orderLink]);

    const scenario = React.useMemo(() => {
        if (!isOrderCompletedOC1Action(action) && !isOrderCompletedOC2Action(action)) {
            return null;
        }
        return isActionOrderCompletedMetadataOC2(action.metadata)
            ? action.metadata?.order?.scenario
            : action.metadata?.scenario;
    }, [action]);

    if (!isOrderCompletedOC1Action(action) && !isOrderCompletedOC2Action(action)) {
        return <ActionCardBase action={action} broken />;
    }

    if (!scenario) {
        return null;
    }

    return (
        <ActionCardBase
            buttons={
                <Button color="primary" variant="outlined" onClick={handleViewClick}>
                    View & Refund
                </Button>
            }
            action={action}
            size="double"
        >
            <Grid container spacing={1}>
                <ActionOrderCompletedContent
                    date={getActionDate(action)}
                    value={action.metadata.value.amount}
                    currency={action.metadata.value.currency}
                    locationTitle={action.context.location && action.context.location.title}
                    scenario={scenario}
                />
            </Grid>
        </ActionCardBase>
    );
};

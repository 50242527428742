import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { SettingDisplay } from 'lib/SettingDisplay';

export const ToggleGiftCardSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplay label="Username">
                    <TextFormField name="toggleGiftCardUsername" />
                </SettingDisplay>
                <SettingDisplay label="Password">
                    <TextFormField name="toggleGiftCardPassword" />
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="EPOS Reference">
                <TextFormField name="toggleGiftCardEposReference" />
            </SettingDisplay>
        )}
    </>
);

import {
    Box,
    Button,
    Card,
    ClickAwayListener,
    List,
    ListItem,
    styled,
    TextField,
    Typography
} from '@mui/material';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { useInputState } from 'lib/hooks/useInputState';
import React from 'react';

interface IProps {
    locationIds: string[];
    onClose: () => void;
}

const PREFIX = 'MenuLocationsTooltip';

const classes = {
    content: `${PREFIX}-content`,
    actions: `${PREFIX}-actions`,
    searchBar: `${PREFIX}-searchBar`
};

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(100 / 8),
    maxHeight: theme.spacing(320 / 8),
    ...theme.typography.body1,
    [`& .${classes.content}`]: {
        flex: 1,
        overflow: 'auto',
        paddingLeft: theme.spacing(0.5)
    },
    [`& .${classes.actions}`]: {
        display: 'flex',
        padding: theme.spacing(1, 2),
        justifyContent: 'flex-end'
    },
    [`& .${classes.searchBar}`]: {
        padding: theme.spacing(1.5, 2, 1, 2)
    }
}));

export const MenuLocationsTooltip: React.FC<IProps> = ({ locationIds, onClose }) => {
    const { locationIdToLocationName } = useLocationHelpers();
    const [search, onSearchChange] = useInputState('');

    const locationOptions = React.useMemo(
        () =>
            locationIds
                .map(id => ({
                    label: locationIdToLocationName.get(id) || '',
                    value: id
                }))
                .filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())),
        [locationIdToLocationName, locationIds, search]
    );
    const renderLocationItem = React.useCallback(
        (option: { value: string; label: string }) => (
            <ListItem key={option.value}>
                {option.label} ({option.value})
            </ListItem>
        ),
        []
    );
    return (
        <ClickAwayListener onClickAway={onClose}>
            <StyledCard>
                <Box className={classes.searchBar}>
                    <TextField
                        variant="outlined"
                        value={search}
                        onChange={onSearchChange}
                        fullWidth
                        label="Search locations"
                        size="small"
                    />
                </Box>
                <Box className={classes.content}>
                    {locationOptions.length > 0 ? (
                        <List>{locationOptions.map(renderLocationItem)}</List>
                    ) : (
                        <Box paddingY={1.5} paddingX={2}>
                            <Typography variant="body1">{`No locations found for "${search}"`}</Typography>
                        </Box>
                    )}
                </Box>
                <Box className={classes.actions}>
                    <Button onClick={onClose} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </StyledCard>
        </ClickAwayListener>
    );
};

import { PepperPayAccount } from 'components/pepper-pay/model/PepperPay';
import { IStripeSettingAction, StripeSignUpStatus } from './stripeSettingsActions';
import { StripeSettingsActionTypes } from './stripeSettingsActionTypes';

export interface StripeSettingsState {
    loading: boolean;
    error?: string;
    locationId?: string;
    showLocationPicker?: boolean;
    account?: PepperPayAccount;
    status: StripeSignUpStatus;
}

export const initialState: StripeSettingsState = {
    loading: true,
    error: undefined,
    locationId: undefined,
    showLocationPicker: true,
    account: undefined,
    status: StripeSignUpStatus.UNKNOWN
};

export default function (
    state: StripeSettingsState = initialState,
    action: IStripeSettingAction
): StripeSettingsState {
    switch (action.type) {
        case StripeSettingsActionTypes.START_REQUEST:
            return { ...state, loading: true };
        case StripeSettingsActionTypes.END_REQUEST:
            return { ...state, loading: false };
        case StripeSettingsActionTypes.LOAD_ERROR:
            return { ...state, loading: false, error: action.message };
        case StripeSettingsActionTypes.SET_LOCATION:
            return { ...state, locationId: action.locationId };
        case StripeSettingsActionTypes.SET_PICKER_STATE:
            return { ...state, showLocationPicker: action.open };
        case StripeSettingsActionTypes.SET_SIGNUP_STATUS:
            return { ...state, status: action.status };
        case StripeSettingsActionTypes.SOFT_RESET:
            return { ...initialState, loading: state.loading };
        case StripeSettingsActionTypes.SET_ACCOUNT:
            return { ...state, account: action.account };
        default:
            return state;
    }
}

import * as React from 'react';
import { Box, Button, Chip, Typography, FormControl, FormHelperText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CalendarToday, ExpandMore } from '@mui/icons-material';
import { CustomersTimelineDateFilter } from 'components/customers/modals/CustomersTimelineDateFilter';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { formatDateRange } from 'lib/helpers';
import { Option } from 'lib/types';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { MuiModal } from 'ui/MuiModal';
import { getAllReportColumns } from './model';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { addDays, endOfDay, isSameDay, startOfDay } from 'date-fns';
import logger from 'lib/logger';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { pepperPayApi } from '../pepperPayApi';
import { reportCreationValues } from './consts';
import { PepperPayOperation } from '../model/PepperPay';
import { OperationStatus } from 'components/operation/models/OperationModel';
import {
    MESSAGE_REPORT_CREATION_CREATED,
    MESSAGE_REPORT_CREATION_ERROR,
    MESSAGE_REPORT_CREATION_SUCCESS
} from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { getOperationTypeAndFormat } from './utils';
import { EReportFormat, EReportType } from './enums';
import { GeneralReportForm } from './GeneralReportForm';
import { isString } from 'lib/typeguards';

interface NewReportModalProps {
    open: boolean;
    lastOperation?: PepperPayOperation | null;
    onClose: () => void;
    onCreate: (operation: PepperPayOperation) => void;
}

export interface CreateReportForm {
    appId: string;
    type: EReportType;
    format: EReportFormat;
    date: DateRangePickerValue;
    columns: Map<string, boolean>;
}

export interface IStripeAccount {
    name: string | null;
}

export const NewReportModal: React.FC<NewReportModalProps> = props => {
    const { open, onClose } = props;
    const dispatch = useDispatch();
    const [lastOperation, setLastOperation] = React.useState<PepperPayOperation | null>(null);
    const [anchorDateElement, setAnchorDateElement] = React.useState<HTMLElement>(null);
    const [appIds, setAppIds] = React.useState<Map<string, string>>(new Map());
    const [stripeAccounts, setStripeAccounts] = React.useState<Map<string, IStripeAccount>>(new Map());

    const onSubmit = React.useCallback(
        async (values: CreateReportForm) => {
            const { type, format, date, columns, appId } = values;
            const reportType = reportCreationValues[type][format].name;
            if (reportType) {
                const requestDate = {
                    startDate: startOfDay(date.startDate),
                    endDate: date.endDate ? endOfDay(date.endDate) : endOfDay(date.startDate)
                };
                const { body, ok } = await pepperPayApi.reporting.createReport({
                    reportType,
                    date: requestDate,
                    columns: Array.from(columns.keys()),
                    appId
                });
                if (ok) {
                    const report = body as PepperPayOperation;
                    if (report.status === OperationStatus.ERROR) {
                        dispatch(enqueueSnackbar(MESSAGE_REPORT_CREATION_ERROR, { variant: 'error' }));
                        return;
                    }

                    if (
                        report.status === OperationStatus.PENDING ||
                        report.status === OperationStatus.RUNNING
                    ) {
                        dispatch(enqueueSnackbar(MESSAGE_REPORT_CREATION_CREATED, { variant: 'info' }));
                    } else if (report.status === OperationStatus.DONE) {
                        dispatch(enqueueSnackbar(MESSAGE_REPORT_CREATION_SUCCESS, { variant: 'success' }));
                    }

                    props.onCreate(report);
                    props.onClose();
                }

                return body;
            }
        },
        [dispatch, props]
    );

    const formik = useFormik<CreateReportForm>({
        onSubmit,
        validateOnMount: false,
        validationSchema: yup.object().shape({
            appId: yup.string().required('You must select connected account id.'),
            date: yup.object().shape({
                startDate: yup.string().required('You have to select start date.')
            }),
            type: yup.string().oneOf(Object.values(EReportType)).required('Please, select report type.'),
            format: yup.string().oneOf(Object.values(EReportFormat)).required('Please, select report format.')
        }),
        initialValues: {
            appId: '',
            type: EReportType.ACTIVITY,
            date: {
                startDate: addDays(new Date(), -4),
                endDate: undefined
            },
            format: EReportFormat.SUMMARY,
            columns: new Map()
        }
    });

    const {
        values: { type, format, date },
        errors: { date: dateErrors },
        handleSubmit,
        setFieldValue,
        setValues,
        getFieldMeta,
        validateForm,
        setFieldTouched,
        isSubmitting
    } = formik;

    React.useEffect(() => {
        (async () => {
            try {
                const commonId = await new LocationSettingsApi().getPublicSettings(
                    null,
                    undefined,
                    true,
                    'stripe'
                );

                const result = await new LocationSettingsApi().getAllSettingValuesForTenant(
                    'stripe',
                    'accountId',
                    (obj: any): obj is { accountId: string } => obj && isString(obj.accountId)
                );

                const uniqueConnectIds = new Map<string, string>();
                if (isString(commonId.stripe?.accountId)) {
                    uniqueConnectIds.set(commonId.stripe?.accountId, 'common');
                }
                Object.entries(result).forEach(([locationId, { accountId }]) => {
                    uniqueConnectIds.set(accountId, locationId);
                });

                const accountIds = Array.from(uniqueConnectIds.keys());
                const { body: accounts } = await pepperPayApi.accounts.getAccounts(accountIds);

                setAppIds(uniqueConnectIds);
                setStripeAccounts(new Map(Object.entries(accounts)));
            } catch (err) {
                logger.error(err);
            }
        })();
    }, [dispatch]);

    React.useEffect(() => {
        const { type: lastOperationType, format: lastOperationFormat } = getOperationTypeAndFormat(
            lastOperation?.metadata?.report_type
        );
        if (type && format && lastOperation) {
            if (type === lastOperationType && format === lastOperationFormat) {
                setFieldValue(
                    'columns',
                    new Map(lastOperation.metadata.parameters.columns.map(key => [key, true])),
                    true
                ).then(() => {
                    validateForm();
                });
            } else {
                const values: [string, boolean][] = getAllReportColumns(type, format).map(key => [key, true]);
                const columnValues = new Map<string, boolean>(values);
                setFieldValue('columns', columnValues, true);
            }
            setFieldValue('date', { startDate: '', endDate: '' }, false);
        }
    }, [format, lastOperation, setFieldValue, type, validateForm]);

    React.useEffect(() => {
        if (props.lastOperation && lastOperation?.id !== props.lastOperation?.id) {
            setLastOperation(props.lastOperation);
        }
    }, [lastOperation, props.lastOperation]);

    React.useEffect(() => {
        if (lastOperation) {
            const meta = lastOperation.metadata;
            const { type: lastOperationType, format: lastOperationFormat } = getOperationTypeAndFormat(
                meta.report_type
            );
            const startDate = new Date(meta.parameters.interval_start * 1000);
            const endDate = new Date(meta.parameters.interval_end * 1000);
            const appId = meta.parameters.connected_account;

            setValues(
                {
                    type: lastOperationType,
                    format: lastOperationFormat,
                    date: {
                        startDate,
                        endDate: isSameDay(endDate, startDate) ? undefined : endDate
                    },
                    appId,
                    columns: new Map(meta.parameters.columns.map(key => [key, true]))
                },
                true
            );
        }
    }, [lastOperation, setValues]);

    const handleDateFilterListOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorDateElement(event.currentTarget);
    }, []);

    const handleDeleteDateFilter = React.useCallback(() => {
        setFieldValue('date', { startDate: '', endDate: '' }, true);
        setFieldTouched('date');
    }, [setFieldTouched, setFieldValue]);

    const renderDatePicker = React.useMemo(() => {
        const formattedDate = formatDateRange(date.startDate || date.endDate ? date : null, true);
        const { touched } = getFieldMeta('date');
        return (
            <FormControl>
                <Box paddingTop={2}>
                    {date && formattedDate ? (
                        <Chip
                            label={formattedDate}
                            onDelete={handleDeleteDateFilter}
                            onClick={handleDateFilterListOpen}
                        />
                    ) : (
                        <Button
                            aria-controls="actions-menu"
                            aria-haspopup="true"
                            color="secondary"
                            onClick={handleDateFilterListOpen}
                        >
                            <CalendarToday />
                            <ExpandMore />
                        </Button>
                    )}
                </Box>

                {dateErrors && touched && (
                    <FormHelperText error={Boolean(dateErrors?.startDate || dateErrors?.endDate)}>
                        {dateErrors?.startDate || dateErrors?.endDate}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }, [date, dateErrors, getFieldMeta, handleDateFilterListOpen, handleDeleteDateFilter]);

    const handleDateFilter = React.useCallback(
        (value?: DateRangePickerValue) => {
            setFieldValue('date', value);
        },
        [setFieldValue]
    );

    const handleDateFilterClose = React.useCallback(() => {
        setAnchorDateElement(null);
        setFieldTouched('date');
    }, [setFieldTouched]);

    const maxSelectionDate = React.useMemo(() => {
        if (type === EReportType.ACTIVITY) {
            return addDays(new Date(), -4);
        }
        // 12 hours minimum time requirement means we cannot select "yesterday" as a date
        return addDays(new Date(), -2);
    }, [type]);

    const appIdOptions: Option[] = React.useMemo(
        () =>
            Array.from(appIds.keys()).map(appId => {
                const account = stripeAccounts.get(appId);

                return {
                    label: account?.name ?? appId,
                    value: appId
                };
            }),
        [appIds, stripeAccounts]
    );

    return (
        <MuiModal open={open} onClose={onClose}>
            <Box>
                <Typography variant="h6" color="primary">
                    Create a report
                </Typography>
                {appIdOptions.length > 0 && (
                    <FormikProvider value={formik}>
                        <form onSubmit={handleSubmit}>
                            <Box display="flex" alignItems="center">
                                <Box sx={{ width: 250 }}>
                                    <SelectFormField
                                        emptyLabel="Connect Account"
                                        name="appId"
                                        options={appIdOptions}
                                        displayEmpty
                                    />
                                </Box>
                                <Box marginLeft={2}>{renderDatePicker}</Box>
                                <CustomersTimelineDateFilter
                                    anchorElement={anchorDateElement}
                                    value={date}
                                    onSelect={handleDateFilter}
                                    onClose={handleDateFilterClose}
                                    maxDate={maxSelectionDate}
                                />
                            </Box>
                            <Box>
                                <GeneralReportForm formik={formik} />
                            </Box>
                            <Box display="flex" justifyContent="flex-end" paddingTop={2}>
                                <Box paddingRight={1}>
                                    <Button color="secondary" onClick={onClose}>
                                        Cancel
                                    </Button>
                                </Box>
                                <LoadingButton
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Create
                                </LoadingButton>
                            </Box>
                        </form>
                    </FormikProvider>
                )}
            </Box>
        </MuiModal>
    );
};

import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { CheckboxPlainPicker } from 'lib/checkbox-picker/CheckboxPlainPicker';
import { MenuSummary } from '@pepperhq/menu-sdk';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { MuiModal } from 'ui/MuiModal';
import { useParams } from 'react-router-dom';

const ModalContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: theme.spacing(430 / 8),
    minHeight: theme.spacing(410 / 8)
}));

interface MenuPickerModalProps {
    open: boolean;
    onClose: () => void;
    menus: MenuSummary[];
    defaultMenuIds?: string[];
    selectedMenuIds?: string[];
    onSubmit: (selectedMenus: string[]) => void;
    loading?: boolean;
    allowEmpty?: boolean;
}

const MenuPickerModalPropsComponent: React.FC<MenuPickerModalProps> = props => {
    const { menuId: currentMenuId, menuChangeId: currentMenuChangeId } =
        useParams<{ menuId?: string; menuChangeId?: string }>();
    const { locationIdToLocationName } = useLocationHelpers();
    const {
        onClose,
        onSubmit,
        selectedMenuIds = [],
        defaultMenuIds = [],
        menus,
        loading,
        allowEmpty
    } = props;
    const options = React.useMemo(() => {
        if (!Array.isArray(menus)) {
            return [];
        }
        const displayNameCounts = menus
            .map(item => item.menuDescription ?? item.menuId)
            .reduce((counts: Record<string, number>, displayName) => {
                counts[displayName] = (counts[displayName] || 0) + 1;
                return counts;
            }, {});

        return Object.values(
            menus
                .filter(
                    summary =>
                        !(summary.menuId === currentMenuId && summary.menuChangeId === currentMenuChangeId)
                )
                .reduce((output: Record<string, { value: string; label: string }>, item) => {
                    const [locationId] = item.locationIds;
                    const genericDisplayName = item.menuDescription ?? item.menuId;
                    const specificDisplayName =
                        displayNameCounts[genericDisplayName] > 1
                            ? `${genericDisplayName} (${locationIdToLocationName.get(locationId)})`
                            : genericDisplayName;

                    const key = `${locationId}::${item.menuChangeId}`;

                    if (!output[key]) {
                        output[key] = { value: key, label: specificDisplayName };
                    } else {
                        output[key].label = `${output[key].label}\n${specificDisplayName}`;
                    }

                    return output;
                }, {})
        );
    }, [currentMenuId, currentMenuChangeId, locationIdToLocationName, menus]);

    const defaultSelectedMenus = React.useMemo(
        () => new Set([...defaultMenuIds, ...selectedMenuIds]),
        [defaultMenuIds, selectedMenuIds]
    );

    const [selectedMenus, setSelectedMenus] = React.useState<Set<string>>(new Set(defaultSelectedMenus));
    const value = React.useMemo(() => [...selectedMenus], [selectedMenus]);
    const handleChange = React.useCallback((value: string[]) => {
        setSelectedMenus(new Set(value));
    }, []);

    const handleSubmit = React.useCallback(() => {
        onSubmit([...selectedMenus]);
        onClose();
    }, [onClose, onSubmit, selectedMenus]);

    return (
        <ModalContainer>
            <CheckboxPlainPicker
                options={options}
                defaultItems={defaultMenuIds}
                value={value}
                loading={loading}
                searchPlaceholder="Search Menu"
                onChange={handleChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={allowEmpty ? false : !selectedMenus.size}
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </Box>
        </ModalContainer>
    );
};

export const MenuPickerModal: React.FC<MenuPickerModalProps> = props => {
    const { open, onClose } = props;
    return (
        <MuiModal open={open} onClose={onClose} sx={{ whiteSpace: 'pre-wrap' }}>
            <MenuPickerModalPropsComponent {...props} />
        </MuiModal>
    );
};

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { IDisplay, isSquaredDisplay } from 'components/content/models/ContentModule';
import { AutoFormFields, LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import { getImageAspect, getImagePickerDescriptionByDisplay, MarketingMessageProps } from './base';

interface IBrowserItemProperties {
    url: string;
    imageUrl: string;
    requiresLocationSelection?: boolean;
    title?: string;
    subtitle?: string;
    subtitleTwo?: string;
    invertTextColour?: boolean;
    showTextBackgroundBar?: boolean;
}

const createBrowserMessagePropertiesForm: (display: IDisplay) => AutoFormFields = (display: IDisplay) => {
    const shouldHideMessageOverlay = isSquaredDisplay(display);

    return {
        url: {
            label: 'URL',
            validation: 'required|url'
        },
        name: {
            label: 'Reporting Name',
            validation: 'required',
            description:
                'This code is used to track the number of marketing module item taps on anchor screen'
        },
        imageUrl: {
            label: 'Image',
            type: 'image',
            validation: 'required|url',
            aspect: getImageAspect(display),
            accept: ['image/jpeg', 'image/png', 'image/gif'],
            description: getImagePickerDescriptionByDisplay(display)
        },
        ...(!shouldHideMessageOverlay && {
            title: {
                label: 'Title'
            },
            subtitle: {
                label: 'Subtitle'
            },
            subtitleTwo: {
                label: 'Subtitle two'
            },
            invertTextColour: {
                label: 'Invert text colour',
                type: 'switch'
            },
            showTextBackgroundBar: {
                label: 'Show text background bar',
                type: 'switch'
            }
        })
    };
};

export default class BrowserMessageProperties extends React.PureComponent<
    MarketingMessageProps<IBrowserItemProperties>
> {
    static defaultProps = {
        properties: {}
    };
    browserMessagePropertiesForm = createBrowserMessagePropertiesForm(this.props.display);
    form = new LegacyForm(this.browserMessagePropertiesForm, data => {
        const request = Object.entries(data).reduce((acc, [field, item]) => {
            if (!!item || this.browserMessagePropertiesForm[field].type === 'switch') {
                acc[field] = item;
            }
            return acc;
        }, {} as any);
        this.props.onSubmit(request);
    }).update({ name: this.props.name, ...this.props.properties });
    render() {
        const hideMarketingMessageOverlay = isSquaredDisplay(this.props.display);

        return (
            <AutoForm form={this.form}>
                {({ url, name, imageUrl, ...fields }, render) => (
                    <React.Fragment>
                        {url}
                        {name}
                        {imageUrl}
                        {!hideMarketingMessageOverlay && (
                            <>
                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Typography color="primary">Marketing message overlay</Typography>
                                    </Grid>
                                </Grid>
                                {render(fields)}
                            </>
                        )}
                        {this.props.contextForm()}
                    </React.Fragment>
                )}
            </AutoForm>
        );
    }
}

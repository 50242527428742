import React from 'react';
import { Box, Chip, Divider, Paper, TableCell, Tooltip, Typography, styled } from '@mui/material';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems } from 'ui/table/MuiTable';
import { INotificationReceipt } from './cards/NotificationReceiptsCard';
import { mapNotificationReceiptToStyles } from './helpers';

const PREFIX = 'NotificationReceptsTable';

const classes = {
    statusChip: `${PREFIX}-statusChip`
};

const StyledChip = styled(Chip)<{ receipt: INotificationReceipt }>(({ theme, receipt }) => ({
    ...mapNotificationReceiptToStyles(receipt, theme),
    fontSize: '0.8rem'
}));

// If we have a more friendly error message, it should be added here
// These should be lower-cased keys as all messages are checked with toLowerCase
const receiptStatusText: { [key: string]: string } = {
    // SKIPS
    'marketing opt out': 'The Customer opted out of marketing messages',
    'no device token': 'The Customer did not have a valid device token', // App Uninstalled?

    // ERRORS
    notregistered: 'The Customer was previously unregistered',
    'unrecognised token': 'The Customer did not have a valid token'
};

function NotificationStatusChip(props: { receipt: INotificationReceipt }) {
    const {
        receipt: { skipMessage, errorMessage }
    } = props;
    let label = 'SENT';
    let popover = '';
    if (!!errorMessage) {
        label = 'ERROR';
        popover = receiptStatusText[errorMessage.toLowerCase()] || errorMessage;
    } else if (!!skipMessage) {
        label = 'SKIPPED';
        popover = receiptStatusText[skipMessage.toLowerCase()] || skipMessage;
    }

    // Tooltip doesn't use className for it's styles, it requires the entire classes Object
    // Since it applies these to it's own internal child elements (arrow, tooltip etc)
    // It also sometimes shows an error if there are any other keys that do not exist on the MUI Tooltip
    return (
        <Tooltip title={popover} placement="left" {...props}>
            <StyledChip classes={{ root: classes.statusChip }} label={label} receipt={props.receipt} />
        </Tooltip>
    );
}

const columns: MuiTableColumnItem[] = [
    { key: 'userFullName', label: 'Customer' },
    {
        key: 'status',
        label: 'Status',
        headerProps: {
            align: 'center'
        },
        render: (item: INotificationReceipt, key: string) => (
            <TableCell align="center" key={key} size="small">
                <NotificationStatusChip receipt={item} />
            </TableCell>
        )
    }
];

interface NotificationReceiptsTableProps {
    scroll: MuiTableScrollItems;
    data: INotificationReceipt[];
}

export const NotificationReceiptsTable: React.FC<NotificationReceiptsTableProps> = props => (
    <Paper>
        <Box padding={2}>
            <Typography>Receipts</Typography>
        </Box>
        <Divider />
        <MuiTable columns={columns} {...props} />
    </Paper>
);

import { AutoFormFields } from 'lib/LegacyForm';

export const createSignUpMerchantForm: () => AutoFormFields = () => ({
    title: {
        label: 'Name',
        validation: 'required',
        description: 'The display name of your merchant.'
    },
    identifier: {
        label: 'Short nickname',
        validation: 'required',
        description:
            'The unique identifier of your company. We recommend using your company name without spaces.'
    }
});

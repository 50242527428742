/* eslint-disable max-len */
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { MultiSelectFormField } from 'lib/form/fields/MultiSelectFormField';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';

export enum NotificationTarget {
    GLOBAL = 'global',
    AUDIENCE = 'segment',
    LOCATION = 'location'
}

export type NotificationFormData = {
    message: string;
    target: NotificationTarget;
    audiences?: Option[];
    locations?: Option[];
};

interface NotificationFormProps {
    onSubmit: (data: NotificationFormData) => void;
    onClose: () => void;
    locations: Option[];
    segments: Option[];
    initialTarget?: NotificationTarget;
    initialSegments?: Option[];
    initialLocations?: Option[];
    initialMessage?: string;
}

const validationSchema = Yup.object().shape({
    message: Yup.string().required('You must specify a message to send in this notification.'),
    audiences: Yup.array().when('target', {
        is: (target: NotificationTarget) => target === NotificationTarget.AUDIENCE,
        then: Yup.array().min(1, 'You must select at least one Audience.'),
        otherwise: Yup.array().notRequired()
    }),
    locations: Yup.array().when('target', {
        is: (target: NotificationTarget) => target === NotificationTarget.LOCATION,
        then: Yup.array().min(1, 'You must select at least one Location.'),
        otherwise: Yup.array().notRequired()
    })
});

const options = [
    { value: NotificationTarget.GLOBAL, label: 'All Users' },
    { value: NotificationTarget.AUDIENCE, label: 'Audience' },
    { value: NotificationTarget.LOCATION, label: 'Location' }
];

export const NotificationForm: React.FC<NotificationFormProps> = ({
    onSubmit,
    onClose,
    initialTarget,
    segments,
    locations,
    initialSegments,
    initialLocations,
    initialMessage
}) => {
    const initialValues = {
        target: initialTarget || NotificationTarget.GLOBAL,
        message: initialMessage || '',
        audiences: initialSegments || [],
        locations: initialLocations || []
    };
    return (
        <MuiForm onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ submitForm, isSubmitting, values: { target } }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                Send Notification
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFormField
                                name="target"
                                options={options}
                                label="Recipient(s)"
                                description="This is who the notification will be sent to. You can choose between sending it to everyone, sending it to users who are currently
                        checked in at a set of locations or have favourited one of them, or sending it to the members in a set of audiences."
                            />
                        </Grid>
                        {target === NotificationTarget.AUDIENCE && (
                            <Grid item xs={12}>
                                <MultiSelectFormField
                                    name="audiences"
                                    label={<MuiFormLabel required>Audiences</MuiFormLabel>}
                                    options={segments}
                                    isMulti
                                />
                            </Grid>
                        )}
                        {target === NotificationTarget.LOCATION && (
                            <Grid item xs={12}>
                                <MultiSelectFormField
                                    name="locations"
                                    label={<MuiFormLabel required>Locations</MuiFormLabel>}
                                    options={locations}
                                    isMulti
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextFormField
                                label={<MuiFormLabel required>Message</MuiFormLabel>}
                                name="message"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Row gutter align="flex-end" valign="center">
                                <Button color="secondary" variant="outlined" onClick={onClose}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Submit
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </MuiForm>
    );
};

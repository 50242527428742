import { Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import {
    ETierOperator,
    ETierType,
    ETierUserProperty,
    TierItemBody,
    TieredLoyaltyTiersFieldName,
    getTieredLoyaltyConditionType
} from '../model/loyalty-tier';
import { Option } from 'lib/types';
import { UserMetric, UserMetricListenerCondition } from '../model';
import { EPrimaryPlatform } from 'components/customers/models/Customer';
import { TieredLoyaltyConditionField, TieredLoyaltyTierFormField } from './TieredLoyaltyTierFormField';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { isNull } from '@pepperhq/menu-sdk/dist/libs/typeUtils';

interface TieredLoyaltyTierFormProps {
    metrics?: Record<string, UserMetric>;
    item?: UserMetricListenerCondition;
    audienceOptions: Option[];
    locationOptions: Option[];
    onSubmit?: (data: TierItemBody) => void;
    onDelete?: (id: string) => void;
    loading?: boolean;
    disableDelete?: boolean;
    metricOnly?: boolean;
}

const tierTypeOptions = [
    { value: ETierType.Metric, label: 'Metric' },
    { value: ETierType.Audience, label: 'Audience' },
    { value: ETierType.UserProperty, label: 'User property' }
];

const metricOperatorOptions = [
    { value: ETierOperator.Equal, label: 'Equal' },
    { value: ETierOperator.NotEqual, label: 'Not equal' },
    { value: ETierOperator.GreaterThan, label: 'Greater than' },
    { value: ETierOperator.LessThan, label: 'Less than' }
];

const audienceOperatorOptions = [
    { value: ETierOperator.IsInAudience, label: 'In audience' },
    { value: ETierOperator.IsNotInAudience, label: 'Not in audience' }
];

const primaryPlatformOperatorOptions = [
    { value: ETierOperator.Equal, label: 'Is' },
    { value: ETierOperator.NotEqual, label: 'Is not' }
];

const primaryPlatformValueOptions = [
    { value: EPrimaryPlatform.ANDROID, label: 'Android' },
    { value: EPrimaryPlatform.IOS, label: 'iOS' },
    { value: EPrimaryPlatform.WEB, label: 'Web' },
    { value: EPrimaryPlatform.UNKNOWN, label: 'Unknown' }
];

const favouriteLocationsOperatorOptions = [
    { value: ETierOperator.Contains, label: 'Contain' },
    { value: ETierOperator.NotContains, label: "Don't contain" }
];

const marketingOperatorOptions = [
    { value: ETierOperator.Equal, label: 'Agreed' },
    { value: ETierOperator.NotEqual, label: "Didn't agree" }
];

const userPropertyOptions = [
    { value: ETierUserProperty.FavouriteLocations, label: 'Favourite locations' },
    { value: ETierUserProperty.PrimaryPlatform, label: 'Primary platform' },
    { value: ETierUserProperty.HasAgreedToShareData, label: 'Has agreed to share data' },
    { value: ETierUserProperty.HasAgreedToReceiveMarketing, label: 'Has agreed to marketing' }
];

const getFormValues = (
    item: UserMetricListenerCondition | undefined,
    locationOptions: Option[],
    audienceOptions: Option[]
) => ({
    type: getTieredLoyaltyConditionType(item) ?? ETierType.Metric,
    metricId: item && 'metricId' in item ? item?.metricId : undefined,
    metricOperator: item?.operator,
    value: isDefined(item?.value) ? String(item?.value) : undefined,
    audienceOperator: item?.operator ?? ETierOperator.IsInAudience,
    audience: isDefined(item?.value) ? audienceOptions?.find(option => option.value === item?.value) : null,
    userProperty:
        item && 'userProperty' in item ? item.userProperty : ETierUserProperty.HasAgreedToReceiveMarketing,
    primaryPlatformOperator: item?.operator ?? ETierOperator.Equal,
    primaryPlatformValue: item?.value,
    favouriteLocationsOperator: item?.operator ?? ETierOperator.Contains,
    favouriteLocationsValue: isDefined(item?.value)
        ? locationOptions?.find(option => option.value === item?.value)
        : null,
    marketingOperator: item?.operator
});

export const TieredLoyaltyTierRow: React.FC<TieredLoyaltyTierFormProps> = ({
    metrics = {},
    item,
    audienceOptions,
    onSubmit,
    onDelete,
    locationOptions,
    loading,
    disableDelete,
    metricOnly
}) => {
    const [formState, setFormState] = React.useState<Record<TieredLoyaltyTiersFieldName, any>>(
        getFormValues(item, locationOptions, audienceOptions)
    );
    const [touched, setTouched] = React.useState(false);
    const readOnly = React.useMemo(() => !!item, [item]);
    const metricOptions = React.useMemo(
        () => Object.entries(metrics).map(([id, metric]) => ({ value: id, label: metric.name })),
        [metrics]
    );
    const fieldDefinitions: Record<TieredLoyaltyTiersFieldName, TieredLoyaltyConditionField> = React.useMemo(
        () => ({
            type: {
                select: true,
                options: tierTypeOptions,
                width: 2
            },
            metricId: {
                select: true,
                options: metricOptions,
                width: 4,
                label: 'Metric',
                fieldMapper: (body, value) => {
                    body.metricId = value;
                }
            },
            metricOperator: {
                select: true,
                options: metricOperatorOptions,
                width: 3,
                label: 'Operator',
                fieldMapper: (body, value) => {
                    body.operator = value;
                }
            },
            value: {
                width: 2,
                label: 'Value',
                fieldMapper: (body, value) => {
                    body.value = Number(value);
                }
            },
            audienceOperator: {
                select: true,
                options: audienceOperatorOptions,
                width: 4,
                fieldMapper: (body, value) => {
                    body.operator = value;
                }
            },
            audience: {
                searchable: true,
                options: audienceOptions,
                width: 5,
                placeholder: 'Select an audience',
                fieldMapper: (body, value) => {
                    body.value = value ? value.value : value;
                }
            },
            userProperty: {
                select: true,
                options: userPropertyOptions,
                width: 4,
                placeholder: 'Select a user property',
                fieldMapper: (body, value) => {
                    body.userProperty = value;
                }
            },
            primaryPlatformOperator: {
                select: true,
                options: primaryPlatformOperatorOptions,
                width: 2,
                placeholder: 'Select an operator',
                fieldMapper: (body, value) => {
                    body.operator = value;
                }
            },
            primaryPlatformValue: {
                select: true,
                options: primaryPlatformValueOptions,
                width: 3,
                placeholder: 'Select a primary platofrm',
                fieldMapper: (body, value) => {
                    body.value = value;
                }
            },
            favouriteLocationsOperator: {
                select: true,
                options: favouriteLocationsOperatorOptions,
                width: 2,
                placeholder: 'Select an operator',
                fieldMapper: (body, value) => {
                    body.operator = value;
                }
            },
            favouriteLocationsValue: {
                select: true,
                options: locationOptions,
                width: 3,
                searchable: true,
                placeholder: 'Select a location',
                fieldMapper: (body, value) => {
                    body.value = value ? value.value : value;
                }
            },
            marketingOperator: {
                select: true,
                options: marketingOperatorOptions,
                width: 5,
                placeholder: 'Select an option',
                fieldMapper: (body, value) => {
                    body.value = true;
                    body.operator = value;
                }
            }
        }),
        [audienceOptions, locationOptions, metricOptions]
    );
    const feildsToUse: TieredLoyaltyTiersFieldName[] = React.useMemo(() => {
        switch (formState.type) {
            case ETierType.UserProperty: {
                switch (formState.userProperty) {
                    case ETierUserProperty.FavouriteLocations:
                        return [
                            'type',
                            'userProperty',
                            'favouriteLocationsOperator',
                            'favouriteLocationsValue'
                        ];
                    case ETierUserProperty.HasAgreedToShareData:
                    case ETierUserProperty.HasAgreedToReceiveMarketing:
                        return ['type', 'userProperty', 'marketingOperator'];
                    case ETierUserProperty.PrimaryPlatform:
                        return ['type', 'userProperty', 'primaryPlatformOperator', 'primaryPlatformValue'];
                    default:
                        return ['type', 'userProperty'];
                }
            }
            case ETierType.Audience:
                return ['type', 'audienceOperator', 'audience'];
            case ETierType.Metric:
            default:
                return ['type', 'metricId', 'metricOperator', 'value'];
        }
    }, [formState.type, formState.userProperty]);
    const errors = React.useMemo<string[]>(() => {
        if (item) {
            return [];
        }
        const emptyFields = feildsToUse.filter(
            field =>
                !isDefined(formState[field]) || isNull(formState[field]) || isEmptyString(formState[field])
        );
        return emptyFields;
    }, [feildsToUse, formState, item]);
    const handleFieldChange = React.useCallback((name: TieredLoyaltyTiersFieldName, value: any) => {
        setFormState(state => ({ ...state, [name]: value }));
    }, []);
    const renderField = React.useCallback(
        (name: TieredLoyaltyTiersFieldName) => (
            <TieredLoyaltyTierFormField
                key={name}
                field={fieldDefinitions[name]}
                name={name}
                value={formState[name]}
                onChange={handleFieldChange}
                readOnly={readOnly || (name === 'type' && metricOnly)}
                loading={loading}
                error={touched && errors.includes(name)}
            />
        ),
        [errors, fieldDefinitions, formState, handleFieldChange, loading, metricOnly, readOnly, touched]
    );
    // ==========
    const handleSave = React.useCallback(() => {
        if (!onSubmit) {
            throw new Error('No submit handler provided!');
        }
        setTouched(true);
        if (errors.length) {
            return;
        }
        const body: TierItemBody = {};
        feildsToUse.forEach(field => {
            const definition = fieldDefinitions[field];
            if (definition?.fieldMapper) {
                definition?.fieldMapper(body, formState[field]);
            }
        });
        onSubmit(body);
    }, [errors.length, feildsToUse, fieldDefinitions, formState, onSubmit]);
    const handleDelete = React.useCallback(() => {
        if (!onDelete) {
            throw new Error('No submit handler provided!');
        }
        onDelete(item?._id);
    }, [item?._id, onDelete]);
    // ==========
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {feildsToUse.map(renderField)}
            {!item && (
                <Grid item xs={12} lg={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        fullWidth
                        disabled={loading || (!!errors.length && touched)}
                    >
                        Add
                    </Button>
                </Grid>
            )}
            {!!item && disableDelete && (
                <Tooltip title="You must have at least one metric condition" arrow>
                    <Grid item xs={12} lg={1}>
                        <Button variant="outlined" color="error" fullWidth disabled>
                            Delete
                        </Button>
                    </Grid>
                </Tooltip>
            )}
            {!!item && !disableDelete && (
                <Grid item xs={12} lg={1}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDelete}
                        fullWidth
                        disabled={loading}
                    >
                        Delete
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

import { Button, TextField } from '@mui/material';
import { useInputState } from 'lib/hooks/useInputState';
import { isEmptyString } from 'lib/typeguards';
import React from 'react';
import { Row } from 'ui/Flex';

interface GridFilterInputProps {
    onChange: (value: string | undefined, name: string) => void;
    value?: string;
    label?: string;
    name: string;
    disabled?: boolean;
    size?: 'small';
}

export const GridFilterInput: React.FC<GridFilterInputProps> = ({
    value = '',
    onChange,
    label,
    name,
    disabled,
    size
}) => {
    const [field, handleChange] = useInputState(value);
    const handleClick = React.useCallback(() => {
        onChange(isEmptyString(field) ? undefined : field, name);
    }, [field, onChange, name]);
    const renderButton = React.useCallback(
        () => (
            <Button
                color="secondary"
                disabled={value === field}
                onClick={handleClick}
                variant="outlined"
                size={size}
            >
                {value === field || field.length ? 'Submit' : 'Clear'}
            </Button>
        ),
        [field, handleClick, size, value]
    );
    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => e.key === 'Enter' && value !== field && handleClick(),
        [field, handleClick, value]
    );
    return (
        <Row>
            <TextField
                disabled={disabled}
                variant="outlined"
                value={field}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                label={label}
                name={name}
                size={size}
                InputProps={{ endAdornment: renderButton() }}
            />
        </Row>
    );
};

import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { DateRangePickerInput, DateRangePickerInputLabels } from 'lib/DateRangePickerInput';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';

interface DateRangePickerFormFieldProps {
    labels?: DateRangePickerInputLabels;
    description?: string;
}

export const DateRangePickerFormField: React.SFC<FieldAttributes<DateRangePickerFormFieldProps>> = ({
    labels,
    description,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <DateRangePickerFormFieldComponent labels={labels} description={description} {...childProps} />
        )}
    </Field>
);

const DateRangePickerFormFieldComponent: React.SFC<FieldProps & DateRangePickerFormFieldProps> = ({
    field,
    meta,
    labels,
    description
}) => {
    const handleChange = React.useCallback(
        (value: DateRangePickerValue) => {
            field.onChange({ target: { value, name: field.name } });
        },
        [field]
    );
    const isError = meta.touched && !!meta.error;
    return (
        <FormControl fullWidth error={meta.touched && !!meta.error}>
            <DateRangePickerInput labels={labels} value={field.value} onChange={handleChange} />
            {(isError || description) && (
                <FormHelperText sx={{ mx: 0 }} error={isError}>
                    {isError ? meta.error : description}
                </FormHelperText>
            )}
        </FormControl>
    );
};

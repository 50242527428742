import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { ISettingAction } from './settingsActions';
import { SettingsActionTypes } from './settingsActionTypes';

export interface SettingsState {
    settings: IPublicAndPrivateSettings;
    loading: boolean;
    error?: string;
}

export const initialState: SettingsState = {
    settings: {},
    loading: true,
    error: undefined
};

export default function (state: SettingsState = initialState, action: ISettingAction): SettingsState {
    switch (action.type) {
        case SettingsActionTypes.START_REQUEST:
            return { ...state, loading: true };
        case SettingsActionTypes.END_REQUEST:
            return { ...state, loading: false };
        case SettingsActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                error: undefined,
                settings: action.settings
            };
        case SettingsActionTypes.LOAD_ERROR:
            return { ...state, loading: false, error: action.message };
        case SettingsActionTypes.RESET:
            return { ...initialState };
        default:
            return state;
    }
}

import { Theme } from '@mui/material';
import { green, orange } from '@mui/material/colors';
import { AwardPoints } from './models/Award';
import { Customer, CustomerCredentialProvider, CustomerState, EPrimaryPlatform } from './models/Customer';

export function mapCustomerStateToStyles(
    state: CustomerState,
    theme: Theme
): { backgroundColor: string; color: string } {
    switch (state) {
        case CustomerState.ACTIVE:
            return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
        case CustomerState.PENDING:
            return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
    }
}

export function getPrimaryPlatform(platform?: string) {
    switch (platform) {
        case EPrimaryPlatform.ANDROID:
            return 'Android';
        case EPrimaryPlatform.IOS:
            return 'iOS';
        default:
            return 'Unknown';
    }
}

export const getStampCard = (points: AwardPoints) => {
    const cards = Math.floor(points.available / points.redemption);
    const stamps = points.available % points.redemption;
    return `${cards > 0 ? cards : 0} ${cards === 1 ? 'Card' : 'Cards'}, ${stamps > 0 ? stamps : 0} ${
        stamps === 1 ? 'Stamp' : 'Stamps'
    }`;
};

export const processCredentials = (customer: Customer) => {
    if (customer.credentials) {
        const primaryCredential = customer.credentials.find(credential => credential.isPrimary);
        if (primaryCredential) {
            if (
                primaryCredential.provider === CustomerCredentialProvider.GOOGLE ||
                primaryCredential.provider === CustomerCredentialProvider.APPLE
            ) {
                const emailCredential = customer.credentials.find(
                    credential => credential?.provider === CustomerCredentialProvider.EMAIL
                );
                return emailCredential.id;
            }

            return primaryCredential.id;
        }
    }
    return '';
};

import { EmptyState } from 'components/utils/emptyState';
import { MainLayout } from 'layouts/MainLayout';
import React from 'react';
import EmptyStateImage from 'static/terminal-empty-state.svg';

export const TerminalAccessPage: React.FC = () => (
    <MainLayout pageName="Terminal Access">
        <EmptyState
            headerText="You can start using your QuickPad now"
            paragraphText="More features are coming soon."
            buttonText="Get started"
            imageUrl={EmptyStateImage}
        />
    </MainLayout>
);

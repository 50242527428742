import React from 'react';
import {
    MESSAGE_TIERED_LOYALTY_METRIC_CREATE_ERROR,
    MESSAGE_TIERED_LOYALTY_METRIC_CREATE_SUCCESS
} from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { useDispatch } from 'react-redux';
import logger from 'lib/logger';
import { UserMetric, UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';

export function useTieredLoyaltyMetricEdit(
    metricDefinition: UserMetricDefinition | undefined,
    setDefinition: (value: UserMetricDefinition) => void
) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleSubmit = React.useCallback(
        async (metric: UserMetric) => {
            setLoading(true);
            const metricId = metric.name.replaceAll(' ', '_');
            const result = await metricsApi.addMetric(metricDefinition?._id, metricId, metric);
            setLoading(false);
            if (!result.ok) {
                logger.error(result.body.message, result.body);
                dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_METRIC_CREATE_ERROR, { variant: 'error' }));
                return;
            }
            dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_METRIC_CREATE_SUCCESS, { variant: 'success' }));
            setDefinition(result.body);
            setOpen(false);
        },
        [dispatch, metricDefinition?._id, setDefinition]
    );
    return {
        metricsModalOpen: open,
        handleMetricEditOpen: handleOpen,
        handleMetricEditClose: handleClose,
        handleMetricEditSubmit: handleSubmit,
        loading
    };
}

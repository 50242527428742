import * as React from 'react';
import { SortableContainer, SortableElement, SortableHandle as sortableHandle } from 'react-sortable-hoc';
import { Box, Button, Grid, Menu, MenuItem, styled, Typography } from '@mui/material';
import DragHandle from '@mui/icons-material/DragHandle';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OrderScenario, OrderScenarioLabels } from 'components/order/model/Order';
import { arrayMove, removeArrayItem } from 'lib/helpers';
import { CardViewTextRowValue } from 'ui/viewComponents';
import {
    CreateOrderNoteModal,
    EditLocationOrderNote,
    ILocationOrderNoteForm
} from '../modal/LocationOrderNoteModal';
import { LocationOrderNote } from '../models/LocationModel';
import { LocationOrderNoteMap } from './LocationOrderNotes';

interface IProps {
    notes: LocationOrderNoteMap;
    setNotes: React.Dispatch<React.SetStateAction<Record<string, LocationOrderNote[]>>>;
    isEditing: boolean;
}

const PREFIX = 'LocationOrderNotesView';

const classes = {
    cell: `${PREFIX}-cell`,
    dragHandle: `${PREFIX}-dragHandle`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.cell}`]: {
        border: 'none',
        padding: theme.spacing(1),
        paddingLeft: 0
    },
    [`& .${classes.dragHandle}`]: {
        cursor: 'pointer',
        height: '1em',
        width: '1em',
        flexBasis: '5%',
        marginRight: '30px'
    }
}));

interface LocationOrderNoteProps {
    value: LocationOrderNote;
    sortIndex: number;
    scenario: string;
    isEditing: boolean;
    onNoteEditClick: (index: number, scenario: string) => void;
    onNoteDeleteClick: (index: number, scenario: string) => void;
}
const LocationOrderNoteRow = SortableElement<LocationOrderNoteProps>(
    ({
        value,
        sortIndex,
        scenario,
        isEditing,
        onNoteEditClick,
        onNoteDeleteClick
    }: LocationOrderNoteProps) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handleClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);
        const handleEditClick = React.useCallback(
            () => onNoteEditClick(sortIndex, scenario),
            [onNoteEditClick, scenario, sortIndex]
        );
        const handleNoteDeleteClick = React.useCallback(
            () => onNoteDeleteClick(sortIndex, scenario),
            [onNoteDeleteClick, scenario, sortIndex]
        );

        return (
            <StyledBox key={value.title} display="flex" alignItems="center">
                {isEditing && (
                    <Box className={classes.cell}>
                        <CustomDragHandle className={classes.dragHandle} />
                    </Box>
                )}
                <Box flex={1} className={classes.cell}>
                    <Typography>
                        <b>{value.title}</b>
                    </Typography>
                    <Typography>{value.description}</Typography>
                </Box>
                {isEditing && (
                    <Box>
                        <Button
                            aria-haspopup="true"
                            aria-controls={`edit-menu-${scenario}-${sortIndex}`}
                            onClick={handleClick}
                            color="secondary"
                        >
                            <MoreVertIcon />
                        </Button>
                        <Menu
                            open={!!anchorEl}
                            id={`edit-menu-${scenario}-${sortIndex}`}
                            keepMounted={false}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                        >
                            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                            <MenuItem onClick={handleNoteDeleteClick}>Delete</MenuItem>
                        </Menu>
                    </Box>
                )}
            </StyledBox>
        );
    }
);

interface LocationOrderNoteListProps {
    notes: LocationOrderNote[];
    disabled: boolean;
    scenario: string;
    isEditing: boolean;
    onNoteEditClick: (index: number, scenario: string) => void;
    onNoteDeleteClick: (index: number, scenario: string) => void;
}
const LocationOrderNoteList = SortableContainer<LocationOrderNoteListProps>(
    ({
        notes,
        disabled,
        scenario,
        isEditing,
        onNoteEditClick,
        onNoteDeleteClick
    }: LocationOrderNoteListProps) => {
        const renderRow = React.useCallback(
            (note: LocationOrderNote, index) => (
                <LocationOrderNoteRow
                    disabled={disabled}
                    key={`item-${note.title}`}
                    index={index}
                    sortIndex={index}
                    value={note}
                    scenario={scenario}
                    isEditing={isEditing}
                    onNoteEditClick={onNoteEditClick}
                    onNoteDeleteClick={onNoteDeleteClick}
                />
            ),
            [disabled, onNoteDeleteClick, onNoteEditClick, isEditing, scenario]
        );

        return <div>{notes.map(renderRow)}</div>;
    }
);

const CustomDragHandle = sortableHandle(({ className }: { className: string }) => (
    <DragHandle className={className} />
));

export const LocationOrderNotesView: React.FC<IProps> = ({ notes, setNotes, isEditing }) => {
    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
    const [editNote, setEditNote] = React.useState({ index: -1, scenario: null });

    const handleNoteEditClick = React.useCallback((index: number, scenario: string) => {
        setEditNote({ index, scenario });
    }, []);
    const handleNoteDelete = React.useCallback(
        (index: number, scenario: string) => {
            setNotes(notes => {
                const newNotes = { ...notes };
                newNotes[scenario] = removeArrayItem(newNotes[scenario], index);
                return newNotes;
            });
        },
        [setNotes]
    );

    const handleSortEnd = React.useCallback(
        (scenario: string) =>
            ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
                setNotes({ ...notes, [scenario]: [...arrayMove(notes[scenario], oldIndex, newIndex)] });
            },
        [notes, setNotes]
    );
    const renderNoteList = React.useCallback(
        ([scenario, notes], index: number, array: []) => (
            <Box marginBottom={index !== array.length - 1 && 2} key={`order-note-${index}`}>
                {OrderScenarioLabels[scenario]}
                <Box>
                    <LocationOrderNoteList
                        onSortEnd={handleSortEnd(scenario)}
                        disabled={!isEditing}
                        lockAxis="y"
                        notes={notes}
                        scenario={scenario}
                        isEditing={isEditing}
                        useDragHandle
                        onNoteEditClick={handleNoteEditClick}
                        onNoteDeleteClick={handleNoteDelete}
                        lockToContainerEdges
                    />
                </Box>
            </Box>
        ),
        [handleNoteDelete, handleNoteEditClick, handleSortEnd, isEditing]
    );
    const toggleIsCreateModalOpen = React.useCallback(
        () => setIsCreateModalOpen(!isCreateModalOpen),
        [isCreateModalOpen]
    );
    const handleCreateNewNote = React.useCallback(
        (note: LocationOrderNote, scenario: OrderScenario) => {
            setNotes((notesState: LocationOrderNoteMap) => {
                const newNotes = { ...notesState };
                if (Array.isArray(newNotes[scenario])) {
                    newNotes[scenario] = [...newNotes[scenario], note];
                } else {
                    newNotes[scenario] = [note];
                }

                return newNotes;
            });
            toggleIsCreateModalOpen();
        },
        [toggleIsCreateModalOpen, setNotes]
    );
    const editNoteInitialValues = React.useMemo(() => {
        if (editNote.index !== -1) {
            const { index, scenario } = editNote;
            return { ...notes[scenario][index], scenario };
        }

        return null;
    }, [editNote, notes]);
    const handleEditClose = React.useCallback(() => setEditNote({ index: -1, scenario: null }), []);
    const handleEdit = React.useCallback(
        (values: ILocationOrderNoteForm) => {
            setNotes(notes => {
                const newNotes = { ...notes };
                if (values.scenario === editNote.scenario) {
                    newNotes[values.scenario][editNote.index] = {
                        title: values.title,
                        description: values.description
                    };
                } else {
                    newNotes[editNote.scenario] = removeArrayItem(
                        newNotes[editNote.scenario],
                        editNote.index
                    );

                    newNotes[values.scenario] = [
                        ...(Array.isArray(newNotes[values.scenario]) ? newNotes[values.scenario] : []),
                        {
                            title: values.title,
                            description: values.description
                        }
                    ];
                }

                return newNotes;
            });

            handleEditClose();
        },
        [editNote.index, editNote.scenario, handleEditClose, setNotes]
    );

    return (
        <>
            <Grid container>
                {Object.keys(notes).length > 0 ? (
                    <Grid item xs={12}>
                        {Object.entries(notes)
                            .filter(([, notes]) => notes.length > 0)
                            .map(renderNoteList)}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <CardViewTextRowValue value="You have no notes configured" />
                    </Grid>
                )}
                {isEditing && (
                    <Grid item xs={12}>
                        <Box width="100%" display="flex" marginTop={2}>
                            <Button variant="outlined" onClick={toggleIsCreateModalOpen} color="secondary">
                                <AddIcon />
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <CreateOrderNoteModal
                open={isCreateModalOpen}
                title="Create a new note"
                onClose={toggleIsCreateModalOpen}
                onAddition={handleCreateNewNote}
            />
            {!!editNoteInitialValues && (
                <EditLocationOrderNote
                    onEdit={handleEdit}
                    onClose={handleEditClose}
                    open={editNote.index !== -1}
                    initialValues={editNoteInitialValues}
                />
            )}
        </>
    );
};

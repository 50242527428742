import React from 'react';
import { Typography } from '@mui/material';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';

export const ArteemoSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplaySwitch
                    title="Display External Member Number"
                    description="Should users' external member numbers be visible in the app"
                    checked={settings?.loyalty.displayExternalMemberNumber}
                />
                <SettingDisplay label="Host">{settings?.atreemo?.host}</SettingDisplay>
                <SettingDisplay label="Username">
                    <Typography variant="body2">{settings?.atreemo?.username}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Password">
                    <Typography variant="body2">{settings?.atreemo?.password}</Typography>
                </SettingDisplay>
                <SettingDisplay label="Loyalty Balance Redemption">
                    <Typography variant="body2">
                        {settings?.atreemo?.loyaltyBalanceRedemptionValue}
                    </Typography>
                </SettingDisplay>
                <SettingDisplay label="Loyalty Balance Voucher Category ID">
                    <Typography variant="body2">
                        {settings?.atreemo?.loyaltyBalanceVoucherCategoryId}
                    </Typography>
                </SettingDisplay>
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplay label="Atreemo Location ID">
                <Typography variant="body2">{settings?.atreemo?.locationId}</Typography>
            </SettingDisplay>
        )}
    </>
);

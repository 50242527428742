import React from 'react';
import { Grid, Typography } from '@mui/material';
import { IDisplay, isSquaredDisplay } from 'components/content/models/ContentModule';
import { AutoFormFields, LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';
import { getImageAspect, getImagePickerDescriptionByDisplay, MarketingMessageProps } from './base';

interface IContent {
    imageUrl: string;
    pageTitle: string;
    title: string;
    subtitle: string;
    body: string;
    buttonTitle: string;
    buttonUrl: string;
}

interface INativeItemProperties {
    imageUrl: string;
    // overlay text properties
    title?: string;
    subtitle?: string;
    subtitleTwo?: string;
    invertTextColour?: boolean;
    content: IContent;
}

const createNativeMessagePropertiesForm: (display: IDisplay) => AutoFormFields = (display: IDisplay) => {
    const shouldHideMessageOverlay = isSquaredDisplay(display);

    return {
        imageUrl: {
            label: 'Image',
            type: 'image',
            validation: 'required|url',
            aspect: getImageAspect(display),
            accept: ['image/jpeg', 'image/png', 'image/gif'],
            description: getImagePickerDescriptionByDisplay(display)
        },
        name: {
            label: 'Reporting Name',
            validation: 'required',
            description:
                'This code is used to track the number of marketing module item taps on anchor screen'
        },
        ...(!shouldHideMessageOverlay && {
            title: {
                label: 'Title'
            },
            subtitle: {
                label: 'Subtitle'
            },
            subtitleTwo: {
                label: 'Subtitle two'
            },
            invertTextColour: {
                label: 'Invert text colour',
                type: 'switch'
            },
            showTextBackgroundBar: {
                label: 'Show text background bar',
                type: 'switch'
            }
        }),
        'content.imageUrl': {
            label: 'Content image',
            type: 'image',
            aspect: getImageAspect(),
            accept: ['image/jpeg', 'image/png', 'image/gif']
        },
        'content.pageTitle': {
            label: 'Content page title',
            validation: 'required'
        },
        'content.title': {
            label: 'Content title',
            validation: 'required'
        },
        'content.subtitle': {
            label: 'Content subtitle'
        },
        'content.body': {
            label: 'Content body',
            type: 'multiline',
            validation: 'required'
        },
        'content.buttonTitle': {
            label: 'Button title'
        },
        'content.buttonUrl': {
            label: 'Button url'
        }
    };
};

function getFormFromContent(content: IContent) {
    if (!content) {
        return {};
    }
    return Object.entries(content).reduce((acc, [field, item]) => {
        acc[`content.${field}`] = item;
        return acc;
    }, {} as any);
}

export default class NativeMessageProperties extends React.PureComponent<
    MarketingMessageProps<INativeItemProperties>
> {
    static defaultProps = {
        properties: {}
    };
    browserMessagePropertiesForm = createNativeMessagePropertiesForm(this.props.display);
    form = new LegacyForm(this.browserMessagePropertiesForm, data => {
        const formattedData = Object.entries(data).reduce(
            (acc, [field, item]) => {
                if (!!item || this.browserMessagePropertiesForm[field].type === 'switch') {
                    if (field.startsWith('content.')) {
                        acc.content[field.substring(8, field.length)] = item;
                    } else {
                        acc[field] = item;
                    }
                }
                return acc;
            },
            { content: {} } as any
        );
        this.props.onSubmit(formattedData);
    }).update({
        name: this.props.name,
        ...this.props.properties,
        ...getFormFromContent(this.props.properties.content)
    });
    render() {
        const hideMarketingMessageOverlay = isSquaredDisplay(this.props.display);

        return (
            <AutoForm form={this.form}>
                {(
                    {
                        imageUrl,
                        title,
                        name,
                        subtitle,
                        subtitleTwo,
                        invertTextColour,
                        showTextBackgroundBar,
                        ...fields
                    },
                    render
                ) => (
                    <React.Fragment>
                        {imageUrl}
                        {name}
                        {!hideMarketingMessageOverlay && (
                            <>
                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Typography color="primary">Marketing message overlay</Typography>
                                    </Grid>
                                </Grid>
                                {title}
                                {subtitle}
                                {subtitleTwo}
                                {invertTextColour}
                                {showTextBackgroundBar}
                            </>
                        )}
                        <Grid item xs={12}>
                            <Grid container direction="column">
                                <Typography color="primary">Content</Typography>
                                <Typography variant="caption">
                                    Configure what you want your users to see when they tap on your marketing
                                    item
                                </Typography>
                            </Grid>
                        </Grid>
                        {render(fields)}
                        {this.props.contextForm()}
                    </React.Fragment>
                )}
            </AutoForm>
        );
    }
}

import React from 'react';
import { styled } from '@mui/material';
import { RGBAValue } from 'lib/form/fields/ColorFormField';
import { rgbaToHex } from 'lib/helpers';

function getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
}

interface StampCircleProps {
    stampColor: RGBAValue;
    stampImage?: string;
    reduced?: boolean;
    randomizeStampCard?: boolean;
    small?: boolean;
    filled?: boolean;
}

const PREFIX = 'StampCircle';

const classes = {
    root: `${PREFIX}-root`,
    img: `${PREFIX}-img`,
    div: `${PREFIX}-div`
};

const StyledDiv = styled('div')<StampCircleProps>(
    ({ theme, small, stampColor, reduced, randomizeStampCard }) => {
        const getStampSize = (small?: boolean, reduced?: boolean) =>
            small ? theme.spacing(reduced ? 2.5 : 3) : theme.spacing(reduced ? 4.5 : 5);
        const getImageSize = (small?: boolean, reduced?: boolean) =>
            small ? theme.spacing(reduced ? 2 : 2.5) : theme.spacing(reduced ? 3.5 : 4);
        const getMarginSize = (small?: boolean) => getRandomInt(small ? 2 : 6);
        const getImageMargin = (small?: boolean) =>
            `${getMarginSize(small)}px ${getMarginSize(small)}px ${getMarginSize(small)}px ${getMarginSize(
                small
            )}px`;
        // CHECK
        return {
            [`&.${classes.root}`]: {
                width: getStampSize(small, reduced),
                height: getStampSize(small, reduced),
                border: `1px solid ${rgbaToHex(stampColor)}`,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: theme.spacing(0.5),
                marginBottom: theme.spacing(0.5),
                transition: 'all .1s'
            },
            [`& .${classes.img}`]: {
                width: getImageSize(small, reduced),
                height: getImageSize(small, reduced),
                margin: randomizeStampCard ? getImageMargin(small) : 0,
                borderRadius: '50%',
                transform: `rotate(${randomizeStampCard ? getRandomInt(361) : 0}deg)`,
                transition: 'all .2s'
            },
            [`& .${classes.div}`]: {
                width: getImageSize(small, reduced),
                height: getImageSize(small, reduced),
                backgroundColor: rgbaToHex(stampColor),
                borderRadius: '50%',
                transition: 'all .1s'
            }
        };
    }
);

const StampCircleComponent: React.FC<StampCircleProps> = props => {
    const { stampImage, filled } = props;
    return (
        <StyledDiv className={classes.root} {...props}>
            {filled &&
                (stampImage ? (
                    <img src={stampImage} className={classes.img} alt="" />
                ) : (
                    <div className={classes.div} />
                ))}
        </StyledDiv>
    );
};

export const StampCircle = React.memo(
    StampCircleComponent,
    (prevProps, nextProps) =>
        prevProps.filled === nextProps.filled &&
        nextProps.stampImage === prevProps.stampImage &&
        prevProps.stampColor === nextProps.stampColor &&
        prevProps.randomizeStampCard === nextProps.randomizeStampCard &&
        prevProps.reduced === nextProps.reduced
);

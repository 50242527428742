import { Page } from './models/Page';
import { pages } from './pages';

const getPathname = (pathname: string) => {
    if (pathname !== '/') {
        // The leading / is only added to support static hosting (resolve /index.html).
        // We remove it to normalize the pathname.
        return pathname.replace(/\/$/, '');
    }
    return pathname;
};

export const findActivePage = (currentPages: Page[], pathname: string): Page => {
    const activePage = currentPages.find(page => {
        if (page.children) {
            return page.children.find(pageChild =>
                pathname.startsWith(pageChild.pathname) ? pageChild : false
            );
        }

        if (!page.pathname) {
            return null;
        }

        return pathname.startsWith(page.pathname);
    });

    if (!activePage) {
        return null;
    }

    return activePage;
};

export const getActivePage = (pathname: string) => findActivePage(pages, getPathname(pathname));

import { styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { titleize } from 'lib/helpers';
import React from 'react';

const PREFIX = 'TransactionStatusCell';

const classes = {
    green: `${PREFIX}-green`,
    yellow: `${PREFIX}-yellow`,
    red: `${PREFIX}-red`,
    grey: `${PREFIX}-grey`
};

const Root = styled('span')(({ theme }) => ({
    [`&.${classes.green}`]: { color: green[500] },
    [`&.${classes.yellow}`]: { color: orange[500] },
    [`&.${classes.red}`]: { color: red[400] },
    [`&.${classes.grey}`]: { color: theme.palette.grey[500] }
}));

type TransactionStatusCellProps = GridRenderCellParams;

export const TransactionStatusCell: React.FC<TransactionStatusCellProps> = ({ value }) => {
    const className = React.useMemo(() => {
        switch (value) {
            case 'COMPLETED':
                return classes.green;
            case 'PENDING':
                return classes.yellow;
            case 'DECLINED':
            case 'DISPUTED':
                return classes.red;
            default:
                return classes.grey;
        }
    }, [value]);
    return <Root className={className}>{titleize(String(value)).replace('_', ' ')}</Root>;
};

import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { Box, Button, Grid, Paper } from '@mui/material';
import { actionExportsApi } from 'components/analytics/export/actionExportsApi';
import { IActionOperation } from 'components/analytics/export/models';
import { ExportedDataTable } from 'components/analytics/export/tables/ExportedDataTable';
import { useInterval } from 'lib/hooks/useInterval';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_ACTION_EXPORT_ERROR, MESSAGE_ACTION_EXPORT_SUCCESS } from 'config/messages';
import logger from 'lib/logger';
import { useDispatch } from 'react-redux';
import { ActionExportDrawer } from 'components/analytics/export/drawer/ActionExportDrawer';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { LOCATIONS } from 'config/routes';
import { Throbber } from 'ui/Throbber';
import { Row } from 'ui/Flex';
import { ExportAnalyticsDialog } from 'components/analytics/export/ExportAnalyticsDialog';
import { getAllLocations } from 'store/locations/locationsActions';

function filterPendingOperations(item: IActionOperation) {
    return item.status === OperationStatus.PENDING || item.status === OperationStatus.RUNNING;
}

function mergeOperations(oOne: IActionOperation[], oTwo: IActionOperation[]) {
    return oOne.map(item => {
        const newOperation = oTwo.find(operation => operation._id === item._id);
        if (newOperation) {
            return newOperation;
        }
        return item;
    });
}

const ExportDataPage: React.FC = () => {
    const dispatch = useDispatch();
    const [exportOpen, setExportOpen] = React.useState(false);
    const [pendingOperations, setPendingOperations] = React.useState<IActionOperation[]>([]);
    const [isOperationsLoading, setOperationsLoading] = React.useState(false);
    const [exportOperations, setExportOperations] = React.useState<IActionOperation[]>([]);
    const [nextPageKey, setNextPageKey] = React.useState<string>();
    const [operationsError, setOperationsError] = React.useState<string>();
    const [selectedOperationId, setSelectedOperationId] = React.useState<string>(undefined);

    useInterval(() => {
        if (pendingOperations.length) {
            const promises = pendingOperations.map(item => actionExportsApi.getOperation(item._id));
            Promise.all(promises)
                .then(results => {
                    const resultOperations = results.map(item => item.body);
                    const pending = resultOperations.filter(filterPendingOperations);
                    setPendingOperations(pending);
                    if (resultOperations.length - pending.length) {
                        dispatch(enqueueSnackbar(MESSAGE_ACTION_EXPORT_SUCCESS, { variant: 'success' }));
                    }
                    setExportOperations(current => mergeOperations(current, resultOperations));
                })
                .catch(e => {
                    logger.error(e.message);
                    dispatch(enqueueSnackbar(MESSAGE_ACTION_EXPORT_ERROR, { variant: 'error' }));
                });
        }
    }, 1500);

    React.useEffect(() => {
        dispatch(getAllLocations());
    }, [dispatch]);

    const selectedOperation = React.useMemo(() => {
        if (!selectedOperationId) {
            return undefined;
        }
        const exportOperation = exportOperations.find(item => item._id === selectedOperationId);

        if (exportOperation) {
            return exportOperation;
        }

        return undefined;
    }, [selectedOperationId, exportOperations]);

    const loadActionExportOperations = React.useCallback(() => {
        setOperationsLoading(true);
        actionExportsApi
            .getOperations()
            .then(res => {
                setExportOperations(res.body.items);
                setPendingOperations(res.body.items.filter(filterPendingOperations));
                setNextPageKey(res.body.page.nextKey);
            })
            .catch(e => {
                setExportOperations([]);
                setNextPageKey(undefined);
                setOperationsError(e.message);
            })
            .finally(() => {
                setOperationsLoading(false);
            });
    }, []);
    React.useEffect(() => {
        loadActionExportOperations();
    }, [loadActionExportOperations]);

    const handleOperationsScroll = React.useCallback(() => {
        if (nextPageKey) {
            actionExportsApi
                .getOperations({ nextKey: nextPageKey })
                .then(res => {
                    setExportOperations(current => [...current, ...res.body.items]);
                    setNextPageKey(res.body.page.nextKey);
                })
                .catch(e => {
                    setNextPageKey(undefined);
                    dispatch(enqueueSnackbar(e.message || 'Failed to load exports', { variant: 'error' }));
                });
        }
    }, [dispatch, nextPageKey]);
    const handleOperationClick = React.useCallback((operation: IActionOperation) => {
        setSelectedOperationId(operation._id);
    }, []);
    const handleDrawerClose = React.useCallback(() => {
        setSelectedOperationId(undefined);
    }, []);
    const handleActionExportCreation = React.useCallback((exportOperation: IActionOperation) => {
        setPendingOperations(currentOperations => [...currentOperations, exportOperation]);
        setExportOperations(currentOperations => [exportOperation, ...currentOperations]);
    }, []);

    const handleExportClick = React.useCallback(() => {
        setExportOpen(true);
    }, []);

    const handleExportClose = React.useCallback(() => {
        setExportOpen(false);
    }, []);

    if (isOperationsLoading && !operationsError) {
        return <Throbber removeMargin text="We're just loading your exports..." />;
    }

    return (
        <MainLayout
            pageName="Export Data"
            pageDescription="Export data points on your customers' interactions with the app."
        >
            {!!operationsError && (
                <ErrorLayout title="Unexpected Error" fallbackUrl={LOCATIONS} fallbackTitle="Home" />
            )}
            {!operationsError && !isOperationsLoading && (
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper>
                                <Box padding={1}>
                                    <Row align="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleExportClick}
                                        >
                                            Create a new Export
                                        </Button>
                                    </Row>
                                </Box>

                                <Box marginY={2}>
                                    {!!exportOperations.length && (
                                        <ExportedDataTable
                                            operations={exportOperations}
                                            onScroll={handleOperationsScroll}
                                            onClick={handleOperationClick}
                                            nextKey={nextPageKey}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <ActionExportDrawer onClose={handleDrawerClose} operation={selectedOperation} />
                    <ExportAnalyticsDialog
                        open={exportOpen}
                        onCancel={handleExportClose}
                        onActionExportCreation={handleActionExportCreation}
                    />
                </>
            )}
        </MainLayout>
    );
};

export default ExportDataPage;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import { Perk } from 'components/loyalty/models/PerkModel';
import { MESSAGE_CUSTOMER_REDEEM_AWARD_ERROR, MESSAGE_CUSTOMER_REDEEM_AWARD_SUCCESS } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { customerApi } from '../customerApi';
import { Award, AwardTemplate, awardTemplateLabels } from '../models/Award';
import { Customer } from '../models/Customer';
import { Form, Formik } from 'formik';
import { AdditionalAwardRedeemFields } from '../forms/AdditionalAwardRedeemFields';
import { Row } from 'ui/Flex';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import * as Yup from 'yup';

const awardsToOptions = (award: Award) => ({
    value: award._id,
    label: award.title,
    description: awardTemplateLabels[award.template]
});

interface CustomerAwardFormProps {
    customer: Customer;
    perks: Perk[];
    awards: Partial<Award>[];
    onClose: () => void;
    onSuccess: () => void;
}

const initialValues = {
    award: '',
    amount: 0
};

const numberRegex = /^\d+$/;

export const CustomerRedeemAwardForm: React.FC<CustomerAwardFormProps> = ({
    customer,
    onClose,
    onSuccess,
    awards
}) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const enableRedeemButton = React.useCallback((state: boolean) => {
        setDisabled(!state);
    }, []);
    const awardOptions = React.useMemo(() => (awards ? awards.map(awardsToOptions) : []), [awards]);
    const validationSchema = Yup.object().shape({
        award: Yup.string().required('This field is required.'),
        amount: Yup.string().when('award', {
            is: (awardId: string) => {
                const currentAward = awards?.find(item => item._id === awardId);
                return (
                    currentAward &&
                    (currentAward.template === AwardTemplate.PSEUDO_CURRENCY ||
                        currentAward.template === AwardTemplate.STAMP_CARD)
                );
            },
            then: Yup.string()
                .required('This field is required.')
                .test(
                    'is-amount',
                    'This field is required.',
                    value => numberRegex.test(value) && Number(value) > 0
                )
        })
    });
    const handleSubmit = React.useCallback(
        async ({ award: awardId, amount }: { award: string; amount: number }) => {
            setLoading(true);
            const award = awards.find(item => item._id === awardId);
            if (!!award) {
                const amountToRedeem = Number(amount);
                const result = await customerApi.redeem(customer._id, awardId, {
                    body: {
                        valueToRedeem: amountToRedeem > 0 ? amountToRedeem : 1
                    }
                });
                if (result.ok) {
                    setLoading(false);
                    onSuccess();
                    return dispatch(
                        enqueueSnackbar(MESSAGE_CUSTOMER_REDEEM_AWARD_SUCCESS, { variant: 'success' })
                    );
                }
                setLoading(false);
                return dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_REDEEM_AWARD_ERROR, { variant: 'error' }));
            }
        },
        [awards, dispatch, onSuccess, customer._id]
    );
    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ values: { award, amount }, submitForm, isValid }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                Redeem {customer?.firstName}&apos;s award
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFormField name="award" label="Select an Award" options={awardOptions} />
                        </Grid>
                        <AdditionalAwardRedeemFields
                            awards={awards}
                            awardId={award}
                            amount={Number(amount)}
                            buttonEnabled={!disabled}
                            enableRedeemButton={enableRedeemButton}
                        />
                        <Grid item xs={12}>
                            <Row flex={1} align="flex-end" valign="center" gutter>
                                <Button onClick={onClose} color="primary" disabled={isLoading}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    disabled={isLoading || disabled || !isValid}
                                    loading={isLoading}
                                    onClick={submitForm}
                                    variant="contained"
                                    color="primary"
                                >
                                    Redeem
                                </LoadingButton>
                            </Row>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

import React from 'react';
import Geosuggest, { GeosuggestProps } from 'react-geosuggest';
import { MenuItem, styled } from '@mui/material';
import { Omit } from 'lib/types';

const PREFIX = 'Geosuggest';

const classes = {
    input: `${PREFIX}-input`,
    suggests: `${PREFIX}-suggests`,
    hiddenSuggests: `${PREFIX}-hiddenSuggests`
};

const StyledGeosuggest = styled(Geosuggest)(({ theme }) => {
    const light = theme.palette.mode === 'light';
    const bottomLineColor = light ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)';

    return {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        cursor: 'text',
        position: 'relative',
        width: '100%',
        '&:before': {
            borderBottom: `1px solid ${bottomLineColor}`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '"\\00a0"',
            position: 'absolute',
            right: 0,
            transition: theme.transitions.create('border-bottom-color', {
                duration: theme.transitions.duration.shorter
            }),
            pointerEvents: 'none' // Transparent to the hover style.
        },
        '&:after': {
            borderBottom: `2px solid ${theme.palette.primary[light ? 'dark' : 'light']}`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.easeOut
            }),
            pointerEvents: 'none' // Transparent to the hover style.
        },
        '&:focus-within:after': {
            transform: 'scaleX(1)'
        },
        '&:hover:before': {
            borderBottom: `2px solid ${theme.palette.text.primary}`,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                borderBottom: `1px solid ${bottomLineColor}`
            }
        },
        [`& .${classes.input}`]: {
            width: '100%',
            border: 'none',
            color: 'inherit',
            fontSize: '1.1428571428571428rem',
            lineHeight: '1.1875em',
            padding: '6px 0 7px'
        },
        [`& .${classes.suggests}`]: {
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            backgroundColor: theme.palette.background.paper,
            zIndex: 2,
            boxShadow: theme.shadows[1]
        },
        [`& .${classes.hiddenSuggests}`]: {
            maxHeight: 0,
            overflow: 'hidden',
            borderWidth: 0
        }
    };
});

export interface MuiGeosuggestProps extends Omit<GeosuggestProps, 'value'> {
    value: { lat: number; lng: number };
}

export const MuiGeosuggest = class extends React.Component<MuiGeosuggestProps> {
    state = {
        inputValue: ''
    };
    componentDidMount() {
        if (this.props.value) {
            const { lat, lng } = this.props.value;
            this.getLocationByGeo(lat, lng);
        }
    }
    getLocationByGeo = (lat: number, lng: number) => {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: latlng }, this.geocodeCallback);
    };
    geocodeCallback = (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (String(status) === 'OK' && result.length) {
            this.setState({ inputValue: result[0].formatted_address });
        }
    };
    handleChange = (inputValue: string) => {
        this.setState({ inputValue });
    };
    // (suggest: Suggest, inputValue: string) => broken index.d.ts(
    renderSuggestItem = (suggest: any) => <MenuItem component="div">{suggest.label}</MenuItem>;

    render() {
        const { inputValue } = this.state;
        return (
            <StyledGeosuggest
                suggestsHiddenClassName={classes.hiddenSuggests}
                inputClassName={classes.input}
                suggestsClassName={classes.suggests}
                onChange={this.handleChange}
                placeholder=""
                renderSuggestItem={this.renderSuggestItem}
                {...this.props}
                initialValue={inputValue}
                value={inputValue}
            />
        );
    }
};

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';
import { EOrderStatusFilter, orderStatusToFilter } from '../hooks/useOrdersFilter';

interface OrderStatusFilterProps {
    onChange: (e: SelectChangeEvent) => void;
    value?: EOrderStatusFilter;
}

const statusOptions = Object.entries(orderStatusToFilter).map(([value, label]) => ({ label, value }));

export const OrderStatusFilter: React.FC<OrderStatusFilterProps> = ({ onChange, value }) => {
    const renderOption = React.useCallback(
        (item: Option) => (
            <MenuItem key={`order-status-${item.value}`} value={item.value}>
                {item.label}
            </MenuItem>
        ),
        []
    );
    return (
        <Select value={value ?? EOrderStatusFilter.Any} name="status" onChange={onChange}>
            {statusOptions.map(renderOption)}
        </Select>
    );
};

/* eslint-disable unused-imports/no-unused-vars-ts */
import * as React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { GridEditCellProps } from '@mui/x-data-grid-pro';
import { parseFloatOrUndefined, roundToDecimal } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function GridEditPriceCell(props: GridEditCellProps & InputBaseProps) {
    const {
        id,
        value,
        formattedValue,
        api,
        field,
        row,
        colDef,
        cellMode,
        isEditable,
        tabIndex,
        hasFocus,
        getValue,
        rowNode,
        ...other
    } = props;
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const inputRef = React.useRef<HTMLInputElement>();
    const [valueState, setValueState] = React.useState(value);

    const handleBlur = React.useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const formattedAmount = parseFloatOrUndefined(valueState);

            if (!formattedAmount) {
                setValueState(0);
                return api.setEditCellValue({ id, field, value: 0 }, event);
            }
            const newValue = String(roundToDecimal(Math.abs(formattedAmount)).toFixed(2));
            setValueState(newValue);
            api.setEditCellValue({ id, field, value: newValue }, event);
        },
        [api, field, id, valueState]
    );

    const handleChange = React.useCallback(
        event => {
            const newValue = event.target.value.replace('-', '');
            setValueState(newValue);
            api.setEditCellValue({ id, field, value: newValue }, event);
        },
        [api, field, id]
    );

    React.useEffect(() => {
        setValueState(value);
    }, [value]);

    React.useLayoutEffect(() => {
        if (hasFocus) {
            inputRef.current.focus();
        }
    }, [hasFocus]);

    return (
        <InputBase
            inputRef={inputRef}
            fullWidth
            type={colDef.type === 'number' ? colDef.type : 'text'}
            value={valueState ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            startAdornment={settings?.region?.currencySymbol}
            {...other}
        />
    );
}
export const renderEditPriceCell = (params: GridEditCellProps) => <GridEditPriceCell {...params} />;

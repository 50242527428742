/* eslint-disable max-len */
import React from 'react';

import { IPerkBasketQualification, PerkDiscount } from 'components/loyalty/models/PerkModel';
import { LoyaltyScope } from 'components/loyalty/enums';
import { IAwardEnrichmentDateTimeRestriction } from 'components/loyalty/models/AwardEnrichment';
import { Option } from 'lib/types';

import { PerkFormStep } from 'components/perks/PerksEditStepper';
import { RedemptionAutoForm } from './RedemptionAutoForm';
import { RedemptionCommonForm } from './RedemptionCommonForm';

export interface AwardRedemptionFormData {
    token?: string;
    discount?: Partial<Omit<PerkDiscount, 'maxValue'>>;
    basketQualification?: Omit<IPerkBasketQualification, 'scope'> & {
        scope?: LoyaltyScope;
    };
    dateTimeRestriction: Omit<IAwardEnrichmentDateTimeRestriction, 'startTime' | 'daysOfWeek' | 'endTime'> & {
        startTime: string | undefined;
        endTime: string | undefined;
        daysOfWeek: string[];
    };
}

export interface AwardRedemptionFullFormData extends AwardRedemptionFormData {
    // this is UI only fields, do not forget to exclude them from the request
    dateTimeRestriction: AwardRedemptionFormData['dateTimeRestriction'] & {
        dateTimeRestictionOption: DateTimeOptionValue[];
    };
}

export interface AwardRedemptionFormProps {
    onSubmit: (data: AwardRedemptionFormData) => void;
    initialData?: AwardRedemptionFormData;
    isEdit?: boolean;
    isLoading?: boolean;
    isFormValid?: boolean;
    currencySymbol: string;
    perkType: string;
    onPrevious?: (isValid?: boolean, values?: AwardRedemptionFormData) => void;
    clickPrevious: () => void;
    onUpdate?: (values: { type: PerkFormStep.REDEMPTION; values: AwardRedemptionFormData }) => void;
    auto?: boolean;
}

const dateAndTimeRestricionOptions: Option[] = [
    {
        value: 'date',
        label: 'specific start and end date'
    },
    {
        value: 'time',
        label: 'specific start and end time'
    },
    {
        value: 'day',
        label: 'particular weekday'
    }
];
export type DateTimeOptionValue = typeof dateAndTimeRestricionOptions[number]['value'];

export const RedemptionForm: React.FC<AwardRedemptionFormProps> = ({ auto, ...props }) => {
    if (auto) {
        return <RedemptionAutoForm {...props} auto />;
    }
    return <RedemptionCommonForm {...props} />;
};

import { TableCell } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { MuiCircularProgress } from 'lib/MuiCircularProgress';
import * as React from 'react';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { PepperPayOperation } from '../model/PepperPay';
import { pepperPayApi } from '../pepperPayApi';
import { getOperationTypeAndFormat } from './utils';

interface PepperPayReportingCellAction {
    operation: PepperPayOperation;
}

export const ReportingDownloadActionCell: React.FC<PepperPayReportingCellAction> = ({ operation }) => {
    const [loading, setLoading] = React.useState(false);
    const { format, type } = React.useMemo(
        () => getOperationTypeAndFormat(operation.metadata.report_type),
        [operation.metadata.report_type]
    );
    const handleButtonClick = React.useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setLoading(true);
            if (operation.result) {
                const response = await pepperPayApi.file.downloadFile(operation.result.id);
                if (response.status === 200) {
                    const blob = (await (response as any).blob()) || new Blob();

                    const downloadUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = `PepperReport-${type}-${format}-${
                        operation.metadata.parameters.connected_account
                    }-${operation.nonce.replaceAll('-', '')}.csv`;

                    document.body.appendChild(link);

                    link.click();
                    link.remove();

                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL.
                        // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
                        window.URL.revokeObjectURL(downloadUrl);
                    }, 100);
                }
                setLoading(false);
            }
        },
        [format, operation.metadata.parameters.connected_account, operation.nonce, operation.result, type]
    );
    return (
        <TableCell align="center" size="small">
            <MuiCircularProgress
                progress={operation.result?.href && 100}
                isError={operation.status === OperationStatus.ERROR}
            >
                {operation.status === OperationStatus.DONE && operation.result && operation.result?.href && (
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        onClick={handleButtonClick}
                    >
                        Download
                    </LoadingButton>
                )}
            </MuiCircularProgress>
        </TableCell>
    );
};

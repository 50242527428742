import React from 'react';
import { Route, Switch } from 'react-router';
import {
    ACTION,
    FORGOT_YOUR_PASSWORD,
    FORGOT_YOUR_PASSWORD_CONFIRM,
    HEALTH,
    JOIN,
    SIGN_IN,
    SIGN_UP,
    UNAUTHORIZED
} from 'config/routes';
import { PrivateRoute } from 'lib/PrivateRoute';
import ForgotYourPassword from 'pages/forgot-your-password';
import ForgotYourPasswordConfirm from 'pages/forgot-your-password/confirm';
import Health from 'pages/health';
import Join from 'pages/join';
import SignIn from 'pages/sign-in';
import SignUp from 'pages/sign-up';
import Unathorized from 'pages/unauthorized';
import { Throbber } from 'ui/Throbber';
import ActionPage from './action';
import Authorized from './authorized';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getPublicAndPrivateSettings, resetPublicSettings } from 'store/settings/settingsActions';

interface RootProps {
    isFirebaseLoaded: boolean;
}

const RootPure: React.FC<RootProps> = ({ isFirebaseLoaded }) => {
    const dispatch = useDispatch();
    const { tenant, claims } = useSelector((state: ApplicationState) => state.auth);

    React.useEffect(() => {
        if (isFirebaseLoaded && tenant?._id) {
            getPublicAndPrivateSettings(claims)(dispatch);
        }
        return () => {
            resetPublicSettings()(dispatch);
        };
    }, [claims, dispatch, isFirebaseLoaded, tenant?._id]);
    if (!isFirebaseLoaded) {
        return <Throbber />;
    }
    return (
        <Switch>
            <Route exact path={ACTION} component={ActionPage} />
            <Route exact path={HEALTH} component={Health} />
            <Route exact path={UNAUTHORIZED} component={Unathorized} />
            <Route exact path={SIGN_IN} component={SignIn} />
            <Route exact path={SIGN_UP} component={SignUp} />
            <Route exact path={FORGOT_YOUR_PASSWORD} component={ForgotYourPassword} />
            <Route exact path={FORGOT_YOUR_PASSWORD_CONFIRM} component={ForgotYourPasswordConfirm} />
            <Route exact path={JOIN} component={Join} />
            <PrivateRoute path="/" component={Authorized} />
        </Switch>
    );
};

export const Root = React.memo(RootPure);

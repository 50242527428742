import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { GlobalSettings } from 'components/settings/GlobalSettings';
import { LocationScopedSettings } from 'components/settings/LocationScopedSettings';
import { MERCHANT_MOBILE_SETTINGS } from 'config/routes';

export const GlobalSettingsPage: React.FC = () => (
    <MainLayout
        pageName="Global Settings"
        pageDescription="Adjust your general settings and Android/iOS specific settings."
        breadcrumbs={[{ label: 'Settings', url: MERCHANT_MOBILE_SETTINGS }]}
    >
        <LocationScopedSettings globalComponent={GlobalSettings} />
    </MainLayout>
);

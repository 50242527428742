import { Crud, httpClient, HttpClient } from 'lib/HttpClient';

class GiftingReportsActivityApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.POWERCARD_URL, 'reports/gift/activity', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            Accept: 'application/json'
        });
    }
}

class GiftingReportsLiabilityApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.POWERCARD_URL, 'reports/gift/liability', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            Accept: 'application/json'
        });
    }
}

export const giftingApi = {
    activityReport: new GiftingReportsActivityApi(httpClient),
    liabilityReport: new GiftingReportsLiabilityApi(httpClient)
};

import React from 'react';
import { ValueType } from 'react-select';
import { MultiSelectSuggest, MultiSelectSuggestProps } from 'lib/MultiSelectSuggest';
import { Option } from 'lib/types';
import { isDefined } from './typeguards';

interface ChipInputProps extends MultiSelectSuggestProps {
    value: ValueType<Option, true> | ValueType<Option, false>;
    placeholder?: string;
    onChange: (value: ValueType<Option, true> | ValueType<Option, false>) => void;
    label?: React.ReactNode;
    options?: Option[];
}

export const ChipInput: React.FC<ChipInputProps> = props => {
    const [inputValue, setInputValue] = React.useState('');
    const { onChange, value, isCreatable = true } = props;
    const handleInputChange = React.useCallback((newValue: string) => {
        setInputValue(newValue);
    }, []);
    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            if (!inputValue) {
                return;
            }
            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    setInputValue('');
                    if (
                        !isDefined(value) ||
                        (Array.isArray(value) && value.every(item => item.value !== inputValue))
                    ) {
                        onChange([...(value ?? []), { value: inputValue, label: inputValue }]);
                    }
                    event.preventDefault();
            }
        },
        [inputValue, onChange, value]
    );
    return (
        <MultiSelectSuggest
            isCreatable={isCreatable}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            inputValue={inputValue}
            {...props}
        />
    );
};

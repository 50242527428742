import React from 'react';
import { Drawer, styled } from '@mui/material';
import { ActionExportDrawerContent } from './ActionExportDrawerContent';
import { IActionOperation } from 'components/analytics/export/models';

const PREFIX = 'ActionExportDrawer';

const classes = {
    paper: `${PREFIX}-paper`
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        width: theme.spacing(52),
        paddingBottom: theme.spacing(10)
    }
}));

interface OverwritesDrawerProps {
    onClose: () => void;
    operation?: IActionOperation;
}

export const ActionExportDrawer: React.FC<OverwritesDrawerProps> = props => {
    const { onClose, operation } = props;

    return (
        <StyledDrawer
            anchor="right"
            hideBackdrop
            open={!!operation}
            classes={{ paper: classes.paper }}
            disableScrollLock
            disableEnforceFocus
            style={{ left: 'auto' }}
        >
            <ActionExportDrawerContent operation={operation} onClose={onClose} />
        </StyledDrawer>
    );
};

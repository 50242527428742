import React from 'react';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';

export function TevalisSettingsDisplay({ settings, locationId }: IPointOfSaleSettingsProps) {
    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <>
                    <SettingDisplayText title="CompanyId" text={settings?.tevalis?.companyId} />
                    <SettingDisplayText title="DevId" text={settings?.tevalis?.devID} />
                    <SettingDisplayText title="Guid" text={settings?.tevalis?.guid} />
                    <SettingDisplayText title="Guid2" text={settings?.tevalis?.guid2} />
                    <SettingDisplayText
                        title="Placeholder Item PLU"
                        text={settings?.tevalis?.placeholderItemPLU}
                    />
                </>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <SettingDisplayText title="Site Id" text={settings?.tevalis?.siteId} />
            )}
            {/* Both */}
            <SettingDisplayText
                title="Pre-Order Price Group Id"
                text={settings?.tevalis?.PREORDER_priceGroupId}
            />
            <SettingDisplayText
                title="Order to Table Price Group Id"
                text={settings?.tevalis?.ORDER_TO_TABLE_priceGroupId}
            />
            <SettingDisplayText
                title="Pay at Table Price Group Id"
                text={settings?.tevalis?.TABLE_priceGroupId}
            />
            <SettingDisplayText title="Tab Price Group Id" text={settings?.tevalis?.TAB_priceGroupId} />
            <SettingDisplayText title="Payment Tender Type" text={settings?.tevalis?.tenderType} />
            <SettingDisplayText title="Discount Tender Type" text={settings?.tevalis?.discountTenderType} />
        </>
    );
}

import { MENU_MANAGER_ENRICHMENTS } from 'config/routes';
import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router';

export const RedirectToNewMenu = () => {
    const { menuId, locationId, menuChangeId, tabId } =
        useParams<{ menuId?: string; locationId?: string; menuChangeId?: string; tabId?: string }>();
    const { search } = useLocation();
    const path = React.useMemo(
        () =>
            MENU_MANAGER_ENRICHMENTS.replace(':menuId', menuId)
                .replace(':locationId', locationId)
                .replace(':menuChangeId', menuChangeId)
                .replace(':tabId', tabId || 'products'),
        [locationId, tabId, menuId, menuChangeId]
    );
    return <Redirect to={{ pathname: path, search }} />;
};

import { isDefined, isOptional, isOptionalOrNull, isString } from 'lib/typeguards';

export interface SegmentUser {
    userId: string;
    expires?: string;
    userFullName?: string;
}

export function isSegmentUser(user: any): user is SegmentUser {
    return (
        isDefined(user) &&
        isString(user.userId) &&
        isOptionalOrNull(isString, user.expires) &&
        isOptional(isString, user.userFullName)
    );
}

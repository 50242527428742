import React from 'react';
import { useDropzone } from 'react-dropzone';
import { styled, Theme, Typography } from '@mui/material';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';
import clsx from 'clsx';
import { Row } from 'ui/Flex';
import { MUIStyledCommonProps } from '@mui/system';

const PREFIX = 'MuiDropZone';

const classes = {
    image: `${PREFIX}-image`,
    label: `${PREFIX}-label`,
    activeLabel: `${PREFIX}-activeLabel`,
    overlay: `${PREFIX}-overlay`
};

interface StyledDivProps extends MUIStyledCommonProps<Theme> {
    aspect?: number;
    fullWidth?: boolean;
}

const StyledDiv: any = styled('div')(({ theme, aspect, fullWidth }: StyledDivProps) => ({
    marginTop: theme.spacing(1),
    width: (fullWidth && '100%') || (aspect ? theme.spacing(18 * aspect) : '100%'),
    maxWidth: '100%',
    cursor: 'pointer',
    display: 'block',
    overflow: 'hidden',
    boxSizing: 'content-box',
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    '&:after': {
        content: 'no-open-quote',
        display: 'block',
        paddingTop: `${aspect ? 100 * (1 / aspect) : 30}%`
    },
    [`& .${classes.image}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: '100%',
        borderRadius: theme.shape.borderRadius
    },
    [`& .${classes.label}`]: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        fontSize: 28,
        transform: 'translate(-50%, -50%)',
        color: theme.palette.divider
    },
    [`& .${classes.activeLabel}`]: {
        color: theme.palette.common.white
    },
    [`& .${classes.overlay}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: '100%',
        borderRadius: theme.shape.borderRadius
    }
}));

interface MuiDropZoneProps {
    onSelect: (src: string | ArrayBuffer | File, file?: File) => void;
    aspect?: number;
    fullWidth?: boolean;
    className?: string;
    imageUrl?: string;
    overlay?: React.ReactNode;
    smallText?: boolean;
    accept?: string | string[];
    submitFileAs?: 'BYTE' | 'FILE';
}

const DEFAULT_ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png'];

export const MuiDropZone: React.FC<MuiDropZoneProps> = function (props) {
    const {
        onSelect,
        imageUrl,
        overlay,
        smallText,
        submitFileAs = 'BYTE',
        accept = DEFAULT_ACCEPTED_FILE_TYPES,
        className,
        aspect
    } = props;
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            if (submitFileAs === 'BYTE') {
                let gifFile: File | undefined;
                const reader = new FileReader();
                reader.onload = () => {
                    const binaryStr = reader.result;
                    onSelect(binaryStr, gifFile);
                };
                acceptedFiles.forEach(file => {
                    if (file.type === 'image/gif') {
                        gifFile = file;
                    }
                    reader.readAsDataURL(file);
                });
            } else {
                acceptedFiles.forEach(file => onSelect(file));
            }
        },
        [onSelect, submitFileAs]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept
    });

    return (
        <StyledDiv {...getRootProps()} className={className} aspect={aspect} fullWidth={props.fullWidth}>
            <input {...getInputProps()} />
            {imageUrl && <img className={classes.image} src={imageUrl} alt="" />}
            {!!overlay && <div className={classes.overlay}>{overlay}</div>}
            <Row gutter valign="center" className={clsx(classes.label, imageUrl && classes.activeLabel)}>
                {!imageUrl &&
                    (isDragActive ? (
                        <Typography variant={smallText ? 'caption' : 'inherit'} color="inherit">
                            Upload photo
                        </Typography>
                    ) : (
                        <Typography variant={smallText ? 'caption' : 'inherit'} color="inherit">
                            Drag or upload your photo
                        </Typography>
                    ))}
                <AddPhotoAlternate color="inherit" fontSize="inherit" />
            </Row>
        </StyledDiv>
    );
};

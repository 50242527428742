import { contentApi } from 'components/content/contentApi';
import { MAX_IMAGE_UPLOAD_SIZE, MAX_IMAGE_UPLOAD_SIZE_ERROR } from 'config/const';
import { resizeImage } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { Crop } from 'react-image-crop';
import { v4 } from 'uuid';
import { IAdditionalUpload } from '.';

interface BlobWithName extends Blob {
    name?: string;
}

interface ICropConfig {
    aspect?: number;
    height?: number;
    width?: number;
    uploadFileName?: string;
    uploadFolder?: string;
    additionalUploads?: IAdditionalUpload[];
    onSelect: (imageUrl: string) => void;
    randomNamePrefix?: string;
}

const getDefaultCrop = (props: ICropConfig) => {
    let aspect: number;
    if (props.aspect) {
        // eslint-disable-next-line prefer-destructuring
        aspect = props.aspect;
    } else {
        aspect = props.width && props.height ? props.width / props.height : null;
    }
    return {
        aspect,
        width: props.width,
        height: props.height,
        x: 0,
        y: 0
    };
};

export function useImageCrop(
    config: ICropConfig,
    onStartSuccess: () => void,
    onCloseSuccess: () => void,
    onUploadSuccess: (resultUrl: string) => void,
    file?: File
) {
    const imageRef = React.useRef<HTMLImageElement>();

    const [crop, setCrop] = React.useState<Crop>(getDefaultCrop(config));
    const [cropLoading, setCropLoading] = React.useState(false);
    const [cropError, setCropError] = React.useState<string>();
    const [blob, setBlob] = React.useState<BlobWithName>();

    const isCropAvailable = React.useMemo(() => file && file.type !== 'image/gif', [file]);

    const getCroppedImg = React.useCallback(
        (image: HTMLImageElement, crop: Crop, fileName: string): Promise<{ blob: Blob }> => {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width * scaleX;
            canvas.height = crop.height * scaleY;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );

            return new Promise((resolve, reject) => {
                canvas.toBlob((blob: BlobWithName) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                    }
                    if (blob.size >= MAX_IMAGE_UPLOAD_SIZE) {
                        reject(new Error(MAX_IMAGE_UPLOAD_SIZE_ERROR));
                    }
                    blob.name = fileName;
                    resolve({ blob });
                });
            });
        },
        []
    );

    const makeClientCrop = React.useCallback(
        async (crop: Crop) => {
            if (imageRef.current && crop.width && crop.height) {
                setCropLoading(true);
                setCropError(undefined);
                try {
                    const { blob } = await getCroppedImg(
                        imageRef.current,
                        crop,
                        config.uploadFileName ?? `${v4()}` // For compatibility
                    );
                    setCropError(undefined);
                    setCropLoading(false);
                    setBlob(blob);
                } catch (error) {
                    setCropError(error.message);
                    setCropLoading(false);
                }
            }
        },
        [config.uploadFileName, getCroppedImg]
    );

    const onImageLoaded = React.useCallback(
        (image: HTMLImageElement) => {
            imageRef.current = image;
            makeClientCrop({ ...crop, height: image.height, width: image.width });
        },
        [crop, makeClientCrop]
    );
    const onCropComplete = React.useCallback(
        (crop: Crop) => {
            makeClientCrop(crop);
        },
        [makeClientCrop]
    );
    const onCropChange = React.useCallback((crop: Crop) => {
        setCrop(crop);
    }, []);
    const onCropStart = React.useCallback(() => {
        onStartSuccess();
    }, [onStartSuccess]);
    const onCropClose = React.useCallback(() => {
        onCloseSuccess();
        setCrop(getDefaultCrop(config));
    }, [config, onCloseSuccess]);
    const onCropSubmit = React.useCallback(async () => {
        setCropLoading(true);
        setCropError(undefined);
        const formData = new FormData();
        formData.append(
            'files',
            blob,
            config.randomNamePrefix ? `${config.randomNamePrefix}${v4()}` : blob.name
        );
        try {
            const result = await contentApi.media.uploadFile(
                { body: formData, skipStringify: true },
                config.uploadFolder
            );
            if (!result.ok) {
                throw new Error('Failed to upload an image');
            }

            // There may be additional uploads to consider, with specific sizes
            // This is used in branding images, for iOS icons and such.
            if (config.additionalUploads) {
                await Promise.all(
                    config.additionalUploads.map(
                        async ({ width, height, format, uploadFolder, fileName }) => {
                            const resizedFile = await resizeImage(blob, width, height, format);
                            const formData = new FormData();
                            formData.append(
                                'files',
                                resizedFile,
                                config.randomNamePrefix ? `${config.randomNamePrefix}${v4()}` : fileName
                            );
                            await contentApi.media.uploadFile(
                                { body: formData, skipStringify: true },
                                uploadFolder
                            );
                        }
                    )
                );
            }
            // TODO: find a way to do it better
            const resultImageUrl = result.body.items[0].url;
            config.onSelect(resultImageUrl);
            const date = new Date().toTimeString();
            setCropLoading(false);
            onUploadSuccess(`${resultImageUrl}?${date}`);
        } catch (e) {
            logger.error('Failed to upload an image', e);
            setCropError(e.message);
            setCropLoading(false);
        }
    }, [blob, config, onUploadSuccess]);

    return {
        crop,
        cropLoading,
        isCropAvailable,
        cropError,
        onCropChange,
        onCropStart,
        onCropClose,
        onCropComplete,
        onImageLoaded,
        onCropSubmit
    };
}

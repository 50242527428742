import { IMenuOverrideEditUpdate, MenuOverridesFilter } from '../location/models/Overrides';
import { Crud, HttpClient, httpClient, QueryParameter } from 'lib/HttpClient';
import { setHours, setMinutes, isValid, format } from 'date-fns';
import { ProductOverride } from '@pepperhq/menu-sdk';
import { v4 as uuid } from 'uuid';

interface IMenuOverridesBulkUpdateBody {
    update: {
        schema: number;
        products: Record<string, Partial<ProductOverride>>;
    };
    locationIds: string[];
}

class MenuOverridesApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.MENU_SERVICE_URL, 'menuoverrides', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'x-api-version': 6
        });
    }

    getMenuOverridesEdit(locationId: string, filter?: MenuOverridesFilter) {
        const formatter = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
        const filterMerged: Omit<MenuOverridesFilter, 'time'> = {
            zone: filter.zone,
            scenario: filter.scenario,
            date: filter.date && isValid(new Date(filter.date)) && formatter(new Date(filter.date))
        };

        if (filter.time && isValid(new Date(filter.time))) {
            if (filter.date && isValid(new Date(filter.date))) {
                filterMerged.date = setHours(
                    new Date(filter.date),
                    new Date(filter.time).getHours()
                ).toString();
                filterMerged.date = setMinutes(
                    new Date(filterMerged.date),
                    new Date(filter.time).getMinutes()
                ).toString();
                filterMerged.date = formatter(new Date(filterMerged.date));
            } else {
                filterMerged.date = formatter(new Date(filter.time));
            }
        }
        const filterQueryParams: QueryParameter[] = Object.entries(filterMerged || {})
            .map(([key, value]: [keyof MenuOverridesFilter, string]) => ({ key, value }))
            .filter(({ value }) => !!value);
        return this.httpClient.get(`${process.env.MENU_SERVICE_URL}/menuoverrides/${locationId}/edit`, {
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            },
            queryParameters: filterQueryParams
        });
    }

    updateMenuOverride(locationId: string, body: IMenuOverrideEditUpdate) {
        return this.httpClient.put(`${process.env.MENU_SERVICE_URL}/menuoverrides/${locationId}`, {
            body,
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
            }
        });
    }
    bulkUpdateMenuOverride(body: IMenuOverridesBulkUpdateBody) {
        const nonce = uuid();
        return this.httpClient.post(`${process.env.MENU_SERVICE_URL}/menuoverrides/bulkupdate`, {
            body,
            headers: {
                'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
                'x-pepper-req-nonce': nonce
            }
        });
    }
}

export const menuOverridesApi = new MenuOverridesApi(httpClient);

import { IGroupByOption } from 'components/overwrites/dialogs/ExportGroupsDialog';

export const GROUP_BY_OPTIONS: IGroupByOption[] = [
    {
        value: 'default',
        text: 'Default'
    },
    {
        value: 'allergens',
        text: 'Allergens'
    },
    {
        value: 'categoryGroups',
        text: 'Category Groups'
    },
    {
        value: 'dailyAvailability',
        text: 'Daily Availability'
    },
    {
        value: 'nutritionInfo',
        text: 'Nutrition Info'
    },
    {
        value: 'general',
        text: 'General'
    },
    {
        value: 'perkCodes',
        text: 'Perk Codes'
    },
    {
        value: 'sortHide',
        text: 'Sort Hide'
    },
    {
        value: 'tagGroups',
        text: 'Tag Groups'
    },
    {
        value: 'taxIds',
        text: 'Tax Ids'
    },
    {
        value: 'taxes',
        text: 'Taxes'
    },
    {
        value: 'weeklyAvailability',
        text: 'Weekly Availability Info'
    }
];

export const SELECTED_OPTIONS_LOCAL_STORAGE_ITEM = 'SELECTED_GROUP_BY_OPTIONS';

import { SelectChangeEvent } from '@mui/material';
import React, { ChangeEventHandler } from 'react';
import { IOrder, IOrderPayment } from '../model/Order';
import { roundToDecimal } from 'lib/helpers';
import { useBaseRefundFields } from './useBaseRefundFields';

interface OrderRefundField<T, V = string> {
    value: V | undefined;
    onChange: T;
}

interface OrderRefundHook {
    refundType: OrderRefundField<(e: { target: { value: string } }) => void>;
    reason: OrderRefundField<(e: SelectChangeEvent) => void>;
    notes: OrderRefundField<ChangeEventHandler<HTMLInputElement>>;
    amount: number;
    isRefundable: boolean;
    refundableAmount: number;
}

export enum EOrderRefundType {
    Custom = 'CUSTOM',
    Calculated = 'CALCULATED'
}

export function useOrderRefund(
    payment: IOrderPayment,
    order: IOrder,
    customAmount?: number,
    calculatedAmount?: number
): OrderRefundHook {
    const refundedAmount = React.useMemo(() => {
        const refundPayments = order.payments.filter(orderPayment => orderPayment.parentId === payment.id);
        if (refundPayments.length) {
            return refundPayments.reduce((acc, item) => acc + item.amount, 0);
        }
        return undefined;
    }, [order.payments, payment.id]);
    const refundableAmount = React.useMemo(
        () => roundToDecimal(payment.amount + (refundedAmount ?? 0)),
        [payment.amount, refundedAmount]
    );
    const [refundType, setRefundType] = React.useState<EOrderRefundType>(EOrderRefundType.Calculated);
    const { notes, reason } = useBaseRefundFields();
    const handleRefundTypeChange = React.useCallback((e: SelectChangeEvent) => {
        setRefundType(
            e.target.value === EOrderRefundType.Custom ? EOrderRefundType.Custom : EOrderRefundType.Calculated
        );
    }, []);
    const amount = React.useMemo(
        () => (refundType === EOrderRefundType.Calculated ? calculatedAmount : customAmount),
        [calculatedAmount, customAmount, refundType]
    );
    const isRefundable = React.useMemo(
        () => refundableAmount >= amount && amount > 0 && !!reason.value?.length,
        [amount, reason, refundableAmount]
    );
    return {
        refundType: { value: refundType, onChange: handleRefundTypeChange },
        reason,
        notes,
        amount: amount ?? 0,
        isRefundable,
        refundableAmount
    };
}

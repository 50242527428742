import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';
import { TextFormField } from 'lib/form/fields/TextFormField';

export const RevelGiftCardSettingsEdit: React.FC<IPointOfSaleSettingsProps> = ({ locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <SettingDisplay label="User ID">
                <TextFormField name="revelGiftCardUserId" />
            </SettingDisplay>
        )}
    </>
);

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { Option } from 'lib/types';
import React from 'react';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { fromModifierProductKey } from '@pepperhq/menu-sdk';

interface CreateModifierProductDialogProps {
    open: boolean;
    categories: Option[];
    modifiers: Option[];
    modifierProducts: Option[];
    modifierByCategory: Record<string, Set<string>>;
    productByModifier: Record<string, Set<string>>;
    onSubmit: (categoryId: string, modifierId: string, modifierProductId: string) => void;
    onCancel: () => void;
    deafultId?: string;
}

export const CreateModifierProductDialog: React.FC<CreateModifierProductDialogProps> = ({
    open,
    categories,
    modifiers,
    modifierProducts,
    modifierByCategory,
    productByModifier,
    deafultId,
    onSubmit,
    onCancel
}) => {
    const [touched, setTouched] = React.useState({ productId: false, categoryId: false, modifierId: false });
    const { categoryId, modifierId, id } = fromModifierProductKey(deafultId ?? '');
    const [selectedCategoryId, setSelectedCategoryId] = React.useState(categoryId ?? '');
    const [selectedModifierId, setSelectedModifierId] = React.useState(modifierId ?? '');
    const [selectedModifierProductId, setSelectedModifierProductId] = React.useState(id ?? '');
    const handleCategoryChange = React.useCallback((_: React.SyntheticEvent, value: Option) => {
        setTouched(prev => ({ ...prev, categoryId: true }));
        setSelectedCategoryId(value?.value ?? '');
    }, []);
    const handleCategoryIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(prev => ({ ...prev, categoryId: true }));
        setSelectedCategoryId(e.target.value ?? '');
    }, []);
    const handleModifierChange = React.useCallback((_: React.SyntheticEvent, value: Option) => {
        setTouched(prev => ({ ...prev, modifierId: true }));
        setSelectedModifierId(value?.value ?? '');
    }, []);
    const handleModifierIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(prev => ({ ...prev, modifierId: true }));
        setSelectedModifierId(e.target.value ?? '');
    }, []);
    const handleModifierProductChange = React.useCallback((_: React.SyntheticEvent, value: Option) => {
        setTouched(prev => ({ ...prev, productId: true }));
        setSelectedModifierProductId(value?.value ?? '');
    }, []);
    const handleModifierProductIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(prev => ({ ...prev, productId: true }));
        setSelectedModifierProductId(e.target.value ?? '');
    }, []);
    const selectedCategory = React.useMemo(
        () => categories.find(item => item.value === selectedCategoryId) ?? null,
        [categories, selectedCategoryId]
    );
    const selectedModifier = React.useMemo(
        () => modifiers.find(item => item.value === selectedModifierId) ?? null,
        [modifiers, selectedModifierId]
    );
    const selectedModifierProduct = React.useMemo(
        () => modifierProducts.find(item => item.value === selectedModifierProductId) ?? null,
        [modifierProducts, selectedModifierProductId]
    );
    const isCategoryError = touched.categoryId && !selectedCategory;
    const isModifierError =
        touched.modifierId &&
        (!selectedModifier || !modifierByCategory[selectedCategoryId]?.has(selectedModifier.value));
    const isModifierProductError =
        touched.productId &&
        (!selectedModifierProduct || !productByModifier[selectedModifierId]?.has(selectedModifierProductId));
    const valid = React.useMemo(
        () => !!selectedCategory && !!selectedModifier && !!selectedModifierProduct,
        [selectedCategory, selectedModifier, selectedModifierProduct]
    );
    const renderCategoriesInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                error={isCategoryError}
                helperText={isCategoryError && 'Please select a category from the list.'}
                label="Category"
            />
        ),
        [isCategoryError]
    );
    const renderModifierInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                error={isModifierError}
                label="Modifier"
                helperText={isModifierError && 'Please select a modifier from the list.'}
            />
        ),
        [isModifierError]
    );
    const renderModifierProductInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                error={isModifierProductError}
                label="Modifier Product"
                helperText={isModifierProductError && 'Please select a modifier product from the list.'}
            />
        ),
        [isModifierProductError]
    );
    const isTouched = React.useMemo(
        () => touched.categoryId || touched.modifierId || touched.productId,
        [touched.categoryId, touched.modifierId, touched.productId]
    );
    const handleSubmit = React.useCallback(() => {
        if (!isTouched) {
            setTouched({ productId: true, categoryId: true, modifierId: true });
            if (!valid) {
                return;
            }
        }
        onSubmit(selectedCategoryId, selectedModifierId, selectedModifierProductId);
    }, [isTouched, onSubmit, selectedCategoryId, selectedModifierId, selectedModifierProductId, valid]);
    const filteredModifiers = React.useMemo(() => {
        const selectedIds = modifierByCategory[selectedCategoryId];
        if (!selectedIds) {
            return [];
        }
        return modifiers.filter(item => selectedIds.has(item.value));
    }, [modifierByCategory, modifiers, selectedCategoryId]);
    const filteredProducts = React.useMemo(() => {
        const selectedIds = productByModifier[selectedModifierId];
        if (!selectedIds) {
            return [];
        }
        return modifierProducts.filter(item => selectedIds.has(item.value));
    }, [modifierProducts, productByModifier, selectedModifierId]);
    return (
        <MuiModal open={open} onClose={onCancel}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        Create modifier product change
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Autocomplete
                        options={categories}
                        renderInput={renderCategoriesInput}
                        onChange={handleCategoryChange}
                        value={selectedCategory}
                        disableClearable
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Category ID"
                        value={selectedCategoryId}
                        onChange={handleCategoryIdChange}
                        error={isCategoryError}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Autocomplete
                        options={filteredModifiers}
                        renderInput={renderModifierInput}
                        onChange={handleModifierChange}
                        value={selectedModifier}
                        disableClearable
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Modifier ID"
                        value={selectedModifierId}
                        onChange={handleModifierIdChange}
                        error={isModifierError}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Autocomplete
                        options={filteredProducts}
                        renderInput={renderModifierProductInput}
                        onChange={handleModifierProductChange}
                        value={selectedModifierProduct}
                        disableClearable
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Product ID"
                        value={selectedModifierProductId}
                        onChange={handleModifierProductIdChange}
                        error={isModifierProductError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Row align="flex-end" gutter>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button
                            disabled={isTouched && !valid}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Row>
                </Grid>
            </Grid>
        </MuiModal>
    );
};

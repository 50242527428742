import { Box } from '@mui/material';
import { OrderScenario } from '@pepperhq/menu-sdk';
import { MenuFilter } from 'components/menu-manager/menu-scheduling/filter/MenuFilter';
import { MenuOptionFilter } from 'components/menu-manager/MenuManagerModel';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'ui/Flex';
import { MenuOrderScenariosFilter } from './MenuOrderScenariosFilter';
import { MenuZonesFilter } from './MenuZonesFilter';

interface IProps {
    filter: MenuOptionFilter;
    onFilterChange: (newFilter: MenuOptionFilter) => void;
}

export const MenuSchedulingFilter: React.FC<IProps> = ({ filter, onFilterChange }) => {
    const { locationId } = useParams<{ locationId: string }>();
    const handleValueChange = React.useCallback(
        (field: keyof MenuOptionFilter) => (value: MenuOptionFilter[keyof MenuOptionFilter]) => {
            const newFilter = { ...filter };

            newFilter[field] = value as OrderScenario;

            onFilterChange(newFilter);
        },
        [filter, onFilterChange]
    );

    return (
        <Row>
            <Box marginTop={1} marginRight={1}>
                <MenuFilter value={filter.menu} onChange={handleValueChange('menu')} />
            </Box>
            <Box marginTop={1} marginRight={1}>
                <MenuZonesFilter
                    value={filter.zone}
                    onChange={handleValueChange('zone')}
                    locationId={locationId}
                />
            </Box>
            <Box marginTop={1}>
                <MenuOrderScenariosFilter value={filter.scenario} onChange={handleValueChange('scenario')} />
            </Box>
        </Row>
    );
};

import * as React from 'react';
import { Box, FormControlLabel, Grid, GridSize, InputLabel, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { FormFieldType } from 'lib/LegacyForm';
import { RequiredStar } from './RequiredStar';

const PREFIX = 'AutoFormFieldWithLabel';

const classes = {
    formControl: `${PREFIX}-formControl`,
    switch: `${PREFIX}-switch`,
    numberLabel: `${PREFIX}-numberLabel`,
    description: `${PREFIX}-description`,
    noLabelSwitch: `${PREFIX}-noLabelSwitch`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.formControl}`]: {
        marginLeft: 0,
        width: '100%'
    },

    [`& .${classes.switch}`]: {
        marginLeft: 0,
        width: '100%',
        justifyContent: 'space-between'
    },

    [`& .${classes.numberLabel}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.description}`]: {
        marginTop: theme.spacing(0.5),
        color: theme.palette.grey[500],
        fontSize: '0.75rem'
    },

    [`& .${classes.noLabelSwitch}`]: {
        justifyContent: 'flex-end',
        marginLeft: -11
    }
}));

interface AutoFormFieldWithLabelProps {
    label: React.ReactNode;
    children: React.ReactElement;
    description?: React.ReactNode;
    error?: string;
    required?: boolean;
    fieldType?: FormFieldType;
    width?: GridSize;
    disabled?: boolean;
    builtInLabel?: boolean;
}

export const AutoFormFieldWithLabel = (props: AutoFormFieldWithLabelProps) => {
    const { label, children, error, description, fieldType, required, width, disabled, builtInLabel } = props;

    let content;
    switch (fieldType) {
        case 'switch': {
            content = (
                <FormControlLabel
                    classes={{ root: clsx(classes.switch, { [classes.noLabelSwitch]: !label }) }}
                    control={children}
                    disabled={disabled}
                    label={
                        <React.Fragment>
                            {label}
                            {required && <RequiredStar />}
                        </React.Fragment>
                    }
                    labelPlacement="start"
                />
            );
            break;
        }
        case 'checkbox': {
            content = (
                <FormControlLabel
                    classes={{ root: classes.formControl }}
                    control={children}
                    disabled={disabled}
                    label={
                        <React.Fragment>
                            {label}
                            {required && <RequiredStar />}
                        </React.Fragment>
                    }
                />
            );
            break;
        }
        case 'number': {
            content = builtInLabel ? (
                children
            ) : (
                <FormControlLabel
                    classes={{ root: classes.formControl, label: classes.numberLabel }}
                    labelPlacement="start"
                    control={children}
                    disabled={disabled}
                    label={
                        <React.Fragment>
                            {label}
                            {required && <RequiredStar />}
                        </React.Fragment>
                    }
                />
            );
            break;
        }
        case 'select':
        case 'color':
        case 'image':
        case 'sortableField':
        case 'geo': {
            content = (
                <React.Fragment>
                    <InputLabel disabled={disabled} shrink error={!!error}>
                        {label}
                        {required && <RequiredStar />}
                    </InputLabel>
                    {children}
                </React.Fragment>
            );
            break;
        }
        default:
            content = children;
    }
    return (
        <StyledGrid item xs={width || 12}>
            {content}
            {description && (
                <Typography className={classes.description} variant="caption">
                    {description}
                </Typography>
            )}
            <Box>
                <Typography variant="caption" color="error">
                    {error}
                </Typography>
            </Box>
        </StyledGrid>
    );
};

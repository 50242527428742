import { MenuSummary, OrderScenario } from '@pepperhq/menu-sdk';

export interface MenuSummaryFilter {
    search: string;
}

export const filterKeyToFilterChecker: Record<
    keyof MenuSummaryFilter,
    (summary: MenuSummary, value: string, meta?: any) => boolean
> = {
    search: (summary, searchValue, meta: { locationIdToLocationName: Map<string, string> }) => {
        const sanitizedValue = searchValue.trim().toLowerCase();
        return (
            summary.menuDescription.toLowerCase().includes(sanitizedValue) ||
            summary.menuChangeId.includes(sanitizedValue) ||
            summary.menuId.includes(sanitizedValue) ||
            !!summary.locationIds.find(
                locId =>
                    meta.locationIdToLocationName.has(locId) &&
                    meta.locationIdToLocationName.get(locId).toLowerCase().includes(sanitizedValue)
            )
        );
    }
};

export interface MenuOptionFilter {
    menu: string;
    scenario: OrderScenario;
    zone: string;
}

import { Grid, Paper } from '@mui/material';
import { EditTieredLoyaltyModal } from 'components/metrics/modals/EditTieredLoyaltyModal';
import { TieredLoyaltyStateModal } from 'components/metrics/modals/TieredLoyaltyStateModal';
import { TieredLoyaltyMetrics } from 'components/metrics/tiered-loyalty/TieredLoyaltyMetrics';
import { TieredLoyaltyOverview } from 'components/metrics/tiered-loyalty/TieredLoyaltyOverview';
import { TieredLoyaltyTiers } from 'components/metrics/tiered-loyalty/TieredLoyaltyTiers';
import { useTieredLoyaltyTiers } from 'components/metrics/tiered-loyalty/hooks/useLoyaltyTiers';
import { useTieredLoyaltyEditModal } from 'components/metrics/tiered-loyalty/hooks/useTieredLoyaltyEditModal';
import { useTieredLoyaltyStateModal } from 'components/metrics/tiered-loyalty/hooks/useTieredLoyaltyStateModal';
import { useUserMetricDefinition } from 'components/metrics/tiered-loyalty/hooks/useUserMetricDefinition';
import { MainLayout } from 'layouts/MainLayout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAudiences } from 'store/audiences/audiencesActions';
import { getAllLocations } from 'store/locations/locationsActions';
import { getAllPerks } from 'store/perks/perksActions';
import { ApplicationState } from 'store/store';

export const TieredLoyalty = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: ApplicationState) => state.audiences);
    const {
        metricDefinition,
        isSetup,
        state,
        isValidMetricDefinition,
        setMetricDefinition,
        finishSetup,
        loading: metricDefinitionLoading,
        loadTieredLoyalty
    } = useUserMetricDefinition();
    const { tiers } = useTieredLoyaltyTiers(metricDefinition);
    const {
        stateModalOpen,
        handleStateChangeOpen,
        handleStateChangeClose,
        handleStateChangeSubmit,
        loading: stateChangeLoading
    } = useTieredLoyaltyStateModal(metricDefinition, setMetricDefinition);
    const {
        editModalOpen,
        handleDefinitionEditOpen,
        handleDefinitionEditClose,
        handleDefinitionEditSubmit,
        loading: editLoading
    } = useTieredLoyaltyEditModal(metricDefinition, setMetricDefinition, finishSetup);
    React.useEffect(() => {
        getAllAudiences()(dispatch);
        getAllPerks()(dispatch);
        getAllLocations()(dispatch);
    }, [dispatch]);
    const tiersLoading = React.useMemo(
        () => metricDefinitionLoading || isLoading,
        [metricDefinitionLoading, isLoading]
    );
    return (
        <MainLayout
            title="Tier Schemes"
            pageName="Tier Schemes"
            pageDescription="Audience based loyalty tiers"
        >
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <TieredLoyaltyOverview
                        metricDefinition={metricDefinition}
                        loading={metricDefinitionLoading}
                        onEdit={handleDefinitionEditOpen}
                        onStateChange={handleStateChangeOpen}
                        readyToActivate={isValidMetricDefinition}
                        state={state}
                    />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <TieredLoyaltyMetrics
                        metricDefinition={metricDefinition}
                        loading={metricDefinitionLoading}
                        onDefinitionUpdate={setMetricDefinition}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Paper>
                        <TieredLoyaltyTiers
                            tiers={tiers}
                            loading={tiersLoading}
                            loadTieredLoyalty={loadTieredLoyalty}
                            metricDefinition={metricDefinition}
                            onDefinitionUpdate={setMetricDefinition}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <EditTieredLoyaltyModal
                open={editModalOpen || isSetup}
                onClose={handleDefinitionEditClose}
                onSubmit={handleDefinitionEditSubmit}
                loading={editLoading}
                metricDefinition={metricDefinition}
            />
            <TieredLoyaltyStateModal
                open={stateModalOpen}
                onClose={handleStateChangeClose}
                loading={stateChangeLoading}
                onSubmit={handleStateChangeSubmit}
                tierState={state}
                readyToActivate={isValidMetricDefinition}
            />
        </MainLayout>
    );
};

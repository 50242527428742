import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { isNotification, Notification } from 'components/notification/models/Notification';
import { notificationApi } from 'components/notification/notificationApi';
import { NotificationsView } from 'components/notification/NotificationsView';
import { MARKETING_NOTIFICATIONS } from 'config/routes';
import { isString } from 'lib/typeguards';
import logger from 'lib/logger';

const getNotification = async (id: string): Promise<Notification<string>> => {
    try {
        const response = await notificationApi.get(id);

        if (response.ok) {
            if (isNotification(response.body)) {
                return response.body;
            }
            throw new Error(`Expected Notification but got ${JSON.stringify(response.body)}`);
        }
        throw new Error(response.body.message);
    } catch (error) {
        logger.error(error);
        return error;
    }
};

const breadcrumbs = [
    {
        label: 'Notifications',
        url: MARKETING_NOTIFICATIONS
    }
];

const NotificationsViewPage = () => {
    const [notification, setNotification] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const { notificationId: id } = useParams<{ notificationId: string }>();
    React.useEffect(() => {
        async function load() {
            try {
                if (!isString(id)) {
                    throw new Error(`Expected notification id, but got ${id}`);
                }
                const newNotification = await getNotification(id);
                setNotification(newNotification);
            } catch (e) {
                history.push(MARKETING_NOTIFICATIONS);
                setNotification(null);
            } finally {
                setLoading(false);
            }
        }
        load();
    }, [history, id]);
    // We need to put a subtitle here
    return (
        <MainLayout
            breadcrumbs={breadcrumbs}
            pageName="Notification Details"
            pageDescription="View the success rate of your notification"
        >
            <NotificationsView notification={notification} loading={loading} />
        </MainLayout>
    );
};

export default NotificationsViewPage;

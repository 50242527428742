import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Paper, Typography } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import EmptyImage from 'static/select-module-guide.png';
import { updateCurrentModuleProperties as _updateCurrentModuleProperties } from 'store/content/contentActions';
import { ApplicationState } from 'store/store';
import { Column, Row } from 'ui/Flex';
import { ContentModule } from '../models/ContentModule';
import { ModuleForm } from './ModuleForm';

interface ModulesConfigurationProps {
    className?: string;
    currentModules: ContentModule[];
    selectedModuleId: string;
    updateCurrentModuleProperties: (fieldName: string, value: any, id: string) => void;
    handleSubmit: () => void;
    onDelete: (id: string) => void;
}

const getContent = ({
    module,
    selectedModuleId,
    updateCurrentModuleProperties,
    handleSubmit,
    onDelete
}: {
    module: ContentModule;
    selectedModuleId: string;
    updateCurrentModuleProperties: (fieldName: string, value: any, id: string) => void;
    handleSubmit: () => void;
    onDelete: (id: string) => void;
}) => {
    if (!!module) {
        return (
            <ModuleForm
                updateCurrentModuleProperties={updateCurrentModuleProperties}
                module={module}
                onDelete={onDelete}
                moduleId={selectedModuleId}
                handleSubmit={handleSubmit}
            />
        );
    }
    return (
        <Column align="center" valign="center" style={{ padding: '16px', minWidth: '500px' }}>
            <Row align="center">
                <img src={EmptyImage} width="50%" height="50%" alt="" />
            </Row>
            <Row style={{ marginTop: '16px' }} align="center">
                <Typography variant="caption">Click a module to begin editing</Typography>
            </Row>
        </Column>
    );
};

const ModulesConfigurationElement: React.FC<ModulesConfigurationProps> = ({
    currentModules,
    selectedModuleId,
    updateCurrentModuleProperties,
    handleSubmit,
    onDelete,
    className
}) => {
    const [content, setContent] = useState(null);
    const currentModule = React.useMemo(
        () =>
            !!currentModules && selectedModuleId
                ? currentModules.find(item => item.id === selectedModuleId)
                : null,
        [currentModules, selectedModuleId]
    );

    useEffect(() => {
        setContent(
            getContent({
                handleSubmit,
                selectedModuleId,
                updateCurrentModuleProperties,
                module: currentModule,
                onDelete
            })
        );
    }, [selectedModuleId, currentModule, handleSubmit, updateCurrentModuleProperties, onDelete]);

    return <Paper className={className}>{content}</Paper>;
};

const mapStateToProps = ({ content }: ApplicationState) => ({
    currentModules: content.currentModules,
    selectedModuleId: content.selectedModuleId
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ updateCurrentModuleProperties: _updateCurrentModuleProperties }, dispatch);

export const ModulesConfiguration = connect(mapStateToProps, mapDispatchToProps)(ModulesConfigurationElement);

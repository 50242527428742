import React from 'react';
import { Grid } from '@mui/material';
import { CardViewTextRow } from 'ui/viewComponents';
import { isGiftCardTopupAction } from '../models/Action';
import { getActionDate } from '../utils/formatDate';
import { ActionCardBase } from './ActionCardBase';
import { ActionCardModalComponentProps } from './ActionCardModalComponent';
import { getCurrencyString } from 'lib/helpers';

export const ActionCardGiftCardTopup: React.FC<ActionCardModalComponentProps> = ({ action }) => {
    if (!isGiftCardTopupAction(action)) {
        return <ActionCardBase action={action} broken />;
    }

    const { metadata } = action;

    return (
        <ActionCardBase action={action}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CardViewTextRow title="Date" value={getActionDate(action)} />
                </Grid>
                <Grid item xs={12}>
                    <CardViewTextRow title="Gift Card Number" value={metadata.giftCardNumber} />
                </Grid>
                <Grid item xs={12}>
                    <CardViewTextRow
                        title="Amount Topped Up"
                        value={getCurrencyString(metadata.value.amount, metadata.value.currency)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CardViewTextRow title="Gift Card Provider" value={metadata.provider} />
                </Grid>
            </Grid>
        </ActionCardBase>
    );
};

import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { CategoryGroupChangeUpdate, DisplayCode, MenuChange } from '@pepperhq/menu-sdk';
import { menuApi } from 'components/menu/MenuApi';
import {
    ImagePickerColumn,
    MultiSelectGridColumn,
    NumberIdSortComparator,
    SelectGridColumn,
    TextGridColumn
} from 'lib/MuiGrid/Columns';
import { EMuiGridStatus, MuiGrid } from 'lib/MuiGrid/MuiGrid';
import React, { useContext } from 'react';
import {
    BaseMenuTab,
    getCategoryGroupsData,
    getMenuChangeDataToCopy,
    MENU_CHANGE_SCHEMA,
    useMenuChange
} from '../model/menu';
import merge from 'deepmerge';
import * as Yup from 'yup';
import { useLeavePageBlock } from 'lib/hooks/useLeavePageBlock';
import { useDispatch, useSelector } from 'react-redux';
import logger from 'lib/logger';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_CATEGORY_GROUP_UPDATE_ERROR, MESSAGE_CATEGORY_GROUP_UPDATE_SUCCESS } from 'config/messages';
import { ZonesArrayColumn } from '../availability/ZonesColumn';
import { isDefined, isEmptyString } from 'lib/typeguards';
import { useGridSearch } from '../model/useMenuGridSearch';
import { GridStorageName } from 'lib/MuiGrid/StateController';
import { LocationsContext, useTabCopy } from '../useCopyTab';
import { getMenuStorageName } from '../gridStateStorageHelpers';
import { ActionsHeader } from '../ActionsHeader';
import { useMenuSelection } from '../useMenuSelection';
import { useMenuDelete } from '../model/useMenuDelete';
import { ApplicationState } from 'store/store';

type CategoryGroupProps = BaseMenuTab;

const validationSchema = {
    title: Yup.string().required('Required'),
    sort: Yup.number().integer('Whole number only').max(999999, '6 or less digits').required('Required'),
    displayCode: Yup.string().required('Required'),
    imageUrl: Yup.string().required('Required'),
    categoryIds: Yup.array().of(Yup.string()).typeError('Required').min(1, 'Required').required('Required')
};
const ZONES_TOOLTIP =
    'You cannot set an available zone on this category group due to an existing weekly availability. Please review in Weekly Availability section.';

const displayCodeLabels = {
    [DisplayCode.HIDE]: 'Hide',
    [DisplayCode.LARGE]: 'Large',
    [DisplayCode.SMALL]: 'Small'
};

export const CategoryGroups: React.FC<CategoryGroupProps> = ({
    menu,
    menuChangeId,
    loadFreshMenu,
    onUpdate,
    change: externalChange,
    search,
    onSearchChange
}) => {
    const locations = useContext(LocationsContext);
    const dispatch = useDispatch();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const {
        change: [change, setChange],
        readyToSave,
        handleCancel,
        handleStatusChange,
        gridRef
    } = useMenuChange(externalChange);

    const categoryIdToTitle = React.useMemo(() => {
        if (!menu || !menu.categories) {
            return {};
        }
        return menu.categories.reduce<Record<string, string>>((acc, item) => {
            acc[item.id] =
                (externalChange?.categories && externalChange.categories[item.id]?.title) ?? item.title;
            return acc;
        }, {});
    }, [menu, externalChange]);

    const {
        selectedIds,
        resetSelection,
        selectedCell,
        handleSelectChange,
        handleFocusOut,
        handleCellSelection,
        copyEnabled
    } = useMenuSelection(!!locations.length);

    const { toggleIsCopyModalOpen, setMenuChangeToCopy, activeOperationId, renderLocationPickerModal } =
        useTabCopy(resetSelection, selectedCell, [
            'title',
            'shortDescription',
            'sort',
            'categoryIds',
            'displayCode',
            'imageUrl',
            'availableZones',
            'availableTimesByDay',
            'availableTimesByDayByZone'
        ]);

    const handleRefreshMenu = React.useCallback(() => {
        loadFreshMenu();
        gridRef.current.reset();
    }, [gridRef, loadFreshMenu]);

    const categoryGroups = React.useMemo(() => {
        if (!menu || !change) {
            return [];
        }
        return getCategoryGroupsData(menu, change);
    }, [change, menu]);
    const { deleted, resetDeleted, isCellEditable, onRowDelete } = useMenuDelete();

    const editedOrDeleted = React.useMemo(
        () => readyToSave || deleted?.size > 0,
        [deleted?.size, readyToSave]
    );

    const handleCancelEditing = React.useCallback(() => {
        resetDeleted();
        handleCancel();
    }, [handleCancel, resetDeleted]);

    const configuredZones = React.useMemo(() => {
        const zones = new Set<string>();
        categoryGroups.forEach(categoryGroup => {
            if (isDefined(categoryGroup.availableTimesByDayByZone)) {
                zones.add(categoryGroup.id);
            }
        });
        return zones;
    }, [categoryGroups]);

    useLeavePageBlock(editedOrDeleted);
    const gridSearchOptions = React.useMemo(
        () => [{ column: 'id' }, { column: 'title' }, { column: 'shortDescription' }],
        []
    );
    const { filterModel, renderInput } = useGridSearch(
        gridSearchOptions,
        search,
        onSearchChange,
        'categoryGroups',
        'Search by ID, title or description'
    );

    const editable = !settings?.accessMenuManagerEnabled;

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 120,
                sortComparator: NumberIdSortComparator
            },
            {
                field: 'title',
                headerName: 'Title',
                editable,
                width: 200,
                ...TextGridColumn()
            },
            {
                field: 'shortDescription',
                headerName: 'Description',
                editable,
                width: 200
            },
            {
                field: 'sort',
                headerName: 'Sort',
                editable,
                type: 'number',
                width: 120,
                headerAlign: 'left',
                ...TextGridColumn()
            },
            {
                field: 'availableZones',
                headerName: 'Available Zones',
                width: 230,
                description: 'Seperate each zone with a comma.',
                editable,
                ...ZonesArrayColumn(configuredZones, ZONES_TOOLTIP)
            },
            {
                field: 'displayCode',
                headerName: 'Display',
                editable,
                width: 150,
                ...SelectGridColumn(displayCodeLabels)
            },
            {
                field: 'imageUrl',
                headerName: 'Image',
                width: 300,
                ...ImagePickerColumn('cat-group-', editable, 600 / 200)
            },
            {
                field: 'categoryIds',
                headerName: 'Category',
                editable,
                width: 300,
                ...MultiSelectGridColumn(categoryIdToTitle)
            }
        ],
        [categoryIdToTitle, configuredZones, editable]
    );

    const handleCreate = React.useCallback(() => {
        const { changes } = gridRef.current?.getState() ?? {};
        const categoryGroupChanges = merge(change?.categoryGroups || {}, changes);
        let newId: string;
        let i = 1;
        while (!newId) {
            if (!categoryGroupChanges[`${100 + i}`]) {
                newId = `${100 + i}`;
            } else {
                i++;
            }
        }
        setChange({
            ...change,
            categoryGroups: {
                ...categoryGroupChanges,
                [newId]: {
                    title: '',
                    shortDescription: '',
                    sort: 0,
                    availableZones: [],
                    displayCode: DisplayCode.HIDE,
                    imageUrl: '',
                    categoryIds: []
                }
            }
        });
        gridRef.current?.createItem(newId, 'title', {
            title: '',
            shortDescription: '',
            sort: 0,
            availableZones: [],
            displayCode: DisplayCode.HIDE,
            imageUrl: '',
            categoryIds: []
        });
        handleStatusChange(EMuiGridStatus.CHANGED);
    }, [change, gridRef, handleStatusChange, setChange]);
    const handleSubmit = React.useCallback(async () => {
        try {
            if (!gridRef.current) {
                throw new Error(MESSAGE_CATEGORY_GROUP_UPDATE_ERROR);
            }
            const errors = gridRef.current.validate();
            if (errors) {
                return;
            }
            const { changes } = gridRef.current.getState();
            const groups = Object.entries(changes).reduce<MenuChange['categoryGroups']>(
                (acc, [id, { _overriden, ...item }]) => {
                    if (!change?.categoryGroups || !change?.categoryGroups[id]) {
                        acc[id] = { ...item };
                    } else {
                        acc[id] = { ...change.categoryGroups[id], ...item };
                    }
                    // this check for removing by backspace
                    if (!isDefined(acc[id].availableZones) || isEmptyString(acc[id].availableZones)) {
                        acc[id].availableZones = null;
                    }
                    // this check for leaving them blank
                    if (Array.isArray(acc[id].availableZones)) {
                        const availableZones = acc[id].availableZones.filter(item => !isEmptyString(item));
                        if (!availableZones.length) {
                            acc[id].availableZones = null;
                        }
                    }
                    if (!isDefined(acc[id].categoryIds) || isEmptyString(acc[id].categoryIds)) {
                        acc[id].categoryIds = null;
                    }
                    if (isEmptyString(acc[id].imageUrl)) {
                        acc[id].imageUrl = null;
                    }
                    return acc;
                },
                {}
            );
            if (deleted) {
                deleted.forEach(deletedGroup => {
                    groups[deletedGroup] = null;
                });
            }
            const result = await menuApi.updateMenuChange(menuChangeId, {
                categoryGroups: groups,
                schema: MENU_CHANGE_SCHEMA
            });
            if (!result.ok) {
                throw new Error(result.body.message);
            }
            setChange(result.body);
            onUpdate(result.body);
            resetDeleted();
            dispatch(enqueueSnackbar(MESSAGE_CATEGORY_GROUP_UPDATE_SUCCESS, { variant: 'success' }));
            gridRef.current.reset();
        } catch (e) {
            logger.error(e);
            dispatch(enqueueSnackbar(e.message, { variant: 'error' }));
        }
    }, [gridRef, deleted, menuChangeId, setChange, onUpdate, resetDeleted, dispatch, change.categoryGroups]);

    const handleCopyTo = React.useCallback(() => {
        toggleIsCopyModalOpen();
        const menuChange = getMenuChangeDataToCopy<CategoryGroupChangeUpdate>(
            change,
            selectedIds,
            'categoryGroups',
            selectedCell,
            row => row.id
        );
        setMenuChangeToCopy(menuChange);
    }, [toggleIsCopyModalOpen, change, selectedIds, selectedCell, setMenuChangeToCopy]);

    const deleteEnabled = React.useMemo(() => selectedIds.length > 0, [selectedIds.length]);

    const handleDelete = React.useCallback(() => {
        selectedIds.forEach(id => {
            onRowDelete(id);
        });
        resetSelection();
    }, [onRowDelete, resetSelection, selectedIds]);

    return (
        <React.Fragment>
            <Box pb={1}>
                <ActionsHeader
                    onCreate={editable ? handleCreate : undefined}
                    menu={menu}
                    onCancelEditing={handleCancelEditing}
                    onSubmit={handleSubmit}
                    activeOperationId={activeOperationId}
                    onCopy={editable ? handleCopyTo : undefined}
                    onRefreshMenu={handleRefreshMenu}
                    isEditedOrDeleted={editedOrDeleted}
                    copyEnabled={editable ? copyEnabled : false}
                    onDelete={handleDelete}
                    deleteEnabled={deleteEnabled}
                />
                {renderInput()}
            </Box>
            <MuiGrid
                rows={categoryGroups}
                columns={columns}
                stateRef={gridRef}
                filterModel={filterModel}
                onStatusChange={handleStatusChange}
                validationScheme={validationSchema}
                deleted={deleted}
                disableColumnFilter
                isCellEditable={isCellEditable}
                storageName={getMenuStorageName(GridStorageName.CategoryGroups)}
                checkboxSelection
                onSelectionModelChange={handleSelectChange}
                selectionModel={selectedIds}
                checkboxVisibility
                onCellClick={handleCellSelection}
                onCellFocusOut={handleFocusOut}
            />
            {renderLocationPickerModal()}
        </React.Fragment>
    );
};

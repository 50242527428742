/* eslint-disable max-len */
import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { segmentsApi } from 'components/audience/segmentsApi';
import { CustomerSegment } from 'components/customers/models/Customer';
import { locationApi } from 'components/location/LocationApi';
import { Location } from 'components/location/models/LocationModel';
import { PrimaryActionItem, PrimaryActionType } from '../models/ContentModule';
import { Form, FormikProvider, useFormik } from 'formik';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { RequiredStar } from 'lib/form/RequiredStar';
import { PRIMARY_ACTION_ITEMS } from '../ContentModuleActions';
import { CheckboxFormField } from 'lib/form/fields/CheckboxFormField';
import * as Yup from 'yup';

interface PrimaryCtaModuleFormProps {
    value?: PrimaryActionItem;
    onSubmit: (value: PrimaryActionItem) => void;
    close: () => void;
    formTitle: string;
}

const uriRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

interface FormFields {
    title: string;
    type: PrimaryActionType;
    name: string;
    locationIds: string[];
    segmentIds: string[];
    properties?: {
        useCheckinLocation?: boolean;
    };
    url?: string;
    index: number;
    excludingSegmentIds?: string[];
    authenticated?: boolean;
    unauthenticated?: boolean;
}

const hasUrl = (actionType: PrimaryActionType) =>
    actionType === 'BOOK_TABLE' || actionType === 'BOOK_ROOM' || actionType === 'BROWSER';

const PrimaryCtaModuleForm = React.memo((props: PrimaryCtaModuleFormProps) => {
    const { close, onSubmit } = props;
    const [locations, setLocations] = React.useState([] as Location[]);
    const [segments, setSegments] = React.useState<CustomerSegment[]>([]);

    const handleSubmit = React.useCallback(
        ({
            title,
            type,
            name,
            index,
            properties,
            locationIds,
            segmentIds,
            url,
            excludingSegmentIds,
            authenticated,
            unauthenticated
        }: FormFields) => {
            const item: PrimaryActionItem = {
                title,
                type,
                name,
                index: index || 0,
                context: {
                    authenticated,
                    unauthenticated
                }
            };

            if (locationIds.length > 0) {
                item.context = { ...item.context, locationIds };
            }
            if (segmentIds.length > 0) {
                item.context = { ...item.context, segmentIds };
            }
            if (excludingSegmentIds.length > 0) {
                item.context = { ...item.context, excludingSegmentIds };
            }
            if (item.type === 'TABLE_ORDER') {
                item.properties = { useCheckinLocation: properties.useCheckinLocation };
            }

            if (hasUrl(item.type)) {
                item.properties = { url };
            }

            onSubmit({
                ...item
            });
            close();
        },
        [onSubmit, close]
    );
    const formik = useFormik<FormFields>({
        initialValues: {
            title: props.value?.title || '',
            type: props.value?.type,
            name: props.value?.name || '',
            index: props.value?.index || 0,
            url: props.value?.properties?.url,
            properties: {
                useCheckinLocation: props.value?.properties?.useCheckinLocation || false
            },
            locationIds: props.value?.context?.locationIds || [],
            segmentIds: props.value?.context?.segmentIds || [],
            excludingSegmentIds: props.value?.context?.excludingSegmentIds || [],
            authenticated: props.value?.context?.authenticated ?? true,
            unauthenticated: props.value?.context?.unauthenticated ?? true
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required('This field is required'),
            name: Yup.string().required('This field is required'),
            type: Yup.mixed()
                .oneOf(PRIMARY_ACTION_ITEMS.map(({ value }) => value))
                .required('This field is required'),
            url: Yup.string().when('type', {
                is: (value: PrimaryActionType) => hasUrl(value),
                then: Yup.string()
                    .required('This field is required.')
                    .matches(uriRegex, 'This field must be a valid url e.g. https://google.co.uk'),
                otherwise: Yup.string().notRequired()
            })
        }),
        onSubmit: handleSubmit
    });
    React.useEffect(() => {
        async function getLocations() {
            const result = await locationApi.getList();
            if (!result.ok) {
                return setLocations([]);
            }
            setLocations(result.body.locations);
        }
        async function getSegments() {
            const result = await segmentsApi.getAll();
            if (!result) {
                return setSegments([]);
            }
            setSegments(result);
        }
        getLocations();
        getSegments();
    }, []);

    const segmentOptions = React.useMemo(
        () => segments.map(segment => ({ value: segment._id, label: segment.title })),
        [segments]
    );
    const locationOptions = React.useMemo(
        () => locations.map(location => ({ value: location._id, label: location.title })),
        [locations]
    );

    return (
        <Box padding={0} paddingBottom={0}>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <Grid container spacing={3} sx={{ maxWidth: 512, p: 0 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary">
                                {props.formTitle}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                label={
                                    <>
                                        Title
                                        <RequiredStar />
                                    </>
                                }
                                required
                                name="title"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFormField
                                label={
                                    <>
                                        Action
                                        <RequiredStar />
                                    </>
                                }
                                required
                                name="type"
                                options={PRIMARY_ACTION_ITEMS}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFormField
                                label={
                                    <>
                                        Reporting Name
                                        <RequiredStar />
                                    </>
                                }
                                required
                                name="name"
                                description="This code is used to track the number of primary call to action item taps on anchor screen"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Typography color="primary">Context</Typography>
                                        <Typography variant="caption">
                                            Allow only certain groups to see this primary action module. All
                                            of these fields are optional.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectFormField
                                        label="Locations"
                                        multiple
                                        disabled={!locationOptions.length}
                                        name="locationIds"
                                        options={locationOptions}
                                    />
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{ lineHeight: 1.4 }}
                                    >
                                        {locations && locations.length
                                            ? 'Choose which locations you would like to display this primary action module to when a user is checked in, or is set as the favourite location. Leaving this empty will show it to all locations.'
                                            : 'No locations available.'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectFormField
                                        label="Show if user is in Audience"
                                        multiple
                                        disabled={!segmentOptions.length}
                                        name="segmentIds"
                                        options={segmentOptions}
                                    />
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{ lineHeight: 1.4 }}
                                    >
                                        {segments && segments.length
                                            ? 'Choose which audiences you would like to display this primary action module to. Leaving this empty will show it to all locations.'
                                            : 'No audiences available.'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectFormField
                                        label="Hide if user is in Audience"
                                        multiple
                                        disabled={!segmentOptions.length}
                                        name="excludingSegmentIds"
                                        options={segmentOptions}
                                    />
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{ lineHeight: 1.4 }}
                                    >
                                        {segments && segments.length
                                            ? 'Choose which audiences you would not like to display this content to. Leaving this empty will show it to all audiences.'
                                            : 'No audiences available.'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>Show content when user is:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxFormField label="Signed In" name="authenticated" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxFormField label="Signed Out" name="unauthenticated" />
                                </Grid>
                            </Grid>
                        </Grid>
                        {formik.values.type === 'TABLE_ORDER' && (
                            <Grid item xs={12}>
                                <CheckboxFormField
                                    label="Use check-in location"
                                    description="Automatically use the user’s checked-in location."
                                    name="properties.useCheckinLocation"
                                />
                            </Grid>
                        )}
                        {hasUrl(formik.values.type) && (
                            <Grid item xs={12}>
                                <TextFormField label="Url" name="url" />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box marginTop={1.5} width="100%" display="flex" justifyContent="flex-end">
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </Box>
    );
});

export const getPrimaryCtaModuleForm = (props: PrimaryCtaModuleFormProps) => (
    <PrimaryCtaModuleForm {...props} />
);

import React from 'react';
import { IOrder, IOrderPayment } from '../model/Order';
import { MuiModal } from 'ui/MuiModal';
import { isDefined } from 'lib/typeguards';
import { OrderRefund } from './OrderRefund';

interface CustomRefundDialogProps {
    order: IOrder;
    onClose: () => void;
    payment: IOrderPayment;
    onRefund: (amount?: number, reason?: string, note?: string) => void;
    loading: boolean;
}

export const CustomRefundDialog: React.FC<CustomRefundDialogProps> = ({
    payment,
    order,
    onClose,
    ...rest
}) => (
    <MuiModal open={isDefined(payment) && isDefined(order)} onClose={onClose} noMaxWidth>
        <OrderRefund order={order} payment={payment} onClose={onClose} {...rest} />
    </MuiModal>
);

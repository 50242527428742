import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

interface Point {
    lat: number;
    lng: number;
}

interface GMapProps {
    center: Point;
    width: number;
    className?: string;
    height?: number;
    markers?: Point[];
    name?: string;
    options?: google.maps.MapOptions;
    zoom?: number;
}

function fitMapBounds(lat: number, lng: number, width: number, map: google.maps.Map) {
    if (lat && lng && width && map) {
        map.fitBounds(
            new google.maps.LatLngBounds(
                new google.maps.LatLng(lat, lng - width / 2),
                new google.maps.LatLng(lat, lng + width / 2)
            )
        );
    }
}

export function Map(props: GMapProps) {
    const { center, width, name, className, height, options, markers, ...rest } = props;
    const { lat, lng } = center;
    const [map, setMap] = React.useState(null);

    React.useEffect(() => {
        fitMapBounds(lat, lng, width, map);
    }, [lat, lng, map, width]);

    const onMapLoad = React.useCallback(
        (newMap: google.maps.Map) => {
            setMap(newMap);
            fitMapBounds(lat, lng, width, newMap);
        },
        [lat, lng, width]
    );

    const mapOptions: google.maps.MapOptions = {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        draggable: false,
        gestureHandling: 'none',
        ...options
    };

    return (
        <GoogleMap
            id={`gmap-${name}`}
            zoom={16}
            clickableIcons={false}
            center={center}
            onLoad={onMapLoad}
            mapContainerClassName={className}
            mapContainerStyle={{}}
            options={mapOptions}
            {...rest}
        >
            {Array.isArray(markers) &&
                markers.map(item => <Marker key={`marker-${item.lat}-${item.lng}`} position={item} />)}
            <div style={{ height: height || 'inherit', width: '100%' }} />
        </GoogleMap>
    );
}

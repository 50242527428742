import { AwardTemplate } from 'components/customers/models/Award';
import React from 'react';

interface PerkSettingsTypeHandlerProps {
    isEdit?: boolean;
    perkType?: AwardTemplate;
    onPerkTypeChange: (perkType: AwardTemplate) => void;
}

export const PerkSettingsTypeHandler: React.FC<PerkSettingsTypeHandlerProps> = ({
    isEdit,
    perkType,
    onPerkTypeChange
}) => {
    React.useEffect(() => {
        if (!isEdit) {
            onPerkTypeChange(perkType);
        }
    }, [onPerkTypeChange, isEdit, perkType]);
    return null;
};

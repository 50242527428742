import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { LocationScopedSettings } from 'components/settings/LocationScopedSettings';
import { PointOfSaleSettings } from 'components/settings/PointOfSaleSettings';
import { MERCHANT_MOBILE_SETTINGS } from 'config/routes';

export const PointOfSaleSettingsPage: React.FC = () => (
    <MainLayout
        pageName="Point of Sale Settings"
        pageDescription="Adjust your point of sale settings."
        breadcrumbs={[{ label: 'Settings', url: MERCHANT_MOBILE_SETTINGS }]}
    >
        <LocationScopedSettings globalComponent={PointOfSaleSettings} scopedComponent={PointOfSaleSettings} />
    </MainLayout>
);

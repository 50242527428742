import { usePagedData } from 'lib/hooks/usePagedData';
import React from 'react';
import { Location } from './models/LocationModel';
import { locationApi } from './LocationApi';

interface LocationsFilterBody {
    title?: string;
}

export function useLocationsData(title?: string) {
    const filter = React.useMemo(() => ({ title }), [title]);
    const getFilterBody = React.useCallback((body: LocationsFilterBody) => body, []);
    const data = usePagedData<Location, LocationsFilterBody>(filter, locationApi.getListV9, getFilterBody);
    return data;
}

import { Theme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { LocationState } from './models/LocationModel';

export function parseTimeRange(value: string) {
    const reg = new RegExp(/([0-9]{1,2}:[0-9]{2})([ap]m)? - ([0-9]{1,2}:[0-9]{2})([ap]m)?/);
    const result = reg.exec(value);
    if (!result) {
        return { fullMatch: null, start: null, end: null };
    }
    const [fullMatch, startValue, startMopdifier, endValue, endModifier] = reg.exec(value);
    return { fullMatch, start: `${startValue}${startMopdifier}`, end: `${endValue}${endModifier}` };
}

export function mapLocationStateToStyles(
    state: LocationState,
    theme: Theme
): { backgroundColor: string; color: string } {
    switch (state) {
        case LocationState.ACTIVE:
            return { backgroundColor: green['A700'], color: theme.palette.primary.contrastText };
        case LocationState.PENDING:
            return { backgroundColor: orange['500'], color: theme.palette.primary.contrastText };
        case LocationState.RETIRED:
        default:
            return { backgroundColor: red['500'], color: theme.palette.primary.contrastText };
    }
}

const isZoneArrayRange = (zoneTableNumbers: number[]) => zoneTableNumbers[0] !== zoneTableNumbers[1];

export const getZonesString = (zones: number[][], withSpaces = true) =>
    zones
        .map(zone => (isZoneArrayRange(zone) ? zone.join('-') : String(zone[0])))
        .join(withSpaces ? ', ' : ',');

export const getZonesArrayFromString = (zonesStr: string) => {
    const rangesSeparatedByComma = zonesStr.split(',');

    const resultZones: number[][] = [];

    rangesSeparatedByComma.forEach(zone => {
        const rangesFromZoneString = zone.split('-');

        if (rangesFromZoneString.length > 1) {
            resultZones.push([+rangesFromZoneString[0], +rangesFromZoneString[1]]);
        } else {
            resultZones.push([+rangesFromZoneString[0], +rangesFromZoneString[0]]);
        }
    });

    return resultZones;
};

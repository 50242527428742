/* eslint-disable max-len */
import React from 'react';
import { Grid } from '@mui/material';
import { Location } from 'components/location/models/LocationModel';
import { CardViewSwitchRow, CardViewTextRow } from 'ui/viewComponents';

interface LocationInAppLinksCardComponentProps {
    location: Location;
}

export const LocationInAppLinksCardComponent = (props: LocationInAppLinksCardComponentProps) => {
    const {
        location: {
            links: { facebook, twitter, instagram, uber, citymapper }
        }
    } = props;

    return (
        <Grid container spacing={2} direction="row">
            <Grid item sm={6} xs={12}>
                <Grid container spacing={1}>
                    <CardViewTextRow
                        title="Facebook"
                        value={facebook && facebook.parameters}
                        tooltip="This is the ID of the Facebook page you want to link to the location. You can find it easily using https://findmyfbid.com."
                    />
                    <CardViewTextRow
                        title="Twitter"
                        value={twitter && twitter.parameters}
                        tooltip="This is the username of the Twitter account you want to link to the location."
                    />
                    <CardViewTextRow
                        title="Instagram"
                        value={instagram && instagram.parameters}
                        tooltip="This is the username of the Instagram page you want to link to the location."
                    />
                </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Grid container spacing={1}>
                    <CardViewSwitchRow title="Uber" value={uber && uber.enabled} />
                    <CardViewSwitchRow title="Citymapper" value={citymapper && citymapper.enabled} />
                </Grid>
            </Grid>
        </Grid>
    );
};

import React from 'react';
import { Button, styled, Theme } from '@mui/material';
import clsx from 'clsx';
import { Row } from 'ui/Flex';
import { IPrimaryAction, PrimaryActionItem } from '../models/ContentModule';
import { ContentAnchorProperties } from '../models/ContentTemplate';
import { ContentModuleRenderer, getBorderRadius, MobileLayoutTheme } from '../renderContentModule';
import { MUIStyledCommonProps } from '@mui/system';

const PREFIX = 'renderPrimaryActionModule';

const classes = {
    root: `${PREFIX}-root`,
    row: `${PREFIX}-row`,
    button: `${PREFIX}-button`,
    active: `${PREFIX}-active`,
    glass: `${PREFIX}-glass`
};

const Root = styled('div', {
    shouldForwardProp: propName =>
        propName === 'className' || propName === 'children' || propName === 'onClick'
})(
    ({
        theme: mainTheme,
        primaryAction,
        properties,
        layoutTheme: theme
    }: MUIStyledCommonProps<Theme> & StyledPrimaryActionProps) => ({
        [`&.${classes.root}`]: {
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            minHeight: '52px'
        },

        [`& .${classes.row}`]: {
            minWidth: '100%',
            width:
                primaryAction.items && primaryAction.items.length > 1
                    ? `${primaryAction.items.length * 46}%`
                    : '100%',
            position: 'relative',
            '&:hover': {
                backgroundColor: properties.isSelectable
                    ? `rgba(92, 92, 92, ${properties.isSelected ? '0.16' : '0.12'})`
                    : 'initial',
                transform: `${properties.isSelectable && !properties.isSelected ? 'scale(1.025)' : 'none'}`
            },
            padding: mainTheme.spacing(0.5, 0),
            paddingLeft: mainTheme.spacing(0.5),
            backgroundColor: properties.isSelected ? 'rgba(92, 92, 92, 0.16)' : 'initial',
            cursor: properties.isSelectable ? 'pointer' : 'inherit',
            transition: 'background-color 0.1s ease-in, transform 0.1s ease-in',
            border: `2px solid ${
                properties.isSelectable && properties.isSelected ? 'rgb(62, 141, 208)' : 'rgba(0,0,0,0)'
            }`
        },

        [`& .${classes.button}`]: {
            marginRight: theme.unit,
            width: primaryAction.items && primaryAction.items.length > 1 ? '46%' : '100%',
            backgroundColor: properties.primaryActionBackgroundColour,
            color: properties.primaryActionTextColour,
            borderRadius: getBorderRadius(properties),
            transition: 'border-radius .15s linear, color .15s linear, background-color .15s linear',
            fontSize: theme.fontSize,
            textTransform: 'none',
            lineHeight: 'initial',
            overflow: 'hidden',
            height: theme.borderRadius * 5,
            padding: theme.unit
        },

        [`& .${classes.active}`]: {
            backgroundColor: properties.primaryActionTextColour,
            color: properties.primaryActionBackgroundColour,
            border: `1px solid ${properties.primaryActionBackgroundColour}`
        },

        [`& .${classes.glass}`]: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute'
        }
    })
);

const alternativeStyleButtons = ['CHECKIN', 'TABS'];

export const renderPrimaryActionModule: ContentModuleRenderer = (
    primaryAction: IPrimaryAction,
    theme,
    properties,
    _
) => <PrimaryActionComponent primaryAction={primaryAction} theme={theme} properties={properties} />;

interface PrimaryActionProps {
    primaryAction: IPrimaryAction;
    theme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

interface StyledPrimaryActionProps {
    primaryAction: IPrimaryAction;
    layoutTheme: MobileLayoutTheme;
    properties: ContentAnchorProperties;
}

function PrimaryActionComponent({ theme: layoutTheme, ...props }: PrimaryActionProps) {
    const { primaryAction, properties } = props;

    const handleClick = React.useCallback(() => {
        if (properties.handleClick && !properties.isSelected) {
            properties.handleClick();
        }
    }, [properties]);
    return (
        <Root
            className={clsx('hidden-scroll', classes.root)}
            onClick={handleClick}
            {...props}
            layoutTheme={layoutTheme}
        >
            <Row className={classes.row}>
                {primaryAction.items &&
                    primaryAction.items.map((item: PrimaryActionItem, index: number) => (
                        <Button
                            key={`primary-action-${index}`}
                            className={clsx(
                                classes.button,
                                alternativeStyleButtons.includes(item.type) && classes.active
                            )}
                        >
                            {item.title}
                        </Button>
                    ))}
                <div className={classes.glass} />
            </Row>
        </Root>
    );
}

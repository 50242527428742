import React from 'react';
import { useDispatch } from 'react-redux';
import { ListItemButton, Typography, styled } from '@mui/material';
import { copyToClipboard } from 'lib/helpers';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

const PREFIX = 'OverwritesErrorMessage';

const classes = {
    root: `${PREFIX}-root`
};

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: theme.spacing(1),
        borderBottom: `1px dotted ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 'none'
        }
    }
}));

interface OverwritesErrorMessageProps {
    message: string;
}

const OverwritesErrorMessagePure: React.FC<OverwritesErrorMessageProps> = ({ message }) => {
    const dispatch = useDispatch();

    const handleClick = React.useCallback(() => {
        dispatch(enqueueSnackbar(`"${message}" copied to the clipboard`, { variant: 'default' }));
        copyToClipboard(message);
    }, [message, dispatch]);
    return (
        <StyledListItem onClick={handleClick} className={classes.root}>
            <Typography variant="caption" sx={{ fontSize: '0.85rem' }}>
                {message}
            </Typography>
        </StyledListItem>
    );
};

export const OverwritesErrorMessage = React.memo(
    OverwritesErrorMessagePure,
    (l, r) => l.message !== r.message
);

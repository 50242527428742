import { GridDateFilter } from 'lib/grid/GridDateFilter';
import { Box } from '@mui/material';
import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { Row } from 'ui/Flex';
import { OnGiftingLiabilityFilterChange } from 'components/analytics/gifting/liability/hooks/useGiftingLiabilityFilter';
import { GiftingLiabilityFilter } from 'components/analytics/gifting/liability/model/giftingLiabilityFilter';

interface GiftingLiabilityFiltersProps {
    filter: GiftingLiabilityFilter;
    onChange: OnGiftingLiabilityFilterChange;
}

export const GiftingLiabilityFilters: React.FC<GiftingLiabilityFiltersProps> = ({ filter, onChange }) => {
    const handleDateChange = React.useCallback(
        (value?: DateRangePickerValue) => {
            onChange('date', value);
        },
        [onChange]
    );
    return (
        <Box mt={2} display="flex">
            <Row flex={1} wrap="wrap" valign="center">
                <Box marginRight={1} marginTop={2} minWidth={273}>
                    <GridDateFilter value={filter.date} onChange={handleDateChange} />
                </Box>
            </Row>
        </Box>
    );
};

import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { FallbackSkeleton } from 'ui/skeleton/FallbackSkeleton';
import { SkeletonProps } from 'ui/skeleton/SkeletonComponent';
import { NotificationDetailsCard } from './cards/NotificationDetailsCard';
import { NotificationReceiptsCard } from './cards/NotificationReceiptsCard';
import { NotificationSuccessRateCard } from './cards/NotificationSuccessRateCard';
import { Notification } from './models/Notification';

interface NotificationsViewProps {
    notification: Notification<string>;
    loading: boolean;
}

const fallbackProps: Partial<SkeletonProps> = {
    height: '320px'
};

export const NotificationsView: React.FC<NotificationsViewProps> = ({ notification, loading }) => (
    <React.Fragment>
        <Hidden lgDown>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FallbackSkeleton loading={loading} {...fallbackProps}>
                                <NotificationDetailsCard notification={notification} />
                            </FallbackSkeleton>
                        </Grid>
                        <Grid item xs={12}>
                            <FallbackSkeleton loading={loading} {...fallbackProps}>
                                <NotificationSuccessRateCard notification={notification} />
                            </FallbackSkeleton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FallbackSkeleton loading={loading} {...fallbackProps} height="400px">
                                <NotificationReceiptsCard notification={notification} />
                            </FallbackSkeleton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Hidden>

        <Hidden mdDown lgUp>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FallbackSkeleton loading={loading} {...fallbackProps}>
                                <NotificationDetailsCard notification={notification} />
                            </FallbackSkeleton>
                        </Grid>
                        <Grid item xs={12}>
                            <FallbackSkeleton loading={loading} {...fallbackProps}>
                                <NotificationSuccessRateCard notification={notification} />
                            </FallbackSkeleton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FallbackSkeleton loading={loading} {...fallbackProps} height="400px">
                                <NotificationReceiptsCard notification={notification} />
                            </FallbackSkeleton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Hidden>
        <Hidden mdUp>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FallbackSkeleton loading={loading} {...fallbackProps}>
                        <NotificationDetailsCard notification={notification} />
                    </FallbackSkeleton>
                </Grid>
                <Grid item xs={12}>
                    <FallbackSkeleton loading={loading} {...fallbackProps}>
                        <NotificationSuccessRateCard notification={notification} />
                    </FallbackSkeleton>
                </Grid>
                <Grid item xs={12}>
                    <FallbackSkeleton loading={loading} {...fallbackProps} height="400px">
                        <NotificationReceiptsCard notification={notification} />
                    </FallbackSkeleton>
                </Grid>
            </Grid>
        </Hidden>
    </React.Fragment>
);

import { Box } from '@mui/material';
import React from 'react';
import { Row } from 'ui/Flex';
import { MenuSummaryFilter } from '../MenuManagerModel';
import { MagicSearch } from './MagicSearch';

interface IProps {
    filter: MenuSummaryFilter;
    onFilterChange: (newFilter: MenuSummaryFilter) => void;
}

export const MenuManagerTableFilter: React.FC<IProps> = ({ filter, onFilterChange }) => {
    const handleValueChange = React.useCallback(
        (field: keyof MenuSummaryFilter) => (value: string) => {
            const newFilter = { ...filter };
            newFilter[field] = value;
            onFilterChange(newFilter);
        },
        [filter, onFilterChange]
    );

    return (
        <Row>
            <Box marginTop={1} marginRight={1}>
                <MagicSearch
                    value={filter.search}
                    placeholder="Search by menu or location name"
                    onChange={handleValueChange('search')}
                />
            </Box>
        </Row>
    );
};

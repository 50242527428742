import React from 'react';
import { useToggle } from 'lib/hooks/useToggle';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { Tenant } from 'components/merchant/models/Tenant';
import { tenantApi } from 'components/merchant/tenantApi';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_TENANT_DISABLE_ERROR,
    MESSAGE_TENANT_DISABLE_SUCCESS,
    MESSAGE_TENANT_REACTIVATE_ERROR,
    MESSAGE_TENANT_REACTIVATE_SUCCESS
} from 'config/messages';
import { ApplicationState } from 'store/store';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import { authorize } from 'store/auth/authActions';

const CONFIRM_DISABLE = 'CONFIRM DISABLE';
const CONFIRM_REACTIVATE = 'CONFIRM REACTIVATE';

export const DisableTenantButton: React.FC = () => {
    const dispatch = useDispatch();
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);

    const [tenant, setTenant] = React.useState<Tenant | null>(null);
    const [isDialogOpen, toggleIsDialogOpen] = useToggle(false);

    const isTenantDisabled = React.useMemo(() => tenant?.isDisabled, [tenant]);

    const protectionWord = isTenantDisabled ? CONFIRM_REACTIVATE : CONFIRM_DISABLE;
    const successText = isTenantDisabled ? MESSAGE_TENANT_REACTIVATE_SUCCESS : MESSAGE_TENANT_DISABLE_SUCCESS;
    const errorText = isTenantDisabled ? MESSAGE_TENANT_REACTIVATE_ERROR : MESSAGE_TENANT_DISABLE_ERROR;

    const dialogContent = React.useCallback(
        () => (
            <>
                <span>
                    If you {isTenantDisabled ? 'reactivate' : 'disable'} this tenant, customers will
                    {isTenantDisabled ? ' ' : ' not '}be able to use the Pepper app and console. You can
                    {isTenantDisabled ? ' disable ' : ' reactivate '}the tenant in the future.
                </span>
                <br />
                <span>
                    To confirm, please type <strong>{protectionWord}</strong> in the text field below.
                </span>
            </>
        ),
        [isTenantDisabled, protectionWord]
    );

    const handleDisableOrReactivateTenant = React.useCallback(async () => {
        try {
            const res = await tenantApi.update(tenant.tenantId, {
                body: {
                    isDisabled: !isTenantDisabled,
                    ...(!isTenantDisabled && { confirmDisable: CONFIRM_DISABLE })
                }
            });

            if (!res.ok) {
                throw new Error();
            }

            setTenant(res.body);
            toggleIsDialogOpen();
            authorize()(dispatch);

            dispatch(enqueueSnackbar(successText, { variant: 'success' }));
        } catch {
            dispatch(enqueueSnackbar(errorText, { variant: 'error' }));
        }
    }, [dispatch, errorText, isTenantDisabled, successText, tenant, toggleIsDialogOpen]);

    const handleFetchTenant = React.useCallback(async () => {
        const tenantResult = await tenantApi.get(tenantId);

        if (tenantResult.ok === true) {
            setTenant(tenantResult.body);
        }
    }, [tenantId]);

    React.useEffect(() => {
        handleFetchTenant();
    }, [handleFetchTenant]);

    if (!tenant) {
        return null;
    }

    return (
        <>
            <Button
                variant="outlined"
                color={!isTenantDisabled ? 'error' : 'primary'}
                onClick={toggleIsDialogOpen}
                fullWidth
            >
                {isTenantDisabled ? 'Reactivate' : 'Disable'} Tenant
            </Button>
            <DeleteDialog
                title={`${isTenantDisabled ? 'Reactivate' : 'Disable'} ${tenant.title}`}
                content={dialogContent()}
                onClose={toggleIsDialogOpen}
                onDelete={handleDisableOrReactivateTenant}
                protectionWord={protectionWord}
                open={isDialogOpen}
                submitLabel={isTenantDisabled ? 'Reactivate' : 'Disable'}
            />
        </>
    );
};

import React from 'react';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getAllPerks } from 'store/perks/perksActions';
import { SettingDisplay } from 'lib/SettingDisplay';
import { Chip } from '@mui/material';
import { getGoodtillPriceSourceLabel } from './goodtill';

export function GoodtillSettingsDisplay({ settings, locationId }: IPointOfSaleSettingsProps) {
    const dispatch = useDispatch();
    const { perks } = useSelector((state: ApplicationState) => state.perks);

    React.useEffect(() => {
        if (perks.length === 0) {
            getAllPerks()(dispatch);
        }
    }, [dispatch, perks.length]);
    const selectedPerk = React.useMemo(
        () => perks.find(perk => perk._id === settings?.goodtill?.loyaltyAtPOSPerkId),
        [perks, settings]
    );

    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <>
                    <SettingDisplayText title="Subdomain" text={settings?.goodtill?.subdomain} />
                    <SettingDisplayText title="Username" text={settings?.goodtill?.username} />
                    <SettingDisplayText title="Password" text={settings?.goodtill?.password} />
                    <SettingDisplayText
                        title="Verification Token"
                        text={settings?.goodtill?.verificationToken}
                    />
                    <SettingDisplayText title="Loyalty At POS Perk" text={selectedPerk?.title} />
                </>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <SettingDisplayText title="Outlet Id" text={settings?.goodtill?.outletId} />
            )}
            {/* Both */}
            <SettingDisplay label="Pre-Order Price Source">
                <Chip
                    label={getGoodtillPriceSourceLabel(settings?.goodtill?.PREORDER_priceSource)}
                    color="primary"
                />
            </SettingDisplay>
            <SettingDisplay label="Order to Table Price Source">
                <Chip
                    label={getGoodtillPriceSourceLabel(settings?.goodtill?.ORDER_TO_TABLE_priceSource)}
                    color="primary"
                />
            </SettingDisplay>
        </>
    );
}

import { ICreateTagItem } from 'components/location-tags/CreateTags';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

interface IProps {
    tag: ICreateTagItem;
    index: number;
    onDelete: (index: number) => void;
    onEdit: (details: ICreateTagItem, index: number) => void;
}

export function CreateTagItem({ tag, index, onDelete, onEdit }: IProps) {
    const [editingItemDetails, setEditingItemDetails] = React.useState<ICreateTagItem>();

    const handleDelete = React.useCallback(() => {
        onDelete(index);
    }, [index, onDelete]);
    const handleEdit = React.useCallback(() => {
        if (!editingItemDetails) {
            setEditingItemDetails({ ...tag });
        } else {
            onEdit(editingItemDetails, index);
            setEditingItemDetails(undefined);
        }
    }, [editingItemDetails, onEdit, tag, index]);
    const handleTitleChange = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setEditingItemDetails(oldValues => ({ ...oldValues, title: value }));
        },
        []
    );
    const handleSortChange = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setEditingItemDetails(oldValues => ({ ...oldValues, sort: value }));
        },
        []
    );

    return (
        <Grid container columnSpacing={2} mb={1.5} alignItems="flex-end">
            <Grid item xs={5}>
                {editingItemDetails ? (
                    <TextField value={editingItemDetails?.title} onChange={handleTitleChange} />
                ) : (
                    <Box borderBottom="1px solid" pt={1} pb={0.5}>
                        <Typography>{tag.title}</Typography>
                    </Box>
                )}
            </Grid>
            <Grid item xs={5}>
                {editingItemDetails ? (
                    <TextField
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={editingItemDetails?.sort}
                        onChange={handleSortChange}
                    />
                ) : (
                    <Box borderBottom="1px solid" pt={1} pb={0.5}>
                        <Typography>{tag.sort}</Typography>
                    </Box>
                )}
            </Grid>
            <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        onClick={handleEdit}
                        disabled={
                            !!editingItemDetails && (!editingItemDetails.title || !editingItemDetails.sort)
                        }
                    >
                        {editingItemDetails ? <DoneIcon /> : <EditIcon />}
                    </IconButton>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    );
}

/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Box, Button, Chip, styled, Typography } from '@mui/material';
import CalendarToday from '@mui/icons-material/CalendarToday';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FilterList from '@mui/icons-material/FilterList';
import clsx from 'clsx';
import { addDays, format } from 'date-fns';
import { actionApi } from 'components/actions/ActionApi';
import { ActionTimeline } from 'components/actions/ActionTimeline';
import { Perk } from 'components/loyalty/models/PerkModel';
import { formatDateRange, getPlatform } from 'lib/helpers';
import { Row } from 'ui/Flex';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { Panel } from 'ui/Panel';
import { ActionType, IAction, IActionData, isActionData } from '../../actions/models/Action';
import { CustomersActionModal } from '../modals/CustomersActionModal';
import { CustomersTimelineDateFilter } from '../modals/CustomersTimelineDateFilter';
import { CustomersTimelineTypesFilter } from '../modals/CustomersTimelineTypesFilter';
import customersActionTimelineReducer from '../reducers/CustomersActionTimelineReducer';

const { reducer, initialState, ActionType: CustomersTimelineActionType } = customersActionTimelineReducer;

const PREFIX = 'CustomersTimelineCard';

const classes = {
    timelineWrapper: `${PREFIX}-timelineWrapper`
};

const StyledPanel = styled(Panel)(({ theme }) => ({
    minHeight: theme.spacing(36),
    paddingBottom: 0,
    [`& .${classes.timelineWrapper}`]: {
        marginTop: -theme.spacing(1),
        overflow: 'auto',
        maxHeight: theme.spacing(82)
    }
}));

interface ICustomersTimelineCardProps {
    title: string;
    customerId: string;
    perks: Perk[];
    paymentMode: string;
}

const ITEMS_PER_PAGE = 50;
const allowedFilters: ActionType[] = [
    ActionType.ORDER_COMPLETED,
    ActionType.ORDER_USER_BILLED,
    ActionType.CHECKIN,
    ActionType.USER_CHANGED,
    ActionType.USER_CREATED,
    ActionType.USER_ACTIVATED,
    ActionType.USER_ACTIVATION_RESENT
];

const getActionsData = async (
    customerId: string,
    options?: Record<string, string | number>
): Promise<IActionData & { error?: Error }> => {
    try {
        const response = await actionApi.getActionsList(
            customerId,
            Object.entries(options).map(([key, value]) => ({ key, value }))
        );
        if (response.ok) {
            if (isActionData(response.body)) {
                return response.body;
            }
            if (!Array.isArray(response.body.actions) || response.body.actions.length !== 0) {
                throw new Error(`Expected Actions but got ${response.body}`);
            }
        }
        throw new Error(response.body.message);
    } catch (error) {
        return { error, actions: [], summary: null };
    }
};

export const CustomersTimelineCard: React.FC<ICustomersTimelineCardProps> = props => {
    const { customerId, title, paymentMode, perks } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const {
        actionsFilter,
        anchorTypesElement,
        hasMoreItems,
        loading,
        actionsList,
        currentPage,
        error,
        anchorDateElement,
        currentAction,
        actionsDate
    } = state;

    const loadActions = React.useCallback(
        async ({
            filter,
            fresh,
            date
        }: {
            filter?: string;
            fresh?: boolean;
            date?: DateRangePickerValue;
        }) => {
            dispatch({ type: CustomersTimelineActionType.START_REQUEST });
            const options: Record<string, string | number> = {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                per_page: ITEMS_PER_PAGE,
                page: fresh ? 1 : currentPage + 1
            };
            if (filter) {
                options.include = filter;
            }
            if (date) {
                if (date.startDate) {
                    options.date_from = format(date.startDate, 'yyyy-MM-dd');
                }
                if (date.endDate) {
                    options.date_to = format(addDays(date.endDate, 1), 'yyyy-MM-dd');
                }
                if (!date.endDate && date.startDate) {
                    options.date_to = format(addDays(date.startDate, 1), 'yyyy-MM-dd');
                }
            }
            const { actions, summary, error: localError } = await getActionsData(customerId, options);
            if (localError) {
                return dispatch({
                    type: CustomersTimelineActionType.CUSTOMER_ACTIONS_LOAD_ERROR,
                    error: localError.message
                });
            }
            if (fresh) {
                return dispatch({
                    type: CustomersTimelineActionType.CUSTOMER_ACTIONS_INITIAL_LOAD_SUCCESS,
                    data: { actions, summary }
                });
            }
            return dispatch({
                type: CustomersTimelineActionType.CUSTOMER_ACTIONS_LOAD_SUCCESS,
                data: { actions, summary }
            });
        },
        [currentPage, customerId]
    );

    React.useEffect(() => {
        loadActions({ fresh: true });
        // eslint-disable-next-line
    }, []);

    if (paymentMode?.toUpperCase() === 'PREPAY') {
        allowedFilters.push(ActionType.TOPUP);
    }

    const handleTypesFilterListOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        dispatch({
            type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_TYPES_ANCHOR_ELEMENT,
            element: event.currentTarget
        });
    }, []);

    const handleTypesFilterClose = React.useCallback(() => {
        dispatch({
            type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_TYPES_ANCHOR_ELEMENT,
            element: null
        });
    }, []);

    const handleDateFilterListOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        dispatch({
            type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_DATE_ANCHOR_ELEMENT,
            element: event.currentTarget
        });
    }, []);

    const handleDateFilterClose = React.useCallback(() => {
        dispatch({
            type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_DATE_ANCHOR_ELEMENT,
            element: null
        });
    }, [dispatch]);

    const handleItemClick = React.useCallback(
        (action: IAction) => {
            dispatch({ action, type: CustomersTimelineActionType.CUSTOMER_ACTION_OPEN });
        },
        [dispatch]
    );
    const handleModalClose = React.useCallback(() => {
        dispatch({ type: CustomersTimelineActionType.CUSTOMER_ACTION_CLOSE });
    }, [dispatch]);

    const handleScroll = React.useCallback(async () => {
        await loadActions({ filter: actionsFilter, date: actionsDate });
    }, [actionsDate, actionsFilter, loadActions]);

    const handleTypeFilter = React.useCallback(
        async (filter?: string) => {
            dispatch({ filter, type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_TYPES_FILTER });
            await loadActions({ filter, date: actionsDate, fresh: true });
        },
        [dispatch, actionsDate, loadActions]
    );

    const handleDateFilter = React.useCallback(
        async (date?: DateRangePickerValue) => {
            if (!date.startDate && !date.endDate) {
                dispatch({
                    date: undefined,
                    type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_DATE_FILTER
                });
            } else {
                dispatch({ date, type: CustomersTimelineActionType.CUSTOMER_ACTIONS_SET_DATE_FILTER });
            }
            await loadActions({ date, filter: actionsFilter, fresh: true });
        },
        [dispatch, actionsFilter, loadActions]
    );

    const actionRender = React.useCallback(
        () => (
            <Box marginTop={1}>
                <Row gutter valign="center">
                    {actionsDate ? (
                        <Chip
                            label={formatDateRange(actionsDate)}
                            onDelete={handleDateFilter}
                            onClick={handleDateFilterListOpen}
                        />
                    ) : (
                        <Button
                            color="secondary"
                            aria-controls="actions-menu"
                            aria-haspopup="true"
                            onClick={handleDateFilterListOpen}
                        >
                            <CalendarToday />
                            <ExpandMore />
                        </Button>
                    )}
                    <Button
                        color={actionsFilter ? 'primary' : 'secondary'}
                        aria-controls="actions-menu"
                        aria-haspopup="true"
                        onClick={handleTypesFilterListOpen}
                    >
                        <FilterList />
                        <ExpandMore />
                    </Button>
                </Row>
            </Box>
        ),
        [actionsDate, handleDateFilter, handleDateFilterListOpen, handleTypesFilterListOpen, actionsFilter]
    );

    return (
        <React.Fragment>
            <StyledPanel
                title={title}
                divider
                isLoading={loading}
                showAction={true}
                actionComponentRender={actionRender}
                onClick={() => {}}
            >
                <Box
                    className={clsx(classes.timelineWrapper, { 'hidden-scroll': getPlatform() !== 'MacOS' })}
                >
                    {loading || actionsList.length ? (
                        <ActionTimeline
                            loading={loading}
                            actions={actionsList}
                            handleScroll={handleScroll}
                            hasMoreItems={hasMoreItems}
                            onItemClick={handleItemClick}
                        />
                    ) : (
                        <Typography variant="subtitle2">
                            {error || 'No events could be found using this search term'}
                        </Typography>
                    )}
                </Box>
            </StyledPanel>
            <CustomersActionModal action={currentAction} perks={perks} onClose={handleModalClose} />
            <CustomersTimelineTypesFilter
                anchorElement={anchorTypesElement}
                items={allowedFilters}
                onSelect={handleTypeFilter}
                onClose={handleTypesFilterClose}
                selectedItem={actionsFilter}
            />
            <CustomersTimelineDateFilter
                anchorElement={anchorDateElement}
                value={actionsDate}
                onSelect={handleDateFilter}
                onClose={handleDateFilterClose}
            />
        </React.Fragment>
    );
};

import React, { useRef } from 'react';
import { MainLayout } from 'layouts/MainLayout';
import { locationApi } from 'components/location/LocationApi';
import { ITag, ITagGroup } from 'components/location/models/LocationModel';
import { Box, Button, Chip, styled } from '@mui/material';
import { EmptyState } from 'components/utils/emptyState';
import EmptyStateImage from 'static/locations-empty-state.svg';
import { LocationTagDetailsModal } from 'components/location-tags/modals/LocationTagDetailsModal';
import { MuiGrid } from 'lib/MuiGrid/MuiGrid';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { MESSAGE_DELETE_TAG_GROUP_ERROR, MESSAGE_DELETE_TAG_GROUP_SUCCESS } from 'config/messages';
import { useDispatch } from 'react-redux';
import { DeleteDialog } from 'ui/dialogs/DeleteDialog';
import AddIcon from '@mui/icons-material/Add';
import { LocationTagsActionsCell } from 'components/location-tags/LocationTagsActionsCell';
import { useLocation } from 'react-router-dom';
import { LocationTagsApplicationPopover } from 'components/location-tags/LocationTagsApplicationPopover';

const StyledMuiGrid = styled(MuiGrid)({
    '& .MuiDataGrid-cell': {
        maxHeight: 'unset !important'
    },
    '& .MuiDataGrid-row': {
        maxHeight: 'unset !important'
    }
});

function LocationTagsPage() {
    const dispatch = useDispatch();
    const { state } = useLocation<{ openCreateModal?: boolean }>();
    const boxRef = useRef<HTMLDivElement>();

    const [loading, setLoading] = React.useState(false);
    const [tagGroups, setTagGroups] = React.useState<ITagGroup[]>([]);
    const [isTagDetailsModalOpen, setTagDetailsModalOpen] = React.useState(!!state?.openCreateModal);
    const [isTagApplicationPopoverOpen, setTagApplicationPopoverOpen] = React.useState(false);
    const [tagGroupToEdit, setTagGroupToEdit] = React.useState<ITagGroup>();
    const [anchorElements, setAnchorElements] = React.useState<Record<string, HTMLElement | null>>({});
    const [tagGroupToDelete, setTagGroupToDelete] = React.useState<ITagGroup>();
    const [deleteLoading, setDeleteLoading] = React.useState(false);

    const getTagGroups = React.useCallback(() => {
        setLoading(true);
        locationApi
            .getTagGroups()
            .then(res => {
                if (res.ok) {
                    setTagGroups(res.body.items);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        getTagGroups();
    }, [getTagGroups]);

    const handleCreateClick = React.useCallback(() => {
        setTagDetailsModalOpen(true);
    }, []);

    const handleDetailsModalClose = React.useCallback(() => {
        setTagDetailsModalOpen(false);
        setTagGroupToEdit(undefined);
    }, []);
    const maxId = React.useMemo(() => {
        const ids = tagGroups.map(group => {
            const idAsNumber = Number(group.id);
            return isNaN(idAsNumber) || idAsNumber === Number.NEGATIVE_INFINITY ? 0 : idAsNumber;
        });

        if (ids.length) {
            return Math.max(...ids);
        }

        return 0;
    }, [tagGroups]);

    const handleActionsClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        setAnchorElements({ [id]: event.currentTarget });
    }, []);
    const handleActionsClose = React.useCallback(() => {
        setAnchorElements({});
    }, []);
    const handleItemEditClick = React.useCallback((tagGroup: ITagGroup) => {
        setTagGroupToEdit(tagGroup);
        setAnchorElements({});
        setTagDetailsModalOpen(true);
    }, []);
    const handleItemDeleteClick = React.useCallback((tagGroup: ITagGroup) => {
        setTagGroupToDelete(tagGroup);
        setAnchorElements({});
    }, []);
    const handleDeleteDialogClose = React.useCallback(() => {
        setTagGroupToDelete(undefined);
    }, []);
    const handleDeleteTagGroup = React.useCallback(() => {
        if (tagGroupToDelete) {
            setDeleteLoading(true);
            locationApi
                .deleteTagGroup(tagGroupToDelete.id)
                .then(res => {
                    if (res.ok) {
                        dispatch(enqueueSnackbar(MESSAGE_DELETE_TAG_GROUP_SUCCESS, { variant: 'success' }));
                        setTagGroupToDelete(undefined);
                        getTagGroups();
                    } else {
                        dispatch(enqueueSnackbar(MESSAGE_DELETE_TAG_GROUP_ERROR, { variant: 'error' }));
                    }
                })
                .finally(() => {
                    setDeleteLoading(false);
                });
        }
    }, [dispatch, getTagGroups, tagGroupToDelete]);

    const renderTagsCell = React.useCallback(
        (params: GridCellParams) => (
            <Box display="flex" flex={1} justifyContent="center" flexWrap="wrap" pt={1}>
                {Object.values(params.row.tags).map((tag: ITag) => (
                    <Chip label={tag.title} key={tag.id} sx={{ ml: 1, mb: 1 }} />
                ))}
            </Box>
        ),
        []
    );
    const renderActionsCell = React.useCallback(
        (params: GridCellParams) => (
            <LocationTagsActionsCell
                onClick={handleActionsClick}
                onClose={handleActionsClose}
                anchorEl={anchorElements[params.row.id]}
                tagGroup={params.row}
                onEdit={handleItemEditClick}
                onDelete={handleItemDeleteClick}
            />
        ),
        [anchorElements, handleActionsClick, handleActionsClose, handleItemDeleteClick, handleItemEditClick]
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                headerName: 'Title',
                field: 'title',
                flex: 0.2,
                sortable: false
            },
            {
                headerName: 'Tags',
                field: 'tags',
                sortable: false,
                filterable: false,
                flex: 0.5,
                renderCell: renderTagsCell,
                headerAlign: 'center'
            },
            {
                headerName: 'Actions',
                field: 'actions',
                filterable: false,
                flex: 0.3,
                sortable: false,
                renderCell: renderActionsCell,
                headerAlign: 'right'
            }
        ],
        [renderActionsCell, renderTagsCell]
    );

    const handleApplicationPopoverClose = React.useCallback(() => {
        setTagApplicationPopoverOpen(false);
    }, []);

    const handleTagsCreation = React.useCallback(() => {
        if (!tagGroups.length) {
            setTagApplicationPopoverOpen(true);
        }
    }, [tagGroups]);

    return (
        <MainLayout
            breadcrumbs={[{ label: 'Locations', url: '/locations' }]}
            pageName="Tags"
            pageDescription="Manage your location tags"
        >
            {(tagGroups && tagGroups.length > 0) || loading ? (
                <>
                    <Box height={0} flex={0} ref={boxRef} />
                    <LocationTagsApplicationPopover
                        anchorEl={boxRef.current}
                        onClose={handleApplicationPopoverClose}
                        open={isTagApplicationPopoverOpen}
                    />
                    <Box display="flex" justifyContent="flex-end" mb={1.5}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleCreateClick}
                        >
                            Add Tag
                        </Button>
                    </Box>
                    <StyledMuiGrid loading={loading} columns={columns} rows={tagGroups} />
                </>
            ) : (
                <EmptyState
                    headerText="You haven't created any tags yet"
                    paragraphText="Tags allow users to filter the location list to find the most suited location to order from."
                    buttonText="Create tags"
                    imageUrl={EmptyStateImage}
                    onClick={handleCreateClick}
                />
            )}
            <LocationTagDetailsModal
                maxId={maxId}
                open={isTagDetailsModalOpen}
                onClose={handleDetailsModalClose}
                getTagGroups={getTagGroups}
                tagGroup={tagGroupToEdit}
                onCreate={handleTagsCreation}
            />
            <DeleteDialog
                open={!!tagGroupToDelete}
                title={`Delete ${tagGroupToDelete?.title}?`}
                content="If you delete this tag group, you won't be able to recover it. To confirm deletion, type 'DELETE' in the text input field."
                onClose={handleDeleteDialogClose}
                onDelete={handleDeleteTagGroup}
                protectionWord="DELETE"
                loading={deleteLoading}
            />
        </MainLayout>
    );
}

export default LocationTagsPage;

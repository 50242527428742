import React from 'react';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { GiftingActivityFilter } from '../model/giftingActivityFilter';

export type OnGiftingActivityFilterChange = (
    field: keyof GiftingActivityFilter,
    value: string | string[] | DateRangePickerValue
) => void;

export function useGiftingActivityFilter(): [GiftingActivityFilter, OnGiftingActivityFilterChange] {
    const [filter, setFilter] = React.useState<GiftingActivityFilter>({
        date: {
            startDate: new Date(),
            endDate: new Date()
        }
    });
    const onFilterChange = React.useCallback((field, value) => {
        setFilter(currentFilter => ({ ...currentFilter, [field]: value }));
    }, []);
    return [filter, onFilterChange];
}

/* eslint-disable @typescript-eslint/naming-convention */
export const getGiftingActivityFilterBody = (filter: Partial<GiftingActivityFilter>) => ({
    start_date: filter.date?.startDate?.toISOString(),
    end_date: filter.date?.endDate?.toISOString() ?? filter.date?.startDate?.toISOString(),
    card_number: filter.card_number,
    location_ids: filter.location_ids
});

import { v4 as uuid } from 'uuid';

interface SessionInfo {
    serverHost: string;
    serverType?: string;
    region?: string;
}
interface Thread {
    id: number;
    name: string;
}
interface ScalyrEvent {
    thread?: string;
    ts: number;
    type?: 0 | 1 | 2;
    sev?: EventSeverity;
    attrs: { [key: string]: string | number };
}
interface AddEventRequest {
    token: string;
    session: string;
    sessionInfo: SessionInfo;
    events: ScalyrEvent[];
    threads?: Thread[];
}

enum EventSeverity {
    finest = 0,
    finer = 1,
    fine = 2,
    info = 3,
    warning = 4,
    error = 5,
    fatal = 6
}

enum MessageModificators {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL',
    LOG = 'LOG'
}

function getSessionInfo() {
    return {
        serverHost: `manager-${process.env.SERVICE_VERSION}-${process.env.TIER}`
    };
}

const localLogger = {
    [MessageModificators.CRITICAL]: console.trace,
    [MessageModificators.ERROR]: console.error,
    [MessageModificators.LOG]: console.log,
    [MessageModificators.WARNING]: console.warn
};

class Logger {
    constructor(
        private session = uuid(),
        private sessionInfo = getSessionInfo(),
        private token = process.env.SCALYR_API_KEY
    ) {}
    private get isProduction() {
        return process.env.NODE_ENV !== 'development';
    }
    generateRequestData(message: string): AddEventRequest {
        return {
            token: this.token,
            session: this.session,
            sessionInfo: this.sessionInfo,
            events: [
                {
                    ts: new Date().getMilliseconds(),
                    attrs: { message }
                }
            ]
        };
    }
    addEvent(modificator: MessageModificators, message: string, error: any) {
        if (this.isProduction) {
            localLogger[modificator](message, error);
        } else {
            localLogger[modificator](message, error);
        }
    }
    info(message: string, info?: string) {
        this.addEvent(MessageModificators.LOG, message, info);
    }
    warn(message: string, info?: any) {
        this.addEvent(MessageModificators.WARNING, message, info);
    }
    error(message: string, error?: any) {
        this.addEvent(MessageModificators.ERROR, message, error);
    }
    critical(message: string, error?: any) {
        this.addEvent(MessageModificators.CRITICAL, message, error);
    }
}

export default new Logger();

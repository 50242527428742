import { ELocalStorageKey, ESessionStorageKey } from 'config/storageKeys';
import { useSomethingNewPopupState } from 'lib/hooks/useSomethingNewPopupState';
import React from 'react';

export function useCalculatedRefundWarning(onSelectionChange: (newSelection: Set<string>) => void) {
    const [open, setOpen] = React.useState(false);
    const [pendingState, setPendingState] = React.useState<Set<string>>();
    const { setIsConfirmed, incrementCountShown, shouldShow } = useSomethingNewPopupState(
        ESessionStorageKey.RefundSessionIsConfirmed,
        ELocalStorageKey.CalculatedRefundInfoShown
    );
    const handleSelectionChange = React.useCallback(
        (newSelection: Set<string>) => {
            if (shouldShow()) {
                setPendingState(newSelection);
                setOpen(true);
            } else {
                onSelectionChange(newSelection);
            }
        },
        [onSelectionChange, shouldShow]
    );
    const handleCofirmSelection = React.useCallback(() => {
        setOpen(false);
        onSelectionChange(pendingState);
        setIsConfirmed();
        incrementCountShown();
        setPendingState(undefined);
    }, [incrementCountShown, onSelectionChange, pendingState, setIsConfirmed]);
    const handleDiscardSelectionChange = React.useCallback(() => {
        setOpen(false);
        setPendingState(undefined);
    }, []);
    return {
        open,
        onBridgeSelectionChange: handleSelectionChange,
        onCofirmSelection: handleCofirmSelection,
        onDiscardSelection: handleDiscardSelectionChange
    };
}

import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocations } from 'store/locations/locationsActions';
import { ApplicationState } from 'store/store';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';

export const useAppIds = () => {
    const dispatch = useDispatch();
    const { locations } = useSelector((state: ApplicationState) => state.locations);
    const [appIdToLocations, setAppIdToLocations] = React.useState<
        Map<string, { id: string; name: string; appId: string }[]>
    >(new Map());

    React.useEffect(() => {
        (async () => {
            if (locations.length === 0) {
                getAllLocations()(dispatch);
                return;
            }

            try {
                const result = await new LocationSettingsApi().getAllSettingValuesForTenant(
                    'stripe',
                    'accountId',
                    (obj: any): obj is { accountId: string } => obj && isString(obj.accountId)
                );
                // And we need to add the accountId for the common/global stripe account
                const commonId = await new LocationSettingsApi().getPublicSettings(
                    null,
                    undefined,
                    true,
                    'stripe'
                );
                const uniqueConnectIds = new Map<string, { name: string; id: string; appId: string }[]>();
                locations.forEach(location => {
                    const accountId = result[location._id]?.accountId ?? commonId?.stripe?.accountId;
                    if (isString(accountId)) {
                        const currentLocations = uniqueConnectIds.get(accountId) || [];
                        uniqueConnectIds.set(accountId, [
                            ...currentLocations,
                            {
                                name: location ? location.title : '',
                                id: location._id,
                                appId: accountId
                            }
                        ]);
                    }
                });

                setAppIdToLocations(uniqueConnectIds);
            } catch (err) {
                logger.error(err);
            }
        })();
    }, [dispatch, locations]);

    return { appIdToLocations } as const;
};

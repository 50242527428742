/* eslint-disable max-len */
import { GridStorageName } from 'lib/MuiGrid/StateController';

// Here we'll be declaring changes to menu grid columns
// -0 Added checkbox column to all the menu tables
// -1 mui5
const DEFAULT_SUFFIX = '-1';
const versionUpdates: Record<string, string> = {
    // -3 -> added text color, background color, and background image
    // -4 -> added priceLevel and price is editable now
    // -5 -> added restricted channels
    // -6 -> added quickpad menu listing colours
    [GridStorageName.Products]: '-6',
    // -3 added nutrition, changed the position of the nutrition => mui5
    [GridStorageName.ModifierOptions]: '-3',
    // -2 added categoryId and applyToCategoryId
    // -3 removed applyToCategoryId
    [GridStorageName.ModifiersProducts]: '-3',
    [GridStorageName.CategoryProducts]: '-3', // returned other columns as readonly => mui5 => removed availability
    /**
     * -2 -> New overrides table UI
     * -3 -> Swapped title and id columns
     * -4 -> Separate availability and visibility override
     * -5 -> Swap override columns order
     * -6 -> Hide visiblity override column by default
     * -7 -> Completely removed visiblity override column
     *  */
    [GridStorageName.ProductAvailability]: '-7',
    [GridStorageName.Modifiers]: '-2', // added isCollapsed
    // -2 -> Visible now editable
    // -3 -> Added restricted channels
    // -4 -> added quickpad menu listing colours
    [GridStorageName.Categories]: '-4',
    [GridStorageName.CategoryGroups]: '-2', // removed delete column
    [GridStorageName.Tags]: '-2', // removed delete column
    [GridStorageName.Taxes]: '-2', // removed delete column
    [GridStorageName.DynamicImages]: '-2', // removed delete column
    [GridStorageName.WeeklyAvailability]: '-2' // removed delete column
};

export function getMenuStorageName(storageName: GridStorageName) {
    return `${storageName}${versionUpdates[storageName] ?? DEFAULT_SUFFIX}`;
}

import React from 'react';
import { Link as NextLink, withRouter } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import clsx from 'clsx';
import { WithRouterProps } from 'types/history';

interface NextComposedProps {
    as?: string;
    href?: string;
    className?: string;
}

// had to put any for a meanshile
// TODO: remove type casting to any
const NextComposed = React.forwardRef<NextLink, NextComposedProps>(({ href, ...other }, ref) => (
    <NextLink to={href} ref={ref as any} {...other} />
));

interface MuiLinkProps {
    children?: React.ReactNode;
    underline?: 'none' | 'hover' | 'always';
    href: string;
    as?: string;
    staticContext?: never;
    activeClassName?: string;
    className?: string;
    naked?: boolean;
    onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) &
        ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void);
    innerRef?: any;
}

type MuiLinkPropsElement = MuiLinkProps & WithRouterProps;

function LinkElement(props: MuiLinkPropsElement) {
    const {
        activeClassName,
        className: classNameProps,
        innerRef,
        naked,
        location,
        staticContext: _,
        ...other
    } = props;

    const className = clsx(classNameProps, {
        [activeClassName]: location.pathname === props.href && activeClassName
    });

    if (naked) {
        return <NextComposed className={className} ref={innerRef} {...other} />;
    }

    return (
        <MuiLink component={NextComposed} className={className} ref={innerRef} {...other} underline="hover" />
    );
}

LinkElement.defaultProps = {
    activeClassName: 'active'
};
const RouterLink = withRouter(LinkElement);

export const Link = React.forwardRef((props: MuiLinkProps, ref) => <RouterLink {...props} innerRef={ref} />);

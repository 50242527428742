import React from 'react';
import { TENANT_ACCESS_ITEMS_PER_PAGE, getTenantAccessData } from './useTenantAccess';
import { TenantAccessState } from 'components/merchant/models/TenantAccess';

interface TenantSearchProps {
    updateTenantAccess: React.Dispatch<React.SetStateAction<any>>;
    updateNextKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    updatePage: React.Dispatch<React.SetStateAction<number>>;
}

export const useTenantSearch = ({ updateTenantAccess, updateNextKey, updatePage }: TenantSearchProps) => {
    const [searchValue, setSearchValue] = React.useState('');
    const [searchFilter, setSearchFilter] = React.useState('');
    const [isSearchPending, setIsSearchPending] = React.useState(false);

    const handleSearchValueChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }, []);

    const handleSearchClick = React.useCallback(async () => {
        setIsSearchPending(true);
        setSearchFilter(searchValue);
        updatePage(0);

        const response = await getTenantAccessData({
            tenantName: searchValue,
            limit: TENANT_ACCESS_ITEMS_PER_PAGE,
            states: TenantAccessState.ACTIVE
        });

        if (response) {
            updateTenantAccess(response.tenantAccess);
            updateNextKey(response.nextKey);
        }
        setIsSearchPending(false);
    }, [searchValue, updateNextKey, updatePage, updateTenantAccess]);

    const handleSearchKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                handleSearchClick();
            }
        },
        [handleSearchClick]
    );

    return {
        searchValue,
        searchFilter,
        isSearchPending,
        handleSearchValueChange,
        handleSearchClick,
        handleSearchKeyDown
    };
};

import React from 'react';
import { Grid, Hidden } from '@mui/material';
import {
    LocationAddressCardConnected,
    LocationGeneralCard,
    LocationInAppLinksCard,
    LocationOpeningHoursCard,
    LocationServicesCard,
    LocationTablesAndZonesCard
} from 'components/location/cards';
import { Location } from 'components/location/models/LocationModel';
import { LocationButtonCard } from './LocationButtonCard';
import { LocationImagesCard } from './LocationImagesCard';
import { LocationOrderNotes } from './LocationOrderNotes';
import { LocationTagsCard } from 'components/location/cards/LocationTagsCard';
import { LocationWebLinks } from './LocationWebLinks';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { Permission, Resource } from '@pepperhq/auth-client';

interface LocationCardsWrapperProps {
    location: Location;
    setCurrentLocation: (location: Location) => void;
}

export const LocationCardsWrapper = ({ location, setCurrentLocation }: LocationCardsWrapperProps) => {
    const { claims } = useSelector((state: ApplicationState) => state.auth);
    const showWebLinks = React.useMemo(
        () => claims?.hasPermissionForLocation(location?._id, Resource.LocationWebLinks, Permission.read),
        [claims, location?._id]
    );
    return (
        <React.Fragment>
            <Hidden lgDown>
                <Grid container spacing={2}>
                    <Grid item lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocationImagesCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationGeneralCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationButtonCard
                                    location={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationTagsCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocationAddressCardConnected
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationServicesCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationTablesAndZonesCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            {showWebLinks && (
                                <Grid item xs={12}>
                                    <LocationWebLinks currentLocation={location} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocationOpeningHoursCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationInAppLinksCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationOrderNotes
                                    setCurrentLocation={setCurrentLocation}
                                    currentLocation={location}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdDown lgUp>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocationGeneralCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationImagesCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationServicesCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocationAddressCardConnected
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationOpeningHoursCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationInAppLinksCard
                                    currentLocation={location}
                                    setCurrentLocation={setCurrentLocation}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LocationGeneralCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationAddressCardConnected
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationImagesCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationOpeningHoursCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationServicesCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationInAppLinksCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationButtonCard location={location} setCurrentLocation={setCurrentLocation} />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationTagsCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocationTablesAndZonesCard
                            currentLocation={location}
                            setCurrentLocation={setCurrentLocation}
                        />
                    </Grid>
                    {showWebLinks && (
                        <Grid item xs={12}>
                            <LocationWebLinks currentLocation={location} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LocationOrderNotes
                            setCurrentLocation={setCurrentLocation}
                            currentLocation={location}
                        />
                    </Grid>
                </Grid>
            </Hidden>
        </React.Fragment>
    );
};

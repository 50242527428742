import React from 'react';
import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { MuiModal } from 'ui/MuiModal';
import { IAction } from 'components/actions/models/Action';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_ACTION_COPY_TO_CLIPBOARD_FAIL,
    MESSAGE_ACTION_COPY_TO_CLIPBOARD_SUCCESS
} from 'config/messages';

const PREFIX = 'ActionDetailModal';

const classes = {
    jsonStringContainer: `${PREFIX}-jsonStringContainer`,
    jsonText: `${PREFIX}-jsonText`
};

const StyledMuiModal = styled(MuiModal)({
    [`& .${classes.jsonStringContainer}`]: {
        backgroundColor: '#eee',
        borderRadius: 4
    },
    [`& .${classes.jsonText}`]: {
        userSelect: 'none',
        cursor: 'pointer'
    }
});

interface IActionDetailModalProps {
    onClose: () => void;
    open: boolean;
    action?: IAction;
}

const ActionDetailModalContent: React.FC<IActionDetailModalProps> = ({ action, onClose }) => {
    const dispatch = useDispatch();

    const handleJsonTextDoubleClick = React.useCallback(async () => {
        try {
            await navigator.clipboard.writeText(JSON.stringify(action));
            dispatch(enqueueSnackbar(MESSAGE_ACTION_COPY_TO_CLIPBOARD_SUCCESS, { variant: 'success' }));
        } catch {
            dispatch(enqueueSnackbar(MESSAGE_ACTION_COPY_TO_CLIPBOARD_FAIL, { variant: 'error' }));
        }
    }, [action, dispatch]);

    return (
        <Box minWidth={640}>
            <Box paddingY={2}>
                <Typography color="primary" variant="h5">
                    History
                </Typography>
            </Box>
            <Divider />
            <Box paddingY={2}>
                <Box marginBottom={1}>
                    <Typography variant="body2">Double click to copy</Typography>
                </Box>
                <Box padding={1} className={classes.jsonStringContainer}>
                    <Typography
                        variant="body2"
                        className={classes.jsonText}
                        noWrap
                        onDoubleClick={handleJsonTextDoubleClick}
                    >
                        {JSON.stringify(action)}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box marginTop={2}>
                {!!action && (
                    <ReactJson
                        indentWidth={5}
                        enableClipboard={false}
                        style={{ userSelect: 'none' }}
                        name="action"
                        src={action}
                    />
                )}
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <Button size="large" variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </Box>
        </Box>
    );
};

export const ActionDetailModal: React.FC<IActionDetailModalProps> = props => {
    const { onClose, open } = props;
    return (
        <StyledMuiModal noMaxWidth open={open} onClose={onClose}>
            <ActionDetailModalContent {...props} />
        </StyledMuiModal>
    );
};

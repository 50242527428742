import {
    CustomerCredential,
    CustomerCredentialProvider,
    EPrimaryPlatform
} from 'components/customers/models/Customer';
import { IOrder, isOrderScenario, OrderScenario } from 'components/order/model/Order';
import {
    isArrayOf,
    isBoolean,
    isDefined,
    isNumber,
    isOptional,
    isOptionalString,
    isString
} from 'lib/typeguards';

export enum ActionType {
    // actions from api-server
    CARD_CREATED = 'CARD_CREATED',
    CARD_DELETED = 'CARD_DELETED',
    CHECKIN = 'CHECKIN',
    USER_CREDITED = 'USER_CREDITED',
    CAUSE_GIFT = 'CAUSE_GIFT',
    ORDER_CREATED = 'ORDER_CREATED',
    ORDER_COMPLETED = 'ORDER_COMPLETED',
    REDEEM_PERK = 'REDEEM_PERK',
    AWARD_POINTS_REFUNDED = 'AWARD_POINTS_REFUNDED',
    REFERAL_CLAIMED_REFEREE = 'REFERAL_CLAIMED_REFEREE',
    REFERAL_CLAIMED_REFERER = 'REFERAL_CLAIMED_REFERER',
    PURCHASE = 'PURCHASE',
    REWARD_EARNED = 'REWARD_EARNED',
    REWARD_BURNED = 'REWARD_BURNED', // deprecated, should this be removed?
    REFUND = 'REFUND',
    TOPUP = 'TOPUP',
    TOPUP_REFUNDED = 'TOPUP_REFUNDED',
    SURVEY_RESPONDED = 'SURVEY_RESPONDED',
    MEMBER_ADDED = 'MEMBER_ADDED',
    MEMBER_REMOVED = 'MEMBER_REMOVED',
    USER_CREATED = 'USER_CREATED',
    USER_CHANGED = 'USER_CHANGED',
    USER_DELETED = 'USER_DELETED',
    USER_ACTIVATION_RESENT = 'USER_ACTIVATION_RESENT',
    USER_ACTIVATED = 'USER_ACTIVATED',
    CREDENTIAL_VERIFIED = 'CREDENTIAL_VERIFIED',
    VOUCHER_REDEEMED = 'VOUCHER_REDEEMED',
    GIFT_CARD_ACTIVATED = 'GIFT_CARD_ACTIVATED',
    GIFT_CARD_TOPUP = 'GIFT_CARD_TOPUP',

    // from order service
    ORDER_PAYMENT_REFUNDED = 'ORDER_PAYMENT_REFUNDED',
    ORDER_PAYMENT_RECEIVED = 'ORDER_PAYMENT_RECEIVED',
    ORDER_USER_JOINED = 'ORDER_USER_JOINED',
    ORDER_USER_BILLED = 'ORDER_USER_BILLED',
    ORDER_ITEMS_ADDED = 'ORDER_ITEMS_ADDED',

    // fallback
    UNKNOWN = 'UNKNOWN'
}

export function isKnownActionType(value: string | ActionType): value is ActionType {
    return Object.values(ActionType).some((x: string) => x.toUpperCase() === value.toUpperCase());
}

function isActionType(value: string | ActionType): value is ActionType {
    return isString(value);
}

export function isActionRewardEarnedMetadata(metadata: any): metadata is IActionRewardEarnedMetadata {
    return isNumber(metadata.loyaltyEarned) && isOptionalString(metadata.perkId);
}

export enum CreditScheme {
    BALANCE = 'BALANCE',
    POINTS = 'POINTS',
    POINT_PERK = 'POINT_PERK'
}

interface IActionUserContext {
    _id: string;
    fullName: string;
    primaryPlatform: EPrimaryPlatform;
}

interface ILocationContext {
    _id: string;
    title: string;
}

export interface IActionContext {
    user?: IActionUserContext;
    location?: ILocationContext;
}

function isActionContextUser(user: any): user is IActionUserContext {
    return (
        isDefined(user) &&
        isString(user._id) &&
        isOptionalString(user.name) &&
        isOptionalString(user.fullName)
    );
}

export function isActionContext(context: any): context is IActionContext {
    return (
        isDefined(context) &&
        isOptional(isActionContextUser, context.user) &&
        isOptional(isActionContextLocation, context.location)
    );
}

export interface IActionWhen {
    timezone: string;
    timestamp: string;
    year: number;
    monthofyear: number;
    weekofyear: number;
    dayofmonth: number;
    dayofweek: number;
    daypart: string;
}

export function isActionWhen(when: any): when is IActionWhen {
    return (
        isDefined(when) &&
        isString(when.timezone) &&
        isNumber(isValidDate(when.timestamp)) &&
        isNumber(when.year) &&
        isNumber(when.dayofmonth) &&
        isNumber(when.dayofweek) &&
        isNumber(isValidDate(`${when.year}/${when.dayofmonth}/${when.dayofweek}/`)) &&
        isNumber(when.monthofyear) &&
        isNumber(when.weekofyear) &&
        isString(when.daypart)
    );
}

export interface IActionMetadataOrderValue {
    range: string;
    amount: number;
    currency: string;
}

export function isActionMetadataOrderValue(value: any): value is IActionMetadataOrderValue {
    return isString(value.range) && isNumber(value.amount) && isString(value.currency);
}

export interface IActionOrderCompletedMetadataOC2 {
    value: IActionMetadataOrderValue;
    totals: {
        taxAdded: number;
        taxToAdd: number;
        total: number;
        subtotal: number;
        tips: number;
        discounts: number;
        charges: number;
        payments: number;
    };
    order: IOrder;
}

export function isActionOrderCompletedMetadataOC2(
    metadata: any
): metadata is IActionOrderCompletedMetadataOC2 {
    return (
        isDefined(metadata.order) && isActionMetadataOrderValue(metadata.value) && isDefined(metadata.totals)
    );
}

export interface IActionOrderCompletedMetadataOC1 {
    value: IActionMetadataOrderValue;
    payment: {
        totalPrice: number;
        tip: number;
        tax: number;
        total: number;
        subTotal: number;
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    basket_size: number;
    basket: any[];
    scenario: OrderScenario;
    orderId: string;
}

export function isActionOrderCompletedMetadataOC1(
    metadata: any
): metadata is IActionOrderCompletedMetadataOC1 {
    return (
        isString(metadata.orderId) &&
        isActionMetadataOrderValue(metadata.value) &&
        isDefined(metadata.payment) &&
        isString(metadata.scenario) &&
        isNumber(metadata.basket_size) &&
        Array.isArray(metadata.basket)
    );
}

type IActionOrderCompletedMetadata = IActionOrderCompletedMetadataOC2 | IActionOrderCompletedMetadataOC1;

interface IActionCredentialVerifiedMetadata {
    credential: CustomerCredential;
}

function isActionCredentialVerifiedMetadata(metadata: any): metadata is IActionCredentialVerifiedMetadata {
    return isDefined(metadata.credential) && isString(metadata.credential.id);
}

interface IActionRefundMetadata {
    refundId: string;
    shortCode: string;
    value: IActionMetadataOrderValue;
}

export function isActionRefundMetadata(metadata: any): metadata is IActionRefundMetadata {
    return (
        isString(metadata.refundId) &&
        isString(metadata.shortCode) &&
        isActionMetadataOrderValue(metadata.value)
    );
}

interface IActionCreditedMetadata {
    creditId?: string;
    perkId?: string;
    scheme: CreditScheme;
    value: IActionMetadataOrderValue;
}

function isActionCreditedMetadata(metadata: any): metadata is IActionCreditedMetadata {
    return (
        isString(metadata.creditId) &&
        isOptionalString(metadata.perkId) &&
        isOptionalString(metadata.creditId) &&
        isString(metadata.scheme) &&
        isActionMetadataOrderValue(metadata.value) &&
        isString(metadata.value.currency) &&
        isNumber(metadata.value.amount) &&
        isString(metadata.value.range)
    );
}

interface IActionUserCreatedMetadata {
    hasAgreedToReceiveMarketing: boolean;
    hasAgreedToShareData: boolean;
    provider: CustomerCredentialProvider;
    username: string;
}

export function isActionUserCreatedMetadata(metadata: any): metadata is IActionUserCreatedMetadata {
    return (
        isBoolean(metadata.hasAgreedToReceiveMarketing) &&
        isBoolean(metadata.hasAgreedToShareData) &&
        isString(metadata.provider) &&
        isString(metadata.username)
    );
}

interface IActionGiftCardMetadata {
    giftCardNumber: string;
    provider: string;
    value: IActionMetadataOrderValue;
}

export function isActionGiftCardMetadata(metadata: any): metadata is IActionGiftCardMetadata {
    return (
        isString(metadata.giftCardNumber) &&
        isString(metadata.provider) &&
        isActionMetadataOrderValue(metadata.value)
    );
}

interface IActionReferalClaimedRefereeMetadata {
    refererFullName: string;
    refererId: string; // nb old actions were incorrect with refereeId and refereeFullName
    referalId: string;
}

export function isActionReferalClaimedRefereeMetadata(
    metadata: any
): metadata is IActionReferalClaimedRefereeMetadata {
    return isString(metadata.refererFullName) && isString(metadata.refererId) && isString(metadata.referalId);
}

interface IActionReferalClaimedRefererMetadata {
    refereeFullName: string;
    refereeId: string; // nb old actions were incorrect with refererId and refererFullName
    referalId: string;
}

export function isActionReferalClaimedRefererMetadata(
    metadata: any
): metadata is IActionReferalClaimedRefererMetadata {
    return isString(metadata.refereeFullName) && isString(metadata.refereeId) && isString(metadata.referalId);
}

export interface IActionOrderCreatedMetadata {
    orderId: string | number;
    scenario: OrderScenario;
}

export function isActionOrderCreatedMetadata(metadata: any): metadata is IActionOrderCreatedMetadata {
    return (isString(metadata.orderId) || isNumber(metadata.orderId)) && isOrderScenario(metadata.scenario);
}

export interface IActionOrderCreated extends IAction {
    type: ActionType.ORDER_CREATED;
    context: IActionWithLocationContext;
    metadata: IActionOrderCreatedMetadata;
}

export function isActionOrderCreated(action: any): action is IActionOrderCreated {
    return (
        action.type === ActionType.ORDER_CREATED &&
        isActionWithLocationContext(action.context) &&
        isActionOrderCreatedMetadata(action.metadata)
    );
}

export interface IActionOrderUserJoinedMetadata {
    order: {
        id: number;
        scenario: OrderScenario;
    };
}

export function isActionOrderUserJoinedMetadata(metadata: any): metadata is IActionOrderUserJoinedMetadata {
    return (
        isDefined(metadata.order) && isNumber(metadata.order.id) && isOrderScenario(metadata.order?.scenario)
    );
}

export interface IActionOrderUserJoined extends IAction {
    type: ActionType.ORDER_USER_JOINED;
    context: IActionWithLocationContext;
    metadata: IActionOrderUserJoinedMetadata;
}

export function isActionOrderUserJoined(action: any): action is IActionOrderUserJoined {
    return (
        action.type === ActionType.ORDER_USER_JOINED &&
        isActionWithLocationContext(action.context) &&
        isActionOrderUserJoinedMetadata(action.metadata)
    );
}

export interface IActionOrderPaymentReceivedMetadata {
    value: IActionMetadataOrderValue;
    order: {
        scenario: OrderScenario;
    };
}

export function isActionOrderPaymentReceivedMetadata(
    metadata: any
): metadata is IActionOrderPaymentReceivedMetadata {
    return (
        isActionMetadataOrderValue(metadata.value) &&
        isDefined(metadata.order) &&
        isOrderScenario(metadata.order?.scenario)
    );
}

export interface IActionOrderPaymentReceived extends IAction {
    type: ActionType.ORDER_PAYMENT_RECEIVED;
    context: IActionWithLocationContext;
    metadata: IActionOrderPaymentReceivedMetadata;
}

export function isActionOrderPaymentReceived(action: any): action is IActionOrderPaymentReceived {
    return (
        action.type === ActionType.ORDER_PAYMENT_RECEIVED &&
        isActionWithLocationContext(action.context) &&
        isActionOrderPaymentReceivedMetadata(action.metadata)
    );
}

export interface IActionVoucherRedeemedMetadata {
    voucherTitle: string;
    voucherId: string;
    voucherRedemptionId: string;
    voucherCode: string;
}

export function isActionVoucherRedeemedMetadata(metadata: any): metadata is IActionVoucherRedeemedMetadata {
    return (
        isString(metadata.voucherTitle) &&
        isString(metadata.voucherId) &&
        isString(metadata.voucherRedemptionId) &&
        isString(metadata.voucherCode)
    );
}

export interface IActionVoucherRedeemed extends IAction {
    type: ActionType.VOUCHER_REDEEMED;
    context: IActionContext;
    metadata: IActionVoucherRedeemedMetadata;
}

export function isActionVoucherRedeemed(action: any): action is IActionVoucherRedeemed {
    return (
        action.type === ActionType.VOUCHER_REDEEMED &&
        isActionWithLocationContext(action.context) &&
        isActionVoucherRedeemedMetadata(action.metadata)
    );
}

type IActionMetadata =
    | IActionAudienceMetadata
    | IActionOrderCompletedMetadata
    | IActionCredentialVerifiedMetadata
    | IActionRewardEarnedMetadata
    | IActionRewardBurnedMetadata
    | IActionRedeemPerkMetadata
    | IActionCreditedMetadata
    | IActionUserCreatedMetadata
    | IActionRefundMetadata
    | IActionOrderPaymentRefundedMetadata
    | IActionReferalClaimedRefereeMetadata
    | IActionReferalClaimedRefererMetadata
    | IActionOrderCreatedMetadata
    | IActionOrderPaymentReceivedMetadata
    | IActionOrderUserJoinedMetadata
    | IActionVoucherRedeemedMetadata
    | IActionGiftCardMetadata;

interface IActionWithLocationContext extends IActionContext {
    location: ILocationContext;
}

function isActionContextLocation(location: any): location is ILocationContext {
    return isDefined(location) && isString(location._id) && isOptionalString(location.title);
}

function isActionWithLocationContext(context: any): context is IActionWithLocationContext {
    return isActionContext(context) && isActionContextLocation(context.location);
}

export interface IAction {
    _id: string;
    type: ActionType;
    version?: number;
    source?: string;
    context: IActionContext;
    when: IActionWhen;
    metadata?: IActionMetadata;
}

export interface IOrderAction {
    _id: string;
    type: ActionType;
    version?: number;
    source?: string;
    context: IActionContext;
    when: IActionWhen;
    metadata?: IActionOrderCompletedMetadataOC2 | IActionOrderCompletedMetadataOC1;
}

export function isAction(action: any): action is IAction {
    return (
        isDefined(action) &&
        isString(action._id) &&
        isActionType(action.type) &&
        isActionContext(action.context) &&
        isActionWhen(action.when)
    );
}

interface IActionAudienceMetadata {
    audienceTitle: string;
    audienceId: string;
}

export function isActionAudienceMetadata(metadata: any): metadata is IActionAudienceMetadata {
    return isString(metadata.audienceTitle) && isString(metadata.audienceId);
}

export interface IAudienceAction extends IAction {
    type: ActionType.MEMBER_REMOVED | ActionType.MEMBER_ADDED;
    metadata: IActionAudienceMetadata;
}

export function isAudienceAction(action: any): action is IAudienceAction {
    return (
        isAction(action) &&
        (action.type === ActionType.MEMBER_REMOVED || action.type === ActionType.MEMBER_ADDED) &&
        isDefined(action.metadata) &&
        isActionAudienceMetadata(action.metadata)
    );
}

export interface IRefundAction extends IAction {
    type: ActionType.REFUND;
    metadata: IActionRefundMetadata;
    context: IActionContext;
}

export function isRefundAction(action: any): action is IRefundAction {
    return (
        isAction(action) &&
        action.type === ActionType.REFUND &&
        isActionContext(action.context) &&
        isActionRefundMetadata(action.metadata)
    );
}

export interface IOrderCompletedOC1Action extends IAction {
    type: ActionType.ORDER_COMPLETED;
    metadata: IActionOrderCompletedMetadataOC1;
    context: IActionWithLocationContext;
}

export function isOrderCompletedOC1Action(action: any): action is IOrderCompletedOC1Action {
    return (
        isAction(action) &&
        isActionWithLocationContext(action.context) &&
        action.type === ActionType.ORDER_COMPLETED &&
        isDefined(action.metadata) &&
        isActionOrderCompletedMetadataOC1(action.metadata)
    );
}

export interface IOrderCompletedOC2Action extends IAction {
    type: ActionType.ORDER_COMPLETED;
    metadata: IActionOrderCompletedMetadataOC2;
    context: IActionWithLocationContext;
}

export function isOrderCompletedOC2Action(action: any): action is IOrderCompletedOC2Action {
    return (
        isAction(action) &&
        isActionWithLocationContext(action.context) &&
        action.type === ActionType.ORDER_COMPLETED &&
        isDefined(action.metadata) &&
        isActionOrderCompletedMetadataOC2(action.metadata)
    );
}

export interface IActionOrderUserBilledMetadata {
    value: IActionMetadataOrderValue;
    totals: {
        taxAdded: number;
        taxToAdd: number;
        total: number;
        subtotal: number;
        tips: number;
        discounts: number;
        charges: number;
        payments: number;
    };
    order: IOrder;
}

export function isActionOrderUserBilledMetadata(metadata: any): metadata is IActionOrderUserBilledMetadata {
    return (
        isDefined(metadata.order) && isActionMetadataOrderValue(metadata.value) && isDefined(metadata.totals)
    );
}

export interface IOrderUserBilledAction extends IAction {
    type: ActionType.ORDER_USER_BILLED;
    metadata: IActionOrderUserBilledMetadata;
    context: IActionWithLocationContext;
}

export function isOrderUserBilledAction(action: any): action is IOrderUserBilledAction {
    return (
        isAction(action) &&
        isActionWithLocationContext(action.context) &&
        action.type === ActionType.ORDER_USER_BILLED &&
        isDefined(action.metadata) &&
        isActionOrderUserBilledMetadata(action.metadata)
    );
}

export interface ICredentialVerifiedAction extends IAction {
    type: ActionType.CREDENTIAL_VERIFIED;
    metadata: IActionCredentialVerifiedMetadata;
}

export function isCredentialVerifiedAction(action: any): action is ICredentialVerifiedAction {
    return (
        isAction(action) &&
        action.type === ActionType.CREDENTIAL_VERIFIED &&
        isDefined(action.metadata) &&
        isActionCredentialVerifiedMetadata(action.metadata)
    );
}

export interface IRewardEarnedAction extends IAction {
    type: ActionType.REWARD_EARNED;
    metadata: IActionRewardEarnedMetadata;
}

export function isRewardEarnedAction(action: any): action is IRewardEarnedAction {
    return (
        isAction(action) &&
        action.type === ActionType.REWARD_EARNED &&
        isDefined(action.metadata) &&
        isActionRewardEarnedMetadata(action.metadata)
    );
}

export interface ICheckinAction extends IAction {
    type: ActionType.CHECKIN;
    context: IActionWithLocationContext;
}

export function isCheckinAction(action: any): action is ICheckinAction {
    return (
        isAction(action) && action.type === ActionType.CHECKIN && isActionWithLocationContext(action.context)
    );
}

export interface IUserCreditedAction extends IAction {
    type: ActionType.USER_CREDITED;
    metadata: IActionCreditedMetadata;
}

export function isUserCreditedAction(action: any): action is IUserCreditedAction {
    return (
        isAction(action) &&
        action.type === ActionType.USER_CREDITED &&
        isDefined(action.metadata) &&
        isActionCreditedMetadata(action.metadata)
    );
}

export interface IUserCreatedAction extends IAction {
    type: ActionType.USER_CREATED;
    metadata: IActionUserCreatedMetadata;
}

export function isUserCreatedAction(action: any): action is IUserCreatedAction {
    return (
        isAction(action) &&
        action.type === ActionType.USER_CREATED &&
        isDefined(action.metadata) &&
        isActionUserCreatedMetadata(action.metadata)
    );
}

export interface IGiftCardActivatedAction extends IAction {
    type: ActionType.GIFT_CARD_ACTIVATED;
    metadata: IActionGiftCardMetadata;
}

export function isGiftCardActivatedAction(action: any): action is IGiftCardActivatedAction {
    return (
        isAction(action) &&
        action.type === ActionType.GIFT_CARD_ACTIVATED &&
        isDefined(action.metadata) &&
        isActionGiftCardMetadata(action.metadata)
    );
}

export interface IGiftCardTopupAction extends IAction {
    type: ActionType.GIFT_CARD_TOPUP;
    metadata: IActionGiftCardMetadata;
}

export function isGiftCardTopupAction(action: any): action is IGiftCardTopupAction {
    return (
        isAction(action) &&
        action.type === ActionType.GIFT_CARD_TOPUP &&
        isDefined(action.metadata) &&
        isActionGiftCardMetadata(action.metadata)
    );
}

export interface ISummary {
    total: number;
    count: number;
    pages: number;
    page: number;
}

function isSummary(summary: any): summary is ISummary {
    return (
        isDefined(summary) &&
        isNumber(summary.total) &&
        isNumber(summary.count) &&
        isNumber(summary.pages) &&
        isNumber(summary.page)
    );
}

export interface IActionData {
    actions: IAction[];
    summary: ISummary;
}

export function isActionData(data: any): data is IActionData {
    return isDefined(data) && isArrayOf(isAction, data.actions) && isSummary(data.summary);
}

const isValidDate = (d: string) => Date.parse(d);

export interface IOrderPaymentRefundedAction extends IAction {
    type: ActionType.ORDER_PAYMENT_REFUNDED;
    metadata: IActionOrderPaymentRefundedMetadata;
    context: IActionWithLocationContext;
}

export function isOrderPaymentRefundedAction(action: any): action is IOrderPaymentRefundedAction {
    return (
        isAction(action) &&
        action.type === ActionType.ORDER_PAYMENT_REFUNDED &&
        isActionWithLocationContext(action.context) &&
        isActionOrderPaymentRefundedMetadata(action.metadata)
    );
}

export interface IActionOrderPaymentRefundedMetadata {
    value: IActionMetadataOrderValue;
    totals: {
        taxAdded: number;
        taxToAdd: number;
        total: number;
        subtotal: number;
        tips: number;
        discounts: number;
        charges: number;
        payments: number;
    };
    order: IOrder;
}

export function isActionOrderPaymentRefundedMetadata(
    metadata: any
): metadata is IActionOrderPaymentRefundedMetadata {
    return (
        isDefined(metadata.order) && isActionMetadataOrderValue(metadata.value) && isDefined(metadata.totals)
    );
}

interface IActionRedeemPerkMetadata {
    awardId: string;
    perkId: string;
    pointsRedeemed: number;
    pointsAvailable: number;
}

export function isActionRedeemPerkMetadata(metadata: any): metadata is IActionRedeemPerkMetadata {
    return (
        isNumber(metadata.pointsRedeemed) &&
        isNumber(metadata.pointsAvailable) &&
        isString(metadata.awardId) &&
        isString(metadata.perkId)
    );
}

interface IActionRewardBurnedMetadata {
    loyaltyBurned: number;
}

export function isActionRewardBurnedMetadata(metadata: any): metadata is IActionRewardBurnedMetadata {
    return isNumber(metadata.loyaltyBurned);
}

interface IActionRewardEarnedMetadata {
    loyaltyEarned: number;
    perkId: string;
}

import { AutoFormFields } from 'lib/LegacyForm';
import { ContentModule, displayToMarketingHeight, EHeightOption } from '../models/ContentModule';
import { createMarketingItemForm } from './createMarketingItemForm';
import { createModuleBasicForm } from './createModuleBasicForm';
import { ContentModuleAdditionalData } from './createModuleForm';
import { getMarketingModuleForm } from './MarketingModuleItem';

const MARKETING_SHAPE_TO_LABEL: Record<EHeightOption, string> = {
    [EHeightOption.SINGLE_HEIGHT_RECTANGLE]: 'Single Height Rectangle (3:1)',
    [EHeightOption.DOUBLE_HEIGHT_RECTANGLE]: 'Double Height Rectangle (3:2)',
    [EHeightOption.SMALL_SQUARE]: 'Small Square (1:1)',
    [EHeightOption.LARGE_SQUARE]: 'Large Square (1:1)'
};

export const createModuleMarketingForm: (additionalData?: ContentModuleAdditionalData) => AutoFormFields =
    additionalData => ({
        ...createModuleBasicForm,
        display: {
            label: 'Shape',
            type: 'select',
            options: Object.keys(EHeightOption).map((key: EHeightOption) => ({
                label: MARKETING_SHAPE_TO_LABEL[key],
                value: key
            }))
        },
        items: {
            type: 'sortableField',
            fields: [
                { label: 'Action', width: '40%', key: 'action' },
                { label: 'Reporting Name', width: '45%', key: 'name' }
            ],
            itemForm: createMarketingItemForm,
            FormComponent: getMarketingModuleForm(additionalData && additionalData.display),
            label: 'Marketing messages',
            validation: 'required',
            errors: {
                required: 'Thie field is required'
            }
        }
    });

export function transformModuleToMarketingFormFields(module: ContentModule): any {
    return {
        ...module,
        secondaryActionTitle: module.secondaryAction ? module.secondaryAction.title : undefined,
        secondaryActionAction: module.secondaryAction ? module.secondaryAction.action : undefined,
        display: displayToMarketingHeight(module.display)
    };
}

export function transformMarketingFormFieldToModuleFields(
    fieldName: string,
    value: any,
    formData: { [key: string]: any }
): { name: string; value: any }[] {
    switch (fieldName) {
        case 'secondaryActionTitle':
            return [
                { name: 'secondaryAction', value: { action: formData.secondaryActionAction, title: value } }
            ];
        case 'secondaryActionAction':
            if (!value) {
                return [
                    {
                        name: 'secondaryAction',
                        value: undefined
                    }
                ];
            }
            return [
                {
                    name: 'secondaryAction',
                    value: {
                        action: value,
                        title: formData.secondaryActionTitle
                    }
                }
            ];
        case 'display':
            return [
                {
                    name: 'display',
                    value: {
                        height: value
                    }
                }
            ];
        default:
            return [{ value, name: fieldName }];
    }
}

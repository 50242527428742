/* eslint-disable @typescript-eslint/naming-convention */
import { RadarListItem } from './../models';
import { updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { pepperPayApi } from '../../pepperPayApi';

export const BLOCK_LIST_PAGE_LIMIT = 100;

interface IProps {
    activeListId: string;
    selectedLocation: string;
}

export function usePepperBlockList({ activeListId, selectedLocation }: IProps) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [blockListItems, setBlockListItems] = React.useState<RadarListItem[]>([]);
    const reset = React.useCallback(() => {
        setBlockListItems([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);

    React.useEffect(() => {
        const getBlockList = async () => {
            if (endOfData) {
                return;
            }
            setLoading(true);

            try {
                const pageBody: {
                    limit: number;
                    starting_after?: string;
                } = { limit: BLOCK_LIST_PAGE_LIMIT };
                if (pages[page]) {
                    pageBody.starting_after = pages[page];
                }

                const { body, ok } = await pepperPayApi.radarListItems.getRadarListItems(
                    activeListId,
                    selectedLocation,
                    pageBody
                );
                if (!ok) {
                    throw new Error(body.message);
                }
                if (!body.has_more) {
                    setEndOfData(true);
                }
                setBlockListItems(currentBlockListItems => [...currentBlockListItems, ...body.data]);
                const lastItem = body.data[body.data.length - 1]?.id;
                setPages(currentPages => updateArrayItem(currentPages, page + 1, lastItem));
            } catch (e) {
                logger.error(e);
                setBlockListItems([]);
            }
            setLoading(false);
        };

        if (activeListId && selectedLocation) {
            if (
                (page === 0 && blockListItems.length === 0) ||
                (!pages[page + 1] && blockListItems.length < pages.length * BLOCK_LIST_PAGE_LIMIT)
            ) {
                getBlockList();
            }
        }
    }, [activeListId, blockListItems.length, endOfData, page, pages, selectedLocation]);

    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return blockListItems.length;
        }
        return -1;
    }, [blockListItems.length, endOfData]);

    return { data: blockListItems, page, onPageChange, reset, loading, count } as const;
}

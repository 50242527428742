import { GridCellParams, GridEventListener } from '@mui/x-data-grid-pro';
import { isDefined } from 'lib/typeguards';
import React from 'react';

export function useMenuSelection(globalCopyEnabled = true) {
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
    const [selectedCell, setSelectedCell] = React.useState<GridCellParams | undefined>();

    const handleSelectChange = (newSelection: string[]) => {
        setSelectedIds(newSelection);
    };

    const handleFocusOut = React.useCallback<GridEventListener<'cellFocusOut'>>((params, event) => {
        // we need this to prevent unfocusing when picking a file
        if (params.cellMode === 'edit' && event && params.colDef.type === 'image') {
            event.defaultMuiPrevented = true;
        } else {
            setSelectedCell(null);
        }
    }, []);

    const handleCellSelection = React.useCallback((params: GridCellParams) => {
        setSelectedCell(params);
    }, []);

    const resetSelection = React.useCallback(() => {
        setSelectedIds([]);
        setSelectedCell(undefined);
    }, []);

    const copyEnabled = React.useMemo(
        () => (selectedIds.length > 0 || isDefined(selectedCell)) && globalCopyEnabled,
        [globalCopyEnabled, selectedCell, selectedIds.length]
    );

    return {
        selectedIds,
        selectedCell,
        handleSelectChange,
        handleFocusOut,
        resetSelection,
        handleCellSelection,
        copyEnabled
    };
}

import { GridDateFilter } from 'lib/grid/GridDateFilter';
import { Box } from '@mui/material';
import React from 'react';
import { Row } from 'ui/Flex';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import {
    PepperTransactionsFilter,
    PepperTransactionsFilterChange,
    transactionsFilterOptions
} from '../hooks/usePepperTransactionsFilter';
import { TransactionsLastFourFilter } from './TransactionsLastFourFilter';
import { GridLocationFilter } from 'lib/grid/GridLocationFilter';
import { GridFilterInput } from 'lib/grid/GridFilterInput';
import { TransactionExpiryInput } from './TransactionExpiryFilter';
import { isEmptyString, isString } from 'lib/typeguards';
import { TransactionsSelectFilter } from './TransactionsSelectFilter';

interface TransactionsFiltersProps {
    filter: PepperTransactionsFilter;
    onChange: PepperTransactionsFilterChange;
}

export const TransactionsFilters: React.FC<TransactionsFiltersProps> = ({ filter, onChange }) => {
    const handleDateChange = React.useCallback(
        (value?: DateRangePickerValue) => {
            onChange('date', value);
        },
        [onChange]
    );
    const handleTextChange = React.useCallback(
        (value: string, name: keyof PepperTransactionsFilter) => {
            onChange(name, value);
        },
        [onChange]
    );
    const handleLast4Change = React.useCallback(
        (value?: string) => {
            onChange('last4', value);
        },
        [onChange]
    );
    const handleExpiryChange = React.useCallback(
        (value?: string) => {
            onChange('expiryDate', value);
        },
        [onChange]
    );
    const handleLocationChange = React.useCallback(
        (value?: string) => {
            onChange('locationId', value);
        },
        [onChange]
    );
    const isOrderIdPresent = React.useMemo(
        () => isString(filter.orderId) && !isEmptyString(filter.orderId) && Number(filter.orderId) >= 0,
        [filter.orderId]
    );
    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginTop={2} marginRight={1}>
                <GridDateFilter
                    value={filter.date}
                    onChange={handleDateChange}
                    clearable={false}
                    disabled={isOrderIdPresent}
                    maxRangeMonths={3}
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridFilterInput
                    value={filter.paymentIntentId}
                    onChange={handleTextChange}
                    label="Payment Intent ID"
                    name="paymentIntentId"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionsSelectFilter
                    value={filter.state}
                    onChange={onChange}
                    disabled={isOrderIdPresent}
                    options={transactionsFilterOptions.state}
                    unselectedLabel="All transaction states"
                    name="state"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionsSelectFilter
                    value={filter.walletType}
                    onChange={onChange}
                    disabled={isOrderIdPresent}
                    options={transactionsFilterOptions.wallet}
                    unselectedLabel="All wallet types"
                    name="walletType"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionsSelectFilter
                    value={filter.risk}
                    onChange={onChange}
                    disabled={isOrderIdPresent}
                    options={transactionsFilterOptions.risk}
                    unselectedLabel="All risk levels"
                    name="risk"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridFilterInput
                    value={filter.orderId}
                    onChange={handleTextChange}
                    label="Order Number"
                    name="orderId"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionsSelectFilter
                    value={filter.type}
                    onChange={onChange}
                    disabled={isOrderIdPresent}
                    options={transactionsFilterOptions.type}
                    unselectedLabel="All transaction types"
                    name="type"
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionsLastFourFilter
                    value={filter.last4}
                    onChange={handleLast4Change}
                    disabled={isOrderIdPresent}
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridLocationFilter
                    value={filter.locationId}
                    onChange={handleLocationChange}
                    disabled={isOrderIdPresent}
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <GridFilterInput
                    value={filter.customerId}
                    onChange={handleTextChange}
                    label="Customer ID"
                    name="customerId"
                    disabled={isOrderIdPresent}
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionExpiryInput
                    value={filter.expiryDate}
                    onChange={handleExpiryChange}
                    disabled={isOrderIdPresent}
                />
            </Box>
            <Box marginTop={2} marginRight={1}>
                <TransactionsSelectFilter
                    value={filter.method}
                    onChange={onChange}
                    disabled={isOrderIdPresent}
                    options={transactionsFilterOptions.method}
                    unselectedLabel="Any transaction method"
                    name="method"
                />
            </Box>
        </Row>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const PowercardGiftCardSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId
}) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplaySwitch
                    title="Production Environment"
                    checked={settings?.powercardGift?.isProduction}
                />
                <SettingDisplayText title="API Token" text={settings?.powercardGift?.apiToken} />
                <SettingDisplayText title="Merchant ID" text={settings?.powercardGift?.m_id} />
                <SettingDisplayText title="Gift PID" text={settings?.powercardGift?.gift_pid} />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <SettingDisplayText title="Gift Card Location ID" text={settings?.powercardGift?.l_id} />
        )}
    </>
);

import { format } from 'date-fns';
import { Timeslot } from 'components/timeslots/model/Timeslot';

export function getDay(date: Date) {
    return format(date, 'EEEE do MMMM');
}

export function getTimeslotTimes(timeslot: Timeslot, full?: boolean) {
    let timeString: string;
    if (full) {
        timeString = `${getDay(new Date(timeslot.start))} ${format(
            new Date(timeslot.start),
            'h:mm a'
        )} - ${format(new Date(timeslot.end), 'h:mm a')}`;
    } else {
        timeString = `${format(new Date(timeslot.start), 'h:mm a')} - ${format(
            new Date(timeslot.end),
            'h:mm a'
        )}`;
    }
    return timeString.replace(/am/g, 'AM').replace(/pm/g, 'PM').replaceAll(',', '');
}

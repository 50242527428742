import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplay } from 'lib/SettingDisplay';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { OAuthProvider } from '@pepperhq/location-sdk';

export const LightspeedSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId,
    onSettingsSaved
}) => {
    const isAuthorized = React.useMemo(() => !!settings?.lightspeed?.oauth2AccessToken, [settings]);
    const [loadingOAuth, setLoadingOAuth] = React.useState(false);
    const { tenantId } = useSelector((state: ApplicationState) => state.auth.tenant);

    const handleAuthorizeClicked = React.useCallback(async () => {
        setLoadingOAuth(true);
        if (isAuthorized) {
            await new LocationSettingsApi().revokeOAuthAccess(OAuthProvider.LIGHTSPEED, locationId);
            // Trigger parent update of settings
            if (typeof onSettingsSaved === 'function') {
                await onSettingsSaved();
            }
        } else {
            // Redirect to auth
            window.location.href = `https://${
                process.env.TIER === 'beta' ? 'test.' : ''
            }lightspeedapis.com/resto/oauth2/v1/authorize?response_type=code&client_id=${
                process.env.LIGHTSPEED_CLIENT_ID
            }&code_challenge=${
                process.env.LIGHTSPEED_CODE_CHALLENGE
            }&code_challenge_method=S256&state=${encodeURIComponent(
                JSON.stringify({
                    tenantId,
                    locationId
                })
            )}`;
        }
        setLoadingOAuth(false);
    }, [isAuthorized, locationId, tenantId, onSettingsSaved]);

    return (
        <>
            {/* Common Only */}
            {locationId === undefined && (
                <SettingDisplay label="Web Orders Server Host">
                    <Typography variant="body1">{settings?.lightspeed?.host}</Typography>
                </SettingDisplay>
            )}
            {/* Location Only */}
            {locationId !== undefined && (
                <>
                    <SettingDisplay label="Lightspeed Id">
                        <Typography variant="body1">{settings?.lightspeed?.companyId}</Typography>
                    </SettingDisplay>
                    <SettingDisplay label="Payment Type Name">
                        <Typography variant="body1">{settings?.lightspeed?.paymentTypeName}</Typography>
                    </SettingDisplay>
                    <SettingDisplay label="OAuth2">
                        <LoadingButton
                            variant={isAuthorized ? 'outlined' : 'contained'}
                            color="primary"
                            onClick={handleAuthorizeClicked}
                            loading={loadingOAuth}
                        >
                            {isAuthorized ? 'De-Authorize' : 'Authorize'}
                        </LoadingButton>
                    </SettingDisplay>
                </>
            )}
        </>
    );
};

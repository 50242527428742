import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { debounce } from 'debounce';
import { AwardTemplate } from 'components/customers/models/Award';
import { perkApi } from 'components/loyalty/PerkApi';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import logger from 'lib/logger';
import { MultiSelectSuggest } from 'lib/MultiSelectSuggest';
import { Option } from 'lib/types';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';

export type SegmentGrantPerkModalSubmitData = { segmentId: string; perkId: string; points?: number };

interface SegmentGrantPerkModalComponentProps {
    onSubmit: (data: SegmentGrantPerkModalSubmitData) => void;
    onClose: () => void;
    segmentId: string;
}

const SegmentGrantPerkModalComponent: React.FC<SegmentGrantPerkModalComponentProps> = props => {
    const { onSubmit, onClose, segmentId } = props;
    const [perks, setPerks] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [selectedOption, setSelectedOption] = React.useState(undefined);
    const [points, setPoints] = React.useState(undefined);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        async function load() {
            try {
                setLoading(true);
                const perksResult = await perkApi.getList();
                if (!perksResult.ok) {
                    throw new Error(perksResult.body);
                }
                setPerks(perksResult.body.perks);
            } catch (e) {
                setPerks([]);
                setSearch('');
            } finally {
                setLoading(false);
            }
        }
        load();
    }, []);

    const handleSubmit = React.useCallback(() => {
        try {
            setLoading(true);
            onSubmit({ segmentId, perkId: selectedOption.value, points });
        } catch (e) {
            logger.error(e);
        } finally {
            setLoading(false);
            onClose();
        }
    }, [onClose, onSubmit, segmentId, selectedOption, points]);

    const handlePerkChange = React.useCallback((value: Option) => {
        setSelectedOption(value);
        setPoints(undefined);
    }, []);

    const handlePerkInputChange = debounce(
        React.useCallback(
            (newValue: string) => {
                if (newValue !== search) {
                    setSearch(newValue);
                }
            },
            [search]
        ),
        200
    );

    const handlePointsChange = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setPoints(parseInt(value, 10));
        },
        []
    );

    const selectedPerk = selectedOption && perks.find(perk => perk._id === selectedOption.value);

    return (
        <Box width={500}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                        Grant a perk to this audience
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MultiSelectSuggest
                        value={selectedOption}
                        isCreatable={false}
                        isMulti={false}
                        label={<MuiFormLabel required>Perk</MuiFormLabel>}
                        options={perks.map(perk => ({ value: perk._id, label: perk.title }))}
                        onChange={handlePerkChange}
                        onInputChange={handlePerkInputChange}
                        placeholder="Start typing..."
                    />
                </Grid>
                {selectedPerk &&
                    (selectedPerk.template === AwardTemplate.PSEUDO_CURRENCY ||
                        selectedPerk.template === AwardTemplate.STAMP_CARD) && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={points}
                                placeholder="Input Value"
                                type="number"
                                onChange={handlePointsChange}
                                label={<MuiFormLabel required>Points</MuiFormLabel>}
                            />
                        </Grid>
                    )}
                <Grid item xs={12}>
                    <Row gutter flex={1} align="flex-end" valign="center">
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!selectedOption}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Row>
                </Grid>
            </Grid>
        </Box>
    );
};

interface SegmentGrantPerkModalProps {
    open: boolean;
    onSubmit: (data: SegmentGrantPerkModalSubmitData) => void;
    onClose: () => void;
    segmentId: string;
}

export const SegmentGrantPerkModal: React.FC<SegmentGrantPerkModalProps> = ({
    open,
    onSubmit,
    onClose,
    segmentId
}) => (
    <MuiModal overflowVisible open={open} onClose={onClose}>
        <SegmentGrantPerkModalComponent onSubmit={onSubmit} onClose={onClose} segmentId={segmentId} />
    </MuiModal>
);

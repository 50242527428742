import React, { ReactElement } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import HardwareIcon from '@mui/icons-material/Hardware';

export const SettingDisplay: React.FC<{
    label: string;
    description?: string;
    buildtimeIOS?: boolean;
    buildtimeAndroid?: boolean;
    tooltipIcon?: ReactElement;
    tooltipText?: string;
}> = ({ label, description, buildtimeAndroid, buildtimeIOS, children, tooltipText, tooltipIcon }) => {
    const tooltip = React.useMemo(() => {
        if (tooltipText) {
            return tooltipText;
        }
        if (buildtimeIOS && buildtimeAndroid) {
            return 'Build time setting for iOS and Android';
        }
        if (buildtimeAndroid) {
            return 'Build time setting for Android';
        }
        if (buildtimeIOS) {
            return 'Build time setting for iOS';
        }
        return undefined;
    }, [buildtimeAndroid, buildtimeIOS, tooltipText]);
    return (
        <Box display="flex" flexDirection="row" width="100%" marginBottom={2} alignItems="center">
            <Box display="flex" flexDirection="column" flexShrink={0} marginRight={2} width="30%">
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="body2">
                        <b>{label}</b>
                    </Typography>
                    {(!!tooltipText || !!buildtimeAndroid || buildtimeIOS) && (
                        <Tooltip title={tooltip}>{tooltipIcon ?? <HardwareIcon />}</Tooltip>
                    )}
                </Box>
                {!!description && <Typography variant="caption">{description}</Typography>}
            </Box>
            <Box display="flex" flex={1}>
                {children}
            </Box>
        </Box>
    );
};

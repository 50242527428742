import { ApplicationState } from 'store/store';
import { useSelector, useDispatch } from 'react-redux';
import * as React from 'react';
import { getAllLocationTitles } from 'store/locations/locationsActions';
import { Location } from 'components/location/models/LocationModel';

export const useLocationHelpers = () => {
    const [allLocations, setAllLocations] = React.useState<Location[]>([]);
    const dispatch = useDispatch();
    const { locationIdToLocationTitle, locations, isLoading } = useSelector(
        (state: ApplicationState) => state.locations
    );

    React.useEffect(() => {
        if (locationIdToLocationTitle.size === 0) {
            getAllLocationTitles(dispatch).then(locations => {
                if (locations) {
                    setAllLocations(locations);
                }
            });
        }
    }, [dispatch, locationIdToLocationTitle]);

    React.useEffect(() => {
        if (allLocations.length === 0 && locations) {
            setAllLocations(locations);
        }
    }, [allLocations.length, locations]);

    return { locations, allLocations, locationIdToLocationName: locationIdToLocationTitle, isLoading };
};

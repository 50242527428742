// TODO: change it to generic file upload component
import React from 'react';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, styled } from '@mui/material';
import Folder from '@mui/icons-material/Folder';
import { Field, FieldAttributes, FieldProps } from 'formik';
import logger from 'lib/logger';
import { isString } from 'lib/typeguards';
import { isArrayBuffer } from 'lodash';

interface KeyFileInputFormFieldProps {
    label?: React.ReactNode;
    attributes?: Record<string, string>;
    placeholder?: string;
    description?: string;
    isText?: boolean;
}

const PREFIX = 'KeyFileInputFormField';

const classes = {
    fileInput: `${PREFIX}-fileInput`,
    icon: `${PREFIX}-icon`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [`& .${classes.fileInput}`]: {
        paddingRight: theme.spacing(1.2),
        cursor: 'pointer'
    },
    [`& .${classes.icon}`]: {
        cursor: 'pointer'
    }
}));

export const KeyFileInputFormField: React.SFC<FieldAttributes<KeyFileInputFormFieldProps>> = ({
    label,
    placeholder,
    attributes,
    description,
    isText,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <KeyFileInputFormFieldComponent
                label={label}
                attributes={attributes}
                placeholder={placeholder}
                description={description}
                isText={isText}
                {...childProps}
            />
        )}
    </Field>
);

const buildFileSelector = (
    handler: (event?: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
    attributes: Record<string, string> = { type: 'file', accept: '.p8' }
) => {
    const fileSelector = document.createElement('input');
    Object.entries(attributes).forEach(([attribute, value]) => {
        fileSelector.setAttribute(attribute, value);
    });

    // FIXME: remove when we implement dropzone for files
    fileSelector.onchange = handler as any;
    return fileSelector;
};

const readFile = (file: File): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = () => resolve(reader.result);
        reader.onerror = err => reject(err);
    });

const readFileAsBuffer = (file: File): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = err => reject(err);
    });

const KeyFileInputFormFieldComponent: React.FC<FieldProps & KeyFileInputFormFieldProps> = props => {
    const { field, meta, label, placeholder, description, attributes, isText = true } = props;
    const [fileSelector, setFileSelector] = React.useState(null);
    const isError = meta.touched && !!meta.error;
    const openFileSelector = React.useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            fileSelector.click();
        },
        [fileSelector]
    );
    React.useEffect(() => {
        const fileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files?.length) {
                const fileList: FileList = event.target.files;
                const file = fileList.item(0);
                const key = isText ? await readFile(file) : await readFileAsBuffer(file);
                if (isString(key)) {
                    field.onChange({ target: { value: btoa(key), name: field.name } });
                } else if (isArrayBuffer(key)) {
                    let binary = '';
                    const bytes = new Uint8Array(key);
                    const length = bytes.byteLength;
                    for (let i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    field.onChange({
                        target: { value: btoa(binary), name: field.name }
                    });
                } else {
                    logger.error('Failed to read key file');
                }
            }
        };
        setFileSelector(buildFileSelector(fileHandler, attributes));
    }, [attributes, field, isText]);
    return (
        <StyledFormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Input
                readOnly
                fullWidth
                value={field.value || ''}
                placeholder={placeholder}
                inputProps={{ className: classes.fileInput }}
                name={field.name}
                onClick={openFileSelector}
                endAdornment={
                    <InputAdornment position="start">
                        <Folder className={classes.icon} />
                    </InputAdornment>
                }
            />
            {(isError || description) && (
                <FormHelperText error={isError}>{isError ? meta.error : description}</FormHelperText>
            )}
        </StyledFormControl>
    );
};

import React from 'react';
import { IPointOfSaleSettingsProps, itemMappingSchemeLabel } from 'components/settings/PointOfSaleSettings';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const MicrosSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({ settings, locationId }) => (
    <>
        {/* Common Only */}
        {locationId === undefined && (
            <>
                <SettingDisplayText
                    title="Omnivore Micros API Key"
                    text={settings?.omnivore?.apiKey}
                    crop
                    variant="body1"
                />
                <SettingDisplayText
                    title="Webhook Activation Code"
                    text={settings?.omnivore?.webhookActivationCode}
                    variant="body1"
                />
            </>
        )}
        {/* Location Only */}
        {locationId !== undefined && (
            <>
                <SettingDisplayText title="Location ID" text={settings?.omnivore?.locationId} />
                <SettingDisplayText title="Employee ID" text={settings?.omnivore?.employeeId} />
                <SettingDisplayText title="Tender Type ID" text={settings?.omnivore?.tenderType} />
                <SettingDisplayText
                    title="Pre-Order Order Type ID"
                    text={settings?.omnivore?.PREORDER_orderType}
                />
                <SettingDisplayText
                    title="Pre-Order Revenue Center ID"
                    text={settings?.omnivore?.PREORDER_revenueCenter}
                />
                <SettingDisplayText
                    title="Table Order Type ID"
                    text={settings?.omnivore?.ORDER_TO_TABLE_orderType}
                />
                <SettingDisplayText
                    title="Table Order Revenue Center ID"
                    text={settings?.omnivore?.ORDER_TO_TABLE_revenueCenter}
                />
                <SettingDisplayText
                    title="Pay At Table Order Type ID"
                    text={settings?.omnivore?.TABLE_orderType}
                />
                <SettingDisplayText
                    title="Pay At Table Revenue Center ID"
                    text={settings?.omnivore?.TABLE_revenueCenter}
                />
                <SettingDisplayText
                    title="Menu Category Filter"
                    // eslint-disable-next-line max-len
                    description="Comma delimited list of categories to include in the menu. If blank, all are returned."
                    text={settings?.omnivore?.menuCategoryFilter}
                />
                <SettingDisplayText
                    title="Item Level Price"
                    // eslint-disable-next-line max-len
                    description="Will only use price level for item pricing otherwise will fall back to base price, if blank all price levels are returned."
                    text={settings?.omnivore?.onlyUsePriceLevel}
                />
                <SettingDisplayText
                    title="Item Mapping Scheme"
                    text={
                        settings?.omnivore?.itemMappingScheme
                            ? itemMappingSchemeLabel[settings?.omnivore?.itemMappingScheme]
                            : 'None'
                    }
                />
            </>
        )}
    </>
);

import React from 'react';
import { ActionCardBasicInfo } from 'components/actions/cards/ActionCardBasicInfo';
import {
    ActionCardModalComponent,
    ActionCardModalComponentProps
} from 'components/actions/cards/ActionCardModalComponent';
import { IAction } from 'components/actions/models/Action';
import { Perk } from 'components/loyalty/models/PerkModel';
import { MuiModal } from 'ui/MuiModal';

interface CustomersActionModalComponentProps {
    action: IAction;
    perks: Perk[];
}

const CustomersActionModalComponent: React.FC<CustomersActionModalComponentProps> = props => {
    const { action, perks } = props;
    const Component: React.FC<ActionCardModalComponentProps> = ActionCardModalComponent[action.type] || null;
    return Component ? <Component action={action} perks={perks} /> : <ActionCardBasicInfo action={action} />;
};

interface CustomersActionModalProps {
    action: IAction;
    perks: Perk[];
    onClose: () => void;
}

export const CustomersActionModal: React.FC<CustomersActionModalProps> = props => {
    const { action, onClose, perks } = props;
    return (
        <MuiModal open={!!action} onClose={onClose}>
            <CustomersActionModalComponent perks={perks} action={action} />
        </MuiModal>
    );
};

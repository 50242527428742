import React from 'react';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';

export const StripeSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId,
    hasInternalSettingsReadPermission,
    globalSettings
}) => {
    const isConnectEnabled = React.useMemo(
        () => globalSettings?.stripe?.connectEnabled ?? false,
        [globalSettings]
    );

    if (locationId !== undefined) {
        return (
            <>
                {isConnectEnabled && (
                    <>
                        <SettingDisplayText
                            title="Account ID"
                            text={settings?.stripe?.accountId}
                            variant="body2"
                        />
                        <SettingDisplayText
                            title="Dispute Notification Email"
                            text={settings?.stripe?.disputeNotificationEmail}
                            variant="body2"
                        />
                        <SettingDisplayText
                            title="Stripe Location ID (for card reader)"
                            text={settings?.stripe?.locationId}
                            variant="body2"
                        />
                    </>
                )}
                {isConnectEnabled && hasInternalSettingsReadPermission && (
                    <>
                        <SettingDisplayText
                            title="Franchise Account ID"
                            text={settings?.stripe?.franchiseConnectAccountId}
                            variant="body2"
                        />
                        <SettingDisplayText
                            title="Franchise Fee Percentage (Default)"
                            text={settings?.stripe?.franchiseConnectFeePercentage}
                            variant="body2"
                        />
                        <SettingDisplayText
                            title="Franchise Fee Percentage (Quickpad)"
                            text={settings?.stripe?.franchiseConnectFeePercentageCardholderPresent}
                            variant="body2"
                        />
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <SettingDisplaySwitch title="Production Environment" checked={settings?.stripe?.isProduction} />
            <SettingDisplaySwitch title="Connected Account" checked={settings?.stripe?.connectEnabled} />
            {isConnectEnabled && (
                <>
                    <SettingDisplayText
                        title="Account ID"
                        text={settings?.stripe?.accountId}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="Dispute Notification Email"
                        text={settings?.stripe?.disputeNotificationEmail}
                        variant="body2"
                    />
                </>
            )}
            {isConnectEnabled && hasInternalSettingsReadPermission && (
                <>
                    <SettingDisplayText
                        title="Gateway Fee (Default)"
                        text={settings?.stripe?.gatewayFee}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="Gateway Fee (Quickpad)"
                        text={settings?.stripe?.gatewayFeeCardholderPresent}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="Transaction Fee Rate (Default)"
                        text={settings?.stripe?.transactionFeeRate}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="Transaction Fee Rate (Quickpad)"
                        text={settings?.stripe?.transactionFeeRateCardholderPresent}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="3DS2 Fee"
                        text={settings?.stripe?.threeDS2Fee}
                        variant="body2"
                    />

                    <SettingDisplayText
                        title="Franchise Account ID"
                        text={settings?.stripe?.franchiseConnectAccountId}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="Franchise Fee Percentage (Default)"
                        text={settings?.stripe?.franchiseConnectFeePercentage}
                        variant="body2"
                    />
                    <SettingDisplayText
                        title="Franchise Fee Percentage (Quickpad)"
                        text={settings?.stripe?.franchiseConnectFeePercentageCardholderPresent}
                        variant="body2"
                    />
                </>
            )}
            {!isConnectEnabled && (
                <>
                    <SettingDisplayText
                        title="Secret Key"
                        text={settings?.stripe?.secretKey}
                        variant="body2"
                        crop
                    />
                    <SettingDisplayText
                        title="Publishable Key"
                        text={settings?.stripe?.publishableKey}
                        variant="body2"
                        crop
                    />
                </>
            )}
        </>
    );
};

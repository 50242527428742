import { Box, Button } from '@mui/material';
import React from 'react';
import { VouchersFilter, VouchersFilterChange } from './hooks/useVouchersFilter';
import { Row } from 'ui/Flex';
import { GridFilterInput } from 'lib/grid/GridFilterInput';

interface VoucherFiltersProps {
    filter: VouchersFilter;
    onChange: VouchersFilterChange;
    onCreateClick: () => void;
    loading?: boolean;
}

export const VoucherFilters: React.FC<VoucherFiltersProps> = ({
    onCreateClick,
    filter,
    onChange,
    loading
}) => {
    const handleTextChange = React.useCallback(
        (value: string, name: keyof VouchersFilter) => {
            onChange(name, value);
        },
        [onChange]
    );
    return (
        <Row flex={1} align="space-between" valign="center">
            <Box>
                <GridFilterInput
                    value={filter.voucherSchemeId}
                    onChange={handleTextChange}
                    label="Scheme ID"
                    name="voucherSchemeId"
                    disabled={loading}
                />
            </Box>
            <Box>
                <Button variant="contained" color="primary" onClick={onCreateClick} disabled={loading}>
                    Create
                </Button>
            </Box>
        </Row>
    );
};

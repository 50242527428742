import { TableCell, Typography } from '@mui/material';
import { OperationStatusChip } from 'components/overwrites/OperationStatusChip';
import { formatDateTime } from 'lib/helpers';
import * as React from 'react';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems, MuiTableToolbarItems } from 'ui/table/MuiTable';
import { PepperPayOperation } from '../model/PepperPay';
import { reportFormatLabelMap, reportTypeLabelMap } from './model';
import { ReportingDownloadActionCell } from './ReportingDownloadActionCell';
import { getOperationTypeAndFormat } from './utils';

interface ReportingTableProps {
    operations: PepperPayOperation[];
    nextKey?: string;
    isLoading: boolean;
    toolbarItems: MuiTableToolbarItems;
    onScroll: () => void;
}

export const ReportingTable: React.FC<ReportingTableProps> = ({
    operations,
    nextKey,
    isLoading,
    toolbarItems,
    onScroll
}) => {
    const columns = React.useMemo<MuiTableColumnItem[]>(
        () => [
            {
                key: 'createdAt',
                label: 'Date',
                render: (item: PepperPayOperation) => (
                    <TableCell key={`create-date-${item.nonce}`}>{formatDateTime(item.createdAt)}</TableCell>
                )
            },
            {
                key: 'type',
                label: 'Type',
                render: (item: PepperPayOperation) => {
                    const { type } = getOperationTypeAndFormat(item.metadata.report_type);
                    return (
                        <TableCell key={`export-type-${item.nonce}`} align="center" size="small">
                            <Typography variant="caption" align="center">
                                {reportTypeLabelMap[type]}
                            </Typography>
                        </TableCell>
                    );
                },
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'format',
                label: 'Format',
                render: (item: PepperPayOperation) => {
                    const { format } = getOperationTypeAndFormat(item.metadata.report_type);
                    return (
                        <TableCell key={`export-format-${item.nonce}`} align="center" size="small">
                            <Typography variant="caption" align="center">
                                {reportFormatLabelMap[format]}
                            </Typography>
                        </TableCell>
                    );
                },
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'account_id',
                label: 'Conencted Account',
                render: (item: PepperPayOperation) => (
                    <TableCell key={`export-account-${item.nonce}`} align="center" size="small">
                        <Typography variant="caption" align="center">
                            {item.metadata.parameters.connected_account}
                        </Typography>
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'status',
                label: 'State',
                render: (item: PepperPayOperation) => (
                    <TableCell key={`export-status-${item.nonce}`} align="center" size="small">
                        <OperationStatusChip status={item.status} />
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'reportName',
                label: 'Schedule Name',
                render: (item: PepperPayOperation) => (
                    <TableCell
                        key={`export-account-${item.nonce}-${item.metadata?.reportName ?? item.id}`}
                        align="center"
                        size="small"
                    >
                        <Typography variant="caption" align="center">
                            {item.metadata?.reportName || 'Manual Report'}
                        </Typography>
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'action',
                label: '',
                render: (item: PepperPayOperation) => (
                    <ReportingDownloadActionCell key={`export-action-${item.nonce}`} operation={item} />
                )
            }
        ],
        []
    );

    const scroll = React.useMemo<MuiTableScrollItems>(
        () => ({ isMoreItems: !!nextKey, key: nextKey, onScroll }),
        [onScroll, nextKey]
    );
    const getRowKey = React.useCallback((item: PepperPayOperation) => item.nonce, []);
    return (
        <MuiTable
            alwaysShowHead
            data={operations}
            columns={columns}
            getRowKey={getRowKey}
            isLoading={isLoading || !!nextKey}
            toolbarItems={toolbarItems}
            scroll={scroll}
        />
    );
};

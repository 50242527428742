import { IOperationResult } from 'components/operation/models/OperationModel';
import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

class TenantApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.TENANT_SERVICE_URL, 'tenants', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        });
    }
    public getOperation = async (operationId: string): Promise<IOperationResult<string> | undefined> => {
        const headers = {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID
        };
        const result = await this.httpClient.get(`${this.api}/operations/${operationId}`, { headers });
        if (result.ok) {
            return result.body as IOperationResult<string>;
        }
        return undefined;
    };
}

export const tenantApi = new TenantApi(httpClient);

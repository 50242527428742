import * as React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { CardViewTextRowValue } from 'ui/viewComponents';
import { getZonesString } from '../helpers';
import { ILocationZones } from '../models/LocationModel';

interface ILocationTablesAndZonesCardComponentProps {
    zones: ILocationZones;
}

export const LocationTablesAndZonesCardComponent: React.FC<ILocationTablesAndZonesCardComponentProps> = ({
    zones
}) => {
    const zoneKeys = React.useMemo(() => Object.keys(zones ?? {}), [zones]);

    const renderZoneRow = React.useCallback(
        (zoneKey: string) => (
            <TableRow key={zoneKey}>
                <TableCell sx={{ border: 'none', p: 1 }}>{zoneKey}</TableCell>
                <TableCell sx={{ border: 'none', p: 1 }}>{getZonesString(zones[zoneKey])}</TableCell>
            </TableRow>
        ),
        [zones]
    );

    return (
        <Grid container>
            {!!zoneKeys.length ? (
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ border: 'none', p: 1 }}>
                                    <b>Zone</b>
                                </TableCell>
                                <TableCell sx={{ border: 'none', p: 1 }}>
                                    <b>Table Number(s)</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{zoneKeys.map(renderZoneRow)}</TableBody>
                    </Table>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <CardViewTextRowValue value="You have no zones configured" />
                </Grid>
            )}
        </Grid>
    );
};

/* eslint-disable */
import React from 'react';
import { styled } from '@mui/material';
import { Option } from 'lib/types';
import { MuiModal } from 'ui/MuiModal';
import { NotificationForm, NotificationFormData, NotificationTarget } from '../forms/NotificationForm';
import { Notification, NotificationCreate, NotificationType } from '../models/Notification';
import { notificationApi } from '../notificationApi';

function getSelectedOptions(options: Option[], selectedValues: string[]): Option[] {
    return selectedValues.reduce((selectedOptions, value) => {
        const match = options.find(option => option.value === value);
        if (match) {
            selectedOptions.push(match);
        }
        return selectedOptions;
    }, []);
}

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    width: theme.spacing(60),
    overflow: 'visible'
}));

interface CreateNotificationModalProps {
    open: boolean;
    onClose?: () => void;
    onError: (message: string) => void;
    onSuccess: (notification: Notification<string>) => void;
    notification?: Notification<string>;
    segments: Option[];
    locations: Option[];
}

export const CreateNotificationModal: React.FunctionComponent<CreateNotificationModalProps> = props => {
    const { open, onClose } = props;
    return (
        <StyledMuiModal open={open} onClose={onClose}>
            <CreateNotificationModalComponent {...props} />
        </StyledMuiModal>
    );
};

const CreateNotificationModalComponent: React.FC<CreateNotificationModalProps> = props => {
    const { onClose, onError, onSuccess, notification, segments, locations } = props;

    const isLocationNotification = notification && notification.locationIds.length > 0;
    const isSegmentNotification = notification && notification.segmentIds.length > 0;

    const notificationTarget = isLocationNotification
        ? NotificationTarget.LOCATION
        : isSegmentNotification
        ? NotificationTarget.AUDIENCE
        : NotificationTarget.GLOBAL;

    const selectedLocations = isLocationNotification
        ? getSelectedOptions(locations, notification.locationIds)
        : [];
    const selectedSegments = isSegmentNotification
        ? getSelectedOptions(segments, notification.segmentIds)
        : [];
    const message = notification && notification.message;

    const handleSubmit = async ({ message, locations, audiences, target }: NotificationFormData) => {
        const notification: NotificationCreate = {
            message,
            locationIds:
                target === NotificationTarget.LOCATION ? locations.map(location => location.value) : [],
            segmentIds:
                target === NotificationTarget.AUDIENCE ? audiences.map(audiences => audiences.value) : [],
            global: target === NotificationTarget.GLOBAL,
            type: NotificationType.Alert,
            isProcessingMessage: false,
            value: '', // RESERVED API, UNUSED BUT REQUIRED
            userIds: []
        };

        const result = await notificationApi.create({
            body: notification
        });
        if (!result.ok) {
            onError(result.body.message);
        } else {
            onSuccess(result.body);
        }
    };

    return (
        <NotificationForm
            onSubmit={handleSubmit}
            onClose={onClose}
            locations={locations}
            segments={segments}
            initialTarget={notificationTarget}
            initialLocations={selectedLocations}
            initialSegments={selectedSegments}
            initialMessage={message}
        />
    );
};

import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { Box, Step, StepButton, Stepper } from '@mui/material';
import { CreateTagGroupForm } from 'components/location-tags/forms/CreateTagGroupForm';
import { CreateTags, ICreateTagItem } from 'components/location-tags/CreateTags';
import { ITag, ITagGroup } from 'components/location/models/LocationModel';
import { locationApi } from 'components/location/LocationApi';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import {
    MESSAGE_CREATE_TAG_GROUP_ERROR,
    MESSAGE_CREATE_TAG_GROUP_SUCCESS,
    MESSAGE_UPDATE_TAG_GROUP_ERROR,
    MESSAGE_UPDATE_TAG_GROUP_SUCCESS
} from 'config/messages';

interface IProps {
    open: boolean;
    onClose: () => void;
    maxId: number;
    getTagGroups: () => void;
    tagGroup?: ITagGroup;
    onCreate?: () => void;
}

export interface ITagGroupDetails {
    title: string;
    matchAll: boolean;
    sort: string;
}

const steps = ['tagGroup', 'tags'];

export function LocationTagDetailsModal({ open, onClose, maxId, getTagGroups, tagGroup, onCreate }: IProps) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [tagGroupDetails, setTagGroupDetails] = React.useState<ITagGroupDetails>();
    const [isCreateTagGroupLoading, setCreateTagGroupLoading] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTagGroupDetails(
            tagGroup
                ? { title: tagGroup.title, sort: `${tagGroup.sort ?? 1}`, matchAll: tagGroup.matchAll }
                : undefined
        );

        return () => {
            setTagGroupDetails(undefined);
        };
    }, [tagGroup]);

    React.useEffect(() => {
        if (!open) {
            setActiveStep(0);
            setTagGroupDetails(undefined);
        }
    }, [open]);

    const handleStepClick = React.useCallback(
        (index: number) => () => {
            setActiveStep(index);
        },
        []
    );

    const handleCreateTagGroupSubmit = React.useCallback((values: ITagGroupDetails) => {
        setTagGroupDetails(values);
        setActiveStep(1);
    }, []);

    const handleCreateTagsSubmit = React.useCallback(
        (tags: ICreateTagItem[]) => {
            const tagsToSubmit: Record<string, ITag> = {};

            tags.forEach((tag, index) => {
                const tagId = `${index + 1}`;
                tagsToSubmit[tagId] = {
                    id: tagId,
                    title: tag.title,
                    sort: isNaN(Number(tag.sort)) ? 1 : Number(tag.sort)
                };
            });

            const body: ITagGroup = {
                id: tagGroup ? tagGroup.id : `${maxId + 1}`,
                ...tagGroupDetails,
                sort: isNaN(Number(tagGroupDetails.sort)) ? 1 : Number(tagGroupDetails.sort),
                tags: tagsToSubmit
            };

            setCreateTagGroupLoading(true);

            locationApi
                .updateTagGroup(body)
                .then(res => {
                    if (res.ok) {
                        dispatch(
                            enqueueSnackbar(
                                tagGroup
                                    ? MESSAGE_UPDATE_TAG_GROUP_SUCCESS
                                    : MESSAGE_CREATE_TAG_GROUP_SUCCESS,
                                { variant: 'success' }
                            )
                        );
                        setActiveStep(0);
                        setTagGroupDetails(undefined);
                        getTagGroups();
                        if (!tagGroup && onCreate) {
                            onCreate();
                        }
                        onClose();
                    } else {
                        dispatch(
                            enqueueSnackbar(
                                tagGroup ? MESSAGE_UPDATE_TAG_GROUP_ERROR : MESSAGE_CREATE_TAG_GROUP_ERROR,
                                { variant: 'error' }
                            )
                        );
                    }
                })
                .finally(() => {
                    setCreateTagGroupLoading(false);
                });
        },
        [dispatch, getTagGroups, maxId, onClose, onCreate, tagGroup, tagGroupDetails]
    );

    return (
        <MuiModal paperMaxWidth={520} open={open} onClose={onClose} disableBackdropClick>
            <Box overflow="hidden" width={480} px={1}>
                <Box mb={3}>
                    <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                        {steps.map((item, index) => (
                            <Step key={`step-button-${item}`} completed={activeStep > index}>
                                <StepButton
                                    disableRipple
                                    disabled={activeStep < index}
                                    onClick={handleStepClick(index)}
                                    color="inherit"
                                />
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <CreateTagGroupForm
                    onSubmit={handleCreateTagGroupSubmit}
                    open={activeStep === 0}
                    onCancel={onClose}
                    initialTagGroupDetails={tagGroupDetails}
                />

                <CreateTags
                    open={activeStep === 1}
                    tagGroupTitle={tagGroupDetails?.title ?? ''}
                    onClose={onClose}
                    onSubmit={handleCreateTagsSubmit}
                    loading={isCreateTagGroupLoading}
                    initialTags={
                        tagGroup
                            ? Object.values(tagGroup.tags).map(({ title, sort }) => ({
                                  title,
                                  sort: `${sort}`
                              }))
                            : []
                    }
                />
            </Box>
        </MuiModal>
    );
}

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import * as Yup from 'yup';
import { MuiForm } from 'lib/Form';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { titleize } from 'lib/helpers';
import { LoadingButton } from 'ui/buttons/LoadingButton';
import { Row } from 'ui/Flex';
import { AudienceType } from '../models/segmentModel';
import { Option } from 'lib/types';

export enum TimeMeasure {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK'
}

export interface AudienceCreateFormData {
    title: string;
    description?: string;
    type: string;
    measure: TimeMeasure;
    expires?: number;
}

interface AudienceCreateFormProps {
    onSubmit: (data: AudienceCreateFormData) => void;
    isLoading: boolean;
    initialData?: Partial<AudienceCreateFormData>;
}

const initialValues: AudienceCreateFormData = {
    title: '',
    description: '',
    type: AudienceType.PERSISTENT,
    measure: TimeMeasure.DAY,
    expires: 0
};

const getAudienceTypeOptionDescription = (type: string): string | undefined => {
    switch (type) {
        case AudienceType.PERSISTENT:
            return 'A User will remain a member of a permanent audience until they are removed manually.';
        case AudienceType.ROLLING:
            return 'A rolling membership will extend the time at which the user will leave the audience each time they re-qualify.';
        case AudienceType.SCHEDULED:
            return `A scheduled membership will expire at a pre-determined
             time after the user has been joined to the audience; regardless of re-qualifying.`;
    }
};

const options: Option[] = Object.keys(AudienceType).map(item => ({
    value: item,
    label: titleize(item.toLowerCase()),
    description: getAudienceTypeOptionDescription(item)
}));
const measureOptions = [
    { label: 'Week(s)', value: TimeMeasure.WEEK },
    { label: 'Day(s)', value: TimeMeasure.DAY },
    { label: 'Hour(s)', value: TimeMeasure.HOUR }
];

const reg = /^\d+$/;

const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required.'),
    type: Yup.string().required('This field is required.'),
    expires: Yup.mixed().when('type', {
        is: (target: string) => target !== AudienceType.PERSISTENT,
        then: Yup.mixed()
            .required('This field is required.')
            .test('', 'This field must be higher than zero.', value => reg.test(value) && value > 0),
        otherwise: Yup.mixed().notRequired()
    })
});

export const AudienceCreateForm: React.FC<AudienceCreateFormProps> = ({
    onSubmit,
    isLoading,
    initialData = {}
}) => (
    <MuiForm
        onSubmit={onSubmit}
        initialValues={{ ...initialValues, ...initialData }}
        validationSchema={validationSchema}
    >
        {({ submitForm, isSubmitting, values: { type: audienceType } }) => (
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            Create Audience
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField name="title" label={<MuiFormLabel required>Title</MuiFormLabel>} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField name="description" multiline maxRows={4} label="Description" />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <InputLabel id="audience_type_label">*/}
                        {/*    <MuiFormLabel required>Type</MuiFormLabel>*/}
                        {/* </InputLabel>*/}
                        <SelectFormField
                            options={options}
                            name="type"
                            label={<MuiFormLabel required>Type</MuiFormLabel>}
                        />
                    </Grid>
                    {audienceType !== AudienceType.PERSISTENT && (
                        <React.Fragment>
                            <Grid item xs={12} sm={6}>
                                <TextFormField
                                    name="expires"
                                    type="number"
                                    label={<MuiFormLabel required>Expires After</MuiFormLabel>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectFormField options={measureOptions} name="measure" />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={12}>
                        <Row gutter align="flex-end" valign="center">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || isLoading}
                                loading={isSubmitting || isLoading}
                                onClick={submitForm}
                            >
                                Submit
                            </LoadingButton>
                        </Row>
                    </Grid>
                </Grid>
            </Form>
        )}
    </MuiForm>
);

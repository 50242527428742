import { createReducer } from 'redux-create-reducer';
import { updateArrayItem } from 'lib/helpers';
import { TenantAccessUsersAction } from './tenantAccessActions';
import { tenantAccessActionTypes as actionTypes } from './tenantAccessActionTypes';
import { TenantAccess } from 'components/merchant/models/TenantAccess';

export interface TenantAccessState {
    tenantUsersAccess?: TenantAccess[];
    error?: string;
    isLoading: boolean;
    summary?: { count: number; limit: number; nextKey?: string };
}

type TenantAccessActionHandlers = {
    [key in actionTypes]: (state: TenantAccessState, action?: TenantAccessUsersAction) => TenantAccessState;
};

export const initialState: TenantAccessState = {
    tenantUsersAccess: null,
    error: null,
    isLoading: false
};

const actionHandlers: TenantAccessActionHandlers = {
    [actionTypes.START_REQUEST]: state => ({ ...state, error: null, isLoading: true }),
    [actionTypes.END_REQUEST]: state => ({ ...state, error: null, isLoading: false }),
    [actionTypes.GET_TENANT_USERS_ACCESS_SUCCESS]: (state, { tenantUsersAccess, summary }) => ({
        ...state,
        summary,
        tenantUsersAccess
    }),
    [actionTypes.LOAD_TENANT_USERS_ACCESS_SUCCESS]: (state, { tenantUsersAccess, summary }) => ({
        ...state,
        tenantUsersAccess: [...state.tenantUsersAccess, ...tenantUsersAccess],
        summary
    }),
    [actionTypes.GET_TENANT_USERS_ACCESS_ERROR]: (state, { error }) => ({
        ...state,
        error,
        tenantUsersAccess: null
    }),
    [actionTypes.UPDATE_TENANT_USER_ACCESS_SUCCESS]: (state, { tenantAccess, index }) => ({
        ...state,
        tenantUsersAccess: updateArrayItem(state.tenantUsersAccess, index, tenantAccess)
    }),
    [actionTypes.UPDATE_TENANT_USER_ACCESS_ERROR]: (state, { error }) => ({ ...state, error }),
    [actionTypes.ADD_TENANT_USER_ACCESS_SUCCESS]: (state, { tenantAccess }) => ({
        ...state,
        tenantUsersAccess: [...state.tenantUsersAccess, tenantAccess]
    }),
    [actionTypes.ADD_TENANT_USER_ACCESS_ERROR]: (state, { error }) => ({ ...state, error })
};

export default createReducer(initialState, actionHandlers);

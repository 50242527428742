/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import logger from 'lib/logger';
import { giftingApi } from '../../giftingApi';
import { FormattedGiftingLiabilityEntry, GiftingLiabilitySummaryData, GiftingLiabilityEntry } from '../model';
import { GiftingLiabilityFilter } from '../model/giftingLiabilityFilter';
import { getGiftingActivityFilterBody } from './useGiftingLiabilityFilter';
import { isDefined } from 'lib/typeguards';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { CurrencyCode } from '@pepperhq/regions';
import Dinero from 'dinero.js';

export function useGiftingLiabilityData(filter: GiftingLiabilityFilter) {
    const [loading, setLoading] = React.useState(false);
    const [summary, setSummary] = React.useState<GiftingLiabilitySummaryData>();
    const [data, setData] = React.useState<GiftingLiabilityEntry[]>([]);
    const [page, setPage] = React.useState<number>(0);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const [pageLimit, setPageLimit] = React.useState(50);
    const [internalFilter, setInternalFilter] = React.useState<Partial<GiftingLiabilityFilter>>();
    const [error, setError] = React.useState<string>();
    const reset = React.useCallback(() => {
        setData([]);
        setSummary(undefined);
        setLoading(true);
        setPage(0);
    }, []);
    React.useEffect(() => {
        const getGiftingLiabilityData = async () => {
            if (!internalFilter || error) {
                return;
            }
            setLoading(true);
            try {
                if (!internalFilter.date?.startDate && !internalFilter.date?.endDate) {
                    reset();
                    setLoading(false);
                    return;
                }

                const paging: Record<string, string | number> = { limit: pageLimit };
                if (isDefined(page)) {
                    paging.page = page + 1;
                }
                const sorting: Record<string, string> = {};
                if (isDefined(sortModel) && sortModel.length > 0) {
                    sorting.sort_by = sortModel[0].field;
                    sorting.sort_order = sortModel[0].sort;
                }

                const { body, ok } = await giftingApi.liabilityReport.create({
                    body: { ...getGiftingActivityFilterBody(internalFilter), ...paging, ...sorting }
                });
                if (!ok) {
                    throw new Error(body.message);
                }
                setSummary(body.report_summary);
                setData(currentData => [...currentData, ...body.report]);
            } catch (e) {
                setError(e.message);
                logger.error(e);
                setData([]);
            }
            setLoading(false);
        };
        if (!isDefined(summary)) {
            getGiftingLiabilityData();
        }
    }, [internalFilter, data.length, filter, error, reset, summary, pageLimit, page, sortModel]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const formattedData = React.useMemo(() => {
        if (!Array.isArray(data)) {
            return [];
        }
        return data.map<FormattedGiftingLiabilityEntry>(entry => {
            const currency = summary?.currency || CurrencyCode.GBP;
            const liability = (entry.sum_credits || 0) - (entry.sum_debits || 0);
            return {
                ...entry,
                currency,
                liability,
                formatted_liability: Dinero({
                    precision: 2,
                    amount: Math.round(liability * 100),
                    currency
                }).toFormat(),
                formatted_credits: Dinero({
                    precision: 2,
                    amount: Math.round((entry.sum_credits || 0) * 100),
                    currency
                }).toFormat(),
                formatted_debits: Dinero({
                    precision: 2,
                    amount: Math.round((entry.sum_debits || 0) * 100),
                    currency
                }).toFormat()
            };
        });
    }, [data, summary?.currency]);
    const onPageLimitChange = React.useCallback(
        (limit: number) => {
            setPageLimit(limit);
            reset();
        },
        [reset]
    );
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const onSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            setSortModel(sortModel);
            reset();
        },
        [reset]
    );
    const count = React.useMemo(() => data.length ?? 0, [data.length]);
    return {
        data,
        summary,
        formattedData,
        page,
        onPageChange,
        pageLimit,
        onPageLimitChange,
        sortModel,
        onSortModelChange,
        loading,
        count,
        error
    };
}

import React from 'react';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';

export const RevelGiftCardSettingsDisplay: React.FC<IPointOfSaleSettingsProps> = ({
    settings,
    locationId
}) => (
    <>
        {/* Common Only */}
        {locationId === undefined && <SettingDisplayText title="User ID" text={settings?.revel?.userId} />}
    </>
);

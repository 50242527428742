import { CustomerSegment } from 'components/customers/models/Customer';
import { NamespacedOperationsApi } from 'components/operation/operationApi';
import { IncludeOptions } from 'lib/api/include';
import { IPagedCollection, IPageOptions, isPagedCollection } from 'lib/api/paging';
import { Crud, HttpClient, httpClient, HttpClientResponseContent } from 'lib/HttpClient';
import { isDefined } from 'lib/typeguards';
import {
    GrantPerkToSegmentOperation,
    isGrantPerkToSegmentOperation,
    isSegmentOperation,
    SegmentOperation
} from './models/segmentOperationModel';
import { isSegmentUser, SegmentUser } from './models/segmentUserModel';

export class SegmentsApi extends Crud {
    private readonly operationsApi: NamespacedOperationsApi<SegmentOperation>;
    constructor(client: HttpClient) {
        super(client, process.env.API_SERVER_URL, 'segments', { 'x-api-version': '4' });
        this.operationsApi = new NamespacedOperationsApi(client, 'segments', isSegmentOperation);
    }

    // FIXME should be replaced by v3/4 but requires all segments
    public async getAll(): Promise<CustomerSegment[]> {
        const pageReducer = async (currentSegments: CustomerSegment[], startKey?: string) => {
            const response = await this.httpClient.get(
                `${this.api}/segments?limit=100${!!startKey ? `&startKey=${startKey}` : ''}`,
                {
                    headers: { 'x-api-version': '4' }
                }
            );

            if (!response.ok) {
                throw new Error(`${response.statusCode} - Failed to fetch Segments`);
            }

            // Parse body
            const {
                page: { nextKey },
                items
            } = response.body;
            currentSegments.push(...items);
            if (nextKey) {
                await pageReducer(currentSegments, nextKey);
            }
        };

        const segments: CustomerSegment[] = [];
        await pageReducer(segments);
        return segments;
    }

    public async getSegmentUsersPaged(
        segmentId: string,
        include: IncludeOptions<keyof Pick<SegmentUser, 'userFullName'>>,
        paging: IPageOptions
    ): Promise<HttpClientResponseContent<IPagedCollection<SegmentUser>>> {
        const includeParameter = {
            key: 'include',
            value: Object.keys(include).reduce((str, key) => `${str}${str === '' ? ',' : ''}${key}`, '')
        };

        const pagingParameters = [...Object.entries(paging)]
            .filter(([_, value]) => isDefined(value))
            .map(([key, value]) => ({ key, value }));

        const response = await this.httpClient.get(
            `${process.env.API_SERVER_URL}/segments/${segmentId}/users`,
            {
                headers: { 'x-api-version': '3' },
                queryParameters: [...pagingParameters, includeParameter]
            }
        );

        if (!response.ok) {
            throw new Error(response.body?.message || response.body?.error?.message || 'Request failed');
        }

        if (!isPagedCollection(isSegmentUser, response.body)) {
            throw new Error('Failed to parse body');
        }

        return response;
    }

    public async addToSegment(segmentId: string, userIds: string[]) {
        return this.httpClient.post(`${process.env.API_SERVER_URL}/segments/${segmentId}/users`, {
            body: userIds,
            headers: { 'x-api-version': '3' }
        });
    }

    public async removeFromSegment(segmentId: string, userId: string) {
        return this.httpClient.delete(`${process.env.API_SERVER_URL}/segments/${segmentId}/users`, {
            body: [userId],
            headers: { 'x-api-version': '3' }
        });
    }

    public getSegmentOperation(
        segmentId: string,
        operationId: string
    ): Promise<HttpClientResponseContent<SegmentOperation>> {
        return this.operationsApi.getOne(segmentId, operationId);
    }

    public getSegmentOperationsPaged(
        segmentId: string,
        query: Partial<Pick<SegmentOperation, 'status'>>,
        paging: IPageOptions
    ): Promise<HttpClientResponseContent<IPagedCollection<SegmentOperation>>> {
        return this.operationsApi.getAllPaged(segmentId, query, paging);
    }

    public async grantPerkToSegment(
        segmentId: string,
        perkId: string,
        points: number | undefined,
        nonce: string
    ): Promise<HttpClientResponseContent<GrantPerkToSegmentOperation>> {
        const response = await this.httpClient.post(
            `${process.env.API_SERVER_URL}/segments/${segmentId}/awards`,
            {
                headers: { 'x-pepper-req-nonce': nonce },
                body: { perkId, points }
            }
        );
        if (!response.ok) {
            throw new Error(response.body?.message || response.body?.error?.message || 'Request failed');
        }
        if (!isGrantPerkToSegmentOperation(response.body)) {
            throw new Error('Failed to parse body');
        }
        return response;
    }

    public retryOperation(
        segmentId: string,
        operationId: string,
        nonce: string
    ): Promise<HttpClientResponseContent<SegmentOperation>> {
        return this.operationsApi.retry(segmentId, operationId, nonce, isSegmentOperation);
    }
}

export const segmentsApi = new SegmentsApi(httpClient);

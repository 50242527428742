import { MuiNotification, MuiNotificationOptions } from 'ui/MuiNotifier';
import { notificationsActionTypes } from './notificationsActionTypes';

export interface MuiNotificationsAction {
    type: string;
    key?: string;
    notification?: MuiNotification;
}

export const enqueueSnackbar = (message: string, options: MuiNotificationOptions) => ({
    type: notificationsActionTypes.ENQUEUE_SNACKBAR,
    notification: {
        message,
        options,
        key: new Date().getTime() + Math.random()
    }
});

export const removeSnackbar = (key: string) => ({
    key,
    type: notificationsActionTypes.REMOVE_SNACKBAR
});

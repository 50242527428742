import { Box, Tooltip, Typography } from '@mui/material';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import { formatDateTime, titleize } from 'lib/helpers';
import React from 'react';
import { PepperPayTimelineItem } from '../model/PepperPay';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface PepperPayTimelineItemProps {
    item: PepperPayTimelineItem;
}

export const PepperPayTimelineItemComponent: React.FC<PepperPayTimelineItemProps> = ({ item }) => {
    const date = React.useMemo(() => formatDateTime(new Date(item.created)), [item.created]);
    const text = React.useMemo(() => titleize(item.type.replaceAll('_', ' ')), [item.type]);
    return (
        <Box alignItems="center" justifyContent="flex-start" display="flex" width="max-content">
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Typography>{text}</Typography>
                <Typography variant="caption">
                    {date} {item.resourceId}
                </Typography>
            </TimelineContent>
            {item.type === 'PAYMENT_INTENT_SUCCEEDED' && (
                <Tooltip title="Capture of PreAuth">
                    <InfoOutlinedIcon />
                </Tooltip>
            )}
            {item.type === 'PAYMENT_INTENT_CREATED' && (
                <Tooltip title="PreAuth">
                    <InfoOutlinedIcon />
                </Tooltip>
            )}
        </Box>
    );
};

import { Popper, Fade, Paper, Box, Typography, IconButton, Button } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

interface IProps {
    anchorEl: HTMLElement;
    onClose: () => void;
    open: boolean;
}

export function LocationTagsApplicationPopover({ anchorEl, onClose, open }: IProps) {
    const { push } = useHistory();

    const handleViewLocationsClick = React.useCallback(() => {
        push('/locations');
    }, [push]);

    return (
        <Popper
            sx={{ zIndex: 1, marginTop: '-56px !important' }}
            placement="top-end"
            open={open}
            transition
            anchorEl={anchorEl}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Paper>
                        <Box maxWidth={400} padding={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" fontWeight="600">
                                    Apply tag to a location?
                                </Typography>
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="body2">
                                Please go to the locations tab and select &apos;View Locations&apos; for your
                                desired location and scroll to the Tags section.
                            </Typography>
                            <Box marginTop={1.5}>
                                <Button
                                    onClick={handleViewLocationsClick}
                                    variant="contained"
                                    color="primary"
                                >
                                    View Locations
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
}

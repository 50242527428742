import React from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, styled, Typography } from '@mui/material';
import { IPublicAndPrivateSettings } from '@pepperhq/location-sdk';
import { locationApi } from 'components/location/LocationApi';
import { LocationSettingsApi } from 'components/location/LocationSettingsApi';
import { Location } from 'components/location/models/LocationModel';
import logger from 'lib/logger';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';

export interface ILocationScopedSettingsComponentProps {
    settings: IPublicAndPrivateSettings;
    onSettingsSaved: () => Promise<void>;
    locationId?: string;
    globalSettings: IPublicAndPrivateSettings;
}

interface ILocationScopedSettingsProps {
    globalComponent: React.FC<ILocationScopedSettingsComponentProps>;
    scopedComponent?: React.FC<ILocationScopedSettingsComponentProps>;
}

async function getLocations(): Promise<Location[]> {
    const queryParameters = [{ key: 'state', value: 'ACTIVE,PENDING' }];
    const result = await locationApi.getList({ queryParameters });
    if (!result.ok) {
        return [];
    }
    return result.body.locations;
}

const PREFIX = 'LocationScopedSettings';

const classes = {
    globalWrapper: `${PREFIX}-globalWrapper`,
    locationWrapper: `${PREFIX}-locationWrapper`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.globalWrapper}`]: {
        borderRight: `1px solid ${theme.palette.action.disabled}`,
        paddingRight: theme.spacing(4)
    },
    [`& .${classes.locationWrapper}`]: {
        paddingLeft: theme.spacing(4)
    }
}));

export const LocationScopedSettings: React.FC<ILocationScopedSettingsProps> = props => {
    const { globalComponent, scopedComponent } = props;
    const [selectedLocation, setSelectedLocation] = React.useState<string | null>('');
    const [locations, setLocations] = React.useState<Location[] | null>(null);
    const [globalSettings, setGlobalSettings] = React.useState<IPublicAndPrivateSettings>({});
    const [locationSettings, setLocationSettings] = React.useState<IPublicAndPrivateSettings>({});
    const [isLoadingLocationSettings, setLoadingLocationSettings] = React.useState(false);

    const loadGlobalSettings = React.useCallback(async () => {
        const settings = await new LocationSettingsApi().getPublicAndPrivateSettings(null);
        setGlobalSettings(settings);
    }, []);

    const loadLocationSettings = React.useCallback(async (locationId: string) => {
        setLoadingLocationSettings(true);
        const settings = await new LocationSettingsApi().getPublicAndPrivateSettings(null, locationId, true);
        setLocationSettings(settings);
        setLoadingLocationSettings(false);
    }, []);

    // Load global settings, locations, and location settings
    React.useEffect(() => {
        async function load() {
            try {
                await loadGlobalSettings();
                const newLocations = await getLocations();
                setLocations(newLocations);
                const firstLocationId = newLocations[0]?._id ?? null;
                setSelectedLocation(firstLocationId);
                if (firstLocationId !== null) {
                    loadLocationSettings(firstLocationId);
                }
            } catch (e) {
                logger.error('Failed to load locations', e);
            }
        }
        load();
    }, [loadGlobalSettings, loadLocationSettings]);

    // Initialise components to be used
    const GlobalSettings = globalComponent;
    const ScopedSettings = scopedComponent;

    const handleSettingsSaved = React.useCallback(async () => {
        await loadGlobalSettings();
        if (selectedLocation !== null) {
            await loadLocationSettings(selectedLocation);
        }
    }, [loadGlobalSettings, loadLocationSettings, selectedLocation]);

    const handleLocationSelectChange = React.useCallback(
        (event: SelectChangeEvent) => {
            setSelectedLocation(event.target.value ?? '');
            loadLocationSettings(event.target.value);
        },
        [loadLocationSettings]
    );

    const renderLocationSelectOption = React.useCallback(
        ({ _id, title }: Location) => (
            <MenuItem key={`select-location-${_id}`} value={_id}>
                {title}
            </MenuItem>
        ),
        []
    );

    return (
        <StyledBox display="flex" flexDirection="row" flex={1} justifyContent="space-between">
            <Box display="flex" flexDirection="column" flex={1} className={classes.globalWrapper}>
                <Box marginBottom={2}>
                    <Typography variant="body1">{'Global Settings'}</Typography>
                </Box>
                <GlobalSettings
                    settings={globalSettings}
                    globalSettings={globalSettings}
                    onSettingsSaved={handleSettingsSaved}
                />
            </Box>
            <Box display="flex" flexDirection="column" flex={1} className={classes.locationWrapper}>
                {scopedComponent && (
                    <>
                        <Box
                            marginBottom={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="body1">{'Location Settings'}</Typography>
                            <Box minWidth={200} display="flex" flex={1} paddingLeft={2}>
                                <Select
                                    fullWidth
                                    value={selectedLocation}
                                    onChange={handleLocationSelectChange}
                                >
                                    {(locations ?? []).map(renderLocationSelectOption)}
                                </Select>
                            </Box>
                        </Box>
                        {(locations === null || isLoadingLocationSettings) && (
                            <SkeletonComponent variant="rectangular" height={360} />
                        )}
                        {locations !== null && !isLoadingLocationSettings && locations.length !== 0 && (
                            <ScopedSettings
                                settings={locationSettings}
                                onSettingsSaved={handleSettingsSaved}
                                locationId={selectedLocation}
                                globalSettings={globalSettings}
                            />
                        )}
                    </>
                )}
            </Box>
        </StyledBox>
    );
};

import { Box } from '@mui/material';
import React from 'react';
import { Row } from 'ui/Flex';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';

import { GridDateFilter } from 'lib/grid/GridDateFilter';
import { GridLocationFilter } from 'lib/grid/GridLocationFilter';
import { PepperDisputesFilter, PepperDisputesFilterChange } from './hooks/usePepperDisputesFilter';

interface DisputesFiltersProps {
    filter: PepperDisputesFilter;
    onChange: PepperDisputesFilterChange;
}

export const DisputesFilters: React.FC<DisputesFiltersProps> = ({ filter, onChange }) => {
    const handleDateChange = React.useCallback(
        (value?: DateRangePickerValue) => {
            onChange('date', value);
        },
        [onChange]
    );
    const handleLocationChange = React.useCallback(
        (value?: string) => {
            onChange('locationId', value);
        },
        [onChange]
    );
    return (
        <Row flex={1} wrap="wrap" valign="center">
            <Box marginTop={1} marginRight={1}>
                <GridDateFilter
                    value={filter.date}
                    onChange={handleDateChange}
                    clearable={false}
                    maxRangeMonths={3}
                />
            </Box>
            <Box marginTop={1} marginRight={1}>
                <GridLocationFilter value={filter.locationId} onChange={handleLocationChange} />
            </Box>
        </Row>
    );
};

import { isArrayOf, isDefined, isNumber, isOptional, isString } from 'lib/typeguards';

export enum EDynamicImageryType {
    BOX = 'BOX',
    LAYER = 'LAYER'
}

export interface LayerContainer {
    suitableFor: string[];
    count: number;
}

export interface BoxContainer {
    suitableFor: string[];
    count: number;
    x: number;
    y: number;
    width: number;
    height: number;
    division?: string;
}

export function isBoxContainer(obj: any): obj is BoxContainer {
    return (
        isDefined(obj) &&
        isArrayOf(isString, obj.suitableFor) &&
        isNumber(obj.count) &&
        isOptional(isNumber, obj.x) &&
        isOptional(isNumber, obj.y) &&
        isOptional(isNumber, obj.width) &&
        isOptional(isNumber, obj.height)
    );
}

export function isLayerContainer(obj: any): obj is LayerContainer {
    return isDefined(obj) && isArrayOf(isString, obj.suitableFor) && isNumber(obj.count);
}

export const DYNAMIC_IMAGE_ASPECT_RATIO = 0.5625;

import { MuiModal } from 'ui/MuiModal';
import { Box, Button, Grid, Typography } from '@mui/material';
import { TextFormField } from 'lib/form/fields/TextFormField';
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { NumberFormField } from 'lib/form/fields/NumberFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import { ICreateTagItem } from 'components/location-tags/CreateTags';

interface IProps {
    open: boolean;
    onClose: () => void;
    onCreate: (values: ICreateTagItem) => void;
}

enum EFormField {
    TITLE = 'title',
    SORT = 'sort'
}

const INITIAL_VALUES: ICreateTagItem = {
    title: '',
    sort: '1'
};

const REQUIRED_ERROR_MESSAGE = 'This field is required.';

const validationSchema = Yup.object().shape({
    title: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    sort: Yup.number().required(REQUIRED_ERROR_MESSAGE)
});

export function CreateTagItemModal({ open, onClose, onCreate }: IProps) {
    return (
        <MuiModal hideBackdrop paperMaxWidth={480} open={open} onClose={onClose}>
            <Box minWidth={360}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={INITIAL_VALUES}
                    onSubmit={onCreate}
                >
                    {({ handleSubmit: onSubmit, isValid, values }) => (
                        <form onSubmit={onSubmit}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} mb={1.5}>
                                    <Typography sx={{ mb: 1 }} variant="h6" color="primary">
                                        Create a Tag
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFormField
                                        name={EFormField.TITLE}
                                        label={<MuiFormLabel required>Title</MuiFormLabel>}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberFormField
                                        InputLabelProps={{ shrink: true }}
                                        name={EFormField.SORT}
                                        label={<MuiFormLabel required>Sort</MuiFormLabel>}
                                    />
                                </Grid>

                                <Grid item xs={12} mt={2}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button variant="outlined" color="primary" onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={!isValid || !values.title}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            sx={{ ml: 1 }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Box>
        </MuiModal>
    );
}

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LocationTablesAndZonesCardEditComponent } from 'components/location/cards/LocationTablesAndZonesCardEditComponent';
import { locationApi } from 'components/location/LocationApi';
import { ILocationZones, Location } from 'components/location/models/LocationModel';
import { MESSAGE_TABLE_ZONE_UPDATE_ERROR, MESSAGE_TABLE_ZONE_UPDATE_SUCCESS } from 'config/messages';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { Panel } from 'ui/Panel';
import { LocationTablesAndZonesCardComponent } from './LocationTablesAndZonesCardComponent';

export interface ILocationTablesAndZonesCardProps {
    currentLocation: Location;
    setCurrentLocation: (location: Location) => void;
}

export const LocationTablesAndZonesCard: React.FC<ILocationTablesAndZonesCardProps> = ({
    currentLocation,
    setCurrentLocation
}) => {
    const [isEditing, setEditingStatus] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const toggleState = React.useCallback(() => {
        setEditingStatus(currentStatus => !currentStatus);
    }, [setEditingStatus]);

    const handleSubmit = React.useCallback(
        async (zones: ILocationZones) => {
            const locationId = currentLocation._id;

            setLoading(true);

            const response = await locationApi.update(locationId, {
                body: {
                    zones
                }
            });

            if (response.ok) {
                setEditingStatus(false);
                setCurrentLocation(response.body);
                dispatch(enqueueSnackbar(MESSAGE_TABLE_ZONE_UPDATE_SUCCESS, { variant: 'success' }));
            } else {
                dispatch(
                    enqueueSnackbar(
                        response.body?.httpCode === 422
                            ? response.body.message
                            : MESSAGE_TABLE_ZONE_UPDATE_ERROR,
                        {
                            variant: 'error'
                        }
                    )
                );
            }

            setLoading(false);
        },
        [currentLocation._id, dispatch, setCurrentLocation]
    );

    return (
        <Panel title="Tables & Zones" showAction={!isEditing} divider onClick={toggleState}>
            {isEditing ? (
                <LocationTablesAndZonesCardEditComponent
                    zones={currentLocation.zones}
                    onClose={toggleState}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                />
            ) : (
                <LocationTablesAndZonesCardComponent zones={currentLocation.zones} />
            )}
        </Panel>
    );
};

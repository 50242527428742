import React from 'react';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { styled } from '@mui/material';

const PREFIX = 'MuiTableRow';

const classes = {
    clickEnabled: `${PREFIX}-clickEnabled`
};

const StyledTableRow = styled(TableRow)({
    [`&.${classes.clickEnabled}`]: {
        cursor: 'pointer'
    }
});

interface MuiTableRowProps {
    onClick?: (id: string, index: number) => void;
    id?: string;
    hover: boolean;
    selected: boolean;
    selectable: boolean;
    index: number;
    children: React.ReactNodeArray;
    pointer?: boolean;
}

export const MuiTableRow = (props: MuiTableRowProps) => {
    const { children, hover, selectable, selected, onClick, pointer, ...rest } = props;

    const handleClick = React.useCallback(() => {
        onClick(props.id, props.index);
    }, [onClick, props.id, props.index]);
    const rowProps = React.useMemo<TableRowProps>(() => {
        const result: TableRowProps = {
            hover
        };
        if (onClick) {
            result.onClick = handleClick;
        }
        if (pointer) {
            result.className = classes.clickEnabled;
        }
        if (selectable) {
            result.selected = selected;
        }
        return result;
    }, [hover, onClick, handleClick, selectable, selected, pointer]);
    return (
        <StyledTableRow {...rest} {...rowProps}>
            {children}
        </StyledTableRow>
    );
};

import { isArrayOf, isBoolean, isNumber, isOptionalString, isString } from 'lib/typeguards';

export enum NotificationType {
    Alert = 'alert',
    OrderFailed = 'order_failed',
    OrderCompleted = 'order_completed',
    Topup = 'topup',
    Survey = 'survey',
    Credit = 'credit',
    Referral = 'referral',
    Unknown = 'unknown'
}

export interface NotificationResult<T> {
    body: Notification<T>;
    ok: boolean;
}

// Should this be Generic?
// Aren't keys always returned as hex strings?
export interface Notification<T> {
    completedAt?: string;
    createdAt: string;
    failures: 0 | 1;
    global: boolean;
    isProcessingMessage: boolean;
    locationIds: string[];
    message: string;
    segmentIds: string[];
    sent: 0 | 1;
    skipped: 0 | 1;
    status: 'PENDING' | 'DONE' | 'ERROR';
    tenantId: string;
    type: NotificationType;
    userIds: string[];
    value: string;
    errorMessage?: string;
    _id: T;
}

export interface NotificationCreate {
    message: string;
    type: NotificationType;
    value: string;
    isProcessingMessage: boolean;
    userIds: string[];
    locationIds: string[];
    segmentIds: string[];
    global: boolean;
}

interface Page {
    nextKey?: string;
    startKey: string;
}

interface NotificationData {
    items: Notification<string>[];
    page: Page;
}

export function isNotification(input: any): input is Notification<string> {
    return (
        isString(input._id) &&
        isOptionalString(input.completedAt) &&
        isString(input.createdAt) &&
        isNumber(input.failures) &&
        isBoolean(input.global) &&
        isBoolean(input.isProcessingMessage) &&
        isArrayOf(isString, input.locationIds) &&
        isString(input.message) &&
        isArrayOf(isString, input.segmentIds) &&
        isNumber(input.sent) &&
        isNumber(input.skipped) &&
        (input.status === 'PENDING' || input.status === 'DONE' || input.status === 'ERROR') &&
        isString(input.tenantId) &&
        isString(input.type) &&
        isArrayOf(isString, input.userIds) &&
        isString(input.value) &&
        (!input.errorMessage || isString(input.errorMessage))
    );
}

export function isNotificationData(input: any): input is NotificationData {
    return (
        isArrayOf(isNotification, input.items) &&
        !!input.page &&
        isOptionalString(input.page.nextKey) &&
        isOptionalString(input.page.startKey)
    );
}

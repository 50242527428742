import React from 'react';
import { CircularProgress, styled, Typography } from '@mui/material';
import { Column } from './Flex';
import clsx from 'clsx';

const PREFIX = 'Throbber';

const classes = {
    papper: `${PREFIX}-papper`,
    removeMargin: `${PREFIX}-removeMargin`,
    spinner: `${PREFIX}-spinner`,
    rowWithGutter: `${PREFIX}-rowWithGutter`,
    rowCell: `${PREFIX}-rowCell`,
    columnCell: `${PREFIX}-columnCell`
};

const StyledColumn = styled(Column)(({ theme }) => ({
    [`& .${classes.papper}`]: {
        padding: theme.spacing(3),
        marginTop: '30vh'
    },
    [`& .${classes.removeMargin}`]: {
        marginTop: '0vh'
    },
    [`& .${classes.spinner}`]: {
        marginBottom: theme.spacing(5)
    }
}));

interface ThrobberProps {
    text?: string;
    removeMargin?: boolean;
}

export const Throbber = (props: ThrobberProps) => (
    <StyledColumn flex={1} align="center" valign="center">
        <Column
            align="center"
            className={clsx(classes.papper, { [classes.removeMargin]: props.removeMargin })}
        >
            <CircularProgress size={100} className={classes.spinner} />
            <Typography variant="h5">{props.text || "We're just loading your content..."}</Typography>
        </Column>
    </StyledColumn>
);

interface ErrorDetails {
    title: string;
    description?: string;
}

export const getErrorDetails = (code: number): ErrorDetails => {
    switch (code) {
        case 401:
            return {
                title: 'Error 401 - Unauthorized',
                description:
                    "Sorry you don't have permission to view this page. Please contact your administrator."
            };
        case 404:
            return {
                title: 'Error 404 - Not Found',
                description: 'Page not found.'
            };
        case 500:
            return {
                title: 'Error 500 - Internal Server Error',
                description: 'An error occured processing your request.'
            };
        default:
            return {
                title: code ? `Error ${code}` : 'Unexpected error',
                description: 'Unknown error occured.'
            };
    }
};

import React from 'react';
import { Box, Grid } from '@mui/material';
import { Location } from 'components/location/models/LocationModel';
import { LegacyForm } from 'lib/LegacyForm';
import { AutoForm } from 'ui/form/AutoForm';

interface LocationGeneralEditCardComponentProps {
    form: LegacyForm;
    onClose: () => void;
    location: Location;
}

export const LocationGeneralEditCardComponent = (props: LocationGeneralEditCardComponentProps) => (
    <AutoForm {...props}>
        {({ title, state, VATnumber, phone, email, description, timezone }) => (
            <React.Fragment>
                <Grid item sm={6} xs={6}>
                    {title}
                </Grid>
                <Grid item sm={6} xs={6}>
                    {state}
                </Grid>
                <Grid item sm={6} xs={12}>
                    {VATnumber}
                </Grid>
                <Grid item sm={6} xs={12}>
                    {phone}
                </Grid>
                <Grid item sm={6} xs={12}>
                    {timezone}
                </Grid>
                <Grid item sm={6} xs={12}>
                    {/* Just because the field in the left is searchable
                      select it has 1px more height than text field */}
                    <Box marginTop="1px">{email}</Box>
                </Grid>
                <Grid item xs={12}>
                    {description}
                </Grid>
            </React.Fragment>
        )}
    </AutoForm>
);

import { Crud, HttpClient, httpClient } from 'lib/HttpClient';

export interface IPOSLocation {
    name: string;
    externalLocationId: string;
}

class MenuPosApi extends Crud {
    constructor(client: HttpClient) {
        super(client, process.env.MENU_SERVICE_URL, 'locations', {
            'x-auth-id': process.env.CONSOLE_FIREBASE_PROJECT_ID,
            'x-api-version': 1
        });
    }
}

export const menuPosApi = new MenuPosApi(httpClient);

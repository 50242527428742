import * as React from 'react';
import { Box, Button, styled } from '@mui/material';
import { useToggle } from 'lib/hooks/useToggle';
import { MenuLocationsListModal } from './MenuLocationsList';

interface ILocationOption {
    id: string;
    title: string;
}

interface IProps {
    locationOptions: ILocationOption[];
    menuName: string;
}

const StyledP = styled('p')({
    whiteSpace: 'nowrap',
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});

export const MenuLocations: React.FC<IProps> = ({ locationOptions, menuName }) => {
    const [isOpen, setIsOpen] = useToggle();

    return (
        <Box marginBottom={1} marginTop={1}>
            <Button onClick={setIsOpen} size="small">
                <StyledP>
                    Menu active at{' '}
                    {locationOptions.length === 1 && menuName !== locationOptions[0].title
                        ? locationOptions[0].title || locationOptions[0].id
                        : `${locationOptions.length} location${locationOptions.length !== 1 ? 's' : ''}`}
                </StyledP>
            </Button>
            <MenuLocationsListModal open={isOpen} onClose={setIsOpen} locationOptions={locationOptions} />
        </Box>
    );
};

import * as React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Row } from 'ui/Flex';
import { Search } from '@mui/icons-material';

interface IProps {
    onChange: (value?: string) => void;
    value?: string;
    placeholder?: string;
}

export const MagicSearch: React.FC<IProps> = ({ value = '', placeholder = 'Search', onChange }) => {
    const handleInputChange = React.useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    return (
        <Row>
            <TextField
                sx={{ minWidth: 360 }}
                variant="outlined"
                value={value}
                onChange={handleInputChange}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
        </Row>
    );
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Link } from '@mui/material';
import { format, set } from 'date-fns';
import {
    MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_ERROR,
    MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_SUCCESS
} from 'config/messages';
import { titleize } from 'lib/helpers';
import { isDefined } from 'lib/typeguards';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { CardViewSwitchRow, CardViewTextRow, CardViewTitle } from 'ui/viewComponents';
import { customerApi } from '../customerApi';
import { getPrimaryPlatform } from '../helpers';
import { Customer, CustomerCredentialProvider, CustomerState } from '../models/Customer';
import { MuiForm } from 'lib/Form';
import { TextFormField } from 'lib/form/fields/TextFormField';
import { Form } from 'formik';
import { Row } from 'ui/Flex';
import { LoadingButton } from '@mui/lab';
import { SelectFormField } from 'lib/form/fields/SelectFormField';
import { MuiFormLabel } from 'lib/form/MuiFormLabel';
import * as Yup from 'yup';
import { DatePickerFormField } from 'lib/form/fields/DatePickerFormField';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface GeneralEditForm {
    firstName: string;
    lastName: string;
    state: CustomerState;
    email: string;
    phone: string;
    birthdate: Date;
}

const stateOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Pending', value: 'PENDING' }
];

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required.'),
    lastName: Yup.string().required('This field is required.'),
    state: Yup.string().required('This field is required.'),
    email: Yup.string().email('This must be a valid email.'),
    phone: Yup.string().test(
        'phone-validation',
        'This must be a valid international phone number.',
        value => {
            if (!!value && !parsePhoneNumberFromString(value)?.isValid()) {
                return false;
            }
            return true;
        }
    )
});

interface CustomersGeneralEditProps {
    onClose: () => void;
    onChange: (data: Partial<Customer>) => void;
    customer: Customer;
    uniqueDeviceVerification: boolean;
    toggleDeviceUniquenessModal: () => void;
}

function isInfoUpdated(body: Partial<Customer>, customer: Customer) {
    return (
        body.firstName !== customer.firstName ||
        body.lastName !== customer.lastName ||
        customer.fullName !== body.fullName ||
        customer.state !== body.state ||
        customer.credentials !== body.credentials ||
        customer.birthdate !== body.birthdate
    );
}

export const CustomersGeneralEdit: React.FC<CustomersGeneralEditProps> = props => {
    const { onClose, customer, onChange, uniqueDeviceVerification, toggleDeviceUniquenessModal } = props;
    const [loading, setLoading] = React.useState(false);
    const emailCredential = React.useMemo(
        () =>
            customer.credentials.find(
                cred => cred.provider === CustomerCredentialProvider.EMAIL && cred.isPrimary
            ),
        [customer.credentials]
    );
    const phoneCredential = React.useMemo(
        () =>
            customer.credentials.find(
                cred => cred.provider === CustomerCredentialProvider.MOBILE && cred.isPrimary
            ),
        [customer.credentials]
    );
    const dispatch = useDispatch();
    const initialData = React.useMemo(() => {
        const baseDetails = {
            firstName: customer.firstName,
            lastName: customer.lastName,
            state: customer.state,
            email: isDefined(emailCredential) ? emailCredential.id : '',
            phone: isDefined(phoneCredential) ? phoneCredential.id : ''
        };
        if (!customer.birthdate) {
            return baseDetails;
        }
        const [year, month, day] = customer.birthdate.split('-');
        return {
            ...baseDetails,
            birthdate: set(new Date(), { year: Number(year), month: Number(month) - 1, date: Number(day) })
        };
    }, [
        customer.birthdate,
        customer.firstName,
        customer.lastName,
        customer.state,
        emailCredential,
        phoneCredential
    ]);
    const handleSubmit = React.useCallback(
        async (data: GeneralEditForm) => {
            setLoading(true);
            const { email, phone, birthdate, ...dataRest } = data;
            const body: Partial<Customer> = {
                ...dataRest,
                birthdate: birthdate ? format(birthdate, 'yyyy-MM-dd') : null,
                fullName: `${data.firstName} ${data.lastName}`
            };
            // Handle Credentials
            if (email !== '' || phone !== '') {
                if (!isDefined(body.credentials)) {
                    body.credentials = [];
                }
                if (email !== (isDefined(emailCredential) ? emailCredential.id : undefined) && email !== '') {
                    body.credentials.push({
                        id: email,
                        provider: CustomerCredentialProvider.EMAIL
                    });
                }
                if (phone !== (isDefined(phoneCredential) ? phoneCredential.id : undefined) && phone !== '') {
                    body.credentials.push({
                        id: phone,
                        provider: CustomerCredentialProvider.MOBILE
                    });
                }
            }
            if (!isInfoUpdated(body, customer)) {
                return onClose();
            }
            const result = await customerApi.update(customer._id, { body });
            setLoading(false);
            if (!result.ok) {
                return dispatch(
                    enqueueSnackbar(MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_ERROR, { variant: 'error' })
                );
            }
            dispatch(enqueueSnackbar(MESSAGE_CUSTOMER_GENERAL_INFO_UPDATE_SUCCESS, { variant: 'success' }));
            onChange({ ...result.body, birthdate: result.body.birthdate });
            onClose();
        },
        [customer, dispatch, emailCredential, onChange, onClose, phoneCredential]
    );
    return (
        <Grid item xs={12}>
            <MuiForm onSubmit={handleSubmit} initialValues={initialData} validationSchema={validationSchema}>
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextFormField
                                    name="firstName"
                                    label={<MuiFormLabel required>First Name</MuiFormLabel>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextFormField
                                    name="lastName"
                                    label={<MuiFormLabel required>Last Name</MuiFormLabel>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectFormField
                                    name="state"
                                    options={stateOptions}
                                    label={<MuiFormLabel required>State</MuiFormLabel>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CardViewTitle title="These are the Customer's login credentials, if they exist" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField name="email" label="Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField name="phone" label="Phone Number" />
                            </Grid>
                            <CardViewTextRow
                                title={'Role' + (customer.roles?.length > 1 ? 's' : '')}
                                value={customer.roles?.map(role => titleize(role)).join(', ')}
                            />
                            <CardViewTextRow
                                title="Primary platform"
                                value={getPrimaryPlatform(customer.primaryPlatform)}
                            />
                            <CardViewTextRow
                                title="Created"
                                value={format(new Date(customer.created), 'dd/MM/yyyy')}
                            />
                            <CardViewSwitchRow
                                title="Push Notifications Enabled?"
                                value={customer.isRegisteredForPushNotifications}
                            />
                            <CardViewSwitchRow
                                title="Agreed to Receive Marketing?"
                                value={customer.hasAgreedToReceiveMarketing}
                            />
                            <Grid item xs={12}>
                                <DatePickerFormField name="birthdate" label="Date of Birth" />
                            </Grid>
                            <Grid item xs={12}>
                                {uniqueDeviceVerification &&
                                    (customer.resetUniqueDeviceVerification ? (
                                        <p>
                                            The device registration reset process has been started for this
                                            user
                                        </p>
                                    ) : (
                                        <p>
                                            {customer.firstName} has a device registered to their user, click
                                            <Link onClick={toggleDeviceUniquenessModal} underline="hover">
                                                {' '}
                                                here{' '}
                                            </Link>
                                            to release it
                                        </p>
                                    ))}
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Row align="flex-end" gutter>
                                    <Button variant="outlined" onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        onClick={submitForm}
                                        loading={isSubmitting || loading}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </LoadingButton>
                                </Row>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </MuiForm>
        </Grid>
    );
};

import React from 'react';
import { IconButton, ListItem, ListItemText, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Container } from '@pepperhq/menu-sdk';

const PREFIX = 'DynamicImageryContainerEditItem';

const classes = {
    button: `${PREFIX}-button`,
    errorButton: `${PREFIX}-errorButton`,
    box: `${PREFIX}-box`
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
    [`& .${classes.button}`]: {
        margin: theme.spacing(-1, 0)
    },

    [`& .${classes.errorButton}`]: {
        color: theme.palette.error.light,
        margin: theme.spacing(-1, 0)
    },

    [`& .${classes.box}`]: {
        height: theme.spacing(2),
        width: theme.spacing(8),
        marginRight: theme.spacing(1)
    }
}));

interface IProps {
    container: Container;
    index: number;
    onRemove: (index: number) => void;
    onEdit: (index: number) => void;
    color?: string;
}

export const DynamicImageryContainerEditItem: React.FC<IProps> = ({
    container,
    index,
    onRemove,
    onEdit,
    color
}) => {
    const handleEdit = React.useCallback(() => {
        onEdit(index);
    }, [index, onEdit]);
    const handleRemove = React.useCallback(() => {
        onRemove(index);
    }, [index, onRemove]);
    return (
        <StyledListItem disableGutters dense>
            <ListItemText secondary={`Container ${index + 1} (${container.x}:${container.y})`} />
            <div className={classes.box} style={{ backgroundColor: color }} />
            <IconButton onClick={handleEdit} className={classes.button} size="large">
                <EditIcon />
            </IconButton>
            <IconButton onClick={handleRemove} className={classes.errorButton} size="large">
                <DeleteIcon />
            </IconButton>
        </StyledListItem>
    );
};

import React from 'react';
import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Option } from 'lib/types';

interface CheckboxPickerItemProps {
    item: Option;
    defaultItems?: string[];
    handleToggle: (value: string) => () => void;
    selected: string[];
    disabled?: boolean;
}

export function CheckboxPickerItem({
    item,
    defaultItems,
    disabled,
    handleToggle,
    selected
}: CheckboxPickerItemProps) {
    const checked = React.useMemo(() => {
        if (!Array.isArray(selected)) {
            return false;
        }
        return selected.includes(item.value);
    }, [item.value, selected]);
    return (
        <ListItem
            role={undefined}
            button
            disabled={defaultItems?.includes(item.value) || disabled}
            onClick={handleToggle(item.value)}
        >
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={checked}
                    tabIndex={-1}
                    color="primary"
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.value}` }}
                />
            </ListItemIcon>
            <ListItemText
                id={`checkbox-list-label-${item.value}`}
                primary={item.label}
                secondary={item.description}
                secondaryTypographyProps={{ variant: 'caption' }}
            />
        </ListItem>
    );
}

import React from 'react';
import { MuiModal } from 'ui/MuiModal';
import { StandalonePaymentRefund } from './StandalonePaymentRefund';
import { CardBrand } from '../model/PepperPay';

interface StandalonePaymentRefundDialogProps {
    open: boolean;
    preauthAmount: number;
    amount: number;
    refundedAmount: number;
    paymentMethodBrand: CardBrand;
    last4: string;
    onClose: () => void;
    onRefund: (amount?: number, reason?: string, note?: string) => void;
    loading: boolean;
    currency?: string;
}

export const StandalonePaymentRefundDialog: React.FC<StandalonePaymentRefundDialogProps> = ({
    open,
    amount,
    onClose,
    ...rest
}) => (
    <MuiModal open={!!amount && open} onClose={onClose} noMaxWidth>
        <StandalonePaymentRefund amount={amount} onClose={onClose} {...rest} />
    </MuiModal>
);

import React from 'react';
import { SettingDisplayText } from 'components/settings/SettingDisplayText';
import { IPointOfSaleSettingsProps } from 'components/settings/PointOfSaleSettings';
import {
    deliverectServiceLabel,
    DeliverectServiceType,
    isDeliverectMenuServiceType
} from './DeliverectSettingsEdit';
import { SettingDisplaySwitch } from 'components/settings/SettingDisplaySwitch';
import { isDefined } from 'lib/typeguards';

export const DeliverectSettingsDisplay = ({ settings, locationId }: IPointOfSaleSettingsProps) => (
    <>
        {locationId !== undefined && (
            <SettingDisplayText title="Channel Link ID" text={settings?.deliverect?.channelLinkId} />
        )}
        <SettingDisplayText
            title="Order To Table Menu Type"
            text={mapServiceTypeToDeliverectMenuTypeLabel(settings.deliverect?.ORDER_TO_TABLE_menuType)}
        />
        <SettingDisplayText
            title="Pre-Order Menu Type"
            text={mapServiceTypeToDeliverectMenuTypeLabel(settings.deliverect?.PREORDER_menuType)}
        />
        <SettingDisplaySwitch
            checked={!!settings?.deliverect?.mapTableNameToTableId}
            title="Map Table Name To Table Id"
        />
    </>
);

function mapServiceTypeToDeliverectMenuTypeLabel(serviceType?: number): string {
    if (!isDefined(serviceType)) {
        return '';
    }
    if (!isDeliverectMenuServiceType(serviceType)) {
        return deliverectServiceLabel[DeliverectServiceType.PICKUP];
    }
    return deliverectServiceLabel[serviceType];
}

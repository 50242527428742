import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, styled, Tabs, Tab } from '@mui/material';
import { MainLayout } from 'layouts/MainLayout';
import { ChangeAccessLevelForm, IChangeAccessValues } from 'components/merchant/forms/ChangeAccessLevelForm';
import { IInviteUserFormValues, InviteUserForm } from 'components/merchant/forms/InviteUserForm';
import { Role } from 'components/merchant/models/Role';
import { TenantAccess } from 'components/merchant/models/TenantAccess';
import { tenantAccessApi } from 'components/merchant/tenantAccessApi';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { ApplicationState } from 'store/store';
import * as routes from 'config/routes';
import {
    addUsersAccess,
    getTenantUsersAccess,
    updateUsersAccess
} from 'store/tenant-access/tenantAccessActions';
import { Column } from 'ui/Flex';
import { MuiModal } from 'ui/MuiModal';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { ApiTokensPage } from 'components/merchant/api-tokens';
import { UserAccessTable } from 'components/merchant/users-access.tsx';
import { useMerchantAppIds } from 'components/merchant/api-tokens/useAppIds';
import { getAllLocations } from 'store/locations/locationsActions';

const PREFIX = 'AccessManagement';

const classes = {
    root: `${PREFIX}-root`,
    tabs: `${PREFIX}-tabs`,
    label: `${PREFIX}-label`
};

const StyledColumn = styled(Column)(({ theme }) => ({
    [`&.${classes.root}`]: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius
    },
    [`& .${classes.tabs}`]: {
        zIndex: 1,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[2],
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
    },
    [`& .${classes.label}`]: {
        color: theme.palette.common.white
    }
}));

const StyledMuiModal = styled(MuiModal)(({ theme }) => ({
    minWidth: theme.spacing(60)
}));

const getRoles = async (): Promise<{ roles: Role[]; error?: string }> => {
    try {
        const response = await tenantAccessApi.getRolesList();
        if (response.ok) {
            return { roles: response.body.items };
        }
        throw new Error(response.body.message);
    } catch (error) {
        return { error, roles: [] };
    }
};

const buttons = [
    {
        key: 'users',
        label: 'Users'
    },
    {
        key: 'api',
        label: 'API Tokens'
    }
];

export const AccessManagementPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { tenantUsersAccess } = useSelector((state: ApplicationState) => state.tenantAccess);
    const { locations: currentLocations } = useSelector((state: ApplicationState) => state.locations);

    const history = useHistory();
    const { tabId } = useParams<{ tabId?: string }>();

    const { appIdDetails, handleAddAppId } = useMerchantAppIds();

    const [isLoading, setLoading] = React.useState(true);
    const [inviteUserOpen, setInviteUserOpen] = React.useState(false);
    const [isResend, setIsResend] = React.useState(false);
    const [isModalLoading, setModalLoading] = React.useState(false);
    const [currentTenantAccessId, setCurrentTenantAccessId] = React.useState<string | null>(null);
    const [currentRoles, setRoles] = React.useState<Role[] | null>(null);

    const handleChange = React.useCallback(
        (_: never, newValue: string) => {
            history.push({
                pathname: routes.MERCHANT_CONFIG.replace(':tabId', newValue)
            });
        },
        [history]
    );

    React.useEffect(() => {
        (async function () {
            await getTenantUsersAccess()(dispatch);
            const { roles, error: rolesError } = await getRoles();
            if (!rolesError) {
                setRoles(roles);
            }
            await getAllLocations()(dispatch);
            setLoading(false);
        })();
    }, [dispatch]);

    const handleToggleInviteOpen = React.useCallback(() => {
        setInviteUserOpen(!inviteUserOpen);
        if (!!inviteUserOpen) {
            setIsResend(false);
            setCurrentTenantAccessId(null);
        }
    }, [inviteUserOpen]);
    const handleTenantAccessEditClose = React.useCallback(() => {
        setCurrentTenantAccessId(null);
    }, []);
    const handleToggleResendInvite = React.useCallback(
        (tenantAccess: TenantAccess) => {
            handleToggleInviteOpen();
            setCurrentTenantAccessId(tenantAccess.id);
            setIsResend(true);
            setModalLoading(false);
        },
        [handleToggleInviteOpen, setIsResend, setModalLoading]
    );
    const handleChangeAccessLevelClick = React.useCallback(
        (tenantAccessId: string) => () => {
            setCurrentTenantAccessId(tenantAccessId);
        },
        [setCurrentTenantAccessId]
    );

    const handleInviteSubmit = React.useCallback(
        async (values: IInviteUserFormValues) => {
            const result = await tenantAccessApi.invite({
                roleId: values.roleId,
                email: values.email,
                locationIds: values.locationIds.map(({ value }) => value)
            });
            if (result.ok) {
                const updatedUserIndex = tenantUsersAccess.findIndex(
                    item => item.email === result.body.email
                );
                if (updatedUserIndex >= 0) {
                    updateUsersAccess(updatedUserIndex, result.body)(dispatch);
                } else {
                    addUsersAccess(result.body)(dispatch);
                }

                setInviteUserOpen(false);
                dispatch(enqueueSnackbar('Your invite has been successfully sent', { variant: 'success' }));
            } else {
                dispatch(enqueueSnackbar(result.body.message, { variant: 'error' }));
            }
            setModalLoading(false);
        },
        [setModalLoading, tenantUsersAccess, setInviteUserOpen, dispatch]
    );

    const handleChangeAccessSubmit = React.useCallback(
        async (values: IChangeAccessValues) => {
            setModalLoading(true);
            const result = await tenantAccessApi.updateUserAccess(currentTenantAccessId, {
                roleId: values.roleId,
                locationIds: values.locationIds ? values.locationIds.map(({ value }) => value) : []
            });
            if (!result.ok) {
                setModalLoading(false);
                dispatch(
                    enqueueSnackbar(`Error updating user access level: ${result.body.message}`, {
                        variant: 'error'
                    })
                );
                return;
            }
            const index = tenantUsersAccess.findIndex(
                tenantAccess => tenantAccess.id === currentTenantAccessId
            );
            updateUsersAccess(index, result.body)(dispatch);
            setModalLoading(false);
            setCurrentTenantAccessId(null);
            dispatch(
                enqueueSnackbar(`Access level was successfully updated for ${result.body.email}`, {
                    variant: 'success'
                })
            );
        },
        [currentTenantAccessId, dispatch, tenantUsersAccess]
    );

    const currentTenantAccess = React.useMemo(
        () =>
            tenantUsersAccess &&
            tenantUsersAccess.find(tenantAccess => tenantAccess.id === currentTenantAccessId),
        [tenantUsersAccess, currentTenantAccessId]
    );

    const changeAccessInitialValues = React.useMemo(() => {
        if (!currentTenantAccess) {
            return undefined;
        }
        return {
            roleId: currentTenantAccess.roleId,
            locationIds: currentTenantAccess.locationIds
                ? currentTenantAccess.locationIds.map((locationId: string) => ({
                      value: locationId,
                      label: currentLocations.find(location => location._id === locationId)?.title
                  }))
                : []
        };
    }, [currentTenantAccess, currentLocations]);
    const resendInitialValues = React.useMemo(() => {
        if (!isResend || !currentTenantAccess) {
            return undefined;
        }
        return {
            email: currentTenantAccess.email,
            ...changeAccessInitialValues
        };
    }, [isResend, currentTenantAccess, changeAccessInitialValues]);

    return (
        <React.Fragment>
            <MainLayout
                pageName="Access management"
                pageDescription="Merchant users and API credentials for interaction with Pepper"
            >
                <Paper>
                    <StyledColumn flex={1} className={classes.root}>
                        <Tabs
                            className={classes.tabs}
                            value={tabId}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="scrollable"
                        >
                            {buttons.map(({ label, key }, index) => (
                                <Tab
                                    key={`label-${index}`}
                                    value={key}
                                    label={<span className={classes.label}>{label}</span>}
                                />
                            ))}
                        </Tabs>
                        <Paper>
                            <Switch>
                                <Route path={routes.MERCHANT_CONFIG_USER}>
                                    <UserAccessTable
                                        isLoading={isLoading}
                                        currentRoles={currentRoles}
                                        currentLocations={currentLocations}
                                        onChangeAccessLevelClick={handleChangeAccessLevelClick}
                                        onToggleInviteOpen={handleToggleInviteOpen}
                                        onToggleResendInvite={handleToggleResendInvite}
                                    />
                                </Route>
                                <Route path={routes.MERCHANT_CONFIG_API}>
                                    <ApiTokensPage appIdDetails={appIdDetails} onAppIdAdd={handleAddAppId} />
                                </Route>
                            </Switch>
                        </Paper>
                    </StyledColumn>
                </Paper>
            </MainLayout>
            <StyledMuiModal
                open={inviteUserOpen}
                onClose={handleToggleInviteOpen}
                title={isResend ? 'Resend User Invite' : 'Invite User'}
                overflowVisible
            >
                <InviteUserForm
                    onClose={handleToggleInviteOpen}
                    isLoading={isModalLoading}
                    onSubmit={handleInviteSubmit}
                    roles={currentRoles}
                    locations={currentLocations}
                    isResend={isResend}
                    initialValues={resendInitialValues}
                />
            </StyledMuiModal>
            <StyledMuiModal
                open={!!currentTenantAccessId && !isResend}
                onClose={handleTenantAccessEditClose}
                title="Change Access Level"
                overflowVisible
            >
                <ChangeAccessLevelForm
                    onClose={handleTenantAccessEditClose}
                    isLoading={isModalLoading}
                    onSubmit={handleChangeAccessSubmit}
                    roles={currentRoles}
                    locations={currentLocations}
                    initialValues={changeAccessInitialValues}
                />
            </StyledMuiModal>
        </React.Fragment>
    );
};

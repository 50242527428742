import React from 'react';
import { FieldAttributes } from 'formik';
import { FormFieldDropZone } from '../../../lib/form/fields/FormFieldDropZone';
import { TagFormValues } from './TagsForm';
import { styled } from '@mui/material';

interface TagsImagePikerProps {
    setFieldValue: (name: string, item: any) => void;
    values: TagFormValues[];
    index: number;
}

const DROP_ZONE_CLASS = 'TagsImage-dropZone';

const StyledDiv = styled('div')({
    width: '100%',
    height: '100%',
    [`& .${DROP_ZONE_CLASS}`]: {
        width: 80,
        height: 80
    }
});

export const TagsImagePiker: React.FC<FieldAttributes<TagsImagePikerProps>> = ({ ...props }) => {
    const { setFieldValue, name, values, index } = props;

    const [imageFile, setImageFile] = React.useState<string>(null);

    const selectImage = React.useCallback(
        (files: File[]) => {
            setFieldValue(name, files);
            setImageFile(null);

            const reader = new FileReader();
            reader.onload = () => {
                const binaryStr = reader.result;
                setImageFile(binaryStr.toString());
            };
            files.forEach(file => reader.readAsDataURL(file));
        },
        [name, setFieldValue]
    );

    return (
        <StyledDiv>
            <FormFieldDropZone
                disableCrop
                onSelect={selectImage}
                className={DROP_ZONE_CLASS}
                imageUrl={imageFile ? imageFile : (values[index]?.imageUrl as string)}
            />
        </StyledDiv>
    );
};

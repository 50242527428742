import { PrimaryActionType } from './models/ContentModule';

export const PRIMARY_ACTION_ITEMS: { label: string; value: PrimaryActionType }[] = [
    {
        value: 'CHECKIN',
        label: 'Check-In'
    },
    {
        value: 'PREORDER',
        label: 'Preorder'
    },
    {
        value: 'TABLE_ORDER',
        label: 'Order to Table'
    },
    {
        value: 'PAY_AT_TABLE',
        label: 'Pay at Table'
    },
    {
        value: 'TABS',
        label: 'Tab'
    },
    {
        value: 'VOUCHER',
        label: 'Voucher'
    },
    {
        value: 'REFERRALS',
        label: 'Referrals'
    },
    {
        value: 'BARCODE',
        label: 'Barcode'
    },
    {
        value: 'USER_DETAILS',
        label: 'User Details'
    },
    {
        value: 'USER_PREFERENCES',
        label: 'User Preferences'
    },
    {
        value: 'BOOK_TABLE',
        label: 'Book a Table'
    },
    {
        value: 'BOOK_ROOM',
        label: 'Book a Room'
    },
    {
        value: 'BROWSER',
        label: 'Browser'
    }
];

export const PRESET_ACTION_ITEM = [
    {
        value: 'BROWSER',
        label: 'Browser',
        description: 'Add a link from your app to your webpage'
    },
    {
        value: 'NATIVE',
        label: 'Native',
        description: 'Create a fully customisable marketing item for your app'
    },
    {
        value: 'LOCATION',
        label: 'Location',
        description: 'Add a link that takes a user to the selected location details'
    },
    {
        value: 'START_ORDER',
        label: 'Start Order',
        description: 'Add a link which deep links a user to a menu or product'
    },
    {
        value: 'PREORDER',
        label: 'Preorder',
        description: 'Add a link which allows users to preorder at a location'
    },
    {
        value: 'TABLE_ORDER',
        label: 'Table Order',
        description: 'Add a link which allows users to order to their table'
    },
    {
        value: 'PAY_AT_TABLE',
        label: 'Pay at Table',
        description: 'Add a link which allows users to fetch their bill and pay'
    },
    {
        value: 'VOUCHER',
        label: 'Vouchers',
        description: 'Add a link which allows users to enter a voucher code'
    },
    {
        value: 'REFERRALS',
        label: 'Referrals',
        description: 'Add a link that allows users to share a code with a friend'
    },
    {
        value: 'USER_DETAILS',
        label: 'User Details',
        description: 'Add a link that directs the user to their details within the app'
    },
    {
        value: 'USER_PREFERENCES',
        label: 'User Preferences',
        description: 'Add a link that directs the user to their marketing and tipping preferences'
    },
    {
        value: 'CHECKIN',
        label: 'Checkin',
        description:
            'Add a button which allows users to checkin to their closest location to see location specific content'
    },
    {
        value: 'BARCODE',
        label: 'Barcode',
        description: 'Display a barcode or QR code for offline checkin'
    }
];

import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Box, Paper, styled, Typography } from '@mui/material';
import { Column } from 'ui/Flex';
import { SkeletonComponent } from 'ui/skeleton/SkeletonComponent';
import { IAction } from './models/Action';
import { getActionDay } from './utils/formatDate';
import { ActionTimelineElement } from './ActionTimelineElement';

export interface ActionTimelineProps {
    actions: IAction[];
    loading?: boolean;
    className?: string;
    onItemClick: (action: IAction) => void;
    hasMoreItems: boolean;
    handleScroll: () => void;
}

const PREFIX = 'ActionTimeline';

const classes = {
    timeline: `${PREFIX}-timeline`,
    dateCard: `${PREFIX}-dateCard`,
    skeletonItem: `${PREFIX}-skeletonItem`
};

const StyledBox = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.25),
    [`& .${classes.dateCard}`]: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.background.paper,
        padding: theme.spacing(1)
    },
    [`& .${classes.skeletonItem}`]: {
        margin: theme.spacing(1.5, 0),
        borderRadius: theme.shape.borderRadius
    }
}));

export const ActionTimeline = (props: ActionTimelineProps) => {
    const { actions, handleScroll, hasMoreItems, onItemClick, loading } = props;
    const [actionsByDate, setActionsByDate] = React.useState<Record<string, IAction[]>>({});
    React.useEffect(() => {
        const actionsList: Record<string, IAction[]> = {};
        actions.forEach(item => {
            const date = getActionDay(item);
            if (!date) {
                return;
            }
            if (!actionsList[date]) {
                actionsList[date] = [];
            }
            actionsList[date] = [...actionsList[date], item];
        });
        setActionsByDate(actionsList);
    }, [actions]);
    function renderDateBlock([date, localActions]: [string, IAction[]]) {
        return (
            <div key={`date-${date}`}>
                <Paper className={classes.dateCard} elevation={2}>
                    <Typography color="inherit">{date}</Typography>
                </Paper>
                {localActions.map((action: IAction) => (
                    <ActionTimelineElement key={action._id} action={action} onClick={onItemClick} />
                ))}
            </div>
        );
    }
    return (
        <StyledBox>
            {Object.entries(actionsByDate).map(renderDateBlock)}
            {(hasMoreItems || loading) && (
                <Column flex={1} align="center" valign="center">
                    {!loading && (
                        <Waypoint
                            onEnter={handleScroll}
                            scrollableAncestor={document.getElementById('timelineListWrapper')}
                        />
                    )}
                    <SkeletonComponent
                        className={classes.skeletonItem}
                        count={6}
                        variant="rectangular"
                        height={36}
                    />
                </Column>
            )}
        </StyledBox>
    );
};

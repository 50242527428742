import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, styled } from '@mui/material';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { ButtonGroupInput } from 'lib/ButtonGroupInput';
import { Option } from 'lib/types';

interface ButtonGroupInputFormFieldProps {
    label?: React.ReactNode;
    options: Option[];
    description?: React.ReactNode;
    disabled?: boolean;
    multi?: boolean;
}

const StyledInputLabel = styled(InputLabel)(() => ({
    '&[data-shrink="true"]': {
        transform: 'translate(0, -4px) scale(0.8)'
    },
    transform: 'translate(0, 16px)'
}));

export const ButtonGroupInputFormField: React.SFC<FieldAttributes<ButtonGroupInputFormFieldProps>> = ({
    label,
    placeholder: _placeholder,
    description,
    disabled,
    options,
    multi,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <ButtonGroupInputFormFieldComponent
                label={label}
                description={description}
                disabled={disabled}
                options={options}
                multi={multi}
                {...childProps}
            />
        )}
    </Field>
);

const ButtonGroupInputFormFieldComponent: React.SFC<FieldProps & ButtonGroupInputFormFieldProps> = ({
    description,
    multi,
    field,
    meta,
    disabled,
    label,
    options
}) => {
    const isError = meta.touched && !!meta.error;
    return (
        <FormControl fullWidth error={meta.touched && !!meta.error} disabled={disabled}>
            {label && <StyledInputLabel shrink>{label}</StyledInputLabel>}
            <Box paddingTop={label ? 3 : 1}>
                <ButtonGroupInput
                    disabled={disabled}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    multi={multi}
                />
            </Box>
            {(isError || description) && (
                <FormHelperText error={isError}>{isError ? meta.error : description}</FormHelperText>
            )}
        </FormControl>
    );
};

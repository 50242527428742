import { updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { FormattedPepperTransaction, PepperTransaction, PepperTransactionType } from '../../model/PepperPay';
import { pepperPayApi } from '../../pepperPayApi';
import { getTransactionsFilterBody, PepperTransactionsFilter } from './usePepperTransactionsFilter';
import Dinero from 'dinero.js';
import { isCurrencyCode } from '@pepperhq/regions';
import { isDefined } from 'lib/typeguards';
import { EGAEventName, useGAHelpers } from 'lib/hooks/useGAHelper';

export const TRANSACTIONS_PAGE_LIMIT = 100;

export function usePepperTransactions(filter: PepperTransactionsFilter) {
    const [loading, setLoading] = React.useState(true);
    const [endOfData, setEndOfData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState([undefined]);
    const [transactions, setTransactions] = React.useState<PepperTransaction[]>([]);
    const [internalFilter, setInternalFilter] = React.useState<PepperTransactionsFilter>();
    const { logUserEvent } = useGAHelpers();
    const reset = React.useCallback(() => {
        setTransactions([]);
        setPages([undefined]);
        setPage(0);
        setLoading(true);
        setEndOfData(false);
    }, []);
    React.useEffect(() => {
        const getTransactions = async () => {
            if (!internalFilter || endOfData) {
                return;
            }
            setLoading(true);
            try {
                if (!internalFilter.paymentIntentId) {
                    const filterBody = getTransactionsFilterBody(internalFilter);
                    const pageBody: Record<string, string | number> = { limit: TRANSACTIONS_PAGE_LIMIT };
                    if (pages[page]) {
                        pageBody.startKey = pages[page];
                    }
                    logUserEvent(EGAEventName.TransactionsFilterBulk, { fields: Object.keys(filterBody) });
                    const { body, ok } = await pepperPayApi.transactionsSearch.create({
                        body: { page: pageBody, ...filterBody }
                    });
                    if (!ok) {
                        throw new Error(body.message);
                    }
                    if (!body.page.nextKey) {
                        setEndOfData(true);
                    }
                    setTransactions(currentTransactions => [...currentTransactions, ...body.items]);
                    setPages(currentPages => updateArrayItem(currentPages, page + 1, body.page.nextKey));
                } else {
                    const { body, ok } = await pepperPayApi.transactions.get(internalFilter.paymentIntentId);
                    if (!ok) {
                        throw new Error(body.message);
                    }
                    setEndOfData(true);
                    setTransactions([body]);
                }
            } catch (e) {
                logger.error(e);
                setTransactions([]);
            }
            setLoading(false);
        };
        if (
            (page === 0 && transactions.length === 0) ||
            (!pages[page + 1] && transactions.length < pages.length * TRANSACTIONS_PAGE_LIMIT)
        ) {
            getTransactions();
        }
    }, [endOfData, internalFilter, logUserEvent, page, pages, transactions.length]);
    React.useEffect(() => {
        setInternalFilter(filter);
        reset();
    }, [filter, reset]);
    const formattedTransactions = React.useMemo(() => {
        if (!Array.isArray(transactions)) {
            return [];
        }
        return transactions
            .slice(page * TRANSACTIONS_PAGE_LIMIT, page * TRANSACTIONS_PAGE_LIMIT + TRANSACTIONS_PAGE_LIMIT)
            .map<FormattedPepperTransaction>(item => ({
                amountReceived: Dinero({
                    amount: isDefined(item.paymentIntent?.amountReceived)
                        ? item.paymentIntent?.amountReceived
                        : item.total,
                    currency: isCurrencyCode(item.currency) ? item.currency : 'GBP'
                }).toFormat(),
                amount: item.paymentIntent?.amount
                    ? Dinero({
                          amount: item.paymentIntent?.amount,
                          currency: isCurrencyCode(item.currency) ? item.currency : 'GBP'
                      }).toFormat()
                    : undefined,
                orderId: item.order?.orderId,
                date: new Date(item.created),
                state: item.state,
                customerId: item.userId,
                risk: item.charge?.riskLevel,
                paymentChargeId: item.charge?.chargeId,
                currencyCode: item.currency,
                lastFour: item.paymentIntent?.lastFour,
                expiryDate: item.paymentIntent?.expiryDate,
                pi: item.paymentIntent?.paymentIntentId ?? 'N/A',
                type: item.standalone ? PepperTransactionType.Standalone : PepperTransactionType.Order,
                id: item._id
            }));
    }, [page, transactions]);
    const onPageChange = React.useCallback(
        (pageNumber: number) => {
            if (!loading) {
                setPage(pageNumber);
            }
        },
        [loading]
    );
    const count = React.useMemo(() => {
        if (endOfData) {
            return transactions.length;
        }
        return -1;
    }, [endOfData, transactions.length]);
    return { transactions, formattedTransactions, page, onPageChange, loading, count };
}

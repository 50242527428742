import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LocationSelectByAppIdContext } from './LocationSelectByAppIdContext';
import { useAppIds } from './useAppIds';

interface IOption {
    value: string;
    label: string;
    otherLocationsCount: number;
    appId: string;
}

interface IProps {
    onLocationChange: (locationId: string) => void;
}

export const LocationSelectGroupedByAccountId: React.FC<IProps> = ({ onLocationChange }) => {
    const { appIdToLocations } = useAppIds();
    const { onSelectLocation, selectedLocation } = useContext(LocationSelectByAppIdContext);

    const options: IOption[] = React.useMemo(
        () =>
            Array.from(appIdToLocations.values()).map(locations => {
                const [location] = locations;

                return {
                    label: location.name,
                    value: location.id,
                    otherLocationsCount: locations.length > 1 ? locations.length - 1 : 0,
                    appId: location.appId
                };
            }),
        [appIdToLocations]
    );
    React.useEffect(() => {
        if (options.length > 0) {
            onSelectLocation(options[0].value);
        }
    }, [onSelectLocation, options]);

    const renderOption = React.useCallback(
        ({ value, label, otherLocationsCount, appId }: IOption) => (
            <MenuItem key={`select-location-${value}`} value={value}>
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="caption">
                        {otherLocationsCount > 1 && `+${otherLocationsCount} other locations`} ({appId})
                    </Typography>
                </Box>
            </MenuItem>
        ),
        []
    );
    const renderSingleItem = React.useCallback(
        ({ label, otherLocationsCount, appId }: IOption) => (
            <Box display="flex" flexDirection="column">
                <Typography variant="body2">{label}</Typography>
                <Typography variant="caption">
                    {otherLocationsCount > 1 && `+${otherLocationsCount} other locations`} ({appId})
                </Typography>
            </Box>
        ),
        []
    );
    const handleSelect = React.useCallback(
        (event: SelectChangeEvent) => {
            onSelectLocation(event.target.value);
            onLocationChange(event.target.value);
        },
        [onSelectLocation, onLocationChange]
    );

    return (
        <>
            {options.length > 1 && (
                <Select
                    fullWidth
                    value={selectedLocation}
                    onChange={handleSelect}
                    placeholder="Choose location"
                >
                    {options.map(renderOption)}
                </Select>
            )}
            {options.length === 1 && renderSingleItem(options[0])}
        </>
    );
};

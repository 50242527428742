import React from 'react';
import { shallowEqual } from 'react-redux';
import { Box, TableCell, Typography } from '@mui/material';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { formatDateTime } from 'lib/helpers';
import { MuiTable, MuiTableColumnItem, MuiTableScrollItems } from 'ui/table/MuiTable';
import { ExportedDataActionCell } from 'components/analytics/export/tables/ExportedDataActionCell';
import { OperationStatusChip } from 'components/overwrites/OperationStatusChip';
import { IActionOperation } from 'components/analytics/export/models';

interface IExportedDataTableProps {
    operations: IActionOperation[];
    nextKey?: string;
    onScroll: () => void;
    onClick: (item: IActionOperation) => void;
}

export const ExportedDataTable: React.FC<IExportedDataTableProps> = ({
    operations,
    nextKey,
    onScroll,
    onClick
}) => {
    const columns = React.useMemo<MuiTableColumnItem[]>(
        () => [
            {
                key: 'createdAt',
                label: 'Date',
                render: (item: IActionOperation) => (
                    <TableCell key={`export-date-${item.nonce}`}>{formatDateTime(item.createdAt)}</TableCell>
                )
            },
            {
                key: 'status',
                label: 'State',
                render: (item: IActionOperation) => (
                    <TableCell key={`export-status-${item.nonce}`} align="center" size="small">
                        <OperationStatusChip status={item.status} />
                    </TableCell>
                ),
                headerProps: {
                    align: 'center'
                }
            },
            {
                key: 'result.message',
                label: 'Message',
                headerProps: {
                    align: 'center'
                },
                render: (item: IActionOperation) => {
                    let message = item.result && item.result.message;
                    const isError = item.status === OperationStatus.ERROR;
                    if (isError) {
                        message = item?.error?.message;
                    }
                    return (
                        <TableCell key={`export-message-${item.nonce}`} align="center" size="small">
                            <Box display="flex" justifyContent="center">
                                <Typography
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        display: 'block',
                                        width: '140px'
                                    }}
                                    variant="caption"
                                    align="center"
                                >
                                    {message}
                                </Typography>
                            </Box>
                        </TableCell>
                    );
                }
            },
            {
                key: 'action',
                label: '',
                render: (item: IActionOperation) => (
                    <ExportedDataActionCell key={`export-action-${item.nonce}`} operation={item} />
                )
            }
        ],
        []
    );
    const scroll = React.useMemo<MuiTableScrollItems>(
        () => ({ isMoreItems: !!nextKey, key: nextKey, onScroll }),
        [onScroll, nextKey]
    );

    const getRowKey = React.useCallback((item: IActionOperation) => item.nonce, []);

    return (
        <MuiTable
            alwaysShowHead
            data={operations}
            scroll={scroll}
            columns={columns}
            getRowKey={getRowKey}
            onClick={onClick}
            isLoading={!!nextKey}
        />
    );
};

export const ExportedDataTablePure = React.memo(ExportedDataTable, (prev, next) => shallowEqual(prev, next));

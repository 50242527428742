import { IOperationV2, isOperationV2 } from 'components/operation/models/OperationModel';
import { isApiError } from 'lib/api/error';
import { isDefined, isNumber, isOptional, isString } from 'lib/typeguards';

export interface IGrantPerkToSegmentOperationMetadata {
    perkId: string;
    perkTitle?: string;
    points: number;
}

export function isGrantPerkToSegmentOperationMetadata(
    metadata: any
): metadata is IGrantPerkToSegmentOperationMetadata {
    return (
        isDefined(metadata) &&
        isString(metadata.perkId) &&
        isOptional(isString, metadata.perkTitle) &&
        isNumber(metadata.points)
    );
}

export interface IGrantPerkToSegmentOperationResult {
    etaMs: number;
    successes: number;
    errors: number;
    skipped: number;
    total: number;
}

export function isGrantPerkToSegmentOperationResult(
    result: any
): result is IGrantPerkToSegmentOperationResult {
    return (
        isDefined(result) &&
        isNumber(result.etaMs) &&
        isNumber(result.successes) &&
        isNumber(result.errors) &&
        isNumber(result.skipped) &&
        isNumber(result.total)
    );
}

export type GrantPerkToSegmentOperationError =
    | {
          httpCode: number;
          code: string;
          message: string;
          isRetryable: boolean;
      }
    | { message: string };

export function isGrantPerkToSegmentOperationError(error: any): error is GrantPerkToSegmentOperationError {
    return isDefined(error) && (isApiError(error) || isString(error.message));
}

export type GrantPerkToSegmentOperation = IOperationV2<
    string,
    IGrantPerkToSegmentOperationMetadata,
    IGrantPerkToSegmentOperationResult,
    GrantPerkToSegmentOperationError
>;

export function isGrantPerkToSegmentOperation(operation: any): operation is GrantPerkToSegmentOperation {
    return isOperationV2(
        isString,
        isGrantPerkToSegmentOperationMetadata,
        isGrantPerkToSegmentOperationResult,
        isGrantPerkToSegmentOperationError,
        operation
    );
}

export type RetryGrantPerkToSegmentOperation = GrantPerkToSegmentOperation;

export function isRetryGrantPerkToSegmentOperation(operation: any): operation is GrantPerkToSegmentOperation {
    return isGrantPerkToSegmentOperation(operation);
}

export type SegmentOperation =
    | GrantPerkToSegmentOperation
    | RetryGrantPerkToSegmentOperation
    | IOperationV2<any, any, any, any>;

export function isSegmentOperation(operation: any): operation is SegmentOperation {
    return isGrantPerkToSegmentOperation(operation) || isRetryGrantPerkToSegmentOperation(operation);
}

import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Location, locationActionToLabel } from 'components/location/models/LocationModel';

interface LocationButtonCardComponentProps {
    location: Location;
}

interface LocationButtonCardComponentProps {
    location: Location;
}

export const LocationButtonCardComponent = (props: LocationButtonCardComponentProps) => {
    const {
        location: { buttons }
    } = props;

    return (
        <Grid container spacing={2} direction="row">
            {buttons && buttons.length ? (
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ p: 1, border: 'none' }}>
                                    <b>Title</b>
                                </TableCell>
                                <TableCell sx={{ p: 1, border: 'none' }}>
                                    <b>Action</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buttons.map(button => (
                                <TableRow key={button.title}>
                                    <TableCell sx={{ p: 1, border: 'none' }}>{button.title}</TableCell>
                                    <TableCell sx={{ p: 1, border: 'none' }}>
                                        {locationActionToLabel[button.action]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                        Here you can define buttons that will appear in your locations page in the app. Click
                        the edit button to get started
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

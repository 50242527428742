import Resizer from 'react-image-file-resizer';
import { format, isToday, isValid } from 'date-fns';
import { Page } from 'components/navigation/models/Page';
import { preloadImage } from 'lib/imageHelpers';
import { DateRangePickerValue } from 'ui/MuiDateRangePicker';
import { RGBAValue } from './form/fields/ColorFormField';
import { isDefined, isNumber, isString, isUndefined } from './typeguards';
import { Option } from 'lib/types';
import { hexToRgb } from '@mui/material';

export const titleize = (string?: string, everyWord?: boolean): string => {
    if (typeof string !== 'string' || string.length <= 0) {
        console.warn('titleize(string) expects a non empty string argument.');
        return '';
    }

    if (everyWord) {
        const words: string[] = [];
        string.split(' ').forEach(word => {
            words.push(word.charAt(0).toUpperCase() + word.toLowerCase().slice(1));
        }, []);

        return words.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const pageToTitle = (page: Page): string => {
    if (page.title) {
        return page.title;
    }

    const name = page && page.pathname ? page.pathname.replace(/.*\//, '') : '';

    return titleize(name);
};

export const getFieldWithAppPrefix = (fieldName: string) => {
    const appPrefix = 'phq';
    return `${appPrefix}-${fieldName}`;
};

export const updateArrayItem = <T>(arr: T[], i: number, value: T): T[] =>
    Object.assign([...arr], { [i]: value });

export const removeArrayItem = <T>(arr: T[], index: number): T[] => {
    if (typeof index !== 'number') {
        console.warn('index must be a number');
        return arr;
    }
    if (index < 0) {
        console.warn('index must be greater than 0');
        return arr;
    }
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const arrayMove = <T>(arr: T[], from: number, to: number): T[] => {
    if (from === to) {
        return arr;
    }
    if (to >= arr.length) {
        let k = to - arr.length + 1;
        while (k--) {
            arr.push(undefined as unknown as T);
        }
    }
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
};

export function getFullHex(colour: string) {
    if (typeof colour !== 'string') {
        return null;
    }
    if (colour.length === 4) {
        const shortNumber = colour.substr(1);
        return `#${shortNumber}${shortNumber}`;
    }
    return colour;
}

export function getCurrencyString(value: number, currency?: string) {
    if (!currency || currency === 'UNKNOWN') {
        return String(value);
    }
    return new Intl.NumberFormat('en-GB', { currency, style: 'currency' }).format(value);
}

export const generateNumArray = (length: number) =>
    Array.from({ length }, (_: never, index: number) => index);

export function getPlatform() {
    let osName = 'Unknown OS';
    if (navigator.appVersion.includes('Win')) {
        osName = 'Windows';
    }
    if (navigator.appVersion.includes('Mac')) {
        osName = 'MacOS';
    }
    if (navigator.appVersion.includes('X11')) {
        osName = 'UNIX';
    }
    if (navigator.appVersion.includes('Linux')) {
        osName = 'Linux';
    }
    return osName;
}

export const formatDateTime = (date: string | Date) => {
    if (isValid(isString(date) ? new Date(date) : date)) {
        return format(new Date(date), 'dd/MM/yyyy h:mmaa');
    }
};

export const formatDate = (date: string | Date) => {
    if (isValid(isString(date) ? new Date(date) : date)) {
        return format(new Date(date), 'dd/MM/yyyy');
    }
};

export const rgbaToHex = (value: RGBAValue, isShort?: boolean) => {
    if (isString(value)) {
        if (isShort && value.length === 9) {
            return value.substring(0, 7);
        }
        return value;
    }
    const { r, g, b, a } = value;
    let opacity = ((isNumber(a) ? a : 1) * 255).toString(16);
    const dotIndex = opacity.indexOf('.');
    if (dotIndex === 1) {
        opacity = `0${opacity}`;
    }
    if (a === 0) {
        opacity = '00';
    }
    if (a === 1) {
        opacity = '';
    }
    let rValue = r.toString(16);
    if (rValue.length === 1) {
        rValue = `0${rValue}`;
    }
    let gValue = g.toString(16);
    if (gValue.length === 1) {
        gValue = `0${gValue}`;
    }
    let bValue = b.toString(16);
    if (bValue.length === 1) {
        bValue = `0${bValue}`;
    }
    if (isShort) {
        return `#${rValue}${gValue}${bValue}`;
    }
    return `#${rValue}${gValue}${bValue}${opacity.substring(0, 2)}`;
};

export function arraysEqual(a: any[] = [], b: any[] = []) {
    return a.length === b.length && a.every(item => b.includes(item)) && b.every(item => a.includes(item));
}

export function arraysSubstract(a: any[] = [], b: any[] = []) {
    return a.filter(item => !b.includes(item));
}

export function formatDateRange(value?: DateRangePickerValue, useToday = false) {
    if (!value) {
        // Not sure what should be here
        return undefined;
    }
    const isStartToday = useToday && value.startDate && isToday(value.startDate);
    const isEndToday = useToday && value.endDate && isToday(value.endDate);
    if (!value.endDate) {
        return isStartToday ? 'Today' : format(value.startDate, 'dd/MM/yyyy');
    }
    return `${isStartToday ? 'Today' : format(value.startDate, 'dd/MM/yyyy')} - ${
        isEndToday ? 'Today' : format(value.endDate, 'dd/MM/yyyy')
    }`;
}

export function copyToClipboard(str: string) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function parseIntOrUndefined(input: string, base = 10): number | undefined {
    const parsed = parseInt(input, base);
    if (isNaN(parsed)) {
        return undefined;
    }
    return parsed;
}

export function parseIntOrNull(input: string, base = 10): number | null {
    const parsed = parseInt(input, base);
    return isNaN(parsed) ? null : parsed;
}

export function stringOrNull(input?: string | null): string | null {
    if (input === null || !isDefined(input)) {
        return null;
    }
    const isBlank = input.trim().length === 0;
    return isBlank ? null : input;
}

export function parseFloatOrUndefined(input: string): number | undefined {
    const parsed = parseFloat(input);
    if (isNaN(parsed)) {
        return undefined;
    }
    return parsed;
}

export function parseFloatOrNull(input: string): number | null {
    const parsed = parseFloat(input);
    if (isNaN(parsed)) {
        return null;
    }
    return parsed;
}

export function defaultTippingIndexLabel(defaultTipIndex: any, defaultTipOptions: Option[]) {
    if (
        !isUndefined(defaultTipIndex) &&
        Number.isInteger(defaultTipIndex) &&
        defaultTipIndex >= 0 &&
        defaultTipIndex < 4
    ) {
        return defaultTipOptions[defaultTipIndex].label;
    }
    return '';
}

export function getTippingDefault(defaultTipIndex?: number, values?: number[]): string {
    if (isUndefined(defaultTipIndex) && !values && defaultTipIndex < 0 && defaultTipIndex < 3) {
        return '';
    }

    return String(defaultTipIndex);
}

export function resizeImage(file: Blob, width: number, height: number, format: string): Promise<Blob> {
    return new Promise(res => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            format,
            100,
            0,
            (newBlob: Blob) => {
                res(newBlob);
            },
            'blob'
        );
    });
}

// this function is to get the first valid asset among some
export async function checkAvailableAsset(urls: string[]): Promise<string | undefined> {
    const [url, ...otherUrls] = urls;
    if (url) {
        const res = await preloadImage(url);
        if (res) {
            return res;
        }

        if (otherUrls.length === 0) {
            throw new Error('Not found');
        } else {
            return checkAvailableAsset(otherUrls);
        }
    }
}

export const isSeparatedIntegerValid = (stringToCheck: string, separator = ',') =>
    stringToCheck.split(separator).every(str => Number.isInteger(Number(str.trim())));

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const roundToDecimal = (num: number, decimalCount = 2) => {
    const powerOfTen = Math.pow(10, decimalCount);
    return Math.round((num + Number.EPSILON) * powerOfTen) / powerOfTen;
};

export const getFileName = (url?: string, separator = '/content/') => {
    if (url) {
        const [, fileName] = url.split(separator);
        if (fileName) {
            return fileName.split('.')[0];
        }
    }
};

export const addOpacity = (colour: string, opacity: number) => hexToRgb(colour).replace(')', `, ${opacity})`);

export const getTimezoneIrrelevantDateTime = (date: string | Date) => {
    const newEndDate = new Date(date);
    const hoursDiff = newEndDate.getHours() - newEndDate.getTimezoneOffset() / 60;
    const minutesDiff = (newEndDate.getHours() - newEndDate.getTimezoneOffset()) % 60;
    newEndDate.setHours(hoursDiff);
    newEndDate.setMinutes(minutesDiff);
    return newEndDate;
};

const MINUTES_IN_DAY = 1440;

export const getDaysFromMinutes = (minutes?: number) => {
    if (!minutes) {
        return minutes;
    }
    return Math.round(minutes / MINUTES_IN_DAY);
};
export const getMinutesFromDays = (days: number) => {
    if (!days) {
        return days;
    }
    return days * MINUTES_IN_DAY;
};

export const createDownloadLink = (blob: Blob, fileName: string) => {
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();
    link.remove();

    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL.
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
        window.URL.revokeObjectURL(downloadUrl);
    }, 100);
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ExpiringClaims } from '@pepperhq/auth-client';
import { ActionCreator } from 'redux';
import { WithRouterProps } from 'types/history';
import { SignInForm } from 'components/auth/forms/SignInForm';
import { getAuthToken } from 'components/auth/getAuthToken';
import { MarketingLayout } from 'components/auth/MarketingLayout';
import * as routes from 'config/routes';
import { signIn, SignInAction } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { Link } from 'ui/Link';

interface SignInPageProps extends WithRouterProps {
    signIn: (email: string, password: string) => Promise<SignInAction>;
    claims?: ExpiringClaims;
    redirectTo?: string;
    error?: string;
}

interface SignInPageState {
    isLoading: boolean;
    isSubmitted: boolean;
    redirectTo: string;
}

class SignInPage extends React.Component<SignInPageProps, SignInPageState> {
    private textInput: HTMLInputElement;

    constructor(props: SignInPageProps) {
        super(props);
        this.state = {
            isLoading: false,
            isSubmitted: false,
            redirectTo: undefined
        };
    }

    async componentDidMount() {
        // Focus the text input using the raw DOM API
        if (this.textInput) {
            this.textInput.focus();
        }
        const params = new URLSearchParams(this.props.location.search);
        const redirectTo = params.get('redirectTo');
        this.setState({ redirectTo });
        const authToken = await getAuthToken();
        // Do not render this page and redirect to home one if user is authenticated
        if (authToken && !this.props.error) {
            this.props.history.replace(redirectTo || routes.USER_MERCHANTS);
        }
    }

    setTextInputRef = (element: HTMLInputElement) => {
        this.textInput = element;
    };

    handleKeyDown = (e: React.KeyboardEvent, data: { email: string; password: string }) => {
        if (e.keyCode === 13) {
            this.handleSubmit(data);
        }
    };

    handleSubmit = async (data: { email: string; password: string }) => {
        const { email, password } = data;
        await this.props.signIn(email, password);
        this.setState({ isSubmitted: true });
    };

    handleSignUpClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const { redirectTo } = this.state;
        if (redirectTo) {
            e.preventDefault();
            this.props.history.push(`${routes.SIGN_UP}?redirectTo=${redirectTo}`);
        }
    };

    async componentDidUpdate() {
        const { error, claims } = this.props;
        const { isSubmitted, redirectTo } = this.state;

        if (isSubmitted && (error || claims)) {
            if (claims) {
                this.props.history.push(redirectTo || routes.USER_MERCHANTS);
            } else {
                this.setState({ isLoading: false, isSubmitted: false });
            }
        } else if (claims) {
            const authToken = await getAuthToken();
            if (authToken && !this.props.error) {
                this.props.history.replace(redirectTo || routes.USER_MERCHANTS);
            }
        }
    }

    render() {
        const { error } = this.props;
        return (
            <MarketingLayout
                marketingId="optimize-sign-in"
                after={
                    <React.Fragment>
                        <Typography align="center" variant="caption" color="textSecondary">
                            <Link href={routes.FORGOT_YOUR_PASSWORD}>Forgot your password? </Link>
                        </Typography>
                        <Typography align="center" variant="caption" color="textSecondary">
                            Don&apos;t have an account?{' '}
                            <Link onClick={this.handleSignUpClick} href={routes.SIGN_UP}>
                                Sign up
                            </Link>
                        </Typography>
                    </React.Fragment>
                }
            >
                <SignInForm onSubmit={this.handleSubmit} />
                {error && (
                    <Typography sx={{ pt: 2 }} align="center" color="error" data-cy="loginErrorLabel">
                        {error}
                    </Typography>
                )}
            </MarketingLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    error: state.auth.error,
    claims: state.auth.claims
});

const mapDispatchToProps = (dispatch: ActionCreator<SignInAction>) => ({
    signIn: async (email: string, password: string) => signIn(email, password)(dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInPage));

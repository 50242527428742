import React from 'react';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { useDispatch } from 'react-redux';
import {
    MESSAGE_TIERED_LOYALTY_STATE_EDIT_ERROR,
    MESSAGE_TIERED_LOYALTY_STATE_EDIT_SUCCESS
} from 'config/messages';
import logger from 'lib/logger';
import { ELoyaltyTierState } from 'components/metrics/model/loyalty-tier';
import { UserMetricDefinition } from 'components/metrics/model';
import { metricsApi } from 'components/metrics/metricsApi';

export function useTieredLoyaltyStateModal(
    metricDefinition: UserMetricDefinition | undefined,
    onUpdate: (value: UserMetricDefinition) => void
) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleStateChangeOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleStateChangeClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleStateChangeSubmit = React.useCallback(
        async (state: string) => {
            const getFlagsFromState = (tierState: string) => {
                switch (tierState) {
                    case ELoyaltyTierState.SCHEDULED_AND_REALTIME:
                        return { realtime: true, scheduled: true };
                    case ELoyaltyTierState.REALTIME:
                        return { realtime: true, scheduled: false };
                    case ELoyaltyTierState.SCHEDULED:
                        return { realtime: false, scheduled: true };
                    case ELoyaltyTierState.INACTIVE:
                    default:
                        return { realtime: false, scheduled: false };
                }
            };
            setLoading(true);
            const result = await metricsApi.update(metricDefinition?._id, {
                body: { ...getFlagsFromState(state) }
            });
            setLoading(false);
            if (!result.ok) {
                logger.error(result.body.message, result.body);
                dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_STATE_EDIT_ERROR, { variant: 'error' }));
                return;
            }
            dispatch(enqueueSnackbar(MESSAGE_TIERED_LOYALTY_STATE_EDIT_SUCCESS, { variant: 'success' }));
            onUpdate(result.body);
            setOpen(false);
        },
        [dispatch, metricDefinition?._id, onUpdate]
    );
    return {
        stateModalOpen: open,
        handleStateChangeOpen,
        handleStateChangeClose,
        handleStateChangeSubmit,
        loading
    };
}

import { awardEnrichmentApi } from 'components/loyalty/AwardEnrichmentsApi';
import { IAwardEnrichment } from 'components/loyalty/models/AwardEnrichment';
import {
    MESSAGE_MARKETING_AWARD_DELETE_ERROR,
    MESSAGE_MARKETING_AWARD_DELETE_SUCCESS
} from 'config/messages';
import { removeArrayItem, updateArrayItem } from 'lib/helpers';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';

export const useExternalPerksMutation = ({
    awardEnrichments,
    indexToDelete,
    setAwardEnrichments,
    setIndexToDelete,
    setIsCreateOpen,
    setIsCreateConfirmationOpen,
    setIsUpdateConfirmationOpen,
    handleResetEditSelection
}: {
    awardEnrichments: IAwardEnrichment[];
    indexToDelete: number;
    setIsCreateOpen: () => void;
    setIsCreateConfirmationOpen: () => void;
    setIsUpdateConfirmationOpen: () => void;
    setAwardEnrichments: React.Dispatch<React.SetStateAction<IAwardEnrichment[]>>;
    setIndexToDelete: React.Dispatch<React.SetStateAction<number>>;
    handleResetEditSelection: () => void;
}) => {
    const dispatch = useDispatch();

    const [isProcessing, setIsProcessing] = React.useState(false);

    const handleConfirmDelete = React.useCallback(async () => {
        const award = awardEnrichments[indexToDelete];

        if (award) {
            try {
                setIsProcessing(true);
                const res = await awardEnrichmentApi.deleteAward(award._id, { skipResponseBody: true });

                if (!res.ok) {
                    throw new Error(res.body.message);
                }

                setAwardEnrichments(values => removeArrayItem(values, indexToDelete));
                setIndexToDelete(-1);

                dispatch(enqueueSnackbar(MESSAGE_MARKETING_AWARD_DELETE_SUCCESS, { variant: 'success' }));
            } catch (error) {
                logger.error(error.message);
                dispatch(enqueueSnackbar(MESSAGE_MARKETING_AWARD_DELETE_ERROR, { variant: 'error' }));
            }
            setIsProcessing(false);
        }
    }, [awardEnrichments, dispatch, indexToDelete, setAwardEnrichments, setIndexToDelete]);

    const handleCreateSubmit = React.useCallback(
        (award: IAwardEnrichment) => {
            setIsCreateOpen();
            setAwardEnrichments(awards => [...awards, award]);
        },
        [setAwardEnrichments, setIsCreateOpen]
    );
    const handleUpdateSuccess = React.useCallback(
        (award: IAwardEnrichment) => {
            const indexToUpdate = awardEnrichments.findIndex(({ _id }) => _id === award._id);
            if (indexToUpdate !== -1) {
                setAwardEnrichments(awards => updateArrayItem(awards, indexToUpdate, award));
                handleResetEditSelection();
            }
        },
        [awardEnrichments, handleResetEditSelection, setAwardEnrichments]
    );

    return {
        isProcessing,
        setIsCreateOpen,
        setIsCreateConfirmationOpen,
        setIsUpdateConfirmationOpen,
        handleCreateSubmit,
        handleUpdateSuccess,
        handleConfirmDelete
    };
};

import { Box, Paper } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { OperationStatus } from 'components/operation/models/OperationModel';
import { PepperPayOperation } from 'components/pepper-pay/model/PepperPay';
import { pepperPayApi } from 'components/pepper-pay/pepperPayApi';
import { NewReportModal } from 'components/pepper-pay/reporting/NewReportModal';
import { ReportingTable } from 'components/pepper-pay/reporting/ReportingTable';
import { MESSAGE_REPORT_CREATION_ERROR } from 'config/messages';
import { useInterval } from 'lib/hooks/useInterval';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'store/notifications/notificationsActions';
import { TableActionButton } from 'ui/buttons/TableActionButton';
import { MuiTableToolbarItems } from 'ui/table/MuiTable';

export const Reporting: React.FC = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
    const [operations, setOperations] = React.useState<PepperPayOperation[]>([]);
    const lastOperation = React.useMemo(() => (operations ? operations[0] : null), [operations]);
    const pendingOperations = React.useMemo(
        () =>
            operations.filter(
                operation =>
                    operation.status === OperationStatus.PENDING ||
                    operation.status === OperationStatus.RUNNING
            ),
        [operations]
    );
    const [nextKey, setNextKey] = React.useState();

    React.useEffect(() => {
        pepperPayApi.operations
            .getReportList()
            .then(res => {
                setOperations(res.body.items);
                setNextKey(res.body.page?.nextKey);

                return res;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    const toggleCreateModal = React.useCallback(() => setIsCreateModalOpen(state => !state), []);
    const toolbarItems: MuiTableToolbarItems = {
        renderCustomElement: () => (
            <TableActionButton onClick={toggleCreateModal} Icon={Add}>
                Create Report
            </TableActionButton>
        )
    };
    const handleCreateReport = React.useCallback((operation: PepperPayOperation) => {
        setOperations(previous => [operation, ...previous]);
    }, []);

    useInterval(() => {
        if (pendingOperations.length) {
            const promises = pendingOperations.map(item => pepperPayApi.operations.getOperation(item.id));
            Promise.all(promises)
                .then(results => {
                    const resultOperations: PepperPayOperation[] = results.map(item => item.body);
                    setOperations(current =>
                        current.map(operation => {
                            const newOperation = resultOperations.find(
                                resultOperation => resultOperation.id === operation.id
                            );

                            if (newOperation) {
                                return newOperation;
                            }

                            return operation;
                        })
                    );
                })
                .catch(e => {
                    logger.error(e.message);
                    dispatch(enqueueSnackbar(MESSAGE_REPORT_CREATION_ERROR, { variant: 'error' }));
                });
        }
    }, 1500);
    const handleLoadMore = React.useCallback(() => {
        if (nextKey) {
            pepperPayApi.operations.getReportList(nextKey).then(({ body, ok }) => {
                if (ok && body.items?.length > 0) {
                    setOperations(prev => [...prev, ...body.items]);
                }
            });
        }
    }, [nextKey]);

    return (
        <Box>
            <Paper>
                <ReportingTable
                    isLoading={isLoading}
                    toolbarItems={toolbarItems}
                    operations={operations}
                    nextKey={nextKey}
                    onScroll={handleLoadMore}
                />
            </Paper>
            {!isLoading && (
                <NewReportModal
                    open={isCreateModalOpen}
                    onClose={toggleCreateModal}
                    onCreate={handleCreateReport}
                    lastOperation={lastOperation}
                />
            )}
        </Box>
    );
};

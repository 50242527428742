import * as React from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { Row } from 'ui/Flex';
import { Search } from '@mui/icons-material';

interface IProps {
    onChange: (value?: string) => void;
    value?: string;
}

export const MenuFilter: React.FC<IProps> = ({ value = '', onChange }) => {
    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );
    const handleClear = React.useCallback(() => {
        onChange('');
    }, [onChange]);

    const renderButton = React.useCallback(
        () =>
            value ? (
                <Button disabled={value.length === 0} onClick={handleClear} variant="outlined">
                    {'Clear'}
                </Button>
            ) : null,
        [handleClear, value]
    );

    return (
        <Row>
            <TextField
                variant="outlined"
                value={value}
                onChange={handleChange}
                label="Menu Name"
                InputProps={{
                    endAdornment: renderButton(),
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
        </Row>
    );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IResourcePermissionsList } from '@pepperhq/auth-client';
import { ApplicationState } from 'store/store';

interface RestrictedRouteProps extends RouteProps {
    resources?: IResourcePermissionsList;
}

export const RestrictedRoute: React.FC<RestrictedRouteProps> = props => {
    const claims = useSelector((state: ApplicationState) => state.auth.claims);
    if (!props.resources || claims.hasPermissions(props.resources)) {
        return <Route {...props} />;
    }
    return <Redirect to={'/'} />;
};
